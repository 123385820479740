<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import EisSocForm from "../../../components/payroll/eissocsubmissionrecord/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": EisSocForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('alleissocso.editsocsoandeissubmissionrec'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadHeaderHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/eissocsosubmissionrecord/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          console.log('formdata', vm.formData)

          vm.formData.eissocsubreccode = res.data.rec[0].eissocsubreccode;

          vm.pageTitleValue = res.data.rec[0].eissocsubreccode;
          vm.formData.userid = this.$store.getters.userId;

          vm.loadDetailHandlerPay();

          //vm.headLoading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDetailHandlerPay() {
      const vm = this;
      console.log("id", this.id);

      this.$store.dispatch('get', {url: "/eissocsosubmissionrecordpy/" + this.id})
        .then((res) => {
          vm.formData.eissocsosubrecpay = res.data.rec;

           for (let i = 0; i < vm.formData.eissocsosubrecpay.length; i++) {

            // vm.formData.socsosubrecpay[i].pyctrlid = res.data.rec[0].pyctrlid;
            // vm.formData.socsosubrecpay[i].socsosubrecid = res.data.rec[0].socsosubrecid;
            // vm.formData.socsosubrecpay[i].payoutcode = res.data.rec[0].payoutcode;
            // vm.formData.socsosubrecpay[i].payoutdesc = res.data.rec[0].payoutdesc;
            // vm.formData.socsosubrecpay[i].pystatuscode = res.data.rec[0].pystatuscode;
            // vm.formData.socsosubrecpay[i].pystatusdesc = res.data.rec[0].pystatusdesc;

            Object.assign(vm.formData.eissocsosubrecpay[i], { action: 2 });
          }

          vm.loadDetailHandlerRef();
          // vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDetailHandlerRef() {
      const vm = this;

      this.$store.dispatch('get', {url: "/eissocsosubmissionrecordref/" + this.id})
        .then((res) => {
          vm.formData.eissocsosubrecref = res.data.rec;

          console.log('subref', vm.formData.eissocsosubrecref)

          for (let i = 0; i < vm.formData.eissocsosubrecref.length; i++) {
            if (vm.formData.eissocsosubrecref[i].receiptdate) {
              vm.formData.eissocsosubrecref[i].receiptdate = moment(
                vm.formData.eissocsosubrecref[i].receiptdate
              ).format("YYYY-MM-DD");
            }
          }


           for (let i = 0; i < vm.formData.eissocsosubrecref.length; i++) {
            Object.assign(vm.formData.eissocsosubrecref[i], { action: 2 });
            Object.assign(vm.formData.eissocsosubrecref[i], { no: i + 1 });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
        this.$router.push({name: 'eissocsubnew'});
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/eissocsosubmissionrecord/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "eissocsublist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "eissocsublist" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/eissocsosubmissionrecord/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "eissocsublist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadHeaderHandler();
    // this.loadDetailHandlerPay();
    // this.loadDetailHandlerRef();
  },
};
</script>