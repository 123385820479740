<template>
  <div id="header">
    <v-app-bar height="110px" color="#ECEFF1" light class="px-10" fixed dense>
      <div>
        <!-- <div>
          <v-img
            class="float-left"
            :src="`${publicPath}images/company_name.png`"
            max-height="50"
            max-width="220"
          />
        </div> -->
        <app-header-logo />
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-row>
          <div style="padding-bottom: 3px; padding-top: 10px">
            <span class="welcome_text pb-2"
              >{{$t('header.loginas')}}: {{ this.$store.getters.userName }}</span
            >
          </div>
        </v-row>
        <v-row>
          <div>
            <span>{{$t('header.lastlogin')}}: {{ this.lastAccess }}</span>
          </div>
        </v-row>

        <v-row style="padding-top: 15px">
          <!-- <v-btn text>{{$t('langname')}}</v-btn> -->
          <template v-if="userType"><app-admin-screen /></template>
          <app-lang-switch />
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on"><v-icon>menu</v-icon></v-btn>
            </template>
            <v-list style="color: white" dense>
              <v-list-item
                v-for="link in links"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-title
                  style="color: black; font-size: 14px; padding-top: -5px"
                  >{{ link.text }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title style="color: black; font-size: 14px"
                  ><span @click="logoutHandler" style="cursor: pointer"
                    >{{$t('logout')}}</span
                  ></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import LangSwitch from "./LangSwitch.vue";
import HeaderLogo from "./HeaderLogo.vue";
import AdminScreen from "./AdminScreen.vue";
import moment from "moment";

export default {

  components: {
    "app-lang-switch": LangSwitch,
    "app-header-logo": HeaderLogo,
    "app-admin-screen": AdminScreen,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      // links: [
      //   // { text: (this.$t('home')), route: "/" + this.$i18n.locale + "/dashboard" },
      //   {
      //     text: (this.$t('changepaswword')),
      //     route:
      //       "/" +
      //       this.$i18n.locale +
      //       "/changepassword/" +
      //       this.$store.getters.userId,
      //   },
      // ],
      links: [],
      lastAccess: moment(this.$store.getters.lastAccess).format("ll h:mm:ss A"),
      userType: null
    };
  },

  methods: {
    logoutHandler() {
      this.$router.replace("/" + this.$i18n.locale + "/home/" + this.$store.getters.db);
      this.$store.dispatch("deleteToken");
    }
  },

  mounted() {
    this.userType = (this.$store.getters.userType == 1 && this.$store.getters.psnId !== null) ? true : false

    if(this.$store.getters.userType == 1){
      this.links = [
        { text: (this.$t('home')), route: "/" + this.$i18n.locale + "/dashboard" },
        {
          text: (this.$t('changepaswword')),
          route:
            "/" +
            this.$i18n.locale +
            "/changepassword/" +
            this.$store.getters.userId,
        },
      ]
    } else {
      this.links = [
        // { text: (this.$t('home')), route: "/" + this.$i18n.locale + "/dashboard" },
        {
          text: (this.$t('changepaswword')),
          route:
            "/" +
            this.$i18n.locale +
            "/changepassword/" +
            this.$store.getters.userId,
        },
      ]
    }

    console.log(this.userType)
    
    // if(this.userType == true) {
    //   this.links = this.links;
    // } else {
    //   this.links = [
    //     {
    //       text: (this.$t('changepaswword')),
    //       route:
    //         "/" +
    //         this.$i18n.locale +
    //         "/changepassword/" +
    //         this.$store.getters.userId,
    //     }
    //   ]
    // }
  }

  
};
</script>