<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="12" sm="8" class="pb-n5" v-if="!this.formData.lockstaff">
              <!-- based on staff banka account id-->
              <v-autocomplete
                outlined
                :label="this.$t('columns.name')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffid"
                @input="updateValue"
                dense
                :items="psn0List"
                @change="changePersonExtraInfoHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="8" class="pb-n5" v-else>
              <!-- based on staff banka account id-->
              <v-autocomplete
                outlined
                :label="this.$t('columns.name')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffid"
                readonly
                dense
                :items="psn0List"
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4" sm="4" class="pb-n5 pl-0">
              <v-btn v-if="!hideAiBtn" readonly depressed outlined color="black"
                >{{$t('maintenanceforpayroll.foundbankaccount')}}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                ref="startdate"
                :rules="[rules.required]"
                v-model="formData.startdate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                ref="enddate"
                :rules="[rules.required]"
                v-model="formData.enddate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-select
                outlined
                :label="this.$t('maintenanceforpayroll.paymentmode')"
                ref="paymodeid"
                :rules="[rules.required]"
                v-model="formData.paymodeid"
                @input="updateValue"
                :items="paymodeList"
                @change="changePaymodeHandler(formData.paymodeid)"
                dense
              ></v-select>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-btn
                v-model="aiBtn"
                v-if="hideAiBtn"
                @click="aiBtn = !aiBtn"
                color="white"
                class="black--text"
                depressed
                >{{$t('maintenanceforpayroll.accountinfo')}}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-select
                outlined
                :label="this.$t('maintenanceforpayroll.paymentstatus')"
                ref="paystatid"
                :rules="[rules.required]"
                v-model="formData.paystatid"
                @input="updateValue"
                :items="paystatList"
                @change="changePaystatHandler(formData.paystatid)"
                dense
              ></v-select>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-autocomplete
                v-if="!aiBtn"
                outlined
                :label="this.$t('columns.staffcode')"
                dense
                :items="psn1List"
                v-model="formData.staffid"
                readonly
                append-icon=" "
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-autocomplete
                v-if="!aiBtn"
                outlined
                :label="this.$t('setupcompanybankaccount.accountno')"
                dense
                :items="psn2List"
                v-model="formData.staffid"
                readonly
                append-icon=" "
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row cols="1" sm="1" class="pb-n5 mt-n8">
            <v-btn-toggle rounded group>
              <v-btn
                v-model="taxBtn"
                @click="taxBtn = !taxBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.tax')}}</v-btn
              >

              <v-btn
                v-model="epfBtn"
                @click="epfBtn = !epfBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.epf')}}</v-btn
              >

              <v-btn
                v-model="socsoBtn"
                @click="socsoBtn = !socsoBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.socso')}}</v-btn
              >

              <v-btn
                v-model="epfveBtn"
                @click="epfveBtn = !epfveBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('setupepfve.epfve')}}</v-btn
              >

              <v-btn
                v-model="eisBtn"
                @click="eisBtn = !eisBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.eis')}}</v-btn
              >

              <v-btn
                v-model="baitulmalBtn"
                @click="baitulmalBtn = !baitulmalBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.baitulmal')}}</v-btn
              >

              <v-btn
                v-model="zakatBtn"
                @click="zakatBtn = !zakatBtn"
                depressed
                color="white"
                class="blue--text"
                >{{$t('maintenanceforpayroll.zakat')}}</v-btn
              >
            </v-btn-toggle>
          </v-row>

          <!-- TAX PART -->
          <v-row>
            <v-col cols="10" sm="6" class="pb-n4">
              <v-autocomplete
                v-if="taxBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.taxcategory')"
                type="text"
                ref="pcbcatid"
                v-model="formData.pcbcatid"
                @input="updateValue"
                dense
                :items="pcbcatList"
                @change="changePcbHandler(formData.pcbcatid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4">
              <v-text-field
                v-if="taxBtn"
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.pcbcatcode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                v-if="taxBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.childcount')"
                type="number"
                ref="childcount"
                v-model="formData.childcount"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="6" class="pb-n4 mt-n8">
              <v-autocomplete
                v-if="taxBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.pcbset')"
                type="text"
                ref="taxid"
                v-model="formData.taxid"
                @input="updateValue"
                dense
                :items="taxList"
                @change="changeTaxHandler(formData.taxid)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                v-if="taxBtn"
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.pcbsetcode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n10">
              <v-checkbox
                v-if="taxBtn"
                ref="isCompnypaytax"
                v-model="formData.isCompnypaytax"
                :label="this.$t('maintenanceforpayroll.companypaxtax')"
                @change="updateValue"
              ></v-checkbox>
            </v-col>
          </v-row>

          <!-- EPF PART -->

          <v-row v-if="epfBtn">
            <v-col cols="8" sm="8" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setupepf.epfgroup')"
                type="text"
                id="epf1"
                :items="epfList"
                v-model="formData.epfgroupid"
                @input="updateValue"
                @change="changeEpfHandler(formData.epfgroupid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="epfBtn">
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeepercentage')"
                type="number"
                ref="epfeep"
                v-model="formData.epfeep"
                @input="updateValue"
                dense
                :rules="[rules.percentage]"
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideerpercentage')"
                type="number"
                ref="epferp"
                v-model="formData.epferp"
                @input="updateValue"
                dense
                :rules="[rules.percentage]"
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeeamount')"
                type="number"
                ref="epfeeamt"
                v-model="formData.epfeeamt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeramount')"
                type="number"
                ref="epferamt"
                v-model="formData.epferamt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- SOCSO PART -->
          <v-row>
            <v-col cols="8" sm="8" class="pb-n4 mt-n8">
              <v-autocomplete
                v-if="socsoBtn"
                outlined
                :label="this.$t('setupsocso.socsoscheme')"
                type="text"
                ref="socsoid"
                hide-selected
                :items="socsoList"
                v-model="formData.socsoid"
                @input="updateValue"
                @change="changeSocsoHandler(formData.socsoid)"
                dense
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- EPF VE PART -->

          <v-row>
            <v-col cols="8" sm="8" class="pb-n4 mt-n8">
              <v-autocomplete
                v-if="epfveBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.epfvegroup')"
                type="text"
                ref="epfid"
                hide-selected
                :items="epfveList"
                v-model="formData.epfveid"
                @input="updateValue"
                @change="changeEpfveHandler(formData.epfveid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="epfveBtn">
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeepercentage')"
                type="number"
                ref="veeep"
                v-model="formData.veeep"
                @input="updateValue"
                dense
                :rules="[rules.percentage]"
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideerpercentage')"
                type="number"
                ref="veerp"
                v-model="formData.veerp"
                @input="updateValue"
                dense
                :rules="[rules.percentage]"
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeeamount')"
                type="number"
                ref="veeeamt"
                v-model="formData.veeeamt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeramount')"
                type="number"
                ref="veeramt"
                v-model="formData.veeramt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- EIS PART -->
          <v-row>
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-checkbox
                v-if="eisBtn"
                outlined
                ref="isEis"
                :label="this.$t('maintenanceforpayroll.contributetoeis')"
                v-model="formData.isEis"
                @change="updateValue"
              ></v-checkbox>
            </v-col>
          </v-row>

          <!-- BAITULMAL PART -->
          <v-row>
            <v-col cols="8" sm="8" class="pb-n4 mt-n8">
              <v-autocomplete
                v-if="baitulmalBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.baitulmalcategory')"
                type="text"
                ref="baitulmalid"
                hide-selected
                :items="baitulmalList"
                v-model="formData.baitulmalid"
                @input="updateValue"
                @change="changeBaitulmalHandler(formData.baitulmalid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="baitulmalBtn">
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeeamount')"
                type="number"
                ref="baieeamt"
                v-model="formData.baieeamt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('maintenanceforpayroll.overrideeramount')"
                type="number"
                ref="baieramt"
                v-model="formData.baieramt"
                @input="updateValue"
                dense
                class="removeSpin"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- ZAKAT PART -->
          <v-row>
            <v-col cols="2" sm="2" class="pb-n4 mt-n10">
              <v-checkbox
                v-if="zakatBtn"
                ref="isDynamic"
                v-model="formData.isdynamic"
                :label="this.$t('maintenanceforpayroll.dynamic')"
                @change="updateValue"
              ></v-checkbox>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                v-if="zakatBtn"
                outlined
                :label="this.$t('maintenanceforpayroll.percentageofpcb')"
                type="number"
                ref="zakatp"
                v-model="formData.zakatp"
                @input="updateValue"
                dense
                :rules="[rules.percentage]"
                class="removeSpin"
              ></v-text-field>
            </v-col>

            <!--v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                v-if="zakatBtn"
                                outlined
                                :label="Zakat Amount"
                                type="number"
                                ref="zakatamt"
                                v-model="formData.zakatamt"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col-->
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
// import moment from 'moment';

//import GenericForm from '../../generic/GridForm';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    //'app-genericform': GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || "Value is 1-100",
      },

      aiBtn: true,
      hideAiBtn: true,
      taxBtn: false,
      epfBtn: false,
      socsoBtn: false,
      epfveBtn: false,
      eisBtn: false,
      baitulmalBtn: false,
      zakatBtn: false,

      formData: {
        staffid: this.parentObject.data.staffid,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        paymodeid: this.parentObject.data.paymodeid,
        paystatid: this.parentObject.data.paystatid,
        pcbcatid: this.parentObject.data.pcbcatid,
        pcbcatcode: this.parentObject.data.pcbcatcode,
        childcount: this.parentObject.data.childcount,
        taxid: this.parentObject.data.taxid,
        pcbsetcode: this.parentObject.data.pcbsetcode,
        isCompnypaytax: this.parentObject.data.isCompnypaytax,
        epfgroupid: this.parentObject.data.epfgroupid,
        epfeep: this.parentObject.data.epfeep,
        epferp: this.parentObject.data.epferp,
        epfeeamt: this.parentObject.data.epfeeamt,
        epferamt: this.parentObject.data.epferamt,
        socsoid: this.parentObject.data.socsoid,
        epfveid: this.parentObject.data.epfveid,
        veeep: this.parentObject.data.veeep,
        veerp: this.parentObject.data.veerp,
        veeeamt: this.parentObject.data.veeeamt,
        veeramt: this.parentObject.data.veeramt,
        isEis: this.parentObject.data.isEis,
        baitulmalid: this.parentObject.data.baitulmalid,
        baieeamt: this.parentObject.data.baieeamt,
        baieramt: this.parentObject.data.baieramt,
        // zakatamt: this.parentObject.data.zakatamt,
        zakatp: this.parentObject.data.zakatp,
        isdynamic: this.parentObject.data.isdynamic,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },
      itemData: {
        itemcode: null,
        itemdesc: null,
        itemstamp: 0,
      },

      pcbcats: [],
      pcbcatList: [],

      taxes: [],
      taxList: [],

      epfs: [],
      epfList: [],

      epfves: [],
      epfveList: [],

      socsos: [],
      socsoList: [],

      baitulmals: [],
      baitulmalList: [],

      paymodes: [],
      paymodeList: [],

      paystats: [],
      paystatList: [],

      psns: [],
      psn0List: [],
      psn1List: [],
      psn2List: [],

      errMsg: "",
      isErr: false,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    // psnpayrollExtra will have columns from staff, staffbank account, person, psndetails and staff account number
    // the reason i use staffid is because staff has column that can be connected to person table, staffbankaccount table and company table
    loadPersonExtraInfoHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/psnpayrollExtra/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.psns = res.data.rec;

          for (let i = 0; i < vm.psns.length; i++) {
            vm.psn0List.push({
              text: vm.psns[i].psndetails,
              value: vm.psns[i].staffid,
            });

            vm.psn1List.push({
              text: vm.psns[i].staffcode,
              value: vm.psns[i].staffid,
            });

            vm.psn2List.push({
              text: vm.psns[i].staffaccountno,
              value: vm.psns[i].staffid,
            });
          }
        });
    },

    changePersonExtraInfoHandler(id) {
      let item = this.psns.find((item) => item.staffid == id);

      this.psndetails = item.psndetails;

      if (item.staffaccountno == null) {
        this.aiBtn = true;
        this.hideAiBtn = false;
      } else {
        this.aiBtn = false;
        this.hideAiBtn = true;
      }
    },

    //loadpcbcat is to laod the contents from the database
    loadPcbcat() {
      const vm = this;

      this.$store.dispatch('get', {url: "/pcbcat/all"}).then((res) => {
        vm.pcbcats = res.data.rec;

        for (let i = 0; i < vm.pcbcats.length; i++) {
          vm.pcbcatList.push({
            text: vm.pcbcats[i].pcbcatdesc,
            value: vm.pcbcats[i].id,
          });
        }
      });
    },

    //changepcb is to show what has been loaded
    // changePcbHandler(id) {
    //     this.pcbcats.find(item => item.id == id);
    // },

    changePcbHandler(id) {
      let item = this.pcbcats.find((item) => item.id == id);

      this.formData.pcbcatcode = item.pcbcatcode;
    },

    loadTaxset() {
      const vm = this;

      this.$store.dispatch('get', {url: "/taxset/all"}).then((res) => {
        vm.taxes = res.data.rec;

        for (let i = 0; i < vm.taxes.length; i++) {
          vm.taxList.push({
            text: vm.taxes[i].pcbsetdesc,
            value: vm.taxes[i].id,
          });
        }
      });
    },

    changeTaxHandler(id) {
      let item = this.taxes.find((item) => item.id == id);

      this.formData.pcbsetcode = item.pcbsetcode;
    },

    loadEpf() {
      const vm = this;

      this.$store.dispatch('get', {url: "/epfsetup/all"}).then((res) => {
        vm.epfs = res.data.rec;

        for (let i = 0; i < vm.epfs.length; i++) {
          vm.epfList.push({
            text: vm.epfs[i].epfgroupdesc,
            value: vm.epfs[i].id,
          });
        }
      });
    },

    changeEpfHandler(id) {
      let item = this.epfs.find((item) => item.id == id);

      this.epfgroupcode = item.epfgroupcode;
    },

    loadSocso() {
      const vm = this;

      this.$store.dispatch('get', {url: "/socso/all"}).then((res) => {
        vm.socsos = res.data.rec;

        for (let i = 0; i < vm.socsos.length; i++) {
          vm.socsoList.push({
            text: vm.socsos[i].socsocode,
            value: vm.socsos[i].id,
          });
        }
      });
    },

    changeSocsoHandler(id) {
      let item = this.socsos.find((item) => item.id == id);

      this.socsocode = item.socsocode;
    },

    loadEpfve() {
      const vm = this;

      this.$store.dispatch('get', {url: "/epfve/all"}).then((res) => {
        vm.epfves = res.data.rec;

        for (let i = 0; i < vm.epfves.length; i++) {
          vm.epfveList.push({
            text: vm.epfves[i].epfvedesc,
            value: vm.epfves[i].id,
          });
        }
      });
    },

    changeEpfveHandler(id) {
      let item = this.epfves.find((item) => item.id == id);

      this.epfvecode = item.epfvecode;
    },

    loadBaitulmal() {
      const vm = this;

      this.$store.dispatch('get', {url: "/baitulmal/all"}).then((res) => {
        vm.baitulmals = res.data.rec;

        for (let i = 0; i < vm.baitulmals.length; i++) {
          vm.baitulmalList.push({
            text: vm.baitulmals[i].baitulmalcode,
            value: vm.baitulmals[i].id,
          });
        }
      });
    },

    changeBaitulmalHandler(id) {
      let item = this.baitulmals.find((item) => item.id == id);

      this.baitulmalcode = item.baitulmalcode;
    },

    loadPaymode() {
      const vm = this;

      this.$store.dispatch('get', {url: "/paymode/all"}).then((res) => {
        vm.paymodes = res.data.rec;

        for (let i = 0; i < vm.paymodes.length; i++) {
          vm.paymodeList.push({
            text: vm.paymodes[i].paymodecode,
            value: vm.paymodes[i].id,
          });
        }
      });
    },

    changePaymodeHandler(id) {
      this.paymodes.find((item) => item.id == id);
    },

    loadPaystat() {
      const vm = this;

      this.$store.dispatch('get', {url: "/paystat/all"}).then((res) => {
        vm.paystats = res.data.rec;

        for (let i = 0; i < vm.paystats.length; i++) {
          vm.paystatList.push({
            text: vm.paystats[i].paystatcode,
            value: vm.paystats[i].id,
          });
        }
      });
    },

    changePaystatHandler(id) {
      this.paystats.find((item) => item.id == id);
    },

    updateValue() {
      this.$emit("input", {
        staffid: this.formData.staffid,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        paymodeid: this.formData.paymodeid,
        paystatid: this.formData.paystatid,
        pcbcatid: this.formData.pcbcatid,
        childcount: this.formData.childcount,
        taxid: this.formData.taxid,
        isCompnypaytax: this.formData.isCompnypaytax,
        epfgroupid: this.formData.epfgroupid,
        epfeep: this.formData.epfeep,
        epferp: this.formData.epferp,
        epfeeamt: this.formData.epfeeamt,
        epferamt: this.formData.epferamt,
        socsoid: this.formData.socsoid,
        epfveid: this.formData.epfveid,
        veeep: this.formData.veeep,
        veerp: this.formData.veerp,
        veeeamt: this.formData.veeeamt,
        veeramt: this.formData.veeramt,
        isEis: this.formData.isEis,
        baitulmalid: this.formData.baitulmalid,
        baieeamt: this.formData.baieeamt,
        baieramt: this.formData.baieramt,
        // zakatamt: this.formData.zakatamt,
        zakatp: this.formData.zakatp,
        isdynamic: this.formData.isdynamic,
        lockstaff: this.formData.lockstaff,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "psnpayrolllist" , params: { search: this.search}});
    },
  },

  mounted() {
    this.loadPcbcat();
    this.loadTaxset();
    this.loadSocso();
    this.loadEpfve();
    this.loadBaitulmal();
    this.loadPaymode();
    this.loadPaystat();
    this.loadPersonExtraInfoHandler();
    this.loadEpf();

    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>

<style>
.removeSpin input[type="number"] {
  -moz-appearance: textfield;
  -moz-appearance: autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>    