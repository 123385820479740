<template>
    <div>
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:save="saveHandler"
      />
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  
  import PsnStatutoryForm from "../../../components/payroll/mmpsnstatutory/Form.vue";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    components: {
      "app-form": PsnStatutoryForm,
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        pageTitle: this.$t('mmsetup.psnstatutory'),
        pageTitleValue: "",
        formData: {
        },
  
        errMsg: "",
        isErr: false,
        editMode: false,
      };
    },
  
    methods: {
      saveHandler() {
        const vm = this;
        if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
          return false;
        }
        console.log('formData', vm.formData)
        this.$store.dispatch('post', {url: "/mmpsnstatutory/create", payload: vm.formData})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = true;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "mmpsnstatutorylist" });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
          });
      },
  
      cancelHandler() {
        this.$router.push({ name: "mmpsnstatutorylist" });
      },
    },
  };
  </script>