<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:new="createHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import StatutoryReportForm from "../../../components/payroll/statutoryreportsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": StatutoryReportForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupbaitulmal.editstatutorysetup'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //load statutory report by id
    loadStatutoryReport() {
      const vm = this;

      this.$store.dispatch('get', {url: "/statutoryreportsetup/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          if (res.data.rec[0].startdate) {
            vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
            vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }

          //vm.pageTitleValue = res.data.rec[0].payitemdesc;
          vm.formData.userid = this.$store.getters.userId;

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //load statutory report detail associated with that id
    loadDetail() {
      const vm = this;

      this.$store.dispatch('get', {url: "/statutoryreportsetupd/" + this.id})
        .then((res) => {
          vm.formData.statutoryDetail = res.data.rec;

          console.log("statutory detail", vm.formData.statutoryDetail);

          for (let i = 0; i < vm.formData.statutoryDetail.length; i++) {
            Object.assign(vm.formData.statutoryDetail[i], { action: 2 });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      //update record
      this.$store.dispatch('put', {url: "/statutoryreportsetup/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "statutoryreportsetuplist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          //emit error --> req failure
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/statutoryreportsetup/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "statutoryreportsetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "statutoryreportsetuplist" });
    },

    createHandler() {
      this.$router.push({ name: "statutoryreportsetupnew" });
    },
  },

  mounted() {
    this.loadStatutoryReport();
    this.loadDetail();
  },
};
</script>