<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            />
        </div>    
    </div>
</template>

<script>
import CashDenomination from '../../../components/payroll/cashdenomination/Form_Parameter';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-form': CashDenomination,
        'app-error': ErrorDialog
    },

    data() {
            return{
                pageTitle: this.$t('cashdenominationlistingreport.cashdenominationlisting'),
                pageTitleValue: '',
                
                formData: {
                    //payoutcode: null,
                    //compnyname: null,
                    },

                dataItems:[],

                goBtn: false,

                codeList: [],   
                compnyList: [],            

            errMsg: '',
            isErr:false,
            editMode: false 
            }
                },
        

    methods: {
        
        
    }
}

</script>