var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("+")])]}}]),model:{value:(_vm.periodicdialog),callback:function ($$v) {_vm.periodicdialog=$$v},expression:"periodicdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline mb-4"},[_vm._v(_vm._s(_vm.$t('periodicincentivesetup.periodicattendanceincentiveinputitem')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"logicname",attrs:{"outlined":"","label":this.$t('columns.logic'),"type":"text","dense":"","rules":[_vm.rules.required],"items":_vm.logicList},on:{"change":function($event){return _vm.changeLogicHandler(
                                _vm.selections.logicid
                            )}},model:{value:(_vm.selections.logicid),callback:function ($$v) {_vm.$set(_vm.selections, "logicid", $$v)},expression:"selections.logicid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.logiccode),callback:function ($$v) {_vm.$set(_vm.selections, "logiccode", $$v)},expression:"selections.logiccode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"workdaygroupdesc",attrs:{"outlined":"","label":this.$t('periodicincentivesetup.workdaygroup'),"type":"text","dense":"","rules":[_vm.rules.required],"items":_vm.workdayList},on:{"change":function($event){return _vm.changeWorkDayHandler(
                                _vm.selections.workdaygroupid
                            )}},model:{value:(_vm.selections.workdaygroupid),callback:function ($$v) {_vm.$set(_vm.selections, "workdaygroupid", $$v)},expression:"selections.workdaygroupid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.workdaygroupcode),callback:function ($$v) {_vm.$set(_vm.selections, "workdaygroupcode", $$v)},expression:"selections.workdaygroupcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"compnyname",attrs:{"outlined":"","label":this.$t('columns.company'),"type":"text","dense":"","items":_vm.companyList},on:{"change":function($event){return _vm.changeCompanyHandler(
                                _vm.selections.compnyid
                            )}},model:{value:(_vm.selections.compnyid),callback:function ($$v) {_vm.$set(_vm.selections, "compnyid", $$v)},expression:"selections.compnyid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.compnycode),callback:function ($$v) {_vm.$set(_vm.selections, "compnycode", $$v)},expression:"selections.compnycode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"brchname",attrs:{"outlined":"","label":this.$t('columns.branch'),"type":"text","dense":"","items":_vm.branchList},on:{"change":function($event){return _vm.changeBranchHandler(
                                _vm.selections.branchid
                            )}},model:{value:(_vm.selections.branchid),callback:function ($$v) {_vm.$set(_vm.selections, "branchid", $$v)},expression:"selections.branchid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.brchcode),callback:function ($$v) {_vm.$set(_vm.selections, "brchcode", $$v)},expression:"selections.brchcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"deptname",attrs:{"outlined":"","label":this.$t('columns.department'),"type":"text","dense":"","items":_vm.departmentList},on:{"change":function($event){return _vm.changeDepartmentHandler(
                                _vm.selections.deptid
                            )}},model:{value:(_vm.selections.deptid),callback:function ($$v) {_vm.$set(_vm.selections, "deptid", $$v)},expression:"selections.deptid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.deptcode),callback:function ($$v) {_vm.$set(_vm.selections, "deptcode", $$v)},expression:"selections.deptcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"divnname",attrs:{"outlined":"","label":this.$t('columns.division'),"type":"text","dense":"","items":_vm.divisionList},on:{"change":function($event){return _vm.changeDivisionHandler(
                                _vm.selections.divisionid
                            )}},model:{value:(_vm.selections.divisionid),callback:function ($$v) {_vm.$set(_vm.selections, "divisionid", $$v)},expression:"selections.divisionid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.divncode),callback:function ($$v) {_vm.$set(_vm.selections, "divncode", $$v)},expression:"selections.divncode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"sectioname",attrs:{"outlined":"","label":this.$t('columns.section'),"type":"text","dense":"","items":_vm.sectionList},on:{"change":function($event){return _vm.changeSectionHandler(
                                _vm.selections.sectionid
                            )}},model:{value:(_vm.selections.sectionid),callback:function ($$v) {_vm.$set(_vm.selections, "sectionid", $$v)},expression:"selections.sectionid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.sectiocode),callback:function ($$v) {_vm.$set(_vm.selections, "sectiocode", $$v)},expression:"selections.sectiocode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"unitname",attrs:{"outlined":"","label":this.$t('columns.unit'),"type":"text","dense":"","items":_vm.unitList},on:{"change":function($event){return _vm.changeUnitHandler(
                                _vm.selections.unitid
                            )}},model:{value:(_vm.selections.unitid),callback:function ($$v) {_vm.$set(_vm.selections, "unitid", $$v)},expression:"selections.unitid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.unitcode),callback:function ($$v) {_vm.$set(_vm.selections, "unitcode", $$v)},expression:"selections.unitcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"emptypedesc",attrs:{"outlined":"","label":this.$t('columns.employmenttype'),"type":"text","dense":"","items":_vm.emptypeList},on:{"change":function($event){return _vm.changeEmpTypeHandler(
                                _vm.selections.emptypeid
                            )}},model:{value:(_vm.selections.emptypeid),callback:function ($$v) {_vm.$set(_vm.selections, "emptypeid", $$v)},expression:"selections.emptypeid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.emptype),callback:function ($$v) {_vm.$set(_vm.selections, "emptype", $$v)},expression:"selections.emptype"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"grddesc",attrs:{"outlined":"","label":this.$t('columns.grade'),"type":"text","dense":"","items":_vm.gradeList},on:{"change":function($event){return _vm.changeGradeHandler(
                                _vm.selections.gradeid
                            )}},model:{value:(_vm.selections.gradeid),callback:function ($$v) {_vm.$set(_vm.selections, "gradeid", $$v)},expression:"selections.gradeid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.grdcode),callback:function ($$v) {_vm.$set(_vm.selections, "grdcode", $$v)},expression:"selections.grdcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"jobtitle",attrs:{"outlined":"","label":this.$t('columns.job'),"type":"text","dense":"","items":_vm.jobList},on:{"change":function($event){return _vm.changeJobHandler(
                                _vm.selections.jobid
                            )}},model:{value:(_vm.selections.jobid),callback:function ($$v) {_vm.$set(_vm.selections, "jobid", $$v)},expression:"selections.jobid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.jobcode),callback:function ($$v) {_vm.$set(_vm.selections, "jobcode", $$v)},expression:"selections.jobcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-autocomplete',{ref:"posdesc",attrs:{"outlined":"","label":this.$t('columns.position'),"type":"text","dense":"","items":_vm.positionList},on:{"change":function($event){return _vm.changePositionHandler(
                                _vm.selections.positionid
                            )}},model:{value:(_vm.selections.positionid),callback:function ($$v) {_vm.$set(_vm.selections, "positionid", $$v)},expression:"selections.positionid"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","background-color":"#ffeab2","readonly":"","label":this.$t('columns.code'),"type":"text","dense":""},model:{value:(_vm.selections.poscode),callback:function ($$v) {_vm.$set(_vm.selections, "poscode", $$v)},expression:"selections.poscode"}})],1)],1)],1)],1),(_vm.isErr)?_c('v-card-text',{staticClass:"mt-n12"},[_c('span',{staticStyle:{"font-weight":"bold","color":"red"}},[_vm._v(_vm._s(_vm.errMsg))])]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.closePeriodShiftIncInput}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.savePeriodShiftIncInput}},[_vm._v("Save")])],1)],1)],1),_c('v-data-table',{staticClass:"elavation-1",attrs:{"headers":this.parentObject.tabHeader,"items":_vm.detailItem,"fixed-header":"","sort-by":['code']},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.modifyItemHandler(item)}}},[_vm._v(" mdi-pen ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItemHandler(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }