<template>
    <div>
        <div v-if="isSuccess">
            <app-success
                v-bind:parentObject="{
                isSuccess: this.isSuccess,
                messageSuccess: this.messageSuccess,
                }"
            />
        </div>
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.intgdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.logic')"
                                type="text"
                                ref="intgtype"
                                :items="intgTypeList"
                                v-model="formData.logic"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('intgsetup.sourcedest')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.destination"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.port')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.port"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.user')"
                                v-model="formData.username"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('login.password')"
                                v-model="formData.userpwd"
                                type="password"
                                @input="updateValue"
                                dense
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.intsettingd,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>

                    <v-row>       
                        <v-col class="pb-n5">
                            <v-btn color="blue darken-1" style="color: white; text-align: center; width: 150px" @click="onTest" >{{$t('intgsetup.testcont')}}</v-btn>        
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from "./CRUDTable";
import Loading from "vue-loading-overlay";
import axios from "axios";
import SuccessDialog from "../../../components/common/SuccessDialog";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable,
        "app-error": ErrorDialog,
        "app-success": SuccessDialog,
        Loading
    },

    data() {
        return {
            formData: {
                intgdesc: this.parentObject.data.intgdesc,
                logic: this.parentObject.data.logic,
                destination: this.parentObject.data.destination,
                username: this.parentObject.data.username,
                userpwd: this.parentObject.data.userpwd,
                port: this.parentObject.data.port,
                remark: this.parentObject.data.remark,
                intsettingd: this.parentObject.data.intsettingd,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.company'), value: 'compnycode', width: '100px', sortable: false},
                { text: this.$t('reportprocessloglisting.id'), value: 'refid', width: '100px', sortable: false},
                { text: this.$t('intgsetup.key1'), value: 'refkey1', width: '100px', sortable: false},
                { text: this.$t('intgsetup.key2'), value: 'refkey2', width: '100px', sortable: false}
    
            ],
            rules: { required: value => !!value || this.$t('reportsparam.required') },
            intgTypeList: [],

            fileHolder:[],
            // attachnew: [],
            filecount: 0,
            intgTypeItems: [],
            documentTypeDialog: false,

            errMsg: '',
            messageSuccess: '',
            isErr: false,
            isSuccess: false,
            loading: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        onTest() {
            const vm = this;
            this.loading = true;
            axios
            .post(`${vm.formData.destination}:${vm.formData.port}/authKey`, 
                { "customerCode":"", "serviceCode":""}, 
                { "Content-Type": "application/json", usr: vm.formData.username, pwd: vm.formData.userpwd}
            )
            .then((res) => {
                console.log(res);
                this.loading = false;
                this.isSuccess = true;
                this.messageSuccess =
                    this.$t('intgsetup.successcont');
                    })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.isErr = true;
                this.errMsg = 
                    this.$t('intgsetup.failcont');
            });
        },

        getIntgtype(){
            const vm = this;

            this.$store.dispatch('get', {url: '/intgtype/all'})
            .then(res => {

                let data = res.data.rec;

                for (let i = 0; i < data.length; i++) {

                    vm.intgTypeList.push(
                        {
                            text: data[i].type,
                            value: data[i].id
                        }
                    )
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });

        },

        updateValue() {
            this.$emit('input', {
                intgdesc: this.formData.intgdesc,
                logic: this.formData.logic,
                destination: this.formData.destination,
                username: this.formData.username,
                userpwd: this.formData.userpwd,
                port: this.formData.port,
                remark: this.formData.remark,
                intsettingd: this.formData.intsettingd,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            // console.log(this.formData)
            // this.$emit('save', this.formData.attachnew);
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'intgsetuplist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getIntgtype()
    }
}
</script>