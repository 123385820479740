<template>
    <div id="app-langswitch">
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    text
                    v-on="on"
                >
                {{ $t('langname') }}
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(lang, i) in this.localeMessages" :key="`Lang${i}`"
                    @click.prevent="setLocale(lang.locale)"
                >
                {{lang.langname}}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    data() {
        return {
            localeMessages: this.$i18n.messages
        }
    },
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale;
            this.$router.push(
                {
                    params: {lang: locale}
                }
            );
            location.reload();
        },
    }
}
</script>