<template>
    <div>
      
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 pt-8"
                >

                      <!-- <v-row>
                      <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        label="Name"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="formData.staffid"
                        dense
                        :items="staffDetailsList"
                        @input="updateValue"
                        @change="changeStaffIDandCompanyHandler(formData.staffid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row> -->

                    <v-row v-if="!this.formData.lockstaff">
                      <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('columns.staffname')"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="formData.staffid"
                        dense
                        :items="staffDetailsList"
                        @input="updateValue"
                        @change="changeStaffIDandCompanyHandler(formData.staffid)"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>

                    <v-row v-else>
                    <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                            <v-autocomplete
                            outlined
                            :label="this.$t('columns.staffname')"
                            type="text"
                            ref="psnid"
                            :rules="[rules.required]"
                            v-model="formData.staffid"
                            dense
                            :items="staffDetailsList"
                            readonly
                            background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-row>
                      <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('recgrpinsuranceassignment.insurancepolicyno')"
                        type="text"
                        ref="inspolicyid"
                        :rules="[rules.required]"
                        v-model="formData.inspolicyid"
                        dense
                        :items="InsurancePolicyList"
                        @input="updateValue"
                        @change="changeInsurancePolicyHandler(formData.inspolicyid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recgrpinsuranceassignment.policyperiodfromdate')"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                dense
                                ref="startdate"
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recgrpinsuranceassignment.policyperiodtodate')"
                                type="date"
                                ref="enddate"
                                v-model="formData.enddate"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                     <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppolicymaster.policydesc')"
                                type="text"
                                ref="policydesc"
                                v-model="formData.policydesc"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                     </v-row>

                    
                        <v-row v-if="hidetable" class="blue--text ">
                            <h3>{{$t('recgrpinsuranceassignment.dependents')}} </h3>
                        </v-row>
                     <v-row v-if="hidetable">
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.dependentdetail,
                                psnid: this.formData.psnid,
                                staffid: this.formData.staffid

                                
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row> 

                     <v-row v-if="hidetable2" class="blue--text ">
                            <h3>{{$t('recgrpinsuranceassignment.detailrec')}} </h3>
                        </v-row>

                <v-row v-if="hidetable2">
                
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table2 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader2,
                                detailItem: this.formData.insurancedetail,
                                 staffid: this.formData.staffid,
                                 inspolicyid: this.formData.inspolicyid,
                                 psnid: this.formData.psnid,
                                 id: this.formData.id
                            }"
                            @input="changePayoutHandler()"
                            v-model="selected"
                            v-on:input="addItemHandler2"
                        />
                    </v-col>
                </v-row> 
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.editMode

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';
import CRUDTable2 from './CRUDTable2';
import moment from "moment";


export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-table2' : CRUDTable2,
 
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
           // pageTitle: 'Claim Limit Setup Detail Input Dashboard',
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                dependentdetail: this.parentObject.data.dependentdetail,
                insurancedetail: this.parentObject.data.insurancedetail,
                psnid: this.parentObject.data.psnid,
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                staffname: this.parentObject.data.staffname,
                compnyname: this.parentObject.data.compnyname,
                compnyid: this.parentObject.data.compnyid,
                inspolicyid : this.parentObject.data.inspolicyid,
                policyno: this.parentObject.data.policyno,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                policydesc: this.parentObject.data.policydesc,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.name'), value: 'dependentname', width: '200px', sortable: false},
                { text: this.$t('setupreport_exportmapping.relationship'), value: 'memberdesc', width: '200px', sortable: false },
                { text: this.$t('recfamdetails.icno'), value: 'icno', width: '200px', sortable: false },
                { text: this.$t('recgrpinsuranceassignment.passportno'), value: 'passportno', width: '200px', sortable: false },
                { text: this.$t('columns.gender'), value: 'gendername', width: '200px', sortable: false },
                { text: this.$t('columns.dob'), value: 'dob', width: '200px', sortable: false },
                { text: this.$t('recpersonalinfo.maritalstatus'), value: 'marstatname', width: '200px', sortable: false },
                { text: this.$t('recpersonalinfo.nationality'), value: 'nationalityname', width: '200px', sortable: false },
                { text: this.$t('setupcompanybankaccount.bank'), value: 'bankcode', width: '200px', sortable: false },
                { text: this.$t('setupcompanybankaccount.accountno'), value: 'accountno', width: '200px', sortable: false },
            ],

            tabHeader2: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.date'), value: 'movdate', width: '150px', sortable: false},
                { text: this.$t('reportsexportstaffinsurancerec.movtype'), value: 'insurancemovtypedesc', width: '250px', sortable: false },
                { text: this.$t('recfamdetails.membername'), value: 'dependentname', width: '200px', sortable: false },
                { text: this.$t('reportsexportstaffinsurancerec.gtlcvrage'), value: 'gtlcoveragevalue', width: '200px', sortable: false },
                { text: this.$t('reportsexportstaffinsurancerec.ghscvrage'), value: 'ghscoveragevalue', width: '200px', sortable: false },
                { text: this.$t('reportsexportstaffinsurancerec.outpatient'), value: 'outpatientcoveragevalue', width: '200px', sortable: false },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px', sortable: false },
            ],


            grpinspolicyitems: [],
            InsurancePolicyList: [],

            selected: [],

            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            hidetable: false,
            hidetable2: false,

            errMsg: '',
            isErr: false,

            loading: false,
            isEdit: this.parentObject.editMode,
            search: this.parentObject.searchnew
        }
    },

    methods: {          
        addItemHandler($event) {

            this.formData.dependentdetail =$event
        
            this.formData.psnid = this.formData.psnid
            this.formData.staffid = this.formData.staffid
            this.formData.staffname = this.formData.staffname
       
        },

          addItemHandler2($event) {
             this.formData.insurancedetail =$event
             this.formData.staffid = this.formData.staffid
             this.formData.staffname = this.formData.staffname
             this.formData.psnid = this.formData.psnid
             this.formData.inspolicyid = this.formData.inspolicyid
             this.formData.id = this.formData.id
         },


        changePayoutHandler() {
            this.formData.insurancedetail = this.selected
            this.updateValue()
        },

          //load companies list
        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {

            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
            this.crud();
        },

        crud(){

            this.hidetable = true;
            this.formData.psnid = this.formData.psnid;
            this.formData.staffid = this.formData.staffid;

        },

         crud2(){

            this.hidetable2 = true;
            this.formData.psnid = this.formData.psnid;
            this.formData.staffid = this.formData.staffid;
            this.formData.inspolicyid = this.formData.inspolicyid;
            this.formData.id = this.formData.id;
        },


          changeInsurancePolicy() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/groupinsurancepolicy/all'})
            .then(res => {

                vm.grpinspolicyitems = res.data.rec;

                for (let i = 0; i < vm.grpinspolicyitems.length; i++) {
                    vm.InsurancePolicyList.push({
                        text: vm.grpinspolicyitems[i].policyno,
                        value: vm.grpinspolicyitems[i].id,
                    });
                }

                if (this.formData.policyno) {
                this.changeInsurancePolicyHandler(this.formData.policyno);
                }  
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeInsurancePolicyHandler(id) {
            var item = this.grpinspolicyitems.find(item => item.id === id);
            this.formData.startdate = moment(item.startdate).format("YYYY-MM-DD");
            this.formData.enddate = moment(item.enddate).format("YYYY-MM-DD");
            this.formData.policyno = item.policyno;
            this.formData.policydesc = item.policydesc;

            this.crud2();

        },

                
        updateValue() {

            this.$emit('input', {
                id: this.formData.id,
                dependentdetail: this.formData.dependentdetail,
                insurancedetail: this.formData.insurancedetail,
                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                staffname: this.formData.staffname,
                compnyname: this.formData.compnyname,
                inspolicyid: this.formData.inspolicyid,
                policyno: this.formData.policyno,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                policydesc: this.formData.policydesc,
                lockstaff: this.formData.lockstaff,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'grpinsassignmentlist', params: { search: this.search}});
        },

    },

    mounted(){
        this.changeInsurancePolicy();
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();

        if (this.isEdit == true){
            
            this.hidetable2 = true;
            this.hidetable = true;
            this.formData.psnid = this.formData.psnid;
            this.formData.staffid = this.formData.staffid;
            this.formData.inspolicyid = this.formData.inspolicyid;
        }  
        
          if (this.parentObject.editMode){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>