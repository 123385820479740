    <template>
  <div>
    <v-dialog v-model="exchangeratedialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('payrollprocessrun.exchangerate')}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  outlined
                  :label="this.$t('recexchangerateset.fromcurrency')"
                  type="text"
                  dense
                  v-model="selections[0].frcurrencyid"
                  :items="currencyList"
                  @change="changeCurrencyHandlerf(selections[0].frcurrencyid)"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="2" sm="2">
                <v-text-field
                  dense
                  :label="this.$t('columns.code')"
                  outlined
                  readonly
                  background-color="#ffeab2"
                  v-model="this.selections[0].frcrcycode"
                ></v-text-field>
              </v-col>

              <v-col cols="2" sm="2">
                <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn dense v-on="on">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <app-genericform
                      v-bind:parentData="{
                        tabItems: currencyItems,
                        formTitle: `${this.$t('columns.currency')}`,
                        formId: 'currency',
                      }"
                      v-on:input="itemData = $event"
                      v-on:saveCurrency="saveCurrencyHandler"
                      v-on:deleteCurrency="deleteCurrencyHandler"
                    />

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="warning"
                        text
                        @click="currencyDialog = false"
                      >
                      {{$t('button.close')}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('recexchangerateset.tocurrency')"
                  type="text"
                  dense
                  v-model="selections[0].tocurrencyid"
                  :items="currencyList"
                  @change="changeCurrencyHandlert(selections[0].tocurrencyid)"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                <v-text-field
                  dense
                  :label="this.$t('columns.code')"
                  outlined
                  readonly
                  background-color="#ffeab2"
                  v-model="this.selections[0].tocrcycode"
                ></v-text-field>
              </v-col>

              <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn dense v-on="on">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <app-genericform
                      v-bind:parentData="{
                        tabItems: currencyItems,
                        formTitle: `${this.$t('columns.currency')}`,
                        formId: 'currency',
                      }"
                      v-on:input="itemData = $event"
                      v-on:saveCurrency="saveCurrencyHandler"
                      v-on:deleteCurrency="deleteCurrencyHandler"
                    />

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="warning"
                        text
                        @click="currencyDialog = false"
                      >
                      {{$t('button.close')}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.rate')"
                  type="number"
                  dense
                  v-model="selections[0].rate"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="6" class="mt-n8">
                <v-checkbox
                  ref="canreverse"
                  v-model="selections[0].canreverse"
                  :label="this.$t('recexchangerateset.canreverse')"
                  @change="changeCanReverseHandler(selections[0].canreverse)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="isErr" class="mt-n12">
          <span style="font-weight: bold; color: red">{{ errMsg }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeExchangeRate"
            >{{$t('button.cancel')}}</v-btn
          >
          <v-btn color="green darken-1" text @click="saveExchangeRate"
            >{{$t('button.save')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="this.parentObject.tabHeader"
      :items="detailItem"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import compareArray from "../../../util/comparearray";
import GenericForm from "../../generic/GridForm";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-genericform": GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred'),
      },

      selections: [
        {
          id: null,
          frcurrencyid: null,
          frcrcycode: null,
          frcrcydesc: null,
          tocurrencyid: null,
          tocrcycode: null,
          tocrcydesc: null,
          rate: null,
          canreverse: null,
          canreversedesc: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ],

      itemHolder: [],
      exchangeratedialog: false,
      detailItem: this.parentObject.detailItem,

      currencyList: [],
      currencies: [],
      currencyItems: [],
      modify: false,

      currencyDialog: false,
      currencyLoading: true,
      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    compareArray,

    loadCurrencyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/currency/all"})
        .then((res) => {
          vm.currencies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.currencyList.push({
              text: res.data.rec[i].crcydesc,
              value: res.data.rec[i].id,
            });

            vm.currencyItems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].crcycode,
              desc: res.data.rec[i].crcydesc,
              stamp: res.data.rec[i].stamp,
            });
          }

          vm.currencyLoading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeCurrencyHandlerf(id) {
      let item = this.currencies.find((item) => item.id === id);
      this.selections[0].frcrcycode = item.crcycode;
      this.selections[0].frcrcydesc = item.crcydesc;
    },

    changeCurrencyHandlert(id) {
      let item = this.currencies.find((item) => item.id === id);
      this.selections[0].tocrcycode = item.crcycode;
      this.selections[0].tocrcydesc = item.crcydesc;
    },

    changeCanReverseHandler(item) {
      if (item == true) {
        this.selections[0].canreverse = 1;
        this.selections[0].canreversedesc = "Y";
      } else {
        this.selections[0].canreverse = 0;
        this.selections[0].canreversedesc = "N";
      }
    },

    modifyItemHandler(item) {
      // assign loaded data to selections array
      if (item.action == 1) {
        this.modify = true;
      }
      if (item.action == 2) {
        this.isExisting = true;
      }

      this.itemHolder = item;
      this.selections[0] = this.itemHolder;

      this.exchangeratedialog = true;
    },

    deleteItemHandler(item) {
      let itemIndex = this.detailItem.indexOf(item);

      this.detailItem.splice(itemIndex, 1);
    },

    saveExchangeRate() {
       if (this.selections[0].frcurrencyid == null || this.selections[0].tocurrencyid == null){

              this.$dialog.alert(this.$t('fillinfromandtocurrency')).then((dialog) => {
                console.log(dialog);
             });
            } else {
      this.isErr = false;

      this.changeCanReverseHandler(this.selections[0].canreverse);

      if (!this.isExisting && this.modify) {
        this.resetSelections();
      } else if (!this.isExisting) {
        this.selections[0].id = null;
        this.selections[0].action = 1;

        this.detailItem.push(Object.assign({}, this.selections[0]));
      } else {
        if (this.compareArray(this.detailItem, this.selections[0])) {
          console.log(this.compareArray(this.detailItem, this.selections[0]));
        }
      }

      this.$emit("input", this.detailItem);

      this.modify = false;
      this.exchangeratedialog = false;

      // reset the form fields
      this.resetSelections();
            }
    },

    closeExchangeRate() {
      this.exchangeratedialog = false;
      this.isErr = false;

      // reset the form fields
      this.resetSelections();
    },
    // Saving payeeinput details into payeeinputd
    saveCurrencyHandler() {
      const vm = this;

      if (this.itemData.itemid) {
        this.$store.dispatch('put', {url: "/currency/" + this.itemData.itemid,
            payload: {
              crcycode: this.itemData.itemcode,
              crcydesc: this.itemData.itemdesc,
              stamp: this.itemData.itemstamp,
            }}
          )
          .then((res) => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.currencies = [];
              vm.currencyItems = [];
              vm.currencyList = [];

              this.loadCurrencyListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      } else {
        this.$store.dispatch('post', {url: "/currency/create", payload: {
            crcycode: this.itemData.itemcode,
            crcydesc: this.itemData.itemdesc,
          }})
          .then((res) => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.currencies = [];
              vm.currencyItems = [];
              vm.currencyList = [];

              this.loadCurrencyListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    deleteCurrencyHandler($event) {
      const vm = this;

      if (confirm("Remove " + $event.desc + "?")) {
        this.$store.dispatch('delete', {url: "/currency/" + $event.id})
          .then((res) => {
            if (res.data.status == 200) {
              // resets all arrays
              vm.currencies = [];
              vm.currencyList = [];
              vm.currencyItems = [];

              this.loadCurrencyListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    resetSelections() {
      this.selections = [
        {
          id: null,
          frcurrencyid: null,
          frcrcycode: null,
          frcrcydesc: null,
          tocurrencyid: null,
          tocrcycode: null,
          tocrcydesc: null,
          rate: null,
          canreverse: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ];
    },
  },

  created() {
    this.loadCurrencyListHandler();
  },
};
</script>
