<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.year')"
                                    type="text"
                                    ref="year"
                                    hide-selected
                                    v-model="formData.poyr"
                                    :items="yearList"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="2" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.month')"
                                    type="text"
                                    ref="month"
                                    hide-selected
                                    v-model="formData.pomth"
                                    :items="monthList"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.payout')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="formData.poid"
                                    :items="payoutcodeList"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    ref="company"
                                    hide-selected
                                    v-model="formData.compnycode"
                                    :items="compnycodeList"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setuppayitem.payitem')"
                                    type="text"
                                    ref="payitem"
                                    hide-selected
                                    v-model="formData.payitemcode"
                                    :items="payitemcodeList"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import moment from 'moment'


export default {
    props: {
        parentObject: Object
    },

    components: {
      
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
            formData: {

                poyr: null,
                pomth: null,
                poid: null,
                compnyid: null,
                compnycode: null,
                payitemid: null,
                payitemcode: null,
                total: 0,
            },
            
            yearList: [],
            monthList: [],
            payoutcodeList: [],
            compnycodeList: [],
            payitemcodeList: [],

        }
    },

    methods: {

        loadYear(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/vw_salpayitem/all'})
                .then(res => {                    

                    for (let i = 0; i < res.data.rec.length; i++) {
                        const x = vm.yearList.filter(function(x) {
                            return x == res.data.rec[i].poyr;
                        })[0];

                        if (!x || x == undefined) {
                            vm.yearList.push(res.data.rec[i].poyr)
                        }                  
                    }
                    vm.yearList.sort(function(a, b){return a-b});
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadMonth(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/vw_salpayitem/all'})
                .then(res => {

                    for (let i = 0; i < res.data.rec.length; i++) {
                        const x = vm.monthList.filter(function(x) {
                            return x == res.data.rec[i].pomth;
                        })[0];

                        if (!x || x == undefined) {
                            vm.monthList.push(res.data.rec[i].pomth)
                        }                 
                    }
                    vm.monthList.sort(function(a, b){return a-b});
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPayoutCode(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/vw_pyctrl/all'})
                .then(res => {
                    vm.poid = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payoutcodeList.push(
                            {
                                text: res.data.rec[i].payoutcode,
                                value: res.data.rec[i].id
                            }
                        )                        
                    }

                    if (vm.formData.poid) {
                        vm.changePayoutcodeHandler(vm.formData.poid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePayoutcodeHandler(id) {
            this.payoutcodes.find(item => item.id === id);
            
        },

        loadCompnyCode(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/vw_salpayitem/all'})
                .then(res => {
                    vm.compnycodes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.compnycodeList.push(
                            {
                                text: res.data.rec[i].compnydesc,
                                value: res.data.rec[i].compnycode
                            }
                        )                        
                    }

                    if (vm.formData.compnycode) {
                        vm.changeCompnycodeHandler(vm.formData.compnycode);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCompnycodeHandler(id) {
            this.compnycodes.find(item => item.id === id);
            
        },

        loadPayitemCode(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/vw_salpayitem/all'})
                .then(res => {
                    vm.payitemcodes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payitemcodeList.push(
                            {
                                text: res.data.rec[i].payitemdesc,
                                value: res.data.rec[i].payitemcode
                            }
                        )                        
                    }

                    if (vm.formData.payitemcode) {
                        vm.changePayitemcodeHandler(vm.formData.payitemcode);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePayitemcodeHandler(id) {
            this.payitemcodes.find(item => item.id === id);
            
        },

        goHandler(){
            const vm = this;

            const dataHolder = {
                poid: this.formData.poid,
                pomth: this.formData.pomth,
                poyr: this.formData.poyr,
            }

            const formData = this.formData
            
            var getdata = [];

            for (const key in dataHolder) {
                if (dataHolder[key]) {
                    getdata.push(dataHolder[key])
                }
            }

            // if ((this.formData.poyr != null && this.formData.pomth != null && this.formData.compnycode != null && this.formData.payitemcode != null) || this.formData.poid != null && this.formData.compnycode != null && this.formData.payitemcode != null) {
                
            //     var poidHolder = vm.poid.filter(function(x) {
            //         return  x.pymonth == vm.formData.pomth || 
            //                 x.pyyear == vm.formData.poyr ||
            //                 x.id == vm.formData.poid
            //         })[0]
                
            //     if (poidHolder) {
            //         this.$router.push({name: 'detailsMisc', params: { formData, param } });
            //     } else {
            //         //this.$refs.form.validate()
            //         vm.isErr = true;
            //         vm.errMsg = 'There are inconsistencies in parameter selection';
                    
            //         vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            //     }

            // } else {
            //     this.$refs.form.validate()
            // }

            if (this.formData.payitemcode && this.formData.compnycode && getdata.length > 0) {

                switch (getdata.length) {
                    case 1:
                        this.$router.push({name: 'detailsMisc', params: { formData } });
                        break;
                    case 2:
                        var poidHolder1 = vm.poid.filter(function(x) {
                            return  (x.id == vm.formData.poid &&
                                    (x.pymonth == vm.formData.pomth || 
                                     x.pyyear == vm.formData.poyr)) || (
                                     x.pymonth == vm.formData.pomth &&
                                     x.pyyear == vm.formData.poyr)
                            })[0]
                        
                        if (poidHolder1) {
                            this.$router.push({name: 'detailsMisc', params: { formData } });
                        } else {
                            //this.$refs.form.validate()
                            vm.isErr = true;
                            vm.errMsg = this.$t('miscpayrolllsitreport.inconsistencymsg');
                            
                            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                        }
                        break;
                    case 3:
                        var poidHolder2 = vm.poid.filter(function(x) {
                            return  x.id == vm.formData.poid &&
                                    x.pymonth == vm.formData.pomth && 
                                    x.pyyear == vm.formData.poyr
                            })[0]
                        
                        if (poidHolder2) {
                            this.$router.push({name: 'detailsMisc', params: { formData } });
                        } else {
                            //this.$refs.form.validate()
                            vm.isErr = true;
                            vm.errMsg = this.$t('miscpayrolllsitreport.inconsistencymsg');
                            
                            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                        }
                        break;
                }
            } else {
                this.$refs.form.validate()
            }
            
        },

        updateValue() {
            this.$emit("input", {
                poyr: this.formData.poyr,
                pomth: this.formData.pomth,
                poid: this.formData.poid,
                compnycode: this.formData.compnycode,
                payitemcode: this.formData.payitemcode,
            });
        },
    }, 

    mounted() { 
      this.loadYear();
      this.loadMonth();
      this.loadPayoutCode();
      this.loadCompnyCode();
      this.loadPayitemCode();
     
    },
    
}
</script>
