<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container
          id="scroll-target"
        >
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}</v-btn
            >
          </v-row>
        </v-col>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        
        <v-container 
        style="overflow: auto"
         >
          <v-row>
            <v-toolbar flat>
              <v-toolbar-title
                >{{$t('latecomerlistingreportfrom')}}
                <span class="blue--text">
                    {{ this.data.formData.asofdate1 }}</span
                  >
                  {{$t('columns.to')}}
                  <span class="blue--text">
                    {{ this.data.formData.asofdate2 }}</span
                  > {{$t('attendancereportbypeiordbycompanyreport.for')}}
                   <span class="blue--text">
                    {{ this.data.formData.compnycode }}</span
                  > 
              </v-toolbar-title>
              <v-spacer></v-spacer>

                <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>

              <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 20px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
          </v-toolbar>
        </v-row>

        <v-row class="pb-5" font-size = "5px">
              <v-toolbar-title>

                {{$t('attendancereportbypeiordbycompanyreport.filterby')}}
                   <span class="blue--text">
                     <v-div
                      v-if="this.data.parameters.staffid"
                     > {{this.data.parameters.staffcode}}, </v-div>
                     
                     <v-div
                      v-if="this.data.parameters.deptid"
                     > {{this.data.parameters.deptname}}, </v-div>

                     <v-div
                      v-if="this.data.parameters.brchid"
                     > {{this.data.parameters.brchname}}, </v-div>

                     <v-div
                      v-if="this.data.parameters.sectioid"
                     > {{this.data.parameters.sectioname}}, </v-div>

                     <v-div
                      v-if="this.data.parameters.unitid"
                     > {{this.data.parameters.unitname}}, </v-div>

                    <v-div
                      v-if ="this.data.parameters.divnid"
                     > {{this.data.parameters.divnname}} </v-div>

                    <v-div
                      v-if="this.data.parameters.workgroupid"
                    > {{this.data.parameters.valuedesc}}, </v-div>

                  </span>   
                                
              </v-toolbar-title>
              
              <v-spacer></v-spacer>
              </v-row>

          <v-row >
            <v-col cols="20" sm="20">
              <v-data-table
              hide-default-footer
                :headers="dataHeader"
                :search="search"
                :items="dataItems"
                :items-per-page="1000"
                class="font-weight-light;text--secondary;"
                :sort-by="['code']"
                fixed-header
                no-data-text="No records found."
              >
              </v-data-table>
            </v-col>
          </v-row>

       
        </v-container>
       
      </v-form>
   </v-container>
  </div>
</template>


<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },

  components: {
  //  "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },

      data: {
        formData: {
           asofdate1: this.$route.params.formData.asofdate1,
           asofdate2: this.$route.params.formData.asofdate2,
           compnyid : this.$route.params.formData.compnyid,
           compnycode: this.$route.params.formData.compnycode,
           compnyname: this.$route.params.formData.compnyname,
 
         //  userid: this.$store.getters.userId,
        },

         parameters: {
           brchid: this.$route.params.param.branchid,
           brchname: this.$route.params.param.brchname,
           deptid: this.$route.params.param.departmentid,
           deptname: this.$route.params.param.deptname,
           sectioid: this.$route.params.param.sectionid,
           sectioname: this.$route.params.param.sectioname,
           unitid: this.$route.params.param.unitid,
           unitname: this.$route.params.param.unitname,
           workgroupid: this.$route.params.param.workgroupid,
           valuedesc: this.$route.params.param.valuedesc2,
           divnid: this.$route.params.param.divisionid,
           divnname: this.$route.params.param.divnname,
           staffid: this.$route.params.param.staffid,
           staffcode: this.$route.params.param.staffcode,
   
         },
         
      },


      dataHeader: [
        { text: this.$t('statutoryexportcommonused.no'),  width:'84', value: 'id'},
        { text: this.$t('columns.date'), width:'120', value: 'shiftdate'},
        { text: this.$t('attendancereportbypeiordbycompanyreport.staffcode'), width:'84', value: "staffcode"},
        { text: this.$t('columns.name'), width:'140', value: "psnname"},
        { text: this.$t('setuprestdaypatternsetup.daytype'), value: "daytypecode"},
        { text: this.$t('staffdailyrosterrecord.shift'), value: "shiftcode" },
        { text: this.$t('attendancereportbypeiordbycompanyreport.shifttype'), width:'140', value: "shiftdesc" },
        { text: this.$t('attendancereportbypeiordbycompanyreport.shiftstarttime'), value: "starttime" },
        { text: this.$t('attendancereportbypeiordbycompanyreport.shiftendtime'), value: "endtime" },
        { text: this.$t('processedattendancedatarecord.intime'), value: "intime" },
        { text: this.$t('processedattendancedatarecord.outtime'), value: "outtime" },
        { text: this.$t('processedattendancedatarecord.workhours'), value: "workhrs" },
        { text: this.$t('setupforshift.othours'), value: "othrs" },
      ],

      tableOpt: {
        itemsPerPage: -1,
      },

      search: '',
      dataItems: [],
      branch: [],
      branchList: [],
      units: [],
      unitList: [],
      divisions: [],
      divisionList: [],
      sections: [],
      sectionList: [],
      workgroup: [],
      workgroupList: [],
      staff: [],
      staffList: [],
      department: [],
      departmentList: [],

      goBtn: false,
      loading: false,
    };
  },

  methods: {
    backButton() {
      this.$router.push({ name: "attendancelatecomer" });
    },


    loadReport(){

      const vm = this;

      this.goBtn = true;
      vm.loading = true;

     this.loadAttendanceLateComer();
       
    },

    loadStaffListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: `/staff/all/${this.$store.getters.userId}`
        })
        .then((res) => {
          vm.staff = res.data.rec;
    
          for (let i = 0; i < vm.staff.length; i++) {
            vm.staffList.push({
              text: vm.staff[i].staffcode + " " + vm.staff[i].psnname + " " + vm.staff[i].compnyname,
              value: vm.staff[i].id,
            });
            if (vm.data.parameters.staffid) {
                this.ChangeStaffHandler(vm.data.parameters.staffid);

           }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

     ChangeStaffHandler(id){

       let item = this.staff.find((item) => item.id === id);
       this.data.parameters.staffid = item.id;
       this.data.parameters.staffcode = item.staffcode;

    },

    loadBranchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/branch/all'
        })
        .then((res) => {
          vm.branch = res.data.rec;

          for (let i = 0; i < vm.branch.length; i++) {
            vm.branchList.push({
              text: vm.branch[i].brchname,
              value: vm.branch[i].id,
            });

           if (vm.data.parameters.brchid) {
                this.ChangeBranchHandler(vm.data.parameters.brchid);
           }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    ChangeBranchHandler(id){

       let item = this.branch.find((item) => item.id === id);
       this.data.parameters.brchid = item.id;
       this.data.parameters.brchname = item.brchname;

    },

      loadDepartmentListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/department/all'
        })
        .then((res) => {
          vm.department = res.data.rec;

          for (let i = 0; i < vm.department.length; i++) {
            vm.departmentList.push({
              text: vm.department[i].deptname,
              value: vm.department[i].id,
            });
            if (vm.data.parameters.deptid) {
                this.ChangeDepartmentHandler(vm.data.parameters.deptid);
           }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

     ChangeDepartmentHandler(id){

       let item = this.department.find((item) => item.id === id);
       this.data.parameters.deptid = item.id;
       this.data.parameters.deptname = item.deptname;

    },


     //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/unit/all'
        })
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < vm.units.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
            if (vm.data.parameters.unitid) {
                this.ChangeUnitHandler(vm.data.parameters.unitid);

           }
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

     ChangeUnitHandler(id){

       let item = this.units.find((item) => item.id === id);
       this.data.parameters.unitid = item.id;
       this.data.parameters.unitname = item.unitname;
    },

    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/section/all'
        })
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < vm.sections.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
            if (vm.data.parameters.sectioid) {
                this.ChangeSectionHandler(vm.data.parameters.sectioid);
           }
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

     ChangeSectionHandler(id){

       let item = this.sections.find((item) => item.id === id);
       this.data.parameters.sectioid = item.id;
       this.data.parameters.sectioname = item.sectioname;
    },

    
    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/division/all'
        })
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < vm.divisions.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
            if (vm.data.parameters.divnid) {
                this.ChangeDivisionHandler(vm.data.parameters.divnid);
           }
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

     ChangeDivisionHandler(id){

       let item = this.divisions.find((item) => item.id === id);
       this.data.parameters.divnid = item.id;
       this.data.parameters.divnname = item.divnname;
    },

    loadWorkGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          url: '/groupp_grouppb_workday/all'
        })
        .then((res) => {
          vm.workgroup = res.data.rec;

          for (let i = 0; i < vm.workgroup.length; i++) {
            vm.workgroupList.push({
              text: vm.workgroup[i].valuedesc,
              value: vm.workgroup[i].valvalueid,
            });
             if (vm.data.parameters.workgroupid) {
                this.ChangeWorkGroupHandler(vm.data.parameters.workgroupid);
           }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

     ChangeWorkGroupHandler(valvalueid){

       let item = this.workgroup.find((item) => item.valvalueid === valvalueid);
       this.data.parameters.workgroupid = item.valvalueid;
       this.data.parameters.valuedesc = item.valuedesc;
    },

     loadAttendanceLateComer(){

    //  this.goBtn = true;
            
      const vm = this;

      this.$store.dispatch('post', {
          url: `/attendancelatecomerlisting/${this.$store.getters.userId}`,
          payload: vm.data
        })
          .then(res => {
            vm.loading = false;

            vm.dataItems = res.data.rec;

            for(let i = 0; i < vm.dataItems.length; i++){
               vm.dataItems[i].id = i + 1;
               vm.dataItems[i].shiftdate = moment(vm.dataItems[i].shiftdate).format("DD-MM-YYYY");

              if (vm.dataItems[i].intime != null) {
                vm.dataItems[i].intime = moment(vm.dataItems[i].intime).format("HH:mm:ss");
               }

              if (vm.dataItems[i].outtime != null) {
                vm.dataItems[i].outtime = moment(vm.dataItems[i].outtime).format("HH:mm:ss");
               } 
            
            }
          })

     },

    printPDF() {
       const doc = new jsPDF({
         orientation: "landscape",
       });

      var head = [
        { title: this.$t('statutoryexportcommonused.no'),  dataKey: 'id'},
        { title: this.$t('columns.date'),  dataKey: 'shiftdate'},
        { title: this.$t('attendancereportbypeiordbycompanyreport.staffcode'), dataKey: "staffcode"},
        { title: this.$t('columns.name'), dataKey: "psnname"},
        { title: this.$t('setuprestdaypatternsetup.daytype'), dataKey: "daytypecode"},
        { title: this.$t('staffdailyrosterrecord.shift'), dataKey: "shiftcode" },
        { title: this.$t('attendancereportbypeiordbycompanyreport.shifttype'), dataKey: "shiftdesc" },
        { title: this.$t('attendancereportbypeiordbycompanyreport.shiftstarttime'), dataKey: "starttime" },
        { title: this.$t('attendancereportbypeiordbycompanyreport.shiftendtime'), dataKey: "endtime" },
        { title: this.$t('processedattendancedatarecord.intime'), dataKey: "intime" },
        { title: this.$t('processedattendancedatarecord.outtime'), dataKey: "outtime" },
        { title: this.$t('processedattendancedatarecord.workhours'), dataKey: "workhrs" },
        { title: this.$t('setupforshift.othours'), dataKey: "othrs" },
       ];

       let body = [];
        for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          id: this.dataItems[i].id,
          shiftdate: this.dataItems[i].shiftdate,
          staffcode : this.dataItems[i].staffcode,
          psnname: this.dataItems[i].psnname,
          daytypecode: this.dataItems[i].daytypecode,
          shiftcode: this.dataItems[i].shiftcode,
          shiftdesc: this.dataItems[i].shiftdesc,
          starttime: this.dataItems[i].starttime,
          endtime: this.dataItems[i].endtime,
          intime: this.dataItems[i].intime,
          outtime: this.dataItems[i].outtime,
          workhrs: this.dataItems[i].workhrs,
          othrs: this.dataItems[i].othrs,
        });
      }
         doc.text(
        `Late Comer Attendance Report from ${this.formData.asofdate1} to ${this.formData.asofdate2} for ${this.formData.compnycode}`,
        10,
        10
      );
 
      doc.autoTable(head, body);
      doc.save("Late Comer Attendance Report " + "-" + this.formData.compnycode + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        "Late Comer Attendance Report " + "-" + this.formData.compnycode + ".csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [`${this.$t('statutoryexportcommonused.no')}`]: item[i].id,
          [`${this.$t('columns.date')}`]: item[i].shiftdate,
          [`${this.$t('attendancereportbypeiordbycompanyreport.staffcode')}`]: item[i].staffcode,
          [`${this.$t('columns.name')}`]: item[i].psnname,
          [`${this.$t('setuprestdaypatternsetup.daytype')}`]: item[i].daytypecode,
          [`${this.$t('staffdailyrosterrecord.shift')}`]: item[i].shiftcode,
          [`${this.$t('attendancereportbypeiordbycompanyreport.shifttype')}`]: item[i].shiftdesc,
          [`${this.$t('attendancereportbypeiordbycompanyreport.shiftstarttime')}`]: item[i].starttime,
          [`${this.$t('attendancereportbypeiordbycompanyreport.shiftendtime')}`]: item[i].endtime,
          [`${this.$t('processedattendancedatarecord.intime')}`]: item[i].intime,
          [`${this.$t('processedattendancedatarecord.outtime')}`]: item[i].outtime,
          [`${this.$t('processedattendancedatarecord.workhours')}`]: item[i].workhrs,
          [`${this.$t('setupforshift.othours')}`]: item[i].othrs,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },

},


  mounted() {},

  created() {
    const vm = this;
    vm.formData = this.$route.params.formData;

    this.loadReport();
    this.loadBranchListHandler();
    this.loadDepartmentListHandler();
    this.loadDivisionListHandler();
    this.loadUnitListHandler();
    this.loadSectionListHandler();
    this.loadStaffListHandler();
    this.loadWorkGroupListHandler();
  
  },
};
</script>

<style>
.dateTitle {
  font-size: 12px;
  color: #ffffff;
  height: 0px;
  background-color: orange;
  align-content: center;
  justify-content: center;
}

.scroller {
  height: 100%;
  width: 100%;
}
</style>