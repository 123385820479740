<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline red white"
                    error-title
                >
                <span style="color: white">{{$t('vdatatable.error')}}</span>
                </v-card-title>

                <v-card-text>
                    {{ this.parentError.data }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="onClosePress"
                    >
                    {{$t('vdatatable.ok')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        parentError: Object
    },

    data() {
        return {
            dialog: this.parentError.isErr
        }
    },

    methods: {
        onClosePress() {
            this.dialog = ! this.dialog;
            this.$emit('closeError', this.dialog);
        }
    }
}
</script>