<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
    
                    <v-row>
                        <v-col cols="4" sm="6" class="pb-n4">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('apprailsalexercise')"
                                    type="text"
                                    ref="appexerciseid"
                                    v-model="formData.appexerciseid"
                                    dense
                                    clearable
                                    :items="appraisalexerciseList"
                                 
                                    :rules="[rules.required]"
                                    @change="changeAppraisalExercise(formData.appexerciseid)"
                                ></v-autocomplete>
                            </v-col>

                             <v-col cols="8" sm="4" class="pb-n4">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="appraisalexercisecode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                          <v-col cols="8" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewtype')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="reviewtype"
                            ></v-text-field>
                        </v-col>

                         <v-col cols="8" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="reviewtypecode"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>

                          <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewfrom')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.startdate"
                            ></v-text-field>
                        </v-col>

                          <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewuntil')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.enddate"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    </div>
                    </v-card>

                     <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="goHandler" 
                                > 
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
// import axios from 'axios'
import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{

              rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData:{
               /// asatdate: new Date().toISOString().slice(0,10),
                appexerciseid: null,
                
                // startdate: null,
                // enddate: null,
                // appexercisedesc: null,
                // fromMonth: null,
                // untilYear: null,
                // untilMonth: null,
         
                title: this.$t('performancereport.performancereviewanalysis')
            },

              parameters: {
                companyid: null,
                sectionid: null,
                employmenttypeid: null,  
                branchid: null,
                unitid: null,
                positionid: null,
                departmentid: null,
                gradeid: null,
                jobid: null,
                divisionid: null,
              //  gradecatid: null,
            },

            showFilter: {
                companyid: true,
                sectionid: true,
                employmenttypeid: true,
                branchid: true,
                unitid: true,
                positionid: true,
                departmentid: true,
                gradeid: true,
                jobid: true,
                divisionid: true,
            },

            appraisalexerciseItems: [],
            appraisalexerciseList: [],
            appraisalexercisecode: '',
            reviewtype: '',
            reviewtypecode: '',
       


            isErr: false,
            errMsg: [],
        }
    },

    methods: {
         goHandler() {

             const formData = this.formData
             const param = this.parameters

             if ((this.formData.appexerciseid != null ))  {
             

            this.$router.push({name: 'performancereviewdetails', params: { formData, param } });

            } else {
                this.$refs.form.validate()
            }

          
        },

          changeAppraisalExercise() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/appraisalexercise/all'
                })
            .then(res => {

                vm.appraisalexerciseItems = res.data.rec;

             //   console.log('1234', vm.appraisalexerciseItems)


                for (let i = 0; i < vm.appraisalexerciseItems.length; i++) {
                    vm.appraisalexerciseList.push({
                        text: vm.appraisalexerciseItems[i].appexercisedesc,
                        value: vm.appraisalexerciseItems[i].id,
                    });
                }
                if (this.formData.appexerciseid) {
                this.changeAppraisalExerciseHandler(this.formData.appexerciseid);
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeAppraisalExerciseHandler(id) {
            var item = this.appraisalexerciseItems.find(item => item.id === id);
            this.formData.appexercisedesc = item.appexercisedesc;
            this.appraisalexercisecode = item.appexercisecode;
            this.reviewtype = item.assessmenttypedesc;
            this.reviewtypecode = item.assessmenttypecode;
            this.formData.startdate = moment(item.startdate).format("DD-MM-YYYY");
            this.formData.enddate = moment(item.enddate).format("DD-MM-YYYY");
            this.rankingsetid = item.rankingsetid;  
            
           // console.log('formData', this.formData)
        },


           updateValue() {
            this.$emit("input", {
                 appexerciseid: this.formData.appexerciseid,
                //  startdate: this.formData.startdate,
                //  enddate: this.formData.enddate,
                //  appexercisedesc : this.formData.appexercisedesc,
                        
            });
        },
    
    },
    mounted() {

   
        this.changeAppraisalExercise();
     //   this.monthGeneration();
    }
}
</script>