<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxableexemption.exemptiongroup')"
                            type="text"
                            dense
                            v-model="formData.taxexemptionid"
                            :items="exemptionGroupList"
                            @change="changeExemptionGroupHandler(formData.taxexemptionid)"
                        ></v-autocomplete>
                    </v-col>

                     <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxableexemption.exemptiongroup')"
                            type="text"
                            dense
                            v-model="formData.taxexemptionid"
                            :items="exemptionGroupList"
                            readonly
                            background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="1" sm="1" class="pb-n5">
                        <v-dialog v-model="exemptiongroupDialog" max-width="600px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: exemptionGroupItems,
                                        formTitle: `${this.$t('setuptaxableexemption.exemptiongroupsetup')}`,
                                        formId: 'exemptiongroupsetup'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveExemptionGroup="saveExemptionGroupHandler"
                                    v-on:deleteExemptionGroup="deleteExemptionGroupHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="exemptiongroupDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.exemptiondesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="currencyDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: currencyItems,
                                            formTitle: `${this.$t('columns.currency')}`,
                                            formId: 'currency'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCurrency="saveCurrencyHandler"
                                        v-on:deleteCurrency="deleteCurrencyHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="currencyDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :rules="[rules.required]"
                                ref="exemptionamount"
                                :label="this.$t('setuptaxableexemption.exemptionamount')"
                                type="number"
                                v-model="formData.exemptionamount"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>  

                    <v-row>
                        <v-col cols="12" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                v-model="formData.remark"
                                :label="this.$t('columns.remark')"
                                ref="remark"
                                type="text"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>  
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || 'Required.',
            },

            formData: {
                taxexemptionid: this.parentObject.data.taxexemptionid,
                exemptioncode: this.parentObject.data.exemptioncode,
                exemptiondesc: this.parentObject.data.exemptiondesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                exemptionamount: this.parentObject.data.exemptionamount,
                crcyid: this.parentObject.data.crcyid,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                lockstaff: this.parentObject.data.lockstaff,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            currencyCode: '',

            currencyList: [],
            currencies: [],
            currencyItems: [],
            exemptionGroupList: [],
            exemptionGroupItems: [],
            
            currencyDialog: false,
            currencyLoading: true,
            exemptiongroupDialog: false,
            
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        //the load handler is to load the contents of each items from the database

        loadExemptionGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/taxexemption/all'})
                .then(res => {
                    vm.exemptionGroup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exemptionGroupList.push(
                            {
                                text: res.data.rec[i].exemptioncode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.exemptionGroupItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].exemptioncode,
                                desc: res.data.rec[i].exemptiondesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changeExemptionGroupHandler(id) {
            let item = this.exemptionGroup.find(item => item.id === parseInt(id));
            
            this.formData.exemptioncode = item.exemptioncode;
            this.formData.exemptiondesc = item.exemptiondesc;
        },

        //save handler is for if the user wants to save a new item into the database
        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/currency/' + this.itemData.itemid, 
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/currency/create', 
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveExemptionGroupHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/taxexemption/' + this.itemData.itemid, 
                    payload: {
                        exemptioncode: this.itemData.itemcode,
                        exemptiondesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.exemptionGroup = [];
                        vm.exemptionGroupItems = [];
                        vm.exemptionGroupList = [];

                        this.loadExemptionGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/taxexemption/create', 
                    payload: {
                        exemptioncode: this.itemData.itemcode,
                        exemptiondesc: this.itemData.itemdesc,
                        userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.exemptionGroup = [];
                        vm.exemptionGroupItems = [];
                        vm.exemptionGroupList = [];

                        this.loadExemptionGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deleteCurrencyHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/currency/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteExemptionGroupHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/taxexemption/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.exemptionGroup = [];
                        vm.exemptionGroupItems = [];
                        vm.exemptionGroupList = [];

                        this.loadExemptionGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        updateValue() {
            this.$emit('input', {
                taxexemptionid: this.formData.taxexemptionid,
                exemptioncode: this.formData.exemptioncode,
                exemptiondesc: this.formData.exemptiondesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                crcyid: this.formData.crcyid,
                exemptionamount: this.formData.exemptionamount,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'exemptionlist', params: { search: this.search}});
        },

        genCurrencyTabItems() {
            for (let i = 0; i < this.currencies.length; i++) {
                this.currencyItems.push(
                    {
                        id: this.currencies[i].id,
                        code: this.currencies[i].crcycode,
                        desc: this.currencies[i].crcydesc,
                        stamp: this.currencies[i].stamp
                    }
                )
            }
        },

    },

    mounted() {
        this.loadCurrencyListHandler();
        this.loadExemptionGroupListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }

}
</script>

<style>
    /* this chunk of codes is to hide the spin button in type=number */
    .removeSpin input[type='number'] {
        -moz-appearance:textfield;
        -moz-appearance:autocomplete;
    }
    .removeSpin input::-webkit-outer-spin-button,
    .removeSpin input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style> 