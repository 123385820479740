<template>
  <div>
    <vendor-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import VendorForm from "../../../components/payroll/vendorsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "vendor-form": VendorForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupvendor.addnewvendor'),
      pageTitleValue: "",
      formData: {
        vendorcode: null,
        vendordesc: null,
        vendorcatid: null,
        vendorcatcode: null,
        addr1: null,
        addr2: null,
        addr3: null,
        stateid: null,
        cntryid: null,
        poscode: null,
        tel: null,
        fax: null,
        email: null,
        officer: null,
        remark: null,
        stamp: null,

        // additional line
        userid: this.$store.getters.userId,
      },

      vendorcategoryList: [],
      countryList: [],
      cityList: [],
      countries: [],
      cities: [],
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    //insert new record
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/vendor/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "vendorlist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
    cancelHandler() {
      this.$router.push({ name: "vendorlist" });
    },
  },
};
</script>