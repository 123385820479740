<template>
    <div>
        <v-btn color="primary" dark class="mb-2" @click.stop="newItem">+</v-btn>
        <v-dialog v-model="ItemDialog" max-width="800px">
            <!-- <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
            </template> -->
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('setupapprvlworkflow.apprvlworkflowitem')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="8" sm="5" md="10">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    ref = "compnyname"
                                    clearable
                                    :items="companyList"
                                    v-model="selections[0].compnyid"
                                    @change="changeCompanyHandler(selections[0].compnyid)"
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" md="2">
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.code')"
                                    type="text"
                                    v-model="selections[0].compnycode"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" md="10" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupapprvlworkflow.nooflevels')"
                                    type="text"
                                    clearable
                                    :items="levelList"
                                    :rules="[rules.requiredd]"
                                    v-model="selections[0].levelno"
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" md="6" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupapprvlworkflow.firstreminderhour')"
                                    type="number"
                                    clearable
                                    :rules="[rules.requiredd]"
                                    v-model="selections[0].remindone"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" md="6" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupapprvlworkflow.subsequentreminderhours')"
                                    type="number"
                                    clearable
                                    :rules="[rules.requiredd]"
                                    v-model="selections[0].remindnext"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" md="6" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupapprvlworkflow.maxreminder')"
                                    type="number"
                                    clearable
                                    :rules="[rules.requiredd]"
                                    v-model="selections[0].maxreminder"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupapprvlworkflow.dueaction')"
                                    type="text"
                                    clearable
                                    :items="wfdueactionList"
                                    :rules="[rules.required]"
                                    v-model="selections[0].wfdueactionid"
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" class="mt-n8">
                                <v-checkbox
                                    :label="this.$t('setupapprvlworkflow.emailnoti')"
                                    v-model="selections[0].IsEmail"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" class="mt-n8">
                                <v-checkbox
                                    :label="this.$t('setupapprvlworkflow.autoapproved')"
                                    v-model="selections[0].IsAutoapr"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" class="mt-n8">
                                <v-checkbox
                                    :label="this.$t('setupapprvlworkflow.onlyhrapproval')"
                                    v-model="selections[0].IsHronly"
                                ></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" sm="5" class="mt-n8">
                                <v-checkbox
                                    :label="this.$t('setupapprvlworkflow.hrasfinalapprover')"
                                    v-model="selections[0].IsHrlast"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red">{{
                        errMsg
                    }}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1" 
                        text @click="closeCRUD"
                        >{{$t('button.cancel')}}</v-btn>

                    <v-btn 
                        color="green darken-1" 
                        text 
                        @click="saveItems"
                        >{{$t('button.save')}}</v-btn>

                    <!-- <v-btn
                        v-else
                        disabled
                        color="green darken-1"
                        text
                        @click="saveItems"
                        >Save</v-btn> -->

                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="this.detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <div v-if="isErr">
            <app-error v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>
        <div v-if="isSuccess">
            <app-success v-bind:parentObject="{
                isSuccess: this.isSuccess,
                messageSuccess: this.messageSuccess,
            }"
            />
        </div>
        <div v-if="isConfirm">
            <app-confirm v-bind:parentObject="{
                messageConfirm: this.messageConfirm,
                isConfirm: this.isConfirm,
            }"
            v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
    </div>
</template>

<script>
import compareArray from "../../../util/comparearray";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-error": ErrorDialog
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                requiredd: value => this.validate(value) || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                }
            },

            ItemDialog: false,
            detailItem: this.parentObject.detailItem,
            wftypeid: null,
            detailid: null,
            selections: [
                {
                    id: null,
                    wfsetupid: parseInt(this.$route.params.id),
                    levelno: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname : null,
                    autoapr: "",
                    IsAutoapr: false,
                    email: "",
                    IsEmail: false,
                    hronly: "",
                    IsHronly: false,
                    hrlast: "",
                    IsHrlast: false,
                    remindone: null,
                    remindnext: null,
                    maxreminder: null,
                    wfdueactionid: null,
                    wfdueaction: null,
                    rectimestamp: null,
                    updatedby: null,
                    stamp: 0,
                    action: 0
                }
            ],

            companies: [],
            wfdueactions: [],
            companyList: [],
            
            detailList: [],
            selected: [],

            levelList: [],
            wfdueactionList: [],

            modify: false,
            isConfirm: false,

            tmpDetailItem: null,
            oItem: null,
            allowSave:false,
            withDuplicates: false,
            isDupItem: false,

            isNew: false,
           // isConfirm: false,
            confirmDelete: false,
            isSuccess: false,

            isExisting: false,
            isErr: false,
            errMsg: "",
        };
    },

    methods: {
        compareArray,

        validate(value) {
            if(value == null){
                return false;
            }
            if(value > -1 && value < 6){
                return true;
            }
        },

         dupDetailItemHandler() {
             let origDetailItem = [...this.detailItem];
             let tmpDetailItem = [];

             origDetailItem.forEach((item) => {
                 tmpDetailItem.push({
                     id: item.id,
                     wfsetupid: item.wfsetupid,
                     levelno: item.levelno,
                     compnyid: item.compnyid,
                     autoapr: item.autoapr,
                     email: item.email,
                     hronly: item.hronly,
                     hrlast: item.hrlast,
                     remindone: item.remindone,
                     remindnext: item.remindnext,
                     maxreminder: item.maxreminder,
                     wfdueactionid: item.wfdueactionid,
                     wfdueaction: item.wfdueaction
                 });
             });

             return tmpDetailItem;
         },

        modifyItemHandler(item) {

            this.isExisting = true; 
            
            // if (item.compnyid ) {
            //     this.allowSave = true
            // }

            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }

             if(item.action == 2) {
                 this.isExisting = true;
             }
            
           
             if (item.autoapr == "Y") {
                item.IsAutoapr = true;
             } else {
                item.IsAutoapr = false;
             }

             if (item.email == "Y") {
                 item.IsEmail = true;
             }else {
                 item.IsEmail = false;
             }

             if (item.hronly == "Y") {
                 item.IsHronly = true;
             }else {
                 item.IsHronly = false;
             }

             if (item.hrlast == "Y") {
                 item.IsHrlast = true;
             }else {
                 item.IsHrlast = false;
             }
            

              this.selections  = [{
                id: item.id,
                wfsetupid: item.wfsetupid,
                levelno: item.levelno,
                compnyid: item.compnyid,
                compnycode: item.compnycode,
                compnyname: item.compnyname,
                autoapr: item.autoapr,
                IsAutoapr: item.IsAutoapr,
                email: item.email,
                IsEmail: item.IsEmail,
                hronly: item.hronly,
                IsHronly: item.IsHronly,
                hrlast: item.hrlast,
                IsHrlast: item.IsHrlast,
                remindone: item.remindone,
                remindnext: item.remindnext,
                maxreminder: item.maxreminder,
                wfdueactionid: item.wfdueactionid,
                wfdueaction: item.wfdueaction,
                action: item.action
            }]
            
            // this.selections[0] = item;
            this.oItem = item.compnyid;
            // this.ItemDialog = true;
            //this.allowSave = true;
          

            this.openDialog();
        },


       checkDupItem(val) {
            if (this.tmpDetailItem.some((item) => item.compnyid == val)) {
                return true;
            }

            return false;
        },

        checkAllFields(){
            let currItemId = parseInt(this.selections[0].compnyid);

            if(this.selections[0].compnyid){
                let isDupItem = false;
               
               if (this.isExisting) {
                    // check item
                    if (this.oItem !== currItemId) {
                        isDupItem = this.checkDupItem(currItemId);

                        if (isDupItem) {
                            this.duplicateError("item");
                            return;
                        }
                    }

               } else {
                   isDupItem = this.checkDupItem(currItemId);

                    if (isDupItem) {
                        this.duplicateError("item");
                        return;
                    }
               }

               
            }
        },

        newItem() {
            // set the form to empty if it is new
            this.isNew = true;
            this.allowSave = false;

            if (this.isNew) {
                this.resetSelections();
            }

            this.openDialog();
        },

        
        deleteItemHandler(item) {
            const vm = this
            this.$store.dispatch('get', {
                url: `/wftranbyid/` + item.id,
                payload: vm.data
                })
            .then(res => {
                let item = res.data.rec;
                if (item.length > 0) {
                    vm.isErr = true;
                    vm.errMsg = 'Cannot delete active workflow setup';
                    return false
                } else {
                    let itemIndex = this.detailItem.indexOf(item);
                    this.detailItem.splice(itemIndex, 1);
                    return true
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        },

               
        openDialog() {
            this.tmpDetailItem = this.dupDetailItemHandler();

            this.ItemDialog = true;
        },

        duplicateError(typeStr) {
            this.isErr = true;
            this.errMsg = "Duplicate " + typeStr + " not allowed";
            
            this.selections[0].compnyid = ''
            this.selections[0].compnyname = ''
            this.selections[0].compnycode = ''
        },

        closeCRUD() {

            this.resetSelections();

            this.ItemDialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            this.detailItem = [...this.detailItem];
        },

        async saveItems() {
             if (this.selections[0].compnyid == null ) {
                 this.$dialog.alert(this.$t('fillinthecompanycolumn')).then((dialog) => {
                console.log(dialog);
             });
             }
             else if (this.selections[0].levelno == null ) {
                 this.$dialog.alert(this.$t('fillinnooflevelcolumn')).then((dialog) => {
                console.log(dialog);
             });
             }
              else if(this.selections[0].remindone == null) {
                 this.$dialog.alert(this.$t('fillinfirstreminderhour')).then((dialog) => {
                console.log(dialog);
             });}
             else if (this.selections[0].remindnext == null){
                 this.$dialog.alert(this.$t('fillinsubsequentreminderhour')).then((dialog) => {
                console.log(dialog);
             });
             } else if (this.selections[0].maxreminder == null ){

              this.$dialog.alert(this.$t('fillinmaxreminderhours')).then((dialog) => {
                console.log(dialog);
             });
            } else if (this.selections[0].wfdueactionid == null ){

              this.$dialog.alert(this.$t('fillindueactioncolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {
            this.isErr = false;
            
            this.wftypeid = this.parentObject.wftypeid
            this.detailid = this.parentObject.detailid

            let item = await this.$store.dispatch('get', {
                    url: `/workflowcompany/` +
                    this.wftypeid + "/" + this.detailid + "/" + this.selections[0].compnyid
                });
                
            if (item.data.rec.length > 0 && this.selections[0].action != 2) {
                this.isErr = true;
                this.errMsg = this.$t('alreadyexistworkflow');
                return false
            }
            
            if (this.selections[0].IsAutoapr) {
                this.selections[0].autoapr = "Y";
            }
            else {
                this.selections[0].autoapr = "N";
            }
            if (this.selections[0].IsEmail) {
                this.selections[0].email = "Y";
            }
            else {
                this.selections[0].email = "N";
            }

            if (this.selections[0].IsHronly) {
                this.selections[0].hronly = "Y";
            }
            else {
                this.selections[0].hronly = "N";
            }

            if (this.selections[0].IsHrlast) {
                this.selections[0].hrlast = "Y";
            }
            else {
                this.selections[0].hrlast = "N";
            }

                if ((this.selections[0].remindone > 0 && (this.selections[0].maxreminder < 1)) || 
                (this.selections[0].remindone > 0 && this.selections[0].remindnext > 0 && (this.selections[0].maxreminder < 2))) {
                    this.errMsg = this.$t('fieldmaxremindershouldbehigher');
                    this.isErr = true;
                    
                    return;
                }

                if (this.selections[0].levelno == null) {
                    this.errMsg = this.$t('fieldnooflevelisrequired');
                    this.isErr = true;
                    
                    return;
                }

                if (this.selections[0].wfdueactionid == null) {
                    this.errMsg = this.$t('fielddueactionisrequired');
                    this.isErr = true;
                    
                    return;
                }

            if (this.isNew && !this.isExisting && !this.modify) {
                this.detailItem.push(Object.assign({}, this.selections[0]));

                for (let i = 0; i < this.detailItem.length; i++) {
                    if (this.detailItem[i].action == 0) {
                        this.detailItem[i].action = 1;
                    }
                }
            } else { 

                this.detailItem.forEach((element) => {
                    if (element.id == this.selections[0].id) {
                        (element.wfsetupid = this.selections[0].wfsetupid),
                        (element.levelno = this.selections[0].levelno),
                        (element.compnyid = this.selections[0].compnyid),
                        (element.autoapr = this.selections[0].autoapr),
                        (element.email = this.selections[0].email),
                        (element.hronly = this.selections[0].hronly),
                        (element.hrlast = this.selections[0].hrlast),
                        (element.remindone = this.selections[0].remindone),
                        (element.remindnext = this.selections[0].remindnext);
                        (element.maxreminder = this.selections[0].maxreminder),
                        (element.wfdueactionid = this.selections[0].wfdueactionid);
                       // (element.stamp = this.selections[0].stamp);
                    }
                });

            }

            this.$emit("input", this.detailItem);

            this.modify = false;
            this.isExisting = false;
            this.ItemDialog = false;
            //this.modify = false;
           // this.isExisting = false;

           // this.resetSelections();
            }
        },

        resetSelections() {
            this.selections = [
                {
                    id: null,
                    wfsetupid: null,
                    levelno: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname: null,
                    autoapr: "",
                    IsAutoapr: false,
                    email: "",
                    IsEmail: false,
                    hronly: "",
                    IsHronly: false,
                    hrlast: "",
                    IsHrlast: false,
                    remindone: null,
                    remindnext: null,
                    maxreminder: null,
                    wfdueactionid: null,
                    wfdueaction: null,
                    rectimestamp: null,
                    updatedby: null,
                    stamp: 0,
                    action: 0
                }
            ];
        },

        loadCompanies() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"}).then(res => {
                vm.companies = res.data.rec;

                for (let i = 0; i < vm.companies.length; i++) {
                    vm.companyList.push({
                        text: vm.companies[i].compnyname,
                        code: vm.companies[i].compnycode,
                        value: vm.companies[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadWfdueactions() {
            const vm = this;

            this.$store.dispatch('get', {url: "/wfdueaction/all"}).then(res => {
                vm.wfdueactions = res.data.rec;

                for (let i = 0; i < vm.wfdueactions.length; i++) {
                    vm.wfdueactionList.push({
                        text: vm.wfdueactions[i].wfdueaction,
                        value: vm.wfdueactions[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeCompanyHandler(id) {
            if (id) {
                this.allowSave = true;
            } else {
                this.allowSave = false;
            }
            this.isErr = false;

            let item = this.companies.find((item) => item.id == id);

            if (item == undefined) {
                this.selections[0].compnycode = null;
                this.selections[0].compnyname = null;
            } else {
                this.selections[0].compnycode = item.compnycode;
                this.selections[0].compnyname = item.compnyname;
            }

            this.checkAllFields();
        },

        changeWfdueactionHandler(id) {
            this.isErr = false;

            let item = this.wfdueactions.find((item) => item.id == id);

            this.selections[0].wfdueaction = item.wfdueaction;

            this.checkAllFields();
        },

        loadLevels() {
            const vm = this;

            for (let i = 1; i < 6; i++) {
                vm.levelList.push({
                    text: i,
                    value: i,
                })
            }
        },
    },

    created() {
        this.loadCompanies();
        this.loadLevels();
        this.loadWfdueactions();
    }
};
</script>
