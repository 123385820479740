<template>
  <div>
    <div>
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          pageTitle: this.pageTitle,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:save="saveHandler"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>

import DisciplinaryRecordForm from "../../../components/personnel/disciplinaryrecordslisting/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": DisciplinaryRecordForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('recdisciplinaryrec.newdisciplinaryrec'),
      formData: {
        staffid: null,
        staffcode: null,
        compnyid: null,
        disciplinid: null,
        severelevelid: null,
        casedesc: null,
        casedate: null,
        officerid: null,
        dsoutcomeid: null,
        supportdoc: null,
        supportdocname: null,
        attach: null,
        attachname: null,
        remark: null,
        stamp: null,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/disciplinaryrecord/stfdisciplin/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "disciplinarylist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },
};
</script>