<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PasswordPolicyForm from "../../../components/settings/passwordpolicy/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": PasswordPolicyForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setuppasswordpolicy.newpasswordpolicy'),
      pageTitleValue: "",
      formData: {
        usertypeid: null,
        pwdgen: null,
        expirydays: null,
        inactivitydays: null,
        maximumattempt: null,
        pwdmin: null,
        pwdmax: null,
        alphanumeric: false,
        symbol: false,
        capitalletter: false,
        repeatchar: false,
        logicscrpt: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      // Convert boolean to integer. 1 = true, 0 = false
      if (
        this.formData.alphanumeric == false ||
        this.formData.alphanumeric == null
      ) {
        this.formData.alphanumeric = 0;
      } else {
        this.formData.alphanumeric = 1;
      }

      if (this.formData.symbol == false || this.formData.symbol == null) {
        this.formData.symbol = 0;
      } else {
        this.formData.symbol = 1;
      }

      if (
        this.formData.capitalletter == false ||
        this.formData.capitalletter == null
      ) {
        this.formData.capitalletter = 0;
      } else {
        this.formData.capitalletter = 1;
      }

      if (
        this.formData.repeatchar == false ||
        this.formData.repeatchar == null
      ) {
        this.formData.repeatchar = 0;
      } else {
        this.formData.repeatchar = 1;
      }

      this.$store.dispatch('post', {url: "/pwdplcy/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "passwordpolicylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          console.log("err", err);
        });
    },

    cancelHandler() {
      this.$router.push({ name: "passwordpolicylist" });
    },
  },
};
</script>