<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('reportsparam.asatdate')"
                                    type="date"
                                    ref="asofdate"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('generateunpaidleaverecord.leavetype')"
                                    type="text"
                                    ref="leavedesc"
                                    :rules="[rules.required]"
                                    v-model="formData.leavetypeid"
                                    dense
                                    :items="leavetypelist"
                                    @input="updateValue"
                                    @change="changeLeavetype(formData.leavetypeid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('generateunpaidleaverecord.leavecode')"
                                    type="text"
                                    ref="leavecode"
                                    v-model="formData.leavecode"
                                    dense
                                    readonly
                                    append-icon=""
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                 <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {

                asofdate: this.parentObject.data.asofdate,
                leavetypeid: this.parentObject.data.leavetypeid,
                leavecode: this.parentObject.data.leavecode,

            },
            
                parameters: {
                 staffid: null,
                 companyid: null,
                 employmenttypeid: null,
                 branchid: null,
                 departmentid: null,
                 valvalueid: null,
             },

             showFilter: {
                 staffid: true,
                 employmenttypeid: true,
                 companyid: true,
                 branchid: true,
                 departmentid: true,
                 valvalueid: true,
             },

            leavetypelist: [],
        }
    },

    methods: {

        //load leavetype list view
        loadLeavetypeListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/leavesetup/all`})
                .then((res) => {
                //req success --> push res into array
                vm.leavetype = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.leavetypelist.push({
                    text: res.data.rec[i].leavedesc,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.leavetypeid) {
                    this.changeLeavetype(vm.formData.leavetypeid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeLeavetype(id) {
                let item = this.leavetype.find((item) => item.id === id);
                this.formData.leavetypeid = item.id;
                this.formData.leavecode = item.leavecode;

                // if (this.formData.leavedays) {
                //     if (item.unittype == "Hour") {
                //     this.formData.leavehours = this.formData.leavedays;
                //     this.formData.leavedays = 0;
                //     }
                // }
            },


        goHandler(){

            const formData = this.formData
            const param = this.parameters

            //this.$router.push({name: 'detailsleavebalance' , params: { formData, param } });
           // , params: { formData, param } });


             if ((this.formData.asofdate != null && this.formData.leavetypeid != null))  {
                
                 this.$router.push({name: 'detailsleavebalance', params: { formData, param } });

             } else {
                 this.$refs.form.validate()
             }
            
        },

        updateValue() {
            this.$emit("input", {
                asofdate: this.formData.asofdate,
                leavetypeid: this.formData.leavetypeid,
                leavecode: this.formData.leavecode,
                
            });
        },
    }, 

    mounted() { 
      this.loadLeavetypeListHandler();
     
    },
    
}
</script>
