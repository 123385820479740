<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline orange white"
                    error-title
                >
                <span style="color: white">{{$t('letterlist.printletter')}}</span>
                </v-card-title>
                
                <v-card-text class="mt-4 mb-n8">
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                outlined
                                :label="this.$t('letterlist.lettertemplatename')"
                                type="text"
                                ref="templatetypeid"
                                hide-selected
                                :items="letterTemplateList"
                                v-model="templatetypeid"
                                dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                <v-card-text v-if="messageConfirm">
                    {{messageConfirm}}
                </v-card-text>
                <v-card-text v-else>
                    {{$t('letterlist.areyousuretoprint')}} <span class="page_title_value">{{this.parentObject.pageTitleValue }}</span>?
                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="onOKPress"
                    >
                    {{$t('vdatatable.ok')}}
                    </v-btn>
                    <v-btn
                        color="danger"
                        text
                        @click="onCancelPress"
                    >
                    {{$t('button.cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            dialog: this.parentObject.isPrint,
            messageConfirm: this.parentObject.messageConfirm,
            templatetypeid: null,
            letterTemplateRec: [],
            letterTemplateList: [],
        }
    },

    methods: {
        loadLetterTemplateListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/letter/all'})
                .then(res => {
                    vm.letterTemplateRec = res.data.rec;
                    vm.letterTemplateList = [];
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.letterTemplateList.push(
                            {
                                text: res.data.rec[i].templatename,
                                value: res.data.rec[i].id
                            }
                        )
                    } 
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        onOKPress() {
            this.dialog = !this.dialog;
            this.$emit('print', this.templatetypeid);
        },

        onCancelPress() {
            this.dialog = ! this.dialog;
            this.$emit('cancelPrint');
        }
    },

    mounted() {
        this.loadLetterTemplateListHandler();
    }
}
</script>