<template>
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5 pl-7">{{ this.parentObject.pageTitle }}<span class="page_title_value pl-2">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-row>
                    <div class="pt-2 pl-7 pb-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 70px;"
                            @click="backHandler" 
                            > 
                            Back
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 ml-n1"
                >

                    <div>
                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5">
                                <v-text-field
                                    outlined
                                    label="Exercise code"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>


                            <v-col cols="8" sm="8" class="pb-n5">
                                <v-text-field
                                    outlined
                                    label="Exercise description"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Period start date"
                                    ref="asofdate1"
                                    type="date"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>


                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Period end date"
                                    ref="asofdate1"
                                    type="date"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Status"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="1" sm="1" class="mt-n6 mb-4 big-field">
                                <v-text-field
                                    label="Locked"
                                    ref="asofdate1"
                                    outlined
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.test"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#6DB961"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="1" sm="1" class="mt-n6 mb-4 big-field">
                                <v-text-field
                                    label="Pending"
                                    ref="asofdate1"
                                    outlined
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.test"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#6DB961"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="1" sm="1" class="mt-n6 mb-4 big-field">
                                <v-text-field
                                    label="Open"
                                    ref="asofdate1"
                                    outlined
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.test"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#6DB961"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>

                <v-container>
                    <div>
                        <v-row>
                            <app-table
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.ratingscalesetdetail
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-row>
                    </div>
                </v-container>

                <v-row>
                    <div class="pt-5 pl-7">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px; margin-bottom: 20px;"
                            @click="closeHandler" 
                            > 
                            Close
                        </v-btn>
                        </v-col>

                        </v-row>
                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import CRUDTable from './CRUDTableStaff';

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-table': CRUDTable,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                asofdate1: null,
                asofdate2: null,
                compnyid: null,
                compnycode: null,
                test: 35,

            },

            companyname: [],
            companyList: [],
            errMsg: "",
            isErr: false,

            ratingscalesetdetail: [
                {ratingcode: 1},
            ],

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: 'Staff Code', value: 'ratingcode', width: '200px', sortable: true},
                { text: 'Name', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer Name', value: 'label', width: '200px', sortable: true},
                { text: 'Status', value: 'label', width: '200px', sortable: true},
                { text: 'Objective Reviewer', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer Staff Code', value: 'label', width: '200px', sortable: true},
                { text: 'Company', value: 'label', width: '200px', sortable: true},
                { text: 'Branch', value: 'label', width: '200px', sortable: true},
                { text: 'Department', value: 'label', width: '200px', sortable: true},
                { text: 'Section', value: 'label', width: '200px', sortable: true},
                { text: 'Unit', value: 'label', width: '200px', sortable: true},
                { text: 'Grade', value: 'label', width: '200px', sortable: true},
                { text: 'Position', value: 'label', width: '200px', sortable: true},
            ],
        }
    },

    methods: {

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
                let item = this.companyname.find((item) => item.id === id);
                this.formData.compnyid = item.id;
                this.formData.compnycode = item.compnycode;

            },


            closeHandler(){

            // const formData = this.formData
            // const param = this.parameters

            // if(this.formData.asofdate1 > this.formData.asofdate2 || this.asofdate2 < this.formData.asofdate1 ){

            //     this.$dialog.alert("Attendance From Date should be earlier than Attendance Until Date").then((dialog) => {
            //     console.log('dailog', dialog);
            //  });

            // } else if ((this.formData.asofdate1 != null && this.formData.asofdate2 != null && this.formData.compnyid != null))  {
                
                 this.$router.push({name: 'kpisettinglist'});

            //  } else {
            //     this.$refs.form.validate()
            //  }   
        },

        backHandler(){
            // this.$router.push({name: 'kpisettingnew', params: { formData, param } });
            this.$router.push({name: 'kpisettinglist'});
        },

        updateValue() {
            this.$emit("input", {
                 asofdate1: this.formData.asofdate1,
                 asofdate2: this.formData.asofdate2,
                 compnyid: this.formData.compnyid,
                 compnycode: this.formData.compnycode,
                
            });
        },
    }, 

    mounted() { 
      this.loadCompanyListHandler();
     
    },
    
}
</script>

<style scoped>
.big-field .v-input__slot{
    height: 60px;
}
.big-field .v-input__control input {
  font-size: 20px;
  font-weight: 600;
  color: white;
}
.big-field .v-text-field__slot #input-44 {
  position: relative;
  top: 10px;
  left: 35px !important;
}
.big-field .v-text-field__slot #input-47 {
  position: relative;
  top: 10px;
  left: 35px !important;
}
.big-field .v-text-field__slot #input-50 {
  position: relative;
  top: 10px;
  left: 35px !important;
}
.big-field .v-label {
  color: white;
}
.big-field .v-input__control {
  border-color: transparent;
}
</style>
