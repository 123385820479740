<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row>
          <v-col cols="12" sm="6" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('allepf.epfsubmissionrec')"
              type="text"
              v-model="formData.epfexprecid"
              :items="epfExpRecList"
              :rules="[rules.required]"
              @change="changeEpfExportRecord(formData.epfexprecid)"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" class="mb-n1 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
              type="text"
              v-model="formData.year"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3" class="mb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('bankpaymentlistingreport.payoutmonth')"
              type="text"
              v-model="formData.month"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}}  </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="10">
            <v-data-table
              :headers="dataHeader"
              :items="this.payoutItems"
              :options="tableOpt"
              hide-default-footer
              item-key="payoutcode"
            >
            </v-data-table>
          </v-col>
        </v-row>

          <v-row>
              <v-col cols="12" sm="6" class="pb-n5 mt-3">
                  <v-autocomplete
                    outlined
                    :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                    type="text"
                    v-model="formData.refnum"
                    :rules="[rules.required]"
                    :items="epfSubRefRecList"
                    dense
                    @change="epfSubRefRecHandler(formData.refnum)"
                  ></v-autocomplete>
              </v-col>
          </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="6" class="pb-n5 mt-3">
            <v-text-field
              outlined
              :label="Reference No."
              type="text"
              v-model="formData.refnum"
              :rules="[rules.required]"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="displayReport">
              {{$t('button.print')}} 
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('allepf.epfborang_a'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        {
          text: this.$t('statutoryexportcommonused.payoutdescription'),
          value: "payoutdesc",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('payrollprocessrun.payoutstatus'),
          value: "pystatusdesc",
          width: "300px",
          sortable: true,
        },
      ],
      formData: {
        epfexprecid: null,
        refnum: null,
        refcode: null,
        year: null,
        month: null,
        epfsubrecrefid: null,
        epfsubrecpy: [],
        epfsubrecref: [],
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      epfExpRecList: [],
      payoutItems: [],
      epfsubrecitems: [],
      exportformatItems: [],
       epfSubRefRecList: [],
      // exportFmtList: [],
      notFinalize: false,
      loading: false,
      isErr: false,
      errMsg: "",
      reportFile: "borang_a.rptdesign",
    };
  },

  methods: {

    getEpfSubRecRef(id) {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/epfsubmissionrecordref/' + id})
            .then (res => {

              vm.epfsubrecitems = res.data.rec;
                
                //set the taxsubrefreclist array null (to avoid getting value from previous array)
                vm.epfSubRefRecList = [];

                vm.formData.epfsubrecref = vm.epfsubrecitems;
                
                 for (let i = 0; i < vm.epfsubrecitems.length; i++) {
                    vm.epfSubRefRecList.push({
                    text:  vm.epfsubrecitems[i].refcode + ' - ' + res.data.rec[i].refnum ,
                    value: vm.epfsubrecitems[i].refnum,
                    });
                  
                }
                
                vm.loading = !vm.loading;
            })
            .catch (err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
        
            });

        },

  
      epfSubRefRecHandler(refnum){
       let item = this.epfsubrecitems.find((item) => item.refnum === refnum);

       this.formData.epfsubrecrefid = item.id;
    },

    getEpfSubRecs() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/epfsubmissionrecord/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.epfExpRecList.push({
              text: res.data.rec[i].epfsubreccode,
              value: res.data.rec[i].id,
            });
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getEpfSubRecPayout(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/epfsubmissionrecordpy/" + id})
        .then((res) => {
          vm.payoutItems = res.data.rec;
          vm.formData.epfsubrecpy = vm.payoutItems;

          for (let i = 0; i < vm.payoutItems.length; i++) {
            if (vm.payoutItems[i].pystatuscode != 99) {
              this.notFinalize = true;
            } else {
              this.notFinalize = false;
            }
          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    changeEpfExportRecord(id) {
      this.formData.refnum = null;
      let itemExportRecord = this.dataItems.find((item) => item.id === id);

      this.formData.epfexprecid = itemExportRecord.id;
      this.formData.year = itemExportRecord.recyear;
      this.formData.month = itemExportRecord.recmonth;

      this.getEpfSubRecPayout(id);
      this.getEpfSubRecRef(id);
    },

    exportHandler() {
      console.log(this.formData);
      if (this.notFinalize) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.payoutnotfinalized');
      } else {
        if (this.$refs.form.validate()) {
          window.open(
            `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&id=${this.formData.epfexprecid}&epfsubrecrefid=${this.formData.epfsubrecrefid}&userid=${this.$store.getters.userId}&dbname=${this.$store.getters.dbname}`,
            "_blank" // <- This is what makes it open in a new window.
          );
        } else {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        }
      }
    },
    
    displayReport() {
      const vm = this;
      const reporturl =  `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&id=${this.formData.epfexprecid}&epfsubrecrefid=${this.formData.epfsubrecrefid}&userid=${this.$store.getters.userId}&dbname=${this.$store.getters.dbname}`;
      if (this.notFinalize) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.payoutnotfinalized');
      } else {
        if (this.$refs.form.validate()) {
          this.$store.dispatch('post', {
            url: "/reportdisplay/url", 
            payload: {reporturl: reporturl, reportname: this.reportFile},
            responseType: "blob"})
            .then((res) => {
              console.log(res.data);
              if (res.data && res.status == 200) {
                var fileURL = URL.createObjectURL(res.data);
                window.open(fileURL, "_blank");
              } else {
                vm.isErr = true;
                vm.errMsg = res.data.error;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
              }
            })
            .catch((err) => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        } else {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        }
      }
    },
  },

  mounted() {
    this.getEpfSubRecs();
  },
};
</script>