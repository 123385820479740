<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="2" md="2" xs="12" sm="12" class="pb-n5 text-right"> </v-col>
      <v-col cols="4" md="4" xs="12" sm="12" class="pb-n5 text-right">
        <v-card class="mx-auto">
          <v-img src="../../assets/t2hr login page2.png" style="width: 100%">
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" xs="12" sm="12" class="pb-n5 text-left">
        <!-- mx-auto -->
        <div id="login">
          <v-card width="500" class="mx-auto">
            <v-card-text>
              <v-form id="login-form" v-on:submit.prevent="login">
                <v-text-field
                  id="username"
                  :label="$t('login.username')"
                  v-model="username"
                  prepend-icon="mdi-account-circle"
                />
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  :label="$t('login.password')"
                  v-model="password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  class="mt-n1"
                />
              </v-form>
            </v-card-text>
            <v-spacer></v-spacer>
            <div v-if="errorMsg" class="pl-5 pb-1">{{ errorMsg }}</div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="info" type="submit" form="login-form">{{
                $t("login.login")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn v-on:click="forgotbtn()" color="success">{{
                $t("login.forgot_password")
              }}</v-btn>
              <v-spacer></v-spacer>
              <app-lang-switch />
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <v-col cols="3" md="3" xs="12" sm="12" class="pb-n5 text-right"> </v-col>
    </v-row>
  </v-container>
</template>
<style>
.v-content {
  height: 100%;
}
</style>
<script>
//import i18n from '../../i18n';
import LangSwitch from "../common/LangSwitch";
import axios from "axios";
import crypto from "crypto-js";

export default {
  components: {
    "app-lang-switch": LangSwitch,
  },

  data() {
    return {
      showPassword: false,
      username: null,
      password: null,
      errorMsg: null,
      ip: ""
    };
  },

  methods: {
    forgotbtn() {
      this.$router.push({ name: "forgotpassword", params: {db: this.$route.params.db} });
    },
    login() {
      const vm = this;
      vm.errorMsg = null;
      this.$store
        .dispatch("retrvAccessToken", {
          username: vm.username,
          password: String(crypto.AES.encrypt(vm.password, 'secret_t2hr')),
          ip: vm.ip,
          origin: 'browser',
          db: this.$route.params.db
        })
        .then((res) => {
          if (res.data.status == 400 && res.data.errornum == 1) { // First Time Login
            this.$router.push({
              name: "firsttimelogin",
              params: { id: res.data.userid, db: this.$route.params.db },
            });
          } else if (
            res.data.status == 400 &&
            res.data.errornum == 2 // Reset password
          ) {
            this.$router.push({
              name: "firsttimelogin",
              params: { id: res.data.userid, db: this.$route.params.db },
            });
          } else if (res.data.status == 201) {
            // this.$router.push({ name: "dashboard" });
            if(res.data.funcaccess.length > 0) { // Checking if admin or not. If admin go to dashboard page
              this.$router.push({ name: "dashboard" });
            } else {
              this.$router.push({ name: "selfservicehome" });
            }            
          } else {
            vm.errorMsg = res.data.error;
          }

          if(this.username == null || this.password == null || 
             this.username == '' || this.password == '' || 
             this.username == null && this.password == null
             || this.username == '' && this.password == ''){
             this.$dialog.alert(this.$t('userandpassrequired')).then((dialog) => {
                console.log('dailog', dialog);
             });
          }

        })
        .catch((error) => {
          vm.errorMsg = error;
        });
    },

    //resetTokenHandler() {
    //  this.$store.dispatch('deleteToken')
    //}
  },

  mounted() {
    //this.resetTokenHandler();
    axios
      .get("https://api.ipify.org?format=json", {})
      .then((res) => {
        console.log(res);
        this.ip = res.data.ip;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
