<template>
    <div id="companygrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>
<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('recpersonalinfo.personlist'), 
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('recpersonalinfo.personid'), value: 'psncode', width: '120px' },
                { text: this.$t('columns.name'), value: 'psnname', width: '300px' },
                { text: this.$t('recpersonalinfo.name_2'), value: 'psnname2', width: '200px' },
                { text: this.$t('recpersonalinfo.salutation'), value: 'salutname', width: '140px' },
                { text: this.$t('recpersonalinfo.firstname'), value: 'fname', width: '150px' },
                { text: this.$t('recpersonalinfo.middlename'), value: 'mname', width: '150px' },
                { text: this.$t('recpersonalinfo.lastname'), value: 'lname', width: '150px' },
                { text: this.$t('columns.gender'), value: 'gendername', width: '120px' },
                { text: this.$t('columns.dob'), value: 'dob', width: '120px' },
                { text: this.$t('recpersonalinfo.idno'), value: 'idno', width: '150px' },
                { text: this.$t('recpersonalinfo.oldidno'), value: 'oldidno', width: '120px' },
                { text: this.$t('recpersonalinfo.maritalstatus'), value: 'marstatname', width: '120px' },
                { text: this.$t('recpersonalinfo.race'), value: 'racename', width: '120px' },
                { text: this.$t('recpersonalinfo.religion'), value: 'rlgnname', width: '120px' },
                { text: this.$t('recpersonalinfo.nationality'), value: 'cntryoname', width: '120px' },
                { text: this.$t('recpersonalinfo.countryoforigin'), value: 'cntryoname', width: '120px' }
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getPersonListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].dob) {
                            vm.dataItems[i].dob = moment(vm.dataItems[i].dob).format('YYYY-MM-DD');
                        }
                    }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'personedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'personnew'});
        }
    },

    mounted() {
        this.getPersonListHandler();
    }
}
</script>