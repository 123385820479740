<template>
    <div>
        <v-container>
            <form enctype="multipart/form-data">
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

        <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >                

                    <v-row>                                                

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('recannouncementsetup.subject')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.announce"
                                @input="updateValue"
                                dense
                            ></v-text-field>    
                        </v-col>
                    </v-row>

                    <v-row>                                                

                        <v-col cols="15" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('recannouncementsetup.announcementdetails')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.announcedesc"
                                @input="updateValue"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                     <v-row>                                                

                        <v-col cols="6" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recannouncementsetup.publishfrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recannouncementsetup.publishuntil')"
                                type="date"
                                ref="enddate"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="suspended"
                                v-model="formData.suspended"
                                :label="this.$t('recannouncementsetup.suspended')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>


                    <v-col cols="4" sm="4">
                            
                            <v-row>
                                <v-col
                                    class="pa-0"
                                    cols="10"
                                >
                                    <div class="text-center">
                                    <v-avatar
                                        class="profile"
                                        size="220"
                                        width="230"
                                        height="150"
                                        tile
                                    >
                                        <v-img 
                                            v-if="!this.formData.picture"
                                            :src="`${publicPath}images/company.png`">
                                        </v-img>
                                        <v-img v-else
                                            :src="this.formData.picture"
                                        >
                                        </v-img>
                                    </v-avatar>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="10"  
                                    class="pb-n4 ml-n6"
                                    align-self="center"
                                >
                                    <div class="text-center">
                                        <v-file-input
                                            :label="this.$t('recannouncementsetup.changeannouncementpic')"
                                            ref="profileimg"
                                            :accept=this.$store.getters.profileAcceptType
                                            dense
                                            show-size
                                            style="font-size: 14px"
                                            prepend-icon="mdi-camera"
                                            @change="imgToBase64">
                                        </v-file-input>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-col>


                </v-container>
                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </form>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            formData: {
                announce: this.parentObject.data.announce,               
                announcedesc: this.parentObject.data.announcedesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                suspended: this.parentObject.data.suspended,
                picture: this.parentObject.data.picture,
                search: this.parentObject.data.search,
                userid: this.parentObject.data.userid,
                stamp: this.parentObject.data.stamp
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },           
            
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },
    methods: {

        imgToBase64() {
            var file = this.$refs.profileimg.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.picture = b64;
            
                this.$emit('profile', b64);

       
                //file size in bytes. (5000000bytes = 5mb)
                if (file.size > '5000000'){
                     alert('File size is bigger than 5MB. Please upload new picture.');
                    return;
                }
            }
            reader.readAsDataURL(file); 


        },

         fileToBase64() {
            var file = this.$refs.file.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.attach = b64;
            }

            reader.readAsDataURL(file);
        },

        addItemHandler($event) {
            this.formData.holidaydetail =$event
            console.log(this.formData.holidaydetail)
        },

        getAnnouncementList() {
            const vm = this;
            console.log(this.formData);
            this.$store.dispatch('get', {url: "/announcement/all"})
                .then(res => {
                    vm.holiday = res.data.rec;

                    
                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
               
        

        changeAnnouncementListHandler(id) {
            let item = this.holiday.find(item => item.id === id);

            this.announcementList = item.announce;
        },

        
       

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                announce: this.formData.announce,
                announcedesc: this.formData.announcedesc,
                startdate: this.formData.startdate,               
                enddate: this.formData.enddate,
                suspended: this.formData.suspended,
                picture: this.formData.picture,
                search: this.formData.search,
                userid: this.formData.userid,
                stamp: this.formData.stamp
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            console.log(this.formData)
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'announcementlist',  params: { search: this.search}});
        }
    },

    mounted() {
        this.getAnnouncementList();
        
    }
}
</script>