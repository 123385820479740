<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('recdocrec.docreclist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.name'), value: "psnname", width: "350px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "150px" },
        { text: this.$t('reporalertlisting.docno'), value: "docno", width: "150px" },
        { text: this.$t('reporalertlisting.doctype'), value: "doctypecode", width: "200px" },
        { text: this.$t('columns.startdate'), value: "startdate", width: "200px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "200px" },
        { text: this.$t('columns.remark'), value: "remark", width: "200px" },
      ],
      dataItems: [],
      errMsg: "",
      isErr: false,
      filename: null,
      search: this.$route.params.search,
    };
  },

  methods: {
    getDocumentRecordDetailListHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: `/documentrecordd/all/${this.$store.getters.userId}`})
        .then((res) => {
          this.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format(
                "YYYY-MM-DD"
              );
            }
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "documentrecordedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "documentrecordnew" });
    },
  },

  mounted() {
    this.getDocumentRecordDetailListHandler();
  },
};
</script>