<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4">
                            <v-text-field
                                dense
                                :label="this.$t('columns.workflowtype')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.wftypedesc"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" class="pb-n4">
                            <v-text-field
                                dense
                                :label="this.$t('recworkflowmaintenance.recid')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.recordid"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.staffname')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.psnname"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.staffcode')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.staffcode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('attendancereportbypeiordbycompanyreport.companycode')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.compnycode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.recorddate')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.recdate"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1" sm="1" class="pb-n2 mt-n6">
                            <v-icon 
                                class="material-icons"
                                @click="showDetail"
                            >
                                error
                            </v-icon>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.actions')"
                                type="text"
                                ref="aprstatid"
                                :items="aprstatusList"
                                v-model="formData.aprstatid"
                                @input="updateValue"
                                @change="changePersonHandler(formData.aprstatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('recworkflowmaintenance.persontodelegate')"
                                type="text"
                                ref="aprid"
                                :items="personList"
                                v-model="formData.aprid"
                                @input="updateValue"
                                :rules="[rules.required]"
                                @change="personChangeHandler(formData.aprid)"
                                :disabled="formData.aprstatid != 4"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                id="remark"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-dialog v-model="detaildialog" max-width="800px">
                        <v-card>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col 
                                        cols="4" 
                                        sm="10" 
                                        class="blue--text"
                                        style="font-size: 15px"
                                        >{{$t('recworkflowmaintenance.approvaldetail')}}</v-col>
                                    </v-row>
                                    <div v-for="item in formData.wftrandinfo" :key="item.id">
                                        <v-row style="margin-top: 15px">
                                            <v-col
                                            cols="4"
                                            sm="4"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{$t('recworkflowmaintenance.levelno')}}</v-col
                                            >
                                            <v-col
                                            cols="8"
                                            sm="8"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{ item.lvlno }}</v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col
                                            cols="4"
                                            sm="4"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{$t('columns.staffname')}}</v-col
                                            >
                                            <v-col
                                            cols="8"
                                            sm="8"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{ item.psnname }}</v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col
                                            cols="4"
                                            sm="4"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{$t('recworkflowmaintenance.outcome')}}</v-col
                                            >
                                            <v-col
                                            cols="8"
                                            sm="8"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{ item.aprstatus }}</v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col
                                            cols="4"
                                            sm="4"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{$t('trainingrecord.submittedon')}}</v-col
                                            >
                                            <v-col
                                            cols="8"
                                            sm="8"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{ item.wftranddate }}</v-col
                                            >
                                        </v-row>
                                        <v-row>
                                            <v-col
                                            cols="4"
                                            sm="4"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{$t('recworkflowmaintenance.approvaltime')}}</v-col
                                            >
                                            <v-col
                                            cols="8"
                                            sm="8"
                                            class="mt-n3"
                                            style="padding: 8px 12px"
                                            >{{ item.aprdate }}</v-col
                                            >
                                        </v-row>
                                    </div>
                                </v-container>
                                <v-row justify="center" align="center">
                                    <div class="pt-5">
                                        <v-btn 
                                            color="warning" 
                                            style="margin-right:20px; width: 100px" 
                                            @click="closeDetail">
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </div>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                </v-container>
                <v-row justify="center" align="center">
                    <div class="pt-5">
                        <v-btn 
                            color="success" 
                            style="margin-right:20px; width: 100px" 
                            @click="saveHandler">
                            {{$t('button.save')}}
                        </v-btn>
                        <v-btn 
                            color="warning" 
                            style="margin-right:20px; width: 100px" 
                            @click="cancelHandler">
                            {{$t('button.cancel')}}
                        </v-btn>
                        <!-- <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="actionHandler">
                            Start
                        </v-btn> -->
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
// import axios from 'axios';

export default {
    props: {
        parentObject: Object
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            formData: {
                id: this.parentObject.data.id,
                aprstatid : this.parentObject.data.aprstatid,
                aprid: this.parentObject.data.aprid,
                wftypeid: this.parentObject.data.wftypeid,
                wftypecode: this.parentObject.data.wftypecode,
                wftypedesc: this.parentObject.data.wftypedesc,
                recordid: this.parentObject.data.recordid,
                psnname: this.parentObject.data.psnname,
                staffcode: this.parentObject.data.staffcode,
                compnyname: this.parentObject.data.compnyname,
                compnycode: this.parentObject.data.compnycode,
                recdate: this.parentObject.data.recdate,
                wftrand: this.parentObject.data.wftrand,
                wftranid : this.parentObject.data.wftranid,
                wftrandinfo: this.parentObject.data.wftrandinfo,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            aprstatusList: [],
            personList: [],
            person: [],
            aprstatus: [],
            errMsg: '',
            isErr: false,
            detaildialog: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadPerson() {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/staff/all/${this.$store.getters.userId}`
                })
                .then(res => {
                    vm.person = res.data.rec;

                   // console.log('person', vm.person)
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.personList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )

                      //  console.log('list', vm.personList)
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        personChangeHandler(id) {
          
        let item = this.person.find((item) => item.id == id);
        //console.log(item);
        this.formData.aprid = item.id;
        
        
        },

        loadApprovalStatus() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/selectedaprstatus/all`
                })
                .then(res => {
                    vm.aprstatus = res.data.rec;
                    //console.log('status', vm.aprstatus)
                    for (let i = 0; i < res.data.rec.length; i++) {
                     //   if (res.data.rec[i].apraction) {
                            vm.aprstatusList.push(
                                {
                                    text: res.data.rec[i].aprstatus,
                                    value: res.data.rec[i].id
                                }
                            )
                      //  }

                    }

                    //console.log('list', vm.aprstatusList)
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        showDetail() {
            this.detaildialog = true
        },

        closeDetail() {
            this.detaildialog = false
        },

        changePersonHandler(id) {
           if (id != 4) this.formData.aprid = null
        },

        updateValue() {
            console.log('formdata', this.formData)
            this.$emit('input', {
                id: this.formData.id,
                wftypeid: this.formData.wftypeid,
                wftypecode: this.formData.wftypecode,
                wftypedesc: this.formData.wftypedesc,
                aprstatid: this.formData.aprstatid,
                aprid: this.formData.aprid,
                remark: this.formData.remark,
                wftrandid: this.formData.wftranid,
                wftrandinfo: this.formData.wftrandinfo,
            });
        },

        saveHandler() {
            this.$emit('save');
        },

        cancelHandler() {
            this.$router.push({name: 'workflowmaintenancelist', params: { search: this.search}});
        },

        actionHandler() {
            console.log('action')
        }
    },

    mounted() {
        this.loadPerson();
        this.loadApprovalStatus();
    }
}
</script>