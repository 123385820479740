<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import LeaveRecordForm from "../../../components/leave/leaverecord/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": LeaveRecordForm,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('recleaverec.newleaverec'),
      pageTitleValue: "",
      formData: {
        id: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnyname: null,
        leavetypeid: null,
        leavecode: null,
        startdate: null,
        enddate: null,
        partialday: [],
        reasonid: null,
        reasoncode: null,
        clinicid: null,
        cliniccode: null,
        reliefstaffid: null,
        lvstatusid: null,
        lvstatuscode: null,
        posted: false,
        attachment: null,
        attachname: null,
        aprvremark: null,
        lvremark: null,
        entrytime: null,
        entrybid: null,
        entrybyusername: null,
        appsrcid: 1,
        src: null,
        leavehours: null,
        leavedays: null,
        nonpanelclinic: null,
       // leavedate: [],
        //daytypeid: [],
        leaverecorddetail: [],
        stamp: 0,
        userid: this.$store.getters.userId,
        ishistory: false,
        fromweb: true
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      if (this.formData.posted == true) {
        this.formData.posted = 1;
      } else {
        this.formData.posted = 0;
      }

      if (this.formData.ishistory == true) {
        this.formData.ishistory = 1;
      } else {
        this.formData.ishistory = 0;
      }

      console.log('form New',vm.formData);
      this.$store.dispatch('post', {url: "/leaverecord/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            if (res.data.rec != "") {
              this.$dialog.alert(res.data.rec).then((dialog) => {
                console.log(dialog);
                this.$router.push({ name: "leaverecordlist" });
              });
            } else {
              this.$router.push({ name: "leaverecordlist" });
            }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "leaverecordlist" });
    },
  },
};
</script>
