<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.shiftcode')"
                                type="text"
                                ref="shiftcode"
                                v-model="formData.shiftcode"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.shiftdescription')"
                                type="text"
                                ref="shiftdesc"
                                v-model="formData.shiftdesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pn-n4 mt-n8">

                    <v-radio-group
                         v-model="radiogroup"
                         row
                         @change="changeShiftHandler(radiogroup)"
                        >
                    
                    <v-radio
                        :label="this.$t('setupforshift.fixedtime')"
                        value="ft"
                    ></v-radio>
                      
                    <v-radio
                        :label="this.$t('setupforshift.flexistaggered')"
                        value="fs"
                    ></v-radio>

                     <v-radio
                        :label="this.$t('setupforshift.flexihours')"
                        value="fh"
                    ></v-radio>
                    </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" md="3" class="mt-n8"> {{$t('setupforshift.starttime')}}
                            <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            type = "text"
                            v-model="starttimeHolder"
                            format="HH:mm"
                            
                            dense
                            input-class="time-picker"
                            ref="starttime"
                            :disabled="radiogroup == 'fh'"
                            input-width="100%"
                            @input="starttime"
                            close-on-complete
                            outlined
                        ></vue-timepicker>   
                    </v-col>

                    <v-col cols="3" sm="3" md="3" class="mt-n8">  {{$t('setupforshift.endtime')}}
                            <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            type = "text"
                            v-model="endtimeHolder"
                            format="HH:mm"
                        
                            input-class="time-picker"
                            ref="endtime"
                            @input="endtime"
                            :disabled="radiogroup == 'fh'"
                            input-width="100%"
                        ></vue-timepicker>    
                    </v-col>
                </v-row>
                    <br>
                    <br>

                      <v-row>
                    
                        <v-col cols="3" sm="3" md="3" class="mt-n8"> {{$t('setupforshift.latecomertime')}}
                            <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            v-model="latetimeHolder"
                            format="HH:mm"
                            
                            input-class="time-picker"
                            ref="latetime"
                            @input="latetime"
                            :disabled="radiogroup == 'fs' || radiogroup == 'fh'"
                            input-width="100%"
                        ></vue-timepicker>    
                    </v-col>

                    <v-col cols="3" sm="3" md="3" class="mt-n8"> {{$t('setupforshift.earlyleavertime')}}
                            <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            v-model="earlytimeHolder"
                            format="HH:mm"
                            
                            input-class="time-picker"
                            ref="earlytime"
                            @input="earlytime"
                            :disabled="radiogroup == 'fs' || radiogroup == 'fh'"
                            input-width="100%"
                        ></vue-timepicker>    
                    </v-col>
                </v-row>
               <br>
               <br>
                <v-row>
                    
                    <v-col cols="3" sm="3" md="3" class="mt-n8"> {{$t('setupforshift.otstarttime')}}
                        <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            v-model="otStartHolder"
                            format="HH:mm"
                            
                            input-class="time-picker"
                            ref="otstart"
                            @input ="otstart"
                            :disabled="radiogroup == 'fs' || radiogroup == 'fh'"
                            input-width="100%"
                        ></vue-timepicker>    
                    </v-col>
                    

                        <v-col cols="3" sm="3" md="3" class="mt-n8"> {{$t('setupforshift.earlyotstarttime')}}
                            <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            v-model="earlyOTStartHolder"
                            format="HH:mm"
                           
                            input-class="time-picker"
                            ref="earlyotstart"
                            @input="earlyotstart"
                            :disabled="radiogroup == 'fs' || radiogroup == 'fh'"
                            input-width="100%"
                        ></vue-timepicker>    
                    </v-col>
                </v-row>
        
                <br>
                <br>
                <br>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.breaktimeinminutes')"
                                type="text"
                                ref="breaktime"
                                v-model="formData.breaktime"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.otbufferinminutes')"
                                type="text"
                                ref="otbuffer"
                                v-model="formData.otbuffer"
                                @input="updateValue"
                                :disabled="radiogroup == 'ft'"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.shifthours')"
                                type="text"
                                ref="shifthours"
                                v-model="formData.shifthours"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'ft'"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.othours')"
                                type="text"
                                ref="otinshift"
                                v-model="formData.otinshift"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'fs' || radiogroup == 'fh'"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isbreakpaid"
                                v-model="formData.isbreakpaid"
                                :label="this.$t('setupforshift.paidbreaktime')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                     
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="earlyot"
                                v-model="formData.earlyot"
                                :label="this.$t('setupforshift.earlyOT')"
                                @change="updateValue"
                                :disabled="radiogroup == 'fh'"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isotpreapproved"
                                v-model="formData.isotpreapproved"
                                :label="this.$t('setupforshift.otneedspreapproval')"
                                @change="updateValue"
                                :disabled="radiogroup == 'fh'"
                            ></v-checkbox>
                        </v-col>
                     </v-row> 

                    <v-row>
                        <v-col cols="6" sm="2" class="blue--text ml-2">
                            {{$t('setupforshift.incentives')}}
                        </v-col>
                    </v-row>

                    <v-card class="grey lighten-3">
                        <v-row>
                            <v-col cols="12" sm="8" class="blue--text ml-2">
                                {{$t('setupforshift.shiftallowance')}}
                                <v-row>
                                <v-col cols="12" sm="6" class="pb-n4 mt-2">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupforshift.shiftallowance')"
                                    type="text"
                                    ref ="sftallwpayitemdesc"
                                    :items="shiftAllowanceList"
                                    v-model="formData.sftallwpayitemid"
                                    background-color="white"
                                    :rules="[rules.required]"
                                    @change="changeShiftAllowanceHandler(formData.sftallwpayitemid)"
                                    dense
                                ></v-autocomplete>
                             </v-col>

                            <v-col cols="4" sm="4" class="pb-n4 mt-2">
                                <v-text-field
                                     outlined
                                     background-color="#ffeab2"
                                     readonly
                                     :label="this.$t('columns.code')"
                                     ref = "shiftallwcode"
                                     type="text"
                                     v-model="shiftallwcode"
                                     dense
                                 ></v-text-field>
                               
                                </v-col>

                                </v-row>
                            </v-col>
                        </v-row>

                        
                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                <v-checkbox
                                    ref="isfullshift"
                                    v-model="formData.isfullshift"
                                    :label="this.$t('setupforshift.mustfulfillshifthours')"
                                    @change="updateValue"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card>
                    <br>
                    <br>

                     <v-card class="grey lighten-3">
                        <v-row>
                            <v-col cols="12" sm="6" class="blue--text ml-2">
                                {{$t('setupforshift.otallowance')}}
                                <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-2">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupforshift.otallowance')"
                                    type="text"
                                    v-model="formData.otallwpayitemid"
                                    :items="OTAllowanceList"
                                    :rules="[rules.required]"
                                    @change="changeOTAllowanceHandler(formData.otallwpayitemid)"
                                    background-color="white"
                                    dense
                                ></v-autocomplete>
                             </v-col>

                             <v-col cols="4" sm="4" class="pb-n4 mt-2">
                                <v-text-field
                                    outlined
                                    v-model="otallwcode"
                                    type="text"
                                    ref= "otallwcode"
                                    :label="this.$t('columns.code')"
                                    readonly
                                    background-color="#ffeab2"
                                    dense
                                ></v-text-field>
                             </v-col>
                        </v-row>

                        <v-row>

                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupforshift.minumumothours')"
                                type="text"
                                ref="minothours"
                                v-model="formData.minothours"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                                background-color="white"
                            ></v-text-field>
                        </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                <v-checkbox
                                    ref="onnorm"
                                    v-model="formData.onnorm"
                                    :label="this.$t('setupforshift.normal')"
                                    @change="updateValue"
                                ></v-checkbox>
                            </v-col>

                            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                <v-checkbox
                                    ref="onoff"
                                    v-model="formData.onoff"
                                    :label="this.$t('setupforshift.off')"
                                    @change="updateValue"
                                ></v-checkbox>
                            </v-col>

                            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                <v-checkbox
                                    ref="onrest"
                                    v-model="formData.onrest"
                                    :label="this.$t('setupforshift.rest')"
                                    @change="updateValue"
                                ></v-checkbox>
                            </v-col>

                            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                <v-checkbox
                                    ref="onhol"
                                    v-model="formData.onhol"
                                    :label="this.$t('setupforshift.holiday')"
                                    @change="updateValue"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    </v-row>
                    </v-card>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
//import moment from 'moment';
import VueTimepicker from "vue2-timepicker";
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object,
        timeHolder: Object
    },

    components: {
        //'app-genericform': GenericForm,
        "vue-timepicker": VueTimepicker,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {
                shiftcode: this.parentObject.data.shiftcode,
                shiftdesc: this.parentObject.data.shiftdesc,
                fixed: this.parentObject.data.fixed,
                staggered: this.parentObject.data.staggered,
                flexi: this.parentObject.data.flexi,
                starttime: this.parentObject.data.starttime,
                endtime: this.parentObject.data.endtime,
                latetime: this.parentObject.data.latetime,
                earlytime: this.parentObject.data.earlytime,
                otstart: this.parentObject.data.otstart,
                earlyotstart: this.parentObject.data.earlyotstart,
                breaktime: this.parentObject.data.breaktime,
                otbuffer: this.parentObject.data.otbuffer,
                shifthours: this.parentObject.data.shifthours,
                otinshift: this.parentObject.data.otinshift,
                isbreakpaid: this.parentObject.data.isbreakpaid,
                isotpreapproved: this.parentObject.data.isotpreapproved,
                earlyot: this.parentObject.data.earlyot,
                sftallwpayitemid: this.parentObject.data.sftallwpayitemid,
                sftallwpayitemdesc : this.parentObject.data.sftallwpayitemdesc,
                otallwpayitemid: this.parentObject.data.otallwpayitemid,
                otallwpayitemdesc: this.parentObject.data.otallwpayitemdesc,
                isfullshift: this.parentObject.data.isfullshift,
                minothours: this.parentObject.data.minothours,
                onnorm: this.parentObject.data.onnorm,
                onoff: this.parentObject.data.onoff,
                onrest: this.parentObject.data.onrest, 
                onhol:this.parentObject.data.onhol,   
                payitemcode: this.parentObject.data.payitemcode,  
                search: this.parentObject.data.search,          
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            starttimeHolder: this.timeHolder.starttime,
            endtimeHolder: this.timeHolder.endtime,
            latetimeHolder: this.timeHolder.latetime,
            earlytimeHolder: this.timeHolder.earlytime,
            otStartHolder: this.timeHolder.otstart,
            earlyOTStartHolder: this.timeHolder.earlyotstart,
   

            shiftallwcode: '',
            otallwcode: '',
            radiogroup: null,
            shiftAllowanceList: [],
            shiftAllowanceItems: [],
            OTAllowanceList: [],
            OTAllowanceItems: [],
            
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew

        }
    },

    methods: {

         changeShiftHandler(item){
             if (item == 'ft'){
                 this.formData.fixed = true
                 this.formData.staggered = false
                 this.formData.flexi = false
                 this.formData.starttime = null
                 this.formData.endtime = null
                 this.formData.latetime = null
                 this.formData.earlytime = null
                 this.formData.otstart = null
                 this.formData.otbuffer = null
                 this.formData.earlyotstart = null
                 this.formData.otinshift = null

            }

             if (item == 'fs'){
                 this.formData.fixed = false
                 this.formData.staggered = true
                 this.formData.flexi = false
                 
                 this.formData.starttime = null
                 this.formData.endtime = null
                 this.formData.latetime = null
                 this.formData.earlytime = null
                 this.formData.otstart = null
                 this.formData.earlyotstart = null
                 this.formData.breaktime = null
                 this.formData.otbuffer = null
                 this.formData.shifthours = null
                 
                // this.starttimeHolder = ""
                // this.endtimeHolder = ""
                 this.latetimeHolder = ""
                 this.earlytimeHolder = ""
                 this.otStartHolder = ""
                 this.earlyOTStartHolder = ""
             }

             if (item == 'fh'){
                 this.formData.fixed = false
                 this.formData.staggered = false
                 this.formData.flexi = true
                 this.formData.starttime = null
                 this.formData.endtime = null
                 this.formData.latetime = null
                 this.formData.earlytime = null
                 this.formData.otstart = null
                 this.formData.earlyotstart = null
                 this.starttimeHolder = ""
                 this.endtimeHolder = ""
                 this.latetimeHolder = ""
                 this.earlytimeHolder = ""
                 this.otStartHolder = ""
                 this.earlyOTStartHolder = ""
                 this.formData.breaktime = null
                 this.formData.otbuffer = null
                 this.formData.shifthours = null
            }

            this.updateValue();

         },

          changeRadioHandler() {
            if (this.formData.fixed == true && this.formData.staggered == false && this.formData.flexi == false) {
                this.radiogroup = 'ft'
            }
            if (this.formData.fixed == false && this.formData.staggered == true && this.formData.flexi == false) {
                this.radiogroup = 'fs'
            }
            if (this.formData.fixed == false && this.formData.staggered == false && this.formData.flexi == true) {
                this.radiogroup = 'fh'
            }
        },

        
        changeShiftAllowance() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/shiftallowance/all'
            })
            .then(res => {

                vm.shiftAllowanceItems = res.data.rec;

                for (let i = 0; i < vm.shiftAllowanceItems.length; i++) {
                    vm.shiftAllowanceList.push({
                        text: vm.shiftAllowanceItems[i].payitemdesc,
                        value: vm.shiftAllowanceItems[i].id,
                    });
                }
                if (this.formData.sftallwpayitemid) {
                this.changeShiftAllowanceHandler(this.formData.sftallwpayitemid);
                }

                this.changeRadioHandler()
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeShiftAllowanceHandler(id) {
            var item = this.shiftAllowanceItems.find(item => item.id === id);
            this.shiftallwcode = item.payitemcode;
            this.sftallwpayitemid = item.payitemdesc;
        },

        changeOTAllowance() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/otallowance/all'
            })
            .then(res => {

                vm.OTAllowanceItems = res.data.rec;

                for (let i = 0; i < vm.OTAllowanceItems.length; i++) {
                    vm.OTAllowanceList.push({
                        text: vm.OTAllowanceItems[i].payitemdesc,
                        value: vm.OTAllowanceItems[i].id,
                    });
                }

                if (this.formData.otallwpayitemid) {
                this.changeOTAllowanceHandler(this.formData.otallwpayitemid);
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeOTAllowanceHandler(id) {
            var item = this.OTAllowanceItems.find(item => item.id === id);
            this.otallwcode = item.payitemcode;
        },

        timeConversion(val){
                let timeHolder = ""
                timeHolder = val.HH + ':' + val.mm
                return timeHolder

        },

        starttime(){

            if(this.starttimeHolder.HH && this.starttimeHolder.mm){
                this.formData.starttime = this.timeConversion(this.starttimeHolder)
            }
        },


        endtime(){
             if(this.endtimeHolder.HH && this.endtimeHolder.mm){
            this.formData.endtime = this.timeConversion(this.endtimeHolder)
            }
        },

        latetime(){
             if(this.latetimeHolder.HH && this.latetimeHolder.mm){
                this.formData.latetime = this.timeConversion(this.latetimeHolder)
            }
        },

        earlytime(){
            if(this.earlytimeHolder.HH && this.earlytimeHolder.mm){
            this.formData.earlytime = this.timeConversion(this.earlytimeHolder)
            }
        },

        otstart(){
            
             if(this.otStartHolder.HH && this.otStartHolder.mm){
                this.formData.otstart = this.timeConversion(this.otStartHolder)
            }
        },

        earlyotstart(){
             if(this.earlyOTStartHolder.HH && this.earlyOTStartHolder.mm){
                this.formData.earlyotstart = this.timeConversion(this.earlyOTStartHolder)
            }
        },
        

        updateValue() {
         
           console.log('formData', this.formData)
            this.$emit('input', {
                
                shiftcode: this.formData.shiftcode,
                shiftdesc: this.formData.shiftdesc,
                fixed: this.formData.fixed,
                staggered: this.formData.staggered,
                flexi: this.formData.flexi,
                starttime: this.formData.starttime,
                endtime: this.formData.endtime,
                latetime: this.formData.latetime,
                earlytime: this.formData.earlytime,
                otstart: this.formData.otstart,
                earlyotstart: this.formData.earlyotstart,
                breaktime: this.formData.breaktime,
                otbuffer: this.formData.otbuffer,
                shifthours: this.formData.shifthours,
                otinshift: this.formData.otinshift,
                isbreakpaid: this.formData.isbreakpaid,
                isotpreapproved: this.formData.isotpreapproved,
                earlyot: this.formData.earlyot,
                sftallwpayitemid: this.formData.sftallwpayitemid,
                otallwpayitemid: this.formData.otallwpayitemid,
                isfullshift: this.formData.isfullshift,
                minothours: this.formData.minothours,
                onnorm: this.formData.onnorm,
                onoff: this.formData.onoff,
                onrest: this.formData.onrest, 
                onhol:this.formData.onhol,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'shiftsetuplist', params: { search: this.search}});
        },

        // check(){
        //     console.log('timeHolder', this.starttimeHolder)
        //    // console.log('starttime', this.formData.starttime)
        //   //  console.log('formdata', this.formData)
        //     console.log('parentobject', this.parentObject.data)
        //     console.log('123', this.timeHolder)
        // },
    },

    mounted() {
        this.changeShiftAllowance();
        this.changeOTAllowance();
       // this.check();
     
    }


    
}
</script>