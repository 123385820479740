<template>
    <div>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-row>
              <v-col cols="6" sm="6" class="pb-n5">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.corporateid')"
                  type="text"
                  v-model="formData.corporateid"
                  maxlength="30"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.payerrefnumber')"
                  type="text"
                  v-model="formData.payerrefnumber"
                  maxlength="20"
                  dense
                ></v-text-field>
              </v-col>
              
              <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.debitdescription')"
                  type="text"
                  v-model="formData.debitdescription"
                  maxlength="20"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.recipientref')"
                  type="text"
                  v-model="formData.recipientref"
                  maxlength="20"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('claimlimitadjustmentrecord.valuedate')"
                  type="date"
                  v-model="formData.valuedate"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="exportHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
        },
        pageTitle: 'Affin Bank',
  
        parameter: this.parentObject.parameter,
        id: this.parentObject.id,
        data: this.parentObject.data,
        formData: {
          recref: '',
          corporateid: '',
          payerrefnumber: '',
          debitdescription: '',
          recipientref: '',
          valuedate: ''
        },
        fileHolder: null,
        file: null,
  
        paymentModeList: ['IT', 'IG'],
        bank: [
          {
            id: 1,
            defaultcode: "RBSB",
            bankcode: "ABNAMYKL",
            bankdesc: "THE ROYAL BANK OF SCOTLAND BERHAD",
            bankgencode: 35
          },
          {
            id: 2,
            defaultcode: "AFB",
            bankcode: "AFBQMYKL",
            bankdesc: "MBSB BANK BERHAD",
            bankgencode: 37
          },
          {
            id: 3,
            defaultcode: "AGRO",
            bankcode: "AGOBMYKL",
            bankdesc: "AGROBANK",
            bankgencode: 2
          },
          {
            id: 4,
            defaultcode: "ABB",
            bankcode: "AISLMYKL",
            bankdesc: "AMISLAMIC BANK BHD",
            bankgencode: 39
          },
          {
            id: 5,
            defaultcode: "AIBMB",
            bankcode: "ALSRMYKL",
            bankdesc: "ALLIANCE ISLAMIC BANK BHD",
            bankgencode: 95
          },
          {
            id: 6,
            defaultcode: "AMBANK",
            bankcode: "ARBKMYKL",
            bankdesc: "AMBANK BHD",
            bankgencode: 5
          },
          {
            id: 7,
            defaultcode: "BIM",
            bankcode: "BIMBMYKL",
            bankdesc: "BANK ISLAM MALAYSIA BERHAD",
            bankgencode: 7
          },
          {
            id: 8,
            defaultcode: "BCMB",
            bankcode: "BKCHMYKL",
            bankdesc: "BANK OF CHINA (MALAYSIA) BHD",
            bankgencode: 10
          },
          {
            id: 9,
            defaultcode: "BANGKOK",
            bankcode: "BKKBMYKL",
            bankdesc: "BANGKOK BANK BERHAD",
            bankgencode: 6
          },
          {
            id: 10,
            defaultcode: "BKRMB",
            bankcode: "BKRMMYKL",
            bankdesc: "BANK KERJASAMA RAKYAT",
            bankgencode: 40
          },
          {
            id: 11,
            defaultcode: "MUAM",
            bankcode: "BMMBMYKL",
            bankdesc: "BANK MUAMALAT MALAYSIA BHD",
            bankgencode: 8
          },
          {
            id: 12,
            defaultcode: "BNPPARIBAS",
            bankcode: "BNPAMYKL",
            bankdesc: "BNP PARIBAS MALAYSIA BERHAD",
            bankgencode: 14
          },
          {
            id: 13,
            defaultcode: "BAMB",
            bankcode: "BOFAMY2X",
            bankdesc: "BANK OF AMERICA (MALAYSIA) BERHAD",
            bankgencode: 9
          },
          {
            id: 14,
            defaultcode: "BTMMB",
            bankcode: "BOTKMYKX",
            bankdesc: "BANK OF TOKYO",
            bankgencode: 11
          },
          {
            id: 15,
            defaultcode: "BSN",
            bankcode: "BSNAMYK1",
            bankdesc: "BANK SIMPANAN NASIONAL BERHAD",
            bankgencode: 13
          },
          {
            id: 16,
            defaultcode: "JPMCBB",
            bankcode: "CHASMYKX",
            bankdesc: "J.P MORGAN CHASE BANK BERHAD",
            bankgencode: 24
          },
          {
            id: 17,
            defaultcode: "CIMB",
            bankcode: "CIBBMYKL",
            bankdesc: "CIMB BANK BERHAD",
            bankgencode: 16
          },
          {
            id: 18,
            defaultcode: "CITI",
            bankcode: "CITIMYKL",
            bankdesc: "CITIBANK",
            bankgencode: 17
          },
          {
            id: 19,
            bankcode: "CITIMYM1",
            bankdesc: "CITIBANK - SPI",
            bankgencode: 64
          },
          {
            id: 20,
            defaultcode: "CIMBIBB",
            bankcode: "CTBBMYKL",
            bankdesc: "CIMB ISLAMIC BANK BHD",
            bankgencode: 41
          },
          {
            id: 21,
            bankcode: "DEUTMY21",
            bankdesc: "DEUTSCHE BANK SPI",
            bankgencode: 65
          },
          {
            id: 22,
            defaultcode: "DBMB",
            bankcode: "DEUTMYKL",
            bankdesc: "DEUTSCHE BANK (MALAYSIA) BHD",
            bankgencode: 18
          },
          {
            id: 23,
            defaultcode: "EON",
            bankcode: "EOBBMYKL",
            bankdesc: "EON BANK",
            bankgencode: 19
          },
          {
            id: 24,
            defaultcode: "HSBC",
            bankcode: "HBMBMYKL",
            bankdesc: "HSBC BANK MALAYSIA BHD",
            bankgencode: 21
          },
          {
            id: 25,
            defaultcode: "HLBB",
            bankcode: "HLBBMYKL",
            bankdesc: "HONG LEONG BANK",
            bankgencode: 20
          },
          {
            id: 26,
            defaultcode: "HLIBB",
            bankcode: "HLIBMYKL",
            bankdesc: "HONG LEONG ISLAMIC BANK BHD",
            bankgencode: 42
          },
          {
            id: 27,
            defaultcode: "ICBCMB",
            bankcode: "ICBKMYKL",
            bankdesc: "IND & COMM BANK OF CHINA",
            bankgencode: 23
          },
          {
            id: 28,
            defaultcode: "KFHM",
            bankcode: "KFHOMYKL",
            bankdesc: "KUWAIT FINANCE HOUSE (MALAYSIA) BERHAD",
            bankgencode: 25
          },
          {
            id: 29,
            defaultcode: "MBB",
            bankcode: "MBBEMYKL",
            bankdesc: "MALAYAN BANKING",
            bankgencode: 26
          },
          {
            id: 30,
            defaultcode: "ABMB",
            bankcode: "MFBBMYKL",
            bankdesc: "ALLIANCE BANK MALAYSIA BHD",
            bankgencode: 4
          },
          {
            id: 31,
            defaultcode: "MBMB",
            bankcode: "MHCBMYKA",
            bankdesc: "MIZUHO BANK (MALAYSIA) BERHAD",
            bankgencode: 27
          },
          {
            id: 32,
            defaultcode: "OCBC",
            bankcode: "OCBCMYKL",
            bankdesc: "OCBC BANK",
            bankgencode: 29
          },
          {
            id: 33,
            defaultcode: "PBB",
            bankcode: "PBBEMYKL",
            bankdesc: "PUBLIC BANK",
            bankgencode: 30
          },
          {
            id: 34,
            defaultcode: "CCBMB",
            bankcode: "PCBCMYKL",
            bankdesc: "CHINA CONSTRUCTION BANK (MALAYSIA) BERHAD",
            bankgencode: 15
          },
          {
            id: 35,
            bankcode: "PIBEMYK1",
            bankdesc: "PUBLIC ISLAMIC BANK BHD",
            bankgencode: 87
          },
          {
            id: 36,
            defaultcode: "RHB",
            bankcode: "RHBBMYKL",
            bankdesc: "RHB BANK",
            bankgencode: 31
          },
          {
            id: 37,
            defaultcode: "ALRAJHI",
            bankcode: "RJHIMYKL",
            bankdesc: "AL-RAJHI BANK & INV CORP BHD",
            bankgencode: 3
          },
          {
            id: 38,
            defaultcode: "SCB",
            bankcode: "SCBLMYKX",
            bankdesc: "STANDARD CHARTERED BANK",
            bankgencode: 32
          },
          {
            id: 39,
            defaultcode: "SMBCMB",
            bankcode: "SMBCMYKL",
            bankdesc: "SUMITOMO MITSUI BANK BHD",
            bankgencode: 33
          },
          {
            id: 40,
            defaultcode: "UOB",
            bankcode: "UOVBMYKL",
            bankdesc: "UNITED OVERSEAS BANK BERHAD",
            bankgencode: 36
          },
        ],
  
        staffilterlist: [],
        staffaccount: [],
        companyaccount: [],
        psnpayroll: [],
        titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
        payrolldata: [],
  
        companyrecnum: "",
        paymentmode: null,
        bankcode: null,
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
        filler: "",
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
  
        currency: null,
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
  
      getAffinbankExportListHandler() {
        const vm = this
        
        this.$store.dispatch('get', {url: `/affinbanksalexport/all/${this.$store.getters.userId}`})
        .then(res => {
          let dateHolder = vm.data.year + "-" + 
                           vm.padRJ(vm.data.month, 2) + "-" + 
                           vm.daysInMonth(vm.data.month, vm.data.year);
            
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let payrolldataholder = res.data.rec;
          payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)
  
          payrolldataholder.forEach(function (o) {
            var existing = vm.payrolldata.filter(function (i) {
              return i.staffid === o.staffid;
            })[0];
            if (!existing || existing == undefined) {
              if (o.bankid) {
                if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                      vm.payrolldata.push(o);
                }
              } else {
                vm.payrolldata.push(o);
              }
            }
          });
          console.log('payrolldataholder', payrolldataholder)
          this.loadStaffFilter()
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      loadStaffFilter() {
        const vm = this;
  
        this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
          .then(res => {
              vm.staffilterlist = res.data.rec;
              let checkval = false;
              for (const key in this.parameter) {
                if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
              }
  
              if (checkval) {
                vm.payrolldata = vm.payrolldata.filter((el) => {
                    return vm.staffilterlist.some((f) => {
                        return f.staffid === el.staffid;
                    });
                });
              }
              
          })
          .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
          });
      },
  
      async getData() {
        const vm = this
        let body = []
        let holdpayment = []
        // let bankaccount = {}
        let dataholder = vm.payrolldata
        // let recordtypebody = '02'
        let transactiontype = '2'
        let totalcount = 0

        let payrolldetail = this.filter(dataholder);

        this.processedCount = dataholder.length;
        this.successCount = payrolldetail.length;
        this.errCount = dataholder.length - payrolldetail.length;
        this.currency = payrolldetail[0].exchratecode;

        payrolldetail.forEach(element => {
          // let validationvalue = ''
          let paymode = ''
          let residentindicator = ''
          let residentcountry = ''
          // let hpnoholder = ''
          
          let bankcodeacc = ''
          let bankcodeholder = vm.bank.find(e => e.defaultcode == element.bankcode)
          bankcodeholder == undefined ? bankcodeacc = '' : bankcodeacc = bankcodeholder.bankcode
          // bankaccount = vm.bank.find(e => e.id == element.bankid)

          // if (element.iddetails != null) validationvalue = element.iddetails.split("-").join("")
          // let staffbankacc = element.staffaccountno.split("-").join("")
          // let psnnameHolder = element.psnname.substring(0,40)

          // let netpayholder = element.netpay.toFixed(2);
          // netpayholder = netpayholder.split(".").join("");
          // if (bankaccount.id == 1) bankaccount.bankcode = 'AFFIN'

          // body.push({
          //   "Record Type": this.padRJ(recordtypebody, 2),
          //   "BNM Code": this.padLJ(bankaccount.bnmcode, 7),
          //   "Account Number": this.padSpaceRJ(staffbankacc, 16),
          //   "Beneficiary Name": this.padSpaceRJ(psnnameHolder, 40),
          //   "Payment Amount": this.padRJ(netpayholder, 11),
          //   "Reference Number": this.padSpaceRJ(element.staffcode, 30),
          //   "Beneficiary ID": this.padSpaceRJ(validationvalue, 20),
          //   "Transaction Type": this.padSpaceRJ(transactiontype,1),
          //   "Payment Description": this.padSpaceRJ(this.formData.paymentdesc, 20)
          // });
          let customerrefnumber = totalcount + 1;
          element.bankcode == 'AFFIN' ? paymode = 'IT' : paymode = 'IG'
          if (element.ntlycode == 'MY' ) {
            residentindicator = 'Y'
            residentcountry = element.ntlycode
          } else {
            residentindicator = 'N'
            residentcountry = element.ntlycode
          }

          for (const key in element) {
            if (element[key] == null) element[key] = ''
          }

          // hpnoholder = element.hpno

          let nameholder = element.accountname.slice(0, 30)

          body.push({
            "Record Type": this.padSpaceRJ('01', 0) + ',',
            "Payment Mode": this.padSpaceRJ(paymode, 0) + ',',
            "Provide Product Group": this.padSpaceRJ('Auto Pay', 0) + ',',
            "Destination Country": this.padSpaceRJ(element.ntlycode, 0) + ',',
            "Value Date": this.padSpaceRJ(moment(this.formData.valuedate).format('DDMMYYYY'), 0) + ',',
            "Customer Reference Number": this.padSpaceRJ(customerrefnumber, 0) + ',',
            "Payer Ref No": this.padSpaceRJ(this.formData.payerrefnumber, 0) + ',',
            "Debit Description": this.padSpaceRJ(this.formData.debitdescription, 0) + ',',
            "Transaction Currency": this.padSpaceRJ('MYR', 0) + ',',
            "Transaction Amount": this.padSpaceRJ(element.netpay.toFixed(2), 0) + ',',
            "Is In Debit Account Currency": this.padSpaceRJ('N', 0) + ',',
            "Debiting Currency": this.padSpaceRJ('MYR', 0) + ',',
            "Debiting Account Number": this.padSpaceRJ(element.accountno, 0) + ',',
            "Benificiary Account Number": this.padSpaceRJ(element.staffaccountno.split("-").join(""), 0) + ',',
            "Favourite Benificiary": this.padSpaceRJ('', 0) + ',',
            "Benificiary Name 1": this.padSpaceRJ(nameholder, 0) + ',',
            "Benificiary Name 2": this.padSpaceRJ('', 0) + ',',
            "Benificiary Name 3": this.padSpaceRJ('', 0) + ',',
            "Resident Indicator": this.padSpaceRJ(residentindicator, 0) + ',',
            "Resident Country": this.padSpaceRJ(residentcountry, 0) + ',',
            "Benificiary Bank Code": this.padSpaceRJ(bankcodeacc, 0) + ',',
            "Benificiary Bank Branch": this.padSpaceRJ('', 0) + ',',
            "Recipient Reference": this.padSpaceRJ(this.formData.recipientref, 0) + ',',
            "Other Payment Detail": this.padSpaceRJ('', 0) + ',',
            "Payment Details 1": this.padSpaceRJ('', 0) + ',',
            "Payment Details 2": this.padSpaceRJ('', 0) + ',',
            "Benificiary Contact Number": this.padSpaceRJ('', 0) + ',', 
            "ID Checking Required": this.padSpaceRJ('Y', 0) + ',',
            "Benificiary ID Type": this.padSpaceRJ(element.idtypeid, 0) + ',',
            "Benificiary ID No.": this.padSpaceRJ(element.iddetails.replace(/-/g, ''), 0) + ',',
            "Beneficiary Address 1": this.padSpaceRJ(element.correspondanceaddr1, 0) + ',',
            "Beneficiary Address 2": this.padSpaceRJ(element.correspondanceaddr2, 0) + ',',
            "Beneficiary Address 3": this.padSpaceRJ(element.correspondanceaddr3, 0) + ',',
            "Beneficiary Country": this.padSpaceRJ('', 0) + ',',
            "Beneficiary State/Province": this.padSpaceRJ('', 0) + ',',
            "Beneficiary State/Province Text Box": this.padSpaceRJ('', 0) + ',',
            "Beneficiary Prefecture": this.padSpaceRJ('', 0) + ',',
            "Beneficiary Prefecture Text Box": this.padSpaceRJ(transactiontype, 0) + ',',
            "Beneficiary City": this.padSpaceRJ('', 0) + ',',
            "Beneficiary City Text Box": this.padSpaceRJ('', 0) + ',',
            "Beneficiary ZIP/Postal Code": this.padSpaceRJ('', 0) + ',',
            "National Clearing Code": this.padSpaceRJ('', 0) + ',',
            "Joint Name": this.padSpaceRJ('', 0) + ',',
            "Joint Name - ID Checking Required": this.padSpaceRJ('', 0) + ',',
            "Joint Name - ID Type": this.padSpaceRJ('', 0) + ',',
            "Joint Name - ID No.": this.padSpaceRJ('', 0) + ',',
            "Intermediary Bank Code": this.padSpaceRJ('', 0) + ',',
            "Intermediary Bank Account No.": this.padSpaceRJ('', 0) + ',',
            "Intermediary Bank Country": this.padSpaceRJ('', 0) + ',',
            "Intermediary Bank Other Codes Value": this.padSpaceRJ('', 0) + ',',
            "FX Mode": this.padSpaceRJ('', 0) + ',',
            "Contract Ref. No. (Filler)": this.padSpaceRJ('', 0) + ',',
            "Applicant Business Registration Number": this.padSpaceRJ('', 0) + ',',
            "Applicant Address 1": this.padSpaceRJ('', 0) + ',',
            "Applicant Address 2": this.padSpaceRJ('', 0) + ',',
            "Applicant Address 3": this.padSpaceRJ('', 0) + ',',
            "Applicant Country": this.padSpaceRJ('', 0) + ',',
            "Applicant State/Province": this.padSpaceRJ('', 0) + ',',
            "Applicant State/Province Text Box": this.padSpaceRJ('', 0) + ',',
            "Applicant Prefecture": this.padSpaceRJ('', 0) + ',',
            "Applicant Prefecture Text Box": this.padSpaceRJ('', 0) + ',',
            "Applicant City": this.padSpaceRJ('', 0) + ',',
            "Applicant City Text Box": this.padSpaceRJ('', 0) + ',',
            "Applicant ZIP/Postal Code": this.padSpaceRJ('', 0) + ',',
            "Charges Borne By": this.padSpaceRJ('01', 0) + ',',
            "Remitter and Beneficiary Relationship": this.padSpaceRJ('02', 0) + ',',
            "Purpose code/ Declaration of Purpose": this.padSpaceRJ('', 0) + ',',
            "Purpose of Transfer": this.padSpaceRJ('', 0) + ',',
            "Others Purpose of Transfer": this.padSpaceRJ('', 0) + ',',
            "Purpose Description": this.padSpaceRJ('', 0) + ',',
            "Filler 1": this.padSpaceRJ('', 0) + ',',
            "Filler 2": this.padSpaceRJ('', 0) + ',',
            "Filler 3": this.padSpaceRJ('', 0) + ',',
            "Filler 4": this.padSpaceRJ('', 0) + ',',
            "Filler 5": this.padSpaceRJ('', 0) + ',',
            "Filler 6": this.padSpaceRJ('', 0) + ',',
            "Filler 7": this.padSpaceRJ('', 0) + ',',
            "Filler 8": this.padSpaceRJ('', 0) + ',',
            "Filler 9": this.padSpaceRJ('', 0) + ',',
            "Filler 10": this.padSpaceRJ('', 0) + ',',
            "Filler 12": this.padSpaceRJ('', 0) + ',',
            "Filler 13": this.padSpaceRJ('', 0) + ',',
            "Filler 14": this.padSpaceRJ('', 0) + ',',
            "Returned Status": this.padSpaceRJ('', 0) + ',',
            "Returned Reason": this.padSpaceRJ('', 0) + ',',
            "Return Status Date": this.padSpaceRJ('', 0) + ',',
            "Processing Date": this.padSpaceRJ('', 0) + ',',
            "Txn Ref No OR Stop/Reject Reason": this.padSpaceRJ('', 0) + ',',
            "Transaction Return Status": this.padSpaceRJ('', 0) + ','
          });

          holdpayment.push(element.netpay)
          totalcount++
        });

        if (holdpayment) {
          let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
          this.gettotalAmt = totalAmt.toFixed(2);
        }

        let header =
          '00' + ',' +
          this.padSpaceRJ(this.formData.corporateid, 2) + ',' +
          this.padSpaceRJ('01', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' ;

        let footer = 
          '99' + ',' +
          this.padSpaceRJ(totalcount, 0) + ',' +
          this.padSpaceRJ(this.gettotalAmt, 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' ;
          
        if (body.length > 0) {
          const txtData = this.objectToTxt(header, body, footer);
          this.download(txtData, this.titleData);
        }
      },
  
      filter(item) {
        let checkacc = this.accfilter(item)
        let checkaccbank = this.accBankfilter(checkacc)
        let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
        let checkactive = this.activefilter(checkapaymentmethod)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      accfilter(item) {
        let data = item.filter(e => e.bankid)
        let noacc = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noacc.length; i++) {
          noacc[i].errormsg = this.$t('allbank.noaccfound');
          this.accErr.push(noacc[i]);
        }
        return data
      },
  
      accBankfilter(item) {
        let data = item.filter(e => {
          for (let i = 0; i < this.bank.length; i++) {
            if (this.bank[i].id == e.bankid) return e
          }
        })
        let noaccbank = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noaccbank.length; i++) {
          noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
          this.accErr.push(noaccbank[i]);
        }
        return data
      },
  
      paymentmethodfilter(item) {
        let data = item.filter(e => e.paymodecode == 'BANK')
        let diffpayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < diffpayment.length; i++) {
          diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
          this.accErr.push(diffpayment[i]);
        }
        return data
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => e.netpay > 0)
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToTxt(header, data, footer) {
        const csvRows = [];
  
        const dataheaders = Object.keys(data[0]);
  
        csvRows.push(header);
  
        //loop over the rows
        for (const row of data) {
          const values = dataheaders.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          csvRows.push(values.join(""));
        }
        
        csvRows.push(footer);
        return csvRows.join("\n");
      },
  
      download(data, title) {
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalAmt = this.gettotalAmt;
          item.accounterr = this.accErr;
          item.negErrMss = this.negErrMsg;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
          item.currency = this.currency;
          this.$router.push({ name: "salaryexportlog", params: { item } });
        }
      },
  
      backHandler() {
        let param = this.parameter
  
        this.$router.push({ name: "salaryexport", params: { param } });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
    mounted() {
      this.getAffinbankExportListHandler();
    },
  };
  </script>