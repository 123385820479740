<template>
  <div id="menu">
    <v-treeview activatable dense hoverable :items="items">
      <template slot="label" slot-scope="props">
        <router-link :to="{ name: props.item.to }" v-if="props.item.to">{{
          props.item.name
        }}</router-link>
        <span v-else>{{ props.item.name }}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: "1", name: this.$t('settings'), children: 
          [
            { id: "1.1", name: this.$t('subtitles.systemsetup'), children: 
              [
                { id: "1.1.0", name: this.$t('columns.company'), to: "companylist" },
                { id: "1.1.1", name: this.$t('addressmaster'), to: "addresslist" },
                { id: "1.1.2", name: this.$t('columns.branch'), to: "branchlist" },
                { id: "1.1.3", name: this.$t('columns.division'), to: "divisionlist" },
                { id: "1.1.4", name: this.$t('columns.department'), to: "departmentlist" },
                { id: "1.1.5", name: this.$t('columns.section'), to: "sectionlist" },
                { id: "1.1.6", name: this.$t('columns.unit'), to: "unitlist" },
                { id: "1.1.7", name: this.$t('columns.employmenttype'), to: "emptypelist" },
                { id: "1.1.8", name: this.$t('columns.grade'), to: "gradelist" },
                { id: "1.1.9", name: this.$t('columns.job'), to: "joblist" },
                { id: "1.1.10", name: this.$t('columns.position'), to: "positionlist" },
                { id: "1.1.11", name: this.$t('servicerectype'),to: "servicerecordlist" },
                { id: "1.1.12", name: this.$t('salfreq'), to: "basicfreqlist" },
                { id: "1.1.13", name: this.$t('columns.group'), to: "grouplist" },
                { id: "1.1.14", name: this.$t('setupgrp.grpvalidval'), to: "valvaluelist" },
                { id: "1.1.15", name: this.$t('passwordpolicy'), to: "passwordpolicylist" },           
                { id: "1.1.16", name: this.$t('wizardsetup'), to: "wizardlist" },
                { id: "1.1.17", name: this.$t('alert'), to: "alertlist" },
                { id: "1.1.18", name: this.$t('mapping'), to: "mappinglist" },
                { id: "1.1.19", name: this.$t('apprvlworkflow'), to: "workflowlist" },
                { id: "1.1.20", name: this.$t('workflowexception'), to: "workflowexceptionlist" },
                { id: "1.1.21", name: this.$t('lettertemplate'), to: "letterlist" },
                { id: "1.1.22", name: this.$t('edoc'), to: "edoclist" },
                { id: "1.1.23", name: this.$t('intgsetup.intgsetup'), to: "intgsetuplist" },
              ],
            },
            { id: "1.2", name: this.$t('subtitles.recordsandprocesses'), children: 
              [
                { id: "1.2.0", name: this.$t('exchangerateset'), to: "exchangeratesetlist" },
                { id: "1.2.1", name: this.$t('assetrec'), to: "assetlist" },
                { id: "1.2.2", name: this.$t('columns.user'), to: "secuserlist" },
                { id: "1.2.3", name: this.$t('hruserdataaccess'), to: "dataaccesslist" },
                { id: "1.2.4", name: this.$t('hruserfunctionaccess'), to: "functionaccesslist" },
                { id: "1.2.5", name: this.$t('mappingprocess'), to: "mappingprocess" },
                { id: "1.2.6", name: this.$t('announcementsetup'), to: "announcementlist" },
                { id: "1.2.7", name: this.$t('import'), to: "importdata" },
                { 
                  id: "1.2.8", 
                  name: this.$t('batchusercreation'), 
                  to: "batchusercreationparameter",
                },
                { 
                  id: "1.2.9", 
                  name: this.$t('workflowmaintenance'), 
                  to: "workflowmaintenancelist",
                },
                { 
                  id: "1.2.10", 
                  name: this.$t('workflowmaintenancebatch'), 
                  to: "workflowmaintenancebatch",
                },
              ],
            },
            { id: "1.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "1.3.1", name: this.$t('auditlisting'), to: "parameterauditlisting"},
                { id: "1.3.2", name: this.$t('wizardlisting'), to: "wizardlisting" },
                { id: "1.3.3", name: this.$t('alertlisting'), to: "alertlisting" },
                { id: "1.3.4", name: this.$t('processloglisting'), to: "processloglisting" },
              ],
            },
          ],
        },

        { id: "2", name: this.$t('personnel'), children: 
          [
            { id: "2.1", name: this.$t('subtitles.records'), children: 
              [
                { id: "2.1", name: this.$t('personalinfo'), to: "personlist" },
                { id: "2.2", name: this.$t('staffdetails'), to: "stafflist" },
                { id: "2.3", name: this.$t('staffdetail'), to: "stafflisths" },
                { id: "2.4", name: this.$t('famdetails'), to: "familylist" },
                { id: "2.5", name: this.$t('contactdetails'), to: "contactdetailslist" },
                { id: "2.6", name: this.$t('grpassignment'), to: "groupassignmentlist" },
                { id: "2.7", name: this.$t('docrec'), to: "documentrecordlist" },
                { id: "2.8", name: this.$t('disciplinaryrec'), to: "disciplinarylist" },
                { id: "2.9", name: this.$t('assetassignmentrec'), to: "assetassignmentlist" },
                { id: "2.10", name: this.$t('qualification'), to: "qualificationlist" },
              ],
            },
            { id: "2.2", name: this.$t('subtitles.reports'), children: 
              [
                { id: "2.2.1", name: this.$t('headcountanalysis'), to: "parameterheadcount" },
                { id: "2.2.2", name: this.$t('servicemovlisting'), to: "parameterservicemovementlisting" },
                { id: "2.2.3", name: this.$t('personandstaffdetailssummary'), to: "parameterpersonandstaff" },
                { id: "2.2.4", name: this.$t('positionassigmentanalysis'), to: "positionassignmentanalysisparameter" },
                { id: "2.2.5", name: this.$t('staffenquiry'), to: "staffenquiryparameter" },
                { id: "2.2.6", name: this.$t('orgchart'), to: "orgchartfilter" },
              ],
            },
          ],
        },
        { id: "3", name: this.$t('payroll'), children: 
          [
            { id: "3.1", name: this.$t('payrollsetup'), children: 
              [
                // { id: "3.1.1", name: "Affect Item", to: "affectitemlist" },
                { id: "3.1.2", name: this.$t('setupaffecttype.affecttype'), to: "affecttypelist" },
                { id: "3.1.3", name: this.$t('setuppayitmetype.payitemtype'), to: "payitemtypelist" },
                { id: "3.1.4", name: this.$t('setuppayitem.payitem'), to: "payitemlist" },
                { id: "3.1.5", name: this.$t('setuppayrate.payrate'), to: "payratelist" },
                { id: "3.1.6", name: this.$t('setupprocesslistmaster.processlistmaster'), to: "payprocesslist"},
                { id: "3.1.7", name: this.$t('setuppayrolltype.payrolltype'), to: "paytypelist" },
                { id: "3.1.8", name: this.$t('setupcompanybankaccount.companybankaccount'), to: "bankaccountlist" },
                { id: "3.1.9", name: this.$t('setupvendor.vendorsetup'), to: "vendorlist" },
                { id: "3.1.10", name: this.$t('setupcompanyreferencenumber.companyreferencenumbersetup'), to: "refnumlist" },
                { id: "3.1.13", name: this.$t('glcode.glcodesetup'), to: "glcodesetup" },
                { id: "3.1.14", name: this.$t('glcode.glcodemappingpolicy'), to: "glcodemappingpolicylist" },
                { id: "3.1.15", name: this.$t('glcode.glcodemappinglisting'), to: "glcodemappinglistinglist" },
                { id: "3.1.11", name: this.$t('malaysia'), children:
                  [
                    { id: "3.1.11.1", name: this.$t('setuptaxablerebategroup.taxablerebategroupsetup'), to: "taxablerebategroupsetuplist"},
                    { id: "3.1.11.2", name: this.$t('setuptaxablerebate.taxablerebatesetup'), to: "taxablerebatesetuplist",          },
                    { id: "3.1.11.3", name: this.$t('setuptaxableexemption.taxableexemptionsetup'), to: "exemptionlist" },
                    { id: "3.1.11.4", name: this.$t('setuptax.taxsetup'), to: "taxsetlist" },
                    { id: "3.1.11.5", name: this.$t('setupepf.epfsetup'), to: "epfsetuplist" },
                    { id: "3.1.11.6", name: this.$t('setupsocso.socsosetup'), to: "socsolist" },
                    { id: "3.1.11.7", name: this.$t('setupeis.eissetup'), to: "eislist" },
                    { id: "3.1.11.8", name: this.$t('setupepfve.epfvesetup'), to: "epfvelist" },
                    { id: "3.1.11.9", name: this.$t('setupbaitulmal.baitulmalsetup'), to: "baitulmallist" },
                    { id: "3.1.11.10", name: this.$t('setupbaitulmal.statutoryreportsetup'), to: "statutoryreportsetuplist" },
                  ],
                },
                { id: "3.1.12", name: this.$t('myanmar'), children:
                  [
                    { id: "3.1.12.1", name: this.$t('mmsetup.ssbsetup'), to: "mmssbsetuplist" },
                    { id: "3.1.12.2", name: this.$t('mmsetup.taxratesetup'), to: "mmtaxratesetuplist" },
                    { id: "3.1.12.3", name: this.$t('mmsetup.mmtaxperiodsetup'), to: "mmtaxperiodsetuplist"},
                    { id: "3.1.12.4", name: this.$t('mmsetup.mmtaxablerebatelimit'), to: "mmtaxablerebatelimitlist"},
                  ],
                },
              ],
            },
            { id: "3.2", name: this.$t('payrollprocessrun.payrollrun'), children: 
              [
                { id: "3.2.1", name: this.$t('payrollprocessrun.payoutcontrol'), to: "payoutcontrollist" },
                { id: "3.2.2", name: this.$t('payeeinputprocess.payeeinput'), to: "payeeinputlist" },
                { id: "3.2.3", name: this.$t('previewpayroll.payrollpreview'), to: "payrollpreviewfilter" },
                { id: "3.2.4", name: this.$t('payrollcommandstep.payrollcommand'), to: "payrollcommandfilter" },
                { id: "3.2.5", name: this.$t('previewexception.payrollexception'), to: "payrollexceptionfilter" },
                { id: "3.2.6", name: this.$t('finizepayroll.payrollfinalization'), to: "payrollfinalizationlist" },
                { id: "3.2.7", name: this.$t('malaysia'), children:
                  [
                    { id: "3.2.7.1", name: this.$t('payrollrunprocess'), to: "payrollrunprocess" },
                  ],
                },
                { id: "3.2.8", name: this.$t('myanmar'), children:
                  [
                    { id: "3.2.8.1", name: this.$t('mm.payrollrun'), to: "mmpayrollrunprocess" },
                  ],
                },   
              ],
            },
            { id: "3.3", name: this.$t('postpayroll.payrollposting'), children: 
              [
                { id: "3.3.1", name: this.$t('postpayroll.leaveposting'), to: "leavepayrollposting" },
                { id: "3.3.2", name: this.$t('postpayroll.attendancetopayrollposting'), to: "attendancepayrollposting" },
                { id: "3.3.3", name: this.$t('postpayroll.claimpostingtopayroll'), to: "claimpayrollposting" },
                { id: "3.3.4", name: this.$t('latenessdeduction.posting'), to: "latenessdeductionposting"}
              ],
            },
            { id: "3.4", name: this.$t('maintenanceforpayroll.payrollmaintenance'), children: 
              [
                { id: "3.4.1", name: this.$t('setupstaffbankaccount.staffbankaccount'), to: "staffbankaccountlist" },
                { id: "3.4.2", name: this.$t('setuppersonstatutorynumberinfo.personstatutorynumberinfosetup'), to: "personstatutoryinfolist" },
                { id: "3.4.3", name: this.$t('setuppersonpayrolldirectassignment.personpayrolldirectassignment'), to: "personpayrolldirectassignmentlist" },
                { id: "3.4.4", name: this.$t('setupgrouppayment.grouppayment'), to: "grouppaymentlist" },
                { id: "3.4.5", name: this.$t('malaysia'), children:
                  [
                    { id: "3.4.5.1", name: this.$t('maintenanceforpayroll.personpayrollinstructionsetup'), to: "psnpayrolllist" },
                    { id: "3.4.5.2", name: this.$t('setupbaitulmal.tp1'), to: "tp1list" },
                    { id: "3.4.5.3", name: this.$t('setuppersonpcbbfinformation.personpcbbfinformation'), to: "tp3list" },
                    { id: "3.4.5.4", name: this.$t('cntryss.cntryss'), to: "cntrysslist" },
                    { id: "3.4.5.5", name: this.$t('eadtl.eadtl'), to: "eadtllist" },
                  ],
                },
                { id: "3.4.6", name: this.$t('myanmar'), children:
                  [
                    { id: "3.4.6.1", name: this.$t('mmsetup.psnstatutory'), to: "mmpsnstatutorylist" },
                    { id: "3.4.6.2", name: this.$t('mmsetup.mmpsntaxbf'), to: "mmpsntaxbflist" },
                    { id: "3.4.6.3", name: this.$t('mmsetup.mmpersontaxablerebatereg'), to: "mmpersontaxablerebatereglist"},
                  ],
                },
              ],
            },
            { id: "3.5", name: this.$t('payrollreports'), children: 
              [
                { id: "3.5.1", name: this.$t('payrollsymmarybycompanyreport.payrollsummarybycompany'), to: "parametercompanysummary" },
                { id: "3.5.2", name: this.$t('payrollreconciliationreport.payrollreconciliation'), to: "parameterpayrollreconciliation" },
                { id: "3.5.3", name: this.$t('staff12monthpayrollsummaryreport.staff12monthpayrollsummary'), to: "staff12monthpayrollsummary" },
                { id: "3.5.4", name: this.$t('bankpaymentlistingreport.bankpaymentlisting'), to: "parameterbank" },
                { id: "3.5.5", name: this.$t('nonbankpaymentlistingreport.nonbankpaymentlisting'), to: "parameternonbank" },
                { id: "3.5.6", name: this.$t('cashdenominationlistingreport.cashdenominationlisting'), to: "parametercashdenomination" },
                { id: "3.5.7", name: this.$t('payrollpivotreport.payrollpivot'), to: "payrollpivotfilter" },
                { id: "3.5.8",name: this.$t('miscpayrolllsitreport.miscpayrolllist'), to: "parameterMisc" },
                { id: "3.5.9",name: this.$t('payrollsummarylistingreport.payrollsummarylisting'), to: "payrollsummarylisting" },
                { id: "3.5.10",name: this.$t('payrolldetaillistingreport.payrolldetaillisting'), to: "payrolldetaillisting" },
                { id: "3.5.11",name: this.$t('payrollsummaryanalysisreport.payrollsummaryanalysis'), to: "payrollsummaryanalysis" },
                { id: "3.5.12", name: this.$t('payrollenquiryreport.payrollenquiry'), to: "payrollenquiryfilter" },
                { id: "3.5.13", name: this.$t('payrollenquirybyservicemovementreport.payrollenquirybyservicemovement'), to: "payrollenquirybysmfilter" },
                { id: "3.5.14", name: this.$t('staffpayrolldirectassignmentenquireyreport.staffpayrolldirectassignmentenquiry'), to: "staffpayrolldirectassignmentenquiry" },
                { id: "3.5.15", name: this.$t('malaysia'), children:
                  [
                    { id: "3.5.15.1", name: this.$t('payrolldashboardreport.payrolldashboard'), to: "payrolldashboardfilter" },
                    { id: "3.5.15.2", name: this.$t('payslipreport.payslip'), to: "parameterpayslip" },
                    { id: "3.5.15.3",name: this.$t('hrdflisitngreport.hrdflisting'), to: "hrdflistingparameter" },
                  ],
                },
                { id: "3.5.16", name: this.$t('myanmar'), children:
                  [
                    { id: "3.5.16.1", name: this.$t('payslipreport.mmpayslip'), to: "mmparameterpayslip" },
                    //{ id: "3.5.16.2", name: this.$t('mmsetup.mmpaye'), to: "mmmonthlytaxexport" },
                  ],
                },
              ],
            },
            { id: "3.6", name: this.$t('statutoryandbankreportsexports'), children: 
              [
                { id: "3.6.1", name: this.$t('bank'), children: 
                    [
                      { id: "3.6.1.1", name: this.$t('allbank.salaryexportrec'), to: "salaryexportrecordlist" },
                      { id: "3.6.1.2", name: this.$t('allbank.salaryexport'), to: "salaryexportparameter" },
                    ],
                },
                { id: "3.6.2", name: this.$t('malaysia'), children:
                  [
                    { id: "3.6.2.1", name: this.$t('epf'), children: 
                      [
                        { id: "3.6.2.1.1", name: this.$t('allepf.borang_a'), to: "formaexport" },
                        { id: "3.6.2.1.2", name: this.$t('allepf.monthlyepfsubmissionrec'), to: "monthlyepfsubmissionrecordlist" },
                        { id: "3.6.2.1.3", name: this.$t('allepf.monthlyepfexport'), to: "monthlyepfexport" },
                      ],
                    },
                    { id: "3.6.2.2", name: this.$t('socso'), children: 
                      [
                        { id: "3.6.2.2.1", name: this.$t('allsocso.monthlysocsosubmissionrec'), to: "socsosublist" },
                        { id: "3.6.2.2.2", name: this.$t('allsocso.monthlysocsoexport'), to: "monthlysocsoexport" },
                        { id: "3.6.2.2.3", name: this.$t('allsocso.borang_8a'), to: "socso8a" },
                      ],
                    },
                    { id: "3.6.2.3", name: this.$t('eis'), children: 
                      [
                        { id: "3.6.2.3.1", name: this.$t('alleis.monthlyeissubmissionrec'), to: "eissublist" },
                        { id: "3.6.2.3.2", name: this.$t('alleis.monthlyeisexport'), to: "monthlyeisexport" },
                        { id: "3.6.2.3.3", name: this.$t('alleis.eislampiran_1'), to: "eislampiran1" },
                      ],
                    },
                    { id: "3.6.2.4", name: this.$t('tax'), children: 
                      [
                        { id: "3.6.2.4.1", name: this.$t('alltax.cp38_39'), to: "parametertaxcp3839" },
                        { id: "3.6.2.4.2", name: this.$t('alltax.monthlytaxsubmissionrec'), to: "monthlytaxsubmissionrecordlist" },
                        { id: "3.6.2.4.3", name: this.$t('alltax.monthlytaxexport'), to: "monthlytaxexport" },
                        { id: "3.6.2.4.4", name: this.$t('alltax.eaform'), to: "parametereaform" },
                        { id: "3.6.2.4.5", name: this.$t('alltax.pcb'), to: "parametertaxpcbii" },
                        { id: "3.6.2.4.6", name: this.$t('payrollauditexport.payrollaudit'), to: "payrollauditexport" },
                      ],
                    },
                    { id: "3.6.2.5", name: this.$t('ptptn'), children: 
                    [
                        { id: "3.6.2.5.1", name: this.$t('allptptn.monthlyptptnsubmissionrec'), to: "monthlyptptnsubmissionrecordlist" },
                        { id: "3.6.2.5.2", name: this.$t('allptptn.ptptnexport'), to: "ptptnexport" },
                      ],
                    },

                    { id: "3.6.2.6", name: this.$t('socsoandeis'), children: 
                      [ 
                        { id: "3.6.2.6.1", name: this.$t('alleissocso.monthlysocsoandeissubmissionrec'), to: "eissocsublist" },
                        { id: "3.6.2.6.2", name: this.$t('alleissocso.monthlysocsoandeisexport'), to: "monthlyeissocsoexport" },
                        { id: "3.6.2.6.3", name: this.$t('alleissocso.eissocso_borang_8a'), to: "eissocso8a" },
                        { id: "3.6.2.6.4", name: this.$t('alleissocso.eisoclampiran_1'), to: "eisoclampiran1" },
                      ],
                    },
                  ],
                },
                { id: "3.6.3", name: this.$t('myanmar'), children:
                  [
                  { id: "3.6.3.1", name: this.$t('tax'), children: 
                      [
                        { id: "3.6.3.1.1", name: this.$t('mmsetup.mmmonthlytaxsubmissionrec'), to: "mmmonthlytaxsubmissionrecordlist"},
                        { id: "3.6.3.1.2", name: this.$t('mmsetup.mmpaye'), to: "mmmonthlytaxexport" },
                      ],
                    },
                  { id: "3.6.3.2", name: this.$t('SSB'), children: 
                      [
                        { id: "3.6.3.2.1", name: this.$t('mmsetup.mmmonthlyssbsubmissionrec'), to: "mmmonthlyssbsubmissionrecordlist"},
                        { id: "3.6.3.2.2", name: this.$t('mmsetup.mmmonthlyssbexport'), to: "mmmonthlyssbexport" },
                      ],
                    },
                  ],
                },
                { id: "3.6.4", name: this.$t('glcode.gl'), children: 
                    [
                      { id: "3.6.4.1", name: this.$t('glcode.glexport'), to: "glexportparameter" }
                    ],
                },
              ],
            },
            { id: "3.7", name: this.$t('payrollothers.others'), children: 
              [
                { id: "3.7.1", name: this.$t('payrollothers.miscellaneousexport'), to:"monthlymiscellaneousexport"},
              ],
            },
            // { id: "3.8", name: this.$t('mmpayroll'), children:
            //   [
                // id: "3.8.1", name: this.$t('mmsetup.ssbsetup'), to: "mmssbsetuplist" },
                //{ id: "3.8.2", name: this.$t('mmsetup.taxratesetup'), to: "mmtaxratesetuplist" },
                //{ id: "3.8.3", name: this.$t('mmsetup.psnstatutory'), to: "mmpsnstatutorylist" },
                //{ id: "3.8.4", name: this.$t('mmsetup.mmpsntaxbf'), to: "mmpsntaxbflist" },
               // { id: "3.8.5", name: this.$t('mmsetup.mmpsntaxrebate'), to: "mmpsntaxrebatelist" },
                //{ id: "3.8.6", name: this.$t('mmsetup.mmpaye'), to: "mmmonthlytaxexport" },
                //{ id: "3.8.7", name: this.$t('mmsetup.mmtaxperiodsetup'), to: "mmtaxperiodsetuplist"},
                //{ id: "3.8.8", name: this.$t('mmsetup.mmtaxablerebatelimit'), to: "mmtaxablerebatelimitlist"},
                //{ id: "3.8.9", name: this.$t('mmsetup.mmpersontaxablerebatereg'), to: "mmpersontaxablerebatereglist"},
                //{ id: "3.8.10", name: this.$t('mmsetup.mmmonthlytaxsubmissionrec'), to: "mmmonthlytaxsubmissionrecordlist"},
                //{ id: "3.8.11", name: this.$t('mm.payrollrun'), to: "mmpayrollrunprocess" },
                //{ id: "3.8.12", name: this.$t('payslipreport.mmpayslip'), to: "mmparameterpayslip" },
            //   ]
            // },
          ],
        },
        
        { id: "4", name: this.$t('leave'), children: 
          [
            { id: "4.1", name: this.$t('subtitles.setup'), children: 
              [
                { id: "4.1.1", name: this.$t('panelclinic'), to: "cliniclist" },
                { id: "4.1.2", name: this.$t('holidaysetup'), to: "holidaylist" },
                { id: "4.1.3", name: this.$t('holassigment'), to: "holidayassignmentlist" },
                { id: "4.1.4", name: this.$t('restdaypatternsetup'), to: "restdaylist" },
                { id: "4.1.5", name: this.$t('restdayscheduleinstruction'), to: "rdayscheduleinstructionlist" },
                //{ id: "4.1.10", name: "Rest Day Assignment by Staff", to: "restdaystaff" },
                { id: "4.1.6", name: this.$t('leavesetup'), to: "leavesetuplist" },
                { id: "4.1.7", name: this.$t('leaveentitlementpolicysetup'), to: "leaveentitlementpolicylist" },
                { id: "4.1.8", name: this.$t('columns.leavetopayrollpostingsetup'), to: "leavetopayrollpostingsetuplist" },
                { id: "4.1.9", name: this.$t('leavecarryforwardpolicy'), to: "leavecarryforwardpolicyform" },
              ],
            },
            { id: "4.2", name: this.$t('subtitles.records'), children: 
              [
                { id: "4.2.1", name: this.$t('leaverec'), to: "leaverecordlist" },
                { id: "4.2.2", name: this.$t('leavebalanceadjustment'), to: "leavebalanceadjlist" },
              ],
            },
            { id: "4.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "4.3.1", name: this.$t('leavebalancebystaff'), to: "leavebalancebystaff" },
                { id: "4.3.2", name: this.$t('leavebalancesummary'), to: "leavebalancesummary" },
                { id: "4.3.3", name: this.$t('leavedashboard'), to: "leavedashboardfilter" },
                { id: "4.3.4", name: this.$t('whoisonleave'), to: "whoisonleavedashboard"},
                { id: "4.3.5", name: this.$t('leaveenquiry'), to: "leaveenquiryparameter"}, 
              ],
            },
          ],
        },
        { id: "5", name: this.$t('attendance'), children: 
          [
            { id: "5.1", name: this.$t('subtitles.setup'), children: 
              [
                { id: "5.1.1", name: this.$t('shiftsetup'), to: "shiftsetuplist" },
                { id: "5.1.2", name: this.$t('periodicattendanceincentivesetup'), to: "periodshiftinclist" },
                { id: "5.1.3", name: this.$t('workhourspayitemmapping'), to: "workhourspayitemmappinglist" },
              ],
            },
            { id: "5.2", name: this.$t('latenessdeduction.title'), children: 
              [
                { id: "5.2.1", name: this.$t('latenessdeduction.policy'), to: "latenessdeductionpolicylist"},
                { id: "5.2.2", name: this.$t('latenessdeduction.generation'), to: "latenessdeductiongenerate"},
                { id: "5.2.3", name: this.$t('latenessdeduction.records'), to: "latenessdeductionlisting"}
              ],
            },
            { id: "5.3", name: this.$t('subtitles.recordsandprocesses'), children: 
              [
                { id: "5.3.1", name: this.$t('workschedulegroupassignment'), to: "workschedulegrouplist" },
                { id: "5.3.2", name: this.$t('workschedulebystaffforaperiod'), to: "workschedulestafflist" },
                { id: "5.3.3", name: this.$t('staffdailyroster'), to: "staffdailyrosterlist" },
                { id: "5.3.4", name: this.$t('preapprovedot'), to: "preapprovedotlist" },
                { id: "5.3.5", name: this.$t('processclockdata'), to: "processclockdata" },
                { id: "5.3.6", name: this.$t('processedattendancedata'), to: "processedattendancedata" },
                { id: "5.3.7", name: this.$t('generateunpaidleaveforabsentdays'), to: "generateunpaidleave" },
                { id: "5.3.8", name: this.$t('genearateperiodicattendanceincentive'), to: "generateperiodicattnincentive" },
              ],
            },
            { id: "5.4", name: this.$t('subtitles.reports'), children: 
              [
                { id: "5.4.1", name: this.$t('attendancereportbyperiodbycompany'), to: "attendanceperiod" },
                { id: "5.4.2", name: this.$t('latecomerlistingreport'), to: "attendancelatecomer" },
                { id: "5.4.3", name: this.$t('absentlistingreport'), to: "attendanceabsent" },
                { id: "5.4.4", name: this.$t('attendanceenquiry'), to: "attendanceenquiryfilter" },
                { id: "5.4.5", name: this.$t('attendancesummarybystaff'), to: "attendancesummarybystafffilter" },
              ],
            },
          ],
        },   
        { id: "6", name: this.$t('claim'), children: 
          [
            { id: "6.1", name: this.$t('subtitles.setup'), children: 
              [
                { id: "6.1.1", name: this.$t('claimtypesetup'), to: "claimtypesetuplist" },
                { id: "6.1.2", name: this.$t('claimlimitsetup'), to: "claimlimitsetuplist" },
              ],
            },
            { id: "6.2", name: this.$t('subtitles.records'), children: 
              [ 
                { id: "6.2.1", name: this.$t('claimrecord'), to: "claimrecordlist" },
                { id: "6.2.2", name: this.$t('claimlimitadjustment'), to: "claimlimitadjustmentlist" },
              ],
            },
            { id: "6.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "6.3.1", name: this.$t('claimlimitandutilizationbystaff'), to: "claimlimitutilizationbystaff" },
                { id: "6.3.2", name: this.$t('claimutilizationforperiod'), to: "claimutilizationperiodparameter" },
                { id: "6.3.3", name: this.$t('claimtransactionlistingreport.claimtransactionlisting'), to: "claimtransactionparameter" },
                { id: "6.3.4", name: this.$t('claimutilizationtrendanalysis.claimutilizationtrendanlysis'), to: "claimutilizationtrendanalysisparameter" },
                { id: "6.3.5", name: this.$t('claimenquiry'), to: "claimenquiryparameter" },
              ],
            },
          ],
        },

        { id: "7", name: this.$t('training'), children: 
          [
            { id: "7.1", name: this.$t('subtitles.setup'), children: 
              [
                { id: "7.1.1", name: this.$t('trainingsetup.targettraininghours'), to: "targettraininghourslist" },
              ],
            },
            { id: "7.2", name: this.$t('subtitles.records'), children: 
              [
                { id: "7.2.1", name: this.$t('trainingrecord.trainingrecord'), to: "trainingrecordlist" },
              ],
            },
            { id: "7.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "7.3.1", name: this.$t('traininghoursanalysis'), to: "traininghoursanalysisparameter" },
                { id: "7.3.2", name: this.$t('trainingexpensesanalysis'), to: "trainingexpensesanalysisparameter" },
              ],
            },
          ]
        },

        { id: "8",name: this.$t('grpinsurance'), children: 
          [
            { id: "8.1", name: this.$t('subtitles.setup'), children: 
              [
                { id: "8.1.1", name: this.$t('policymaster'), to: "groupinsurancepolicylist" },
                { id: "8.1.2", name: this.$t('report_exportmapping'), to: "reportexportmappinglist" },
              ],
            },
            { id: "8.2", name: this.$t('subtitles.records'), children: 
              [
                { id: "8.2.1", name: this.$t('grpinsuranceassignment'), to: "grpinsassignmentlist" },
              ],
            },
            { id: "8.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "8.2.1", name: this.$t('exportstaffinsurancerecords'), to: "exportstaffinsuranceparameter" },
              ],
            },
          ]
        },

        { id: "9", name: this.$t('performance'), children:
        [
          {id: "9.1", name: this.$t('subtitles.setup'), children:
          [
            {id: "9.1.1", name: this.$t('ratingscaleset.ratingscaleset'), to: "ratingscalesetlist"},
            {id: "9.1.2", name: this.$t('rangkingset'), to: "rankingsetlist"},
          ],
          },

          { id: "9.2", name: this.$t('subtitles.records'), children: 
              [
                { id: "9.2.1", name: this.$t('apprailsalexercise'), to: "appraisalexerciselist" },
                { id: "9.2.2", name: this.$t('apprisalrecord'), to: "appraisalrecordlist" },
              ],
          },

            { id: "9.3", name: this.$t('subtitles.reports'), children: 
              [
                { id: "9.3.1", name: this.$t('performancereport.performancereviewanalysis'), to: "performancereviewparameter" },
                { id: "9.3.2", name: this.$t('performancereport.performancerewardsummary'), to: "performancerewardparameter" },
             //   { id: "9.2.2", name: "Appraisal Record", to: "appraisalrecordlist" },
              ],
          },

        ]
        },
        { id: "10", name: "Performance Management", children: 
          [
            { id: "10.1", name: "Target Setting", to: "kpisettinglist" },
          ],
        },

      //    { id: "10", name: "Industrial Relations (IR)", children:
      //      [
      //        {id: "10.1", name: "Setup", children:
      //      [
      //           {id: "10.1.1", name: "Industrial Relations (IR) Case Maintenance ", to: "ircasemaintenancelist"},
      //           {id: "10.1.3", name: "Industrial Relations (IR) Case Staff Maintenance", to: "casesubmaintenancelist"},
      //           {id: "10.1.2", name: "Domestic Inquiry (DI) Maintenance", to: "domesticinquirylist"},
      //     ],
      //       },

      //     // { id: "9.2", name: "Records", children: 
      //     //     [
      //     //       { id: "9.2.1", name: "Appraisal Exercise", to: "appraisalexerciselist" },
      //     //       { id: "9.2.2", name: "Appraisal Record", to: "appraisalrecordlist" },
      //     //     ],
      //     // },

      //     //   { id: "9.3", name: "Reports", children: 
      //     //     [
      //     //       { id: "9.3.1", name: "Performance - Review Analysis", to: "performancereviewparameter" },
      //     //       { id: "9.3.2", name: "Performance - Reward Summary", to: "performancerewardparameter" },
      //     //    //   { id: "9.2.2", name: "Appraisal Record", to: "appraisalrecordlist" },
      //     //     ],
      //      // },

      //  ]
      //   }

      ],
    };
  },

  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__subtitle,
.v-card__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 10px;
  color: #e91e63;
  letter-spacing: 0.0071428571em;

  .v-treeview--dense .v-treeview-node__root {
    height: 10px;
  }
}
</style>
