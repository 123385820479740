<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppanelclinic.cliniccode')"
                                type="text"
                                ref="cliniccode"
                                v-model="formData.cliniccode"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="clinicdesc"
                                v-model="formData.clinicdesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline1')"
                                type="text"
                                ref="add1"
                                v-model="formData.add1"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline2')"
                                type="text"
                                ref="add2"
                                v-model="formData.add2"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline3')"
                                type="text"
                                ref="add3"
                                v-model="formData.add3"

                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.city')"
                                type="text"
                                ref="cityid"
                                :items="cityList"
                                hide-selected
                                v-model="formData.cityid"
                                @input="updateValue"
                                @change="changeCityHandler(formData.cityid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="cityCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="cityDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: cityItems,
                                            formTitle: `${this.$t('columns.city')}`,
                                            formId: 'city'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveCity="saveCityHandler"
                                        v-on:deleteCity="deleteCityHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="cityDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.country')"
                                type="text"
                                ref="cntryid"
                                :items="countryList"
                                v-model="formData.cntryid"
                                @input="updateValue"
                                @change="changeCountryHandler(formData.cntryid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="countryCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="countryDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: countryItems,
                                            formTitle: `${this.$t('columns.country')}`,
                                            formId: 'country'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCountry="saveCountryHandler"
                                        v-on:deleteCountry="deleteCountryHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="countryDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.postcode')"
                                type="text"
                                ref="poscode"
                                v-model="formData.poscode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.telephone')"
                                type="text"
                                ref="tel"
                                v-model="formData.tel"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.email')"
                                type="text"
                                ref="email"
                                v-model="formData.email"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {
                cliniccode: this.parentObject.data.cliniccode,
                clinicdesc: this.parentObject.data.clinicdesc,
                add1: this.parentObject.data.add1,
                add2: this.parentObject.data.add2,
                add3: this.parentObject.data.add3,
                cityid: this.parentObject.data.cityid,
                cntryid: this.parentObject.data.cntryid,
                poscode: this.parentObject.data.poscode,
                tel: this.parentObject.data.tel,
                email: this.parentObject.data.email,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            cityCode: '',
            countryCode: '',
            countryList: [],
            cityList: [],
            countries: [],
            cities: [],
            cityItems: [],
            countryItems: [],
            cityDialog: false,
            countryDialog: false,
            cityLoading: true,
            countryLoading: true,
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {
        loadCityListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/city/all'})
                .then(res => {
                    vm.cities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.cityList.push(
                            {
                                text: res.data.rec[i].cityname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.cityItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].citycode,
                                desc: res.data.rec[i].cityname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    vm.cityLoading = false;

                    if (vm.formData.cityid) {
                        vm.changeCityHandler(vm.formData.cityid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },


        changeCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.cityCode = item.citycode;
        },

        changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.countryCode = item.cntrycode;
        },

        saveCityHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/city/' + this.itemData.itemid, 
                    payload: {
                        citycode: this.itemData.itemcode,
                        cityname: this.itemData.itemdesc,    
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/city/create', 
                    payload: {
                        citycode: this.itemData.itemcode,
                        cityname: this.itemData.itemdesc,
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                       
                       // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCountryHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/country/' + this.itemData.itemid, 
                    payload: {
                        cntrycode: this.itemData.itemcode,
                        cntryname: this.itemData.itemdesc,stamp: this.itemData.itemstamp
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/country/create', 
                    payload: {
                        cntrycode: this.itemData.itemcode,
                        cntryname: this.itemData.itemdesc
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCityHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/city/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    console.log(res.data.status)
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCountryHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/country/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        updateValue() {
            this.$emit('input', {
                cliniccode: this.formData.cliniccode,
                clinicdesc: this.formData.clinicdesc,
                add1: this.formData.add1,
                add2: this.formData.add2,
                add3: this.formData.add3,
                cityid: this.formData.cityid,
                cntryid: this.formData.cntryid,
                poscode: this.formData.poscode,
                tel: this.formData.tel,
                email: this.formData.email,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'cliniclist', params: { search: this.search}});
        },

        genCityTabItems() {
            for (let i = 0; i < this.cities.length; i++) {
                this.cityItems.push(
                    {
                        id: this.cities[i].id,
                        code: this.cities[i].citycode,
                        desc: this.cities[i].cityname,
                        stamp: this.cities[i].stamp
                    }
                )
            }
        },

        genCountryTabItems() {
            for (let i = 0; i < this.countries.length; i++) {
                this.cityItems.push(
                    {
                        id: this.countries[i].id,
                        code: this.countries[i].cntrycode,
                        desc: this.countries[i].cntryname,
                        stamp: this.countries[i].stamp
                    }
                )
            }
        },
    },

    mounted() {
        this.loadCityListHandler();
        this.loadCountryListHandler();
    }
    
}
</script>