<template>
    <div>
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:save="saveHandler"
      />
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  
  import GLCodeMappingPolicyForm from "../../../components/payroll/glcodemappingpolicy/Form";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    components: {
      "app-form": GLCodeMappingPolicyForm,
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        pageTitle: "Create GL Code Mapping Policy",
        pageTitleValue: "",
        formData: {
            policycode: null,
            policydesc: null,
            remark: null,
            level1: null,
            level2: null,
            level3: null,
            level4: null,
            level5: null,
            level6: null,
            level7: null,
            level8: null,
            stamp: 0,
            userid: this.$store.getters.userId,
        },
        errMsg: "",
        isErr: false,
        editMode: false,
      };
    },
  
    methods: {
      errorHandler($event) {
        this.isErr = $event.isErr;
        this.errMsg = $event.errMsg;
      },
  
      saveHandler() {
        const vm = this;
  
        if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
          return false;
        }
  
        // Convert boolean to integer. 1 = true, 0 = false
        if (this.formData.ishidden == false) {
          this.formData.ishidden = 0;
        } else {
          this.formData.ishidden = 1;
        }
  
        this.$store.dispatch('post', {url: "/payglcodemappingpolicy/create", payload: vm.formData})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "glcodemappingpolicylist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      cancelHandler() {
        this.$router.push({ name: "glcodemappingpolicylist" });
      },
    },
  };
  </script>