<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('recstaffdetails.stafflist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "psnname", width: "250px" },
        { text: this.$t('recstaffdetails.employmentnumber'), value: "empno", width: "50px" },
        { text: this.$t('columns.recorddate'), value: "recdate", width: "120px" },
        { text: this.$t('columns.recordcode'), value: "movtypecode", width: "120px" }, 
        { text: this.$t('columns.recordtype'), value: "movtypedesc", width: "100px" },
        { text: this.$t('columns.reason'), value: "reasondesc", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "120px" },
        { text: this.$t('columns.division'), value: "divncode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.section'), value: "sectiocode", width: "120px" },
        { text: this.$t('columns.unit'), value: "unitcode", width: "120px" },
        { text: this.$t('columns.position'), value: "poscode", width: "120px"},
        // { text: this.$t('payrolldashboardreport.basic'), value: "basicsal", width: "150px"},
        // { text: this.$t('recstaffdetails.adjustedamount'), value: "adjustamt", width: "150px"},
        // { text: this.$t('recstaffdetails.newbasic'), value: "newbasicsal", width: "150px"},
        { text: this.$t('columns.job'), value: "jobcode", width: "120px" },
        { text: this.$t('columns.grade'), value: "grdcode", width: "120px" },
        { text: this.$t('columns.employmenttype'), value: "emptype", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchname", width: "200px" },
        { text: this.$t('columns.division'), value: "divnname", width: "200px" },
        { text: this.$t('columns.department'), value: "deptname", width: "200px" },
        { text: this.$t('columns.section'), value: "sectioname", width: "200px" },
        { text: this.$t('columns.unit'), value: "unitname", width: "200px" },
        { text: this.$t('setupjob.jobtitle'), value: "jobtitle", width: "200px" },
        { text: this.$t('columns.gradedesc'), value: "grddesc", width: "200px" },
        { text: this.$t('columns.employmenttypedesc'), value: "emptypedesc", width: "200px" },        
              ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getStaffListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: `/staffmov/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].recdate) {
              //vm.dataItems[i].recdate = moment(vm.dataItems[i].recdate).format('YYYY-MM-DD');
              vm.dataItems[i].recdate = moment(vm.dataItems[i].recdate).format(
                "YYYY-MM-DD"
              );
            }
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "staffediths", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "staffnewhs" });
    },
  },

  mounted() {
    this.getStaffListHandler();
  },
};
</script>