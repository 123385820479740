<template>
    <div>
        <v-dialog v-model="taxsetdialog" max-width="800px">
          <template v-slot:activator="{ on }">

            <v-btn 
                color="primary" 
                dark class="mb-2" 
                v-on="on"
            >+</v-btn>

          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setuptax.taxsetupdeduction')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                  <v-row>
                    <v-col cols="6" sm="6" md="6" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('mmtaxratesetup.taxgroup')"
                            v-model="selections[0].taxgroupid"
                            :items="taxgroupList"
                            dense
                            @change="changeTaxGroupHandler(selections[0].taxgroupid)"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-n8">
                        <v-select
                            
                            outlined
                            :label="this.$t('columns.description')"
                            :items="taxgroupList"
                            append-icon=" "
                            v-model="selections[0].taxgroupid"
                            readonly
                            dense
                            background-color="#ffeab2"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            
                            outlined
                            :label="this.$t('mmtaxratesetup.child')"
                            type="number"
                            v-model="selections[0].childamt"
                            dense
                            class="removeSpin"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            
                            :label="this.$t('mmtaxratesetup.spouse')"
                            type="number"
                            v-model="selections[0].spouseamt"
                            dense
                            class="removeSpin"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            
                            outlined
                            :label="this.$t('mmtaxratesetup.parent')"
                            type="number"
                            v-model="selections[0].parentamt"
                            dense
                            class="removeSpin"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            
                            :label="this.$t('mmtaxratesetup.self')"
                            type="number"
                            v-model="selections[0].selfpercent"
                            dense
                            class="removeSpin"
                        ></v-text-field>
                    </v-col>
                </v-row>

                

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeTaxset">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveTaxset">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader2"
            :items="detailItem2"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            isTaxset: false,
            taxsetdialog: false,
            detailItem2: this.parentObject.detailItem2,
            selections: [{
                id: null,
                taxgroupid: null,
                childamt: null,
                spouseamt: null,
                parentamt: null,
                selfpercent: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            taxgroups: [],
            taxgroupList:[],
            taxgroup2List:[],

            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }
            if(item.action == 2) {
                this.isExisting = true;
            }

            this.selections = [{
                id: item.id,
                taxgroupid: item.taxgroupid,
                childamt: item.childamt,
                parentamt: item.parentamt,
                spouseamt: item.spouseamt,
                selfpercent: item.selfpercent,
            }]

            this.taxsetdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem2.indexOf(item);

            this.detailItem2.splice(itemIndex, 1);
        },

        closeTaxset() {
            this.taxsetdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        //save taxset is used to save the header details contents into the database
        saveTaxset() {

            if (this.selections[0].childamt == '' ){
                this.selections[0].childamt = null
            }

            if (this.selections[0].parentamt == '' ){
                this.selections[0].parentamt = null
            }

            if (this.selections[0].spouseamt == '' ){
                this.selections[0].spouseamt = null       
            }

             if (this.selections[0].selfpercent == '' ){
                this.selections[0].selfpercent = null 
            }

            if (this.selections[0].taxgroupid == null  ){

              this.$dialog.alert(this.$t('fillintaxgroupcolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            this.isErr = false;
            // indicate value of 1 as insert, 2 as modify

            if (!this.isExisting && !this.modify) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem2.push(Object.assign({}, this.selections[0]));
            } else {
                for (let i = 0; i < this.detailItem2.length; i++) {
                    if (this.detailItem2[i].id == this.selections[0].id) {
                        this.detailItem2[i].taxgroupid = this.selections[0].taxgroupid
                        this.detailItem2[i].childamt = this.selections[0].childamt
                        this.detailItem2[i].parentamt = this.selections[0].parentamt
                        this.detailItem2[i].spouseamt = this.selections[0].spouseamt
                        this.detailItem2[i].selfpercent = this.selections[0].selfpercent
                    }
                }
            }

            this.$emit('input', this.detailItem2);

            this.modify = false;
            this.isExisting = false;
            this.taxsetdialog = false;

            // reset the form fields
            this.resetSelections();
            }
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                taxgroupid: null,
                childamt: null,
                parentamt: null,
                spouseamt: null,
                selfpercent: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

        loadTaxGroup() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxgroup/all'})
            .then (res => {
                vm.taxgroups = res.data.rec;

                for (let i = 0; i < vm.taxgroups.length; i++) {
                    vm.taxgroupList.push(
                        {
                            text: vm.taxgroups[i].taxgroupcode,
                            value: vm.taxgroups[i].id
                        }
                    )
                }

                for (let i = 0; i < vm.taxgroups.length; i++) {
                    vm.taxgroupList.push(
                        {
                            text: vm.taxgroups[i].taxgroupdesc,
                            value: vm.taxgroups[i].id
                        }
                    )
                }
            });
        },

        changeTaxGroupHandler(id) {
            let item = this.taxgroups.find(item => item.id == id)

            this.selections[0].taxgroupcode = item.taxgroupcode;
            this.selections[0].taxgroupdesc = item.taxgroupdesc;
        },

        check() {
            console.log('detailItem2', this.detailItem2)
        }

        
    },

    created() {
            this.loadTaxGroup();
            this.check();
        }
}
</script>

<style>

.removeSpin input[type='number'] {
    /* this chunks of code is to hide the spin button in type=number */
    -moz-appearance:textfield;
    -moz-appearance:autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>