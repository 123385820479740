<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
//import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: 'Case Staff Maintenance List',
            dataHeader: [
                { text: 'ACTION',  value: 'action',  width: '100px', sortable: false},
                { text: 'CASE ID', value: 'caseid', width: '200px' },
                { text: 'CASE DESCRIPTION', value: 'shortdesc', width: '200px' },
            ],

            dataItems: [],
            getId: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {

        loadCaseSubList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/casesubmaintenance/all'})
            .then (res => {
                vm.dataItems = res.data.rec;

                //  for (let i = 0; i < vm.dataItems.length; i++) {
                //     if (vm.dataItems[i].startdate) {
                //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                //     }

                //     if (vm.dataItems[i].enddate) {
                //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                //     }
                //  }

            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        editItemHandler(item) {
            this.$router.push({name: 'casesubmaintenanceedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'casesubmaintenancenew'});
        }
    },

    mounted() {
        this.loadCaseSubList()
    }
}
</script>