<template>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
      />
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  import ThisForm from "../../../components/payroll/mmssbsetup/Form";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  import ConfirmDialog from "../../../components/common/ConfirmDialog";
  
  export default {
    components: {
      "app-form": ThisForm,
      "app-error": ErrorDialog,
      "app-confirm": ConfirmDialog,
    },
  
    data() {
      return {
        id: this.$route.params.id,
        search: this.$route.params.search,
        pageTitle: this.$t('mmsetup.ssbsetup'),
        pageTitleValue: "",
        formData: [],
        headLoading: true,
        loading: true,
        errMsg: "",
        isErr: false,
        isConfirm: false,
        editMode: true,
      };
    },
  
    methods: {
      errorHandler($event) {
        this.isErr = $event.isErr;
        this.errMsg = $event.errMsg;
      },
  
      loadSsbHandler() {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/mmssbsetupdt/" + this.id})
          .then((res) => {
            vm.formData = res.data.rec[0];
  
            if (res.data.rec[0].startdate) {
              vm.formData.startdate = moment(res.data.rec[0].startdate).format(
                "YYYY-MM-DD"
              );
            }
  
            if (res.data.rec[0].enddate) {
              vm.formData.enddate = moment(res.data.rec[0].enddate).format(
                "YYYY-MM-DD"
              );
            }
  
            vm.formData.lockstaff = res.data.rec[0].lockstaff;
            vm.pageTitleValue = res.data.rec[0].eisdesc;
            vm.formData.userid = this.$store.getters.userId;
  
            this.loadSsbDetailHandler(vm.formData.id);
  
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      loadSsbDetailHandler(id) {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/mmssbsetupdetail/" + id})
          .then((res) => {
            vm.formData.ssbsetupdetail = res.data.rec;
  
            for (let i = 0; i < vm.formData.ssbsetupdetail.length; i++) {
              Object.assign(vm.formData.ssbsetupdetail[i], { action: 2 });
            }
  
            vm.loading = false;
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      saveHandler() {
        const vm = this;
        if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
          return false;
        }
        this.$store.dispatch('put', {url: "/mmssbsetupdt/" + this.id, payload: vm.formData})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "mmssbsetuplist", params: { search: this.search}  });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      createHandler() {
        this.$router.push({ name: "eisnew" });
      },
  
      cancelHandler() {
        this.$router.push({ name: "mmssbsetuplist" });
      },
  
      deleteHandler() {
        const vm = this;
        if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
          return false;
        }
        this.$store.dispatch('delete', {url:"/mmssbsetupdetail/" + this.id + "/" + this.formData.userid})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "mmssbsetuplist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
    },
  
    mounted() {
      this.loadSsbHandler();
    },
  };
  </script>