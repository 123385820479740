import { render, staticRenderFns } from "./ClaimPayrollPosting.vue?vue&type=template&id=6efc8d0d&scoped=true&"
import script from "./ClaimPayrollPosting.vue?vue&type=script&lang=js&"
export * from "./ClaimPayrollPosting.vue?vue&type=script&lang=js&"
import style0 from "./ClaimPayrollPosting.vue?vue&type=style&index=0&id=6efc8d0d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6efc8d0d",
  null
  
)

export default component.exports