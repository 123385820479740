<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >                                      
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupalert.alertname')"
                                type="text"
                                ref="alerttype"
                                v-model="formData.alerttypeid"
                                :rules="[rules.required]"
                                :items="alertTypeList"                                                               
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.user')"
                                type="text"
                                ref="secuserid"
                                v-model="formData.secuser"
                                :rules="[rules.required]"
                                :items="secUserList"                                                               
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="email"
                                v-model="formData.email"
                                :label="this.$t('setupalert.alertviaemail')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>                       
                    
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                        <h3>{{$t('setupalert.alertperiod')}}</h3>
                        </v-col>
                    </v-row>

                    <v-row>                                                

                        <v-col cols="6" sm="3" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupalert.alertbefore_days')"
                                type="text"
                                ref="alertbefore"
                                :rules="[rules.required]"
                                v-model="formData.alertbefore"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
           

                    <v-row>
                        <v-col cols="6" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupalert.alertafter_days')"
                                type="text"
                                ref="alertafter"
                                v-model="formData.alertafter"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>



export default {
    props: {
        parentObject: Object
    },

    

    data() {
        return {
            formData: {
                alerttypeid: this.parentObject.data.alerttypeid,
                alerttype: this.parentObject.data.alerttype,
                secuser: this.parentObject.data.secuser,               
                isdisable: this.parentObject.data.isdisable,
                email: this.parentObject.data.email,
                alertbefore: this.parentObject.data.alertbefore,
                alertafter: this.parentObject.data.alertafter,
                 search: this.parentObject.data.search,
                userid: this.parentObject.data.userid,
                stamp: this.parentObject.data.stamp
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            
            alertTypeList: [],
            alertType: [],
            secUserList: [],
            secUser: [],
            
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        getalerttypeList() {
            const vm = this;

            this.$store.dispatch('get', {url: "/alerttype/all"})
                .then(res => {
                    vm.alertType = res.data.rec;
                    console.log("vm.alerttype",vm.alertType)
                    for (let i = 0; i < res.data.rec.length; i++) {                        
                        vm.alertTypeList.push(
                            {
                                text: res.data.rec[i].alerttype,
                                value: res.data.rec[i].id
                            }
                        )
                      
                    }

                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        
        getsecuserList() {
            const vm = this;

            this.$store.dispatch('get', {url: "/secuser/all"})
                .then(res => {
                    vm.secUser = res.data.rec;
                    console.log("vm.secuser",vm.secUser)
                    for (let i = 0; i < res.data.rec.length; i++) {                        
                        vm.secUserList.push(
                            {
                                text: res.data.rec[i].userid,
                                value: res.data.rec[i].id
                            }
                        )
                      
                    }

                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        

        changeAlertTypeListHandler(id) {
            let item = this.alertType.find(item => item.id === id);

            this.alertTypeList = item.alerttype;
        },

        saveAlertTypeListHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/alertsetup/' + this.itemData.itemid, payload: {
                    alerttype: this.itemData.itemcode,
                    
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.alertType = [];
                        vm.alertTypeList = [];
                        

                        this.getalerttypeList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/alertsetup/create', payload: {
                    alerttype: vm.itemData.itemcode,
                    alertTypeList: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.alertType = [];
                        vm.alertTypeList = [];
                        

                        this.getalerttypeList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteAlertTypeListHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc+ '?')) {
                this.$store.dispatch('delete', {url: '/alertsetup/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.alertType = [];
                        vm.alertTypeList = [];
                        

                        this.getalerttypeList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        updateValue() {
            this.$emit('input', {
                alerttypeid: this.formData.alerttypeid,
                alerttype: this.formData.alerttype,
                secuser: this.formData.secuser,
                isdisable: this.formData.isdisable,
                email: this.formData.email,
                alertbefore: this.formData.alertbefore,
                alertafter: this.formData.alertafter,
                 search: this.formData.search,
                userid: this.formData.userid,
                stamp: this.formData.stamp
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'alertlist',  params: { search: this.search}});
        }
    },

    mounted() {
        this.getalerttypeList();
        this.getsecuserList();
    }

    
}
</script>