<template>
    <div>
        <v-dialog v-model="emptypedialog" max-width="800px" @click:outside="closeDetail">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{this.$t('intgsetup.configuration')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-form ref="form">
                    <v-row>
                        <v-col cols="8" sm="5" md="6" >
                            <v-autocomplete
                                outlined
                                :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
                                type="text"
                                ref="companyid"
                                :rules="[rules.required]"
                                v-model="selections.companyid"
                                dense
                                :items="companyList"
                                @input="updateValue"
                                @change="changeCompany($event)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="5" md="6" >
                            <v-text-field
                                outlined
                                :label="this.$t('attendancereportbypeiordbycompanyreport.companycode')"
                                type="text"
                                ref="compnycode"
                                v-model="selections.compnycode"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportprocessloglisting.id')"
                                id="refid"
                                type="text"
                                ref="refid"
                                :rules="[rules.required]"
                                v-model="selections.refid"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('intgsetup.key1')"
                                id="refkey1"
                                type="text"
                                ref="refkey1"
                                :rules="[rules.required]"
                                v-model="selections.refkey1"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('intgsetup.key2')"
                                id="refkey2"
                                type="text"
                                ref="refkey2"
                                :rules="[rules.required]"
                                v-model="selections.refkey2"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                  </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDetail">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveDetail">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },
    components: {
    },
//Data call need to modify according to back end controller
    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },

            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                companyid: null,
                compnycode: null,
                compnyname: null,
                refid: null,
                refkey1: null,
                refkey2: null,
                action: 0
            },
            companyname: [],
            companyList: [],

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

//All back end not done and do sql to call in controller so need to 
//make the back end controller for add on group employee
//Methods of calling data need to adjust to suits the form for add on group since the one below 
//is copied from existing crudtable with slight modification

    methods: {
        compareArray,
        
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


    changeCompany(id) {
        let item = this.companyname.find((item) => item.id === id);
        this.selections.companyid = item.id;
        this.selections.compnycode = item.compnycode;

    },

        modifyItemHandler(item) {
          // assign loaded data to selections array
            if (item.action) {
                this.isExisting = true;
            } else {
                this.modify = true;
            }

            this.selections = item;
            this.emptypedialog = true;
            // this.changeGroupTypeHandler(this.selections.type);
            // this.changeGroupHandler(this.selections.id);
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },  
        
        saveDetail() {
            this.isErr = false;
            // this.selections.addongrp = this.selections.code;
            // this.selections.addongrpval = this.selections.text;
            if (!this.isExisting && !this.modify) {
                this.detailItem.push(Object.assign({}, this.selections));

            } else {
                if(!this.modify){
                    this.selections.action = 2;
                }
                if (this.compareArray(this.detailItem, this.selections)) {
                    console.log(this.compareArray(this.detailItem, this.selections))
                }
                    
            }
            // console.log('detailitem', this.detailItem)
            this.$emit('input', this.detailItem);
            this.closeDetail();
        },
        
        closeDetail() {
            this.emptypedialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        
        resetSelections() {
            this.selections = {
                id: null,
                companyid: null,
                compnycode: null,
                compnyname: null,
                refid: null,
                refkey1: null,
                refkey2: null,
                action: 0
            }
        },
    },

    created() {
        this.loadCompanyListHandler();
    }, 
}
</script>