<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="createHandler"
      
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import LeaveToPayrollPostingSetup from "../../../components/leave/leavetopayrollpostingsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LeaveToPayrollPostingSetup,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupleavetopayrollpostingsetup.editleavetopayrollposting'),
      pageTitleValue: "",
      formData: {
        leavetypeid: null,
        payitemid: null,
        remark: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      deletedItems: [],
      insertedItems: [],
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadLeavePayrollPosting() {
      const vm = this;

      this.$store.dispatch('get', {url: "/leavepayrollposting/" + this.id})
        .then((res) => {

            vm.formData.leavetypeid = res.data.rec[0].leavetypeid;
            vm.formData.payitemid = res.data.rec[0].payitemid;
            vm.formData.lockstaff = res.data.rec[0].lockstaff;
            vm.formData.remark = res.data.rec[0].remark;
            vm.formData.stamp = res.data.rec[0].stamp;

            vm.pageTitleValue = res.data.rec[0].ltleavedesc
            
            vm.loading = false;

        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      
      this.$store.dispatch('put', {url: "/leavepayrollposting/" + this.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavetopayrollpostingsetuplist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/leavepayrollposting/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavetopayrollpostingsetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "leavetopayrollpostingsetupnew" });
    },

    cancelHandler() {
      this.$router.push({ name: "leavetopayrollpostingsetuplist" });
    },
  },

  mounted() {
    this.loadLeavePayrollPosting();
  },
};
</script>