<template>
  <div>
    <v-container>
      <form enctype="multipart/form-data">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle
              }}<span class="page_title_value">{{
                this.parentObject.pageTtileValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
          :style="{
            'max-height': `${filterSectionHeight}px`,
          }"
        > -->
        <v-row>
          <v-col cols="10" sm="10" class="mt-n8">
            <v-row v-if="!this.formData.lockstaff">
              <v-col cols="8" sm="8" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="$t('columns.user')"
                  type="text"
                  ref="hrUserid"
                  :items="secuserList"
                  hide-selected
                  v-model="formData.hrUserid"
                  :rules="[rules.required]"
                  @input="updateValue"
                  dense
                  @change="changeSecuserHandler(formData.hrUserid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="8" sm="8" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="$t('columns.user')"
                  type="text"
                  ref="hrUserid"
                  :items="secuserList"
                  readonly
                  v-model="formData.hrUserid"
                  :rules="[rules.required]"
                  background-color="#ffeab2"
                  dense
                 ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="$t('columns.staffname')"
                  type="text"
                  readonly
                  background-color="#ffeab2"
                  dense
                  disabled
                  v-model="username"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="pb-n5 mt-n8">
                <v-tabs>
                  <v-tab>{{$t('columns.company')}}</v-tab>
                  <v-tab>{{$t('columns.group')}}</v-tab>

                  <v-tab-item>
                    <v-row class="mt-5">
                      <v-col col="12" sm="12" class="pb4-n4 mt-n8">
                        <v-data-table
                          :headers="tabCompanyHeader"
                          :items="this.formData.companyvalue"
                          class="elavation-1"
                          fixed-header
                          :sort-by="['code']"
                        >
                          <template v-slot:top>
                            <v-toolbar flat>
                              <v-spacer></v-spacer>
                              <v-dialog
                                v-model="subDialogCompany"
                                max-width="500px"
                                persistent
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    style="background-color: #2980b9"
                                    text
                                    v-on="on"
                                    >+</v-btn
                                  >
                                </template>

                                <v-card>
                                  <app-genericlist
                                    v-bind:parentData="{
                                      tabItems: companyItems,
                                      formTitle: `${$t('setupcompany.companylist')}`,
                                      formId: 'companylist',
                                    }"
                                    v-on:addValue="addCompanyHandler"
                                  />

                                  <v-card-text v-if="isErr">
                                    <span
                                      style="font-weight: bold; color: red"
                                      >{{ errMsg }}</span
                                    >
                                  </v-card-text>

                                  <v-card-actions class="pb-5">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="warning"
                                      @click="closeHandler"
                                      style="margin-right: 20px; width: 100px"
                                      >{{$t('button.close')}}</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-toolbar>
                          </template>

                          <template v-slot:[`item.action`]="{ item }">
                            <v-icon
                              small
                              class="ml-3 mr-2"
                              @click="deleteCompanyHandler(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <v-row class="mt-5">
                      <v-col col="12" sm="12" class="pb4-n4 mt-n8">
                        <v-data-table
                          :headers="tabGroupHeader"
                          :items="this.formData.groupvalue"
                          class="elavation-1"
                          fixed-header
                          :sort-by="['code']"
                        >
                          <template v-slot:top>
                            <v-toolbar flat>
                              <v-spacer></v-spacer>
                              <v-dialog
                                v-model="subDialogGroup"
                                max-width="500px"
                                persistent
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    style="background-color: #2980b9"
                                    text
                                    v-on="on"
                                    >+</v-btn
                                  >
                                </template>

                                <v-card>
                                  <app-genericlist
                                    v-bind:parentData="{
                                      tabItems: groupItems,
                                      formTitle: `${$t('setupgrp.grplist')}`,
                                      formId: 'valvalues',
                                    }"
                                    v-on:addValue="addGroupHandler"
                                  />

                                  <v-card-text v-if="isErr">
                                    <span
                                      style="font-weight: bold; color: red"
                                      >{{ errMsg }}</span
                                    >
                                  </v-card-text>

                                  <v-card-actions class="pb-5">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="warning"
                                      @click="closeHandler"
                                      style="margin-right: 20px; width: 100px"
                                      >{{$t('button.close')}}</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-toolbar>
                          </template>

                          <template v-slot:[`item.action`]="{ item }">
                            <v-icon
                              small
                              class="ml-3 mr-2"
                              @click="deleteGroupHandler(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- </v-container> -->

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </form>
    </v-container>
  </div>
</template>

<script>
import GenericList from "../../generic/GridList";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-genericlist": GenericList,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      formData: {
        hrUserid: this.parentObject.data.hrUserid,
        userid: this.parentObject.data.userid,
        companyvalue: this.parentObject.data.companyvalue,
        groupvalue: this.parentObject.data.groupvalue,
        search: this.parentObject.data.search,
        lockstaff: this.parentObject.data.lockstaff,
        stamp: this.parentObject.data.stamp,
      },
      tabCompanyHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "5px" },
        { text: this.$t('columns.company'), value: "code", width: "200px", sortable: false },
        { text: this.$t('columns.description'), value: "desc", width: "200px", sortable: false },
      ],
      tabGroupHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "5px" },
        { text: this.$t('columns.group'), value: "code", width: "200px", sortable: false },
        { text: this.$t('columns.description'), value: "desc", width: "200px", sortable: false },
      ],
      username: "",
      selected: [],

      secUser: [],
      secuserList: [],

      companyItems: [],
      companyList: [],
      companyListCopy: [],

      groupItems: [],
      groupList: [],
      groupListCopy: [],

      subDialogCompany: false,
      subDialogGroup: false,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
      search: this.parentObject.searchnew
    };
  },
  computed: {
    filterSectionHeight() {
      const filterSectionDOM = document.getElementById("menu");
      return filterSectionDOM ? filterSectionDOM.offsetHeight - 100 : 0;
    },
  },
  methods: {
    loadSecUserListHandler() {
      const vm = this;
      console.log(vm.formData);
      this.$store.dispatch('get', {url: "/secuser/all"})
        .then((res) => {
          vm.secUser = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.secuserList.push({
              text: res.data.rec[i].userid,
              value: res.data.rec[i].id.toString(),
            });
          }

          if (this.parentObject.data.hrUserid) {
            vm.formData.hrUserid = this.parentObject.data.hrUserid;
            vm.changeSecuserHandler(this.parentObject.data.hrUserid);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeSecuserHandler(id) {
      let item = this.secUser.find((item) => item.id == id);
      this.username = item.psnname;
    },

    loadCompanyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          //vm.valueItems = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnycode,
              value: res.data.rec[i].id,
            });

            vm.companyItems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].compnycode,
              desc: res.data.rec[i].compnyname,
            });
          }

          vm.companyListCopy = vm.valueList;

          if (vm.formData.compnycode) {
            vm.changeCompanyVarHandler(vm.formData.compnycode);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    loadGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/groupp_grouppb_security/all"})
        .then((res) => {
          //vm.valueItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.groupList.push({
              text: res.data.rec[i].valuecode,
              value: res.data.rec[i].id,
            });

            vm.groupItems.push({
              id: res.data.rec[i].valvalueid,
              code: res.data.rec[i].valuecode,
              desc: res.data.rec[i].valuedesc,
            });
          }

          vm.groupListCopy = vm.groupList;

          if (vm.formData.valuecode) {
            vm.changeGroupVarHandler(vm.formData.valuecode);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeCompanyVarHandler(id) {
      this.companyVars.find((item) => item.id === id);
    },
    changeGroupVarHandler(id) {
      this.groupVars.find((item) => item.id === id);
    },

    addCompanyHandler($event) {
      console.log(this.formData);
      for (let i = 0; i < this.formData.companyvalue.length; i++) {
        if (this.formData.companyvalue[i].code == $event.code) {
          this.errMsg = "Company already added. Duplicates not allowed";
          this.isErr = true;
          return;
        }
      }

      this.formData.companyvalue.push({
        id: $event.id,
        code: $event.code,
        desc: $event.desc,
        action: 1,
      });

      this.updateValue();
      this.closeHandler();
    },

    addGroupHandler($event) {
      for (let i = 0; i < this.formData.groupvalue.length; i++) {
        if (this.formData.groupvalue[i].code == $event.code) {
          this.errMsg = "Group already added. Duplicates not allowed";
          this.isErr = true;
          return;
        }
      }

      this.formData.groupvalue.push({
        id: $event.id,
        code: $event.code,
        desc: $event.desc,
        action: 1,
      });

      this.updateValue();
      this.closeHandler();
    },

    deleteCompanyHandler(val) {
      this.formData.companyvalue.splice(
        this.formData.companyvalue.findIndex((item) => item.id === val.id),
        1
      );
      this.updateValue();
      this.companyList.push({
        text: val.code,
        value: val.id,
      });
    },

    deleteGroupHandler(val) {
      this.formData.groupvalue.splice(
        this.formData.groupvalue.findIndex((item) => item.id === val.id),
        1
      );
      this.updateValue();
      this.groupList.push({
        text: val.code,
        value: val.id,
      });
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {

      if (this.formData.companyvalue.length == '0' || this.formData.groupvalue.length == '0' ) {
        //  this.isErr = true;
       this.$dialog.alert(this.$t('companyandgrouptablecannotempty')).then((dialog) => {
                console.log(dialog);
             });
          
      } else if (this.formData.companyvalue.length > '0' && this.formData.groupvalue.length > '0') {
      this.$emit("save");
      }
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "dataaccesslist",  params: { search: this.search} });
    },

    closeHandler() {
      this.subDialogCompany = false;
      this.subDialogGroup = false;
      this.isErr = !this.isErr;
      this.errMsg = "";
    },

    updateValue() {
      console.log(this.formData);
      this.$emit("input", {
        hrUserid: this.formData.hrUserid,
        userid: this.formData.userid,
        companyvalue: this.formData.companyvalue,
        search: this.formData.search,
        groupvalue: this.formData.groupvalue,
        lockstaff:this.formData.lockstaff,
        stamp: this.formData.stamp,
      });
    },
  },

  mounted() {
    this.loadSecUserListHandler();
    this.loadCompanyListHandler();
    this.loadGroupListHandler();

     if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>