<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>

<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setuppersonpayrolldirectassignment.personpayrolldirectassignment'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.staffname'), value: "psnname", width: "200px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "200px" },
        { text: this.$t('columns.startdate'), value: "startdate", width: "200px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "200px" },
        { text: this.$t('setuppayitem.payitem'), value: "payitemcode", width: "200px" },
        { text: this.$t('setuppersonpayrolldirectassignment.amount'), value: "amount", width: "200px" },
        { text: this.$t('columns.currency'), value: "crcycode", width: "200px" },
        { text: this.$t('setuppayrolltype.payrolltype'), value: "payrolltypecode", width: "200px" },
        { text: this.$t('payeeinputprocess.justification'), value: "justification", width: "400px" },
        { text: this.$t('columns.remark'), value: "remark", width: "200px" },
      ],

      dataItems: [],
      getId: [],
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    loadPsnPayrollDirectAssignment() {
      const vm = this;

      this.$store.dispatch('get', {url: `/psnpayrolldirectassignment/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.dataItems = res.data.rec;
          console.log(vm.dataItems);

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format(
                "YYYY-MM-DD"
              );
            }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    editItemHandler(item) {
      this.$router.push({
        name: "personpayrolldirectassignmentedit",
        params: { id: item.id, search: item.search },
      });
    },

    newRecordHandler() {
      this.$router.push({ name: "personpayrolldirectassignmentnew" });
    },
  },

  mounted() {
    this.loadPsnPayrollDirectAssignment();
  },
};
</script>