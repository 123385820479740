<template>
    <div>
        <v-data-table
            :headers="tabHeaders"
            :items="this.parentData.tabItems"
            :search="search"
            append-icon="search"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                     <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        style="width: 200px"
                        class="pr-5 pt-3"
                        hide-details
                    >
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="subDialog" max-width="600px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="primary"
                                dark
                                v-on="on"
                                @click="changeSubDialogTitleCreate"
                                >+</v-btn
                            >
                        </template>

                        <v-card>
                            <v-card-title>
                                {{ subDialogTitle }} <v-spacer></v-spacer
                                ><span
                                    class="page_title_value"
                                    v-if="subDialog"
                                    >{{ subDialogTitleValue }}</span
                                >
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row v-if="isPayItemSetup  || isTaxableRebateSetup">
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.code')"
                                                v-model="
                                                    formDataWithType.itemcode
                                                "
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.description')"
                                                v-model="
                                                    formDataWithType.itemdesc
                                                "
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="isLeaveEntitlement">
                                        <v-col cols="8" sm="8" md="8">
                                            <v-autocomplete
                                                outlined
                                                :label="$t('leave')"
                                                v-model="leavetypeid"
                                                :items="leaveTypeSetup"
                                                @input="updateValue"
                                                @change="
                                                    changeLeaveEntitlementHandler(
                                                        leavetypeid
                                                    )
                                                "
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" sm="4" md="4">
                                            <v-text-field
                                                outlined
                                                :label="$t('columns.code')"
                                                background-color="#ffeab2"
                                                v-model="formData.itemcode"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="doctypecode">
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.code')"
                                                v-model="formData.itemcode"
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="addongrp">
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.description')"
                                                v-model="formData.itemdesc"
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.code')"
                                                v-model="formData.itemcode"
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                :label="$t('columns.description')"
                                                v-model="formData.itemdesc"
                                                @input="updateValue"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!-- another row to accommodate payitem setup -->
                                    <v-row v-if="isPayItemSetup">
                                        <v-col>
                                            <v-autocomplete
                                                :label="$t('typedescription')"
                                                :items="payItemTypes"
                                                v-model="
                                                    formDataWithType.typeid
                                                "
                                                @change="
                                                    changePayItemTypeHandler(
                                                        formDataWithType.typeid
                                                    )
                                                "
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                readonly
                                                outlined
                                                background-color="#ffeab2"
                                                :label="$t('typecode')"
                                                v-model="payItemTypeCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="isTaxableRebateSetup">
                                        <v-col>
                                            <v-autocomplete
                                                :label="$t('typedescription')"
                                                :items="taxableRebate"
                                                v-model="
                                                    formDataWithType.typeid
                                                "
                                                @change="
                                                    changeTaxableRebateHandler(
                                                        formDataWithType.typeid
                                                    )
                                                "
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                readonly
                                                outlined
                                                background-color="#ffeab2"
                                                :label="$t('typecode')"
                                                v-model="taxableRebateCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>                                 
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="pb-5">
                                <v-spacer></v-spacer>
                                <app-formbuttons
                                    v-on:SavePressed="saveHandler"
                                    v-on:CancelPressed="cancelHandler"
                                />
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <!-- <template  v-if="doctypecode" v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editHandler(item)">
                    mdi-pen
                </v-icon> -->
                <!-- <v-icon small class="ml-3 mr-2" @click="deleteHandler(item)">
                    mdi-delete
                </v-icon>
            </template> -->

            <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon small class="ml-3 mr-2" @click="deleteHandler(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {
    props: {
        parentData: Object
    },

    data() {
        return {
            search: "",
            formTitle: this.parentData.formTitle,
            formId: this.parentData.formId,
            subDialogTitle: "",
            subDialogTitleValue: "",
            formData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            formDataWithType: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                typeid: null,
                typecode: null,
                typedesc: null,
                itemstamp: 0
            },
            subDialog: false,
            tabHeaders: [
                { text: this.$t('columns.actions'), value: "action", sortable: false },
                { text: this.$t('columns.code'), value: "code" },
                { text: this.$t('columns.description'), value: "desc" }
            ],
            isPayItemSetup: false,
            isLeaveEntitlement: false,
            isTaxableRebateSetup: false,
            payItemTypes: this.parentData.payitemTypes,
            taxableRebate: this.parentData.taxableRebate,
            leaveTypeSetup: this.parentData.leaveTypeSetup,
            payItemTypeCode: "",
            taxableRebateCode: "",
            leavetypeid: null,
            doctypecode: false,
            addongrp: false
        };
    },

    methods: {
        changeSubDialogTitleCreate() {
            this.subDialogTitle = this.$t('reportsparam.createnew') + " " + this.formTitle;
            this.subDialogTitleValue = null;
            

            // reset form
            this.formData.itemcode = null;
            this.formData.itemdesc = null;
            this.formData.itemid = null;
            this.formData.itemstamp = 0;
            

            if (this.formId == "payitemsetup") {
                //this.loadPayItemTypes();

                this.formDataWithType.itemid = null;
                this.formDataWithType.itemcode = null;
                this.formDataWithType.itemdesc = null;
                this.formDataWithType.typeid = null;
                this.formDataWithType.typecode = null;
                this.formDataWithType.typedesc = null;
                this.isPayItemSetup = true;
            } else if (this.formId == "taxablerebatesetup") {
                //this.loadPayItemTypes();
                this.formDataWithType.itemid = null;
                this.formDataWithType.itemcode = null;
                this.formDataWithType.itemdesc = null;
                this.formDataWithType.typeid = null;
                this.formDataWithType.typecode = null;
                this.formDataWithType.typedesc = null;
                this.isTaxableRebateSetup = true;
            } else if (this.formId == "leaveentitlement") {
                this.isLeaveEntitlement = true;
                this.leavetypeid = null;
            } else if (this.formId == 'doctype'){
                this.doctypecode = true;
            } else if (this.formId == 'addongrp'){
                this.addongrp = true;
            }
        },

        changePayItemTypeHandler(id) {
            let item = this.payItemTypes.find(item => item.value === id);

            this.payItemTypeCode = item.code;
            this.formDataWithType.typeid = item.value;
            this.formDataWithType.typecode = item.code;
            this.formDataWithType.typedesc = item.text;

            this.updateValue();
        },
        
        changeTaxableRebateHandler(id) {
            let item = this.taxableRebate.find(item => item.value === id);
            this.taxableRebateCode = item.code;
            this.formDataWithType.typeid = item.value;
            this.formDataWithType.typecode = item.code;
            this.formDataWithType.typedesc = item.text;
            this.updateValue();
        },

        changeLeaveEntitlementHandler(id) {
            let item = this.leaveTypeSetup.find(item => item.value === id);
            this.formData.itemcode = item.code;
            this.formData.itemdesc = item.text;
            this.updateValue();
        },

        updateValue() {
            if (this.formId == "payitemsetup" || this.formId == "taxablerebatesetup") {
                this.$emit("input", {
                    itemid: this.formDataWithType.itemid,
                    itemcode: this.formDataWithType.itemcode,
                    itemdesc: this.formDataWithType.itemdesc,
                    typeid: this.formDataWithType.typeid,
                    typecode: this.formDataWithType.typecode,
                    typedesc: this.formDataWithType.typedesc,
                    itemstamp: this.formDataWithType.itemstamp
                });
            } else if (this.formId == 'leaveentitlement') {
                this.$emit("input", {
                    itemid: this.formData.itemid,
                    leavetypeid: this.leavetypeid,
                    itemcode: this.formData.itemcode,
                    itemdesc: this.formData.itemdesc,
                    itemstamp: this.formData.itemstamp,
                });
            } else if (this.formId == 'doctype') {
                this.$emit("input", {
                    itemid: this.formData.itemid,
                    itemcode: this.formData.itemcode,
                    itemdesc: "edoc",
                    itemstamp: this.formData.itemstamp,
                });
            } else {
                this.$emit("input", {
                    itemid: this.formData.itemid,
                    itemcode: this.formData.itemcode,
                    itemdesc: this.formData.itemdesc,
                    itemstamp: this.formData.itemstamp
                });
            }
        },

        saveHandler($event) {
            if (this.formId == "affecttype") {
                this.$emit("saveAffectType", $event);
            }

            if (this.formId == "city") {
                this.$emit("saveCity", $event);
            }

            if (this.formId == "country") {
                this.$emit("saveCountry", $event);
            }

            if (this.formId == "currency") {
                this.$emit("saveCurrency", $event);
            }

            if (this.formId == "emptypecat") {
                this.$emit("saveEmpTypeCat", $event);
            }

            if (this.formId == "epfgroupsetup") {
                this.$emit("saveEPFGroup", $event);
            }

            if (this.formId == "eisgroupsetup") {
                this.$emit("saveEISGroup", $event);
            }

            if (this.formId == 'exchangeset') {
                this.$emit('saveExchangeSet', $event);
            }

            if (this.formId == "jobcat") {
                this.$emit("saveJobCat", $event);
            }

            if (this.formId == "gradecat") {
                this.$emit("saveGradeCat", $event);
            }

            if (this.formId == "salutation") {
                this.$emit("saveSalutation", $event);
            }

            if (this.formId == "gender") {
                this.$emit("saveGender", $event);
            }

            if (this.formId == "marital") {
                this.$emit("saveMarital", $event);
            }

            if (this.formId == "race") {
                this.$emit("saveRace", $event);
            }

            if (this.formId == "religion") {
                this.$emit("saveReligion", $event);
            }

            if (this.formId == "nation") {
                this.$emit("saveNation", $event);
            }

            if (this.formId == "origin") {
                this.$emit("saveOrigin", $event);
            }

            if (this.formId == "reason") {
                this.$emit("saveReason", $event);
            }

            if (this.formId == "socsogroupsetup") {
                this.$emit("saveSocsoGroup", $event);
            }

            if (this.formId == "taxsetup") {
                this.$emit("saveTaxSetup", $event);
            }

            if (this.formId == "baitulmalsetup") {
                this.$emit("saveBaitulmal", $event);
            }

            if (this.formId == "exemptiongroupsetup") {
                this.$emit("saveExemptionGroup", $event);
            }

            if (this.formId == "epfvegroupsetup") {
                this.$emit("saveEpfveGroup", $event);
            }

            if (this.formId == "bank") {
                this.$emit("saveBank", $event);
            }

            if (this.formId == "expformat") {
                this.$emit("saveExpformat", $event);
            }

            if (this.formId == "edulevel") {
                this.$emit("saveEdulevel", $event);
            }

            if (this.formId == "institution") {
                this.$emit("saveInst", $event);
            }

            if (this.formId == "payprocesssetup") {
                this.$emit("savePayProcessSetup", $event);
            }

            if (this.formId == "payrolltypesetup") {
                this.$emit("savePayrollTypeSetup", $event);
            }

            if (this.formId == "payitemsetup") {
                this.$emit("savePayitemSetup", $event);
            }

            if (this.formId == "proclistmas") {
                this.$emit("saveProcListMas", $event);
            }

            if (this.formId == "payrolltype") {
                this.$emit("savePayrollType", $event);
            }

            if (this.formId == "leaveentitlement") {
                this.$emit("saveLeaveEntitlement", $event);
            }

            if (this.formId == "rebategroupsetup") {
                this.$emit("saveRebateGroup", $event);
            }

            if (this.formId == "taxablerebatesetup") {
                this.$emit("saveTaxableRebateSetup", $event);
            }

            if (this.formId == "disciplin") {
                this.$emit("saveDisciplin", $event);
            }

            if (this.formId == "severelevel") {
                this.$emit("saveSevereLevel", $event);
            }

            if (this.formId == "dsoutcome") {
                this.$emit("saveDisciplinOutcome", $event);
            }

            if (this.formId == "returncondition") {
                this.$emit("saveReturnCondition", $event);
            }

            if (this.formId == "chargecode") {
                this.$emit("saveChargeCode", $event);
            }

            if (this.formId == "claimadjustreason") {
                this.$emit("saveClaimAdjustReason", $event);
            }

             if (this.formId == "leavereason") {
                this.$emit("saveLeaveReason", $event);
            }

             if (this.formId == "adjreason") {
                this.$emit("saveADJReason", $event);
            }

            if (this.formId == "trainingcoursecat") {
                this.$emit("saveTrainingCourseCat", $event);
            }

            if (this.formId == "trainingprovider") {
                this.$emit("saveTrainingProvider", $event);
            }

            if (this.formId == "traininghrdfscheme") {
                this.$emit("saveHRDFScheme", $event);
            }
            
            if (this.formId == "expensetype") {
                this.$emit("saveExpenseType", $event);
            }

            if (this.formId == "trainingchargecode") {
                this.$emit("saveTrainingChargeCode", $event);
            }

            if (this.formId == "insuranceprovider") {
                this.$emit("saveInsuranceProvider", $event);
            }

            if (this.formId == "assessmenttype") {
                this.$emit("saveAssessmentType", $event);
            }

            if (this.formId == "incidentcat") {
                this.$emit("saveIncidentCat", $event);
            }

            if (this.formId == "involvementlevel") {
                this.$emit("saveInvolvementLevel", $event);
            }

            if (this.formId == "outcomecode") {
                this.$emit("saveOutcomeCode", $event);
            }

            if (this.formId == "qualevel") {
                this.$emit("saveQuaLevel", $event);
            }

            if (this.formId == "quafield") {
                this.$emit("saveQuaField", $event);
            }

            if (this.formId == "quamajor") {
                this.$emit("saveQuaMajor", $event);
            }

            if (this.formId == "quainstitution") {
                this.$emit("saveQuaInstitution", $event);
            }

            if (this.formId == "quaresult") {
                this.$emit("saveQuaResult", $event);
            }

             if (this.formId == "venue") {
                this.$emit("saveDomesticVenue", $event);
            }

            if (this.formId == "panelrole") {
                this.$emit("savePanelRole", $event);
            }

            if (this.formId == "doctype") {
                this.$emit("saveDocType", $event);
            }

            if (this.formId == "ssbsetup") {
                this.$emit("saveSsbSetup", $event);
            }

            if (this.formId == "mmtaxsetup") {
                this.$emit("savemmTaxSetup", $event);
            }

            if (this.formId == "addongrp") {
                this.$emit("saveAddOnGrp", $event);
            }

            this.subDialog = false;
        },

        editHandler(item) {
            if (this.formId == "affecttype") {
                this.editAffectTypeHandler(item);
            }
            
            if (this.formId == "city") {
                this.editCityHandler(item);
            }

            if (this.formId == "country") {
                this.editCountryHandler(item);
            }

            if (this.formId == "currency") {
                this.editCurrencyHandler(item);
            }

            if (this.formId == "emptypecat") {
                this.editEmpTypeCatHandler(item);
            }

            if (this.formId == "epfgroupsetup") {
                this.editEpfGroupSetupHandler(item);
            }

            if (this.formId == "eisgroupsetup") {
                this.editEisGroupSetupHandler(item);
            }

            if (this.formId == 'exchangeset') {
                this.editExchangeSetHandler(item);
            }

            if (this.formId == "jobcat") {
                this.editJobCatHandler(item);
            }

            if (this.formId == "gradecat") {
                this.editGradeCatHandler(item);
            }

            if (this.formId == "salutation") {
                this.editSalutationHandler(item);
            }

            if (this.formId == "gender") {
                this.editGenderHandler(item);
            }

            if (this.formId == "marital") {
                this.editMaritalHandler(item);
            }

            if (this.formId == "race") {
                this.editRaceHandler(item);
            }

            if (this.formId == "religion") {
                this.editReligionHandler(item);
            }

            if (this.formId == "nation") {
                //this.editNationHandler(item);
                this.editCountryHandler(item);
            }

            if (this.formId == "origin") {
                //this.editOriginHandler(item);
                this.editCountryHandler(item);
            }

            if (this.formId == "reason") {
                this.editReasonHandler(item);
            }

            if (this.formId == "socsogroupsetup") {
                this.editSocsoGroupSetupHandler(item);
            }

            if (this.formId == "taxsetup") {
                this.editTaxSetupHandler(item);
            }

            if (this.formId == "exemptiongroupsetup") {
                this.editExemptionGroupSetupHandler(item);
            }

            if (this.formId == "baitulmalsetup") {
                this.editBaitulmalSetupHandler(item);
            }

            if (this.formId == "epfvegroupsetup") {
                this.editEpfveGroupSetupHandler(item);
            }

            if (this.formId == "bank") {
                this.editBankHandler(item);
            }

            if (this.formId == "expformat") {
                this.editExpformatHandler(item);
            }

            if (this.formId == "edulevel") {
                this.editEdulevelHandler(item);
            }

            if (this.formId == "institution") {
                this.editInstHandler(item);
            }

            if (this.formId == "payprocesssetup") {
                this.editPayProcessSetupHandler(item);
            }

            if (this.formId == "payrolltypesetup") {
                this.editPayrollTypeSetupHandler(item);
            }

            if (this.formId == "payitemsetup") {
                this.editPayItemSetupHandler(item);
            }

            if (this.formId == "proclistmas") {
                this.editProcListMasHandler(item);
            }

            if (this.formId == "payrolltype") {
                this.editPayrollTypeHandler(item);
            }

            if (this.formId == "leaveentitlement") {
                this.editLeaveEntitlementHandler(item);
            }

            if (this.formId == "rebategroupsetup") {
                this.editRebateGroupSetupHandler(item);
            }
            
            if (this.formId == "taxablerebatesetup") {
                this.editTaxableRebateSetupHandler(item);
            }

            if (this.formId == "disciplin") {
                this.editDisciplinHandler(item);
            }

            if (this.formId == "severelevel") {
                this.editSevereLevelHandler(item);
            }

            if (this.formId == "dsoutcome") {
                this.editDsoutcomeHandler(item);
            }

            if (this.formId == "returncondition") {
                this.editReturnConditionHandler(item);
            }

            if (this.formId == "chargecode") {
                this.editChargeCodeHandler(item);
            }

            if (this.formId == "claimadjustreason") {
                this.editClaimAdjustReasonHandler(item);
            }

             if (this.formId == "leavereason") {
                this.editLeaveReasonHandler(item);
            }

             if (this.formId == "adjreason") {
                this.editADJReasonHandler(item);
            }

            
             if (this.formId == "trainingcoursecat") {
                this.editTrainingCourseCatHandler(item);
            }
  
            if (this.formId == "trainingprovider") {
                this.editTrainingProviderHandler(item);
            }

             if (this.formId == "traininghrdfscheme") {
                this.editHRDFSchemeHandler(item);
            }

             if (this.formId == "expensetype") {
                this.editExpenseTypeHandler(item);
            }

             if (this.formId == "trainingchargecode") {
                this.editTrainingChargeCodeHandler(item);
            }

             if (this.formId == "insuranceprovider") {
                this.editInsuranceProviderHandler(item);
            }

             if (this.formId == "assessmenttype") {
                this.editAssessmentTypeHandler(item);
            }

             if (this.formId == "incidentcat") {
                this.editIncidentCatHandler(item);
            }

              if (this.formId == "involvementlevel") {
                this.editInvolvementLevelHandler(item);
            }

             if (this.formId == "outcomecode") {
                this.editOutcomeHandler(item);
            }

            if (this.formId == "qualevel") {
                this.editQuaLevelHandler(item);
            }

            if (this.formId == "quafield") {
                this.editQuaFieldHandler(item);
            }

            if (this.formId == "quamajor") {
                this.editQuaMajorHandler(item);
            }

            if (this.formId == "quainstitution") {
                this.editQuaInstitutionHandler(item);
            }

            if (this.formId == "quaresult") {
                this.editQuaResultHandler(item);
            }

             if (this.formId == "venue") {
                this.editDomesticVenueHandler(item);
            }

            if (this.formId == "panelrole") {
                this.editPanelRoleHandler(item);
            }

            if (this.formId == "doctype") {
                this.editDocTypeHandler(item);
            }

            if (this.formId == "ssbsetup") {
                this.editSsbSetupHandler(item);
            }

            if (this.formId == "mmtaxsetup") {
                this.editMmTaxRateSetupHandler(item);
            }

            if (this.formId == "addongrp") {
                this.editAddOnGrpHandler(item);
            }

        },

        deleteHandler(item) {
            if (this.formId == "affecttype") {
                this.$emit("deleteAffectType", item);
            }

            if (this.formId == "city") {
                this.$emit("deleteCity", item);
            }

            if (this.formId == "country") {
                this.$emit("deleteCountry", item);
            }

            if (this.formId == "currency") {
                this.$emit("deleteCurrency", item);
            }

            if (this.formId == "emptypecat") {
                this.$emit("deleteEmpTypeCat", item);
            }

            if (this.formId == "epfgroupsetup") {
                this.$emit("deleteEPFGroup", item);
            }

            if (this.formId == "eisgroupsetup") {
                this.$emit("deleteEISGroup", item);
            }

            if (this.formId == 'exchangeset') {
                this.$emit('deleteExchangeset', item);
            }

            if (this.formId == "jobcat") {
                this.$emit("deleteJobCat", item);
            }

            if (this.formId == "gradecat") {
                this.$emit("deleteGradeCat", item);
            }

            if (this.formId == "salutation") {
                this.$emit("deleteSalutation", item);
            }

            if (this.formId == "gender") {
                this.$emit("deleteGender", item);
            }

            if (this.formId == "marital") {
                this.$emit("deleteMarital", item);
            }

            if (this.formId == "race") {
                this.$emit("deleteRace", item);
            }

            if (this.formId == "religion") {
                this.$emit("deleteReligion", item);
            }

            if (this.formId == "nation") {
                //this.$emit('deleteNation', item);
                this.$emit("deleteCountry", item);
            }

            if (this.formId == "origin") {
                //this.$emit('deleteOrigin', item);
                this.$emit("deleteCountry", item);
            }

            if (this.formId == "reason") {
                this.$emit("deleteReason", item);
            }

            if (this.formId == "socsogroupsetup") {
                this.$emit("deleteSocsoGroup", item);
            }

            if (this.formId == "taxsetup") {
                this.$emit("deleteTaxSetup", item);
            }

            if (this.formId == "exemptiongroupsetup") {
                this.$emit("deleteExemptionGroup", item);
            }

            if (this.formId == "baitulmalsetup") {
                this.$emit("deleteBaitulmal", item);
            }

            if (this.formId == "epfvegroupsetup") {
                this.$emit("deleteEpfveGroup", item);
            }

            if (this.formId == "bank") {
                this.$emit("deleteBank", item);
            }

            if (this.formId == "expformat") {
                this.$emit("deleteExpformat", item);
            }

            if (this.formId == "edulevel") {
                this.$emit("deleteEdulevel", item);
            }

            if (this.formId == "institution") {
                this.$emit("deleteInst", item);
            }

            if (this.formId == "payprocesssetup") {
                this.$emit("deletePayProcessSetup", item);
            }

            if (this.formId == "payrolltypesetup") {
                this.$emit("deletePayrollTypeSetup", item);
            }

            if (this.formId == "payitemsetup") {
                this.$emit("deletePayItemSetup", item);
            }

            if (this.formId == "proclistmas") {
                this.$emit("deleteProcListMas", item);
            }

            if (this.formId == "payrolltype") {
                this.$emit("deletePayrollType", item);
            }

            if (this.formId == "leaveentitlement") {
                this.$emit("deleteLeaveEntitlement", item);
            }
            
            if (this.formId == "rebategroupsetup") {
                this.$emit("deleteRebateGroup", item);
            }

            if (this.formId == "taxablerebatesetup") {
                this.$emit("deleteTaxableRebateSetup", item);
            }

            if (this.formId == "disciplin") {
                this.$emit("deleteDisciplin", item);
            }

            if (this.formId == "severelevel") {
                this.$emit("deleteSevereLevel", item);
            }

            if (this.formId == "dsoutcome") {
                this.$emit("deleteDisciplinOutcome", item);
            }

            if (this.formId == "returncondition") {
                this.$emit("deleteReturnCondition", item);
            }

            if (this.formId == "chargecode") {
                this.$emit("deleteChargeCode", item);
            }

            if (this.formId == "claimadjustreason") {
                this.$emit("deleteClaimAdjustReason", item);
            }

            if (this.formId == "leavereason") {
                this.$emit("deleteLeaveReason", item);
            }

            if (this.formId == "adjreason") {
                this.$emit("deleteADJReason", item);
            }

            if (this.formId == "trainingcoursecat") {
                this.$emit("deleteTrainingCourseCat", item);
            }

             if (this.formId == "trainingprovider") {
                this.$emit("deleteTrainingProvider", item);
            }

            if (this.formId == "traininghrdfscheme") {
                this.$emit("deleteHRDFScheme", item);
            }

             if (this.formId == "expensetype") {
                this.$emit("deleteExpenseType", item);
            }

            if (this.formId == "trainingchargecode") {
                this.$emit("deleteTrainingChargeCode", item);
            }

             if (this.formId == "insuranceprovider") {
                this.$emit("deleteInsuranceProvider", item);
            }

             if (this.formId == "assessmenttype") {
                this.$emit("deleteAssessmentType", item);
            }

            if (this.formId == "incidentcat") {
                this.$emit("deleteIncidentCat", item);
            }

            if (this.formId == "involvementlevel") {
                this.$emit("deleteInvolvementLevel", item);
            }

            if (this.formId == "outcomecode") {
                this.$emit("deleteOutcomeCode", item);
            }

            if (this.formId == "qualevel") {
                this.$emit("deleteQuaLevel", item);
            }

            if (this.formId == "quafield") {
                this.$emit("deleteQuaField", item);
            }

            if (this.formId == "quamajor") {
                this.$emit("deleteQuaMajor", item);
            }

            if (this.formId == "quainstitution") {
                this.$emit("deleteQuaInstitution", item);
            }

            if (this.formId == "quaresult") {
                this.$emit("deleteQuaResult", item);
            }

            if (this.formId == "venue") {
                this.$emit("deleteDomesticVenue", item);
            }

            if (this.formId == "panelrole") {
                this.$emit("deletePanelRole", item);
            }

            if (this.formId == "doctype") {
                this.$emit("deleteDocType", item);
            }

            if (this.formId == "ssbsetup") {
                this.$emit("deleteSsbSetup", item);
            }

            if (this.formId == "mmtaxsetup") {
                this.$emit("deletemmTaxSetup", item);
            }

            if (this.formId == "addongrp") {
                this.$emit("deleteAddOnGrp", item);
            }
            
        },
        editAffectTypeHandler(item) {
            const vm = this;
            this.$store.dispatch('get', {
                url:  "/affecttype/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].affecttypecode;
                    vm.formData.itemdesc = res.data.rec[0].affecttypedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;
                    vm.subDialogTitleValue = res.data.rec[0].affecttypedesc;
                    vm.subDialogTitle = this.$t('rechruserfunctionaccess.edit') + " " + vm.formTitle;
                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editCityHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/city/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].citycode;
                    vm.formData.itemdesc = res.data.rec[0].cityname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].cityname;
                    vm.subDialogTitle = "Edit " + vm.formTitle;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editCountryHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/country/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].cntrycode;
                    vm.formData.itemdesc = res.data.rec[0].cntryname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].cntryname;
                    vm.subDialogTitle = "Edit " + vm.formTitle;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editCurrencyHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/currency/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].crcycode;
                    vm.formData.itemdesc = res.data.rec[0].crcydesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].crcydesc;
                    vm.subDialogTitle = "Edit " + vm.formTitle;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editEmpTypeCatHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/emptypecat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].emptypecat;
                    vm.formData.itemdesc = res.data.rec[0].emptypecatdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editEpfGroupSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/epfsetup/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].epfgroupcode;
                    vm.formData.itemdesc = res.data.rec[0].epfgroupdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editEisGroupSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/eis/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].eiscode;
                    vm.formData.itemdesc = res.data.rec[0].eisdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editExchangeSetHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/exchangerate/' + item.id})
            .then(res => {
                vm.formData.itemid = res.data.rec[0].id;
                vm.formData.itemcode = res.data.rec[0].exchratecode;
                vm.formData.itemdesc = res.data.rec[0].exchratedesc;
                vm.formData.itemstamp = res.data.rec[0].stamp;

                vm.subDialogTitleValue = res.data.rec[0].exchratedesc;
                vm.subDialogTitle = 'Edit ' + vm.formTitle;

                this.subDialog = true;
            })
            .catch(err => {
                console.log(err);
            })
        },

        editJobCatHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/jobcat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].jobcat;
                    vm.formData.itemdesc = res.data.rec[0].jobcatdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editGradeCatHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/gradecat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].grdcat;
                    vm.formData.itemdesc = res.data.rec[0].grdcatdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editSalutationHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/salutation/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].salutcode;
                    vm.formData.itemdesc = res.data.rec[0].salutname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editGenderHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/gender/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].gendercode;
                    vm.formData.itemdesc = res.data.rec[0].gendername;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editMaritalHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/maritalstat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].marstatcode;
                    vm.formData.itemdesc = res.data.rec[0].marstatname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editRaceHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/race/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].racecode;
                    vm.formData.itemdesc = res.data.rec[0].racename;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editReligionHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/religion/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].rlgncode;
                    vm.formData.itemdesc = res.data.rec[0].rlgnname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editNationHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/nation/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].ntlycode;
                    vm.formData.itemdesc = res.data.rec[0].ntlyname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editOriginHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/countryorig/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].cntryocode;
                    vm.formData.itemdesc = res.data.rec[0].cntryoname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editReasonHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/reason/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].reasoncode;
                    vm.formData.itemdesc = res.data.rec[0].reasondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editSocsoGroupSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/socso/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].socsocode;
                    vm.formData.itemdesc = res.data.rec[0].socsodesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editTaxSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/taxset/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].pcbsetcode;
                    vm.formData.itemdesc = res.data.rec[0].pcbsetdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editExemptionGroupSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/taxexemption/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].exemptioncode;
                    vm.formData.itemdesc = res.data.rec[0].exemptiondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editBaitulmalSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/baitulmal/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].baitulmalcode;
                    vm.formData.itemdesc = res.data.rec[0].baitulmaldesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editEpfveGroupSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/epfve/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].epfvecode;
                    vm.formData.itemdesc = res.data.rec[0].epfvedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editBankHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/bank/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].bankcode;
                    vm.formData.itemdesc = res.data.rec[0].bankname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editExpformatHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/expformat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].expcode;
                    vm.formData.itemdesc = res.data.rec[0].expdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editEdulevelHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/edulevel/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].edulevelcode;
                    vm.formData.itemdesc = res.data.rec[0].eduleveldesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editInstHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/institution/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].instcode;
                    vm.formData.itemdesc = res.data.rec[0].instdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editPayProcessSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:"/proclistmassetup/" + item.id
        })
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].proclistmascode;
                    vm.formData.itemdesc = res.data.rec[0].proclistmasdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editPayrollTypeSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:"/payrolltypesetup/" + item.id
        })
                .then((res) => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].payrolltypecode;
                    vm.formData.itemdesc = res.data.rec[0].payrolltypedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        editPayItemSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/payitem/code/${item.id}`})
                .then(res => {
                    vm.formDataWithType.itemid = res.data.rec[0].id;
                    vm.formDataWithType.itemcode = res.data.rec[0].payitemcode;
                    vm.formDataWithType.itemdesc = res.data.rec[0].payitemdesc;
                    vm.formDataWithType.typeid = res.data.rec[0].payitemtypeid;
                    vm.formDataWithType.typecode =
                        res.data.rec[0].payitemtypecode;
                    vm.formDataWithType.typedesc =
                        res.data.rec[0].payitemtypedesc;
                    vm.formDataWithType.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].payitemdesc;
                    vm.subDialogTitle = "Edit " + vm.formTitle;

                    if (vm.formDataWithType.itemid) {
                        vm.changePayItemTypeHandler(
                            res.data.rec[0].payitemtypeid
                        );
                    }

                    vm.isPayItemSetup = true;

                    this.subDialog = true;
                });
        },

        editProcListMasHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/proclistmascode/${item.id}`})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].proclistmascode;
                    vm.formData.itemdesc = res.data.rec[0].proclistmasdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].proclistmasdesc;
                    vm.subDialogTitle = `Edit ${vm.formTitle}`;

                    this.subDialog = true;
                });
        },

        editPayrollTypeHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/payrolltypecode/${item.id}`})
                .then((res) => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].payrolltypecode;
                    vm.formData.itemdesc = res.data.rec[0].payrolltypedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].payrolltypedesc;
                    vm.subDialogTitle = `Edit ${vm.formTitle}`;

                    this.subDialog = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        editLeaveEntitlementHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/leaveentitlement/${item.id}`})
                .then((res) => {
                    console.log('check', res.data.rec)
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.leavetypeid = res.data.rec[0].leavetypeid;
                    vm.formData.itemcode = res.data.rec[0].leavecode;
                    vm.formData.itemdesc = res.data.rec[0].leavedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.subDialogTitleValue = res.data.rec[0].leavedesc;
                    vm.subDialogTitle = `Edit ${vm.formTitle}`;
                    console.log('leavetypeid', vm.leavetypeid
                    )

                    vm.isLeaveEntitlement = true;

                    this.subDialog = true;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        editRebateGroupSetupHandler(item) {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/taxablerebategroup/code/${item.id}`})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].rebategroupcode;
                    vm.formData.itemdesc = res.data.rec[0].rebategroupdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;
                    vm.subDialogTitleValue = res.data.rec[0].rebategroupdesc;
                    vm.subDialogTitle = `Edit ${vm.formTitle}`;
                    this.subDialog = true;
                });
        },
        
        editTaxableRebateSetupHandler(item) {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/taxablerebatesetup/code/${item.id}`})
                .then(res => {
                    vm.formDataWithType.itemid = res.data.rec[0].id;
                    vm.formDataWithType.itemcode = res.data.rec[0].rebatetypecode;
                    vm.formDataWithType.itemdesc = res.data.rec[0].rebatetypedesc;
                    vm.formDataWithType.typeid = res.data.rec[0].rebategroupid;
                    vm.formDataWithType.typecode = res.data.rec[0].rebategroupcode;
                    vm.formDataWithType.typedesc = res.data.rec[0].rebategroupdesc;
                    vm.formDataWithType.itemstamp = res.data.rec[0].stamp;
                    vm.subDialogTitleValue = res.data.rec[0].rebatetypedesc;
                    vm.subDialogTitle = "Edit " + vm.formTitle;
                    if (vm.formDataWithType.itemid) {
                        vm.changeTaxableRebateHandler(
                            res.data.rec[0].rebategroupid
                        );
                    }
                    vm.isTaxableRebateSetup = true;
                    this.subDialog = true;
                });
        },

        editDisciplinHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/disciplinaryrecord/disciplin/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].disciplincode;
                    vm.formData.itemdesc = res.data.rec[0].disciplindesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editSevereLevelHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/disciplinaryrecord/severelevel/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].sevlevelcode;
                    vm.formData.itemdesc = res.data.rec[0].sevleveldesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editDsoutcomeHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/disciplinaryrecord/dsoutcome/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].outcomecode;
                    vm.formData.itemdesc = res.data.rec[0].outcomedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editReturnConditionHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/assetassignment/returncondition/' + item.id})
            .then(res => {
                vm.formData.itemid = res.data.rec[0].id;
                vm.formData.itemcode = res.data.rec[0].assetcondition;
                vm.formData.itemdesc = res.data.rec[0].assetconditiondesc;
                vm.formData.itemstamp = res.data.rec[0].stamp;

                vm.subDialogTitleValue = res.data.rec[0].assetconditiondesc;
                vm.subDialogTitle = 'Edit ' + vm.formTitle;

                this.subDialog = true;
            })
            .catch(err => {
                console.log(err);
            })
        },

        editChargeCodeHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/chargecode/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].chargecode;
                    vm.formData.itemdesc = res.data.rec[0].chargecodedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

         editClaimAdjustReasonHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/claimadjustmentreason/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].clmadjreason;
                    vm.formData.itemdesc = res.data.rec[0].clmadjreasondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

         editLeaveReasonHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/leavereason/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].reasoncode;
                    vm.formData.itemdesc = res.data.rec[0].reasondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editADJReasonHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/adjreason/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].adjreasoncode;
                    vm.formData.itemdesc = res.data.rec[0].adjreasondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

          editTrainingCourseCatHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/trainingcoursecat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].coursecatcode;
                    vm.formData.itemdesc = res.data.rec[0].coursecatname;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

          editTrainingProviderHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/trainingprovider/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].providercode;
                    vm.formData.itemdesc = res.data.rec[0].providername;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

          editHRDFSchemeHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/trnhrdfscheme/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].hrdfscheme;
                    vm.formData.itemdesc = res.data.rec[0].hrdfschemename;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

         editExpenseTypeHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/expensetype/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].expensecode;
                    vm.formData.itemdesc = res.data.rec[0].expensename;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

         editTrainingChargeCodeHandler(item){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/trainingchargecode/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].chargecode;
                    vm.formData.itemdesc = res.data.rec[0].chargecodename;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editInsuranceProviderHandler(item){
             const vm = this;

            this.$store.dispatch('get', {
                url:  "/insuranceprovider/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].insprovidercode;
                    vm.formData.itemdesc = res.data.rec[0].insprovidername;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editAssessmentTypeHandler(item){
         const vm = this;

            this.$store.dispatch('get', {
                url:  "/assessmenttype/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].assessmenttypecode;
                    vm.formData.itemdesc = res.data.rec[0].assessmenttypedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editIncidentCatHandler(item){
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/incidentcat/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].irincidentcatcode;
                    vm.formData.itemdesc = res.data.rec[0].irincidentcatdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editInvolvementLevelHandler(item){

            const vm = this;

            this.$store.dispatch('get', {
                url:  "/involvementlvl/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].involvementlvlcode;
                    vm.formData.itemdesc = res.data.rec[0].involvementlvldesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

         editOutcomeHandler(item){

            const vm = this;

            this.$store.dispatch('get', {
                url:  "/outcome/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].outcomecode;
                    vm.formData.itemdesc = res.data.rec[0].outcomedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editQuaLevelHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/qualevel/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].levelcode;
                    vm.formData.itemdesc = res.data.rec[0].leveldesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editQuaFieldHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/quafield/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].fieldcode;
                    vm.formData.itemdesc = res.data.rec[0].fielddesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editQuaMajorHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/quamajor/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].majorcode;
                    vm.formData.itemdesc = res.data.rec[0].majordesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editQuaInstitutionHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/quainstitution/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].institutioncode;
                    vm.formData.itemdesc = res.data.rec[0].institutiondesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editQuaResultHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/quaresult/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].resultcode;
                    vm.formData.itemdesc = res.data.rec[0].resultdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },


         editDomesticVenueHandler(item){

            const vm = this;

            this.$store.dispatch('get', {
                url:  "/irdomesticvenue/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].irdomesticvenuecode;
                    vm.formData.itemdesc = res.data.rec[0].irdomesticvenuedesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editPanelRoleHandler(item){

            const vm = this;

            this.$store.dispatch('get', {
                url:  "/irdomesticpanel/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].irdomesticpanelroledesc;
                    vm.formData.itemdesc = res.data.rec[0].irdomesticpanelroledesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });

        },

        editDocTypeHandler(item) {
            const vm = this;

            console.log(item.id);

            this.$store.dispatch('get', {
                url:  "/documenttypeby/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].doctypecode;
                    vm.formData.itemdesc = res.data.rec[0].src;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    vm.doctypecode = true;
                    vm.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editSsbSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/mmssbsetup/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].ssbgroupcode;
                    vm.formData.itemdesc = res.data.rec[0].ssbgroupdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editMmTaxRateSetupHandler(item) {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/mmtaxratesetup/" + item.id})
                .then(res => {
                    vm.formData.itemid = res.data.rec[0].id;
                    vm.formData.itemcode = res.data.rec[0].taxsetupcode;
                    vm.formData.itemdesc = res.data.rec[0].taxsetupdesc;
                    vm.formData.itemstamp = res.data.rec[0].stamp;

                    this.subDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        editAddOnGrpHandler(item) {
            const vm = this;
            vm.formData.itemid = item.id;
            vm.formData.itemcode = item.code;
            vm.formData.itemdesc = item.desc;
            vm.addongrp = true;
            vm.subDialog = true;
        },

        cancelHandler() {
            this.subDialog = false;
        }

        // loadPayItemTypes() {
        //     console.log(this.parentData.payitemTypes);
        //     this.payItemTypes = this.parentData.payitemTypes;
        // }
    }
};
</script>

<style lang="scss">
table {
    background: #f8f9f9;
}

.v-data-table td {
    font-size: 14px;
    height: 38px;
}

.class-on-data-table table {
    table-layout: fixed;
}
</style>