<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    
                <v-row>
                    <v-col cols="12" sm="8" md="8" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupaffecttype.affecttype')"
                            type="text"
                            dense
                            v-model="formData.affecttypeid"
                            :items="affectTypeList"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="8" md="8" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupaffecttype.affecttype')"
                            type="text"
                            dense
                            readonly
                            background-color="#ffeab2"
                            v-model="formData.affecttypeid"
                            :items="affectTypeList"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5">
                        <v-dialog v-model="affecttypeDialog" max-width="600px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: affectTypeItems,
                                        formTitle: `${this.$t('setupaffecttype.affecttype')}`,
                                        formId: 'affecttype'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveAffectType="saveAffectTypeHandler"
                                    v-on:deleteAffectType="deleteAffectTypeHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="affecttypeDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="10" sm="10" class="pb4-n4 mt-n8">
                            <v-data-table
                                :headers="tabHeader"
                                :items="this.formData.affectitems"
                                class="elavation-1"
                                fixed-header

                                :sort-by="['code']"
                            >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title> {{$t('setupaffecttype.affectitems')}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="subDialog" max-width="600px" persistent>
                                        <template v-slot:activator= "{ on }">
                                            <v-icon text v-on="on">+</v-icon>
                                        </template>

                                        <v-card>

                                            <app-genericlist 
                                                 v-bind:parentData = "{
                                                    tabItems: affectItems,
                                                    formTitle: `${$t('setupaffecttype.affectitems')}`,
                                                    formId: 'affectitems'
                                                }"

                                                v-on:addAffectItem="addItemHandler"
                                            />


                                            
                                            <v-card-text v-if="isErr">
                                                <span style="font-weight: bold; color: red">{{errMsg}}</span>
                                            </v-card-text>

                                            <v-card-actions class="pb-5">
                                                <v-spacer></v-spacer>
                                                <v-btn 
                                                    color="warning" 
                                                    @click="closeHandler" 
                                                    style="margin-right:20px; width: 100px">{{$t('button.close')}}</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>

                            <template v-slot:item.action="{ item }">
                                <v-icon
                                    small
                                    class="ml-3 mr-2"
                                    @click="deleteItemHandler(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import GenericList from '../../generic/GridList';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericlist': GenericList,
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
                affecttypeid: this.parentObject.data.affecttypeid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                affectitems: this.parentObject.data.affectitems,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width: '5px' },
                { text: this.$t('columns.code'), value: 'code', width: '200px', sortable: false},
                { text: this.$t('columns.description'), value: 'desc', width: '300px', sortable: false },
            ],
            selected: [],

            affectItems: [],
            affectItemList: [],
            affectItemsListCopy: [],
            affectTypeList: [],
            affectTypes: [],
            affectTypeItems: [],
            affectVarCode: '',
            
            subDialog: false,
            affecttypeDialog: false,
            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        loadAffectTypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/affecttype/all'})
                .then(res => {
                    vm.affectTypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.affectTypeList.push(
                            {
                                text: res.data.rec[i].affecttypedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.affectTypeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].affecttypecode,
                                desc: res.data.rec[i].affecttypedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveAffectTypeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/affecttype/' + this.itemData.itemid, 
                    payload: {
                        affecttypecode: this.itemData.itemcode,
                        affecttypedesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.affectTypes = [];
                        vm.affectTypeItems = [];
                        vm.affectTypeList = [];

                        this.loadAffectTypeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/affecttype/create', 
                    payload: {
                        affecttypecode: this.itemData.itemcode,
                        affecttypedesc: this.itemData.itemdesc,
                        userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.affectTypes = [];
                        vm.affectTypeItems = [];
                        vm.affectTypeList = [];

                        this.loadAffectTypeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteAffectTypeHandler($event) {
            const vm = this;
            console.log('check early')

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/affecttype/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        console.log('check')
                        // resets all arrays
                        vm.affectTypes = [];
                        vm.affectTypeItems = [];
                        vm.affectTypeList = [];

                        this.loadAffectTypeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        loadAffectItemsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/affectitem/all'})
                .then(res => {
                    //vm.affectItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.affectItemList.push(
                            {
                                text: res.data.rec[i].affectitemdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.affectItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].affectitemcode,
                                desc: res.data.rec[i].affectitemdesc,
                            }
                        )
                    } 

                    vm.affectItemsListCopy = vm.affectItemList;

                    if (vm.formData.affectitemid) {
                        vm.changeAffectVarHandler(vm.formData.affectvarid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeAffectVarHandler(id) {
            let item = this.affectVars.find(item => item.id === id);

            this.affectVarCode = item.varcode;
        },

        updateValue() {
            this.$emit('input', {
                affecttypeid: this.formData.affecttypeid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                affectitems: this.formData.affectitems,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        addItemHandler($event) {

            for (let i = 0; i < this.formData.affectitems.length; i++) {

                if (this.formData.affectitems[i].code == $event.code) {
                    this.errMsg = this.$t('setupaffecttype.affecttypeerrormsg')
                    console.log('check')
                    this.isErr = true;

                    return
                }
            }

            console.log('event', $event)

            this.formData.affectitems.push(
                {
                    id: $event.id, 
                    code: $event.code, 
                    desc: $event.desc, 
                    action: 1
                })


            this.updateValue();
            this.closeHandler();
        },

        deleteItemHandler(val) {
            //this.$emit('deleteItem', val.id);

            let itemIndex = this.formData.affectitems.indexOf(val);

            this.formData.affectitems.splice(itemIndex,1)

            this.updateValue();
            
            this.affectItemList.push(
                {
                    text: val.code,
                    value: val.id
                }
            );
        },

        cancelHandler() {
            this.$router.push({name: 'affecttypelist', params: { search: this.search}});
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = false;
            this.errMsg = '';
        }
    },

    mounted() {
        this.loadAffectItemsListHandler();
        this.loadAffectTypeListHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>