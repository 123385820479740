<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode,
                isWizard: this.isWizard
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            />
        </div>    
    </div>
</template>

<script>
import KpiSettingForm from '../../../components/performancemanagement/targetsetting/Form_StaffExercise';
import ErrorDialog from '../../../components/common/ErrorDialog';
//import moment from 'moment';


export default {
    components: {
        'app-form': KpiSettingForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: 'KPI Setting for',
            pageTitleValue: '<< Exercise Description >>',
            
            formData: {
                // asofdate1: moment().format("YYYY-MM-DD"),
                // asofdate2: moment().format("YYYY-MM-DD"),
                // compnyid: null,
                // compnycode: null,
                
                },
            

            dataItems:[],

            goBtn: false,

            
            codeList: [],            

        errMsg: '',
        isErr:false,
        editMode: false,
        isWizard: false,
        }
    },
        

    methods: {
        
        
    }
}

</script>