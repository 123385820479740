var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.id == 44)?_c('kbz-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            salexprecid: this.salexprecid,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 1)?_c('maybank-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 42)?_c('maybank-form2',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 7)?_c('hlb-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 12)?_c('ambank-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 17)?_c('rhb-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 26)?_c('rhb-smart-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 19)?_c('cimb-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 31)?_c('public-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 32)?_c('affin-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(this.id == 37)?_c('hsbc-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            parameter: this.parameter,
            errMsg: this.errMsg,
        }},on:{"input":function($event){_vm.formData = $event}}}):_vm._e(),(_vm.isErr)?_c('div',[_c('app-error',{attrs:{"parentError":{
                data: this.errMsg,
                isErr: this.isErr
            }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }