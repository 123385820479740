<template>
    <div id="edocgrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>
<script>
import moment from 'moment';
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            // pageTitle: this.$t('recpersonalinfo.personlist'), 
            pageTitle: 'E-Document List', 
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: 'Document Type', value: 'doctypecode', width: '200px' },
                { text: 'Title', value: 'title', width: '200px' },
                { text: 'Description', value: 'description', width: '300px' },
                { text: 'Start Date', value: 'startdate', width: '200px' },
                { text: 'End Date', value: 'enddate', width: '200px' },
                { text: 'Status', value: 'isdisable', width: '200px' },
                { text: 'Updated By', value: 'psnname', width: '200px' }
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getEdocListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: `/edoc/all/`})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    // console.log('Hello', vm.dataItems);

                    for (let i = 0; i < vm.dataItems.length; i++) {

                        if (vm.dataItems[i].startdate) {
                            vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                        }
                        if (vm.dataItems[i].enddate) {
                            vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                        }

                        (vm.dataItems[i].isdisable == 0) ? vm.dataItems[i].isdisable = "Disable" : vm.dataItems[i].isdisable = "Enable"

                    }

                    console.log(vm.dataItems);

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            console.log(item);
            this.$router.push({name: 'edocedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'edocnew'});
        }
    },

    mounted() {
        this.getEdocListHandler();
    }
}
</script>