<template>
    <div>
        <v-dialog v-model="trainingrecorddialog" max-width="1200px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
           >+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('trainingrecord.trainingexpenses')}}</span>
              <!--<span class="headline mb-4">Training Expenses</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingrecord.expensetype')"
                                type="text"
                                ref="expensename"
                                clearable
                                :items="expensetypeList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="selections[0].trnexpensetypeid"
                                @change="changeExpenseTypeHandler(selections[0].trnexpensetypeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
            

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="selections[0].trnexpensecode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="expensetypeDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: expensetypeItems,
                                            formTitle: this.$t('trainingrecord.expensetype'),
                                            formId: 'expensetype'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveExpenseType="saveExpenseTypeHandler"
                                        v-on:deleteExpenseType="deleteExpenseTypeHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="expensetypeDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>


                    <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('trainingrecord.amount')"
                            type="text"
                             :rules="[rules.required]"
                            v-model="selections[0].amount"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcydesc"
                                :rules="[rules.required]"
                                :items="currencyList"
                                clearable
                            
                                hide-selected
                                v-model="selections[0].currencyid"
                                @change="changeCurrencyHandler(selections[0].currencyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="selections[0].crcycode"
                            ></v-text-field>
                        </v-col>
                 </v-row>
        
                   
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('claimrecordlisting.chargecode')"
                                type="text"
                                ref="chargecodename"
                                clearable
                                :items="trnchargeList"
                                hide-selected
                                v-model="selections[0].trnchargecodeid"
                                @change="changeTrainingChargeCodeHandler(selections[0].trnchargecodeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="selections[0].trnchargecode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="trainingchargeDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: trnchargesItems,
                                            formTitle: this.$t('trainingrecord.trainingchargecode'),
                                            formId: 'trainingchargecode'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveTrainingChargeCode="saveTrainingChargeCodeHandler"
                                        v-on:deleteTrainingChargeCode="deleteTrainingChargeCodeHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="trainingchargeDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                     <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            v-model="selections[0].remark"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>

                     </v-row>
             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeTrainingExpenseInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveTrainingExpenseInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            trainingrecorddialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                trnrecordid: null,
                trnexpensetypeid: null,
                trnexpensecode: null,
                trnexpensename:null,
                amount: null ,
                currencyid: null,
                crcycode: null,
                crcydesc: null,
                trnchargecodeid: null,
                trnchargecode: null,
                chargecodename: null,
                remark: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],
            

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            trnexpensecode: '',
            expensetypeList: [],
            expensetype: [],
            expensetypeItems: [],
            expensetypeDialog: false,
            expensetypeLoading: true,

            chargecode: '',
            trnchargeList: [],
            trncharges: [],
            trnchargesItems: [],
           
            trainingchargeDialog: false,
            trainingchargeLoading: true,

            currencies:[],
            currencyList: [],
            crcycode: '',
            
            staffnameItems:[],

             itemHolder: [],
            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,

        // changeStaffClaim() {
        //     const vm = this;
        //     this.$store.dispatch('get', {url: "/staff/" + this.staffid)
        //     .then(res => {

                
        //         vm.staffnameItems = res.data.rec;
                
        //         console.log('staffitem', vm.staffnameItems)
                
        //         vm.selections[0].staffid = res.data.rec[0].staffid;
        //         // vm.selections[0].exchrateid = res.data.rec[0].exchrateid;
        //         // vm.selections[0].clmtypecrycdesc = res.data.rec[0].crcydesc;
                
        //     })
        //     .catch(err => {
        //         vm.errMsg = err;
        //     });
        // },

    loadCurrencyListHandler() {
        const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {

                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                code: res.data.rec[i].crcycode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    vm.currencyLoading = false;

                    if (vm.selections[0].currencyid) {
                        vm.changeCurrencyHandler(vm.selections[0].currencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.selections[0].crcycode = item.crcycode;
            this.selections[0].crcydesc = item.crcydesc;
        },

        loadExpenseTypeHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/expensetype/all'})
                .then(res => {
                    vm.expensetype = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.expensetypeList.push(
                            {
                                text: res.data.rec[i].expensename,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.expensetypeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].expensecode,
                                desc: res.data.rec[i].expensename,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.expensetypeLoading = false;

                    if (vm.selections[0].trnexpensetypeid) {
                        vm.changeExpenseTypeHandler(vm.selections[0].trnexpensetypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changeExpenseTypeHandler(id) {
            let item = this.expensetype.find(item => item.id === id);

            this.selections[0].trnexpensecode = item.expensecode;
            this.selections[0].trnexpensename = item.expensename; 
        },

         //save handler is for if the user wants to save a new item into the database
        saveExpenseTypeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/expensetype/' + this.itemData.itemid, payload: {
                expensecode: this.itemData.itemcode,
                expensename: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.selections[0].userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.expensetype = [];
                        vm.expensetypeItems = [];
                        vm.expensetypeList = [];

                        this.loadExpenseTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/expensetype/create', payload: {
                expensecode: this.itemData.itemcode,
                expensename: this.itemData.itemdesc,
                userid: this.selections[0].userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.expensetype = [];
                        vm.expensetypeItems = [];
                        vm.expensetypeList = [];

                        this.loadExpenseTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deleteExpenseTypeHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/expensetype/' + $event.id + '/' + this.selections[0].userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.expensetype = [];
                        vm.expensetypeItems = [];
                        vm.expensetypeList = [];

                        this.loadExpenseTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

          genExpenseTypeTabItems() {
            for (let i = 0; i < this.expensetype.length; i++) {
                this.expensetypeItems.push(
                    {
                        id: this.expensetypeItems[i].id,
                        code: this.expensetypeItems[i].expensecode,
                        desc: this.expensetypeItems[i].expensename,
                        stamp: this.expensetypeItems[i].stamp
                    }
                )
            }
        },

         loadTrainingChargeCodeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/trainingchargecode/all'})
                .then(res => {
                    vm.trncharges = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.trnchargeList.push(
                            {
                                text: res.data.rec[i].chargecodename,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.trnchargesItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].chargecode,
                                desc: res.data.rec[i].chargecodename,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.trainingchargeLoading = false;

                    if (vm.selections[0].chargecodeid) {
                        vm.changeTrainingChargeCodeHandler(vm.selections[0].chargecodeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changeTrainingChargeCodeHandler(id) {
            let item = this.trncharges.find(item => item.id === id);

            this.selections[0].trnchargecode = item.chargecode;
            this.selections[0].chargecodename = item.chargecodename; 
        },

         //save handler is for if the user wants to save a new item into the database
        saveTrainingChargeCodeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/trainingchargecode/' + this.itemData.itemid, payload: {
                chargecode: this.itemData.itemcode,
                chargecodename: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.selections[0].userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trncharges = [];
                        vm.trnchargesItems = [];
                        vm.trnchargeList = [];

                        this.loadTrainingChargeCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/trainingchargecode/create', payload: {
                chargecode: this.itemData.itemcode,
                chargecodename: this.itemData.itemdesc,
                userid: this.selections[0].userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trncharges = [];
                        vm.trnchargesItems = [];
                        vm.trnchargeList = [];

                        this.loadTrainingChargeCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deleteTrainingChargeCodeHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/trainingchargecode/' + $event.id + '/' + this.selections[0].userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.trncharges = [];
                        vm.trnchargeList = [];
                        vm.trnchargesItems = [];

                        this.loadTrainingChargeCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

          genChargeCodeTabItems() {
            for (let i = 0; i < this.trncharges.length; i++) {
                this.trnchargesItems.push(
                    {
                        id: this.trncharges[i].id,
                        code: this.trncharges[i].chargecode,
                        desc: this.trncharges[i].chargecodename,
                        stamp: this.trncharges[i].stamp
                    }
                )
            }
        },


       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.trainingrecorddialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveTrainingExpenseInput() {

            
             if (this.selections[0].trnexpensetypeid == null || this.selections[0].amount == null|| this.selections[0].currencyid == null ){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }



            // if (!this.isExisting) {
            //     this.selections[0].id = null;
            //     this.selections[0].action = 1;
            //     this.detailItem.push(Object.assign({}, this.selections[0]));
            // } else {
            //     if (this.compareArray(this.detailItem, this.selections[0])) {
            //         console.log(this.compareArray(this.detailItem, this.selections[0]))
            //     }
                
            // }
            this.$emit('input', this.detailItem);
            this.trainingrecorddialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        closeTrainingExpenseInput() {
            this.trainingrecorddialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.trainingrecorddialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
               // clmtypeid: this.selections[0].clmtypeid,
                id: null,
                trnrecordid: null,
                trnexpensetypeid: null,
                trnexpensename:null,
                trnexpensecode:null,
                amount: null ,
                currencyid: null,
                crcycode: null,
                crcydesc: null,
                trnchargecodeid: null,
                trnchargecode: null,
                chargecodename: null,
                remark: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {

       // console.log('ln1377', this.selections)
      
       this.loadCurrencyListHandler();
       this. loadTrainingChargeCodeListHandler();
     //  this.changeStaffClaim();
       this. loadExpenseTypeHandler();
      // this.getExchangeRate();
      
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>