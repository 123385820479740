    <template>
    <div>
        <v-dialog v-model="assignmentdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-3">{{$t('setupholassigment.selecthol')}}</span>
              <!--<span class="headline mb-3">Select Holiday</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="10" sm="6" md="6" class="mt-n8">
                         <v-autocomplete
                            outlined
                                    :label="this.$t('columns.description')"
                                    type="text"
                                    ref= "holdesc"
                                    :items="HolidayList"
                                    hide-selected
                                    :rules="[rules.required]"
                                    v-model="selections[0].holid"
                                    @change="changeDayHandler(selections[0].holid)"
                                    dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('setupholidaysetup.holidaycode')"
                            type="text"
                            v-model="selections[0].holcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeHolidayAssignmentInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveHolidayAssignmentInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },

            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            assignmentdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                holid: null,
                holcode: null,
                holdesc: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }],

            HolidayList:[],
            itemHolder: [],
            detailList: [],

            assignmentItems: [],


            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
        compareArray,

        loadGroup() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/holiday/all'})
            .then(res => {

                vm.assignmentItems = res.data.rec;
                console.log('loh', this.assignmentItems)

                for (let i = 0; i < vm.assignmentItems.length; i++) {
                    vm.HolidayList.push({
                        text: vm.assignmentItems[i].holdesc,
                        code: vm.assignmentItems[i].holcode,
                        value: vm.assignmentItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeDayHandler(id) {
            let item = this.assignmentItems.find(item => item.id == id);

            this.selections[0].holcode = item.holcode;
            this.selections[0].holdesc = item.holdesc;
           // this.selections[0].daytypeid = item.text;
        },


        modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

            this.itemHolder = item
            this.selections[0] =  this.itemHolder;

            this.assignmentdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveHolidayAssignmentInput() {

              if (this.selections[0].holid == null){

              this.$dialog.alert(this.$t('fillintheholidaydesccolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {
           this.isErr = false;


            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            this.$emit('input', this.detailItem);
            this.assignmentdialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
        },

        closeHolidayAssignmentInput() {
            this.assignmentdialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.assignmentdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },
        
        resetSelections() {
            this.selections = [{
                
                holid: null,
                rectimestamp: null,
                updatedby: null,
                action: 0
            }]
        },

    },

    mounted() {
        this.loadGroup();
    }
}
</script>   


       