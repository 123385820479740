<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
       v-on:cancel="cancelHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
//import moment from 'moment';

import ReportExportMappingForm from "../../../components/insurance/reportexportmapping/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": ReportExportMappingForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupreport_exportmapping.editmapreport_export'),
      pageTitleValue: "",
      formData: [],

      // chargeItems: [],
      // chargeList: [],
      // chargecodes: [],
 
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadReportExportMappingHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/reportexportmapping/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];         

       //   vm.formData.grpvalueid = res.data.rec[0].grpvalueid;
          
          vm.formData.stamp = res.data.rec[0].stamp;
        //  vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.formData.userid = this.$store.getters.userId;
          vm.pageTitleValue = res.data.rec[0].valuedesc;

           this.loadTrainingExpenseHandler();

       //  vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadTrainingExpenseHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/reportexportmappingdetail/" + this.id})
        .then((res) => {
          vm.formData.reportexportmappingdetail = res.data.rec;
         
        // for (let i = 0; i < res.data.rec.length; i++) {
        //     if (res.data.rec[i].receiptdate) {
        //       vm.formData.trainingrecorddetail[i].receiptdate = moment(
        //         res.data.rec[i].receiptdate
        //       ).format("YYYY-MM-DD");
        //     }
        //   }
          

          for (let i = 0; i < vm.formData.reportexportmappingdetail.length; i++) {
            Object.assign(vm.formData.reportexportmappingdetail[i], {
              action: 2,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/reportexportmapping/" + vm.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "reportexportmappinglist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "reportexportmappinglist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/reportexportmapping/" +
            vm.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "reportexportmappinglist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "reportexportmappingnew" });
    },
  },

  mounted() {
    this.loadReportExportMappingHandler();
  
  },
};
</script>