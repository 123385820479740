<template>

    <div>
    
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-row>
            <v-col cols="1" sm="2" md="2">
                <v-btn block color="primary" @click="goBack()">
                {{$t('button.back')}}
                </v-btn>
            </v-col>
            <v-col cols="10" sm="10" md="10">
                    <h3 class="blue--text mt-2" >{{$t('selfserviceleaveapproval.leaveapproval')}}</h3>
            </v-col>            
        </v-row>

        <app-leave-details
            v-bind:leaveDetail="this.leaveDetail"
            v-bind:staffApproveList="this.staffApproveList"
        />
    
    </div>

</template>

<script>

import LeaveDetails from '../../sscomponents/leaveapproval/LeaveDetails.vue'
import moment from 'moment'
import Loading from "vue-loading-overlay";

export default {

    components: {
        "app-leave-details": LeaveDetails,
        Loading
    },

    data() {
        return {

            leaveDetail: null,
            wftList: [],
            leavePartialRecord: [],
            leaveApprInfo: [],
            inactiveworkflow: Boolean,
            staffApproveList: [],

            loading: false

        }
    },

    methods: {
        goBack() {
            this.$router.push({name: 'selfservicemytask'});
        },

        loadWorkflowTransactionDetails() {
            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('get', {url: "/workflowtransaction/approvalinfo/" + this.leaveDetail.wftranid})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    let wftholder = result.rec
                    if(wftholder.length > 0) {
                        for (let i = 0; i < wftholder.length; i++) {
                            if (wftholder[i].remark == 'null') wftholder[i].remark = null
                            wftholder[i].aprv = moment(wftholder[i].aprdate).format('DD-MM-YYYY HH:mm:ss')
                            this.wftList.push(wftholder[i])
                        }
                    }
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });
        },

        loadLeavePartial() {
            const vm = this;
            vm.loading = true;
            this.$store.dispatch('get', {url: "/leaverecordpartial/" + this.leaveDetail.recordid})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    this.leavePartialRecord = result.rec
                    if(this.leavePartialRecord.length > 0){
                        for (let i = 0; i < this.leavePartialRecord.length; i++) {
                            this.leavePartialRecord[i].datedisplay = moment(this.leavePartialRecord[i].startdatetime).format('DD-MM-YYYY')
                            this.leavePartialRecord[i].starttimedisplay = moment(this.leavePartialRecord[i].startdatetime).format('hh:mm A')
                            this.leavePartialRecord[i].endtimedisplay = moment(this.leavePartialRecord[i].enddatetime).format('hh:mm A')
                        }
                    }
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });
        },

        loadWorkflowTransactionApproval() {

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('get', {url: "/leaverecord/wftran/" + this.leaveDetail.recordid})
            .then((res) => {

                const result = res.data;

                if (result.status === 200) {

                    vm.leaveApprInfo = result.rec

                    if (vm.leaveApprInfo.length > 0) {

                        for (var i = 0; i < vm.leaveApprInfo.length; i++) {

                            if (vm.leaveApprInfo[i].aprdate) {
                                vm.leaveApprInfo[i].aprdate = moment(vm.leaveApprInfo[i].aprdate).format("YYYY-MM-DD HH:mm:ss");
                            }

                            if (vm.leaveApprInfo[i].wfstatus == 'PENDING') {
                                vm.inactiveworkflow = !vm.inactiveworkflow
                            }
                        }

                    }

                }

                vm.staffApproveList = vm.leaveApprInfo.filter(val => val.wfstatus == 'DONE')

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        }
      
    },

    mounted() {
        this.leaveDetail = this.$route.params.item;
        this.leaveDetail.startdate = moment(this.leaveDetail.startdate).format('YYYY-MM-DD')
        this.leaveDetail.enddate = moment(this.leaveDetail.enddate).format('YYYY-MM-DD')

        this.loadWorkflowTransactionDetails();
        this.loadLeavePartial();
        this.loadWorkflowTransactionApproval();
    }

}

</script>

<style>
p {
    font-size: 9px;
}
.subtext {
    font-size: 12px;
}
</style>