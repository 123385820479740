import { render, staticRenderFns } from "./CRUDTable.vue?vue&type=template&id=73949749&scoped=true&"
import script from "./CRUDTable.vue?vue&type=script&lang=js&"
export * from "./CRUDTable.vue?vue&type=script&lang=js&"
import style0 from "./CRUDTable.vue?vue&type=style&index=0&id=73949749&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73949749",
  null
  
)

export default component.exports