<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>

<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('recgrpassignment.grpassignmentlist'), 
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "100px" },
        { text: this.$t('columns.name'), value: "psnname", width: "300px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "150px" },
        { text: this.$t('columns.effectivedate'), value: "startdate", width: "150px" },
        { text: this.$t('setupposition.invaliddate'), value: "enddate", width: "150px" },
        { text: this.$t('columns.remark'), value: "remark", width: "150px" },
      ],
      dataItems: [],
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    //get all records
    loadGroupAssignmentItems() {
      const vm = this;

      this.$store.dispatch('get', {url: `/groupassignment/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.dataItems = res.data.rec;

          //set correct  date format using moment
          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format(
                "YYYY-MM-DD"
              );
            }
          }
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "groupassignmentedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "groupassignmentnew" });
    },
  },

  mounted() {
    this.loadGroupAssignmentItems();
  },
};
</script>