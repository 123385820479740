<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import Form from "../../../components/attendance/preapprovedot/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";
import moment from "moment";

export default {
  components: {
    "app-form": Form,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('preapprovedotrecord.newpreapprovedotrecord'),
      pageTitleValue: "",
      formData: {
        id: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnyname: null,
        compnycode: null,
        daytypeid: null,
        daytypecode: null,
        shiftdate: moment().format("YYYY-MM-DD"),
        otstartdate: moment().format("YYYY-MM-DD"),
        shiftid: null,
        shiftcode: null,
        otstart: "",
        otend: "",
        othrs: null,
        justify: null,
        transtatusid: null,
        transtatus: null,
        earlyot: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      console.log(vm.formData);
      this.$store.dispatch('post', {url: "/preapprovedot/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "preapprovedotlist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "preapprovedotlist" });
    },
  },
};
</script>
