<template>
    <div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reportsparam.asatdate')"
                                        type="date"
                                        id="asatdate"
                                        v-model="asatdate"
                                        dense                                
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="8" sm="8" class="pb-n5">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.position')"
                                        type="text"
                                        :rules="[rules.required]"
                                        v-model="formData.position"
                                        :items="positionList"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                        </div>
                    </v-card>
                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                > 
                                    <!-- Go -->
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>

import Loading from "vue-loading-overlay";

export default {

    components: {
        Loading
    },

    data() {
        return{

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            asatdate: new Date().toISOString().slice(0,10),

            formData:{
                position: '',
                // title: "Organization Chart"
                title: this.$t('orgchart')
            },

            positionList: [],

            loading: false,
        }
    },

    watch: {

        asatdate(){

            this.loadPosition(this.asatdate);

        }

    },

    methods: {

        goHandler() {

            // if(this.activeStaff === true) {
            //     this.$router.push({name: 'orgchartdetails', params: { asatdate: this.formData.asatdate, activedate: this.formData.asatdate} });
            // } else {
            //     this.$router.push({name: 'staffenquirydetails', params: { asatdate: this.formData.asatdate } });
            // }

            if(this.formData.position != undefined && this.formData.position != '' && this.formData.position != null) {
                this.$router.push({name: 'orgchartdetails', params: { positionid: this.formData.position, asatdate: this.asatdate } });
            } else {
                console.log('Error', 'NO POSITION ASSIGN');
            }
            
        },

        loadPosition(x) {

            const vm = this;

            vm.loading = true;

            this.$store.dispatch('get', { url: "/organizationzhart/position/" + x })  
            .then(res => {

                let data = res.data.rec;

                data.sort(function(a, b) {
                    let textA = a.text.toUpperCase();
                    let textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                vm.positionList = data;

            })
            .catch(err => {
                this.isErr = true;
                this.errMsg = err;
            });

            vm.loading = false;

        }

    },

    mounted() {
        this.loadPosition(new Date().toISOString().slice(0,10));
    },
}
</script>