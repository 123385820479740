<template>
    <div id="footer">
        <v-footer font-szie="8px" app fixed>
            <v-spacer></v-spacer>
            <div class="mr-3">
                &copy; <a :href="website">{{ companyName }}</a> {{ copyYear}}. {{$t('footer.allrightreserved')}}
            </div>
        </v-footer>
    </div>
</template>

<script>
import moment from "moment";

export default {
    data () {
        return {
            companyName: 'Transact2 Sdn. Bhd.',
            copyYear: moment().format('YYYY'),
            website: 'https://www.transact2.com'
        }
    }
}
</script>
