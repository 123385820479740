<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                    outlined
                    :label="this.$t('vdatatable.receiptreference')"
                    type="text"
                    v-model="formData.recref"
                    maxlength='20'
                    dense
                ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="exportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";
import XLSX from 'xlsx/xlsx';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
      },
      pageTitle: 'RHB-01',

      parameter: this.parentObject.parameter,
      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        recref: '',
        othpaydet: ''
      },
      fileHolder: null,
      file: null,

      paymentModeList: ['FT', 'IBG'],
      bank: [
        {
          id: 1,
          bankcode: "PABB",
          bankdesc: "AFFIN BANK BHD",
        },
        {
          id: 3,
          bankcode: "ARB",
          bankdesc: "AL RAJHI BANK",
        },
        {
          id: 4,
          bankcode: "ALBB",
          bankdesc: "ALLIANCE BANK MALAYSIA BHD",
        },
        {
          id: 5,
          bankcode: "AMBB",
          bankdesc: "AMBANK BERHAD",
        },
        {
          id: 6,
          bankcode: "BKKB",
          bankdesc: "BANGKOK BANK BHD",
        },
        {
          id: 7,
          bankcode: "BIMB",
          bankdesc: "BANK ISLAM BHD",
        },
        {
          id: 12,
          bankcode: "BKRM",
          bankdesc: "BANK KERJASAMA RAKYAT MALAYSIA",
        },
        {
          id: 8,
          bankcode: "BMMB",
          bankdesc: "BANK MUAMALAT MALAYSIA BERHAD ",
        },
        {
          id: 9,
          bankcode: "BOFA",
          bankdesc: "BANK OF AMERICA BHD",
        },
        {
          id: 10,
          bankcode: "BOCM",
          bankdesc: "BANK OF CHINA (M) BHD",
        },
        {
          id: 15,
          bankcode: "CCBM",
          bankdesc: "CHINA CONSTRUCTION BANK MALAYSIA BERHAD",
        },
        {
          id: 37,
          bankcode: "MBSB",
          bankdesc: "MBSB BANK BERHAD",
        },
        {
          id: 28,
          bankcode: "BTMU",
          bankdesc: "MUFG Bank (M) BERHAD",
        },
        {
          id: 2,
          bankcode: "AGRO",
          bankdesc: "BANK PERTANIAN MALAYSIA BHD",
        },
        {
          id: 13,
          bankcode: "BSNB",
          bankdesc: "BANK SIMPANAN NASIONAL        ",
        },
        {
          id: 14,
          bankcode: "BNPM",
          bankdesc: "BNP PARIBAS MALAYSIA BERHAD",
        },
        {
          id: 16,
          bankcode: "CIMB",
          bankdesc: "CIMB BANK BHD",
        },
        {
          id: 17,
          bankcode: "CITI",
          bankdesc: "CITIBANK BHD",
        },
        {
          id: 18,
          bankcode: "DEUM",
          bankdesc: "DEUTSCHE BANK (M) BHD",
        },
        {
          id: 21,
          bankcode: "HSBC",
          bankdesc: "HONGKONG BANK MALAYSIA BHD",
        },
        {
          id: 23,
          bankcode: "ICBC",
          bankdesc: "IND&COMM BANK OF CHINA (M) BHD",
        },
        {
          id: 24,
          bankcode: "JPMC",
          bankdesc: "J.P. MORGAN CHASE BANK BHD",
        },
        {
          id: 25,
          bankcode: "KFHB",
          bankdesc: "KUWAIT FINANCE HOUSE",
        },
        {
          id: 26,
          bankcode: "MBBB",
          bankdesc: "MALAYAN BANKING BHD",
        },
        {
          id: 27,
          bankcode: "MHCB",
          bankdesc: "MIZUHO BANK (M) BERHAD",
        },
        {
          id: 29,
          bankcode: "OCBC",
          bankdesc: "OCBC BANK (M) BHD",
        },
        {
          id: 30,
          bankcode: "PBBB",
          bankdesc: "PUBLIC BANK BHD",
        },
        {
          id: 31,
          bankcode: "RHBB",
          bankdesc: "RHB BANK BHD",
        },
        {
          id: 32,
          bankcode: "SCBB",
          bankdesc: "STANDARD CHARTERED BANK BHD",
        },
        {
          id: 33,
          bankcode: "SMBC",
          bankdesc: "SUMITOMO MITSUI BANK BHD",
        },
        {
          id: 36,
          bankcode: "UOBB",
          bankdesc: "UNITED OVERSEAS BANK M BHD",
        },
      ],

      staffilterlist: [],
      staffaccount: [],
      companyaccount: [],
      psnpayroll: [],
      titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
      payrolldata: [],

      companyrecnum: "",
      paymentmode: null,
      bankcode: null,
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],

      currency: null,
      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {

    getHLBExportListHandler() {
      const vm = this
      
      this.$store.dispatch('get', {url: `/rhbsalexport/all/${this.$store.getters.userId}`})
      .then(res => {
        let dateHolder = vm.data.year + "-" + 
                         vm.padRJ(vm.data.month, 2) + "-" + 
                         vm.daysInMonth(vm.data.month, vm.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let payrolldataholder = res.data.rec;

        payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)
        
        payrolldataholder.forEach(function (o) {
          var existing = vm.payrolldata.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            if (o.bankid) {
              if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                    vm.payrolldata.push(o);
              }
            } else {
              vm.payrolldata.push(o);
            }
          }
        });
        this.loadStaffFilter()
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    loadStaffFilter() {
      const vm = this;

      this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
        .then(res => {
            vm.staffilterlist = res.data.rec;
            let checkval = false;
            for (const key in this.parameter) {
              if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            }

            if (checkval) {
              vm.payrolldata = vm.payrolldata.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }

        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    async getData() {
      const vm = this
      let body = []
      let holdpayment = []
      //let validationcode = ''
      let dataholder = vm.payrolldata

      let payrolldetail = this.filterAcc(dataholder);
      this.successCount = payrolldetail.length;

      this.errCount = this.processedCount - payrolldetail.length;
      this.currency = payrolldetail[0].exchratecode;

      payrolldetail.forEach(element => {
        let icno = ''
        let oldic = ''
        let passport = ''
        
        if (element.idtypeid == 1 && element.iddetails != null ) icno = parseInt(element.iddetails.split("-").join(""))
        if (element.idtypeid == 2 && element.iddetails != null) oldic = parseInt(element.iddetails.split("-").join(""))
        if (element.idtypeid == 2 && element.iddetails != null) passport = parseInt(element.iddetails.split("-").join(""))
        
        body.push({
          "Beneficiary Name": element.accountname,
          "Beneficiary IC Number": icno.toString(),
          "Beneficiary Account Number": element.staffaccountno,
          "Amount": element.netpay.toFixed(2),
          "Old IC": oldic,
          "Passport": passport,
          "Reference Description": vm.formData.recref,
        });

        holdpayment.push(element.netpay)
      });

      if (holdpayment) {
        let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
        this.gettotalAmt = totalAmt.toFixed(2);
      }

      if (body.length > 0) {
        // const csvData = this.objectToCSV(body);
        // this.download(csvData, this.titleData);
        const ws = XLSX.utils.json_to_sheet(body);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.titleData);
        XLSX.writeFile(wb, this.titleData + '.xlsx');
      }
    },

    filterAcc(item) {
      let checkacc = this.accfilter(item)
      let checkaccbank = this.accBankfilter(checkacc)
      let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
      let checkactive = this.activefilter(checkapaymentmethod)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    accfilter(item) {
      let data = item.filter(e => e.bankid)
      let noacc = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noacc.length; i++) {
        noacc[i].errormsg = this.$t('allbank.noaccfound');
        this.accErr.push(noacc[i]);
      }
      return data
    },

    accBankfilter(item) {
      let vm = this
      let data = item.filter(e => {
        for (let i = 0; i < this.bank.length; i++) {
          if (e.bankid == '31') return e
        }
      })
      let noaccbank = item.filter((e) => !data.includes(e));
      this.processedCount = vm.payrolldata.length - noaccbank.length;
      
      // for (let i = 0; i < noaccbank.length; i++) {
      //   noaccbank[i].errormsg = "The bank account is not in the list of bank for transfer";
      //   this.accErr.push(noaccbank[i]);
      // }
      return data
    },

    paymentmethodfilter(item) {
      let data = item.filter(e => e.paymodecode == 'BANK')
      let diffpayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < diffpayment.length; i++) {
        diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
        this.accErr.push(diffpayment[i]);
      }
      return data
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => e.netpay > 0)
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToCSV(data) {
        const csvRows = []

        // get header
        const headers = Object.keys(data[0])
        //csvRows.push(headers.join(','))

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }
        return csvRows.join('\n')
    },

    download(data, title) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmt = this.gettotalAmt;
        item.accounterr = this.accErr;
        item.negErrMss = this.negErrMsg;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        item.currency = this.currency;
        this.$router.push({ name: "salaryexportlog", params: { item } });
      }
    },

    backHandler() {
      let param = this.parameter

      this.$router.push({ name: "salaryexport", params: { param } });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    this.getHLBExportListHandler();
  },
};
</script>