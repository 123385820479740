<template>
    <div v-if="!loading">
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="8" sm="8" class="pb-n5" >
                            <v-autocomplete
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payoutctrlList"
                                v-model="formData.id"
                                @change="changePayout(formData.id)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="8" sm="8" class="pb-n5" >
                            <v-autocomplete
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payoutctrlList"
                                v-model="formData.id"
                                background-color="#ffeab2"
                                readonly
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                v-model="formData.payoutdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.year')"
                                type="number"
                                v-model="formData.pyyear"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.month')"
                                type="number"
                                v-model="formData.pymonth"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-toolbar-title class="subtitle-2">{{$t('payeeinputprocess.expand')}}</v-toolbar-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>

                            <v-row class="pt-4">
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstartdate')"
                                        type="date"
                                        v-model="formData.startdate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutenddate')"
                                        type="date"
                                        v-model="formData.enddate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstatus')"
                                        readonly
                                        type="text"
                                        v-model="formData.pystatusdesc"
                                        dense
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                    <input type="text" hidden v-model="formData.pystatuscode" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('setuppayrolltype.payrolltype')"
                                        type="text"
                                        v-model="formData.payrolltypedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payrollgroup')"
                                        type="text"
                                        v-model="formData.grouppdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.exchangerate')"
                                        type="text"
                                        v-model="formData.exchratedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutfrequency')"
                                        type="text"
                                        v-model="formData.salfreqdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payeelist')"
                                        type="text"
                                        v-model="formData.payeelistdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-container>
                <div style="display: flex; align-items: flex-end; width: 100%">
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isEdit" color="primary" 
                        class="btn-process"
                        @click="finalizeHandler"
                    >{{$t('finizepayroll.finalize')}}
                    </v-btn>
                    <v-btn v-if="isEdit" color="primary" 
                        class="btn-process"
                        @click="undoHandler"
                    >{{$t('finizepayroll.undo')}}
                    </v-btn>
                </div>
                <div v-if="isErr">
                    <app-error 
                        v-bind:parentError = "{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div> 
                
            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-error': ErrorDialog
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: this.$t('payrollcommandstep.payeeinputdash'),
            pageTitleValue: '',
            formData: this.parentObject.data,
            
            payoutctrls: [],
            payoutctrlsNew: [],
            payoutctrlsEdit: [],
            payoutctrlList: [],
            salhdList: [],
            
            loading: true,
            isEdit: this.parentObject.isEdit,
            errMsg: '',
            isErr: false,
            editMode: false,
            isUndefined: false,
            isNotNone: false,
            isNetpay:false,
             search: this.parentObject.searchnew,
        }
    },

    methods: {

        loadPayoutListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
            .then (res => {
                if (this.isEdit) {
                    console.log('load')
                    let payoutctrl = res.data.rec
                    for (let i = 0; i < payoutctrl.length; i++) {
                        if (payoutctrl[i].pystatuscode == 99) {
                            vm.payoutctrlsEdit.push(payoutctrl[i])
                        }
                    }
                    for (let i = 0; i < vm.payoutctrlsEdit.length; i++) {
                        if (vm.payoutctrlsEdit[i].startdate) {
                            vm.payoutctrlsEdit[i].startdate = moment(vm.payoutctrlsEdit[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.payoutctrlsEdit[i].enddate) {
                            vm.payoutctrlsEdit[i].enddate = moment(vm.payoutctrlsEdit[i].enddate).format('YYYY-MM-DD');
                        }

                        vm.payoutctrlList.push(
                            {
                                text: vm.payoutctrlsEdit[i].payoutcode,
                                value: vm.payoutctrlsEdit[i].id
                            }
                        )
                    }
                } else {
                    let payoutctrl = res.data.rec
                    for (let i = 0; i < payoutctrl.length; i++) {
                        if (payoutctrl[i].pystatuscode != 99) {
                            vm.payoutctrlsNew.push(payoutctrl[i])
                        }
                    }
                    
                    for (let i = 0; i < vm.payoutctrlsNew.length; i++) {
                        if (vm.payoutctrlsNew[i].startdate) {
                            vm.payoutctrlsNew[i].startdate = moment(vm.payoutctrlsNew[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.payoutctrlsNew[i].enddate) {
                            vm.payoutctrlsNew[i].enddate = moment(vm.payoutctrlsNew[i].enddate).format('YYYY-MM-DD');
                        }

                        vm.payoutctrlList.push(
                            {
                                text: vm.payoutctrlsNew[i].payoutcode,
                                value: vm.payoutctrlsNew[i].id
                            }
                        )
                    }
                }
                vm.loading = false;
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        loadSummaryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/ppsummarylist/all'})
            .then (res => {
                console.log('load2')
                vm.salhdList = res.data.rec
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        changePayout(id) {
            console.log('loadasdfas')
            let item = this.payoutctrlsNew.find(item => item.id == id)
  
            if (item.allowinput === 'N') {
                this.formData.allowinput = 0
            } else {
                this.formData.allowinput = 1
            }

            if (item.ssrel === 'N') {
                this.formData.ssrel = 0
            } else {
                this.formData.ssrel = 1
            }

            this.formData.id = item.id,
            this.formData.payoutcode = item.payoutcode,
            this.formData.payoutdesc = item.payoutdesc,
            this.formData.pyyear = item.pyyear,
            this.formData.pymonth = item.pymonth,
            this.formData.startdate = item.startdate,
            this.formData.enddate = item.enddate,
            this.formData.pystatusdesc = item.pystatusdesc,
            this.formData.payrolltypedesc = item.payrolltypedesc,
            this.formData.grouppdesc = item.grouppdesc,
            this.formData.pycrcyid = item.pycrcyid,
            this.formData.exchratedesc = item.exchratedesc,
            this.formData.salfreqdesc = item.salfreqdesc,
            this.formData.payeelistdesc = item.payeelistdesc,
            this.formData.payrolltypeid = item.payrolltypeid,
            this.formData.grouppbid = item.grouppbid,
            this.formData.exchrateid = item.exchrateid,
            this.formData.salfreqid = item.salfreqid,
            this.formData.payeelistcode = item.payeelistcode,
            this.formData.pystatuscode = item.pystatuscode,
            this.formData.stamp = item.stamp,
            this.formData.userid = this.formData.userid

            let itemhd = []
          
            for (let i = 0; i < this.salhdList.length; i++) {
                if (this.salhdList[i].poid == id) {
                    itemhd.push(this.salhdList[i])
                }

            }
            if (itemhd.length > 0) {
                this.isUndefined = false;
                for (let j = 0; j < itemhd.length; j++) {
                    if (itemhd[j].pycmdstatuscode != 10){
                        return this.isNotNone = true
                    } else {
                        this.isNotNone = false
                    }

                    if (itemhd[j].netpay < 0){
                        return this.isNetpay = true
                    //zeronetpay.push(itemhd[j])
                    } else {
                    this.isNetpay = false
                    }

                }
            } else {
                this.isUndefined = true;
            }            
        },

        updateValue() {
            this.$emit('input', this.formData);
        },

        finalizeHandler() {
            if (this.isUndefined == true) {
                this.isErr = !this.isErr;
                this.errMsg = this.$t('finizepayroll.finalizepayrollmsg1');
                this.$emit('error', {isErr: this.isErr, errMsg: this.errMsg});
            } else if (this.isNotNone == true) {
                this.isErr = !this.isErr;
                this.errMsg = this.$t('finizepayroll.finalizepayrollmsg2');
                this.$emit('error', {isErr: this.isErr, errMsg: this.errMsg});
            } else if (this.isNetpay == true) {
                this.isErr = !this.isErr;
                this.errMsg = this.$t('previewexception.finalizepayrollmsg3');
                this.$emit('error', {isErr: this.isErr, errMsg: this.errMsg});
            } else {
                this.$emit('finalize');
            }
        },

        undoHandler() {
            this.$emit('undo');
        },

        backHandler() {
            this.$router.push({name: 'payrollfinalizationlist'});
        },

    },

    mounted() {
        this.loadPayoutListHandler();
        this.loadSummaryListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>