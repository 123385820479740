<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetrec.assettype')"
                                type="text"
                                ref="assettype"
                                :rules="[rules.required]"
                                v-model="formData.assettype"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetrec.assettypedesc')"
                                type="text"
                                ref="assetdesc"
                                :rules="[rules.required]"
                                v-model="formData.assetdesc"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetrec.quantity')"
                                type="number"
                                ref="qty"
                                :rules="[rules.required]"
                                v-model="formData.qty"
                                @input="qtyChangeHandler(formData.qty)"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetrec.avquantity')"
                                v-model="availableQty"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="2" class="pb-n4 mt-n12">
                            <v-checkbox
                                outlined
                                ref="trackdetails"
                                v-model="formData.trackdetails"
                                :label="this.$t('recassetrec.trackdetails')"
                                @change="trackDetailsChangeHandler(formData.trackdetails)"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="2" class="pb-n4 mt-n12">
                            <v-checkbox
                                outlined
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                dense
                                rows="1"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="formData.trackdetails">
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.assetdetail,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                    <app-formbuttons
                        v-bind:EditBool="this.parentObject.isEdit" 
                        v-on:NewPressed="newHandler"
                        v-on:SavePressed="saveHandler"
                        v-on:DeletePressed="deleteHandler"
                        v-on:CancelPressed="cancelHandler"
                    />
                </v-container>
            </v-form>
        </v-container>
    </div>
</template>
<script>
import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            availableQty: '',

            pageTitleValue: '',

            activeAssignment: '',

            formData: {
                id: this.parentObject.data.id,
                assettype: this.parentObject.data.assettype,
                assetdesc: this.parentObject.data.assetdesc,
                qty: this.parentObject.data.qty,
                trackdetails: this.parentObject.data.trackdetails,
                isdisable: this.parentObject.data.isdisable,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                assetdetail: this.parentObject.data.assetdetail,

                //additional line
                userid: this.parentObject.data.userid
            },

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false,
                },
                {
                    text: this.$t('recassetrec.unitno'),
                    value: "unitno",
                    width: "100px",
                    sortable: true,
                },
                {
                    text: this.$t('recassetrec.serialno'),
                    value: "serialno",
                    width: "120px",
                    sortable: true,
                },
                {
                    text: this.$t('columns.description'),
                    value: "assetitemdesc",
                    width: "130px",
                    sortable: true,
                },
                {
                    text: this.$t('recassetrec.estval'),
                    value: "estvalue",
                    width: "140px",
                    sortable: true,
                },
                {
                    text: this.$t('columns.status'),
                    value: "assetstatuscode",
                    width: "120px",
                    sortable: true,
                },
                {
                    text: this.$t('recassetrec.disposed'),
                    value: "disposed",
                    width: "120px",
                    sortable: true,
                },
                {
                    text: this.$t('recassetrec.undermaintenance'),
                    value: "undermaintenance",
                    width: "120px",
                    sortable: true,
                }
            ],

            errMsg: '',
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        loadAvailableQtyHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assetd3/" + this.formData.id})
                .then((res) => {
                    vm.availableQty = res.data.rec[0].count;
                    vm.qtyChangeHandler(vm.availableQty);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadActiveAssignmentHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assetassignment2/" + this.formData.id})
                .then((res) => {
                    vm.activeAssignment = res.data.rec;
                    this.availableQty = this.formData.qty - vm.activeAssignment.length;
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },
        
        addItemHandler($event) {
            this.formData.assetdetail = $event
            //this.updateValue();
        },

        qtyChangeHandler(val) {
            this.availableQty = val;
        },

        trackDetailsChangeHandler(val) {
            if (val) {
                this.loadAvailableQtyHandler();
            }
            else {
                this.qtyChangeHandler(this.formData.qty);
                this.loadActiveAssignmentHandler();
            }
            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                assettype: this.formData.assettype,
                assetdesc: this.formData.assetdesc,
                qty: this.formData.qty,
                trackdetails: this.formData.trackdetails,
                isdisable: this.formData.isdisable,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
                assetdetail: this.formData.assetdetail
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'assetlist',  params: { search: this.search}});
        },
    },
    mounted() {
        this.trackDetailsChangeHandler(this.formData.trackdetails);
    }
}
</script>