<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <!-- <v-row>
            <v-col cols="3" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('statutoryexportcommonused.statecode')"
                  type="text"
                  v-model="formData.statecode"
                  :items="statecodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="5" sm="5" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('allbank.payerrefnumber')"
                type="text"
                v-model="formData.payerrefnum"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('allbank.corporateid')"
                type="text"
                v-model="formData.corporateid"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactpersonname')"
                type="text"
                v-model="formData.contactpsnname"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactphonenumber')"
                type="text"
                v-model="formData.contactphoneno"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.debitdescription')"
                type="text"
                v-model="formData.debitdescription"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allepf.fileprocessingdate')"
                type="date"
                v-model="formData.valuedate"
                :rules="[rules.nonpastdate]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getAffinExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        nonpastdate: (value) => {
          var today = new Date();
          today.setHours(0,0,0,0);
          return new Date(value) >= today || this.$t('reportsparam.todayandfuturedate')
        }
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        payerrefnum: '',
        statecode: '',
        corporateid: '',
        // contactpsnname: '',
        // contactphoneno: '',
        debitdescription: '',
        valuedate: ''
      },

      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: this.parentObject.data.taxsubrecpy[0].payoutdesc,
      statecodeList: [
        {
            text: 'Johor',
            value: '01',
        },
        {
            text: 'Kedah',
            value: '02',
        },
        {
            text: 'Kelantan',
            value: '03',
        },
        {
            text: 'Melaka',
            value: '04',
        },
        {
            text: 'Negeri Sembilan',
            value: '05',
        },
        {
            text: 'Pahang',
            value: '06',
        },
        {
            text: 'Pulau Pinang',
            value: '07',
        },
        {
            text: 'Perak',
            value: '08',
        },
        {
            text: 'Perlis',
            value: '09',
        },
        {
            text: 'Selangor',
            value: '10',
        },
        {
            text: 'Terengganu',
            value: '11',
        },
        {
            text: 'Sabah',
            value: '12',
        },
        {
            text: 'Sarawak',
            value: '13',
        },
        {
            text: 'W. Persekutuan',
            value: '14',
        },
      ],

      fillerSpace: "",
      fillerNum: "",
      totalAmtEmployer: null,
      totalAmtMember: null,
      totalRefno: null,

      bodyholder: null,
      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],
      taxdata: [],

      loading: false,

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    
    getAffinExportListHandler() {
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/affintaxexport/all/${this.$store.getters.userId}`
          + "/" +  this.data.taxexprecid
          + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.loading = false;

        vm.taxdata = res.data.rec;
        
        vm.exportHandler(); 
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {
      const vm = this
      // let holdAmtEmployer = ''
      // let holdAmtMember = ''
      let rec = 0
      let body = []
      let newtaxdetail = []
      // let holdpayEmployer = []
      let holdpayMember = []
      let holdpayRefno = []
      let dataholder = vm.taxdata
      // console.log('dataholder', dataholder);
      let dataholderval = dataholder.filter(el => el.stfpcb)
      let taxdetail = this.filter(dataholderval);

      this.processedCount = dataholderval.length;
      this.successCount = taxdetail.length;
      this.errCount = dataholderval.length - taxdetail.length;
      // let yearholder = this.data.year
      // let monthholder = this.data.month + 1
      // if (monthholder > 12) {
      //   monthholder = 1
      //   yearholder++
      // }
      // let getyear = yearholder.toString()
      // let getdate = this.padRJ(monthholder, 2) + getyear
      // let currentdate = moment().format('YYYYMMDD')
      // let currenttime = moment().format('hhmmss')
      let contdateholder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let contdate = moment(contdateholder).format("MMYYYY");
      console.log('formData', this.formData)

      let header = 
        '00' + ',' +
        this.padSpaceRJ('LHDN', 0) + ',' +
        this.padSpaceRJ('3', 0) + ',' + //Provide Product Group
        '02' + ',' + //Statutory Body Type
        this.padSpaceRJ(this.data.refnum.replace(/-/g, ''), 0) + ',' + //employer reference number
        this.padSpaceRJ(taxdetail[0].compnyname, 0) + ',' +
        this.padSpaceRJ(contdate, 0) + ',' +
        this.padSpaceRJ(taxdetail[0].bankaccountno, 0) + ',' +
        this.padSpaceRJ(moment(this.formData.valuedate).format('DDMMYYYY'), 0) + ',' +
        this.padSpaceRJ('2', 0) + ',' +//Tax payment option
        this.padSpaceRJ('A', 0) + ',' + //Payment Option
        this.padSpaceRJ('10', 0) + ',' + //company state code
        this.padSpaceRJ(this.formData.payerrefnum.replace(/-/g, ''), 0) + ',' +
        this.padSpaceRJ(this.formData.debitdescription, 0) + ',' +
        this.padSpaceRJ('', 0) + ',' + //Recipient Reference
        this.padSpaceRJ('', 0) + ',' + //Credit Description
        // contact person name   this.padSpaceRJ(this.formData.contactpsnname, 40) + ',' +
        // contact phone number  this.padSpaceRJ(this.formData.contactphoneno, 20) + ',' +
        this.padSpaceRJ('', 0) + ',' + //penalty sequence number
        this.padSpaceRJ('', 0) + ',' + //form reference number
        this.padSpaceRJ('', 0) + ',' + //client batch reference number
        this.padSpaceRJ('', 0) + ',' +
        this.padSpaceRJ('', 0) + ',' +
        this.padSpaceRJ(this.formData.corporateid, 0) + ',' +
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',';

      taxdetail.forEach(function (o) {
        var existing = newtaxdetail.filter(function (i) {
          return i.staffid === o.staffid;
        })[0];
        if (!existing || existing == undefined) {
          newtaxdetail.push(o);
        } else {
          existing.stfpcb += o.stfpcb;
        }
      });

      newtaxdetail.forEach(element => {
        
        // let coamountholder = element.cosocso.toFixed(2);
        let stfamountholder = element.stfpcb.toFixed(2);
        // let stfepfwageholder = element.stfepfwage.toFixed(2);

        // coamountholder = coamountholder.split(".").join("");
        // stfamountholder = stfamountholder.split(".").join("");
        // stfepfwageholder = stfepfwageholder.split(".").join("");
        let wifecode = ''
        if (element.refno) wifecode = element.refno.charAt(element.refno.length - 1)
        
        body.push({
          "Record Type": this.padSpaceRJ('01', 0) + ',',
          "Customer Reference Number": this.padSpaceRJ('', 0) + ',',
          "Payment Code": this.padSpaceRJ('092', 0) + ',',
          "Favourite Employee Code": this.padSpaceRJ('', 0) + ',',
          "Employee Name": this.padSpaceRJ(element.psnname, 0) + ',',
          "Malaysian": this.padSpaceRJ('Y', 0) + ',',
          "Country Code": this.padSpaceRJ('', 0) + ',',
          "Employee Number": element.refno ? this.padLJ(element.refno.replace(/-/g, ''), 13) + ',' : this.padSpaceRJ('', 0) + ',',
          "ID Type": this.padSpaceRJ('1', 0) + ',',
          "ID No": this.padSpaceRJ(element.icno.replace(/-/g, ''), 0) + ',',
          "Employee Amount": this.padSpaceRJ(stfamountholder, 0) + ',',
          "Employer Amount": this.padSpaceRJ('', 0) + ',',
          "Employment Date": this.padSpaceRJ('', 0) + ',',
          "Employment Status": this.padSpaceRJ('', 0) + ',',
          "Wife Code": this.padSpaceRJ(wifecode, 0) + ',',
          "Employee Staff Number": this.padSpaceRJ(element.staffcode, 0) + ',',
          "Employee Wages": this.padSpaceRJ('', 0) + ',',
          "Employee's identifying initials": this.padSpaceRJ('', 0) + ',',
          "Filler1": this.padSpaceRJ('', 0) + ',',
          "Filler2": this.padSpaceRJ('', 0) + ',',
          "Filler3": this.padSpaceRJ('', 0) + ',',
          "Filler4": this.padSpaceRJ('', 0) + ',',
          "Filler5": this.padSpaceRJ('', 0) + ',',
          "Filler6": this.padSpaceRJ('', 0) + ',',
          "Filler7": this.padSpaceRJ('', 0) + ',',
          "Filler8": this.padSpaceRJ('', 0) + ',',
          "Filler9": this.padSpaceRJ('', 0) + ',',
          "Filler10": this.padSpaceRJ('', 0) + ',',
          "Filler11": this.padSpaceRJ('', 0) + ',',
          "Filler12": this.padSpaceRJ('', 0) + ',',
          "Filler13": this.padSpaceRJ('', 0) + ',',
          "Filler14": this.padSpaceRJ('', 0) + ',',
          "Returned Status": this.padSpaceRJ('', 0) + ',',
          "Returned Reason": this.padSpaceRJ('', 0) + ',',
          "Return Status Date": this.padSpaceRJ('', 0) + ',',
          "Processing Date": this.padSpaceRJ('', 0) + ',',
          "Txn Ref No": this.padSpaceRJ('', 0) + ',',
          "Transaction Return Status": this.padSpaceRJ('', 0) + ',',
        });

        // holdpayEmployer.push(element.cosocso);
        holdpayMember.push(element.stfpcb);
        if (element.refno) holdpayRefno.push(parseInt(element.refno));
        rec++;
      });

      // if (holdpayEmployer.length > 0) {
      //   let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
      //   this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
      //   // holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
      // }

      if (holdpayMember.length > 0) {
        let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtMember = totalAmtMemHolder.toFixed(2);
        // holdAmtMember = this.totalAmtMember.split(".").join("");
      }

      // if (holdpayRefno.length > 0) {
      //   getAmtRefno = holdpayRefno.reduce(function (a, b) {
      //       return a + b;
      //   }, 0);
      // }
      // let totalamount = 0
      // totalamount = parseInt(this.totalAmtMember) + parseInt(this.totalAmtEmployer)

      let footer = 
        '99' + ',' +
        this.padSpaceRJ(this.totalAmtMember, 0) + ',' + //Employee Amount
        this.padSpaceRJ('', 0) + ',' + //Employer Amount
        this.padSpaceRJ('', 0) + ',' + //Employee Amount Total Record
        this.padSpaceRJ('', 0) + ',' + //Employer Amount Total Record
        this.padSpaceRJ(rec, 0) + ',' +
        this.padSpaceRJ(this.totalAmtMember, 0) + ',' + //Total amount of employee + employer
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',' + 
        this.padSpaceRJ('', 0) + ',';

      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body, footer);
        this.download(txtData, this.titleData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
        return e.stfpcb != 0
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(header, data, footer) {
      const csvRows = [];

      const dataheaders = Object.keys(data[0]);
      
      csvRows.push(header);

      //loop over the rows
      for (const row of data) {
        const values = dataheaders.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(footer);

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmtEmployer = this.totalAmtEmployer;
        item.totalAmtMember = this.totalAmtMember;
        item.accounterr = this.accErr;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        if (!this.isErr) {
          this.$router.push({ name: "monthlytaxexportlog", params: { item } });
        }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlytaxexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    // this.getAffinExportListHandler();
  },
};
</script>