<template>
  <div>
    <v-form>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
              >{{this.$t('reportsparam.filtercriteria')}}</v-subheader
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mb-8" />
            <v-row>
              <v-col
                v-if="this.showFilter.staffid"
                cols="4"
                sm="4"
                class="pb-4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.staff')"
                  type="text"
                  ref="staff"
                  v-model="filterCriteria.staffid"
                  :items="staffList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.gradeid"
                cols="4"
                sm="4"
                class="pb-4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.grade')"
                  type="text"
                  ref="grade"
                  v-model="filterCriteria.gradeid"
                  :items="gradeList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.employmenttypeid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.employmenttype')"
                  type="text"
                  ref="employmentType"
                  v-model="filterCriteria.employmenttypeid"
                  :items="emptypecatList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.companyid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.company')"
                  type="text"
                  ref="company"
                  v-model="filterCriteria.companyid"
                  :items="companyList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.branchid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.branch')"
                  type="text"
                  ref="description"
                  v-model="filterCriteria.branchid"
                  :items="branchList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.departmentid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.department')"
                  type="text"
                  ref="department"
                  v-model="filterCriteria.departmentid"
                  :items="departmentList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              
              <v-col
                v-if="this.showFilter.jobid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.job')"
                  type="text"
                  ref="job"
                  v-model="filterCriteria.jobid"
                  :items="jobList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

               <v-col
                v-if="this.showFilter.valvalueid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('setupleaveentitlementpolicysetup.leavegrp')"
                  type="text"
                  ref="leavegroup"
                  v-model="filterCriteria.valvalueid"
                  :items="leaveList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

               <v-col
                v-if="this.showFilter.unitid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.unit')"
                  type="text"
                  ref="unit"
                  v-model="filterCriteria.unitid"
                  :items="unitList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.sectionid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.section')"
                  type="text"
                  ref="section"
                  v-model="filterCriteria.sectionid"
                  :items="sectionList"
                  @input="updateValue"
                 
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              
              <v-col
                v-if="this.showFilter.divisionid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.division')"
                  type="text"
                  ref="division"
                  v-model="filterCriteria.divisionid"
                  :items="divisionList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

               <v-col
                v-if="this.showFilter.workgroupid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('attendancereportbypeiordbycompanyreport.workgroup')"
                  type="text"
                  ref="workgrpup"
                  v-model="filterCriteria.workgroupid"
                  :items="workgroupList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.claimgroupid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('setupclaimlimit.claimgroup')"
                  type="text"
                  ref="claimgroup"
                  v-model="filterCriteria.claimgroupid"
                  :items="claimgroupList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

               <v-col
                v-if="this.showFilter.targettraininggroupid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('traininghoursanalysisreport.targettraininggroup')"
                  type="text"
                  ref="targettraininggroup"
                  v-model="filterCriteria.targettraininggroupid"
                  :items="targettraininggroupList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col
                v-if="this.showFilter.positionid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.position')"
                  type="text"
                  ref="position"
                  v-model="filterCriteria.positionid"
                  :items="positionList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>

               <v-col
                v-if="this.showFilter.gradecatid"
                cols="4"
                sm="4"
                class="pb-n4 mt-n8"
              >
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.gradecategory')"
                  type="text"
                  ref="job"
                  v-model="filterCriteria.gradecatid"
                  :items="gradecatList"
                  @input="updateValue"
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>


            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>
<script>

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      filterCriteria: {
        staffid: "",
        gradeid: "",
        employmenttypeid: "",
        companyid: "",
        branchid: "",
        departmentid: "",
        jobid: "",
        valvalueid: "",
        unitid: "",
        sectionid: "",
        divisionid: "",
        workgroupid: "",
        claimgroupid: "",
        targettraininggroupid: "",
        positionid: "",
        gradecatid: "",
      },

      showFilter: {
        staffid: this.parentObject.filter.staffid,
        staffcode: this.parentObject.filter.staffcode,
        gradeid: this.parentObject.filter.gradeid,
        grddesc: this.parentObject.filter.grddesc,
        employmenttypeid: this.parentObject.filter.employmenttypeid,
        emptypecatdesc:this.parentObject.filter.emptypecatdesc,
        companyid: this.parentObject.filter.companyid,
        compnyname: this.parentObject.filter.compnyname,
        branchid: this.parentObject.filter.branchid,
        brchname: this.parentObject.filter.brchname,
        posdesc: this.parentObject.filter.posdesc,
        departmentid: this.parentObject.filter.departmentid,
        deptname: this.parentObject.filter.deptname,
        jobid: this.parentObject.filter.jobid,
        jobtitle: this.parentObject.filter.jobtitle,
        valvalueid: this.parentObject.filter.valvalueid,
        valuedesc: this.parentObject.filter.valuedesc,
        unitid:this.parentObject.filter.unitid,
        unitname:this.parentObject.filter.unitname,
        sectionid: this.parentObject.filter.sectionid,
        sectioname:this.parentObject.filter.sectioname,
        divisionid: this.parentObject.filter.divisionid,
        divnname:this.parentObject.filter.divnname,
        workgroupid: this.parentObject.filter.workgroupid,
        valuedes2: this.parentObject.filter.valuedesc,
        claimgroupid: this.parentObject.filter.claimgroupid,
        targettraininggroupid: this.parentObject.filter.targettraininggroupid,
        positionid: this.parentObject.filter.positionid,
        gradecatid: this.parentObject.filter.gradecatid,

      },

      staff: [],
      staffList: [],
      grade: [],
      gradeList: [],
      company: [],
      companyList: [],
      branch: [],
      branchList: [],
      department: [],
      departmentList: [],
      emptypecat: [],
      emptypecatList: [],
      job: [],
      jobList: [],
      leavegroup: [],
      leaveList: [],
      units: [],
      unitList: [],
      divisions: [],
      divisionList: [],
      sections: [],
      sectionList: [],
      workgroup: [],
      workgroupList: [],
      claimgroup: [],
      claimgroupList: [],
      targettraininggroup: [],
      targettraininggroupList: [],
      position: [],
      positionList: [],
      gradecat: [],
      gradecatList: [],
    };
  },

  methods: {
    loadStaffListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.staff = res.data.rec;
          console.log(res);
          for (let i = 0; i < vm.staff.length; i++) {
            vm.staffList.push({
              text: vm.staff[i].staffcode + " " + vm.staff[i].psnname + " " + vm.staff[i].compnyname,
              value: vm.staff[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadGradeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/grade/all"})
        .then((res) => {
          vm.grade = res.data.rec;

          for (let i = 0; i < vm.grade.length; i++) {
            vm.gradeList.push({
              text: vm.grade[i].grddesc,
              value: vm.grade[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadCompanyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: `/company/allfilter/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.company = res.data.rec;

          for (let i = 0; i < vm.company.length; i++) {
            vm.companyList.push({
              text: vm.company[i].compnyname,
              value: vm.company[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadBranchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/branch/all"})
        .then((res) => {
          vm.branch = res.data.rec;

          for (let i = 0; i < vm.branch.length; i++) {
            vm.branchList.push({
              text: vm.branch[i].brchname,
              value: vm.branch[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },


    loadDepartmentListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/department/all"})
        .then((res) => {
          vm.department = res.data.rec;

          for (let i = 0; i < vm.department.length; i++) {
            vm.departmentList.push({
              text: vm.department[i].deptname,
              value: vm.department[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadEmploymentTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/emptypecat/all"})
        .then((res) => {
          vm.emptypecat = res.data.rec;

          for (let i = 0; i < vm.emptypecat.length; i++) {
            vm.emptypecatList.push({
              text: vm.emptypecat[i].emptypecatdesc,
              value: vm.emptypecat[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadJobTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/job/all"})
        .then((res) => {
          vm.job = res.data.rec;

          for (let i = 0; i < vm.job.length; i++) {
            vm.jobList.push({
              text: vm.job[i].jobtitle,
              value: vm.job[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadLeaveGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/groupp_grouppb_leave/all"})
        .then((res) => {
          vm.leavegroup = res.data.rec;

          for (let i = 0; i < vm.leavegroup.length; i++) {
            vm.leaveList.push({
              text: vm.leavegroup[i].valuedesc,
              value: vm.leavegroup[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
     
    //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/unit/all"})
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < vm.units.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/section/all"})
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < vm.sections.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    
    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/division/all"})
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < vm.divisions.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

     loadWorkGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/groupp_grouppb_workday/all"})
        .then((res) => {
          vm.workgroup = res.data.rec;

          for (let i = 0; i < vm.workgroup.length; i++) {
            vm.workgroupList.push({
              text: vm.workgroup[i].valuedesc,
              value: vm.workgroup[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadClaimGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/groupp_grouppb_claim/all"})
        .then((res) => {
          vm.claimgroup = res.data.rec;

          for (let i = 0; i < vm.claimgroup.length; i++) {
            vm.claimgroupList.push({
              text: vm.claimgroup[i].valuedesc,
              value: vm.claimgroup[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

     loadTargetTrainingGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/groupp_grouppb_training/all"})
        .then((res) => {
          vm.targettraininggroup = res.data.rec;

          for (let i = 0; i < vm.targettraininggroup.length; i++) {
            vm.targettraininggroupList.push({
              text: vm.targettraininggroup[i].valuedesc,
              value: vm.targettraininggroup[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    
    loadPositionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/position/all"})
        .then((res) => {
          vm.position = res.data.rec;

          for (let i = 0; i < vm.position.length; i++) {
            vm.positionList.push({
              text: vm.position[i].posdesc,
              value: vm.position[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

     loadGradeCatListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/gradecat/all"})
        .then((res) => {
          vm.gradecat = res.data.rec;

          for (let i = 0; i < vm.gradecat.length; i++) {
            vm.gradecatList.push({
              text: vm.gradecat[i].grdcatdesc,
              value: vm.gradecat[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },




    updateValue() {
      this.$emit("input", {
        staffid: this.filterCriteria.staffid,
        staffcode: this.filterCriteria.staffcode,
        gradeid: this.filterCriteria.gradeid,
        grddesc: this.filterCriteria.grddesc,
        employmenttypeid: this.filterCriteria.employmenttypeid,
        emptypecatdesc: this.filterCriteria.emptypecatdesc,
        companyid: this.filterCriteria.companyid,
        compnyname: this.filterCriteria.compnyname,
        branchid: this.filterCriteria.branchid,
        brchname: this.filterCriteria.brchname,
        posdesc: this.filterCriteria.posdesc,
        departmentid: this.filterCriteria.departmentid,
        deptname: this.filterCriteria.deptname,
        jobid: this.filterCriteria.jobid,
        jobtitle: this.filterCriteria.jobtitle,
        valvalueid: this.filterCriteria.valvalueid,
        valuedesc: this.filterCriteria.valuedesc,
        unitid: this.filterCriteria.unitid,
        unitname: this.filterCriteria.unitname,
        sectionid: this.filterCriteria.sectionid,
        sectioname: this.filterCriteria.sectioname,
        divisionid: this.filterCriteria.divisionid,
        divnname: this.filterCriteria.divnname,
        workgroupid: this.filterCriteria.workgroupid,
        claimgroupid: this.filterCriteria.claimgroupid,
        targettraininggroupid: this.filterCriteria.targettraininggroupid,
        positionid: this.filterCriteria.positionid,
        gradecatid: this.filterCriteria.gradecatid,
      });
    },
  },

  mounted() {
    this.loadStaffListHandler();
    this.loadGradeListHandler();
    this.loadCompanyListHandler();
    this.loadBranchListHandler();
    this.loadDepartmentListHandler();
    this.loadEmploymentTypeListHandler();
    this.loadJobTypeListHandler();
    this.loadLeaveGroupListHandler();
    this.loadUnitListHandler();
    this.loadSectionListHandler();
    this.loadDivisionListHandler();
    this.loadWorkGroupListHandler();
    this.loadClaimGroupListHandler();
    this.loadTargetTrainingGroupListHandler();
    this.loadPositionListHandler();
    this.loadGradeCatListHandler();
  },
};
</script>