<template>
  <div>
    <contactdetails-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:profile="formData.pic = $event"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ContactDetailsForm from "../../../components/personnel/contactdetails/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "contactdetails-form": ContactDetailsForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('reccontactdetails.newcontact'),
      pageTitleValue: "",
      formData: {
        psnid: null,
        pic: null,
        staffid: null,
        compnyid: null,
        // effectivedate: null,
        // invaliddate: null,
        email: null,
        hpno: null,
        alternativeemail: null,
        externalno: null,
        correspondanceaddr1: null,
        correspondanceaddr2: null,
        correspondanceaddr3: null,
        cityid: null,
        cntryid: null,
        psocode: null,
        tel: null,
        permanentaddr1: null,
        asabove: null,
        permanentaddr2: null,
        permanentaddr3: null,
        permanentcityid: null,
        permanentcntryid: null,
        permanentposcode: null,
        permanenttel: null,
        emergencycontact1: null,
        contactno1: null,
        emergencycontact2: null,
        contactno2: null,
        remark: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    //insert new record
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      // if (vm.formData.asabove == true) {
      //   vm.formData.asabove = 1;
      // } else {
      //   vm.formData.asabove = 0;
      // }

      this.$store.dispatch('post', {url: "/contactdetails/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "contactdetailslist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
    cancelHandler() {
      this.$router.push({ name: "contactdetailslist" });
    },
  },
};
</script>