<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6 pt-6">
            <v-autocomplete
              outlined
             :label="this.$t('payrollprocessrun.payout')"
              type="text"
              :rules="[rules.required]"
              :items="payoutctrlList"
              v-model="formData.payoutctrlid"
              @change="changePayout(formData.payoutctrlid)"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="8" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutdescription')"
              type="text"
              v-model="payoutdesc"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutyear')"
              type="number"
              v-model="pyyear"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutmonth')"
              type="number"
              v-model="pymonth"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('postpayroll.payrollgroupdescription')"
              type="text"
              v-model="grouppdesc"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('postpayroll.claimrecorduptodate')"
              type="date"
              ref="receiptdate"
              :rules="[rules.required]"
              v-model="formData.receiptdate"
              dense
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <div class="btn-process">
            <v-btn color="primary" style="width: 150px" @click="onPost">
              {{$t('button.post')}}
            </v-btn>
          </div>

          <div class="btn-process">
            <v-btn color="primary" style="width: 150px" @click="onUnPost">
              {{$t('button.undoposting')}}
            </v-btn>
          </div>
        </v-row>
      </v-container>
      </v-form>
    </v-container>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
//import moment from "moment";
import ErrorDialog from "../../../components/common/ErrorDialog";
import "vue-loading-overlay/dist/vue-loading.css";


export default {

  components: {
    "app-error": ErrorDialog,
    // Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('postpayroll.claimpostingtopayroll'),
   

      formData: {
        payoutctrlid: null,
        receiptdate: null,
        userid: this.$store.getters.userId,
      },

      items: [],
      dataItems: [],
      tableOpt: {
        itemsPerPage: -1,
      },
      payoutctrls: [],
      process: [],
      leaverecord: [],
      payoutctrlList: [],
      leaveTypeList: [],
      allRecord: [],
      processedRecord: [],

      leavecode: null,
      payeeinputdetail: null,
      payoutdesc: "",
      pymonth: "",
      pyyear: "",
      grouppdesc: "",
      errMsg: "",
      isErr: false,
      loading: true,
    };
  },

  methods: {

    onUnPost() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      if ((this.$refs.form.validate())){
      this.$store.dispatch('put', {url: "/claimposting/claimtopayrollundo", payload: this.formData})
        .then((res) => {
          let dataholder = res.data.rec;

          if (res.data.status != 409) {
            let item = {
              processedCount: dataholder.unposted,
              staffprocessedCount: dataholder.staffprocess,
              totalUnPosted: dataholder.unposted,
            }
          
            this.$router.push({ name: "claimpayrolllog", params: { item } });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
      }
    },

    onPost() {
      const vm = this;

      if ((this.$refs.form.validate())){
      this.$store.dispatch('post', {url: "/claimposting/claimtopayroll", payload: this.formData})
        .then((res) => {
          let dataholder = res.data.rec;
          console.log('res', res.data)

          if (res.data.status != 409) {
            let item = {
              processedCount: dataholder.posted,
              staffprocessedCount: dataholder.staffprocess,
              totalPosted: dataholder.posted,
            }
            this.$router.push({ name: "claimpayrolllog", params: { item } });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
          
        })
        .catch((err) => {
          console.log('err', err)
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
      }
    },

    getPayoutRecords() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutrecord/all"})
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    changePayout(id) {
      let item = this.payoutctrls.find((item) => item.id == id);

      this.payoutcode = item.payoutcode;
      this.payoutdesc = item.payoutdesc;
      this.pyyear = item.pyyear;
      this.pymonth = item.pymonth;
      this.grouppdesc = item.grouppdesc;

    },

  },

  mounted() {
    this.getPayoutRecords();
  
  },
};
</script>

<style scoped>
.btn-process {
  margin-right: 120px;
  width: 100px;
}
</style>