<template>
  <div>
    <div v-if="!loading">
      <contactdetails-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:profile="formData[0].pic = $event"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactDetailsForm from "../../../components/personnel/contactdetails/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
// import moment from "moment";

//additional line
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "contactdetails-form": ContactDetailsForm,
    "app-error": ErrorDialog,

    //additional line
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('reccontactdetails.editcontactdetails'),
      pageTitleValue: "",
      formData: [],
      errMsg: "",
      isErr: false,
      editMode: true,
      loading: true,

      //additional line
      isConfirm: false,
    };
  },

  methods: {
    // delete contact details item
    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      //add div and change v-on delete
      this.isConfirm = !this.isConfirm;

      if ($event) {
        //request
        this.$store.dispatch('delete', {url:
              "/contactdetails/" +
              this.id +
              "/" +
              this.formData[0].userid})
          .then((res) => {
            // request --> successs
            // if error --> display error dialog
            // else push contact details list
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "contactdetailslist" });
            }
          })
          .catch((err) => {
            // request --> failure

            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "contactdetailsnew" });
    },

    cancelHandler() {
      this.$router.push({ name: "contactdetailslist" });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    // save contact details item after edit
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      var payload = this.formData[0];

      //request
      this.$store.dispatch('put', {url: "/contactdetails/" + vm.id, payload: payload})
        .then((res) => {
          // request --> success
          // if error --> display error dialog
          // else push contactdetails list

          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "contactdetailslist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          //request --> failure

          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //load ccontactdetails item to edit
    loadContactDetailsHandler() {
      const vm = this;

      //request
      this.$store.dispatch('get', {url: "/contactdetails/" + vm.id})
        .then((res) => {
          // request --> success

          //response ok
          if (res.status == 200) {
            console.log("formdata", res.data.rec);

            // push record into formData
            vm.formData = res.data.rec;
            vm.pageTitleValue = vm.formData[0].contactname;
            vm.formData[0].contactname = vm.formData[0].staffid;
            //vm.formData.lockstaff = res.data.rec[0].lockstaff;

            //set correct date format using moment
            // if (vm.formData[0].effectivedate) {
            //   vm.formData[0].effectivedate = moment(vm.formData[0].effectivedate).format("YYYY-MM-DD");
            // }

            // if (vm.formData[0].invaliddate) {
            //   vm.formData[0].invaliddate = moment(vm.formData[0].invaliddate).format("YYYY-MM-DD");
            // }

            //additional line --> get user id, passed in formData for update and delete functions
            vm.formData[0].userid = this.$store.getters.userId;

            vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          //request --> failure

          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadContactDetailsHandler();
  },
};
</script>
