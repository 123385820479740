<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >


                    <v-row>
                      <v-col cols="4" sm="6" class="pb-n5">
                       <v-text-field
                                outlined
                                :label="this.$t('ratingscaleset.ratingscaleset')"
                                type="text"
                                ref="ratingscalecode"
                                :rules="[rules.required]"
                                v-model="formData.ratingscalecode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('Setupratingscaleset.ratingscalesetdescription')"
                                type="text"
                                ref="ratingscaledesc"
                                :rules="[rules.required]"
                                v-model="formData.ratingscaledesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                        <v-row>
                      <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remarks"
                                :rules="[rules.required]"
                                v-model="formData.remarks"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                  
                       
                     <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                           {{$t('Setupratingscaleset.ratingscale')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.ratingscalesetdetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';
//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
       //'app-genericform': GenericForm,
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                ratingscalecode: this.parentObject.data.ratingscalecode,
                ratingscaledesc: this.parentObject.data.ratingscaledesc,
                isdisable: this.parentObject.data.isdisable,
                remarks: this.parentObject.data.remarks,
                ratingscalesetdetail: this.parentObject.data.ratingscalesetdetail,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
      
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew,

              tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('Setupratingscaleset.rating'), value: 'ratingcode', width: '200px', sortable: true},
                { text: this.$t('Setupratingscaleset.label'), value: 'label', width: '200px', sortable: true},
                { text: this.$t('Setupratingscaleset.ratingdescription'), value: 'ratingdesc', width: '200px', sortable: true},
                { text: this.$t('Setupratingscaleset.score'), value: 'score', width: '200px', sortable: true},
              
            ],

        }
    },

    methods: {

        addItemHandler($event) {
            this.formData.ratingscalesetdetail=$event
    
            this.updateValue();
        },


        updateValue() {
            this.$emit('input', {
                ratingscalecode: this.formData.ratingscalecode,
                ratingscaledesc: this.formData.ratingscaledesc,
                isdisable: this.formData.isdisable,
                remarks: this.formData.remarks,
                ratingscalesetdetail: this.formData.ratingscalesetdetail,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'ratingscalesetlist', params: { search: this.search}});
        },
    },

    mounted() {

    }
    
}
</script>