<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color = "'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('payrollprocessrun.payout')"
                type="text"
                :rules="[rules.required]"
                :items="payoutctrlList"
                v-model="formData.payoutid"
                @change="changePayout"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n5">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                v-model="payoutcode"
                outlined
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.description')"
                type="text"
                v-model="payoutdesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payoutstartdate')"
                type="date"
                v-model="startdate"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payoutenddate')"
                type="date"
                v-model="enddate"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsparam.year')"
                type="number"
                v-model="pyyear"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsparam.month')"
                type="number"
                v-model="pymonth"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payoutstatus')"
                readonly
                type="text"
                v-model="pystatusdesc"
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppayrolltype.payrolltype')"
                type="text"
                v-model="payrolltypedesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payrollgroup')"
                type="text"
                v-model="grouppdesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.exchangerate')"
                type="text"
                v-model="exchratedesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payoutfrequency')"
                type="text"
                v-model="salfreqdesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payrollprocessrun.payeelist')"
                type="text"
                v-model="payeelistdesc"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <div style="display: flex; align-items: flex-end; width: 100%">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-process" @click="onProcess"
            >{{$t('button.process')}}</v-btn
          >
        </div>
      </v-form>
    </v-container>
    <!-- <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">Payroll Run Process Log</h2>
        </v-row>

        <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
          <v-container>
            <v-row>
              <v-col cols="6" sm="6" class="orange lighten-5">
                <v-row>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="# Processed"
                      type="text"
                      v-model="listErr.processed"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="# Staff processed"
                      type="text"
                      v-model="listErr.staffprocessed"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="# Errors"
                      type="text"
                      v-model="listErr.errors"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="# Deleted"
                      type="text"
                      v-model="listErr.deleted"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.dataItems"
                :options="tableOpt"
                hide-default-footer
                item-key="errorlog"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-container>-->
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ErrorDialog from "../../../components/common/ErrorDialog";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    "app-error": ErrorDialog,
    Loading,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('payrollprocessrun.payrollrun'),
      formData: {
        payoutid: null,
        userid: this.$store.getters.userId,
      },
      dataHeader: [
        { text: this.$t('columns.no'), value: "index", width: "100px", sortable: true },
        { text: this.$t('columns.name'), value: "name", width: "300px", sortable: true },
        {
          text: this.$t('columns.staffid'),
          value: "staffid",
          width: "300px",
          sortable: true,
        },
        {
          text:this.$t('statutoryexportcommonused.errormessage'),
          value: "message",
          width: "300px",
          sortable: true,
        },
      ],
      dataItems: [],
      listErr: {
        processed: null,
        staffprocessed: null,
        deleted: null,
        errors: null,
      },
      tableOpt: {
        itemsPerPage: -1,
      },
      payoutctrls: [],
      payoutctrlList: [],
      payoutcode: "",
      payoutdesc: "",
      pymonth: "",
      pyyear: "",
      startdate: "",
      enddate: "",
      pystatusdesc: "",
      payrolltypedesc: "",
      grouppdesc: "",
      exchratedesc: "",
      salfreqdesc: "",
      payeelistdesc: "",
      errMsg: "",
      isErr: false,
      loading: true,
    };
  },
  methods: {
    onProcess() {
      // run process
      //const vm = this
      this.loading = true;
      this.$store.dispatch('post', {url: "/payroll/process",
          /*{payoutid: this.formData.payoutid}*/ payload: this.formData})
        .then((res) => {
          console.log(res);

          this.$router.push({ name: "payrollprocesslog" });

          // this.listErr.processed = res.data.res.TotalProcessed;
          // this.listErr.staffprocessed = res.data.res.StaffProcessed;
          // this.listErr.deleted = res.data.res.Deleted;
          // this.listErr.errors = res.data.res.Errors;

          // if (res.data.res.Details.length > 0) {
          //   this.dataItems = res.data.res.Details;
          //   for (let i = 0; i < this.dataItems.length; i++) {
          //     this.dataItems[i].index = i + 1;
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadPayoutControls() {
      const vm = this;
      this.$store.dispatch('get', {url: "/payroll/process/pyctrl"})
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            if (vm.payoutctrls[i].startdate) {
              vm.payoutctrls[i].startdate = moment(
                vm.payoutctrls[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.payoutctrls[i].enddate) {
              vm.payoutctrls[i].enddate = moment(
                vm.payoutctrls[i].enddate
              ).format("YYYY-MM-DD");
            }

            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    changePayout($event) {
      let item = this.payoutctrls.find((item) => item.id == $event);

      this.payoutcode = item.payoutcode;
      this.payoutdesc = item.payoutdesc;
      this.startdate = item.startdate;
      this.enddate = item.enddate;
      this.pyyear = item.pyyear;
      this.pymonth = item.pymonth;
      this.pystatusdesc = item.pystatusdesc;
      this.payrolltypedesc = item.payrolltypedesc;
      this.grouppdesc = item.grouppdesc;
      this.exchratedesc = item.exchratedesc;
      this.salfreqdesc = item.salfreqdesc;
      this.payeelistdesc = item.payeelistdesc;
    },
  },

  mounted() {
    this.loadPayoutControls();
  },
};
</script>

<style scoped>
.btn-process {
  margin-right: 120px;
  width: 100px;
}
</style>