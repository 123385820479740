<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: "Domestic Inquiry List",
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: "Case ID", value: "caseid", width: "100px" },
        { text: "Incident Date", value: "incidentdate", width: "160px" },
        { text: "Short Description", value: "incidentshortdesc", width: "200px" },
        { text: "Staff Name", value: "staffname", width: "200px" },
        { text: "Session Description", value: "sessiondesc", width: "200px" },
        { text: "DI Date", value: "didate", width: "160px" },
        { text: "DI Time", value: "ditime", width: "160px" },
        { text: "DI Venue", value: "irdomesticvenuecode", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getDomesticInquiryHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/irdomesticinquiry/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
              if (vm.dataItems[i].incidentdate) {
                  vm.dataItems[i].incidentdate = moment(vm.dataItems[i].incidentdate).format('YYYY-MM-DD');
                }

              if (vm.dataItems[i].didate) {
                  vm.dataItems[i].didate = moment(vm.dataItems[i].didate).format('YYYY-MM-DD');
                }
          }

          vm.loading = !vm.loading;

        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "domesticinquiryedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "domesticinquirynew" });
    },
  },

  mounted() {
    this.getDomesticInquiryHandler();
  },
};
</script>