<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:profile="formData.picture = $event"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import AnnouncementForm from '../../../components/settings/announcement/Form';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-form': AnnouncementForm,
        'app-error': ErrorDialog
    },

    data() {
        return {
            pageTitle: this.$t('recannouncementsetup.newannouncement'),
            pageTitleValue : '',
            formData: {
                id: null,
                announce: null,
                announcedesc: null,               
                startdate: null,
                enddate: null,
                suspended: 0,
                picture: null,
                stamp: 0,
                userid: this.$store.getters.userId,                
                   
            },
            
            errMsg: '',
            isErr: false,
            editMode: false
        }
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        saveHandler() {
            const vm = this;

            // Convert boolean to integer. 1 = true, 0 = false
            if (this.formData.suspended == false) {
                this.formData.suspended = 0;
            } else {
                this.formData.suspended = 1;
            }
            

            this.$store.dispatch('post', {url: '/announcement/create', payload: this.formData})
                .then(res => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } else {
                        this.$router.push({name: 'announcementlist'});
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        cancelHandler() {
            this.$router.push({name: 'announcementlist'});
        },
    }
}
</script>