<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row v-if="!this.formData.lockstaff">
            <v-col cols="10" sm="10" md="10">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                dense
                :items="staffDetailsList"
                v-model="formData.staffid"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="10" sm="10" md="10">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                dense
                :items="staffDetailsList"
                v-model="formData.staffid"
                readonly
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" class="mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffcode')"
                dense
                v-model="formData.staffid"
                :items="staffIdList"
                hide-selected
                readonly
                background-color="#ffeab2"
                append-icon=""
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" class="mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.company')"
                dense
                v-model="formData.staffid"
                :items="staffCompanyList"
                hide-selected
                readonly
                background-color="#ffeab2"
                append-icon=""
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" class="mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setuppayitem.payitem')"
                type="text"
                dense
                :items="payItemList"
                v-model="formData.payitemid"
                @change="changePayItemCodeHandler(formData.payitemid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.payitemcode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" md="4" class="mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                dense
                v-model="formData.startdate"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" class="mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                dense
                v-model="formData.enddate"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" md="4" class="mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonpayrolldirectassignment.amount')"
                type="number"
                dense
                v-model="formData.amount"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" class="mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.currency')"
                type="text"
                ref="crcyid"
                :items="currencyList"
                v-model="formData.crcyid"
                hide-selected
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setuppayrolltype.payrolltype')"
                type="text"
                :items="payrollTypeList"
                :rules="[rules.required]"
                v-model="formData.payrolltypeid"
                dense
                @change="changePayrollTypeCodeHandler(formData.payrolltypeid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.payrolltypecode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" md="10" class="mt-n8">
              <v-text-field
                outlined
                :label="this.$t('payeeinputprocess.justification')"
                type="text"
                dense
                v-model="formData.justification"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" md="10" class="mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                dense
                v-model="formData.remark"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      formData: {
        id: this.parentObject.data.id,
        staffid: this.parentObject.data.staffid,
        payitemid: this.parentObject.data.payitemid,
        payitemcode: this.parentObject.data.payitemcode,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        amount: this.parentObject.data.amount,
        payrolltypeid: this.parentObject.data.payrolltypeid,
        payrolltypecode: this.parentObject.data.payrolltypecode,
        crcyid: this.parentObject.data.crcyid,
        payeeactionid: 2,
        justification: this.parentObject.data.justification,
        lockstaff: this.parentObject.data.lockstaff,
        remark: this.parentObject.data.remark,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },

      payItemList: [],
      currencyList: [],
      staffDetailsList: [],
      staffIdList: [],
      staffCompanyList: [],
      payrollTypeList: [],

      errMsg: "",
      isErr: false,
      editMode: false,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    loadStaffDetailsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffIdList.push({
              text: res.data.rec[i].staffcode,
              value: res.data.rec[i].id,
            });
          }

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffCompanyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPayItemsHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payitem/codes"})
        .then((res) => {
          vm.payitem = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.payItemList.push({
              text: res.data.rec[i].payitemdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadCurrencyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/currency/all"})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.currencyList.push({
              text: res.data.rec[i].crcydesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPayrollType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payrolltype/all"})
        .then((res) => {
          vm.payrollType = res.data.rec;

          vm.payrollType.forEach((element) => {
            vm.payrollTypeList.push({
              text: element.payrolltypedesc,
              value: element.id,
            });
          });
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changePayrollTypeCodeHandler(id) {
      let item = this.payrollType.find((item) => item.id == id);

      this.formData.payrolltypecode = item.payrolltypecode;
    },

    changePayItemCodeHandler(id) {
      let item = this.payitem.find((item) => item.id == id);

      this.formData.payitemcode = item.payitemcode;
    },

    updateValue() {
      this.$emit("input", {
        id: this.formData.id,
        staffid: this.formData.staffid,
        payitemid: this.formData.payitemid,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        amount: this.formData.amount,
        payrolltypeid: this.formData.payrolltypeid,
        crcyid: this.formData.crcyid,
        payeeactionid: 2,
        justification: this.formData.justification,
        lockstaff: this.formData.lockstaff,
        remark: this.formData.remark,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.updateValue();
      console.log("check2", this.formData);
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "personpayrolldirectassignmentlist" , params: { search: this.search}});
    },

    check() {
      console.log("check", this.formData);
    },
  },

  mounted() {
    this.loadPayItemsHandler();
    this.loadCurrencyListHandler();
    this.loadStaffDetailsListHandler();
    this.loadPayrollType();
    this.check();

    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>