<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView'

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupleaveentitlementpolicysetup.leaveentitlementpolicylist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('columns.description'), value: 'leavedesc', width: '200px' },
                { text: this.$t('columns.effectivefrom'), value: 'startdate', width: '200px' },
                { text: this.$t('columns.effectiveuntil'), value: 'enddate', width: '200px' },
                { text: this.$t('setupleaveentitlementpolicysetup.roundingoption'), value: 'roundingdesc', width: '200px' },
                { text: this.$t('setupleaveentitlementpolicysetup.prorateby'), value: 'proratemtd', width: '200px' },
                { text: this.$t('setupleaveentitlementpolicysetup.allocationpolicy'), value: 'allocatemtd', width: '200px' },
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadLeaveEntitlement() {
            const vm = this;

            this.$store.dispatch('get', {url: '/leaveentitlementdt/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'leaveentitlementpolicyedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'leaveentitlementpolicynew'});
        }
    },

    mounted() {
        this.loadLeaveEntitlement();
    }
}
</script>