<template>
  <div>
    <personstatutory-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PersonStatutoryForm from "../../../components/payroll/personstatutoryinfo/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "personstatutory-form": PersonStatutoryForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setuppersonstatutorynumberinfo.createpersonstatutoryinfo'),
      pageTitleValue: "",
      formData: {
        psnid: null,
        staffid: null,
        compnyid: null,
        vendorcatid: null,
        refno: null,
        startdate: null,
        enddate: null,
        spouserefno: null,
        spousebranch: null,
        refcodeid: null,
        remark: null,
        stamp: null,

        // additional line
        userid: this.$store.getters.userId,
      },

      vendorcategoryList: [],
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      //req to insert record

      this.$store.dispatch('post', {url: "/personstatutoryinfo/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            //go to list after req
            this.$router.push({ name: "personstatutoryinfolist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
    cancelHandler() {
      this.$router.push({ name: "personstatutoryinfolist" });
    },
  },
};
</script>