<template>
    <div>

 <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>

    </div>
   
</template>


<script>

import GridView from '../../../components/common/GridView'; 
export default {
    
    components: {
        'app-gridview': GridView
    
    },  

    data() {

         return {
            pageTitle: this.$t('setupvendor.vendorlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('setupcompanyreferencenumber.vendor'), value: 'vendorcode', width: '150px' },
                { text: this.$t('columns.description'), value: 'vendordesc', width: '200px' },
                { text: this.$t('setupvendor.category'), value: 'categorycode', width: '200px' },
                { text: this.$t('columns.addressline1'), value: 'addr1', width: '200px' },
                { text: this.$t('columns.addressline2'), value: 'addr2', width: '200px' },
                { text: this.$t('columns.addressline3'), value: 'addr3', width: '200px' },
                { text: this.$t('columns.state'), value: 'cityname', width: '200px' },
                { text: this.$t('columns.country'), value: 'cntryname', width: '200px' },
                { text: this.$t('columns.postcode'), value: 'poscode', width: '200px' },
                { text: this.$t('columns.telephone'), value: 'tel', width: '200px' },
                { text: this.$t('columns.fax'), value: 'fax', width: '200px' },
                { text: this.$t('columns.email'), value: 'email', width: '200px' },
                { text: this.$t('setupcompanybankaccount.officer'), value: 'officer', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' },
            ],
            dataItems: [],

            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }

    }, 

    methods: {
        //get all records 
        getVendorListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/vendor/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },

        editItemHandler(item) {
            this.$router.push({name: 'vendoredit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'vendornew'});
        }, 

         


    },

    mounted() {
        this.getVendorListHandler();
    }
}
</script>