<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{this.parentObject.pageTitle}}
            <span
              class="page_title_value"
            >{{this.parentObject.pageTtileValue}}</span>
          </h2>
        </v-row>

        <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('setupcompanyreferencenumber.vendor')"
                type="text"
                ref="vendorcode"
                :rules="[rules.required]"
                v-model="formData.vendorcode"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.description')"
                type="text"
                ref="vendordesc"
                :rules="[rules.required]"
                v-model="formData.vendordesc"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setupvendor.category')"
                type="text"
                ref="vendorcatid"
                :rules="[rules.required]"
                :items="catitemsList"
                v-model="formData.vendorcatid"
                @input="updateValue"
                @change="changeCategoryHandler(formData.vendorcatid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.categorycode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.addressline1')"
                type="text"
                ref="addr1"
                v-model="formData.addr1"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.addressline2')"
                type="text"
                ref="addr2"
                v-model="formData.addr2"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.addressline3')"
                type="text"
                ref="addr3"
                v-model="formData.addr3"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.city')"
                type="text"
                ref="stateid"
                hide-selected
                :items="cityList"
                v-model="formData.stateid"
                @input="updateValue"
                @change="changeCityHandler(formData.stateid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.cityCode"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-dialog v-model="cityDialog" max-width="600px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn dense v-on="on">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <app-genericform
                    v-bind:parentData="{
                                            tabItems: cityItems,
                                            formTitle: `${this.$t('columns.city')}`,
                                            formId: 'city'
                                        }"
                    v-on:input="itemData = $event"
                    v-on:saveCity="saveCityHandler"
                    v-on:deleteCity="deleteCityHandler($event)"
                  />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" text @click="cityDialog = false">{{$t('button.close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.country')"
                type="text"
                ref="cntryid"
                :items="countryList"
                hide-selected
                v-model="formData.cntryid"
                @input="updateValue"
                @change="changeCountryHandler(formData.cntryid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')
"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="countryCode"
                @input="updateValue"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-dialog v-model="countryDialog" max-width="600px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn dense v-on="on">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <app-genericform
                    v-bind:parentData="{
                                            tabItems: countryItems,
                                            formTitle:`${this.$t('columns.country')}`,
                                            formId: 'country'
                                        }"
                    v-on:input="itemData = $event"
                    v-on:saveCountry="saveCountryHandler"
                    v-on:deleteCountry="deleteCountryHandler"
                  />

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" text @click="countryDialog = false">{{$t('button.close')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                type="number"
                ref="poscode"
                :label="this.$t('columns.postcode')"
                outlined
                dense
                class="numberfield"
                v-model="formData.poscode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="5" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.telephone')"
                type="number"
                ref="tel"
                v-model="formData.tel"
                @input="updateValue"
                dense
                class="numberfield"
              ></v-text-field>
            </v-col>

            <v-col cols="5" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.fax')"
                type="number"
                ref="fax"
                v-model="formData.fax"
                @input="updateValue"
                dense
                class="numberfield"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.email')"
                type="text"
                ref="email"
                v-model="formData.email"
                @input="updateValue"
                :rules="rules.email"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setupcompanybankaccount.officer')"
                type="text"
                ref="officer"
                v-model="formData.officer"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                ref="remark"
                v-model="formData.remark"
                @input="updateValue"
                dense
                class="border-color"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>

import GenericForm from "../../generic/GridForm";

export default {
  props: {
    parentObject: Object
  },

  components: {
    "app-genericform": GenericForm
  },

  data() {
    return {
      formData: {
        vendorcode: this.parentObject.data.vendorcode,
        vendordesc: this.parentObject.data.vendordesc,
        vendorcatid: this.parentObject.data.vendorcatid,
        categorycode: this.parentObject.data.categorycode,
        addr1: this.parentObject.data.addr1,
        addr2: this.parentObject.data.addr2,
        addr3: this.parentObject.data.addr3,
        stateid: this.parentObject.data.stateid,
        cityCode: this.parentObject.data.citycode,
        cntryid: this.parentObject.data.cntryid,
        poscode: this.parentObject.data.poscode,
        tel: this.parentObject.data.tel,
        fax: this.parentObject.data.fax,
        email: this.parentObject.data.email,
        remark: this.parentObject.data.remark,
        officer: this.parentObject.data.officer,
        stamp: this.parentObject.data.stamp,

        //additional line
        userid: this.parentObject.data.userid
      },
      rules: {
        required: value => !!value || this.$t('reportsparam.required'),
        counter: value => value.length <= 200 || "Max 200 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },

      // categories records array
      categories: [],

      //category items --> desc + id
      catitemsList: [],

      //category items --> all fields
      catitems: [],

      //cities --> name + id
      cityList: [],

      //cities --> all fields
      cityItems: [],

      //cities --> records
      cities: [],

      //countries --> records
      countries: [],

      //countries --> name + id
      countryList: [],

      //countries --> all fields
      countryItems: [],

      cityDialog: false,
      countryDialog: false,

      countryCode: "",

      categorycode: "",
       search: this.parentObject.searchnew
    };
  },

  methods: {
    // load list handlers

    //load vendor cats
    loadVendorcatidListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/vendorcategory/all"})
        .then(res => {

          //req success --> push data into categories array 
          vm.categories = res.data.rec;


          //push data into catitemsList 
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.catitemsList.push({
              text: res.data.rec[i].categorydesc,
              value: res.data.rec[i].id
            });

          //push data in catitems 
            vm.catitems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].categorycode,
              desc: res.data.rec[i].categorydesc,
              stamp: res.data.rec[i].stamp
            });
          }
        })
        .catch(err => {

          //req fail --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load citites list 
    loadCityListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/city/all"})
        .then(res => {

          // req success --> push data into citites array 
          vm.cities = res.data.rec;

          //push data into cityList array 
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.cityList.push({
              text: res.data.rec[i].cityname,
              value: res.data.rec[i].id
            });

          //push ddta in cityItems array 
            vm.cityItems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].citycode,
              desc: res.data.rec[i].cityname,
              stamp: res.data.rec[i].stamp
            });
          }

          //change citycode if has formData.cityid 
          if (vm.formData.cityid) {
            vm.changeCityHandler(vm.formData.cityid);
          }
        })
        .catch(err => {

          //req fail --> emit error 
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

  //load countries list 
    loadCountryListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/country/all"})
        .then(res => {
          vm.countries = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.countryList.push({
              text: res.data.rec[i].cntryname,
              value: res.data.rec[i].id
            });

            vm.countryItems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].cntrycode,
              desc: res.data.rec[i].cntryname,
              stamp: res.data.rec[i].stamp
            });
          }

          vm.countryLoading = false;

          if (vm.formData.cntryid) {
            vm.changeCountryHandler(vm.formData.cntryid);
          }
        })
        .catch(err => {

          //req fail --> emit error 
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    updateValue() {
      this.$emit("input", {
        vendorcode: this.formData.vendorcode,
        vendordesc: this.formData.vendordesc,
        vendorcatid: this.formData.vendorcatid,
        categorycode: this.formData.categorycode,
        addr1: this.formData.addr1,
        addr2: this.formData.addr2,
        addr3: this.formData.addr3,
        stateid: this.formData.stateid,
        cityCode: this.formData.cityCode,
        cntryid: this.formData.cntryid,
        poscode: this.formData.poscode,
        tel: this.formData.tel,
        fax: this.formData.fax,
        email: this.formData.email,
        officer: this.formData.officer,
        remark: this.formData.remark,
        stamp: this.formData.stamp,

        //addtional line
        userid: this.formData.userid
      });
    },
    
    //add new city in popup 
    saveCityHandler() {
      const vm = this;
      

      //if already exists, 
      if (this.itemData.itemid) {

        //update city record 
        this.$store.dispatch('put', {url: "/city/" + this.itemData.itemid, payload: {
            citycode: this.itemData.itemcode,
            cityname: this.itemData.itemdesc,
            stamp: this.itemData.itemstamp
          }})
          .then(res => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.cities = [];
              vm.cityList = [];
              vm.cityItems = [];

              this.loadCityListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {

            //req fail --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      } else {

        //else if not exists, insert new city 
        this.$store.dispatch('post', {url: "/city/create", payload: {
            citycode: this.itemData.itemcode,
            cityname: this.itemData.itemdesc
          }})
          .then(res => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.cities = [];
              vm.cityList = [];
              vm.cityItems = [];

              this.loadCityListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {

            //req fail --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    //add country handler 
    saveCountryHandler() {
      const vm = this;

      //if items exists, update record 
      if (this.itemData.itemid) {
        this.$store.dispatch('put', {url: "/country/" + this.itemData.itemid,
            payload: {
              cntrycode: this.itemData.itemcode,
              cntryname: this.itemData.itemdesc,
              stamp: this.itemData.itemstamp
            }
          })
          .then(res => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.countries = [];
              vm.countryList = [];
              vm.countryItems = [];

              this.loadCountryListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {

            //req fail --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      } else {

        //else if not exists, insert new record 
        this.$store.dispatch('post', {url: "/country/create", payload: {
            cntrycode: this.itemData.itemcode,
            cntryname: this.itemData.itemdesc
          }})
          .then(res => {
            if (res.data.status == 201) {
              // resets all arrays
              vm.countries = [];
              vm.countryList = [];
              vm.countryItems = [];

              this.loadCountryListHandler();
            } else {

              //req fail --> emit error
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {

            //req fail --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    
    deleteCityHandler($event) {
      const vm = this;

      if (confirm("Remove " + $event.desc + "?")) {
        this.$store.dispatch('delete', {url: "/city/" + $event.id})
          .then(res => {
            if (res.data.status == 200) {
              // resets all arrays
              vm.cities = [];
              vm.cityList = [];
              vm.cityItems = [];

              this.loadCityListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    deleteCountryHandler($event) {
      const vm = this;

      if (confirm("Remove " + $event.desc + "?")) {
        this.$store.dispatch('delete', {url: "/country/" + $event.id})
          .then(res => {
            if (res.data.status == 200) {
              // resets all arrays
              vm.countries = [];
              vm.countryList = [];
              vm.countryItems = [];

              this.loadCountryListHandler();
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    // change handlers
    //change citycode after selecting cityid
    changeCityHandler(id) {
      let item = this.cities.find(item => item.id === id);

      this.formData.cityCode = item.citycode;
    },

    //change countryCode after selecting cntryid
    changeCountryHandler(id) {
      let item = this.countries.find(item => item.id === id);

      this.countryCode = item.cntrycode;
    },

    //change categorycode after selecting category
    changeCategoryHandler(id) {
      let item = this.catitems.find(item => item.id == id);
      //alert(item.code);
      this.formData.categorycode = item.code;
    },

    newHandler() {
      this.$emit("new");
    },
    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "vendorlist", params: { search: this.search} });
    }
  },

  mounted() {
    this.loadVendorcatidListHandler();
    this.loadCityListHandler();
    this.loadCountryListHandler();
  }
};
</script>


<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

