<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <!-- <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff"> -->
                        <v-col cols="12" sm="7" md="7" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                dense
                                v-model="formData.staffid"
                                :items="staffList"
                                @change="changeStaffHandler(formData.staffid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>

                        <!-- <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmsetup.ssbsetup')"
                                type="text"
                                dense
                                v-model="formData.ssbgroupid"
                                :items="ssbSetupList"
                               readonly
                               background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col> -->

                        <!-- <v-col cols="1" sm="1" class="pb-n5">
                            <v-dialog v-model="ssbSetupDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: ssbSetupItems,
                                            formTitle: `${this.$t('mmsetup.ssbsetup')}`,
                                            formId: 'ssbsetup'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveSsbSetup="saveSsbSetupHandler"
                                        v-on:deleteSsbSetup="deleteSsbSetupHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="ssbSetupDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col> -->
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.staffcode')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.compnyname"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('maintenanceforpayroll.paymentmode')"
                                type="text"
                                dense
                                v-model="formData.paymentmodeid"
                                :items="paymodeList"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('maintenanceforpayroll.paymentstatus')"
                                type="text"
                                dense
                                v-model="formData.paymentstatusid"
                                :items="paystatList"
                                @change="changePaystatHandler(formData.paymentstatusid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="2" md="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.paymentstatus"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmsetup.ssbgroup')"
                                type="text"
                                dense
                                v-model="formData.ssbgroupid"
                                :items="ssbSetupList"
                                @change="changeSsbSetupHandler(formData.ssbgroupid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="2" md="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="formData.ssbgroupdesc"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuptax.taxsetup')"
                                type="text"
                                dense
                                v-model="formData.taxsetupid"
                                :items="taxsetupList"
                                @change="changeTaxSetupHandler(formData.taxsetupid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="2" md="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="formData.taxsetupcode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmtaxratesetup.taxgroup')"
                                type="text"
                                dense
                                v-model="formData.taxgroupid"
                                :items="taxgroupsetupList"
                                @change="changeTaxGroupSetupHandler(formData.taxgroupid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="2" md="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="formData.taxgroupcode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmpsnstatutory.taxchildcount')"
                                type="number"
                                ref="taxchild"
                                v-model="formData.taxchild"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmpsnstatutory.taxparentcount')"
                                type="number"
                                ref="taxparent"
                                v-model="formData.taxparent"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        // 'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffno,
                compnyname: this.parentObject.data.companyname,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                paymentmodeid: this.parentObject.data.paymentmodeid,
                paymodecode: this.parentObject.data.paymodecode,
                paymentstatusid: this.parentObject.data.paymentstatusid,
                paymentstatus: this.parentObject.data.paymentstatus,
                ssbgroupid: this.parentObject.data.ssbgroupid,
                ssbgroupcode: this.parentObject.data.ssbgroupcode,
                ssbgroupdesc: this.parentObject.data.ssbgroupdesc,
                taxsetupid: this.parentObject.data.taxsetupid,
                taxsetupcode: this.parentObject.data.taxsetupcode,
                taxsetupdesc: this.parentObject.data.taxsetupdesc,
                taxgroupid: this.parentObject.data.taxgroupid,
                taxgroupcode: this.parentObject.data.taxgroupcode,
                taxgroupdesc: this.parentObject.data.taxgroupdesc,
                taxchild: this.parentObject.data.childcount,
                taxparent: this.parentObject.data.parentcount,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            selected: [],
            affectItems: [],

            staffList: [],
            staff: [],

            paymodeList: [],
            paymode: [],

            paystatList: [],
            paystat: [],

            ssbSetupList: [],
            ssbSetup: [],

            taxsetupList: [],
            taxsetup: [],

            taxgroupsetupList: [],
            taxgroupsetup: [],

            errMsg: '',

            ssbSetupDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        loadStaffListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.staff = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPaymodeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/paymode/all'})
                .then(res => {
                    vm.paymode = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.paymodeList.push(
                            {
                                text: res.data.rec[i].paymodecode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPaystatListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/paystat/all'})
                .then(res => {
                    vm.paystat = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.paystatList.push(
                            {
                                text: res.data.rec[i].paystatcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadSsbSetupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmssbsetup/all'})
                .then(res => {
                    vm.ssbSetup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.ssbSetupList.push(
                            {
                                text: res.data.rec[i].ssbgroupcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadTaxSetupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxratesetup/all'})
                .then(res => {
                    vm.taxsetup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxsetupList.push(
                            {
                                text: res.data.rec[i].taxsetupcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadTaxGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxgroup/all'})
                .then(res => {
                    vm.taxgroupsetup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxgroupsetupList.push(
                            {
                                text: res.data.rec[i].taxgroupcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        // saveSetupHandler() {
        //     const vm = this;
        //     if (this.itemData.itemid) {
        //         this.$store.dispatch('put', {url: '/mmssbsetup/' + this.itemData.itemid, payload: {
        //         ssbgroupcode: this.itemData.itemcode,
        //         ssbgroupdesc: this.itemData.itemdesc,
        //         stamp: this.itemData.itemstamp,
        //         userid: this.formData.userid
        //         }})
        //         .then(res => {
        //             if (res.data.status == 201) {
        //                 // resets all arrays
        //                 vm.ssbSetup = [];
        //                 vm.ssbSetupItems = [];
        //                 vm.ssbSetupList = [];

        //                 this.loadSsbSetupListHandler();
        //             } else {
        //                 vm.isErr = true;
        //                 vm.errMsg = res.data.error;
        //                 vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //             }
        //         })
        //         .catch(err => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //         });
        //     } else {
        //         this.$store.dispatch('post', {url: '/mmssbsetup/create', payload: {
        //         ssbgroupcode: this.itemData.itemcode,
        //         ssbgroupdesc: this.itemData.itemdesc,
        //         userid: this.formData.userid
        //         }})
        //         .then(res => {
        //             if (res.data.status == 201) {
        //                 // resets all arrays
        //                 vm.ssbSetup = [];
        //                 vm.ssbSetupItems = [];
        //                 vm.ssbSetupList = [];

        //                 this.loadSsbSetupListHandler();
        //             } else {
        //                 vm.isErr = true;
        //                 vm.errMsg = res.data.error;
        //                 vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //             }
        //         })
        //         .catch(err => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //         });
        //     }
        // },

        // deleteSetupHandler($event) {
        //     const vm = this;

        //     if (confirm('Remove ' + $event.desc + '?')) {
        //         this.$store.dispatch('delete', {url: '/mmssbsetup/' + $event.id + '/' + this.formData.userid})
        //         .then(res => {
        //             if (res.data.status == 200) {
        //                 // resets all arrays
        //                 vm.ssbSetup = [];
        //                 vm.ssbSetupItems = [];
        //                 vm.ssbSetupList = [];

        //                 this.loadSsbSetupListHandler();
        //             } else {
        //                 vm.isErr = true;
        //                 vm.errMsg = res.data.error;
        //                 vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //             }
        //         })
        //         .catch(err => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //         });                
        //     }

        // },

        changeStaffHandler(id) {
            let item = this.staff.find(item => item.id === parseInt(id));
            console.log('item', item)
            this.formData.staffcode = item.staffcode;
            this.formData.compnyname = item.compnyname;
        },

        changePaymodeHandler(id) {
            let item = this.paymode.find(item => item.id === parseInt(id));
            
            this.formData.paymodecode = item.paymodecode;
        },

        changePaystatHandler(id) {
            let item = this.paystat.find(item => item.id === parseInt(id));
            
            this.formData.paymentstatus = item.paystatcode;
        },

        changeSsbSetupHandler(id) {
            let item = this.ssbSetup.find(item => item.id === parseInt(id));
            
            this.formData.ssbgroupcode = item.ssbgroupcode;
            this.formData.ssbgroupdesc = item.ssbgroupdesc;
        },

        changeTaxSetupHandler(id) {
            let item = this.taxsetup.find(item => item.id === parseInt(id));
            
            this.formData.taxsetupcode = item.taxsetupcode;
            this.formData.taxsetupdesc = item.taxsetupdesc;
        },

        changeTaxGroupSetupHandler(id) {
            let item = this.taxgroupsetup.find(item => item.id === parseInt(id));
            
            this.formData.taxgroupcode = item.taxgroupcode;
            this.formData.taxgroupdesc = item.taxgroupdesc;
        },

        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyname: this.formData.compnyname,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                paymentmodeid: this.formData.paymentmodeid,
                paymodecode: this.formData.paymodecode,
                paymentstatusid: this.formData.paymentstatusid,
                paymentstatus: this.formData.paymentstatus,
                ssbgroupid: this.formData.ssbgroupid,
                ssbgroupcode: this.formData.ssbgroupcode,
                ssbgroupdesc: this.formData.ssbgroupdesc,
                taxsetupid: this.formData.taxsetupid,
                taxsetupcode: this.formData.taxsetupcode,
                taxsetupdesc: this.formData.taxsetupdesc,
                taxgroupid: this.formData.taxgroupid,
                taxgroupcode: this.formData.taxgroupcode,
                taxgroupdesc: this.formData.taxgroupdesc,
                taxchild: this.formData.taxchild,
                taxparent: this.formData.taxparent,
                stamp: this.formData.stamp,
                userid: this.$store.getters.userId
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmpsnstatutorylist', params: { search: this.search}});
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = !this.isErr;
            this.errMsg = '';
        }
        
    },

    mounted() {
        this.loadStaffListHandler();
        this.loadPaymodeListHandler();
        this.loadPaystatListHandler();
        this.loadSsbSetupListHandler();
        this.loadTaxSetupListHandler();
        this.loadTaxGroupListHandler();

        // if (this.parentObject.isEdit){
        //     this.duplicateStaffData = this.formData;
            
        //     if (this.parentObject.data.lockstaff == "N") {
        //         this.formData.lockstaff = false;
        //     } else {
        //         this.formData.lockstaff = true;
        //     }
        // }
    }
}
</script>