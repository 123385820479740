<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData= $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:cancel="cancelHandler"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";


import AppraisalRecordForm from "../../../components/performance/appraisalrecord/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": AppraisalRecordForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('appraisalrecord.editappraisalrecord'),
      pageTitleValue: "",
      formData: [],

      errMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
    };
  },

  methods: {

    errorHandler($event) {
    this.isErr = $event.isErr;
    this.errMsg = $event.errMsg;
    },

    loadRatingScaleSetHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/appraisalrecord/" + vm.id})
        .then((res) => {

          vm.formData = res.data.rec[0];

         if (res.data.rec[0].isincrementpost == "N") {
                vm.formData.isincrementpost = false; 
          } else {
                vm.formData.isincrementpost = true;
          }

          if (res.data.rec[0].isbonuspost == "N") {
                vm.formData.isbonuspost = false; 
          } else {
                vm.formData.isbonuspost = true;
          }
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.userid = this.$store.getters.userId;
          
          vm.pageTitleValue = res.data.rec[0].appexercisecode;

          this.loadRatingScaleDetailHandler();

        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    
    loadRatingScaleDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/appraisalrecorddetail/" + this.id})
        .then((res) => {
          vm.formData.appraisalrecorddetail = res.data.rec;
         

          for (let i = 0; i < vm.formData.appraisalrecorddetail.length; i++) {
             
             if (res.data.rec[i].startdate) {
              vm.formData.appraisalrecorddetail[i].startdate = moment(res.data.rec[i].startdate).format(
              "YYYY-MM-DD");
            }
             if (res.data.rec[i].enddate) {
              vm.formData.appraisalrecorddetail[i].enddate = moment(res.data.rec[i].enddate).format(
              "YYYY-MM-DD");
            }

            Object.assign(vm.formData.appraisalrecorddetail[i], {
              action: 2,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/appraisalrecord/" + vm.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;

          } else {
            this.$router.push({ name: "appraisalrecordlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

      cancelHandler() {
      this.$router.push({ name: "appraisalrecordlist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

         this.$store.dispatch('delete', {url:
            "/appraisalrecord/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "appraisalrecordlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "appraisalrecordnew" });
    },
  },

  mounted() {
    this.loadRatingScaleSetHandler();
  },
};
</script>