<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>

import EADtlForm from "../../../components/payroll/eadtl/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": EADtlForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('eadtl.editeadtl'),
      pageTitleValue: "",
      formData: [],
      errMsg: "",
      confirmMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
      userid: this.$store.getters.userId,

    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      vm.formData[0].userid = this.$store.getters.userId;

      var payload = this.formData[0];

      this.$store.dispatch('put', {url: "/eadtl/" + vm.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "eadtllist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    cancelHandler() {
      this.$router.push({ name: "eadtllist" });
    },

    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/eadtl/" +
              this.id})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "eadtllist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "eadtlnew" });
    },

    loadEADtllHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/eadtl/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.pageTitleValue = res.data.rec[0].staffcode;
            vm.formData[0] = {};
            vm.formData[0].id = res.data.rec[0].id;
            vm.formData[0].year = res.data.rec[0].year;
            vm.formData[0].staffid = res.data.rec[0].staffid;
            vm.formData[0].stamp = res.data.rec[0].stamp;
            vm.formData[0].eaaddondetail = res.data.rec.map(e => {
              return {id:e.id, col:e.col, coldesc: e.coldesc, replacement: e.replacement, action: e.action}
            });
           vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadEADtllHandler();
  },
};
</script>