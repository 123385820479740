<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mt-n6 mb-n8">
            <v-text-field
              outlined
              :label="this.$t('workhourspayitemmappingsetup.mappingset')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.mapset"
              dense
              @input="updateValue"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mb-n8">
            <v-text-field
              outlined
              :label="this.$t('workhourspayitemmappingsetup.mappingdescription')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.mapsetdesc"
              dense
              @input="updateValue"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-n8">
            <app-table
              v-bind:parentObject="{
                tabHeader: this.tabHeader,
                detailItem: this.formData.dataItems,
                startdate: formData.startdate,
                enddate: formData.enddate,
              }"
              v-on:input="addItemHandler"
              v-on:delete="delItemHandler"
            />
          </v-col>
        </v-row>
      </v-form>

      <app-formbuttons
        v-bind:EditBool="this.parentObject.isEdit"
        v-on:NewPressed="newHandler"
        v-on:SavePressed="saveHandler"
        v-on:DeletePressed="deleteHandler"
        v-on:CancelPressed="cancelHandler"
      />
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
// import moment from "moment";
import CRUD from "./CRUD";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    "app-table": CRUD,
  },

  data() {
    return {
      showDate: new Date(),
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      tabHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "auto" },
        {
          text: this.$t('setuprestdaypatternsetup.daytype'),
          value: "daytypedesc",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('workhourspayitemmappingsetup.fromhours'),
          value: "fromhours",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('workhourspayitemmappingsetup.uptohours'),
          value: "uptohours",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('workhourspayitemmappingsetup.exclusive'),
          value: "exclusive",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('setuppayitem.payitem'),
          value: "payitemdesc",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('workhourspayitemmappingsetup.sourcehours'),
          value: "sourcehour",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('workhourspayitemmappingsetup.mappedqty'),
          value: "mappedqty",
          width: "auto",
          sortable: false,
        },
      ],
      dataItems: [],

      formData: {
        id: this.parentObject.data.mapset,
        mapset: this.parentObject.data.mapset,
        mapsetdesc: this.parentObject.data.mapsetdesc,
        dataItems: this.parentObject.data.dataItems,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },

      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        id: this.formData.id,
        mapset: this.formData.mapset,
        mapsetdesc: this.formData.mapsetdesc,
        dataItems: this.formData.dataItems,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },
    newHandler() {
      this.$emit("new");
    },
    saveHandler() {
      this.$emit("save");
    },
    deleteHandler() {
      this.$emit("delete");
    },
    cancelHandler() {
      this.$router.push({ name: "workhourspayitemmappinglist" , params: { search: this.search}});
    },
    addItemHandler($event) {
      this.formData.dataItems = $event;
      this.updateValue();
    },
    delItemHandler($event) {
      this.formData.dataItems = $event;
      this.updateValue();
    },
  },
  mounted() {},
};
</script>