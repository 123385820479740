<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('finizepayroll.payrollfinalizationlist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false},
                {text: this.$t('payrollprocessrun.payout'), value: 'payoutcode', width: '200px'},
                {text: this.$t('columns.description'), value: 'payoutdesc', width: '200px'},
                {text: this.$t('reportsparam.year'), value: 'pyyear', width: '200px'},
                {text: this.$t('reportsparam.month'), value: 'pymonth', width: '200px'},
                {text: this.$t('payrollprocessrun.payoutstatus'), value: 'pystatusdesc', width: '200px'},
                {text: this.$t('payrollprocessrun.payoutstartdate'), value: 'startdate', width: '200px'},
                {text: this.$t('payrollprocessrun.payoutenddate'), value: 'enddate', width: '200px'},
                {text: this.$t('setuppayrolltype.payrolltype'), value: 'payrolltypedesc', width: '200px'},
                {text: this.$t('payrollprocessrun.payrollgroup'), value: 'grouppdesc', width: '200px'},
                {text: this.$t('payrollprocessrun.exchangerate'), value: 'exchratedesc', width: '200px'},
                {text: this.$t('payrollprocessrun.payoutfrequency'), value: 'salfreqdesc', width: '200px'},
                {text: this.$t('payrollprocessrun.payeelist'), value: 'payeelistdesc', width: '200px'},
            ],
            payoutCtrlList: [],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadPayrollFinalization() {
            const vm = this;
            console.log('check')

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
            .then(res => {
                vm.payoutCtrlList = res.data.rec;
                for (let i = 0; i < vm.payoutCtrlList.length; i++) {
                    if (vm.payoutCtrlList[i].startdate) {
                        vm.payoutCtrlList[i].startdate = moment(vm.payoutCtrlList[i].startdate).format('YYYY-MM-DD');
                    }
                    if (vm.payoutCtrlList[i].enddate) {
                        vm.payoutCtrlList[i].enddate = moment(vm.payoutCtrlList[i].enddate).format('YYYY-MM-DD');
                    }
                    if (vm.payoutCtrlList[i].pystatuscode == 99) {
                        vm.dataItems.push(vm.payoutCtrlList[i])
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'payrollfinalizationedit', params: { id: item.id, search: item.search }});
        },

        newRecordHandler() {
            this.$router.push({name: 'payrollfinalizationnew'});
        }
    },

    mounted() {
        this.loadPayrollFinalization()
    }
}
</script>