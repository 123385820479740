<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
            v-on:error="errorHandler"
        />
        <div v-if="isErr">
            <!-- <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            /> -->
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>    
    </div>
</template>

<script>
import HRDFListForm from '../../../components/payroll/hrdfList/Form_Parameter';
import ErrorDialog from '../../../components/common/ErrorDialog';
//import moment from 'moment';


export default {
    components: {
        'app-form': HRDFListForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('hrdflisitngreport.hrdflisting'),
            pageTitleValue: '',
            
            formData: {
                // payoutcode: null,
                // pyyear: moment().year(),
                // pymonth: null,
                // compnyid: null,
                // total: 0,
            },
            

            dataItems:[],

            goBtn: false,

            yearList: [],
            monthList: [],
            payoutcodeList: [],
            compnycodeList: [],
         //   payitemcodeList: [],           

            errMsg: '',
            isErr:false,
            editMode: false 
        }
    },
        

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },
        
    }
}

</script>