<template>
    <div> 
        <v-container>
            <v-form>
                 <v-row>
                    <h2 class="pb-5"> {{this.parentObject.pageTitle}}<span class='page_title_value'>{{this.parentObject.pageTtileValue}}</span></h2>
                </v-row>

                <v-container 
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebate.rebatetype')"
                            type="text"
                            ref="rebatetypeid"
                            :items="taxableRebateList"
                            v-model="formData.rebatetypeid"
                            @input="updateValue"
                            dense
                            :rules="[rules.required]"
                            @change="
                                changeTaxableRebateHandler(formData.rebatetypeid)
                            "
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebate.rebatetype')"
                            type="text"
                            ref="rebatetypeid"
                            :items="taxableRebateList"
                            v-model="formData.rebatetypeid"
                            dense
                            :rules="[rules.required]"
                            readonly
                            background-color="#ffeab2"
                           
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5">
                        <v-dialog
                            v-model="taxableRebateDialog"
                            max-width="500px"
                            persistent
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform
                                    v-bind:parentData="{
                                        tabItems: taxableRebateCodes,
                                        formTitle: `${this.$t('setuptaxablerebate.rebatetype')}`,
                                        formId: 'taxablerebatesetup',
                                        taxableRebate: rebategroupitemsList
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveTaxableRebateSetup="
                                        saveTaxableRebateHandler
                                    "
                                    v-on:deleteTaxableRebateSetup="
                                        deleteTaxableRebateHandler($event)
                                    "
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="taxableRebateDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.description')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="formData.rebatetypedesc"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                        <v-text-field
                            background-color="#ffeab2"
                            outlined
                            readonly
                            :label="this.$t('setuptaxablerebategroup.rebategroup')"
                            type="text"
                            @change="changeRebateGroupHandler"
                            @input="updateValue"
                            v-model="formData.rebategroupdesc"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="rebateGroupCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            type="date"
                            ref="startdate"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            type="date"
                            ref="enddate"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setuptaxablerebategroup.rebatelimitamount')"
                            type="number"
                            ref="rebatelimitamount"
                            v-model="formData.rebatelimitamount"
                            @input="updateValue"
                            class="numberfield"
                            dense
                            :rules="[rules.required]"
                        > 
                        </v-text-field>
                    </v-col>

                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                    <v-autocomplete
                        outlined
                        :label="this.$t('columns.currency')"
                        type="text"
                        ref="crcyid"
                        :rules="[rules.required]"
                        v-model="formData.crcyid"
                        @input="updateValue"
                        dense
                        :items="currencyItemsList"
                        @change="changeCurrencyHandler(formData.crcyid)"
                    ></v-autocomplete>
                    </v-col>

                    <v-col cols="1" sm="1" class="pb-n5 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="currencyCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="1" sm="1" class="pb-n4 mt-n8">
                        <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: currencyItems,
                                        formTitle: `${this.$t('columns.currency')}`,
                                        formId: 'currency'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveCurrency="saveCurrencyHandler"
                                    v-on:deleteCurrency="deleteCurrencyHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="currencyDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                        <v-select
                            outlined
                            :label="this.$t('setuptaxablerebategroup.control')"
                            type="text"
                            v-model="formData.controlid"
                            ref="controlid"
                            dense
                            :items="controlitemsList"
                            @input="updateValue"
                            > 
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                      <v-textarea
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            ref="remark"
                            maxlength="200"
                            v-model="formData.remark"
                            @input="updateValue"
                            dense
                            counter> 
                        </v-textarea>
                    </v-col>
                </v-row>


                </v-container>
                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>


import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },
  
    data() {
        return {
            formData: {
                rebatetypeid: this.parentObject.data.rebatetypeid,
                rebatetypecode: this.parentObject.data.rebatetypecode,
                rebatetypedesc: this.parentObject.data.rebatetypedesc,
                rebategroupid: this.parentObject.data.rebategroupid,
                rebategroupcode: this.parentObject.data.rebategroupcode,
                rebategroupdesc: this.parentObject.data.rebategroupdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                crcyid: this.parentObject.data.crcyid,
                rebatelimitamount: this.parentObject.data.rebatelimitamount,
                controlid: this.parentObject.data.controlid,
                remark: this.parentObject.data.remark,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            rules:  {
               required:value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length <= 200 || 'Max 200 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },

            taxableRebateCodes: [],
            taxableRebateList: [],
            currencyItemsList: [],
            currencyDialog: false,
            taxableRebateDialog: false,
            currencyItems: [],
            rebategroups: [],
            rebategroupitemsList: [],
            controls: [],
            controlitemsList: [],
            currencyCode: '',
            rebateGroupCode: '',
            itemData: null,

            errMsg: "",
            isErr: false,
             search: this.parentObject.searchnew

        } //end of return 
            
    },  //end of data 

   

    methods: {
        saveTaxableRebateHandler() {
            const vm = this;

            let payload = {
                rebatetypecode: this.itemData.itemcode,
                rebatetypedesc: this.itemData.itemdesc,
                rebategroupid: this.itemData.typeid,
                rebategroupcode: this.itemData.typecode,
                rebategroupdesc: this.itemData.typedesc,
                userid: this.formData.userid,
                stamp: this.itemData.itemstamp
            };

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: `/taxablerebatesetup/code/${vm.itemData.itemid}`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.taxableRebateCodes = [];
                            vm.taxableRebateList = [];

                            this.loadTaxableRebateHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: `/taxablerebatesetup/code/create`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.taxableRebateCodes = [];
                            vm.taxableRebateList = [];

                            this.loadTaxableRebateHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        deleteTaxableRebateHandler($event) {
            const vm = this;

            if (confirm(`Remove ${$event.desc} ?`)) {
                this.$store.dispatch('delete', {url: `/taxablerebatesetup/code/${$event.id}/${vm.formData.userid}`})
                    .then(res => {
                        if (res.data.status == 200) {
                            // resets all arrays
                            vm.taxableRebateCodes = [];
                            vm.taxableRebateList = [];

                            this.loadTaxableRebateHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        updateValue() {
            this.$emit('input', {
                rebatetypeid: this.formData.rebatetypeid,
                rebatetypecode: this.formData.rebatetypecode,
                rebatetypedesc: this.formData.rebatetypedesc,
                rebategroupid: this.formData.rebategroupid,
                rebategroupcode: this.formData.rebategroupcode,
                rebategroupdesc: this.formData.rebategroupdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                crcyid: this.formData.crcyid,
                rebatelimitamount: this.formData.rebatelimitamount,
                controlid: this.formData.controlid,
                remark: this.formData.remark,  
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        //load handlers 
        loadRebateGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/taxablerebategroup/all"})
                .then(res => {
                    vm.rebategroups = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.rebategroupitemsList.push({
                            text: res.data.rec[i].rebategroupdesc,
                            value: res.data.rec[i].id,
                            code: res.data.rec[i].rebategroupcode
                        });
                    }

                    if (vm.formData.rebategroupid) {
                        vm.changeRebateGroupHandler(vm.formData.rebategroupid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadTaxableRebateHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/taxablerebatesetup/codes`})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxableRebateCodes.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].rebatetypecode,
                            desc: res.data.rec[i].rebatetypedesc,
                            typeid: res.data.rec[i].rebategroupid,
                            typedesc: res.data.rec[i].rebategroupdesc,
                            stamp: res.data.rec[i].stamp
                        });

                        vm.taxableRebateList.push({
                            text: res.data.rec[i].rebatetypecode,
                            value: res.data.rec[i].id,
                            desc: res.data.rec[i].rebatetypedesc,
                            typeid: res.data.rec[i].rebategroupid,
                            typecode: res.data.rec[i].rebategroupcode,
                            typedesc: res.data.rec[i].rebategroupdesc
                        });
                    }

                    if (vm.formData.rebatetypeid) {
                        this.changeTaxableRebateHandler(vm.formData.rebatetypeid);
                    }
                });
        },

        changeTaxableRebateHandler(id) {
            let item = this.taxableRebateList.find(
                item => item.value === parseInt(id)
            );

            this.formData.rebatetypeid = item.value;
            this.formData.rebatetypecode = item.text;
            this.formData.rebatetypedesc = item.desc;
            this.formData.rebategroupid = item.typeid;
            this.formData.rebategroupcode = item.typecode;
            this.formData.rebategroupdesc = item.typedesc;
            this.rebateGroupCode = item.typecode;
        },

        changeRebateGroupHandler(id) {
            let item = this.rebategroups.find(item => item.id === id);

            this.rebateGroupCode = item.rebategroupcode;
        },
        
        loadControlListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/control/all'})
                .then(res => {
                    vm.controls = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.controlitemsList.push(
                            {
                                text: res.data.rec[i].controldesc,
                                value: res.data.rec[i].id
                                
                            }
                        )

                    }         

                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyItemsList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);
            this.currencyCode = item.crcycode;
        },
        
        newHandler() {
            this.$emit('new');
        },
        saveHandler() {
            this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'taxablerebatesetuplist', params: { search: this.search}});
        },

        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/currency/' + this.itemData.itemid, payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/currency/create', payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete currency is if the user wnats to delete from the database
        deleteCurrencyHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/currency/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },
    }, // end of methods 

    mounted() {
        this.loadCurrencyListHandler();
        this.loadRebateGroupListHandler();
        this.loadControlListHandler();
        this.loadTaxableRebateHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
} 
</script>

<style>

.numberfield input[type='number'] {
    -moz-appearance:textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}


</style>

