<template>
    <div>
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container class="mb-10">
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5 pl-7">{{ this.parentObject.pageTitle }}<span class="page_title_value pl-2">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-row>
                    <div class="pt-2 pl-7 pb-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 70px;"
                            @click="backHandler" 
                            > 
                            Back
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 ml-n1 mb-10"
                >

                    <div>
                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5">
                                <v-text-field
                                    outlined
                                    label="Exercise code"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>


                            <v-col cols="8" sm="8" class="pb-n5">
                                <v-text-field
                                    outlined
                                    label="Exercise description"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    ref="asofdate1"
                                    type="date"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>


                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    ref="asofdate1"
                                    type="date"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-autocomplete
                                    outlined
                                    label="Status"
                                    type="text"
                                    :items="kpisettingstatus"
                                    hide-selected
                                    v-model="formData.insproviderid"
                                    @input="updateValue"
                                    @change="changeInsuranceProviderHandler(formData.insproviderid)"
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mb-10">
                        <v-row>
                            <app-table
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.ratingscalesetdetail,
                                    isWizard: this.parentObject.isWizard
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-row>
                    </div>
                </v-container>

                <div class="mt-n4">
                    <app-card-individual
                        class="mx-4"
                        v-bind:parentObject = "{
                            tabHeader: this.tabHeader,
                            detailItem: this.ratingscalesetdetail,
                            isWizard: this.parentObject.isWizard
                        }"
                    
                        v-on:input="addItemHandler"
                    />
                </div>
                <div class="mt-10">
                    <app-card-common
                        class="mx-4"
                        v-bind:parentObject = "{
                            tabHeader: this.tabHeader,
                            detailItem: this.ratingscalesetdetail,
                            isWizard: this.parentObject.isWizard
                        }"

                        v-on:input="addItemHandler"
                    />
                </div>
            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import CRUDTable from './CRUDTableStaffExercise';
import KPICardIndividual from "./KPICardIndividual";
import KPICardCommon from "./KPICardCommon";

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-table': CRUDTable,
        "app-card-individual": KPICardIndividual,
        "app-card-common": KPICardCommon,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                asofdate1: null,
                asofdate2: null,
                compnyid: null,
                compnycode: null,
                test: 35,

            },

            companyname: [],
            companyList: [],
            errMsg: "",
            isErr: false,

            kpisettingstatus: [],

            ratingscalesetdetail: [
                {ratingcode: 1},
            ],

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: 'Staff Code', value: 'ratingcode', width: '200px', sortable: true},
                { text: 'Name', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer Name', value: 'label', width: '200px', sortable: true},
                { text: 'Status', value: 'label', width: '200px', sortable: true},
                { text: 'Objective Reviewer', value: 'label', width: '200px', sortable: true},
                { text: 'Reviewer Staff Code', value: 'label', width: '200px', sortable: true},
                { text: 'Company', value: 'label', width: '200px', sortable: true},
                { text: 'Branch', value: 'label', width: '200px', sortable: true},
                { text: 'Department', value: 'label', width: '200px', sortable: true},
                { text: 'Section', value: 'label', width: '200px', sortable: true},
                { text: 'Unit', value: 'label', width: '200px', sortable: true},
                { text: 'Grade', value: 'label', width: '200px', sortable: true},
                { text: 'Position', value: 'label', width: '200px', sortable: true},
            ],
        }
    },

    methods: {

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
            let item = this.companyname.find((item) => item.id === id);
            this.formData.compnyid = item.id;
            this.formData.compnycode = item.compnycode;
        },

        backHandler(){
            this.$router.push({name: 'kpisettingstaff', params: { id: 1 } });
        },

        updateValue() {
            this.$emit("input", {
                 asofdate1: this.formData.asofdate1,
                 asofdate2: this.formData.asofdate2,
                 compnyid: this.formData.compnyid,
                 compnycode: this.formData.compnycode,
                
            });
        },
    }, 

    mounted() { 
      this.loadCompanyListHandler();
     
    },
    
}
</script>
