<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:new="newHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import GroupPaymentForm from "../../../components/payroll/grouppayment/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": GroupPaymentForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupgrouppayment.editgrouppayment'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadGroupPaymentHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/grouppayment/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          vm.formData.startdate = moment(vm.formData.startdate).format(
            "YYYY-MM-DD"
          );

          vm.formData.enddate = moment(vm.formData.enddate).format(
            "YYYY-MM-DD"
          );

          vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.formData.userid = this.$store.getters.userId;
          this.loadGroupPaymentDetailHandler(vm.formData.id);

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadGroupPaymentDetailHandler(id) {
      const vm = this;

      this.$store.dispatch('get', {url: "/grouppaymentdetails/" + id})
        .then((res) => {
          vm.formData.grouppaymentdetail = res.data.rec;

          for (let i = 0; i < vm.formData.grouppaymentdetail.length; i++) {
            Object.assign(vm.formData.grouppaymentdetail[i], { action: 2 });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/grouppayment/" + vm.formData.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grouppaymentlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "grouppaymentlist" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/grouppayment/" +
            vm.formData.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grouppaymentlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    newHandler() {
      this.$router.push({ name: "grouppaymentnew" });
    },
  },

  mounted() {
    this.loadGroupPaymentHandler();
  },
};
</script>