<template>
    <div>
        <v-container>
            <form enctype="multipart/form-data">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="10" sm="10" class="mt-n4 pt-6">
                    
                            <v-row v-if="!this.formData.lockstaff">
                                <v-col cols="10" sm="10" class="pb-n4">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.staffname')"
                                        type="text"
                                        :rules="[rules.required]"
                                        hide-selected
                                        v-model="formData.psnid"
                                        @input="updateValue"
                                        :items="personList"
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <v-col cols="10" sm="10" class="pb-n4">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.staffname')"
                                        type="text"
                                        :rules="[rules.required]"
                                        hide-selected
                                        v-model="formData.psnid"
                                        readonly
                                        :items="personList"
                                        dense
                                        background-color="#ffeab2"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recfamdetails.membername')"
                                        id="membername"
                                        type="text"
                                        ref="membername"
                                        v-model="formData.membername"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recfamdetails.membertype')"
                                        type="text"
                                        v-model="formData.membertypeid"
                                        @input="updateValue"
                                        :items="membertypeList"
                                        dense
                                        @change="changeMemberHandler(formData.membertypeid)"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.dob')"
                                        id="dob"
                                        type="date"
                                        ref="dob"
                                        v-model="formData.dob"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        readonly
                                        :label="this.$t('recpersonalinfo.age_year')"
                                        type="number"
                                        ref="agey"
                                        background-color="#ffeab2"
                                        v-model="ageYear"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 ml-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        readonly
                                        :label="this.$t('recpersonalinfo.age_month')"
                                        id="agem"
                                        type="number"
                                        ref="agem"
                                        background-color="#ffeab2"
                                        v-model="ageMonth"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.salutation')"
                                        type="text"
                                        ref="salutid"
                                        :items="salutationList"
                                        hide-selected
                                        v-model="formData.salutid"
                                        @input="updateValue"
                                        @change="changeSalutationHandler(formData.salutid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="salutationDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: salutationItems,
                                                    formTitle: `${this.$t('recpersonalinfo.salutation')}`,
                                                    formId: 'salutation'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveSalutation="saveSalutationHandler"
                                                v-on:deleteSalutation="deleteSalutationHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="salutationDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">                                    
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.gender')"
                                        type="text"
                                        ref="genderid"
                                        :items="genderList"
                                        hide-selected
                                        v-model="formData.genderid"
                                        @input="updateValue"
                                        @change="changeGenderHandler(formData.genderid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="genderDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: genderItems,
                                                    formTitle: `${this.$t('columns.gender')}`,
                                                    formId: 'gender'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveGender="saveGenderHandler"
                                                v-on:deleteGender="deleteGenderHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="genderDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.maritalstatus')"
                                        type="text"
                                        ref="marstatid"
                                        :items="marStatList"
                                        hide-selected
                                        v-model="formData.marstatid"
                                        @input="updateValue"
                                        @change="changeMarStatHandler(formData.marstatid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="marStatDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: marStatItems,
                                                    formTitle: `${this.$t('recpersonalinfo.maritalstatus')}`,
                                                    formId: 'marital'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveMarital="saveMarStatHandler"
                                                v-on:deleteMarital="deleteMarStatHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="marStatDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recfamdetails.icno')"
                                        type="text"
                                        ref="icno"
                                        v-model="formData.icno"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recfamdetails.refno')"
                                        type="number"
                                        class="removeSpin"
                                        ref="refno"
                                        v-model="formData.refno"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                                    <v-checkbox
                                        ref="isSpouse"
                                        v-model="formData.isSpouse"
                                        :label="this.$t('recfamdetails.nominatedspouse')"
                                        @change="updateValue"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                                    <v-checkbox
                                        ref="isHandicap"
                                        v-model="formData.isHandicap"
                                        :label="this.$t('recfamdetails.handicap')"
                                        @change="updateValue"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                                    <v-checkbox
                                        ref="isWorking"
                                        v-model="formData.isWorking"
                                        :label="this.$t('recfamdetails.working')"
                                        @change="updateValue"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                v-if= formData.isWorking
                                :label="this.$t('recfamdetails.employername')"
                                type="text"
                                ref="employername"
                                v-model="formData.employername"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                         </v-row>

                         <v-row>
                                <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                v-if= formData.isWorking
                                :label="this.$t('recfamdetails.companyaddress')"
                                type="text"
                                ref="compnyaddr"
                                v-model="formData.compnyaddr"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                         </v-row>

                         <v-row>
                                <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                v-if= formData.isWorking
                                :label="this.$t('recfamdetails.employercontactinfo')"
                                type="text"
                                ref="empcontactinfo"
                                v-model="formData.empcontactinfo"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                         </v-row>

                         <v-row>
                                <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                                    <v-checkbox
                                        ref="isStudying"
                                        v-model="formData.isStudying"
                                        :label="this.$t('recfamdetails.studying')"
                                        @change="updateValue"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        v-if="formData.isStudying"
                                        :label="this.$t('recfamdetails.educationlevel')"
                                        type="text"
                                        ref="edulevelid"
                                        :items="edulevelList"
                                        hide-selected
                                        v-model="formData.edulevelid"
                                        @input="updateValue"
                                        @change="changeEdulevelHandler(formData.edulevelid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                                
                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-if="formData.isStudying" v-model="edulevelDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: edulevelItems,
                                                    formTitle: `${this.$t('recfamdetails.educationlevel')}`,
                                                    formId: 'edulevel'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveEdulevel="saveEdulevelHandler"
                                                v-on:deleteEdulevel="deleteEdulevelHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="edulevelDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        v-if="formData.isStudying"
                                        :label="this.$t('recfamdetails.institutionname')"
                                        type="text"
                                        ref="instid"
                                        :items="instList"
                                        hide-selected
                                        v-model="formData.instid"
                                        @input="updateValue"
                                        @change="changeInstHandler(formData.instid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-if="formData.isStudying" v-model="instDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: instItems,
                                                    formTitle: `${this.$t('recfamdetails.institutionname')}`,
                                                    formId: 'institution'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveInst="saveInstHandler"
                                                v-on:deleteInst="deleteInstHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="instDialog = false"
                                                >
                                                {{$t('button.name')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                                    <v-checkbox
                                        v-if="formData.isStudying"
                                        ref="isOversea"
                                        v-model="formData.isOversea"
                                        :label="this.$t('recfamdetails.oversea')"
                                        @change="updateValue"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                v-if="formData.isStudying"
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                v-if="formData.isStudying"
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                            <v-row>
                            <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.remark')"
                                outlined
                                v-model="formData.remark"
                                @change="updateValue"
                            ></v-text-field>
                        </v-col>
                        </v-row>

                            <v-row>
                                <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                                    <!-- <v-file-input 

                                    
                                        label="Attachment" 
                                        v-model="formData.attach"
                                        @change="updateValue"
                                        ref="file"
                                        :accept=this.$store.getters.attachmentAcceptType
                                        show-size
                                        outlined 
                                        style="font-size: 12px"
                                        dense>
                                    </v-file-input> -->

                                     <v-file-input 
                                        ref="attach"
                                        :label="this.$t('claimrecordlisting.browseforattachment')"
                                        outlined
                                        v-model="fileHolder"
                                        @change="fileToBase64"
                                        dense
                                        >
                                    </v-file-input>
                                </v-col>
                        </v-row>

            <div v-if="this.formData.attach">
            <v-row>
              <v-col cols="4" sm="6" class="pb-8 mt-n5">
                <p>
                  <a
                    v-bind:href="this.formData.attach"
                    v-bind:download="this.formData.attach"
                    >{{$t('recleaverec.downloadattach')}}</a
                  >
                  <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteAttachmentHandler"
                  >
                    mdi-delete
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </div>

                        </v-col>
                </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool ="this.isEdit"

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                psnid: this.parentObject.data.psnid,
                //pic: this.parentObject.data.pic,
                membername: this.parentObject.data.membername,
                membertypeid: this.parentObject.data.membertypeid,
                dob: this.parentObject.data.dob,
                salutid: this.parentObject.data.salutid,
                genderid: this.parentObject.data.genderid,
                marstatid: this.parentObject.data.marstatid,
                icno: this.parentObject.data.icno,
                refno: this.parentObject.data.refno,
                isSpouse: this.parentObject.data.isSpouse,
                isHandicap: this.parentObject.data.isHandicap,
                isWorking: this.parentObject.data.isWorking,
                employername: this.parentObject.data.employername,
                compnyaddr: this.parentObject.data.compnyaddr,
                empcontactinfo: this.parentObject.data.empcontactinfo,
                isStudying: this.parentObject.data.isStudying,
                edulevelid: this.parentObject.data.edulevelid,
                instid: this.parentObject.data.instid,
                isOversea: this.parentObject.data.isOversea,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                attach: this.parentObject.data.attach,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            fileHolder: null,
            salutationList: [],
            genderList: [],
            marStatList: [],
            edulevelList: [],
            instList: [],
            personList: [],
            compnyList: [],
            membertypeList: [],
            psnidList: [],

            salutations: [],
            genders: [],
            marStats: [],
            edulevels: [],
            insts: [],
            person: [],
            membertypes: [],

            salutationItems: [],
            genderItems: [],
            marStatItems: [],
            edulevelItems: [],
            instItems: [],

            salutationDialog: false,
            genderDialog: false,
            marStatDialog: false,
            edulevelDialog: false,
            instDialog: false,
            isEdit: this.parentObject.isEdit,

            salutationCode: '',
            genderCode: '',
            marStatCode: '',
            edulevelCode: '',
            instCode: '',

            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    computed: {
        ageYear: {
            get: function() {
                var years = 0;

                if (this.formData.dob) {
                    years =   moment().diff(this.formData.dob, 'years');
                    if (!years) {
                        years = 0;
                    }
                }
                
                return years;
            }
        },

        ageMonth: {
            get: function() {
                var months = 0;

                if (this.formData.dob) {
                    var totalMonths = moment().diff(this.formData.dob, 'months'); 
                    months = totalMonths % 12;
                    if (!months) {
                        months = 0;
                    }
                }
                
                return months;
            }
        }
    },

    methods: {

        // imgToBase64() {
        //     var file = this.$refs.profileimg.internalValue;
        //     var reader = new FileReader();

        //     reader.onloadend = () => {
        //         //var b64 = reader.result.replace(/^data:.+base64,/, '');
        //         var b64 = reader.result;
        //         this.formData.pic = b64;

        //         this.$emit('profile', b64);
        //     }
        //     reader.readAsDataURL(file); 
        // },

        // fileToBase64() {
        //     var file = this.$refs.file.internalValue;
        //     var reader = new FileReader();

        //     reader.onloadend = () => {
        //         //var b64 = reader.result.replace(/^data:.+base64,/, '');
        //         var b64 = reader.result;
        //         this.formData.attach = b64;
        //     }

        //     reader.readAsDataURL(file);
        // },

        fileToBase64() {
            if (this.fileHolder) {
                var file = this.$refs.attach.internalValue;
                this.formData.attach = this.fileHolder.name;

                var reader = new FileReader();

                reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.attach = b64;
                this.updateValue();
                };

                reader.readAsDataURL(file);
            }
        },

         deleteAttachmentHandler() {
            this.formData.attach = "";
            this.formData.attach = "";
        },

        loadPersonListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.person = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.personList.push(
                            {
                                text: res.data.rec[i].psnname,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getMarStatListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/maritalstat/all'})
                .then(res => {
                    vm.marStats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.marStatList.push(
                            {
                                text: res.data.rec[i].marstatname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.marStatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].marstatcode,
                                desc: res.data.rec[i].marstatname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.marstatid) {
                        vm.changeMarStatHandler(vm.formData.marstatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getSalutListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/salutation/all'})
                .then(res => {
                    vm.salutations = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.salutationList.push(
                            {
                                text: res.data.rec[i].salutname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.salutationItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].salutcode,
                                desc: res.data.rec[i].salutname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.salutid) {
                        vm.changeSalutationHandler(vm.formData.salutid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getGenderListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/gender/all'})
                .then(res => {
                    vm.genders = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.genderList.push(
                            {
                                text: res.data.rec[i].gendername,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.genderItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].gendercode,
                                desc: res.data.rec[i].gendername,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.genderid) {
                        vm.changeGenderHandler(vm.formData.genderid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getEdulevelListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/edulevel/all'})
                .then(res => {
                    vm.edulevels = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.edulevelList.push(
                            {
                                text: res.data.rec[i].eduleveldesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.edulevelItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].edulevelcode,
                                desc: res.data.rec[i].eduleveldesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.edulevelid) {
                        vm.changeEdulevelHandler(vm.formData.edulevelid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getInstListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/institution/all'})
                .then(res => {
                    vm.insts = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.instList.push(
                            {
                                text: res.data.rec[i].instdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.instItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].instcode,
                                desc: res.data.rec[i].instdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.instid) {
                        vm.changeInstHandler(vm.formData.instid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getMemberHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/membertype/all'})
                .then(res => {
                    vm.insts = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.membertypeList.push(
                            {
                                text: res.data.rec[i].memberdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    
                    if (vm.formData.membertypeid) {
                        vm.changeMemberHandler(vm.formData.membertypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },


        changeMemberHandler(id) {
            this.membertypes.find(item => item.id === id);
        },

        changeSalutationHandler(id) {
            let item = this.salutations.find(item => item.id == id);

            this.salutationCode = item.salutcode;
        },

        changeGenderHandler(id) {
            let item = this.genders.find(item => item.id == id);

            this.genderCode = item.gendercode;
        },

        changeEdulevelHandler(id) {
            let item = this.edulevels.find(item => item.id == id);

            this.edulevelCode = item.edulevelcode;
        },

        changeInstHandler(id) {
            let item = this.insts.find(item => item.id == id);

            this.instCode = item.instcode;
        },

        changeMarStatHandler(id) {
            let item = this.marStats.find(item => item.id == id);

            this.marStatCode = item.marstatcode;
        },

        saveSalutationHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/salutation/' + this.itemData.itemid, payload: {
                    salutcode: vm.itemData.itemcode,
                    salutname: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/salutation/create', payload: {
                    salutcode: vm.itemData.itemcode,
                    salutname: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveMarStatHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/maritalstat/' + this.itemData.itemid, payload: {
                    marstatcode: vm.itemData.itemcode,
                    marstatname: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/maritalstat/create', payload: {
                    marstatcode: vm.itemData.itemcode,
                    marstatname: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteMarStatHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/maritalstat/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteSalutationHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/salutation/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveGenderHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/gender/' + this.itemData.itemid, payload: {
                    gendercode: vm.itemData.itemcode,
                    gendername: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/gender/create', payload: {
                    gendercode: vm.itemData.itemcode,
                    gendername: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteGenderHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/gender/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveEdulevelHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/edulevel/' + this.itemData.itemid, payload: {
                    edulevelcode: vm.itemData.itemcode,
                    eduleveldesc: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.edulevels = [];
                        vm.edulevelList = [],
                        vm.edulevelItems = [],

                        this.getEdulevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/edulevel/create', payload: {
                    edulevelcode: vm.itemData.itemcode,
                    eduleveldesc: vm.itemData.itemdesc,
                     userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.edulevels = [];
                        vm.edulevelList = [],
                        vm.edulevelItems = [],

                        this.getEdulevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteEdulevelHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/edulevel/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.edulevels = [];
                        vm.edulevelList = [],
                        vm.edulevelItems = [],

                        this.getEdulevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveInstHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/institution/' + this.itemData.itemid, payload: {
                    instcode: vm.itemData.itemcode,
                    instdesc: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.insts = [];
                        vm.instList = [],
                        vm.instItems = [],

                        this.getInstListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/institution/create', payload: {
                    instdesc: vm.itemData.itemdesc
                    ,instcode: vm.itemData.itemcode
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.insts = [],
                        vm.instList = [],
                        vm.instItems = [],

                        this.getInstListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteInstHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/institution/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.insts = [];
                        vm.instList = [],
                        vm.instItems = [],

                        this.getInstListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        updateValue() {
            this.$emit('input', {                
                psnid: this.formData.psnid,
                //pic: this.formData.pic,
                membername: this.formData.membername,
                membertypeid: this.formData.membertypeid,
                dob: this.formData.dob,
                salutid: this.formData.salutid,
                genderid: this.formData.genderid,
                marstatid: this.formData.marstatid,
                icno: this.formData.icno,
                refno: this.formData.refno,
                isSpouse: this.formData.isSpouse,
                isHandicap: this.formData.isHandicap,
                isWorking: this.formData.isWorking,
                employername: this.formData.employername,
                compnyaddr: this.formData.compnyaddr,
                empcontactinfo: this.formData.empcontactinfo,
                isStudying: this.formData.isStudying,
                edulevelid: this.formData.edulevelid,
                instid: this.formData.instid,
                isOversea: this.formData.isOversea,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                attach: this.formData.attach,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'familylist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getSalutListHandler();
        this.loadPersonListHandler();
        this.getGenderListHandler();
        this.getEdulevelListHandler();
        this.getInstListHandler();
        this.getMarStatListHandler();
        this.getMemberHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    },
}
</script>

<style>

.removeSpin input[type='number'] {
    -moz-appearance:textfield;
    -moz-appearance:autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>