<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col>
                    <v-toolbar flat>
                        <v-toolbar-title>{{$t('payrolldashboardreport.payrollsummaryfor')}} <span class="blue--text"> {{data.formData.pyyear}}/{{data.formData.pymonth}} </span></v-toolbar-title>
                    </v-toolbar>
                    <v-card>
                        <div class="ma-4">
                            <v-row>
                                <v-col class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="dateTitle">
                                            {{$t('reportsparam.year')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="dateContent">
                                                {{data.formData.pyyear}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="dateTitle">
                                            {{$t('reportsparam.month')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="dateContent">
                                                {{data.formData.pymonth}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="payoutTitle">
                                            {{$t('reportsparam.payout')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="payoutContent">
                                                {{payoutname}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="3" class="pb-n5 mr-n3">
                                    <v-card>
                                        <v-card-title class="paidTitle">
                                            {{$t('payrolldashboardreport.totalpaid')}}
                                        </v-card-title>
                                        <v-card-text>
                                            <div class="paidContent">
                                               {{payoutDetail.totalpaid}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n5 mr-n3">
                                    <v-card>
                                        <v-card-title class="paidTitle">
                                            {{$t('payrolldashboardreport.paxprocessed')}}
                                        </v-card-title>
                                        <v-card-text >
                                            <div class="payContent">
                                                {{totalStaff}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n5 ">
                                    <v-card>
                                        <!-- <v-card-title class="narTitle">
                                            N | A | R
                                        </v-card-title> -->
                                        <v-card-title class="narTitle">
                                            <v-row>
                                                <v-col class="mr-n2 ml-2">N</v-col>
                                                <v-col class="ml-n2 mr-n2">|</v-col>
                                                <v-col class="ml-n2 mr-n2">A</v-col>
                                                <v-col class="ml-n2 mr-n2">|</v-col>
                                                <v-col class="ml-n2">R</v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <div class="payContent">
                                                <!-- 1  <span class="textBlue">10</span>  11 -->
                                                <v-row>
                                                    <v-col class="mr-n2 ml-2 mt-n3">{{newJoiner}}</v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3"></v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3 textBlue">{{availableStaff}}</v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3"></v-col>
                                                    <v-col class="ml-n2 mt-n3">{{resignStaff}}</v-col>
                                                </v-row>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row class="pt-10">
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('previewpayroll.grosspay')"
                                        type="text"
                                        ref="grossPay"
                                        hide-selected
                                        v-model="dashboardItems.grossPay"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('previewpayroll.netpay')"
                                        type="text"
                                        ref="netPay"
                                        hide-selected
                                        v-model="dashboardItems.netPay"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.companyfringe')"
                                        type="text"
                                        ref="companyFringe"
                                        hide-selected
                                        v-model="dashboardItems.companyFringe"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.basic')"
                                        type="text"
                                        ref="basic"
                                        hide-selected
                                        v-model="dashboardItems.basic"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.allowance')"
                                        type="text"
                                        ref="allowance"
                                        hide-selected
                                        v-model="dashboardItems.allowance"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.reimbursement')"
                                        type="text"
                                        ref="reimbursement"
                                        hide-selected
                                        v-model="dashboardItems.reimbursement"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.overtime')"
                                        type="text"
                                        ref="overtime"
                                        hide-selected
                                        v-model="dashboardItems.overtime"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.bonus')"
                                        type="text"
                                        ref="bonus"
                                        hide-selected
                                        v-model="dashboardItems.bonus"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.generaldeductions')"
                                        type="text"
                                        ref="generalDeductions"
                                        hide-selected
                                        v-model="dashboardItems.generalDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.staffstatutorydeductions')"
                                        type="text"
                                        ref="staffStatutoryDeductions"
                                        hide-selected
                                        v-model="dashboardItems.staffStatutoryDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.companystatutorydeductions')"
                                        type="text"
                                        ref="companyStatutoryDeductions"
                                        hide-selected
                                        v-model="dashboardItems.companyStatutoryDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </div>
                        
                    </v-card>
                    </v-col>
                </v-row>

                <v-row class="mt-n2 mb-n4 pl-4">
                    <v-col>
                        {{$t('payrolldashboardreport.view')}} <span @click="goToDetail" class="blue--text"> {{$t('payrolldashboardreport.details')}}</span>
                    </v-col>
                </v-row>

                <v-row class="mt-n6 mb-n6"></v-row>

                <div v-for="det in detailItems" :key="det.id">
                <v-row>
                    <v-col col="12" sm="12" class="pb4-4 mt-8">
                        <v-data-table
                            :headers="tabHeader"
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>{{$t('payrolldashboardreport.payrollsummaryfor')}}  <span class="blue--text"> {{data.formData.pyyear}}/{{data.formData.pymonth}}  </span> 
                                <div class="subtitle-2 blue--text">{{det[0].compnydesc}}</div>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>

                                <v-avatar class="mr-5" size="40" tile>
                                <img
                                    :src="`${publicPath}images/export_pdf.png`"
                                    @click="printPDF(det)">
                                </v-avatar>

                                <v-avatar size="40" tile>
                                <img
                                    :src="`${publicPath}images/export_csv.png`"
                                    @click="getCSV(det)">
                                </v-avatar>
                                
                            </v-toolbar>
                        </template>
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in det.slice(0,-1)" :key="item.id">
                                    <td >
                                        {{item.no}}
                                    </td>
                                    <td >
                                        {{item.payItemType}}
                                    </td>
                                    <td >
                                        {{item.payItem}}
                                    </td>
                                    <td >
                                        {{item.amount}}
                                    </td>
                                    <td >
                                        {{item.subTotal}}
                                    </td>
                                </tr>
                                <!-- <tr class="total">
                                    <td></td>
                                    <td></td>
                                    <td class="text-right">Total</td>
                                    <td>{{det[det.length-1].totalamt}}</td>
                                    <td>{{det[det.length-1].totalsubTotal}}</td>
                                </tr> -->
                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                </div>
                
                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: 'Payroll Dashboard Detail',
            tableTitle: this.$route.params.formData.pyyear + '_' + this.$route.params.formData.pymonth,

            data: {
                formData: {
                    pyyear: this.$route.params.formData.pyyear,
                    pymonth: this.$route.params.formData.pymonth,
                    poid: this.$route.params.formData.poid,
                },

                parameters: {
                    brchid: this.$route.params.param.brchid,
                    compnyid: this.$route.params.param.compnyid,
                    deptid: this.$route.params.param.deptid,
                    emptypeid: this.$route.params.param.emptypeid,
                    gradeid: this.$route.params.param.gradeid,
                    staffid: this.$route.params.param.staffid,
                },
            },
            
            dashboardItemsHolder: {
                grossPay: null,
                netPay: null,
                companyFringe: null,
                basic: null,
                allowance: null,
                reimbursement: null,
                overtime: null,
                bonus: null,
                generalDeductions: null,
                staffStatutoryDeductions: null,
                companyStatutoryDeductions: null,
            },

            payoutDetail: {
                totalpaid: null,
            },

            dashboardItems: {
                grossPay: null,
                netPay: null,
                companyFringe: null,
                basic: null,
                allowance: null,
                reimbursement: null,
                overtime: null,
                bonus: null,
                generalDeductions: null,
                staffStatutoryDeductions: null,
                companyStatutoryDeductions: null,
            },

            payoutname: null,
            totalStaff: null,
            availableStaff: null,
            newJoiner: null,
            resignStaff: null,

            options: {
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('columns.company')
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('setuppersonpayrolldirectassignment.amount')
                    },
                },
                title: {
                    text: this.$t('payrolldashboardreport.totalpaidoutbycompany')
                },
            },
            series: [{
                data: []
            }],

            tpbc: [],
            tpbch: [],
            
            tableOpt: {
                itemsPerPage: -1
            },
            title: null,
            detailItems: [],
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            tabHeader: [
                { text: this.$t('columns.no'), value: 'no', sortable: false, width: '5px' },
                { text: this.$t('setuppayitmetype.payitemtype'), value: 'payItemType', width: '50px', sortable: false},
                { text: this.$t('setuppayitem.payitem'), value: 'payItem', width: '200px', sortable: false },
                { text: this.$t('setuppersonpayrolldirectassignment.amount'), value: 'amount', sortable: false, width: '5px' },
                { text: this.$t('payrolldashboardreport.subtotal'), value: 'subTotal', width: '50px', sortable: false},
            ],
            
            amountTotal: null,
            subTotal: null,

            errMsg: '',
            isErr: false,
        }
    },

    methods: {

        totalPaid() {
            setTimeout(() => {
                const vm = this;

                let totalpaidholder = [
                    vm.dashboardItemsHolder.netPay,
                    vm.dashboardItemsHolder.companyFringe,
                    vm.dashboardItemsHolder.staffStatutoryDeductions,
                    vm.dashboardItemsHolder.companyStatutoryDeductions,
                ]

                let totalpaidsum = totalpaidholder.reduce(function(a, b) {
                    return a + b;
                }, 0);

                vm.payoutDetail.totalpaid = 'RM ' + totalpaidsum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }, 1000);
        },

        loadPayoutControl() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/" + vm.data.formData.poid})
            .then((res) => {
                
                let payout = res.data.rec[0];
                vm.payoutname = payout.payoutdesc
                vm.data.formData.pyyear = payout.pyyear
                vm.data.formData.pymonth = payout.pymonth
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        loadNewJoiner() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/newjoiner/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let newJoinerHolder = res.data.rec;
                vm.newJoiner = newJoinerHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadResign() {
            const vm = this;
            
            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/resign/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let resignHolder = res.data.rec;
                vm.resignStaff = resignHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
            
        },

        loadTotalStaff() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/totalstaff/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let totalStaffHolder = res.data.rec;
                vm.totalStaff = totalStaffHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        
        },

        loadAvailableStaff() {
            const vm = this;
            vm.availableStaff = vm.totalStaff - vm.newJoiner - vm.resignStaff
        },

        loadDelay() {
            setTimeout(() => {
                this.loadNewJoiner();
                this.loadResign();
            }, 300);
            setTimeout(() => {
                this.totalPaid();
                this.loadAvailableStaff();
            }, 800);
        },

        loadGrossPay() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/grosspay/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let grossPay = res.data.rec;
                    let grossPayHolder = []

                    grossPay.forEach(element => {
                        grossPayHolder.push(parseFloat(element.grosspayamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.grossPay = grossPayHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.grossPay = 'RM ' + vm.dashboardItemsHolder.grossPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                } else {
                    vm.dashboardItemsHolder.grossPay = 0
                    vm.dashboardItems.grossPay =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadNetPay() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/netpay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/netpay/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let netPay = res.data.rec;
                    let netPayHolder = []

                    netPay.forEach(element => {
                        netPayHolder.push(parseFloat(element.netpayamount.replace(/,/g, '')))
                        vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.netPay = netPayHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.netPay = 'RM ' + vm.dashboardItemsHolder.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                } else {
                    vm.dashboardItemsHolder.netPay = 0
                    vm.dashboardItems.netPay =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadCompanyFringe() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/companyfringe ', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/companyfringe/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let companyFringe = res.data.rec;
                    let companyFringeHolder = []

                    companyFringe.forEach(element => {
                        companyFringeHolder.push(parseFloat(element.companyfringe.replace(/,/g, '')))
                        vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.companyFringe = companyFringeHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.companyFringe = 'RM ' + vm.dashboardItemsHolder.companyFringe.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.companyFringe = 0
                    vm.dashboardItems.companyFringe =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadBasic() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/basic', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/basic/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let basic = res.data.rec;
                    let basicHolder = []

                    basic.forEach(element => {
                        basicHolder.push(parseFloat(element.basicamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.basic = basicHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.basic = 'RM ' + vm.dashboardItemsHolder.basic.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.basic = 0
                    vm.dashboardItems.basic =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadAllowance() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/allowance', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/allowance/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let allowance = res.data.rec;
                    let allowanceHolder = []

                    allowance.forEach(element => {
                        allowanceHolder.push(parseFloat(element.allowanceamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.allowance = allowanceHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.allowance = 'RM ' + vm.dashboardItemsHolder.allowance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.allowance = 0
                    vm.dashboardItems.allowance =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadReimbursement() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/reimbursement', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/reimbursement/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let reimbursement = res.data.rec;
                    let reimbursementHolder = []

                    reimbursement.forEach(element => {
                        reimbursementHolder.push(parseFloat(element.reimbursementamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.reimbursement = reimbursementHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.reimbursement = vm.dashboardItemsHolder.reimbursement.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.reimbursement = 0
                    vm.dashboardItems.reimbursement =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadOvertime() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/overtime', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/overtime/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let overtime = res.data.rec;
                    let overtimeHolder = []

                    overtime.forEach(element => {
                        overtimeHolder.push(parseFloat(element.overtimeamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.overtime = overtimeHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.overtime = 'RM ' + vm.dashboardItemsHolder.overtime.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.overtime = 0
                    vm.dashboardItems.overtime =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadBonus() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/bonus', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/bonus/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let bonus = res.data.rec;
                    let bonusHolder = []

                    bonus.forEach(element => {
                        bonusHolder.push(parseFloat(element.bonusamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.bonus = bonusHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.bonus = 'RM ' + vm.dashboardItemsHolder.bonus.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.bonus = 0
                    vm.dashboardItems.bonus =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadGeneralDeduction() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/generaldeduction', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/generaldeduction/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let generalDeductions = res.data.rec;
                    let generalDeductionsHolder = []

                    generalDeductions.forEach(element => {
                        generalDeductionsHolder.push(parseFloat(element.generaldeduction.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.generalDeductions = generalDeductionsHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.generalDeductions = 'RM ' + vm.dashboardItemsHolder.generalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.generalDeductions = 0
                    vm.dashboardItems.generalDeductions =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadStatutoryDeductionStaff() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/statutorydeductionstaff', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/statutorydeductionstaff/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let statutoryDeductionsStaff = res.data.rec;
                    let statutoryDeductionsStaffHolder = []

                    statutoryDeductionsStaff.forEach(element => {
                        statutoryDeductionsStaffHolder.push(parseFloat(element.statutorydeduction.replace(/,/g, '')))
                        vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.staffStatutoryDeductions = statutoryDeductionsStaffHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.staffStatutoryDeductions = 'RM ' + vm.dashboardItemsHolder.staffStatutoryDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.staffStatutoryDeductions = 0
                    vm.dashboardItems.staffStatutoryDeductions =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadStatutoryDeductionCompany() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/statutorydeductioncompany', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/statutorydeductioncompany/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let statutoryDeductionsCompany = res.data.rec;
                    let statutoryDeductionsCompanyHolder = []

                    statutoryDeductionsCompany.forEach(element => {
                        statutoryDeductionsCompanyHolder.push(parseFloat(element.statutorydeduction.replace(/,/g, '')))
                        vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.companyStatutoryDeductions = statutoryDeductionsCompanyHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.companyStatutoryDeductions = 'RM ' + vm.dashboardItemsHolder.companyStatutoryDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.companyStatutoryDeductions = 0
                    vm.dashboardItems.companyStatutoryDeductions =  'RM 0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadCompanyS() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/summaryearning', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/summaryearning/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let dataItemsCompanyS = res.data.rec;
                
                //let dataItemsCompanyHolder = res.data.rec;
                let getamount = []
                let getheader = []

                dataItemsCompanyS.forEach(function (o) {
                    var existing = getamount.filter(function (i) {
                        return i.payitemtypecode === o.payitemtypecode && i.compnydesc === o.compnydesc;
                    })[0];
                    if (!existing || existing == undefined) {
                        o.subTotal = parseFloat(o.amount.replace(/,/g, ''))
                        getamount.push(o);
                    } else {
                        o.payitemtypedesc = ''
                        existing.subTotal += parseFloat(o.amount.replace(/,/g, ''));
                    }
                    if (getheader.indexOf(o.compnydesc) === -1 ) getheader.push(o.compnydesc)
                });

                for (let i = 0; i < getheader.length; i++) {
                    let detholder = []
                    let totalamtholder = []
                    let totalsubTotalholder = []
                    let count = 1
                    for (let j = 0; j < dataItemsCompanyS.length; j++) {
                        if (dataItemsCompanyS[j].compnydesc == getheader[i]) {
                            if (dataItemsCompanyS[j].subTotal) {
                                dataItemsCompanyS[j].subTotal = dataItemsCompanyS[j].subTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                totalsubTotalholder.push(parseFloat(dataItemsCompanyS[j].subTotal.replace(/,/g, '')))
                            }
                            detholder.push({
                                no: count++,
                                compnydesc: dataItemsCompanyS[j].compnydesc,
                                payItemType: dataItemsCompanyS[j].payitemtypedesc,
                                payItem: dataItemsCompanyS[j].payitemdesc,
                                amount: dataItemsCompanyS[j].amount,
                                subTotal: dataItemsCompanyS[j].subTotal
                            })
                            totalamtholder.push(parseFloat(dataItemsCompanyS[j].amount.replace(/,/g, '')))
                            
                        }
                    }
                    let totalamt = totalamtholder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    let totalsubTotal = totalsubTotalholder.reduce(function(a, b) {
                        return a + b;
                    }, 0);
                    
                    let totalholder = {
                        totalamt: totalamt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        totalsubTotal: totalsubTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    
                    detholder.push(totalholder)
                    
                    vm.detailItems.push(detholder)
                }
            })
            .catch(function (err) {
                vm.errMsg = err;
            });
        },

        backHandler() {
            let formData = this.data.formData
            let param = this.data.parameters

            this.$router.push({name: 'payrolldashboardsummary', params: { formData, param }});
        },

        goToDetail() {
            let formData = this.data.formData
            let param = this.data.parameters

            this.$router.push({name: 'payrolldashboarddetail2', params: { formData, param }});
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.$t('payrolldashboardreport.payrollsummaryfor'), + this.tableTitle + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV(item) {
            let itemBasic = item
            let data = []

            for (var i = 0; i < itemBasic.length - 1; i++) {
                let subTotalholder = ''
                if (itemBasic[i].subTotal) subTotalholder = item[i].subTotal
                data.push({
                    [`${this.$t('columns.no')}`]: itemBasic[i].no,
                    [`${this.$t('setuppayitmetype.payitemtype')}`]: itemBasic[i].payItemType,
                    [`${this.$t('setuppayitem.payitem')}`]: itemBasic[i].payItem,
                     [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemBasic[i].amount,
                     [`${this.$t('payrolldashboardreport.subtotal')}`]: subTotalholder,
                })
            }

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('setuppayitmetype.payitemtype')}`]: '',
                [`${this.$t('setuppayitem.payitem')}`]: 'Total',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemBasic[itemBasic.length-1].totalamt,
                 [`${this.$t('payrolldashboardreport.subtotal')}`]: itemBasic[itemBasic.length-1].totalsubTotal,
            })

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        printPDF(item) {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let itemBasic = item

            var head = [                
                { title: this.$t('columns.no'), dataKey: 'no' },
                { title: this.$t('setuppayitmetype.payitemtype'), dataKey: 'payItemType' },
                { title: this.$t('setuppayitem.payitem'), dataKey: 'payItem' },
                { title: this.$t('setuppersonpayrolldirectassignment.amount'), dataKey: 'amount' },
                { title: this.$t('payrolldashboardreport.subtotal'), dataKey: 'subTotalholder' },
            ]

            let body = []

            for (var i = 0; i < itemBasic.length - 1; i++) {
                let subTotalholder = ''
                if (itemBasic[i].subTotal) subTotalholder = item[i].subTotal
                body.push({
                    no: itemBasic[i].no,
                    payItemType: itemBasic[i].payItemType,
                    payItem: itemBasic[i].payItem,
                    amount: itemBasic[i].amount,
                    subTotalholder: subTotalholder,
                })
            }
            body.push({
                no: '',
                payItemType: '',
                payItem: 'Total',
                amount: itemBasic[itemBasic.length-1].totalamt,
                subTotalholder: itemBasic[itemBasic.length-1].totalsubTotal,
            })

            doc.text(this.$t('payrolldashboardreport.payrollsummaryfor') + this.tableTitle, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.$t('payrolldashboardreport.payrollsummaryfor') + this.tableTitle + '.pdf')
        },
    },

    mounted() {
        this.loadPayoutControl();
        this.loadGrossPay();
        this.loadNetPay();
        this.loadBasic();
        this.loadCompanyFringe();
        this.loadAllowance();
        this.loadReimbursement();
        this.loadOvertime();
        this.loadBonus();
        this.loadStatutoryDeductionStaff();
        this.loadStatutoryDeductionCompany();
        this.loadGeneralDeduction();
        this.loadCompanyS();
        this.loadTotalStaff();
        this.loadDelay();
    }
    
}
</script>

<style scoped>
.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}
.dateContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    margin: 0px;
    min-height: 60px;
}
.payoutTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: grey;
    align-content: center;
    justify-content: center;
}
.payoutContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}
.narTitle {
    align-content: center;
    justify-content: center;
    font-size: 12px;
    max-height: 30px;
}
.paidTitle {
    align-content: center;
    justify-content: left;
    font-size: 12px;
    max-height: 30px;
}
.paidContent {
    font-weight: 700;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    margin: 0px;
    min-height: 60px;
}
.payContent {
    font-weight: 700;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #980000;
    margin: 0px;
    min-height: 60px;
}
.textBlue {
    color:  #004080;
}
.text1 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#004080;
}
.text2 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#653565;
}
</style>
