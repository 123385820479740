<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                 <v-row>
                    <v-col cols="10" sm="10"  class="mt-n4">

                    <v-row v-if="!this.isEdit && !this.formData.lockstaff">
                        
                        <v-col cols="6" sm="6" class="pb-n5 pt-6">
                            <v-autocomplete
                                outlined
                               :label="this.$t('setuppayrolltype.payrolltype')"
                                type="text"
                                :items="payrollTypeList"
                                :rules="[rules.required]"
                                v-model="formData.payrolltypeid"
                                @input="updateValue"
                                @change="
                                    changePayrollTypeHandler(
                                        formData.payrolltypeid
                                    )
                                "
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5 pt-6">
                            <v-dialog
                                v-model="payrollTypeDialog"
                                max-width="600px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform
                                        v-bind:parentData="{
                                            tabItems: payrollTypeList,
                                            formTitle: `${this.$t('setuppayrolltype.payrolltype')}`,
                                            formId: 'payrolltype',
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:savePayrollType="
                                            savePayrollTypeHandler
                                        "
                                        v-on:deletePayrollType="
                                            deletePayrollTypeHandler($event)
                                        "
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="payrollTypeDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row v-else>

                        <v-col cols="6" sm="6" class="pb-n5 pt-6">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayrolltype.payrolltype')"
                                type="text"
                                :items="payrollTypeList"
                                :rules="[rules.required]"
                                v-model="formData.payrolltypeid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>
                       

                        <v-col cols="2" sm="2" class="pb-n5 pt-6">
                            <v-dialog
                                v-model="payrollTypeDialog"
                                max-width="600px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform
                                        v-bind:parentData="{
                                            tabItems: payrollTypeList,
                                            formTitle: `${this.$t('setuppayrolltype.payrolltype')}`,
                                            formId: 'payrolltype',
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:savePayrollType="
                                            savePayrollTypeHandler
                                        "
                                        v-on:deletePayrollType="
                                            deletePayrollTypeHandler($event)
                                        "
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="payrollTypeDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.payrolltypedesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!--v-row>
                        <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Exchange Rate"
                                type="text"
                                :rules="[rules.required]"
                                :items="exchrateList"
                                hide-selected
                                v-model="formData.exchrateid"
                                @change="
                                    changeExchRateHandler(formData.exchrateid)
                                "
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mb-n8 mt-n8">
                            <v-text-field
                                dense
                                label="Code"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="exchrateCode"
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.payrolltypedetail,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                        </v-col>
                 </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from "../../generic/GridForm";
import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object,
    },

    components: {
        "app-genericform": GenericForm,
        "app-table": CRUDTable,
    },

    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
            },

            formData: {
                payrolltypeid: this.parentObject.data.payrolltypeid,
                payrolltypecode: this.parentObject.data.payrolltypecode,
                payrolltypedesc: this.parentObject.data.payrolltypedesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                // exchrateid: this.parentObject.data.exchrateid,
               // lockpayroll: this.parentObject.data.lockpayroll,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                payrolltypedetail: this.parentObject.data.payrolltypedetail,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            // exchrates: [],
            // exchrateList: [],
            // exchrateCode: "",

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false,
                },
                {
                    text: this.$t('setupprocesslistmaster.processlist'),
                    value: "proclistmascode",
                    width: "250px",
                    sortable: true,
                },
                {
                    text: this.$t('setuppayrolltype.listtypedescription'),
                    value: "proclistmasdesc",
                    width: "400px",
                    sortable: true,
                },
                {
                    text: this.$t('setupprocesslistmaster.precedence'),
                    value: "precd",
                    width: "100px",
                    sortable: true,
                },
            ],

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0,
            },

            payrollTypeDialog: false,
            payrollTypeList: [],

            errMsg: "",
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        };
    },

    methods: {
        loadPayrollType() {
            const vm = this;
            this.$store.dispatch('get', {url: "/payrolltype/codes"})
                .then((res) => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payrollTypeList.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].payrolltypecode,
                            desc: res.data.rec[i].payrolltypedesc,
                            text: res.data.rec[i].payrolltypedesc,
                            value: res.data.rec[i].id,
                            stamp: res.data.rec[i].stamp,
                        });
                    }

                    if (vm.payrolltypeid) {
                        this.changePayrollTypeHandler(
                            vm.formData.payrolltypeid
                        );
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayrollTypeHandler(id) {
            let item = this.payrollTypeList.find(
                (item) => item.value === parseInt(id)
            );

            this.formData.payrolltypedesc = item.text;
            this.formData.payrolltypecode = item.code;
            this.formData.payrolltypeid = item.id;
        },

        savePayrollTypeHandler() {
            const vm = this;

            let payload = {
                payrolltypecode: this.itemData.itemcode,
                payrolltypedesc: this.itemData.itemdesc,
                userid: this.formData.userid,
            };

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: `/payrolltypecode/${vm.itemData.itemid}`,
                        payload: payload})
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.payrollTypeList = [];

                            vm.loadPayrollType();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: `/payrolltypecode/create`,
                        payload: payload})
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.payrollTypeList = [];

                            vm.loadPayrollType();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        deletePayrollTypeHandler($event) {
            const vm = this;

            if (confirm(`Remove ${$event.desc} ?`)) {
                this.$store.dispatch('delete', {url: `/payrolltypecode/${$event.id}/${vm.formData.userid}`})
                    .then((res) => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.payrollTypeList = [];

                            vm.loadPayrollType();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        // loadExchRateHandler() {
        //     const vm = this;

        //     axios
        //         .get(this.$store.getters.apiURL + "/exchrate/all")
        //         .then((res) => {
        //             vm.exchrates = res.data.rec;

        //             for (let i = 0; i < res.data.rec.length; i++) {
        //                 vm.exchrateList.push({
        //                     text: res.data.rec[i].exchratedesc,
        //                     value: res.data.rec[i].id,
        //                 });
        //             }

        //             if (vm.formData.exchrateid) {
        //                 vm.changeExchRateHandler(vm.formData.exchrateid);
        //             }
        //         })
        //         .catch((err) => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        //         });
        // },

        // changeExchRateHandler(id) {
        //     let item = this.exchrates.find((item) => item.id === id);

        //     this.exchrateCode = item.exchratecode;
        //     this.updateValue();
        // },

        addItemHandler($event) {
            this.formData.payrolltypedetail = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit("input", {
                payrolltypeid: this.formData.payrolltypeid,
                payrolltypecode: this.formData.payrolltypecode,
                payrolltypedesc: this.formData.payrolltypedesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                lockstaff: this.formData.lockstaff,
                lockpayroll: this.formData.lockpayroll,
                // exchrateid: this.formData.exchrateid,
                remark: this.formData.remark,
                payrolltypedetail: this.formData.payrolltypedetail,
               
                stamp: this.formData.stamp,
                userid: this.formData.userid,
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "paytypelist", params: { search: this.search} });
        },
    },

    mounted() {
        this.loadPayrollType();
       // this.loadExchRateHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    },
};
</script>