<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value"> {{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('latenessdeduction.latenessparamcode')"
                                type="text"
                                ref="latenessparamcode"
                                v-model="formData.latenessparamcode"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('latenessdeduction.latenessparamdesc')"
                                type="text"
                                ref="latenessparamdesc"
                                v-model="formData.latenessparamdesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n6">
                            <v-text-field
                            outlined
                            :label="this.$t('latenessdeduction.fromdate')"
                            type="date"
                            ref="fromdate"
                            :rules="[rules.required]"
                            v-model="formData.fromdate"
                            dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n6">
                            <v-text-field
                            outlined
                            :label="this.$t('latenessdeduction.todate')"
                            type="date"
                            ref="todate"
                            :rules="[rules.required]"
                            v-model="formData.todate"
                            dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('latenessdeduction.payitem')"
                                type="text"
                                ref="payitemid"
                                v-model="formData.dedtpayitemid"
                                dense
                                clearable
                                :items="payItemsList"
                                @input="updateValue"
                                @change="changePayItem(formData.dedtpayitemid)"
                                ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.payitemcode')"
                                type="text"
                                ref="payitemcode"
                                v-model="formData.payitemcode"
                                dense
                                readonly
                                clearable
                                ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.currencyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.currencyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>


                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('latenessdeduction.frequencyfactor')"
                                type="text"
                                :items="payoutFreqList"
                                :rules="[rules.required]"
                                v-model="formData.frequencyid"
                                @input="updateValue"
                                @change="changePayoutFreqHandler"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="payoutFreqCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('claimlimitadjustmentrecord.remarkoptional')"
                                    type="text"
                                    ref="remark"
                                    v-model="formData.remark"
                                    @input="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>
                    </v-row>
                    <v-row>
                        <v-col col="12" sm="12" class="pb4-4">
                            <app-table 
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.formData.policydetail
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {
                id: this.parentObject.data.id,
                policydetail: this.parentObject.data.policydetail,

                latenessparamcode: this.parentObject.data.latenessparamcode,
                latenessparamdesc: this.parentObject.data.latenessparamdesc,
                fromdate: this.parentObject.data.fromdate,
                todate: this.parentObject.data.todate,
                dedtpayitemid: this.parentObject.data.dedtpayitemid,
                currencyid: this.parentObject.data.currencyid,
                frequencyid: this.parentObject.data.frequencyid,
                remark: this.parentObject.data.remark,

                payitemcode: this.parentObject.data.payitemcode,
                payitemdesc: this.parentObject.data.payitemdesc,
                currencycode: this.parentObject.data.currencycode,
                currencydesc: this.parentObject.data.currencydesc,
                frequencycode: this.parentObject.data.frequencycode,
                frequencydesc: this.parentObject.data.frequencydesc,
                frequencyfactor: this.parentObject.data.frequencyfactor,

                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                // { text: this.$t('columns.byamount'), value: 'byamount', width: '100px', sortable: false},
                // { text: this.$t('columns.byfactor'), value: 'byfactor', width: '100px', sortable: false},
                { text: this.$t('latenessdeduction.dedtfactor'), value: 'dedtfactor', width: '100px', sortable: false},
                // { text: this.$t('columns.roundid'), value: 'roundid', width: '100px', sortable: false},
                // { text: this.$t('columns.roundto'), value: 'roundto', width: '100px', sortable: false},
                { text: this.$t('trainingrecord.amount'), value: 'amount', width: '100px', sortable: false},
                { text: this.$t('latenessdeduction.uptominutes'), value: 'uptominutes', width: '100px', sortable: false},
                 
                { text: this.$t('columns.company'), value: 'compnyname', width: '200px', sortable: false },
                { text: this.$t('columns.branch'), value: 'brchname', width: '200px', sortable: false },
                { text: this.$t('columns.department'), value: 'deptname', width: '200px', sortable: false },
                { text: this.$t('columns.division'), value: 'divnname', width: '200px', sortable: false },
                { text: this.$t('columns.section'), value: 'sectioname', width: '200px', sortable: false },
                { text: this.$t('columns.unit'), value: 'unitname', width: '200px', sortable: false },
                { text: this.$t('columns.employmenttype'), value: 'emptypedesc', width: '200px', sortable: false },
                { text: this.$t('columns.grade'), value: 'grddesc', width: '200px', sortable: false },
                { text: this.$t('columns.job'), value: 'jobtitle', width: '200px', sortable: false },
                // { text: this.$t('columns.position'), value: 'posdesc', width: '200px', sortable: false },

                { text: this.$t('latenessdeduction.uptosalary'), value: 'uptosalary', width: '100px', sortable: false},
    
            ],

            payItems: [],
            payItemsList: [],
            payitemdesc: '',

            currencyCode: '',
            currencyList: [],
            currencyItems: [],

            payoutFreqCode: "",
            payoutFreq: [],
            payoutFreqList: [],

            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {
        addItemHandler($event) {
            this.formData.policydetail =$event
        },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.currencyid) {
                        vm.changeCurrencyHandler(vm.formData.currencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        loadPayoutFreq() {
            const vm = this;

            this.$store.dispatch('get', {url: "/salfreq/all"})
                .then((res) => {
                    vm.payoutFreq = res.data.rec;

                    vm.payoutFreq.forEach((element) => {
                        vm.payoutFreqList.push({
                            text: element.basicfreqdesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.frequencyid)
                        vm.changePayoutFreqHandler(vm.formData.frequencyid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayoutFreqHandler($event) {
            let item = this.payoutFreq.find((item) => item.id == $event);

            this.payoutFreqCode = item.basicfreqcode;

            this.updateValue();
        },

        changePayItem() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payitem/all'})
            .then(res => {

                vm.payItems = res.data.rec;

                for (let i = 0; i < vm.payItems.length; i++) {
                    vm.payItemsList.push({
                        text: vm.payItems[i].payitemdesc,
                        value: vm.payItems[i].id,
                    });
                }
                if (this.formData.dedtpayitemid) {
                this.changePayItemHandler(this.formData.dedtpayitemid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changePayItemHandler(id) {
            var item = this.payItems.find(item => item.id === id);
            this.payitemcode = item.payitemcode;
            this.payitemdesc = item.payitemdesc;
        },

        updateValue() {

        this.$emit('input', {

                id: this.formData.id,
                policydetail: this.formData.policydetail,

                latenessparamcode: this.formData.latenessparamcode,
                latenessparamdesc: this.formData.latenessparamdesc,
                fromdate: this.formData.fromdate,
                todate: this.formData.todate,
                dedtpayitemid: this.formData.dedtpayitemid,
                currencyid: this.formData.currencyid,
                frequencyid: this.formData.frequencyid,
                remark: this.formData.remark,

                payitemcode: this.formData.payitemcode,
                payitemdesc: this.formData.payitemdesc,
                currencycode: this.formData.currencycode,
                currencydesc: this.formData.currencydesc,
                frequencycode: this.formData.frequencycode,
                frequencydesc: this.formData.frequencydesc,
                frequencyfactor: this.formData.frequencyfactor,

                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'latenessdeductionpolicylist', params: { search: this.search}});
        },

    },

    mounted() {

        this.changePayItem();
        // this.LoadClaimCategoryType();
        // this.LoadClaimLimitCategoryType();
        this.loadCurrencyListHandler();
        this.loadPayoutFreq();
        // this.loadExchangeRate();
    }



}
</script>