<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <!-- <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2">Export Process Log</v-col>
          </v-row>-->

        <v-container>
          <v-row>
            <v-col cols="6" sm="6" class="orange lighten-5">
              <v-row>
                <v-col cols="3" sm="3">
                  <v-text-field
                    :label="this.$t('processclockdatarecord.uploaded')"
                    type="text"
                    v-model="listErr.uploaded"
                    class="custom"
                    readonly
                    dense
                    background-color="#FFF3E0"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3">
                  <v-text-field
                    :label="this.$t('processclockdatarecord.recordprocessed')"
                    type="text"
                    v-model="listErr.processed"
                    class="custom"
                    readonly
                    dense
                    background-color="#FFF3E0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    :label="this.$t('processclockdatarecord.staffcount')"
                    type="text"
                    v-model="listErr.staffcount"
                    class="custom"
                    readonly
                    dense
                    background-color="#FFF3E0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <!-- </v-container> -->
        <v-row>
          <div class="pt-5 pl-4" @click="ViewProceesed" style="cursor: pointer">
            {{$t('processclockdatarecord.viewprocessedattendancedata')}}
          </div>
        </v-row>
        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="returnHandler"
              >{{$t('button.back')}}</v-btn
            >
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      pageTitle: this.$t('processclockdatalog'),
      listErr: {
        uploaded: this.$route.params.uploaded,
        processed: this.$route.params.processed,
        staffcount: this.$route.params.staffcount,
      },
      errMsg: "",
      isErr: false,
    };
  },

  methods: {
    ViewProceesed() {
      this.$router.push({ name: "processedattendancedata" });
    },
    returnHandler() {
      this.$router.push({ name: "processclockdata" });
    },
  },
  mounted() {},
};
</script>