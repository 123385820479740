<template>
    <div id="`bankaccountlist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>


<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupcompanybankaccount.companybankaccountlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false,width:'80px'},
                { text: this.$t('setupcompanybankaccount.account'), value: 'accountcode', width: '150px'},
                { text: this.$t('columns.description'), value: 'accountdesc', width: '300px' },
                { text: this.$t('setupcompanybankaccount.bank'), value: 'compnybankname', width: '300px' },
                { text: this.$t('setupcompanybankaccount.accountno'), value: 'accountno', width: '150px' },
                { text: this.$t('setupreport_exportmapping.exportformat'), value: 'expdesc', width: '200px' },
                { text: this.$t('setupcompanybankaccount.officer'), value: 'officer', width: '200px' },
                { text: this.$t('columns.telephone'), value: 'tel', width: '150px' },
                { text: this.$t('columns.email'), value: 'email', width: '150px' },
                { text: this.$t('columns.addressline1'), value: 'addr1', width: '200px' },
                { text: this.$t('columns.addressline2'), value: 'addr2', width: '200px' },
                { text: this.$t('columns.addressline3'), value: 'addr3', width: '200px' },
                { text: this.$t('columns.city'), value: 'cityname', width: '200px' },
                { text: this.$t('columns.country'), value: 'cntryname', width: '200px' },
                { text: this.$t('columns.currency'), value: 'crcydesc', width: '200px' },
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        
        getBankaccountListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/bankaccount/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'bankaccountedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'bankaccountnew'});
        }
    },

    mounted() {
        this.getBankaccountListHandler();
    }
}
</script>