<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData[0],
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        editMode: this.editMode,
      }"
      v-on:input="formData[0] = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import QualificationForm from "../../../components/personnel/qualification/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": QualificationForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('qualificationlist.editqualificationrecords'),
      pageTitleValue: "",
      //formData: [],
       formData: {
        psnid: null,
        levelid: null,
        fieldid: null,
        majorid: null,
        institutionid: null,
        startdate: null,
        enddate: null,
        isOversea: null,
        resultid: null,
        cgpa: null,
        attach: [],
        attachname: [],
        documents: [],
        docid: null,
        docdid: [],
        remark: null,
        stamp: 0,
        userid: this.$store.getters.userId,
       },
      document: [],
      documentdetail: [],

      
      
      loading: true,
      confirmMsg: "",
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadQualificationHandler() {
      const vm = this;
    
      this.$store.dispatch('get', {url: "/quarecord/" + vm.id})

        .then((res) => {
          //if (res.status == 200) {
            vm.formData = res.data.rec;

            
            vm.pageTitleValue = vm.formData[0].psnname;

            if (vm.formData[0].startdate) {
              vm.formData[0].startdate = moment(
                vm.formData[0].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.formData[0].enddate) {
              vm.formData[0].enddate = moment(
                vm.formData[0].enddate).format(
                "YYYY-MM-DD"
              );
            }

            if (vm.formData[0].isOversea == "Y") {
              vm.formData[0].isOversea = 1;
              vm.formData[0].isOverseadesc = "Y";
            } else {
              vm.formData[0].isOversea = 0;
              vm.formData[0].isOverseasdesc = "N";
            }

            vm.formData[0].attach = [];
            vm.formData[0].attachname = [];
            vm.formData[0].documents = [];
            vm.formData[0].docdid = [];
            vm.formData[0].docid = res.data.rec[0].id;

            // vm.formData.isOversea = res.data.rec[0].isOversea;
            // vm.formData.id = res.data.rec[0].id;
            // vm.formData.psnid = res.data.rec[0].psnid;
            // vm.formData.levelid = res.data.rec[0].levelid;
            // vm.formData.fieldid = res.data.rec[0].fieldid;
            // vm.formData.majorid = res.data.rec[0].majorid;
            // vm.formData.institutionid = res.data.rec[0].institutionid;
            // vm.formData.resultid = res.data.rec[0].resultid;
            // vm.formData.cgpa = res.data.rec[0].cgpa;
            // vm.formData.remark = res.data.rec[0].remark;
            // vm.formData.lockstaff = res.data.rec[0].lockstaff;
          //  console.log('lockstaff',vm.formData.lockstaff)
            // vm.pageTitleValue = res.data.rec[0].psnid;
            // vm.formData.stamp = res.data.rec[0].stamp;
            vm.formData[0].userid = this.$store.getters.userId;
            vm.loading = !vm.loading;
          // } else {
          //   vm.isErr = !vm.isErr;
          // }

          // console.log('attach', vm.formData.attach)

          vm.loadDocumentHandler();

          
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadDocumentHandler() {
      const vm = this;
      // console.log('abc')

      this.$store.dispatch('get', {url: "/quadocument/" + this.id})
      .then((res) => {

        vm.document = res.data.rec;
        // console.log('document',vm.document)

        vm.formData[0].docid = res.data.rec[0].id;
        // console.log('docid',vm.formData[0].docid)
        vm.loadDocumentDHandler()
      })
      .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    loadDocumentDHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/quadocumentdetail/" + this.id})
        .then((res) => {
          
        //  console.log('new', vm.formData.newdocuments)
          vm.documentdetail = res.data.rec;
        //  console.log('documentdetail', vm.documentdetail)
         
          for (let i = 0; i < vm.documentdetail.length; i++) {
            vm.formData[0].documents.push(vm.documentdetail[i])
            vm.formData[0].docdid.push(vm.documentdetail[i].id)
          //  Object.assign(vm.formData.attach = vm.documentdetail[i].attach);
          }

        //  console.log('documents', vm.formData[0].documents)
        //  console.log('vm.formData.docdid', vm.formData[0].docdid)
          
         // vm.formData.attach = res.data.rec.attach;    
          
         // console.log('vm.attach', vm.formData.attach)
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      var payload = this.formData[0];

      this.$store.dispatch('put', {url: "/quarecord/" + this.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "qualificationlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/quarecord/" +
              this.id +
              "/" +
              this.formData[0].userid +
              "/" +
              this.formData[0].docid})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "qualificationlist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "qualificationnew" });
    },
  },

  mounted() {
    this.loadQualificationHandler();
  },
};
</script>