var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-form',{attrs:{"parentObject":{
      data: this.formData,
      pageTitle: this.pageTitle,
      pageTitleValue: this.pageTitleValue,
      errMsg: this.errMsg,
      isEdit: this.editMode,
    }},on:{"input":function($event){_vm.formData = $event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }