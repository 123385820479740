<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import DepartmentForm from "../../../components/settings/department/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": DepartmentForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupdepartment.newdepartment'),
      formData: {
        deptcode: null,
        deptname: null,
        // startdate: null,
        // enddate: null,
        remark: null,
        isdisable: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      // Convert boolean to integer. 1 = true, 0 = false
      if (this.formData.isdisable == false) {
        this.formData.isdisable = 0;
      } else {
        this.formData.isdisable = 1;
      }

      this.$store.dispatch('post', {url: "/department/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "departmentlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "departmentlist" });
    },
  },
};
</script>