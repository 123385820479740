<template>
    <div>
        <v-container>
            <v-form ref = "form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.payout')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="formData.payoutcode"
                                    dense
                                    :items="codeList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="formData.compnyname"
                                    dense
                                    :items="compnyList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>


              

                  <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

     components: {
        'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                

                compnyname: null,
                payoutcode: null,


            },

              parameters: {
                staffid: null,
                gradeid: null,
                employmenttypeid: null,
                branchid: null,
                departmentid: null
            },

            showFilter: {
                staffid: true,
                gradeid: true,
                employmenttypeid: true,
                branchid: true,
                departmentid: true
            },

            compnyList: [],
            codeList: []
        }
    },

    methods: {


        loadPayoutCode(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payoutctrl/all'})
                .then(res => {
                    vm.payoutcodes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.codeList.push(
                            {
                                text: res.data.rec[i].payoutcode,
                                value: res.data.rec[i].payoutcode
                            }
                        )                        
                    }

                    if (vm.formData.payoutcode) {
                        vm.changePayoutcodeHandler(vm.formData.payoutcode);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePayoutcodeHandler(id) {
            this.payoutcodes.find(item => item.id === id);
            
        },

        loadCompanies(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/company/all'})
                .then(res => {
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.compnyList.push(
                            {
                                text: res.data.rec[i].compnyname,
                                value: res.data.rec[i].compnyname
                            }
                        )                        
                    }

                    if (vm.formData.compnyname) {
                        vm.changeCompanyHandler(vm.formData.compnyname);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCompanyHandler(id) {
            this.companies.find(item => item.id === id);
            
        },

        // loadCurrencyListHandler() {
        //     const vm = this;

        //     axios.get(this.$store.getters.apiURL + '/currency/all')
        //         .then(res => {
        //             vm.currencies = res.data.rec;

        //             for (let i = 0; i < res.data.rec.length; i++) {
        //                 vm.currencyList.push(
        //                     {
        //                         text: res.data.rec[i].crcydesc,
        //                         value: res.data.rec[i].id
        //                     }
        //                 )

        //             }

        //             if (vm.formData.crcyid) {
        //                 vm.changeCurrencyHandler(vm.formData.crcyid);
        //             }
        //         })
        //         .catch(err => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //         });
        // },

        // changeCurrencyHandler(id) {
        //     this.currencies.find(item => item.id === id);

        // },

        // loadExchangeSet() {
        //     const vm = this;

        //     axios.get(this.$store.getters.apiURL + '/exchrate/all')
        //         .then(res => {
        //             vm.exchanges = res.data.rec;

        //             for (let i = 0; i < res.data.rec.length; i++) {
        //                 vm.exchangeList.push(
        //                     {
        //                         text: res.data.rec[i].exchratedesc,
        //                         value: res.data.rec[i].id
        //                     }
        //                 )

        //             }

        //             if (vm.formData.exchrateid) {
        //                 vm.changeExhcangeHandler(vm.formData.exchrateid);
        //             }
        //         })
        //         .catch(err => {
        //             vm.isErr = true;
        //             vm.errMsg = err;
        //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        //         });
        // },

        // changeExhcangeHandler(id) {
        //     this.exchanges.find(item => item.id === id);

        // },

        goHandler(){
            const formData = this.formData
            const param = this.parameters


            if (this.formData.payoutcode != null)  {
                
                this.$router.push({name: 'detailscashdenomination', params: { formData,param } });
                console.log("form data passed", this.formData)

            } else {
                this.$refs.form.validate()
            }
            
        },

        updateValue() {
            this.$emit("input", {
                compnyname: this.formData.compnyname,
                payoutcode: this.formData.payoutcode
            });
        },  
    }, 

    mounted() {
      this.loadCompanies();
      this.loadPayoutCode();
    },    
    
}
</script>
