<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{this.parentObject.pageTitle}}
            <span
              class="page_title_value"
            >{{this.parentObject.pageTtileValue}}</span>
          </h2>
        </v-row>

        <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5" v-if="!this.formData.lockstaff">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.vendorcategory')"
                type="text"
                ref="vendorcatid"
                hide-selected
                :items="catitemsList"
                v-model="formData.vendorcatid"
                @input="updateValue"
                @change="changeCategoryHandler(formData.vendorcatid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" sm="6" class="pb-n5" v-else>
              <v-autocomplete
                outlined
                ::label="this.$t('setupcompanyreferencenumber.vendorcategory')"
                type="text"
                ref="vendorcatid"
                :items="catitemsList"
                v-model="formData.vendorcatid"
                readonly
                background-color="#ffeab2"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                ref="categorycode"
                v-model="formData.categorycode"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                type="text"
                ref="refnum"
                v-model="formData.refnum"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencecode')"
                type="text"
                ref="refcode"
                v-model="formData.refcode"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" class="pb-n5 mt-n8">
              <v-select
                :items="vendoritemList"
                :label="this.$t('setupcompanyreferencenumber.vendor')"
                dense
                outlined
                ref="vendorid"
                v-model="formData.vendorid"
                @input="updateValue"
                @change="changeVendorAddressHandler(formData.vendorid)"
              ></v-select>
            </v-col>
          </v-row>

          <!-- address goes here -->

          <v-row>
            <v-col cols="12" sm="10" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.address')"
                type="text"
                v-model="formData.vendoraddress"
                dense
                :disabled="false"
                readonly
                background-color="#ffeab2"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                hide-selected
                :rules="[rules.required]"
                :items="companyitemList"
                v-model="formData.compnyid"
                @input="updateValue"
                @change="changeCompanyHandler(formData.compnyid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                  outlined
                  :label="this.$t('columns.code')"
                  type="text"
                  ref="compnycode"
                  v-model="formData.compnycode"
                  dense
                  readonly
                  background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="5" sm="2" class="pb-n5 mt-n8">
              <v-select
                :label="this.$t('setupcompanyreferencenumber.addressid')"
                dense
                outlined
                hide-selected
                type="text"
                ref="addressid"
                v-model="formData.addressid"
                @input="updateValue"
                :items="addressitemList"
                @change="changeAddressIDHandler(formData.addressid)"
              ></v-select>
            </v-col>

            <v-col cols="2" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.description')"
                type="text"
                ref="adrmasdesc"
                v-model="formData.adrmasdesc"
                dense
                readonly
                @input="updateValue"
                background-color="#ffeab2"  
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="10" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.address')"
                type="text"
                v-model="formData.adrmasaddress"
                ref="adrmasaddress"
                dense
                readonly
                background-color="#ffeab2"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="10" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                ref="remark"
                maxlength="200"
                v-model="formData.remark"
                @input="updateValue"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
// for sending and receiving requests to and from server

export default {
  props: {
    parentObject: Object
  },

  data() {
    return {
      formData: {
        vendorcatid: this.parentObject.data.vendorcatid,
        categorycode: this.parentObject.data.categorycode,
        refnum: this.parentObject.data.refnum,
        refcode: this.parentObject.data.refcode,
        vendorid: this.parentObject.data.vendorid,
        vendoraddress: this.parentObject.data.vendoraddress,
        compnyid: this.parentObject.data.compnyid,
        compnycode: this.parentObject.data.compnycode,
        addressid: this.parentObject.data.addressid,
        adrmasdesc: this.parentObject.data.adrmasdesc,
        adrmasaddress: this.parentObject.data.adrmasaddress,
        lockstaff: this.parentObject.data.lockstaff,
        remark: this.parentObject.data.remark,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid
      },

      // rules used in the form
      rules: {
        required: value => !!value || this.$t('reportsparam.required'),
        counter: value => value.length <= 200 || "Max 200 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },

      // vendor categories --> desc + id
      catitemsList: [],

      // vendor categories --> all fields
      catitems: [],

      //vendors --> desc + id
      vendoritemList: [],

      // vendors --> all fields
      vendoritems: [],

      //companies -->  records returned
      companies: [],
      companyitemList: [],
      companyitems: [],

      //addresss --> records returned
      addresses: [],
      addressitemList: [],

      addressitems: [],
      adrmasdesc: '',
      compnycode: '',
      isErr: false,
      errMsg: '',
       search: this.parentObject.searchnew
    }; //end of return
  }, //end of data

  methods: {
    // load  all vendor categories
    loadVendorcatidListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/vendorcategory/all"})
        .then(res => {

          //request successfull
          // response pushed in catitemsList
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.catitemsList.push({
              text: res.data.rec[i].categorydesc,
              value: res.data.rec[i].id
            });

            // response pushed in catitems array
            vm.catitems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].categorycode,
              desc: res.data.rec[i].categorydesc,
              stamp: res.data.rec[i].stamp
            });
          }
        })
        .catch(err => {
          // request unsuccessfull - emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load vendors
    loadVendorListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/vendor/all"})
        .then(res => {
          //response pushed in vendoritemList and vendoritems arrays - used in vendorid (v-select items)
          // vendoritems used in change handlers
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.vendoritemList.push({
              text: res.data.rec[i].vendorcode,
              value: res.data.rec[i].id
            });

            vm.vendoritems.push({
              id: res.data.rec[i].id,
              vendorcode: res.data.rec[i].vendorcode,
              vendordesc: res.data.rec[i].vendordesc,
              vendorcatid: res.data.rec[i].vendrocatid,
              categorycode: res.data.rec[i].categorycode,
              addr1: res.data.rec[i].addr1,
              addr2: res.data.rec[i].addr2,
              addr3: res.data.rec[i].addr3,
              stateid: res.data.rec[i].stateid,
              cityname: res.data.rec[i].cityname,
              cntryid: res.data.rec[i].cntryid,
              cntryname: res.data.rec[i].cntryname,
              poscode: res.data.rec[i].poscode,
              tel: res.data.rec[i].tel,
              fax: res.data.rec[i].fax,
              email: res.data.rec[i].email,
              officer: res.data.rec[i].officer,
             // remark: res.data.rec[i].remark,
              stamp: res.data.rec[i].stamp
            });
          }
        })
        .catch(err => {
          //error - request unsuccessfull
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load companies
    loadCompanyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then(res => {
          vm.companies = res.data.rec;

          for (let i = 0; i < vm.companies.length; i++) {
            vm.companyitemList.push({
              text: vm.companies[i].compnyname,
              value: vm.companies[i].id
            });

            if (this.formData.compnyid) {
                this.changeCompanyHandler(this.formData.compnyid);
                }

            // vm.companyitems.push({
            //   id: res.data.rec[i].id,
            //   compnycode: res.data.rec[i].compnycode,
            //   compnyname: res.data.rec[i].compnyname
            // });
          }
        })
        .catch(err => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    // change compnycode when company changes
    changeCompanyHandler(id) {
      var item = this.companies.find(item => (item.id === id));

      this.formData.compnycode = item.compnycode;
     // this.updateValue();
    },

    //get addresses list
    loadAddressListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/address/all"})
        .then(res => {
          // request successfull
          // push response in arrays
          vm.addresses = res.data.rec;

          for (let i = 0; i < vm.addresses.length; i++) {
            vm.addressitemList.push({
              text: vm.addresses[i].adrmascode,
              value: vm.addresses[i].id
            });

              if (this.formData.addressid) {
                this.changeAddressIDHandler(this.formData.addressid);
                }
          }
        })
        .catch(err => {
          // error - request failed
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    
    // change adrmasaddress when addressid changes
    changeAddressIDHandler(id) {
      var item = this.addresses.find(item => (item.id === id));
  
      this.formData.adrmasdesc = item.adrmasdesc;
      //this.formData.adrmasaddress = item.adrmasaddress;
      this.formData.adrmasaddress = "";
      this.formData.adrmasaddress += item.addr1;

      if (item.addr2) {
        this.formData.adrmasaddress += " ";
        this.formData.adrmasaddress += item.addr2;
      }

      if (item.addr3) {
        this.formData.adrmasaddress += " ";
        this.formData.adrmasaddress += item.addr3;
      }
    },

    updateValue() {
      this.$emit("input", {
        vendorcatid: this.formData.vendorcatid,
        refnum: this.formData.refnum,
        refcode: this.formData.refcode,
        vendorid: this.formData.vendorid,
        vendoraddress: this.formData.vendoraddress,
        compnyid: this.formData.compnyid,
        compnycode: this.formData.compnycode,
        addressid: this.formData.addressid,
        adrmasdesc: this.formData.adrmasdesc,
        adrmasaddress: this.formData.adrmasaddress,
        lockstaff: this.formData.lockstaff,
        remark: this.formData.remark,
        stamp: this.formData.stamp,
        userid: this.formData.userid
      });

      console.log('remark', this.formData)
    },

    // change handlers
    //change category code when category  changes
    changeCategoryHandler(id) {
      let item = this.catitems.find(item => item.id === id);
      //alert(item.code);
      this.formData.categorycode = item.code;
    },

    // change vendoraddress field when vendor changes
    changeVendorAddressHandler(id) {
      var item = this.vendoritems.find(item => item.id === id);

      //this.vendoraddress = item.addr1;
      this.formData.vendoraddress = item.addr1;
      this.formData.vendoraddress += item.addr2;

      if (item.addr1) {
        this.formData.vendoraddress = item.addr1;
        this.formData.vendoraddress += " ";
      }

      if (item.addr2) {
        this.formData.vendoraddress += item.addr2;
        this.formData.vendoraddress += " ";
      }

      if (item.addr3) {
        this.formData.vendoraddress += item.addr3;
        this.formData.vendoraddress += " ";
      }

    //  this.updateValue();
    },

    newHandler() {
      this.$emit("new");
    },
    saveHandler() {
      this.updateValue();
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    //on cancel, go back to refnumlist 
    cancelHandler() {
      this.$router.push({ name: "refnumlist", params: { search: this.search} });
    }
  }, // end of methods

  mounted() {
    this.loadVendorcatidListHandler();
    this.loadVendorListHandler();
    this.loadCompanyListHandler();
    this.loadAddressListHandler();

    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  }
};
</script>

<!-- numberfield style class to remove buttons in number type fields--> 
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

