<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
//import moment from 'moment';

import PasswordPolicyForm from "../../../components/settings/passwordpolicy/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": PasswordPolicyForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
       search: this.$route.params.search,
      pageTitle: this.$t('setuppasswordpolicy.editpasswordpolicy'),
      pageTitleValue: "",
      formData: {
        usertypeid: null,
        pwdgen: null,
        pwdmin: null,
        pwdmax: null,
        expirydays: null,
        inactivitydays: null,
        maximumattempt: null,
        alphanumeric: false,
        symbol: false,
        repeatchar: false,
        capitalletter: false,
        logicscrpt: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadPasswordPolicy() {
      const vm = this;

      this.$store.dispatch('get', {url: "/pwdplcy/" + this.id})
        .then((res) => {
          if (
            res.data.rec[0].alphanumeric == "N" ||
            res.data.rec[0].alphanumeric == null
          ) {
            vm.formData.alphanumeric = false;
          } else {
            vm.formData.alphanumeric = true;
          }

          if (res.data.rec[0].symbol == "N" || res.data.rec[0].symbol == null) {
            vm.formData.symbol = false;
          } else {
            vm.formData.symbol = true;
          }

          if (
            res.data.rec[0].capitalletter == "N" ||
            res.data.rec[0].capitalletter == null
          ) {
            vm.formData.capitalletter = false;
          } else {
            vm.formData.capitalletter = true;
          }

          if (
            res.data.rec[0].repeatchar == "N" ||
            res.data.rec[0].repeatchar == null
          ) {
            vm.formData.repeatchar = false;
          } else {
            vm.formData.repeatchar = true;
          }

          vm.formData.usertypeid = res.data.rec[0].usertypeid;
          vm.formData.pwdgen = res.data.rec[0].pwdgen;
          vm.formData.pwdmin = res.data.rec[0].pwdmin;
          vm.formData.pwdmax = res.data.rec[0].pwdmax;
          vm.formData.expirydays = res.data.rec[0].expirydays;
          vm.formData.inactivitydays = res.data.rec[0].inactivitydays;
          vm.formData.maximumattempt = res.data.rec[0].maximumattempt;
          vm.formData.logicscrpt = res.data.rec[0].logicscrpt;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.pageTitleValue = res.data.rec[0].usertype;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/pwdplcy/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "passwordpolicylist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/pwdplcy/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "passwordpolicylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "passwordpolicynew" });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadPasswordPolicy();
  },
};
</script>