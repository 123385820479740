<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-card class="mt-n6">
          <div class="ma-4">
            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
            <v-row>
              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.company')"
                  type="text"
                  ref="company"
                  v-model="formData.company"
                  dense
                  :items="companyList"
                  :rules="[rules.required]"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.year')"
                  type="text"
                  ref="year"
                  hide-selected
                  v-model="formData.year"
                  dense
                  :items="years"
                  :rules="[rules.required]"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.month')"
                  type="text"
                  ref="month"
                  hide-selected
                  v-model="formData.month"
                  dense
                  :rules="[rules.required]"
                  :items="months"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.payout')"
                  type="text"
                  ref="payout"
                  hide-selected
                  v-model="formData.payout"
                  dense
                  :items="payoutList"
                  @change="changePayout(formData.payout)"
                  :rules="[rules.required]"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- <v-row>
                <v-col cols="6" sm="12" class="pb-n4 mt-n8">
                  <v-text-field
                    outlined
                    label="Message "
                    type="text"
                    ref="message"
                    v-model="formData.message"
                    dense
                    @input="updateValue"
                  ></v-text-field>
                </v-col>
              </v-row> -->
          </div>
        </v-card>

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="blue--text"
              >{{$t('reportsparam.filtercriteria')}}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.staff')"
                    type="text"
                    ref="staff"
                    v-model="formData.staff"
                    dense
                    :items="staffList"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.division')"
                    type="text"
                    ref="division"
                    v-model="formData.division"
                    @input="updateValue"
                    dense
                    :items="divisionList"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <!-- <v-col cols="4" sm="4" class="pb-4">
                    <v-autocomplete
                      outlined
                      label="Grade Category"
                      type="text"
                      ref="gradeCategory"
                      :rules="[rules.required]"
                      v-model="formData.gradeCategory"
                      dense
                      :items="gradecatList"
                    ></v-autocomplete>
                  </v-col> -->

                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.grade')"
                    type="text"
                    ref="grade"
                    v-model="formData.grade"
                    dense
                    :items="gradeList"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.section')"
                    type="text"
                    ref="section"
                    v-model="formData.section"
                    dense
                    :items="sectionList"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.employmenttype')"
                    type="text"
                    ref="employmentType"
                    v-model="formData.employmentType"
                    dense
                    :items="emptypeList"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.branch')"
                    type="text"
                    ref="description"
                    v-model="formData.branch"
                    dense
                    :items="branchList"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.unit')"
                    type="text"
                    ref="unit"
                    v-model="formData.unit"
                    dense
                    :items="unitList"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <!-- <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Payroll Group"
                      type="text"
                      ref="payrollGroup"
                      :rules="[rules.required]"
                      v-model="formData.payrollGroup"
                      dense
                    ></v-autocomplete>
                  </v-col> -->
                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.department')"
                    type="text"
                    ref="department"
                    v-model="formData.department"
                    dense
                    :items="departmentList"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <!-- <v-row>
                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Position"
                      type="text"
                      ref="position"
                      v-model="formData.position"
                      dense
                      :items="positionList"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- </v-container> -->

        <v-row>
          <v-col>
            <v-btn color="warning" style="width: 100px; margin-top: 20px;" @click="displayHandler"
              >{{$t('button.go')}}</v-btn
            >
          </v-col>

          <!-- <v-col>
                <v-btn color="warning" width="200px" @click="emailHandler">
                  Generate & Email
                </v-btn>
              </v-col> -->
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
//import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      formData: {
        year: null,
        month: null,
        payout: null,
        payoutcode: null,
        message: "",
        staff: "",
        division: "",
        gradeCategory: "",
        company: null,
        section: "",
        employmentType: "",
        branch: "",
        unit: "",
        payrollGroup: "",
        department: "",
        grade: "",
        position: "",
        reporturl: "",
        userid: "",
        username: "",
      },

      years: [],
      staffs: [],
      staffList: [],
      divisions: [],
      divisionList: [],
      gradecats: [],
      gradecatList: [],
      companies: [],
      companyList: [],
      sections: [],
      sectionList: [],
      emptypes: [],
      emptypeList: [],
      branches: [],
      branchList: [],
      units: [],
      unitList: [],
      payrollgroups: [],
      payrollgroupList: [],
      departments: [],
      departmentList: [],
      grades: [],
      gradeList: [],
      positions: [],
      positionList: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      payouts: [],
      payoutList: [],
      isErr: false,
      reportFile: "payrollsummarylisting.rptdesign",
      loading: false,
    };
  },

  methods: {
    loadYears() {
      for (let i = 2020; i < 2200; i++) {
        this.years.push(i);
      }
    },

    changePayout(id) {
      let item = this.payouts.find((item) => item.id === id);
      //set psnid to psnid and staffid to staffid
      this.formData.payoutcode = item.payoutcode;
      this.updateValue();
    },

    //load payouts
    loadPayoutsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutctrl/all"})
        .then((res) => {
          vm.payouts = res.data.rec;
          console.log(res.data.rec);
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.payoutList.push({
              text: res.data.rec[i].payoutcode,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load staffs
    loadStaffListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffs = res.data.rec;
          console.log(res);
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffList.push({
              text:
                res.data.rec[i].psnname +
                " " +
                res.data.rec[i].staffcode +
                " " +
                res.data.rec[i].compnyname,
              value: res.data.rec[i].staffcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/division/all"})
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].divncode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load grade category
    loadGradeCatListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/gradecat/all"})
        .then((res) => {
          vm.gradecats = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradecatList.push({
              text: res.data.rec[i].grdcatdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load companies
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].compnycode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/section/all"})
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].sectiocode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load emptypes
    loadEmpTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/emptype/all"})
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptypedesc,
              value: res.data.rec[i].emptype,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load branches
    loadBanchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/branch/all"})
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].brchcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/unit/all"})
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].unitcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load payroll groups
    loadPayrollGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutctrl/all"})
        .then((res) => {
          vm.payouts = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.payoutList.push({
              text: res.data.rec[i].payoutcode,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load depts
    loadDeptListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/department/all"})
        .then((res) => {
          vm.departments = res.data.rec;
          console.log(res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].deptcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load grades
    loadGradeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/grade/all"})
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].grdcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load positions
    loadPosiListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/position/all"})
        .then((res) => {
          vm.positions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.positionList.push({
              text: res.data.rec[i].posdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    emailHandler() {
      const vm = this;
      if (typeof this.formData.payout == "undefined") {
        this.formData.payout = 0;
      }

      this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
      this.formData.userid = this.$store.getters.userId;
      this.formData.username = this.$store.getters.userName;

      this.$store.dispatch('post', {url: "/reportemail/payrollsummarylisting",
          payload: this.formData})
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 201) {
            this.$dialog.alert("Email Successfully Send.").then((dialog) => {
              console.log(dialog);
            });
          } else {
            vm.isErr = true;
            vm.errMsg = res.data.error;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    displayHandler() {
      const vm = this;
      if ((this.formData.company != null && this.formData.year != null && this.formData.month != null ) || 
      this.formData.company != null && this.formData.payout != null){

    //if ((this.$refs.form.validate())){
      if (typeof this.formData.payout == "undefined") {
        this.formData.payout = "";
        this.formdata.payoutcode = "";
      }
      if (typeof this.formData.staff == "undefined") {
        this.formData.staff = "";
      }
      if (typeof this.formData.division == "undefined") {
        this.formData.division = "";
      }
      if (typeof this.formData.company == "undefined") {
        this.formData.company = "";
      }
      if (typeof this.formData.section == "undefined") {
        this.formData.section = "";
      }
      if (typeof this.formData.employmentType == "undefined") {
        this.formData.employmentType = "";
      }
      if (typeof this.formData.branch == "undefined") {
        this.formData.branch = "";
      }
      if (typeof this.formData.unit == "undefined") {
        this.formData.unit = "";
      }
      if (typeof this.formData.department == "undefined") {
        this.formData.department = "";
      }
      if (typeof this.formData.grade == "undefined") {
        this.formData.grade = "";
      }
      if (typeof this.formData.position == "undefined") {
        this.formData.position = "";
      }
        vm.loading = true;
        if (typeof this.formData.payout == "undefined") {
          this.formData.payout = 0;
        }

        this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
        this.formData.userid = this.$store.getters.userId;
        this.formData.username = this.$store.getters.userName;

        this.$store.dispatch('post', {url: "/reportdisplay/generic",
          payload: this.formData,
          responseType: "blob"})
          .then((res) => {
            console.log(res.data);
            vm.loading = false;
            if (res.data && res.status == 200) {
              var fileURL = URL.createObjectURL(res.data);
              window.open(fileURL, "_blank");
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.loading = false;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      } else {
        this.$refs.form.validate()
      }
    },
    goHandler() {

    const formData = this.formData
    
    if ((this.formData.company != null && this.formData.year != null && this.formData.month != null ) || 
      this.formData.company != null && this.formData.payout != null){

    //if ((this.$refs.form.validate())){
      if (typeof this.formData.payout == "undefined") {
        this.formData.payout = "";
        this.formdata.payoutcode = "";
      }
      if (typeof this.formData.staff == "undefined") {
        this.formData.staff = "";
      }
      if (typeof this.formData.division == "undefined") {
        this.formData.division = "";
      }
      if (typeof this.formData.company == "undefined") {
        this.formData.company = "";
      }
      if (typeof this.formData.section == "undefined") {
        this.formData.section = "";
      }
      if (typeof this.formData.employmentType == "undefined") {
        this.formData.employmentType = "";
      }
      if (typeof this.formData.branch == "undefined") {
        this.formData.branch = "";
      }
      if (typeof this.formData.unit == "undefined") {
        this.formData.unit = "";
      }
      if (typeof this.formData.department == "undefined") {
        this.formData.department = "";
      }
      if (typeof this.formData.grade == "undefined") {
        this.formData.grade = "";
      }
      if (typeof this.formData.position == "undefined") {
        this.formData.position = "";
      }

     this.$router.push(window.open( `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&Year=${this.formData.year}&Month=${this.formData.month}&Message=${this.formData.message}&payoutcode=${this.formData.payoutcode}&payout=${this.formData.payout}&username=${this.$store.getters.userName}&userid=${this.$store.getters.userId}` +
          `&dbname=${this.$store.getters.dbname}` +
          `&staffid=${this.formData.staff}&division=${this.formData.division}&company=${this.formData.company}&section=${this.formData.section}&employmentType=${this.formData.employmentType}` +
          `&branch=${this.formData.branch}&unit=${this.formData.unit}&department=${this.formData.department}&grade=${this.formData.grade}&position=${this.formData.position}`,
        "_blank" // <- This is what makes it open in a new window.
        ) , {params: { formData } });

      // window.open(
      //   `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&Year=${this.formData.year}&Month=${this.formData.month}&Message=${this.formData.message}&payoutcode=${this.formData.payoutcode}&payout=${this.formData.payout}&username=${this.$store.getters.userName}&userid=${this.$store.getters.userId}` +
      //     `&staffid=${this.formData.staff}&division=${this.formData.division}&company=${this.formData.company}&section=${this.formData.section}&employmentType=${this.formData.employmentType}` +
      //     `&branch=${this.formData.branch}&unit=${this.formData.unit}&department=${this.formData.department}&grade=${this.formData.grade}&position=${this.formData.position}`,
      //   "_blank" // <- This is what makes it open in a new window.
      // );
    } else {
      this.$refs.form.validate()
    }
    
    },

    updateValue() {
      this.$emit("input", {
        year: this.formData.year,
        month: this.formData.month,
        payout: this.formData.payout,
        payoutcode: this.formData.payoutcode,
        message: this.formData.message,
        division: this.formData.division,
        gradeCategory: this.formData.gradeCategory,
        company: this.formData.company,
        section: this.formData.section,
        employmentType: this.formData.employmentType,
        branch: this.formData.branch,
        unit: this.formData.unit,
        payrollGroup: this.formData.payrollGroup,
        department: this.formData.department,
        grade: this.formData.grade,
        position: this.formData.position,
      });
    },
  },

  mounted() {
    this.formData.message = "";
    this.formData.payout = "";
    this.loadYears();
    this.loadPayoutsListHandler();
    this.loadStaffListHandler();
    this.loadPosiListHandler();
    this.loadGradeListHandler();
    this.loadDeptListHandler();
    this.loadUnitListHandler();
    this.loadBanchListHandler();
    this.loadEmpTypeListHandler();
    this.loadSectionListHandler();
    this.loadCompaniesListHandler();
    this.loadGradeCatListHandler();
    this.loadDivisionListHandler();
  },
};
</script>
<style lang="scss" scoped>
.v-subheader {
  padding: 0px !important;
}
</style>