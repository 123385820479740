<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                :items="staffDetailsList"
                                @input="updateValue"
                                @change="
                                    changeStaffIDandCompanyHandler(
                                        formData.staffid
                                    )
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                         <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.staffid"
                                readonly
                                dense
                                :items="staffDetailsList"
                                background-color="#ffeab2"
                               
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffcode')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupposition.invaliddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-checkbox
                                :label="this.$t('recgrpassignment.override')"
                                v-model="formData.override"
                                @change="updateValue()"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="10" sm="10" class="pb4-n4 mt-n8">
                            <v-data-table
                                :headers="tabHeader"
                                :items="this.formData.groupitems"
                                class="elavation-1"
                                fixed-header
                                :sort-by="['code']"
                            >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title
                                            >{{$t('recgrpassignment.grpitem')}}</v-toolbar-title
                                        >
                                        <v-spacer></v-spacer>
                                        <v-dialog
                                            v-model="subDialog"
                                            max-width="800px"
                                            persistent
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-icon text v-on="on"
                                                    >+</v-icon
                                                >
                                            </template>

                                            <v-card>
                                                <app-genericlist
                                                    v-bind:parentData="{
                                                        tabItems: groupitems,
                                                        formTitle:
                                                        `${$t('recgrpassignment.grpitem')}`,
                                                        formId: 'groupitems'
                                                    }"
                                                    v-on:addGroupItem="
                                                        addItemHandler
                                                    "
                                                />

                                                <v-card-text v-if="isErr">
                                                    <span
                                                        style="font-weight: bold; color: red"
                                                        >{{ errMsg }}</span
                                                    >
                                                </v-card-text>

                                                <v-card-actions class="pb-5">
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="warning"
                                                        @click="closeHandler"
                                                        style="margin-right:20px; width: 100px"
                                                        >{{$t('button.close')}}</v-btn
                                                    >
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>

                                <template v-slot:[`item.action`]="{ item }">
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteItemHandler(item)"
                                        >mdi-delete</v-icon
                                    >
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
        <div v-if="isErrTrigger">
        <app-error
            v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErrTrigger,
            }"
            v-on:closeError="isErr = $event"
        />
        </div>
    </div>
</template>

<script>
import GenericList from "../../generic/GridListGroupAssignment";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-genericlist": GenericList,
        "app-error": ErrorDialog
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                }
            },

            formData: {
                psnid: this.parentObject.data.psnid,
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                compnyname: this.parentObject.data.compnyname,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                override: this.parentObject.data.override,
                remark: this.parentObject.data.remark,
                groupitems: this.parentObject.data.groupitems,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    sortable: false,
                    width: "5px"
                },
                {
                    text: this.$t('columns.grouptype'),
                    value: "gtypedesc",
                    width: "200px",
                    sortable: false
                },
                {
                    text: this.$t('recgrpassignment.grpcode'),
                    value: "valuecode",
                    width: "200px",
                    sortable: false
                },
                {
                    text: this.$t('columns.description'),
                    value: "valuedesc",
                    width: "300px",
                    sortable: false
                }
            ],
            selected: [],

            //arrays to store data objects from load list handlers
            groupitems: [],
            groupItemList: [],
            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            subDialog: false,
            errMsg: "",
            isErr: false,
            isErrTrigger: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        };
    },

    methods: {
        loadGroupsItemsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/groupp_grouppb/all"})
                .then(res => {
                    vm.groupitems = res.data.rec;
                    //push data in arrays
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.groupItemList.push({
                            text: res.data.rec[i].gtypedesc,
                            value: res.data.rec[i].id
                        });
                    }

                    //vm.groupItemsListCopy = vm.groupItemList;
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        updateValue() {
            this.$emit("input", {
                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                override: this.formData.override,
                remark: this.formData.remark,
                stamp: this.formData.stamp,
                groupitems: this.formData.groupitems,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                userid: this.formData.userid
            });
        },

        //load companies list
        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            if (this.formData.groupitems.length > 0) {
                this.$emit("save");
            } else {
                this.isErrTrigger = true
                this.errMsg = 'Group Items empty. Please select a group type'
            }
        },

        deleteHandler() {
            this.$emit("delete");
        },

        addItemHandler($event) {
            console.log('event', $event)
            //check for duplicates
            for (let i = 0; i < this.formData.groupitems.length; i++) {
                if (
                    this.formData.groupitems[i].gtypedesc == $event.gtypedesc /*&&
                    this.formData.groupitems[i].valuecode == $event.valuecode*/
                ) {
                    this.errMsg =
                        "Group Type already added. Duplicates not allowed";
                    this.isErr = true;
                    return;
                }
            }

            // no duplicates, push into groupitems
            this.formData.groupitems.push({
                id: $event.id,
                gtypedesc: $event.gtypedesc,
                valuecode: $event.valuecode,
                valuedesc: $event.valuedesc,
                valvalueid: $event.valvalueid,
                gtypeid: $event.gtypeid,
                action: 1
            });

            this.updateValue();
            this.closeHandler();
        },

        deleteItemHandler(val) {
            //this.$emit('deleteItem', val.id);

            let itemIndex = this.formData.groupitems.indexOf(val);

            this.formData.groupitems.splice(itemIndex, 1);

            this.updateValue();

            this.groupItemList.push({
                text: val.code,
                value: val.id
            });
        },

        cancelHandler() {
            this.$router.push({ name: "groupassignmentlist", params: { search: this.search} });
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = false;
            this.errMsg = "";
        }
    },

    mounted() {
        this.loadGroupsItemsListHandler();
        this.loadStaffDetailsListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
    -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>