<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            v-on:closeError="isErr = $event"
            />
        </div>    
    </div>
</template>

<script>
import AppForm from '../../../components/payroll/cashdenomination/Form_Details';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-form': AppForm,
        'app-error': ErrorDialog
    },

    data() {
            return{
                pageTitle: this.$t('cashdenominationlistingreport.cashdenominationlisting'),
                pageTitleValue: "",
                
                formData: {

                    userid: this.$store.getters.userId

                    },            

            errMsg: '',
            isErr: false,
            editMode: false 
            };
                },
        

    methods: {
        
        
    }
};

</script>