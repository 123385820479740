<template>
  <div>
    <app-form
      v-bind:parentObject="{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
      
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import TrainingExpensesAnalysis from "../../../components/training/trainingexpensesanalysis/Form_Detail";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": TrainingExpensesAnalysis,
    "app-error": ErrorDialog
  },

  data() {
    return {
      pageTitle: "",
      pageTitleValue: "",
      formData: {
        
        userid: this.$store.getters.userId
      },

      errMsg: "",
      isErr: false,
      editMode: false
    };
  },

  methods: {

  }

};
</script>