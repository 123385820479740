<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('reportsparam.year')"
                type="text"
                ref="year"
                hide-selected
                v-model="formData.year"
                dense
                :items="yearList"
                :rules="[rules.required]"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

         <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
              type="text"
              ref="compnyid"
              :rules="[rules.required]"
              v-model="formData.compnyid"
              dense
              :items="companyList"
              @change="changeCompany(formData.compnyid)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('attendancereportbypeiordbycompanyreport.companycode')"
              type="text"
              ref="compnycode"
              v-model="formData.compnycode"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="exportHandlerNew">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import moment from "moment";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: "Payroll Audit Export",
      
      formData: {
        year: null,
        compnyid: null,
        compnycode: null,
       
      },

       yearList: [],
       companyname: [],
       companyList: [],

       employeemaster: [],
       employeemasternew: [],
       chartaccount: [],
       payrollregister: [],

      errnorecfound: this.$t('vdatatable.norecordsfound'),
      errselected: this.$t('vdatatable.selectedpayouthasnotbeenfinalized'),

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      ptptnExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      salstatutoryother: [],
      psnstatutoryinfo: [],
      staff: [],
      psnpayroll: [],
      staffaccount: [],
      ptptndata: [],


      notFinalize: false,
      loading: false,
      isErr: false,
      errMsg: "",
       ptptnSubRefRecList: [],
       total: null,
    };
  },

  methods: {

     yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;
                console.log('vm.companyname', vm.companyname)
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
                let item = this.companyname.find((item) => item.id === id);
                this.formData.compnyid = item.id;
                this.formData.compnycode = item.compnycode;

            },


      objectToTxt(header, data) {
      const csvRows = [];

      //for header
        for (const key in header) {
            let headobj = key + ':' + header[key]
            csvRows.push(headobj) 
        }
        csvRows.push([])

      //get header for body
      const headers = Object.keys(data[0]);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    
    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

  exportHandlerNew(){
    this.getEmployeeMasterListHandler();
    this.getPayrollRegisterListHandler();
    this.getChartofAccountListHandler();   
  },

    exportHandler() {
      this.getData();
    },

     getPayrollRegisterListHandler() {
      const vm = this
       
      this.$store.dispatch('get', {url: `/payrollregister/${this.$store.getters.userId}`+ "/" +  this.formData.compnyid
      + "/" + this.formData.year })
      .then(function (res) {
      
      vm.payrollregister = res.data.rec;

        console.log('payrollregister', vm.payrollregister)
       
          if (vm.payrollregister.length > 0) {
        //     //vm.employeemasternew.push(employeemaster)   
             vm.getPayrollRegisterData();                   
         } 
      },
      )
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getPayrollRegisterData() {
      const vm = this
      let body = []
      let payrollregisterdataholder = vm.payrollregister
      let compnyname = ''
      let refnum = ''
      let staffcodenew
      let payitemcodenew
      let payitemdescnew
      let jannew
      let febnew
      let marnew
      let aprnew
      let meinew
      let junnew
      let julnew
      let ogosnew
      let septnew
      let oktnew
      let novnew
      let disnew
      let ttlnew

        body.push({
          "No Pekerja": "NO PEKERJA" + '|',
          "Kod Transaksi Payroll": "KOD TRANSAKSI PAYROLL" + '|',
          "Keterangan Transaksi": "KETERANGAN TRANSAKSI" + '|',
          "Januari": "JANUARI" + '|',
          "Februari": "FEBRUARI" + '|',
          "March": "MAC" + '|',
          "April": "APRIL" + '|',
          "May": "MEI" + '|',
          "June": "JUN" + '|',
          "July": "JULAI" + '|',
          "August": "OGOS" + '|',
          "September": "SEPTEMBER" + '|',
          "October": "OKTOBER" + '|',
          "November": "NOVEMBER" + '|',
          "Disember": "DISEMBER" + '|',
          "Total": "JUMLAH" ,
        });

      for (let i = 0; i < payrollregisterdataholder.length; i++) {    
        
        if(payrollregisterdataholder[i].staffcode == null){
          staffcodenew = ''
        } else{
          staffcodenew = payrollregisterdataholder[i].staffcode
        }

        if(payrollregisterdataholder[i].payitemcode == null){
          payitemcodenew = ''
        } else{
          payitemcodenew = payrollregisterdataholder[i].payitemcode
        }

        if(payrollregisterdataholder[i].payitemdesc == null){
          payitemdescnew = ''
        } else{
          payitemdescnew = payrollregisterdataholder[i].payitemdesc
        }

        if(payrollregisterdataholder[i].jan == null){
          jannew = ''
        } else{
          jannew = payrollregisterdataholder[i].jan
        }

        if(payrollregisterdataholder[i].feb == null){
          febnew = ''
        } else{
          febnew = payrollregisterdataholder[i].feb
        }


        if(payrollregisterdataholder[i].mar == null){
          marnew = ''
        } else{
          marnew = payrollregisterdataholder[i].mar
        }

        if(payrollregisterdataholder[i].apr == null){
          aprnew = ''
        } else{
          aprnew = payrollregisterdataholder[i].apr
        }

        if(payrollregisterdataholder[i].mei == null){
          meinew = ''
        } else{
          meinew = payrollregisterdataholder[i].mei
        }

        if(payrollregisterdataholder[i].jun == null){
          junnew = ''
        } else{
          junnew = payrollregisterdataholder[i].jun
        }

        if(payrollregisterdataholder[i].jul == null){
          julnew = ''
        } else{
          julnew = payrollregisterdataholder[i].jul
        }

         if(payrollregisterdataholder[i].ogos == null){
          ogosnew = ''
        } else{
          ogosnew = payrollregisterdataholder[i].ogos
        }

        if(payrollregisterdataholder[i].sept == null){
          septnew = ''
        } else{
          septnew = payrollregisterdataholder[i].sept
        }

        if(payrollregisterdataholder[i].okt == null){
          oktnew = ''
        } else{
          oktnew = payrollregisterdataholder[i].okt
        }

        if(payrollregisterdataholder[i].nov == null){
          novnew = ''
        } else{
          novnew = payrollregisterdataholder[i].nov
        }

        if(payrollregisterdataholder[i].dis == null){
          disnew = ''
        } else{
          disnew = payrollregisterdataholder[i].dis
        }

        if(payrollregisterdataholder[i].ttl == null){
          ttlnew = ''
        } else{
          ttlnew = parseFloat(payrollregisterdataholder[i].ttl).toFixed(2);
        }

        body.push({
          "No Pekerja": staffcodenew + '|',
          "Kod Transaksi Payroll": payitemcodenew + '|',
          "Keterangan Transaksi": payitemdescnew + '|',
          "Januari": jannew + '|',
          "Februari": febnew + '|',
          "March": marnew + '|',
          "April": aprnew + '|',
          "May": meinew + '|',
          "June": junnew + '|',
          "July": julnew + '|',
          "August": ogosnew + '|',
          "September": septnew + '|',
          "October": oktnew + '|',
          "November": novnew + '|', 
          "Disember": disnew + '|',
          "Total": ttlnew ,
        });

        compnyname = payrollregisterdataholder[i].compnyname
        refnum = payrollregisterdataholder[i].refnum
      }

      let header = {
         
        "NAMA" : compnyname,
        "TAHUN SARAAN" : vm.formData.year, 
        "BILANGAN REKOD" : payrollregisterdataholder.length 
       }

      this.titleData = refnum + " " + "PAYROLL";

      
      if (body.length > 0) {
        const csvData = this.objectToTxt(header, body);
        this.download(csvData, this.titleData);
      }
  
    },

    getChartofAccountListHandler() {
      const vm = this
       
      this.$store.dispatch('get', {url: `/chartaccount`+ "/" +  this.formData.compnyid
      + "/" + this.formData.year })
      .then(function (res) {
      
      vm.chartaccount = res.data.rec;

        console.log('chartaccount', vm.chartaccount)
       
          if (vm.chartaccount.length > 0) {
        //     //vm.employeemasternew.push(employeemaster)   
             vm.getChartAccountData();                   
         } 
      },
      )
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

      async getChartAccountData() {
      const vm = this
      let body = []
      let chartdataholder = vm.chartaccount
      let compnyname = ''
      let refnum = ''
      let payitemcodenew
      let payitemdescnew
      let payitemcatcodenew
      let affectitemnew

        body.push({
          "Kod Payroll": "KOD PAYROLL" + '|',
          "Keterangan Kod": "KETERANGAN KOD" + '|',
          "Kategori Item": "KATEGORI ITEM" + '|',
          "Status Item": "STATUS ITEM",
        });

      for (let i = 0; i < chartdataholder.length; i++) {    
        
         
        if(chartdataholder[i].payitemcode == null){
          payitemcodenew = ''
        } else{
          payitemcodenew = chartdataholder[i].payitemcode
        }

        if(chartdataholder[i].payitemdesc == null){
          payitemdescnew = ''
        } else{
          payitemdescnew = chartdataholder[i].payitemdesc
        }

         
        if(chartdataholder[i].payitemcatcode == null){
          payitemcatcodenew = ''
        } else{
          payitemcatcodenew = chartdataholder[i].payitemcatcode
        }

        if(chartdataholder[i].affectitem == null){
          affectitemnew = ''
        } else{
          affectitemnew = chartdataholder[i].affectitem
        }

        body.push({
          "Kod Payroll": payitemcodenew +'|',
          "Keterangan Kod": payitemdescnew + '|',
          "Kategori Item": payitemcatcodenew + '|',
          "Status Item": affectitemnew,
        });

        compnyname = chartdataholder[i].compnydesc
        refnum = chartdataholder[i].refnum
      }

      let header = {
         
        "NAMA" : compnyname,
        "TAHUN SARAAN" : vm.formData.year, 
        "BILANGAN REKOD" : chartdataholder.length 
       }

      this.titleData = refnum + " " + "CHART";

      
      if (body.length > 0) {
        const csvData = this.objectToTxt(header, body);
        this.download(csvData, this.titleData);
      }
  
    },
   

  getEmployeeMasterListHandler() {
      const vm = this
       
      this.$store.dispatch('get', {url: `/employeemaster/${this.$store.getters.userId}`+ "/" +  this.formData.compnyid
      + "/" + this.formData.year })
      .then(function (res) {
      
      vm.employeemaster = res.data.rec;

        console.log('employeemaster', vm.employeemaster)
       
         if (vm.employeemaster.length > 0) {
            //vm.employeemasternew.push(employeemaster)   
            vm.exportHandler();                   
          } 
      },
      )
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {
      const vm = this
      let body = []
      let dataholder = vm.employeemaster
      let staffcodenew
      let staffnamenew
      let idnonew
      let rlgnnamenew
      let dobnew
      let jobtitlenew
      let recdatenew
      let recenddatenew
      let marstatcodenew
      let spousenamenew
      let spouseidnonew
      let spouseIsWorkingnew
      let below18new
      let above18studynew
      let below18handinew
      let above18handinew
      let compnyname = ''
      let refnum = ''

        body.push({
          "No Pekerja": "NO PEKERJA" + '|',
          "Nama": "NAMA" + '|',
          "NO KP / Passport": "No KP / PASSPORT" + '|',
          "Agama": "AGAMA" + '|',
          "Tarikh Lahir": "TARIKH LAHIR" + '|',
          "Kategori Pekerja": "KATEGORI PEKERJA" + '|',
          "Tarikh Mula Kerja": "TARIKH MULA KERJA" + '|',
          "Tarikh Berhenti": "TARIKH BERHENTI" + '|',
          "Taraf Perkahwinan": "TARAF PERKAHWINAN" + '|',
          "Nama Pasangan": "NAMA PASANGAN" + '|',
          "No K/P Pasangan": "NO K/P PASANGAN" + '|',
          "Status Berkerja Pasangan": "STATUS BEKERJA PASANGAN" + '|',
          "Bil. Anak (Bawah 18 Tahun)": "BIL.ANAK (BAWAH 18 TAHUN)" + '|',
          "Bil. Anak (Atas 18 Tahun)": "BIL.ANAK (ATAS 18 TAHUN)" + '|',
          "Bil. Anak Kurang Upaya (Bawah 18 Tahun)": "BIL. ANAK KURANG UPAYA(BAWAH 18 TAHUN)" + '|',
          "Bil. Anak Kurang Upaya (Atas 18 Tahun)": "BIL. ANAK KURANG UPAYA(ATAS 18 TAHUN)",
        });

      for (let i = 0; i < dataholder.length; i++) {
         
        if(dataholder[i].staffcode == null){
          staffcodenew = ''
        } else{
          staffcodenew = dataholder[i].staffcode
        }

        if(dataholder[i].staffname == null){
          staffnamenew = ''
        } else{
          staffnamenew = dataholder[i].staffname
        }

        if(dataholder[i].idno == null){
          idnonew = dataholder[i].docno
        } else if (dataholder[i].idno == null && dataholder[i].docno == null){
          idnonew = ""
        } else{
          idnonew = dataholder[i].idno
        }

        if(dataholder[i].rlgnname == null){
          rlgnnamenew = ''
        } else{
          rlgnnamenew = dataholder[i].rlgnname
        }

        if(dataholder[i].dob == null){
          dobnew = ''
        } else{
          dobnew =dataholder[i].dob
        }
  
        if(dataholder[i].jobtitle == null){
          jobtitlenew = ''
        } else{
          jobtitlenew = dataholder[i].jobtitle
        }

        if(dataholder[i].hiredate == null){
          recdatenew = ''
        } else{
          recdatenew = dataholder[i].hiredate
        }

        if(dataholder[i].cessadate == null || moment (dataholder[i].recenddate).format("YYYY") > this.formData.year){
          recenddatenew = ''
        } else{
          recenddatenew = dataholder[i].cessadate
        }

        if(dataholder[i].marstatcode == null){
          marstatcodenew = ''
        } else{
          marstatcodenew = dataholder[i].marstatcode
        }

        if(dataholder[i].spousename == null){
          spousenamenew = ''
        } else{
          spousenamenew = dataholder[i].spousename
        }

        if(dataholder[i].spouseicno == null){
          spouseidnonew = ''
        } else{
          spouseidnonew = dataholder[i].spouseicno
        }

        if(dataholder[i].spouseIsWorking == null){
          spouseIsWorkingnew = ''
        } else{
          spouseIsWorkingnew = dataholder[i].spouseIsWorking
        }

        if(dataholder[i].below18 == null){
          below18new = ''
        } else{
          below18new = dataholder[i].below18
        }

        if(dataholder[i].above18study == null){
          above18studynew = ''
        } else{
          above18studynew = dataholder[i].above18study
        }

        if(dataholder[i].below18handi == null){
          below18handinew = ''
        } else{
          below18handinew = dataholder[i].below18handi
        }

        if(dataholder[i].above18handi == null){
          above18handinew = ''
        } else{
          above18handinew = dataholder[i].above18handi
        }

       

        body.push({
          "No Pekerja": staffcodenew +'|',
          "Nama": staffnamenew + '|',
          "NO KP / Passport": idnonew + '|',
          "Agama": rlgnnamenew + '|',
          "Tarikh Lahir": dobnew + '|',
          "Kategori Pekerja": jobtitlenew + '|',
          "Tarikh Mula Kerja": recdatenew + '|',
          "Tarikh Berhenti": recenddatenew + '|',
          "Taraf Perkahwinan": marstatcodenew + '|',
          "Nama Pasangan": spousenamenew + '|',
          "No K/P Pasangan": spouseidnonew + '|',
          "Status Berkerja Pasangan": spouseIsWorkingnew + '|',
          "Bil. Anak (Bawah 18 Tahun)": below18new + '|',
          "Bil. Anak (Atas 18 Tahun)": above18studynew + '|',
          "Bil. Anak Kurang Upaya (Bawah 18 Tahun)": below18handinew + '|',
          "Bil. Anak Kurang Upaya (Atas 18 Tahun)": above18handinew,
        });

        compnyname = dataholder[i].compnyname
        refnum = dataholder[i].refnum
      }

      let header = {
         
        "NAMA SYARIKAT" : compnyname,
        "TAHUN SARAAN" : vm.formData.year, 
        "BILANGAN REKOD" : dataholder.length 
       }

      this.titleData = refnum + " " + "MASTER";

      
      if (body.length > 0) {
        const csvData = this.objectToTxt(header, body);
        this.download(csvData, this.titleData);
      }
  
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },


  },

  mounted() {
    this.loadCompanyListHandler();
    this.yearGeneration();
  },
};
</script>

