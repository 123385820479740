<template>
  <div>
    <div v-if="!loading">
      <tp1-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tp1Form from "../../../components/payroll/tp1/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

//additional line
import ConfirmDialog from "../../../components/common/ConfirmDialog";

import moment from "moment";

export default {
  components: {
    "tp1-form": tp1Form,
    "app-error": ErrorDialog,

    //additional line
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setuptp1.edittp1'),
      pageTitleValue: "",
      formData: [],
      errMsg: "",
      isErr: false,
      editMode: true,
      loading: true,

      //additional line
      isConfirm: false,
    };
  },

  methods: {
    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      //add div and change v-on delete
      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/tp1/" +
              this.id +
              "/" +
              this.formData[0].userid})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "tp1list" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "tp1new" });
    },

    cancelHandler() {
      this.$router.push({ name: "tp1list" });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      var payload = this.formData[0];

      this.$store.dispatch('put', {url: "/tp1/" + vm.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "tp1list", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadTp1Handler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/tp1/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData = res.data.rec;

            //additional line
            vm.formData[0].userid = this.$store.getters.userId;

            vm.formData[0].submittedon = moment(
              vm.formData[0].submittedon
            ).format("YYYY-MM-DD");

            vm.formData.lockstaff = res.data.rec[0].lockstaff;

            vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadTp1Handler();
  },
};
</script>
