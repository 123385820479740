<template>
    <div v-if="!loading">
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col col="12" sm="12" class="pb4-n4 mt-5 pt-5 ma-n3">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData,
                            }"
                            v-on:onload="loadCarryForwardPolicy"
                        />
                        </v-col>
                    </v-row>
                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('leavecarryforwardpolicy'),

            formData: [],
            tabHeader: [
                { text: this.$t('columns.actions'), value: "action", width: "100px", sortable: false},
                { text: this.$t('setupleavesetup.maxunittocf'), value: 'maxunitcf', sortable: false, width: '150px' },
                { text: this.$t('setupleavesetup.maxbalancetocf'), value: 'maxperccf', width: '200px', sortable: false},
                { text: this.$t('setupleavesetup.maxenttocf'), value: 'maxpercentcf', width: '150px', sortable: false },
                { text: this.$t('setupleavesetup.validmonthstocf'), value: 'bfmth', sortable: false, width: '150px' },
                { text: this.$t('setupleavesetup.uptoyearsservice'), value: 'uptosrv', width: '200px', sortable: false},
                { text: this.$t('columns.company'), value: 'compnyname', sortable: false, width: '200px' },
                { text: this.$t('columns.branch'), value: 'brchname', width: '200px', sortable: false},
                { text: this.$t('columns.division'), value: 'divnname', width: '200px', sortable: false },
                { text: this.$t('columns.department'), value: 'deptname', sortable: false, width: '200px' },
                { text: this.$t('columns.section'), value: 'sectioname', width: '200px', sortable: false},
                { text: this.$t('columns.unit'), value: 'unitname', width: '200px', sortable: false },
                { text: this.$t('columns.employmenttype'), value: 'emptypedesc', sortable: false, width: '200px' },
                { text: this.$t('columns.grade'), value: 'grddesc', width: '200px', sortable: false},
                { text: this.$t('columns.gradecategory'), value: 'grdcatdesc', width: '200px', sortable: false },
                { text: this.$t('columns.job'), value: 'jobtitle', sortable: false, width: '200px' },
                { text: this.$t('columns.position'), value: 'posdesc', width: '200px', sortable: false},
            ],
            loading: true,
            errMsg: '',
            isErr: false,
            
        }
    },

    methods: {
        loadCarryForwardPolicy() {
            const vm = this;
            vm.loading = true;
            this.$store.dispatch('get', {url: "/leavecarryforward/all"})
                .then((res) => {
                    vm.formData = res.data.rec
                    vm.loading = false;

                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },
    },

    mounted() {
        this.loadCarryForwardPolicy();
    }
    
}
</script>