<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline orange white"
                    error-title
                >
                <span style="color: white">{{$t('setupservicerectype.confirmation')}}</span>
                </v-card-title>

                <v-card-text v-if="messageConfirm" class="text-pre-wrap">
                    {{messageConfirm}}
                </v-card-text>
                <v-card-text v-else>
                    {{$t('areyousureyouwanttoremove')}} <span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span>?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="onOKPress"
                    >
                    {{$t('vdatatable.ok')}}
                    </v-btn>
                    <v-btn
                        color="danger"
                        text
                        @click="onCancelPress"
                    >
                    {{$t('button.cancel')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            dialog: this.parentObject.isConfirm,
            messageConfirm: this.parentObject.messageConfirm,
            toDelete: false
        }
    },

    methods: {
        onOKPress() {
            this.dialog = !this.dialog;
            this.toDelete = !this.toDelete;
            this.$emit('confirm', this.toDelete);
        },

        onCancelPress() {
            this.dialog = ! this.dialog;
            this.$emit('cancelConfirm');
        }
    }
}
</script>