<template>
    <div>
        <v-dialog v-model="appraisalrecorddialog" max-width="900px">
          <template v-slot:activator="{ on }"
          this.parentObject.rankingsetid>
            <v-btn color="primary" dark class="mb-2" v-on="on"
           >+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-2">{{$t('appraisalrecord.appraisaldetailsetup')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                   <v-row>
                      <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('appraisalrecord.reviewername')"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="selections[0].reviewerid"
                        dense
                        :items="staffDetailsList"
                        @change="changeStaffIDandCompanyHandler(selections[0].reviewerid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('appraisalrecord.reviewercode(staffcode)')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="selections[0].reviewercode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="selections[0].compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                      <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('appraisalrecord.periodfrom')"
                            type="date"
                            :rules="[rules.required]"
                            v-model="selections[0].startdate"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                   

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('appraisalrecord.perioduntil')"
                            type="date"
                            :rules="[rules.required]"
                            v-model="selections[0].enddate"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>

                      <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('Setupratingscaleset.score')"
                            type="text"
                            v-model="selections[0].score"
                            dense
                            @change="getRanking(selections[0].score)"
                            class="v-input"
                        ></v-text-field>
                    </v-col>
            
                     <v-col cols="8" sm="4" class="pb-n4 mt-n8">
                    <v-text-field
                        dense
                        :label="this.$t('Setuprankingset.rankdescription')"
                        outlined
                        readonly
                        background-color="#ffeab2"
                        v-model="selections[0].rankingdesc"
                    ></v-text-field>
                </v-col>

                  <v-col cols="8" sm="4" class="pb-n4 mt-n8">
                    <v-text-field
                        dense
                        :label="this.$t('columns.code')"
                        outlined
                        readonly
                        background-color="#ffeab2"
                        v-model="selections[0].rankingcode"
                    ></v-text-field>
                </v-col>
            </v-row>

           

                <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                           :label="this.$t('appraisalrecord.factor')"
                            type="text"
                            v-model="selections[0].factor"
                            dense
                            class="v-input"
                            @change="statusHandler"
                        ></v-text-field>
                    </v-col>
                    </v-row>

                     <!-- <v-row>
                <v-col cols="4" sm="4" class="pb-n5">
                            <v-card-text v-if="isErr">
                                <span style="font-weight: bold; color: red"
                                >{{errMsg}}</span>
                            </v-card-text>
                         </v-col>
                    </v-row> -->

             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions v-if="this.selections[0].factor > '1'">
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeAppraisalRecordInput">{{$t('button.cancel')}}</v-btn>
              <v-btn 
              disabled
              color="green darken-1" 
              text 
              @click="saveAppraisalRecordInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>

            <v-card-actions v-else>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeAppraisalRecordInput">{{$t('button.cancel')}}</v-btn>
              <v-btn 
           
              color="green darken-1" 
              text 
              @click="saveAppraisalRecordInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            appraisalrecorddialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            rankingsetid : this.parentObject.rankingsetid,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                rankingsetid: null,
                psnid: null,
                reviewername: null,
                reviewerid: null,
                reviewercode: null,
                compnyid: null,
                compnyname: null,
                startdate: null,
                enddate: null,
                score: null,
                rankid: null,
                rankingcode: null,
                rankingdesc: null,
                factor: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],

             staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            // rankdesc: '', 
            // rankcode: '',
            ranksetItems:[],


            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,

         statusHandler() {
                if (this.selections[0].factor > 1 ) {
                    this.errMsg = "**Factor value should be from 0 - 1";
                    this.isErr = true;
                  
                } else{
                    this.isErr = false;
                }
        },

         getRanking(){
            const vm = this;

             this.$store.dispatch('get', {url: "/rankingperformance/" + this.parentObject.rankingsetid + "/" +
             this.selections[0].score + "/" + this.selections[0].score})
            .then((res) => {

                vm.ranksetItems = res.data.rec;
                
                this.selections[0].rankid = res.data.rec[0].id;
                this.selections[0].rankingdesc = res.data.rec[0].rankingdesc;
                this.selections[0].rankingcode = res.data.rec[0].rankingcode;
    
            })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });

        }, 

           loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {

            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.selections[0].psnid = item.psnid;
            this.selections[0].reviewerid = item.id;
            this.selections[0].reviewercode = item.staffcode;
            this.selections[0].compnyid = item.compnyid;
            this.selections[0].compnyname = item.compnyname;
            this.selections[0].reviewername = item.psnname;



        },
      
       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.appraisalrecorddialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveAppraisalRecordInput() {

            
              if (this.selections[0].reviewerid == null ) {
                 this.$dialog.alert(this.$t('fillinthereviewercolumn')).then((dialog) => {
                console.log(dialog);
             });
             }
              else if(this.selections[0].startdate == null) {
                 this.$dialog.alert(this.$t('fillintheperiodfromcolumn')).then((dialog) => {
                console.log(dialog);
             });}
             else if (this.selections[0].enddate == null){
                 this.$dialog.alert(this.$t('fillintheperioduntilcolumn')).then((dialog) => {
                console.log(dialog);
             });
            
            } else {


            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
//            }

            this.$emit('input', this.detailItem);
            this.appraisalrecorddialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
      
        },

        closeAppraisalRecordInput() {
            this.appraisalrecorddialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.appraisalrecorddialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                rankingsetid: this.parentObject.rankingsetid,
                psnid: null,
                reviewername: null,
                reviewerid: null,
                reviewercode: null,
                compnyid: null,
                compnyname: null,
                startdate: null,
                enddate: null,
                score: null,
                rankid: null,
                rankingcode: null,
                rankingdesc: null,
                factor: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {
  
    },

    mounted(){
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>