<template>
    <div>
        <div v-if="!loading">
            <app-form 
                v-bind:parentObject = "{
                        data: this.formData[0],
                        searchnew: this.search,
                        pageTitle: this.pageTitle,
                        pageTitleValue: this.pageTitleValue,
                        errMsg: this.errMsg,
                        isEdit: this.editMode
                    }"
                    
                    v-on:input="formData[0] = $event"

                    v-on:new="createHandler"
                    v-on:save="saveHandler"
                    v-on:delete="isConfirm = !isConfirm"
                    v-on:error="errorHandler"
            />

            <div v-if="isErr">
                <app-error 
                    v-bind:parentError = "{
                        data: this.errMsg,
                        isErr: this.isErr
                    }"
                    v-on:closeError="isErr = $event"
                />
            </div>

            <div v-if="isConfirm">
                <app-confirm 
                    v-bind:parentObject = "{
                        pageTitleValue: this.pageTitleValue,
                        isConfirm: this.isConfirm
                    }"
                    v-on:confirm="deleteHandler($event)"
                    v-on:cancelConfirm="isConfirm = !isConfirm"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AlertForm from '../../../components/settings/alert/Form';
import ErrorDialog from '../../../components/common/ErrorDialog';
import ConfirmDialog from '../../../components/common/ConfirmDialog';

export default {
    components: {
        'app-form': AlertForm,
        'app-error': ErrorDialog,
        'app-confirm': ConfirmDialog
    },

    data() {
        return {
            id: this.$route.params.id,
             search: this.$route.params.search,
            pageTitle: this.$t('setupalert.editalert'),
            pageTitleValue : '',
            formData: {
                alerttypeid: null,
                alerttype:null,
                secuse: null,
                isdisable: false,
                email: null,
                alertbefore: null,
                alertafter: null,
                userid: null,
                stamp: 0,
                id: this.$store.getters.userId,
            },
            alertsetuplist: [],
            alertsetup: [],
            errMsg: "",
            isErr: false,
            loading: true,
            editMode: true,
            isConfirm: false
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },
        
        saveHandler() {
            const vm = this;

            if (this.formData[0].isdisable == false) {
                this.formData[0].isdisable = 0;
            } else {
                this.formData[0].isdisable = 1;
            }
            
             if (this.formData[0].email == false) {
                this.formData[0].email = 0;
            } else {
                this.formData[0].email = 1;
            }

            var payload = this.formData[0];
            console.log(payload);
            this.$store.dispatch('put', {url: '/alertsetup/' + vm.id, payload: payload})
                .then(res => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    }
                    else {
                        this.$router.push({name: 'alertlist' , params: { search: this.search}});
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        deleteHandler($event) {
            const vm = this;

            this.isConfirm = !this.isConfirm

            if ($event) {
                
                this.$store.dispatch('delete', {url: '/alertsetup/' + this.id + '/' + this.formData[0].userid})
                .then (res => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    }  else {
                        this.$router.push({name: 'alertlist'});        
                    }
                })
                .catch (err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }
        },

        createHandler() {
            this.$router.push({name: 'alertnew'});
        },

        loadAlertSetupHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/alertsetup/' + vm.id})
                .then(res => {
                    if (res.status == 200) {
                        vm.formData = res.data.rec;
                        console.log(vm.formData);
                        vm.pageTitleValue = vm.formData[0].AlertForm;

                        if (vm.formData[0].isdisable === 'N') {
                            vm.formData[0].isdisable = false;
                        } else {
                            vm.formData[0].isdisable = true
                        }

                        if (vm.formData[0].email === 'N') {
                            vm.formData[0].email = false;
                        } else {
                            vm.formData[0].email = true
                        }

                        vm.formData[0].alerttype = res.data.rec[0].alerttype;
                        vm.formData[0].userid = vm.$store.getters.userId;

                        vm.loading = !vm.loading;
                    } else {
                        vm.isErr = !vm.isErr;    
                    }
                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },
    },

    mounted() {
        this.loadAlertSetupHandler();
    }
    
}
</script>