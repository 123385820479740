<template>
    <div>
        <v-dialog v-model="ssbsetupdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('mmssbsetup.ssbdetail')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.uptowages')"
                            type="number"
                            v-model="selections[0].uptowages"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.employerrate')"
                            type="number"
                            v-model="selections[0].employerrate"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.employerhscisrate')"
                            type="number"
                            v-model="selections[0].employerhscisrate"
                            dense
                        ></v-text-field>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.workerrate')"
                            type="number"
                            v-model="selections[0].workerrate"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.employermax')"
                            type="number"
                            v-model="selections[0].employermax"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('mmssbsetup.workermax')"
                            type="number"
                            v-model="selections[0].workermax"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeSsb">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveSsb">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            isSsb: false,
            ssbsetupdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                ssbgroupid: null,
                uptowages: null,
                employerrate: null,
                employerhscisrate: null,
                workerrate: null,
                employermax: null,
                workermax: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            itemHolder: null,
            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;
            }
            if(item.action == 2) {
                this.isExisting = true;
                
            }

            this.selections = [{
                id: item.id,
                uptowages: item.uptowages,
                employerrate: item.employerrate,
                employerhscisrate: item.employerhscisrate,
                workerrate: item.workerrate,
                employermax: item.employermax,
                workermax: item.workermax,
            }]
            console.log('id', item.id)

            console.log('modify', this.modify)
            console.log('isExisting', this.isExisting)

            this.ssbsetupdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

            this.resetSelections();
        },

        closeSsb() {
            this.ssbsetupdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        //Saving header details into the database
        

        saveSsb(){
            if (this.selections[0].uptowages == null || this.selections[0].uptowages == '' 
            || this.selections[0].employerrate == null || this.selections[0].employerrate == '' 
            || this.selections[0].employerhscisrate == null || this.selections[0].employerhscisrate == '' 
            || this.selections[0].workerrate == null || this.selections[0].workerrate == ''
            || this.selections[0].employermax == null || this.selections[0].employermax == ''
            || this.selections[0].workermax == null || this.selections[0].workermax == ''){

            this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            }
            else {
                this.isErr = false;
                if (!this.isExisting && !this.modify) {
                    this.selections[0].id = null;
                    this.selections[0].action = 1;   
                    console.log(this.selections[0]);   
                    console.log(this.detailItem);               
                    this.detailItem.push(Object.assign({}, this.selections[0]));
                    
                } else {
                    for (let i = 0; i < this.detailItem.length; i++) {
                        if (this.detailItem[i].id == this.selections[0].id) {
                            this.detailItem[i].uptowages = this.selections[0].uptowages
                            this.detailItem[i].employerrate = this.selections[0].employerrate
                            this.detailItem[i].employerhscisrate = this.selections[0].employerhscisrate
                            this.detailItem[i].workerrate = this.selections[0].workerrate
                            this.detailItem[i].employermax = this.selections[0].employermax
                            this.detailItem[i].workermax = this.selections[0].workermax
                        }
                    }
                }

                this.$emit('input', this.detailItem);
                // indicate value of 1 as insert, 2 as modify
                this.modify = false;
                this.isExisting = false;
                this.ssbsetupdialog = false;

                // reset the form fields
                this.resetSelections();
            }
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                ssbgroupid: null,
                wagesamt: null,
                employerrate: null,
                employerhscisrate: null,
                workerrate: null,
                employermax: null,
                workermax: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },
    }
}

</script>