<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('appraisalexerciserecords.appraisalexercise')"
                                type="text"
                                ref="appexercisecode"
                                v-model="formData.appexercisecode"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('appraisalexerciserecords.appraisalexercisedescription')"
                                type="text"
                                ref="appexercisedesc"
                                v-model="formData.appexercisedesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('appraisalexerciserecords.assesmenttype')"
                                type="text"
                                ref="assessmenttypeid"
                                :items="assessmenttypeList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.assessmenttypeid"
                                @input="updateValue"
                                @change="changeAssessmentTypeHandler(formData.assessmenttypeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="assessmenttypecode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="assessmenttypeDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform  
                                        v-bind:parentData = "{
                                            tabItems: assessmenttypeItems,
                                            formTitle: `${this.$t('appraisalexerciserecords.assessmenttypelist')}`,
                                            formId: 'assessmenttype'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveAssessmentType="saveAssessmentTypeHandler"
                                        v-on:deleteAssessmentType="deleteAssessmentTypeHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="assessmenttypeDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                         
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    label="Ranking Set"
                                    type="text"
                                    ref="rankingsetid"
                                    v-model="formData.rankingsetid"
                                    dense
                                    clearable
                                    :items="rankingsetItemsList"
                                    @input="updateValue"
                                    :rules="[rules.required]"
                                    @change="changeRankingSet(formData.rankingsetid)"
                                ></v-autocomplete>
                            </v-col>

                             <v-col cols="8" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="rankingsetcode"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                       <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remarks"
                                v-model="formData.remarks"
                               
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('appraisalexerciserecords.reviewstatistics')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4">
                            <v-text-field
                            outlined
                            :label="this.$t('appraisalexerciserecords.initiated')"
                            type="text"
                            v-model="initiated"
                            readonly
                            background-color="#ffeab2"
                            dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4">
                            <v-text-field
                            outlined
                            :label="this.$t('appraisalexerciserecords.ongoing')"
                            type="text"
                            v-model="ongoing"
                            readonly
                            background-color="#ffeab2"
                            dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4">
                            <v-text-field
                            outlined
                            :label="this.$t('appraisalexerciserecords.completed')"
                            type="text"
                            v-model="completed"
                            readonly
                            background-color="#ffeab2"
                            dense
                            ></v-text-field>
                        </v-col>
                        </v-row>
                                
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object,
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                id: this.parentObject.data.id,
                appexercisecode: this.parentObject.data.appexercisecode,
                appexercisedesc: this.parentObject.data.appexercisedesc,
                assessmenttypeid: this.parentObject.data.assessmenttypeid,
                assessmenttypecode: this.parentObject.data.assessmenttypecode,
                assessmenttypedesc: this.parentObject.data.assessmenttypedesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                rankingsetid: this.parentObject.data.rankingsetid,
                rankingsetcode: this.parentObject.data.rankingsetcode,
                rankingsetdesc: this.parentObject.data.rankingsetdesc,
                remarks: this.parentObject.data.remarks,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            assessmenttypecode: '',
            assessmenttypeList: [],
            assessmenttype: [],
            assessmenttypeItems: [],
            assessmenttypeDialog: false,
            assessmenttypeLoading: true,

            rankingsetItems: [],
            rankingsetItemsList: [],
            rankingsetcode: '',

            initiated: '',
            completed: '',
            ongoing: '',

            statusItems: [],
            
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew

        }
    },

    methods: {

        loadStatus() {
            const vm = this;

            this.$store.dispatch('get', {url: '/appraisalexercisestatus/' + this.formData.id})
            .then(res => {

                vm.statusItems = res.data.rec;

                let initiatedcount = [];
                let ongoingcount = [];
                let completedcount = [];

        

                 for (let i = 0; i < vm.statusItems.length; i++) {
            
                    if (vm.statusItems[i].pmstatuscode == '10'){

                        initiatedcount.push(parseFloat(vm.statusItems[i].pmstatusid));                         
                    }

                    if (vm.statusItems[i].pmstatuscode == '20'){

                        ongoingcount.push(parseFloat(vm.statusItems[i].pmstatusid));                         
                    }

                    if (vm.statusItems[i].pmstatuscode == '99'){

                        completedcount.push(parseFloat(vm.statusItems[i].pmstatusid));                         
                    }
                 }
                    this.initiated = initiatedcount.length
                    this.ongoing = ongoingcount.length
                    this.completed = completedcount.length
                })
            .catch(err => {
                vm.errMsg = err;
            });
        },
    
        changeRankingSet() {
            const vm = this;

            this.$store.dispatch('get', {url: '/rankingset/all'})
            .then(res => {

                vm.rankingsetItems = res.data.rec;

                for (let i = 0; i < vm.rankingsetItems.length; i++) {
                    vm.rankingsetItemsList.push({
                        text: vm.rankingsetItems[i].rankingsetdesc,
                        value: vm.rankingsetItems[i].id,
                    });
                }
                if (this.formData.rankingsetid) {
                this.changeRankingSetHandler(this.formData.rankingsetid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeRankingSetHandler(id) {
            var item = this.rankingsetItems.find(item => item.id === id);
            this.rankingsetcode = item.rankingsetcode;
            this.rankingsetdesc = item.rankingsetdesc;
        },

       loadAssessmentTypeHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/assessmenttype/all'})
                .then(res => {
                    vm.assessmenttype = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.assessmenttypeList.push(
                            {
                                text: res.data.rec[i].assessmenttypedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.assessmenttypeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].assessmenttypecode,
                                desc: res.data.rec[i].assessmenttypedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.assessmenttypeLoading = false;

                    if (vm.formData.assessmenttypeid) {
                        vm.changeAssessmentTypeHandler(vm.formData.assessmenttypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeAssessmentTypeHandler(id) {
            let item = this.assessmenttype.find(item => item.id === id);

            this.assessmenttypecode = item.assessmenttypecode;
        },

          saveAssessmentTypeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/assessmenttype/' + this.itemData.itemid, 
                payload: {
                assessmenttypecode: this.itemData.itemcode,
                assessmenttypedesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.assessmenttype = [];
                        vm.assessmenttypeItems = [];
                        vm.assessmenttypeList = [];

                        this.loadAssessmentTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/assessmenttype/create', payload: {
                assessmenttypecode: this.itemData.itemcode,
                assessmenttypedesc: this.itemData.itemdesc,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.assessmenttype = [];
                        vm.assessmenttypeItems = [];
                        vm.assessmenttypeList = [];

                        this.loadAssessmentTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteAssessmentTypeHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/assessmenttype/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.assessmenttype = [];
                        vm.assessmenttypeItems = [];
                        vm.assessmenttypeList = [];

                        this.loadAssessmentTypeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genTrainingCourseCatTabItems() {
            for (let i = 0; i < this.assessmenttype.length; i++) {
                this.assessmenttypeItems.push(
                    {
                        id: this.assessmenttype[i].id,
                        code: this.assessmenttype[i].assessmenttypecode,
                        desc: this.assessmenttype[i].assessmenttypedesc,
                        stamp: this.assessmenttype[i].stamp
                    }
                )
            }
        },

        updateValue() {

        this.$emit('input', {
                id: this.formData.id,
                appexercisecode: this.formData.appexercisecode,
                appexercisedesc: this.formData.appexercisedesc,
                assessmenttypeid: this.formData.assessmenttypeid,
                assessmenttypecode: this.formData.assessmenttypecode,
                assessmenttypedesc: this.formData.assessmenttypedesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                rankingsetid: this.formData.rankingsetid,
                rankingsetcode: this.formData.rankingsetcode,
                rankingsetdesc: this.formData.rankingsetdesc,
                search: this.formData.search,
                remarks: this.formData.remarks,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'appraisalexerciselist', params: { search: this.search}});
        },

    },

    mounted() {

        this.changeRankingSet();
        this.loadAssessmentTypeHandler();
 
        if(this.parentObject.isEdit == true){
            this.loadStatus();
        }
    }



}
</script>