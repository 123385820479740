<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row v-if="!this.formData.lockstaff">
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.staffname')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              @input="updateValue"
              @change="changeStaffIDandCompanyHandler(formData.staffid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.staffname')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              readonly
              background-color="#ffeab2"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.staffcode')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.staffcode"
              dense
              readonly
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.company')"
              type="text"
              ref="compnyid"
              v-model="formData.compnyname"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('generateunpaidleaverecord.leavetype')"
              type="text"
              ref="leavecode"
              :rules="[rules.required]"
              v-model="formData.leavetypeid"
              dense
              :items="leavetypelist"
              @input="updateValue"
              @change="changeLeavetype(formData.leavetypeid)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.leavecode')"
              type="text"
              ref="leavecode"
              v-model="formData.leavecode"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('recleavebalanceadjustment.adjustmenttype')"
              type="text"
              ref="adjtype"
              :rules="[rules.required]"
              v-model="formData.adjtype"
              dense
              :items="adjtype"
              @input="updateValue"
            ></v-autocomplete>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('recassetrec.quantity')"
              type="text"
              ref="adjqty"
              :rules="[rules.required]"
              v-model="formData.adjqty"
              dense
              append-icon=""
              @input="updateValue"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.startdate')"
              type="date"
              ref="startdate"
              :rules="[rules.required]"
              v-model="formData.startdate"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('recleavebalanceadjustment.validuntil')"
              type="date"
              ref="enddate"
              :rules="[rules.required]"
              v-model="formData.enddate"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('columns.reason')"
              type="text"
              ref="adjreasonid"
              :rules="[rules.required]"
              v-model="formData.adjreasonid"
              dense
              :items="leavereasonlist"
              @input="updateValue"
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="adjreasonDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: leavereasonItems,
                                            formTitle: `${this.$t('recleavebalanceadjustment.leaveadjustreason')}`,
                                            formId: 'adjreason'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveADJReason="saveADJReasonHandler"
                                        v-on:deleteADJReason="deleteADJReasonHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="adjreasonDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
        </v-row>

        <!-- <v-row>
            <v-col cols="10" sm="10" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                label="Remark"
                type="text"
                v-model="formData.remark"
                @input="updateValue"
                dense
              ></v-textarea>
            </v-col>
          </v-row> -->
        <!-- </v-container> -->

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
import GenericForm from '../../generic/GridForm';


export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    'app-genericform': GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      formData: {
        id: this.parentObject.data.id,
        psnid: this.parentObject.data.psnid,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnyname: this.parentObject.data.compnyname,
        leavetypeid: this.parentObject.data.leavetypeid,
        leavecode: this.parentObject.data.leavecode,
        adjtype: this.parentObject.data.adjtype,
        adjqty: this.parentObject.data.adjqty,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        adjreasonid: this.parentObject.data.adjreasonid,
        remark: this.parentObject.data.remark,
        groupitems: this.parentObject.data.groupitems,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },
      selected: [],

      adjreasonDialog: false,

        itemData: {
        itemcode: null,
        itemdesc: null,
        itemstamp: 0
      },
      

      //arrays to store data objects from load list handlers
      staffdetails: [],
      staffDetailsList: [],
      companies: [],
      companiesitemsList: [],
      leavetype: [],
      leavetypelist: [],
      leavereason: [],
      leavereasonlist: [],
      leavereasonItems: [],
      adjtype: [
        {
          text: this.$t('recleavebalanceadjustment.increase'),
          value: "INCREASE",
        },
        {
          text: this.$t('recleavebalanceadjustment.decrease'),
          value: "DECREASE",
        },
      ],
      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        psnid: this.formData.psnid,
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        leavetypeid: this.formData.leavetypeid,
        leavecode: this.formData.leavecode,
        adjtype: this.formData.adjtype,
        adjqty: this.formData.adjqty,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        adjreasonid: this.formData.adjreasonid,
        lockstaff: this.formData.lockstaff,
        remark: this.formData.remark,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },

    //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            if (vm.formData.staffid) {
              this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load leavetype list view
    loadLeavetypeListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/leavesetup/all`})
        .then((res) => {
          //req success --> push res into array
          vm.leavetype = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.leavetypelist.push({
              text: res.data.rec[i].leavedesc,
              value: res.data.rec[i].id,
            });

            if (vm.formData.leavetypeid) {
              this.changeLeavetype(vm.formData.leavetypeid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load leavereason list view
    loadAdjReasonListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/leavebalanceadj/adjreason`})
        .then((res) => {
          //req success --> push res into array
          vm.leavereason = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.leavereasonlist.push({
              text: res.data.rec[i].adjreasondesc,
              value: res.data.rec[i].id,
            });

             vm.leavereasonItems.push(
              {
                id: res.data.rec[i].id,
                code: res.data.rec[i].adjreasoncode,
                desc: res.data.rec[i].adjreasondesc,
                stamp: res.data.rec[i].stamp
              }
              )
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    
      saveADJReasonHandler() {
          const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                  url: '/adjreason/' + this.itemData.itemid, 
                  payload: {
                    adjreasoncode: this.itemData.itemcode,
                    adjreasondesc: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadAdjReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                  url: '/adjreason/create', 
                  payload: {
                    adjreasoncode: this.itemData.itemcode,
                    adjreasondesc: this.itemData.itemdesc,
                    userid: this.formData.userid,
                  }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadAdjReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteADJReasonHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                  url: '/adjreason/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadAdjReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genLeaveReasonTabItems() {
            for (let i = 0; i < this.leavereason.length; i++) {
                this.leavereasonItems.push(
                    {
                        id: this.leavereason[i].id,
                        code: this.leavereason[i].adjreasoncode,
                        desc: this.leavereason[i].adjreasondesc,
                        stamp: this.leavereason[i].stamp
                    }
                )
            }
        },

    changeLeavetype(id) {
      let item = this.leavetype.find((item) => item.id === id);
      this.formData.leavetypeid = item.id;
      this.formData.leavecode = item.leavecode;
    },

    //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      this.updateValue();
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "leavebalanceadjlist", params: { search: this.search} });
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadLeavetypeListHandler();
    this.loadAdjReasonListHandler();

     if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>