<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row >
                    <v-col cols="12" sm="8" md="8" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('recexchangerateset.exchangeset')"
                            type="text"
                            dense
                            v-model="formData.exchrateid"
                            :items="exchangerateList"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="8" md="8" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('recexchangerateset.exchangeset')"
                            type="text"
                            dense
                            v-model="formData.exchrateid"
                            :items="exchangerateList"
                            readonly
                            background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5">
                        <v-dialog v-model="exchangerateDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: exchangerateItems,
                                        formTitle: `${this.$t('recexchangerateset.exchangeset')}`,
                                        formId: 'exchangeset'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveExchangeSet="saveExchangeSetHandler"
                                    v-on:deleteExchangeset="deleteExchangeSetHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="exchangerateDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            type="date"
                            dense
                            v-model="formData.startdate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            type="date"
                            dense
                            v-model="formData.enddate"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="10" sm="10" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.exchangeratedetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('payeeinputprocess.payeeinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                exchrateid: this.parentObject.data.exchrateid,
                exchangeratedetail: this.parentObject.data.exchangeratedetail,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                search: this.parentObject.data.search,
                lockstaff: this.parentObject.data.lockstaff, 
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '5px', sortable: false},
                { text: this.$t('recexchangerateset.fromcurrency'), value: 'frcrcycode', width: '200px', sortable: false},
                { text: this.$t('recexchangerateset.tocurrency'), value: 'tocrcycode', width: '200px', sortable: false },
                { text: this.$t('columns.rate'), value: 'rate', width: '100px', sortable: false },
                { text: this.$t('recexchangerateset.canreverse'), value: 'canreversedesc', sortable: false, width: '100px' },
            ],
            currencyCode: '',
            
            payItemList: [],
            payrollTypeList: [],
            currencyList: [],
            exchangerateList: [],
            currencies: [],
            exchangerates: [],
            currencyItems: [],
            exchangerateItems: [],
            
            currencyDialog: false,
            exchangerateDialog: false,
            currencyLoading: true,
            exchangarateLoading: true,
            errMsg: '',
            isErr: false,
            editMode: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadExchangeRateListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/exchangerate/all'})
                .then(res => {
                    vm.exchangerates = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangerateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.exchangerateItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].exchratecode,
                                desc: res.data.rec[i].exchratedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.exchangarateLoading = false;

                    if (vm.formData.exchrateid) {
                        vm.changeCurrencyHandler(vm.formData.exchrateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        addItemHandler($event) {
            this.formData.exchangeratedetail=$event
        },

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                exchrateid: this.formData.exchrateid,
                exchangeratedetail: this.formData.exchangeratedetail,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                search: this.formData.search,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        saveExchangeSetHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/exchangerate/' + this.itemData.itemid, payload: {
                exchratecode: this.itemData.itemcode,
                exchratedesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.exchangerates = [];
                        vm.exchangerateItems = [];
                        vm.exchangerateList = [];

                        this.loadExchangeRateListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/exchangerate/create', payload: {
                exchratecode: this.itemData.itemcode,
                exchratedesc: this.itemData.itemdesc,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.exchangerates = [];
                        vm.exchangerateItems = [];
                        vm.exchangerateList = [];

                        this.loadExchangeRateListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteExchangeSetHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/exchangerate/' + $event.id + '/' + this.formData.userId})
                .then(res => {
                    console.log('check', res)
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.exchangerates = [];
                        vm.exchangerateItems = [];
                        vm.exchangerateList = [];

                        this.loadExchangeRateListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'exchangeratesetlist',  params: { search: this.search}});
        },

    },

    mounted() {
        this.loadExchangeRateListHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>