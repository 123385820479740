    <template>
    <div>
        <v-dialog v-model="periodicdialog" max-width="800px" @click:outside="closeDetail">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('latenessdeduction.deductionrate')}}</span>
              <!--<span class="headline mb-4">Periodic Attendance Incentive Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" class="pb-n5">
                        <v-radio-group
                        v-model="selections.amountfactor"
                        @change="ChangeAmountFactor(selections.amountfactor)"
                        row
                        >
                        <v-radio :label="this.$t('latenessdeduction.fixedamount')" value="Fixed"></v-radio>
                        <v-radio :label="this.$t('latenessdeduction.dedtfactor')" value="Factor"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('latenessdeduction.uptominutes')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="selections.uptominutes"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" sm="3" class="pb-n4 mt-n8" v-if="this.factor">
                        <v-text-field
                            outlined
                            :label="this.$t('latenessdeduction.dedtfactor')"
                            type="number"
                            v-model="selections.dedtfactor"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" class="pb-n4 mt-n8" v-if="!this.factor">
                        <v-text-field
                            outlined
                            :label="this.$t('trainingrecord.amount')"
                            type="number"
                            v-model="selections.amount"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupleaveentitlementpolicysetup.roundingoption')"
                            type="text"
                            :items="roundingList"
                            :rules="[rules.required]"
                            v-model="selections.roundid"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="6" sm="6" class="pb-n4 mb-n8 mt-n8">
                        <v-text-field
                            dense
                            label=""
                            type="number"
                            outlined
                            v-model="selections.roundto"
                            :disabled="selections.roundid != 4 "
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.company')"
                            type="text"
                            ref="compnyname"
                            dense
                            :items="companyList"
                            v-model="selections.companyid"
                            @change="
                                changeCompanyHandler(
                                    selections.companyid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.compnycode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.branch')"
                            type="text"
                            ref="brchname"
                            dense
                            :items="branchList"
                            v-model="selections.branchid"
                            @change="
                                changeBranchHandler(
                                    selections.branchid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.brchcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.department')"
                            type="text"
                            ref="deptname"
                            dense
                            :items="departmentList"
                            v-model="selections.deptid"
                            @change="
                                changeDepartmentHandler(
                                    selections.deptid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.deptcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.division')"
                            type="text"
                            ref="divnname"
                            dense
                            :items="divisionList"
                            v-model="selections.divid"
                            @change="
                                changeDivisionHandler(
                                    selections.divid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.divncode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.section')"
                            type="text"
                            ref="sectioname"
                            dense
                            :items="sectionList"
                            v-model="selections.sectid"
                            @change="
                                changeSectionHandler(
                                    selections.sectid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.sectiocode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.unit')"
                            type="text"
                            ref="unitname"
                            dense
                            :items="unitList"
                            v-model="selections.unitid"
                            @change="
                                changeUnitHandler(
                                    selections.unitid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.unitcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.employmenttype')"
                            type="text"
                            ref="emptypedesc"
                            dense
                            :items="emptypeList"
                            v-model="selections.emptypeid"
                            @change="
                                changeEmpTypeHandler(
                                    selections.emptypeid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.emptype"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.grade')"
                            type="text"
                            ref="grddesc"
                            dense
                            :items="gradeList"
                            v-model="selections.gradeid"
                            @change="
                                changeGradeHandler(
                                    selections.gradeid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.grdcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.job')"
                            type="text"
                            ref="jobtitle"
                            dense
                            :items="jobList"
                            v-model="selections.jobid"
                            @change="
                                changeJobHandler(
                                    selections.jobid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.jobcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.position')"
                            type="text"
                            ref="posdesc"
                            dense
                            :items="positionList"
                            v-model="selections.positionid"
                            @change="
                                changePositionHandler(
                                    selections.positionid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.poscode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row> -->
                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('latenessdeduction.uptosalary')"
                            type="number"
                            
                            v-model="selections.uptosalary"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDetail">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveDetail">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            periodicdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,

                byamount: true,
                byfactor: null,
                amountfactor: "Fixed",

                dedtfactor: null,
                roundid: null,
                roundto: null,
                amount: null,
                uptominutes: null,
                uptosalary: null,

                companyid: null,
                compnyname:null,
                compnycode: null,
                branchid: null,
                brchname: null,
                brchcode: null,
                deptid: null,
                deptname: null,
                deptcode:null,
                divid: null,
                divnname: null,
                divncode: null,
                sectid: null,
                sectioname: null,
                sectiocode: null,
                unitid:null,
                unitname: null,
                unitcode: null,
                emptypeid:null,
                emptypedesc: null,
                emptype: null,
                gradeid: null,
                grddesc: null,
                grdcode: null,
                jobid: null,
                jobtitle:null,
                jobcode:null,
                // posdesc: null,
                // positionid: null,
                // poscode: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            },

            roundingList: [],

            companyList:[],
            companyItems: [],

            branchList: [],
            branchItems: [],

            departmentList: [],
            departmentItems: [],

            divisionList: [],
            divisionItems: [],

            sectionList: [],
            sectionItems: [],

            unitList:[],
            unitItems:[],

            emptypeList: [],
            emptypeItems: [],

            gradeList: [],
            gradeItems: [],

            jobList: [],
            jobItems: [],

            // positionList: [],
            // positionItems: [],

            itemHolder: [],
            detailList: [],
            factor: false,
            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
        compareArray,
        ChangeAmountFactor(amountfactor) {
            console.log(amountfactor);

            if (amountfactor == "Fixed") {
                this.factor = false;
                this.selections.byamount = true;
                this.selections.byfactor = false;
            } else if (amountfactor == "Factor") {
                this.factor = true;
                this.selections.byamount = false;
                this.selections.byfactor = true;
            }
        },

        loadRounding() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/rounding/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.roundingList.push({
                        text: res.data.rec[i].roundingdesc,
                        value: res.data.rec[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadCompany(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/company/all'
            })
            .then(res => {

                vm.companyItems = res.data.rec;

                for (let i = 0; i < vm.companyItems.length; i++) {
                    vm.companyList.push({
                        text: vm.companyItems[i].compnyname,
                        code: vm.companyItems[i].compnycode,
                        value: vm.companyItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeCompanyHandler(id){
            let item = this.companyItems.find(item => item.id == id);

            this.selections.compnycode = item.compnycode;
            this.selections.compnyname = item.compnyname;

        },

        loadBranch(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/branch/all'
            })
            .then(res => {

                vm.branchItems = res.data.rec;

                for (let i = 0; i < vm.branchItems.length; i++) {
                    vm.branchList.push({
                        text: vm.branchItems[i].brchname,
                        code: vm.branchItems[i].brchcode,
                        value: vm.branchItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeBranchHandler(id){
            let item = this.branchItems.find(item => item.id == id);

            this.selections.brchcode = item.brchcode;
            this.selections.brchname = item.brchname;

        },

        loadDepartment(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/department/all'
            })
            .then(res => {

                vm.departmentItems = res.data.rec;

                for (let i = 0; i < vm.departmentItems.length; i++) {
                    vm.departmentList.push({
                        text: vm.departmentItems[i].deptname,
                        code: vm.departmentItems[i].deptcode,
                        value: vm.departmentItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeDepartmentHandler(id){
            let item = this.departmentItems.find(item => item.id == id);

            this.selections.deptcode = item.deptcode;
            this.selections.deptname = item.deptname;

        },
        
        loadDivisionHandler(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/division/all'
            })
            .then(res => {

                vm.divisionItems = res.data.rec;

                for (let i = 0; i < vm.divisionItems.length; i++) {
                    vm.divisionList.push({
                        text: vm.divisionItems[i].divnname,
                        code: vm.divisionItems[i].divncode,
                        value: vm.divisionItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeDivisionHandler(id){
            let item = this.divisionItems.find(item => item.id == id);

            this.selections.divncode = item.divncode;
            this.selections.divnname = item.divnname;

        },

         loadSection(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/section/all'
            })
            .then(res => {

                vm.sectionItems = res.data.rec;

                for (let i = 0; i < vm.sectionItems.length; i++) {
                    vm.sectionList.push({
                        text: vm.sectionItems[i].sectioname,
                        code: vm.sectionItems[i].sectiocode,
                        value: vm.sectionItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeSectionHandler(id){
            let item = this.sectionItems.find(item => item.id == id);

            this.selections.sectiocode = item.sectiocode;
            this.selections.sectioname = item.sectioname;

        },

        loadUnit(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/unit/all'
            })
            .then(res => {

                vm.unitItems = res.data.rec;

                for (let i = 0; i < vm.unitItems.length; i++) {
                    vm.unitList.push({
                        text: vm.unitItems[i].unitname,
                        code: vm.unitItems[i].unitcode,
                        value: vm.unitItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeUnitHandler(id){
            let item = this.unitItems.find(item => item.id == id);

            this.selections.unitcode = item.unitcode;
            this.selections.unitname = item.unitname;

        },

        loadEmpType(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/emptype/all'
            })
            .then(res => {

                vm.emptypeItems = res.data.rec;

                for (let i = 0; i < vm.emptypeItems.length; i++) {
                    vm.emptypeList.push({
                        text: vm.emptypeItems[i].emptypedesc,
                        code: vm.emptypeItems[i].emptype,
                        value: vm.emptypeItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeEmpTypeHandler(id){
            let item = this.emptypeItems.find(item => item.id == id);

            this.selections.emptype = item.emptype;
            this.selections.emptypedesc = item.emptypedesc;

        },

    
        loadGrade(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/grade/all'
            })
            .then(res => {

                vm.gradeItems = res.data.rec;

                for (let i = 0; i < vm.gradeItems.length; i++) {
                    vm.gradeList.push({
                        text: vm.gradeItems[i].grddesc,
                        code: vm.gradeItems[i].grdcode,
                        value: vm.gradeItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeGradeHandler(id){
            let item = this.gradeItems.find(item => item.id == id);

            this.selections.grdcode = item.grdcode;
            this.selections.grddesc = item.grddesc;

        },

        
        loadJob(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/job/all'
            })
            .then(res => {

                vm.jobItems = res.data.rec;

                for (let i = 0; i < vm.jobItems.length; i++) {
                    vm.jobList.push({
                        text: vm.jobItems[i].jobtitle,
                        code: vm.jobItems[i].jobcode,
                        value: vm.jobItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeJobHandler(id){
            let item = this.jobItems.find(item => item.id == id);

            this.selections.jobcode = item.jobcode;
            this.selections.jobtitle = item.jobtitle;

        },

        
        // loadPosition(){
        //     const vm = this;

        //     this.$store.dispatch('get', {
        //         url: '/position/all'
        //     })
        //     .then(res => {

        //         vm.positionItems = res.data.rec;

        //         for (let i = 0; i < vm.positionItems.length; i++) {
        //             vm.positionList.push({
        //                 text: vm.positionItems[i].posdesc,
        //                 code: vm.positionItems[i].poscode,
        //                 value: vm.positionItems[i].id,
        //             });
        //         }
                
        //     })
        //     .catch(err => {
        //         vm.errMsg = err;
        //     });

        // },

        // changePositionHandler(id){
        //     let item = this.positionItems.find(item => item.id == id);

        //     this.selections.poscode = item.poscode;
        //     this.selections.posdesc = item.posdesc;

        // },

       
         modifyItemHandler(item) {
          // assign loaded data to selections array
            if (item.action) {
                this.isExisting = true;
            } else {
                this.modify = true;
            }
            
           if(item.amount) {
                item.amountfactor = "Fixed";    
            } else {
                item.amountfactor = "Factor";  
            }
            this.ChangeAmountFactor(item.amountfactor);
                
            
            this.itemHolder = item
            this.selections =  this.itemHolder;
            //this.selections.userid = this.$store.getters.userId
            console.log("selections", this.selections)
            this.periodicdialog = true;
          },




        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },


        saveDetail() {
            if (this.selections.uptominutes == null || this.selections.roundid == null){

                this.$dialog.alert(this.$t('fillinrequirefields')).then((dialog) => {
                console.log(dialog);
                });
            } else {
                this.isErr = false;
                if (!this.isExisting && !this.modify) {
                    this.selections.id = null;

                    this.detailItem.push(Object.assign({}, this.selections));

                } else {
                    if(!this.modify){
                        this.selections.action = 2;
                    }
                    if (this.compareArray(this.detailItem, this.selections)) {
                        console.log(this.compareArray(this.detailItem, this.selections))
                    }
                    
                }
            // console.log('detailitem', this.detailItem)
                this.$emit('input', this.detailItem);
                this.periodicdialog = false;
                

                // reset the form fields
                this.closeDetail();
            }
        },

        
        closeDetail() {
            this.periodicdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        resetSelections() {
            this.selections = {
                id: null,

                byamount: true,
                byfactor: null,
                amountfactor: "Fixed",

                dedtfactor: null,
                roundid: null,
                roundto: null,
                amount: null,
                uptominutes: null,
                uptosalary: null,

                companyid: null,
                compnyname:null,
                compnycode: null,
                branchid: null,
                brchname: null,
                brchcode: null,
                deptid: null,
                deptname: null,
                deptcode:null,
                divid: null,
                divnname: null,
                divncode: null,
                sectid: null,
                sectioname: null,
                sectiocode: null,
                unitid:null,
                unitname: null,
                unitcode: null,
                emptypeid:null,
                emptypedesc: null,
                emptype: null,
                gradeid: null,
                grddesc: null,
                grdcode: null,
                jobid: null,
                jobtitle:null,
                jobcode:null,
                // posdesc: null,
                // positionid: null,
                // poscode: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }
        },

    },

    created() {
        this.loadRounding();
        this.loadCompany();
        this.loadBranch();
        this.loadDepartment();
        this.loadDivisionHandler();
        this.loadSection();
        this.loadUnit();
        this.loadEmpType();
        this.loadGrade();
        this.loadJob();
        // this.loadPosition();
    }
}
</script>   


       