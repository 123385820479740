<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>
<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupholidaysetup.holidaylist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width: '50px' },
                { text: this.$t('setupholidaysetup.holidaycode'), value: 'holcode', width: '100px' },
                { text: this.$t('setupholidaysetup.holidayname'), value: 'holdesc', width: '100px' },
                { text: this.$t('setupholidaysetup.holidaytype'), value: 'holtype', width: '100px' },
                { text: this.$t('setupholidaysetup.repeat'), value: 'repeatday', width: '100px' },               
                
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getHolidayListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/holiday/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                    console.log("dataitem",vm.dataItems)

                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].holdate) {
                            vm.dataItems[i].holdate = moment(vm.dataItems[i].holdate).format('YYYY-MM-DD');
                        }

                        
                    }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'holidayedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'holidaynew'});
        }
    },

    mounted() {
        this.getHolidayListHandler();
    }
}
</script>