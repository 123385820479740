<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: 'GL Code Mapping Policy List',
            dataHeader: [
                {text: 'ACTION', value: 'action', sortable: false, width:'80px'},
                {text: 'POLICY CODE', value: 'policycode', width: '200px'},
                {text: 'POLICY DESCRIPTION', value: 'policydesc', width: '200px'},
                {text: 'REMARK', value: 'remark', width: '200px'},
                {text: 'LEAVEL 1', value: 'level1', width: '150px'},
                {text: 'LEAVEL 2', value: 'level2', width: '150px'},
                {text: 'LEAVEL 3', value: 'level3', width: '150px'},
                {text: 'LEAVEL 4', value: 'level4', width: '150px'},
                {text: 'LEAVEL 5', value: 'level5', width: '150px'},
                {text: 'LEAVEL 6', value: 'level6', width: '150px'},
                {text: 'LEAVEL 7', value: 'level7', width: '150px'},
                {text: 'LEAVEL 8', value: 'level8', width: '150px'},
            ],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadGLCodeMappingPolicyItems() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payglcodemappingpolicy/all'})
            .then(res => {
                vm.dataItems = res.data.rec;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'glcodemappingpolicyedit', params: { id: item.id, search: item.search  } });
        },

        newRecordHandler() {
            this.$router.push({name: 'glcodemappingpolicynew'});
        }
    },

    mounted() {
        this.loadGLCodeMappingPolicyItems();
    }
}
</script>