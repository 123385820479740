<template>
    <div>
        <v-card>
            <div class="ma-4">
                <v-row class="mt-4">
                    <v-col cols="3" sm="3" class="pb-n5 pt-2 mt-4">
                        <v-text-field
                            outlined
                            label="Type"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="9" sm="9" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Goal"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Weightage"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="9" sm="9" class="mt-n2">
                        <v-text-field
                            class="kpi-field"
                            outlined
                            label="KPI"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Progress %"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="4" sm="4" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Target Option"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Calculation Method"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <!-- <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Threshold"
                            type="date"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col> -->
                    <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Target"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Stretch"
                            type="date"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col> -->
                </v-row>
            </div>
        </v-card>

        <div v-if="isErr">  
            <app-error
                v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                compnyid: 1
            },

            ratingdialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],

            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            isEditCommonKPI: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,
      
       modifyItemHandler(item) {
        //     // assign loaded data to selections array
        //     if(item.action == 1) {
        //         this.modify = true;    
        //     }
        //     if(item.action == 2) {
        //         this.isExisting = true;    
        //     }

        //    // this.itemHolder = item
        //     this.selections[0] =  item;
            console.log(item);
            this.isEditCommonKPI = true;

            this.ratingdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        openRatingInput() {
            this.ratingdialog = true;
        },

        saveRatingInput() {

            
             if (this.selections[0].ratingcode == null || this.selections[0].label == null
             || this.selections[0].ratingdesc == null || this.selections[0].score == null){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.ratingdialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        onAdvanceEditItem(item) {
            console.log('item', item);
            // this.$router.push({ name: "kpisettingedit", params: { id: item.id } });
            this.$router.push({ name: "kpisettingeditcommonkpi", params: { id: 1 } });
        },

        closeRatingInput() {
            console.log('here')
            this.isEditCommonKPI = !this.isEditCommonKPI;
            this.ratingdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.ratingdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {
  
    }
}
</script>

<style scoped>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}

.mdi-delete::before {
    margin-top: -8px;
}

.mdi-pen::before {
  margin-top: -8px;
}
</style>