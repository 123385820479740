<template>
  <div>
      <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
           <!-- <v-toolbar-title class="subtitle-2">No Parameter</v-toolbar-title>  -->
            <v-row>
            <v-col cols="3" sm="3" class="pb-n5">
              <v-autocomplete
                  outlined
                  :label="this.$t('setupreport_exportmapping.zakat_payment_classification')"
                  type="text"
                  v-model="formData.zakatclasification"
                  :items="zakatcodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
           </v-row>


        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getCIMBExportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      refnum: this.parentObject.refnum,
      recyear: this.parentObject.recyear,
      recmonth: this.parentObject.recmonth,
      formData: {

        zakatclasification: '',
        
      },

      zakatcodeList: [
        {
            text: this.$t('setupreport_exportmapping.harta'),
            value: 'HARTA',
        },
        {
            text: this.$t('setupreport_exportmapping.pendapatan'),
            value: 'PENDAPATAN',
        },
      ],
      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: this.$t('setupreport_exportmapping.zakat_cimb'),
      
      filler: "",
      totalZakat: null,
 

      accErr: [],
      taxdata: [],

      zakatholder: [],

      loading: false,

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {   
    
    getCIMBExportHandler() {
      this.loading = true;
      const vm = this


      let idnew = [];

            for (let i = 0; i < this.data.selectedPayouts.length; i++){

              idnew.push(this.data.selectedPayouts[i].id)
           }

      let dateHolder2 = this.data.recyear + "-" + this.padRJ(this.data.recmonth, 2) + "-" + this.daysInMonth(this.data.recmonth, this.data.recyear);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url: `/zakatexport/all/${this.$store.getters.userId}` + "/" + this.data.refnum + "/" + date + "/" + date + "/" + idnew
        })
      .then(function (res) {

        vm.loading = false;

        vm.zakatholder = res.data.rec;

        vm.exportHandler();

    
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {
      const vm = this

      let body = []
      let newzakatdetail = []
      let zakatamount = []
      let dataholder = vm.zakatholder
      let zakatdetail = this.filter(dataholder);
      let compnyname = '';

      this.processedCount = dataholder.length;
      this.successCount = zakatdetail.length;
      this.errCount = dataholder.length - zakatdetail.length;

       zakatdetail.forEach(function (o) {
        var existing = newzakatdetail.filter(function (i) {
          return i.staffcode === o.staffcode;
        })[0];
        if (!existing || existing == undefined) {
          newzakatdetail.push(o);
        } else {
          existing.stfpcb += o.stfpcb;
          existing.cp38 += o.cp38;
        }
      });

      newzakatdetail.forEach(element => {
        let icnoholder = ''
        let oldicnoholder  = ''


        if (element.icno) icnoholder = element.icno.split("-").join("")
        if (element.oldicno) oldicnoholder = element.oldicno
        let psnnameHolder = element.psnname.substring(0,40)

        let zakatholder = element.amount.toFixed(2)
        zakatholder = zakatholder.split(".").join("")
      
        body.push({
          "Record Type": 'D',
          "Old IC No.": this.padSpaceLJ(oldicnoholder, 14),
          "New IC No.": this.padSpaceLJ(icnoholder, 14),
          "Employee Name": this.padSpaceLJ(psnnameHolder, 40),
          "Amount": this.padRJ(zakatholder, 15),
          "ZAKAT Payment Clasification": this.padSpaceLJ(this.formData.zakatclasification, 20)

        });

         zakatamount.push(element.amount);

        compnyname = element.compnyname;
      });

      if (zakatamount) {
        let totalZakatAmountHolder = zakatamount.reduce(function (a, b) {return a + b}, 0);
        this.totalZakat = totalZakatAmountHolder.toFixed(2);
      }

      let totalzakatHolder = this.totalZakat.split(".").join("")
 
      
      let header = 
        'H' +
        this.padSpaceLJ(compnyname, 50) +
        this.padRJ(this.data.recmonth, 2) +
        this.padRJ(this.data.recyear, 4) +
        this.padRJ(totalzakatHolder, 15) +
        this.padSpaceLJ((this.data.refnum.replace(/\D/g, "")), 10) 
     

      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body);
        this.download(txtData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.recyear + "-" + this.padRJ(this.data.recmonth, 2) + "-" 
        + this.daysInMonth(this.data.recmonth, this.data.recyear);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.stfpcb != 0 || e.cp38 != 0 
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(header, data) {
      const csvRows = [];

      const dataheaders = Object.keys(data[0]);

      csvRows.push(header);

      //loop over the rows
      for (const row of data) {
        const values = dataheaders.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      return csvRows.join("\n");
    },

    download(data) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.$t('setupreport_exportmapping.zakat_cimb') + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
       if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
      
      this.getData();
      let item = [];
      item.totalZAKAT = this.totalZakat;
      item.accounterr = this.accErr;
      item.successCount = this.successCount;
      item.processedCount = this.processedCount;
      item.errCount = this.errCount;
     // console.log('item', item.totalZakat)
      if (!this.isErr) {
        this.$router.push({ name: "monthlymiscellaneousexportlog", params: { item } });
      }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlymiscellaneousexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
  },
};
</script>