<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >


                    <v-row>
                      <v-col cols="4" sm="6" class="pb-n5">
                       <v-text-field
                                outlined
                                :label="this.$t('setuppolicymaster.policyno')"
                                type="text"
                                ref="policyno"
                                :rules="[rules.required]"
                                v-model="formData.policyno"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="3" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                   
                        <v-col cols="4" sm="3" class="pb-n4 mt-n8 ">
                               <v-text-field
                                outlined
                               :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                      <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('setuppolicymaster.policydesc')"
                                type="text"
                                ref="policydesc"
                                :rules="[rules.required]"
                                v-model="formData.policydesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    
                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                               :label="this.$t('setuppolicymaster.provider')"
                                type="text"
                                ref="insproviderid"
                                :items="insuranceproviderList"
                                hide-selected
                                v-model="formData.insproviderid"
                                @input="updateValue"
                                @change="changeInsuranceProviderHandler(formData.insproviderid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="insprovidercode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="insuranceproviderDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: insuranceproviderItems,
                                            formTitle: `${this.$t('setuppolicymaster.insprovider')}`,
                                            formId: 'insuranceprovider'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveInsuranceProvider="saveInsuranceProviderHandler"
                                        v-on:deleteInsuranceProvider="deleteInsuranceProviderHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="insuranceproviderDialog = false"
                                        >
                                            {{$t('button.close')}} 
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppolicymaster.insurancedataexportformat')"
                                type="text"
                                ref= "expformatid"
                                :items="expformatList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.expformatid"
                                @change ="changeGroupHandler(formData.expformatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                   
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('columns.code')"
                                id="expformatcode"
                                type="text"
                                ref="expformatcode" 
                                background-color="#ffeab2"
                                readonly
                                v-model="expformatcode" 
                                dense                       
                        ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                             {{$t('setuppolicymaster.cvragesetup')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.groupinsurancepolicydetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-genericform': GenericForm,
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                policyno: this.parentObject.data.policyno,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                policydesc: this.parentObject.data.policydesc,
                insproviderid: this.parentObject.data.insproviderid,
                remark: this.parentObject.data.remark,
                expformatid: this.parentObject.data.expformatid,
                 groupinsurancepolicydetail: this.parentObject.data.groupinsurancepolicydetail,
                 search: this.parentObject.data.search,
               // lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            insprovidercode: '',
            insuranceproviderList: [],
            insuranceprovider: [],
            insuranceproviderItems: [],
            insuranceproviderDialog: false,
            insuranceproviderLoading: true,
       
            expformatList:[],
            expformat: [],
            expformatcode: '',
            search: this.parentObject.searchnew,
          
    

            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,

              tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setuppolicymaster.cvragetype'), value: 'coveragedesc', width: '200px', sortable: true},
                { text: this.$t('setuppolicymaster.cvragevalue'), value: 'coveragevalue', width: '200px', sortable: true},
              
            ],

        }
    },

    methods: {

        addItemHandler($event) {
            this.formData.groupinsurancepolicydetail=$event
    
            this.updateValue();
        },

        loadExportFormatList() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/exportformatinsurance/all'})
                .then(res => {
                    vm.expformat = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.expformatList.push(
                                {
                                    text: res.data.rec[i].expdesc,
                                    value: res.data.rec[i].id
                                }
                            )}
                    }

                    if (this.formData.expformatid) {
                        this.changeGroupHandler(this.formData.expformatid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeGroupHandler(id) {
            let item = this.expformat.find(item => item.id === id);

            this.expdesc = item.expdesc;
            this.expformatcode = item.expcode;
        },

          loadInsuranceProviderListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/insuranceprovider/all'})
                .then(res => {
                    vm.insuranceprovider = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.insuranceproviderList.push(
                            {
                                text: res.data.rec[i].insprovidername,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.insuranceproviderItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].insprovidercode,
                                desc: res.data.rec[i].insprovidername,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.insuranceproviderLoading = false;

                    if (vm.formData.insproviderid) {
                        vm.changeInsuranceProviderHandler(vm.formData.insproviderid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeInsuranceProviderHandler(id) {
            let item = this.insuranceprovider.find(item => item.id === id);

            this.insprovidercode = item.insprovidercode;
        },

          saveInsuranceProviderHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url:  '/insuranceprovider/' + this.itemData.itemid, 
                    payload: {
                        insprovidercode: this.itemData.itemcode,
                        insprovidername: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid,
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.insuranceprovider = [];
                        vm.insuranceproviderItems = [];
                        vm.insuranceproviderList = [];

                        this.loadInsuranceProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url:  '/insuranceprovider/create', 
                    payload: {
                        insprovidercode: this.itemData.itemcode,
                        insprovidername: this.itemData.itemdesc,
                        userid: this.formData.userid,
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.insuranceprovider = [];
                        vm.insuranceproviderItems = [];
                        vm.insuranceproviderList = [];

                        this.loadInsuranceProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteInsuranceProviderHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url:  '/insuranceprovider/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.insuranceprovider = [];
                        vm.insuranceproviderList = [];
                        vm.insuranceproviderItems = [];

                        this.loadInsuranceProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genInsuranceProviderTabItems() {
            for (let i = 0; i < this.insuranceprovider.length; i++) {
                this.insuranceproviderItems.push(
                    {
                        id: this.insuranceprovider[i].id,
                        code: this.insuranceprovider[i].insprovidercode,
                        desc: this.insuranceprovider[i].insprovidername,
                        stamp: this.insuranceprovider[i].stamp
                    }
                )
            }
        },


        updateValue() {
            this.$emit('input', {
                policyno: this.formData.policyno,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                policydesc: this.formData.policydesc,
                insproviderid: this.formData.insproviderid,
                remark: this.formData.remark,
                expformatid: this.formData.expformatid,
                groupinsurancepolicydetail: this.formData.groupinsurancepolicydetail,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'groupinsurancepolicylist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadInsuranceProviderListHandler();
        this.loadExportFormatList();

    }
    
}
</script>