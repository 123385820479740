<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('payeeinputprocess.payeeinputlist'),
            dataHeader: [
                { text: this.$t('columns.actions'),               value: 'action',                sortable: false},
                { text: this.$t('payrollprocessrun.payout'),               value: 'payoutcode',            width: '130px' },
                { text: this.$t('columns.description'),          value: 'payoutdesc',            width: '300px' },
                { text: this.$t('reportsparam.year'),                 value: 'pyyear',                width: '100px' },
                { text: this.$t('reportsparam.month'),                value: 'pymonth',               width: '100px' },
                { text: this.$t('payrollprocessrun.payoutstartdate'),    value: 'startdate',             width: '200px' },
                { text: this.$t('payrollprocessrun.payoutenddate'),      value: 'enddate',               width: '200px' },
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',          width: '150px' },
                { text: this.$t('setuppayrolltype.payrolltype'),         value: 'payrolltypedesc',       width: '150px' },
                { text: this.$t('payrollprocessrun.payrollgroup'),        value: 'grouppdesc',            width: '160px' },
                { text: this.$t('payrollprocessrun.exchangerate'),        value: 'exchratedesc',          width: '210px' },
                { text: this.$t('payrollprocessrun.payoutfrequency'),     value: 'salfreqdesc',           width: '200px' },
                { text: this.$t('payrollprocessrun.payeelist'),           value: 'payeelistdesc',         width: '200px' },
            ],

            dataItems: [],
            getPayCtrl: [],
            payoutCtrls: [],
            getId: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadPayInputItems() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payeeinputlist/all'})
            .then(res => {
                vm.getPayCtrl = res.data.rec;
                for (let i = 0; i < vm.getPayCtrl.length; i++) {
                    this.getId.push({payoutctrlid: vm.getPayCtrl[i].payoutctrlid})
                }
                this.getPayoutList()
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadPayoutListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutcontrolinput/all'})
            .then (res => {
                let getPayout = res.data.rec

                for (let i = 0; i < getPayout.length; i++) {
                   // if (getPayout[i].pystatuscode != 99) {
                        vm.payoutCtrls.push(getPayout[i])
                   // }
                }

                for (let i = 0; i < vm.payoutCtrls.length; i++) {
                    if (vm.payoutCtrls[i].startdate) {
                        vm.payoutCtrls[i].startdate = moment(vm.payoutCtrls[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.payoutCtrls[i].enddate) {
                        vm.payoutCtrls[i].enddate = moment(vm.payoutCtrls[i].enddate).format('YYYY-MM-DD');
                    }
                }

                this.loadPayInputItems()
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        getPayoutList() {
            for (let i = 0; i < this.getId.length; i++) {
                for (let j = 0; j < this.payoutCtrls.length; j++) {
                    if (this.payoutCtrls[j].id === this.getId[i].payoutctrlid) {
                        this.dataItems.push(this.payoutCtrls[j])
                    }
                }
            }
        },

        editItemHandler(item) {
            this.$router.push({name: 'payeeinputedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'payeeinputnew'});
        }
    },

    mounted() {
        this.loadPayoutListHandler()
    }
}
</script>