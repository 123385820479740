<template>
    <div>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>

        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('alleis.eissubmissionrec')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.eissubreccode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.eissubrecdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.year')"
                                v-model="formData.recyear"
                                @input="changeYearHandler()"
                                dense
                                :items="yearList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.month')"
                                v-model="formData.recmonth"
                                @input="changeMonthHandler()"
                                dense
                                :items="monthList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5 mt-n7">
                            <v-btn icon color="orange">
                                <v-icon @click="loadPayoutList()">mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="10" class="blue--text">
                            {{$t('statutoryexportcommonused.payouts')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-data-table
                            v-model="selected"
                            @input="changePayoutHandler()"
                            :headers="dataHeader"
                            :items="this.dataItems"
                            :options="tableOpt"
                            hide-default-footer
                            :single-select="singleSelect"
                            item-key="payoutcode"
                            show-select
                        >
                        </v-data-table>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text mt-6">
                            {{$t('statutoryexportcommonused.approverdetails')}} 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('statutoryexportcommonused.fullname')"
                                type="text"
                                v-model="formData.aprname"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('statutoryexportcommonused.icno')"
                                type="text"
                                v-model="formData.apricno"
                                @input="updateValue"
                                dense
                                
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('statutoryexportcommonused.designation')"
                                type="text"
                                v-model="formData.aprdesignation"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.email')"
                                type="text"
                                v-model="formData.apremail"
                                @input="updateValue"
                                dense
                                :rules="[rules.email]"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('statutoryexportcommonused.telno')"
                                type="text"
                                v-model="formData.aprtel"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('statutoryexportcommonused.referencenumberandpaymentinfo')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.eissubrecref
                            }"

                            v-on:input="addItemHandler"
                        />

                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        Loading,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                    }
            },

            formData: {
                eissubreccode:  this.parentObject.data.eissubreccode,
                eissubrecpay: this.parentObject.data.eissubrecpay,
                eissubrecref: this.parentObject.data.eissubrecref,
                eissubrecdesc:  this.parentObject.data.eissubrecdesc,
                recyear:  this.parentObject.data.recyear,
                recmonth:    this.parentObject.data.recmonth,
                aprname:    this.parentObject.data.aprname,
                apricno:    this.parentObject.data.apricno,
                aprdesignation:    this.parentObject.data.aprdesignation,
                apremail:    this.parentObject.data.apremail,
                aprtel:    this.parentObject.data.aprtel,
                stamp:      this.parentObject.data.stamp,
                userid:     this.parentObject.data.userid
            },

            singleSelect: false,
            dataItems: [],
            dataHeader: [
                { text: this.$t('statutoryexportcommonused.payoutid'),            value: 'payoutcode',    width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.payoutdescription'),   value: 'payoutdesc',    width: '300px', sortable: true},
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',  width: '250px', sortable: true}
            ],

            tableOpt: {
                itemsPerPage: -1
            },

            
            selected: [],
            affectItems: [],

            tabHeader: [
                { text: this.$t('columns.actions'),       value: 'action',    width: '100px', sortable: false},
                { text: this.$t('statutoryexportcommonused.refcode'), value: 'refcode',          width: '150px', sortable: true},
                { text: this.$t('setuppersonstatutorynumberinfo.refno'),value: 'refnum',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptno'),  value: 'receiptno',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptdate'), value: 'receiptdate',          width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.receiptref'),  value: 'receiptref',          width: '150px', sortable: true},
            ],
            

            monthList: [],
            yearList: [],
            payoutList: [],
            months: [],
            years: [],
            loading: false,

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadPayout() {
            const vm = this;

             vm.loading = true;

            this.$store.dispatch('get', {
                url: '/payoutctrlfew/few'})
                .then(res => {
                    vm.payoutList = res.data.rec;
                    vm.loading = false

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )        
                        
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )
                    }
                    this.loadPayoutEdit();

                    if (vm.formData.recyear) {
                        vm.changeYearHandler(vm.formData.recyear);
                    }

                    if (vm.formData.recmonth) {
                        vm.changeMonthHandler(vm.formData.recmonth);
                    }

                    
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeYearHandler(id) {
            this.years.find(item => item.id === id);
        },

        changeMonthHandler(id) {
            this.months.find(item => item.id === id);
        },

        changePayoutHandler() {
            console.log('abc', this.dataItems)
            this.formData.eissubrecpay = this.selected
            console.log('this.formData.eissubrecpay', this.formData.eissubrecpay)
            this.updateValue()

            
        },

        loadPayoutList() {
            let payoutYear = [];
            if (!this.formData.recyear && !this.formData.recmonth) {
                this.dataItems = this.payoutList
            } else if (this.formData.recyear) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    if (this.payoutList[i].pyyear === this.formData.recyear) {
                        payoutYear.push(this.payoutList[i])
                    }
                }
                if (this.formData.recmonth) {
                    this.dataItems = []
                    for (let j = 0; j < payoutYear.length; j++) {
                        if (payoutYear[j].pymonth === this.formData.recmonth) {
                            this.dataItems.push(payoutYear[j])
                        }
                    }
                }
            }
        },

        loadPayoutEdit() {
            let getItem = []
            if (this.isEdit) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    for (let j = 0; j < this.formData.eissubrecpay.length; j++) {
                        if (this.payoutList[i].pyctrlid === this.formData.eissubrecpay[j].pyctrlid) {
                            getItem[j] = this.payoutList[i]
                            getItem[j].exId = this.formData.eissubrecpay[j].id
                            
                        }
                    }
                }
                
                this.loadPayoutList()
                this.selected = getItem
                this.formData.eissubrecpay = []
            }
        },

        addItemHandler($event) {
            this.formData.eissubrecref=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                eissubreccode: this.formData.eissubreccode,
                eissubrecdesc: this.formData.eissubrecdesc,
                recyear: this.formData.recyear,
                recmonth: this.formData.recmonth,
                aprname: this.formData.aprname,
                apricno: this.formData.apricno,
                aprdesignation: this.formData.aprdesignation,
                apremail: this.formData.apremail,
                aprtel: this.formData.aprtel,
                eissubrecpay: this.formData.eissubrecpay,
                eissubrecref: this.formData.eissubrecref,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'eissublist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadPayout();
    }
}
</script>