<template>
    <div>
        <v-btn color="primary" dark class="mb-2" @click.stop="newItem">+</v-btn>
        <v-dialog v-model="formDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('setupprocesslistmaster.processlist')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setuppayrolltype.listtype')"
                                    type="text"
                                    :items="proclistList"
                                    v-model="selections[0].proclistmasid"
                                    @change="
                                        changeProcListItemHandler(
                                            selections[0].proclistmasid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="4" md="4">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupprocesslistmaster.precedence')"
                                    type="number"
                                    v-model="selections[0].precd"
                                    @input="changePrecedenceHandler"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red;">{{
                        errMsg
                    }}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeFormDialog"
                        >{{$t('button.cancel')}}</v-btn
                    >

                    <v-btn
                        v-if="allowSave"
                        color="blue darken-1"
                        text
                        @click="saveItem"
                        >{{$t('button.save')}}</v-btn
                    >
                    <v-btn
                        v-else
                        disabled
                        color="blue darken-1"
                        text
                        @click="saveItem"
                        >{{$t('button.save')}}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="this.detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
// import moment from "moment";

export default {
    props: {
        parentObject: Object,
    },

    data() {
        return {
            formDialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [
                {
                    id: null,
                    payrolltypeid: parseInt(this.$route.params.id),
                    proclistmasid: null,
                    proclistmascode: null,
                    proclistmasdesc: null,
                    precd: null,
                    rectimestamp: null,
                    updatedby: null,
                    action: 0,
                },
            ],

            detailList: [],

            selected: [],
            proclists: [],
            proclistList: [],

            isNew: false,
            isExisting: false,

            tmpDetailItem: null,

            oItem: null,
            oPrecd: null,

            isErr: false,
            allowSave: false,
            withDuplicates: false,
            errMsg: "",
        };
    },

    methods: {
        loadProcessList() {
            const vm = this;

            this.$store.dispatch('get', {url: "/proclistmas/payrolltype"})
                .then((res) => {
                    vm.proclists = res.data.rec;

                    for (let i = 0; i < vm.proclists.length; i++) {

                        // if (vm.proclists[i].startdate) {
                        //     vm.proclists[i].startdate = moment(vm.proclists[i].startdate).format('YYYY-MM-DD');
                        // }

                        // if (vm.proclists[i].enddate) {
                        //     vm.proclists[i].enddate = moment(vm.proclists[i].enddate).format('YYYY-MM-DD');
                        // }

                        vm.proclistList.push({
                            // text: `${vm.proclists[i].proclistmasdesc} (${vm.proclists[i].startdate})(${vm.proclists[i].enddate})`,
                            text: `${vm.proclists[i].proclistmasdesc}`,
                            // text: vm.proclists[i].proclistmasdesc,
                            // value: vm.proclists[i].proclistmasid
                            value: vm.proclists[i].id
                        });
                    }
                });
        },

        dupDetailItemHandler() {
            let origDetailItem = [...this.detailItem];
            let tmpDetailItem = [];

            origDetailItem.forEach((item) => {
                tmpDetailItem.push({
                    id: item.proclistmasid,
                    precd: item.precd,
                });
            });

            return tmpDetailItem;
        },

        modifyItemHandler(item) {
            this.isExisting = true;

            this.selections[0] = item;
            this.oItem = item.proclistmasid;
            this.oPrecd = item.precd;

            this.openDialog();
        },

        deleteItemHandler(item) {
            // get the index of the item
            let itemIndex = this.detailItem.indexOf(item);

            // remove item from detailItem
            this.detailItem.splice(itemIndex, 1);
        },

        checkDupItem(val) {
            if (this.tmpDetailItem.some((item) => item.id == val)) {
                return true;
            }

            return false;
        },

        checkDupPrecd(val) {
            if (this.tmpDetailItem.some((item) => item.precd == val)) {
                return true;
            }

            return false;
        },

        checkAllFields() {
            // conver curretn selectios to int
            let currItemId = parseInt(this.selections[0].proclistmasid);
            let currPrecd = parseInt(this.selections[0].precd);

            if (this.selections[0].proclistmasid && this.selections[0].precd) {
                let isDupItem = false;
                let isDupPrecd = false;

                if (this.isExisting) {
                    // check item
                    if (this.oItem !== currItemId) {
                        isDupItem = this.checkDupItem(currItemId);

                        if (isDupItem) {
                            this.duplicateError("item");
                            return;
                        }
                    }

                    // check precd
                    if (this.oPrecd !== currPrecd) {
                        isDupPrecd = this.checkDupPrecd(currPrecd);

                        if (isDupPrecd) {
                            this.duplicateError("precedence");
                            return;
                        }
                    }
                } else {
                    // check item
                    isDupItem = this.checkDupItem(currItemId);

                    if (isDupItem) {
                        this.duplicateError("item");
                        return;
                    }

                    // check precd
                    isDupPrecd = this.checkDupPrecd(currPrecd);

                    if (isDupPrecd) {
                        this.duplicateError("precedence");
                        return;
                    }
                }

                this.allowSave = true;
            }
        },

        changeProcListItemHandler(id) {
            this.isErr = false;

            let item = this.proclists.find((item) => item.id == id);
            // let item = this.proclists.find((item) => item.proclistmasid == id);
            this.selections[0].proclistmasid = item.id;
            this.selections[0].proclistmascode = item.proclistmascode;
            this.selections[0].proclistmasdesc = item.proclistmasdesc;

            this.checkAllFields();
        },

        changePrecedenceHandler() {
            this.isErr = false;

            this.checkAllFields();
        },

        newItem() {
            // set the form to empty if it is new
            this.isNew = true;

            if (this.isNew) {
                this.resetSelections();
            }

            this.openDialog();
        },

        openDialog() {
            this.tmpDetailItem = this.dupDetailItemHandler();

            this.formDialog = true;
        },

        closeFormDialog() {
            // this function is the cancel button event

            // set the form to empty
            this.resetSelections();

            // revert detailItem to the state before modify
            this.detailItem = [...this.detailItem];

            this.formDialog = false;
            this.isErr = false;
        },

        duplicateError(typeStr) {
            this.isErr = true;
            this.errMsg = "Duplicate " + typeStr + " not allowed";
        },

        saveItem() {
            this.isErr = false;

            if (this.isNew) {
                this.detailItem.push(Object.assign({}, this.selections[0]));

                for (let i = 0; i < this.detailItem.length; i++) {
                    if (this.detailItem[i].action == 0) {
                        this.detailItem[i].action = 1;
                    }
                }
            }

            this.$emit("input", this.detailItem);
            this.formDialog = false;
        },

        resetSelections() {
            this.selections = [
                {
                    id: null,
                    payrolltypeid: parseInt(this.$route.params.id),
                    proclistmasid: null,
                    proclistmascode: null,
                    proclistmasdesc: null,
                    precd: null,
                    rectimestamp: null,
                    updatedby: null,
                    action: 0,
                },
            ];
        },
    },

    created() {
        this.loadProcessList();
    },
};
</script>
