<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
            
            v-on:input="formData = $event"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>    
    </div>
</template>

<script>
import FullForm from '../../../components/personnel/staffulldetails/Form';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-form': FullForm,
        'app-error': ErrorDialog
    },

    data() {
            return{
                pageTitle: 'Person & Staff Details Summary',
                pageTitleValue: '',
                formData: {
                    staffid: null,
                    divid: null,
                    gradeCatid: null,
                    compnyid: null,
                    sectioid: null,
                    emptypeid: null,
                    brchid: null,
                    unitid: null,
                    deptid: null,
                    grdid: null,
                    positionid: null,

                    pic: null,
                    recdate: new Date().toISOString().slice(0,10),
                    dob: null,
                    psnname: null,
                    idno: null,
                    gendername: null,
                    ntlyname: null,

                    emailadd: null,
                    hpno: null,
                    corresaddr: null,
                    permaaddr: null,
                    emergencycontact1: null,
                    emergencycontact2: null,
                    emergencycontactno1: null,
                    emergencycontactno2: null,

                    companyd: null,
                    staffcode: null,
                    hiredate: null,
                    branchd: null,
                    divisiond: null,
                    deparmentd: null,
                    sectiond: null,
                    unitd: null,
                    positiond: null,
                    graded: null,
                    empTyped: null,
                    jobd: null,
                    basicsal: null,
                    salaryfreq: null,
                    contprd: null,
                    contenddate: null,

                    epfRefno: null,
                    eisRefno: null,
                    socsoRefno: null,
                    taxRefno: null,
                    bankDetails:null,

                    employeeInfo: null,
                    leaveInfo: null,
                    payrollInfo: null,
                    claimInfo: null,
                    securityInfo: null,
                    costInfo: null,
                    claimchargingInfo: null,
                    workdayInfo: null
                    
                    },

                staffList: [],
                compnyList: [],
                branchList: [],
                deptList: [],
                divisionList: [],
                sectionList: [],
                unitList: [],
                gradeList: [],
                emptypeList: [],
                positionList: [],


                companies: [],
                staffs: [],
                branches: [],
                departs: [],
                divisions: [],
                sections: [],
                units: [],
                grades: [],
                emptypes: [],
                positions:[],

            
            errMsg: '',
            isErr:false,
            editMode: false 
            }
                },
        

    methods: {

        cancelHandler() {
            this.$router.push({name: 'dashboard'});
        },
    }
}

</script>