<template>
	<div v-if="!loading">
	<app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:cancel="cancelHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

import AssetAssignmentForm from "../../../components/personnel/assetassignment/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": AssetAssignmentForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recassetassignmentrec.editassetassignmentrec'),
      pageTitleValue: "",
      formData:  {
        staffid: null,
        staffcode: null,
        compnyid: null,
        assetid: null,
        assettype: null,
        assetdesc: null,
        assetdid: null,
        unitno: null,
        serialno: null,
        assetitemdesc: null,
        assigndate: null,
        returndate: null,
        qty: null,
        returnconditionid: null,
        assetcondition: null,
        assetconditiondesc: null,
        assigncasestatusid: null,
        assigncasestatus: null,
        remark: null,
        stamp: null,
        userid: this.$store.getters.userId,
      },
      assetassignmentHolder: null,
      errMsg: "",
      isErr: false,
      editMode: true,
      loading: true,
      isConfirm: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      let vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/assetassignment/edit/" + vm.id, payload: this.formData})
        .then((res) => {
          if (res.data.status == 201) {
            vm.$router.push({ name: "assetassignmentlist", params: { search: this.search}  });
          } 
          else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/assetassignment/edit/" +
            this.assetassignmentHolder.id +
            "/" +
            this.formData.userid,
          payload: { data: this.formData }})
        .then((res) => {
          if (res.data.status == 200) {
            vm.$router.push({ name: "assetassignmentlist" });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "assetassignmentlist" });
    },

    createHandler() {
      this.$router.push({ name: "assetassignmentnew" });
    },

    loadAssetAssignmentDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/assetassignment/edit/" + this.id})
        .then((res) => {
          vm.assetassignmentHolder = res.data.rec[0];

          this.loadAssetAssignmentHandler(vm.assetassignmentHolder);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadAssetAssignmentHandler(item) {
      const vm = this;

      this.$store.dispatch('get', {url: "/assetassignment/edit/" + item.id})
        .then((res) => {
          vm.assetassignmentHolder = res.data.rec[0];

          this.formData.staffid = vm.assetassignmentHolder.staffid;
          this.formData.staffcode = vm.assetassignmentHolder.staffcode;
          this.formData.compnyid = vm.assetassignmentHolder.compnyid;
          this.formData.assetid = vm.assetassignmentHolder.assetid;
          this.formData.assettype = vm.assetassignmentHolder.assettype;
          this.formData.assetdesc = vm.assetassignmentHolder.assetdesc;
          this.formData.assetdid = vm.assetassignmentHolder.assetdid;
          this.formData.unitno = vm.assetassignmentHolder.unitno;
          this.formData.serialno = vm.assetassignmentHolder.serialno;
          this.formData.assetitemdesc = vm.assetassignmentHolder.assetitemdesc;
          this.formData.assigndate = moment(vm.assetassignmentHolder.assigndate).format("YYYY-MM-DD");
          if (vm.assetassignmentHolder.returndate) {
              this.formData.returndate = moment(vm.assetassignmentHolder.returndate).format("YYYY-MM-DD");
          }
          this.formData.qty = vm.assetassignmentHolder.qty;
          this.formData.returnconditionid = vm.assetassignmentHolder.returnconditionid;
          this.formData.assetcondition = vm.assetassignmentHolder.assetcondition;
          this.formData.assetconditiondesc = vm.assetassignmentHolder.assetconditiondesc;
          this.formData.assigncasestatusid = vm.assetassignmentHolder.assigncasestatusid;
          this.formData.assigncasestatus = vm.assetassignmentHolder.assigncasestatus;
          this.formData.remark = vm.assetassignmentHolder.remark;
          this.formData.stamp = vm.assetassignmentHolder.stamp;
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadAssetAssignmentDetailHandler();
  },
};
</script>