<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";

import WorkflowMaintenanceForm from "../../../components/settings/workflowmaintenance/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": WorkflowMaintenanceForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recworkflowmaintenance.editworkflowmaintenancelist'),
      pageTitleValue: "",
      formData: [],
      wftrandinfo: [],
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadWorkflow() {
      const vm = this;

      this.$store.dispatch('get', {
          url: `/workflowmaintenancenew/${this.id}`
        })
        .then((res) => {
          if (res.data.rec[0].recdate) {
            res.data.rec[0].recdate = moment(res.data.rec[0].recdate).format(
              "YYYY-MM-DD"
            );
          }

          vm.formData = res.data.rec[0];
          this.loadWorkflowDetail();

          // this.loadWorkflowDetailInfo()
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadWorkflowDetail() {
      const vm = this;

      this.$store.dispatch('get', {
          url: `/workflowmaintenancedetail/${this.formData.id}/${this.formData.wftranid}`
        })
        .then((res) => {

          vm.formData.wftrand = res.data.rec;

          console.log('wftrand', vm.formData.wftrand)

          // console.log('wftrand', vm.formData.wftrand)

          for (let i = 0; i < res.data.rec.length; i++) {
            if (res.data.rec[i].wftranddate) {
              res.data.rec[i].wftranddate = moment(res.data.rec[i].wftranddate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }

            if (res.data.rec[i].aprdate) {
              res.data.rec[i].aprdate = moment(res.data.rec[i].aprdate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
          }

        //  this.loadWorkflowDetailInfo();

          

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //   loadWorkflowDetailInfo() {
    //   const vm = this;

    //   this.$store.dispatch('get', {
    //       url: `/workflowmaintenancedetailinfo/${this.formData.wftranid}`
    //     })
    //     .then((res) => {

    //       vm.formData.wftrandinfo = res.data.rec;

    //       console.log('wftrandinfo', vm.formData.wftrandinfo)

    //       for (let i = 0; i < res.data.rec.length; i++) {
    //         if (res.data.rec[i].wftranddate) {
    //           res.data.rec[i].wftranddate = moment(res.data.rec[i].wftranddate).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           );
    //         }

    //         if (res.data.rec[i].aprdate) {
    //           res.data.rec[i].aprdate = moment(res.data.rec[i].aprdate).format(
    //             "YYYY-MM-DD HH:mm:ss"
    //           );
    //         }
    //       }
    //       vm.loading = false;
    //       this.loadWorkflowDetail();
    //     })
    //     .catch((err) => {
    //       vm.isErr = !vm.isErr;
    //       vm.errMsg = err;
    //     });
    // },


    saveHandler() {
      const vm = this;
      //console.log('formdata', this.formData)
      //console.log('abc')
      this.$store.dispatch('put', {
        url: `/workflowmaintenance/${this.formData.id}`,
        payload: vm.formData
      })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workflowmaintenancelist" , params: { search: this.search}});
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadWorkflow();
  },
};
</script>