<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                    outlined
                    :label="this.$t('vdatatable.rentastranscode')"
                    type="text"
                    v-model="formData.rentastranscode"
                    maxlength='20'
                    dense
                ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="exportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";
import XLSX from 'xlsx/xlsx';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
      },
      pageTitle: 'RHB-01',

      parameter: this.parentObject.parameter,
      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        rentastranscode: '',
        othpaydet: ''
      },
      fileHolder: null,
      file: null,

      paymentModeList: ['FT', 'IBG'],
      bank: [
        {
          id: 1,
          bankcode: "PHBMMYKL",
          bankdesc: "Affin Bank",
          bankgencode: 1
        },
        {
          id: 2,
          bankcode: "HDSBMY2P",
          bankdesc: "Affin Hwang Investment Bank Berhad",
          bankgencode: 74
        },
        {
          id: 3,
          bankcode: "AIBBMYKL",
          bankdesc: "Affin Islamic Bank",
          bankgencode: 75
        },
        {
          id: 4,
          bankcode: "AGOBMYKL",
          bankdesc: "Agrobank",
          bankgencode: 2,
        },
        {
          id: 5,
          bankcode: "AGOBMY21",
          bankdesc: "Agrobank - SPI",
          bankgencode: 101
        },
        {
          id: 6,
          bankcode: "MFBBMYKL",
          bankdesc: "Alliance Bank",
          bankgencode: 4
        },
        {
          id: 7,
          bankcode: "MBAMMYKL",
          bankdesc: "Alliance Investment Bank Bhd",
          bankgencode: 76
        },
        {
          id: 8,
          bankcode: "MBAMMY21",
          bankdesc: "Alliance Investment Bank Bhd - SPI",
          bankgencode: 94
        },
        {
          id: 9,
          bankcode: "ALSRMYKL",
          bankdesc: "Alliance Islamic Bank",
          bankgencode: 95
        },
        {
          id: 10,
          bankcode: "RJHIMYKL",
          bankdesc: "Al-Rajhi Banking & Inv.Corp.(M) Bhd",
          bankgencode: 3
        },
        {
          id: 11,
          bankcode: "ARBKMYKL",
          bankdesc: "AmBank Berhad",
          bankgencode: 5
        },
        {
          id: 12,
          bankcode: "AMMBMYKL",
          bankdesc: "AmInvestment Bank Bhd",
          bankgencode: 96
        },
        {
          id: 13,
          bankcode: "AMMBMY21",
          bankdesc: "AmInvestment Bank Bhd - SPI",
          bankgencode: 49
        },
        {
          id: 14,
          bankcode: "AISLMYKL",
          bankdesc: "AmIslamic Bank",
          bankgencode: 48
        },
        {
          id: 15,
          bankcode: "AFBQMYKL",
          bankdesc: "Asian Finance Bank",
          bankgencode: 37
        },
        {
          id: 16,
          bankcode: "BKKBMYKL",
          bankdesc: "Bangkok Bank Berhad",
          bankgencode: 6
        },
        {
          id: 17,
          bankcode: "BIMBMYKL",
          bankdesc: "Bank Islam Malaysia Berhad",
          bankgencode: 7
        },
        {
          id: 18,
          bankcode: "BMMBMYKL",
          bankdesc: "Bank Muamalat Malaysia Berhad",
          bankgencode: 8
        },
        {
          id: 19,
          bankcode: "BNMAMYKL",
          bankdesc: "Bank Negara Malaysia",
          bankgencode: 50
        },
        {
          id: 20,
          bankcode: "BOFAMY2X",
          bankdesc: "Bank of America",
          bankgencode: 9
        },
        {
          id: 21,
          bankcode: "BKCHMYKL",
          bankdesc: "Bank of China",
          bankgencode: 10
        },
        {
          id: 22,
          bankcode: "BOTKMYKX",
          bankdesc: "Bank of Tokyo-Mitsubishi UFJ",
          bankgencode: 11
        },
        {
          id: 23,
          bankcode: "BOTKMY21",
          bankdesc: "Bank of Tokyo-Mitsubishi UFJ - SPI",
          bankgencode: 83
        },
        {
          id: 24,
          bankcode: "PEMBMYK1",
          bankdesc: "Bank Pembangunan",
          bankgencode: 51
        },
        {
          id: 25,
          bankcode: "PEMBMY21",
          bankdesc: "Bank Pembangunan - SPI",
          bankgencode: 52
        },
        {
          id: 26,
          bankcode: "BKRMMYKL",
          bankdesc: "Bank Rakyat",
          bankgencode: 12
        },
        {
          id: 27,
          bankcode: "BSNAMYK1",
          bankdesc: "Bank Simpanan Nasional",
          bankgencode: 13
        },
        {
          id: 28,
          bankcode: "BSNAMY21",
          bankdesc: "Bank Simpanan Nasional - SPI",
          bankgencode: 54
        },
        {
          id: 29,
          bankcode: "BNPAMYKL",
          bankdesc: "BNP Paribas",
          bankgencode: 14
        },
        {
          id: 30,
          bankcode: "BNPAMY21",
          bankdesc: "BNP Paribas - SPI",
          bankgencode: 55
        },
        {
          id: 31,
          bankcode: "MCDSMYK1",
          bankdesc: "Bursa Malaysia Depository",
          bankgencode: 56
        },
        {
          id: 32,
          bankcode: "CAGAMYKL",
          bankdesc: "Cagamas",
          bankgencode: 59
        },
        {
          id: 33,
          bankcode: "CAGAMY21",
          bankdesc: "Cagamas - SPI",
          bankgencode: 60
        },
        {
          id: 34,
          bankcode: "CIBBMYKL",
          bankdesc: "CIMB Bank",
          bankgencode: 16
        },
        {
          id: 35,
          bankcode: "COIMMYKL",
          bankdesc: "CIMB Investment Bank",
          bankgencode: 61
        },
        {
          id: 36,
          bankcode: "COIMMY21",
          bankdesc: "CIMB Investment Bank - SPI",
          bankgencode: 62
        },
        {
          id: 37,
          bankcode: "CTBBMYKL",
          bankdesc: "CIMB Islamic Bank",
          bankgencode: 63
        },
        {
          id: 38,
          bankcode: "CITIMYKL",
          bankdesc: "Citibank",
          bankgencode: 17
        },
        {
          id: 39,
          bankcode: "CITIMYM1",
          bankdesc: "Citibank - SPI",
          bankgencode: 64
        },
        {
          id: 40,
          bankcode: "DEUTMYKL",
          bankdesc: "Deutsche Bank",
          bankgencode: 18
        },
        {
          id: 41,
          bankcode: "DEUTMY21",
          bankdesc: "Deutsche Bank - SPI",
          bankgencode: 65
        },
        {
          id: 42,
          bankcode: "MGTCBEBE",
          bankdesc: "Euroclear",
          bankgencode: 66
        },
        {
          id: 43,
          bankcode: "EXMBMYKL",
          bankdesc: "EXIM Bank",
          bankgencode: 67
        },
        {
          id: 44,
          bankcode: "EXMBMY21",
          bankdesc: "EXIM Bank - SPI",
          bankgencode: 68
        },
        {
          id: 45,
          bankcode: "HLBBMYKL",
          bankdesc: "Hong Leong Bank",
          bankgencode: 20
        },
        {
          id: 46,
          bankcode: "HLIVMYKL",
          bankdesc: "Hong Leong Investment Bank Bhd",
          bankgencode: 69
        },
        {
          id: 47,
          bankcode: "HLIBMYKL",
          bankdesc: "Hong Leong Islamic Bank",
          bankgencode: 70
        },
        {
          id: 48,
          bankcode: "HMABMYKL",
          bankdesc: "HSBC Amanah",
          bankgencode: 71
        },
        {
          id: 49,
          bankcode: "HBMBMYKL",
          bankdesc: "HSBC Bank",
          bankgencode: 21
        },
        {
          id: 50,
          bankcode: "IIMBMYKL",
          bankdesc: "India International Bank(M) Berhad",
          bankgencode: 22
        },
        {
          id: 51,
          bankcode: "ICBKMYKL",
          bankdesc: "Industrial & Commercial Bank of China",
          bankgencode: 23
        },
        {
          id: 52,
          bankcode: "CHASMYKX",
          bankdesc: "JP Morgan Chase Bank",
          bankgencode: 24
        },
        {
          id: 53,
          bankcode: "KAFBMYKL",
          bankdesc: "KAF Investment",
          bankgencode: 72
        },
        {
          id: 54,
          bankcode: "KAFBMY21",
          bankdesc: "KAF Investment - SPI",
          bankgencode: 73
        },
        {
          id: 55,
          bankcode: "KKENMYK1",
          bankdesc: "Kenanga Investment",
          bankgencode: 97
        },
        {
          id: 56,
          bankcode: "KKENMY21",
          bankdesc: "Kenanga Investment - SPI",
          bankgencode: 98
        },
        {
          id: 57,
          bankcode: "KWAPMYK1",
          bankdesc: "Kumpulan Wang Persaraan Diperbadankan(KWAP)",
          bankgencode: 99
        },
        {
          id: 58,
          bankcode: "KWSPMYK1",
          bankdesc: "Kumpulan Wang Simpanan Pekerja(KWSP)",
          bankgencode: 77
        },
        {
          id: 59,
          bankcode: "KFHOMYKL",
          bankdesc: "Kuwait Finance House",
          bankgencode: 25
        },
        {
          id: 60,
          bankcode: "MBBEMYKL",
          bankdesc: "Malayan Banking Berhad",
          bankgencode: 26
        },
        {
          id: 61,
          bankcode: "MBEAMYKL",
          bankdesc: "Maybank Investment Bank",
          bankgencode: 78
        },
        {
          id: 62,
          bankcode: "MBEAMY21",
          bankdesc: "Maybank Investment Bank - SPI",
          bankgencode: 79
        },
        {
          id: 63,
          bankcode: "MBISMYKL",
          bankdesc: "Maybank Islamic Bank",
          bankgencode: 80
        },
        {
          id: 64,
          bankcode: "MEPSMYK1",
          bankdesc: "MEPS",
          bankgencode: 85
        },
        {
          id: 65,
          bankcode: "MIDFMYK1",
          bankdesc: "MIDF Amanah Investment",
          bankgencode: 81
        },
        {
          id: 66,
          bankcode: "MIDFMY21",
          bankdesc: "MIDF Amanah Investment - SPI",
          bankgencode: 82
        },
        {
          id: 67,
          bankcode: "MHCBMYKA",
          bankdesc: "Mizuho Bank(M) Berhad",
          bankgencode: 27
        },
        {
          id: 68,
          bankcode: "NBADMYKL",
          bankdesc: "National Bank of Abu Dhabi",
          bankgencode: 100
        },
        {
          id: 69,
          bankcode: "OABBMYKL",
          bankdesc: "OCBC Al-Amin Bank Berhad",
          bankgencode: 84
        },
        {
          id: 70,
          bankcode: "OCBCMYKL",
          bankdesc: "OCBC Bank",
          bankgencode: 29
        },
        {
          id: 71,
          bankcode: "PBBEMYKL",
          bankdesc: "Public Bank",
          bankgencode: 30
        },
        {
          id: 72,
          bankcode: "SMBBMYK1",
          bankdesc: "Public Investment Bank Bhd",
          bankgencode: 86
        },
        {
          id: 73,
          bankcode: "PIBEMYK1",
          bankdesc: "Public Islamic Bank Berhad",
          bankgencode: 87
        },
        {
          id: 74,
          bankcode: "RHBBMYKL",
          bankdesc: "RHB Bank",
          bankgencode: 31
        },
        {
          id: 75,
          bankcode: "OSKIMYKL",
          bankdesc: "RHB Investment Bank",
          bankgencode: 88
        },
        {
          id: 76,
          bankcode: "RHBAMYKL",
          bankdesc: "RHB Islamic Bank",
          bankgencode: 89
        },
        {
          id: 77,
          bankcode: "MSMEMYK1",
          bankdesc: "SME Bank",
          bankgencode: 90
        },
        {
          id: 78,
          bankcode: "MSMEMY21",
          bankdesc: "SME Bank - SPI",
          bankgencode: 91
        },
        {
          id: 79,
          bankcode: "SCBLMYKX",
          bankdesc: "Standard Chartered Bank Berhad",
          bankgencode: 32
        },
        {
          id: 80,
          bankcode: "SCSRMYKK",
          bankdesc: "Standard Chartered Saadiq Bank Berhad",
          bankgencode: 92
        },
        {
          id: 81,
          bankcode: "SMBCMYKL",
          bankdesc: "Sumitomo Mitsui Bank",
          bankgencode: 33
        },
        {
          id: 82,
          bankcode: "NOSCMYKL",
          bankdesc: "The Bank of Nova Scotia",
          bankgencode: 34
        },
        {
          id: 83,
          bankcode: "ABNAMYKL",
          bankdesc: "The Royal Bank of Scotland",
          bankgencode: 35
        },
        {
          id: 84,
          bankcode: "UOVBMYKL",
          bankdesc: "United Overseas Bank",
          bankgencode: 36
        }
      ],

      staffilterlist: [],
      staffaccount: [],
      companyaccount: [],
      psnpayroll: [],
      titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
      payrolldata: [],

      companyrecnum: "",
      paymentmode: null,
      bankcode: null,
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],

      currency: null,
      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {

    getHLBExportListHandler() {
      const vm = this
      
      this.$store.dispatch('get', {url: `/rhbsalexport/${vm.data.salexprecdetail[0].payoutctrlid}/${this.$store.getters.userId}`})
      .then(res => {
        let dateHolder = vm.data.year + "-" + 
                         vm.padRJ(vm.data.month, 2) + "-" + 
                         vm.daysInMonth(vm.data.month, vm.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        
        let payrolldataholder = res.data.rec;

        payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)
        payrolldataholder.forEach(function (o) {
          var existing = vm.payrolldata.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            if (o.bankid) {
              if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                    vm.payrolldata.push(o);
              }
            } else {
              vm.payrolldata.push(o);
            }
          }
        });
        this.loadStaffFilter()
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    loadStaffFilter() {
      const vm = this;

      this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
        .then(res => {
            vm.staffilterlist = res.data.rec;
            let checkval = false;
            for (const key in this.parameter) {
              if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            }

            if (checkval) {
              vm.payrolldata = vm.payrolldata.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }

        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    async getData() {
      const vm = this
      let count = 0
      let body = []
      let holdpayment = []
      let dataholder = vm.payrolldata
      let payrolldetail = this.filterAcc(dataholder);
      this.successCount = payrolldetail.length;

      this.errCount = this.processedCount - payrolldetail.length;
      this.currency = payrolldetail[0].exchratecode;

      payrolldetail.forEach(element => {
        // let icno = ''
        // let oldic = ''
        // let passport = ''
        let idno = ''
        let rectype = 0
        let benname = ''
        let bankholder = null
        let bopindicator = 0
        let idindicator = ''
        count++
        
        if (element.idtypeid == 1 && element.iddetails != null ) {
            idno = element.iddetails.split("-").join("")
            // const firstDigitStr = String(idno)[0];
            // if (firstDigitStr == '0') idno = "'" + idno
            bopindicator = 1
            idindicator = '01'
        }
        if (element.idtypeid == 2 && element.iddetails != null) {
            idno = parseInt(element.iddetails.split("-").join(""))
            bopindicator = 1
            idindicator = '02'
        }
        if (element.idtypeid == 2 && element.iddetails != null) {
            idno = parseInt(element.iddetails.split("-").join(""))
            bopindicator = 2
            idindicator = '03'
        }

        if (element.accountname) benname = element.accountname.slice(0, 40);

        if (element.bankid == 31) {
            rectype = 10
        } else {
            if (element.bankid == 6 || element.bankid == 15 || element.bankid == 19 || 
                element.bankid == 22 || element.bankid == 28 || element.bankid == 34 || 
                element.bankid == 37 ) {
                rectype = 20
            } else {
                rectype = 30
            }
        }

        bankholder = vm.bank.filter(el => el.bankgencode == element.bankid)[0]
        
        body.push({
          "Record Type": rectype,
          "Transact2ion Record Number": count,
          "Crediting Amount": element.netpay,
          "Receiving Member Bank": rectype == 10 ? '' : bankholder.bankcode,
          "RENTAS Transaction Code": vm.formData.rentastranscode,
          "BOP-Indicator": bopindicator,
          "Beneficiary Name": benname,
          "Beneficiary Account Number": element.staffaccountno,
          "I/D Number": idno,
          "Address1": '',
          "Address2": '',
          "Address3": '',
          "ID Indicator": idindicator,
        });

        holdpayment.push(element.netpay)
      });

      if (holdpayment) {
        let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
        this.gettotalAmt = totalAmt.toFixed(2);
      }

      if (body.length > 0) {
        // const csvData = this.objectToCSV(body);
        // this.download(csvData, this.titleData);
        const ws = XLSX.utils.json_to_sheet(body, {skipHeader: true});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.titleData);
        XLSX.writeFile(wb, this.titleData + '.xlsx');
      }
    },

    filterAcc(item) {
      let checkacc = this.accfilter(item)
      let checkaccbank = this.accBankfilter(checkacc)
      let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
      let checkactive = this.activefilter(checkapaymentmethod)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    accfilter(item) {
      let data = item.filter(e => e.bankid)
      let noacc = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noacc.length; i++) {
        noacc[i].errormsg = this.$t('allbank.noaccfound');
        this.accErr.push(noacc[i]);
      }
      return data
    },

    accBankfilter(item) {
      let vm = this
      let data = item.filter(e => {
        for (let i = 0; i < this.bank.length; i++) {
          if (this.bank[i].id == e.bankid) return e
        }
      })
      let noaccbank = item.filter((e) => !data.includes(e));
      this.processedCount = vm.payrolldata.length - noaccbank.length;
      
      // for (let i = 0; i < noaccbank.length; i++) {
      //   noaccbank[i].errormsg = "The bank account is not in the list of bank for transfer";
      //   this.accErr.push(noaccbank[i]);
      // }
      return data
    },

    paymentmethodfilter(item) {
      let data = item.filter(e => e.paymodecode == 'BANK')
      let diffpayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < diffpayment.length; i++) {
        diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
        this.accErr.push(diffpayment[i]);
      }
      return data
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => e.netpay > 0)
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToCSV(data) {
        const csvRows = []

        // get header
        const headers = Object.keys(data[0])
        //csvRows.push(headers.join(','))

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }
        return csvRows.join('\n')
    },

    download(data, title) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmt = this.gettotalAmt;
        item.accounterr = this.accErr;
        item.negErrMss = this.negErrMsg;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        item.currency = this.currency;
        this.$router.push({ name: "salaryexportlog", params: { item } });
      }
    },

    backHandler() {
      let param = this.parameter

      this.$router.push({ name: "salaryexport", params: { param } });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    this.getHLBExportListHandler();
  },
};
</script>