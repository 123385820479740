<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 pt-6"
                >


                 <v-row>
                      <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('columns.name')"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="formData.staffid"
                        dense
                        :items="staffDetailsList"
                        @input="updateValue"
                        @change="changeStaffIDandCompanyHandler(formData.staffid)"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>

                    <!-- <v-row v-else>
                    <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                            <v-autocomplete
                            outlined
                            label="Name"
                            type="text"
                            ref="psnid"
                            :rules="[rules.required]"
                            v-model="formData.staffid"
                            dense
                            :items="staffDetailsList"
                            readonly
                            background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row> -->

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="4" sm="10" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.trainingcoursename')"
                                type="text"
                                ref="trncoursename"
                                :rules="[rules.required]"
                                v-model="formData.trncoursename"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingrecord.trainingcoursecategory')"
                                type="text"
                                ref="trncoursecatid"
                                :items="trainingcoursecatList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.trncoursecatid"
                                @input="updateValue"
                                @change="changeTrainingCourseCatHandler(formData.trncoursecatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="trncoursecatcode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="trainingcoursecatDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform  
                                        v-bind:parentData = "{
                                            tabItems: trainingcoursecatItems,
                                            formTitle: this.$t('trainingrecord.trainingcoursecategory'),
                                            formId: 'trainingcoursecat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveTrainingCourseCat="saveTrainingCourseCatHandler"
                                        v-on:deleteTrainingCourseCat="deleteTrainingCourseCatHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="trainingcoursecatDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingrecord.trainingcourseprovider')"
                                type="text"
                                ref="trnproviderid"
                                :items="trainingproviderList"
                                hide-selected
                                v-model="formData.trnproviderid"
                                @input="updateValue"
                                @change="changeTrainingProviderHandler(formData.trnproviderid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="trnprovidercode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="trainingproviderDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: trainingproviderItems,
                                            formTitle: this.$t('trainingrecord.trainingprovider'),
                                            formId: 'trainingprovider'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveTrainingProvider="saveTrainingProviderHandler"
                                        v-on:deleteTrainingProvider="deleteTrainingProviderHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="trainingproviderDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.recertificationdate')"
                                type="date"
                                ref="recertification"
                                v-model="formData.recertification"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.traininghours')"
                                type="text"
                                ref="trnhours"
                            
                                v-model="formData.trnhours"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                          <v-col cols="2" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.venue')"
                                type="text"
                                ref="venue"
                               
                                v-model="formData.venue"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row>
                        <v-col cols="1" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="Iselearning"
                                v-model="formData.Iselearning"
                                :label="this.$t('trainingrecord.elearning')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>

                         <v-col cols="2" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.assessmentresult')"
                                type="text"
                                ref="result"
                               
                                v-model="formData.result"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                       

                      
                    <v-col cols="4" sm="7" class="pb-n5 mt-n8">
                    <div class="text-center">
                        <v-file-input
                        ref="materialdoc"
                        :label="this.$t('trainingrecord.trainingmaterialsupportingdocument')"
                        outlined
                        dense
                        v-model="fileHolder"
                        @change="fileToBase64"
                         @input="updateValue"
                        
                        ></v-file-input>
                    </div>
                    </v-col>
                       </v-row>


                     <div v-if="this.formData.materialdoc">
                        <v-row >
                       
                           <!-- <v-col cols="1" sm="2" class="pb-n4 mt-n8"> </v-col> -->
                         
                            <v-col cols="2" sm="5" class="pb-8 mt-n5">
                            </v-col>
                             
                            <v-col cols="4" sm="7" class="pb-8 mt-n5">
                            <p>
                            <a
                                v-bind:href="this.formData.materialdoc"
                                v-bind:download="this.formData.materialdocname"
                               
                                >{{formData.materialdocname}}</a
                            >
                            <v-icon
                                small
                                class="ml-3 mr-2"
                                @click="deleteAttachmentHandler"
                            >
                                mdi-delete
                            </v-icon>
                            </p>
                          </v-col>
                            </v-row>
                    </div>
                     
                 
                       <v-row>
                             <v-col cols="1" sm="2" class="pb-n5 mt-n8">
                             </v-col>
                            <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                            <!-- <v-text-field
                                outlined
                                label="Total Expenses"
                                type="text"
                                ref="traininghours"
                                :rules="[rules.required]"
                                v-model="formData.traininghours"
                                @input="updateValue"
                                dense
                            ></v-text-field> -->
                        </v-col>

                <v-col cols="4" sm="7" class="pb-n5 mt-n8">
                    <div class="text-center">
                        <v-file-input
                        ref="certificatedoc"
                        :label="this.$t('trainingrecord.trainingcertificate')"
                        outlined
                        v-model="fileHolder2"
                        @change="fileTo2Base64"
                        dense
                         @input="updateValue"
                        ></v-file-input>
                    </div>
                    </v-col>
                       </v-row>


                    <div v-if="this.formData.certificatedoc">
                        <v-row>
                              <v-col cols="1" sm="2" class="pb-8 mt-n5">
                             </v-col>
                            <v-col cols="4" sm="3" class="pb-8 mt-n5"></v-col>
                        <v-col cols="4" sm="6" class="pb-8 mt-n5">
                            <p>
                            <a
                                v-bind:href="this.formData.certificatedoc"
                                v-bind:download="this.formData.certificatedocname"
                                >{{formData.certificatedocname}}</a
                            >
                            <v-icon
                                small
                                class="ml-3 mr-2"
                                @click="deleteAttachmentHandler2"
                            >
                                mdi-delete
                            </v-icon>
                            </p>
                        </v-col>
                        </v-row>
                    </div>
            

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('trainingrecord.trainingexpenses')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.trainingrecorddetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>

                     <v-row>
                        <v-col cols="6" sm="6" class="blue--text pb-n5 mt-n8">
                            {{$t('trainingrecord.hrdfclaiminfo')}}
                        </v-col>
                    </v-row>

                    <br>
                    <br>

                    <v-row>
                          <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.amountclaimed')"
                                type="text"
                                ref="hrdfamtclaimed"
                               
                                v-model="formData.hrdfamtclaimed"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="hrdfamtclaimedcurrencyid"
                              
                                :items="currencyList"
                                hide-selected
                                v-model="formData.hrdfamtclaimedcurrencyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.hrdfamtclaimedcurrencyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.submittedon')"
                                type="date"
                                ref="hrdfclaimeddate"
                             
                                v-model="formData.hrdfclaimeddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.amountapproved')"
                                type="text"
                                ref="hrdfamtapproved"
                             
                                v-model="formData.hrdfamtapproved"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="hrdfamtapprovedcurrencyid"
                            
                                :items="approvedcurrencyList"
                                hide-selected
                                v-model="formData.hrdfamtapprovedcurrencyid"
                                @input="updateValue"
                                @change="changeApprovedCurrencyHandler(formData.hrdfamtapprovedcurrencyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingrecord.approvedon')"
                                type="date"
                                ref="hrdfapproveddate"
                             
                                v-model="formData.hrdfapproveddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        
                    </v-row>

                      <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingrecord.traininghrdfscheme')"
                                type="text"
                                ref="trnhrdfschemeid"
                                :items="traininghrdfschemeList"
                                hide-selected
                                v-model="formData.trnhrdfschemeid"
                                @input="updateValue"
                                @change="changeHRDFSchemeHandler(formData.trnhrdfschemeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="hrdfscheme"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="traininghrdfschemeDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: traininghrdfschemeItems,
                                            formTitle: this.$t('trainingrecord.traininghrdfscheme'),
                                            formId: 'traininghrdfscheme'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveHRDFScheme="saveHRDFSchemeHandler"
                                        v-on:deleteHRDFScheme="deleteHRDFSchemeHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="traininghrdfschemeDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />
            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-genericform': GenericForm,

    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                    }
            },

            formData: {
                psnid:this.parentObject.data.psnid,
                staffid:this.parentObject.data.staffid,
                staffcode:this.parentObject.data.staffcode,
                compnyid:this.parentObject.data.compnyid,
                compnyname:this.parentObject.data.compnyname,
                trncoursename:this.parentObject.data.trncoursename,
                trncoursecatid:this.parentObject.data.trncoursecatid,
                trnproviderid:this.parentObject.data.trnproviderid,
                startdate:this.parentObject.data.startdate,
                enddate:this.parentObject.data.enddate,
                recertification:this.parentObject.data.recertification,
                trnhours:this.parentObject.data.trnhours,
                venue:this.parentObject.data.venue,
                Iselearning:this.parentObject.data.Iselearning,
                result:this.parentObject.data.result,
                materialdoc:this.parentObject.data.materialdoc,
                materialdocname:this.parentObject.data.materialdocname,
                certificatedoc:this.parentObject.data.certificatedoc,
                certificatedocname:this.parentObject.data.certificatedocname,
                trnhrdfchemeid:this.parentObject.data.trnhrdfchemeid,
                hrdfamtclaimed:this.parentObject.data.hrdfamtclaimed,
                hrdfamtclaimedcurrencyid:this.parentObject.data.hrdfamtclaimedcurrencyid,
                hrdfclaimeddate:this.parentObject.data.hrdfclaimeddate,
                hrdfamtapproved:this.parentObject.data.hrdfamtapproved,
                hrdfamtapprovedcurrencyid:this.parentObject.data.hrdfamtapprovedcurrencyid,
                hrdfapproveddate: this.parentObject.data.hrdfapproveddate,
                
                trnhrdfschemeid:this.parentObject.data.trnhrdfschemeid,
                hrdfschemename:this.parentObject.data.hrdfschemename,
                hrdfscheme: this.parentObject.data.hrdfscheme,
                trainingrecorddetail: this.parentObject.data.trainingrecorddetail,
                search: this.parentObject.data.search,
                lockstaff:         this.parentObject.data.lockstaff,
                stamp:             this.parentObject.data.stamp,
                userid:            this.parentObject.data.userid
            },


            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            staffdetails: [],
            staffDetailsList: [],

            chargeItems: [],
            chargeList: [],
            chargecodes: [],

            hrdfamtclaimedcrcycode: '',
            currencyList: [],
            currencies: [],

            approvedcurrencies: [],
            hrdfamtapprovedcrcycode: '',
            approvedcurrencyList: [],


            companies: [],
            companiesitemsList: [],

            trncoursecatcode: '',
            trainingcoursecatList: [],
            trainingcoursecat: [],
            trainingcoursecatItems: [],
            trainingcoursecatDialog: false,
            trainingcoursecatLoading: true,

            trnprovidercode: '',
            trainingproviderList: [],
            trainingprovider: [],
            trainingproviderItems: [],
            trainingproviderDialog: false,
            trainingproviderLoading: true,


            hrdfscheme: '',
            traininghrdfschemeList: [],
            traininghrdfscheme: [],
            traininghrdfschemeItems: [],
            traininghrdfschemeDialog: false,
            traininghrdfschemeLoading: true,

             fileHolder: null,
             fileHolder2: null,


            singleSelect: false,
            dataItems: [],
        
            tableOpt: {
                itemsPerPage: -1
            },


            selected: [],
            affectItems: [],

            tabHeader: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('trainingrecord.expensetype'),      value: 'trnexpensename',          width: '200px', sortable: true},
                { text: this.$t('trainingrecord.amount'),            value: 'amount',          width: '200px', sortable: true},
                { text: this.$t('columns.currency'),          value: 'crcydesc',          width: '200px', sortable: true},
                { text: this.$t('trainingrecord.trainingchargecode'),          value: 'chargecodename',          width: '200px', sortable: true},
                { text: this.$t('columns.remark'),            value: 'remark',          width: '200px', sortable: true},
              
            ],

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {
         loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

            
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.hrdfamtclaimedcurrencyid) {
                        vm.changeCurrencyHandler(vm.formData.hrdfamtclaimedcurrencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.hrdfamtclaimedcrcycode = item.crcycode;
        },

        loadApprovedCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.approvedcurrencies = res.data.rec;



                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.approvedcurrencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.approvedcurrencyLoading = false;

                    if (vm.formData.hrdfamtapprovedcurrencyid) {
                        vm.changeApprovedCurrencyHandler(vm.formData.hrdfamtapprovedcurrencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeApprovedCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.hrdfamtapprovedcrcycode = item.crcycode;
        },


         //load companies list
        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url:"/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
        },

        addItemHandler($event) {
            this.formData.trainingrecorddetail=$event
        

            this.updateValue();
        },

            fileToBase64() {
            if (this.fileHolder) {
                var file = this.$refs.materialdoc.internalValue;
                this.formData.materialdocname = this.fileHolder.name;

                var reader = new FileReader();

                reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.materialdoc = b64;
                };

                reader.readAsDataURL(file);
            }
        },

         deleteAttachmentHandler() {
            this.formData.materialdoc = ''
            this.formData.materialdocname = ''
        },

           fileTo2Base64() {
            if (this.fileHolder2) {
                var file = this.$refs.certificatedoc.internalValue;
                this.formData.certificatedocname = this.fileHolder2.name;

                var reader = new FileReader();

                reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.certificatedoc = b64;
                };

                reader.readAsDataURL(file);
            }
        },

         deleteAttachmentHandler2() {
            this.formData.certificatedoc = ''
            this.formData.certificatedocname = ''
        },


          loadTrainingCourseCatHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/trainingcoursecat/all'})
                .then(res => {
                    vm.trainingcoursecat = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.trainingcoursecatList.push(
                            {
                                text: res.data.rec[i].coursecatname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.trainingcoursecatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].coursecatcode,
                                desc: res.data.rec[i].coursecatname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.trainingcoursecatLoading = false;

                    if (vm.formData.trncoursecatid) {
                        vm.changeTrainingCourseCatHandler(vm.formData.trncoursecatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeTrainingCourseCatHandler(id) {
            let item = this.trainingcoursecat.find(item => item.id === id);

            this.trncoursecatcode = item.coursecatcode;
        },

          saveTrainingCourseCatHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/trainingcoursecat/' + this.itemData.itemid, payload: {
                coursecatcode: this.itemData.itemcode,
                coursecatname: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trainingcoursecat = [];
                        vm.trainingcoursecatItems = [];
                        vm.trainingcoursecatList = [];

                        this.loadTrainingCourseCatHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/trainingcoursecat/create', payload: {
                coursecatcode: this.itemData.itemcode,
                coursecatname: this.itemData.itemdesc,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trainingcoursecat = [];
                        vm.trainingcoursecatItems = [];
                        vm.trainingcoursecatList = [];

                        this.loadTrainingCourseCatHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteTrainingCourseCatHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/trainingcoursecat/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.trainingcoursecat = [];
                        vm.trainingcoursecatItems = [];
                        vm.trainingcoursecatList = [];

                        this.loadTrainingCourseCatHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genTrainingCourseCatTabItems() {
            for (let i = 0; i < this.trainingcoursecat.length; i++) {
                this.trainingcoursecatItems.push(
                    {
                        id: this.trainingcoursecat[i].id,
                        code: this.trainingcoursecat[i].coursecatcode,
                        desc: this.trainingcoursecat[i].coursecatname,
                        stamp: this.trainingcoursecat[i].stamp
                    }
                )
            }
        },

          loadTrainingProviderListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/trainingprovider/all'})
                .then(res => {
                    vm.trainingprovider = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.trainingproviderList.push(
                            {
                                text: res.data.rec[i].providername,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.trainingproviderItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].providercode,
                                desc: res.data.rec[i].providername,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.trainingproviderLoading = false;

                    if (vm.formData.trnproviderid) {
                        vm.changeTrainingProviderHandler(vm.formData.trnproviderid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeTrainingProviderHandler(id) {
            let item = this.trainingprovider.find(item => item.id === id);

            this.trnprovidercode = item.providercode;
        },

          saveTrainingProviderHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/trainingprovider/' + this.itemData.itemid, payload: {
                providercode: this.itemData.itemcode,
                providername: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trainingprovider = [];
                        vm.trainingproviderItems = [];
                        vm.trainingproviderList = [];

                        this.loadTrainingProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/trainingprovider/create', payload: {
                providercode: this.itemData.itemcode,
                providername: this.itemData.itemdesc,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.trainingprovider = [];
                        vm.trainingproviderItems = [];
                        vm.trainingproviderList = [];

                        this.loadTrainingProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteTrainingProviderHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/trainingprovider/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.trainingprovider = [];
                        vm.trainingproviderList = [];
                        vm.trainingproviderItems = [];

                        this.loadTrainingProviderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genClaimAdjustReasonTabItems() {
            for (let i = 0; i < this.trainingprovider.length; i++) {
                this.trainingproviderItems.push(
                    {
                        id: this.trainingprovider[i].id,
                        code: this.trainingprovider[i].providercode,
                        desc: this.trainingprovider[i].providername,
                        stamp: this.trainingprovider[i].stamp
                    }
                )
            }
        },

        
          loadHRDFSchemeHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/trnhrdfscheme/all'})
                .then(res => {
                    vm.traininghrdfscheme = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.traininghrdfschemeList.push(
                            {
                                text: res.data.rec[i].hrdfschemename,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.traininghrdfschemeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].hrdfscheme,
                                desc: res.data.rec[i].hrdfschemename,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.traininghrdfschemeLoading = false;

                    if (vm.formData.trnhrdfschemeid) {
                        vm.changeHRDFSchemeHandler(vm.formData.trnhrdfschemeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeHRDFSchemeHandler(id) {
            let item = this.traininghrdfscheme.find(item => item.id === id);

            this.hrdfscheme = item.hrdfscheme;
        },

          saveHRDFSchemeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/trnhrdfscheme/' + this.itemData.itemid, payload: {
                hrdfscheme: this.itemData.itemcode,
                hrdfschemename: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.traininghrdfscheme = [];
                        vm.traininghrdfschemeItems = [];
                        vm.traininghrdfschemeList = [];

                        this.loadHRDFSchemeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/trnhrdfscheme/create', payload: {
                hrdfscheme: this.itemData.itemcode,
                hrdfschemename: this.itemData.itemdesc,
                userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                         vm.traininghrdfscheme = [];
                        vm.traininghrdfschemeItems = [];
                        vm.traininghrdfschemeList = [];

                        this.loadHRDFSchemeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteHRDFSchemeHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/trnhrdfscheme/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                       vm.traininghrdfscheme = [];
                        vm.traininghrdfschemeItems = [];
                        vm.traininghrdfschemeList = [];

                        this.loadHRDFSchemeHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genHRDFSchemeTabItems() {
            for (let i = 0; i < this.traininghrdfscheme.length; i++) {
                this.traininghrdfschemeItems.push(
                    {
                        id: this.traininghrdfscheme[i].id,
                        code: this.traininghrdfscheme[i].hrdfscheme,
                        desc: this.traininghrdfscheme[i].hrdfschemename,
                        stamp: this.traininghrdfscheme[i].stamp
                    }
                )
            }
        },



        updateValue() {
          //  console.log('formdata', this.formData)
            this.$emit('input', {

                psnid:this.formData.psnid,
                staffid:this.formData.staffid,
                staffcode:this.formData.staffcode,
                compnyid:this.formData.compnyid,
                compnyname:this.formData.compnyname,
                trncoursename:this.formData.trncoursename,
                trncoursecatid:this.formData.trncoursecatid,
                trnproviderid:this.formData.trnproviderid,
                startdate:this.formData.startdate,
                enddate:this.formData.enddate,
                recertification:this.formData.recertification,
                trnhours:this.formData.trnhours,
                venue:this.formData.venue,
                Iselearning:this.formData.Iselearning,
                result:this.formData.result,
                materialdoc:this.formData.materialdoc,
                materialdocname:this.formData.materialdocname,
                certificatedoc:this.formData.certificatedoc,
                certificatedocname:this.formData.certificatedocname,
                trnhrdfchemeid:this.formData.trnhrdfchemeid,
                hrdfamtclaimed:this.formData.hrdfamtclaimed,
                hrdfamtclaimedcurrencyid:this.formData.hrdfamtclaimedcurrencyid,
                hrdfclaimeddate:this.formData.hrdfclaimeddate,
                hrdfamtapproved:this.formData.hrdfamtapproved,
                hrdfamtapprovedcurrencyid:this.formData.hrdfamtapprovedcurrencyid,
                hrdfapproveddate: this.formData.hrdfapproveddate,
                trnhrdfschemeid:this.formData.trnhrdfschemeid,
                hrdfschemename:this.formData.hrdfschemename,
                hrdfscheme: this.formData.hrdfscheme,
                trainingrecorddetail: this.formData.trainingrecorddetail,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
             this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
             this.updateValue()
            this.$emit('delete');
        },

        // check(){
        //     console.log('claimrecorddetail', this.formData.claimrecorddetail)
        // },

        cancelHandler() {
            this.$router.push({name: 'trainingrecordlist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadCurrencyListHandler();
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
        this.loadTrainingCourseCatHandler();
        this.loadTrainingProviderListHandler();
        this.loadHRDFSchemeHandler();
        this.loadApprovedCurrencyListHandler();

        // this.changeParentClaim();
        // this.LoadClaimCategoryType();

        //  if (this.parentObject.isEdit){
        //    this.duplicateStaffData = this.formData;
            
        //     if (this.parentObject.data.lockstaff == "N") {
        //         this.formData.lockstaff = false;
        //     } else {
        //         this.formData.lockstaff = true;
        //     }
        // }

      //  this.check()

      //  console.log('update', this.updateValue)
    }
}
</script>