<template>
    <div>
        <v-container>
            <v-form>
                <v-row class="px-8">
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
                </v-row>
                <v-row class="mt-n4 mb-4 px-8">
                    <h4 class="text-gray">
                        {{ this.parentObject.pageTitleValue }}
                </h4>
                </v-row>
                
        
                <v-container
                    id="scroll-target"
                    class="custom-form px-5 mb-10"
                >


                    <v-row>
                      <v-col cols="8" class="pb-n5">
                       <v-text-field
                                outlined
                                label="Exercise Code"
                                type="text"
                                ref="ratingscalecode"
                                :rules="[rules.required]"
                                v-model="formData.ratingscalecode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                label="Exercise Description"
                                type="text"
                                ref="ratingscaledesc"
                                :rules="[rules.required]"
                                v-model="formData.ratingscaledesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                label="Period Start Date"
                                type="date"
                                ref="asofdate1"
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                label="Period End Date"
                                type="date"
                                ref="asofdate1"
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Status"
                                type="text"
                                :items="kpisettingstatus"
                                hide-selected
                                v-model="formData.insproviderid"
                                @input="updateValue"
                                @change="changeInsuranceProviderHandler(formData.insproviderid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <div class="pl-n2" v-if="!this.parentObject.isWizard">
                        <v-row>
                            <app-table
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.formData.ratingscalesetdetail
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-row>
                    </div>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-bind:WizardBool = this.parentObject.isWizard

                    v-on:NextPressed="nextHandler"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
       //'app-genericform': GenericForm,
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                ratingscalecode: this.parentObject.data.ratingscalecode,
                ratingscaledesc: this.parentObject.data.ratingscaledesc,
                isdisable: this.parentObject.data.isdisable,
                remarks: this.parentObject.data.remarks,
                ratingscalesetdetail: this.parentObject.data.ratingscalesetdetail,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
      
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew,

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '200px', sortable: false},
                { text: 'Description', value: 'ratingcode', width: '200px', sortable: true},
                { text: 'Goal', value: 'label', width: '200px', sortable: true},
                { text: 'KPI', value: 'label', width: '200px', sortable: true},
                { text: 'Target Option', value: 'label', width: '200px', sortable: true},
                { text: 'Calculcation Method', value: 'label', width: '200px', sortable: true},
                { text: 'Weigthage', value: 'label', width: '200px', sortable: true},
                { text: 'Threshold', value: 'label', width: '200px', sortable: true},
                { text: 'Target', value: 'label', width: '200px', sortable: true},
                { text: 'Stretch', value: 'label', width: '200px', sortable: true},
                { text: 'Company', value: 'label', width: '200px', sortable: true},
                { text: 'Branch', value: 'label', width: '200px', sortable: true},
                { text: 'Divisipn', value: 'label', width: '200px', sortable: true},
                { text: 'Department', value: 'label', width: '200px', sortable: true},
            ],

            kpisettingstatus: [],

        }
    },

    methods: {

        addItemHandler($event) {
            this.formData.ratingscalesetdetail=$event
    
            this.updateValue();
        },


        updateValue() {
            this.$emit('input', {
                ratingscalecode: this.formData.ratingscalecode,
                ratingscaledesc: this.formData.ratingscaledesc,
                isdisable: this.formData.isdisable,
                remarks: this.formData.remarks,
                ratingscalesetdetail: this.formData.ratingscalesetdetail,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        nextHandler() {
            this.$emit('next');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'kpisettinglist', params: { search: this.search}});
        },
    },

    mounted() {
        console.log(this.parentObject);
    }
    
}
</script>

<style scoped>
#form-btn {
    margin-top: 80px;
    margin-bottom: 20px;
}
</style>