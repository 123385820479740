    <template>
    <div>
        <v-dialog v-model="periodicdialog" max-width="800px" @click:outside="closeDetail">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('eadtl.addeaformdtl')}}</span>
              <!--<span class="headline mb-4">Periodic Attendance Incentive Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="8" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.col')"
                            type="text"
                            ref="compnyname"
                            dense
                            :items="eaaddondtlList"
                            v-model="selections.col"
                            :rules="[rules.required]"
                            @change="
                                changeEAaddOnHandler(
                                    selections.col
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections.col"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="10" sm="10" md="10" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.description')"
                            type="text"
                            ref="description"
                            dense
                            v-model="selections.replacement"
                        ></v-text-field>
                    </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDetail">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveDetail">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            periodicdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                colid: null,
                col: null,
                coldesc: null,
                replacement: null,

                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            },

            
            eaaddondtlList:[],
            eaaddondtlItems: [],

            companyList:[],
            companyItems: [],

            branchList: [],
            branchItems: [],

            itemHolder: [],
            detailList: [],
            factor: false,
            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {

        compareArray,
        loadEAaddOnList(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/eadtlcol'
            })
            .then(res => {

                vm.eaaddondtlItems = res.data.rec;

                for (let i = 0; i < vm.eaaddondtlItems.length; i++) {
                    vm.eaaddondtlList.push({
                        text: vm.eaaddondtlItems[i].coldesc,
                        code: vm.eaaddondtlItems[i].col,
                        value: vm.eaaddondtlItems[i].col,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeEAaddOnHandler(id){
            let item = this.eaaddondtlItems.find(item => item.col == id);

            this.selections.colid = item.id;
            this.selections.coldesc = item.coldesc;

        },

        loadCompany(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/company/all'
            })
            .then(res => {

                vm.companyItems = res.data.rec;

                for (let i = 0; i < vm.companyItems.length; i++) {
                    vm.companyList.push({
                        text: vm.companyItems[i].compnyname,
                        code: vm.companyItems[i].compnycode,
                        value: vm.companyItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeCompanyHandler(id){
            let item = this.companyItems.find(item => item.id == id);

            this.selections.compnycode = item.compnycode;
            this.selections.compnyname = item.compnyname;

        },

        loadBranch(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/branch/all'
            })
            .then(res => {

                vm.branchItems = res.data.rec;

                for (let i = 0; i < vm.branchItems.length; i++) {
                    vm.branchList.push({
                        text: vm.branchItems[i].brchname,
                        code: vm.branchItems[i].brchcode,
                        value: vm.branchItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeBranchHandler(id){
            let item = this.branchItems.find(item => item.id == id);

            this.selections.brchcode = item.brchcode;
            this.selections.brchname = item.brchname;

        },

         modifyItemHandler(item) {
          // assign loaded data to selections array
            if (item.action) {
                this.isExisting = true;
            } else {
                this.modify = true;
            }
            
            this.itemHolder = item
            this.selections =  this.itemHolder;
            //this.selections.userid = this.$store.getters.userId
            console.log("selections", this.selections)
            this.periodicdialog = true;
          },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },

        saveDetail() {
                this.isErr = false;
                if (!this.isExisting && !this.modify) {
                    this.selections.id = null;

                    this.detailItem.push(Object.assign({}, this.selections));

                } else {
                    if(!this.modify){
                        this.selections.action = 2;
                    }
                    if (this.compareArray(this.detailItem, this.selections)) {
                        console.log(this.compareArray(this.detailItem, this.selections))
                    }
                    
                }
            // console.log('detailitem', this.detailItem)
                this.$emit('input', this.detailItem);
                this.periodicdialog = false;
                

                // reset the form fields
                this.closeDetail();
        },

        
        closeDetail() {
            this.periodicdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        resetSelections() {
            this.selections = {
                id: null,

                colid: null,
                col: null,
                coldesc: null,
                replacement: null,
                
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }
        },

    },

    created() {
        this.loadEAaddOnList();
        this.loadCompany();
        this.loadBranch();
    }
}
</script>   


       