<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.wizardname')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.wizardcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.wizarddescription')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.wizarddesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="disable"
                                v-model="formData.disable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.wizarddetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';

//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('setupwizardsetup.wizinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                wizarddetail: this.parentObject.data.wizarddetail,
                wizardcode: this.parentObject.data.wizardcode,
                wizarddesc: this.parentObject.data.wizarddesc,
                disable: this.parentObject.data.disable,
                 search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '5px', sortable: false},
                { text: this.$t('setupwizardsetup.number'), value: 'seqno', width: '100px', sortable: false},
                { text: this.$t('setupwizardsetup.taskdesc'), value: 'taskdesc', width: '200px', sortable: false },
                { text: this.$t('setupwizardsetup.taskscreen'), value: 'funcname', width: '200px', sortable: false }
            ],

            errMsg: '',
            isErr: false,
            editMode: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.wizarddetail =$event
        },
            
        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                wizarddetail: this.formData.wizarddetail,
                wizardcode: this.formData.wizardcode,
                wizarddesc: this.formData.wizarddesc,
                disable: this.formData.disable,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'wizardlist',  params: { search: this.search}});
        },

    }
}
</script>