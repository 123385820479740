<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>

import GridView from "../../../components/common/GridView";
export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setuptp1.tp1list'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "150px" },
        { text: this.$t('columns.staffname'), value: "staffname", width: "200px" },
        { text: this.$t('setuptaxablerebate.rebatetype'), value: "rebatetypecode", width: "200px" },
        { text: this.$t('setuptaxablerebategroup.rebategroup'), value: "rebategroupcode", width: "200px" },
        { text: this.$t('setuppersonpayrolldirectassignment.amount'), value: "amount", width: "200px" },
        { text: this.$t('columns.currency'), value: "crcycode", width: "120px" },
        { text: this.$t('reportsparam.year'), value: "dyear", width: "120px" },
        { text: this.$t('reportsparam.month'), value: "dmonth", width: "120px" },
        { text: this.$t('setuptp1.rebatelimit'), value: "rebatelimitamount", width: "200px" },
        { text: this.$t('columns.remark'), value: "remark", width: "200px" },
        //{ text: "ATTACHMENT", value: "attach", width: "200px" },
      ],

      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    //get all records
    getTp1ListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {
          // this.$store.getters.apiURL + "/tp1/all"
          url: `/tp1/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;
          console.log("dataitems", vm.dataItems);

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "tp1edit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "tp1new" });
    },
  },

  mounted() {
    this.getTp1ListHandler();
  },
};
</script>