    <template>
    <div>
        <v-dialog v-model="baitulmaldialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupbaitulmal.baitulmalitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            outlined
                            :label="this.$t('setupsocso.uptowages')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="selections[0].wagesamt"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eeamount')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="selections[0].eeamt"
                            dense
                        ></v-text-field>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eramount')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="selections[0].eramt"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeBaitulmal">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveBaitulmal">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },


            isBaitulmal: false,
            baitulmaldialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                baitulmalid: null,
                wagesamt: null,
                eeamt: null,
                eramt: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }
            if(item.action == 2) {
                this.isExisting = true;
            }

            this.selections = [{
                id: item.id,
                wagesamt: item.wagesamt,
                eeamt: item.eeamt,
                eramt: item.eramt,
            }]

            this.baitulmaldialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeBaitulmal() {
            this.baitulmaldialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving baitulmal details into baitulmald
        saveBaitulmal() {

            if (this.selections[0].wagesamt == null || this.selections[0].wagesamt == '' 
            || this.selections[0].eeamt == null || this.selections[0].eeamt == '' 
            || this.selections[0].eramt == null || this.selections[0].eramt == ''){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog); 
             });
            } else {

            this.isErr = false;
            // indicate value of 1 as insert, 2 as modify

            if (!this.isExisting && !this.modify) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
                
            } else {

                for (let i = 0; i < this.detailItem.length; i++) {
                    if (this.detailItem[i].id == this.selections[0].id) {
                        this.detailItem[i].wagesamt = this.selections[0].wagesamt
                        this.detailItem[i].eeamt = this.selections[0].eeamt
                        this.detailItem[i].eramt = this.selections[0].eramt
                    }
                }
            }

            this.$emit('input', this.detailItem);

            this.modify = false;
            this.isExisting = false;
            this.baitulmaldialog = false;

            // reset the form fields
            this.resetSelections();

            }
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                baitulmalid: null,
                wagesamt: null,
                eeamt: null,
                eramt: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },
    }
}
</script>
