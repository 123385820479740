<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >


                        <v-row>
                      <v-col cols="4" sm="4" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.fromyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.startyear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                            @input="updateValue"
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('trainingsetup.frommonths')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.startmonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                            @input="updateValue"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.untilyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.endyear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            @input="updateValue"
                            clearable
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('trainingsetup.untilmonth')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.endmonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                            @input="updateValue"
                        ></v-autocomplete>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('trainingsetup.targethours')"
                                type="text"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.minhours"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingsetup.trainingtargetgroup')"
                                type="text"
                                ref= "trntargetgrpid"
                                :items="trntargetgrpList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.trntargetgrpid"
                                @change ="changeGroupHandler(formData.trntargetgrpid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                   
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('trainingsetup.targetgroupcode')"
                                id="trntargetgrpcode"
                                type="text"
                                ref="trntargetgrpcode" 
                                background-color="#ffeab2"
                                readonly
                                v-model="trntargetgrpcode" 
                                dense                       
                        ></v-text-field>
                        </v-col>
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {
                startyear: this.parentObject.data.startyear,
                startmonth: this.parentObject.data.startmonth,
                endyear: this.parentObject.data.endyear,
                endmonth: this.parentObject.data.endmonth,
                minhours: this.parentObject.data.minhours,
                trntargetgrpid: this.parentObject.data.trntargetgrpid,
               // lockstaff: this.parentObject.data.lockstaff,
               search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            trntargetgrpList: [],
            trntargetgrpcode: '',
            valvalue: [],
            pattern: [],
          
            yearList:[],
            monthList: [],

            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew

        }
    },

    methods: {

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },

        loadValvalueList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/groupp_grouppb_training/all'})
                .then(res => {
                    vm.valvalue = res.data.rec;
                    console.log('value', vm.valvalue)

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.trntargetgrpList.push(
                                {
                                    text: res.data.rec[i].valuedesc,
                                    value: res.data.rec[i].valvalueid
                                }
                            )    
                        }
                    }

                    if (this.formData.trntargetgrpid) {
                        this.changeGroupHandler(this.formData.trntargetgrpid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeGroupHandler(valvalueid) {
            let item = this.valvalue.find(item => item.valvalueid === valvalueid);

            this.trntargetgrpdesc = item.valuedesc;
            this.trntargetgrpcode = item.valuecode;
        },


        updateValue() {
            this.$emit('input', {
                startyear: this.formData.startyear,
                startmonth: this.formData.startmonth,
                endyear: this.formData.endyear,
                endmonth: this.formData.endmonth,
                minhours: this.formData.minhours,
                trntargetgrpid: this.formData.trntargetgrpid,
                search: this.formData.search,
              // lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'targettraininghourslist', params: { search: this.search}});
        },
    },

    mounted() {
        this.yearGeneration();
        this.monthGeneration();
        this.loadValvalueList();

    }
    
}
</script>