<template>
  <div id="companygrid">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import GridView from "../../../components/common/GridView";
import moment from "moment";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('recleavebalanceadjustment.leavebalanceadjustmentlist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", width: "100px", sortable: false },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "200px" },
        { text: this.$t('columns.staffname'), value: "psnname", width: "200px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "200px" },
        { text: this.$t('generateunpaidleaverecord.leavetype'), value: "leavecode", width: "200px" },
        { text: this.$t('recleavebalanceadjustment.adjustmenttype'), value: "adjtype", width: "200px" },
        { text: this.$t('recleavebalanceadjustment.adjustmentdays'), value: "adjdays", width: "200px" },
        { text: this.$t('columns.startdate'), value: "startdate", width: "200px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "200px" },
        { text: this.$t('recleavebalanceadjustment.adjustmentreason'), value: "adjreasondesc", width: "200px" },
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    editItemHandler(item) {
      this.$router.push({ name: "leavebalanceadjedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "leavebalanceadjnew" });
    },
    getLeaveAdjList() {
      const vm = this;
      this.$store.dispatch('get', {url: "/leavebalanceadj/balanceadj"})
        .then((res) => {
          //req success --> push res into array
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }
            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(
                vm.dataItems[i].enddate
              ).format("YYYY-MM-DD");
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
  },

  mounted() {
    this.getLeaveAdjList();
  },
};
</script>