<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PayoutControlForm from "../../../components/payroll/payoutcontrol/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": PayoutControlForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      formData: {
        payoutcode: "",
        payoutdesc: "",
        pyyear: 0,
        pymonth: 0,
        remark: "",
        payrolltypeid: null,
        grouppbid: null,
        pycrcyid: null,
        exchrateid: null,
        salfreqid: null,
        payeelistcode: null,
        pystatuscode: 10,
        allowinput: false,
        ssrel: false,
        userid: this.$store.getters.userId,
      },
      pageTitle: this.$t('payrollprocessrun.createnewpayoutcontrol'),
      pageTitleValue: "",
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      if (
        this.formData.allowinput == false ||
        this.formData.allowinput == null
      ) {
        this.formData.allowinput = 0;
      } else {
        this.formData.allowinput = 1;
      }

      if (
        this.formData.ssrel == false || 
        this.formData.ssrel == null
      ) {
        this.formData.ssrel = 0;
      } else {
        this.formData.ssrel = 1;
      }

      // if (this.formData.allowinput == true) {
      //   this.formData.allowinput = 0;
      // } else {
      //   this.formData.allowinput = 1;
      // }

      // if (this.formData.ssrel == true) {
      //   this.formData.ssrel = 0;
      // } else {
      //   this.formData.ssrel = 1;
      // }

      this.$store.dispatch('post', {
          url:  "/payoutctrl/create",
          payload: this.formData
      })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "payoutcontrollist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
};
</script>

<style scoped>
</style>