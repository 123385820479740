<template>
  <div>
    <app-input-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import TrainingRecordForm from "../../../components/training/trainingrecord/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
//import moment from 'moment';

export default {
  components: {
    "app-input-form": TrainingRecordForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('trainingrecord.createnewtrainingrecord'),
      pageTitleValue: "",
      formData: {
          staffid:null,
          staffcode:null,
          compnyid:null,
          compnyname:null,
          trncoursename:null,
          trncoursecatid:null,
          trnproviderid:null,
          startdate:null,
          enddate:null,
          recertification:null,
          trnhours:null,
          venue:null,
          Iselearning:null,
          result:null,
          materialdoc:null,
          materialdocname:null,
          certificatedoc:null,
          certificatedocname:null,
          trnhrdfchemeid:null,
          hrdfamtclaimed:null,
          hrdfamtclaimedcurrencyid:null,
          hrdfclaimeddate:null,
          hrdfamtapproved:null,
          hrdfamtapprovedcurrencyid:null,
          hrdfapproveddate: null,
       //  clmtypecatid: null,
        trainingrecorddetail: [],
   
        stamp: 0,
        userid: this.$store.getters.userId,
      },

     
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    // buttonHandler(){

    //   this.formData.entrytime = moment(this.entrytime).format('YYYY-MM-DD HH:mm:ss');

    //  },


    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      this.$store.dispatch('post', {url: "/trainingrecord/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "trainingrecordlist" });
          //  this.buttonHandler();
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "trainingrecordlist" });
    },
  },
};
</script>