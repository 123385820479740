<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
    
                    <v-row>
                      <v-col cols="4" sm="4" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.fromyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.fromYear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                            @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsparam.month')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.fromMonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                             @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.untilyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.untilYear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                             @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsparam.month')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.untilMonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                             @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>

                    </v-row>


                   
                        </div>
                    </v-card>

                     <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="goHandler" 
                                > 
                                {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios'
//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{

              rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },

            formData:{
               /// asatdate: new Date().toISOString().slice(0,10),
                fromYear: null,
                fromMonth: null,
                untilYear: null,
                untilMonth: null,
         
                title: this.$t('traininghoursanalysis')
            },

              parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                deptname: null,
                divisionid: null,
                sectionid: null,
                unitid: null,
                gradeid: null,
                employmenttypeid: null,  
                targettraininggroupid: null,    
                jobid: null,    
                positionid: null,   
                staffid: null,  
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                gradeid: true,
                employmenttypeid: true,  
                targettraininggroupid: true,
                jobid: true,
                positionid: true,
                staffid: true,
            },


            yearList: [],
            monthList: [],


            isErr: false,
            errMsg: [],
        }
    },

    methods: {

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },


         goHandler() {

             const formData = this.formData
             const param = this.parameters

             if ((this.formData.fromYear != null && this.formData.fromMonth != null &&
             this.formData.untilYear != null && this.formData.untilMonth != null) )  {
             

            this.$router.push({name: 'traininghoursanalysisdetails', params: { formData, param } });

            } else {
                this.$refs.form.validate()
            }

          
        },

           updateValue() {
            this.$emit("input", {
                 fromYear: this.formData.fromYear,
                 fromMonth: this.formData.fromMonth,
                 untilYear: this.formData.untilYear,
                 untilMonth: this.formData.untilMonth,               
            });
        },
    
    },
    mounted() {

   
        this.yearGeneration();
        this.monthGeneration();
    }
}
</script>