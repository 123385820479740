<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";

import WorkflowExceptionForm from "../../../components/settings/workflowexception/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": WorkflowExceptionForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
       search: this.$route.params.search,
      pageTitle: this.$t('setupworkflowexception.editworkflowexception'),
      pageTitleValue: "",
      formData: {
        staffid: null,
        psndetails: null,
        staffcode: null,
        compnyname: null,
        startdate: null,
        enddate: null,
        wftypeid: null,
        wftypedesc: null,
        detailid: null,
        leavecode: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadWorkflowException() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/workflowexception/" + this.id + "/" + this.formData.userid
        })
        .then((res) => {
          if (res.data.rec[0].startdate) {
            vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
            vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }


          vm.formData.staffid = res.data.rec[0].staffid;
          vm.formData.psndetails = res.data.rec[0].psndetails;
          vm.formData.staffcode = res.data.rec[0].staffcode;
          vm.formData.compnyname = res.data.rec[0].compnyname;
          vm.formData.wftypeid = res.data.rec[0].wftypeid;
          vm.formData.wftypedesc = res.data.rec[0].wftypedesc;
          vm.formData.detailid = res.data.rec[0].detailid;
          vm.formData.leavecode = res.data.rec[0].leavecode;
          vm.formData.stamp = res.data.rec[0].stamp;

          vm.pageTitleValue = res.data.rec[0].psnname;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      this.$store.dispatch('put', {
        url: "/workflowexception/" + this.id,
        payload: this.formData
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workflowexceptionlist" , params: { search: this.search}});
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      this.$store.dispatch('delete', {
        url: "/workflowexception/" +
            this.id +
            "/" +
            this.formData.staffid +
            "/" +
            this.formData.startdate +
            "/" +
            this.formData.enddate +
            "/" +
            this.formData.userid
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workflowexceptionlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "workflowexceptionnew" });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadWorkflowException();
  },
};
</script>