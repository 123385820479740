<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendancefromdate')"
              type="date"
              ref="fromdate"
              :rules="[rules.required]"
              v-model="formData.fromdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendanceuntildate')"
              type="date"
              ref="untildate"
              :rules="[rules.required]"
              v-model="formData.untildate"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n10">
            <v-checkbox
              outlined
              v-model="formData.isOverride"
              :label="this.$t('generateperiodicattendancerecord.overridependingfoundforsameperiodinpayroll')"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('reportsparam.payout')"
              type="text"
              :rules="[rules.required]"
              :items="payoutctrlList"
              v-model="formData.payoutid"
              @change="changePayout"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutdescription')"
              readonly
              type="text"
              v-model="payoutdesc"
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('reportsparam.year')"
              type="number"
              v-model="pyyear"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('reportsparam.month')"
              type="number"
              v-model="pymonth"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('payrollprocessrun.payrollgroup')"
              type="text"
              v-model="grouppdesc"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n6">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="blue--text"
                  >{{$t('reportsparam.filtercriteria')}}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row class="mt-5">
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.company')"
                        type="text"
                        ref="company"
                        v-model="formData.company"
                        dense
                        :items="companyList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.division')"
                        type="text"
                        ref="division"
                        v-model="formData.division"
                        dense
                        :items="divisionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('periodicincentivesetup.workdaygroup')"
                        type="text"
                        ref="workdaygroup"
                        v-model="formData.workdaygroup"
                        dense
                        :items="workdaygrouplist"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.branch')"
                        type="text"
                        ref="description"
                        v-model="formData.branch"
                        dense
                        :items="branchList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.section')"
                        type="text"
                        ref="section"
                        v-model="formData.section"
                        dense
                        :items="sectionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.employmenttype')"
                        type="text"
                        ref="employmentType"
                        v-model="formData.employmentType"
                        dense
                        :items="emptypeList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.department')"
                        type="text"
                        ref="department"
                        v-model="formData.department"
                        dense
                        :items="departmentList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.unit')"
                        type="text"
                        ref="unit"
                        v-model="formData.unit"
                        dense
                        :items="unitList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.staff')"
                        type="text"
                        ref="staff"
                        v-model="formData.staff"
                        dense
                        :items="staffDetailsList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pb-n5">
            <v-btn color="warning" @click="onPost" style="width: 150px">
              {{$t('button.post')}}
            </v-btn>
            <v-btn
              color="warning"
              @click="onUnPost"
              style="width: 150px"
              class="ml-5"
            >
            {{$t('button.undoposting')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<style>
.ExceptionClass td {
  color: red !important;
}
</style>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      search: "",
      showDate: new Date(),
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },

      formData: {
        fromdate: moment().format("YYYY-MM-DD"),
        untildate: moment().format("YYYY-MM-DD"),
        isOverride: false,
        company: null,
        division: null,
        workdaygroup: null,
        branch: null,
        section: null,
        employmentType: null,
        department: null,
        unit: null,
        staff: null,
        payoutid: null,
        userid: this.$store.getters.userId,
      },

      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,

      payoutdesc: "",
      pyyear: "",
      pymonth: "",
      grouppdesc: "",

      payoutctrls: [],
      payoutctrlList: [],
      staffdetails: [],
      staffDetailsList: [],
      divisions: [],
      divisionList: [],
      companies: [],
      companyList: [],
      sections: [],
      sectionList: [],
      emptypes: [],
      emptypeList: [],
      branches: [],
      branchList: [],
      units: [],
      unitList: [],
      departments: [],
      departmentList: [],
      grades: [],
      gradeList: [],
      positions: [],
      positionList: [],
      workdaygroup: [],
      workdaygrouplist: [],
    };
  },

  methods: {
    onPost() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.loading = true;
      this.$store.dispatch('post', {
        url: '/attendance/postgenerateperiodicattnincentive',
        payload: this.formData
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 201) {
            console.log(res.data);
            this.$router.push({
              name: "generateperiodicattnincentivelog",
              params: {
                processed: res.data.rec.processed,
                inserted: res.data.rec.inserted,
              },
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isErr = true;
          this.errMsg = err;
        });
    },
    onUnPost() {
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('post', {
        url: '/attendance/unpostgenerateperiodicattnincentive',
        payload: this.formData
        })
        .then((res) => {
          if (res.data.status == 201) {
            console.log(res.data);
            this.$dialog.alert("Records are deleted").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    //load work day group
    loadPayoutControls() {
      const vm = this;
      this.$store.dispatch('get', {
        url: '/payroll/process/pyctrl'
        })
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            if (vm.payoutctrls[i].startdate) {
              vm.payoutctrls[i].startdate = moment(
                vm.payoutctrls[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.payoutctrls[i].enddate) {
              vm.payoutctrls[i].enddate = moment(
                vm.payoutctrls[i].enddate
              ).format("YYYY-MM-DD");
            }

            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    changePayout($event) {
      let item = this.payoutctrls.find((item) => item.id == $event);

      this.payoutcode = item.payoutcode;
      this.payoutdesc = item.payoutdesc;
      this.pyyear = item.pyyear;
      this.pymonth = item.pymonth;
      this.grouppdesc = item.grouppdesc;
    },
    loadWorkdayGroupListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: '/groupp_grouppb_workday/all'
        })
        .then((res) => {
          console.log(res);
          vm.workdaygroup = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.workdaygrouplist.push({
              text: res.data.rec[i].valuedesc,
              value: res.data.rec[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/division/all'
        })
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].divncode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load companies
    loadCompaniesListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: '/company/all'
        })
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].compnycode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/section/all'
        })
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].sectiocode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load emptypes
    loadEmpTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/emptype/all'
        })
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptypedesc,
              value: res.data.rec[i].emptype,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load branches
    loadBanchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/branch/all'
        })
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].brchcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/unit/all'
        })
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].unitcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load depts
    loadDeptListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/department/all'
        })
        .then((res) => {
          vm.departments = res.data.rec;
          console.log(res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].deptcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load grades
    loadGradeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: '/grade/all'
        })
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].grdcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
        })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            // if (vm.formData.staffid) {
            //   this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            // }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeleavereason(id) {
      let item = this.leavereason.find((item) => item.id === id);
      this.formData.reasonid = item.id;
      this.formData.reasoncode = item.reasoncode;
    },
  },

  mounted() {
    this.loadPayoutControls();
    this.loadStaffDetailsListHandler();
    this.loadGradeListHandler();
    this.loadDeptListHandler();
    this.loadUnitListHandler();
    this.loadBanchListHandler();
    this.loadEmpTypeListHandler();
    this.loadSectionListHandler();
    this.loadCompaniesListHandler();
    this.loadDivisionListHandler();
    this.loadWorkdayGroupListHandler();
    this.loadLeavetypeListHandler();
    this.loadLeaveReasonListHandler();
  },
};
</script>