<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"

                >
                
                    <v-row >
                        <v-col cols="4" sm="10" class="pb-n5 mt-n6 pt-8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('columns.name')"
                            type="text"
                            ref="psnid"
                            :rules="[rules.required]"
                            v-model="formData.staffid"
                            dense
                            :items="staffDetailsList"
                            @input="updateValue"
                            @change="changeStaffIDandCompanyHandler(formData.staffid)"
                            clearable
                            ></v-autocomplete>
                        </v-col>
                        
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row >
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaimlimit.selectclaimtype')"
                                    type="text"
                                    ref="clmtype"
                                    :rules="[rules.required]"
                                    v-model="formData.clmtypeid"
                                    dense
                                    :items="clmList"
                                    @input="updateValue"
                                    @change="changeClaimHandler(formData.clmtypeid)"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypedesc"
                                    v-model="clmtypedesc"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                    
                                ></v-text-field>
                            </v-col>
                    </v-row>

                     <v-row >
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('claimlimitadjustmentrecord.claimadjustmenttype')"
                                    type="text"
                                    ref="clmlimitadjtypeid"
                                    :items="clmlimitadjtypeList"
                                    hide-selected
                                    :rules="[rules.required]"
                                    v-model="formData.clmlimitadjtypeid"
                                    dense
                                    @input="updateValue"
                                    clearable

                                ></v-autocomplete>
                            </v-col>

                             <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('claimlimitadjustmentrecord.adjustmentamount')"
                                    type="text"
                                    ref="adjamount"
                                    :rules="[rules.required]"
                                    v-model="formData.adjamount"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>
                     </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                          <v-text-field
                                outlined
                                :label="this.$t('claimlimitadjustmentrecord.valuedate')"
                                type="date"
                                ref="valuedate"
                                :rules="[rules.required]"
                                v-model="formData.valuedate"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.reason')"
                                type="text"
                                ref="clmadjreasonid"
                                :items="claimadjustreasonList"
                                hide-selected
                                v-model="formData.clmadjreasonid"
                                @input="updateValue"
                                @change="changeClaimAdjustReasonHandler(formData.clmadjreasonid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="claimadjustreasonCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="claimadjustreasonDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: claimadjustreasonItems,
                                            formTitle: this.$t('claimlimitadjustmentrecord.claimadjustmentreason'),
                                            formId: 'claimadjustreason'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveClaimAdjustReason="saveClaimAdjustReasonHandler"
                                        v-on:deleteClaimAdjustReason="deleteClaimAdjustReasonHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="claimadjustreasonDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('claimlimitadjustmentrecord.remarkoptional')"
                                    type="text"
                                    ref="remark"
                                    v-model="formData.remark"
                                    @input="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>
                     </v-row>




                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import GenericForm from '../../generic/GridForm';
//import moment from 'moment';
//import VueTimepicker from "vue2-timepicker";
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
        'app-genericform': GenericForm,
       // "vue-timepicker": VueTimepicker,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                compnyname: this.parentObject.data.compnyname,
                clmtypeid: this.parentObject.data.clmtypeid,
                clmtypedesc: this.parentObject.data.clmtypedesc,
                clmtype: this.parentObject.data.clmtype,
                clmlimitadjtypeid: this.parentObject.data.clmlimitadjtypeid,
                clmlimitadjtype: this.parentObject.data.clmlimitadjtype,
                adjamount: this.parentObject.data.adjamount,
                valuedate: this.parentObject.data.valuedate,
                clmadjreasonid: this.parentObject.data.clmadjreasonid,
                clmadjreason: this.parentObject.data.clmadjreason,
                clmadjreasondesc: this.parentObject.data.clmadjreasondesc,
                remark: this.parentObject.data.remark,
                // clmprdtypeid: this.parentObject.data.clmprdtypeid,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            staffdetails: [],
            staffDetailsList: [],

            companies: [],
            companiesitemsList: [],

            clmItems: [],
            clmList: [],
          //  clmtypedesc: '',
            clmtypedesc: '',

            clmlimitadjtypeItems: [],
            clmlimitadjtypeList: [],
            clmlimitadjtypeid: [],
            ClaimLimitAdjustmentTypeLoading: true,

             claimadjustreasonCode: '',
             claimadjustreasonList: [],
             claimadjustreasons: [],
             claimadjustreasonItems: [],
             claimadjustreasonDialog: false,
             claimadjustreasonLoading: true,

            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {
        
        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/staff/alls"
            })
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
        },

           changeClaimType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'
            })
            .then(res => {

                vm.clmItems = res.data.rec;

                for (let i = 0; i < vm.clmItems.length; i++) {
                    vm.clmList.push({
                        text: vm.clmItems[i].clmtype,
                        value: vm.clmItems[i].id,
                    });
                }

                if (this.formData.clmtypeid) {
                this.changeClaimHandler(this.formData.clmtypeid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeClaimHandler(id) {
            var item = this.clmItems.find(item => item.id === id);
            this.clmtype = item.clmtype
            this.clmtypedesc = item.clmtypedesc;
        },

        
         LoadClaimAdjustmentType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimlimitadjustmenttype/all'
            })
                .then(res => {
                    vm.clmlimitadjtypeid = res.data.rec;
                   // console.log('item',vm.clmlimitadjtypeid)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.clmlimitadjtypeList.push(
                            {
                                text: res.data.rec[i].clmlimitadjtype,
                                value: res.data.rec[i].id
                            }
                        )

                        } 

                   vm.ClaimLimitAdjustmentTypeLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         // loading the content of claim adjustment reason from the database, providing the name of the claim adjustment reason on the dropdown box
        loadClaimAdjustReasonListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimadjustmentreason/all'
            })
                .then(res => {
                    vm.claimadjustreasons = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.claimadjustreasonList.push(
                            {
                                text: res.data.rec[i].clmadjreasondesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.claimadjustreasonItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].clmadjreason,
                                desc: res.data.rec[i].clmadjreasondesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.claimadjustreasonLoading = false;

                    if (vm.formData.clmadjreasonid) {
                        vm.changeClaimAdjustReasonHandler(vm.formData.clmadjreasonid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeClaimAdjustReasonHandler(id) {
            let item = this.claimadjustreasons.find(item => item.id === id);

            this.claimadjustreasonCode = item.clmadjreason;
        },

        saveClaimAdjustReasonHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                url: '/claimadjustmentreason/' + this.itemData.itemid,
                payload: {
                    clmadjreason: this.itemData.itemcode,
                    clmadjreasondesc: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp,
                    userid: this.formData.userid,
                }
            })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.claimadjustreasons = [];
                        vm.claimadjustreasonItems = [];
                        vm.claimadjustreasonList = [];

                        this.loadClaimAdjustReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                url: '/claimadjustmentreason/create',
                payload: {
                    clmadjreason: this.itemData.itemcode,
                    clmadjreasondesc: this.itemData.itemdesc,
                    userid: this.formData.userid,
                }
            })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.claimadjustreasons = [];
                        vm.claimadjustreasonItems = [];
                        vm.claimadjustreasonList = [];

                        this.loadClaimAdjustReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteClaimAdjustReasonHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                url: '/claimadjustmentreason/' + $event.id + '/' + this.formData.userid
            })
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.claimadjustreasons = [];
                        vm.claimadjustreasonList = [];
                        vm.claimadjustreasonItems = [];

                        this.loadClaimAdjustReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genClaimAdjustReasonTabItems() {
            for (let i = 0; i < this.claimadjustreasons.length; i++) {
                this.claimadjustreasonItems.push(
                    {
                        id: this.claimadjustreasons[i].id,
                        code: this.claimadjustreasons[i].clmadjreason,
                        desc: this.claimadjustreasons[i].clmadjreasondesc,
                        stamp: this.claimadjustreasons[i].stamp
                    }
                )
            }
        },

       
        updateValue() {

        this.$emit('input', {

                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                compnyname: this.formData.compnyname,
                clmtypeid: this.formData.clmtypeid,
                clmtypedesc: this.formData.clmtypedesc,
                clmtype: this.formData.clmtype,
                clmlimitadjtypeid: this.formData.clmlimitadjtypeid,
                clmlimitadjtype: this.formData.clmlimitadjtype,
                adjamount: this.formData.adjamount,
                valuedate: this.formData.valuedate,
                clmadjreasonid: this.formData.clmadjreasonid,
                clmadjreason: this.formData.clmadjreason,
                clmadjreasondesc: this.formData.clmadjreason,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'claimlimitadjustmentlist', params: { search: this.search}});
        },

    },

    mounted() {

        this.loadStaffDetailsListHandler();
        this.changeClaimType();
        this.LoadClaimAdjustmentType();
        this.loadClaimAdjustReasonListHandler();
    }



}
</script>