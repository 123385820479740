<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.pageTitle }}
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                outlined
                :label="this.$t('columns.effectivefrom')"
                type="date"
                dense
                v-model="formData.startdate"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                outlined
                :label="this.$t('columns.effectiveuntil')"
                type="date"
                dense
                v-model="formData.enddate"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <div class="pt-5 col-sm-8 col-8">
              <v-btn
                color="success"
                style="width: 100px"
                @click="processHandler"
              >
              {{$t('button.process')}}
              </v-btn>
            </div>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupmappingprocess.groupmappingprocess'),
      pageTitleValue: "",
      formData: {
        startdate: null,
        enddate: null,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    processHandler() {
      const vm = this;

      //   if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
      //     return false;
      //   }

      this.$store.dispatch('post', {url: "/mapping/process", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$dialog.alert("Mapping Process Completed.").then((dialog) => {
              console.log(dialog);
            });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
};
</script>