<template>
    <div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
      ></loading>
      <v-container>
        <v-form>
          <v-row>
            <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="getGLExportListHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  import XLSX from 'xlsx/xlsx';
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
       Loading,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
        },
        pageTitle: this.$t('alleis.defaulteisexportformat'),
  
        id: this.parentObject.id,
        parameter: this.parentObject.parameter,
        item: this.parentObject.data,
        
        titleData: null,
        gldata: [],
  
        totalContAmt: null,
        debitTotalAmt: null,
        creditTotalAmt: null,
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
  
        loading: false,
  
        processedCount: 0,
        successCount: null,
        errCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
       getGLExportListHandler() {
        this.loading = true;
        const vm = this
        let dataHolder = {
          item: this.item,
          parameter: this.parameter
        }
  
        this.$store.dispatch('post', {url:  `/paygl/export`, payload: dataHolder})
          .then(function (res) {
          
            vm.loading = false;
            vm.gldata = res.data.rec;
    
            vm.exportHandler(); 
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      async getData() {
        const vm = this
        let body = []
        let count = 0
        let glcode_totalamt = []
        let debit_totalamt = []
        let credit_totalamt = []
        let glcode_detail = vm.gldata
        if (glcode_detail.length == 0) {
          alert(this.$t('glcode.noresult'));
        }
  
        glcode_detail.forEach(el => {

          body.push({
            // "Reporting By": el.sumitem ? '' : el.reportby,
            "Internal Code": el.incode,
            "External Code": el.excode,
            "Debit Amt": el.debitgl ? el.aprvamt.toFixed(2) : '',
            "Credit Amt": el.creditgl ? el.aprvamt.toFixed(2) : '',
          });
  
          glcode_totalamt.push(el.aprvamt);

          if (el.debitgl) debit_totalamt.push(el.aprvamt)
          if (el.creditgl) credit_totalamt.push(el.aprvamt)
          
          count++
        });

        this.processedCount = count
  
        if (glcode_totalamt.length > 0) {
          let glcodetotalamt_holder = glcode_totalamt.reduce(function (a, b) {return a + b}, 0);
          this.totalContAmt = glcodetotalamt_holder.toFixed(2);
        }

        if (debit_totalamt.length > 0) {
          let debittotalamt_holder = debit_totalamt.reduce(function (a, b) {return a + b}, 0);
          this.debitTotalAmt = debittotalamt_holder.toFixed(2);
        }

        if (credit_totalamt.length > 0) {
          let credittotalamt_holder = credit_totalamt.reduce(function (a, b) {return a + b}, 0);
          this.creditTotalAmt = credittotalamt_holder.toFixed(2);
        }

        this.titleData = 'Standard GL Export ' +  this.item.salexpyear + ' ' + this.item.salexpmonth

        if (body.length > 0) {
          // const csvData = this.objectToCSV(body);
          // this.download(csvData, this.titleData);
          const ws = XLSX.utils.json_to_sheet(body);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, this.titleData);
          XLSX.writeFile(wb, this.titleData + '.xlsx');
        }
      },
  
      objectToTxt(data) {
        const csvRows = [];
  
        const headers = Object.keys(data[0]);
  
        //loop over the rows
        for (const row of data) {
          const values = headers.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          csvRows.push(values.join(""));
        }
  
        return csvRows.join("\n");
      },
  
      download(data, title) {
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
          this.getData();
          const item = {
            totalContAmt: this.totalContAmt,
            processedCount: this.processedCount,
            debitAmt: this.debitTotalAmt,
            creditAmt: this.creditTotalAmt
          }
          
          if (!this.isErr) {
            this.$router.push({ name: "glexportlog", params: item });
          }
      },
  
      backHandler() {
        const parameter =  {
          brchid: this.parameter.branchid,
          compnyid: this.parameter.companyid,
          deptid: this.parameter.departmentid,
          emptypeid: this.parameter.employmenttypeid,
          gradeid: this.parameter.gradeid,
          staffid: this.parameter.staffid,
          divnid: this.parameter.divisionid,
          sectioid: this.parameter.sectionid,
          unitid: this.parameter.unitid,
          jobid: this.parameter.jobid,
          positionid: this.parameter.positionid
        }
        this.$router.push({ name: "glexport", params: parameter});
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  };
  </script>