<template>
    <div v-if="!loading">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        >
        </app-gridview>
    </div>
</template>

<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('recassetrec.assetreclist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: "action", width: "5px", sortable: false },
                { text: this.$t('recassetrec.assettype'), value: "assettype", width: "60px"},
                { text: this.$t('recassetrec.quantity'), value: "qty", width: "10px"},
                { text: this.$t('recassetrec.avquantity'), value: "availableqty", width: "10px"},
                { text: this.$t('recassetrec.trackdetails'), value: "trackdetails", width: "20px"},
                { text: this.$t('columns.remark'), value: "remark", width: "30px"},
            ],

            dataItems: [],
            getId: [],
            errMsg: '',
            activeAssignment: '',
            loading: true,
            search: this.$route.params.search,
        }
    },

    methods: {
        loadAssetList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/assets/all'})
            .then (res => {
                vm.dataItems = res.data.rec;

                // available quantity if trackdetails is checked = number of records in assetd where assetstatus is available
 
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].trackdetails == "Y") {
                        let availableAssetY = vm.availableQty.filter(val => {
                            return val.assetid == vm.dataItems[i].id && val.assetstatusid == 1});

                        vm.dataItems[i].availableqty = availableAssetY.length;
                    }
                }

                // load function to calculate available quantity if trackdetails is not checked

                this.loadActiveAssignmentHandler();
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        loadAvailableQtyHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assetd2/all"})
                .then((res) => {
                    vm.availableQty = res.data.rec;
                    this.loadAssetList();
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadActiveAssignmentHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/assetassignment3/all"})
                .then((res) => {
                    vm.activeAssignment = res.data.rec;

                    // available quantity if trackdetails is not checked = qty minus number of active records for assettype
                   
                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].trackdetails == "N") {
                            let assignedAssetN = vm.activeAssignment.filter(val => {
                                return val.assetid == vm.dataItems[i].id});

                            vm.dataItems[i].availableqty = vm.dataItems[i].qty - assignedAssetN.length;
                        }
                    }
                    vm.loading = false;
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },
        
        editItemHandler(item) {
            this.$router.push({name: 'assetedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'assetnew'});
        }
    },

    mounted() {
        this.loadAvailableQtyHandler();
    }
}
</script>