<template>
    <div id="companygrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>


<script>
//import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupcompany.companylist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('columns.company'), value: 'compnycode', width: '120px' },
                { text: this.$t('columns.description'), value: 'compnyname', width: '200px' },
                { text: this.$t('setupcompany.regno'), value: 'regno', width: '120px' },
                //{ text: 'EFFECTIVE DATE', value: 'startdate', width: '200px' },
                //{ text: 'INVALID DATE', value: 'enddate', width: '200px' },
                { text: this.$t('columns.addressline1'), value: 'addr1', width: '200px' },
                { text: this.$t('columns.addressline2'), value: 'addr2', width: '200px' },
                { text: this.$t('columns.addressline3'), value: 'addr3', width: '200px' },
                { text: this.$t('columns.city'), value: 'cityname', width: '200px' },
                { text: this.$t('columns.country'), value: 'cntryname', width: '200px' },
                { text: this.$t('columns.postcode'), value: 'postcode', width: '80px' },
                { text: this.$t('columns.telephone'), value: 'tel', width: '200px' },
                { text: this.$t('columns.website'), value: 'www', width: '200px' },
                { text: this.$t('columns.email'), value: 'email', width: '200px' },
                { text: this.$t('setupcompany.parentcompany'), value: 'parentcompnyname', width: '200px' },
                { text: this.$t('columns.disable'), value: 'isdisable', width: '50px' },
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,

        }
    },

    methods: {
        getCompanyListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/company/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    // for (let i = 0; i < vm.dataItems.length; i++) {
                    //     if (vm.dataItems[i].startdate) {
                    //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    //     }

                    //     if (vm.dataItems[i].enddate) {
                    //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    //     }
                    // }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
           
            this.$router.push({name: 'companyedit', params: { id: item.id, search: item.search } });
            
        },

        newRecordHandler() {
            this.$router.push({name: 'companynew'});
        }

    },

    mounted() {
        this.getCompanyListHandler();
    }
}
</script>