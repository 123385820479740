<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">Parameters</v-subheader>
                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.fromdate')"
                                        type="date"
                                        id="fromDate"
                                        v-model="formData.fromDate"
                                        :rules="[rules.required]"
                                        dense                                
                                    ></v-text-field>
                                </v-col>

                                 <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.untildate')"
                                        type="date"
                                        id="untilDate"
                                        v-model="formData.untilDate"
                                         :rules="[rules.required]"
                                        dense                                
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                                  <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>


                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>
                       </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.exchangerate')"
                            type="text"
                            :items="exchangeRateList"
                            :rules="[rules.required]"
                            v-model="formData.exchrateid"
                            @change="changeExchrateHandler(formData.exchrateid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="exchageRateCode"
                        ></v-text-field>
                    </v-col>
                </v-row>
                   
                        </div>
                    </v-card>

                     <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="goHandler" 
                                > 
                                {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{

              rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },

            formData:{
               /// asatdate: new Date().toISOString().slice(0,10),
               fromDate: null,
               untilDate: null,
               crcyid: null,
               exchrateid: null,
               title: this.$t('claimutilizationforperiod')
            },

              parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                deptname: null,
                divisionid: null,
                sectionid: null,
                unitid: null,
                gradeid: null,
                employmenttypeid: null,  
                claimgroupid: null,             
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                gradeid: true,
                employmenttypeid: true,  
                claimgroupid: true,
            },

            currencyList: [],
            exchangeRateList: [],
            currencyCode: '',
            exchageRateCode: '',
        }
    },

    methods: {


         goHandler() {

             const formData = this.formData
             const param = this.parameters

             //pass as at date as parameter to PositionAssignmentAnalysis.vue
            
             if(this.formData.fromDate > this.formData.untilDate || this.untilDate < this.formData.fromDate ){

                this.$dialog.alert("Receipt From Date should be earlier than Receipt Until Date").then((dialog) => {
                console.log(dialog);
             });

             } else if((this.formData.fromDate != null && this.formData.untilDate != null
             && this.formData.crcyid != null && this.formData.exchrateid != null)){
            this.$router.push({name: 'claimutilizationperioddetails', params: { formData, param } });
             } else {
                 this.$refs.form.validate()
             }
        },

            loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

         loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/exchrate/all'})
                .then(res => {
                    vm.exchangeRate = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangeRateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.exchrateid) {
                        vm.changeExchrateHandler(vm.formData.exchrateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeExchrateHandler(id) {
            let item = this.exchangeRate.find(item => item.id === id);

            this.exchageRateCode = item.exchratecode;
        },

    
    },
    mounted() {

        this.loadCurrencyListHandler();
        this.loadExchangeRate();
    }
}
</script>