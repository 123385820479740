<template>
    <div>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-row>
              <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.hsbcconnectcustomerid')"
                  type="text"
                  v-model="formData.connectcustomerid"
                  maxlength="18"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" class="pb-n4">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.hsbcnetcustomerid')"
                  type="text"
                  v-model="formData.netcustomerid"
                  maxlength="20"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.filereference')"
                  type="text"
                  v-model="formData.fileref"
                  maxlength="35"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.refline1')"
                  type="text"
                  v-model="formData.refline"
                  maxlength="35"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5" sm="5" class="pb-n5 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.paymentcode')"
                  type="text"
                  v-model="formData.paymentcode"
                  maxlength="10"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('claimlimitadjustmentrecord.valuedate')"
                  type="date"
                  v-model="formData.valuedate"
                  :rules="[rules.required, rules.date]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="exportHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
          date: (value) => moment(value) >= moment() && moment(value) <= moment().add(45, 'days') || this.$t('reportsparam.datecannotlessthantodayorexceed45dys'),
        },
        pageTitle: 'HSBC Bank',
  
        parameter: this.parentObject.parameter,
        id: this.parentObject.id,
        data: this.parentObject.data,
        formData: {
          connectcustomerid: '',
          netcustomerid: '',
          fileref: '',
          refline: '',
          valuedate: '',
          paymentcode: ''
        },
        fileHolder: null,
        file: null,
  
        paymentModeList: ['IT', 'IG'],
        bank: [
          {
            id: 1,
            code: '32',
            bankdesc: 'Affin Bank', 
          },
          {
            id: 2,
            code: '43',
            bankdesc: 'Agro Bank'
          },
          {
            id: 4,
            code: '12',
            bankdesc: 'Alliance Bank'
          },
          {
            id: 3,
            code: '50',
            bankdesc: 'Al-Rajhi Banking & Investment Corporation'
          },
          {
            id: 5,
            code: '08',
            bankdesc: 'Ambank'
          },
          {
            id: 7,
            code: '40',
            bankdesc: 'Bank Islam'
          },
          {
            id: 12,
            code: '99',
            bankdesc: 'Bank Kerjasama Rakyat Malaysia'
          },
          {
            id: 8,
            code: '41',
            bankdesc: 'Bank Muamalat'
          },
          {
            id: 9,
            code: '07',
            bankdesc: 'Bank of America'
          },
          {
            id: 13,
            code: '06',
            bankdesc: 'Bank Simpanan Nasional'
          },
          {
            id: 11,
            code: '10',
            bankdesc: 'Bank of Tokyo-Mitsubishi UFJ'
          },
          {
            id: 16,
            code: 35,
            bankdesc: 'CIMB Bank'
          },
          {
            id: 14,
            code: 63,
            bankdesc: 'BNP Paribas'
          },
          {
            id: 17,
            code: '17',
            bankdesc: 'Citibank'
          },
          {
            id: 18,
            code: '19',
            bankdesc: 'Deutsche Bank'
          },
          {
            id: 20,
            code: '24',
            bankdesc: 'Hong Leong Bank'
          },
          {
            id: 21,
            code: '22',
            bankdesc: 'HSBC Bank'
          },
          {
            id: 23,
            code: '59',
            bankdesc: 'Industrial and Commercial Bank of China'
          },
          {
            id: 24,
            code: '15',
            bankdesc: 'JP Morgan Chase'
          },
          {
            id: 25,
            code: '44',
            bankdesc: 'Kuwait Finance House'
          },
          {
            id: 26,
            code: '27',
            bankdesc: 'Maybank'
          },
          {
            id: 27,
            code: 60,
            bankdesc: 'Mizuho Bank'
          },
          {
            id: 29,
            code: '29',
            bankdesc: 'OCBC Bank'
          },
          {
            id: 18,
            code: '19',
            bankdesc: 'Deutsche Bank'
          },
          {
            id: 30,
            code: '33',
            bankdesc: 'Public Bank'
          },
          {
            id: 31,
            code: '18',
            bankdesc: 'RHB Bank'
          },
          {
            id: 35,
            code: '02',
            bankdesc: 'Royal Bank of Scotland'
          },
          {
            id: 32,
            code: '14',
            bankdesc: 'Standard Chartered Bank'
          },
          {
            id: 36,
            code: '26',
            bankdesc: 'United Overseas Bank'
          },
          {
            id: 10,
            code: '42',
            bankdesc: 'Bank of China'
          },
          {
            id: 43,
            code: '56',
            bankdesc: 'HSBC Amanah'
          },
        ],
  
        staffilterlist: [],
        staffaccount: [],
        companyaccount: [],
        psnpayroll: [],
        titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
        payrolldata: [],
  
        companyrecnum: "",
        paymentmode: null,
        bankcode: null,
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
        filler: "",
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
  
        currency: null,
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
  
      getAffinbankExportListHandler() {
        const vm = this
        
        this.$store.dispatch('get', {url: `/hsbcsalexport/all/${this.$store.getters.userId}`})
        .then(res => {
          let dateHolder = vm.data.year + "-" + 
                           vm.padRJ(vm.data.month, 2) + "-" + 
                           vm.daysInMonth(vm.data.month, vm.data.year);
            
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let payrolldataholder = res.data.rec;
          payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)
  
          payrolldataholder.forEach(function (o) {
            var existing = vm.payrolldata.filter(function (i) {
              return i.staffid === o.staffid;
            })[0];
            if (!existing || existing == undefined) {
              if (o.bankid) {
                if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                      vm.payrolldata.push(o);
                }
              } else {
                vm.payrolldata.push(o);
              }
            }
          });
          
          this.loadStaffFilter()
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      loadStaffFilter() {
        const vm = this;
  
        this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
          .then(res => {
              vm.staffilterlist = res.data.rec;
              let checkval = false;
              for (const key in this.parameter) {
                if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
              }
  
              if (checkval) {
                vm.payrolldata = vm.payrolldata.filter((el) => {
                    return vm.staffilterlist.some((f) => {
                        return f.staffid === el.staffid;
                    });
                });
              }
              
          })
          .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
          });
      },
  
      async getData() {
        const vm = this
        let body = []
        let holdpayment = []
        let dataholder = vm.payrolldata
        let totalcount = 0
        let payrolldetail = this.filter(dataholder);

        this.processedCount = dataholder.length;
        this.successCount = payrolldetail.length;
        this.errCount = dataholder.length - payrolldetail.length;
        this.currency = payrolldetail[0].exchratecode;

        payrolldetail.forEach(element => {
          console.log('element', element)
          let bankcodeacc = ''
          let bankcodeholder = vm.bank.find(e => e.id == element.bankid)
          bankcodeholder == undefined ? bankcodeacc = '' : bankcodeacc = bankcodeholder.code

          for (const key in element) {
            if (element[key] == null) element[key] = ''
          }

          // hpnoholder = element.hpno

          let nameholder = element.accountname.slice(0, 20)

          body.push({
            "Record Type": this.padSpaceRJ('SECPTY', 0) + ',',
            "Benificiary Account Number": this.padSpaceRJ(element.staffaccountno, 0) + ',',
            "Benificiary Name 1": this.padSpaceRJ(nameholder.replace(/\s+$/, ''), 0) + ',',
            "Identifier": this.padSpaceRJ(element.staffcode.replace(/-/g, ''), 0) + ',',
            "Benificiary Bank Code": this.padSpaceRJ(bankcodeacc, 0) + ',',
            "Benificiary Branch Number": this.padSpaceRJ('', 0) + ',',
            "Transaction Code": this.padSpaceRJ('', 0) + ',',
            "Transaction Amount": this.padSpaceRJ(element.netpay, 0) + ',',
            "Entry Value Date": this.padSpaceRJ('', 0) + ',',
            //"2nd Party Reference": this.padSpaceRJ(element.iddetails.replace(/-/g, ''), 0) + ',',
            "Info Line 1": this.padSpaceRJ('', 0) + ',',
            "Info Line 2": this.padSpaceRJ('', 0) + ',',
            "Info Line 3": this.padSpaceRJ('', 0) + ',',
            "Info Line 4": this.padSpaceRJ('', 0) + ',',
            "Info Line 5": this.padSpaceRJ('', 0) + ',',
            "Advice Indicator": this.padSpaceRJ('N', 0) + ',',
            "Withholding Tax Indicator": this.padSpaceRJ('N', 0)
          });

          holdpayment.push(element.netpay)
          totalcount++
        });

        if (holdpayment) {
          let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
          this.gettotalAmt = totalAmt.toFixed(2);
        }

        let instructioncount = totalcount
        let recordcount = totalcount + 2

        let header =
          'IFH' + ',' +
          'IFILE' + ',' +
          'CSV' + ',' +
          this.padSpaceRJ(this.formData.connectcustomerid, 2) + ',' +
          this.padSpaceRJ(this.formData.netcustomerid, 0) + ',' +
          this.padSpaceRJ(this.formData.fileref, 0) + ',' +
          this.padSpaceRJ(moment().format('YYYY/MM/DD'), 0) + ',' +
          this.padSpaceRJ(moment().format('HH:mm:ss'), 0) + ',' +
          this.padSpaceRJ('P', 0) + ',' +
          this.padSpaceRJ('1.0', 0) + ',' +
          this.padSpaceRJ(recordcount, 0);

        let batchheader = 
          'BATHDR' + ',' +
          'ACH-CR' + ',' +
          this.padSpaceRJ(instructioncount, 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('@1ST@', 0) + ',' +
          this.padSpaceRJ(moment(this.formData.valuedate).format('YYYYMMDD'), 0) + ',' +
          this.padSpaceRJ(payrolldetail[0].accountno, 0) + ',' +
          this.padSpaceRJ('MYR', 0) + ',' +
          this.padSpaceRJ(this.gettotalAmt, 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('MY', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ(payrolldetail[0].crcycode, 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ(payrolldetail[0].compnydesc, 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ('', 0) + ',' +
          this.padSpaceRJ(this.formData.paymentcode, 0) + ',' +
          this.padSpaceRJ(this.formData.refline, 0);
          
        if (body.length > 0) {
          const txtData = this.objectToTxt(header, body, batchheader);
          this.download(txtData, this.titleData);
        }
      },
  
      filter(item) {
        let checkacc = this.accfilter(item)
        let checkaccbank = this.accBankfilter(checkacc)
        let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
        let checkactive = this.activefilter(checkapaymentmethod)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      accfilter(item) {
        let data = item.filter(e => e.bankid)
        let noacc = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noacc.length; i++) {
          noacc[i].errormsg = this.$t('allbank.noaccfound');
          this.accErr.push(noacc[i]);
        }
        return data
      },
  
      accBankfilter(item) {
        let data = item.filter(e => {
          for (let i = 0; i < this.bank.length; i++) {
            if (this.bank[i].id == e.bankid) return e
          }
        })
        let noaccbank = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noaccbank.length; i++) {
          noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
          this.accErr.push(noaccbank[i]);
        }
        return data
      },
  
      paymentmethodfilter(item) {
        let data = item.filter(e => e.paymodecode == 'BANK')
        let diffpayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < diffpayment.length; i++) {
          diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
          this.accErr.push(diffpayment[i]);
        }
        return data
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => e.netpay > 0)
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToTxt(header, data, batchheader) {
        const csvRows = [];
        const dataheaders = Object.keys(data[0]);
  
        csvRows.push(header);
        csvRows.push(batchheader);
  
        //loop over the rows
        for (const row of data) {
          const values = dataheaders.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          csvRows.push(values.join(""));
        }
        return csvRows.join("\n");
      },
  
      download(data, title) {
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalAmt = this.gettotalAmt;
          item.accounterr = this.accErr;
          item.negErrMss = this.negErrMsg;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
          item.currency = this.currency;
          this.$router.push({ name: "salaryexportlog", params: { item } });
        }
      },
  
      backHandler() {
        let param = this.parameter
  
        this.$router.push({ name: "salaryexport", params: { param } });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
    mounted() {
      this.getAffinbankExportListHandler();
    },
  };
  </script>