<template>
    <div>
        <!-- <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="150"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading> -->
        <v-container fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
            <v-form>

                
                 <v-row>
                    <div >
                        <v-row>
                            <v-col>
                                <v-btn 
                                    v-model="goBtn"
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 10px;"
                                    @click="backHandler" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                </v-row>

                
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}
                        
                    </h2>
                </v-row>
                  <v-row class="mb-8">
                        <v-col cols="1">
                            {{$t('reportsparam.filter')}}
                        </v-col>
                        <v-col class="page_title_value">
                            <div v-if="emptypedescholder"> {{ this.emptypedescholder }} </div>
                            <div v-if="compnynameholder"> {{ this.compnynameholder }} </div>
                            <div v-if="brchnameholder"> {{ this.brchnameholder }} </div>
                            <div v-if="deptnameholder"> {{ this.deptnameholder }} </div>
                            <div v-if="sectionameholder"> {{ this.sectionameholder }} </div>
                            <div v-if="divisionameholder"> {{ this.divisionameholder }} </div>
                            <div v-if="unitnameholder"> {{ this.unitnameholder }} </div>
                            <div v-if="gradenameholder"> {{ this.gradenameholder }} </div>
                            <div v-if="jobdescholder"> {{this.jobdescholder}} </div>
                            <div v-if="positiondescholder"> {{this.positiondescholder}} </div>
                            <div v-if="gradecatdescholder"> {{this.gradecatdescholder}} </div>
                        
                        </v-col>
                </v-row>

            
            <v-container 
            fluid id="scroll-target" 
            class="custom-form overflow-y-auto px-5"
            >

            <v-row justify="center" align="center">
                <v-col cols="6" sm = "6">
                    <v-card elevation="5" outlined>
                        <div id="chart">
                            <apexchart 
                            type="bar" 
                            height="350" 
                            :options="newchartOptions" 
                            :series="seriesExpenseType"
                            ></apexchart>
                </div>
                    </v-card>
                </v-col>

                   <v-col cols="6" sm = "6">
                    <v-card elevation="5" outlined>
                        <div id="chart">
                            <apexchart 
                            type="bar" 
                            height="350" 
                            :options="chartOptions" 
                            :series="series"
                            ></apexchart>
                </div>
                    </v-card>
                </v-col>
            </v-row>

                <v-row>
                    <v-toolbar flat>
                    <v-toolbar-title
                        >
                    </v-toolbar-title> 
                    <v-spacer></v-spacer>

                        <v-avatar class="profile mr-5" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_pdf.png`"
                        @click="printPDF"
                        />
                    </v-avatar>

                    <v-avatar class="profile" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_csv.png`"
                        @click="getCSV"
                        />
                    </v-avatar>

                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            style="width: 20px"
                            class="pr-5 pt-3"
                            hide-details>
                        </v-text-field>
                </v-toolbar>
                </v-row>


                         <v-row>
                                <v-col cols="20" sm="20" >
                                <v-data-table
                                    :headers="dataHeader"
                                    :search ="search"
                                    :items="dataItems"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                    no-data-text="No records found for the selected training hours analysis."
                                >
                                </v-data-table>
                                </v-col>
                            </v-row>
                </v-container>
            <!-- </v-container> -->
            </v-form>
        </v-container>    
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
//import Loading from "vue-loading-overlay";

export default {
    components: {
        'apexchart': VueApexCharts,
       // Loading
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
           
            pageTitle: this.$t('trainingexpensesanalysisfrom') + this.$route.params.formData.fromDate + this.$t('until') + this.$route.params.formData.untilDate ,
            pageTitleValue: "",
            search: '',

            data: {
                formData: {
                    fromDate: this.$route.params.formData.fromDate,
                    untilDate: this.$route.params.formData.untilDate,
                    trntargetgrpid: this.$route.params.formData.trntargetgrpid,
                    trncoursecatid: this.$route.params.formData.trncoursecatid,
                    reportingcrcyid: this.$route.params.formData.reportingcrcyid,
                    reportingcrcycode: this.$route.params.formData.reportingcrcycode,
                    exchrateid: this.$route.params.formData.exchrateid,
                  
                    userid: this.$store.getters.userId,
                },

                  parameters: {
                    brchid: this.$route.params.param.branchid,
                    compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    divnid: this.$route.params.param.divisionid,
                    sectioid: this.$route.params.param.sectionid,
                    unitid: this.$route.params.param.unitid,
                    gradeid: this.$route.params.param.gradeid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                  //  targettraininggroupid: this.$route.params.param.targettraininggroupid, 
                    jobid: this.$route.params.param.jobid,
                    positionid: this.$route.params.param.positionid,
                    gradecatid: this.$route.params.param.gradecatid,
                },

            },

            dataItems: [],
            trntargetgrpid: '',
    
            parameter: null,

            //table header 
            dataHeader: [
                    { text: this.$t('statutoryexportcommonused.no'), width: "50px", value: "no" },
                    { text: this.$t('trainingrecord.trainingcourse'), value: 'trncoursename', width: '200px' },
                    { text: this.$t('columns.startdate'), value: 'startdate', width: '150px' },
                    { text: this.$t('columns.enddate'), value: 'enddate', width: '150px' },
                    { text: this.$t('columns.name'), value: 'staffname', width: '200px' },
                    { text: this.$t('columns.staffid'), value: 'staffno', width: '100px' },
                    { text: this.$t('trainingexpensesanalysisreport.coursecategory'), value: 'trncoursecatcode', width: '150px' },
                    { text: this.$t('traininghoursanalysisreport.targettrainingcode'), value: 'trntargetgrpcode', width: '170px' },
                    { text: this.$t('traininghoursanalysisreport.expensestype'), value: 'trnexpensecode', width: '140px' },
                    { text: this.$t('traininghoursanalysisreport.expenses'), value: 'amount', width: '120px' },
                    { text: this.$t('claimrecordlisting.convertedexpenses'), value: 'expenses', width: '120px' },
                    { text: this.$t('columns.company'), value: 'compnycode', width: '120px' },
                    { text: this.$t('columns.branch'), value: 'brchcode', width: '120px' },
                    { text: this.$t('columns.department'), value: 'deptcode', width: '120px' },        
                    { text: this.$t('columns.division'), value: 'divncode', width: '120px' },
                    { text: this.$t('columns.section'), value: 'sectiocode', width: '120px' },
                    { text: this.$t('columns.unit'), value: 'unitcode', width: '120px' },
                    { text: this.$t('columns.job'), value: 'jobtitle', width: '150px' },
                    { text: this.$t('columns.grade'), value: 'grdcode', width: '120px' },
            ],

            series: [],
           // data: []
               
                chartOptions: {
                    chart: {
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    defaultLocale: this.$route.params.lang, 
                    type: 'bar',
                    height: 350
                    },

                    title: {
                    text: this.$t('trainingexpensesanalysisbytargetgroup'),
                    align: 'center',
                    margin: 10,
                    style: {
                        fontsize: '16px',
                        color: ' #004080',
                        fontweight: 'light',
                        fontfamily: 'Lato-Bold'
                    },
                },

                legend: {
                    show: false,
                },

                    plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                    },
                    dataLabels: {
                    enabled: false
                    },
                    xaxis: {
                    categories: [],
                }
            },

               seriesExpenseType: [{
                data: []
                }],
                newchartOptions: {
                    chart: {
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    defaultLocale: this.$route.params.lang, 
                    type: 'bar',
                    height: 350
                    },

                    title: {
                    text: this.$t('trainingexpensesanalysisbyexpensetype'),
                    align: 'center',
                    margin: 10,
                    style: {
                        fontsize: '16px',
                        color: ' #004080',
                        fontweight: 'light',
                        fontfamily: 'Lato-Bold'
                    },
                },

                legend: {
                    show: false,
                },

                    plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                    },
                    dataLabels: {
                    enabled: false
                    },
                    xaxis: {
                    categories: [],
                }
            },
          

            //load error message variable
            isErr: false,
            loading: true,
            checkloading:true,

            goBtn: true,
            title: this.$t('reportheadcountanalysis.stafflistfor'),

            // clmtypeid: '',
            // clmtypdesc: '',
            // clmtypecrcyid: '',

            emptypedescholder: null,
            compnynameholder: null,
            brchnameholder: null,
            deptnameholder: null,
            valuedescholder: null,
            sectionameholder: null,
            divisionameholder: null,
            unitnameholder: null,
            gradenameholder: null,
            jobdescholder: null,
            positiondescholder: null,
            gradecatdescholder: null,

            exchangeItems:[],
            // newdata:[],
            // dataItems2: [],
            clmItems: [],

            rate: null,
            sqlAll: null,
            sqlAll2: null,
            expenses: null,

      
        }
    },

    methods: {

        TargetGroupchart(item){
            const vm = this; 
              const element = [...new Set(item.map(item => item.trntargetgrpid))]

              let barHolder = [];

              for (var i = 0; i< element.length; i++){
                  const targetHolder = item.find(val => val.trntargetgrpid == element[i])

                  barHolder.push({
                      name:targetHolder.trntargetgrpcode,
                      id:targetHolder.trntargetgrpid
                  })

                   vm.chartOptions.xaxis.categories.push(targetHolder.trntargetgrpcode)
              }

              for (var m = 0; m < barHolder.length; m++){
                  let dataHolder = [];

                  const targetData = item.filter(val => val.trntargetgrpid == barHolder[m].id)

                  const targetMap = targetData.map(val => val.expenses)

                  const total = targetMap.reduce((a, b) => a + b, 0)

                  dataHolder.push(total)

                  barHolder[m].data = dataHolder;

                 vm.series = barHolder;
                 vm.chartOptions.xaxis.categories = barHolder[m].name;
              }
     
        },

          ExpenseTypechart(item){
            const vm = this; 
              const element = [...new Set(item.map(item => item.trnexpensetypeid))]

              let barchartHolder = [];

              for (var i = 0; i< element.length; i++){
                  const expenseHolder = item.find(val => val.trnexpensetypeid == element[i])

                  barchartHolder.push({
                      name:expenseHolder.trnexpensecode,
                      id:expenseHolder.trnexpensetypeid
                  })

                  vm.newchartOptions.xaxis.categories.push(expenseHolder.trnexpensecode)
              }

              for (var m = 0; m < barchartHolder.length; m++){
                  let expensedataHolder = [];

                  const expenseData = item.filter(val => val.trnexpensetypeid == barchartHolder[m].id)

                  const expenseMap = expenseData.map(val => val.expenses)

                  const total = expenseMap.reduce((a, b) => a + b, 0)

                  expensedataHolder.push(total)

                  barchartHolder[m].data = expensedataHolder;

                 vm.seriesExpenseType = barchartHolder;
              }
        },

    TrendChart(){
    const vm = this;

    vm.loading = true

        this.$store.dispatch('post', {url: `/trainingexpensesanalysis/${this.$store.getters.userId}`, payload: this.data})
        .then(res => {

           vm.dataItems = res.data.rec;
          // console.log('data', vm.dataItems)

            vm.title = this.$t('stafflistoftrainingexpensesanalysis')

            vm.loading = false

            for (let i = 0; i < vm.dataItems.length; i++) {
                    vm.dataItems[i].no = i + 1;
                    vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format("DD-MM-YYYY");
                    vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format("DD-MM-YYYY");
                    vm.dataItems[i].amount = parseFloat(vm.dataItems[i].amount.toFixed(2));
                    vm.dataItems[i].reportingcrcycode = vm.$route.params.formData.reportingcrcycode;
                    vm.dataItems[i].reportingcrcyid = vm.$route.params.formData.reportingcrcyid;

                    vm.dataItems[i].currencyid = parseInt(vm.dataItems[i].currencyid);
                    vm.dataItems[i].sqlAll = parseInt(vm.dataItems[i].sqlAll);


              if(vm.dataItems[i].currencyid == vm.dataItems[i].reportingcrcyid ){

                    let convertedrate = 1
                     let currentAmount =  parseFloat(vm.dataItems[i].amount) *
                                        parseFloat(convertedrate);
                                
                    vm.dataItems[i].expenses = parseFloat(currentAmount.toFixed(2));
                }

                 if( vm.dataItems[i].sqlAll == '1' && vm.dataItems[i].currencyid != vm.dataItems[i].reportingcrcyid){

                      let convertedrate =   vm.dataItems[i].rate
                      let currentAmount =  parseFloat(vm.dataItems[i].amount) *
                                           parseFloat(convertedrate);

                    vm.dataItems[i].expenses = parseFloat(currentAmount.toFixed(2));
                 
                 }     
            }
                this.TargetGroupchart(vm.dataItems);
                this.ExpenseTypechart(vm.dataItems);
            })

            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });

    },


        employmentHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/emptype/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.emptypeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.emptypeid)[0]
                        vm.emptypedescholder = itemholder.emptypedesc
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          companyHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/company/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.compnyid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.compnyid)[0]
                        vm.compnynameholder = itemholder.compnyname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        branchHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/branch/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.brchid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.brchid)[0]
                        vm.brchnameholder = itemholder.brchname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        departmentHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/department/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.deptid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.deptid)[0]
                        vm.deptnameholder = itemholder.deptname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        valvalueHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/valvalue/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.targettraininggroupid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.targettraininggroupid)[0]
                        vm.valuedescholder = itemholder.valuedesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        jobHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/job/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.jobid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.jobid)[0]
                        vm.jobdescholder = itemholder.jobtitle
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          gradecatHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/gradecat/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradecatid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradecatid)[0]
                        vm.gradecatdescholder = itemholder.grdcatdesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

         positionHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/position/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.positionid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.positionid)[0]
                        vm.positiondescholder = itemholder.posdesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        sectionHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/emptype/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.sectioid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.sectioid)[0]
                        vm.sectionameholder = itemholder.sectioname
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          unitHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/company/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.unitid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.unitid)[0]
                        vm.unitnameholder = itemholder.unitname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        divisionHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/branch/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.divnid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.divnid)[0]
                        vm.divisionameholder = itemholder.divnname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        gradeHandler() {
            const vm = this

            this.$store.dispatch('get', {url:`/grade/all`})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradeid)[0]
                        vm.gradenameholder = itemholder.grddesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    
    // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        // get CSV for tabHeader

        async getCSV() {
            let item = this.dataItems;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [`${this.$t('statutoryexportcommonused.no')}`]: item[i].no,
                    [`${this.$t('trainingrecord.trainingcourse')}`]: item[i].trncoursename,
                    [`${this.$t('columns.startdate')}`]: item[i].startdate,
                    [`${this.$t('columns.enddate')}`]: item[i].enddate,
                    [`${this.$t('columns.name')}`]: item[i].staffname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffno,
                    [`${this.$t('trainingexpensesanalysisreport.coursecategory')}`]: item[i].trncoursecatcode,
                    [`${this.$t('traininghoursanalysisreport.targettrainingcode')}`]: item[i].trnexpensecode,
                    [`${this.$t('traininghoursanalysisreport.expensestype')}`]: item[i].expenses,
                    [`${this.$t('traininghoursanalysisreport.expenses')}`]: item[i].amount,
                    [`${this.$t('claimrecordlisting.convertedexpenses')}`]: item[i].expenses,
                    [`${this.$t('columns.company')}`]: item[i].compnycode,
                    [`${this.$t('columns.branch')}`]: item[i].brchcode,
                    [`${this.$t('columns.department')}`]: item[i].deptcode,
                    [`${this.$t('columns.division')}`]: item[i].divncode,
                    [`${this.$t('columns.section')}`]: item[i].sectiocode,
                    [`${this.$t('columns.unit')}`]: item[i].unitcode,
                    [`${this.$t('columns.job')}`]: item[i].jobtitle,
                    [`${this.$t('columns.grade')}`]: item[i].grdcode,
         
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // get CSV fortabHeader2

       

        // print PDF for tabheader
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })

            var head = [  
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },              
                { title: this.$t('trainingrecord.trainingcourse'), dataKey: 'trncoursename'},
                { title: this.$t('columns.startdate'), dataKey: 'startdate'},
                { title: this.$t('columns.enddate'), dataKey: 'enddate'},
                { title: this.$t('columns.name'), dataKey: 'staffname' },
                { title: this.$t('columns.staffid'), dataKey: 'staffno' },
                { title: this.$t('trainingexpensesanalysisreport.coursecategory'), dataKey: 'trncoursecatcode' },
                { title: this.$t('traininghoursanalysisreport.targettrainingcode'), dataKey: 'trntargetgrpcode' },
                { title: this.$t('traininghoursanalysisreport.expensestype'), dataKey: 'trnexpensecode' },
                { title: this.$t('traininghoursanalysisreport.expenses'), dataKey: 'amount' },
                { title: this.$t('claimrecordlisting.convertedexpenses'), dataKey: 'expenses' },
                { title: this.$t('columns.company'), dataKey: 'compnycode'},
                { title: this.$t('columns.branch'), dataKey: 'brchcode' },
                { title: this.$t('columns.department'), dataKey: 'deptcode' },        
                { title: this.$t('columns.division'), dataKey: 'divncode' },
                { title: this.$t('columns.section'), dataKey: 'sectiocode' },
                { title: this.$t('columns.unit'), dataKey: 'unitcode' },
                { title: this.$t('columns.job'), dataKey: 'jobtitle' },
                { title: this.$t('columns.grade'), dataKey: 'grdcode' },
                
            ]

            let body = []

            for (var i = 0; i < this.dataItems.length; i++) {
           
                body.push({
                    no: this.dataItems[i].no,
                    trncoursename: this.dataItems[i].trncoursename,
                    startdate: this.dataItems[i].startdate,
                    enddate: this.dataItems[i].enddate,
                    staffname: this.dataItems[i].staffname,
                    staffno: this.dataItems[i].staffno,
                    trncoursecatcode: this.dataItems[i].trncoursecatcode,
                    trntargetgrpcode: this.dataItems[i].trntargetgrpcode,
                    trnexpensecode: this.dataItems[i].trnexpensecode,
                    amount: this.dataItems[i].amount,
                    expenses: this.dataItems[i].expenses,
                    compnycode: this.dataItems[i].compnycode,
                    brchcode: this.dataItems[i].brchcode,
                    deptcode: this.dataItems[i].deptcode,
                    divncode: this.dataItems[i].divncode,
                    sectiocode: this.dataItems[i].sectiocode,
                    unitcode: this.dataItems[i].unitcode,
                    jobtitle: this.dataItems[i].jobtitle,
                    grdcode: this.dataItems[i].grdcode
            
                })
            }

            this.title = "Training Expenses Analysis ";
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        //back handler - go back to TrainingExpensesAnalysisParameter.vue
        backHandler(){
            this.$router.push({name: 'trainingexpensesanalysisparameter'});
        },
        
        loadParameters() {

            this.companyHandler();
            this.departmentHandler();
            this.branchHandler();
            this.employmentHandler();
            this.valvalueHandler();
            this.sectionHandler();
            this.divisionHandler();
            this.unitHandler();
            this.gradeHandler();
            this.gradecatHandler();
        }
    },

    created() {

          if (typeof(this.$route.params.parameters) == 'string') {
            let paramHolder = JSON.parse(this.$route.params.parameters)
            this.data.parameters = {
                brchid: paramHolder.branchid,
                compnyid: paramHolder.companyid,
                deptid: paramHolder.departmentid,
                emptypeid: paramHolder.employmenttypeid,
                targettraininggroupid: paramHolder.targettraininggroupid,
                jobid: paramHolder.jobid,
                positionid: paramHolder.positionid,
                divnid:paramHolder.divisionid,
                sectioid:paramHolder.sectionid,
                unitid:paramHolder.unitid,
                grdid:paramHolder.gradeid,
                gradecatid: paramHolder.gradecatid,
            }
          }

        
    },   

    mounted() {
     this.loadParameters();
     this.TrendChart();
  
    }
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}

.TotalClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}
.TotalClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.ClaimAmt {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: green;
    align-content: center;
    justify-content: center;
}
.ClaimAmtContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.Average {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: blue;
    align-content: center;
    justify-content: center;
}
.AverageContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
  
    min-height: 60px;
}

.AverageClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: red;
    align-content: center;
    justify-content: center;
}
.AverageClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}
</style>