<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitmetype.payitemtype')"
                                type="text"
                                :items="payItemList"
                                :rules="[rules.required]"
                                v-model="formData.payitemid"
                                @change="
                                    changePayItemHandler(formData.payitemid)
                                "
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="8" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitmetype.payitemtype')"
                                type="text"
                                :items="payItemList"
                                :rules="[rules.required]"
                                v-model="formData.payitemid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-dialog
                                v-model="payitemDialog"
                                max-width="600px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform
                                        v-bind:parentData="{
                                            tabItems: payItemCodes,
                                            formTitle: `${this.$t('setuppayitmetype.payitemtype')}`,
                                            formId: 'payitemsetup',
                                            payitemTypes: payItemTypeList
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:savePayitemSetup="
                                            savePayItemHandler
                                        "
                                        v-on:deletePayItemSetup="
                                            deletePayItemHandler($event)
                                        "
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="payitemDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.payitemdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8 mb-n8">
                            <v-text-field
                                background-color="#ffeab2"
                                outlined
                                readonly
                                :label="this.$t('setuppayitmetype.payitemtype')"
                                type="text"
                                @change="changePayItemTypeHandler"
                                @input="updateValue"
                                v-model="formData.payitemtypedesc"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8 mb-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="payItemTypeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!--div v-if="this.formData.payitemtypeid">
                        <v-row class="pl-4">
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4"
                                style="background:#ffeab2"
                            >
                                Affect Net Pay
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4"
                                style="background:#ffeab2"
                            >
                                {{ this.afnetpay }}
                            </v-col>
                        </v-row>

                        <v-row class="pl-4">
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4 mt-n4"
                                style="background:#ffeab2"
                            >
                                Affect Gross Pay
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4 mt-n4"
                                style="background:#ffeab2"
                            >
                                {{ this.afgrosspay }}
                            </v-col>
                        </v-row>

                        <v-row class="pl-4">
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4 mt-n4"
                                style="background:#ffeab2"
                            >
                                Vendor Based
                            </v-col>
                            <v-col
                                cols="2"
                                sm="2"
                                class="pb-n4 mt-n4"
                                style="background:#ffeab2"
                            >
                                {{ this.isvendor }}
                            </v-col>
                        </v-row>
                    </div-->

                    <v-row>
                        <v-col cols="1" sm="1" class="pb-n4">
                            {{$t('setupleavesetup.controls')}}
                        </v-col>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n5">
                            <v-checkbox
                                :label="this.$t('setuppayitem.companyfringe')"
                                v-model="formData.cofringe"
                                @change="updateValue()"
                            ></v-checkbox>
                            <v-checkbox
                                :label="this.$t('setuppayitem.arrearitem')"
                                v-model="formData.arritem"
                                @change="updateValue()"
                                class="mt-n4"
                            ></v-checkbox>
                            <v-checkbox
                                :label="this.$t('setuppayitem.basicdependent')"
                                v-model="formData.basdep"
                                @change="updateValue()"
                                class="mt-n4"
                            ></v-checkbox>
                            <v-checkbox
                                :label="this.$t('setuppayitem.regularitem')"
                                v-model="formData.regitem"
                                @change="updateValue()"
                                class="mt-n4"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupaffecttype.affecttype')"
                                type="text"
                                clearable
                                :items="affectTypeList"
                                v-model="formData.affecttypeid"
                                @change="changeAffectTypeHandler"
                                @input="updateValue"
                                :rules="[rules.required]"
                                 dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="affectTypeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div v-if="formData.affecttypeid">
                        <v-row>
                            <v-col col="8" sm="8" class="pb4-n4 mt-n8 mb-8">
                                <v-data-table
                                    :headers="tabHeader"
                                    :items="affectItems"
                                    class="elavation-1"
                                    fixed-header
                                    :sort-by="['affectitemcode']"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.taxableexemption')"
                                type="text"
                                ref = "taxexemptionid"
                                v-model="formData.taxexemptionid"
                                dense
                                :items="taxExempList"
                                 @input="updateValue"
                                @change="changeTaxExempHandler(formData.taxexemptionid)"
                                clearable
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                type="text"
                                ref="exemptioncode"
                                v-model="exemptioncode"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.prorate')"
                                type="text"
                                :items="proratesList"
                                v-model="formData.prorateid"
                                @change="changeProrateHandler"
                                @input="updateValue"
                                :rules="[rules.required]"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="prorateCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.round')"
                                type="text"
                                :items="roundingsList"
                                v-model="formData.roundingid"
                                :rules="[rules.required]"
                                @input="updateValue"
                                @change="
                                    changeRoundingHandler(formData.roundingid)
                                "
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="4"
                            sm="4"
                            v-if="this.isRounding"
                            class="pb-n4 mt-n8"
                        >
                            <!-- use for reports -->
                            <v-text-field
                                dense
                                :label="this.$t('setuppayitem.roundto')"
                                outlined
                                v-model="formData.roundto"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                label="Decimal"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row> -->

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>
<style scoped>
</style>

<script>
import GenericForm from "../../generic/GridForm";

export default {
   props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
        "app-genericform": GenericForm
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                }
            },

            formData: {
                payitemid: this.parentObject.data.payitemid,
                payitemcode: this.parentObject.data.payitemcode,
                payitemdesc: this.parentObject.data.payitemdesc,
                payitemtypeid: this.parentObject.data.payitemtypeid,
                payitemtypecode: this.parentObject.data.payitemtypecode,
                payitemtypedesc: this.parentObject.data.payitemtypedesc,
                cofringe: this.parentObject.data.cofringe,
                arritem: this.parentObject.data.arritem,
                basdep: this.parentObject.data.basdep,
                regitem: this.parentObject.data.regitem,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                prorateid: this.parentObject.data.prorateid,
                roundingid: this.parentObject.data.roundingid,
                roundto: this.parentObject.data.roundto,
              //  decimalval: this.parentObject.data.decimalval,
                remark: this.parentObject.data.remark,
                affecttypeid: this.parentObject.data.affecttypeid,
                taxexemptionid: this.parentObject.data.taxexemptionid,
              //  taxexemptiondesc: this.parentObject.data.taxexemptiondesc,
              // taxexemptioncode: this.parentObject.data.taxexemptioncode,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            selected: [],
            payItemTypes: [],
            payItemTypeList: [],
            payItemTypeCode: "",
            payItemCodes: [],
            payItemList: [],
            afnetpay: "",
            afgrosspay: "",
            isvendor: "",
            prorates: [],
            proratesList: [],
            prorateCode: "",
            roundings: [],
            roundingsList: [],
            roundingCode: "",
            isRounding: false,
            affectTypes: [],
            affectTypeList: [],
            affectTypeCode: "",

            taxExempsItems: [],
            taxExempList: [],
            exemptioncode: '',

            tabHeader: [
                {
                    text: this.$t('columns.code'),
                    value: "affectitemcode",
                    width: "300px",
                    sortable: false
                },
                {
                    text: this.$t('columns.description'),
                    value: "affectitemdesc",
                    width: "300px",
                    sortable: false
                }
            ],
            affectItems: [],

            errMsg: "",
            isErr: false,
           // isEdit: this.parentObject.isEdit,
            payitemDialog: false,
            itemData: null,
             search: this.parentObject.searchnew
        };
    },

    methods: {
        savePayItemHandler() {
            const vm = this;

            let payload = {
                payitemcode: this.itemData.itemcode,
                payitemdesc: this.itemData.itemdesc,
                payitemtypeid: this.itemData.typeid,
                payitemtypecode: this.itemData.typecode,
                payitemdtypedesc: this.itemData.typedesc,
                userid: this.formData.userid
            };

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: `/payitemcode/${vm.itemData.itemid}`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.payItemCodes = [];
                            vm.payItemList = [];

                            this.loadPayItemHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: `/payitemcode/create`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.payItemCodes = [];
                            vm.payItemList = [];

                            this.loadPayItemHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        deletePayItemHandler($event) {
            const vm = this;

            if (confirm(`Remove ${$event.desc} ?`)) {
                this.$store.dispatch('delete', {url: `/payitemcode/${$event.id}/${vm.formData.userid}`})
                    .then(res => {
                        if (res.data.status == 200) {
                            // resets all arrays
                            vm.payItemCodes = [];
                            vm.payItemList = [];

                            this.loadPayItemHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        loadPayItemHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitem/codes"})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemCodes.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].payitemcode,
                            desc: res.data.rec[i].payitemdesc,
                            typeid: res.data.rec[i].payitemtypeid,
                            typedesc: res.data.rec[i].payitemtypedesc,
                            stamp: res.data.rec[i].stamp
                        });

                        vm.payItemList.push({
                            text: res.data.rec[i].payitemdesc,
                            value: res.data.rec[i].id,
                            typeid: res.data.rec[i].payitemtypeid,
                            typecode: res.data.rec[i].payitemtypecode,
                            typedesc: res.data.rec[i].payitemtypedesc
                        });
                    }

                    if (vm.formData.payitemid) {
                        this.changePayItemHandler(vm.formData.payitemid);
                    }
                });
        },

        changePayItemHandler(id) {
            let item = this.payItemList.find(
                item => item.value === parseInt(id)
            );

            this.formData.payitemid = item.value;
            this.formData.payitemdesc = item.text;
            this.formData.payitemtypeid = item.typeid;
            this.formData.payitemtypecode = item.typecode;
            this.formData.payitemtypedesc = item.typedesc;
            this.payItemTypeCode = item.typecode;
        },

        loadPayItemTypesHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitemtype/all"})
                .then(res => {
                    vm.payItemTypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemTypeList.push({
                            text: res.data.rec[i].payitemtypedesc,
                            value: res.data.rec[i].id,
                            code: res.data.rec[i].payitemtypecode
                        });
                    }

                    if (vm.formData.payitemtypeid) {
                        vm.changePayItemTypeHandler(vm.formData.payitemtypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadProrateHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitem/prorate/all"})
                .then(res => {
                    vm.prorates = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.proratesList.push({
                            text: res.data.rec[i].proratedesc,
                            value: res.data.rec[i].id
                        });
                    }

                    if (vm.formData.prorateid) {
                        vm.changeProrateHandler(vm.formData.prorateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadRoundingHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitem/roundings/all"})
                .then(res => {
                    vm.roundings = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.roundingsList.push({
                            text: res.data.rec[i].roundingdesc,
                            value: res.data.rec[i].id
                        });
                    }

                    if (vm.formData.roundingid) {
                        vm.changeRoundingHandler(vm.formData.roundingid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadAffectTypesHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/affecttype/all"})
                .then(res => {
                    vm.affectTypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.affectTypeList.push({
                            text: res.data.rec[i].affecttypedesc,
                            value: res.data.rec[i].id
                        });
                    }

                    if (vm.formData.affecttypeid) {
                        vm.changeAffectTypeHandler(vm.formData.affecttypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadAffectTypeDetailHandler(id) {
            const vm = this;

            this.$store.dispatch('get', {url: "/affecttyped/" + id})
                .then(res => {
                    vm.affectItems = res.data.rec;
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadTaxExemptionsHandler() {
            const vm = this;

            

            this.$store.dispatch('get', {url: '/taxexemption/all'})
                .then(res => {
                    vm.taxExempsItems = res.data.rec;

                    for (let i = 0; i < vm.taxExempsItems.length; i++) {
                        vm.taxExempList.push({
                            text: vm.taxExempsItems[i].exemptiondesc,
                            value: vm.taxExempsItems[i].id,
                         
                        });
                    }

                    if (this.formData.taxexemptionid) {
                        this.changeTaxExempHandler(this.formData.taxexemptionid);
                    }
                })
                .catch(err => {
                   
                    this.errMsg = err;
                 });
        },

        changeTaxExempHandler(id) {

       

        if(id){
                var item = this.taxExempsItems.find(item => item.id === id);

               /// console.log('id', id)

               // console.log('item', item)
                
                this.exemptioncode = item.exemptioncode;
            } 
        },

        changePayItemTypeHandler(id) {
            let item = this.payItemTypes.find(item => item.id === id);

            this.payItemTypeCode = item.payitemtypecode;
            this.afnetpay = item.afnetpay;
            this.afgrosspay = item.afgrosspay;
            this.isvendor = item.isvendor;
        },

        changeProrateHandler(id) {
            let item = this.prorates.find(item => item.id === id);

            this.prorateCode = item.proratecode;
        },

        changeRoundingHandler(id) {
            let item = this.roundings.find(item => item.id === id);

            this.roundingCode = item.roundingcode;

            if (
                this.roundingCode == "ROUNDOFF" ||
                this.roundingCode == "ROUNDUP" ||
                this.roundingCode == "ROUNDDOWN"
            ) {
                this.isRounding = true;
            } else {
                this.isRounding = false;
            }
        },

        changeAffectTypeHandler(id) {
            let item = this.affectTypes.find(item => item.id === id);

            this.affectTypeCode = item.affecttypecode;

            this.loadAffectTypeDetailHandler(id);
        },

        updateValue() {

            if (typeof this.formData.taxexemptionid == "undefined" ) {

           
            this.formData.taxexemptionid = null;
            }

            this.$emit('input', {
                payitemid: this.formData.payitemid,
                payitemcode: this.formData.payitemcode,
                payitemdesc: this.formData.payitemdesc,
                payitemtypeid: this.formData.payitemtypeid,
                cofringe: this.formData.cofringe,
                arritem: this.formData.arritem,
                basdep: this.formData.basdep,
                regitem: this.formData.regitem,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                prorateid: this.formData.prorateid,
                roundingid: this.formData.roundingid,
                roundto: this.formData.roundto,
               // decimalval: this.formData.decimalval,
                remark: this.formData.remark,
                affecttypeid: this.formData.affecttypeid,
                taxexemptionid: this.formData.taxexemptionid,
              //  taxexemptiondesc: this.formData.taxexemptiondesc,
              //  taxexemptioncode: this.formData.taxexemptioncode,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

          // console.log('input', this.formData)
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "payitemlist", params: { search: this.search} });
        }
    },

    mounted() {
        this.loadPayItemHandler();
        this.loadPayItemTypesHandler();
        this.loadProrateHandler();
        this.loadRoundingHandler();
        this.loadAffectTypesHandler();
        this.loadTaxExemptionsHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
};
</script>