<template>
    <div>
        <v-dialog v-model="emptypedialog" max-width="800px" @click:outside="closeDetail">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupemptypeaddon.addaddongroup')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-form ref="form">
                    <v-row>
                        <v-col cols="6" sm="6" md="6" >
                            <v-autocomplete
                                outlined
                                :label="this.$t('Add On Group')"
                                id="addongrpid"
                                type="text"
                                ref="addongrpid"
                                :items="addongrpList"
                                :rules="[rules.required]"
                                v-model="selections.addongrpid"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n2 mt-n0">
                            <v-dialog v-model="addongrpDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: addongrpList,
                                            formTitle: `${this.$t('Add-On')}`,
                                            formId: 'addongrp'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveAddOnGrp="saveAddOnGrpHandler"
                                        v-on:deleteAddOnGrp="deleteAddOnGrpHandler($event)"
                                    />
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="addongrpDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" md="6" >
                            <v-text-field
                                outlined
                                :label="this.$t('setupemptypeaddon.groupvalue')"
                                id="addongrpval"
                                type="text"
                                ref="addongrpval"
                                :rules="[rules.required]"
                                v-model="selections.addongrpval"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                  </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDetail">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveDetail">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },
    components: {
        'app-genericform': GenericForm,
    },
//Data call need to modify according to back end controller
    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },

            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                addongrpid: null,
                addongrp: null,
                addongrpval: null,
                action: 0
            },
            emptypedialog: false,
            groupItems: [],
            groupList: [],
            groupTypeList: [],

            addongrps:[],
            addongrpList: [],

            addongrpDialog: false,

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

//All back end not done and do sql to call in controller so need to 
//make the back end controller for add on group employee
//Methods of calling data need to adjust to suits the form for add on group since the one below 
//is copied from existing crudtable with slight modification

    methods: {
        compareArray,

        getAddOnGrpListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/addongrp/emptype'})
                .then(res => {
                    vm.addongrps = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {

                        vm.addongrpList.push(
                            {
                                code: res.data.rec[i].addongrptype,
                                desc: res.data.rec[i].addongrp,
                                text: res.data.rec[i].addongrp,
                                value: res.data.rec[i].id,
                            }
                        )
                    }
                    
                    // if (vm.formData.salutid) {
                    //     vm.changeGroupTypeHandler(vm.formData.salutid);
                    // }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        saveAddOnGrpHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/addongrp/' + this.itemData.itemid, payload: {
                    // addongrptype: vm.itemData.itemcode,
                    addongrp: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.addongrps = [];
                        vm.addongrpList = [];

                        this.getAddOnGrpListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/addongrp/emptype/create', payload: {
                    // addongrptype: vm.itemData.itemcode,
                    addongrp: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.addongrps = [];
                        vm.addongrpList = [];

                        this.getAddOnGrpListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteAddOnGrpHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/addongrp/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.addongrps = [];
                        vm.addongrpList = [];

                        this.getAddOnGrpListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        // loadGroup() {
        //     const vm = this;

        //     this.$store.dispatch('get', {
        //         url: '/emptypegrplist'})
        //     .then(res => {
        //         vm.groupItems = res.data.rec;
                
        //         for (let i = 0; i < vm.groupItems.length; i++) {
        //             if (!vm.groupTypeList.find(e => e == vm.groupItems[i].type)){
        //                 vm.groupTypeList.push(vm.groupItems[i].type); 
        //             }
        //             vm.groupList.push({
        //                 type: vm.groupItems[i].type,
        //                 text: vm.groupItems[i].addongrpval,
        //                 code: vm.groupItems[i].addongrp,
        //                 value: vm.groupItems[i].id,
        //             });
        //         }
                
        //     })
        //     .catch(err => {
        //         vm.errMsg = err;
        //     });
        // },

        // changeGroupTypeHandler(id) {
        //     const vm = this;
        //     vm.groupList = [];
        //     vm.groupItems.filter(e => e.type == id).forEach(e => {
        //         vm.groupList.push({
        //                 type: e.type,
        //                 text: e.addongrpval,
        //                 code: e.addongrp,
        //                 value: e.id,
        //             });
        //     });
        //     vm.selections.code = null;
        // },
        
        // changeGroupHandler(id) {
        //     let item = this.groupList.find(
        //         (item) => item.value === parseInt(id)
        //     );

        //     this.selections.code = item.code;
        //     this.selections.text = item.text;
        //     this.selections.type = item.type;
        //     this.selections.value = item.value;
        //     this.selections.id = item.value;
        // },


        modifyItemHandler(item) {
          // assign loaded data to selections array
            if (item.action) {
                this.isExisting = true;
            } else {
                this.modify = true;
            }

            this.selections = item;
            this.emptypedialog = true;
            // this.changeGroupTypeHandler(this.selections.type);
            // this.changeGroupHandler(this.selections.id);
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },  
        
        saveDetail() {
            this.isErr = false;
            // this.selections.addongrp = this.selections.code;
            // this.selections.addongrpval = this.selections.text;
            this.selections.addongrp = this.addongrps.find(e => e.id == this.selections.addongrpid)['addongrp'];
            if (!this.isExisting && !this.modify) {
                this.detailItem.push(Object.assign({}, this.selections));

            } else {
                if(!this.modify){
                    this.selections.action = 2;
                }
                if (this.compareArray(this.detailItem, this.selections)) {
                    console.log(this.compareArray(this.detailItem, this.selections))
                }
                    
            }
            // console.log('detailitem', this.detailItem)
            this.$emit('input', this.detailItem);
            this.closeDetail();
        },
        
        closeDetail() {
            this.emptypedialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        
        resetSelections() {
            this.selections = {
                id: null,
                addongrpid: null,
                addongrp: null,
                addongrpval: null,
                action: 0
            }
        },
    },

    created() {
        this.getAddOnGrpListHandler();
    }, 
}
</script>