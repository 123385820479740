<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
            <v-row>
                <v-col cols="3" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('statutoryexportcommonused.statecode')"
                  type="text"
                  v-model="formData.statecode"
                  :items="statecodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
                </v-col>
            </v-row>
           <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-autocomplete
                  outlined
                  :label="this.$t('allepf.new_supplementarypaymentindicator')"
                  type="text"
                  v-model="formData.pindicator"
                  :items="pindicatorList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allepf.sequenceno')"
                type="number"
                v-model="formData.seqno"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-autocomplete
                  outlined
                  :label="this.$t('allepf.testingmode')"
                  type="text"
                  v-model="formData.testmode"
                  :items="testmodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
           </v-row>
          
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getAmbankExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        statecode: '',
        pindicator: '',
        seqno: '',
        testmode: ''
      },
      jsondata: [
        {user: 'hc', name: 'Harry Cole',    phone: '1-415-2345678', gender: 'M', age: 25, birth: '1997-07-01'},
        {user: 'sm', name: 'Simon Minolta', phone: '1-123-7675682', gender: 'M', age: 20, birth: '1999-11-12'},
        {user: 'ra', name: 'Raymond Atom',  phone: '1-456-9981212', gender: 'M', age: 19, birth: '2000-06-11'},
        {user: 'ag', name: 'Mary George',   phone: '1-556-1245684', gender: 'F', age: 22, birth: '2002-08-01'},
        {user: 'kl', name: 'Kenny Linus',   phone: '1-891-2345685', gender: 'M', age: 29, birth: '1990-09-01'}
      ],
      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: this.parentObject.data.epfsubrecpy[0].payoutdesc,
      statecodeList: [
        {
            text: 'Johor',
            value: '01',
        },
        {
            text: 'Kedah',
            value: '02',
        },
        {
            text: 'Kelantan',
            value: '03',
        },
        {
            text: 'Melaka',
            value: '04',
        },
        {
            text: 'Negeri Sembilan',
            value: '05',
        },
        {
            text: 'Pahang',
            value: '06',
        },
        {
            text: 'Pulau Pinang',
            value: '07',
        },
        {
            text: 'Perak',
            value: '08',
        },
        {
            text: 'Perlis',
            value: '09',
        },
        {
            text: 'Selangor',
            value: '10',
        },
        {
            text: 'Terengganu',
            value: '11',
        },
        {
            text: 'Sabah',
            value: '12',
        },
        {
            text: 'Sarawak',
            value: '13',
        },
        {
            text: 'Wilayah Persekutuan (Kuala Lumpur)',
            value: '14',
        },
        {
            text: 'Wilayah Persekutuan (Labuan)',
            value: '15',
        },
        {
            text: 'Wilayah Persekutuan (Putrajaya)',
            value: '16',
        }
      ],
      pindicatorList: [
          {
              text: 'N',
              value: 'N',
          },
          {
              text: 'S',
              value: 'S',
          }
      ],

      testmodeList: [
          {
              text: 'N',
              value: 'N',
          },
          {
              text: 'Y',
              value: 'Y',
          }
      ],

      fillerSpace: "",
      fillerNum: "",
      totalAmtEmployer: null,
      totalAmtMember: null,
      totalRefno: null,

      bodyholder: null,
      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],
      epfdata: [],

      loading: false,

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    
    getAmbankExportListHandler() {
      this.loading = true;
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/ambankepfexport/all/${this.$store.getters.userId}`
        + "/" +  this.data.epfexprecid
       + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.epfdata = res.data.rec;

         vm.exportHandler(); 

      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {
      const vm = this
      let icnoholder = ''
      let holdAmtEmployer = ''
      let holdAmtMember = ''
      let getAmtRefno = ''
      let rec = 0
      let body = []
      let newepfdetail = []
      let holdpayEmployer = []
      let holdpayMember = []
      let holdpayRefno = []
      let dataholder = vm.epfdata
      let dataholderval = dataholder;//.filter(el => el.stfamount)
      let epfdetail = this.filter(dataholderval);

      this.processedCount = dataholderval.length;
      this.successCount = epfdetail.length;
      this.errCount = dataholderval.length - epfdetail.length;
      let yearholder = this.data.year
      let monthholder = this.data.month + 1
      if (monthholder > 12) {
        monthholder = 1
        yearholder++
      }
      let getyear = yearholder.toString()
      let getdate = this.padRJ(monthholder, 2) + getyear
      let currentdate = moment().format('YYYYMMDD')
      let currenttime = moment().format('hhmmss')

      let header = 
        '01' +
        this.padSpaceLJ('EPF MONTHLY FORM A', 20) +
        this.padRJ(this.data.refnum, 19) +
        this.padRJ(getdate, 6) +
        'ITB' +
        '000000009' +
        this.padRJ(this.formData.statecode, 3) +
        this.padSpaceLJ(this.fillerSpace,40) +
        this.padSpaceLJ(this.fillerNum, 20) +
        this.formData.pindicator +
        this.padRJ(this.formData.seqno, 2) +
        currentdate +
        this.padLJ(currenttime, 8) +
        this.padRJ(this.fillerSpace, 16) +
        this.formData.testmode 

      epfdetail.forEach(function (o) {
        var existing = newepfdetail.filter(function (i) {
          return i.staffid === o.staffid;
        })[0];
        if (!existing || existing == undefined) {
          newepfdetail.push(o);
        } else {
          existing.coamount += o.coamount;
          existing.stfamount += o.stfamount;
          existing.stfepfwage += o.stfepfwage;
        }
      });

      newepfdetail.forEach(element => {
        if (element.icno != null) icnoholder = element.icno.split("-").join("")
        
        let coamountholder = element.coamount.toFixed(2);
        let stfamountholder = element.stfamount.toFixed(2);
        let stfepfwageholder = element.stfepfwage.toFixed(2);

        coamountholder = coamountholder.split(".").join("");
        stfamountholder = stfamountholder.split(".").join("");
        stfepfwageholder = stfepfwageholder.split(".").join("");

        let refnoh = ''
        if (element.refno) refnoh = element.refno

        body.push({
          "Details Record Identifier": '02',
          "EPF Number": this.padRJ(refnoh, 19),
          "IC Number": this.padSpaceLJ(icnoholder, 15),
          "Employee Name": this.padSpaceLJ(element.psnname, 80),
          "Reserved": this.padSpaceLJ(this.fillerSpace, 20),
          "Employer Contribution Amount": this.padRJ(coamountholder, 15),
          "Employee Contribution Amount": this.padRJ(stfamountholder, 15),
          "Employee Wages Amount": this.padRJ(stfepfwageholder, 15),
        });

        holdpayEmployer.push(element.coamount);
        holdpayMember.push(element.stfamount);
        if (element.refno) holdpayRefno.push(parseInt(element.refno));
        rec++;
      });

      if (holdpayEmployer.length > 0) {
        let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
        holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
      }

      if (holdpayMember.length > 0) {
        let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtMember = totalAmtMemHolder.toFixed(2);
        holdAmtMember = this.totalAmtMember.split(".").join("");
      }

      if (holdpayRefno.length > 0) {
        getAmtRefno = holdpayRefno.reduce(function (a, b) {
            return a + b;
        }, 0);
      }

      let trailer = 
        '99' +
        this.padRJ(rec, 7) +
        this.padRJ(holdAmtEmployer, 15) +
        this.padRJ(holdAmtMember, 15) +
        this.padRJ(getAmtRefno, 21)

      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body, trailer);
        this.download(txtData, this.titleData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.coamount != 0 || e.stfamount != 0 && e.stfepfwage != 0 
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(header, data, trailer) {
      const csvRows = [];

      const dataheaders = Object.keys(data[0]);
      
      csvRows.push(header);

      //loop over the rows
      for (const row of data) {
        const values = dataheaders.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(trailer);

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmtEmployer = this.totalAmtEmployer;
        item.totalAmtMember = this.totalAmtMember;
        item.accounterr = this.accErr;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        if (!this.isErr) {
          this.$router.push({ name: "monthlyepfexportlog", params: { item } });
        }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlyepfexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
   // this.getAmbankExportListHandler();
    },
};
</script>