    <template>
    <div>
        <v-dialog v-model="movementdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" 
            v-on="on"
            this.parentObject.staffid
            this.parentObject.inspolicyid
            this.parentObject.id>+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('recgrpinsuranceassignment.addetail')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('reportsexportstaffinsurancerec.movdate')"
                            type="date"
                            ref="movdate"
                            :rules="[rules.required]"
                            v-model="selections[0].movdate"
                            dense
                        ></v-text-field>
                    </v-col>
           
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsexportstaffinsurancerec.movtype')"
                            type="text"
                            ref="insurancemovtypeid"
                            dense
                            :rules="[rules.required]"
                            :items="movementTypeList"
                            hide-selected
                            v-model="selections[0].insurancemovtypeid"
                            @change="changeMovementTypeHandler(selections[0].insurancemovtypeid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                       <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.staffname')"
                            type="text"
                            v-model="selections[0].staffname"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                  <v-row 
                  v-if="this.selections[0].insurancemovtypeid == '1' || this.selections[0].insurancemovtypeid == '2' ||
                    this.selections[0].insurancemovtypeid == '3' || this.selections[0].insurancemovtypeid == '4'">

                    <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('recgrpinsuranceassignment.dependentname')"
                            type="text"
                            ref="dependentid"
                            dense
                            background-color="#ffeab2"
                            readonly
                    
                        ></v-text-field>
                    </v-col>
                </v-row> 

                  <v-row v-else>
                    <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('recgrpinsuranceassignment.dependentname')"
                            type="text"
                            ref="dependentid"
                            dense
                            :items="memberList"
                            v-model="selections[0].dependentid"
                             @change="changeMemberHandler(selections[0].dependentid)"
                        ></v-autocomplete>
                    </v-col>
                </v-row> 

                <v-row>
                     <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsexportstaffinsurancerec.gtlcvrage')"
                            type="text"
                            ref="gtlcoveragevalueid"
                            dense
                            :items="GTLcoverageList"
                            v-model="selections[0].gtlcoveragevalueid"
                            @change="changeGTLHandler(selections[0].gtlcoveragevalueid)"
                        ></v-autocomplete>
                    </v-col>
              
                       <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsexportstaffinsurancerec.ghscvrage')"
                            type="text"
                            ref="ghscoveragevalueid"
                            dense
                            :items="GHScoverageList"
                            v-model="selections[0].ghscoveragevalueid"
                            @change="changeGHSHandler(selections[0].ghscoveragevalueid)"
                        ></v-autocomplete>
                    </v-col>
            
                     <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsexportstaffinsurancerec.outpatient')"
                            type="text"
                            ref="outpatientcoveragevalueid"
                            dense
                            :items="outpatientcoverageList"
                            v-model="selections[0].outpatientcoveragevalueid"
                            @change="changeOutPatientHandler(selections[0].outpatientcoveragevalueid)"
                           
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                 <v-row>
                    <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            ref="remark"
                            v-model="selections[0].remark"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="12" class="pb-n4 mt-n8" >
                        <v-checkbox
                           
                            ref="dependentmovement"
                            v-model="selections[0].dependentmovement"
                            :label="this.$t('recgrpinsuranceassignment.dependentmovement')"
                        >
                        </v-checkbox>
                    </v-col> 
                </v-row>
                

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeInsuranceMovementInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveInsuranceMovementInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        <!-- <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      /> 
    </div>-->

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
//import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
       // "app-error": ErrorDialog,
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            movementdialog: false,
            detailItem: this.parentObject.detailItem,
            staffid: this.parentObject.staffid,
            psnid: this.parentObject.psnid,
            inspolicyid: this.parentObject.inspolicyid,
            grpinsassignid: this.parentObject.id,
            
            selections: [{
                id: null,
                grpinsassignid: this.parentObject.id,
                movdate: null,
                insurancemovtypeid: null,
                insurancemovtypedesc: null,
                staffid: null,
                staffcode: null,
                staffname: null,
                dependentid:null,
                dependentname: null,
                gtlcoveragevalueid: null,
                gtlcoveragevalue: null,
                ghscoveragevalueid: null,
                ghscoveragevalue: null,
                outpatientcoveragevalueid: null,
                outpatientcoveragevalue: null,
                remark: null,
                dependentmovement: false,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
                userid: this.$store.getters.userId,
            }],

            movementTypeList:[],
            insurancemovtype:[],

            staffdetails:[],
            GTLcoverageitems: [],
            GTLcoverageList: [],

            outpatientcoverageitems: [],
            outpatientcoverageList: [],

            GHScoverageitems: [],
            GHScoverageList: [],

            memberItems: [],
            memberList: [],


             modify: false,
            isExisting: false,
            isErr: false,
            errMsg: '',
   
        }
    },

    methods: {
        compareArray,

         loadMember(){
             const vm = this;

            this.$store.dispatch('get', {
                url:  "/movementlist/" + this.parentObject.staffid + "/" + this.parentObject.id})
            .then((res) => {

                vm.memberItems = res.data.rec;

                for (let i = 0; i < vm.memberItems.length; i++) {
                    vm.memberList.push({
                        text: vm.memberItems[i].dependentname,
                        value: vm.memberItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeMemberHandler(id){

            let item = this.memberItems.find(item => item.id === id);

            this.selections[0].dependentname = item.dependentname;
        },
        
         loadStaff() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/staff/" + this.parentObject.staffid})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    vm.selections[0].staffid = res.data.rec[0].id;
                    vm.selections[0].staffname = res.data.rec[0].psnname;
                    vm.selections[0].staffcode = res.data.rec[0].staffcode;

                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadGTLCoverageList(){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/gtlcoveragelist/" + this.parentObject.inspolicyid})
                .then(res => {
                    //req success --> push res into array
                    vm.GTLcoverageitems = res.data.rec;

                for (let i = 0; i < vm.GTLcoverageitems.length; i++) {
                    vm.GTLcoverageList.push({
                        text: vm.GTLcoverageitems[i].coveragevalue,
                        value: vm.GTLcoverageitems[i].id,
                    });
                }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });

        },

         changeGTLHandler(id){

            let item = this.GTLcoverageitems.find(item => item.id === id);

            this.selections[0].gtlcoveragevalue = item.coveragevalue;
        },

        
        loadGHSCoverageList(){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/ghscoveragelist/" + this.parentObject.inspolicyid})
                .then(res => {
                    //req success --> push res into array
                    vm.GHScoverageitems = res.data.rec;

                for (let i = 0; i < vm.GHScoverageitems.length; i++) {
                    vm.GHScoverageList.push({
                        text: vm.GHScoverageitems[i].coveragevalue,
                        value: vm.GHScoverageitems[i].id,
                    });
                }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeGHSHandler(id){

            let item = this.GHScoverageitems.find(item => item.id === id);

            this.selections[0].ghscoveragevalue = item.coveragevalue;
        },

        
        loadOutpatientCoverageList(){

              const vm = this;

            this.$store.dispatch('get', {
                url:  "/outpatientcoveragelist/" + this.parentObject.inspolicyid})
                .then(res => {
                    //req success --> push res into array
                    vm.outpatientcoverageitems = res.data.rec;

                for (let i = 0; i < vm.outpatientcoverageitems.length; i++) {
                    vm.outpatientcoverageList.push({
                        text: vm.outpatientcoverageitems[i].coveragevalue,
                        value: vm.outpatientcoverageitems[i].id,
                    });
                }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeOutPatientHandler(id){

            let item = this.outpatientcoverageitems.find(item => item.id === id);

            this.selections[0].outpatientcoveragevalue = item.coveragevalue;
        },

         loadMovementType(){
             const vm = this;

            this.$store.dispatch('get', {
                url:  '/insurancemovementtype/all'})
            .then(res => {

                vm.insurancemovtype = res.data.rec;

                for (let i = 0; i < vm.insurancemovtype.length; i++) {
                    vm.movementTypeList.push({
                        text: vm.insurancemovtype[i].insurancemovtype,
                        value: vm.insurancemovtype[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

           changeMovementTypeHandler(id){

            let item = this.insurancemovtype.find(item => item.id === id);

            this.selections[0].insurancemovtypedesc = item.insurancemovtype;
        },


        modifyItemHandler(item) {
          // assign loaded data to selections array
            
            if(item.action == 1) {
                  this.modify = true;    
             }

             if(item.action == 2) {
                  this.isExisting = true;    
            }

              this.selections[0] =  item;
              this.movementdialog = true;

               if (!this.isExisting){
                // duplicate this.detailItem for duplicate checking
                
                //find index 
                
                var index = this.detailItem.findIndex( x => x.id == item.id);
                //console.log('index', index)
                this.detailItem[index].movdate = item.movdate;
                this.detailItem[index].dependentid = item.dependentid;
                this.detailItem[index].insurancemovtypeid = item.insurancemovtypeid;
                this.detailItem[index].staffid = item.staffid;
                this.detailItem[index].dependentid = item.dependentid;
                this.detailItem[index].gtlcoveragevalueid = item.gtlcoveragevalueid;
                this.detailItem[index].ghscoveragevalueid = item.ghscoveragevalueid;
                this.detailItem[index].outpatientcoveragevalueid = item.outpatientcoveragevalueid;
                this.detailItem[index].remark = item.remark;

            } else {
                this.selections[0].action = 2;

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }

          },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeInsuranceMovementInput() {
            this.movementdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;
    

            // reset the form fields
            this.resetSelections();
        },  

        saveInsuranceMovementInput() {

            if (this.selections[0].movdate == null || this.selections[0].insurancemovtypeid == null){

                    this.errMsg = this.$t('recgrpinsuranceassignment.message5');
                    this.isErr = true;
                    this.movementdialog = true;

                    console.log('movementdialog',this.movementdialog)
            } else {

            this.isErr = false;
            
             if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            
               this.$emit('input', this.detailItem);
               this.movementdialog = false;
            
              // reset the form fields
               this.resetSelections();
            }
            
           },

        
        resetSelections() {
            console.log('selections',this.selections[0])
            this.selections = [{
                id: null,
                movdate: null,
                grpinsassignid: this.parentObject.id,
                insurancemovtypeid: null,
                insurancemovtypedesc: null,
                staffid: this.parentObject.staffid,
                staffcode: null,
                staffname: this.selections[0].staffname,
                dependentid:null,
                dependentname: null,
                gtlcoveragevalueid: null,
                gtlcoveragevalue: null,
                ghscoveragevalueid: null,
                ghscoveragevalue: null,
                outpatientcoveragevalueid: null,
                outpatientcoveragevalue: null,
                remark: null,
                dependentmovement: false,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]
        },
    },

    created() {
        this.loadMovementType();
        this.loadStaff();
        this.loadGTLCoverageList();
        this.loadGHSCoverageList();
        this.loadOutpatientCoverageList();
        this.loadMember();
       // this.loadVehicle();
    }
}
</script>   


       