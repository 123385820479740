<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>



import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('allptptn.monthlyptptnsubmissionrec'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width: '80px'},
                {text: this.$t('allptptn.ptptnsubmissionrecord'), value: 'ptptnsubreccode', width: '180px'},
                {text: this.$t('columns.description'), value: 'ptptnsubrecdesc', width: '200px'},
                {text: this.$t('reportsparam.year'), value: 'recyear', width: '120px'},
                {text: this.$t('reportsparam.month'), value: 'recmonth', width: '120px'},
                // { text: 'APPROVAL NAME',                value: 'aprname',              width: '200px' },
                // { text: 'APPROVAL IC NO',               value: 'apricno',              width: '200px' },
                // { text: 'APPROVAL TEL',                 value: 'aprtel',              width: '200px' },
                // { text: 'APPROVAL EMAIL',               value: 'apremail',              width: '200px' },
                // { text: 'APPROVAL DESIGNATION',         value: 'aprdesignation',              width: '150px' },
                
            ],
            dataItems: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {

        getPtptnSubRecs() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/ptptnsubmissionrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;


                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },
        

        editItemHandler(item) {
            this.$router.push({name: 'monthlyptptnsubmissionrecordedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'monthlyptptnsubmissionrecordnew'});
        }
    },

    mounted() {

        this.getPtptnSubRecs();
        
    }
}
</script>