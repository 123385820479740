<template>
    <div>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
      ></loading>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="getAffinExportListHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
      Loading,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          nonpastdate: (value) => {
            var today = new Date();
            today.setHours(0,0,0,0);
            return new Date(value) >= today || this.$t('reportsparam.todayandfuturedate')
          }
        },
  
        id: this.parentObject.id,
        data: this.parentObject.data,
        salstatutoryother: null,
        psnstatutoryinfo: [],
        psnpayroll: [],
        titleData: this.parentObject.data.epfsubrecpy[0].payoutdesc,
        statecodeList: [
          {
              text: 'Johor',
              value: '01',
          },
          {
              text: 'Kedah',
              value: '02',
          },
          {
              text: 'Kelantan',
              value: '03',
          },
          {
              text: 'Melaka',
              value: '04',
          },
          {
              text: 'Negeri Sembilan',
              value: '05',
          },
          {
              text: 'Pahang',
              value: '06',
          },
          {
              text: 'Pulau Pinang',
              value: '07',
          },
          {
              text: 'Perak',
              value: '08',
          },
          {
              text: 'Perlis',
              value: '09',
          },
          {
              text: 'Selangor',
              value: '10',
          },
          {
              text: 'Terengganu',
              value: '11',
          },
          {
              text: 'Sabah',
              value: '12',
          },
          {
              text: 'Sarawak',
              value: '13',
          },
          {
              text: 'W. Persekutuan',
              value: '14',
          },
        ],
  
        fillerSpace: "",
        fillerNum: "",
        totalAmtEmployer: null,
        totalAmtMember: null,
        totalRefno: null,
  
        bodyholder: null,
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
        epfdata: [],
  
        loading: false,
  
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
      
      getAffinExportListHandler() {
        const vm = this
  
        let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder2).format("YYYY-MM-DD");
        
        this.$store.dispatch('get', {
            url:  `/hsbcepfexport/all/${this.$store.getters.userId}`
          + "/" +  this.data.epfexprecid
         + "/" + this.data.refnum + "/" + date + "/" + date
        })
        .then(function (res) {
          vm.loading = false;
          vm.epfdata = res.data.rec;
          vm.exportHandler();
        })
        .catch(function (err) {
          vm.loading = false
          vm.errMsg = err;
        });
      },
  
      async getData() {
        const vm = this
        let holdAmtEmployer = 0
        let holdAmtMember = 0
        let rec = 0
        let body = []
        let newepfdetail = []
        let holdpayEmployer = []
        let holdpayMember = []
        let holdpayRefno = []
        let dataholder = vm.epfdata
        let dataholderval = dataholder;//.filter(el => el.stfamount)
        let epfdetail = this.filter(dataholderval);
  
        this.processedCount = dataholderval.length;
        this.successCount = epfdetail.length;
        this.errCount = dataholderval.length - epfdetail.length;
        // let yearholder = this.data.year
        // let monthholder = this.data.month + 1
        // if (monthholder > 12) {
        //   monthholder = 1
        //   yearholder++
        // }
        // let getyear = yearholder.toString()
        // let getdate = this.padRJ(monthholder, 2) + getyear
        // let currentdate = moment().format('YYYYMMDD')
        // let currenttime = moment().format('hhmmss')
        let contdateholder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
        let contdate = moment(contdateholder).add(1, 'months').format("MMYYYY");
  
        epfdetail.forEach(function (o) {
          var existing = newepfdetail.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            newepfdetail.push(o);
          } else {
            existing.coamount += o.coamount;
            existing.stfamount += o.stfamount;
            existing.stfepfwage += o.stfepfwage;
          }
        });
  
        newepfdetail.forEach(element => {
          
          let coamountholder = element.coamount.toFixed(2);
          let stfamountholder = element.stfamount.toFixed(2);
          let stfepfwageholder = element.stfepfwage.toFixed(2);
  
          coamountholder = coamountholder.split(".").join("");
          stfamountholder = stfamountholder.split(".").join("");
          stfepfwageholder = stfepfwageholder.split(".").join("");
          
          body.push({
            "Record ID": this.padSpaceRJ('02', 2),
            "Employee EPF No": element.refno ? this.padRJ(element.refno.replace(/-/g, ''), 19) : this.padRJ(0, 19),
            "Employee ID No": this.padSpaceLJ(element.icno.replace(/-/g, ''), 15),
            "Employee Name": this.padSpaceLJ(this.limitStringLength(element.psnname, 40), 40),
            "Employee Initials": this.padSpaceLJ(element.icno.replace(/-/g, ''), 20),
            "Employer Contribution Amount": this.padRJ(coamountholder, 9),
            "Employee Contribution Amount": this.padRJ(stfamountholder, 9),
            "Employee's Wages": this.padRJ(stfepfwageholder, 15),
          });
  
          holdpayEmployer.push(element.coamount);
          holdpayMember.push(element.stfamount);
          if (element.refno) holdpayRefno.push(parseInt(element.refno));
          rec++;
        });
  
        if (holdpayEmployer.length > 0) {
          let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
          this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
          holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
        }
  
        if (holdpayMember.length > 0) {
          let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
          this.totalAmtMember = totalAmtMemHolder.toFixed(2);
          holdAmtMember = this.totalAmtMember.split(".").join("");
        }
  
        let getAmtRefno = 0
        if (holdpayRefno.length > 0) {
          getAmtRefno = holdpayRefno.reduce(function (a, b) {
              return a + b;
          }, 0);
        }
        // let totalamount = 0
        // totalamount = parseInt(this.totalAmtMember) + parseInt(this.totalAmtEmployer)

        let header = 
          '00' +
          this.padSpaceRJ('EPF Monthly Form A', 18) +
          this.padSpaceRJ(moment().format('YYYYMMDD'), 8) +
          this.padSpaceRJ('00001', 5) +
          this.padRJ(holdAmtEmployer, 15) +
          this.padRJ(holdAmtMember, 15) +
          this.padRJ(this.data.refnum.replace(/-/g, ''), 21) +
          this.padSpaceRJ(' ', 45);

        let header2 = 
          '01' +
          this.padRJ(this.data.refnum.replace(/-/g, ''), 19) +
          this.padSpaceRJ(contdate, 6) +
          this.padSpaceRJ('ITB', 3) +
          this.padSpaceRJ('00001', 5) +
          this.padSpaceRJ('0000', 4) +
          this.padSpaceRJ('0000', 4) +
          this.padSpaceLJ(' ', 7) +
          this.padSpaceRJ(' ', 79);
  
        let footer = 
          '99' +
          this.padRJ(rec, 7) +
          this.padRJ(holdAmtEmployer, 15) +
          this.padRJ(holdAmtMember, 15) +
          this.padRJ(getAmtRefno, 21) +
          this.padSpaceRJ(' ', 69);
  
        if (body.length > 0) {
          const txtData = this.objectToTxt(header, header2, body, footer);
          this.download(txtData);
        }
      },
  
      filter(item) {
        let checkdet = this.detailfilter(item)
        let checkactive = this.activefilter(checkdet)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      detailfilter(item) {
          let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
          + this.daysInMonth(this.data.month, this.data.year);
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let dataitem = item.filter(e => e.refnum == this.data.refnum)
          dataitem = dataitem.filter(e => {
              return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
          })
          let noacc = item.filter((e) => !dataitem.includes(e));
          for (let i = 0; i < noacc.length; i++) {
              noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
              this.accErr.push(noacc[i]);
          }
          return dataitem
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => {
            return e.coamount != 0 || e.stfamount != 0 && e.stfepfwage != 0 
        })
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToTxt(header, header2, data, footer) {
        const csvRows = [];
  
        const dataheaders = Object.keys(data[0]);
        
        csvRows.push(header);
        csvRows.push(header2);
  
        //loop over the rows
        for (const row of data) {
          const values = dataheaders.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          csvRows.push(values.join(""));
        }
  
        csvRows.push(footer);
  
        return csvRows.join("\n");
      },
  
      download(data) {
        const newFile = this.convertLFtoCR_LF(data)
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([newFile], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", 'K' + this.data.refnum.replace(/-/g, '') + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },

      convertLFtoCR_LF(fileContent) {
        const lines = fileContent.split("\n");
        const newLines = [];
        
        for (let i = 0; i < lines.length; i++) {
          newLines.push(lines[i].concat("\r\n"));
        }
        
        return newLines.join("");
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },

      limitStringLength(string, maxLength) {
          // Check if the length of the string exceeds the maximum length
          if (string.length > maxLength) {
              // If it does, truncate the string to the specified maximum length
              return string.slice(0, maxLength);
          } else {
              // If not, return the original string
              return string;
          }
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalAmtEmployer = this.totalAmtEmployer;
          item.totalAmtMember = this.totalAmtMember;
          item.accounterr = this.accErr;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
          if (!this.isErr) {
            this.$router.push({ name: "monthlyepfexportlog", params: { item } });
          }
        }
      },
  
      backHandler() {
        this.$router.push({ name: "monthlyepfexport" });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
    mounted() {
      // this.getAffinExportListHandler();
    },
  };
  </script>