<template>

    <div>

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-row>
            <v-col cols="1" sm="2" md="2">
                <v-btn block color="primary" @click="goBack()">
                {{$t('button.back')}}
                </v-btn>
            </v-col>
            <v-col cols="10" sm="10" md="10">
                    <h3 class="blue--text mt-2" >{{$t('selfserviceclaimapproval.claimapproval')}}</h3>
            </v-col>            
        </v-row>

        <app-claim-details 
            v-bind:claimrecordItems="this.claimrecordItems"
            v-on:claim-display-details="routeClaimDetails"
        />

    </div>

</template>

<script>

import Loading from "vue-loading-overlay";
import ClaimDetails from '../../sscomponents/claimapproval/ClaimDetails.vue'
import moment from 'moment'

export default {

    components: {
        "app-claim-details": ClaimDetails,
        Loading
    },

    data() {
        return {
            loading: false,
            claimRecord: [],
            claimrecordItems: []
        }
    },

    methods: {
        goBack() {
            this.$router.push({name: 'selfservicemytask'});
        },

        routeClaimDetails(info) {
            if (info.receiptattach) {
                let stringh = info.receiptattach
                
                let ftypeHolder = stringh.match('application')
                let ftypeHolder2 = stringh.match('image')
                
                if (ftypeHolder == 'application') { info.filetype = 'PDF' } 
                if (ftypeHolder2 == 'image') { info.filetype = 'Image' }
            }
            info.receiptdate = moment(info.receiptdate).format('DD-MM-YYYY');
            this.$router.push({name: 'selfserviceclaimapprovaldetails', params: { item: info }});
        },

        loadClaimRecordDetails(param) {
            const vm = this;
            vm.loading = true;

            this.claimrecordItems = [];
            this.$store.dispatch('get', {url: "/wftrand/record/" + param + "/" + this.$store.getters.staffId})
            .then((res) => {
                const result = res.data

                if (result.status === 200) {
                    let item = result.rec

                    for (let i = 0; i < item.length; i++) {
                    item[i].entrytime = this.claimRecord.entrytime
                    item[i].reqstaffid = this.claimRecord.reqstaffid
                    item[i].maxreminder = this.claimRecord.maxreminder
                    item[i].wfdueactionid = this.claimRecord.wfdueactionid
                    item[i].remindnext = this.claimRecord.remindnext
                    item[i].level = this.claimRecord.level
                    item[i].hrlast = this.claimRecord.hrlast
                    item[i].exception = this.claimRecord.exception
                    let claimheader = this.claimrecordItems.filter(val => val.id  == item[i].clmtypeid)[0]

                    if (!claimheader) {
                        this.claimrecordItems.push({
                        id: item[i].clmtypeid,
                        clmtype: item[i].clmtype,
                        clmtypdesc: item[i].clmtypdesc,
                        clmrecordd: []
                        })
                    } 
                    item[i].displaydate = moment(item[i].receiptdate).format('DD-MM-YYYY')
                    item[i].active = false
                    let index = this.claimrecordItems.findIndex(val => val.id == item[i].clmtypeid)
                    this.claimrecordItems[index].clmrecordd.push(item[i])
                    }

                }

            vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });
        }
    },

    mounted() {

        // From my claim approval page on load data
        if(this.$route.params.item) {
            this.claimRecord = this.$route.params.item
            this.loadClaimRecordDetails(this.claimRecord.wftranid)
        }

        // From my claim approval details page on load data (Back Button Claim Approval Details )
        if(this.$route.params.itemfromapprovaldetails) {
            this.claimRecord = this.$route.params.itemfromapprovaldetails
            this.loadClaimRecordDetails(this.claimRecord.wftranid)
        }

        // this.claimRecord = this.$route.params.item;
        // this.claimRecord = this.$route.params.itemfromapprovaldetails;

    }
}

</script>

<style scoped>
p {
    font-size: 9px;
}
.dark-bg {
    background-color: #F4F4F4;
}
</style>