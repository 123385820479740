<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:cancel="cancelHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import LeaveSetupForm from "../../../components/leave/leavesetup/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": LeaveSetupForm,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('setupleavesetup.newleavesetup'),
      pageTitleValue: "",
      formData: {
        leavecode: null,
        leavedesc: null,
        parentid: null,
        ptypeid: null,
        startmth: null,
        unittype: null,
        dispunit: null,
        hrperday: null,
        remark: null,
        ispaid: false,
        isearn: false,
        isonce: false,
        ismed: false,
        isincloff: false,
        isinclrest: false,
        isinclhol: false,
        isconfirmed: false,
        isreasoncompul: false,
        isreliefcompul: false,
        iscliniccompu: false,
        isparent: false,
        isencash: false,
        isnolimit: false,
        minpertran: null,
        maxpertran: null,
        earlynotice: null,
        cancf: false,
        maxunitcf: null,
        maxperccf: null,
        maxpercentcf: null,
        bfmth: null,
        usecfpolicy: false,
        dependleaveid: null,
        dependminunit: null,
        rectimestamp: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      console.log(this.formData)

      // Convert boolean to integer. 1 = true, 0 = false
      if (this.formData.ispaid == false) {
        this.formData.ispaid = 0;
      } else {
        this.formData.ispaid = 1;
      }

      if (this.formData.isearn == false) {
        this.formData.isearn = 0;
      } else {
        this.formData.isearn = 1;
      }

      if (this.formData.isonce == false) {
        this.formData.isonce = 0;
      } else {
        this.formData.isonce = 1;
      }

      if (this.formData.ismed == false) {
        this.formData.ismed = 0;
      } else {
        this.formData.ismed = 1;
      }

      if (this.formData.isincloff == false) {
        this.formData.isincloff = 0;
      } else {
        this.formData.isincloff = 1;
      }

      if (this.formData.isinclrest == false) {
        this.formData.isinclrest = 0;
      } else {
        this.formData.isinclrest = 1;
      }

      if (this.formData.isinclhol == false) {
        this.formData.isinclhol = 0;
      } else {
        this.formData.isinclhol = 1;
      }

      if (this.formData.isconfirmed == false) {
        this.formData.isconfirmed = 0;
      } else {
        this.formData.isconfirmed = 1;
      }

      if (this.formData.isreasoncompul == false) {
        this.formData.isreasoncompul = 0;
      } else {
        this.formData.isreasoncompul = 1;
      }

      if (this.formData.isreliefcompul == false) {
        this.formData.isreliefcompul = 0;
      } else {
        this.formData.isreliefcompul = 1;
      }

      if (this.formData.iscliniccompu == false) {
        this.formData.iscliniccompu = 0;
      } else {
        this.formData.iscliniccompu = 1;
      }

      if (this.formData.isparent == false) {
        this.formData.isparent = 0;
      } else {
        this.formData.isparent = 1;
      }

      if (this.formData.isencash == false) {
        this.formData.isencash = 0;
      } else {
        this.formData.isencash = 1;
      }

      if (this.formData.isnolimit == false) {
        this.formData.isnolimit = 0;
      } else {
        this.formData.isnolimit = 1;
      }

      if (this.formData.cancf == false) {
        this.formData.cancf = 0;
      } else {
        this.formData.cancf = 1;
      }

      if (this.formData.usecfpolicy == false) {
        this.formData.usecfpolicy = 0;
      } else {
        this.formData.usecfpolicy = 1;
      }

      this.$store.dispatch('post', {url: "/leavesetup/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavesetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "leavesetuplist" });
    },
  },
};
</script>