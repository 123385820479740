<template>
    <div id="`eissublist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';


import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('trainingrecord.trainingrecordlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '80px', sortable: false},
                { text: this.$t('columns.staffid'), value: 'staffno', width: '150px'},
                { text: this.$t('columns.name'), value: 'staffname', width: '200px' },
                { text: this.$t('attendancereportbypeiordbycompanyreport.companyname'), value: 'compnycode', width: '90px' },
                { text: this.$t('trainingrecord.trainingcoursename'), value: 'trncoursename', width: '200px' },
                { text: this.$t('trainingrecord.trainingcoursecategory'), value: 'trncoursecatname', width: '200px' },

                { text: this.$t('columns.startdate'), value: 'startdate', width: '200px'},
                { text: this.$t('columns.enddate'), value: 'enddate', width: '200px' },
                { text: this.$t('trainingrecord.recertificationdate'), value: 'recertification', width: '200px' },
                { text: this.$t('trainingrecord.traininghours'), value: 'trnhours', width: '200px' },
                { text: this.$t('trainingrecord.venue'), value: 'venue', width: '200px' },

                { text: this.$t('trainingrecord.elearning'), value: 'Iselearning', width: '80px'},
                { text: this.$t('trainingrecord.assessmentresult'), value: 'result', width: '200px' },
                { text: this.$t('trainingrecord.amountclaimed'), value: 'hrdfamtclaimed', width: '200px' },
                { text: this.$t('trainingrecord.amountclaimedcurrency'), value: 'hrdfamtclaimedcrcycode', width: '200px' },
                { text: this.$t('trainingrecord.submittedon'), value: 'hrdfclaimeddate', width: '200px' },

                { text: this.$t('trainingrecord.amountapproved'), value: 'hrdfamtapproved', width: '200px'},
                { text: this.$t('trainingrecord.amountapprovedcurrency'), value: 'hrdfamtapprovedcrcycode', width: '200px' },
                { text: this.$t('trainingrecord.approvedon'), value: 'hrdfapproveddate', width: '200px' },
                { text: this.$t('trainingrecord.hrdfscheme'), value: 'hrdfscheme', width: '200px' },

    
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/trainingrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                        }


                        if (vm.dataItems[i].hrdfclaimeddate) {
                        vm.dataItems[i].hrdfclaimeddate = moment(vm.dataItems[i].hrdfclaimeddate).format('YYYY-MM-DD');
                        }

                    if (vm.dataItems[i].hrdfapproveddate) {
                        vm.dataItems[i].hrdfapproveddate = moment(vm.dataItems[i].hrdfapproveddate).format('YYYY-MM-DD');
                        }

                         if (vm.dataItems[i].recertification) {
                        vm.dataItems[i].recertification = moment(vm.dataItems[i].recertification).format('YYYY-MM-DD');
                        }

                }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'trainingrecordedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'trainingrecordnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>