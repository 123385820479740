<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: 'GL Code Mapping Listing',
            dataHeader: [
                {text: 'ACTION', value: 'action', sortable: false, width:'80px'},
                {text: 'MAPPING POLICY', value: 'policycode', width: '200px'},
                {text: 'MAPPING POLICY DESCRIPTION', value: 'policydesc', width: '200px'},
                {text: 'START DATE', value: 'startdate', width: '200px'},
                {text: 'END DATE', value: 'enddate', width: '150px'},
            ],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadGLCodeMappingPolicyListItems() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payglcodemappingpolicylist/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }
                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'glcodemappinglistingedit', params: { id: item.id, search: item.search  } });
        },

        newRecordHandler() {
            this.$router.push({name: 'glcodemappinglistingnew'});
        }
    },

    mounted() {
        this.loadGLCodeMappingPolicyListItems();
    }
}
</script>