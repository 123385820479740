<template>
  <div>
      <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <!-- <v-toolbar-title class="subtitle-2">No Parameter</v-toolbar-title> -->
           <v-row>
            <v-col cols="6" sm="6" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('columns.email')"
                type="text"
                v-model="formData.email"
                :rules="[rules.counter2]"
                maxlength='40'
                dense
              ></v-text-field>
            </v-col>
          </v-row>

            <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactpersonname')"
                type="text"
                v-model="formData.conpsnname"
                maxlength='25'
                :rules="[rules.required, rules.counter3]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactphonenumber')"
                type="number"
                v-model="formData.conpsnno"
                maxlength='15'
                :rules="[rules.required, rules.counter1]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getAmbankExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter2: (value) => value.length <= 40 || this.$t('reportsparam.counter40'),
        counter1: (value) => value.length <= 15 || this.$t('reportsparam.counter15'),
        counter3: (value) => value.length <= 25 || this.$t('reportsparam.counter25'),
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      refnum: this.parentObject.refnum,
      taxexprecid: this.parentObject.taxexprecid,
      year: this.parentObject.year,
      month: this.parentObject.month,
      formData: {
        email: '',
        conpsnname: '',
        conpsnno: '',
        
      },
      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: this.parentObject.data.taxsubrecpy[0].payoutdesc,
      
      filler: "",
      totalAmtStfpcb: null,
      totalAmtCp38: null,

      accErr: [],
      taxdata: [],

      taxdataholder: [],

      loading: false,

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    
    getAmbankExportListHandler() {
      this.loading = true;
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");

      // console.log('date', this.data.year)
      // console.log('this.parentObject.taxexprecid', this.data.taxexprecid)
      // console.log('data', this.parentObject.data)
      
      
      this.$store.dispatch('get', {
          url: `/ambanktaxexport/all/${this.$store.getters.userId}`
          + "/" +  this.data.taxexprecid
          + "/" + this.data.refnum + "/" + date + "/" + date
        })
      .then(function (res) {

        vm.loading = false;

        vm.taxdataholder = res.data.rec;

        vm.exportHandler();

    
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {
      const vm = this


     // let newtaxdetail = vm.taxdataholder
      let recstfpcb = 0
      let reccp38 = 0
      let body = []
      let newtaxdetail = []
      let holdpaystfpcb = []
      let holdpaycp38 = []
      let dataholder = vm.taxdataholder
      let taxdetail = this.filter(dataholder);
      let filler = ''

      this.processedCount = dataholder.length;
      this.successCount = taxdetail.length;
      this.errCount = dataholder.length - taxdetail.length;
      //  let yearholder = this.data.year
      // let monthholder = this.data.month + 1
      // if (monthholder > 12) {
      //   monthholder = 1
      //   yearholder++
      // }

      // let getyear = yearholder.toString()
      // let getdate = this.padRJ(monthholder, 2) + getyear
      //let refnumholder = vm.data.taxsubrecref[0].refnum.replace(/\D/g, "");

       taxdetail.forEach(function (o) {
        var existing = newtaxdetail.filter(function (i) {
          return i.staffcode === o.staffcode;
        })[0];
        if (!existing || existing == undefined) {
          newtaxdetail.push(o);
        } else {
          existing.stfpcb += o.stfpcb;
          existing.cp38 += o.cp38;
        }
      });

      newtaxdetail.forEach(element => {
        let icnoholder = ''
        let oldicnoholder  = ''
        let docno = ''
        let refno = ''
        
        // let refnumget = ''
        // let hqtaxnumget  = ''
        let wifecodeholder = 0
        if (element.icno) icnoholder = element.icno.split("-").join("")
        if (element.oldicno) oldicnoholder = element.oldicno
        if (element.docno) docno = element.docno
        if (element.refno) {
            refno = element.refno.split("SG").join("");
            refno = refno.split("OG").join("");
            refno = refno.split("IG").join("");
            refno = refno.split("-").join("");
            refno = refno.split(" ").join("");
          //  refno = refno.substring
            
            let wifecode = element.refno.substring(element.refno.length-1, element.refno.length)
            if (parseInt(wifecode) > 0) wifecodeholder = wifecode
            refno = refno.substring(0, 10)

          //  let refnoHolder = element.refno.substring(0,10)
        }

        let stfpcbholder = element.stfpcb.toFixed(2)
        stfpcbholder = stfpcbholder.split(".").join("")

        let cp38holder = element.cp38.toFixed(2)
        cp38holder = cp38holder.split(".").join("")

        body.push({
          "Record Type": 'D',
          "Tax Ref No": this.padLJ(refno, 10),
          "Wife Code": this.padRJ(wifecodeholder, 1),
          "Employee Name": this.padSpaceLJ(element.psnname, 60),
          "Old IC No.": this.padSpaceLJ(oldicnoholder, 9),
          "Filler" : this.padSpaceLJ(filler, 3),
          "New IC No.": this.padSpaceLJ(icnoholder, 12),
          "Passport No.": this.padSpaceLJ(docno, 12),
          "Country Code": this.padRJ(element.ntlycode, 2),
          "PCB Amount": this.padRJ(stfpcbholder, 8),
          "CP38 Amount": this.padRJ(cp38holder, 8),
          "Staff No.": this.padSpaceLJ(element.staffcode, 10)
        });

        holdpaystfpcb.push(element.stfpcb);
        holdpaycp38.push(element.cp38);
        if (element.stfpcb != 0) recstfpcb++;
        if (element.cp38 != 0) reccp38++
      });

      if (holdpaystfpcb) {
        let totalAmtStfpcbHolder = holdpaystfpcb.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtStfpcb = totalAmtStfpcbHolder.toFixed(2);
      }

      if (holdpaycp38) {
        let totalAmtCp38Holder = holdpaycp38.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtCp38 = totalAmtCp38Holder.toFixed(2);
      }

      let totalAmtStfpcbholder = this.totalAmtStfpcb.split(".").join("")
      let totalAmtCp38holder = this.totalAmtCp38.split(".").join("")

      // if (element.hqtaxnum != null) hqtaxnumget = element.hqtaxnum.replace(/\D/g, "");
      // if (element.taxrefnum != null) refnumget = element.taxrefnum.replace(/\D/g, "");
     // let hqtaxnumget = this.data.refnum.replace(/\D/g, "");
     // let refnumget = vm.data.taxsubrecref[0].refnum.replace(/\D/g, "");

      console.log('newtaxdetail', newtaxdetail)
      
      let header = 
        'H' +
        this.padSpaceRJ(filler, 10) +
        this.padRJ((this.data.refnum.replace(/\D/g, "")), 10) +
        this.padRJ(this.data.year, 4) +
        this.padRJ(this.data.month, 2) +
        this.padRJ(totalAmtStfpcbholder, 10) +
        this.padRJ(recstfpcb, 5) +
        this.padRJ(totalAmtCp38holder, 10) +
        this.padRJ(reccp38, 5)+
        this.padSpaceLJ(this.formData.email, 40) +
        this.padSpaceLJ(this.formData.conpsnno, 15) +
        this.padSpaceLJ(this.formData.conpsnname, 25)


      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body);
        this.download(txtData, this.titleData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.stfpcb != 0 || e.cp38 != 0 
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(header, data) {
      const csvRows = [];

      const dataheaders = Object.keys(data[0]);

      csvRows.push(header);

      //loop over the rows
      for (const row of data) {
        const values = dataheaders.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
       if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
      
      this.getData();
      let item = [];
      item.totalAmtstfpcb = this.totalAmtStfpcb;
      item.totalAmtcp38 = this.totalAmtCp38;
      item.accounterr = this.accErr;
      item.successCount = this.successCount;
      item.processedCount = this.processedCount;
      item.errCount = this.errCount;
      console.log('item', item.totalAmtstfpcb)
      if (!this.isErr) {
        this.$router.push({ name: "monthlytaxexportlog", params: { item } });
      }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlytaxexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
   // this.getAmbankExportListHandler();
  },
};
</script>