<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                > 

                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('attendancerecordsandprocesses.selectgroup')"
                                type="text"
                                ref= "grpvalueid"
                                :items="wdaygrpList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.grpvalueid"
                                @change ="changeGroupHandler(formData.grpvalueid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                         <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('attendancerecordsandprocesses.selectgroup')"
                                type="text"
                                ref= "grpvalueid"
                                :items="wdaygrpList"
                                :rules="[rules.required]"
                                v-model="formData.grpvalueid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('attendancerecordsandprocesses.groupvaluedescription')"
                                id="wdaygrpdesc"
                                type="text"
                                ref="wdaygrdesc" 
                                background-color="#ffeab2"
                                readonly
                                v-model="wdaygrpdesc" 
                                dense                       
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.assignmentdetail,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object,
    },

    components: {
        "app-table": CRUDTable,
    },

    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                },
            },

            formData: {
                grpvalueid: this.parentObject.data.grpvalueid,
                assignmentdetail: this.parentObject.data.assignmentdetail,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setupholidaysetup.holidaycode'), value: 'holcode', width: '300px', sortable: false},
                { text: this.$t('columns.description'), value: 'holdesc', width: '600px', sortable: false },
    
            ],

            wdaygrpList: [],
            wdaygrpdesc: '',
            valvalue: [],


            errMsg: "",
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        };
    },

    methods: {

         loadValvalueList() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/groupp_grouppb_holiday/all'})
                .then(res => {
                    vm.valvalue = res.data.rec;
                    console.log('value', vm.valvalue)

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.wdaygrpList.push(
                                {
                                    text: res.data.rec[i].valuecode,
                                    value: res.data.rec[i].valvalueid
                                }
                            )    
                        }
                    }

                    if (this.formData.grpvalueid) {
                        this.changeGroupHandler(this.formData.grpvalueid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeGroupHandler(valvalueid) {
            let item = this.valvalue.find(item => item.valvalueid === valvalueid);

            this.wdaygrpdesc = item.valuedesc;
        },

        addItemHandler($event) {
            this.formData.assignmentdetail = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit("input", {
                grpvalueid: this.formData.grpvalueid,
                assignmentdetail: this.formData.assignmentdetail,
                search: this.formData.search,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
           this.$router.push({name: 'holidayassignmentlist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadValvalueList();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    },
};
</script>