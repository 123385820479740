<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
//import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('setuppasswordpolicy.passwordpolicylist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                {text: this.$t('columns.usertype'), value: 'usertype', width: '150px'},
                {text: this.$t('setuppasswordpolicy.generation'), value: 'pwdgen', width: '130px'},
                {text: this.$t('setuppasswordpolicy.minlength'), value: 'pwdmin', width: '100px'},
                {text: this.$t('setuppasswordpolicy.maxlength'), value: 'pwdmax', width: '100px'},
                {text: this.$t('setuppasswordpolicy.expirydays'), value: 'expirydays', width: '100px'},
                {text: this.$t('setuppasswordpolicy.longinactivitydays'), value: 'inactivitydays', width: '100px'},
                {text: this.$t('setuppasswordpolicy.maxattempt'), value: 'maximumattempt', width: '110px'},
                {text: this.$t('setuppasswordpolicy.alphanumeric'), value: 'alphanumeric', width:'150px'},
                {text: this.$t('setuppasswordpolicy.symbol'), value: 'symbol', width:'120px'},
                {text: this.$t('setuppasswordpolicy.repetitivecharacter'), value: 'repeatchar', width: '130px'},
                {text: this.$t('setuppasswordpolicy.capitalletter'), value:'capitalletter', width: '100px'}

            ],
            dataItems: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        getPasswordPolicy() {
            const vm = this;

            this.$store.dispatch('get', {url: '/pwdplcy/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                /*for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }*/

                vm.loading = !vm.loading;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'passwordpolicyedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'passwordpolicynew'});
        }
    },

    mounted() {
        this.getPasswordPolicy();
    }
}
</script>