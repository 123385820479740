<template>

    <div id="LeaveDetails">

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-row class="pt-5">
            <v-col>
                <v-card color="#F4F4F4">
                    <v-row class="ml-2 mr-1 mb-12">
                        <v-card-text class="mb-n6">
                        <div class="text--primary">{{leaveDetail.staffcode}} - {{leaveDetail.psnname}}</div>
                        </v-card-text>
                        <v-card-text class="mb-n6">
                            <div class="text--primary">{{leaveDetail.leavedesc}}</div>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary">{{leaveDetail.startdate}} to {{leaveDetail.enddate}} ({{leaveDetail.lvdays}} {{$t('selfserviceleaveapproval.day')}})</div>
                        </v-card-text>
                    </v-row>
                    <v-text-field v-model="leaveDetail.aprvremark" :label="this.$t('selfserviceleaveapproval.approrrej')" class="ml-8 mr-8 mt-5" outlined dense background-color="white"></v-text-field>
                    <v-row justify="center" class="pb-3">
                        <v-card-actions>
                            <v-btn color="success" @click="approve(leaveDetail)">
                                {{$t('taskapprovemobile.approve')}}
                            </v-btn>
                            <v-btn color="error" @click="reject(leaveDetail)">
                                {{$t('taskapprovemobile.reject')}}
                            </v-btn>
                            <v-btn color="primary" @click="finalApprove(leaveDetail)">
                                {{$t('taskapprovemobile.finalapprove')}}
                            </v-btn>
                        </v-card-actions>
                        <app-alert-event
                            v-bind:subDialog="this.subDialog"
                            v-bind:subDialogId="this.subDialogId"
                            v-bind:subDialogTitle="this.subDialogTitle"
                            v-bind:subDialogText="this.subDialogText"
                            v-bind:subDetailTask="this.subDetailTask"
                            v-on:saveAction="this.saveAction"
                            v-on:cancelAction="this.cancelAction"
                        />
                        <app-staff-list
                            v-bind:subDialogStaffList="this.subDialogStaffList"
                            v-bind:subDialogStaffListParam="this.subDialogStaffListParam"
                            v-on:backAction="this.backAction"
                            v-on:choosenPerson="this.choosenPerson"
                        />
                    </v-row>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-row class="ml-2 mr-1 mb-n6">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('columns.reason')}}</p>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-if="leaveDetail.reasondesc">{{leaveDetail.reasondesc}}</div>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-else>-</div>
                        </v-card-text>
                    </v-row>
                    <v-row class="ml-2 mr-1 mb-n6">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('columns.relperson')}}</p>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-if="leaveDetail.reliefname">{{leaveDetail.reliefname}}</div>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-else>-</div>
                        </v-card-text>
                    </v-row>
                    <v-row class="ml-2 mr-1 mb-n6">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('columns.remark')}}</p>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-if="leaveDetail.remark">{{leaveDetail.lvremarks}}</div>
                            <div class="text--primary mb-n10 mt-n3 subtext" v-else>-</div>
                        </v-card-text>
                    </v-row>
                    <v-row class="ml-2 mr-1 pb-10">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('recdisciplinaryrec.supportingdoc')}}</p>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary mt-n3 subtext" v-if="leaveDetail.filetype" @click="loadAttachment(leaveDetail.filetype, leaveDetail.attachment)">
                                {{leaveDetail.filetype}}
                                <v-icon> mdi-eye </v-icon>
                            </div>
                            <div class="text--primary mt-n3 subtext" v-else>-</div>
                        </v-card-text>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>


        <v-row class="pt-5 pb-n2">
            <v-col>
                <v-card color="#6DB961">
                        <v-card-text>
                            <h4 class="ml-4 mr-1 white--text">{{$t('selfserviceleaveapproval.subapprinfo')}}</h4>
                        </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card color="#1278D7">
                        <v-card-text>
                            <h4 class="ml-4 mr-1 white--text">{{$t('selfserviceleaveapproval.leavecalendar')}}</h4>
                        </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="pt-n4 pb-10">
            <v-col>
                <v-card>
                    <v-row class="ml-2 mr-1 mb-n2 pt-2">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('homemobile.submitted')}}</p>
                        </v-card-text>
                        <v-card-text>
                            <div class="text--primary  mb-n10 mt-n3 subtext">{{$t('selfserviceclaimapproval.by')}} {{leaveDetail.staffcode}} {{leaveDetail.psnname}} {{$t('homemobile.timerelatedon')}} {{leaveDetail.entrytime}}</div>
                        </v-card-text>
                    </v-row>
                    <v-row class="ml-2 mr-1 pb-10">
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('selfserviceleaveapproval.approval')}}</p>
                        </v-card-text>
                        <v-card-text v-for="(item, index) in staffApproveList" :key="index">
                            <div class="text--primary mb-10 mt-n3 subtext">{{$t('selfserviceclaimapproval.by')}} {{item.staffcode}} {{item.psnname}} -- <span class="red--text">{{item.aprstatus}}</span> -- {{$t('homemobile.timerelatedon')}} {{item.aprdate}}</div>
                        </v-card-text>
                    </v-row>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-row>
                        <v-col cols="6" sm="6" md="6">
                            <v-row class="ml-5 mr-2 mt-2">
                                <v-checkbox @change="checkFilter()" v-model="samecompany"></v-checkbox>
                                <p class="text--primary ml-2 mr-2 mt-3">{{$t('leavemobile.samecompany')}}</p>
                            </v-row>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                            <v-row class="ml-2 mr-5 mt-2">
                                <v-checkbox @change="checkFilter()" v-model="samedept"></v-checkbox>
                                <p class="text--primary ml-2 mr-2 mt-3">{{$t('leavemobile.samedepartment')}}</p>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="ml-4 mr-4">
                        <calendar-view
                        :show-date="this.showDate"
                        class="theme-default"
                        @click-date="onClickDate"
                        :events="calendaritems"
                        >
                        <template #header="{ headerProps }">
                            <calendar-view-header
                            :header-props="headerProps"
                            @input="setShowDate"
                            />
                        </template>
                        </calendar-view>
                    </v-row>
                    <v-row class="ml-4 mr-4 mt-5 mb-2">
                        <v-col>
                            <p>
                            <span style="color: blue">{{$t('dayname.today')}} {{this.todaydate}}</span> 
                            <span style="color: orange">({{this.totalStaffPresentToday}}%)</span>
                            </p>
                            <div v-for="item in todayStaffListLeave" :key="item.listid">
                            <p>
                                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
                            </p>
                            </div>
                            <p>
                            <span style="color: blue">{{$t('dayname.tomorrow')}} {{this.tomorrowdate}}</span> 
                            <span style="color: orange">({{this.totalStaffPresentTomorrow}}%)</span>
                            </p>
                            <div v-for="item in tomorrowStaffListLeave" :key="item.listid">
                            <p>
                                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
                            </p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="ml-4 mr-4 mb-5">
                        <v-col>
                            <p><span style="color: blue">{{this.loaddate}}</span></p>
                            <div v-for="item in loadStaffListLeave" :key="item.listid">
                            <p @click="goTo(item)">
                                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
                            </p>
                            </div>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

        <app-alert-error
            v-bind:errorDialog="errorDialog"
            v-bind:errorMessage="errorMessage"
            v-on:OkPressed="dismissHandler"
        />

    </div>

</template>

<script>

import AlertEvent from '../common/AlertEvent.vue';
import Loading from "vue-loading-overlay";
import StaffListEvent from '../common/StaffList.vue'
import ErrorMessageSelService from '../../sscomponents/common/ErrorMessage.vue'
import moment from 'moment'
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";

export default {

    components: {
    "app-alert-event": AlertEvent,
    "app-staff-list": StaffListEvent,
    "app-alert-error": ErrorMessageSelService,
    Loading,
    CalendarView,
    CalendarViewHeader
},

    props: {
        leaveDetail: Object,
        staffApproveList: Array
    },

    data(){
        return {
            subDialog: false,
            subDialogTitle: '',
            subDialogText: '',
            subDialogId: null,
            subDetailTask: null,
            loading: false,

            samedept: null,
            samecompany: null,

            subDialogStaffList: false,
            subDialogStaffListParam: null,

            errorDialog: false,
            errorMessage: '',

            showDate: new Date(),
            loaddate: null,
            leaverecordList: [],
            loadDateRecord: [],
            loadStaffListLeave: [],
            calendaritems: [],
            startMonthDate: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
            endMonthDate: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
            totalStaffMov: [],
            todaydate: null,
            todayStaffListLeave: [],
            todayStaffListHolder: [],
            totalStaffPresentToday: [],
            totalStaff: [],
            tomorrowdate: null,
            tomorrowStaffListLeave: [],
            totalStaffPresentTomorrow: [],

            staff: [],
            userHolder: [],
            backupDataItems: []

        }
    },

    watch: {

        startMonthDate(){

        const vm = this;
        //let itemholder = []
        let id = 0;

        this.todayStaffListLeave = [];
        this.tomorrowStaffListLeave = [];
        this.loadStaffListLeave = [];

        this.calendaritems = [];
        // this.leaverecordList = [];
        // this.todayStaffListHolder = [];
        // this.totalStaff = [];
        // this.totalStaffPresentToday = [];
        // this.totalStaffPresentTomorrow = [];
        // this.totalStaffMov = [];
        this.loadDateRecord = [];

        // this.$store.dispatch('get', {url: `/leaverecordwho/all/${this.$store.getters.userId}/${vm.startMonthDate}/${vm.endMonthDate}`})
        this.$store.dispatch('get', { url: `/leaverecordwhoselfservice/all/${vm.startMonthDate}/${vm.endMonthDate}`})
            .then(async (res) => {

            let dataitems = res.data.rec
            vm.backupDataItems = dataitems;

            for (let i = 0; i < dataitems.length; i++) {
                let leaved = await this.checkDate(dataitems[i])
                dataitems[i].tleave = leaved.hday
                id++
            }

            if (id == dataitems.length) {
            
                // dataitems.map(val => {
                // let it2 = this.totalStaffMov.filter(val2 => {
                //     return val2.staffid == val.staffid && val2.recdate <= val.startdate && val2.recenddate >= val.enddate 
                // })[0]
                // val.emptypeid = it2.emptypeid
                // val.brchid = it2.brchid
                // val.compnyid = it2.compnyid
                // val.deptid = it2.deptid
                // val.gradeid = it2.grdid
                // return val
                // })

                let dateh = []
                for (let i = 0; i < dataitems.length; i++) {
                let getd = [...new Set(dataitems[i].tleave.map(item => item))]
                dateh = dateh.concat(getd)
                }
                let fdate = [...new Set(dateh.map(x => x))]
                let g = 0
                for (let i = 0; i < fdate.length; i++) {
                let dt = dataitems.filter(x => {
                let hol = x.tleave.indexOf(fdate[i])
                if (hol == -1) {
                    return false
                }
                return x
                })

                let newdt = [...dt]
                
                for (let j = 0; j < newdt.length; j++) {
                let itm = {...newdt[j], cdate: fdate[i], listid: g++}
                vm.leaverecordList.push(itm)
                }

                vm.calendaritems.push({
                id: i+1,
                startDate: fdate[i],
                title: newdt.length
                })
                
            }

            console.log('watch data', vm.leaverecordList)
            this.loadRecordToday(vm.leaverecordList);
            this.loadRecordTomorrow(vm.leaverecordList);
            }
            })
            .catch((err) => {
            //req failure --> emit error
            vm.isErrTrigger = true;
            vm.errMsg = err;
            });
        }

    },

    methods: {

        // Error Message Popup Dismiss
        dismissHandler(){
            this.errorDialog = false
            this.errorMessage = ''
        },

        //Button Checking
        finalApprove(param){
            this.subDialogId = 1 //Final Approve ID
            this.subDialog = true
            this.subDialogTitle = this.$t('selfservicemytask.confirmfinapprove')
            this.subDialogText = this.$t('selfservicemytask.approvefintask')
            this.subDetailTask = param
        },

        reject(param){
            if(param.aprvremark) {
                this.subDialogId = 2 //Reject ID
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmrej')
                this.subDialogText = this.$t('selfservicemytask.rejecttask')
                this.subDetailTask = param
            } else {
                this.errorDialog = true
                this.errorMessage = this.$t('selfservicemytask.remcolrequired')
                // console.log(this.$t('selfservicemytask.remcolrequired'))
            }
        },

        approve(param){
            this.subDialogId = 3 //Approve ID
            this.subDialog = true
            this.subDialogTitle = this.$t('selfservicemytask.confirmapprove')
            this.subDialogText = this.$t('selfservicemytask.approvetask')
            this.subDetailTask = param
        },

        // Confirmation Popup
        saveAction($event, param) {
            console.log('Save')
            console.log($event);
            console.log(param);
            
            // Perform Action to final approve
            if($event == 1){
                this.taskFinalApproval(param)
            }

            // Perform Action to reject
            if($event == 2){
                this.taskApproval('REJECT', param)
            }

            // Perform Action to approve
            if($event == 3){
                this.taskCheckApproval(param)
            }

            this.resetSubDialog();
        },

        cancelAction(){
            console.log('Cancel')
            this.resetSubDialog();
        },

        // Reset All
        resetSubDialog(){
            this.subDialogId = null
            this.subDialog = false
            this.subDialogTitle = ''
            this.subDialogText = ''
            this.subDialogTask = null
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null 
        },

        taskApproval(text, value){

            value.apraction = text
            
            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('put', {url: "/approvalworkflow/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: value})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskFinalApproval(value){

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('put', {url: "/finalapprovalworkflow/approve", payload: value})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskCheckApproval(value){
            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('get', {url: "/staffreportingposition/" + value.reqstaffid})
            .then((res) => {
                const result = res.data;

                if (vm.leaveDetail.level == vm.leaveDetail.lvlno){
                    vm.taskApproval('APPROVE', value)
                }

                if (vm.leaveDetail.level != vm.leaveDetail.lvlno){
                    let res = result
                    // if (res.rec.length == 0 || (vm.leaveDetail.hrlast == 1 && (vm.leaveDetail.level - vm.leaveDetail.lvlno > 1)) || res.rec[0].staffId != vm.$store.getters.staffId){
                    //     vm.staffList(value)
                    // } else {
                    //     vm.checkSelfReportingPosition(value)
                    // }

                    if (vm.leaveDetail.hrlast == 1 && (vm.leaveDetail.level - vm.leaveDetail.lvlno == 1)) {
                        vm.taskApproval('APPROVE', value)
                    } else if (res.rec.length == 0 || result.rec[0].staffid != vm.$store.getters.staffId) {
                        vm.staffList(value)
                    } else {
                        vm.checkSelfReportingPosition(value)
                    }
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });
        },

        checkSelfReportingPosition(data){

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('get', {url: "/staffreportingposition/" + this.$store.getters.staffId})
            .then((res) => {
                const result = res.data;

                if(result.rec.length > 0){
                    vm.taskApproval('APPROVE', data)
                } else {
                    vm.staffList(data)
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        staffList(param){
            this.subDialogStaffList = true
            this.subDialogStaffListParam = param 
        },

        // Staff List Action
        backAction(){
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null
        },

        // Fucntion receive from choosen staff for another level approval
        choosenPerson(id, data){
            this.subDialogStaffList = false
            data.refstaffid = id
            this.taskWorkflowExceptionApproval(data)
        },

        taskWorkflowExceptionApproval(levelapproval){

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('put', {url: "/approvalworkflowexception/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: levelapproval})
            .then((res) => {
                const result = res.data;

                if(result.status == 200){
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.subDialogStaffList = false
                vm.subDialogStaffListParam = null
                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        onClickDate(d) {
            this.loaddate = moment(d).format("DD-MM-YYYY")
            this.loadRecordList(moment(d).format("YYYY-MM-DD"));
        },

        loadRecordList(date) {
            let dataholder = [];

            let getdatadefault = this.leaverecordList.filter(x => {
                return x.cdate == date
            })

            let getdatafilter = this.loadDateRecord.filter(x => {
                return x.cdate == date
            })

            this.loadDateRecord.length > 0 ? dataholder = getdatafilter : dataholder = getdatadefault

            for (let i = 0; i < dataholder.length; i++) {
                if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
                if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
                if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
                if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
            }

            let data = this.removeDuplicateKeys(dataholder);
            this.loadStaffListLeave = data;
        },

        removeDuplicateKeys(arr) {
            const ids = arr.map(o => o.listid)
            const filtered = arr.filter(({listid}, index) => !ids.includes(listid, index + 1))
            return filtered;
        },

        loadRecordCountList() {
            const vm = this;
            //let itemholder = []
            let id = 0

            console.log(this.$store.getters.userId)
            console.log(vm.startMonthDate)
            console.log(vm.endMonthDate)
            console.log(this.$store.getters.staffId)

            // this.$store.dispatch('get', {url: `/leaverecordwho/all/${this.$store.getters.userId}/${vm.startMonthDate}/${vm.endMonthDate}`})
            this.$store.dispatch('get', { url: `/leaverecordwhoselfservice/all/${vm.startMonthDate}/${vm.endMonthDate}`})
            .then(async (res) => {

            let dataitems = res.data.rec;
            vm.backupDataItems = dataitems;
            console.log('Init Data', dataitems)

            for (let i = 0; i < dataitems.length; i++) {
                let leaved = await this.checkDate(dataitems[i])
                dataitems[i]. tleave= leaved.hday
                id++
            }

            if (id == dataitems.length) {
          
                // dataitems.map(val => {
                // let it2 = this.totalStaffMov.filter(val2 => {
                //     return val2.staffid == val.staffid && val2.recdate <= val.startdate && val2.recenddate >= val.enddate 
                // })[0]
                // val.emptypeid = it2.emptypeid
                // val.brchid = it2.brchid
                // val.compnyid = it2.compnyid
                // val.deptid = it2.deptid
                // val.gradeid = it2.grdid
                // return val
                // })

                let dateh = []
                for (let i = 0; i < dataitems.length; i++) {
                    let getd = [...new Set(dataitems[i].tleave.map(item => item))]
                    dateh = dateh.concat(getd)
                }
                let fdate = [...new Set(dateh.map(x => x))]
                let g = 0
                for (let i = 0; i < fdate.length; i++) {
                    let dt = dataitems.filter(x => {
                    let hol = x.tleave.indexOf(fdate[i])
                    if (hol == -1) {
                        return false
                    }
                    return x
                })

                let newdt = [...dt]
                
                for (let j = 0; j < newdt.length; j++) {
                    let itm = {...newdt[j], cdate: fdate[i], listid: g++}
                    vm.leaverecordList.push(itm)
                }

                vm.calendaritems.push({
                    id: i+1,
                    startDate: fdate[i],
                    title: newdt.length
                })

                }

                console.log('Test Data', vm.leaverecordList)
                this.loadRecordToday(vm.leaverecordList);
                this.loadRecordTomorrow(vm.leaverecordList);
            }
            })
            .catch((err) => {
                //req failure --> emit error
                vm.isErrTrigger = true;
                vm.errMsg = err;
            });
        },

        async checkDate(item) {
            const vm = this
            
            const dataholder = {
                staffid: item.staffid,
                leavetypeid: item.leavetypeid,
                startdate: moment(item.startdate).format('YYYY-MM-DD'),
                enddate: moment(item.enddate).format('YYYY-MM-DD'),
                userid: vm.$store.getters.userId
            }

            let leavedays = this.$store.dispatch('post', {url: "/leave/leaveday", payload: dataholder})
                .then(res => {
                    let item = res.data.rec
                    item.hday = item.normalday.filter(val => {
                        return val >= dataholder.startdate && val <= dataholder.enddate
                    })
                    return item
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });

            return leavedays
        },

        loadRecordToday(data) {

            this.todaydate = moment().format('DD-MM-YYYY');
            let todayholder = moment().format('YYYY-MM-DD');

            let dataholder = data.filter(x => {
            return x.cdate == todayholder
            })

            for (let i = 0; i < dataholder.length; i++) {
            if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
            if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
            if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
            if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
            }

            let dataNotDuplicate = this.removeDuplicateKeys(dataholder);
            this.todayStaffListLeave = dataNotDuplicate
            this.todayStaffListHolder = dataNotDuplicate
            this.totalStaffPresentToday = this.countStaffPresent(dataNotDuplicate.length)

        },

        loadRecordTomorrow(data) {

            this.tomorrowdate = moment().add(1,'days').format('DD-MM-YYYY');
            let tomorrowholder = moment().add(1,'days').format('YYYY-MM-DD');

            let dataholder = data.filter(x => {
            return x.cdate == tomorrowholder
            })

            for (let i = 0; i < dataholder.length; i++) {
            if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
            if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
            if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
            if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
            }

            let dataNotDuplicate = this.removeDuplicateKeys(dataholder);
            this.tomorrowStaffListLeave = dataNotDuplicate
            this.totalStaffPresentTomorrow = this.countStaffPresent(dataNotDuplicate.length)

        },

        countStaffPresent(val) {
            const staffholder = this.totalStaff.length

            let item = ((staffholder-val)/staffholder)*100
            return item.toFixed(2)
        },

        loadTotalStaff() {
            const vm = this;
        
            this.$store.dispatch('get', {url: "/staff/alls"})
            .then((res) => {
            this.totalStaff = res.data.rec
            this.loadStaffMov();
            })
            .catch((err) => {
            //req failure --> emit error
            vm.isErrTrigger = true;
            vm.errMsg = err;
            });
        },

        loadStaffMov() {
            const vm = this;
            
            this.$store.dispatch('get', {url: `/staffmov/all/${this.$store.getters.userId}`})
            // this.$store.dispatch('get', {url: `/staffmov/all`})
            .then((res) => {
            this.totalStaffMov = res.data.rec
            console.log("Test To STaff Move", this.totalStaffMov);
            this.loadRecordCountList();
            })
            .catch((err) => {
            //req failure --> emit error
            vm.isErrTrigger = true;
            vm.errMsg = err;
            });
        },

        setShowDate(d) {
            this.showDate = d;
            this.startMonthDate = moment(this.showDate).startOf('month').format("YYYY-MM-DD");
            this.endMonthDate = moment(this.showDate).endOf('month').format("YYYY-MM-DD");
        },

        checkFilter(){

            const vm = this;

            if(this.samecompany && !this.samedept){

                console.log('Hello')

                const filteredArray = vm.backupDataItems.filter(obj1 => {
                    return vm.staff.some(obj2 => obj1.compnyid === obj2.compnyid);
                });

                vm.loadCalendar(filteredArray)

            }

            if(this.samedept && !this.samecompany){

                console.log('Hei')

                const filteredArray = vm.backupDataItems.filter(obj1 => {
                    return vm.staff.some(obj2 => obj1.deptid === obj2.deptid);
                });

                vm.loadCalendar(filteredArray);

            }

            if(this.samedept && this.samecompany){

                console.log(this.samedept)
                console.log(this.samecompany)
                
                const filteredArray = vm.backupDataItems.filter(obj1 => {
                    return vm.staff.some(obj2 => obj1.compnyid === obj2.compnyid && obj1.deptid === obj2.deptid);
                });

                vm.loadCalendar(filteredArray)
                
            }
            
            if(!this.samedept && !this.samecompan){

                console.log(this.samedept)
                console.log(this.samecompany)

                vm.loadCalendar(vm.backupDataItems)
            }

        },

        loadAttachment(filetype, attachment) {

            if(filetype == 'PDF'){
                const blob = this.b64toBlob(attachment, 'application/pdf', 512)
                const url = URL.createObjectURL(blob);
                window.open(url);
            }

            if(filetype == 'Image'){
                var windowHandle = window.open("");
                windowHandle.document.write("<img src='" + attachment + "' />");
            }
        },

        b64toBlob(b64Data, contentType, sliceSize = 512) {
            contentType = contentType || ''
            sliceSize = sliceSize || 512
            b64Data = b64Data.replace(/^[^,]+,/, '')
            b64Data = b64Data.replace(/\s/g, '')

            var byteCharacters = atob(b64Data)
            var byteArrays = []

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize)

            var byteNumbers = new Array(slice.length)
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            var byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
            }

            var blob = new Blob(byteArrays, {
            type: contentType
            })
            // return byteCharacters;
            return blob
        },

        loadStaffDetails() {

            const vm = this;

            this.$store.dispatch('get', { url: `/selservicestaff/${this.$store.getters.staffId}`})
            .then((res) => {
                vm.staff = res.data.rec;
                console.log(vm.staff)
            })
            .catch((err) => {
                console.log(err)
            });

        },

        async loadCalendar(filterData) {

            console.log('Filter Data', filterData);

            const vm = this;
            
            vm.calendaritems = []
            vm.leaverecordList = []

            let id = 0

            let dataitems = filterData;
            console.log('Init Data', dataitems)

            if(dataitems.length > 0){

                for (let i = 0; i < dataitems.length; i++) {
                    let leaved = await this.checkDate(dataitems[i])
                    dataitems[i]. tleave= leaved.hday
                    id++
                }

                if (id == dataitems.length) {
            
                    // dataitems.map(val => {
                    // let it2 = this.totalStaffMov.filter(val2 => {
                    //     return val2.staffid == val.staffid && val2.recdate <= val.startdate && val2.recenddate >= val.enddate 
                    // })[0]
                    // val.emptypeid = it2.emptypeid
                    // val.brchid = it2.brchid
                    // val.compnyid = it2.compnyid
                    // val.deptid = it2.deptid
                    // val.gradeid = it2.grdid
                    // return val
                    // })

                    let dateh = []

                    for (let i = 0; i < dataitems.length; i++) {
                        let getd = [...new Set(dataitems[i].tleave.map(item => item))]
                        dateh = dateh.concat(getd)
                    }

                    let fdate = [...new Set(dateh.map(x => x))]
                    let g = 0

                    for (let i = 0; i < fdate.length; i++) {

                        let dt = dataitems.filter(x => {
                            let hol = x.tleave.indexOf(fdate[i])
                            if (hol == -1) {
                                return false
                            }
                            return x
                        })

                    let newdt = [...dt]
                    
                    for (let j = 0; j < newdt.length; j++) {
                        let itm = {...newdt[j], cdate: fdate[i], listid: g++}
                        vm.leaverecordList.push(itm)
                    }

                    vm.calendaritems.push({
                        id: i+1,
                        startDate: fdate[i],
                        title: newdt.length
                    })
                    }

                    console.log('Test Data', vm.leaverecordList)
                    this.loadRecordToday(vm.leaverecordList);
                    this.loadRecordTomorrow(vm.leaverecordList);

                }

            } else {

                console.log('Hello')

                vm.calendaritems = [];
                vm.leaverecordList = [];

            }

        }

    },

    mounted() {
        this.loadStaffDetails();
        this.loadTotalStaff();
    },
}
</script>


<style>
p {
    font-size: 9px;
}
.subtext {
    font-size: 12px;
}

#calendar {
  color: #2c3e50;
  height: 60vh;
  width: 100%;
}

.theme-default .cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
  /* color: yellow; */
  background: none;
  border: none;
  color: red;
  font-size: 14px;
}

</style>