<template>
    <div>
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <v-container >
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('letterlist.templatetypeid')"
                                type="text"
                                ref="templatetypeid"
                                hide-selected
                                :items="letterTemplateList"
                                v-model="formData.templatetypeid"
                                @input="updateValue"
                                v-on:input="loadLetterTemplateDetail()"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="6" class="pb-n4">
                            <v-text-field
                                outlined
                                :label="this.$t('letterlist.templatename')"
                                type="text"
                                ref="templatename"
                                :rules="[rules.required]"
                                v-model="formData.templatename"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="10" sm="8" class="pb-n4 mt-n8">
                            <VueEditor
                                style="height: 200px;"
                                outlined
                                :label="this.$t('letterlist.content')"
                                type="text"
                                ref="content"
                                v-model="formData.content"
                                @input="updateValue"
                                dense 
                                :editor-toolbar="customToolbar"/>
                        </v-col>
                        <v-col cols="10" sm="2" class="pb-n4 mt-n8">
                            <ul style="list-style-type: none; overflow-y:auto; overflow-x:visible; height: 300px; width: 200px;">
                                <li v-for="m in letterColumn" :key="m">
                                    <p v-on:click="addText(m)" class="pb-4 mb-n2" style="font-size: 15px">
                                        <a>{{m.display}}</a></p>
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                    

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <ul style="list-style-type: none; display:inline;">
                                <li v-for="d in details" :key="d" style="display:inline;">
                                    <v-btn
                                        v-bind:color="d.pagenumber === currpage? 'grey lighten-1' : 'grey lighten-4'" 
                                        v-on:click="changePage(d)"
                                        >{{d.pagenumber}}</v-btn>
                                </li>
                            </ul>
                            <v-btn v-on:click="addPage()">+</v-btn>
                            <v-btn v-on:click="removePage(d)">-</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n6">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
import { Quill } from "vue2-editor";

// Generate code-friendly font names
function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);
const fontSizeArr = ['10px', '14px', '18px', '22px'];
var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
    let fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});
fontStyles += `.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: 'Small';
    font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: 'Normal';
    font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Large';
    font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: 'Huge';
    font-size: 22px !important;
}`

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-error": ErrorDialog
    },
    data() {
        return {
            details: (this.parentObject.data.detail ? this.parentObject.data.detail : []).map(e => {return {
                id: e.id,
                templateid: e.templateid,
                pagenumber: e.pagenumber,
                content: e.content,
                stamp: e.stamp
            }}), 
            formData: {
                templatetypeid: this.parentObject.data.templatetypeid,
                templatename: this.parentObject.data.templatename,
                isdisable: this.parentObject.data.isdisable,
                content: this.parentObject.data.content,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
                detail: this.details
            },
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            currpage: 1,
            search: this.parentObject.searchnew,
            letterColumn: [],
            letterTemplateList: [],
            letterTemplateRec: [],

            errMsg: '',
            isErr: false,
            clicked: false,
            customToolbar: [
                [{ 'size': Size.whitelist }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ 'font': fonts.whitelist }],
                ["bold", "italic", "underline"], // toggled buttons
                // [
                // { align: "" },
                // { align: "center" },
                // { align: "right" },
                // { align: "justify" }
                // ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ color: [] }], // dropdown with defaults from theme
                ["image"],
            ]
        }
    },

    methods: {
        loadLetterTemplateListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/lettertype/all/details'})
                .then(res => {
                    vm.letterTemplateRec = res.data.rec;
                    vm.letterTemplateList = [];
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.letterTemplateList.push(
                            {
                                text: res.data.rec[i].templatetype,
                                value: res.data.rec[i].id
                            }
                        )
                    } 
                    vm.formData.templatetypeid = vm.formData.templatetypeid ? vm.formData.templatetypeid : res.data.rec[0].id;
                    vm.loadLetterTemplateDetail();
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        loadLetterTemplateDetail() {
            const vm = this;
            vm.letterColumn = vm.letterTemplateRec.find(e => e.id === vm.formData.templatetypeid)['column'];
        },

        updateValue() {
            this.$emit('input', {
                templatetypeid: this.formData.templatetypeid,
                templatename: this.formData.templatename,
                isdisable: this.formData.isdisable,
                content: this.formData.content,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
                detail: this.details.map(e => {return {
                    id: e.id,
                    templateid: e.templateid,
                    pagenumber: e.pagenumber,
                    content: e.pagenumber == this.currpage? this.formData.content : e.content,
                    stamp: e.stamp
                }})
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'letterlist', params: { search: this.search}} );
        }, 
        addText(x) {
            var idx = this.$refs.content.quill.selection.savedRange.index;
            var addText = '{{' + /*(x.tablename ? x.tablename + '.' : '') +*/ x.columnname + '}}';
            this.$refs.content.quill.insertText(idx, addText);
            this.$refs.content.quill.selection.savedRange.index = idx + addText.length;
        },
        changePage(x, saveContent = true) {
            if (saveContent) {
                this.details.forEach(e => {
                    if(e.pagenumber == this.currpage){
                        e.content = this.formData.content;
                }});
            }
            this.currpage = x.pagenumber;
            this.formData.content = x.content;
            this.formData.detail = this.details;
        },
        addPage() {
            const vm = this;
            var pagecnt = 0;
            this.details.forEach(e => {
                if(e.pagenumber == vm.currpage){
                    e.content = vm.formData.content;
                }
                if(e.content && e.content.length > 0){
                    pagecnt++;
                } else {
                    vm.changePage(e);
                    vm.isErr = true;
                    vm.errMsg = 'Not allow blank page';
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                }
            });
            if (!vm.isErr){
                var newPage = {
                    id: null,
                    templateid: this.$route.params.id,
                    pagenumber: ++pagecnt,
                    content: '',
                    stamp: null
                };
                this.details.push(newPage);
                vm.changePage(newPage);
            }
        },
        removePage() {
            const vm = this;
            var pagecnt = 0;
            this.details = this.details.reduce((a, e) => {
                if(e.pagenumber < vm.currpage){
                    a.push(e);
                    pagecnt++;
                } else if (e.pagenumber > vm.currpage){
                    e.pagenumber--;
                    a.push(e);
                    pagecnt++;
                }
                return a;
            }, []);
            this.formData.detail = this.details;
            if (pagecnt <= vm.currpage){
                vm.changePage(this.details.find(e => e.pagenumber == pagecnt), false);
            } else {
                vm.changePage(this.details.find(e => e.pagenumber == vm.currpage), false);
            }
        },
    },

    mounted() {
        this.loadLetterTemplateListHandler();
        if (!this.details.length) {
                this.details.push({
                    id: null,
                    templateid: null,
                    pagenumber: 1,
                    content: '',
                    stamp: null
                });
                this.changePage({
                    pagenumber: 1,
                    content: ''
                });
        }
    }
}
</script>

<style>
   .white {
       background-color: white;

   }
   .blue {
       background-color: rgb(65, 65, 65);
   }


</style>