<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
// import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('setuppayitmetype.payitemtypelist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                {text: this.$t('setuppayitmetype.payitemtype'), value: 'payitemtypecode', width: '150px'},
                {text: this.$t('columns.description'), value: 'payitemtypedesc', width: '200px'},
                {text: this.$t('setuppayitmetype.payitemcategory'), value: 'payitemcatdesc', width: '200px'},
                // {text: 'EFFECTIVE DATE', value: 'startdate', width: '200px'},
                // {text: 'INVALID DATE', value: 'enddate', width: '200px'},
                {text: this.$t('setuppayitmetype.affectnetpay'), value: 'afnetpay', width: '150px'},
                {text: this.$t('setuppayitmetype.affectgrosspay'), value: 'afgrosspay', width: '150px'},
                {text: this.$t('setuppayitmetype.vendorbased'), value: 'isvendor', width: '150px'},
                {text: this.$t('columns.remark'), value: 'remark', width: '150px'}
            ],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadPayItemTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitemtype/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                // for (let i = 0; i < vm.dataItems.length; i++) {
                //     if (vm.dataItems[i].startdate) {
                //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                //     }

                //     if (vm.dataItems[i].enddate) {
                //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                //     }
                // }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'payitemtypeedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'payitemtypenew'});
        }
    },

    mounted() {
        this.loadPayItemTypes();
    }
}
</script>