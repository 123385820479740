<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('setupworkflowexception.workflowexceptionlist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                {text: this.$t('columns.staffname'), value: 'psnname', width: '200px'},
                {text: this.$t('columns.staffid'), value: 'staffcode', width: '100px'},
                {text: this.$t('columns.company'), value: 'compnyname', width: '200px'},
                {text: this.$t('columns.startdate'), value: 'startdate', width: '150px'},
                {text: this.$t('columns.enddate'), value: 'enddate', width: '150px'},
                {text: this.$t('columns.workflowtype'), value: 'wftypedesc', width: '200px'},
                {text: this.$t('payeeinputprocess.details'), value: 'detaildesc', width: '200px'}
            ],
            dataItems: [],
            leaveTypeHolder: [],
            claimTypeHolder: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadWorkflowExceptions() {
            const vm = this;

            this.$store.dispatch('get', {url: `/workflowexception/all/${this.$store.getters.userId}`})
            .then(res => {
                vm.dataItems = res.data.rec;

                let count = 0;
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                    count++

                    if (count == vm.dataItems.length) this.detailHandler()

                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadLeaveType() {
            const vm = this;
            
            this.$store.dispatch('get', {
                url: '/leavetype/all'
                })
            .then (res => {
                this.leaveTypeHolder = res.data.rec;
            })
            .catch (err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
            
        },

        loadClaimType() {
            const vm = this;
            
            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'
                })
            .then (res => {
                this.claimTypeHolder = res.data.rec;
            })
            .catch (err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
            
        },

        detailHandler() {
            const vm = this;
            for (let i = 0; i < vm.dataItems.length; i++) {
                if (vm.dataItems[i].wftypeid == 3 && vm.dataItems[i].detailid) {
                    let item = this.leaveTypeHolder.filter(val => val.id == vm.dataItems[i].detailid)[0]
                    vm.dataItems[i].detaildesc = item.leavedesc
                }
                if (vm.dataItems[i].wftypeid == 5 && vm.dataItems[i].detailid) {
                    let item = this.claimTypeHolder.filter(val => val.id == vm.dataItems[i].detailid)[0]
                    vm.dataItems[i].detaildesc = item.clmtypedesc
                }
            }
        },

        editItemHandler(item) {
            this.$router.push({name: 'workflowexceptionedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'workflowexceptionnew'});
        }
    },

    mounted() {
        this.loadLeaveType();
        this.loadClaimType();
        this.loadWorkflowExceptions();
    }
}
</script>