<template>
  <div>
    <app-form
      v-bind:parentObject= "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
      
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
        
      />
    </div>
  </div>
</template>

<script>

import NonbankpaymentForm from "../../../components/payroll/nonbankpayment/Form_Parameter";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": NonbankpaymentForm,
    "app-error": ErrorDialog
  },

  data() {
    return {
      pageTitle: this.$t('nonbankpaymentlistingreport.nonbanksalarypaymentlisting'),
      pageTitleValue: "",
      formData: {
  
      },

      dataItems: [],

      goBtn: false,
      
      
      yearList: [],
      monthList: [],
      exchangeList: [],
      currencyList: [],
      codeList: [],
      paymodeList: [],

      errMsg: "",
      isErr: false,
      editMode: false,
    }
  },

  methods: {

  }

}
</script>
