<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

import LeaveBalanceAdjustmentForm from "../../../components/leave/leavebalanceadj/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LeaveBalanceAdjustmentForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recleavebalanceadjustment.editleavebalanceadjustment'),
      pageTitleValue: "",
      formData: {
        id: null,
        psnid: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        leavetypeid: null,
        leavecode: null,
        adjtype: null,
        adjqty: 0,
        adjreasonid: null,
        startdate: null,
        enddate: null,
        remark: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      loading: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //insert new record
    saveHandler() {
      const vm = this;

      vm.formData.id = vm.id;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/leavebalanceadj/" + vm.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavebalanceadjlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadBalanceAdjRecord() {
      const vm = this;
      vm.loading = true;
      this.$store.dispatch('get', {url: "/leavebalanceadj/" + vm.id})
        .then((res) => {
          console.log(res);
          vm.formData.id = res.data.rec[0].id;
          vm.formData.staffid = res.data.rec[0].staffid;
          vm.formData.staffcode = res.data.rec[0].staffcode;
          vm.formData.compnyid = res.data.rec[0].compnyid;
          vm.formData.leavetypeid = res.data.rec[0].leavetypeid;
          vm.formData.leavecode = res.data.rec[0].leavecode;
          vm.formData.adjtype = res.data.rec[0].adjtype;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.formData.adjqty = parseInt(res.data.rec[0].adjdays);
          vm.formData.startdate = moment(res.data.rec[0].startdate).format(
            "YYYY-MM-DD"
          );
          vm.formData.enddate = moment(res.data.rec[0].enddate).format(
            "YYYY-MM-DD"
          );
          vm.formData.adjreasonid = res.data.rec[0].adjreasonid;
          //   vm.formData.remark = res.data.rec[0].remark;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.loading = false;

          console.log(vm.formData);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "leavebalanceadjlist" });
    },

    newHandler() {
      this.$router.push({ name: "leavebalanceadjnew" });
    },
    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url: "/leavebalanceadj/" + vm.id})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavebalanceadjlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
  mounted() {
    this.loadBalanceAdjRecord();
  },
};
</script>