<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppayitmetype.payitemtype')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.payitemtypecode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.payitemtypedesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupvendor.category')"
                                type="text"
                                :items="payItemCatList"
                                :rules="[rules.required]"
                                @change="changePayItemCatHandler"
                                v-model="formData.payitemcatid"
                                 @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="payItemCatCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="3" sm="4" class="pb4-n4 mt-n8">
                            <v-checkbox :label="this.$t('setuppayitmetype.affectnetpay')" v-model="formData.afnetpay" @change="updateValue()"></v-checkbox>
                        </v-col>
                        <v-col col="3" sm="4" class="pb4-n4 mt-n8">
                            <v-checkbox :label="this.$t('setuppayitmetype.affectgrosspay')" v-model="formData.afgrosspay" @change="updateValue()" ></v-checkbox>
                        </v-col>
                        <v-col col="3" sm="4" class="pb4-n4 mt-n8">
                            <v-checkbox :label="this.$t('setuppayitmetype.vendorbased')" v-model="formData.isvendor" @change="updateValue()" ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object
    },

    components: {

    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
                payitemtypecode: this.parentObject.data.payitemtypecode,
                payitemtypedesc: this.parentObject.data.payitemtypedesc,
                payitemcatid: this.parentObject.data.payitemcatid,
                 payitemcatdesc: this.parentObject.data.payitemcatdesc,
                // enddate: this.parentObject.data.enddate,
                afnetpay: this.parentObject.data.afnetpay,
                afgrosspay: this.parentObject.data.afgrosspay,
                isvendor: this.parentObject.data.isvendor,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            selected: [],
            payItemCats: [],
            payItemCatList: [],
            payItemCatCode: '',
            subDialog: false,
            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        loadPayItemCatListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitemcat/all'})
                .then(res => {
                    vm.payItemCats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemCatList.push(
                            {
                                text: res.data.rec[i].payitemcatdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.payitemcatid) {
                        vm.changePayItemCatHandler(vm.formData.payitemcatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePayItemCatHandler(id) {
            let item = this.payItemCats.find(item => item.id === id);

            this.payItemCatCode = item.payitemcatcode;
        },

        updateValue() {
            this.$emit('input', {
                payitemtypecode: this.formData.payitemtypecode,
                payitemtypedesc: this.formData.payitemtypedesc,
                payitemcatid: this.formData.payitemcatid,
                payitemcatdesc: this.formData.payitemcatdesc,
                // startdate: this.formData.startdate,
                // enddate: this.formData.enddate,
                afnetpay: this.formData.afnetpay,
                afgrosspay: this.formData.afgrosspay,
                isvendor: this.formData.isvendor,
                remark: this.formData.remark,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            console.log('formdt', this.formData)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        
        cancelHandler() {
            this.$router.push({name: 'payitemtypelist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadPayItemCatListHandler();
    }
}
</script>