<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:error="errorHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ClaimTypeSetupForm from "../../../components/claim/claimtypesetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": ClaimTypeSetupForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupclaim.createnewclaimtypesetup'),
      pageTitleValue: "",
      formData: {
        clmtype: null,
        clmtypedesc: null,
        clmtypecat: null,
        clmtypecatid: null,
        parentclmid: null,
        parentclmtype: null,
        parentclmtypedesc: null,
        prdtype: null,
        clmprdtypeid: null,
        startmth: null,
        clmviapayroll: true,
        payitemcode: null,
        payitemid: null,
        payitemdesc:null,
        backdateddays: null,
        taxrate: null,
        crcyid: null,
        exchrateid: null,
        selfservice: false,
        confirmedstaff: false,
        onceinemplymt: false, 
        isdisable: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },


      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      // Convert boolean to integer. 1 = true, 0 = false
      if (
        this.formData.clmviapayroll == false ||
        this.formData.clmviapayroll == null
      ) {
        this.formData.clmviapayroll = 0;
      } else {
        this.formData.clmviapayroll = 1;
      }

      if (
        this.formData.selfservice == false || 
        this.formData.selfservice == null
      ) {
        this.formData.selfservice = 0;
      } else {
        this.formData.selfservice = 1;
      }

      if (
        this.formData.confirmedstaff == false || 
        this.formData.confirmedstaff == null
      ) {
        this.formData.confirmedstaff = 0;
      } else {
        this.formData.confirmedstaff = 1;
      }

      if (
        this.formData.onceinemplymt == false ||
        this.formData.onceinemplymt == null
      ) {
        this.formData.onceinemplymt = 0;
      } else {
        this.formData.onceinemplymt = 1;
      }

      if (
        this.formData.isdisable == false ||
        this.formData.isdisable == null
      ) {
        this.formData.isdisable = 0;
      } else {
        this.formData.isdisable = 1;
      }

      if (this.formData.clmviapayroll == true && this.formData.payitemid == null) {

       this.$dialog.alert(this.$t('payitemtoreimburseviapayroll')).then((dialog) => {
                console.log(dialog); 
       });
    } else {
     
      // console.log('form new', payload: this.formData})

      this.$store.dispatch('post', {url: "/claimtypesetup/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "claimtypesetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    }
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    cancelHandler() {
      this.$router.push({ name: "claimtypesetuplist" });
    },
  },
};
</script>