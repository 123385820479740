<template>
  <div>
    <!-- <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading> -->
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>

<script>
//import moment from "moment";
//import Loading from "vue-loading-overlay";
//import "vue-loading-overlay/dist/vue-loading.css";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
    //Loading
  },

  data() {
    return {
      pageTitle: this.$t('setupapprvlworkflow.apprvlworkflowlist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "100px" },
        { text: this.$t('columns.workflowtype'), value: "wftypedesc"},
        { text: this.$t('payeeinputprocess.details'), value: "detaildesc"},
      ],
      listItems: [],
      dataItems: [],
      leaveItems: [],
      claimItems: [],
      loading: true,
      errMsg: "",
       search: this.$route.params.search,
    };
  },

  methods: {
    loadApprovalWorkflowItems() {
      const vm = this;

      this.$store.dispatch('get', {url: `/workflow/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.listItems = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadLeaveType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/leavetype/all"})
        .then((res) => {
          vm.leaveItems = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadClaimType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/claimtypesweb/all"})
        .then((res) => {
          vm.claimItems = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    changeHandler() {
      const vm = this;
      if (vm.listItems.length > 0) {
        for (let i = 0; i < vm.listItems.length; i++) {
        vm.listItems[i].detaildesc = null
          if (vm.listItems[i].wftypeid == 3 && vm.listItems[i].detailid) {
            let item = this.leaveItems.find(item => item.id == vm.listItems[i].detailid);
            vm.listItems[i].detaildesc = item.leavedesc
          }
          if (vm.listItems[i].wftypeid == 7 && vm.listItems[i].detailid) {
            
            let item = this.claimItems.find(item => item.id == vm.listItems[i].detailid);
            vm.listItems[i].detaildesc = item.clmtypedesc
          }
        }
        vm.dataItems = vm.listItems
        vm.loading = false
      } else {
        vm.loading = false
      }
    },

    editItemHandler(item) {
      this.$router.push({ name: "workflowedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "workflownew" });
    },
  },

  mounted() {
    this.loadApprovalWorkflowItems();
    this.loadLeaveType();
    this.loadClaimType();

    setTimeout(() => {
      this.changeHandler();
    }, 500);
  },

};
</script>