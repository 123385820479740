<template>
    <div>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-row>
              <v-col cols="3" sm="3" class="pb-n5">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.corporateid')"
                  type="text"
                  v-model="formData.corporateid"
                  maxlength="30"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.clientbatchid')"
                  type="text"
                  v-model="formData.clientbatchid"
                  maxlength="30"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.paymentdate')"
                  type="date"
                  v-model="formData.paymentdate"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
  
            <!-- <v-row>
                          <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                              <v-checkbox
                                  v-model="formData.sameacc"
                                  :label="Combine same account no."
                                  hide-details
                              ></v-checkbox>
                              <v-checkbox
                                  v-model="formData.checkname"
                                  :label="Check Name"
                                  hide-details
                              ></v-checkbox>
                          </v-col>
                      </v-row> -->
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="exportHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          counter: (value) => value.length < 3 || this.$t('reportsparam.counter3'),
        },
        pageTitle: 'MBB-EMAS-02',
  
        parameter: this.parentObject.parameter,
        id: this.parentObject.id,
        data: this.parentObject.data,
        formData: {
          corporateid: "",
          clientbatchid: "",
          paymentdate: "",
          paymentexpdate: "",
          useridpaymentexpdate: "",
          creditingdate: "",
          reference: "",
          description: "",
          sameacc: "",
          checkname: "",
          stamp: "",
          userid: "",
        },
        bank: [
        {
            id: 26,
            bankcode: "MBBEMYKL",
            bankdesc: "Malayan Banking Berhad",
            mbbcode: "001",
          },
          {
          id: 78,
          bankcode: "MBEAMYKL",
          bankdesc: "Maybank Investment Bank",
          },
          {
          id: 79,
          bankcode: "MBEAMY21",
          bankdesc: "Maybank Investment Bank - SPI",
          },
          {
          id: 80,
          bankcode: "MBISMYKL",
          bankdesc: "MAYBANK ISLAMIC BANK",
          },
          {
            id: 16,
            bankcode: "CIBBMYKL",
            bankdesc: "CIMB Bank",
            mbbcode: "006",
          },
          {
          id: 61,
          bankcode: "COIMMYKL",
          bankdesc: "CIMB Investment Bank",
          },
          {
          id: 62,
          bankcode: "COIMMY21",
          bankdesc: "CIMB Investment Bank - SPI",
          },
          {
          id: 63,
          bankcode: "CTBBMYKL",
          bankdesc: "CIMB Islamic Bank",
          },
          {
            id: 4,
            bankcode: "MFBBMYKL",
            bankdesc: "Alliance Bank Malaysia Berhad",
            mbbcode: "003",
          },
          {
            id: 44,
            bankcode: "MBAMMYKL",
            bankdesc: "ALLIANCE INVESTMENT BANK BHD",
            mbbcode: "003",
          },
          {
            id: 45,
            bankcode: "MBAMMY21",
            bankdesc: "ALLIANCE INVESTMENT BANK BHD SPI",
            mbbcode: "003",
          },
          {
            id: 46,
            bankcode: "ALSRMYKL",
            bankdesc: "ALLIANCE ISLAMIC BANK BERHAD",
            mbbcode: "003",
          },
          {
            id: 31,
            bankcode: "RHBBMYKL",
            bankdesc: "RHB Bank",
            mbbcode: "010",
          },
          {
          id: 88,
          bankcode: "OSKIMYKL",
          bankdesc: "RHB Investment Bank",
          },
          {
          id: 89,
          bankcode: "RHBAMYKL",
          bankdesc: "RHB Islamic Bank",
          },
          {
            id: 30,
            bankcode: "PBBEMYKL",
            bankdesc: "Public Bank Berhad",
            mbbcode: "009",
          },
          {
          id: 86,
          bankcode: "SMBBMYK1",
          bankdesc: "Public Investment Bank Bhd",
          },
          {
          id: 87,
          bankcode: "PIBEMYK1",
          bankdesc: "Public Islamic Bank Berhad",
          },
          {
            id: 20,
            bankcode: "HLBBMYKL",
            bankdesc: "Hong Leong Bank Berhad",
            mbbcode: "008",
          },
          {
          id: 69,
          bankcode: "HLIVMYKL",
          bankdesc: "Hong Leong Investment Bank Bhd",
          },
          {
          id: 70,
          bankcode: "HLIBMYKL",
          bankdesc: "Hong Leong Islamic Bank",
          },
          {
            id: 1,
            bankcode: "PHBMMYKL",
            bankdesc: "Affin Bank Berhad",
            mbbcode: "002",
          },
          {
            id: 42,
            bankcode: "HDSBMY2P",
            bankdesc: "AFFIN HWANG INVESTMENT BANK BERHAD",
            mbbcode: "",
          },
          {
            id: 43,
            bankcode: "AIBBMYKL",
            bankdesc: "AFFIN ISLAMIC BANK BERHAD",
            mbbcode: "",
          },
          {
            id: 5,
            bankcode: "ARBKMYKL",
            bankdesc: "AmBank (M) Berhad",
            mbbcode: "",
          },
          {
            id: 47,
            bankcode: "AMMBMYKL",
            bankdesc: "AMINVESTMENT BANK BHD",
            mbbcode: "",
          },
          {
            id: 49,
            bankcode: "AMMBMY21",
            bankdesc: "AMINVESTMENT BANK BHD SPI",
            mbbcode: "",
          },
          {
            id: 48,
            bankcode: "AISLMYKL",
            bankdesc: "AMBANK ISLAMIC BERHAD",
            mbbcode: "",
          },
          {
            id: 14,
            bankcode: "BNPAMYKL",
            bankdesc: "BNP Paribas Malaysia Berhad",
            mbbcode: "029",
          },
          {
          id: 55,
          bankcode: "BNPAMY21",
          bankdesc: "BNP Paribas SPI",
          },
          {
            id: 6,
            bankcode: "BKKBMYKL",
            bankdesc: "Bangkok Bank Berhad",
            mbbcode: "",
          },
          {
            id: 9,
            bankcode: "BOFAMY2X",
            bankdesc: "Bank of America Malaysia Berhad",
            mbbcode: "022",
          },
          {
            id: 10,
            bankcode: "BKCHMYKL",
            bankdesc: "Bank of China (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 15,
            bankcode: "PCBCMYKL",
            bankdesc: "China Construction Bank (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 17,
            bankcode: "CITIMYKL",
            bankdesc: "Citibank Berhad",
            mbbcode: "013",
          },
          {
          id: 64,
          bankcode: "CITIMYM1",
          bankdesc: "Citibank - SPI",
          },
          {
            id: 18,
            bankcode: "DEUTMYKL",
            bankdesc: "Deutsche Bank (Malaysia) Berhad",
            mbbcode: "020",
          },
          {
          id: 65,
          bankcode: "DEUTMY21",
          bankdesc: "Deutsche Bank - SPI",
          },
          {
            id: 21,
            bankcode: "HBMBMYKL",
            bankdesc: "HSBC Bank Malaysia Berhad",
            mbbcode: "015",
          },
          {
            id: 71,
            bankcode: "HMABMYKL",
            bankdesc: "HSBC AMANAH MALAYSIA BERHAD",
          },
          {
            id: 22,
            bankcode: "IIMBMYKL",
            bankdesc: "India International Bank (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 23,
            bankcode: "ICBKMYKL",
            bankdesc: "Industrial and Commercial Bank of China (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 24,
            bankcode: "CHASMYKX",
            bankdesc: "J.P. Morgan Chase Bank Berhad",
            mbbcode: "024",
          },
          {
            id: 28,
            bankcode: "BOTKMYKX",
            bankdesc: "MUFG Bank (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 83,
            bankcode: "BOTKMY21",
            bankdesc: "MUFG BANK (MALAYSIA) BHD - SPI",
            mbbcode: "",
          },
          {
            id: 27,
            bankcode: "MHCBMYKA",
            bankdesc: "Mizuho Bank (Malaysia) Berhad",
            mbbcode: "",
          },
          {
            id: 29,
            bankcode: "OCBCMYKL",
            bankdesc: "OCBC Bank (Malaysia) Berhad",
            mbbcode: "014",
          },
          {
            id: 84,
            bankcode: "OABBMYKL",
            bankdesc: "OCBC AL-AMIN BANK BERHAD",
          },
          {
            id: 32,
            bankcode: "SCBLMYKX",
            bankdesc: "Standard Chartered Bank Malaysia Berhad",
            mbbcode: "017",
          },
          {
          id: 92,
          bankcode: "SCSRMYKK",
          bankdesc: "Standard Chartered Saadiq Bank Berhad",
          },
          {
            id: 33,
            bankcode: "SMBCMYKL",
            bankdesc: "Sumitomo Mitsui Banking Corporation Malaysia Berhad",
            mbbcode: "026",
          },
          {
            id: 34,
            bankcode: "NOSCMYKL",
            bankdesc: "The Bank of Nova Scotia Berhad",
            mbbcode: "",
          },
          {
            id: 36,
            bankcode: "UOVBMYKL",
            bankdesc: "United Overseas Bank (Malaysia) Bhd.",
            mbbcode: "019",
          },
          {
            id: 93,
            bankcode: "UOVBMY21",
            bankdesc: "UNITED OVERSEAS BANK (MALAYSIA) BERHAD - SPI",
            mbbcode: "019",
          },
          {
            id: 7,
            bankcode: "BIMBMYKL",
            bankdesc: "Bank Islam Malaysia",
            mbbcode: "004",
          },
          {
            id: 19,
            bankcode: "EON",
            bankdesc: "EON Bank",
            mbbcode: "007",
          },
          {
            id: 8,
            bankcode: "BMMBMYKL",
            bankdesc: "Bank Muamalat (Malaysia)",
            mbbcode: "012",
          },
          {
            id: 12,
            bankcode: "BKRMMYKL",
            bankdesc: "Bank Rakyat",
            mbbcode: "016",
          },
          {
            id: 13,
            bankcode: "BSNAMYK1",
            bankdesc: "Bank Simpanan Nasional",
            mbbcode: "018",
          },
          {
          id: 54,
          bankcode: "BSNAMY21",
          bankdesc: "Bank Simpanan Nasional SPI",
          },
          {
            id: 35,
            bankcode: "ABNAMYKL",
            bankdesc: "The Royal Bank of Scotland Berhad",
            mbbcode: "021",
          },
          {
            id: 25,
            bankcode: "KFHOMYKL",
            bankdesc: "Kuwait Finance House Malaysia",
            mbbcode: "023",
          },
          {
            id: 2,
            bankcode: "AGOBMYKL",
            bankdesc: "Agro Bank (Bank Pertanian Malaysia)",
            mbbcode: "025",
          },
          {
            id: 11,
            bankcode: "BOTKMYKX",
            bankdesc: "Bank of Tokyo-Mitsubishi UFJ (M) Berhad",
            mbbcode: "027",
          },
          {
            id: 3,
            bankcode: "RJHIMYKL",
            bankdesc: "Al Rajhi Bank ",
            mbbcode: "028",
          },
          {
          id: 51,
          bankcode: "PEMBMYK1",
          bankdesc: "BANK PEMBANGUNAN MALAYSIA BERHAD",
          },
          {
          id: 52,
          bankcode: "PEMBMY21",
          bankdesc: "BANK PEMBANGUNAN MALAYSIA BERHAD SPI",
          },
          {
            id: 53,
            defaultcode: "BKRMB",
            bankcode: "BKRMMYKL",
            bankdesc: "BANK KERJASAMA RAKYAT MALAYSIA BERHAD",
          },
          {
          id: 56,
          bankcode: "MCDSMYK1",
          bankdesc: "BURSA MALAYSIA DEPOSITY SDN BHD",
          },
          {
          id: 57,
          bankcode: "BMDRMYK1",
          bankdesc: "BURSA MALAYSIA DERIVATIVES CLEARING BHD",
          },
          {
          id: 58,
          bankcode: "BMSCMYK1",
          bankdesc: "BURSA MALAYSIA SECURITIES BERHAD",
          },
          {
          id: 32,
          bankcode: "CAGAMYKL",
          bankdesc: "CAGAMAS BERHAD",
          },
          {
          id: 33,
          bankcode: "CAGAMY21",
          bankdesc: "CAGAMAS BERHAD SPI",
          },
         {
          id: 66,
          bankcode: "MGTCBEBE",
          bankdesc: "Euroclear",
         },
         {
          id: 67,
          bankcode: "EXMBMYKL",
          bankdesc: "EXPORT-IMPORT BANK MALAYSIA BERHAD",
         },
         {
          id: 68,
          bankcode: "EXMBMY21",
          bankdesc: "EXPORT-IMPORT BANK MALAYSIA BERHAD - SPI",
         },
         {
          id: 72,
          bankcode: "KAFBMYKL",
          bankdesc: "KAF Investment",
        },
        {
          id: 73,
          bankcode: "KAFBMY21",
          bankdesc: "KAF Investment - SPI",
        },
        {
          id: 74,
          bankcode: "KKENMYK1",
          bankdesc: "Kenanga Investment",
        },
        {
          id: 75,
          bankcode: "KKENMY21",
          bankdesc: "Kenanga Investment - SPI",
        },
        {
          id: 76,
          bankcode: "KWAPMYK1",
          bankdesc: "Kumpulan Wang Persaraan Diperbadankan(KWAP)",
        },
        {
          id: 77,
          bankcode: "KWSPMYK1",
          bankdesc: "Kumpulan Wang Simpanan Pekerja(KWSP)",
        },
        {
          id: 37,
          bankcode: "AFBQMYKL",
          bankdesc: "MBSB BANK BERHAD",
        },
        {
          id: 81,
          bankcode: "MIDFMYK1",
          bankdesc: "MIDF Amanah Investment",
        },
        {
          id: 82,
          bankcode: "MIDFMY21",
          bankdesc: "MIDF Amanah Investment - SPI",
        },
        {
          id: 85,
          bankcode: "MEPSMYK1",
          bankdesc: "PAYMENTS NETWORK MALAYSIA SDN BHD - MEPS",
        },
        {
          id: 90,
          bankcode: "MSMEMYK1",
          bankdesc: "SME Bank",
        },
        {
          id: 91,
          bankcode: "MSMEMY21",
          bankdesc: "SME Bank - SPI",
        },
        ],
  
        staffilterlist: [],
        staffaccount: [],
        companyaccount: [],
        psnpayroll: [],
        titleData: null,
  
        companyrecnum: "",
        paymentmode: null,
        bankcode: null,
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
  
        currency: null,
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
      getPsnPayrollListHandler() {
        const vm = this;
  
        this.$store.dispatch('get', {url: `/psnpayroll/all/${this.$store.getters.userId}`})
          .then(function (res) {
            vm.psnpayroll = res.data.rec;
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
  
      getCompanybankaccountListHandler() {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/bankaccount/all"})
          .then(function (res) {
            vm.companyaccount = res.data.rec;
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      getStaffbankaccountListHandler(checkval) {
        const vm = this;
        
        this.$store.dispatch('get', {url: `/staffbankaccount/all/${this.$store.getters.userId}`})
          .then(res => {
            let staffaccholder = res.data.rec;
            
            let dateHolder = vm.data.year + "-" + 
                             vm.padRJ(vm.data.month, 2) + "-" + 
                             vm.daysInMonth(vm.data.month, vm.data.year);
            
            let date = moment(dateHolder).format("YYYY-MM-DD");
          
            for (let i = 0; i < staffaccholder.length; i++) {
              if (staffaccholder[i].startdate <= date && date <= staffaccholder[i].enddate ) {
                    vm.staffaccount.push(staffaccholder[i])
              }
            }
  
            if (checkval) {
              vm.staffaccount = vm.staffaccount.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }
            
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      loadStaffFilter() {
        const vm = this;
  
        this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
          .then(res => {
              vm.staffilterlist = res.data.rec;
              let checkval = false;
              
              for (const key in this.parameter) {
                if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
              }
              this.getStaffbankaccountListHandler(checkval)
              this.getSalHdListHandler(checkval)
              
          })
          .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
          });
      },
  
      getSalHdListHandler(checkval) {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/ppsummarylist/all"})
          .then(function (res) {
            vm.salhdItems = res.data.rec;
            
            if (checkval) {
              vm.salhdItems = vm.salhdItems.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      objectToTxt(header, data, data2, footer) {
        const csvRows = [];
        const dataRows = [];
        const dataRows2 = [];
  
        const headers = Object.keys(data[0]);
        const headers2 = Object.keys(data2[0]);
        const getHeader = header;

        csvRows.push(getHeader);

        //loop over the rows
        for (const row of data) {
          const values = headers.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          dataRows.push(values.join(""));
        }

        for (const row of data2) {
          const values = headers2.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
          });
          dataRows2.push(values.join(""));
        }

        const mergedArray = this.mergeArrays(dataRows, dataRows2);

        csvRows.push(mergedArray.join("\n"));
  
        csvRows.push(footer);
        return csvRows.join("\n");
      },

      mergeArrays(arr1, arr2) {
          let mergedArray = [];
          const maxLength = Math.max(arr1.length, arr2.length);
          
          for (let i = 0; i < maxLength; i++) {
              if (i < arr1.length) {
                  mergedArray.push(arr1[i]);
              }
              if (i < arr2.length) {
                  mergedArray.push(arr2[i]);
              }
          }
          
          return mergedArray;
      },
  
      download(data, title) {
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      async getData() {
       
        let body = [];
        let body2 = [];
        let getPayout = this.data.salexprecdetail;
        let netpay = null;
        let holdpay = [];
        let pay = null;
        let getAmt;
        let rescode;
        let totalAmt = 0;
        let getAcc = this.companyaccount.find(
          (item) => item.accountdesc === this.data.bank
        );
        let allacc = [];
        let selacc = [];
        let selaccbank = [];
        let selaccmode = [];
        let selaccactive = [];
        let selaccpay = [];
        let rowCount = 1;
  
        if (this.salhdItems[0].exchratecode) this.currency = this.salhdItems[0].exchratecode;
  
        
          for (let l = 0; l < getPayout.length; l++) {
            for (let k = 0; k < this.salhdItems.length; k++) {
              if (getPayout[l].payoutctrlid === this.salhdItems[k].poid) {
                for (let j = 0; j < this.staffaccount.length; j++) {
                  if (allacc.indexOf(this.salhdItems[k]) === -1) {
                    allacc.push(this.salhdItems[k]);
                  }
                  if (this.staffaccount[j].staffid === this.salhdItems[k].staffid) {
                    if (selacc.indexOf(this.salhdItems[k]) === -1) {
                      selacc.push(this.salhdItems[k]);
                    }
  
                    for (let a = 0; a < this.bank.length; a++) {
                      if (this.bank[a].id == this.staffaccount[j].bankid) {
                        if (selaccbank.indexOf(this.salhdItems[k]) === -1) {
                          selaccbank.push(this.salhdItems[k]);
                        }
                      
                        for (let i = 0; i < this.psnpayroll.length; i++) {
                          if (
                            this.psnpayroll[i].staffid === this.staffaccount[j].staffid &&
                            this.psnpayroll[i].paymodecode === "BANK"&&
                            moment(this.formData.paymentdate).isBetween(this.psnpayroll[i].startdate, this.psnpayroll[i].enddate)
                          ) {
                            if (selaccmode.indexOf(this.salhdItems[k]) === -1) {
                              selaccmode.push(this.salhdItems[k]);
                            }
  
                            if (this.psnpayroll[i].pystatcode === "ACTIVE") {
  
                              if (selaccactive.indexOf(this.salhdItems[k]) === -1) {
                                selaccactive.push(this.salhdItems[k]);
                              }
  
                              if (this.salhdItems[k].netpay > 0) {
                                if (selaccpay.indexOf(this.salhdItems[k]) === -1) {
                                  selaccpay.push(this.salhdItems[k]);
                                }
  
                                pay = this.salhdItems[k].netpay;
                                netpay = this.salhdItems[k].netpay.toFixed(2);

                                this.staffaccount[j].newic = "";
                                this.staffaccount[j].oldic = "";
                                this.staffaccount[j].passport = "";
                                
                                if (this.staffaccount[j].iddetails) {
                                  if (this.staffaccount[j].idtypeid == 1) {
                                    this.staffaccount[j].newic = this.staffaccount[
                                      j
                                    ].iddetails
                                      .split("-")
                                      .join("");
                                  }
                                  
  
                                  if (this.staffaccount[j].idtypeid == 2) {
                                    this.staffaccount[j].oldic = this.staffaccount[
                                      j
                                    ].iddetails
                                      .split("-")
                                      .join("");
                                  }
  
                                  if (this.staffaccount[j].idtypeid == 3) {
                                    this.staffaccount[j].passport = this.staffaccount[
                                      j
                                    ].iddetails
                                      .split("-")
                                      .join("");
                                  }
                                  if (this.staffaccount[j].idtypeid == 1){
                                     this.staffaccount[j].newic = this.staffaccount[
                                      j
                                    ].iddetails
                                    rescode = 'Y'
                                    } else { rescode = 'N'}
                                }
  
                                if (this.staffaccount[j].bankid == 26) {
                                  this.paymentmode = "IT";
                                  this.bankcode = "MBBEMYKL";
                                } else {
                                  this.paymentmode = "IG";
                                  this.bank.forEach((element) => {
                                    if (element.id === this.staffaccount[j].bankid) {
                                      this.bankcode = element.bankcode;
                                    }
                                  });
                                }
  
                                body.push({
                                  "Record Type": this.padRJ('01') + '|',
                                  "Payment Mode": this.padRJ(this.paymentmode) + '|',
                                  "Provider Product Group Name": "Staff Payroll"+ '|',
                                  "Destination Country": ""+ '|',
                                  "Payment Date": this.padLJ(moment(this.formData.paymentdate).format("DDMMYYYY"))+ '|',
                                  "Cheque date": ""+ '|',
                                  "By Order Of": ""+ '|',
                                  "Customer Reference Number": this.padLJ(rowCount)+ '|',
                                  "Debit Reference": ""+ '|',
                                  "Debit Description": ""+ '|',
                                  "Currency": this.padLJ(this.staffaccount[j].crcycode) + '|',
                                  "Transaction Amount": this.padRJ(Number(netpay).toFixed(2)) + '|',
                                  "In Debit Acc Curr": "Y"+ '|',
                                  "Debitting Currency": this.padLJ(this.staffaccount[j].crcycode) + '|',
                                  "Debitting Acc Num": this.padLJ(getAcc.accountno) + '|',
                                  "Credit/Beneficiary A/C": this.padLJ(this.staffaccount[j].staffaccountno) + '|',
                                  "Biller Code": this.padLJ("") + '|',
                                  "Biller Organization": this.padLJ("") + '|',
                                  "Resident": this.padLJ(rescode) + '|',
                                  "Beneficiery name1": this.padLJ(this.staffaccount[j].accountname.slice(0, 40)) + '|',
                                  "Beneficiery name2": this.padLJ("") + '|',
                                  "Beneficiery name3": this.padLJ("") + '|',
                                  "Beneficiery Contact": this.padLJ("") + '|',
                                  "Beneficiery ID": this.padLJ("") + '|',
                                  "New IC": this.padLJ(this.staffaccount[j].newic.replace(/-/g, ''), 12) + '|', 
                                  "Old IC": this.padLJ(this.staffaccount[j].oldic) + '|',
                                  "Business Registration No": this.padLJ("") + '|',
                                  "Police/ Army ID/ Passport No": this.padLJ(this.staffaccount[j].passport) + '|',
                                  "Bene Address 1": this.padLJ("") + '|',
                                  "Bene Address 2": this.padLJ("") + '|',
                                  "Bene Address 3": this.padLJ("") + '|',
                                  "Bene Country": this.padLJ("") + '|',
                                  "Bene State": this.padLJ("") + '|',
                                  "Bene Prefecture": this.padLJ("") + '|',
                                  "Bene City": this.padLJ("") + '|',
                                  "Bene ZIP/Postal Code": this.padLJ("") + '|',
                                  "Bene Bank Code": this.padRJ(this.bankcode) + '|',
                                  "Bene Bank Branch": this.padLJ("") + '|',
                                  "Bene Bank Name": this.padLJ("") + '|',
                                  "Bene Bank Address 1": this.padLJ("") + '|',
                                  "Bene Bank Address 2": this.padLJ("") + '|',
                                  "Bene Bank Address 3": this.padLJ("") + '|',
                                  "Other Codes": this.padLJ("") + '|',
                                  "Other Codes Value": this.padLJ("") + '|',
                                  "Intermediary Bank Account No.": this.padLJ("") + '|',
                                  "Intermediary Bank Code": this.padLJ("") + '|',
                                  "Intermediary Bank Name": this.padLJ("") + '|',
                                  "Intermediary Bank Address 1": this.padLJ("") + '|',
                                  "Intermediary Bank Address 2": this.padLJ("") + '|',
                                  "Intermediary Bank Address 3": this.padLJ("") + '|',
                                  "Intermediary Bank Other Codes": this.padLJ("") + '|',
                                  "Intermediary Bank Other Codes Value": this.padLJ("") + '|',
                                  "Delivery Method / Collection Method": this.padLJ("") + '|',
                                  "Branch": this.padLJ("") + '|',
                                  "Collector Name/ Company Name": this.padLJ("") + '|',
                                  "Collector ID": this.padLJ("") + '|',
                                  "Attention": this.padLJ("") + '|',
                                  "Delivery Address 1": this.padLJ("") + '|',
                                  "Delivery Address 2": this.padLJ("") + '|',
                                  "Delivery Address 3": this.padLJ("") + '|',
                                  "Delivery Country": this.padLJ("") + '|',
                                  "Delivery State/Province": this.padLJ("") + '|',
                                  "Delivery Prefecture": this.padLJ("") + '|',
                                  "Delivery City": this.padLJ("") + '|',
                                  "Delivery Zip/Postal Code": this.padLJ("") + '|',
                                  "FX Mode": this.padLJ("") + '|',
                                  "Contract Number1": this.padLJ("") + '|',
                                  "Rate1": this.padLJ("") + '|',
                                  "Dealer Name1": this.padLJ("") + '|',
                                  "Amount to Utilize1": this.padLJ("") + '|',
                                  "Contract Number2": this.padLJ("") + '|',
                                  "Rate2": this.padLJ("") + '|',
                                  "Dealer Name2": this.padLJ("") + '|',
                                  "Amount to Utilize2": this.padLJ("") + '|',
                                  "Contract Number3": this.padLJ("") + '|',
                                  "Rate3": this.padLJ("") + '|',
                                  "Dealer Name3": this.padLJ("") + '|',
                                  "Amount to Utilize3": this.padLJ("") + '|',
                                  "Contract Number4": this.padLJ("") + '|',
                                  "Rate4": this.padLJ("") + '|',
                                  "Dealer Name4": this.padLJ("") + '|',
                                  "Amount to Utilize4": this.padLJ("") + '|',
                                  "Contract Number5": this.padLJ("") + '|',
                                  "Rate5": this.padLJ("") + '|',
                                  "Dealer Name5": this.padLJ("") + '|',
                                  "Amount to Utilize5": this.padLJ("") + '|',
                                  "Contract Number6": this.padLJ("") + '|',
                                  "Rate6": this.padLJ("") + '|',
                                  "Dealer Name6": this.padLJ("") + '|',
                                  "Amount to Utilize6": this.padLJ("") + '|',
                                  "Contract Number7": this.padLJ("") + '|',
                                  "Rate7": this.padLJ("") + '|',
                                  "Dealer Name7": this.padLJ("") + '|',
                                  "Amount to Utilize7": this.padLJ("") + '|',
                                  "Reference 1": this.padLJ("") + '|',
                                  "Reference 2": this.padLJ("") + '|',
                                  "Reference 3": this.padLJ("") + '|',
                                  "Reference 4": this.padLJ("") + '|',
                                  "Reference 5": this.padLJ("") + '|',
                                  "Reference 6": this.padLJ("") + '|',
                                  "Reference 7": this.padLJ("") + '|',
                                  "Credit Reference/ Client Reference": this.padLJ("") + '|',
                                  "Credit Description/ Payment Reference": this.padLJ("") + '|',
                                  "Payment Details 1": this.padLJ("") + '|',
                                  "Payment Details 2": this.padLJ("") + '|',
                                  "Payment Details 3": this.padLJ("") + '|',
                                  "Payment Details 4": this.padLJ("") + '|',
                                  "Instruction to Bank 1": this.padLJ("") + '|',
                                  "Instruction to Bank 2": this.padLJ("") + '|',
                                  "Charges Borne By": "01"+ '|',
                                  "Purpose of Transfer": this.padLJ("") + '|',
                                  "Filler 1": this.padLJ("") + '|',
                                  "Filler 2": this.padLJ("") + '|',
                                  "Filler 3": this.padLJ("") + '|',
                                  "Filler 4": this.padLJ("") + '|',
                                  "Filler 5": this.padLJ("") + '|',
                                  "Filler 6": this.padLJ("") + '|',
                                  "Filler 7": this.padLJ("") + '|',
                                  "Filler 8": this.padLJ("") + '|',
                                  "Filler 9": this.padLJ("") + '|',
                                  "Filler 10": this.padLJ("") + '|',
                                  "Filler 11": this.padLJ("") + '|',
                                  "Filler 12": this.padLJ("") + '|',
                                  "Filler 13": this.padLJ("") + '|',
                                  "Filler 14": this.padLJ("") + '|',
                                  "Filler 15": this.padLJ("") + '|',
                                  "Filler 16": this.padLJ("") + '|',
                                  "Filler 17": this.padLJ("") + '|',
                                  "Filler 18": this.padLJ("") + '|',
                                  "Filler 19": this.padLJ("") + '|',
                                  "Filler 20": this.padLJ("") + '|',
                                  "Filler 21": this.padLJ("") + '|',
                                  "Filler 22": this.padLJ("") + '|',
                                  "Filler 23": this.padLJ("") + '|',
                                  "Filler 24": this.padLJ("") + '|',
                                  "Filler 25": this.padLJ("") + '|',
                                  "Filler 26": this.padLJ("") + '|',
                                  "Beneficiary Citizenship": this.padLJ("") + '|',
                                  "Citizenship Country": this.padLJ("") + '|',
                                  "Remitter and Beneficiary Relationship": this.padLJ("") + '|',
                                  "Filler 30": this.padLJ("") + '|',
                                  "Filler 31": this.padLJ("") + '|',
                                  "Purpose Description": this.padLJ("") + '|',
                                  "Filler 33": this.padLJ("") + '|',
                                  "Beneficiary State/ Province Text Box": this.padLJ("") + '|',
                                  "Beneficiary Prefecture Text Box": this.padLJ("") + '|',
                                  "Beneficiary City/ District Text Box": this.padLJ("") + '|',
                                  "Delivery State/ Province Text Box": this.padLJ("") + '|',
                                  "Delivery Prefecture Text Box": this.padLJ("") + '|',
                                  "Delivery City/ District Text Box": this.padLJ("") + '|',
                                  "Purpose code/ Declaration of Purpose": this.padLJ("") + '|',
                                  "Filler 41": this.padLJ("") + '|',
                                  "Filler 42": this.padLJ("") + '|',
                                  "Filler 43": this.padLJ("") + '|',
                                  "Filler 44": this.padLJ("") + '|',
                                  "Filler 45": this.padLJ("") + '|',
                                  "Filler 46": this.padLJ("") + '|',
                                  "Filler 47": this.padLJ("") + '|',
                                  "Filler 48": this.padLJ("") + '|',
                                  "Filler 49": this.padLJ("") + '|',
                                  "Joint Name": this.padLJ("") + '|',
                                  "Joint New ID No": this.padLJ("") + '|',
                                  "Joint Old ID No": this.padLJ("") + '|',
                                  "Joint Business Reg. No.": this.padLJ("") + '|',
                                  "Joint Police/ Army ID/ Passport No.": this.padLJ("") + '|',
                                  "Others Purpose of Transfer": this.padLJ("") + '|',
                                  "Rentas Instruction to Bank": this.padLJ("") + '|',
                                  "Segregation Account No": this.padLJ("") + '|',
                                  "Filler a44": this.padLJ("") + '|',
                                  "Filler a45": this.padLJ("") + '|',
                                  "Filler a46": this.padLJ("") + '|',
                                  "Instruction to Bank 3": this.padLJ("") + '|',
                                  "Instruction to Bank 4": this.padLJ("") + '|',
                                  "Regulatory Information 1": this.padLJ("") + '|',
                                  "Regulatory Information 2": this.padLJ("") + '|',
                                  "Regulatory Information 3": this.padLJ("") + '|',
                                  "Child DC 1": this.padLJ("") + '|',
                                  "Child DC 2": this.padLJ("") + '|',
                                  "Child DC 3": this.padLJ("") + '|',
                                  "Child DC 4": this.padLJ("") + '|',
                                  "Child DC 5": this.padLJ("") + '|',
                                  "Child DC 6": this.padLJ("") + '|',
                                  "Child DC 7": this.padLJ("") + '|',
                                  "Guarantee OUR": this.padLJ("") + '|',
                                  "UETR Number": this.padLJ("") + '|',
                                  "DuitNow ID Type": this.padLJ("") + '|',
                                  "DuitNow ID": this.padLJ("") + '|',
                                  "Transaction Type": this.padLJ("") + '|',
                                  "Payment Ref 1": this.padLJ("") + '|',
                                  "Payment Ref 2": this.padLJ("") + '|',
                                  "Filler 63": this.padLJ("") + '|',
                                  "Filler 64": this.padLJ("") + '|',
                                  "Filler 65": this.padLJ("") + '|',
                                  "Filler 66": this.padLJ("") + '|',
                                  "Filler 67": this.padLJ("") + '|',
                                  "Filler 68": this.padLJ("") + '|',
                                  "Filler 69": this.padLJ("") + '|',
                                  "Filler 70": this.padLJ("") + '|',
                                  "Filler 71": this.padLJ("") + '|',
                                  "Filler 72": this.padLJ("") + '|',
                                  "Filler 73": this.padLJ("") + '|',
                                  "Filler 74": this.padLJ("") + '|',
                                  "Filler 75": this.padLJ("") + '|',
                                  "Filler 76": this.padLJ("") + '|',
                                  "Filler 77": this.padLJ("") + '|',
                                  "Filler 78": this.padLJ("") + '|',
                                  "Filler 79": this.padLJ("") + '|',
                                  "Filler 80": this.padLJ("") + '|',
                                  "Filler 81": this.padLJ("") + '|',
                                  "Filler 82": this.padLJ("") + '|',
                                  "Filler 83": this.padLJ("") + '|',
                                  "Filler 84": this.padLJ("") + '|',
                                  "Filler 85": this.padLJ("") + '|',
                                  "Filler 86": this.padLJ("") + '|',
                                  "Filler 87": this.padLJ("") + '|',
                                  "Filler 88": this.padLJ("") + '|',
                                  "Filler 89": this.padLJ("") + '|',
                                  "Filler 90": this.padLJ("") + '|',
                                  "Filler 91": this.padLJ("") + '|',
                                  "Filler 92": this.padLJ("") + '|',
                                  "Filler 93": this.padLJ("") + '|',
                                  "Filler 94": this.padLJ("") + '|',
                                  "Filler 95": this.padLJ("") + '|',
                                  "Filler 96": this.padLJ("") + '|',
                                  "Filler 97": this.padLJ("") + '|',
                                  "Filler 98": this.padLJ("") + '|',
                                  "Filler 99": this.padLJ("") + '|',
                                  "Filler 100": this.padLJ("") + '|',
                                  "Filler 101": this.padLJ("") + '|',
                                  "Filler 102": this.padLJ("") + '|',
                                  "Filler 103": this.padLJ("") + '|',
                                  "Filler 104": this.padLJ("") + '|',
                                  "Filler 105": this.padLJ("") + '|',
                                  "Filler 106": this.padLJ("") + '|',
                                  "Filler 107": this.padLJ("") + '|',
                                  "Filler 108": this.padLJ("") + '|',
                                  "Filler 109": this.padLJ("") + '|',
                                  "Filler 110": this.padLJ("") + '|',
                                  "Filler 111": this.padLJ("") + '|',
                                  "Filler 112": this.padLJ("") + '|',
                                  "Filler 113": this.padLJ("") + '|',
                                  "Filler 114": this.padLJ("") + '|',
                                  "Filler 115": this.padLJ("") + '|',
                                  "Filler 116": this.padLJ("") + '|',
                                  "Filler 117": this.padLJ("") + '|',
                                  "Filler 118": this.padLJ("") + '|',
                                  "Filler 119": this.padLJ("") + '|',
                                  "Filler 120": this.padLJ("") + '|',
                                  "Filler 121": this.padLJ("") + '|',
                                  "Filler 122": this.padLJ("") + '|',
                                  "Filler 123": this.padLJ("") + '|',
                                  "Filler 124": this.padLJ("") + '|',
                                  "Filler 125": this.padLJ("") + '|',
                                  "Filler 126": this.padLJ("") + '|',
                                  "Filler 127": this.padLJ("") + '|',
                                  "Filler 128": this.padLJ("") + '|',
                                  "Filler 129": this.padLJ("") + '|',
                                  "Filler 130": this.padLJ("") + '|',
                                  "Filler 131": this.padLJ("") + '|',
                                  "Filler 132": this.padLJ("") + '|',
                                  "Filler 133": this.padLJ("") + '|',
                                  "Filler 134": this.padLJ("") + '|',
                                  "Filler 135": this.padLJ("") + '|',
                                  "Filler 136": this.padLJ("") + '|',
                                  "Filler 137": this.padLJ("") + '|',
                                  "Filler 138": this.padLJ("") + '|',
                                  "Filler 139": this.padLJ("") + '|',
                                  "Filler 140": this.padLJ("") + '|',
                                  "Filler 141": this.padLJ("") + '|',
                                  "Filler 142": this.padLJ("") + '|',
                                  "Filler 143": this.padLJ("") + '|',
                                  "Filler 144": this.padLJ("") + '|',
                                  "Filler 145": this.padLJ("") + '|',
                                  "Filler 146": this.padLJ("") + '|',
                                  "Filler 147": this.padLJ("") + '|',
                                  "Filler 148": this.padLJ("") + '|',
                                  "Filler 149": this.padLJ("") + '|',
                                  "Filler 150": this.padLJ("") + '|',
                                  "Filler 151": this.padLJ("") + '|',
                                  "Filler 152": this.padLJ("") + '|',
                                  "Filler 153": this.padLJ("") + '|',
                                  "Filler 154": this.padLJ("") + '|',
                                  "Filler 155": this.padLJ("") + '|',
                                  "Filler 156": this.padLJ("") + '|',
                                  "Filler 157": this.padLJ("") + '|',
                                  "Filler 158": this.padLJ("") + '|',
                                  "Filler 159": this.padLJ("") + '|',
                                  "Filler 160": this.padLJ("") + '|',
                                  "Filler 161": this.padLJ("") + '|',
                                  "Filler 162": this.padLJ("") + '|',
                                  "Filler 163": this.padLJ("") + '|',
                                  "Filler 164": this.padLJ("") + '|',
                                  "Filler 165": this.padLJ("") + '|',
                                  "Filler 166": this.padLJ("") + '|',
                                  "Filler 167": this.padLJ("") + '|',
                                  "Filler 168": this.padLJ("") + '|',
                                  "Filler 169": this.padLJ("") + '|',
                                  "Filler 170": this.padLJ("") + '|',
                                  "Filler 171": this.padLJ("") + '|',
                                  "Filler 172": this.padLJ("") + '|',
                                  "Filler 173": this.padLJ("") + '|',
                                  "Filler 174": this.padLJ("") + '|',
                                  "Filler 175": this.padLJ("") + '|',
                                  "Filler 176": this.padLJ("") + '|',
                                  "Filler 177": this.padLJ("") + '|',
                                  "Filler 178": this.padLJ("") + '|',
                                  "Filler 179": this.padLJ("") + '|',
                                  "Filler 180": this.padLJ("") + '|',
                                  "Filler 181": this.padLJ("") + '|',
                                  "Filler 182": this.padLJ("") + '|',
                                  "Filler 183": this.padLJ("") + '|',
                                  "Filler 184": this.padLJ("") + '|',
                                  "Filler 185": this.padLJ("") + '|',
                                  "Filler 186": this.padLJ("") + '|',
                                  "Filler 187": this.padLJ("") + '|',
                                  "Filler 188": this.padLJ("") + '|',
                                  "Filler 189": this.padLJ("") + '|',
                                  "Filler 190": this.padLJ("") + '|',
                                  "Filler 191": this.padLJ("") + '|',
                                  "Filler 192": this.padLJ("") + '|',
                                  "Filler 193": this.padLJ("") + '|',
                                  "Filler 194": this.padLJ("") + '|',
                                  "Filler 195": this.padLJ("") + '|',
                                  "Filler 196": this.padLJ("") + '|',
                                  "Filler 197": this.padLJ("") + '|',
                                  "Filler 198": this.padLJ("") + '|',
                                  "Filler 199": this.padLJ("") + '|',
                                  "Filler 200": this.padLJ("") + '|',
                                  "Filler 201": this.padLJ("") + '|',
                                  "Filler 202": this.padLJ("") + '|',
                                  "Returned Status - Status of the transaction": this.padLJ("") + '|',
                                  "Return File - Returned Reason": this.padLJ("") + '|',
                                  "Return File – Cheque Number": this.padLJ("") + '|',
                                  "Return File - Cheque Status": this.padLJ("") + '|',
                                  "Return File - Return Status Date": this.padLJ("") + '|',
                                  "Return File - Processing Date": this.padLJ("") + '|',
                                  "Return File - Transaction Ref No OR Stop/Reject Reason": this.padLJ("") + '|',
                                  "Transaction Return Status": this.padLJ("") + '|',
                                });

                                body2.push({
                                  "Record Type": this.padRJ('02') + '|',
                                  "Advice Type": "PA" + '|',
                                  "Customer Reference Number": this.padLJ(rowCount++)+ '|',
                                  "Email": this.staffaccount[j].email ? this.staffaccount[j].email + '|' : this.padLJ("") + '|',
                                  "Fax": this.padLJ("") + '|',
                                  "SMS": this.padLJ("") + '|',
                                  "Advice Detail": this.padLJ("") + '|',
                                  "Number": this.padLJ("") + '|',
                                  "Invoice Number": this.padLJ("") + '|',
                                  "Invoice Date": this.padLJ("") + '|',
                                  "Reference Number 1": this.padLJ("") + '|',
                                  "Reference Number 2": this.padLJ("") + '|',
                                  "Description": this.padLJ("") + '|',
                                  "Payment Advice Amount": this.padRJ(Number(netpay).toFixed(2)) + '|',
                                  "Reference Field 7": this.padLJ("") + '|',
                                  "Reference Field 8": this.padLJ("") + '|',
                                  "Reference Field 9": this.padLJ("") + '|',
                                  "Reference Field 10": this.padLJ("") + '|',
                                  "Amount sign Indicator": this.padLJ("") + '|',
                                  "Merge Advice Indicator": this.padLJ("") + '|',
                                  "Email 2": this.padLJ("") + '|',
                                  "Email 3": this.padLJ("") + '|',
                                  "Email 4": this.padLJ("") + '|',
                                  "Email 5": this.padLJ("") + '|',
                                  "Email 6": this.padLJ("") + '|',
                                  "Email 7": this.padLJ("") + '|',
                                  "Email 8": this.padLJ("") + '|',
                                  "Email 9": this.padLJ("") + '|',
                                  "Email 10": this.padLJ("") + '|',
                                  "Email 11": this.padLJ("") + '|',
                                  "Email 12": this.padLJ("") + '|',
                                  "Email 13": this.padLJ("") + '|',
                                  "Email 14": this.padLJ("") + '|',
                                  "Email 15": this.padLJ("") + '|',
                                  "Email 16": this.padLJ("") + '|',
                                  "Email 17": this.padLJ("") + '|',
                                  "Email 18": this.padLJ("") + '|',
                                  "Email 19": this.padLJ("") + '|',
                                  "Email 20": this.padLJ("") + '|',
                                  "Record Return Status": this.padLJ("") + '|',
                                })
  
                                holdpay.push(pay);
                                this.exCount++;
                            }
                          }
                        }
                      }
                    } 
                  }
                }
              }
            }
          }
        }
  
  
  
        let noacc = allacc.filter((e) => !selacc.includes(e));
        let noaccbank = selacc.filter((e) => !selaccbank.includes(e));
        let notpay = selaccbank.filter((e) => !selaccmode.includes(e));
        let notactive = selaccmode.filter((e) => !selaccactive.includes(e));
        let nopay = selaccactive.filter((e) => !selaccpay.includes(e));
  
        this.processedCount = allacc.length;
        this.successCount = selaccpay.length;
        this.errCount = allacc.length - selaccpay.length;
  
        if (noacc) {
          for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('allbank.noaccfound');
            this.accErr.push(noacc[i]);
          }
        }
  
        if (noaccbank) {
          for (let i = 0; i < noaccbank.length; i++) {
            noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
            this.accErr.push(noaccbank[i]);
          }
        }
  
        if (notpay) {
          for (let i = 0; i < notpay.length; i++) {
            notpay[i].errormsg = this.$t('allbank.differentpaymentmethod');
            this.accErr.push(notpay[i]);
          }
        }
  
        if (notactive) {
          for (let i = 0; i < notactive.length; i++) {
            notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
            this.accErr.push(notactive[i]);
          }
        }
  
        if (nopay) {
          for (let i = 0; i < nopay.length; i++) {
            nopay[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
            this.accErr.push(nopay[i]);
          }
        }
  
        if (totalAmt < 0) {
          this.negErrMsg = this.$t('setupreport_exportmapping.cannotinsertnegativevalue');
        }
  
        if (holdpay) {
          totalAmt = holdpay.reduce(function (a, b) {
            return a + b;
          }, 0);
          getAmt = totalAmt.toFixed(2);
        }
  
        this.gettotalAmt = getAmt;
  
  
        let getHeader =
          "00|" +
          this.padLJ(this.formData.corporateid) +
          "|" +
          this.padLJ(this.formData.clientbatchid) +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padSpaceLJ("");
  
        let footer =
          "99" +
          "|" +
          this.padRJ(this.successCount) +
          "|" +
          this.padRJ(Number(getAmt).toFixed(2)) +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ("") +
          "|" +
          this.padRJ('|');
  
        this.titleData =
        this.padLJ(this.formData.clientbatchid) +
          "_" +
          moment(this.formData.paymentdate).format("YYMMDD") +
          "_" +
          "01";
  
        if (body.length > 0) {
          const csvData = this.objectToTxt(getHeader, body, body2, footer);
          this.download(csvData, this.titleData);
        }
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalAmt = this.gettotalAmt;
          item.accounterr = this.accErr;
          item.negErrMss = this.negErrMsg;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
          item.currency = this.currency;
          this.$router.push({ name: "salaryexportlog", params: { item } });
        }
      },
  
      backHandler() {
        let param = this.parameter
  
        this.$router.push({ name: "salaryexport", params: { param } });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
    mounted() {
      this.loadStaffFilter();
      this.getCompanybankaccountListHandler();
      this.getPsnPayrollListHandler();
    },
  };
  </script>