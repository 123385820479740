<template>
    <div>
        <app-form
            v-bind:parentObject="{
            data: this.formData,
            pageTitle: this.pageTitle,
            errMsg: this.errMsg,
            }"
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:profile="formData.pic = $event"
            v-on:error="errorHandler"
        />

        <div v-if="isErr">
            <app-error
            v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>

import intgsetupForm from "../../../components/settings/intgsetup/Form.vue";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    components: {
        "app-form": intgsetupForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            pageTitle: 'Create New Integration Setup',
            pageTitleValue: "",

            formData: {
                intgdesc: null,
                logic: null,
                destination: null,
                username: null,
                userpwd: null,
                port: null,
                remark: null,
                intsettingd: [],
                search: null,
                stamp: 0,
                userid: this.$store.getters.userId,
            },

            errMsg: "",
            isErr: false
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        // saveHandler($event) {
        saveHandler() {

            // console.log($event)
            
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                return false;
            }

            this.$store.dispatch('post', {url: "/intgsetup", payload: this.formData})
            .then((res) => {
                if (res.data.error) {
                vm.isErr = !vm.isErr;
                vm.errMsg = res.data.error;
                } else {
                this.$router.push({ name: "intgsetuplist" });
                }
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });

        },
    },
};

</script>