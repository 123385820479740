    <template>
    <div>
        <v-dialog v-model="periodicdialog" max-width="800px" @click:outside="closeDetail">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('cntryss.addctrl')}}</span>
              <!--<span class="headline mb-4">Periodic Attendance Incentive Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="8" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.type')"
                            type="text"
                            ref="type"
                            dense
                            :items="cntryssList"
                            v-model="selections.type"
                            :rules="[rules.required]"
                            @change="
                                changeCntrySSHandler(
                                    selections.type
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-checkbox cols="8" sm="6" md="6" class="mt-n8"
                        outlined
                        ref="rel"
                        v-model="selections.rel"
                        :label="this.$t('columns.release')"
                        @change="ChangeRel"
                        dense
                    ></v-checkbox>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDetail">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveDetail">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            periodicdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                type: null,
                rel: false,
                reldesc: 'N',

                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            },
            
            cntryssList:[],
            cntryssListItems: [],

            itemHolder: [],
            detailList: [],
            factor: false,
            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {

        compareArray,
        ChangeRel(val) {
            console.log(val);
            this.selections.rel = val;
            this.selections.reldesc = val ? 'Y': 'N';
            
        },
        loadCntrySSList(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/cntrysslist'
            })
            .then(res => {

                vm.cntryssListItems = res.data.rec;

                for (let i = 0; i < vm.cntryssListItems.length; i++) {
                    vm.cntryssList.push({
                        text: vm.cntryssListItems[i].type
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeCntrySSHandler(id){
            this.selections.type = id;
        },

         modifyItemHandler(item) {
          // assign loaded data to selections array
            if (item.action) {
                this.isExisting = true;
            } else {
                this.modify = true;
            }
            
            this.itemHolder = item
            this.selections =  this.itemHolder;
            //this.selections.userid = this.$store.getters.userId
            console.log("selections", this.selections)
            this.periodicdialog = true;
          },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },

        saveDetail() {
                this.isErr = false;
                if (!this.isExisting && !this.modify) {
                    this.selections.id = null;

                    this.detailItem.push(Object.assign({}, this.selections));

                } else {
                    if(!this.modify){
                        this.selections.action = 2;
                    }
                    if (this.compareArray(this.detailItem, this.selections)) {
                        console.log(this.compareArray(this.detailItem, this.selections))
                    }
                    
                }
            // console.log('detailitem', this.detailItem)
                this.$emit('input', this.detailItem);
                this.periodicdialog = false;
                

                // reset the form fields
                this.closeDetail();
        },

        
        closeDetail() {
            this.periodicdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        resetSelections() {
            this.selections = {
                id: null,
                type: null,
                rel: false,
                reldesc: 'N',
                
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }
        },

    },

    created() {
        this.loadCntrySSList();
    }
}
</script>   


       