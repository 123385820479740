<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
    
                    <v-row>
                      <v-col cols="4" sm="4" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.fromyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.fromYear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollsummarylistingreport.month')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.month1"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('claimutilizationtrendanalysis.untilyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.untilYear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="2" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollsummarylistingreport.month')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.month2"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        </v-col>

                    </v-row>


                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaimlimit.selectclaimtype')"
                                    type="text"
                                    ref="clmtype"
                                    clearable
                                    v-model="formData.clmtypeid"
                                    dense
                                    :items="clmtypeList"
                                    @change="changeClaimTypeHandler(formData.clmtypeid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypedesc"
                                    v-model="formData.clmtypedesc"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                    clearable
                                ></v-text-field>
                            </v-col>
                    </v-row> 
                    
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('claimutilizationtrendanalysis.reportingcurrency')"
                                type="text"
                                ref="reportingcrcyid"
                                clearable
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.reportingcrcyid"
                                @change="changeCurrencyHandler(formData.reportingcrcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>


                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.reportingcrcycode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.exchangerate')"
                            type="text"
                            clearable
                            :items="exchangeRateList"
                            :rules="[rules.required]"
                            v-model="formData.exchrateid"
                            @change="changeExchrateHandler(formData.exchrateid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="exchageRateCode"
                        ></v-text-field>
                    </v-col>
                </v-row>
                   
                        </div>
                    </v-card>

                     <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="goHandler" 
                                > 
                                {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{

              rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },

            formData:{
               /// asatdate: new Date().toISOString().slice(0,10),
                fromYear: null,
                month1: null,
                untilYear: null,
                month2: null,
                clmtypeid: null,
                clmtypedesc: null,
                reportingcrcyid: null,
                reportingcrcycode: null,
                exchrateid: null,
                title: this.$t('claimutilizationtrendanalysis.claimutilizationtrendanlysis')
            },

              parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                deptname: null,
                divisionid: null,
                sectionid: null,
                unitid: null,
                gradeid: null,
                employmenttypeid: null,  
                claimgroupid: null,             
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                gradeid: true,
                employmenttypeid: true,  
                claimgroupid: true,
            },

            currencyList: [],
            exchangeRateList: [],
            currencyCode: '',
            exchageRateCode: '',

            yearList: [],
            monthList: [],

            clmtypeItems: [],
            clmtypeList: [],
            clmtypedesc: '',
            isErr: false,
            errMsg: [],
        }
    },

    methods: {

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },

        
        LoadClaimType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'})
            .then(res => {

                vm.clmtypeItems = res.data.rec;

                for (let i = 0; i < vm.clmtypeItems.length; i++) {
                    vm.clmtypeList.push({
                        text: vm.clmtypeItems[i].clmtype,
                        value: vm.clmtypeItems[i].id,
                    });
                }

                if (this.formData.clmtypeid) {
                this.changeClaimTypeHandler(this.formData.clmtypeid);
                }   
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeClaimTypeHandler(id) {
            var item = this.clmtypeItems.find(item => item.id === id);
            this.clmtypeid = item.clmtype;
            this.formData.clmtypedesc = item.clmtypedesc;
        },


         goHandler() {

             const formData = this.formData
             const param = this.parameters

             if ((this.formData.fromYear != null && this.formData.month1 != null &&
             this.formData.untilYear != null && this.formData.month2 != null &&
             this.formData.reportingcrcyid != null && this.formData.exchrateid != null) )  {
             

            this.$router.push({name: 'claimutilizationtrendanalysisdetails', params: { formData, param } });

            } else {
                this.$refs.form.validate()
            }

          
        },

            loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.reportingcrcyid) {
                        vm.changeCurrencyHandler(vm.formData.reportingcrcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.formData.reportingcrcycode = item.crcycode;
        },

         loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/exchrate/all'})
                .then(res => {
                    vm.exchangeRate = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangeRateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.exchrateid) {
                        vm.changeExchrateHandler(vm.formData.exchrateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeExchrateHandler(id) {
            let item = this.exchangeRate.find(item => item.id === id);

            this.exchageRateCode = item.exchratecode;
        },

    
    },
    mounted() {

        this.loadCurrencyListHandler();
        this.loadExchangeRate();
        this.LoadClaimType();
        this.yearGeneration();
        this.monthGeneration();
    }
}
</script>