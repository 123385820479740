<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData= $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";


import RDayScheduleInstructionForm from "../../../components/leave/rdayscheduleinstruction/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": RDayScheduleInstructionForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setuprestdayschedule.editrestdayschedulelist'),
      pageTitleValue: "",
      formData: {
        wdaygrpid: null,
        valuecode: null,
        valuedesc: null,
        startdate: null,
        enddate: null,
        rdaypatternid: null,
        rdaypatterndesc: null,
        rdaypatterncode: null,
        //logicscrpt: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
    };
  },

  methods: {

    errorHandler($event) {
    this.isErr = $event.isErr;
    this.errMsg = $event.errMsg;
    },

    loadScheduleInstructionHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/rdayscheduleinstruction/" + vm.id})
        .then((res) => {

          if (res.data.rec[0].startdate) {
              vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
              vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }

          vm.formData.wdaygrpid = res.data.rec[0].wdaygrpid;
          vm.formData.valuecode = res.data.rec[0].valuecode;
          vm.formData.valuedesc = res.data.rec[0].valuedesc;
          vm.formData.rdaypatternid = res.data.rec[0].rdaypatternid;
          vm.formData.rdaypatterndesc = res.data.rec[0].rdaypatterndesc;
          vm.formData.rdaypatterncode = res.data.rec[0].rdaypatterncode;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.formData.stamp = res.data.rec[0].stamp;
          
          vm.pageTitleValue = res.data.rec[0].valuedesc;
          vm.loading = false;

        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      //var payload = this.formData[0];

      this.$store.dispatch('put', {url: "/rdayscheduleinstruction/" + vm.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "rdayscheduleinstructionlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    // cancelHandler() {
    //   this.$router.push({ name: "rdayscheduleinstructionlist" });
    // },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

         this.$store.dispatch('delete', {url:
            "/rdayscheduleinstruction/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "rdayscheduleinstructionlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "rdayscheduleinstructionnew" });
    },
  },

  mounted() {
    this.loadScheduleInstructionHandler();
  },
};
</script>