<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>
                
                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>

                            <v-container>
                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n5 mt-n4">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.startdate')"
                                        type="date"
                                        ref="startdate"
                                        :rules="[rules.required]"
                                        v-model="formData.startdate"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="5" class="pb-n5 mt-n4">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.enddate')"
                                        type="date"
                                        ref="enddate"
                                        :rules="[rules.required]"
                                        v-model="formData.enddate"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="6" class="pb-n5 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        v-model="formData.usertypeid"
                                        :label="this.$t('columns.usertype')"
                                        type="text"
                                        :items="this.userTypeList"
                                        :rules="[rules.required]"
                                        dense
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            </v-container>

                            <div class="pb-n8 mt-n8"/>
                            <v-subheader class="blue--text">{{$t('recbatchusercreation.defaultpassword')}}</v-subheader>

                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                                        <v-radio-group 
                                            v-model="formData.radiogroup"
                                            >
                                            <v-radio
                                                :label="this.$t('recbatchusercreation.useidnoinpersonscreenasdefaultpassword')"
                                                value="idpasswd"
                                            ></v-radio>
                                            <v-radio
                                                :label="this.$t('recbatchusercreation.userandomlygeneratedpassword_defaultedto8character')"
                                                value="randpasswd"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                                        {{$t('recbatchusercreation.idnowillbeusedasdefaultloginid')}}
                                    </v-col>
                                    <v-col cols="12" sm="12" class="pb-n4 mt-n4">
                                        {{$t('recbatchusercreation.emailwillbesenttoemployees')}}
                                    </v-col>
                                </v-row>
                            </v-container>

                        </div>
                    </v-card>

                    <app-parameter 
                        class="mt-5 mb-3"
                        v-on:input="parameters = $event"
                        v-bind:parentObject="{
                            filter: this.showFilter,
                        }"
                    />
                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px;
                            margin-bottom: 20px;" 
                            @click="processHandler"> 
                            {{$t('button.process')}}
                        </v-btn>
                    </div>
                </v-row>
            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import moment from "moment";
import FilterParameter from '../../../components/common/FilterParameter';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-parameter': FilterParameter,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            parameters: {
                staffid: null,
                gradeid: null,
                employmenttypeid: null,
                companyid: null,
                branchid: null,
                departmentid: null,
                unitid: null,
                divisionid: null,
                positionid: null,
                sectionid: null,
                jobid: null
            },
            showFilter: {
                staffid: true,
                gradeid: true,
                employmenttypeid: true,
                companyid: true,
                branchid: true,
                departmentid: true,
                unitid: true,
                divisionid: true,
                positionid: true,
                sectionid: true,
                jobid: true
            },
            formData: {
                startdate: null,
                enddate: null,
                usertypeid: null,
                radiogroup: 'idpasswd',
                userid: this.$store.getters.userId
            },
            userTypeList: [],
            pageTitle: this.$t('batchusercreation'),
            isErr: false,
            errMsg: null
        }
    },

    methods: {
        getUserTypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/usertype/all'})
                .then(function (res) {
                    vm.exportformatItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.userTypeList.push(
                            {
                                text: res.data.rec[i].type,
                                value: res.data.rec[i].id
                            }
                        )
                    } 
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });
        },

        processHandler() {
            const vm = this;
            for (const key in this.parameters) {
                if (this.parameters[key] == undefined || this.parameters[key] == '') {
                    delete this.formData[key]
                } else {
                    this.formData[key] = this.parameters[key]
                }
            }
            
            if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                return false;
            }
            
            for (const key in this.formData) {
                if (!this.formData[key]) {
                    return false;
                }
            }
            
            this.$store.dispatch('post', {url: "/batchusercreation/create", payload: this.formData})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                    } else {
                        this.$router.push({name: 'batchusercreationlog', params: res.data.rec });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;    
                });
            
        },
        

    },

    mounted() {
        this.getUserTypeListHandler()
    }
}
</script>
