<template>
    <div id="intgsetupgrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>
<script>
// import moment from 'moment';
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            // pageTitle: this.$t('recpersonalinfo.personlist'), 
            pageTitle: this.$t('intgsetup.intgsetuplist'), 
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('columns.description'), value: 'intgdesc', width: '200px' },
                { text: this.$t('columns.logic'), value: 'type', width: '200px' },
                { text: this.$t('intgsetup.sourcedest'), value: 'destination', width: '300px' },
                { text: this.$t('columns.user'), value: 'username', width: '200px' },
                // { text: 'Password', value: 'userpwd', width: '200px' },
                { text: this.$t('columns.port'), value: 'port', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' }
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getintgsetupListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: `/intgsetup/all/`})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    // console.log('Hello', vm.dataItems);
                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            console.log(item);
            this.$router.push({name: 'intgsetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'intgsetupnew'});
        }
    },

    mounted() {
        this.getintgsetupListHandler();
    }
}
</script>