<template>
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('generateunpaidleaverecord.attendancefromdate')"
                                    type="date"
                                    ref="asofdate1"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>


                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('generateunpaidleaverecord.attendanceuntildate')"
                                    type="date"
                                    ref="asofdate2"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate2"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>

                        </v-row>

                        <v-row>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
                                    type="text"
                                    ref="compnyid"
                                    :rules="[rules.required]"
                                    v-model="formData.compnyid"
                                    dense
                                    :items="companyList"
                                    @input="updateValue"
                                    @change="changeCompany(formData.compnyid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('attendancereportbypeiordbycompanyreport.companycode')"
                                    type="text"
                                    ref="compnycode"
                                    v-model="formData.compnycode"
                                    dense
                                    readonly
                                    append-icon=""
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                 <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px; margin-bottom: 20px;"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                asofdate1: null,
                asofdate2: null,
                compnyid: null,
                compnycode: null,

            },
            
                parameters: {
                 staffid: null,
                 staffcode: null,
                 branchid: null,
                 brchname: null,
                 sectionid: null,
                 sectioname: null,
                 unitid: null,
                 unitname: null,
                 divisionid: null,
                 divname: null,
                 departmentid: null,
                 deptname: null,
                 workgroupid: null,
                 valudesc2:null,
                
             },

             showFilter: {
                staffid: true,
                staffcode: true,
                sectionid: true,
                unitid: true,
                divisionid: true,
                branchid: true,
                departmentid: true,
                workgroupid: true,
             },

            companyname: [],
            companyList: [],
            errMsg: "",
            isErr: false
        }
    },

    methods: {

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
                let item = this.companyname.find((item) => item.id === id);
                this.formData.compnyid = item.id;
                this.formData.compnycode = item.compnycode;

            },


        goHandler(){

            const formData = this.formData
            const param = this.parameters

            if(this.formData.asofdate1 > this.formData.asofdate2 || this.asofdate2 < this.formData.asofdate1 ){

                this.$dialog.alert("Attendance From Date should be earlier than Attendance Until Date").then((dialog) => {
                console.log('dailog', dialog);
             });

            } else if ((this.formData.asofdate1 != null && this.formData.asofdate2 != null && this.formData.compnyid != null))  {
                
                 this.$router.push({name: 'detailsattendanceabsent', params: { formData, param } });

             } else {
                this.$refs.form.validate()
             }   
        },

        updateValue() {
            this.$emit("input", {
                 asofdate1: this.formData.asofdate1,
                 asofdate2: this.formData.asofdate2,
                 compnyid: this.formData.compnyid,
                 compnycode: this.formData.compnycode,
                
            });
        },
    }, 

    mounted() { 
      this.loadCompanyListHandler();
     
    },
    
}
</script>
