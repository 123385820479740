<template>
    <div v-if="!loading">
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col>
              <h2 class="pb-5">{{ this.pageTitle }}</h2>
            </v-col>
          </v-row>
  
          <!-- <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          > -->
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('alltax.taxsubmissionrec')"
                type="text"
                v-model="formData.taxexprecid"
                :items="taxExpRecList"
                :rules="[rules.required]"
                @change="changeTaxExportRecord(formData.taxexprecid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" sm="3" class="mb-n1 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
                type="text"
                v-model="formData.year"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
  
            <v-col cols="12" sm="3" class="mb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('bankpaymentlistingreport.payoutmonth')"
                type="text"
                v-model="formData.month"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}} </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12" sm="10">
              <v-data-table
                :headers="dataHeader"
                :items="this.payoutItems"
                :options="tableOpt"
                hide-default-footer
                item-key="payoutcode"
              >
              </v-data-table>
            </v-col>
          </v-row>
  
          <!-- <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-3">
              <v-text-field
                outlined
                :label="Reference No."
                type="text"
                v-model="formData.refnum"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row> -->
  
           <v-row>
              <v-col cols="12" sm="6" class="pb-n5 mt-3">
                <v-autocomplete
                  outlined
                  :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                  type="text"
                  v-model="formData.refnum"
                  :rules="[rules.required]"
                  :items="taxSubRefRecList"
                  dense
              
                ></v-autocomplete>
              </v-col>
            </v-row>
  
          <!-- <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                v-model="formData.taxexpformatid"
                :label="this.$t('alltax.taxexportformat')"
                type="text"
                :items="this.exportFmtList"
                :rules="[rules.required]"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row> -->
          <!-- </v-container> -->
  
          <v-row>
            <div class="pt-5 pl-4 mt-n6">
              <v-btn color="warning" style="width: 100px; margin-bottom: 20px;" @click="nextHandler">
                {{$t('button.next')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    components: {
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$t('reportsparam.invalidemail');
          },
        },
        pageTitle: this.$t('mmpaye.title'),
        dataHeader: [
          {
            text: this.$t('reportsparam.payout'),
            value: "payoutcode",
            width: "150px",
            sortable: true,
          },
          {
            text: this.$t('statutoryexportcommonused.payoutdescription'),
            value: "payoutdesc",
            width: "300px",
            sortable: true,
          },
          {
            text: this.$t('payrollprocessrun.payoutstatus'),
            value: "pystatusdesc",
            width: "300px",
            sortable: true,
          },
        ],
        formData: {
          aprname: null,
          apricno: null,
          apremail: null,
          aprtel: null,
          taxexprecid: null,
          refnum: null,
          year: null,
          month: null,
          myntlycode: null,
          taxsubrecpy: [],
          taxsubrecref: [],
          taxexpformatid: null,
        },
  
        tableOpt: {
          itemsPerPage: -1,
        },
  
        items: [],
        dataItems: [],
        salaryExpRecList: [],
        taxExpRecList: [],
        payoutItems: [],
        exportformatItems: [],
        exportFmtList: [],
        taxSubRefRecList: [],
  
        noPayout: false,
        noSubrecref: false,
        notFinalize: false,
         data2: [],
        loading: false,
        isErr: false,
        errMsg: "",
      };
    },
  
    methods: {
      getTaxSubRecs() {
        const vm = this;
  
        this.loading = !this.loading;
  
        this.$store.dispatch('get', {url: "/mmtaxsubmissionrecord/all"})
          .then(function (res) {
            vm.dataItems = res.data.rec;
  
            for (let i = 0; i < res.data.rec.length; i++) {
              vm.taxExpRecList.push({
                text: res.data.rec[i].taxsubreccode,
                value: res.data.rec[i].id,
              });
            }
  
            vm.loading = !vm.loading;
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      getTaxSubRecPayout(id) {
        const vm = this;
  
        this.loading = !this.loading;
  
        this.$store.dispatch('get', {url: "/mmtaxsubmissionrecordpy/" + id})
          .then((res) => {
            vm.payoutItems = res.data.rec;
            vm.formData.taxsubrecpy = vm.payoutItems;
  
            if (vm.payoutItems.length > 0) {
              for (let i = 0; i < vm.payoutItems.length; i++) {
                if (vm.payoutItems[i].pystatuscode != 99) {
                  this.notFinalize = false;
                } else {
                  this.notFinalize = true;
                }
  
                   vm.data2.push(this.notFinalize)
              }
            } else {
              this.noPayout = true;
            }
  
            vm.loading = !vm.loading;
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      getTaxSubRecRef(id) {
        const vm = this;
  
        this.loading = !this.loading;
  
        this.$store.dispatch('get', {url: "/mmtaxsubmissionrecordref/" + id})
          .then((res) => {
            vm.formData.taxsubrecref = res.data.rec;
  
             //set the taxsubrefreclist array null (to avoid getting value from previous array)
             vm.taxSubRefRecList = [];
  
      
              for (let i = 0; i < res.data.rec.length; i++) {
              vm.taxSubRefRecList.push({
                text: res.data.rec[i].refcode + ' - ' + res.data.rec[i].refnum,
                value: res.data.rec[i].refnum,
              });
            }
    
  
            vm.loading = !vm.loading;
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      // getTaxSubRecReference(id){
      //   const vm = this;
  
      //   this.loading = !this.loading;
  
      //    axios
      //     .get', {url: "/taxsubmissionrecordref/" + id)
      //     .then((res) => {
      //       vm.formData.taxsubrecreference = res.data.rec;
  
      //     for (let i = 0; i < res.data.rec.length; i++) {
      //         vm.taxSubRefRecList.push({
      //           text: res.data.rec[i].refnum,
      //           value: res.data.rec[i].refnum,
      //         });
      //       }
      //       vm.loading = !vm.loading;
            
      //     })
      //     .catch((err) => {
      //       vm.isErr = !vm.isErr;
      //       vm.errMsg = err;
      //     });
  
  
  
      // },
  
      getExportFormattListHandler() {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/exportformat/all"})
          .then(function (res) {
            vm.exportformatItems = res.data.rec;
  
            for (let i = 0; i < res.data.rec.length; i++) {
              if (res.data.rec[i].exptypeid == 5) {
                vm.exportFmtList.push({
                  text: res.data.rec[i].expdesc,
                  value: res.data.rec[i].id,
                });
              }
            }
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      changeTaxExportRecord(id) {
        let itemExportRecord = this.dataItems.find((item) => item.id === id);
  
        this.formData.taxexprecid = itemExportRecord.id;
        this.formData.year = itemExportRecord.recyear;
        this.formData.month = itemExportRecord.recmonth;
        // this.formData.myntlycode = itemExportRecord.myntlycode;
        // this.formData.aprname = itemExportRecord.aprname;
        // this.formData.apricno = itemExportRecord.apricno;
        // this.formData.apremail = itemExportRecord.apremail;
        // this.formData.aprtel = itemExportRecord.aprtel;
  
        this.getTaxSubRecPayout(id);
        this.getTaxSubRecRef(id);
      },
  
      nextHandler() {
        let item = this.formData;
  
        let id = 1;
  
        var newvalue = true;
      
        for (let i = 0; i < this.data2.length; i++) {
                 
          if (!this.data2[i])
          {
            newvalue = false;
          }
        }
        console.log('itemm', item)
        
        
  
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else if (this.noPayout) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.payoutismissing');
        } else if (this.noSubrecref) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.norefnumberfound');
        } else {
          if (newvalue == false) {
            this.isErr = true;
            this.errMsg = this.$t('vdatatable.payoutnotfinalized');
          } else {
            this.$router.push({
              name: "mmmonthlytaxexportparam",
              params: { id, item },
            });
          }
        }
      },
    },
  
    mounted() {
      this.getTaxSubRecs();
      this.getExportFormattListHandler();
    },
  };
  </script>