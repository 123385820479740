<template>
  <div>
    <v-dialog v-model="leaveentitlementdialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('workhourspayitemmappingsetup.workhoursmappingitem')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="8" sm="8" md="8" class="pb-n5 mb-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.selectdaytype')"
                    type="text"
                    ref="psnid"
                    :rules="[rules.required]"
                    v-model="selections[0].daytypeid"
                    dense
                    :items="daytypedetailsList"
                    @change="changeDayTypeHandler(selections[0].daytypeid)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4" class="pb-n5 mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.daytypecode')"
                    type="text"
                    v-model="selections[0].daytypecode"
                    dense
                    readonly
                    append-icon=""
                    background-color="#ffeab2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6" class="mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.fromhours')"
                    type="number"
                    :rules="[rules.percentage]"
                    v-model="selections[0].fromhours"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6" class="mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.uptohours')"
                    type="number"
                    :rules="[rules.percentage]"
                    v-model="selections[0].uptohours"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" sm="8" md="8" class="pb-n5 mb-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.selectpayitem')"
                    type="text"
                    :rules="[rules.required]"
                    v-model="selections[0].payitemid"
                    dense
                    :items="payitemList"
                    @change="changePayitemHandler(selections[0].payitemid)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4" class="pb-n5 mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.payitemcode')"
                    type="text"
                    v-model="selections[0].payitemcode"
                    dense
                    readonly
                    append-icon=""
                    background-color="#ffeab2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="mb-n8">
                  <v-checkbox
                    outlined
                    v-model="selections[0].IsExclusive"
                    :label="this.$t('workhourspayitemmappingsetup.exclusive')"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6" class="mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.sourcehours')"
                    type="text"
                    :rules="[rules.required]"
                    v-model="selections[0].sourcehour"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6" class="mb-n8">
                  <v-text-field
                    outlined
                    :label="this.$t('workhourspayitemmappingsetup.mappedqty')"
                    type="text"
                    :rules="[rules.required]"
                    v-model="selections[0].mappedqty"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <!-- <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{ errMsg }}</span>
              </v-card-text> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeModal">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="saveRecord">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="this.parentObject.tabHeader"
      :items="dataItems"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <!-- <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="subtitle-2"
                    >Partial Day</v-toolbar-title
                  >
                </v-toolbar>
              </template> -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import compareArray from "../../../util/comparearray";

export default {
  props: {
    parentObject: Object,
  },
  components: {},

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0) || this.$t('workhourspayitemmappingsetup.valueismore'),
      },

      leaveentitlementdialog: false,
      dataItems: this.parentObject.detailItem,
      selections: [
        {
          index: 0,
          id: null,
          daytypeid: "",
          daytypecode: "",
          daytypedesc: "",
          fromhours: "",
          uptohours: "",
          payitemid: "",
          payitemcode: "",
          payitemdesc: "",
          exclusive: "",
          IsExclusive: false,
          sourcehour: "",
          mappedqty: "",
          action: 0,
          stamp: 0,
          userid: this.$store.getters.userId,
        },
      ],

      payitems: [],
      payitemList: [],
      daytypedetailsList: [],
      daytypedetails: [],

      modify: false,
      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    compareArray,
    loadPayItem() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/payitem/codes"
      }).then((res) => {
        vm.payitems = res.data.rec;

        for (let i = 0; i < vm.payitems.length; i++) {
          vm.payitemList.push({
            text: vm.payitems[i].payitemdesc,
            value: vm.payitems[i].id,
          });
        }
      });
    },
    changePayitemHandler(id) {
      let item = this.payitems.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].payitemid = item.id;
      this.selections[0].payitemcode = item.payitemcode;
      this.selections[0].payitemdesc = item.payitemdesc;
    },
    loadDaytypeDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/daytype/all"
      })
        .then(function (res) {
          vm.daytypedetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.daytypedetailsList.push({
              text: res.data.rec[i].daytypedesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    changeDayTypeHandler(id) {
      let item = this.daytypedetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].daytypeid = item.id;
      this.selections[0].daytypecode = item.daytypecode;
      this.selections[0].daytypedesc = item.daytypedesc;
    },
    modifyItemHandler(item) {
      // assign loaded data to selections array

      if (item.action == 1) {
        this.modify = true;
      }

      if (item.action == 2) {
        this.isExisting = true;
      }

      if (item.exclusive == "Y") {
        item.IsExclusive = true;
      } else {
        item.IsExclusive = false;
      }

      this.selections = [
        {
          index: item.index,
          id: item.id,
          daytypeid: item.daytypeid,
          daytypecode: item.daytypecode,
          daytypedesc: item.daytypedesc,
          fromhours: item.fromhours,
          uptohours: item.uptohours,
          payitemid: item.payitemid,
          payitemcode: item.payitemcode,
          payitemdesc: item.payitemdesc,
          exclusive: item.exclusive,
          IsExclusive: item.IsExclusive,
          sourcehour: item.sourcehour,
          mappedqty: item.mappedqty,
          action: item.action,
          stamp: item.stamp,
          userid: item.userId,
        },
      ];

      this.leaveentitlementdialog = true;
    },
    deleteItemHandler(item) {
      let itemIndex = this.dataItems.indexOf(item);
      this.dataItems.splice(itemIndex, 1);
      this.$emit("delete", this.dataItems);
    },
    closeModal() {
      this.leaveentitlementdialog = false;
      this.isErr = false;
      this.isExisting = false;
      this.modify = false;

      // reset the form fields
      this.resetSelections();
    },
    saveRecord() {
      if (this.selections[0].IsExclusive) {
        this.selections[0].exclusive = "Y";
      } else {
        this.selections[0].exclusive = "N";
      }

      if (this.$refs.form.validate()) {
        if (!this.isExisting && !this.modify) {
          this.selections[0].id = null;
          this.selections[0].action = 1;
          this.selections[0].index = this.dataItems.length;
          this.dataItems.push(Object.assign({}, this.selections[0]));
        } else {
          for (let i = 0; i < this.dataItems.length; i++) {
            if (this.dataItems[i].index == this.selections[0].index) {
              this.dataItems[i].id = this.selections[0].id;
              this.dataItems[i].daytypeid = this.selections[0].daytypeid;
              this.dataItems[i].daytypecode = this.selections[0].daytypecode;
              this.dataItems[i].daytypedesc = this.selections[0].daytypedesc;
              this.dataItems[i].fromhours = this.selections[0].fromhours;
              this.dataItems[i].uptohours = this.selections[0].uptohours;
              this.dataItems[i].payitemid = this.selections[0].payitemid;
              this.dataItems[i].payitemcode = this.selections[0].payitemcode;
              this.dataItems[i].payitemdesc = this.selections[0].payitemdesc;
              this.dataItems[i].exclusive = this.selections[0].exclusive;
              this.dataItems[i].IsExclusive = this.selections[0].IsExclusive;
              this.dataItems[i].sourcehour = this.selections[0].sourcehour;
              this.dataItems[i].mappedqty = this.selections[0].mappedqty;
              this.dataItems[i].action = this.selections[0].action;
              this.dataItems[i].action = this.selections[0].action;
              this.dataItems[i].stamp = this.selections[0].stamp;
              this.dataItems[i].userid = this.selections[0].userid;
            }
          }
        }
        this.$emit("input", this.dataItems);
        this.modify = false;
        this.isExisting = false;
        this.leaveentitlementdialog = false;
        // reset the form fields
        this.resetSelections();
      }
    },
    resetSelections() {
      this.selections = [
        {
          id: null,
          daytypeid: "",
          daytypecode: "",
          fromhours: "",
          uptohours: "",
          payitemid: "",
          payitemcode: "",
          exclusive: "",
          IsExclusive: false,
          sourcehour: "",
          mappedqty: "",
          action: 0,
          stamp: 0,
          userid: this.$store.getters.userId,
        },
      ];
    },
  },

  created() {
    this.loadPayItem();
    this.loadDaytypeDetailsListHandler();
    this.resetSelections();
  },
};
</script>