<template>
    <div>
        <v-dialog v-model="reportDialog" max-width="800px">
          <template v-slot:activator="{ on }">
             <v-row>
          <v-col cols="1">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </v-col>

          <v-col>
          </v-col>
          <v-col cols="2">
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  style="width: 300px"
                  class="pr-5 pt-3 "
                  hide-details
              ></v-text-field>
          </v-col>


        
        </v-row>
      </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupbaitulmal.statutoryreportgroupitem')}}</span>
            </v-card-title>
           
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayitem.payitem')"
                            type="text"
                            :items="payitemList"
                            v-model="selections[0].payitemid"
                            @change="changePayItemHandler(selections[0].payitemid)"
                            dense
                            
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.ea')"
                            type="text"
                            :items="eaList"
                            v-model="selections[0].eaid"
                           @change="changeEAHandler(selections[0].eaid)"
                            dense
                            clearable
                            @click:clear="resetEA()"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                   
                <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.tp1')"
                            type="text"
                            :items="tp1List"
                            v-model="selections[0].tp1id"
                            @change="changeTP1Handler(selections[0].tp1id)"
                            dense
                            clearable
                            @click:clear="resetTP1()" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.cp22')"
                            type="text"
                            :items="cp22List"
                            v-model="selections[0].cp22id"
                             @change="changeCP22Handler(selections[0].cp22id)"
                            dense
                            clearable
                            @click:clear="resetCP22()" 
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.cp21')"
                            type="text"
                            :items="cp21List"
                            v-model="selections[0].cp21id"
                            @change="changeCP21Handler(selections[0].cp21id)"
                            dense
                            clearable
                            @click:clear="resetCP21()" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupbaitulmal.payslip')"
                            type="text"
                            v-model="selections[0].payslip"
                            dense
                            clearable
                            @click:clear="resetPayslip()" 
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                     <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupclaim.payitemdescription')"
                            type="text"
                            v-model="selections[0].payitemdesc"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                       <v-text-field
                            outlined
                            :label="this.$t('setupbaitulmal.payitemtypedesc')"
                            type="text"
                            v-model="selections[0].payitemtypedesc"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>

             
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeReportSetup">{{$t('button.cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="saveReportSetup">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editDialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupbaitulmal.statutoryreportgroupitem')}}</span>
            </v-card-title>
           
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayitem.payitem')"
                            type="text"
                            :items="payitemList"
                            v-model="selections[0].payitemid"
                            @change="changePayItemHandler(selections[0].payitemid)"
                            dense
                            
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.ea')"
                            type="text"
                            :items="eaList"
                            v-model="selections[0].eaid"
                           @change="changeEAHandler(selections[0].eaid)"
                            dense
                            clearable
                            @click:clear="resetEA()"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                   
                <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.tp1')"
                            type="text"
                            :items="tp1List"
                            v-model="selections[0].tp1id"
                            @change="changeTP1Handler(selections[0].tp1id)"
                            dense
                            clearable
                            @click:clear="resetTP1()" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.cp22')"
                            type="text"
                            :items="cp22List"
                            v-model="selections[0].cp22id"
                             @change="changeCP22Handler(selections[0].cp22id)"
                            dense
                            clearable
                            @click:clear="resetCP22()" 
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.cp21')"
                            type="text"
                            :items="cp21List"
                            v-model="selections[0].cp21id"
                            @change="changeCP21Handler(selections[0].cp21id)"
                            dense
                            clearable
                            @click:clear="resetCP21()" 
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupbaitulmal.payslip')"
                            type="text"
                            v-model="selections[0].payslip"
                            dense
                            clearable
                            @click:clear="resetPayslip()" 
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                     <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupclaim.payitemdescription')"
                            type="text"
                            v-model="selections[0].payitemdesc"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                       <v-text-field
                            outlined
                            :label="this.$t('setupbaitulmal.payitemtypedesc')"
                            type="text"
                            v-model="selections[0].payitemtypedesc"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>

             
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeReportSetup">{{$t('button.cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="editReportSetup">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            :search="search"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>

            
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            isPayRate: false,
            reportDialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                statutoryreportid: null,
                payitemid: null,
                payitemcode: null,
                payitemdesc: null,
                eaid: null,
                eafield_id: null,
                tp1id: null,
                tp1field_id: null,
                cp22id: null,
                cp22field_id: null,
                cp21id: null,
                cp21field_id: null,
                payslip: null,
                payitemtypeid: null,
                payitemtypedesc: null,
                payitemtypecode: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            //arrays to store various data objects from db 
            payitems: [],
            payitemList: [],
            payitemtypes: [],
            payitemtypeList: [],
            tp1List: [],
            reportsetups: [],
            eaList: [],
            cp22List: [],
            cp21List: [],
            search: '',

            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;    
            }
            
            if(item.action == 2) {
                this.isExisting = true;    
            }

            this.selections[0] = item;
            this.editDialog = true;

            // if (!this.isExisting){
            //     // duplicate this.detailItem for duplicate checking
                
            //     //find index 

            //     var index = this.detailItem.findIndex( x => x.payitemid == item.payitemid);

            //     console.log("index", index)
            //     console.log("detail tiem", this.detailItem)

            //     this.detailItem[index].eaid = item.eaid;
            //     this.detailItem[index].eafield_id = item.eafield_id;
            //     this.detailItem[index].tp1id = item.tp1id;
            //     this.detailItem[index].tp1field_id = item.tp1field_id;
            //     this.detailItem[index].cp22id = item.cp22id;
            //     this.detailItem[index].cp22field_id = item.cp22field_id;
            //     this.detailItem[index].cp21id = item.cp21id;
            //     this.detailItem[index].cp21field_id = item.cp21field_id;
            //     this.detailItem[index].payslip = item.payslip;

            //     this.resetSelections();


            // } else {
            //     this.selections[0].action = 2;

            //     if (this.compareArray(this.detailItem, this.selections[0])) {
            //         console.log(this.compareArray(this.detailItem, this.selections[0]))
            //     }
            // }

            //this.editDialog = true;
            console.log(item)
            //this.resetSelections;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

           // this.updateValue();
        },

        closeReportSetup() {
            this.reportDialog = false;
            this.editDialog = false; 
            this.isErr = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

    
        
        saveReportSetup() {

            if (this.selections[0].payitemid == null  ){

              this.$dialog.alert(this.$t('setupbaitulmal.statmsg1')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            this.isErr = false;

            console.log("save")
            
            // indicate value of 1 as insert, 2 as modify


            if (!this.isExisting){
                // duplicate this.detailItem for duplicate checking
                

                    if (this.detailItem.some( rec => rec.payitemid  == this.selections[0].payitemid)) {
                        console.log("here")
                        this.errMsg = this.$t('setupbaitulmal.statmsg2');
                        this.isErr = true;
                        this.reportDialog = true; 
                        
                    } 
                    
                    else {

                        this.selections[0].action = 1;
                        console.log("selectioin", this.selections[0])
                        console.log("detail item", this.detailItem)
                        this.detailItem.push(Object.assign({}, this.selections[0]));
                        this.reportDialog = false;
                        this.$emit('input', this.detailItem);
                         // reset the form fields
                        this.resetSelections();
                       

                    }

            } else {
                this.selections[0].action = 2;

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }

            }

            this.$emit('input', this.detailItem);
            this.reportDialog = false;
            
            // reset the form fields
            this.resetSelections();

            }
            
        },

        editReportSetup() {
            this.isErr = false; 
            this.editDialog = false; 
            this.modify = false;
            

            this.resetSelections();
            //this.editDialog = false;


            
        },
        
        resetSelections() {
            this.selections = [{
               id: null,
                statutoryreportid: null,
                payitemid: null,
                payitemcode: null,
                payitemdesc: null,
                eaid: null,
                eafield_id: null,
                tp1id: null,
                tp1field_id: null,
                cp22id: null,
                cp22field_id: null,
                cp21id: null,
                cp21field_id: null,
                payslip: null,
                payitemtypeid: null,
                payitemtypedesc: null,
                payitemtypecode: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },


        //load  data objects from db, stored in arrays - pay item, pay item type , ea , tp1, cp22, cp21,  
    
        loadPayItem() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitems/all'})
            .then (res => {
                vm.payitems = res.data.rec;

                for (let i = 0; i < vm.payitems.length; i++) {
                    vm.payitemList.push(
                        {   
                            payitemtypedesc: vm.payitems[i].payitemtypedesc,
                            payitemdesc: vm.payitems[i].payitemdesc,
                            text: vm.payitems[i].payitemcode,
                            value: vm.payitems[i].id
                        }
                    )
                }
            });
        },

        loadPayitemTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitemtype/all'})
            .then (res => {
                vm.payitemtypes = res.data.rec;

                for (let i = 0; i < vm.payitemtypes.length; i++) {
                    vm.payitemtypeList.push(
                        {
                            text: vm.payitemtypes[i].payitemtypedesc,
                            value: vm.payitemtypes[i].id
                        }
                    )
                }
            });
        },

        loadReportSetupTypesHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/reportsetup/all'})
            .then (res => {
                vm.reportsetups = res.data.rec;

                console.log("report setups", vm.reportsetups)

                for (let i = 0; i < vm.reportsetups.length; i++) {
                    if (vm.reportsetups[i].formtype == 'EA') {
                         vm.eaList.push(
                            {
                                text: vm.reportsetups[i].fieldid + " - " + vm.reportsetups[i].fielddesc,
                                value: vm.reportsetups[i].id
                            }
                        )
                    }


                    if (vm.reportsetups[i].formtype == 'TP1') {
                         vm.tp1List.push(
                            {
                                text: vm.reportsetups[i].fieldid + " - " + vm.reportsetups[i].fielddesc,
                                value: vm.reportsetups[i].id
                            }
                        )
                    }

                    if (vm.reportsetups[i].formtype == 'CP22') {
                         vm.cp22List.push(
                            {
                                text: vm.reportsetups[i].fieldid + " - " + vm.reportsetups[i].fielddesc ,
                                value: vm.reportsetups[i].id
                            }
                        )
                    }

                    if (vm.reportsetups[i].formtype == 'CP21') {
                         vm.cp21List.push(
                            {
                                text: vm.reportsetups[i].fieldid + " - " + vm.reportsetups[i].fielddesc ,
                                value: vm.reportsetups[i].id
                            }
                        )
                    }
                   
                }
            });
        },


        //set selections[0] values
        changePayItemHandler(id) {
            let item = this.payitems.find(item => item.id == id)
         
            this.selections[0].payitemcode = item.payitemcode;
            console.log('payitemcode', this.selections[0].payitemcode)
            this.selections[0].payitemdesc = item.payitemdesc;
            this.selections[0].payitemtypedesc = item.payitemtypedesc;

        },

        changeEAHandler(id) {
            let item =  this.reportsetups.find(item => item.id == id);
           
            this.selections[0].eafield_id = item.fieldid ;

        },

        changeTP1Handler(id) {
            let item =  this.reportsetups.find(item => item.id == id);

            this.selections[0].tp1field_id = item.fieldid ;

        },

        changeCP22Handler(id) {
            let item =  this.reportsetups.find(item => item.id == id);

            this.selections[0].cp22field_id = item.fieldid ;

        },

        changeCP21Handler(id) {
            let item =  this.reportsetups.find(item => item.id == id);

            this.selections[0].cp21field_id = item.fieldid ;

        },

        //for clearable action 
        resetEA() {
            this.selections[0].eaid = null;
            this.selections[0].eafield_id = null; 
        },

        resetTP1() {
            this.selections[0].tp1id = null;
            this.selections[0].tp1field_id = null; 
        },

        resetCP21() {
            this.selections[0].cp21id = null;
            this.selections[0].cp21field_id = null; 
        },

        resetCP22() {
            this.selections[0].cp22id = null;
            this.selections[0].cp22field_id = null; 
        },



    },

    created() {
        this.loadPayItem();
        this.loadPayitemTypes();
        this.loadReportSetupTypesHandler();

        
    
    }
}
</script>