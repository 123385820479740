<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">
            {{ this.pageTitle
            }}
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
        
        <!-- <app-parameter 
            class="mt-5 mb-3"
            v-on:input="parameters = $event"
            v-bind:parentObject="{
                filter: this.showFilter,
            }"
            @input="check()"
        /> -->

        <app-parameter 
            class="mt-5 mb-3"
            v-on:input="parameters = {
              staffid: $event.staffid,
              gradeid: $event.gradeid,
              emptypeid: $event.employmenttypeid,
              compnyid: $event.companyid,
              brchid: $event.branchid,
              deptid: $event.departmentid
            }"
            v-bind:parentObject="{
                filter: this.showFilter,
            }"
            @input="checkFilter()"
        />

        <!-- <v-btn color="primary" dark class="mb-2" @change="goToday()"
          >Today</v-btn
        > -->

        <v-row>
          <v-col cols="8">
            <calendar-view
              :show-date="this.showDate"
              class="theme-default"
              @click-date="onClickDate"
              :events="calendaritems"
            >
              <template #header="{ headerProps }">
                <calendar-view-header
                  :header-props="headerProps"
                  @input="setShowDate"
                />
              </template>
            </calendar-view>
          </v-col>
          <v-col cols="4">
            <p style="font-size: 14px">
              <span style="color: blue">{{$t('dayname.today')}} {{this.todaydate}}</span> 
              <span style="color: orange">({{this.totalStaffPresentToday}}%)</span>
            </p>
            <div v-for="item in todayStaffListLeave" :key="item.listid">
              <p>
                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
              </p>
            </div>
            <p style="font-size: 14px">
              <span style="color: blue">{{$t('dayname.tomorrow')}} {{this.tomorrowdate}}</span> 
              <span style="color: orange">({{this.totalStaffPresentTomorrow}}%)</span>
            </p>
            <div v-for="item in tomorrowStaffListLeave" :key="item.listid">
              <p>
                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p><span style="color: blue">{{this.loaddate}}</span></p>
            <div v-for="item in loadStaffListLeave" :key="item.listid">
              <p @click="goTo(item)">
                <span v-if="item.lvlstatusholder == '(A)'" style="color: green">{{item.lvlstatusholder}}</span> 
                <span v-if="item.lvlstatusholder == '(P)'" style="color: red">{{item.lvlstatusholder}}</span> 
                {{item.leavecode}} - <span style="color: grey">{{item.psnname}}</span>
              </p>
            </div>
          </v-col>
        </v-row>
          
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<style>
#calendar {
  color: #2c3e50;
  height: 60vh;
  width: 100%;
}

.theme-default .cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
  /* color: yellow; */
  background: none;
  border: none;
  color: red;
  font-size: 14px;
}
</style>

<script>
import moment from "moment";
import FilterParameter from '../../../components/common/FilterParameter';
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";

export default {
  // props: {
  //     parentObject: Object
  // },

  components: {
    'app-parameter': FilterParameter,
    CalendarView,
    CalendarViewHeader,
  },

  data() {
    return {
      // showDate: new Date().toISOString().slice(0,10),
      showDate: new Date(),
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('whoisonleave'),
      todaydate: null,
      tomorrowdate: null,
      loaddate: null,
      startMonthDate: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
      endMonthDate: moment(new Date()).endOf('month').format("YYYY-MM-DD"),

      showFilter: {
          staffid: true,
          gradeid: true,
          employmenttypeid: true,
          companyid: true,
          branchid: true,
          departmentid: true
      },
      parameters: {
          staffid: null,
          gradeid: null,
          emptypeid: null,
          compnyid: null,
          brchid: null,
          deptid: null
      },

      calendaritems: [],
      leaverecordList: [],
      todayStaffListLeave: [],
      todayStaffListHolder: [],
      tomorrowStaffListLeave: [],
      loadStaffListLeave: [],
      totalStaff: [],
      totalStaffPresentToday: [],
      totalStaffPresentTomorrow: [],
      totalStaffMov: [],
      loadDateRecord: []
    };
  },

  watch: {

    startMonthDate(){

      const vm = this;
      //let itemholder = []
      let id = 0;

      this.todayStaffListLeave = [];
      this.tomorrowStaffListLeave = [];
      this.loadStaffListLeave = [];

      this.calendaritems = [];
      // this.leaverecordList = [];
      // this.todayStaffListHolder = [];
      // this.totalStaff = [];
      // this.totalStaffPresentToday = [];
      // this.totalStaffPresentTomorrow = [];
      // this.totalStaffMov = [];
      this.loadDateRecord = [];

      this.$store.dispatch('get', {url: `/leaverecordwho/all/${this.$store.getters.userId}/${vm.startMonthDate}/${vm.endMonthDate}`})
        .then(async (res) => {

          let dataitems = res.data.rec

          for (let i = 0; i < dataitems.length; i++) {
            let leaved = await this.checkDate(dataitems[i])
            dataitems[i].tleave = leaved.hday
            id++
          }

          if (id == dataitems.length) {
          
            dataitems.map(val => {
              let it2 = this.totalStaffMov.filter(val2 => {
                return val2.staffid == val.staffid && val2.recdate <= val.startdate && val2.recenddate >= val.enddate 
              })[0]
              val.emptypeid = it2.emptypeid
              val.brchid = it2.brchid
              val.compnyid = it2.compnyid
              val.deptid = it2.deptid
              val.gradeid = it2.grdid
              return val
            })

            let dateh = []
            for (let i = 0; i < dataitems.length; i++) {
              let getd = [...new Set(dataitems[i].tleave.map(item => item))]
              dateh = dateh.concat(getd)
            }
            let fdate = [...new Set(dateh.map(x => x))]
            let g = 0
            for (let i = 0; i < fdate.length; i++) {
              let dt = dataitems.filter(x => {
              let hol = x.tleave.indexOf(fdate[i])
              if (hol == -1) {
                return false
              }
              return x
            })

            let newdt = [...dt]
            
            for (let j = 0; j < newdt.length; j++) {
              let itm = {...newdt[j], cdate: fdate[i], listid: g++}
              vm.leaverecordList.push(itm)
            }

            vm.calendaritems.push({
              id: i+1,
              startDate: fdate[i],
              title: newdt.length
            })
          }
          this.loadRecordToday(vm.leaverecordList);
          this.loadRecordTomorrow(vm.leaverecordList);
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    }

  },

  methods: {

    async checkDate(item) {
        const vm = this
        
        const dataholder = {
            staffid: item.staffid,
            leavetypeid: item.leavetypeid,
            startdate: moment(item.startdate).format('YYYY-MM-DD'),
            enddate: moment(item.enddate).format('YYYY-MM-DD'),
            userid: vm.$store.getters.userId
        }

        let leavedays = this.$store.dispatch('post', {url: "/leave/leaveday", payload: dataholder})
            .then(res => {
                let item = res.data.rec
                item.hday = item.normalday.filter(val => {
                  return val >= dataholder.startdate && val <= dataholder.enddate
                })
                return item
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
            });

        return leavedays
    },

    loadRecordCountList() {
      const vm = this;
      //let itemholder = []
      let id = 0

      this.$store.dispatch('get', {url: `/leaverecordwho/all/${this.$store.getters.userId}/${vm.startMonthDate}/${vm.endMonthDate}`})
        .then(async (res) => {

          let dataitems = res.data.rec;

          for (let i = 0; i < dataitems.length; i++) {
            let leaved = await this.checkDate(dataitems[i])
            dataitems[i].tleave = leaved.hday
            id++
          }

          if (id == dataitems.length) {
          
            dataitems.map(val => {
              let it2 = this.totalStaffMov.filter(val2 => {
                return val2.staffid == val.staffid && val2.recdate <= val.startdate && val2.recenddate >= val.enddate 
              })[0]
              val.emptypeid = it2.emptypeid
              val.brchid = it2.brchid
              val.compnyid = it2.compnyid
              val.deptid = it2.deptid
              val.gradeid = it2.grdid
              return val
            })

            let dateh = []
            for (let i = 0; i < dataitems.length; i++) {
              let getd = [...new Set(dataitems[i].tleave.map(item => item))]
              dateh = dateh.concat(getd)
            }
            let fdate = [...new Set(dateh.map(x => x))]
            let g = 0
            for (let i = 0; i < fdate.length; i++) {
              let dt = dataitems.filter(x => {
              let hol = x.tleave.indexOf(fdate[i])
              if (hol == -1) {
                return false
              }
              return x
            })

            let newdt = [...dt]
            
            for (let j = 0; j < newdt.length; j++) {
              let itm = {...newdt[j], cdate: fdate[i], listid: g++}
              vm.leaverecordList.push(itm)
            }

            vm.calendaritems.push({
              id: i+1,
              startDate: fdate[i],
              title: newdt.length
            })
          }
          this.loadRecordToday(vm.leaverecordList);
          this.loadRecordTomorrow(vm.leaverecordList);
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },

    loadRecordToday(data) {

      this.todaydate = moment().format('DD-MM-YYYY');
      let todayholder = moment().format('YYYY-MM-DD');

      let dataholder = data.filter(x => {
        return x.cdate == todayholder
      })

      for (let i = 0; i < dataholder.length; i++) {
        if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
        if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
        if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
        if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
      }

      let dataNotDuplicate = this.removeDuplicateKeys(dataholder);
      this.todayStaffListLeave = dataNotDuplicate
      this.todayStaffListHolder = dataNotDuplicate
      this.totalStaffPresentToday = this.countStaffPresent(dataNotDuplicate.length)
    },

    loadRecordTomorrow(data) {

      this.tomorrowdate = moment().add(1,'days').format('DD-MM-YYYY');
      let tomorrowholder = moment().add(1,'days').format('YYYY-MM-DD');
      
      let dataholder = data.filter(x => {
        return x.cdate == tomorrowholder
      })

      for (let i = 0; i < dataholder.length; i++) {
        if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
        if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
        if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
        if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
      }

      let dataNotDuplicate = this.removeDuplicateKeys(dataholder);
      this.tomorrowStaffListLeave = dataNotDuplicate
      this.totalStaffPresentTomorrow = this.countStaffPresent(dataNotDuplicate.length)
    },

    loadRecordList(date) {
      let dataholder = [];

      let getdatadefault = this.leaverecordList.filter(x => {
        return x.cdate == date
      })

      let getdatafilter = this.loadDateRecord.filter(x => {
        return x.cdate == date
      })

      this.loadDateRecord.length > 0 ? dataholder = getdatafilter : dataholder = getdatadefault

      for (let i = 0; i < dataholder.length; i++) {
        if (dataholder[i].lvstatusid == 1) dataholder[i].lvlstatusholder = '(P)'
        if (dataholder[i].lvstatusid == 2) dataholder[i].lvlstatusholder = '(A)'
        if (dataholder[i].lvstatusid == 3) dataholder[i].lvlstatusholder = '(R)'
        if (dataholder[i].lvstatusid == 4) dataholder[i].lvlstatusholder = '(C)'
      }

      let data = this.removeDuplicateKeys(dataholder);
      this.loadStaffListLeave = data;
    },

    removeDuplicateKeys(arr) {
      const ids = arr.map(o => o.listid)
      const filtered = arr.filter(({listid}, index) => !ids.includes(listid, index + 1))
      return filtered;
    },

    loadTotalStaff() {
      const vm = this;
      
      this.$store.dispatch('get', {url: "/staff/alls"})
        .then((res) => {
          this.totalStaff = res.data.rec
          this.loadStaffMov();
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },

    loadStaffMov() {
      const vm = this;
      
      this.$store.dispatch('get', {url: `/staffmov/all/${this.$store.getters.userId}`})
        .then((res) => {
          this.totalStaffMov = res.data.rec
          this.loadRecordCountList();
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },

    countStaffPresent(val) {
        const staffholder = this.totalStaff.length

        let item = ((staffholder-val)/staffholder)*100
        return item.toFixed(2)
    },
    
    setShowDate(d) {
      this.showDate = d;
      this.startMonthDate = moment(this.showDate).startOf('month').format("YYYY-MM-DD");
      this.endMonthDate = moment(this.showDate).endOf('month').format("YYYY-MM-DD");
    },

    onClickDate(d) {
      this.loaddate = moment(d).format("DD-MM-YYYY")
      this.loadRecordList(moment(d).format("YYYY-MM-DD"));
    },

    goTo(item) {
      this.$router.push({name: 'whoisonleavedetail', params: { item }});
    },

    checkFilter() {
      const vm = this
      vm.calendaritems = []
      const query = this.filterParam(vm.parameters);
      const result1 = this.filerData(vm.leaverecordList, query);
      let data = this.removeDuplicateKeys(result1);

      const dateh = [...new Set(data.map(item => item.cdate))]
      
      for (let i = 0; i < dateh.length; i++) {
        let tdate = data.filter(x => x.cdate == dateh[i])
        vm.calendaritems.push({
          id: i+1,
          startDate: dateh[i],
          title: tdate.length
        })
      }

      // const dateh = [...new Set(result1.map(item => item.cdate))]
      // for (let i = 0; i < dateh.length; i++) {
      //   let tdate = result1.filter(x => x.cdate == dateh[i])
      //   vm.calendaritems.push({
      //     id: i+1,
      //     startDate: dateh[i],
      //     title: tdate.length
      //   })
      // }

      // console.log('result',result1);
      // console.log('data', data);

      this.loadRecordToday(data)
      this.loadRecordTomorrow(data)
      this.loadDateRecord = data
      this.loadStaffListLeave = []
      this.loaddate = null
    },

    filterParam(filter) {
      let query = {};
      for (const key in filter) {
        if (filter[key]) {
          query[key] = filter[key];
        }
      }
      return query
    },

    filerData(data, query) {
      const filteredData = data.filter(item => {
        for (let key in query) {
          if (item[key] === undefined || query[key] != item[key]) {
              return false;
          }
        }
        return true;
      });
      return filteredData;
    }
  },

  mounted() {
    this.loadTotalStaff();
  },
};
</script>