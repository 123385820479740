<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        >
        </app-gridview>
    </div>
</template>
<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('recassetassignmentrec.assetassignmentlisting'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: "action", width: "5px", sortable: false },
                { text: this.$t('columns.name'), value: "staffname", width: "350px"},
                { text: this.$t('columns.staffcode'), value: "staffcode", width: "100px"},
                { text: this.$t('columns.company'), value: "compnycode", width: "120px"},
                { text: this.$t('recassetrec.assettype'), value: "assettype", width: "120px"},
                { text: this.$t('recassetrec.unitno'), value: "unitno", width: "110px"},
                { text: this.$t('recassetassignmentrec.assetdesc'), value: "assetdesc", width: "150px"},
                { text: this.$t('recassetassignmentrec.aasigndate'), value: "assigndate", width: "150px"},
                { text: this.$t('recassetassignmentrec.returndate'), value: "returndate", width: "150px"},
                { text: this.$t('recassetrec.quantity'), value: "qty", width: "100px"},
                { text: this.$t('recassetrec.serialno'), value: "serialno", width: "150px"},
                { text: this.$t('recassetassignmentrec.returncondition'), value: "assetcondition", width: "150px"},
                { text: this.$t('columns.status'), value: "assigncasestatus", width: "150px"},
            ],

            dataItems: [],
            getId: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },
    methods: {
        loadAssetAssignmentList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/assetassignment/all'})
            .then (res => {
                vm.dataItems = res.data.rec

                for(let i = 0; i < vm.dataItems.length; i++){
                    if (vm.dataItems[i].assigndate) {
                        vm.dataItems[i].assigndate = moment(vm.dataItems[i].assigndate).format('YYYY-MM-DD');
                    }
                    if (vm.dataItems[i].returndate) {
                        vm.dataItems[i].returndate = moment(vm.dataItems[i].returndate).format('YYYY-MM-DD');
                    }
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        editItemHandler(item) {
            this.$router.push({name: 'assetassignmentedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'assetassignmentnew'});
        }
    },

    mounted() {
        this.loadAssetAssignmentList();
    }
}
</script>