<template>
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                       <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('claimtransactionlistingreport.receiptfromdate')"
                                        type="date"
                                        id="fromDate"
                                        v-model="formData.fromDate"
                                        :rules="[rules.required]"
                                        dense                                
                                    ></v-text-field>
                                </v-col>

                                 <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('claimtransactionlistingreport.receiptuntildate')"
                                        type="date"
                                        id="untilDate"
                                        v-model="formData.untilDate"
                                        :rules="[rules.required]"
                                        dense                                
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaimlimit.selectclaimtype')"
                                    type="text"
                                    ref="clmtype"
                                  
                                    v-model="formData.clmtypeid"
                                    dense
                                    :items="clmtypeList"
                                    @change="changeClaimTypeHandler(formData.clmtypeid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypedesc"
                                    v-model="formData.clmtypedesc"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                    clearable
                                ></v-text-field>
                            </v-col>
                    </v-row>

                      <v-row>
                         <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.status')"
                            type="text"
                            ref="transtatus"
                            clearable
                        
                            v-model="formData.transtatusid"
                            dense
                            :items="transtatusList"
                            @change="changeTranStatusHandler(formData.transtatusid)"
                        ></v-autocomplete>
                        </v-col> 
                      </v-row>
                    

                        <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="clmviapayroll"
                                v-model="formData.clmviapayroll"
                                :label="this.$t('claimtransactionlistingreport.excludeclaimtypesreimburseviapayroll')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    </div>
                </v-card>

                 <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px; margin-bottom: 20px;"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                fromDate: null,
                untilDate: null,
                clmtypeid: null,
                clmtypedesc: null,
                transtatusid: null,
                
                clmviapayroll: false,
              
            },
            
             parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                divisionid: null,
                sectionid: null,
                unitid: null,
                claimgroupid: null,    
                employmenttypeid: null, 
                staffid: null, 
                        
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                employmenttypeid: true,  
                claimgroupid: true,
                staffid: true,
            },


            transtatusItems: [],
            transtatusList: [],
            transtatusLoading: true,

            clmtypeItems: [],
            clmtypeList: [],
            clmtypedesc: '',

            errMsg: "",
            isErr: false
        }
    },

    methods: {

        
        LoadClaimType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'})
            .then(res => {

                vm.clmtypeItems = res.data.rec;

                for (let i = 0; i < vm.clmtypeItems.length; i++) {
                    vm.clmtypeList.push({
                        text: vm.clmtypeItems[i].clmtype,
                        value: vm.clmtypeItems[i].id,
                    });
                }

                if (this.formData.clmtypeid) {
                this.changeClaimTypeHandler(this.formData.clmtypeid);
                }   
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeClaimTypeHandler(id) {
            var item = this.clmtypeItems.find(item => item.id === id);
            this.clmtypeid = item.clmtype;
            this.formData.clmtypedesc = item.clmtypedesc;
        },


        loadTranStatusListHandler() {
        const vm = this;

            this.$store.dispatch('get', {
                url: '/transtatus/all'})
                .then(res => {
                    vm.transtatusItems = res.data.rec;
                    console.log('trans',this.transtatusItems)

                        for (let i = 0; i < res.data.rec.length; i++) {
                    
                        vm.transtatusList.push(
                            {
                                text: res.data.rec[i].transtatus,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.transtatusLoading = false;

                    if (vm.formData.transtatusid) {
                        vm.changeTranStatusHandler(vm.formData.transtatusid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeTranStatusHandler(id) {
            let item = this.transtatusItems.find(item => item.id === id);

            this.transtatusid = item.id;
            this.formData.transtatus = item.transtatus;
        },

        goHandler(){

         //   const vm = this;

            const formData = this.formData
            const param = this.parameters

             if(this.formData.fromDate > this.formData.untilDate || this.untilDate < this.formData.fromDate ){

                this.$dialog.alert("Receipt From Date should be earlier than Receipt Until Date").then((dialog) => {
                console.log(dialog);
             });

            } else if ((this.formData.fromDate != null && this.formData.untilDate != null))  {
                
                 this.$router.push({name: 'claimtransactiondetails', params: { formData, param } });

             } else {
                this.$refs.form.validate()
             }  

          //  this.$router.push({name: 'claimtransactiondetails', params: { formData, param } });

        },

        updateValue() {
            this.$emit("input", {
                 fromDate: this.formData.fromDate,
                 untilDate: this.formData.untilDate,
                 clmtypeid: this.formData.clmtypeid,
                 clmtypedesc: this.formData.clmtypedesc,
                 transtatusid: this.formData.transtatusid,
                 clmviapayroll: this.formData.clmviapayroll,
                
                
            });
        },
    }, 

    mounted() { 
    //  this.loadCompanyListHandler();
      this.LoadClaimType();
      this.loadTranStatusListHandler();
     
    },
    
}
</script>
