<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="180"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.name')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              @input="updateValue"
              @change="changeStaffIDandCompanyHandler(formData.staffid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.staffid')"
              type="text"
              v-model="formData.staffcode"
              dense
              readonly
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.company')"
              type="text"
              ref="compnyid"
              v-model="formData.compnyname"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('claimreport.asofdate')"
              type="date"
              ref="asofdate"
              :rules="[rules.required]"
              v-model="formData.asofdate"
              @input="updateValue"
              @change="updateValue"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-btn color="primary" style="width: auto" @click="CheckBalance">
              {{$t('button.check')}}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n8">
            <v-toolbar flat>
              <v-toolbar-title>{{$t('claimreport.claimlimitandutilizationbalance')}} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="14">
            <v-data-table
              hide-default-footer
              :headers="dataHeader"
              :items="Items"
              :items-per-page="1000"
              multi-sort
            >
            </v-data-table>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
import jsPDF from "jspdf";
import "jspdf-autotable";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },
      formData: {
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyname,
        compnyname: this.parentObject.data.compnyname,
        periodend: this.parentObject.data.periodend,
        asofdate: this.parentObject.data.asofdate,
        userid: this.$store.getters.userId,
      },

      dataHeader: [
        { text: this.$t('claimreport.claimtype'), value: "claimtypedesc",  width: '130px' },
        { text: this.$t('setupclaim.parentclaim'), value: "parentclmtype",  width: '150px' },
        { text: this.$t('setupleavesetup.periodtype'), value: "prdtype",  width: '150px' },
        { text: this.$t('claimrecordlisting.claimtypecurrency'), value: "crcycode",  width: '100px' },
        { text: this.$t('claimreport.periodlimit'), value: "periodlimit",  width: '150px' },
        { text: this.$t('reportleavebalancebystaff.approved'), value: "approvedamt",  width: '150px' },
        { text: this.$t('reportleavebalancebystaff.rejected'), value: "rejectedamt",  width: '100px' },
        { text: this.$t('reportleavebalancebystaff.pending'), value: "pendingamt",  width: '100px' },
        { text: this.$t('claimreport.periodbalance'), value: "periodbalance",  width: '150px' },
        { text: this.$t('claimreport.maxtran'), value: "maxpertransaction", width: '150px' },
        { text: this.$t('claimreport.maxtranperiod'), value: "maxtransactionperpeirod",  width: '150px' },
        { text: this.$t('claimreport.periodlimitincreased'), value: "limitincrease",  width: '150px' },
        { text: this.$t('claimreport.periodlimitdecreased'), value: "limitdecrease",  width: '150px' },
      ],
      dataItems: [],
      Items: [],

      //arrays to store data objects from load list handlers
      staffdetails: [],
      staffDetailsList: [],
      companies: [],
      companiesitemsList: [],
      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        compnyname: this.formData.compnyname,
        asofdate: this.formData.asofdate,
        userid: this.formData.userid,
      });
    },

      loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
              url: "/company/all"
            })
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

    //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
              url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            if (vm.formData.staffid) {
              this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      this.updateValue();
    },

    CheckBalance() {
      const vm = this;
      vm.dataItems = [];
      vm.Items = [];
      if ((this.$refs.form.validate())){
      if (
        this.formData.staffid &&
        this.formData.asofdate &&
        this.formData.staffcode
      ) {
        vm.loading = true;
        var data = {
          asofdate: this.formData.asofdate,
          staffcode: this.formData.staffcode,
          staffid: this.formData.staffid,
          userid: this.$store.getters.userId,
        };
       // console.log(data);
        this.$store.dispatch('post', {
              url: "/claim/claimbalance",
              payload: data
          })
          .then((res) => {
            vm.loading = false;
            //req success --> push res into array
          //  console.log('res', res.data.rec);
            if (res.data.rec.length > 0) {
              vm.dataItems = res.data.rec;

               for (let i = 0; i < vm.dataItems.length; i++) {

                 if (vm.dataItems[i].periodend !== "") {

                    vm.Items.push(vm.dataItems[i])

                  }

                //  console.log('items', vm.Items)
               }

            
             // console.log('dataItems', vm.dataItems)
            } else {
              this.$dialog.alert("No Record Found.").then((dialog) => {
                console.log(dialog);
              });
            }
          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
      }
    },

    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var head = [
        { title: this.$t('claimreport.claimtype'), dataKey: "claimtypedesc" },
        { title: this.$t('setupclaim.parentclaim'), dataKey: "parentclmtype" },
        { title: this.$t('setupleavesetup.periodtype'), dataKey: "prdtype" },
        { title: this.$t('claimrecordlisting.claimtypecurrency'), dataKey: "crcycode" },
        { title: this.$t('claimreport.periodlimit'), dataKey: "periodlimit" },
        { title: this.$t('reportleavebalancebystaff.approved'), dataKey: "approvedamt" },
        { title: this.$t('reportleavebalancebystaff.rejected'), dataKey: "rejectedamt" },
        { title: this.$t('reportleavebalancebystaff.pending'), dataKey: "pendingamt" },
        { title: this.$t('claimreport.periodbalance'), dataKey: "periodbalance" },
        { title: this.$t('claimreport.maxtran'), dataKey: "maxpertransaction" },
        { title: this.$t('claimreport.maxtranperiod'), dataKey: "maxtransactionperpeirod" },
        { title: this.$t('claimreport.periodlimitincreased'), dataKey: "limitincrease" },
        { title: this.$t('claimreport.periodlimitdecreased'), dataKey: "limitdecrease" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          claimtypedesc: this.dataItems[i].claimtypedesc,
          parentclaimdesc: this.dataItems[i].parentclaimdesc,
          periodlimit: this.dataItems[i].periodlimit,
          currencydesc: this.dataItems[i].currencydesc,
          approvedamt: this.dataItems[i].approvedamt,
          rejectedamt: this.dataItems[i].rejectedamt,
          pendingamt: this.dataItems[i].pendingamt,
          periodbalance: this.dataItems[i].periodbalance,
          maxpertransaction: this.dataItems[i].maxpertransaction,
          maxtransactionperpeirod: this.dataItems[i].maxtransactionperpeirod,
          limitincrease: this.dataItems[i].limitincrease,
          limitdecrease: this.dataItems[i].limitdecrease,
        });
      }

      doc.text(
        `Staff Claim Limit and Utilization - ${this.formData.staffcode} as of ${this.formData.asofdate}`,
        10,
        10
      );

      doc.autoTable(head, body);
      doc.save("Staff Claim Limit and Utilization " + "-" + this.formData.staffcode + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        "Staff Claim Limit and Utilization " + "-" + this.formData.staffcode + ".csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [`${this.$t('claimreport.claimtype')}`]: item[i].claimtypedesc,
          [`${this.$t('setupclaim.parentclaim')}`]: item[i].parentclmtype,
          [`${this.$t('setupleavesetup.periodtype')}`]: item[i].prdtype,
          [`${this.$t('claimrecordlisting.claimtypecurrency')}`]: item[i].crcycode,
          [`${this.$t('claimreport.periodlimit')}`]: item[i].periodlimit,
          [`${this.$t('reportleavebalancebystaff.approved')}`]: item[i].approvedamt,
          [`${this.$t('reportleavebalancebystaff.rejected')}`]: item[i].rejectedamt,
          [`${this.$t('reportleavebalancebystaff.pending')}`]: item[i].pendingamt,
          [`${this.$t('claimreport.periodbalance')}`]: item[i].periodbalance,
          [`${this.$t('claimreport.maxtran')}`]: item[i].maxpertransaction,
          [`${this.$t('claimreport.maxtranperiod')}`]: item[i].maxtransactionperpeirod,
          [`${this.$t('claimreport.periodlimitincreased')}`]: item[i].limitincrease,
          [`${this.$t('claimreport.periodlimitdecreased')}`]: item[i].limitdecrease,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadCompaniesListHandler();
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>