import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import MenuIcon from "vue-material-design-icons/Menu.vue";
import i18n from "./i18n";
import VueJsDialog from "vuejs-dialog";
//import VueJsDialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js"

import VueSessionStorage from 'vue-sessionstorage'
Vue.use(VueSessionStorage)


import Routes from './router';
import { store } from './store';

// Global custom components
import FormButtons from './components/common/FormButtons.vue';
Vue.component('app-formbuttons', FormButtons);

Vue.use(VueRouter);
Vue.use(VueJsDialog);
Vue.component('menu-icon', MenuIcon);

import "vue-material-design-icons/styles.css";
import './assets/css/main.css';
import './assets/css/menu.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue2-timepicker/dist/VueTimepicker.css'

Vue.config.productionTip = false

const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  history: true,
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;

  if (!i18n.availableLocales.includes(lang)) return next("en/home/t2hr");

  if (i18n.locale !== lang) {
    i18n.locale = lang;
    changeServerTranslation(lang);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (typeof (store._vm.$session.get("access_token")) == 'undefined') {
      next({
        name: "login"
      });
    } else {
      console.log(to)
      let url = to.fullPath;
      let nameUrl = url.match(`/${lang}/(.*?)/`)

      if(nameUrl) { 
        console.log('Name URL', nameUrl[1])
      }

      if (to.name.includes("selfservice") || to.name == 'systemlogo') {
        next();
      } else if (to.name != 'dashboard' && !hasPermission(to.meta.funcname, "canaccess")) {
       next({
          name: "dashboard"
        });
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    console.log(to)
    let url = to.fullPath;
    let nameUrl = url.match(`/${lang}/(.*?)/`)
    if(nameUrl) { console.log('Name URL', nameUrl[1]) }
    next();
  } else {
    next();
  }
});

function changeServerTranslation(language){  

  store.dispatch('post', { url: `/changeserverlocale/all`, payload: {lang: language} })
  .then(console.log('Server Successfully Change Locale'))

}

function hasPermission(funcname, accesstype) {
  var message = "Permission is not granted. Unable to proceed."
  var dialogoption = { customClass: 'PermissionDialog' };

  if (funcname == "") {
    return true;
  }

  var sessions = "";
  if (typeof (store._vm.$session.get("access_token")) !== 'undefined') {
    sessions = JSON.parse(store._vm.$session.get("access_token"))
    // return true;
  }
  if (sessions.funcaccess.length > 0) {
    const result = sessions.funcaccess.filter(e => e.funcname == funcname);
    if (result.length > 0) {
      if (result[0][accesstype] == "Y") {
        return true;
      } else {
        Vue.dialog.alert(message, dialogoption).then(function () {
          return false;
        })
      }
    } else {
      Vue.dialog.alert(message, dialogoption).then(function () {
        return false;
      })
    }
  } else {
    Vue.dialog.alert(message, dialogoption).then(function () {
      return false;
    })
  }
}

Vue.mixin({
  methods: {
    CheckPermission: function (funcname, accesstype) {
      return hasPermission(funcname, accesstype);
    },
  },
})

new Vue({
  router: router,
  store: store,
  beforeCreate() { this.$store.commit('initialiseStore'); },
  // created() {
  //   window.addEventListener('beforeunload', this.leaving);
  // },
  // methods: {
  //   leaving() {
  //     this.$store.commit('ClearStore');
  //   },
  // },
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
