<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->

                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollothers.refnocode')"
                            type="text"
                            :items="refnumList"
                            v-model="formData.refcodeid"
                            @change="changeRefcodeHandler(formData.refcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="3" sm="3" class="pb-n5" >
                        <v-text-field
                            outlined
                            :label="this.$t('recfamdetails.refno')"
                            type="text"
                            v-model="formData.refnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly

                        ></v-text-field>
                    </v-col>
                </v-row>

                 <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('reportsparam.year')"
                                :rules="[rules.required]"
                                v-model="formData.recyear"
                                dense
                                :items="yearList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('reportsparam.month')"
                                :rules="[rules.required]"
                                v-model="formData.recmonth"
                                dense
                                :items="monthList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5 mt-n7">
                            <v-btn icon color="orange">
                                <v-icon @click="loadPayoutList()">mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2">{{$t('statutoryexportcommonused.payouts')}}</v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.dataItems"
                :options="tableOpt"
                hide-default-footer
                :single-select="singleSelect"
                item-key="payoutcode"
                show-select
                @input="changePayoutHandler()"
                v-model="selected"
              >
              </v-data-table>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="6" class="pb-n5">
              <v-autocomplete
                outlined
                v-model="formData.expformatid"
                :label=" this.$t('setupreport_exportmapping.exportformat')"
                type="text"
                :items="this.exportFmtList"
                :rules="[rules.required]"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="nextHandler">
              {{$t('button.next')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('payrollothers.miscellaneousexportprocess'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        {
          text: this.$t('payrollenquiryreport.payoutdesription'),
          value: "payoutdesc",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('payrollprocessrun.payoutstatus'),
          value: "pystatusdesc",
          width: "300px",
          sortable: true,
        },
      ],
      formData: {
        eisexprecid: null,
       // refnum: null,
        refcodeid: null,
        refcode: null, 
        refnum: null, 
        recyear: null,
        recmonth: null,
        selectedPayouts: null,
        myntlycode: null,
        eissubrecpy: [],
        eissubrecref: [],
        expformatid: null,
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      eisExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      eisSubRefRecList: [],

      monthList: [],
      yearList: [],
      months: [],
      years: [],
      selected: [],

      payoutList: [],

      refnums: [],
      refnumList: [],

      singleSelect: false,

      notFinalize: false,
      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {

     
        loadRefnum() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/refnumexport/all'
              })
            .then (res => {
                vm.refnums = res.data.rec;
                
                for (let i = 0; i < vm.refnums.length; i++) {
                    vm.refnumList.push(
                        {   
                            refnum: vm.refnums[i].refnum,
                            text: vm.refnums[i].refcode,
                            value: vm.refnums[i].id
                        }
                    )
                }

            });
        },

        changeRefcodeHandler(id) {
            let item = this.refnums.find(item => item.id == id)
            
            this.formData.refcode = item.refcode;
            this.formData.refnum = item.refnum;
        },

         changeYearHandler(id) {
            this.years.find(item => item.id === id);
            
        },

        changeMonthHandler(id) {
            this.months.find(item => item.id === id);
            
        },

        changePayoutHandler() {
            this.formData.selectedPayouts = this.selected

        },

        loadPayout() {
            const vm = this;

             vm.loading = true;

            this.$store.dispatch('get', {
              url: '/payoutctrl/all'
              })
                .then(res => {
                    vm.payoutList = res.data.rec;
                    vm.loading = false

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )        
                        
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )
                    }
                    vm.loadPayoutEdit();

                    if (vm.formData.recyear) {
                        vm.changeYearHandler(vm.formData.recyear);
                    }

                    if (vm.formData.recmonth) {
                        vm.changeMonthHandler(vm.formData.recmonth);
                    }

                    
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         loadPayoutList() {
         
            let payoutYear = [];
            if (!this.formData.recyear && !this.formData.recmonth) {
                this.dataItems = this.payoutList
            } else if (this.formData.recyear) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    if (this.payoutList[i].pyyear === this.formData.recyear) {
                        payoutYear.push(this.payoutList[i])
                    }
                }
                if (this.formData.recmonth) {
                    this.dataItems = []
                    for (let j = 0; j < payoutYear.length; j++) {
                        if (payoutYear[j].pymonth === this.formData.recmonth) {
                            this.dataItems.push(payoutYear[j])
                        }
                    }
                }
            }
        },

        loadPayoutEdit() {
            let getItem = []
            if (this.isEdit) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    for (let j = 0; j < this.formData.selectedPayouts.length; j++) {
                        if (this.payoutList[i].id === this.formData.selectedPayouts[j].poid) {
                            getItem[j] = this.payoutList[i]
                            getItem[j].exId = this.formData.selectedPayouts[j].poid
                        }
                    }
                }
                this.loadPayoutList()
                this.selected = getItem
                this.formData.selectedPayouts = []

            }
        },

    getExportFormattListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/exportformat/all"
        })
        .then(function (res) {
          vm.exportformatItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            if ((res.data.rec[i].exptypeid >= 6)) {
              vm.exportFmtList.push({
                text: res.data.rec[i].expdesc,
                value: res.data.rec[i].id,
              });
            }
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    nextHandler() {
      let item = this.formData;
      let item2 = this.selected;
      let id = this.formData.expformatid;

      for (let i = 0; i < item2.length; i++){
          if (item2[i].pystatuscode != '99' ) {
            this.notFinalize = true;
          } else {
            this.notFinalize = false;
          }
      } 

      if (this.notFinalize == true){

          this.isErr = true;
          this.errMsg =
          this.$t('vdatatable.payoutnotfinalized');
          
       } else {
        if (this.$refs.form.validate()) {
          this.$router.push({
            name: "monthlymiscellaneousexportparam",
            params: { id, item, item2 },
          });
  
        } else {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } 
       }
    },
  },

  mounted() {
    this.loadRefnum();
    this.loadPayout();
    this.getExportFormattListHandler();
  },
};
</script>