<template>
    <div>
       
        <monthlyzakatcimb-form v-if="this.id == 24"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>

import MonthlyZAKATCIMBForm from '../../../components/payroll/monthlymiscellaneousexport/MonthlyZAKATCIMB';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
   
        'monthlyzakatcimb-form': MonthlyZAKATCIMBForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('setupreport_exportmapping.monthly_export'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },

}

</script>