<template>
    <v-container>
        <v-form>
            <v-row>
                <h2 class="pb-5">
                    {{ this.parentObject.pageTitle
                    }}<span class="page_title_value">{{
                        this.parentObject.pageTitleValue
                    }}</span>
                </h2>
            </v-row>

            <v-container
                id="scroll-target"
                class="custom-form overflow-y-auto px-5"
            >
                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5">
                        <v-text-field
                            outlined
                            :label="this.$t('payrollprocessrun.payout')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="formData.payoutcode"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.description')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="formData.payoutdesc"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('payrollprocessrun.payoutstartdate')"
                            type="date"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            @change="getMthYrHandler"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('payrollprocessrun.payoutenddate')"
                            type="date"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('reportsparam.year')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="formData.pyyear"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('reportsparam.month')"
                            type="number"
                            :rules="[rules.required]"
                            v-model="formData.pymonth"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            v-model="formData.remark"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayrolltype.payrolltype')"
                            type="text"
                            :items="payrollTypeList"
                            :rules="[rules.required]"
                            v-model="formData.payrolltypeid"
                            @change="changePayrollTypeHandler"
                            @input="updateValue"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="payrollTypeCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.payrollgroup')"
                            type="text"
                            :items="payrollGroupList"
                            :rules="[rules.required]"
                            v-model="formData.grouppbid"
                            @input="updateValue"
                            @change="changePayrollGroupHandler"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="payrollGroupCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.currency')"
                            type="text"
                            :items="currencyList"
                            :rules="[rules.required]"
                            v-model="formData.pycrcyid"
                            @input="updateValue"
                            @change="changeCurrencyHandler"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="currencyCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.exchangerate')"
                            type="text"
                            :items="exchangeRateList"
                            :rules="[rules.required]"
                            v-model="formData.exchrateid"
                            @input="updateValue"
                            @change="changeExchrateHandler"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="exchageRateCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.payoutfrequency')"
                            type="text"
                            :items="payoutFreqList"
                            :rules="[rules.required]"
                            v-model="formData.salfreqid"
                            @input="updateValue"
                            @change="changePayoutFreqHandler"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="payoutFreqCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.payeelist')"
                            type="text"
                            :items="payeeListList"
                            :rules="[rules.required]"
                            v-model="formData.payeelistcode"
                            @input="updateValue"
                            @change="changePayeeListHandler"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="payeeListCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('payrollprocessrun.payoutstatus')"
                            readonly
                            type="text"
                            v-model="pystatusdesc"
                            dense
                            background-color="#ffeab2"
                        ></v-text-field>
                        <input
                            type="text"
                            hidden
                            v-model="formData.pystatuscode"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n12">
                        <v-checkbox
                            outlined
                            :label="this.$t('payrollprocessrun.allowinput')"
                            v-model="formData.allowinput"
                            @input="updateValue"
                            dense
                            @change="updateValue"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n12">
                        <v-checkbox
                            outlined
                            :label="this.$t('payrollprocessrun.selfservicerelease')"
                            v-model="formData.ssrel"
                            @input="updateValue"
                            dense
                            @change="updateValue"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>

            <app-formbuttons
                v-bind:EditBool="this.parentObject.isEdit"
                v-on:NewPressed="newHandler"
                v-on:SavePressed="saveHandler"
                v-on:DeletePressed="deleteHandler"
                v-on:CancelPressed="cancelHandler"
            />
        </v-form>
    </v-container>
</template>

<script>
import moment from "moment";

export default {
    props: {
        parentObject: Object,
    },
    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
            },
            currencyCode: null,
            formData: this.parentObject.data,
            payrollType: [],
            payrollTypeList: [],
            payrollGroup: [],
            payrollGroupList: [],
            currencies: [],
            currencyList: [],
            exchangeRate: [],
            exchangeRateList: [],
            payoutFreq: [],
            payoutFreqList: [],
            payeeList: [],
            payeeListList: [],
            payrollTypeCode: "",
            payrollGroupCode: "",
            exchageRateCode: "",
            payoutFreqCode: "",
            payeeListCode: "",
            payoutStatus: [],
            pystatusdesc: "",
            errMsg: "",
            isErr: false,
            search: this.parentObject.searchnew
        };
    },
    methods: {
        updateValue() {
            this.$emit("input", {
                id: this.formData.id,
                payoutcode: this.formData.payoutcode,
                payoutdesc: this.formData.payoutdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                pyyear: this.formData.pyyear,
                pymonth: this.formData.pymonth,
                remark: this.formData.remark,
                payrolltypeid: this.formData.payrolltypeid,
                grouppbid: this.formData.grouppbid,
                pycrcyid: this.formData.pycrcyid,
                exchrateid: this.formData.exchrateid,
                salfreqid: this.formData.salfreqid,
                payeelistcode: this.formData.payeelistcode,
                pystatuscode: this.formData.pystatuscode,
                allowinput: this.formData.allowinput,
                ssrel: this.formData.ssrel,
                userid: this.formData.userid,
            });
        },

        getMthYrHandler($event) {
            let monthVal = moment($event).month() + 1;
            let yearVal = moment($event).year();

            this.formData.pyyear = yearVal;
            this.formData.pymonth = monthVal;

            this.updateValue();
        },

        loadPayrollType() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payrolltype/codes"})
                .then((res) => {
                    vm.payrollType = res.data.rec;

                    vm.payrollType.forEach((element) => {
                        vm.payrollTypeList.push({
                            text: element.payrolltypedesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.payrolltypeid)
                        vm.changePayrollTypeHandler(vm.formData.payrolltypeid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayrollTypeHandler($event) {
            let item = this.payrollType.find((item) => item.id === $event);

            this.payrollTypeCode = item.payrolltypecode;

            this.updateValue();
        },

        loadPayrollGroup() {
            const vm = this;

            this.$store.dispatch('get', {url: "/groupbtype/3"})
                .then((res) => {
                    vm.payrollGroup = res.data.rec;

                    vm.payrollGroup.forEach((element) => {
                        vm.payrollGroupList.push({
                            text: element.valuedesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.grouppbid)
                        vm.changePayrollGroupHandler(vm.formData.grouppbid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayrollGroupHandler($event) {
            let item = this.payrollGroup.find((item) => item.id == $event);

            this.payrollGroupCode = item.gtypedesc;

            this.updateValue();
        },

        loadCurrency() {
            const vm = this;

            this.$store.dispatch('get', {url: "/currency/all"})
                .then((res) => {
                    vm.currencies = res.data.rec;
                    vm.currencies.forEach((element) => {
                        vm.currencyList.push({
                            text: element.crcydesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.pycrcyid)
                        vm.changeCurrencyHandler(vm.formData.pycrcyid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {url: "/exchrate/all"})
                .then((res) => {
                    vm.exchangeRate = res.data.rec;

                    vm.exchangeRate.forEach((element) => {
                        vm.exchangeRateList.push({
                            text: element.exchratedesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.exchrateid)
                        vm.changeExchrateHandler(vm.formData.exchrateid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeCurrencyHandler($event) {
            let item = this.currencies.find((item) => item.id == $event);

            this.currencyCode = item.crcycode;

            this.updateValue();
        },

        changeExchrateHandler($event) {
            let item = this.exchangeRate.find((item) => item.id == $event);

            this.exchageRateCode = item.exchratecode;

            this.updateValue();
        },

        loadPayoutFreq() {
            const vm = this;

            this.$store.dispatch('get', {url: "/salfreq/all"})
                .then((res) => {
                    vm.payoutFreq = res.data.rec;

                    vm.payoutFreq.forEach((element) => {
                        vm.payoutFreqList.push({
                            text: element.basicfreqdesc,
                            value: element.id,
                        });
                    });

                    if (vm.formData.salfreqid)
                        vm.changePayoutFreqHandler(vm.formData.salfreqid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayoutFreqHandler($event) {
            let item = this.payoutFreq.find((item) => item.id == $event);

            this.payoutFreqCode = item.basicfreqcode;

            this.updateValue();
        },

        loadPayeeList() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payeelist/all"})
                .then((res) => {
                    vm.payeeList = res.data.rec;

                    vm.payeeList.forEach((element) => {
                        vm.payeeListList.push({
                            text: element.payeelistdesc,
                            value: element.payeelistcode,
                        });
                    });

                    if (vm.formData.payeelistcode)
                        vm.changePayeeListHandler(vm.formData.payeelistcode);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayeeListHandler($event) {
            let item = this.payeeList.find(
                (item) => item.payeelistcode == $event
            );

            this.payeeListCode = item.payeelistcode;

            this.updateValue();
        },

        loadPayrollStatus() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/pystatus"})
                .then((res) => {
                    vm.payoutStatus = res.data.rec;

                    if (vm.formData.pystatuscode) {
                        this.changePayrollStatusHandler(vm.formData.pystatuscode);
                    } else {
                        this.changePayrollStatusHandler(10);
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayrollStatusHandler(val) {
            let item = this.payoutStatus.find(
                (item) => item.pystatuscode == val
            );

            this.pystatusdesc = item.pystatusdesc;
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "payoutcontrollist" , params: { search: this.search}});
        },
    },

    mounted() {
        this.loadPayrollType();
        this.loadPayrollGroup();
        this.loadCurrency();
        this.loadExchangeRate();
        this.loadPayoutFreq();
        this.loadPayeeList();
        this.loadPayrollStatus();
    },
};
</script>

<style scoped>
</style>