<template>
  <div>
    <v-container>
      <form enctype="multipart/form-data">
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTtileValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="10" sm="10" class="mt-n4 pt-6">
              <v-row>
                <v-col cols="8" sm="8" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.grouptype')"
                    type="text"
                    :rules="[rules.required]"
                    v-model="formData.gtypeid"
                    :items="gtypeList"
                    @input="updateValue"
                    dense
                    @change="changeGtypeHandler(formData.gtypeid)"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                  <v-checkbox
                    ref="isDisable"
                    v-model="formData.isDisable"
                    :label="this.$t('columns.disable')"
                    @change="updateValue"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col col="10" sm="10" class="pb4-n4 mt-n8">
                  <v-data-table
                    :headers="tabHeader"
                    :items="this.formData.valvalues"
                    class="elavation-1"
                    fixed-header
                    :sort-by="['code']"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>{{$t('setupgrp.grpvalidval')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="subDialog"
                          max-width="500px"
                          persistent
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              style="background-color: #2980b9"
                              text
                              v-on="on"
                              >+</v-btn
                            >
                          </template>

                          <v-card>
                            <app-genericlist
                              v-bind:parentData="{
                                tabItems: valueItems,
                                formTitle: `${$t('setupgrp.grpvalidval')}`,
                                formId: 'valvalues',
                              }"
                              v-on:addValue="addItemHandler"
                            />

                            <v-card-text v-if="isErr">
                              <span style="font-weight: bold; color: red">{{
                                errMsg
                              }}</span>
                            </v-card-text>

                            <v-card-actions class="pb-5">
                              <v-spacer></v-spacer>
                              <v-btn
                                color="warning"
                                @click="closeHandler"
                                style="margin-right: 20px; width: 100px"
                                >{{$t('button.close')}}</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon
                        small
                        class="ml-3 mr-2"
                        @click="deleteItemHandler(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </form>
    </v-container>
  </div>
</template>

<script>
import GenericList from "../../generic/GridList";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-genericlist": GenericList,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      formData: {
        gtypeid: this.parentObject.data.gtypeid,
        isDisable: this.parentObject.data.isDisable,
        valvalues: this.parentObject.data.valvalues,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },
      tabHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "5px" },
        { text: this.$t('columns.valuecode'), value: "code", width: "200px", sortable: false },
        { text: this.$t('columns.description'), value: "desc", width: "200px", sortable: false },
      ],
      selected: [],

      gtypeItems: [],
      gtypeList: [],
      gtypes: [],

      valueItems: [],
      valueList: [],
      valueListCopy: [],

      subDialog: false,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    loadGtypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/gtype/all"})
        .then((res) => {
          vm.cities = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gtypeList.push({
              text: res.data.rec[i].gtypedesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.gtype) {
            vm.changeGtypeHandler(vm.formData.gtypeid);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeGtypeHandler(id) {
      this.gtypes.find((item) => item.id === id);
    },

    loadAffectItemsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/valvalue/all"})
        .then((res) => {
          //vm.valueItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.valueList.push({
              text: res.data.rec[i].valuecode,
              value: res.data.rec[i].id,
            });

            vm.valueItems.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].valuecode,
              desc: res.data.rec[i].valuedesc,
            });
          }

          vm.valueListCopy = vm.valueList;

          if (vm.formData.valvalueid) {
            vm.changeAffectVarHandler(vm.formData.valvalueid);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeAffectVarHandler(id) {
      this.affectVars.find((item) => item.id === id);
    },

    addItemHandler($event) {
      for (let i = 0; i < this.formData.valvalues.length; i++) {
        if (this.formData.valvalues[i].code == $event.code) {
          this.errMsg = "Value already added. Duplicates not allowed";
          this.isErr = true;

          return;
        }
      }

      this.formData.valvalues.push({
        id: $event.id,
        code: $event.code,
        desc: $event.desc,
        action: 1,
      });

      this.updateValue();
      this.closeHandler();
    },

    deleteItemHandler(item) {
      let itemIndex = this.formData.valvalues.indexOf(item);

      this.formData.valvalues.splice(itemIndex, 1);
      
      this.updateValue();

      // this.valueList.push({
      //   text: val.code,
      //   value: val.id,
      // });
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "grouplist", params: { search: this.search} });
    },

    closeHandler() {
      this.subDialog = false;
      this.isErr = !this.isErr;
      this.errMsg = "";
    },

    updateValue() {
      this.$emit("input", {
        gtypeid: this.formData.gtypeid,
        isDisable: this.formData.isDisable,
        valvalues: this.formData.valvalues,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },
  },

  mounted() {
    this.loadAffectItemsListHandler();
    this.loadGtypeListHandler();
  },
};
</script>