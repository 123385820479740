<template>
  <div>
      <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('allepf.fileprocessingdate')"
                type="date"
                v-model="formData.fpdate"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getAmbankExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
     Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      //pageTitle: 'MBB-EMAS-01',

      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        empportionrate: 0,
        memberportionrate: 0,
        sdba: "",
        fpdate: moment().format("YYYY-MM-DD"),
      },
      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: null,

      companyrecnum: "",
      paymentmode: "015",
      bankcode: "011",
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",
      filler: "",
      totalAmtEmployer: null,
      totalAmtMember: null,
      totalRefno: null,

      loading: false,

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],
      epfdata: [],

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    // getSalaryStatutaryOtherListHandler() {
    //   const vm = this;
    //   axios
    //     .get(
    //       // this.$store.getters.apiURL + '/salstatutoryother/all'
    //       `${this.$store.getters.apiURL}/salstatutoryother/all/${this.$store.getters.userId}`
    //     )
    //     .then(function (res) {
    //       vm.salstatutoryother = res.data.rec;
    //     })
    //     .catch(function (err) {
    //       vm.errMsg = err;
    //     });
    // },

    // getPersonStatutaryInfoListHandler() {
    //   const vm = this;

    //   axios
    //     .get(`${this.$store.getters.apiURL}/personstatutoryinfo/all/${this.$store.getters.userId}`)
    //     .then(function (res) {
    //       vm.psnstatutoryinfo = res.data.rec;
    //     })
    //     .catch(function (err) {
    //       vm.errMsg = err;
    //     });
    // },

    // getPsnPayrollListHandler() {
    //   const vm = this;

    //   axios
    //     .get(
    //       `${this.$store.getters.apiURL}/psnpayroll/all/${this.$store.getters.userId}`
    //     )
    //     .then(function (res) {
    //       vm.psnpayroll = res.data.rec;
    //       vm.psnpayroll = vm.psnpayroll.filter(x => x.pystatcode == 'ACTIVE')
    //     })
    //     .catch(function (err) {
    //       vm.errMsg = err;
    //     });
    // },

      getAmbankExportListHandler() {
      this.loading = true;
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/ambankepfexport/all/${this.$store.getters.userId}`
        + "/" +  this.data.epfexprecid
       + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.epfdata = res.data.rec;

         vm.exportHandler(); 

      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    objectToTxt(header, data, subheader, footer) {
      const csvRows = [];

      const headers = Object.keys(data[0]);
      const getHeader = header;
      csvRows.push(getHeader);
      csvRows.push(subheader);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(footer);

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

     async getData() {
      const vm = this
      let data = []
      let icnoholder = ''
      let holdAmtEmployer = ''
      let holdAmtMember = ''
      let getAmtRefno = ''
      let rec = 0

     // let recid = "02";
      
      let newepfdetail = []
      let holdpayEmployer = []
      let holdpayMember = []
      let holdpayRefno = []
      let dataholder = vm.epfdata
      let dataholderval = dataholder;//.filter(el => el.stfamount)
      let epfdetail = this.filter(dataholder);

      let getDate = moment(this.formData.fpdate).format("YYYYMMDD");

      this.processedCount = dataholderval.length;
      this.successCount = epfdetail.length;
      this.errCount = dataholderval.length - epfdetail.length;
     // let yearholder = this.data.year
      let monthholder = this.data.month + 1
      if (monthholder > 12) {
        monthholder = 1
      //  yearholder++
      }
    //  let getyear = yearholder.toString()
      // let getdate = this.padRJ(monthholder, 2) + getyear
      // let currentdate = moment().format('YYYYMMDD')
      // let currenttime = moment().format('hhmmss')


      epfdetail.forEach(function (o) {
        var existing = newepfdetail.filter(function (i) {
          return i.staffid === o.staffid;
        })[0];
        if (!existing || existing == undefined) {
          newepfdetail.push(o);
        } else {
          existing.coamount += o.coamount;
          existing.stfamount += o.stfamount;
          existing.stfepfwage += o.stfepfwage;
        }
      });

      newepfdetail.forEach(element => {
        if (element.icno != null) icnoholder = element.icno.split("-").join("")
        
        let coamountholder = element.coamount.toFixed(2);
        let stfamountholder = element.stfamount.toFixed(2);
        let stfepfwageholder = element.stfepfwage.toFixed(2);
        let psnnameHolder = element.psnname.substring(0,40)

        coamountholder = coamountholder.split(".").join("");
        stfamountholder = stfamountholder.split(".").join("");
        stfepfwageholder = stfepfwageholder.split(".").join("");

        let refnoh = ''
        if (element.refno) refnoh = element.refno

        // if (parseInt(stfamountholder) != 0) {
          data.push({
            "Record ID": '02',
            "Member EPF Number": this.pad(refnoh, 19),
            "Member IC Number": this.padSpace(icnoholder, 15),
            "Member Name": this.padSpace(psnnameHolder, 40),
            "Member Identifying Keys": this.padSpace(element.staffcode, 20),
            "Employer Contribution": this.pad(coamountholder, 9),
            "Member Contribution": this.pad(stfamountholder, 9),
            "Member Wage": this.pad(stfepfwageholder, 15),
          });
        // }


        //console.log('psnname',this.padLJ(element.psnname, 0))

        holdpayEmployer.push(element.coamount);
        holdpayMember.push(element.stfamount);
        if (element.refno) holdpayRefno.push(parseInt(element.refno));
        rec++;
      });

      if (holdpayEmployer.length > 0) {
        let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
        holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
      }

      if (holdpayMember.length > 0) {
        let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtMember = totalAmtMemHolder.toFixed(2);
        holdAmtMember = this.totalAmtMember.split(".").join("");
      }

       let getHeader =
        "00" +
        this.padSpace("EPF MONTHLY FORM A", 18) +
        this.pad(getDate, 8) +
        this.pad("1", 5) +
        this.pad(holdAmtEmployer, 15) +
        this.pad(holdAmtMember, 15) +
        this.pad(this.data.refnum, 21) +
        this.padSpace(this.filler, 45);

      let conMonth =
        this.pad(this.data.epfsubrecref[0].repmonth, 2) +
        this.pad(this.data.epfsubrecref[0].repyear, 4);
      
      let subheader =
        "01" +
        this.pad(this.data.refnum, 19) +
        this.pad(conMonth, 6) +
        this.padSpace("DSK", 3) +
        this.pad("00001", 5) +
        this.pad(this.formData.empportionrate, 4) +
        this.pad(this.formData.memberportionrate, 4) +
        this.padSpace(this.formData.sdba, 7) +
        this.padSpace(this.filler, 79);

      if (holdpayRefno.length > 0) {
        getAmtRefno = holdpayRefno.reduce(function (a, b) {
            return a + b;
        }, 0);
      }

      // let trailer = 
      //   '99' +
      //   this.padRJ(rec, 7) +
      //   this.padRJ(holdAmtEmployer, 15) +
      //   this.padRJ(holdAmtMember, 15) +
      //   this.padRJ(getAmtRefno, 21)

       let footer =
        "99" +
        this.pad(rec, 7) +
        this.pad(holdAmtEmployer, 15) +
        this.pad(holdAmtMember, 15) +
        this.pad(getAmtRefno, 21) +
        this.padSpace(this.filler, 69);

      this.titleData = this.pad(vm.data.month, 2) + "_" + vm.data.year + "_EPF";

    if (data.length > 0) {
        const csvData = this.objectToTxt(getHeader, data, subheader, footer);
        this.download(csvData, this.titleData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.coamount != 0 || e.stfamount != 0 && e.stfepfwage != 0 
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    // async generateTxt() {
    //   const vm = this;
    //   let data = [];
    //   let dataHolder = [];
    //   let holdsalstatothall = [];
    //   let holdsalstatothsel = [];
    //   let holdsalstatwpay = [];
    //   let holdsalstatpaystat = [];
    //   let getAmtEmployer;
    //   let getAmtMember;
    //   let getAmtRefno;
    //   let holdAmtEmployer;
    //   let holdAmtMember;
    //   let holdpayEmployer = [];
    //   let holdpayMember = [];
    //   let holdpayRefno = [];
    //   let rec = 0;

    //   let dateHolder =
    //     this.data.year +
    //     "-" +
    //     this.pad(this.data.month, 2) +
    //     "-" +
    //     this.daysInMonth(this.data.month, this.data.year);
    //   let date = moment(dateHolder).format("YYYY-MM-DD");


    //   for (let z = 0; z < this.data.epfsubrecpy.length; z++) {
    //     for (let i = 0; i < vm.salstatutoryother.length; i++) {
    //       for (let j = 0; j < vm.psnstatutoryinfo.length; j++) {
    //         if (vm.salstatutoryother[i].staffid == vm.psnstatutoryinfo[j].staffid &&
    //             vm.psnstatutoryinfo[j].vendorcatid == 2 &&
    //             vm.salstatutoryother[i].poid == this.data.epfsubrecpy[z].poid) {

    //             if (holdsalstatothall.indexOf(vm.salstatutoryother[i].psnname) === -1) holdsalstatothall.push(vm.salstatutoryother[i])

    //             if (vm.psnstatutoryinfo[j].refnum == this.data.refnum &&
    //               moment(vm.psnstatutoryinfo[j].startdate).format("YYYY-MM-DD") <= date &&
    //               date <= moment(vm.psnstatutoryinfo[j].enddate).format("YYYY-MM-DD")) {
                
    //               holdsalstatothsel.push(vm.salstatutoryother[i]);
                  
    //               let psnpayrollholder = [...new Map(vm.psnpayroll.map((item) => [item["staffid"], item])).values()];

    //               for (let l = 0; l < psnpayrollholder.length; l++) {
    //                 if (psnpayrollholder[l].pystatcode == 'ACTIVE' && 
    //                     vm.salstatutoryother[i].staffcode == psnpayrollholder[l].staffcode) {
    //                     holdsalstatpaystat.push(vm.salstatutoryother[i]);
    //                     let empcont =
    //                       this.salstatutoryother[i].coepf +
    //                       this.salstatutoryother[i].coepfve;
    //                     let membercont =
    //                       this.salstatutoryother[i].stfepf +
    //                       this.salstatutoryother[i].stfepfve;
    //                     let epfwage = this.salstatutoryother[i].stfepfwage;

    //                     if (empcont != 0 || membercont != 0 || epfwage != 0) {
    //                       holdsalstatwpay.push(vm.salstatutoryother[i]);
    //                       let empcontholder = empcont.toFixed(2);
    //                       let membercontholder = membercont.toFixed(2);
    //                       let epfwageholder = epfwage.toFixed(2);

    //                       let refno = ''
    //                       if (this.psnstatutoryinfo[j].refno) {
    //                         refno = this.psnstatutoryinfo[j].refno
    //                           .split("SG")
    //                           .join("");
    //                         refno = refno.split("OG").join("");
    //                         refno = parseInt(refno);
    //                       }

    //                       let staffname = ''
    //                       if (this.psnstatutoryinfo[j].staffname.length > 40) {
    //                         staffname = this.psnstatutoryinfo[j].staffname.slice(0,10)
    //                       } else {
    //                         staffname = this.psnstatutoryinfo[j].staffname
    //                       }

    //                       let icnoholder = ''
    //                       if (this.psnstatutoryinfo[j].icno != null) icnoholder = this.psnstatutoryinfo[j].icno.split("-").join("")
                          
    //                       dataHolder.push({
    //                         recid: "02",
    //                         refno: refno,
    //                         icno: icnoholder,
    //                         staffname: staffname,
    //                         staffcode: this.psnstatutoryinfo[j].staffcode,
    //                         empcontholder: parseFloat(empcontholder),
    //                         membercontholder: parseFloat(membercontholder),
    //                         epfwageholder: parseFloat(epfwageholder),
    //                       });
    //                     }
    //                   }
    //                 }
    //             }
    //         }
    //       }
    //     }
    //   }

    //   var allSum = [];
    //   var selSum = [];
    //   var sum = [];
    //   var paySum = [];
    //   var statSum = [];

    //   holdsalstatpaystat.forEach(function (o) {
    //     var existing = statSum.filter(function (i) {
    //       return i.staffid === o.staffid;
    //     })[0];
    //     if (!existing || existing == undefined) {
    //       statSum.push(o);
    //     }
    //   });

    //   holdsalstatwpay.forEach(function (o) {
    //     var existing = paySum.filter(function (i) {
    //       return i.staffid === o.staffid;
    //     })[0];
    //     if (!existing || existing == undefined) {
    //       paySum.push(o);
    //     }
    //   });

    //   holdsalstatothsel.forEach(function (o) {
    //     var existing = selSum.filter(function (i) {
    //       return i.staffid === o.staffid;
    //     })[0];
    //     if (!existing || existing == undefined) {
    //       selSum.push(o);
    //     }
    //   });

    //   holdsalstatothall.forEach(function (o) {
    //     var existing = allSum.filter(function (i) {
    //       return i.staffid === o.staffid;
    //     })[0];
    //     if (!existing || existing == undefined) {
    //       allSum.push(o);
    //     }
    //   });
      
    //   dataHolder.forEach(function (o) {
    //     var existing = sum.filter(function (i) {
    //       return i.icno === o.icno;
    //     })[0];
    //     if (!existing || existing == undefined) {
    //       sum.push(o);
    //     } else {
    //       existing.empcontholder += o.empcontholder;
    //       existing.epfwageholder += o.epfwageholder;
    //       existing.membercontholder += o.membercontholder;
    //     }
    //   });

    //   let empcontget;
    //   let membercontget;
    //   let epfwageholderget;
    //   let icnoget;

    //   for (let i = 0; i < sum.length; i++) {
    //     empcontget = sum[i].empcontholder.toFixed(2);
    //     empcontget = empcontget.split(".").join("");

    //     membercontget = sum[i].membercontholder.toFixed(2);
    //     membercontget = membercontget.split(".").join("");

    //     epfwageholderget = sum[i].epfwageholder.toFixed(2);
    //     epfwageholderget = epfwageholderget.split(".").join("");
    //     sum[i].icno != null ? icnoget = sum[i].icno.split("-").join("") : icnoget = ' '

    //     data.push({
    //       "Record ID": sum[i].recid,
    //       "Member EPF No.": this.pad(sum[i].refno, 19),
    //       "Member IC No": this.padSpace(icnoget, 15),
    //       "Member Name": this.padSpace(sum[i].staffname, 40),
    //       "Member Identifying Keys": this.padSpace(sum[i].staffcode, 20),
    //       "Employer Contribution": this.pad(empcontget, 9),
    //       "Member Contribution": this.pad(membercontget, 9),
    //       "Member Wage": this.pad(epfwageholderget, 15),
    //     });

    //     holdpayEmployer.push(sum[i].empcontholder);
    //     holdpayMember.push(sum[i].membercontholder);
    //     holdpayRefno.push(sum[i].refno);
    //     rec++;
    //   }

    //   this.processedCount = allSum.length;
    //   this.successCount = paySum.length;
    //   this.errCount = allSum.length - paySum.length;

    //   let nodetpsn = allSum.filter((e) => !selSum.includes(e));
    //   let notactive = selSum.filter((e) => !statSum.includes(e))
    //   let nopay = statSum.filter((e) => !paySum.includes(e));

    //   if (nodetpsn) {
    //     for (let i = 0; i < nodetpsn.length; i++) {
    //       nodetpsn[i].errormsg = "No detail in person statutory info";
    //       this.accErr.push(nodetpsn[i]);
    //     }
    //   }
      
    //   if (notactive) {
    //     for (let i = 0; i < notactive.length; i++) {
    //       notactive[i].errormsg = "Payment is not Active";
    //       this.accErr.push(notactive[i]);
    //     }
    //   }

    //   if (nopay) {
    //     for (let i = 0; i < nopay.length; i++) {
    //       nopay[i].errormsg = "No payment";
    //       this.accErr.push(nopay[i]);
    //     }
    //   }

    //   let getDate = moment(this.formData.fpdate).format("YYYYMMDD");

    //   getAmtEmployer = holdpayEmployer.reduce(function (a, b) {
    //     return a + b;
    //   }, 0);

    //   this.totalAmtEmployer = getAmtEmployer.toFixed(2);
    //   holdAmtEmployer = this.totalAmtEmployer.split(".").join("");

    //   getAmtMember = holdpayMember.reduce(function (a, b) {
    //     return a + b;
    //   }, 0);

    //   this.totalAmtMember = getAmtMember.toFixed(2);
    //   holdAmtMember = this.totalAmtMember.split(".").join("");

    //   let getHeader =
    //     "00" +
    //     this.padSpace("EPF MONTHLY FORM A", 18) +
    //     this.pad(getDate, 8) +
    //     this.pad("1", 5) +
    //     this.pad(holdAmtEmployer, 15) +
    //     this.pad(holdAmtMember, 15) +
    //     this.pad(this.data.refnum, 21) +
    //     this.padSpace(this.filler, 45);

    //   let conMonth =
    //     this.pad(this.data.epfsubrecref[0].repmonth, 2) +
    //     this.pad(this.data.epfsubrecref[0].repyear, 4);

    //   let subheader =
    //     "01" +
    //     this.pad(this.data.refnum, 19) +
    //     this.pad(conMonth, 6) +
    //     this.padSpace("DSK", 3) +
    //     this.pad("00001", 5) +
    //     this.pad(this.formData.empportionrate, 4) +
    //     this.pad(this.formData.memberportionrate, 4) +
    //     this.padSpace(this.formData.sdba, 7) +
    //     this.padSpace(this.filler, 79);

    //   getAmtRefno = holdpayRefno.reduce(function (a, b) {
    //     return a + b;
    //   }, 0);

    //   let footer =
    //     "99" +
    //     this.pad(rec, 7) +
    //     this.pad(holdAmtEmployer, 15) +
    //     this.pad(holdAmtMember, 15) +
    //     this.pad(getAmtRefno, 21) +
    //     this.padSpace(this.filler, 69);

    //   this.titleData = this.pad(vm.data.month, 2) + "_" + vm.data.year + "_EPF";

    //   if (data.length > 0) {
    //     const csvData = this.objectToTxt(getHeader, data, subheader, footer);
    //     this.download(csvData, this.titleData);
    //   }
    // },

    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padSpace(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      this.getData();
      let item = [];
      item.totalAmtEmployer = this.totalAmtEmployer;
      item.totalAmtMember = this.totalAmtMember;
      item.accounterr = this.accErr;
      item.successCount = this.successCount;
      item.processedCount = this.processedCount;
      item.errCount = this.errCount;

      if (!this.isErr) {
        this.$router.push({ name: "monthlyepfexportlog", params: { item } });
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlyepfexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    // this.getPersonStatutaryInfoListHandler();
    // this.getSalaryStatutaryOtherListHandler();
    // this.getPsnPayrollListHandler();
  },
};
</script>