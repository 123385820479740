<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>
<script>
import Form from "../../../components/attendance/preapprovedot/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import moment from "moment";

export default {
  components: {
    "app-form": Form,
    "app-error": ErrorDialogue,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      pageTitle: this.$t('preapprovedotrecord.editpreapprovedotrecord'),
      pageTitleValue: "",
      formData: {
        id: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnyname: null,
        compnycode: null,
        daytypeid: null,
        daytypecode: null,
        shiftdate: moment().format("YYYY-MM-DD"),
        otstartdate: moment().format("YYYY-MM-DD"),
        shiftid: null,
        shiftcode: null,
        otstart: "",
        otend: "",
        othrs: null,
        justify: null,
        transtatusid: null,
        transtatus: null,
        earlyot: null,
        entrytime: null,
        entrybid: null,
        entrybyusername: null,
        appsrcid: null,
        src: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      cur_stamp: 0,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      loading: true,
      search: this.$route.params.search,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //insert new record
    saveHandler() {
      const vm = this;

      vm.formData.id = vm.id;
      vm.formData.stamp = vm.cur_stamp;
      console.log(vm.formData);
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/preapprovedot/" + vm.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "preapprovedotlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    loadPreApprovedOt() {
      const vm = this;
      vm.loading = true;
      this.$store.dispatch('get', {url: "/preapprovedot/" + vm.id})
        .then((res) => {
          console.log(res.data.rec);
          vm.formData.id = res.data.rec[0].id;
          vm.formData.staffid = res.data.rec[0].staffid;
          vm.formData.staffcode = res.data.rec[0].staffcode;
          vm.formData.compnyid = res.data.rec[0].compnyid;
          vm.formData.compnyname = res.data.rec[0].compnyname;
          vm.formData.compnycode = res.data.rec[0].compnycode;
          vm.formData.daytypeid = res.data.rec[0].daytypeid;
          vm.formData.daytypecode = res.data.rec[0].daytypecode;
          vm.formData.shiftdate = moment(res.data.rec[0].shiftdate).format(
            "YYYY-MM-DD"
          );
          vm.formData.otstartdate = moment(res.data.rec[0].otstart).format(
            "YYYY-MM-DD"
          );
          vm.formData.shiftid = res.data.rec[0].shiftid;
          vm.formData.shiftcode = res.data.rec[0].shiftcode;
          vm.formData.otstart = moment(res.data.rec[0].otstart).format("HHmm");
          vm.formData.otend = moment(res.data.rec[0].otend).format("HHmm");
          vm.formData.othrs = res.data.rec[0].othrs;
          vm.formData.justify = res.data.rec[0].justify;
          vm.formData.transtatusid = res.data.rec[0].transtatusid;
          vm.formData.transtatus = res.data.rec[0].transtatus;
          vm.formData.earlyot = res.data.rec[0].earlyot == "Y" ? true : false;
          vm.formData.entrytime = moment(res.data.rec[0].entrytime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          vm.formData.entrybid = res.data.rec[0].entrybid;
          vm.formData.entrybyusername = res.data.rec[0].entrybyusername;
          vm.formData.appsrcid = res.data.rec[0].appsrcid;
          vm.formData.src = res.data.rec[0].src;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.cur_stamp = res.data.rec[0].stamp;

          vm.loading = false;
          console.log(vm.formData);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "preapprovedotlist" });
    },

    newHandler() {
      this.$router.push({ name: "preapprovedotnew" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url: "/preapprovedot/" + vm.id})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "preapprovedotlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
  mounted() {
    this.loadPreApprovedOt();
  },
};
</script>