<template>
    <div>
        <v-dialog v-model="taxsetdialog" max-width="800px">
          <template v-slot:activator="{ on }">

            <v-btn 
                color="primary" 
                dark class="mb-2" 
                v-on="on"
            >+</v-btn>

          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setuptax.taxsetupraterep')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                  <v-row>
                    <v-col cols="6" sm="6" md="6" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('mmtaxratesetup.taxgroup')"
                            v-model="selections[0].taxgroupid"
                            :items="taxgroupList"
                            dense
                            @change="changeTaxGroupHandler(selections[0].taxgroupid)"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-n8">
                        <v-autocomplete
                            
                            outlined
                            :label="this.$t('columns.description')"
                            :items="taxgroup2List"
                            append-icon=" "
                            v-model="selections[0].taxgroupid"
                            @change="changeTaxGroupHandler(selections[0].taxgroupid)"
                            dense
                            background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            
                            outlined
                            class="removeSpin"
                            :label="this.$t('mmtaxratesetup.uptoamount')"
                            type="number"
                            v-model="selections[0].uptoamount"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            
                            outlined
                            :label="this.$t('mmtaxratesetup.rate')"
                            class="removeSpin"
                            type="number"
                            v-model="selections[0].rate"
                            :rules="[rules.percentage]"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeTaxset">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveTaxset">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader1"
            :items="detailItem1"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            isTaxset: false,
            taxsetdialog: false,
            detailItem1: this.parentObject.detailItem1,
            selections: [{
                id: null,
                taxgroupid: null,
                uptoamount: null,
                rate: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            taxgroups: [],
            taxgroupList:[],
            taxgroup2List:[],

            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }
            if(item.action == 2) {
                this.isExisting = true;
            }

            this.selections = [{
                id: item.id,
                taxgroupid: item.taxgroupid,
                uptoamount: item.uptoamount,
                rate: item.rate,
            }]

            this.taxsetdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem1.indexOf(item);

            this.detailItem1.splice(itemIndex, 1);
        },

        closeTaxset() {
            this.taxsetdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        //save taxset is used to save the header details contents into the database
        saveTaxset() {

            if (this.selections[0].uptoamount == '' ){
                this.selections[0].uptoamount = null
            }

            if (this.selections[0].rate == '' ){
                this.selections[0].rate = null    
            }

            if (this.selections[0].taxgroupid == null  ){

              this.$dialog.alert(this.$t('fillintaxgroupcolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            this.isErr = false;
            // indicate value of 1 as insert, 2 as modify

            if (this.selections[0].uptoamount) 
                this.selections[0].uptoamount = parseFloat(this.selections[0].uptoamount);
                
            if (this.selections[0].rate) 
                this.selections[0].rate = parseFloat(this.selections[0].rate);

            
            if (!this.isExisting && !this.modify) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem1.push(Object.assign({}, this.selections[0]));
            } else {
                for (let i = 0; i < this.detailItem1.length; i++) {
                    if (this.detailItem1[i].id == this.selections[0].id) {
                        this.detailItem1[i].taxgroupid = this.selections[0].taxgroupid
                        this.detailItem1[i].uptoamount = this.selections[0].uptoamount
                        this.detailItem1[i].rate = this.selections[0].rate
                    }
                }
            }

            this.$emit('input', this.detailItem1);

            this.modify = false;
            this.isExisting = false;
            this.taxsetdialog = false;

            // reset the form fields
            this.resetSelections();
            }
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                taxgroupid: null,
                uptoamount: null,
                rate: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

        loadTaxGroup() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxgroup/all'})
            .then (res => {
                vm.taxgroups = res.data.rec;

                for (let i = 0; i < vm.taxgroups.length; i++) {
                    vm.taxgroupList.push(
                        {
                            text: vm.taxgroups[i].taxgroupcode,
                            value: vm.taxgroups[i].id
                        }
                    )
                }

                for (let i = 0; i < vm.taxgroups.length; i++) {
                    vm.taxgroup2List.push(
                        {
                            text: vm.taxgroups[i].taxgroupdesc,
                            value: vm.taxgroups[i].id
                        }
                    )
                }
            });
        },

        //changepcb is to show what has been loaded
        changeTaxGroupHandler(id) {
            let item = this.taxgroups.find(item => item.id == id)

            this.selections[0].taxgroupcode = item.taxgroupcode;
            this.selections[0].taxgroupdesc = item.taxgroupdesc;
        },

        check() {
            console.log('detailItem1', this.detailItem1)
        }
        
    },

    created() {
            this.loadTaxGroup();
            this.check();
        }
}
</script>

<style>

.removeSpin input[type='number'] {
    /* this chunks of code is to hide the spin button in type=number */
    -moz-appearance:textfield;
    -moz-appearance:autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>