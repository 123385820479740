<template>
    <div>
        <v-container>
            <v-form>
               <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text ml-2">
                           {{$t('reportsexportstaffinsurancerec.exportstaffinsurancelog')}}
                        </v-col>
                    </v-row>

                    <v-container>
                        <v-row>
                            <v-col cols="6" sm="6" class="orange lighten-5">
                                <v-row>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('reportlog.processed')"
                                            type="text"
                                            v-model="listErr.processed"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('reportlog.exported')"
                                            type="text"
                                            v-model="listErr.success"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                   
                                </v-row>
                            </v-col>
                        </v-row>
                               
                    </v-container>


                </v-container>
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="returnHandler"> 
                            {{$t('button.return')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';


import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },
            pageTitle: this.$t('reportsexportstaffinsurancerec.exportstaffinsurancelog'),
    

            listErr: {
                processed: null,
                success: null,
               
            },
            item: this.$route.params.itemNew,
            item2: this.$route.params.item2,
            product: this.$route.params.product,
            tableOpt: {
                itemsPerPage: -1
            },
            errMsg: '',
            isErr: false,
        }
    },

    methods: {
        getError() {

            if (this.item) {


                if(this.product.length> 0){

                this.listErr.success = this.product.length
                this.listErr.processed = this.item2.length
                } else {
                 this.listErr.success = this.item2.length
                 this.listErr.processed = this.item2.length
                }
            }
        },

   
        returnHandler() {
            this.$router.push({name: 'exportstaffinsuranceparameter'});
        },

    },

    mounted() {
        this.getError();
    }
}
</script>