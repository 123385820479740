    <template>
    <div>
        <v-dialog v-model="casesubdetaildialog" max-width="2000px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Case Subject Maintenance Item</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">

                      <v-row>
                      <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        label="Staff Name"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="selections[0].staffid"
                        dense
                        :items="staffDetailsList"
                        @change="changeStaffIDandCompanyHandler(selections[0].staffid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Staff Code"
                                type="text"
                                :rules="[rules.required]"
                                v-model="selections[0].staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Company"
                                type="text"
                                ref="compnyid"
                                v-model="selections[0].compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Involvement Level"
                                type="text"
                                ref="involvementlvlid"
                                clearable
                                :items="involvementlvlList"
                                hide-selected
                                v-model="selections[0].involvementlvlid"
                                @change="changeInvolvementLvlCodeHandler(selections[0].involvementlvlid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                label="Code"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="involvementlvlCode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="involvementlvlDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: involvementlvlItems,
                                            formTitle: 'Involvement Level',
                                            formId: 'involvementlevel'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveInvolvementLevel="saveIvolvementLevelCodeHandler"
                                        v-on:deleteInvolvementLevel="deleteInvolvementLevelCodeHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="involvementlvlDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                     <!-- <v-row class="blue--text ">
                            <h4>Case Subject Supporting Document Control </h4>
                        </v-row> -->

                <!-- <v-row v-if="hidetable2">
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table2 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader2,
                                detailItem: this.selections[0].documentdetail,
                                staffid: this.selections[0].staffid
                            }"
        
                            v-on:input="addItemHandler2"
                        />
                    </v-col>
                </v-row>  -->

                <v-row>
                <v-col cols="12" sm="12" md="12" class="pb-n4 mt-n8">
                  <v-checkbox
                    outlined
                    v-model="selections[0].termination"
                    label="Termination"
                    dense
                    @change="changeDate(selections[0].termination)"
                  ></v-checkbox>
                </v-col>

              </v-row>

            <v-row v-if="hidecolumn">
              <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  label="Effective Date"
                  type="date"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].effectivedate"
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" sm="12" md="12" class="pb-n4 mt-n8">
                <v-btn
                    color="primary"
                 
                >
                 Update Service Period
                </v-btn>
                </v-col> -->

              </v-row>

            <v-row v-else>
              <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  label="Effective Date"
                  type="date"
                  dense
                  disabled
                  :rules="[rules.required]"
                  v-model="selections[0].effectivedate"
                ></v-text-field>
              </v-col>
              </v-row>

                </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeCaseSubjectInput">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveCaseSubjectInput">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import GenericForm from '../../generic/GridForm';
//import CRUDTable2 from './CRUDTable2';

export default {
    props: {
        parentObject: Object
        
    },

    components: {
        'app-genericform': GenericForm,
        //"app-error": ErrorDialog,
       // 'app-table2' : CRUDTable2,
    },

    data() {
        return {

            rules: {
                required: value => !!value || 'Required.',
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            tabHeader2: [
               { text: 'Action', value: 'action', width: '80px', sortable: false},
               { text: 'Outcome/Action', value: 'outcomecode', width: '150px', sortable: true},
               { text: 'Date', value: 'outcomedate', width: '100px', sortable: true},
               { text: 'Respond Date', value: 'responddate', width: '100px', sortable: true},
               { text: 'Document Description', value: 'attachdesc', width: '200px', sortable: true},
               { text: 'Remark', value: 'remark', width: '120px', sortable: true},
            ],

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            casesubdetaildialog: false,
            detailItem: this.parentObject.detailItem,
            //documentdetail: this.parentObject.data.documentdetail,
            selections: [{
                id: null,
                casesubmaintenanceid: null,
                staffid: null,
                psnid: null,
                staffname: null,
                staffcode: null,
                compnyid: null,
                compnyname: null,
                involvementlvlid: null,
                involvementlvldesc: null,
                // outcomeid: null,
                // outcomedesc: null,
                termination: false,
                effectivedate: null,
                documentdetail: [],
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
                userid: this.$store.getters.userId,
            }],

            staffid: '',
            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],
       
            itemHolder: [],
            hidetable2: false,


            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
                     
            involvementlvlCode: '',
            involvementlvlList: [],
            involvementlvls: [],
            involvementlvlItems: [],
            involvementlvlDialog: false,
            involvementlvlLoading: true,

            outcomeCode: '',
            outcomeList: [],
            outcomes: [],
            outcomeItems: [],
            outcomeDialog: false,
            outcomeLoading: true,

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: '',

            hidecolumn: false,
        }
    },

 

    methods: {
       compareArray,

       changeDate(){

        if(this.selections[0].termination == true)
        {
            this.hidecolumn = true;
        }
        else {
            this.hidecolumn = false;
        }

       },

       addItemHandler2($event) {
            //console.log('docdetail2', this.selections.documentdetail)
             this.selections[0].documentdetail = [];
             this.selections[0].documentdetail = $event
             this.selections[0].staffid = this.selections[0].staffid;
             console.log('docdetail', this.selections[0].documentdetail)
             console.log('staffiddd', this.selections[0].staffid)
         },

         //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {

            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.selections[0].psnid = item.psnid;
            this.selections[0].staffid = item.id;
            this.selections[0].staffcode = item.staffcode;
            this.selections[0].compnyid = item.compnyid;
            this.selections[0].compnyname = item.compnyname;
            this.selections[0].staffname = item.psnname;
            this.crud();


        },

        crud(){
            console.log('.staffid', this.selections[0].staffid)

            this.hidetable2 = true;
            this.selections[0].staffid = this.selections[0].staffid;
        }, 
    
         loadInvolvementLevelListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/involvementlvl/all'
            })
                .then(res => {
                    vm.involvementlvls = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.involvementlvlList.push(
                            {
                                text: res.data.rec[i].involvementlvldesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.involvementlvlItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].involvementlvlcode,
                                desc: res.data.rec[i].involvementlvldesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.involvementlvlLoading = false;

                    if (vm.selections[0].involvementlvlid) {
                        vm.changeInvolvementLvlCodeHandler(vm.selections[0].involvementlvlid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changeInvolvementLvlCodeHandler(id) {
            let item = this.involvementlvls.find(item => item.id === id);

            this.involvementlvlCode = item.involvementlvlcode;
            this.selections[0].involvementlvldesc = item.involvementlvldesc; 
        },

         //save handler is for if the user wants to save a new item into the database
        saveIvolvementLevelCodeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/involvementlvl/' + this.itemData.itemid, 
                    payload: {
                        involvementlvlcode: this.itemData.itemcode,
                        involvementlvldesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.selections[0].userid,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.involvementlvls = [];
                        vm.involvementlvlItems = [];
                        vm.involvementlvlList = [];

                        this.loadInvolvementLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/involvementlvl/create', 
                    payload: {
                        involvementlvlcode: this.itemData.itemcode,
                        involvementlvldesc: this.itemData.itemdesc,
                        userid: this.selections[0].userid,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.involvementlvls = [];
                        vm.involvementlvlItems = [];
                        vm.involvementlvlList = [];

                        this.loadInvolvementLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deleteInvolvementLevelCodeHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/involvementlvl/' + $event.id + '/' + this.selections[0].userid
                })
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.involvementlvls = [];
                        vm.involvementlvlList = [];
                        vm.involvementlvlItems = [];

                        this.loadInvolvementLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

          genInvolvementLevelTabItems() {
            for (let i = 0; i < this.involvementlvls.length; i++) {
                this.involvementlvlItems.push(
                    {
                        id: this.involvementlvls[i].id,
                        code: this.involvementlvls[i].involvementlvlcode,
                        desc: this.involvementlvls[i].involvementlvldesc,
                        stamp: this.involvementlvls[i].stamp
                    }
                )
            }
        },

        
         modifyItemHandler(item) {

            

             if(item.action == 1) {
                  this.modify = true;  
                  this.hidetable2 = true;  
             }
      
             if(item.action == 2) {
                this.isExisting = true;  
                this.hidetable2 = true;
             }

             this.selections[0] = item;
             this.casesubdetaildialog = true;

             if (!this.isExisting){
                 // duplicate this.detailItem for duplicate checking
                
                 //find index 
                
                 var index = this.detailItem.findIndex( x => x.staffid == item.staffid);

                 this.detailItem[index].involvementlvlid = item.involvementlvlid;
                 this.detailItem[index].termination = item.termination;
                 this.detailItem[index].effectivedate = item.effectivedate;

                 //this.resetSelections();

             } else {
                 this.selections[0].action = 2;

                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
             }
             //this.editDialog = true;
         },        

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },
       
        closeCaseSubjectInput() {
            this.casesubdetaildialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        saveCaseSubjectInput() {

            if (this.selections[0].staffid == null || this.selections[0].involvementlvlid == null){

              this.$dialog.alert("Fill in the Staff Name and Involvement Level columns").then((dialog) => {
                console.log(dialog);
             });
            } else {
            
            this.isErr = false;
            
             if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }

            this.$emit("input", this.detailItem);
            this.casesubdetaildialog = false;
            this.resetSelections();
         }
        },

       
        resetSelections() {
            this.selections = [{
                id: null,
                casesubmaintenanceid: null,
                staffid: null,
                psnid: null,
                staffname: null,
                staffcode: null,
                compnyid: null,
                compnyname: null,
                involvementlvlid: null,
                involvementlvldesc: null,
                termination: false,
                effectivedate: null,
                documentdetail: [],
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

            if(this.selections[0].staffid == null){
            this.hidetable2 = false;
            }
        },

    },

    created() {

        this.loadStaffDetailsListHandler();
        this.loadInvolvementLevelListHandler();
    }
}
</script>   


       