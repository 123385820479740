<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}</v-btn
            >
          </v-row>
        </v-col>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-toolbar flat>
              <v-toolbar-title
                >{{$t('reportleavedashboard.leavebalancesummaryfor')}} 
                <span class="blue--text">
                    {{ this.data.formData.leavecode }}</span
                  >
                  {{$t('reportleavedashboard.asat')}} 
                  <span class="blue--text">
                    {{ this.data.formData.asofdate }}</span
                  >
              </v-toolbar-title>
              <v-spacer></v-spacer>

                <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>

              <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 20px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
          </v-toolbar>
        </v-row>

          <v-row>
            <v-col cols="20" sm="20">
              <v-data-table
                :headers="dataHeader"
                :search="search"
                :items="dataItems"
                :items-per-page="10"
                class="font-weight-light;text--secondary;"
                multi-sort
                fixed-header
                no-data-text="No records found."
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>


<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      data: {
        formData: {
           asofdate: this.$route.params.formData.asofdate,
           leavetypeid : this.$route.params.formData.leavetypeid,
           leavecode: this.$route.params.formData.leavecode,
           userid: this.$store.getters.userId,
        },

         parameters: {
           brchid: this.$route.params.param.branchid,
           deptid: this.$route.params.param.departmentid,
           compnyid: this.$route.params.param.companyid,
           emptypeid: this.$route.params.param.employmenttypeid,
           valvalueid: this.$route.params.param.valvalueid,
          // gradeid: this.$route.params.param.gradeid,
           staffid: this.$route.params.param.staffid,
         },
      },

      parameter: null,

      dataHeader: [

        { text: this.$t('columns.staffcode'), value: "staffcode"},
        { text: this.$t('columns.name'), value: "name"},
        { text: this.$t('columns.company'), value: "company"},
        { text: this.$t('reportleavebalancebystaff.balance'), value: "allowed_take" },
        { text: this.$t('reportleavebalancebystaff.currentyearentitlement'), value: "cur_period_ent" },
        { text: this.$t('reportleavebalancebystaff.bf'), value: "bf" },
        { text: this.$t('reportleavebalancebystaff.approved'), value: "approved" },
        { text: this.$t('reportleavebalancebystaff.rejected'), value: "rejected" },
        { text: this.$t('reportleavebalancebystaff.pending'), value: "pending" },
        { text: this.$t('reportleavebalancebystaff.adjustedup'), value: "adj_add" },
        { text: this.$t('reportleavebalancebystaff.adjusteddown'), value: "adj_minus" },
        { text: this.$t('reportleavebalancebystaff.forfeited'), value: "forfeited" },
        { text: this.$t('reportleavebalancebystaff.earmarkedforcf'), value: "earnmarked" },
    
      ],

      tableOpt: {
        itemsPerPage: -1,
      },

      search: '',
      dataItems: [],

      goBtn: false,
      isErr: false,
    };
  },

  methods: {
    backButton() {
      this.$router.push({ name: "leavebalancesummary" });
    },

    loadStaffFilter(val) {
      const vm = this;
      this.parameter = this.data.parameters;

      this.$store.dispatch('post', {
        url: `/stafffilter/${this.$store.getters.userId}`, 
        payload: this.parameter})
        .then(res => {
            let staffilterlist = res.data.rec;
            //let checkval = false;
            // for (const key in this.parameter) {
            //   if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            // }
            console.log('staff', staffilterlist)
            console.log ('val', val)
            //if (checkval) {
              vm.dataItems = val.filter((el) => {
                  return staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
           // }
            
        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

     loadLeaveFilter(val) {
       const vm = this;
       this.parameter = this.data.parameters;

       this.$store.dispatch('post', {
         url: `/leavefilter/${this.$store.getters.userId}`, 
         payload: this.parameter})
         .then(res => {
             let staffilterlist = res.data.rec;

             console.log('staff1', staffilterlist)
             console.log ('val1', val)
      
               vm.dataItems = val.filter((el) => {
                   return staffilterlist.some((f) => {
                       return f.staffid === el.staffid;
                   });
              });
  
            
         })
         .catch(err => {
             vm.isErr = true;
             vm.errMsg = err;
             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
         });
     },


    loadLeaveBalanceSummary() {
      this.goBtn = true;

      const vm = this;
      vm.loading = true;
      vm.dataItems = [];
      if (
        this.formData.leavetypeid &&
        this.formData.asofdate &&
        this.formData.leavecode
     
      ) {
        var data = {
          asofdate: this.formData.asofdate,
          leavetypeid: this.formData.leavetypeid,
          leavecode: this.formData.leavecode,
          userid: this.$store.getters.userId,
        };
        let paramholder = this.data.parameters
        let checkparam = false
        console.log(data);
        console.log('paramholder', paramholder)
        this.$store.dispatch('post', {
          url: `/leave/balancesummary`, 
          payload: data})
          .then((res) => {
            vm.loading = false;
            //req success --> push res into array
            console.log('rec', res.data.rec)

            if (res.data.rec.length > 0 && res.data.rec.includes('do not have group assignment')) {
              vm.isErr = true;
              vm.errMsg = res.data.rec;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }

            console.log('123',res.data.rec);
            if (res.data.rec.length > 0) {
              this.dataItems = res.data.rec; 
              let dataholder = res.data.rec;
              let dataholder2 = res.data.rec;

              for (let i =0; i<this.dataItems.length; i++){
                this.dataItems[i].allowed_take = parseFloat(this.dataItems[i].allowed_take.toFixed(2));
                this.dataItems[i].cur_period_ent = parseFloat(this.dataItems[i].cur_period_ent.toFixed(2));
                this.dataItems[i].bf = parseFloat(this.dataItems[i].bf.toFixed(2));
                this.dataItems[i].approved = parseFloat(this.dataItems[i].approved.toFixed(2));
                this.dataItems[i].rejected = parseFloat(this.dataItems[i].rejected.toFixed(2));
                this.dataItems[i].pending = parseFloat(this.dataItems[i].pending.toFixed(2));
                this.dataItems[i].adj_add = parseFloat(this.dataItems[i].adj_add.toFixed(2));
                this.dataItems[i].adj_minus = parseFloat(this.dataItems[i].adj_minus.toFixed(2));
                this.dataItems[i].forfeited = parseFloat(this.dataItems[i].forfeited.toFixed(2));
                this.dataItems[i].earnmarked = parseFloat(this.dataItems[i].earnmarked.toFixed(2));
               
               // console.log('dataitems', this.dataItems[i].allowed_take)
              }

              for (const key in paramholder) {
                if (paramholder[key] != null) {
                  checkparam = true
                }
              }
            
              
              if (checkparam) {
                console.log('par')
                this.loadStaffFilter(dataholder);
                this.loadLeaveFilter(dataholder2); 
              }


            } else {
              this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
                console.log(dialog);
              });
            }
          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    printPDF() {
       const doc = new jsPDF({
         orientation: "landscape",
       });

      var head = [
          { title: this.$t('columns.staffcode'), dataKey: "staffcode" },
          { title: this.$t('columns.name'), dataKey: "name" },
          { title: this.$t('columns.company'), dataKey: "company" },
          { title: this.$t('reportleavebalancebystaff.balance'), dataKey: "allowed_take" },
          { title: this.$t('reportleavebalancebystaff.currentyearentitlement'), dataKey: "cur_period_ent" },
          { title: this.$t('reportleavebalancebystaff.bf'), dataKey: "bf" },
          { title: this.$t('reportleavebalancebystaff.approved'), dataKey: "approved" },
          { title: this.$t('reportleavebalancebystaff.rejected'), dataKey: "rejected" },
          { title: this.$t('reportleavebalancebystaff.pending'), dataKey: "pending" },
          { title: this.$t('reportleavebalancebystaff.adjustedup'), dataKey: "adj_add" },
          { title: this.$t('reportleavebalancebystaff.adjusteddown'), dataKey: "adj_minus" },
          { title: this.$t('reportleavebalancebystaff.forfeited'), dataKey: "forfeited" },
          { title: this.$t('reportleavebalancebystaff.earmarkedforcf'), dataKey: "earnmarked" },
       ];

       let body = [];
        for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          staffcode : this.dataItems[i].staffcode,
          name: this.dataItems[i].name,
          company: this.dataItems[i].company,
          allowed_take: this.dataItems[i].allowed_take,
          cur_period_ent: this.dataItems[i].cur_period_ent,
          bf: this.dataItems[i].bf,
          approved: this.dataItems[i].approved,
          rejected: this.dataItems[i].rejected,
          pending: this.dataItems[i].pending,
          adj_add: this.dataItems[i].adj_add,
          adj_minus: this.dataItems[i].adj_minus,
          forfeited: this.dataItems[i].forfeited,
          earnmarked: this.dataItems[i].earnmarked,
        });
      }
         doc.text(
        `${this.$t('leavebalancesummary')} - ${this.formData.leavecode} as of ${this.formData.asofdate}`,
        10,
        10
      );
 
      doc.autoTable(head, body);
      doc.save(this.$t('leavebalancesummary') + ' "' + "-" + this.formData.leavecode + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        this.$t('leavebalancesummary') + ' "' + "-" + this.formData.leavecode + ".csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
         [this.$t('columns.staffcode')]: item[i].staffcode,
         [this.$t('columns.name')]: item[i].name,
         [this.$t('columns.company')]: item[i].company,
         [this.$t('reportleavebalancebystaff.balance')]: item[i].allowed_take,
         [this.$t('reportleavebalancebystaff.currentyearentitlement')]: item[i].cur_period_ent,
         [this.$t('reportleavebalancebystaff.bf')]: item[i].bf,
         [this.$t('reportleavebalancebystaff.approved')]: item[i].approved,
         [this.$t('reportleavebalancebystaff.rejected')]: item[i].rejected,
         [this.$t('reportleavebalancebystaff.pending')]: item[i].pending,
         [this.$t('reportleavebalancebystaff.adjustedup')]: item[i].adj_add,
         [this.$t('reportleavebalancebystaff.adjusteddown')]: item[i].adj_minus,
         [this.$t('reportleavebalancebystaff.forfeited')]: item[i].forfeited,
         [this.$t('reportleavebalancebystaff.earmarkedforcf')]: item[i].earnmarked,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },

},


  mounted() {},

  created() {
    const vm = this;
    vm.formData = this.$route.params.formData;

    // if (vm.formData.payoutcode) {
    //   this.showID = true;
    // } else {
    //   this.showDate = true;
    // }
     this.loadLeaveBalanceSummary();
  },
};
</script>

<style>
.dateTitle {
  font-size: 12px;
  color: #ffffff;
  height: 0px;
  background-color: orange;
  align-content: center;
  justify-content: center;
}

.scroller {
  height: 100%;
}
</style>