<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        editMode: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import SecuserForm from "../../../components/settings/secuser/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": SecuserForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      pageTitle: this.$t('recuser.edituser'),
      pageTitleValue: "",
      formData: {
        userid: null,
        psnid: null,
        psnname: null,
        usertypeid: null,
        startdate: null,
        enddate: null,
        lastaccess: null,
        lastaccdays: null,
        pwdlastchg: null,
        pwdchgdays: null,
        activate: true,
        status: null,
        passwd: null,
        idno: null,
        chgsrc: null,
        stamp: 0,
        id: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
       search: this.$route.params.search,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadSecuser() {
      const vm = this;

      this.$store.dispatch('get', {url: "/secuser/" + this.id})
        .then((res) => {
          if (res.data.rec[0].startdate) {
            vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
            vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].lastaccess) {
            vm.formData.lastaccess = moment(res.data.rec[0].lastaccess).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }

          if (res.data.rec[0].pwdlastchg) {
            vm.formData.pwdlastchg = moment(res.data.rec[0].pwdlastchg).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }

          if (res.data.rec[0].activate == "Y") {
            vm.formData.activate = true;
          } else {
            vm.formData.activate = false;
          }

          vm.formData.userid = res.data.rec[0].userid;
          vm.formData.psnid = res.data.rec[0].psnid;
          vm.formData.usertypeid = res.data.rec[0].usertypeid;
          vm.formData.lastaccdays = res.data.rec[0].lastaccdays;
          vm.formData.pwdchgdays = res.data.rec[0].pwdchgdays;
          vm.formData.status = res.data.rec[0].status;
          vm.formData.passwd = res.data.rec[0].passwd;
          vm.formData.idno = res.data.rec[0].idno;
          vm.formData.stamp = res.data.rec[0].stamp;

          vm.pageTitleValue = res.data.rec[0].psnname;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/secuser/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "secuserlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/secuser/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "secuserlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "secusernew" });
    },
  },

  mounted() {
    this.loadSecuser();
  },
};
</script>