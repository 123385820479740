<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="12" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.staffname')"
                                dense
                                ref="staffid"
                                @input="updateValue"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                :items="staffList"
                                @change="changeStaffHandler(formData.staffid)"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="12" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.staffname')"
                                dense
                                ref="staffid"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                :items="staffList"
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.staffcode')"
                                dense
                                :items="staff1List"
                                readonly
                                background-color="#ffeab2"
                                append-icon=""
                                v-model="formData.staffid"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.company')"
                                dense
                                :items="staff2List"
                                readonly
                                background-color="#ffeab2"
                                append-icon=""
                                v-model="formData.staffid"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.taxablegross')"
                                type="number"
                                ref="taxgross"
                                :rules="[rules.required]"
                                v-model="formData.taxgross"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.epfpaid')"
                                type="number"
                                ref="epfpiad"
                                v-model="formData.epfpaid"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.pcbpaid')"
                                type="number"
                                ref="pcbpaid"
                                v-model="formData.pcbpaid"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.taxablerebate')"
                                type="number"
                                ref="rebate"
                                v-model="formData.rebatezakat"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.taxablerebate')"
                                type="number"
                                ref="loectax"
                                v-model="formData.loectax"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                v-model="formData.remark"
                                :label="this.$t('columns.remark')"
                                ref="remark"
                                type="text"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>  
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        // 'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                staffid: this.parentObject.data.staffid,
                startdate: this.parentObject.data.startdate,
                taxgross: this.parentObject.data.taxgross,
                epfpaid: this.parentObject.data.epfpaid,             
                pcbpaid: this.parentObject.data.pcbpaid,             
                rebate: this.parentObject.data.rebate,                
                loectax: this.parentObject.data.loectax, 
                lockstaff: this.parentObject.data.lockstaff,              
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            currencyCode: '',
            staffCode: '',

            //the differents currency list here is so that each 
            //currency fields can have different values 
            //to show and when saving

            staffList: [],
            staff1List: [],
            staff2List: [],

            staffs: [],

            // currencyItems: [],
            
            // currencyDialog: false,

            // currencyLoading: true,
            
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        // load handler is for loading the contents of each items from the database
        loadStaffListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.staffs = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staff1List.push(
                            {
                                text: res.data.rec[i].staffcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staff2List.push(
                            {
                                text: res.data.rec[i].compnyname,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (vm.formData.staffid) {
                        vm.changeStaffHandler(vm.formData.staffid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeStaffHandler(id) {
            let item = this.staffs.find(item => item.id === id);

            this.staffCode = item.staffcode;
            console.log(this.staffCode)
        },

        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                startdate: this.formData.startdate,
                taxgross: this.formData.taxgross,
                epfpaid: this.formData.epfpaid,      
                pcbpaid: this.formData.pcbpaid,               
                rebate: this.formData.rebate,             
                loectax: this.formData.loectax,
                lockstaff: this.formData.lockstaff,              
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'tp3list', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadStaffListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }

}
</script>

<style>
    /* this chunks of code is to hide the spin button in type=number */
    .removeSpin input[type='number'] {
        -moz-appearance:textfield;
        -moz-appearance:autocomplete;
    }
    .removeSpin input::-webkit-outer-spin-button,
    .removeSpin input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

</style>