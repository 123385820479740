<template>
    <div>
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
          isWizard: this.wizardMode,
        }"
        v-on:input="formData = $event"
        v-on:next="nextHandler"
        v-on:save="saveHandler"
        v-on:error="errorHandler"
      />
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  
  import KpiSettingForm from "../../../components/performancemanagement/targetsetting/Form";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    components: {
      "app-form": KpiSettingForm,
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        pageTitle: 'Kpi Setting',
        pageTitleValue: "Complete the details below to create an exercise in capturing employees’ objective for the period defined.",
        formData: {
          ratingscalecode: null,
          ratingscaledesc: null,
          isdisable: false,
          remarks: null,
          ratingscalesetdetail: [],
  
          stamp: 0,
          userid: this.$store.getters.userId,
        },
        errMsg: "",
        isErr: false,
        editMode: false,
        wizardMode: true,
      };
    },
  
    methods: {
  
      errorHandler($event) {
        this.isErr = $event.isErr;
        this.errMsg = $event.errMsg;
        },
  
      saveHandler() {
        // const vm = this;
  
        // if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        //   return false;
        // }
  
        // this.$store.dispatch('post', {url: "/ratingscaleset/create", payload: this.formData})
        //   .then((res) => {
        //     if (res.data.error) {
        //       vm.isErr = !vm.isErr;
        //       vm.errMsg = res.data.error;
        //     } else {
              this.$router.push({ name: "kpisettinglist" });
          //   }
          // })
          // .catch((err) => {
          //   vm.isErr = !vm.isErr;
          //   vm.errMsg = err;
          //    console.log("err", err);
          // });
      },

      nextHandler() {
        // const vm = this;
  
        // if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        //   return false;
        // }
  
        // this.$store.dispatch('post', {url: "/ratingscaleset/create", payload: this.formData})
        //   .then((res) => {
        //     if (res.data.error) {
        //       vm.isErr = !vm.isErr;
        //       vm.errMsg = res.data.error;
        //     } else {
              this.$router.push({ name: "kpisettinggenerate" });
          //   }
          // })
          // .catch((err) => {
          //   vm.isErr = !vm.isErr;
          //   vm.errMsg = err;
          //    console.log("err", err);
          // });
      },
    },
  };
  </script>