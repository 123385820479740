<template>
    <div v-if="!loading">
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }} <span class="page_title_value" v-if="this.payoutctrls.payoutdesc"> {{ this.payoutctrls.payoutdesc }}</span> </h2>
                </v-row>
                

                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col>
                            <h2>
                                <span class="page_title_value"> {{ this.staff.staffcode }}</span> 
                                <span class="page_title_value"> {{ this.staff.psnname }}</span> 
                            </h2>
                        </v-col>
                    </v-row>
                        <v-row>
                            <v-col col="12" sm="12" class="pb4-4">
                                <v-data-table
                                    :headers="tabHeaderDetails"
                                    class="elavation-1"
                                    :items="detailItemList"
                                    fixed-header
                                    multi-sort
                                    :sort-by="['code']"
                                    :options="tableOpt"
                                    hide-default-footer
                                    >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <!-- <v-toolbar-title class="subtitle-1">{{titleDetail}}</v-toolbar-title> -->
                                            <v-spacer></v-spacer>
                                            <v-col cols="1" sm="1" class="ml-n4">
                                                <div style="width: 50%; max-height: 50%" >
                                                    <v-img @click="printPDFDetail" :src="`${publicPath}images/export_pdf.png`"/>
                                                </div>
                                            </v-col>
                                            <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                                                <div style="width: 50%">
                                                    <v-img @click="getCSVDetail" :src="`${publicPath}images/export_csv.png`"/>
                                                </div>
                                            </v-col>
                                        </v-toolbar>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                </v-container>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: this.$t('previewexception.payrollexceptiondashboard'),
            pageTitleValue: '',
            formData: {
                pystatuscode: null
            },
            tabHeaderDetails: [
                { text: this.$t('setuppayitem.payitem'), value: 'payitemcode', sortable: true, width: '80px' },
                { text: this.$t('columns.startdate'), value: 'pistartdate', width: '50px', sortable: true},
                { text: this.$t('columns.enddate'), value: 'pienddate', width: '50px', sortable: true },
                { text: this.$t('payeeinputprocess.approvedamount'), value: 'aprvamt', sortable: true, width: '5px' },
                { text: this.$t('payeeinputprocess.approvedquantity'), value: 'aprvqty', width: '60px', sortable: true},
                { text: this.$t('columns.currency'), value: 'crcycode', width: '100px', sortable: true },
                { text: this.$t('setuptp1.source'), value: 'srctbl', width: '50px', sortable: true },
                { text: this.$t('payeeinputprocess.justification'), value: 'justification', sortable: true, width: '80px' },
            ],
          
            tableOpt: {
                itemsPerPage: -1
            },

            getid: this.$route.params.id,
            getstaffid: this.$route.params.staffid,
            
            data: {
                formData: {
                    poid: this.$route.params.formData.poid,
                },
            },
            
            payoutctrls: null,
            staff: null,
            payoutctrlList: [],
            detailList: [],
            detailItemList: [],
            titleSummary: this.$t('previewpayroll.summary'),
            titleDetail: this.$t('payeeinputprocess.details'),
            
            errMsg: '',
            isErr: false,
            editMode: false,
            loading: true,
        }
    },

    methods: {

        loadStaffListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/staff/' + this.getstaffid})
            .then (res => {
                vm.staff = res.data.rec[0]
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        loadPayoutListHandler() {
            const vm = this;

                this.$store.dispatch('get', {url: '/payoutctrl/' + this.data.formData.poid})
                .then (res => {
                    vm.payoutctrls = res.data.rec[0]
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                })
        },

        loadDetailListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/ppdetaillist/all'})
            .then (res => {
                vm.detailList = res.data.rec
                
                for (let i = 0; i < vm.detailList.length; i++) {
                    if (vm.detailList[i].pistartdate) {
                        vm.detailList[i].pistartdate = moment(vm.detailList[i].pistartdate).format('YYYY-MM-DD');
                    }

                    if (vm.detailList[i].pienddate) {
                        vm.detailList[i].pienddate = moment(vm.detailList[i].pienddate).format('YYYY-MM-DD');
                    }
                }

                vm.detailItemList = vm.detailList.filter(x => x.salhdid == this.getid)
                vm.loading = false
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data, title) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSVDetail() {
            let itemDetail = this.detailItemList

            let dataDetail = []

            for (var j = 0; j < itemDetail.length; j++) {
                if(itemDetail[j].srctbl === null || itemDetail[j].srctbl === undefined) {
                    itemDetail[j].srctbl = ''
                }
                if(itemDetail[j].justification === null || itemDetail[j].justification === undefined) {
                    itemDetail[j].justification = ''
                }
                dataDetail.push({
                    [`${this.$t('setuppayitem.payitem')}`]: itemDetail[j].payitemdesc,
                    [`${this.$t('columns.startdate')}`]: itemDetail[j].pistartdate,
                    [`${this.$t('columns.enddate')}`]: itemDetail[j].pienddate,
                    [`${this.$t('payeeinputprocess.approvedamount')}`]: itemDetail[j].aprvamt,
                    [`${this.$t('payeeinputprocess.approvedquantity')}`]: itemDetail[j].aprvqty,
                    [`${this.$t('columns.currency')}`]: itemDetail[j].crcycode,
                    [`${this.$t('setuptp1.source')}`]: itemDetail[j].srctbl,
                    [`${this.$t('payeeinputprocess.justification')}`]: itemDetail[j].justification,
                    [`${this.$t('setuppayitmetype.payitemtype')}`]: itemDetail[j].payitemtypedesc
                })
            }

            const csvData = this.objectToCSV(dataDetail)
            this.download(csvData, this.titleDetail)

        },


        printPDFDetail() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.detailItemList

            var head = [                
                { title: this.$t('setuppayitem.payitem'), dataKey: 'payitemdesc' },
                { title: this.$t('columns.startdate'), dataKey: 'pistartdate' },
                { title: this.$t('columns.enddate'), dataKey: 'pienddate' },
                { title: this.$t('payeeinputprocess.approvedamount'), dataKey: 'aprvamt' },
                { title: this.$t('payeeinputprocess.approvedquantity'), dataKey: 'aprvqty' },
                { title: this.$t('columns.currency'), dataKey: 'crcycode' },
                { title: this.$t('setuptp1.source'), dataKey: 'srctbl' },
                { title: this.$t('payeeinputprocess.justification'), dataKey: 'justification' },
                { title: this.$t('setuppayitmetype.payitemtype'), dataKey: 'payitemtypedesc' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    payitemdesc: item[i].payitemdesc,
                    pistartdate: item[i].pistartdate,
                    pienddate: item[i].pienddate,
                    aprvamt: item[i].aprvamt,
                    aprvqty: item[i].aprvqty,
                    crcycode: item[i].crcycode,
                    srctbl: item[i].srctbl,
                    justification: item[i].justification,
                    payitemtypedesc: item[i].payitemtypedesc
                })
            }
            doc.text(this.titleDetail, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.titleDetail + '.pdf')
        },

       
        
        backHandler() {
            let id = this.data.formData.poid

            this.$router.push({name: 'payrollexceptionsummary', params: { id }});
        },

    },

    mounted() {
        this.loadDetailListHandler();
        this.loadStaffListHandler();
        this.loadPayoutListHandler();
    }
}
</script>