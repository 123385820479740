<template>
    <div>
        <monthlyeis-form v-if="this.id == 4"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlyeishlb-form v-if="this.id == 10"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlyeisambank-form v-if="this.id == 15"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlyeiscimb-form v-if="this.id == 22"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlyeispublic-form v-if="this.id == 29"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlyeisaffin-form v-if="this.id == 36"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlyeishsbc-form v-if="this.id == 39"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlyEISForm from '../../../components/payroll/monthlyeisexport/MonthlyEIS';
import MonthlyEISHLBForm from '../../../components/payroll/monthlyeisexport/MonthlyEISHLB';
import MonthlyEISAmbankForm from '../../../components/payroll/monthlyeisexport/MonthlyEISAmbank';
import MonthlyEISCIMBForm from '../../../components/payroll/monthlyeisexport/MonthlyEISCIMB';
import MonthlyEISPUBLICForm from '../../../components/payroll/monthlyeisexport/MonthlyEISPublic';
import MonthlyEISAffinForm from '../../../components/payroll/monthlyeisexport/MonthlyEISAffin';
import MonthlyEISHSBCForm from '../../../components/payroll/monthlyeisexport/MonthlyEISHSBC';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlyeis-form': MonthlyEISForm,
        'monthlyeishlb-form': MonthlyEISHLBForm,
        'monthlyeisambank-form': MonthlyEISAmbankForm,
        'monthlyeiscimb-form': MonthlyEISCIMBForm,
        'monthlyeispublic-form': MonthlyEISPUBLICForm,
        'monthlyeisaffin-form': MonthlyEISAffinForm,
        'monthlyeishsbc-form': MonthlyEISHSBCForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('alleis.monthlyeis'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },

}

</script>