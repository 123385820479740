<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('allptptn.ptptnsubmissionrecord_s')"
                type="text"
                v-model="formData.ptptnexprecid"
                :items="ptptnExpRecList"
                :rules="[rules.required]"
                @change="changePTPTNExportRecord(formData.ptptnexprecid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" class="mb-n1 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
                type="text"
                v-model="formData.year"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" class="mb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('bankpaymentlistingreport.payoutmonth')"
                type="text"
                v-model="formData.month"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}} </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.payoutItems"
                :options="tableOpt"
                hide-default-footer
                item-key="payoutcode"
              >
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-3">
              <v-autocomplete
                outlined
                :label="this.$t('recfamdetails.refno')"
                type="text"
                v-model="formData.refnum"
                :rules="[rules.required]"
                :items="ptptnSubRefRecList"
                dense
            
              ></v-autocomplete>
            </v-col>
          </v-row>


        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="getPTPTNExportListHandler">
              {{$t('button.download')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('allptptn.ptptnexport'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        { text: this.$t('payrollenquiryreport.payoutdesription'), value: "payoutdesc", width: "300px", sortable: true },
        { text: this.$t('payrollprocessrun.payoutstatus'), value: "pystatusdesc", width: "300px", sortable: true },
      ],
      formData: {
        ptptnexprecid: null,
        refnum: null,
        year: null,
        month: null,
        myntlycode: null,
        ptptnsubrecpy: [],
        ptptnsubrecref: [],
        ptptnexpformatid: null,
      },

      errnorecfound: this.$t('vdatatable.norecordsfound'),
      errselected: this.$t('vdatatable.selectedpayouthasnotbeenfinalized'),

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      ptptnExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      salstatutoryother: [],
      psnstatutoryinfo: [],
      staff: [],
      psnpayroll: [],
      staffaccount: [],
      ptptndata: [],

      notFinalize: false,
      loading: false,
      isErr: false,
      errMsg: "",
       ptptnSubRefRecList: [],
       total: null,
    };
  },

  methods: {
    getPTPTNSubRecs() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/ptptnsubmissionrecord/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.ptptnExpRecList.push({
              text: res.data.rec[i].ptptnsubreccode,
              value: res.data.rec[i].id,
            });
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getPTPTNSubRecPayout(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/ptptnsubmissionrecordpy/" + id})
        .then((res) => {
          vm.payoutItems = res.data.rec;
          vm.formData.ptptnsubrecpy = vm.payoutItems;

          //console.log('payout', vm.payoutItems)
         // console.log ('000', vm.formData.ptptnsubrecpy)

          for (let i = 0; i < vm.payoutItems.length; i++) {
            if (vm.payoutItems[i].pystatuscode != 99) {
             // console.log(vm.payoutItems[i].pystatuscode)
              this.notFinalize = true;
            } else {
              this.notFinalize = false;
            }
           // console.log(this.notFinalize)
          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getPTPTNSubRecRef(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/ptptnsubmissionrecordref/" + id})
        .then((res) => {
          vm.formData.ptptnsubrecref = res.data.rec;

          vm.ptptnSubRefRecList = [];

        for (let i = 0; i < res.data.rec.length; i++) {
            vm.ptptnSubRefRecList.push({
              text: res.data.rec[i].refcode + ' - ' + res.data.rec[i].refnum,
              value: res.data.rec[i].refnum,
            });
          }
          vm.loading = !vm.loading;
          
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    changePTPTNExportRecord(id) {
      let itemExportRecord = this.dataItems.find((item) => item.id === id);

      this.formData.ptptnexprecid = itemExportRecord.id;
      this.formData.year = itemExportRecord.recyear;
      this.formData.month = itemExportRecord.recmonth;
      this.formData.myntlycode = itemExportRecord.myntlycode;

      this.getPTPTNSubRecPayout(id);
      this.getPTPTNSubRecRef(id);
      
    },


     objectToCSV(data) {
        const csvRows = []
      //for header
        // for (const key in header) {
        //     let headobj = key + ',' + header[key]
        //     csvRows.push(headobj) 
        // }
        // csvRows.push([])

        // get header
        const headers = Object.keys(data[0])
        csvRows.push(headers.join(','))

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }

        return csvRows.join('\n')
    },

    download(data, title) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    
    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },


    exportHandler() {
      this.getData();

    },

   

  getPTPTNExportListHandler() {
      const vm = this
        //to get today's date
       let dateHolder2 = this.formData.year + "-" + this.padRJ(this.formData.month, 2) + "-" + this.daysInMonth(this.formData.month, this.formData.year);
       let date = moment(dateHolder2).format("YYYY-MM-DD");

 
      this.$store.dispatch('get', {url: `/ptptnexport/${this.$store.getters.userId}`+ "/" +  this.formData.ptptnexprecid
      + "/" + this.formData.refnum  + "/" + date  + "/" + date })
      .then(function (res) {
        let ptptndataholder = res.data.rec;

        

      // console.log('ptptn', ptptndataholder.length)
  
        ptptndataholder = ptptndataholder.filter(e => e.poid == vm.formData.ptptnsubrecpy[0].poid)
       // console.log('123', ptptndataholder)

            if(ptptndataholder.length < 1) {
                vm.isErr = true;
                 vm.errMsg = vm.errnorecfound;
     
         }

        ptptndataholder.forEach(function (o) {
          var existing = vm.ptptndata.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            vm.ptptndata.push(o);

         //    console.log('ptptn2', vm.ptptndata)
  

          }         
        });
       
          if (vm.ptptndata.length > 0) {   

    
              if (vm.notFinalize == true ) {

                 vm.isErr = true;
                //  vm.errMsg = this.$t('vdatatable.selectedpayouthasnotbeenfinalized');
                //  vm.errMsg = 'Testing';
                 vm.errMsg = vm.errselected;
                 
                } else {
                  vm.exportHandler(); 
  
                }
                
               
          } 
      },
      )
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    async getData() {

    //  console.log('rr0', neweisdetail)

      const vm = this
      let body = []
     // let holdpaycontamt = []
    //  let neweisdetail = []
      let dataholder = vm.ptptndata
      // let ptptndetail = dataholder;     

      // console.log('1', neweisdetail)

      // ptptndetail.forEach(function (o) {
      //   var existing = neweisdetail.filter(function (i) {
      //     console.log('newisdetail', neweisdetail)
      //     return i.staffcode === o.staffcode;
      //   })[0];
      //   if (!existing || existing == undefined) {
      //     neweisdetail.push(o);
      //   } else {
      //     existing.amount += o.amount;
      //   }
      // });

      // console.log(neweisdetail)
      // console.log('ptptndetail', ptptndetail)

      //   let header = {
      //     "Item": neweisdetail[0].payitemdesc,
      //     "File Name": vm.formData.ptptnsubrecref[0].refnum + "_" + vm.formData.month + "_" + vm.formData.year +"_L"   
      // }
 
      let contamtget;

      for (let i = 0; i < dataholder.length; i++) {
        contamtget = dataholder[i].amount.toFixed(2);
        let refnoholder = ''
    
        if (dataholder[i].kad_pengenalan != null) refnoholder = dataholder[i].kad_pengenalan.split("-").join("")

        body.push({

          "IC Number": this.padSpaceLJ(refnoholder, 12),
          "Salary Number": this.padSpaceLJ(dataholder[i].salarynumber, 12),
          "Amount": this.padSpaceLJ(contamtget, 7),
          "Employer Code": this.padSpaceLJ(vm.formData.ptptnsubrecref[0].refnum, 12),
          "Employee Name": this.padSpaceLJ(dataholder[i].psnname, 50),
          "End Date Deduction": '',

      
        });

        // holdpaycontamt.push(parseFloat(contamtget.replace(/,/g, "")));

        // let gettotal = holdpaycontamt.reduce(function (a, b) {
        //     return a + b;
        //   }, 0);

        //   gettotal = gettotal.toFixed(2);

        //   vm.total = gettotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      }

      this.titleData = vm.formData.ptptnsubrecref[0].refnum + "_" + this.padRJ(vm.formData.month, 2) + vm.formData.year +"_L";

      
      if (body.length > 0) {
        const csvData = this.objectToCSV(body);
        this.download(csvData, this.titleData);
      }
  
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },


  },

  mounted() {
    this.getPTPTNSubRecs();
  },
};
</script>

