<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.staffname')"
                                type="text"
                                :items="staffList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                @input="updateValue"
                                @change="changestaffHandler(formData.staffid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                background-color="#ffeab2"
                                readonly
                                v-model="formData.staffcode" 
                                dense                       
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                background-color="#ffeab2"
                                readonly
                                v-model="formData.compnyname" 
                                dense                       
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="5" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.workflowtype')"
                                type="text"
                                :items="workflowtypeList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.wftypeid"
                                @input="updateValue"
                                @change="changewftypeHandler(formData.wftypeid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('payeeinputprocess.details')"
                                type="text"
                                :items="detailList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.detailid"
                                @input="updateValue"
                                @change="changedetailHandler(formData.detailid)"
                                :disabled="wfdetailCheck == false"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.code')"
                                type="text"
                                background-color="#ffeab2"
                                readonly
                                :disabled="wfdetailCheck == false"
                                v-model="formData.detailcode" 
                                dense                       
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import axios from 'axios';
//import moment from 'moment';

//import GenericForm from '../../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                userid: this.parentObject.data.userid,
                staffid: this.parentObject.data.staffid,
                psndetails: this.parentObject.data.psndetails,
                staffcode: this.parentObject.data.staffcode,
                compnyname: this.parentObject.data.compnyname,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                wftypeid: this.parentObject.data.wftypeid,
                wftypedesc: this.parentObject.data.wftypedesc,
                detailid: this.parentObject.data.detailid,
                detailcode: this.parentObject.data.detailcode,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                id: this.parentObject.data.id,
            },

            staffItem: [],
            workflowtypeItem: [],
            detailItem: [],
            
            staffList:[],
            workflowtypeList: [],
            detailList: [],

            errMsg: '',
            wfdetailCheck: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadStaff() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/staff/all/${this.$store.getters.userId}`
                })
                .then(res => {
                    vm.staffItem = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (this.formData.staffid) {
                       this.changestaffHandler(this.formData.staffid);
                    }

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadWorkflowType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/workflow/type`
                })
                .then(res => {
                    vm.workflowtypeItem = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.workflowtypeList.push(
                            {
                                text: res.data.rec[i].wftypedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    // if (this.formData.wftypeid) {
                    //    this.changewftypeHandler(this.formData.wftypeid);
                    // }
                    //if (this.formData.detailid) {
                        
                    //}
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //to get usertypeid of the logged in user
        loadDetail(id) {
            const vm = this;
            vm.detailList = []
            vm.detailItem = []
            // this.formData.detailid = null
            this.formData.detailid = null
            this.formData.detailcode = null
            // if (id == null) {
            //     this.formData.detailid = null
            //     this.changedetailHandler(0, 0);
            // }
            if (id == 3) {

            this.$store.dispatch('get', {
                url: '/leavetype/all'
                })
                .then (res => {
                    vm.detailItem = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.detailList.push(
                            {
                                text: res.data.rec[i].leavedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    // if (this.formData.detailid) {
                    //    this.changedetailHandler(id, this.formData.detailid);
                    // }
                })
                .catch (err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }

            if (id == 7) {
                this.$store.dispatch('get', {
                url: '/claimtypesweb/all'
                })
                .then (res => {
                    vm.detailItem = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.detailList.push(
                            {
                                text: res.data.rec[i].clmtypedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    // if (this.formData.detailid) {
                    //    this.changedetailHandler(id, this.formData.detailid);
                    // }
                })
                .catch (err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }
        },

        changestaffHandler(id) {
            let item = this.staffItem.find(item => item.id === id);

            if (item) {
                this.formData.staffcode = item.staffcode;
                this.formData.compnyname = item.compnyname;
            } else {
                this.formData.staffcode = null;
                this.formData.compnyname = null;
            }
        },

        changewftypeHandler(id) {
            if (id == undefined) {
                const vm = this;
                vm.detailList = []
                vm.detailItem = []
                this.formData.detailcode = null
            }
            // let item = this.workflowtypeItem.find(item => item.id === id);
            else if (id == 3 || id == 7) {
                let item = this.workflowtypeItem.find(item => item.id === id);
                // if (item.id == 3 || item.id == 7) 
                this.wfdetailCheck = true
                this.loadDetail(item.id);
            } else {
                this.wfdetailCheck = false
                this.loadDetail(null);
            }
        },

        // changedetailHandler(wftypeid, id) {
        changedetailHandler(id) {
            let item = this.detailItem.find(item => item.id === id);
            // formData.wftypeid
            if (id == undefined) {
                this.formData.detailcode = null
                return
            }
            if (this.formData.wftypeid == 3) this.formData.detailcode = item.leavecode;
            if (this.formData.wftypeid == 7) this.formData.detailcode = item.clmtype;
            // if (item) {
            //     if (wftypeid == 3) this.formData.detailcode = item.leavecode;
            //     if (wftypeid == 5) this.formData.detailcode = item.clmtype;
            // } else {
            //     this.formData.detailcode = null;
            // }
        },

        updateValue() {
            this.$emit('input', {
                userid: this.formData.userid,
                staffid: this.formData.staffid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                wftypeid: this.formData.wftypeid,
                detailid: this.formData.detailid,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                id: this.parentObject.data.id,
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'workflowexceptionlist',  params: { search: this.search}});
        },
    },

    mounted() {
        this.loadStaff();
        this.loadWorkflowType();
    }
}
</script>