<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData= $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:cancel="cancelHandler"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import moment from "moment";


import RatingScaleSetForm from "../../../components/performance/ratingscaleset/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": RatingScaleSetForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('Setupratingscaleset.editratingscaleset'),
      pageTitleValue: "",
      formData: [],
      // formData: {
      //   policyno: null,
      //   startdate: null,
      //   enddate: null,
      //   policydesc: null,
      //   insproviderid: null,
      //   remark: null,
      //   expformatid: null,
      //   //logicscrpt: null,
      //   stamp: 0,
      //   userid: this.$store.getters.userId,
      // },

      errMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
    };
  },

  methods: {

    errorHandler($event) {
    this.isErr = $event.isErr;
    this.errMsg = $event.errMsg;
    },

    loadRatingScaleSetHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/ratingscaleset/" + vm.id})
        .then((res) => {

          vm.formData = res.data.rec[0];

         if (res.data.rec[0].isdisable == "N") {
                vm.formData.isdisable = false; 
          } else {
                vm.formData.isdisable = true;
          }

          vm.formData.ratingscalecode = res.data.rec[0].ratingscalecode;
          vm.formData.ratingscaledesc = res.data.rec[0].ratingscaledesc;
          vm.formData.remarks = res.data.rec[0].remarks;
    
  
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.userid = this.$store.getters.userId;
          
          vm.pageTitleValue = res.data.rec[0].ratingscalecode;

          this.loadRatingScaleDetailHandler();
         // vm.loading = false;

        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    
    loadRatingScaleDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/ratingscalesetdetail/" + this.id})
        .then((res) => {
          vm.formData.ratingscalesetdetail = res.data.rec;
         
          

          for (let i = 0; i < vm.formData.ratingscalesetdetail.length; i++) {
            Object.assign(vm.formData.ratingscalesetdetail[i], {
              action: 2,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/ratingscaleset/" + vm.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "ratingscalesetlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

      cancelHandler() {
      this.$router.push({ name: "ratingscalesetlist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

         this.$store.dispatch('delete', {url:
            "/ratingscaleset/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "ratingscalesetlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "ratingscalesetnew" });
    },
  },

  mounted() {
    this.loadRatingScaleSetHandler();
  },
};
</script>