var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.id == 4)?_c('monthlyeis-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 10)?_c('monthlyeishlb-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 15)?_c('monthlyeisambank-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 22)?_c('monthlyeiscimb-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 29)?_c('monthlyeispublic-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 36)?_c('monthlyeisaffin-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(this.id == 39)?_c('monthlyeishsbc-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(_vm.isErr)?_c('div',[_c('app-error',{attrs:{"parentError":{
                data: this.errMsg,
                isErr: this.isErr
            }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }