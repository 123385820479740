<template>
  <div>
    <v-container>
      <form enctype="multipart/form-data">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle
              }}<span class="page_title_value">{{
                this.parentObject.pageTtileValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
          :style="{
            'max-height': `${filterSectionHeight}px`,
          }"
        > -->
        <v-row >
          <v-col cols="10" sm="10" class="mt-n8">
            
           <v-row v-if="!this.formData.lockstaff"> 
              <v-col cols="8" sm="8" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.user')"
                  type="text"
                  ref="hrUserid"
                  :items="secuserList"
                  hide-selected
                  v-model="formData.hrUserid"
                  :rules="[rules.required]"
                  @input="updateValue"
                  dense
                  @change="changeSecuserHandler(formData.hrUserid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

             <v-row v-else>
              <v-col cols="8" sm="8" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.user')"
                  type="text"
                  ref="hrUserid"
                  :items="secuserList"
                  v-model="formData.hrUserid"
                  :rules="[rules.required]"
                  readonly
                  dense
                  background-color="#ffeab2"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.staffname')"
                  type="text"
                  readonly
                  background-color="#ffeab2"
                  dense
                  disabled
                  v-model="username"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-n8">
            <v-btn depressed color="primary" @click="CheckAll()" class="ma-0">
              {{$t('button.checkoruncheck')}}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" class="mt-n4">
            <v-row>
              <v-tabs>
                <v-tab v-for="key in formData.functionkey" :key="key">{{
                  key
                }}</v-tab>

                <v-tab-item
                  v-for="key in formData.functionkey"
                  :key="key"
                  style="margin-top: 10px"
                  class="pb-6"
                >
                  <v-row style="padding-left: 12px" class="pt-4">
                    <v-col class="brdLeft brdTop brdBottom" cols="4" sm="4">
                      <v-row align="center">
                        <v-col cols="1" sm="1">{{$t('rechruserfunctionaccess.screen')}}</v-col>
                        <v-col cols="1" sm="1">
                          <v-checkbox
                            class="ma-0 pa-0"
                            :hide-details="true"
                            style="visibility: hidden"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="brdLeft brdTop brdBottom text-center"
                      cols=""
                      sm="1"
                    >
                      <v-row align="center">
                        <v-col cols="1" sm="1">
                          <v-checkbox
                            class="ma-0 pa-0"
                            @change="AccessCheck()"
                            v-model="isAccessCheck"
                            :hide-details="true"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="9" sm="9">{{$t('rechruserfunctionaccess.access')}}</v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="brdLeft brdTop brdBottom text-center"
                      cols="2"
                      sm="1"
                    >
                      <v-row align="center">
                        <v-col cols="1" sm="1">
                          <v-checkbox
                            class="ma-0 pa-0"
                            @change="AddCheck()"
                            v-model="isAddCheck"
                            :hide-details="true"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="9" sm="9">{{$t('rechruserfunctionaccess.add')}}</v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="brdLeft brdTop brdBottom text-center"
                      cols="2"
                      sm="1"
                    >
                      <v-row align="center">
                        <v-col cols="1" sm="1">
                          <v-checkbox
                            class="ma-0 pa-0"
                            @change="EditCheck()"
                            v-model="isEditCheck"
                            :hide-details="true"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="9" sm="9">{{$t('rechruserfunctionaccess.edit')}}</v-col>
                      </v-row>
                    </v-col>
                    <v-col class="brdAll text-center" cols="2" sm="1">
                      <v-row align="center">
                        <v-col cols="1" sm="1">
                          <v-checkbox
                            class="ma-0 pa-0"
                            @change="DeleteCheck()"
                            v-model="isDeleteCheck"
                            :hide-details="true"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="9" sm="9">{{$t('rechruserfunctionaccess.delete')}}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row style="padding-left: 12px">
                    <v-col
                      cols="12"
                      v-for="(items, idx) in formData.functionlist[key]"
                      :key="items.funcname"
                      style="padding-top: 0px; padding-bottom: 0px"
                    >
                      <v-row class="pt-3">
                        <v-col class="brdLeft brdBottom pb-4" cols="4" sm="4">{{
                          items.funcname
                        }}</v-col>
                        <v-col
                          class="brdLeft brdBottom text-center"
                          cols="2"
                          sm="1"
                        >
                          <v-checkbox
                            class="ma-0 pa-0"
                            v-model="
                              formData.functionlist[key][idx][
                                items.valuekey + '_access'
                              ]
                            "
                            @change="updateValue"
                            :hide-details="true"
                          ></v-checkbox
                        ></v-col>
                        <v-col
                          class="brdLeft brdBottom text-center"
                          cols="2"
                          sm="1"
                        >
                          <v-checkbox
                            class="ma-0 pa-0"
                            v-model="
                              formData.functionlist[key][idx][
                                items.valuekey + '_add'
                              ]
                            "
                            @change="updateValue"
                            :hide-details="true"
                          ></v-checkbox
                        ></v-col>
                        <v-col
                          class="brdLeft brdBottom text-center"
                          cols="2"
                          sm="1"
                        >
                          <v-checkbox
                            class="ma-0 pa-0"
                            v-model="
                              formData.functionlist[key][idx][
                                items.valuekey + '_edit'
                              ]
                            "
                            @change="updateValue"
                            :hide-details="true"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          class="brdLeft brdBottom brdRight text-center"
                          cols="2"
                          sm="1"
                        >
                          <v-checkbox
                            class="ma-0 pa-0"
                            v-model="
                              formData.functionlist[key][idx][
                                items.valuekey + '_delete'
                              ]
                            "
                            @change="updateValue"
                            :hide-details="true"
                          ></v-checkbox
                        ></v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-row>

            <!-- <v-row>
              <v-col class="brdLeft brdTop brdBottom" cols="4" sm="4"
                >Screen</v-col
              >
              <v-col
                class="brdLeft brdTop brdBottom text-center"
                cols="2"
                sm="1"
                >Access</v-col
              >
              <v-col
                class="brdLeft brdTop brdBottom text-center"
                cols="2"
                sm="1"
                >Add</v-col
              >
              <v-col
                class="brdLeft brdTop brdBottom text-center"
                cols="2"
                sm="1"
                >Edit</v-col
              >
              <v-col class="brdAll text-center" cols="2" sm="1">Delete</v-col>
            </v-row>
            <v-row v-for="key in FunctionKey" :key="key">
              <v-col
                class="brdBottom brdLeft brdRight"
                cols="12"
                sm="8"
                style="font-weight: bold; font-size: 14px"
              >
                {{ key }}
              </v-col>
              <v-col
                cols="12"
                v-for="(items, idx) in FunctionList[key]"
                :key="items.funcname"
                style="padding-top: 0px; padding-bottom: 0px"
              >
                <v-row>
                  <v-col class="brdLeft brdBottom" cols="4" sm="4">{{
                    items.funcname
                  }}</v-col>
                  <v-col class="brdLeft brdBottom text-center" cols="2" sm="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="
                        formData.functionlist[key][idx][
                          items.valuekey + '_access'
                        ]
                      "
                      @change="updateValue"
                      :hide-details="true"
                    ></v-checkbox
                  ></v-col>
                  <v-col class="brdLeft brdBottom text-center" cols="2" sm="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formData.ishidden"
                      @change="updateValue"
                      :hide-details="true"
                    ></v-checkbox
                  ></v-col>
                  <v-col class="brdLeft brdBottom text-center" cols="2" sm="1">
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formData.ishidden"
                      @change="updateValue"
                      :hide-details="true"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    class="brdLeft brdBottom brdRight text-center"
                    cols="2"
                    sm="1"
                  >
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="formData.ishidden"
                      @change="updateValue"
                      :hide-details="true"
                    ></v-checkbox
                  ></v-col>
                </v-row>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
        <!-- </v-container> -->
        <app-formbuttons
          style="margin-top: 15px"
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </form>
    </v-container>
  </div>
</template>
<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin: auto;
}
.v-input--selection-controls {
  margin-top: 0px;
}
.brdAll {
  border: 1px solid #cccccc;
}
.brdLeft {
  border-left: 1px solid #cccccc;
}
.brdRight {
  border-right: 1px solid #cccccc;
}
.brdTop {
  border-top: 1px solid #cccccc;
}
.brdBottom {
  border-bottom: 1px solid #cccccc;
}
</style>
<script>
// import GenericList from "../../generic/GridList";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    // "app-genericlist": GenericList,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      formData: {
        hrUserid: this.parentObject.data.hrUserid,
        userid: this.parentObject.data.userid,
        functionlist: this.parentObject.data.functionlist,
        functionkey: this.parentObject.data.functionkey,
        search: this.parentObject.data.search,
        lockstaff: this.parentObject.data.lockstaff,
        stamp: this.parentObject.data.stamp,
      },
      username: "",
      selected: [],

      secUser: [],
      secuserList: [],

      // FunctionKey: [],
      // FunctionList: [],

      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
      isCheckAll: false,
      isAccessCheck: false,
      isAddCheck: false,
      isEditCheck: false,
      isDeleteCheck: false,
      search: this.parentObject.searchnew
    };
  },
  computed: {
    filterSectionHeight() {
      const filterSectionDOM = document.getElementById("menu");
      return filterSectionDOM ? filterSectionDOM.offsetHeight - 100 : 0;
    },
  },
  methods: {
    loadSecUserListHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: "/secuser/all"})
        .then((res) => {
          vm.secUser = res.data.rec;
          console.log(res.data.rec);
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.secuserList.push({
              text: res.data.rec[i].userid,
              value: res.data.rec[i].id.toString(),
            });
          }
          if (vm.formData.hrUserid) {
            console.log(vm.formData.hrUserid);
            this.updateValue();
            vm.changeSecuserHandler(vm.formData.hrUserid);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeSecuserHandler(id) {
      let item = this.secUser.find((item) => item.id == id);
      console.log(item);
      this.username = item.psnname;
    },

    loadFunctionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/functionaccess/function"})
        .then((res) => {
          console.log(res);
          // vm.FunctionKey = res.data.rec.key;
          // vm.FunctionList = res.data.rec.value;

          if (!this.isEdit) {
            vm.formData.functionlist = res.data.rec.value;
            vm.formData.functionkey = res.data.rec.key;
          } else {
            //this.checkIsAllCheck();
            vm.changeSecuserHandler(vm.formData.hrUserid.toString());
          }
          this.updateValue();
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });

      this.updateValue();
    },
    CheckAll() {
      if (this.isCheckAll) {
        this.isCheckAll = false;
      } else {
        this.isCheckAll = true;
      }

      this.isAccessCheck = this.isCheckAll;
      this.isAddCheck = this.isCheckAll;
      this.isEditCheck = this.isCheckAll;
      this.isDeleteCheck = this.isCheckAll;

      for (var i = 0; i < this.formData.functionkey.length; i++) {
        console.log(this.formData.functionkey[i]);
        this.formData.functionlist[this.formData.functionkey[i]].forEach(
          (e) => {
            e[e.valuekey + "_access"] = this.isCheckAll;
            e[e.valuekey + "_add"] = this.isCheckAll;
            e[e.valuekey + "_edit"] = this.isCheckAll;
            e[e.valuekey + "_delete"] = this.isCheckAll;
          }
        );
      }
      this.updateValue();
    },
    AccessCheck() {
      for (var i = 0; i < this.formData.functionkey.length; i++) {
        this.formData.functionlist[this.formData.functionkey[i]].forEach(
          (e) => {
            e[e.valuekey + "_access"] = this.isAccessCheck;
          }
        );
      }
      this.updateValue();
    },
    AddCheck() {
      for (var i = 0; i < this.formData.functionkey.length; i++) {
        this.formData.functionlist[this.formData.functionkey[i]].forEach(
          (e) => {
            e[e.valuekey + "_add"] = this.isAddCheck;
          }
        );
      }
      this.updateValue();
    },
    EditCheck() {
      for (var i = 0; i < this.formData.functionkey.length; i++) {
        this.formData.functionlist[this.formData.functionkey[i]].forEach(
          (e) => {
            e[e.valuekey + "_edit"] = this.isEditCheck;
          }
        );
      }
      this.updateValue();
    },
    DeleteCheck() {
      for (var i = 0; i < this.formData.functionkey.length; i++) {
        this.formData.functionlist[this.formData.functionkey[i]].forEach(
          (e) => {
            e[e.valuekey + "_delete"] = this.isDeleteCheck;
          }
        );
      }
      this.updateValue();
    },
    checkIsAllCheck() {
      for (var i = 0; i < this.formData.functionkey.length; i++) {
        var access = this.formData.functionlist[
          this.formData.functionkey[i]
        ].filter((e) => {
          return e[e.valuekey + "_access"] == true;
        });
        if (
          access.length > 0 &&
          access.length ==
            this.formData.functionlist[this.formData.functionkey[i]].length
        ) {
          this.isAccessCheck = true;
        }

        var add = this.formData.functionlist[
          this.formData.functionkey[i]
        ].filter((e) => {
          return e[e.valuekey + "_add"] == true;
        });
        if (
          add.length > 0 &&
          add.length ==
            this.formData.functionlist[this.formData.functionkey[i]].length
        ) {
          this.isAddCheck = true;
        }

        var edit = this.formData.functionlist[
          this.formData.functionkey[i]
        ].filter((e) => {
          return e[e.valuekey + "_edit"] == true;
        });
        if (
          edit.length > 0 &&
          edit.length ==
            this.formData.functionlist[this.formData.functionkey[i]].length
        ) {
          this.isEditCheck = true;
        }

        var deleteacc = this.formData.functionlist[
          this.formData.functionkey[i]
        ].filter((e) => {
          return e[e.valuekey + "_delete"] == true;
        });
        if (
          deleteacc.length > 0 &&
          deleteacc.length ==
            this.formData.functionlist[this.formData.functionkey[i]].length
        ) {
          this.isDeleteCheck = true;
        }
      }
    },
    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "functionaccesslist",  params: { search: this.search} });
    },

    closeHandler() {
      this.isErr = !this.isErr;
      this.errMsg = "";
    },

    updateValue() {
      this.$emit("input", {
        hrUserid: this.formData.hrUserid,
        userid: this.formData.userid,
        functionlist: this.formData.functionlist,
        functionkey: this.formData.functionkey,
        search: this.formData.search,
        stamp: this.formData.stamp,
      //  lockstaff: this.formData.lockstaff,
        isAccessCheck: this.isAccessCheck,
        isAddCheck: this.isAddCheck,
        isEditCheck: this.isEditCheck,
        isDeleteCheck: this.isDeleteCheck,
      });
    },
  },

  mounted() {
    this.loadSecUserListHandler();
    this.loadFunctionListHandler();

      if (this.parentObject.isEdit){
             this.duplicateStaffData = this.formData;
            
             if (this.parentObject.data.lockstaff == "N") {
                 this.formData.lockstaff = false;
             } else {
                 this.formData.lockstaff = true;
             }
         }
  },
};
</script>