<template>
    <div>
        <div v-if="isConfirm">
            <app-confirm
                v-bind:parentObject="{
                messageConfirm: this.messageConfirm,
                isConfirm: this.isConfirm,
                }"
                v-on:confirm="verified($event)"
                v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!-- Setting up the layout of the form -->
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.account')"
                                id="accountcode"
                                type="text"
                                ref="accountcode"
                                dense
                                :rules="[rules.required]"
                                v-model="formData.accountcode"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.custid')"
                                id="custid"
                                type="text"
                                ref="custid"
                                dense
                                :rules="[rules.required]"
                                v-model="formData.custid"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.accountid')"
                                id="accountid"
                                type="text"
                                ref="accountid"
                                dense
                                :rules="[rules.required]"
                                v-model="formData.accountid"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                dense
                                ref="accountdesc"
                                v-model="formData.accountdesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- bank value will take the value from another DB table/-->
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupcompanybankaccount.bank')"
                                type="text"
                                ref="bankid"
                                :rules="[rules.required]"
                                hide-selected
                                :items="bankList"
                                v-model="formData.bankid"
                                @input="updateValue"
                                @change="changeBankHandler(formData.bankid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="bankCode"
                            ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="bankDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: bankItems,
                                            formTitle: 'Bank',
                                            formId: 'bank'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveBank="saveBankHandler"
                                        v-on:deleteBank="deleteBankHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="bankDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col> -->
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupreport_exportmapping.exportformat')"
                                type="text"
                                ref="expformatid"
                                hide-selected
                                :items="expformatList"
                                v-model="formData.expformatid"
                                @input="updateValue"
                                @change="changeExpformatHandler(formData.expformatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-autocomplete
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.expformatid"
                                :items="expformat2List"
                                append-icon=""
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="expformtaDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: expformatItems,
                                            formTitle: `${this.$t('setupreport_exportmapping.exportformat')}`,
                                            formId: 'expformat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveExpformat="saveExpformatHandler"
                                        v-on:deleteExpformat="deleteExpformatHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="expformtaDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.accountno')"
                                type="number"
                                ref="accountno"
                                :rules="[rules.required]"
                                class= "removeSpin"
                                dense
                                v-model="formData.accountno"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.officer')"
                                type="text"
                                ref="officer"
                                dense
                                v-model="formData.officer"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.telephone')"
                                type="number"
                                ref="tel"
                                dense
                                class="removeSpin"
                                v-model="formData.tel"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.email')"
                                type="text"
                                ref="email"
                                dense
                                v-model="formData.email"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline1')"
                                type="text"
                                dense
                                ref="addr1"
                                v-model="formData.addr1"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline2')"
                                type="text"
                                dense
                                ref="addr2"
                                v-model="formData.addr2"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline3')"
                                type="text"
                                dense
                                ref="addr3"
                                v-model="formData.addr3"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.city')"
                                type="text"
                                ref="cityid"
                                hide-selected
                                :items="cityList"
                                v-model="formData.cityid"
                                @input="updateValue"
                                @change="changeCityHandler(formData.cityid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="cityCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="cityDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: cityItems,
                                            formTitle: `${this.$t('columns.city')}`,
                                            formId: 'city'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveCity="saveCityHandler"
                                        v-on:deleteCity="deleteCityHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="cityDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.country')"
                                type="text"
                                ref="cntryid"
                                :items="countryList"
                                hide-selected
                                v-model="formData.cntryid"
                                @input="updateValue"
                                @change="changeCountryHandler(formData.cntryid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="countryCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="countryDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: countryItems,
                                            formTitle: `${this.$t('columns.country')}`,
                                            formId: 'country'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCountry="saveCountryHandler"
                                        v-on:deleteCountry="deleteCountryHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="countryDialog = false"
                                        >
                                             {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: currencyItems,
                                            formTitle: `${this.$t('columns.currency')}`,
                                            formId: 'currency'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCurrency="saveCurrencyHandler"
                                        v-on:deleteCurrency="deleteCurrencyHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="currencyDialog = false"
                                        >
                                             {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('intgsetup.intgconfiguration')"
                                type="text"
                                ref="intsettingid"
                                hide-selected
                                :items="intSettingList"
                                v-model="formData.intsettingid"
                                @input="updateValue"
                                @change="changeIntSettingHandler(formData.intsettingid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>       
                        <v-col class="pb-n5 mt-n8">
                            <v-btn color="blue darken-1" style="color: white; text-align: center; width: 150px" @click="onTest" >{{$t('button.check')}}</v-btn>        
                             <t v-if="isVerified" style="color: green; font-size: 20px; padding-left: 20px;">VERIFIED</t>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';
import Loading from "vue-loading-overlay";
import axios from "axios";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
        "app-error": ErrorDialog,
        "app-confirm": ConfirmDialog,
        Loading
    },
    
    data() {
        return {
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                    }
            },

            formData: {
                accountcode: this.parentObject.data.accountcode,
                accountdesc: this.parentObject.data.accountdesc,
                bankid: this.parentObject.data.bankid,
                expformatid: this.parentObject.data.expformatid,
                accountno: this.parentObject.data.accountno,
                officer: this.parentObject.data.officer,
                tel: this.parentObject.data.tel,
                email: this.parentObject.data.email,
                addr1: this.parentObject.data.addr1,
                addr2: this.parentObject.data.addr2,
                addr3: this.parentObject.data.addr3,
                cityid: this.parentObject.data.cityid,
                cntryid: this.parentObject.data.cntryid,
                crcyid: this.parentObject.data.crcyid,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
                custid: this.parentObject.data.custid,
                accountid: this.parentObject.data.accountid,
                intsettingid: this.parentObject.data.intsettingid,
            },

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            
            cityCode: '',
            countryCode: '',
            currencyCode: '',
            bankCode: '',
            expCode: '',

            countryList:[],
            cityList: [],
            currencyList: [],
            bankList: [],
            expformatList: [],
            expformat2List: [],
            intSettingList: [],

            intSetting: [],
            countries: [],
            cities: [],
            currencies: [],
            banks: [],
            expformats: [],

            cityItems: [],
            countryItems: [],
            currencyItems: [],
            bankItems: [],
            expformatItems: [],

            cityDialog: false,
            countryDialog: false,
            currencyDialog: false,
            bankDialog: false,
            expformtaDialog: false,

            cityLoading: true,
            countryLoading: true,
            currencyLoading: true,
            bankLoading: true,
            expformatLoading: true,

            errMsg: '',
            messageConfirm: null,
            isErr: false,
            isConfirm: false,
            isVerified: false,
            loading: false,
             search: this.parentObject.searchnew
            }
    
    },

    methods: {
        onTest() {
            const vm = this;
            this.loading = true;
            const intSet = vm.intSetting.find(e => e.id == vm.formData.intsettingid);            
            axios
            .post(`${intSet.destination}:${intSet.port}/authKey`, 
                { "customerCode": Number(vm.formData.custid), "serviceCode": vm.formData.accountid}, 
                { headers:{ "Content-Type": "application/json", "usr": intSet.username, "pwd": intSet.userpwd}}
            )
            .then((res) => {
                console.log(res);
                axios
                .post(`${intSet.destination}:${intSet.port}/bankInfo`, 
                    { "customerCode": Number(vm.formData.custid), "serviceCode": vm.formData.accountid}, 
                    { headers:{ "Content-Type": "application/json", authkey: res.data[0]? res.data[0].authKey: null}}
                )
                .then((res) => {
                    console.log(res);
                    this.loading = false;
                    if(res.data[0].customerCode){
                        this.isConfirm = true;
                        this.messageConfirm = `Bank configuration details are successfully retrieved as below:
Customer Code\t\t\t${res.data[0].customerCode}
Customer Name\t\t\t${res.data[0].customerName}
Service Code\t\t\t\t${res.data[0].serviceCode}
Bank Code\t\t\t\t\t${res.data[0].bankCode}
Bank Name\t\t\t\t\t${res.data[0].bankName}
Bank Account No\t\t${res.data[0].bankAccountNo}`;
                        
                    } else {
                        this.isErr = true;
                        this.errMsg = 
                        "Unable to bank information, please try again, or please check with your local transact2-HR provider for further assistance."
                
                    }
                        })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.isErr = true;
                    this.errMsg = 
                        "Unable to retrieve information, please try again, or please check with your local transact2-HR provider for further assistance."
                });
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.isErr = true;
                this.errMsg = 
                        `Unable to retrieve information, please try again, or please check with your local transact2-HR provider for further assistance.`
            });
        },
        verified() {
            this.isVerified = true;
        },

        // loading the content of city from the database, providing the name of the city on the dropdown box
        loadCityListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/city/all'})
                .then(res => {
                    vm.cities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.cityList.push(
                            {
                                text: res.data.rec[i].cityname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.cityItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].citycode,
                                desc: res.data.rec[i].cityname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    vm.cityLoading = false;

                    if (vm.formData.cityid) {
                        vm.changeCityHandler(vm.formData.cityid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        // loading the content of country from the database, providing the name of the country on the dropdown box
        loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        // loading the content of currency from the database, providing the name of the currency on the dropdown box
        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        // loading the content of bank from the database, providing the name of the bank on the dropdown box
        loadBankListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/bank/all'})
                .then(res => {
                    vm.banks = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankList.push(
                            {
                                text: res.data.rec[i].bankname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.bankItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].bankcode,
                                desc: res.data.rec[i].bankname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.bankLoading = false;

                    if (vm.formData.bankid) {
                        vm.changeBankHandler(vm.formData.bankid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        // loading the content of export format from the database, providing the name of the export format on the dropdown box
        loadExpformatListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/expformats/all'})
                .then(res => {
                    vm.expformats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.expformat2List.push(
                            {
                                text: res.data.rec[i].expcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.expformatList.push(
                            {
                                text: res.data.rec[i].expdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.expformatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].expcode,
                                desc: res.data.rec[i].expdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    

                    vm.expformatLoading = false;

                    if (vm.formData.expformatid) {
                        vm.changeExpformatHandler(vm.formData.expformatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //Change Handler is to show the items from the database that has been loaded

        changeCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.cityCode = item.citycode;
        },

        changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.countryCode = item.cntrycode;
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changeExpformatHandler(id) {
            this.expformats.find(item => item.id === id);

        },

        changeBankHandler(id) {
            let item = this.banks.find(item => item.id === id);
            
            this.bankCode = item.bankcode;
        },
        

        // Save Handler is for saving to the database when the user wants to add a new item

        saveCityHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/city/' + this.itemData.itemid, 
                    payload: {
                        citycode: this.itemData.itemcode,
                        cityname: this.itemData.itemdesc,    
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/city/create', 
                    payload: {
                        citycode: this.itemData.itemcode,
                        cityname: this.itemData.itemdesc,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCountryHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/country/' + this.itemData.itemid, 
                    payload: {
                        cntrycode: this.itemData.itemcode,
                        cntryname: this.itemData.itemdesc,stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/country/create', 
                    payload: {
                        cntrycode: this.itemData.itemcode,
                        cntryname: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/currency/' + this.itemData.itemid, 
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/currency/create',  
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveBankHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/bank/' + this.itemData.itemid, 
                    payload: {
                        bankcode: this.itemData.itemcode,
                        bankname: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/bank/create', 
                    payload: {
                        bankcode: this.itemData.itemcode,
                        bankname: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveExpformatHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/expformat/' + this.itemData.itemid, 
                    payload: {
                        expcode: this.itemData.itemcode,
                        expdesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.expformats = [];
                        vm.expformatList = [];
                        vm.expformatItems = [];

                        this.loadExpformatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/expformat/create', 
                    payload: {
                        expcode: this.itemData.itemcode,
                        expdesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.expformats = [];
                        vm.expformatList = [];
                        vm.expformatItems = [];

                        this.loadExpformatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for deleting items from the database

        deleteCityHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/city/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCountryHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/country/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCurrencyHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/currency/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        deleteBankHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/bank/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        deleteExpformatHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/expformat/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.expformats = [];
                        vm.expformatList = [];
                        vm.expformatItems = [];

                        this.loadExpformatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        updateValue() {
            this.$emit('input', {
                accountcode: this.formData.accountcode,
                accountdesc: this.formData.accountdesc,
                bankid: this.formData.bankid,
                expformatid: this.formData.expformatid,
                accountno: this.formData.accountno,
                officer: this.formData.officer,
                tel: this.formData.tel,
                email: this.formData.email,
                addr1: this.formData.addr1,
                addr2: this.formData.addr2,
                addr3: this.formData.addr3,
                cityid: this.formData.cityid,
                cntryid: this.formData.cntryid,
                crcyid: this.formData.crcyid,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
                custid: this.formData.custid,
                accountid: this.formData.accountid,
                intsettingid: this.formData.intsettingid,
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'bankaccountlist', params: { search: this.search}});
        },

        genCityTabItems() {
            for (let i = 0; i < this.cities.length; i++) {
                this.cityItems.push(
                    {
                        id: this.cities[i].id,
                        code: this.cities[i].citycode,
                        desc: this.cities[i].cityname,
                        stamp: this.cities[i].stamp
                    }
                )
            }
        },

        genCountryTabItems() {
            for (let i = 0; i < this.countries.length; i++) {
                this.countryItems.push(
                    {
                        id: this.countries[i].id,
                        code: this.countries[i].cntrycode,
                        desc: this.countries[i].cntryname,
                        stamp: this.countries[i].stamp
                    }
                )
            }
        },

        genCurrencyTabItems() {
            for (let i = 0; i < this.currencies.length; i++) {
                this.currencyItems.push(
                    {
                        id: this.currencies[i].id,
                        code: this.currencies[i].crcycode,
                        desc: this.currencies[i].crcydesc,
                        stamp: this.currencies[i].stamp
                    }
                )
            }
        },

        genBankTabItems() {
            for (let i = 0; i < this.banks.length; i++) {
                this.bankItems.push(
                    {
                        id: this.banks[i].id,
                        code: this.banks[i].bankcode,
                        desc: this.banks[i].bankname,
                        stamp: this.banks[i].stamp
                    }
                )
            }
        },

        genExpformatTabItems() {
            for (let i = 0; i < this.banks.length; i++) {
                this.bankItems.push(
                    {
                        id: this.expformats[i].id,
                        code: this.expformats[i].expcode,
                        desc: this.expformats[i].expdesc,
                        stamp: this.expformats[i].stamp
                    }
                )
            }
        },

        loadIntSettingListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/intgsetup/all'})
                .then(res => {
                    vm.intSetting = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.intSettingList.push(
                            {
                                text: res.data.rec[i].intgdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    vm.cityLoading = false;

                    if (vm.formData.intsettingid) {
                        vm.changeIntSettingHandler(vm.formData.intsettingid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        changeIntSettingHandler(id) {
            let item = this.intSetting.find(item => item.id === id);
            this.intgdesc = item.intgdesc;
        },

    },

    mounted() {
        this.loadCityListHandler();
        this.loadCountryListHandler();
        this.loadCurrencyListHandler();
        this.loadBankListHandler();
        this.loadExpformatListHandler();
        this.loadIntSettingListHandler();
    }
}
</script>


<style>
/* this part of the from is to only remvoe the spin button in type=number */
.removeSpin input[type='number'] {
    -moz-appearance:textfield;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>