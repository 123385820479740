<template>
    <div>
        <v-dialog v-model="paneldialog" max-width="900px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
           >+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">Panel Member Detail</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                     <v-row v-if="!this.lockstaff">
                      <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        label="Panel Member Name"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="selections[0].panelmemberid"
                        dense
                        :items="staffDetailsList"
                        @change="changeStaffIDandCompanyHandler(selections[0].panelmemberid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row>

                    <v-row v-else>
                      <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        label="Panel Member Name"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="selections[0].panelmemberid"
                        dense
                        :items="staffDetailsList"
                        @change="changeStaffIDandCompanyHandler(selections[0].panelmemberid)"
                        clearable
                        
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5 mt-n6" align="center">
                        <v-avatar size="40" tile>
                            <img
                                :src="`${publicPath}images/print_letter.png`"
                                @click="isPrint = !isPrint"
                                 />
                        </v-avatar>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Panel Member Code"
                                type="text"
                                :rules="[rules.required]"
                                v-model="selections[0].panelmembercode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Company"
                                type="text"
                                ref="compnyid"
                                v-model="selections[0].compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Panel Role"
                                type="text"
                                ref="panelroleid"
                                clearable
                                :items="panelroleList"
                                hide-selected
                                v-model="selections[0].panelroleid"
                                @change="changePanelRoleHandler(selections[0].panelroleid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                         <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                label="Code"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="selections[0].irdomesticpanelrolecode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="panelroleDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: panelroleItems,
                                            formTitle: 'Panel Roles',
                                            formId: 'panelrole'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:savePanelRole="savePanelRoleHandler"
                                        v-on:deletePanelRole="deletePanelRoleHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="panelroleDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col> 
                    </v-row> 


                     <v-row>
                         <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            label="Panel Acceptance"
                            type="text"
                            ref="panelacceptanceid"
                            clearable
                            :rules="[rules.required]"
                            v-model="selections[0].panelacceptanceid"
                            dense
                            :items="panelacceptanceList"
                            @change="changePanelAcceptanceHandler(selections[0].panelacceptanceid)"
                        ></v-autocomplete>
                        </v-col> 
                     </v-row> 



                    <v-row>
                         <v-col cols="8" sm="10" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Remarks"
                                type="text"
                                ref="compnyid"
                                v-model="selections[0].remarks"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closePanelInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="savePanelInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isPrint">
            <app-print
            v-bind:parentObject="{
                
                isPrint: this.isPrint,
                dimaintenanceid: this.selections[0].dimaintenanceid,
            }"
            v-on:print="printHandler($event)"
            v-on:cancelPrint="isPrint = !isPrint"
            />
      </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";
import PrintDialog from "../../../components/common/PrintDialog";
import jsPDF from "jspdf";

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
       "app-error": ErrorDialog,
       "app-print": PrintDialog,
    },

    data() {
        return {

            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            paneldialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            lockstaff: this.parentObject.isedit,
            selections: [{
                id: null,
                dimaintenanceid: null,
                panelmemberid: null,
                psnid: null,
                panelmembername: null,
                panelmembercode: null,
                compnyid: null,
                compnyname: null,
                panelroleid: null,
                irdomesticpanelroledesc: null,
                irdomesticpanelrolecode: null,
                panelacceptanceid: null,
                acceptancedesc: null,
                remarks: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],
            

            itemHolder: [],

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
                     
            irdomesticpanelrolecode: '',
            panelroleList: [],
            panelroles: [],
            panelroleItems: [],
            panelroleDialog: false,
            panelroleLoading: true,

            panelacceptanceList: [],
            panelacceptanceItems: [],
            panelacceptanceLoading: true,

            coverageType: [],
            coverageTypeList: [],
            coverageTypeLoading: true,

            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            
            //error msg and dialog, check for duplicates vars
            showdata: false,
            isExisting: false,
            isErr: false,
            errMsg: '',
            isPrint: false,
        }
    },

    methods: {
        compareArray,


        async printHandler(id) {
            const vm = this;
            console.log('abc')
            vm.isPrint = !vm.isPrint;
            let showDate = new Date().toISOString().slice(0,10);
            let res = await this.$store.dispatch('get', {url: "/letter/" + id});       
            if (res.status == 200) {
                const pageCnt = res.data.rec[0].detail.length;
                const doc = new jsPDF('p', 'pt', 'letter');
                doc.canvas.height = 72 * 11;
                doc.canvas.width = 72 * 8.5;
                
                for(let i = 0; i < pageCnt; i ++) {
                    let e = res.data.rec[0].detail[i];
                    let cntnt = e.content;
                    cntnt = cntnt.replaceAll(`{{CURDATE()}}`, showDate);
                    Object.keys(vm.selections[0]).forEach( key => {
                        cntnt = cntnt.replaceAll(`{{${key}}}`, vm.selections[0][key] ? vm.selections[0][key] : '');
                    });
                    await new Promise((resolve) => {
                        doc.fromHTML(cntnt, 50, 30, {
                            'width': 520,
                        }, async function(){
                            if (pageCnt > e.pagenumber){
                            doc.addPage();
                            } else {
                                doc.output('dataurlnewwindow');
                            }
                            return resolve();
                        })
                    })
                }
            } else {
                vm.isErr = !vm.isErr;
            }
        },

           //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            console.log('lockstaff', this.lockstaff)

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {

            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and panelmemberid to panelmemberid
            this.selections[0].psnid = item.psnid;
            this.selections[0].panelmemberid = item.id;
            this.selections[0].panelmembercode = item.staffcode;
            this.selections[0].compnyid = item.compnyid;
            this.selections[0].compnyname = item.compnyname;
            this.selections[0].panelmembername = item.psnname;
        },

    loadPanelAcceptanceListHandler() {
        const vm = this;

            this.$store.dispatch('get', {
                url: '/irpanelacceptance/all'
            })
                .then(res => {
                    vm.panelacceptanceItems = res.data.rec;

                        for (let i = 0; i < res.data.rec.length; i++) {
                    
                    if (res.data.rec[i].acceptancedesc === 'ACCEPTED' && 
                       !vm.selections[0].panelacceptanceid ) 
                         {
                             this.selections[0].panelacceptanceid = res.data.rec[i].id;
                         }
                        vm.panelacceptanceList.push(
                            {
                                text: res.data.rec[i].acceptancedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.panelacceptanceLoading = false;

                    if (vm.selections[0].panelacceptanceid) {
                        vm.changePanelAcceptanceHandler(vm.selections[0].panelacceptanceid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePanelAcceptanceHandler(id) {
            let item = this.panelacceptanceItems.find(item => item.id === id);

            this.selections[0].panelacceptanceid = item.id;
            this.selections[0].acceptancedesc = item.acceptancedesc;
        },

          loadPanelRoleListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/irdomesticpanel/all'
            })
                .then(res => {
                    vm.panelroles = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.panelroleList.push(
                            {
                                text: res.data.rec[i].irdomesticpanelroledesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.panelroleItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].irdomesticpanelrolecode,
                                desc: res.data.rec[i].irdomesticpanelroledesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.panelroleLoading = false;

                    if (vm.selections[0].panelroleid) {
                        vm.changePanelRoleHandler(vm.selections[0].panelroleid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changePanelRoleHandler(id) {
            let item = this.panelroles.find(item => item.id === id);

            this.selections[0].irdomesticpanelrolecode = item.irdomesticpanelrolecode;
            this.selections[0].irdomesticpanelroledesc = item.irdomesticpanelroledesc; 
        },

         //save handler is for if the user wants to save a new item into the database
        savePanelRoleHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/irdomesticpanel/' + this.itemData.itemid, 
                    payload: {
                        irdomesticpanelrolecode: this.itemData.itemcode,
                        irdomesticpanelroledesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.selections[0].userid,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.panelroles = [];
                        vm.panelroleItems = [];
                        vm.panelroleList = [];

                        this.loadPanelRoleListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/irdomesticpanel/create', 
                    payload: {
                        irdomesticpanelrolecode: this.itemData.itemcode,
                        irdomesticpanelroledesc: this.itemData.itemdesc,
                        userid: this.selections[0].userid,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.panelroles = [];
                        vm.panelroleItems = [];
                        vm.panelroleList = [];

                        this.loadPanelRoleListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deletePanelRoleHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/irdomesticpanel/' + $event.id + '/' + this.selections[0].userid
                })
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.panelroles = [];
                        vm.panelroleList = [];
                        vm.panelroleItems = [];

                        this.loadPanelRoleListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

          genInvolvementLevelTabItems() {
            for (let i = 0; i < this.panelroles.length; i++) {
                this.panelroleItems.push(
                    {
                        id: this.panelroles[i].id,
                        code: this.panelroles[i].irdomesticpanelrolecode,
                        desc: this.panelroles[i].irdomesticpanelroledesc,
                        stamp: this.panelroles[i].stamp
                    }
                )
            }
        },

      
       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.paneldialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        savePanelInput() {

            console.log('detialitemm', this.detailItem)
            
               if (this.selections[0].panelmemberid == null || this.selections[0].panelroleid == null){

               this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                  console.log(dialog);
               });
              } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                
                console.log('detailitem', this.detailItem)
                this.detailItem.push(Object.assign({}, this.selections[0]));
                console.log('detailitem2', this.detailItem)
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.paneldialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        closePanelInput() {
            this.paneldialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                dimaintenanceid: null,
                panelmemberid: null,
                psnid: null,
                panelmembername: null,
                panelmembercode: null,
                compnyid: null,
                compnyname: null,
                panelroleid: null,
                irdomesticpanelroledesc: null,
                irdomesticpanelrolecode: null,
                panelacceptanceid: null,
                acceptancedesc: null,
                remarks: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {
        this.loadStaffDetailsListHandler();
        this.loadPanelRoleListHandler();
       this.loadPanelAcceptanceListHandler();
  
    },

    mounted(){
    if (this.lockstaff){
            this.duplicateStaffData = this.formData;
            
            if (this.lockstaff == "N") {
                this.showdata = false;
            } else {
                this.showdata = true;
            }
        }
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>