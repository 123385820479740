<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >


                    <v-row>
                      <v-col cols="4" sm="4" class="pb-n4">
                                <v-autocomplete
                                    outlined
                                    label="Select Case ID"
                                    type="text"
                                    ref="ircaseid"
                                    :rules="[rules.required]"
                                    v-model="formData.ircaseid"
                                    dense
                                    :items="caseIDList"
                                    @input="updateValue"
                                    @change="changeIRCaseHandler(formData.ircaseid)"
                                ></v-autocomplete>
                            </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentshortdesc')"
                                type="text"
                                v-model="formData.shortdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentdate')"
                                type="text"
                                ref="incidentdate"
                                v-model="formData.incidentdate"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="4" sm="10" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Staff Name"
                                type="text"
                                ref="staffid"
                                :items="staffList"
                                hide-selected
                                v-model="formData.staffid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                     </v-row>

                      <v-row>
                      <v-col cols="4" sm="10" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                label="Session Description"
                                type="text"
                                ref="sessiondesc"
                                :rules="[rules.required]"
                                v-model="formData.sessiondesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="4" sm="3" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                label="Date"
                                type="date"
                                ref="didate"
                                :rules="[rules.required]"
                                v-model="formData.didate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                   
                 <v-row>
                        <v-col cols="4" sm="3" class="pb-n4 mt-n8 ">
                        <vue-timepicker
                            auto-scroll
                            drop-direction="down"
                            v-model="formData.ditime"
                            format="HH:mm"
                            placeholder="Domestic Inquiry Time"
                            input-class="time-picker"
                            :minute-interval="15"
                            close-on-complete
                            @input="ditime"
                            input-width="100%"
                            outlined
                        ></vue-timepicker>   
                        </v-col>
                    </v-row>
                    <br> <br> 

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-8 mt-n5">
                            <v-autocomplete
                                outlined
                                label="Venue"
                                type="text"
                                ref="venueid"
                                :items="venueList"
                                hide-selected
                                v-model="formData.venueid"
                                @input="updateValue"
                                @change="changeVenueHandler(formData.venueid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-8 mt-n5">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="irdomesticvenuecode"
                            ></v-text-field>
                        </v-col> 

                         <v-col cols="2" sm="2" class="pb-8 mt-n5">
                            <v-dialog v-model="venueDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: venueItems,
                                            formTitle: `Venue List`,
                                            formId: 'venue'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveDomesticVenue="saveDomesticVenueHandler"
                                        v-on:deleteDomesticVenue="deleteDomesticVenueHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="venueDialog = false"
                                        >
                                            {{$t('button.close')}} 
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row> 

                     <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                             Panel Member Details
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.panelmemberdetail,
                                isedit : this.parentObject.isEdit
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';
import VueTimepicker from "vue2-timepicker";
import moment from "moment";

export default {
    props: {
        parentObject: Object,
        timeHolder: Object
    },

    components: {
       'app-genericform': GenericForm,
        'app-table': CRUDTable,
        "vue-timepicker": VueTimepicker,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                ircaseid: this.parentObject.data.ircaseid,
                incidentdate: this.parentObject.data.incidentdate,
                shortdesc: this.parentObject.data.shortdesc,
                longdesc: this.parentObject.data.longdesc,
                staffid: this.parentObject.data.staffid,
                sessiondesc: this.parentObject.data.sessiondesc,
                ditime: this.parentObject.data.ditime,
                didate: this.parentObject.data.didate,
                venueid: this.parentObject.data.venueid,
                panelmemberdetail: this.parentObject.data.panelmemberdetail,
                search: this.parentObject.data.search,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            itemHolder: [],

          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            //ditimeHolder: this.timeHolder.ditime,

            irdomesticvenuecode: '',
            venueList: [],
            venue: [],
            venueItems: [],
            venueDialog: false,
            venueLoading: true,

            caseIDItems: [],
            caseIDList: [],

            staffItems: [],
            staffList: [],
       
       
            search: this.parentObject.searchnew,
          
    

            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,

              tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: 'Panel Member Name', value: 'panelmembername', width: '200px', sortable: true},
                { text: 'Panel Member Role', value: 'irdomesticpanelroledesc', width: '200px', sortable: true},
                { text: 'Panel Member Acceptance', value: 'acceptancedesc', width: '240px', sortable: true},
                { text: 'Remark', value: 'remarks', width: '200px', sortable: true},
              
            ],

        }
    },

    methods: {

        addItemHandler($event) {
            this.formData.panelmemberdetail=$event
    
            this.updateValue();
        },

          timeConversion(val){
                 let timeHolder = ""
                 timeHolder = val.HH + ':' + val.mm
                 return timeHolder

         },

           ditime(){


              if(this.formData.ditime.HH && this.formData.ditime.mm){
                  this.formData.ditime = this.timeConversion(this.formData.ditime)
              }

              this.updateValue();
          },

        loadCaseID() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/ircasemaintenance/all'
            })
            .then(res => {

                vm.caseIDItems = res.data.rec;

                for (let i = 0; i < vm.caseIDItems.length; i++) {
                    vm.caseIDList.push({
                        text: vm.caseIDItems[i].caseid,
                        value: vm.caseIDItems[i].id,
                    });
                }

                if (this.formData.ircaseid) {
                this.changeIRCaseHandler(this.formData.ircaseid);
                }

                

            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeIRCaseHandler(id) {
            var item = this.caseIDItems.find(item => item.id === id);
            this.formData.shortdesc = item.shortdesc;
            this.formData.incidentdate = moment(item.incidentdate).format("DD-MM-YYYY");
            this.formData.longdesc = item.longdesc;
         //   this.formData.ircaseid = item.caseid;
           
           this.loadCaseStaffList();

        },


        loadCaseStaffList() {
            const vm = this;
            console.log('abc')
            this.$store.dispatch('get', {
                url: '/irstafflist/' + this.formData.ircaseid
            })
            .then(res => {
                
                vm.staffItems = res.data.rec;
                vm.staffList = [];
                console.log('vm.staffitems', vm.staffItems)

                for (let i = 0; i < vm.staffItems.length; i++) {
                    vm.staffList.push({
                        text: vm.staffItems[i].staffname,
                        value: vm.staffItems[i].staffid,
                    });
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadVenueListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/irdomesticvenue/all'})
                .then(res => {
                    vm.venue = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.venueList.push(
                            {
                                text: res.data.rec[i].irdomesticvenuedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.venueItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].irdomesticvenuecode,
                                desc: res.data.rec[i].irdomesticvenuedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.venueLoading = false;

                    if (vm.formData.venueid) {
                        vm.changeVenueHandler(vm.formData.venueid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeVenueHandler(id) {
            let item = this.venue.find(item => item.id === id);

            this.irdomesticvenuecode = item.irdomesticvenuecode;
        },

          saveDomesticVenueHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url:  '/irdomesticvenue/' + this.itemData.itemid, 
                    payload: {
                        irdomesticvenuecode: this.itemData.itemcode,
                        irdomesticvenuedesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid,
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.venue = [];
                        vm.venueItems = [];
                        vm.venueList = [];

                        this.loadVenueListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url:  '/irdomesticvenue/create', 
                    payload: {
                        irdomesticvenuecode: this.itemData.itemcode,
                        irdomesticvenuedesc: this.itemData.itemdesc,
                        userid: this.formData.userid,
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.venue = [];
                        vm.venueItems = [];
                        vm.venueList = [];

                        this.loadVenueListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteDomesticVenueHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url:  '/irdomesticvenue/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.venue = [];
                        vm.venueList = [];
                        vm.venueItems = [];

                        this.loadVenueListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genInsuranceProviderTabItems() {
            for (let i = 0; i < this.venue.length; i++) {
                this.venueItems.push(
                    {
                        id: this.venue[i].id,
                        code: this.venue[i].irdomesticvenuecode,
                        desc: this.venue[i].irdomesticvenuedesc,
                        stamp: this.venue[i].stamp
                    }
                )
            }
        },


        updateValue() {
            this.$emit('input', {
                ircaseid: this.formData.ircaseid,
                incidentdate: this.formData.incidentdate,
                shortdesc: this.formData.shortdesc,
                longdesc: this.formData.longdesc,
                staffid: this.formData.staffid,
                sessiondesc: this.formData.sessiondesc,
                ditime: this.formData.ditime,
                didate: this.formData.didate,
                venueid: this.formData.venueid,
                panelmemberdetail: this.formData.panelmemberdetail,
                lockstaff: this.formData.lockstaff,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

            console.log('formData', this.formData)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'domesticinquirylist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadVenueListHandler();
      //  this.loadExportFormatList();
        this.loadCaseID();

         

    }
    
}
</script>