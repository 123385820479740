<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{this.parentObject.pageTitle}}<span class="page_title_value">{{this.parentObject.pageTitleValue}}</span></h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row v-if="!this.isEdit">
                        <v-col cols="10" sm="10" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                @input="updateValue"
                                :items="staffDetailsList"
                                @change="changeStaffIDandCompanyHandler(formData.staffid)"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="10" sm="10" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                :items="staffDetailsList"
                                readonly
                                background-color="#ffeab2"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffcode')"
                                type="text"
                                ref="staffid"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                @input="updateValue"
                                readonly
                                background-color="#ffeab2"
                            >
                            </v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyid"
                                dense
                                @input="updateValue"
                                :items="companiesitemsList"
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('recassetrec.assettype')"
                                type="text"
                                ref="assetdesc"
                                :rules="[rules.required]"
                                dense
                                @input="updateValue"
                                :items="assetTypeList"
                                @change="changeAssetTypeHandler(formData.assetid)"
                                v-model="formData.assetid"
                            >
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="assetType"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('recassetrec.unitno')"
                                type="text"
                                :items="unitNoList"
                                dense
                                @input="updateValue"
                                @change="changeSerialNoHandler(formData.assetdid)"
                                v-model="formData.assetdid"
                            >
                            </v-autocomplete>
                        </v-col>

                        <v-col v-if="lockqty" cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('recassetrec.quantity')"
                                type="number"
                                :rules="[rules.required]"
                                outlined
                                v-model="formData.qty"
                                readonly
                                background-color="#ffeab2"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="!lockqty" cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('recassetrec.quantity')"
                                type="number"
                                :rules="[rules.required]"
                                outlined
                                v-model="formData.qty"
                                @input="updateValue"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('recassetrec.serialno')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="serialNo"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.description')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="assetItemDesc"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetassignmentrec.aasigndate')"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.assigndate"
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="8" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recassetassignmentrec.returndate')"
                                type="date"
                                v-model="formData.returndate"
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="8" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('recassetassignmentrec.returncondition')"
                                type="text"
                                ref="returnconditionid"
                                :items="returnConditionList"
                                dense
                                @input="updateValue"
                                @change="changeReturnConditionHandler(formData.returnconditionid)"
                                v-model="formData.returnconditionid"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="returnConditionDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: returnConditionItems,
                                            formTitle: `${this.$t('recassetassignmentrec.returncondition')}`,
                                            formId: 'returncondition'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveReturnCondition="saveReturnConditionHandler"
                                        v-on:deleteReturnCondition="deleteReturnConditionHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="returnConditionDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('recassetassignmentrec.returnconditioncode')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="returnCondition"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.status')"
                                type="text"
                                ref="assigncasestatusid"
                                :rules="[rules.required]"
                                :items="assignCaseStatusList"
                                dense
                                @input="updateValue"
                                v-model="formData.assigncasestatusid"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                                rows="1"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')},
                number: value => value > -0.01 || this.$t('reportsparam.invalidnumber')
            },

            formData: {
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                assetid: this.parentObject.data.assetid,
                assettype: this.parentObject.data.assettype,
                assetdesc: this.parentObject.data.assetdesc,
                assetdid: this.parentObject.data.assetdid,
                unitno: this.parentObject.data.unitno,
                serialno: this.parentObject.data.serialno,
                qty: this.parentObject.data.qty,
                assetitemdesc: this.parentObject.data.assetitemdesc,
                assigndate: this.parentObject.data.assigndate,
                returndate: this.parentObject.data.returndate,
                returnconditionid: this.parentObject.data.returnconditionid,
                assetcondition: this.parentObject.data.assetcondition,
                assigncasestatus: this.parentObject.data.assigncasestatus,
                assigncasestatusid: this.parentObject.data.assigncasestatusid,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,

                //additional line
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            
            staffdetails: [],
            staffDetailsList: [],
            companiesitemsList: [],

            assetTypes: [],
            assetTypeList: [],
            assetType: '',

            unitNos: [],
            unitNoList: [],
            serialNo: '',
            assetItemDesc: '',

            returnConditions: [],
            returnConditionList: [],
            returnConditionItems: [],
            returnCondition: '',

            assignCaseStatuses: [],
            assignCaseStatusList: [],

            pageTitleValue: this.parentObject.pageTitleValue,
            isEdit: this.parentObject.isEdit,
            errMsg: '',
            isErr: false,
            availableQty: '',
            activeAssignment: '',
            returnConditionDialog: false,
            lockqty: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        // staff

        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.staffdetails = res.data.rec;
                    this.onChange();

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set staffid to staffid
            this.formData.staffcode = item.staffcode;
            this.formData.staffid = item.id;
            this.formData.psnname = item.psnname;
        
            //set compnyid to compnyid of item
            for (let i = 0; i < this.companiesitemsList.length; i++) {
                if (item.compnyid == this.companiesitemsList[i].value) {
            
                    this.formData.compnyid = this.companiesitemsList[i].value;
                }
                else {
                    //do nothing
                }
            }
        },

        loadAssetTypeHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assets2/all"})
                .then((res) => {
                    vm.assetTypes = res.data.rec;

                    for (let i = 0; i < vm.assetTypes.length; i++) {

                        let availableAssetY = [];
                        let assignedAssetN = [];
                        let availableAssetN = [];

                        if (vm.assetTypes[i].trackdetails == "Y") {
                            availableAssetY = vm.availableQty.filter(val => {
                                return val.assetid == vm.assetTypes[i].id && val.assetstatusid == 1});
                        }
                
                        if (vm.assetTypes[i].trackdetails == "N") {
                            assignedAssetN = vm.activeAssignment.filter(val => {
                                return val.assetid == vm.assetTypes[i].id});
                            
                            availableAssetN = vm.assetTypes[i].qty - assignedAssetN.length;
                        }

                        if (availableAssetY.length > 0 || availableAssetN > 0 || vm.formData.assetid == vm.assetTypes[i].id) {
                            vm.assetTypeList.push({
                                text: vm.assetTypes[i].assetdesc,
                                value: vm.assetTypes[i].id,
                            });
                        }
                    }
                    this.loadUnitNoListHandler();
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeAssetTypeHandler(id) {
            let unitList = [];
            this.unitNoList = [];
            let item = this.assetTypes.find(item => item.id == id);

            this.assetType = item.assettype;
            unitList = this.unitNos.filter(item => item.assetid == id);

            for (let i = 0; i < unitList.length; i++) {
                if (unitList[i].assetstatusid == 1 || unitList[i].id == this.formData.assetdid) {
                    this.unitNoList.push({
                        text: unitList[i].unitno,
                        value: unitList[i].id,
                    });
                }
            }

            // unlock the qty text field if user selects an asset without details
            this.lockqty = false;
        },

        loadUnitNoListHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assetd2/all"})
                .then((res) => {
                    vm.unitNos = res.data.rec;

                    if (vm.formData.assetid) {
                        vm.changeAssetTypeHandler(vm.formData.assetid);
                    }

                    if (vm.formData.assetdid) {
                        vm.changeSerialNoHandler(vm.formData.assetdid);
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeSerialNoHandler(id) {
            let item = this.unitNos.find(item => item.id == id);

            this.serialNo = item.serialno;
            this.assetItemDesc = item.assetitemdesc;

            // set qty to 1 if user selects an asset that has details
            this.formData.qty = 1;
            this.lockqty = true;

        },

        loadReturnConditionListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/assetassignment/returncondition'})
                .then(res => {
                    vm.returnConditions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.returnConditionList.push(
                            {
                                text: res.data.rec[i].assetconditiondesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.returnConditionItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].assetcondition,
                                desc: res.data.rec[i].assetconditiondesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.returnconditionid) {
                        vm.changeReturnConditionHandler(vm.formData.returnconditionid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveReturnConditionHandler() {
            let vm = this;
            
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/assetassignment/returncondition/' + this.itemData.itemid, 
                payload: {
                    assetcondition: vm.itemData.itemcode,
                    assetconditiondesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.returnConditions = [],
                        vm.returnConditionList = [],
                        vm.returnConditionItems = [],

                        this.loadReturnConditionListHandler();
                    } 
                    else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;    
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } 
            else {
                this.$store.dispatch('post', {url: '/assetassignment/returncondition/create', 
                payload: {
                    assetcondition: vm.itemData.itemcode,
                    assetconditiondesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.returnConditions = [],
                        vm.returnConditionList = [],
                        vm.returnConditionItems = [],

                        this.loadReturnConditionListHandler();
                    } 
                    else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }

                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteReturnConditionHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/assetassignment/returncondition/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.returnConditions = [],
                        vm.returnConditionList = [],
                        vm.returnConditionItems = [],

                        this.loadReturnConditionListHandler();
                    } 
                    else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        changeReturnConditionHandler(id) {
            let item = this.returnConditions.find(item => item.id == id);

            this.returnCondition = item.assetcondition;
        },

        loadAssignCaseStatusHandler() {
            const vm = this;
          
            this.$store.dispatch('get', {url: "/assetassignment/assigncasestatuses/all"})
                .then((res) => {
                    vm.assignCaseStatuses = res.data.rec;

                    for (let i = 0; i < vm.assignCaseStatuses.length; i++) {
                        vm.assignCaseStatusList.push({
                            text: vm.assignCaseStatuses[i].assigncasestatus,
                            value: vm.assignCaseStatuses[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },   

        loadAvailableQtyHandler() {
            const vm = this;
      
            this.$store.dispatch('get', {url: "/assetd2/all"})
                .then((res) => {
                    vm.availableQty = res.data.rec;

                    vm.loadAssetTypeHandler(vm.availableQty);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadActiveAssignmentHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/assetassignment3/all"})
                .then((res) => {
                    vm.activeAssignment = res.data.rec;

                    vm.loadAssetTypeHandler(vm.activeAssignment); 
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                assetid: this.formData.assetid,
                assettype: this.formData.assettype,
                assetdesc: this.formData.assetdesc,
                assetdid: this.formData.assetdid,
                unitno: this.formData.unitno,
                assigndate: this.formData.assigndate,
                returndate: this.formData.returndate,
                qty: this.formData.qty,
                returnconditionid: this.formData.returnconditionid,
                assetcondition: this.formData.assetcondition,
                assigncasestatus: this.formData.assigncasestatus,
                assigncasestatusid: this.formData.assigncasestatusid,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
            });
        },

        newHandler() {
            this.$router.push({name: 'assetassignmentnew'});
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.updateValue()
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'assetassignmentlist', params: { search: this.search}});
        },

        onChange() {
            if (this.isEdit) {
                let item = this.staffdetails.find(item => item.staffcode === this.formData.staffcode);
            
                this.formData.staffid = item.id;
            }
        },
    },

    mounted() {
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
        this.loadReturnConditionListHandler();
        this.loadAssignCaseStatusHandler();
        this.loadAvailableQtyHandler();
        this.loadActiveAssignmentHandler();
    }
}
</script>