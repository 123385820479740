<template>
    <div>
        <v-dialog v-model="holidaydialog" max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline mb-3">{{$t('setupholidaysetup.holidaydate')}}</span>
                    <!--<span class="headline mb-3">Holiday Date</span>-->
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupholidaysetup.holidaydate')"
                                    type="date" 
                                    dense                                   
                                    v-model="selections.holdate"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            
                        </v-row>
                    </v-container>
                </v-card-text>

                

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeHolidayInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveHolidayInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            class="elavation-1"
            :items="detailItem"
            fixed-header
            :sort-by="['code']"
            
            >
        <template v-slot:body>
            <tbody>
                <tr v-for="item in detailItem" :key="item">
                <td>
                  <v-icon 
                  small class="mr-2" 
                  @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
                </td>
                    <td> {{ item.holdate }} </td>

                </tr>  
            </tbody>
        </template>
        </v-data-table>
    
    </div>
</template>

<script>


import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
    },

    data() {
        return {
            holidaydialog: false,
            detailItem: this.parentObject.detailItem,
        
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
             },


            selections: [
                {
                holid: null,
                holdate: null,               
                rectimestamp: null,
                updatedby: null,
                action:0 , 
                stamp: 0,
                
                }
            ],

            itemHolder: [],
            detailList: [],

            holdate: [],

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }

        
    },

    methods: {
        compareArray,


        modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

            this.itemHolder = item
            this.selections =  this.itemHolder;

            this.holidaydialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveHolidayInput() {

             if (this.selections.holdate == null){

              this.$dialog.alert(this.$t('fillintheholidaydatecolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {

           this.isErr = false;
           
            if(!this.isExisting && this.modify){
               this.resetSelections();
           } else if (!this.isExisting) {
                this.selections.id= null;
                this.selections.action = 1;
                console.log(this.selections)
                // console.log(this.detailItem)
                this.detailItem.push(Object.assign({}, this.selections));
                // console.log(this.detailItem)
            } else {
                if (this.compareArray(this.detailItem, this.selections)) {
                    console.log(this.compareArray(this.detailItem, this.selections))
                }
                
            }
            // console.log(this.detailItem)
            this.$emit('input', this.detailItem);
            this.holidaydialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
        },

        closeHolidayInput() {
            this.holidaydialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections.id = null;
                this.selections.action = 1;

                this.detailItem.push(Object.assign({}, this.selections));
            } else {
                if (this.compareArray(this.detailItem, this.selections)) {
                    console.log(this.compareArray(this.detailItem, this.selections))
                }
                
            }
            
            this.$emit('input', this.detailItem);

            this.holidaydialog = false;

            // reset the form fields
            this.resetSelections();
        
        },
        
        resetSelections() {
            this.selections = [{
                holid: null,
                holdate: null,
                rectimestamp: null,
                updatedby: null,
                action: 0
            }]
        },

    },

    mounted() {
        //this.loadScreenLinkHandler();
        //this.seqnoHandler();
    }
}
</script>