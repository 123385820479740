<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('columns.fromdate')"
              type="date"
              ref="fromdate"
              :rules="[rules.required]"
              v-model="formData.fromdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('columns.untildate')"
              type="date"
              ref="untildate"
              :rules="[rules.required]"
              v-model="formData.untildate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-btn
              color="#fd9941"
              style="color: white; text-align: center"
              v-on:click="loadDataHandler()"
            >
              <v-icon small> mdi-reload </v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n8">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="blue--text"
                  >{{$t('reportsparam.filtercriteria')}}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row class="mt-5">
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.company')"
                        type="text"
                        ref="company"
                        v-model="formData.company"
                        dense
                        :items="companyList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.division')"
                        type="text"
                        ref="division"
                        v-model="formData.division"
                        dense
                        :items="divisionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('periodicincentivesetup.workdaygroup')" 
                        type="text"
                        ref="workdaygroup"
                        v-model="formData.workdaygroup"
                        dense
                        :items="workdaygrouplist"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.branch')"
                        type="text"
                        ref="description"
                        v-model="formData.branch"
                        dense
                        :items="branchList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.section')"
                        type="text"
                        ref="section"
                        v-model="formData.section"
                        dense
                        :items="sectionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.employmenttype')"
                        type="text"
                        ref="employmentType"
                        v-model="formData.employmentType"
                        dense
                        :items="emptypeList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.department')"
                        type="text"
                        ref="department"
                        v-model="formData.department"
                        dense
                        :items="departmentList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.unit')"
                        type="text"
                        ref="unit"
                        v-model="formData.unit"
                        dense
                        :items="unitList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5">
            <v-radio-group
              v-model="formData.showtype"
              @change="ChangeShowType(formData.showtype)"
              row
            >
              <v-radio :label="this.$t('processedattendancedatarecord.showall')" value="All"></v-radio>
              <v-radio :label="this.$t('processedattendancedatarecord.showexceptiononly')" value="Exception"></v-radio>
              <v-radio
                :label="this.$t('processedattendancedatarecord.showpendingrecordonly')"
                value="Pending"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" class="mb-n8 mt-n6">
            <div>
              <v-dialog v-model="leaveentitlementdialog" max-width="800px">
                <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template> -->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{$t('processedattendancedatarecord.attendancedataitem')}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="4" class="pb-n5 mb-n8">
                            <v-autocomplete
                              outlined
                              :label="this.$t('columns.status')"
                              type="text"
                              :rules="[rules.required]"
                              v-model="selections[0].statusid"
                              dense
                              :items="statuslist"
                              @change="
                                changeStatusHandler(selections[0].statusid)
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4" class="pb-n5 mb-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('columns.date')"
                              type="date"
                              ref="date"
                              :rules="[rules.required]"
                              v-model="selections[0].shiftdate"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5">
                            <v-autocomplete
                              outlined
                              :label="this.$t('workhourspayitemmappingsetup.selectdaytype')"
                              type="text"
                              ref="psnid"
                              :rules="[rules.required]"
                              v-model="selections[0].daytypeid"
                              dense
                              :items="daytypedetailsList"
                              @change="
                                changeDayTypeHandler(selections[0].daytypeid)
                              "
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="pb-n5 mt-n8">
                            <v-autocomplete
                              outlined
                              :label="this.$t('columns.name')"
                              type="text"
                              ref="psnid"
                              :rules="[rules.required]"
                              v-model="selections[0].staffid"
                              dense
                              :items="staffDetailsList"
                              @change="
                                changeStaffIDandCompanyHandler(
                                  selections[0].staffid
                                )
                              "
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('columns.staffid')"
                              type="text"
                              :rules="[rules.required]"
                              v-model="selections[0].staffcode"
                              dense
                              readonly
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('columns.company')"
                              type="text"
                              ref="compnyid"
                              v-model="selections[0].compnycode"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="8" sm="8" md="8" class="pb-n5 mt-n8">
                            <v-autocomplete
                              outlined
                              :label="this.$t('attendancerecordsandprocesses.selectshift')"
                              type="text"
                              ref="psnid"
                              :rules="[rules.required]"
                              v-model="selections[0].shiftid"
                              dense
                              :items="shiftdetailsList"
                              @change="
                                changeShiftHandler(selections[0].shiftid)
                              "
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('attendancerecordsandprocesses.shiftcode')"
                              type="text"
                              ref="compnyid"
                              v-model="selections[0].shiftcode"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.intime')"
                              type="text"
                              v-model="selections[0].intime"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.outtime')"
                              type="text"
                              v-model="selections[0].outtime"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.workhours')"
                              type="text"
                              v-model="selections[0].workhrs"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('setupforshift.othours')"
                              type="text"
                              v-model="selections[0].othrs"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.preapprovedothours')"
                              type="text"
                              v-model="selections[0].preaprothrs"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.workhourstopay')"
                              type="text"
                              v-model="selections[0].workhrspay"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.othourstopay')"
                              type="text"
                              v-model="selections[0].othrspay"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.shiftallw')"
                              type="text"
                              v-model="selections[0].sftallwpayitemcode"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('processedattendancedatarecord.otallw')"
                              type="text"
                              v-model="selections[0].otallwpayitemcode"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('leave')"
                              type="text"
                              v-model="selections[0].leavecode"
                              dense
                              readonly
                              append-icon=""
                              background-color="#ffeab2"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" class="pb-n5 mt-n8">
                            <v-text-field
                              outlined
                              :label="this.$t('columns.remark')"
                              type="text"
                              v-model="selections[0].remark"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <!-- <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{ errMsg }}</span>
              </v-card-text> -->

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeModal"
                      >{{$t('button.cancel')}}</v-btn
                    >
                    <v-btn color="green darken-1" text @click="saveRecord"
                      >{{$t('button.save')}}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-row align="center">
                <v-col cols="12" sm="12" md="6" style="font-size: 14px; padding: 0px">
                  <v-btn
                    class="mr-5 mt-3"
                    color="#6ACB9A"
                    style="color: white"
                    v-on:click="ApprovedSelectedHandler()"
                    >{{$t('processedattendancedatarecord.approveselected')}}</v-btn
                  >
                  <v-btn
                    class="mr-5 mt-3"
                    color="#6ACB9A"
                    style="color: white"
                    v-on:click="UpdateSelectedAsPendingHandler()"
                    >{{$t('processedattendancedatarecord.updateselectedaspending')}}</v-btn
                  >

                  <v-btn
                    class="mt-3"
                    color="#6ACB9A"
                    style="color: white"
                    v-on:click="UpdateLeaveInfo()"
                    >{{$t('processedattendancedatarecord.updateleaveinfo')}}</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="12" md="6" style="padding: 10px 0px 0px">
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-avatar class="profile mr-5" size="40" tile>
                      <img
                        :src="`${publicPath}images/export_pdf.png`"
                        @click="printPDF"
                      />
                    </v-avatar>
                    <v-avatar class="profile" size="40" tile>
                      <img
                        :src="`${publicPath}images/export_csv.png`"
                        @click="getCSV"
                      />
                    </v-avatar>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      style="width: 200px"
                      class="pr-5 pt-3 ml-10"
                      hide-details
                    >
                    </v-text-field>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" sm="12" class="mt-3" style="padding: 0px">
                  <v-data-table
                    :search="search"
                    append-icon="search"
                    :headers="tabHeader"
                    :items="dataItems"
                    class="elavation-1"
                    fixed-header
                    :sort-by="['code']"
                  >
                    <template v-slot:[`header.isSelectedAll`]="{}">
                      <v-checkbox
                        outlined
                        ref="nolateness"
                        v-model="isSelectedAll"
                        label=""
                        @change="SelectAllRow(isSelectedAll)"
                        dense
                      ></v-checkbox>
                    </template>
                    <template #item="{ item }">
                      <tr :class="item.IsException ? 'ExceptionClass' : ''">
                        <td>
                          <v-checkbox
                            outlined
                            ref="nolateness"
                            v-model="item.isSelected"
                            label=""
                            dense
                            @change="CheckIsAllChecked()"
                          ></v-checkbox>
                        </td>
                        <td>
                          <v-icon
                            small
                            class="mr-1"
                            @click="modifyItemHandler(item)"
                          >
                            mdi-pen
                          </v-icon>
                          <v-icon
                            small
                            class="ml-1"
                            @click="deleteItemHandler(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                        <td>{{ item.transtatus }}</td>
                        <td>{{ item.shiftdate }}</td>
                        <td>{{ item.daytypecode }}</td>
                        <td>{{ item.staffcode }}</td>
                        <td>{{ item.psnname }}</td>
                        <td>{{ item.shiftcode }}</td>
                        <td>{{ item.intime }}</td>
                        <td>{{ item.outtime }}</td>
                        <td>{{ item.inshifthrs }}</td>
                        <td>{{ item.workhrs }}</td>
                        <td>{{ item.othrs }}</td>
                        <td>{{ item.preaprothrs }}</td>
                        <td>{{ item.workhrspay }}</td>
                        <td>{{ item.othrspay }}</td>
                        <td>{{ item.leavecode }}</td>
                        <td>{{ item.sftallwpayitemcode }}</td>
                        <td>{{ item.otallwpayitemcode }}</td>
                        <td>{{ item.remark }}</td>
                      </tr>
                    </template>
                    <!-- <template v-slot:[`item.action`]="{ item }">
                      <v-icon
                        small
                        class="mr-1"
                        @click="modifyItemHandler(item)"
                      >
                        mdi-pen
                      </v-icon>
                      <v-icon
                        small
                        class="ml-1"
                        @click="deleteItemHandler(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template> -->
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<style>
.ExceptionClass td {
  color: red !important;
}
</style>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
// import CRUD from "./CRUD";

import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    // "app-table": CRUD,
    Loading,
  },

  data() {
    return {
      search: "",
      showDate: new Date(),
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },

      tabHeader: [
        { text: "", value: "isSelectedAll", sortable: false, width: "auto" },
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "auto" },
        { text: this.$t('columns.status'), value: "transtatus", width: "auto", sortable: true },
        { text: this.$t('columns.date'), value: "shiftdate", width: "120px", sortable: false },
        {
          text: this.$t('setuprestdaypatternsetup.daytype'),
          value: "daytypecode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('columns.staffid'),
          value: "staffcode",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('columns.name'), value: "psnname", width: "200px", sortable: false },
        { text: this.$t('staffdailyrosterrecord.shift'), value: "shiftcode", width: "auto", sortable: false },
        { text: this.$t('processedattendancedatarecord.intime'), value: "intime", width: "auto", sortable: false },
        { text: this.$t('processedattendancedatarecord.outtime'), value: "outtime", width: "auto", sortable: false },
        {
          text: this.$t('processedattendancedatarecord.inshifthours'),
          value: "inshifthrs",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.workhours'),
          value: "workhrs",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('setupforshift.othours'),
          value: "othrs",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.preapprovedothours'),
          value: "preaprothrs",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.workhourstopay'),
          value: "workhrspay",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.othourstopay'),
          value: "othrspay",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('leave'),
          value: "leavecode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.shiftallw'),
          value: "sftallwpayitemcode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('processedattendancedatarecord.otallw'),
          value: "otallwpayitemcode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('columns.remark'),
          value: "remark",
          width: "auto",
          sortable: false,
        },
      ],

      dataItemlist: [],
      dataItems: [],
      isSelectedAll: false,

      formData: {
        fromdate: moment().format("YYYY-MM-DD"),
        untildate: moment().format("YYYY-MM-DD"),
        showtype: "All",
        company: null,
        division: null,
        workdaygroup: null,
        branch: null,
        section: null,
        employmentType: null,
        department: null,
        unit: null,
        userid: this.$store.getters.userId,
      },

      selections: [
        {
          id: null,
          statusid: null,
          transtatus: null,
          shiftdate: null,
          daytypeid: null,
          daytypecode: null,
          staffid: null,
          staffcode: null,
          psnname: null,
          shiftid: null,
          shiftcode: null,
          intime: null,
          outtime: null,
          workhrs: null,
          othrs: null,
          preaprothrs: null,
          workhrspay: null,
          othrspay: null,
          leavetypeid: null,
          leavecode: null,
          sftallwpayitemid: null,
          sftallwpayitemcode: null,
          otallwpayitemid: null,
          otallwpayitemcode: null,
          action: null,
          stamp: null,
          remark: null,
          compnycode: null,
          userid: this.$store.getters.userId,
        },
      ],

      daytypedetailsList: [],
      daytypedetails: [],
      status: [],
      statuslist: [],
      staffdetails: [],
      staffDetailsList: [],
      shiftdetails: [],
      shiftdetailsList: [],
      selectedRows: [],

      modify: false,
      isExisting: false,

      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,

      leaveentitlementdialog: false,

      divisions: [],
      divisionList: [],
      companies: [],
      companyList: [],
      sections: [],
      sectionList: [],
      emptypes: [],
      emptypeList: [],
      branches: [],
      branchList: [],
      units: [],
      unitList: [],
      departments: [],
      departmentList: [],
      grades: [],
      gradeList: [],
      positions: [],
      positionList: [],
      workdaygroup: [],
      workdaygrouplist: [],
    };
  },

  methods: {
    SelectAllRow(isSelectedAll) {
      for (var i = 0; i < this.dataItems.length; i++) {
        this.dataItems[i].isSelected = isSelectedAll;
      }
    },
    CheckIsAllChecked() {
      var res = this.dataItems.filter((el) => {
        return el.isSelected;
      });

      if (res.length == this.dataItems.length) {
        this.isSelectedAll = true;
      } else {
        this.isSelectedAll = false;
      }
    },
    loadDataHandler() {
      const vm = this;
      vm.loading = true;
      console.log(vm.formData);
      vm.dataItemlist = [];
      vm.dataItems = [];
      vm.isSelectedAll = false;
      this.$store.dispatch('post', {
        url: "/processedattendancedata/record",
        payload: vm.formData
      })
        .then((res) => {
          vm.loading = false;
          console.log(res);
          for (var i = 0; i < res.data.rec.length; i++) {
            let IsException = false;
            let shifthour = moment(res.data.rec[i].endtime, "HH:mm").diff(
              moment(res.data.rec[i].starttime, "HH:mm"),
              "hours"
            );
            if (shifthour < 0) {
              shifthour = moment(res.data.rec[i].starttime, "HH:mm").diff(
                moment(res.data.rec[i].endtime, "HH:mm"),
                "hours"
              );
            }
            if (
              res.data.rec[i].intime == null ||
              res.data.rec[i].outtime == null ||
              res.data.rec[i].workhrs < res.data.rec[i].shifthours ||
              res.data.rec[i].workhrs == null ||
              res.data.rec[i].workhrs < shifthour
            ) {
              if (
                res.data.rec[i].daytypecode == "N" &&
                res.data.rec[i].leavecode == null
              ) {
                IsException = true;
              } else {
                if (
                  res.data.rec[i].intime != null ||
                  res.data.rec[i].outtime != null
                ) {
                  IsException = true;
                }
              }
            }

            if (res.data.rec[i].shiftcode == "Stagged") {
              if (res.data.rec[i].inshifthrs < res.data.rec[i].workhrs) {
                IsException = true;
              }
            }

            let d = {
              id: res.data.rec[i].id,
              statusid: res.data.rec[i].statusid,
              transtatus: res.data.rec[i].transtatus,
              shiftdate: moment(res.data.rec[i].shiftdate).format("YYYY-MM-DD"),
              daytypeid: res.data.rec[i].daytypeid,
              daytypecode: res.data.rec[i].daytypecode,
              staffid: res.data.rec[i].staffid,
              staffcode: res.data.rec[i].staffcode,
              psnname: res.data.rec[i].psnname,
              compnycode: res.data.rec[i].compnycode,
              shiftid: res.data.rec[i].shiftid,
              shiftcode: res.data.rec[i].shiftcode,
              shifthours: res.data.rec[i].shifthours,
              intime:
                res.data.rec[i].intime == null
                  ? ""
                  : moment(res.data.rec[i].intime).format("HH:mm"),
              outtime:
                res.data.rec[i].outtime == null
                  ? ""
                  : moment(res.data.rec[i].outtime).format("HH:mm"),
              inshifthrs: res.data.rec[i].inshifthrs,
              workhrs: res.data.rec[i].workhrs,
              othrs: res.data.rec[i].othrs,
              preaprothrs: res.data.rec[i].preaprothrs,
              workhrspay: res.data.rec[i].workhrspay,
              othrspay: res.data.rec[i].othrspay,
              leavetypeid: res.data.rec[i].leavetypeid,
              leavecode:
                res.data.rec[i].leavestatus == "PENDING"
                  ? "*" + res.data.rec[i].leavecode
                  : res.data.rec[i].leavecode,
              sftallwpayitemid: res.data.rec[i].sftallwpayitemid,
              sftallwpayitemcode: res.data.rec[i].sftallwpayitemcode,
              otallwpayitemid: res.data.rec[i].otallwpayitemid,
              otallwpayitemcode: res.data.rec[i].otallwpayitemcode,
              action: 2,
              stamp: res.data.rec[i].stamp,
              remark: res.data.rec[i].remark,
              IsException: IsException,
              isSelected: false,
            };
            vm.dataItemlist.push(d);
            vm.dataItems.push(d);
          }

          console.log(vm.dataItemlist);
          console.log(vm.dataItems);
        })
        .catch((err) => {
          //error - unsucessfull request
          console.log(err);
        });
    },
    //load work day group
    loadWorkdayGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/groupp_grouppb_workday/all"
      })
        .then((res) => {
          console.log(res);
          vm.workdaygroup = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.workdaygrouplist.push({
              text: res.data.rec[i].valuedesc,
              value: res.data.rec[i].valuecode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/division/all"
      })
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].divncode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load companies
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/company/all"
      })
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].compnycode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/section/all"
      })
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].sectiocode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load emptypes
    loadEmpTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/emptype/all"
      })
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptypedesc,
              value: res.data.rec[i].emptype,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load branches
    loadBanchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/branch/all"
      })
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].brchcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/unit/all"
      })
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].unitcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load depts
    loadDeptListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/department/all"
      })
        .then((res) => {
          vm.departments = res.data.rec;
          console.log(res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].deptcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load grades
    loadGradeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/grade/all"
      })
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].grdcode,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    delItemHandler($event) {
      this.formData.dataItems = $event;
      this.updateValue();
    },
    addItemHandler($event) {
      this.formData.dataItems = $event;
      this.updateValue();
    },
    ChangeShowType(showtype) {
      console.log(showtype);

      if (showtype == "Exception") {
        this.dataItems = this.dataItemlist.filter((el) => {
          return el.IsException === true;
        });
      } else if (showtype == "Pending") {
        this.dataItems = this.dataItemlist.filter((el) => {
          return el.transtatus == "PENDING";
        });
      } else {
        this.dataItems = this.dataItemlist;
      }
    },

    loadStatusListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/leave/status"
      })
        .then((res) => {
          //req success --> push res into array
          vm.status = res.data.rec;
         // console.log('status', vm.status)
          for (let i = 0; i < res.data.rec.length; i++) {
            if (
              res.data.rec[i].transtatus === "APPROVED" ||
              res.data.rec[i].transtatus === "PENDING"
            ) {
              vm.statuslist.push({
                text: res.data.rec[i].transtatus,
                value: res.data.rec[i].id,
              });
            }

            if (this.selections[0].statusid) {
                this.changeStatusHandler(this.selections[0].statusid);

           }
          }
          // if (vm.formData.transtatusid) {
          //   console.log(vm.formData);
          //   this.changeLeaveStatus(vm.formData.transtatusid);
          // }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeStatusHandler(id) {
      let item = this.leavestatus.find((item) => item.id === id);
      this.selections[0].statusid = item.id
      console.log(item);
    },
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            // if (vm.formData.staffid) {
            //   this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            // }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);
      console.log(item);
    },
    loadShiftDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/shiftsetup/all"
      })
        .then(function (res) {
          vm.shiftdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.shiftdetailsList.push({
              text: res.data.rec[i].shiftdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    changeShiftHandler(id) {
      let item = this.shiftdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].shiftid = item.id;
      this.selections[0].shiftcode = item.shiftcode;
    },
    loadDaytypeDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/daytype/all"
      })
        .then(function (res) {
          vm.daytypedetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.daytypedetailsList.push({
              text: res.data.rec[i].daytypedesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    changeDayTypeHandler(id) {
      let item = this.daytypedetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].daytypeid = item.id;
      this.selections[0].daytypecode = item.daytypecode;
      this.selections[0].daytypedesc = item.daytypedesc;
    },
    modifyItemHandler(item) {
      // assign loaded data to selections array
      if (item.action == 1) {
        this.modify = true;
      }

      if (item.action == 2) {
        this.isExisting = true;
      }

      this.selections = [
        {
          id: item.id,
          statusid: item.statusid,
          transtatus: item.transtatus,
          shiftdate: item.shiftdate,
          daytypeid: item.daytypeid,
          daytypecode: item.daytypecode,
          staffid: item.staffid,
          staffcode: item.staffcode,
          psnname: item.psnname,
          compnycode: item.compnycode,
          shiftid: item.shiftid,
          shiftcode: item.shiftcode,
          intime: item.intime,
          outtime: item.outtime,
          workhrs: item.workhrs,
          othrs: item.othrs,
          preaprothrs: item.preaprothrs,
          workhrspay: item.workhrspay,
          othrspay: item.othrspay,
          leavetypeid: item.leavetypeid,
          leavecode: item.leavecode,
          sftallwpayitemid: item.sftallwpayitemid,
          sftallwpayitemcode: item.sftallwpayitemcode,
          otallwpayitemid: item.otallwpayitemid,
          otallwpayitemcode: item.otallwpayitemcode,
          action: 2,
          stamp: item.stamp,
          remark: item.remark,
          userid: this.$store.getters.userId,
        },
      ];

      this.leaveentitlementdialog = true;
    },
    deleteItemHandler(item) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {
        url: "/processedattendancedata/" + item.id
      })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.loadDataHandler();
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    closeModal() {
      this.leaveentitlementdialog = false;
      this.isErr = false;
      this.isExisting = false;
      this.modify = false;

      // reset the form fields
      this.resetSelections();
    },
    saveRecord() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {
        url: "/processedattendancedata/" + this.selections[0].id,
        payload: this.selections[0]
      })
        .then((res) => {
          if (res.data.error) {
            this.isErrTrigger = true;
            this.errMsg = res.data.error;
          } else {
            this.loadDataHandler();
          }
        })
        .catch((err) => {
          this.isErrTrigger = true;
          this.errMsg = err;
        });

      this.modify = false;
      this.isExisting = false;
      this.leaveentitlementdialog = false;
      // reset the form fields
      this.resetSelections();
    },
    resetSelections() {
      this.selections = [
        {
          id: null,
          statusid: null,
          transtatus: null,
          shiftdate: null,
          daytypeid: null,
          daytypecode: null,
          staffid: null,
          staffcode: null,
          psnname: null,
          shiftid: null,
          shiftcode: null,
          intime: null,
          outtime: null,
          workhrs: null,
          othrs: null,
          preaprothrs: null,
          workhrspay: null,
          othrspay: null,
          leavetypeid: null,
          leavecode: null,
          sftallwpayitemid: null,
          sftallwpayitemcode: null,
          otallwpayitemid: null,
          otallwpayitemcode: null,
          action: null,
          stamp: null,
          remark: null,
          userid: this.$store.getters.userId,
        },
      ];
    },
    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a3",
      });

      var head = [
        { title: this.$t('columns.status'), dataKey: "transtatus" },
        { title: this.$t('columns.date'), dataKey: "shiftdate" },
        { title: this.$t('setuprestdaypatternsetup.daytype'), dataKey: "daytypecode" },
        { title: this.$t('columns.staffid'), dataKey: "staffcode" },
        { title: this.$t('columns.name'), dataKey: "psnname" },
        { title: this.$t('staffdailyrosterrecord.shift'), dataKey: "shiftcode" },
        { title: this.$t('processedattendancedatarecord.intime'), dataKey: "intime" },
        { title: this.$t('processedattendancedatarecord.outtime'), dataKey: "outtime" },
        { title: this.$t('processedattendancedatarecord.inshifthours'), dataKey: "inshifthrs" },
        { title: this.$t('processedattendancedatarecord.workhours'), dataKey: "workhrs" },
        { title: this.$t('setupforshift.othours'), dataKey: "othrs" },
        { title: this.$t('processedattendancedatarecord.preapprovedothours'), dataKey: "preaprothrs" },
        { title: this.$t('processedattendancedatarecord.workhourstopay'), dataKey: "workhrspay" },
        { title: this.$t('processedattendancedatarecord.othourstopay'), dataKey: "othrspay" },
        { title: this.$t('leave'), dataKey: "leavecode" },
        { title: this.$t('processedattendancedatarecord.shiftallw'), dataKey: "sftallwpayitemcode" },
        { title: this.$t('processedattendancedatarecord.otallw'), dataKey: "otallwpayitemcode" },
        { title: this.$t('columns.remark'), dataKey: "remark" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          id: this.dataItems[i].id,
          statusid: this.dataItems[i].statusid,
          transtatus: this.dataItems[i].transtatus,
          shiftdate: moment(this.dataItems[i].shiftdate).format("YYYY-MM-DD"),
          daytypeid: this.dataItems[i].daytypeid,
          daytypecode: this.dataItems[i].daytypecode,
          staffid: this.dataItems[i].staffid,
          staffcode: this.dataItems[i].staffcode,
          psnname: this.dataItems[i].psnname,
          shiftid: this.dataItems[i].shiftid,
          shiftcode: this.dataItems[i].shiftcode,
          intime: this.dataItems[i].intime,
          outtime: this.dataItems[i].outtime,
          inshifthrs: this.dataItems[i].inshifthrs,
          workhrs: this.dataItems[i].workhrs,
          othrs: this.dataItems[i].othrs,
          preaprothrs: this.dataItems[i].preaprothrs,
          workhrspay: this.dataItems[i].workhrspay,
          othrspay: this.dataItems[i].othrspay,
          leavetypeid: this.dataItems[i].leavetypeid,
          leavecode: this.dataItems[i].leavecode,
          sftallwpayitemid: this.dataItems[i].sftallwpayitemid,
          sftallwpayitemcode: this.dataItems[i].sftallwpayitemcode,
          otallwpayitemid: this.dataItems[i].otallwpayitemid,
          otallwpayitemcode: this.dataItems[i].otallwpayitemcode,
          action: 2,
          stamp: this.dataItems[i].stamp,
        });
      }

      //   doc.text(
      //     `Staff Leave Balance - ${this.formData.staffcode} as of ${this.formData.asofdate}`,
      //     10,
      //     10
      //   );

      doc.autoTable(head, body);
      doc.save(`${this.$t('processedattendancedata')}.pdf`);
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },
    download(data) {

      // UTF-8 for Chinese Character CSV Render '%ef%bb%bf'
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${this.$t('processedattendancedata')}.csv`);

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async getCSV() {
      let data = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        data.push({
          [`${this.$t('columns.status')}`]: this.dataItems[i].transtatus,
          [`${this.$t('columns.date')}`]: this.dataItems[i].shiftdate,
          [`${this.$t('setuprestdaypatternsetup.daytype')}`]: this.dataItems[i].daytypecode,
          [`${this.$t('columns.staffid')}`]: this.dataItems[i].staffcode,
          [`${this.$t('columns.name')}`]: this.dataItems[i].psnname,
          [`${this.$t('staffdailyrosterrecord.shift')}`]: this.dataItems[i].shiftcode,
          [`${this.$t('processedattendancedatarecord.intime')}`]: this.dataItems[i].intime,
          [`${this.$t('processedattendancedatarecord.outtime')}`]: this.dataItems[i].outtime,
          [`${this.$t('processedattendancedatarecord.inshifthours')}`]: this.dataItems[i].inshifthrs,
          [`${this.$t('processedattendancedatarecord.workhours')}`]: this.dataItems[i].workhrs,
          [`${this.$t('setupforshift.othours')}`]: this.dataItems[i].othrs,
          [`${this.$t('processedattendancedatarecord.preapprovedothours')}`]: this.dataItems[i].preaprothrs,
          [`${this.$t('processedattendancedatarecord.workhourstopay')}`]: this.dataItems[i].workhrspay,
          [`${this.$t('processedattendancedatarecord.othourstopay')}`]: this.dataItems[i].othrspay,
          [`${this.$t('leave')}`]: this.dataItems[i].leavecode,
          [`${this.$t('processedattendancedatarecord.shiftallw')}`]: this.dataItems[i].sftallwpayitemcode,
          [`${this.$t('processedattendancedatarecord.otallw')}`]: this.dataItems[i].otallwpayitemcode,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
    ApprovedSelectedHandler() {
      let selectedRec = this.dataItems.filter((el) => {
        return el.isSelected;
      });

      if (selectedRec.length > 0) {
        let data = {
          data: selectedRec,
          userid: this.$store.getters.userId,
        };
        this.$store.dispatch('post', {
          url: "/processedattendancedata/updatetoapproved",
          payload: data
        })
          .then((res) => {
            if (res.data.error) {
              this.isErrTrigger = true;
              this.errMsg = res.data.error;
            } else {
              this.loadDataHandler();
            }
          })
          .catch((err) => {
            this.isErrTrigger = true;
            this.errMsg = err;
          });
      }
    },
    UpdateSelectedAsPendingHandler() {
      let selectedRec = this.dataItems.filter((el) => {
        return el.isSelected;
      });

      if (selectedRec.length > 0) {
        let data = {
          data: selectedRec,
          userid: this.$store.getters.userId,
        };
        this.$store.dispatch('post', {
          url: "/processedattendancedata/updatetopending",
          payload: data
        })
          .then((res) => {
            if (res.data.error) {
              this.isErrTrigger = true;
              this.errMsg = res.data.error;
            } else {
              this.loadDataHandler();
            }
          })
          .catch((err) => {
            this.isErrTrigger = true;
            this.errMsg = err;
          });
      }
    },
    UpdateLeaveInfo() {
      const vm = this;
      console.log(vm.formData);
      vm.loading = true;
      this.$store.dispatch('post', {
        url: "/processedattendancedata/updateLeaveInfo",
        payload: vm.formData
      })
        .then((res) => {
          vm.loading = false;
          if (res.data.error) {
            this.isErrTrigger = true;
            this.errMsg = res.data.error;
          } else {
            this.loadDataHandler();
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          console.log(err);
        });
    },
  },

  mounted() {
    this.loadGradeListHandler();
    this.loadDeptListHandler();
    this.loadUnitListHandler();
    this.loadBanchListHandler();
    this.loadEmpTypeListHandler();
    this.loadSectionListHandler();
    this.loadCompaniesListHandler();
    this.loadDivisionListHandler();
    this.loadWorkdayGroupListHandler();

    this.loadStatusListHandler();
    this.loadDaytypeDetailsListHandler();
    this.loadShiftDetailsListHandler();
    this.loadStaffDetailsListHandler();
    this.resetSelections();
  },
};
</script>