<template>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />
  
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
  
      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </template>
  
  <script>
  import GLCodeMappingPolicyForm from "../../../components/payroll/glcodemappingpolicy/Form";
  import ErrorDialog from "../../../components/common/ErrorDialog";
  import ConfirmDialog from "../../../components/common/ConfirmDialog";
  
  export default {
    components: {
      "app-form": GLCodeMappingPolicyForm,
      "app-error": ErrorDialog,
      "app-confirm": ConfirmDialog,
    },
  
    data() {
      return {
        id: this.$route.params.id,
        search: this.$route.params.search,
        pageTitle: "Edit GL Code Mapping Policy",
        pageTitleValue: "",
        formData: {
            policycode: null,
            policydesc: null,
            remark: null,
            level1: null,
            level2: null,
            level3: null,
            level4: null,
            level5: null,
            level6: null,
            level7: null,
            level8: null,
            stamp: 0,
            userid: this.$store.getters.userId,
        },
        loading: true,
        errMsg: "",
        isErr: false,
        isConfirm: false,
        editMode: false,
      };
    },
  
    methods: {
      errorHandler($event) {
        this.isErr = $event.isErr;
        this.errMsg = $event.errMsg;
      },
  
      loadGLCodeMappingPolicyItem() {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/payglcodemappingpolicy/" + this.id})
          .then((res) => {
            vm.formData.policycode = res.data.rec[0].policycode;
            vm.formData.policydesc = res.data.rec[0].policydesc;
            vm.formData.remark = res.data.rec[0].remark;
            vm.formData.level1 = res.data.rec[0].level1,
            vm.formData.level2 = res.data.rec[0].level2,
            vm.formData.level3 = res.data.rec[0].level3,
            vm.formData.level4 = res.data.rec[0].level4,
            vm.formData.level5 = res.data.rec[0].level5,
            vm.formData.level6 = res.data.rec[0].level6,
            vm.formData.level7 = res.data.rec[0].level7,
            vm.formData.level8 = res.data.rec[0].level8,
            vm.formData.stamp = res.data.rec[0].stamp;
  
            vm.pageTitleValue = res.data.rec[0].policydesc;
  
            vm.loading = false;
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      saveHandler() {
        const vm = this;
  
        if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
          return false;
        }
  
        this.$store.dispatch('put', {url: "/payglcodemappingpolicy/" + this.id,
            payload: this.formData})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "glcodemappingpolicylist", params: { search: this.search}  });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      deleteHandler() {
        const vm = this;
  
        if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
          return false;
        }
  
        this.$store.dispatch('delete', {url:
              "/payglcodemappingpolicy/" +
              this.id +
              "/" +
              this.formData.userid})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "glcodemappingpolicylist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      },
  
      createHandler() {
        this.$router.push({ name: "glcodemappingpolicynew" });
      },
    },
  
    mounted() {
      this.editMode = true;
      this.loadGLCodeMappingPolicyItem();
    },
  };
  </script>