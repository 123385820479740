<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                    outlined
                    :label="this.$t('vdatatable.receiptreference')"
                    type="text"
                    v-model="formData.recref"
                    :rules="[rules.required, rules.counter]"
                    maxlength='16'
                    dense
                ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="exportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
      },
      pageTitle: 'Ambank',

      parameter: this.parentObject.parameter,
      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        recref: ''
      },
      fileHolder: null,
      file: null,

      paymentModeList: ['FT', 'IBG'],
      bank: [
        {
          id: 4,
          bankcode: "ALBB",
          bankdesc: "ALLIANCE BANK",
        },
        {
          id: 1,
          bankcode: "ABB",
          bankdesc: "AFFIN BANK BERHAD",
        },
        {
          id: 2,
          bankcode: "AGRO",
          bankdesc: "AGRO BANK BERHAD",
        },
        {
          id: 3,
          bankcode: "ALRAJHI",
          bankdesc: "AL-RAJHI BANKING CORPORATION (M) BERHAD",
        },
        {
          id: 5,
          bankcode: "AMBB",
          bankdesc: "AMBANk(M)BERHAD",
        },
        {
          id: 6,
          bankcode: "BBB",
          bankdesc: "BANGKOK BANK",
        },
        {
          id: 16,
          bankcode: "CIMB",
          bankdesc: "CIMB BANK BERHAD",
        },
        {
          id: 7,
          bankcode: "BIMB",
          bankdesc: "BANK ISLAM MALAYSIA",
        },
        {
          id: 12,
          bankcode: "BKR",
          bankdesc: "BANK KERJASAMA RAKYAT",
        },
        {
          id: 8,
          bankcode: "BMMB",
          bankdesc: "BANK MUAMALAT MALAYSIA BERHAD",
        },
        {
          id: 14,
          bankcode: "BNP",
          bankdesc: "BNP PARIBAS MALAYSIA BERHAD",
        },
        {
          id: 9,
          bankcode: "BOFA",
          bankdesc: "BANK OF AMERICA MALAYSIA BERHAD",
        },
        {
          id: 10,
          bankcode: "BOCB",
          bankdesc: "BANK OF CHINA",
        },
        {
          id: 13,
          bankcode: "BSN",
          bankdesc: "BANK SIMPANAN NASIONAL",
        },
        {
          id: 15,
          bankcode: "CCBM",
          bankdesc: "CHINA CONSTRUCTION BANK (MALAYSIA) BERHAD",
        },
        {
          id: 17,
          bankcode: "CBB",
          bankdesc: "CITIBANK",
        },
        {
          id: 18,
          bankcode: "DB",
          bankdesc: "DEUTSCHE BANK MSIA BHD",
        },
        {
          id: 20,
          bankcode: "HLBB",
          bankdesc: "HONG LEONG BANK",
        },
        {
          id: 21,
          bankcode: "HSBC",
          bankdesc: "HSBC",
        },
        {
          id: 23,
          bankcode: "ICBK",
          bankdesc: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD",
        },
        {
          id: 24,
          bankcode: "JPMCBB",
          bankdesc: "JP MORGAN CHASE BANK BHD",
        },
        {
          id: 25,
          bankcode: "KFH",
          bankdesc: "KUWAIT FINANCE HOUSE",
        },
        {
          id: 26,
          bankcode: "MBB",
          bankdesc: "MAYBANK",
        },
        {
          id: 27,
          bankcode: "MIZUHO",
          bankdesc: "MIZUHO CORPORATE BANK (MALAYSIA) BERHAD",
        },
        {
          id: 29,
          bankcode: "OCBC",
          bankdesc: "OCBC",
        },
        {
          id: 30,
          bankcode: "PBB",
          bankdesc: "PUBLIC BANK",
        },
        {
          id: 31,
          bankcode: "RHB",
          bankdesc: "RHB BANK",
        },
        {
          id: 32,
          bankcode: "SCB",
          bankdesc: "STANDARD CHARTERED BANK",
        },
        {
          id: 33,
          bankcode: "SMBC",
          bankdesc: "SUMITOMO MITSUI BANKING CORPORATION MALAYSIA BERHAD",
        },
        {
          id: 36,
          bankcode: "UOB",
          bankdesc: "UNITED OVERSEAS BANK",
        },
        {
          id: 41,
          bankcode: "CIMBIBB",
          bankdesc: "CIMB ISLAMIC BANK BERHAD",
        },
        {
          id: 44,
          bankcode: "MBB",
          bankdesc: "Malayan Islamic Berhad",
        },
      ],

      staffilterlist: [],
      staffaccount: [],
      companyaccount: [],
      psnpayroll: [],
      titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
      payrolldata: [],

      companyrecnum: "",
      paymentmode: null,
      bankcode: null,
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",
      filler: "",

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],

      currency: null,
      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {

    getAmbankExportListHandler() {
      const vm = this
      
      this.$store.dispatch('get', {url: `/ambanksalexport/all/${this.$store.getters.userId}`})
      .then(res => {
        let dateHolder = vm.data.year + "-" + 
                         vm.padRJ(vm.data.month, 2) + "-" + 
                         vm.daysInMonth(vm.data.month, vm.data.year);
          
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let payrolldataholder = res.data.rec;
        payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)

        payrolldataholder.forEach(function (o) {
          var existing = vm.payrolldata.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            if (o.bankid) {
              if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                    vm.payrolldata.push(o);
              }
            } else {
              vm.payrolldata.push(o);
            }
          }
        });
        this.loadStaffFilter()
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    loadStaffFilter() {
      const vm = this;

      this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
        .then(res => {
            vm.staffilterlist = res.data.rec;
            let checkval = false;
            for (const key in this.parameter) {
              if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            }

            if (checkval) {
              vm.payrolldata = vm.payrolldata.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }
            
        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    async getData() {
      const vm = this
      let body = []
      let holdpayment = []
      let bankaccount = {}
      let rec = 0
      // let recref = 0
      // let recrefholder = []
      let paymentmode = ''
      let purposecode = ''
      let rescode = ''
      let dataholder = vm.payrolldata

      let payrolldetail = this.filter(dataholder);

      this.processedCount = dataholder.length;
      this.successCount = payrolldetail.length;
      this.errCount = dataholder.length - payrolldetail.length;
      this.currency = payrolldetail[0].exchratecode;

      payrolldetail.forEach(element => {
        let validationvalue = ''
        bankaccount = vm.bank.find(e => e.id == element.bankid)
        bankaccount.id == '5' ? paymentmode = 'IFT' : paymentmode = 'GIRO'
        if (element.iddetails != null) validationvalue = element.iddetails.split("-").join("")
        let staffbankacc = element.staffaccountno.split("-").join("")
        if (element.ntlycode == 'MY'){
          rescode = 'R'
        } else { rescode = 'N'}
        //element.ntlycode == 'MY' ? rescode = 'R' : rescode = 'N'
        if (rescode == 'N' && element.netpay > 5000) {purposecode = '14300'} else if (rescode == 'R') {purposecode = ''}
        if (rescode == 'N' && element.netpay <= 5000) {purposecode = '71030'} else if (rescode == 'R') {purposecode = ''}

        let netpayholder = element.netpay.toFixed(2);
        netpayholder = netpayholder.split(".").join("");
        if (bankaccount.id == 5) bankaccount.bankcode = ''

        // while(recref == 0){
        //   var rn = Math.floor(Math.random() * 10000000000000000) + 1;
        //   if(recrefholder.indexOf(rn) === -1) {
        //     recrefholder.push(rn)
        //     recref = rn
        //   }
        // }

        body.push({
          "Payment Mode": this.padSpaceLJ(paymentmode, 10),
          "BNM Code": this.padSpaceLJ(bankaccount.bankcode, 12),
          "Account Number": this.padSpaceLJ(staffbankacc, 16),
          "Employee Name": this.padSpaceLJ(element.accountname, 50),
          "Payment Amount": this.padRJ(netpayholder, 15),
          "Resident or Non Resident": rescode,
          "Reference Number": this.padRJ(this.formData.recref, 16),
          "Employee ID": this.padSpaceLJ(element.staffcode, 30),
          "Beneficiary ID": this.padSpaceLJ(validationvalue, 12),
          "Reserved1": this.padSpaceLJ(this.filler, 465),
          "Purpose Code": this.padSpaceLJ(purposecode, 5),
          "Reserved2": this.padSpaceLJ(this.filler, 80),
        });

        holdpayment.push(element.netpay)
        rec++
      });

      if (holdpayment) {
        let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
        this.gettotalAmt = totalAmt.toFixed(2);
      }

      let totalAmtHolder = this.gettotalAmt.split(".").join("")

      let header = 
        moment().format('DDMMYYYY') +
        this.padRJ(rec, 13) +
        this.padRJ(totalAmtHolder, 15)

      //  console.log('body', body)
        
      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body);
        this.download(txtData, this.titleData);
      }
    },

    filter(item) {
      let checkacc = this.accfilter(item)
      let checkaccbank = this.accBankfilter(checkacc)
      let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
      let checkactive = this.activefilter(checkapaymentmethod)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    accfilter(item) {
      let data = item.filter(e => e.bankid)
      let noacc = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noacc.length; i++) {
        noacc[i].errormsg = this.$t('allbank.noaccfound');
        this.accErr.push(noacc[i]);
      }
      return data
    },

    accBankfilter(item) {
      let data = item.filter(e => {
        for (let i = 0; i < this.bank.length; i++) {
          if (this.bank[i].id == e.bankid) return e
        }
      })
      let noaccbank = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noaccbank.length; i++) {
        noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
        this.accErr.push(noaccbank[i]);
      }
      return data
    },

    paymentmethodfilter(item) {
      let data = item.filter(e => e.paymodecode == 'BANK')
      let diffpayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < diffpayment.length; i++) {
        diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
        this.accErr.push(diffpayment[i]);
      }
      return data
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => e.netpay > 0)
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(header, data) {
      const csvRows = [];

      const dataheaders = Object.keys(data[0]);

      csvRows.push(header);

      //loop over the rows
      for (const row of data) {
        const values = dataheaders.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmt = this.gettotalAmt;
        item.accounterr = this.accErr;
        item.negErrMss = this.negErrMsg;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        item.currency = this.currency;
        this.$router.push({ name: "salaryexportlog", params: { item } });
      }
    },

    backHandler() {
      let param = this.parameter

      this.$router.push({ name: "salaryexport", params: { param } });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    this.getAmbankExportListHandler();
  },
};
</script>