<template>

   <v-container  fluid style="height: 100%; width:100%; float: left; padding-left: 0px">

    <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
    ></loading>
      
      <v-row class="me-n5 mt-n4 mb-n8">
            <v-col
            cols="8"
            sm="8"
            class="ms-n2"
            >
               <v-btn 
                  color="warning" 
                  style="width: 100px"
                  @click="backHandler" 
                  > 
                  {{$t('button.back')}} 
               </v-btn>
            </v-col>

            <v-col
            cols="4"
            sm="4"
            class="pe-0"
            >
            <v-autocomplete
               outlined
               :label="this.$t('reportstaffenquiry.hide_showcolumn')"
               type="text"
               v-model="hideColumn"
               :items="hideList"
               dense
               clearable
               multiple
            ></v-autocomplete>
            </v-col>
      </v-row>

      <v-row class="mt-2 mb-2">
      <v-expansion-panels>
         <v-expansion-panel>
            <v-expansion-panel-header>
            <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
            >{{$t('payrollenquiryreport.advancefilter')}}</v-subheader>
            </v-expansion-panel-header>
                  
                  <v-expansion-panel-content>

                     <div class="mt-8">

                        <v-row>
                           <v-col
                              cols="3"
                              sm="3"
                              class="pb-4 mt-n8"
                           >
                           <v-autocomplete
                              outlined
                              :label="this.$t('reportsparam.parameters')"
                              type="text"
                              v-model="columnName"
                              :items="columnList"
                              dense
                              clearable
                           ></v-autocomplete>
                           </v-col>

                           <v-col
                              cols="2"
                              sm="2"
                              class="pb-4 mt-n8"
                           >
                           <v-autocomplete
                              outlined
                              :label="this.$t('reportstaffenquiry.operation')"
                              type="text"
                              v-model="operationValue"
                              :items="operationList"
                              dense
                              clearable
                           ></v-autocomplete>
                           </v-col>

                           <v-col
                              cols="3"
                              sm="3"
                              class="pb-4 mt-n8"
                              v-if="switchSearchType === 'A'"
                           >
                           <v-autocomplete
                              outlined
                              :label="this.$t('reportstaffenquiry.data')"
                              type="text"
                              v-model="searchValue"
                              :items="searchList"
                              dense
                              clearable
                           ></v-autocomplete>
                           </v-col>

                           <v-col
                              cols="3"
                              sm="3"
                              class="pb-4 mt-n8"
                              v-else-if="switchSearchType === 'B'"
                           >
                           <v-text-field
                              :label="this.$t('reportstaffenquiry.search')"
                              v-model="userSearch"
                              style="width: 250px"
                              hide-details
                           ></v-text-field>
                           </v-col>

                           <v-col
                              cols="3"
                              sm="3"
                              class="pb-4 mt-n8"
                              v-else-if="switchSearchType === 'C'"
                           >
                           <v-text-field
                              outlined
                              :label="this.$t('setupwizardsetup.number')"
                              type="number"
                              v-model="numberValue"
                              dense
                           ></v-text-field>
                           </v-col>

                           <v-col
                              cols="3"
                              sm="3"
                              class="pb-4 mt-n8"
                              v-else-if="switchSearchType === 'D'"
                           >
                           <v-text-field
                              outlined
                              :label="this.$t('columns.date')"
                              type="date"
                              v-model="dateValue"
                              dense
                           ></v-text-field>
                           </v-col>

                           <v-tooltip bottom color="warning" v-if="refresh === false">
                           <template v-slot:activator="{ on, attrs }">
                                 <v-avatar class="mx-3 mt-n2" size="23" tile>
                                    <img
                                    :src="`${publicPath}images/reload.png`"
                                    @click="refreshTable"
                                    v-bind="attrs"
                                    v-on="on"
                                    />
                                 </v-avatar>
                           </template>
                           <span>{{$t('reportstaffenquiry.loaddata')}}</span>
                           </v-tooltip>

                           <v-tooltip bottom color="warning" v-if="refresh === false">
                           <template v-slot:activator="{ on, attrs }">
                                 <v-avatar class="mx-3 mt-n2" size="23" tile>
                                    <img
                                       :src="`${publicPath}images/and_orange.png`"
                                       @click="addSearchFilter"
                                       v-bind="attrs"
                                       v-on="on"
                                    />
                                 </v-avatar>
                           </template>
                           <span>{{$t('reportstaffenquiry.and')}}</span>
                           </v-tooltip>

                           <v-tooltip bottom color="warning" v-if="refresh === false">
                           <template v-slot:activator="{ on, attrs }">
                                 <v-avatar class="mx-3 mt-n2" size="23" tile>
                                    <img
                                       :src="`${publicPath}images/or.png`"
                                       @click="orFilter"
                                       v-bind="attrs"
                                       v-on="on"
                                    />
                                 </v-avatar>
                           </template>
                           <span>{{$t('reportstaffenquiry.or')}}</span>
                           </v-tooltip>

                           <v-tooltip bottom color="warning">
                           <template v-slot:activator="{ on, attrs }">
                                 <v-avatar class="mx-3 mt-n2" size="23" tile>
                                    <img
                                       :src="`${publicPath}images/bin_orange.png`"
                                       @click="clearTable"
                                       v-bind="attrs"
                                       v-on="on"
                                    />
                                 </v-avatar>
                           </template>
                           <span>{{$t('reportstaffenquiry.clearallfilter')}}</span>
                           </v-tooltip>

                        </v-row>

                        <v-row class="mx-3 mt-n8">
                           <h4>{{userSql}}</h4>
                        </v-row>

                        <v-row v-if="refresh === false">
                           <ul v-for="(content, index) in userArr" :key="index">
                              <v-tooltip bottom color="error">
                              <template v-slot:activator="{ on, attrs }">
                                 <li @click="removeFilter(content)"
                                 v-bind="attrs"
                                 v-on="on" 
                                 >{{content}}</li> 
                              </template>
                              <span>{{$t('reportstaffenquiry.remove')}}</span>
                              </v-tooltip>
                           </ul>
                        </v-row>

                     </div>  

                  </v-expansion-panel-content>

         </v-expansion-panel>
      </v-expansion-panels>
      </v-row>

      <br>

      <v-row>
      <v-toolbar color="#ECEFF1">

         <h2 style="color: #3f6892; padding-left: 20px">
         {{ pageTitle }}
         </h2>
         <v-spacer></v-spacer>

            <v-avatar size="40" tile>
               <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
               />
            </v-avatar>
            <v-avatar size="40" tile>
               <img
                  :src="`${publicPath}images/export_xls.png`"
                  @click="getXLSX"
               />
            </v-avatar>
            
            <v-col
               cols="3"
               sm="3"
               class="mt-6"
            >
            <v-autocomplete
               outlined
               :label="this.$t('reportstaffenquiry.grpby')"
               type="text"
               v-model="groupName"
               :items="groupList"
               background-color="white" 
               dense
               clearable
            ></v-autocomplete>
            </v-col>

            <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
                  <v-avatar class="mx-3" size="18" tile>
                     <img
                        :src="`${publicPath}images/reload.png`"
                        @click="addGroupBy"
                        v-bind="attrs"
                        v-on="on"
                     />
                  </v-avatar>
            </template>
            <span>{{$t('reportstaffenquiry.addgrp')}}</span>
            </v-tooltip>

            <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
               <v-avatar class="mx-3" size="18" tile>
                  <img
                     :src="`${publicPath}images/bin_orange.png`"
                     @click="clearGroupBy"
                     v-bind="attrs"
                     v-on="on"
                  />
               </v-avatar>
            </template>
            <span>{{$t('reportstaffenquiry.clearallgrp')}}</span>
            </v-tooltip>

      </v-toolbar>
      </v-row>

      <!-- --------------------------------- LIST SEARCH GROUP --------------------------------------- -->
      
      <v-row class="mt-4">
         <h4>{{$t('reportstaffenquiry.clearparameterused')}}</h4>
      </v-row>

      <v-row>
         <ul v-for="(content, index) in userGroup" :key="content">
            <v-tooltip bottom color="error">
            <template v-slot:activator="{ on, attrs }">
               <li @click="deleteGroupBy(index)"
               v-bind="attrs"
               v-on="on" 
               >{{content}}</li>
            </template>
            <span>{{$t('reportstaffenquiry.remove')}}</span>
            </v-tooltip>
         </ul>
      </v-row>
      
   <!-- --------------------------------- TABULATOR --------------------------------------- -->
      <v-row class="mt-4">
         <div ref="table"></div>
      </v-row>

   </v-container>
</template>

<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import moment from 'moment';
import Loading from "vue-loading-overlay";

export default {

   components: {
        Loading
    },

   data() {
      return {

         // Image Path
         publicPath: process.env.BASE_URL,

         // Param
         formData: {
            getpayoutcode: this.$route.query.id, 
            getyearfrom: this.$route.query.yearfrom,
            getyearto: this.$route.query.yearto,
            getmonthfrom: this.$route.query.monthfrom,
            getmonthto: this.$route.query.monthto,
            getyeararr: this.$route.query.yeararr,
            dummy: [],
         },

         pageTitle: this.$t('payrollenquiryreport.payrollenquiry'),

         // Tabulator Function
         tabulator: null, //variable to hold your table
         tableData: [], //data for table to display

         // Default Setting
         column: [
            {title:this.$t('claimrecordlisting.payoutcode'), field:"payoutcode"}, 
            {title:this.$t('statutoryexportcommonused.payoutdescription'), field:"payoutdesc"},
            {title:this.$t('nonbankpaymentlistingreport.payoutyear'), field:"poyr"},
            {title:this.$t('bankpaymentlistingreport.payoutmonth'), field:"pomth"},
            {title:this.$t('payrollprocessrun.payoutstatus'), field:"pystatusdesc"},
            {title:this.$t('payrollprocessrun.payrollgroup'), field:"payrollgroupcode"},
            {title:this.$t('columns.staffcode'), field:"staffcode"},
            {title:this.$t('columns.name'), field:"psnname"},
            {title:this.$t('columns.company'), field:"compnycode"},
            {title:this.$t('columns.branch'), field:"brchcode"},
            {title:this.$t('columns.division'), field:"divcode"},
            {title:this.$t('columns.department'), field:"deptcode"},
            {title:this.$t('columns.section'), field:"sectiocode"},
            {title:this.$t('columns.unit'), field:"unitcode"},
            {title:this.$t('columns.employmenttype'), field:"emptypecode"},
            {title:this.$t('columns.grade'), field:"gradecode"},
            {title:this.$t('columns.job'), field:"jobcode"},
            {title:this.$t('columns.position'), field:"positioncode"},
            {title:this.$t('previewpayroll.grosspay'), field:"grosspay"}, 
            {title:this.$t('previewpayroll.netpay'), field:"netpay"},
            {title:this.$t('recstaffdetails.hiredate'), field:"hiredate"},
            {title:this.$t('recstaffdetails.cessationdate'), field:"cesdate"},
            {title:this.$t('payrollenquiryreport.payitemtypecategory'), field:"payitemcatdesc"},
            {title:this.$t('setuppayitmetype.payitemtype'), field:"payitemtypedesc"},
            {title:this.$t('workhourspayitemmappingsetup.payitemcode'), field:"payitemcode"},
            {title:this.$t('setupclaim.payitemdescription'), field:"payitemdesc"},
            {title:this.$t('payeeinputprocess.approvedamount'), field:"aprvamt"},
            {title:this.$t('payeeinputprocess.approvedquantity'), field:"aprvqty"},
            {title:this.$t('payeeinputprocess.requestedamount'), field:"reqamt"},
            {title:this.$t('payeeinputprocess.requestedquantity'), field:"reqqty"},
            {title:this.$t('setuptp1.source'), field:"srctbl"},
         ],
         operationList: [
            {text:'=' , value:'='},
            {text:'<' , value:'<'},
            {text:'<=' , value:'<='},
            {text:'>' , value:'>'},
            {text:'>=' , value:'>='},
            {text:'!=' , value:'!='},
            {text:'Like' , value:'like'},
         ],
         switchSearchType: 'A',

         // V-model
         columnName: '',
         operationValue: '',
         userSearch: '',
         dateValue: '',
         searchValue: '',
         numberValue: '',

         //:List
         columnList: [],
         searchList: [],
         hideList: [],
         hideColumn: [],
         
         // Group By Function
         userGroup: [],
         group: [],
         groupList: [],
         groupName: '',

         // Test Data
         test: [undefined, undefined, undefined],
         sqlConstruct: [],
         userSeen: [],
         userArr: [],
         userSql: '',
         refresh: false,

         loading: false,
         errMsg: ''
      }
   },

   watch: {
      columnName() {

         if(this.columnName === undefined) {
            this.searchList = [];
            this.searchValue = '';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'<' , value:'<'},
                  {text:'<=' , value:'<='},
                  {text:'>' , value:'>'},
                  {text:'>=' , value:'>='},
                  {text:'!=' , value:'!='},
                  {text:'Like' , value:'like'},
               ]
            if(this.operationValue === undefined || this.operationValue !== 'like') {
               this.switchSearchType = 'A';
               this.userSearch = '';
               this.numberValue = '';
               this.dateValue = '';
            } else if(this.operationValue === 'like') {
               this.switchSearchType = 'B'
               this.searchValue = '';
               this.numberValue = '';
               this.dateValue = '';
            }
         } else if(this.columnName === 'poyr' || this.columnName === 'pomth' || this.columnName === 'grosspay' || this.columnName === 'netpay' || this.columnName === 'aprvamt'|| this.columnName === 'aprvqty'|| this.columnName === 'reqamt'|| this.columnName === 'reqqty') {
            this.switchSearchType = 'C';
            this.operationValue = undefined;
            this.operationList = [
               {text:'=' , value:'='},
               {text:'<' , value:'<'},
               {text:'<=' , value:'<='},
               {text:'>' , value:'>'},
               {text:'>=' , value:'>='},
            ]
            this.searchValue = '';
            this.userSearch = '';
            this.dateValue = '';
         } else if(this.columnName === 'hiredate' || this.columnName === 'cesdate') {
            this.searchValue = '';
            this.userSearch = '';
            this.numberValue = '';
            this.operationValue = undefined;
            this.switchSearchType = 'D';
            this.operationList = [
               {text:'=' , value:'='},
               {text:'<' , value:'<'},
               {text:'<=' , value:'<='},
               {text:'>' , value:'>'},
               {text:'>=' , value:'>='},
            ]
         } else {
            this.operationValue = undefined;
            const vm = this;

            vm.loading = true;
            vm.searchList = [];
            
             this.$store.dispatch('post', {url: "/payrollenquiry/all", payload: {param: vm.formData} })
               .then((res) => {
                  let item = res.data.rec;
      
                  let data = item.map(val => val[vm.columnName]); // .map is to take only "data" for that column : item.map(psnname);

                  for( let i = 0; i < data.length; i++){
                     vm.searchList.push(data[i]);
                  }

                vm.loading = false;

               })
               .catch(function (err) {
                  vm.errMsg = err;
               });
            
            if(this.operationValue === undefined) {
               this.switchSearchType = 'A';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'!=' , value:'!='},
                  {text:'Like' , value:'like'},
               ]
               this.userSearch = '';
               this.numberValue = '';
               this.dateValue = '';
            } else if (this.operationValue === 'like') {
               this.switchSearchType = 'B';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'!=' , value:'!='},
                  {text:'Like' , value:'like'},
               ]
               this.searchValue = '';
               this.numberValue = '';
               this.dateValue = '';
            } else {
               this.switchSearchType = 'A';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'!=' , value:'!='},
                  {text:'Like' , value:'like'},
               ]
               this.userSearch = '';
               this.numberValue = '';
               this.dateValue = '';
            }
         }

         if(this.test[0] !== ''){
            this.test.splice(0, 1, this.columnName);
         } else {
            this.test.splice(0, 0, this.columnName);
         }
      },

      operationValue() {

         if(this.operationValue === undefined || this.operationValue !== 'like') {
            if(this.columnName === undefined) {
               this.switchSearchType = 'A';
               this.userSearch = '';
               this.numberValue = '';
               this.dateValue = '';
            } else if(this.columnName === 'poyr' || this.columnName === 'pomth' || this.columnName === 'grosspay' || this.columnName === 'netpay' || this.columnName === 'aprvamt'|| this.columnName === 'aprvqty'|| this.columnName === 'reqamt'|| this.columnName === 'reqqty') {
               this.switchSearchType = 'C';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'<' , value:'<'},
                  {text:'<=' , value:'<='},
                  {text:'>' , value:'>'},
                  {text:'>=' , value:'>='},
               ]
               this.searchValue = '';
               this.userSearch = '';
               this.dateValue = '';
            } else if(this.columnName === 'hiredate' || this.columnName === 'cesdate') {
               this.switchSearchType = 'D';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'<' , value:'<'},
                  {text:'<=' , value:'<='},
                  {text:'>' , value:'>'},
                  {text:'>=' , value:'>='},
               ]
               this.searchValue = '';
               this.userSearch = '';
               this.numberValue = '';
            } else {
               this.switchSearchType = 'A';
               this.operationList = [
                  {text:'=' , value:'='},
                  {text:'!=' , value:'!='},
                  {text:'Like' , value:'like'},
               ]
               this.userSearch = '';
               this.numberValue = '';
               this.dateValue = '';
            }
         } else {
            this.switchSearchType = 'B';
            this.searchValue = '';
            this.numberValue = '';
            this.dateValue = '';
         }

         if(this.test[1] !== '') {
            this.test.splice(1, 1, this.operationValue);
         } else {
            this.test.splice(1, 0, this.operationValue);
         }
      },

      searchValue() {
         if(this.searchValue !== '') {
            if(this.test[2] !== '') {
               this.test.splice(2, 1, "'"+ this.searchValue + "'");
            } else {
               this.test.splice(2, 0, "'"+ this.searchValue + "'");
            }
         } else {
            this.test[2] = undefined;
         }

      },

      userSearch() {
         if(this.userSearch !== '') {
            if(this.test[2] !== '') {
               this.test.splice(2, 1, "'"+ "%" + this.userSearch + "%" + "'");
            } else {
               this.test.splice(2, 0, "'"+ "%" + this.userSearch + "%" + "'");
            }
         } else {
            this.test[2] = undefined;
         }

      },

      numberValue() {
         this.spliceFunction(this.numberValue, this.test);
      },

      dateValue() {

         if(this.dateValue !== '') {
            if(this.test[2] !== '') {
               this.test.splice(2, 1, "'"+ this.dateValue + "'");
            } else {
               this.test.splice(2, 0, "'"+ this.dateValue + "'");
            }
         } else {
            this.test[2] = undefined;
         }
      },

      hideColumn() {

         for(let i = 0; i < this.column.length; i++){
            let arr = this.column[i].field;
            if(this.hideColumn.includes(arr)) {
               this.tabulator.hideColumn(arr);
            } else {
               this.tabulator.showColumn(arr);
            }
         }

      },

      userSeen() {

         this.userArr = [];
         
         if(this.refresh === false){
            this.userSql = '';
         }

         for(let i = 0; i < this.userSeen.length; i++) {
            
            if(this.userSeen[i] == ' AND ' || this.userSeen[i] == ' OR ') {
               console.log(this.userSeen[i]);
            } else {
               this.userArr.push(this.userSeen[i]);
            }
            
            this.userSql += this.userSeen[i];
         }
         
      }, 

   },

   methods: {

      // Column Name
      getColumnBy() {
         for(let i = 0; i < this.column.length; i++) {
            this.columnContent(this.groupList, i);
            this.columnContent(this.columnList, i);
            this.columnContent(this.hideList, i);
         }
      },

      addGroupBy() {
         if(this.groupName !== '') {
            this.userGroup.push(this.sqlSeenColumn(this.groupName));
            this.group.push(this.groupName); 
            this.tabulator.setGroupBy(this.group);
            this.groupName = '';
         }
      },

      clearGroupBy() {
         this.group = [];
         this.userGroup = [];
         this.tabulator.setGroupBy(this.group);
      },

      deleteGroupBy(x) {
         this.userGroup.splice(x,1);
         this.group.splice(x,1); // Removing Method
         this.tabulator.setGroupBy(this.group);
      },

      addSearchFilter() {
         
         if(this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined) {
            let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.userSeen.push(userSeen);
            this.userSeen.push(' AND ');
            let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.sqlConstruct.push(filter);
            this.sqlConstruct.push(' AND ');
            this.resetValue();
         } else {
            alert(this.$t('reportstaffenquiry.fillinfilter'));
         }

      },

      orFilter() {
        
       if(this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined) {
            let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.userSeen.push(userSeen);
            this.userSeen.push(' OR ');
            let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.sqlConstruct.push(filter);
            this.sqlConstruct.push(' OR ');
            this.resetValue();
         } else {
            alert(this.$t('reportstaffenquiry.fillinfilter'));
         }

      },

      deleteFilter(y) {

         if( this.userSeen[y] == ' AND ' || this.userSeen[y] == ' OR ' ) {
            console.log('Cannot be deleted');
         } else {

            if(this.userSeen.length < 3) {
               this.userSeen.splice(y,1); // Removing Method
               this.sqlConstruct.splice(y,1);
               this.userSeen.splice(y,1); 
               this.sqlConstruct.splice(y,1);
            } else {
               if( y === 0) {
                  this.userSeen.splice(y,1); // Removing Method
                  this.sqlConstruct.splice(y,1);
                  this.userSeen.splice(y,1); 
                  this.sqlConstruct.splice(y,1);
               } else {
                  this.userSeen.splice(y-1,1); // Removing Method
                  this.sqlConstruct.splice(y-1,1);
                  this.userSeen.splice(y-1,1); 
                  this.sqlConstruct.splice(y-1,1);
               }
               
            }
         }

      },

      removeFilter(x) {
         if(this.userSeen.includes(x)) {
            let i = this.userSeen.indexOf(x);
            this.deleteFilter(i);
         }
      },

      refreshTable() {

         if((this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined)) {
            let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.userSeen.push(userSeen);
            let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
            this.sqlConstruct.push(filter);
            this.sqlQuery(this.sqlConstruct);
            this.resetValue();
            this.refresh = !this.refresh;
            
            const vm = this;

            vm.loading = true;
            
            this.$store.dispatch('post', {url: "/payrollenquiry/filter" , payload: {param: vm.formData}})
               .then((res) => {
                  let data = res.data.rec;

                  for (let i = 0; i < data.length; i++) {
                     if (data[i].hiredate) {
                           data[i].hiredate = moment(data[i].hiredate).format('YYYY-MM-DD');
                     }
                     if (data[i].cesdate) {
                           data[i].cesdate = moment(data[i].cesdate).format('YYYY-MM-DD');
                     }
                  }

                  vm.tabulator.setData(data);
                  vm.sqlConstruct = [];
                  vm.formData.dummy = [];
                  vm.loading = false;

               })
               .catch(function (err) {
                  vm.errMsg = err;
               });

        } else if(this.sqlConstruct.length !== 0) {
            alert(this.$t('reportstaffenquiry.expectanotherfilter'));
        } else {
            alert(this.$t('reportstaffenquiry.fillinfilter'));
        }

      },

      clearTable() {
         this.userSeen = [];
         this.userArr = [];
         this.userSql = '';
         this.sqlConstruct = [];
         this.formData.dummy = [];
         this.tabulator.setData(this.tableData);
         this.refresh = false;
      },

      // Repeated Code Function

      columnContent(x, y) {
         x.push(
               {text: this.column[y].title, value: this.column[y].field}
         );
      },

      spliceFunction(value, store) {
         if(value !== '') {
            if(store[2] !== '') {
               store.splice(2, 1, value);
            } else {
               store.splice(2, 0, value);
            }
         } else {
            store[2] = undefined;
         }

      },

      sqlColumn(x) {
         if(x === 'payitemcatdesc' || x === 'payitemtypedesc' || x === 'payitemcode' || x === 'payitemdesc'|| x === 'aprvamt' || x === 'aprvqty' || x === 'reqamt' || x === 'reqqty' || x === 'srctbl' ) {
            return x = 'vp.' + x;
        //  } else if(x === 'staffcode' || x === 'compnyname' || x === 'headcount' || x === 'hiredate' || x === 'cessadate' || x === 'confirmdate' || x === 'servicedate') {
        //     return x = 'vs.' + x;
        //  } else if(x === 'age') {
        //     return x = 'format(datediff(curdate(), dob) / 365.25, 2)';
        //  } else if(x === 'sp') {
        //     return x = 'format(datediff(curdate(), vs.servicedate) / 365.25, 2)'
        //  } else if(x === 'salary') {
        //     return x = '(ROUND(newbasicsal, 2))'
         } else {
            return x = 'vh.' + x;
         }
      },

      sqlSeenColumn(x) {

         for(let i = 0; i < this.column.length; i++) {
            let field =  this.column[i].field;
            let title = this.column[i].title;

            if( field === x ) {
               return x = title;
            }
         } 

      },

    sqlQuery(x) {

         for(let i = 0; i < x.length; i++) {
            this.formData.dummy += x[i];
         }

      },

      resetValue() {
            this.columnName = undefined;
            this.operationValue = undefined;
            this.userSearch = undefined;
            this.dateValue = undefined;
            this.searchValue = undefined;
            this.numberValue = undefined;
      },

      // Axios Function

      getPayrollEnquiry() {

         const vm = this;

         vm.loading = true;

        //  this.$store.dispatch('post', {url: "/payoutctrl/select", payload: vm.getdate})
         this.$store.dispatch('post', {url: "/payrollenquiry/all", payload: {param: vm.formData} })
            .then((res) => {
               vm.tableData = res.data.rec;

               for (let i = 0; i < vm.tableData.length; i++) {
                  if (vm.tableData[i].hiredate) {
                        vm.tableData[i].hiredate = moment(vm.tableData[i].hiredate).format('YYYY-MM-DD');
                  }
                  if (vm.tableData[i].cesdate) {
                        vm.tableData[i].cesdate = moment(vm.tableData[i].cesdate).format('YYYY-MM-DD');
                  }
               }

               vm.tabulator = new Tabulator(vm.$refs.table, {
               
               reactiveData: true, // Reactive Data
               data: vm.tableData, // Table Data
               columns: vm.column, // Table columns      
               groupBy: vm.group, // Group By Function

               height:"350px",
               layout: "fitData",
               pagination: true,
               paginationSize: 10,
               paginationSizeSelector:[10, 25, 50, 100, true], 
               movableColumns: true,

               });

               vm.loading = false;

            })
            .catch(function (err) {
               vm.errMsg = err;
            });

            this.getColumnBy();
      },

      getCSV() {
         this.tabulator.download("csv", `${this.$t('payrollenquiryreport.payrollenquiry')}.csv`, {bom:true});
      },

      getXLSX() {
         this.tabulator.download("xlsx", `${this.$t('payrollenquiryreport.payrollenquiry')}.xlsx`, {});
      },

      backHandler(){
         this.$router.push({name: 'payrollenquiryfilter'});
      },
   },

   created() {

      const xlsx = document.createElement('script');  
      xlsx.setAttribute('src',"https://oss.sheetjs.com/sheetjs/xlsx.full.min.js");
      document.head.appendChild(xlsx);

   },

   mounted() {
      this.getPayrollEnquiry();
   },
}
</script>

<style scoped>
@import  "./../../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
</style>


