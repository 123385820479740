<template>
    <div>

 <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>

    </div>
   
</template>


<script>


import GridView from '../../../components/common/GridView'; 

export default {
    
    components: {
        'app-gridview': GridView
    },  

    data() {

         return {
            pageTitle: this.$t('mmpersontaxablerebatereg.mmpersontaxablerebatereglist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width: '100px' },
                { text: this.$t('columns.staffname'), value: 'name', width: '200px' },
                { text: this.$t('setuptaxablerebate.rebatetype'), value: 'rebatetypecode', width: '200px' },
                { text: this.$t('mmtaxperiodsetup.taxperiod'), value: 'taxperiod', width: '200px' },
                { text: this.$t('reportsparam.year'), value: 'rptyear', width: '200px' },
                { text: this.$t('reportsparam.month'), value: 'rptmonth', width: '200px' },
                { text: this.$t('trainingrecord.amount'), value: 'amount', width: '200px' },
                { text: this.$t('columns.currency'), value: 'crcycode', width: '200px' },
               
            ],
            dataItems: [],

            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }

    }, 

    methods: {
        //get all records 
        getTaxableRebateListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/mmpersontaxablerebatreg/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },

        editItemHandler(item) {
            this.$router.push({name: 'mmpersontaxablerebateregedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'mmpersontaxablerebateregnew'});
        }, 


    },

    mounted() {
       this.getTaxableRebateListHandler();
    }
}
</script>