<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                    <v-col cols="6" sm="6" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayitem.payitem')"
                            type="text"
                            dense
                            :items="payItemList"
                            :rules="[rules.required]"
                            v-model="formData.payitemid"
                            @change="
                                changePayItemCodeHandler(
                                    formData.payitemid
                                )
                            "                            
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" sm="6" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayitem.payitem')"
                            type="text"
                            dense
                            :items="payItemList"
                            :rules="[rules.required]"
                            v-model="formData.payitemid"
                            readonly
                            background-color="#ffeab2"                          
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="formData.payitemcode"
                            @input="updateValue"
                        ></v-text-field>
                    </v-col>                    
                </v-row>

                <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            type="date"
                            dense
                            v-model="formData.startdate"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            type="date"
                            dense
                            v-model="formData.enddate"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuppayrolltype.payrolltype')"
                            type="text"
                            :items="payrollTypeList"
                            :rules="[rules.required]"
                            v-model="formData.payrolltypeid"
                            dense
                            @change="
                            changePayrollTypeCodeHandler(
                                formData.payrolltypeid
                            )
                            "
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="formData.payrolltypecode"
                            @input="updateValue"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.currency')"
                            type="text"
                            dense
                            v-model="formData.crcyid"
                            :items="currencyList"
                            @change="changeCurrencyHandler(formData.crcyid)"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="currencyCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="currencyDialog" max-width="600px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: currencyItems,
                                        formTitle: `${this.$t('columns.currency')}`,
                                        formId: 'currency'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveCurrency="saveCurrencyHandler"
                                    v-on:deleteCurrency="deleteCurrencyHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="currencyDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="10" md="10" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            dense
                            v-model="formData.remark"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-toolbar-title class="pl-5 pt-4 subtitle-2">{{$t('payeeinputprocess.details')}}</v-toolbar-title>

                <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.grouppaymentdetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: 'Payee Input Dashboard',
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                grouppaymentdetail: this.parentObject.data.grouppaymentdetail,
                payitemid: this.parentObject.data.payitemid,
                payitemcode:this.parentObject.data.payitemcode,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                payrolltypeid: this.parentObject.data.payrolltypeid,
                payrolltypecode:this.parentObject.data.payrolltypecode,
                crcyid: this.parentObject.data.crcyid,
                crcydesc: this.parentObject.data.crcydesc,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                
                userid: this.parentObject.data.userid,
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setuppersonpayrolldirectassignment.amount'), value: 'amount', width: '100px', sortable: false},
                { text: this.$t('columns.company'), value: 'compnycode', width: '100px', sortable: false},
                { text: this.$t('columns.branch'), value: 'brchcode', width: '100px', sortable: false },
                { text: this.$t('columns.division'), value: 'divncode', sortable: false, width: '100px' },
                { text: this.$t('columns.department'), value: 'deptcode', width: '100px', sortable: false},
                { text: this.$t('columns.section'), value: 'sectiocode', width: '100px', sortable: false },
                { text: this.$t('columns.unit'), value: 'unitcode', width: '100px', sortable: false},
                { text: this.$t('columns.employmenttype'), value: 'emptypecode', width: '100px', sortable: false },
                { text: this.$t('columns.grade'), value: 'gradecode', sortable: false, width: '100px' },
                { text: this.$t('columns.job'), value: 'jobcode', width: '100px', sortable: false},
                { text: this.$t('columns.position'), value: 'positioncode', width: '100px', sortable: false },
                { text: this.$t('setupepf.serviceperiod'), value: 'serviceperiod', sortable: false, width: '100px' },                
            ],
            currencyCode: '',
            
            payItemList: [],
            payrollTypeList: [],
            currencyList: [],
            currencies: [],
            currencyItems: [],
            
            currencyDialog: false,
            currencyLoading: true,
            errMsg: '',
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadPayItemsHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payitem/codes'})
                .then(res => {
                   vm.payitem = res.data.rec;                    

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemList.push(
                            {
                                text: res.data.rec[i].payitemdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    } 
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPayrollType() {
            const vm = this

            this.$store.dispatch('get', {
                url: '/payrolltype/all'})
            .then (res => {
                vm.payrollType = res.data.rec

                vm.payrollType.forEach(element => {
                    vm.payrollTypeList.push(
                        {
                            text: element.payrolltypedesc,
                            value: element.id
                        }
                    )
                });
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        // loading the content of currency from the database, providing the name of the currency on the dropdown box
        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        addItemHandler($event) {
            this.formData.grouppaymentdetail=$event
        },

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                grouppaymentdetail: this.formData.grouppaymentdetail,
                payitemid: this.formData.payitemid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                payrolltypeid: this.formData.payrolltypeid,
                crcyid: this.formData.crcyid,
                crcydesc: this.formData.crcydesc,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changePayItemCodeHandler(id) {
            let item = this.payitem.find(item => item.id == id);

            this.formData.payitemcode = item.payitemcode;
        },

        changePayrollTypeCodeHandler(id) {
            let item = this.payrollType.find(item => item.id == id);

            this.formData.payrolltypecode = item.payrolltypecode;
        },

        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/currency/' + this.itemData.itemid, 
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/currency/create', 
                    payload: {
                        crcycode: this.itemData.itemcode,
                        crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCurrencyHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/currency/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        genCurrencyTabItems() {
            for (let i = 0; i < this.currencies.length; i++) {
                this.currencyItems.push(
                    {
                        id: this.currencies[i].id,
                        code: this.currencies[i].crcycode,
                        desc: this.currencies[i].crcydesc,
                        stamp: this.currencies[i].stamp
                    }
                )
            }
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'grouppaymentlist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadPayItemsHandler();
        this.loadPayrollType();
        this.loadCurrencyListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>