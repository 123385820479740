<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle
              }}<span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
          :style="{
            'max-height': `${filterSectionHeight}px`,
          }"
        > -->
        <v-card class="mt-n6">
          <div class="ma-4">
            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
            <v-row>
              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.year')"
                  type="text"
                  hide-selected
                  v-model="formData.recyear"
                  dense
                  :items="years"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="12" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('alltax.nameofofficer')"
                  type="text"
                  ref="nameofofficeer"
                  v-model="formData.nameofofficeer"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="12" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('statutoryexportcommonused.designation')"
                  type="text"
                  ref="designation"
                  v-model="formData.designation"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="12" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('alltax.telephoneno')"
                  type="text"
                  ref="telephoneno"
                  v-model="formData.telephoneno"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card>
          <div class="ma-4">
            <v-subheader class="blue--text mb-5"
              >{{$t('alltax.reportgenerationcriteria')}}</v-subheader
            >
            <v-row>
              <v-col cols="4" sm="6" class="pb-n5 mt-n8">
                <v-checkbox
                  :label="this.$t('statutoryexportcommonused.includestaffwithzerotax')"
                  v-model="formData.inclzerotax"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <app-parameter
          class="mt-5 mb-3"
          v-on:input="parameters = $event"
          v-bind:parentObject="{
            filter: this.showFilter,
          }"
        />
        <!-- </v-container> -->

        <v-row>
          <v-col>
            <v-btn color="warning" style="width: 100px; margin-bottom: 20px;" @click="displayHandler">
              {{$t('button.print')}} 
            </v-btn>
            <v-btn color="warning" style="width: 200px; margin-bottom: 20px;" class="ml-5" @click="emailHandler">
              {{$t('button.generateandemail')}} 
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import FilterParameter from "../../../components/common/FilterParameter";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-parameter": FilterParameter,
    Loading,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      formData: {
        recyear: this.parentObject.data.recyear,
        inclzerotax: this.parentObject.data.inclzerotax,
        nameofofficeer: this.parentObject.data.nameofofficeer,
        designation: this.parentObject.data.designation,
        telephoneno: this.parentObject.data.telephoneno,
        reporturl: "",
        userid: "",
        companyid: "",
        staffid: "",
        staff: ""
      },
      parameters: {
        staffid: "",
        gradeid: "",
        employmenttypeid: "",
        companyid: "",
        branchid: "",
        departmentid: "",
      },
      showFilter: {
        staffid: true,
        gradeid: false,
        employmenttypeid: false,
        companyid: true,
        branchid: false,
        departmentid: false,
      },
      isErr: false,
      years: [],
      reportFile: "PCB2II.rptdesign",
      loading: false,
    };
  },
  computed: {
    filterSectionHeight() {
      const filterSectionDOM = document.getElementById("menu");
      return filterSectionDOM ? filterSectionDOM.offsetHeight - 100 : 0;
    },
  },
  methods: {
    loadYears() {
      const vm = this;
      for (let i = 0; i < 200; i++) {
        vm.years.push(2020 + i);
      }
    },
    emailHandler() {
      const vm = this;
      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation)
      ) {
        this.clearFilter();
        this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
        this.formData.userid = this.$store.getters.userId;
        this.formData.staffid = this.parameters.staffid;
        this.formData.staff = this.parameters.staffid;
        this.formData.companyid = this.parameters.companyid;

        this.$store.dispatch('post', {url: "/reportemail/pcbii",
            payload: this.formData})
          .then((res) => {
            if (res.data.status == 201) {
              console.log(res.data.status);
              this.$dialog.alert(this.$t('columns.emailsuccessend')).then((dialog) => {
                console.log(dialog);
              });
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },
    displayHandler() {
      const vm = this;
      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation)
      ) {
        vm.loading = true;
        this.clearFilter();
        this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
        this.formData.userid = this.$store.getters.userId;
        this.formData.staffid = this.parameters.staffid;
        this.formData.staff = this.parameters.staffid;
        this.formData.companyid = this.parameters.companyid;

        this.$store.dispatch('post', {url: "/reportdisplay/pcbii",
            payload: this.formData,
            responseType: "blob"})
          .then((res) => {
            console.log(res.data);
            vm.loading = false;
            if (res.data && res.status == 200) {
              var fileURL = URL.createObjectURL(res.data);
              window.open(fileURL, "_blank");
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.loading = false;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },
    goHandler() {
       if ((this.$refs.form.validate())){
      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation)
      ) {
        this.clearFilter();
        window.open(
          `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&Year=${this.formData.recyear}&officer=${this.formData.nameofofficeer}` +
            `&dbname=${this.$store.getters.dbname}` +
            `&designation=${this.formData.designation}&telephone=${this.formData.telephoneno}&IsIncludeZeroTax=${this.formData.inclzerotax}&userid=${this.$store.getters.userId}` +
            `&companyid=${this.parameters.companyid}&staffid=${this.parameters.staffid}`,
          "_blank" // <- This is what makes it open in a new window.
        );
      }

      }
      //   window.open(
      //     "http://localhost:8080/birt/frameset?__report=PCB2II.rptdesign&__format=pdf&year=" +
      //       this.formData.recyear,
      //     "_blank" // <- This is what makes it open in a new window.
      //   );
    },
    updateValue() {
      this.$emit("input", {
        recyear: this.formData.recyear,
        inclzerotax: this.formData.inclzerotax,
        nameofofficeer: this.formData.nameofofficeer,
        designation: this.formData.designation,
        telephoneno: this.formData.telephoneno,
      });
    },
    clearFilter() {
      if (typeof this.formData.staffid == "undefined" || this.formData.staffid == null) {
        this.formData.staffid = "";
      }
      if (typeof this.formData.companyid == "undefined" || this.formData.companyid == null) {
        this.formData.companyid = "";
      }
    }
  },

  mounted() {
    this.formData.inclzerotax = false;
    this.loadYears();
  },
};
function CheckIsEmpty(value) {
  var isEmpty = false;
  if (typeof value == "undefined" || value == "") {
    isEmpty = true;
  }
  return isEmpty;
}
</script>
<style lang="scss" scoped>
.v-subheader {
  padding: 0px !important;
}
</style>
