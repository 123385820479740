<template>
  <div>
    <div v-if="isErr">
      <app-error
      v-bind:parentError="{
        data: this.errMsg,
        isErr: this.isErr,
      }"
      v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import moment from 'moment';

import LetterForm from "../../../components/settings/letter/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LetterForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('letterlist.editletter'),
      pageTitleValue: "",
      details: [],
      formData: [],
      errMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (this.formData[0].isdisable == false) {
        this.formData[0].isdisable = 0;
      } else {
        this.formData[0].isdisable = 1;
      }

      var payload = this.formData[0];
      this.$store.dispatch('put', {url: "/letter/" + vm.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "letterlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler($event) {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/letter/" +
              this.id}
          )
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "letterlist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "letternew" });
    },

    loadletterHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/letter/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData = res.data.rec;
            vm.pageTitleValue = vm.formData[0].templatename;

            if (vm.formData[0].disable === "N") {
              vm.formData[0].isdisable = false;
            } else {
              vm.formData[0].isdisable = true;
            }

            vm.formData[0].userid = this.$store.getters.userId;
            vm.formData[0].content = vm.formData[0].detail[0] ? vm.formData[0].detail[0].content : '';
            
            vm.details = vm.formData[0].detail.map(e => {
              return {
                id: e.id,
                templateid: e.templateid,
                pagenumber: e.pagenumber,
                content: e.content,
                stamp: e.stamp
            }});

            vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadletterHandler();
  },
};
</script>