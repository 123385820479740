var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-form',[_c('v-row',[_c('h2',{staticClass:"pb-5"},[_vm._v(_vm._s(this.parentObject.pageTitle)),_c('span',{staticClass:"page_title_value"},[_vm._v(_vm._s(this.parentObject.pageTitleValue))])])]),_c('v-container',{staticClass:"custom-form overflow-y-auto px-5",attrs:{"id":"scroll-target"}},[_c('v-row',[(!this.formData.lockstaff)?_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('setuppayitem.payitem'),"type":"text","dense":"","items":_vm.payItemList,"rules":[_vm.rules.required]},on:{"change":function($event){return _vm.changePayItemCodeHandler(
                                _vm.formData.payitemid
                            )}},model:{value:(_vm.formData.payitemid),callback:function ($$v) {_vm.$set(_vm.formData, "payitemid", $$v)},expression:"formData.payitemid"}})],1):_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('setuppayitem.payitem'),"type":"text","dense":"","items":_vm.payItemList,"rules":[_vm.rules.required],"readonly":"","background-color":"#ffeab2"},model:{value:(_vm.formData.payitemid),callback:function ($$v) {_vm.$set(_vm.formData, "payitemid", $$v)},expression:"formData.payitemid"}})],1),_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"2","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","label":this.$t('columns.code'),"outlined":"","readonly":"","background-color":"#ffeab2"},on:{"input":_vm.updateValue},model:{value:(_vm.formData.payitemcode),callback:function ($$v) {_vm.$set(_vm.formData, "payitemcode", $$v)},expression:"formData.payitemcode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.startdate'),"type":"date","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.startdate),callback:function ($$v) {_vm.$set(_vm.formData, "startdate", $$v)},expression:"formData.startdate"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.enddate'),"type":"date","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.enddate),callback:function ($$v) {_vm.$set(_vm.formData, "enddate", $$v)},expression:"formData.enddate"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-n5 mt-n8",attrs:{"cols":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('setuppayrolltype.payrolltype'),"type":"text","items":_vm.payrollTypeList,"rules":[_vm.rules.required],"dense":""},on:{"change":function($event){return _vm.changePayrollTypeCodeHandler(
                            _vm.formData.payrolltypeid
                        )}},model:{value:(_vm.formData.payrolltypeid),callback:function ($$v) {_vm.$set(_vm.formData, "payrolltypeid", $$v)},expression:"formData.payrolltypeid"}})],1),_c('v-col',{staticClass:"pb-n5 mt-n8",attrs:{"cols":"2","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","label":this.$t('columns.code'),"outlined":"","readonly":"","background-color":"#ffeab2"},on:{"input":_vm.updateValue},model:{value:(_vm.formData.payrolltypecode),callback:function ($$v) {_vm.$set(_vm.formData, "payrolltypecode", $$v)},expression:"formData.payrolltypecode"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('columns.currency'),"type":"text","dense":"","items":_vm.currencyList},on:{"change":function($event){return _vm.changeCurrencyHandler(_vm.formData.crcyid)}},model:{value:(_vm.formData.crcyid),callback:function ($$v) {_vm.$set(_vm.formData, "crcyid", $$v)},expression:"formData.crcyid"}})],1),_c('v-col',{staticClass:"pb-n4 mt-n8",attrs:{"cols":"2","sm":"2"}},[_c('v-text-field',{attrs:{"dense":"","label":this.$t('columns.code'),"outlined":"","readonly":"","background-color":"#ffeab2"},model:{value:(_vm.currencyCode),callback:function ($$v) {_vm.currencyCode=$$v},expression:"currencyCode"}})],1),_c('v-col',{staticClass:"pb-n4 mt-n8",attrs:{"cols":"2","sm":"2"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dense":""}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}]),model:{value:(_vm.currencyDialog),callback:function ($$v) {_vm.currencyDialog=$$v},expression:"currencyDialog"}},[_c('v-card',[_c('app-genericform',{attrs:{"parentData":{
                                    tabItems: _vm.currencyItems,
                                    formTitle: `${this.$t('columns.currency')}`,
                                    formId: 'currency'
                                }},on:{"input":function($event){_vm.itemData = $event},"saveCurrency":_vm.saveCurrencyHandler,"deleteCurrency":_vm.deleteCurrencyHandler}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.currencyDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.remark'),"type":"text","dense":""},model:{value:(_vm.formData.remark),callback:function ($$v) {_vm.$set(_vm.formData, "remark", $$v)},expression:"formData.remark"}})],1)],1),_c('v-toolbar-title',{staticClass:"pl-5 pt-4 subtitle-2"},[_vm._v(_vm._s(_vm.$t('payeeinputprocess.details')))]),_c('v-row',[_c('v-col',{staticClass:"pb4-4",attrs:{"col":"12","sm":"12"}},[_c('app-table',{attrs:{"parentObject":{
                            tabHeader: this.tabHeader,
                            detailItem: this.formData.grouppaymentdetail
                        }},on:{"input":_vm.addItemHandler}})],1)],1)],1),_c('app-formbuttons',{attrs:{"EditBool":this.parentObject.isEdit},on:{"NewPressed":_vm.newHandler,"SavePressed":_vm.saveHandler,"DeletePressed":_vm.deleteHandler,"CancelPressed":_vm.cancelHandler}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }