<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import Wizard from "../../../components/settings/wizard/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": Wizard,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
       search: this.$route.params.search,
      pageTitle: this.$t('setupwizardsetup.editwizard'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadWizardHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/wizardsetup/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          if (res.data.rec[0].disable == "N") {
            vm.formData.disable = false;
          } else {
            vm.formData.disable = true;
          }

          vm.formData.userid = this.$store.getters.userId;
          vm.pageTitleValue = res.data.rec[0].wizardcode;

          // vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadWizardSetupDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/wizardsetupd/" + this.id})
        .then((res) => {
          vm.formData.wizarddetail = res.data.rec;

          for (let i = 0; i < vm.formData.wizarddetail.length; i++) {
            Object.assign(vm.formData.wizarddetail[i], {
              action: 2,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/wizardsetup/" + vm.formData.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "wizardlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "wizardlist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/wizardsetup/" +
            vm.formData.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "wizardlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "wizardnew" });
    },
  },

  mounted() {
    this.loadWizardHandler();
    this.loadWizardSetupDetailHandler();
  },
};
</script>