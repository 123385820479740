    <template>
  <div>
    <v-dialog v-model="payeeinputdialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-row>
          <v-col cols="1">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </v-col>

          <v-col>
            <!-- <v-file-input 
                            ref="attach"
                            :label="Import"
                            outlined 
                            v-model="fileHolder"
                            @change="fileToBase64"
                            dense>
                        </v-file-input> -->
            <v-row>
              <v-col class="mt-n1">
                <input
                  id="example-file"
                  type="file"
                  @change="previewFiles"
                  multiple
                />
              </v-col>
              <v-col v-show="file" class="mt-n1">
                <v-icon small class="ml-3 mr-2" @click="removeItemsHandler">
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  style="width: 250px"
                  class="pr-5 pt-3 "
                  hide-details
              ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-btn color="primary" dark class="mb-2" @click="deleteHandler"
              >{{$t('payeeinputprocess.removedocument')}}</v-btn
            >
          </v-col>

          <!-- <v-col cols="2">
                        <div v-if="attachment" align="right">
                        <v-row>
                            <v-col>
                            <p>
                                <a 
                                v-bind:href="attachment" 
                                v-bind:download="attachmentname"
                                >{{attachmentname}}</a>
                                <v-icon
                                    small
                                    class="ml-3 mr-2"
                                    @click="deleteAttachment"
                                >
                                    mdi-delete
                                </v-icon>
                            </p>
                            </v-col>
                        </v-row>
                        </div>
                    </v-col> -->
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">{{$t('payeeinputprocess.payeeinputitem')}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.name')"
                  type="text"
                  dense
                  v-model="selections[0].staffid"
                  :items="staffDetailsList"
                  :rules="[rules.required]"
                  @change="
                    changeStaffIDandCompanyHandler(selections[0].staffid)
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.staffid')"
                  type="text"
                  dense
                  v-model="selections[0].staffcode"
                  background-color="#ffeab2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.company')"
                  type="text"
                  dense
                  v-model="selections[0].compnyname"
                  background-color="#ffeab2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('setuppayitem.payitem')"
                  type="text"
                  dense
                  :items="payItemList"
                  :rules="[rules.required]"
                  v-model="selections[0].payitemid"
                  @change="changePayItemHandler(selections[0].payitemid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.startdate')"
                  type="date"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].startdate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.enddate')"
                  type="date"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].enddate"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('payeeinputprocess.action')"
                  type="text"
                  dense
                  v-model="selections[0].payeeactionid"
                  :items="actionList"
                  :rules="[rules.required]"
                  @change="changeActionHandler(selections[0].payeeactionid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('payeeinputprocess.requestedamount')"
                  type="number"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].reqamt"
                  @input="changeAppAmtHandler(selections[0].reqamt)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('payeeinputprocess.requestedquantity')"
                  type="number"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].reqqty"
                  @input="changeAppQtyHandler(selections[0].reqqty)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('payeeinputprocess.approvedamount')"
                  type="number"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].aprvamt"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('payeeinputprocess.approvedquantity')"
                  type="number"
                  dense
                  :rules="[rules.required]"
                  v-model="selections[0].aprvqty"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.currency')"
                  type="text"
                  ref="crcyid"
                  :items="currencyList"
                  hide-selected
                  v-model="selections[0].crcyid"
                  @change="changeCurrencyHandler(selections[0].crcyid)"
                  :rules="[rules.required]"
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('payeeinputprocess.justification')"
                  type="text"
                  dense
                  v-model="selections[0].justification"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.remark')"
                  type="text"
                  dense
                  v-model="selections[0].remark"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('setuppayitmetype.payitemtype')"
                  type="text"
                  dense
                  background-color="#ffeab2"
                  v-model="selections[0].payitemtypedesc"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="isErr" class="mt-n12">
          <span style="font-weight: bold; color: red">{{ errMsg }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closePayeeInput"
            >{{$t('button.cancel')}}</v-btn
          >
          <v-btn color="green darken-1" text @click="savePayeeInput"
            >{{$t('button.save')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :search="search"
      :headers="this.parentObject.tabHeader"
      :items="detailItem"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isSuccess">
      <app-success
        v-bind:parentObject="{
          isSuccess: this.isSuccess,
          messageSuccess: this.messageSuccess,
        }"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          messageConfirm: this.messageConfirm,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="resetFileHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
//import VueCsvImport from 'vue-csv-import';
import compareArray from "../../../util/comparearray";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import SuccessDialog from "../../../components/common/SuccessDialog";
//import PayeeInputForm from "../../../components/payroll/payeeinput/Form";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    //'csv-import': VueCsvImport,
    "app-error": ErrorDialog,
    "app-success": SuccessDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      search: "",
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || "Value is 1-100",
      },

      //  formData: {
      //   // pystatuscode: this.parentObject.data.pystatuscode,
      //    pystatusdesc: this.parentObject.data.pystatusdesc,
      //  },

      selections: [
        {
          id: null,
          psnname: null,
          staffid: null,
          staffcode: null,
          compnyid: null,
          compnyname: null,
          payitemid: null,
          payitemcode: null,
          payitemdesc: null,
          payitemtypeid: null,
          payitemtypedesc: null,
          startdate: null,
          enddate: null,
          payeeactionid: 1,
          actioncode: null,
          reqamt: null,
          reqqty: null,
          aprvamt: null,
          aprvqty: null,
          crcyid: 1,
          crcycode: null,
          crcydesc: null,
          justification: null,
          remark: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ],
      headercsv: [
        "staffcode",
        "actioncode",
        "payitemcode",
        "startdate",
        "enddate",
        "reqamt",
        "reqqty",
        "aprvamt",
        "aprvqty",
        "crcycode",
        "justification",
        "remark",
      ],

      fileHolder: null,
      itemType: null,
      isPayeeInput: false,
      payeeinputdialog: false,
      detailItem: this.parentObject.detailItem,
      staffDetailsList: [],
      payItemList: [],
      currencyList: [],
      actionList: [],
      currencies: [],
      attachment: "",
      attachmentname: "",
      file: null,

      messageSuccess: "",
      messageConfirm: "",
      isConfirm: false,
      confirmDelete: false,
      isSuccess: false,
      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    compareArray,

    previewFiles($event) {
      this.file = $event.target.files[0];

      if (this.file) {
        var reader = new FileReader();

        reader.onloadend = () => {
          var b64 = reader.result;
          var arr = b64.split(",");
          var csvholder = atob(arr[1]);
          let getcsv = csvholder.split("\n");
          // let csvheader = getcsv[0].split(',')
          let getdata = [];
          for (let i = 1; i < getcsv.length; i++) {
            let csvdata = getcsv[i].split(",");
            let obj = {};

            if (csvdata != "") {
              for (let j = 0; j < csvdata.length; j++) {
                let key = this.headercsv[j].replace(/\r?\n|\r/, "");
                obj[key] = csvdata[j].replace(/\r?\n|\r/, "");

                if (key == "startdate" || key == "enddate") {
                  obj[key] = moment(obj[key], "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  );
                }

                if (
                  (key == "reqamt" && obj[key] == "") ||
                  (key == "aprvamt" && obj[key] == "")
                ) {
                  obj[key] = 'empty';
                }

                if (
                  (key == "reqqty" && obj[key] == "") ||
                  (key == "aprvqty" && obj[key] == "")
                ) {
                  obj[key] = 'empty';
                }
              }
              
              if (obj.staffcode) {
                obj.staffid = this.getstaffid(obj.staffcode);
                obj.psnname = this.getpsnname(obj.staffcode);
              }

              if (obj.payitemcode)
                obj.payitemid = this.getpayitemid(obj.payitemcode);
              if (obj.actioncode)
                obj.payeeactionid = this.getpayeeactionid(obj.actioncode);
              if (obj.crcycode) obj.crcyid = this.getcrcyid(obj.crcycode);
              obj.source = "import";
              obj.action = 1;

              for (const key in obj) {
                if (obj[key] == 'err' || obj[key] == undefined || obj[key] == "Invalid date"){
                  this.isErr = true;
                  this.errMsg = "There is error on line " + i + " at " + key + ". Import abort";
                  this.removeItemsHandler();
                  return;
                }
                if (obj[key] == 'empty') {
                  obj[key] = null
                }
              }
              if (obj.reqamt && obj.aprvamt == null) {
                obj.aprvamt = obj.reqamt
              }

              if (obj.reqqty && obj.aprvqty == null) {
                obj.aprvqty = obj.reqqty
              }
              
              getdata.push(obj);
            }
          }
          this.attachment = b64;
          getdata.forEach((element) => {
            this.detailItem.push(element);
          });
          this.isSuccess = true;
          this.messageSuccess =
            "Successfully import " + getdata.length + " records";
          this.$emit("input", this.detailItem);
        };
      }

      reader.readAsDataURL(this.file);
    },

    // fileToBase64() {
    //   if (this.fileHolder) {
    //     var file = this.$refs.attach.internalValue;
    //     this.attachmentname = this.fileHolder.name

    //     var reader = new FileReader();

    //     reader.onloadend = () => {
    //         var b64 = reader.result;
    //         var arr = b64.split(',')
    //         var csvholder = atob(arr[1])
    //         let getcsv = csvholder.split('\n')
    //         let csvheader = getcsv[0].split(',')
    //         let getdata = []
    //         for (let i = 1; i < getcsv.length; i++) {
    //             let csvdata = getcsv[i].split(',')
    //             let obj = {}
    //             for (let j = 0; j < csvdata.length; j++) {
    //                 let key = csvheader[j].replace(/\r?\n|\r/,'')
    //                 obj[key] = csvdata[j].replace(/\r?\n|\r/,'')

    //                 if (key == 'startdate' || key == 'enddate') {
    //                     obj[key] = moment(obj[key], 'DD-MM-YYYY').format('YYYY-MM-DD');
    //                 }

    //                 if ((key == 'reqamt' && obj[key] == '') || (key == 'aprvamt' && obj[key] == '')) {
    //                     obj[key] = 0
    //                 }

    //                 if ((key == 'reqqty' && obj[key] == '') || (key == 'aprvqty' && obj[key] == '')) {
    //                     obj[key] = 0
    //                 }
    //             }

    //             if (obj.staffcode) {
    //                 obj.staffid = this.getstaffid(obj.staffcode)
    //                 obj.psnname = this.getpsnname(obj.staffcode)
    //             }

    //             if (obj.payitemcode) obj.payitemid = this.getpayitemid(obj.payitemcode)
    //             if (obj.actioncode) obj.payeeactionid = this.getpayeeactionid(obj.actioncode)
    //             if (obj.crcycode) obj.crcyid = this.getcrcyid(obj.crcycode)
    //             obj.source = 'import'
    //             obj.action = 1;

    //             getdata.push(obj)
    //         }
    //         this.attachment = b64
    //         getdata.forEach(element => {
    //             this.detailItem.push(element)
    //         });
    //         this.$emit('input', this.detailItem);
    //     }

    //     reader.readAsDataURL(file);
    //   }
    // },

    deleteAttachment() {
      this.attachment = "";
      this.attachmentname = "";
    },

    deleteHandler() {
      this.isConfirm = true;
      this.messageConfirm = this.$t('payeeinputprocess.payeeinputmsg4')
        ;
    },

    removeItemsHandler() {
      $("#example-file").val("");
      this.file = "";
      if (this.isConfirm) this.isConfirm = !this.isConfirm;
    },

    resetFileHandler() {
      this.removeItemsHandler();
      this.detailItem = this.detailItem.filter(function (i) {
        return !(i.source == "import" && i.action == 1);
      });
      this.$emit("input", this.detailItem);
    },

    getstaffid(staffcode) {
      let item = this.staffdetails.find((item) => item.staffcode == staffcode);
      if (item) {
        return item.id;
      } else {
        return "err";
      }
    },

    getpsnname(staffcode) {
      let item = this.staffdetails.find((item) => item.staffcode == staffcode);
      if (item) {
        return item.psnname;
      } else {
        return "err";
      }
    },

    getpayitemid(payitemcode) {
      let item = this.payItems.find((item) => item.payitemcode == payitemcode);
      if (item) {
        return item.id;
      } else {
        return "err";
      }
    },

    getpayeeactionid(actioncode) {
      let item = this.actionItems.find((item) => item.actioncode == actioncode);
      if (item) {
        return item.id;
      } else {
        return "err";
      }
    },

    getcrcyid(crcycode) {
      let item = this.currencies.find((item) => item.crcycode == crcycode);
      if (item) {
        return item.id;
      } else {
        return "err";
      }
    },

    loadStaffDetailsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPayItemsHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payitem/codes"})
        .then((res) => {
          vm.payItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.payItemList.push({
              text: res.data.rec[i].payitemdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPayItemTypesHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payitemtype/all"})
        .then((res) => {
          vm.payItemTypes = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    modifyItemHandler(item) {
      // assign loaded data to selections array

      if (item.action == 1) {
        this.modify = true;
      }
      if (item.action == 2 || item.action == 3) {
        item.action = 2
        this.isExisting = true;
      } 
      // this.selections = [
      //   {
      //     id: item.id,
      //     staffid: item.staffid,
      //     compnyid: item.compnyid,
      //     payitemid: item.payitemid,
      //     payitemtypeid: item.payitemtypeid,
      //     startdate: item.startdate,
      //     enddate: item.enddate,
      //     payeeactionid: item.payeeactionid,
      //     reqamt: item.reqamt,
      //     reqqty: item.reqqty,
      //     aprvamt: item.aprvamt,
      //     aprvqty: item.aprvqty,
      //     crcyid: item.crcyid,
      //     justification: item.justification,
      //     remark: item.remark,
      //   },
      // ];

      this.selections[0] =  item;
      this.selections[0].reqamt = this.selections[0].aprvamt

      this.payeeinputdialog = true;

      if (item.staffid) this.changeStaffIDandCompanyHandler(item.staffid);
      if (item.payitemid) this.changePayItemHandler(item.payitemid);
      if (item.crcyid) this.changeCurrencyHandler(item.crcyid);
    },

    deleteItemHandler(item) {
      let itemIndex = this.detailItem.indexOf(item);

      this.detailItem.splice(itemIndex, 1);
    },

    loadActionHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payeeaction/all"})
        .then((res) => {
          vm.actionItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.actionList.push({
              text: res.data.rec[i].actioncode,
              value: res.data.rec[i].id,
            });
          }

          this.changeActionHandler(this.selections[0].payeeactionid);
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadCurrencyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/currency/all"})
        .then((res) => {
          vm.currencies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.currencyList.push({
              text: res.data.rec[i].crcydesc,
              value: res.data.rec[i].id,
            });
          }

          this.changeCurrencyHandler(this.selections[0].crcyid);
          vm.currencyLoading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    closePayeeInput() {
      this.payeeinputdialog = false;
      this.isErr = false;
      this.isExisting = false;
      this.modify = false;

      // reset the form fields
      this.resetSelections();
    },
    // Saving payeeinput details into payeeinputd
    savePayeeInput() {
      this.isErr = false;

      if(!this.selections[0].staffid || !this.selections[0].payitemid 
      || !this.selections[0].payeeactionid || !this.selections[0].crcyid
      || !this.selections[0].startdate || !this.selections[0].enddate){
        this.isErr = true;
        this.errMsg = this.$t('payeeinputprocess.payeeinputmsg3');
        return;
      }

      if(!this.selections[0].reqamt && !this.selections[0].reqqty){
        this.isErr = true;
        this.errMsg = this.$t('payeeinputprocess.payeeinputmsg2');
        return;
      }

      if (!this.isExisting && this.modify) {
                this.resetSelections();
            } 

      else if (!this.isExisting) {
        this.selections[0].id = null;
        this.selections[0].source = "input";
        this.selections[0].action = 1;
        this.detailItem.push(Object.assign({}, this.selections[0]));
      } else {
        this.detailItem.forEach((element) => {
          if (element.id == this.selections[0].id) {
            (element.staffid = this.selections[0].staffid),
            (element.compnyid = this.selections[0].compnyid),
            (element.payitemid = this.selections[0].payitemid),
            (element.payitemtypeid = this.selections[0].payitemtypeid),
            (element.startdate = this.selections[0].startdate),
            (element.enddate = this.selections[0].enddate),
            (element.payeeactionid = this.selections[0].payeeactionid),
            (element.reqamt = this.selections[0].reqamt),
            (element.reqqty = this.selections[0].reqqty),
            (element.aprvamt = this.selections[0].aprvamt),
            (element.aprvqty = this.selections[0].aprvqty),
            (element.crcyid = this.selections[0].crcyid),
            (element.justification = this.selections[0].justification),
            (element.remark = this.selections[0].remark);
          }
        });
      }

      for (let i = 0; i < this.detailItem.length; i++) {
        if (this.detailItem[i].id == this.selections[0].id) {
          if (this.detailItem[i].reqamt === '') this.detailItem[i].reqamt = null
          if (this.detailItem[i].reqqty === '') this.detailItem[i].reqqty = null
          if (this.detailItem[i].aprvamt === '') this.detailItem[i].aprvamt = null
          if (this.detailItem[i].aprvqty === '') this.detailItem[i].aprvqty = null
        }
      }

      this.$emit("input", this.detailItem);

      // this.modify = false;
      // this.isExisting = false;
      this.payeeinputdialog = false;

      // reset the form fields
      this.resetSelections();
    },

    resetSelections() {
      this.selections = [
        {
          id: null,
          psnname: null,
          staffcode: null,
          staffid: null,
          payitemid: null,
          payitemdesc: null,
          startdate: null,
          enddate: null,
          payeeactionid: 1,
          reqamt: null,
          reqqty: null,
          aprvamt: null,
          aprvqty: null,
          crcyid: 1,
          crcycode: null,
          crcydesc: null,
          justification: null,
          remark: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ];
    },

    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id == id);
      this.selections[0].staffcode = item.staffcode;
      this.selections[0].psnname = item.psnname;
      this.selections[0].staffid = item.id;
      this.selections[0].compnyid = item.compnyid;
      this.selections[0].compnyname = item.compnyname;
    },

    changeActionHandler(id) {
      let item = this.actionItems.find((item) => item.id === id);
      this.selections[0].actioncode = item.actioncode;
    },

    changePayItemHandler(id) {
      let item = this.payItems.find((item) => item.id === id);
      this.selections[0].payitemcode = item.payitemcode;
      this.selections[0].payitemdesc = item.payitemdesc;
      this.changePayItemTypeHandler(item.payitemtypeid);
    },

    changePayItemTypeHandler(id) {
      let item = this.payItemTypes.find((item) => item.id === id);
      this.selections[0].payitemtypedesc = item.payitemtypedesc;
    },

    changeCurrencyHandler(id) {
      let item = this.currencies.find((item) => item.id === id);
      this.selections[0].crcycode = item.crcycode;
      this.selections[0].crcydesc = item.crcydesc;
    },

    changeAppAmtHandler(reqAmt) {
      this.selections[0].aprvamt = reqAmt;
    },

    changeAppQtyHandler(reqQty) {
      this.selections[0].aprvqty = reqQty;
    },
  },

  created() {
    this.loadStaffDetailsListHandler();
    this.loadPayItemsHandler();
    this.loadPayItemTypesHandler();
    this.loadCurrencyListHandler();
    this.loadActionHandler();
  },
};
</script>