<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('recdisciplinaryrec.disciplinaryreclist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.name'), value: "psnname", width: "300px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "110px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "110px" },
        { text: this.$t('recdisciplinaryrec.disciplinarytype'), value: "disciplindesc", width: "150px" },
        { text: this.$t('recdisciplinaryrec.severitylevel'), value: "sevleveldesc", width: "130px" },
        { text: this.$t('columns.date'), value: "casedate", width: "110px" },
        { text: this.$t('recdisciplinaryrec.officerincharge'), value: "officername", width: "300px" },
        { text: this.$t('recdisciplinaryrec.caseoutcome'), value: "outcomedesc", width: "200px" },
      ],

      dataItems: [],
      errMsg: "",
      isErr: false,
      filename: null,
      search: this.$route.params.search,
    };
  },

  methods: {
    getDisciplinaryRecordListing() {
      const vm = this;
      this.$store.dispatch('get', {url: `/disciplinaryrecord/stfdisciplin/${this.$store.getters.userId}`})
        .then((res) => {
          vm.dataItems = res.data.rec;

          for(let i = 0; i < vm.dataItems.length; i++){
            vm.dataItems[i].casedate = moment(vm.dataItems[i].casedate).format('YYYY-MM-DD');

            if(vm.dataItems[i].officerid == null){
              vm.dataItems[i].officername = "NA";
            }
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "disciplinaryedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "disciplinarynew" });
    },
  },

  mounted() {
    this.getDisciplinaryRecordListing();
  },
};
</script>