<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PsnPayrollForm from "../../../components/payroll/psnpayroll/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": PsnPayrollForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('maintenanceforpayroll.createnewpersonpayrollinstruction'),
      pageTitleValue: "",
      formData: {
        staffid: null,
        startdate: null,
        enddate: null,
        paymodeid: null,
        paystatid: null,
        pcbcatid: null,
        childcount: null,
        taxid: null,
        isCompnypaytax: false,
        epfgroupid: null,
        epfeep: null,
        epferp: null,
        epfeeamt: null,
        epferamt: null,
        socsoid: null,
        epfveid: null,
        isEis: true,
        veeep: null,
        veerp: null,
        veeeamt: null,
        veeramt: null,
        baitulmalid: null,
        baieeamt: null,
        baieramt: null,
        // zakatamt: null,
        zakatp: null,
        isdynamic: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      currencyCode: "",
      paymodecode: "",
      paystatcode: "",

      pcbcats: [],
      pcbcatList: [],

      taxes: [],
      taxList: [],

      epfs: [],
      epfList: [],

      epfves: [],
      epfveList: [],

      socsos: [],
      socsoList: [],

      baitulmals: [],
      baitulmalList: [],

      payitems: [],
      payitemList: [],

      paymodes: [],
      paymodeList: [],

      paystats: [],
      paystatList: [],

      psns: [],
      psn0List: [],
      psn1List: [],
      psn2List: [],

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      if (this.formData.isCompnypaytax == false) {
        this.formData.isCompnypaytax = 0;
      } else {
        this.formData.isCompnypaytax = 1;
      }

      if (this.formData.isEis == false) {
        this.formData.isEis = 0;
      } else {
        this.formData.isEis = 1;
      }

      if (this.formData.isdynamic == false) {
        this.formData.isdynamic = 0;
      } else {
        this.formData.isdynamic = 1;
      }

      this.$store.dispatch('post', {url: "/psnpayroll/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "psnpayrolllist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "psnpayrolllist" });
    },
  },
};
</script>