<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.year')"
                                    type="text"
                                    ref="year"
                                    hide-selected
                                    v-model="formData.pyyear"
                                    dense
                                    :items="yearList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="2" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.month')"
                                    type="text"
                                    ref="month"
                                    hide-selected
                                    v-model="formData.pymonth"
                                    dense
                                    :items="monthList"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.payout')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="formData.payoutcode"
                                    dense
                                    :items="codeList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {

                pyyear: null,
                pymonth: null,
                payoutcode: null,
                total: 0,
            },
            
               parameters: {
                staffid: null,
                gradeid: null,
                companyid: null,
                employmenttypeid: null,
                branchid: null,
                departmentid: null
            },

            showFilter: {
                staffid: true,
                gradeid: true,
                employmenttypeid: true,
                companyid: true,
                branchid: true,
                departmentid: true
            },

            yearList: [],
            monthList: [],
            exchangeList: [],
            currencyList: [],
            codeList: []
        }
    },

    methods: {

        loadYear(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payoutctrl/all'})
                .then(res => {
                    vm.years = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )                        
                    }

                    if (vm.formData.pyyear) {
                        vm.changeYearHandler(vm.formData.pyyear);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeYearHandler(id) {
            this.years.find(item => item.id === id);
            
        },

        loadMonth(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payoutctrl/all'})
                .then(res => {
                    vm.months = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )                        
                    }

                    if (vm.formData.pymonth) {
                        vm.changeMonthHandler(vm.formData.pymonth);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeMonthHandler(id) {
            this.months.find(item => item.id === id);
            
        },

        loadPayoutCode(){
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payoutctrl/all'})
                .then(res => {
                    vm.payoutcodes = res.data.rec;
                    console.log('pay', vm.payoutcodes)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.codeList.push(
                            {
                                text: res.data.rec[i].payoutcode,
                                value: res.data.rec[i].payoutcode
                            }
                        )                        
                    }

                    if (vm.formData.payoutcode) {
                        vm.changePayoutcodeHandler(vm.formData.payoutcode);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changePayoutcodeHandler(id) {
            this.payoutcodes.find(item => item.id === id);
            
        },

        goHandler(){

            const formData = this.formData
            const param = this.parameters

            if ((this.formData.pyyear != null && this.formData.pymonth != null) || this.formData.payoutcode != null)  {
                
                this.$router.push({name: 'detailsbank', params: { formData, param } });

            } else {
                this.$refs.form.validate()
            }
            
        },

        updateValue() {
            this.$emit("input", {
                pyyear: this.formData.pyyear,
                pymonth: this.formData.pymonth,
                payoutcode: this.formData.payoutcode,
                crcyid: this.formData.crcyid,
                exchrateid: this.formData.exchrateid
                
            });
        },
    }, 

    mounted() { 
      this.loadYear();
      this.loadMonth();
      this.loadPayoutCode();
     
    },
    
}
</script>
