<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.glmappingpolicy')"
                                type="text"
                                v-model="formData.payglmappolicyid"
                                :items="payGLCodeMappingPolicyList"
                                :rules="[rules.required]"
                                @change="changeGLCodePolicyHandler(formData.payglmappolicyid)"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                ref= "payGLCodeMappingcode"
                                type="text"
                                v-model="payGLCodeMappingcode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.payglmappolicylistdetail,
                                payglmappolicyid: this.payglmappolicyidholder
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            pageTitle: this.$t('periodicattendanceincentivedetailinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                payglmappolicylistdetail: this.parentObject.data.payglmappolicylistdetail,
                payglmappolicyid: this.parentObject.data.payglmappolicyid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('glcode.reportby'), value: 'reportby', width: '200px', sortable: false},
                { text: this.$t('glcode.incode'), value: 'incode', width: '200px', sortable: false },
                { text: this.$t('glcode.indesc'), value: 'indesc', width: '200px', sortable: false },
                { text: this.$t('glcode.excode'), value: 'excode', width: '200px', sortable: false },
                { text: this.$t('glcode.exdesc'), value: 'exdesc', width: '200px', sortable: false },
            ],

            payGLCodeMappingPolicyList: [],
            payGLCodeMappingPolicyItems: [],
            payGLCodeMappingcode: '',

            errMsg: '',
            payglmappolicyidholder: null,
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.payglmappolicylistdetail =$event
        },
        
        loadGLMappingPolicy() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payglcodemappingpolicy/all'
            })
            .then(res => {
                vm.payGLCodeMappingPolicyItems = res.data.rec;

                for (let i = 0; i < vm.payGLCodeMappingPolicyItems.length; i++) {
                    vm.payGLCodeMappingPolicyList.push({
                        text: vm.payGLCodeMappingPolicyItems[i].policydesc,
                        code: vm.payGLCodeMappingPolicyItems[i].policycode,
                        value: vm.payGLCodeMappingPolicyItems[i].id,
                    });
                }

                if (this.formData.payglmappolicyid) {
                    this.changeGLCodePolicyHandler(this.formData.payglmappolicyid);
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeGLCodePolicyHandler(id) {
            var item = this.payGLCodeMappingPolicyItems.find(item => item.id === id);
            this.payGLCodeMappingcode = item.policycode;
            this.payglmappolicyidholder = id;
        },
            
        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                payglmappolicylistdetail: this.formData.payglmappolicylistdetail,
                payglmappolicyid: this.formData.payglmappolicyid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'glcodemappinglistinglist', params: { search: this.search}});
        },
    },

    mounted(){
        this.loadGLMappingPolicy();
    }
}
</script>