<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('alleis.eissubmissionrec')"
                type="text"
                v-model="formData.eisexprecid"
                :items="eisExpRecList"
                :rules="[rules.required]"
                @change="changeEISExportRecord(formData.eisexprecid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" class="mb-n1 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
                type="text"
                v-model="formData.year"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" class="mb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('bankpaymentlistingreport.payoutmonth')"
                type="text"
                v-model="formData.month"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}}  </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.payoutItems"
                :options="tableOpt"
                hide-default-footer
                item-key="payoutcode"
              >
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-3">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                type="text"
                v-model="formData.refnum"
                :rules="[rules.required]"
                :items="eisSubRefRecList"
                dense
                
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                v-model="formData.eisexpformatid"
                :label="EIS Export Format"
                type="text"
                :items="this.exportFmtList"
                :rules="[rules.required]"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row> -->
        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="getEISExportListHandler">
              {{$t('button.download')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('alleis.eislampiran_1'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        {
          text: this.$t('statutoryexportcommonused.payoutdescription'),
          value: "payoutdesc",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('payrollprocessrun.payoutstatus'),
          value: "pystatusdesc",
          width: "300px",
          sortable: true,
        },
      ],
      formData: {
        eisexprecid: null,
        refnum: null,
        compnyname: null,
        compnyid: null,
        year: null,
        month: null,
        myntlycode: null,
        eissubrecpy: [], 
        eisexpformatid: null,
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      eisExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      salstatutoryother: [],
      psnstatutoryinfo: [],
      staff: [],
      psnpayroll: [],
      staffaccount: [],
      eisdata: [],
      eissubrecref: [],
      eisdataholder: [],

      notFinalize: false,
      loading: false,
      isErr: false,
      errMsg: "",
      eisSubRefRecList: [],
      total: null,
    };
  },

  methods: {
    getEISSubRecs() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eisubmissionrecord/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.eisExpRecList.push({
              text: res.data.rec[i].eissubreccode,
              value: res.data.rec[i].id,
            });
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getEISSubRecPayout(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eissubmissionrecordpy/" + id})
        .then((res) => {
          vm.payoutItems = res.data.rec;
          vm.formData.eissubrecpy = vm.payoutItems;

          for (let i = 0; i < vm.payoutItems.length; i++) {
            if (vm.payoutItems[i].pystatuscode != 99) {
              this.notFinalize = true;
              vm.isErr = true;
              vm.errMsg = this.$t('vdatatable.payoutnotfinalized')
            } else {
              this.notFinalize = false;
            }

            console.log(this.notFinalize)
          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getEISSubRecRef(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eissubmissionrecordref/" + id})
        .then((res) => {
          vm.eissubrecref = res.data.rec;

          //set the eisSubRefRecList array null (to avoid getting value from previous array)
          vm.eisSubRefRecList = [];

        for (let i = 0; i < res.data.rec.length; i++) {
          vm.eisSubRefRecList.push({
              text: res.data.rec[i].refcode + ' - ' + res.data.rec[i].refnum,
              value: res.data.rec[i].refnum,
            });
          }
          
          vm.loading = !vm.loading;
          
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },


  changeEISSubRecRef(refnum) {
      let item = this.eissubrecref.find((item) => item.refnum === refnum);

      this.formData.compnyname = item.compnyname;
      this.formData.compnyid = item.compnyid;

      console.log(this.formData.compnyname)
     
  },

  changeEISExportRecord(id) {
      let itemExportRecord = this.dataItems.find((item) => item.id === id);

      this.formData.eisexprecid = itemExportRecord.id;
      this.formData.year = itemExportRecord.recyear;
      this.formData.month = itemExportRecord.recmonth;
      this.formData.myntlycode = itemExportRecord.myntlycode;

      console.log('id', this.formData.eisexprecid)

      this.getEISSubRecPayout(id);
      this.getEISSubRecRef(id);
      
    },


     objectToCSV(header, data, footer) {
        const csvRows = []
      //for header
        for (const key in header) {
            let headobj = key + ',' + header[key]
            csvRows.push(headobj) 
        }
        csvRows.push([])


        // get header
        const headers = Object.keys(data[0])
        csvRows.push(headers.join(','))

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })

           // let row = rowArray.map(x => `"${x}"`).join(",");
            csvRows.push(values.map(x => `${x}`).join(","))
            
        }

        //loop over the rows for footer
         for (const row of footer) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }

        return csvRows.join('\n')
    },

    download(data, title) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    
    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padLJ2(num, size) {
      var s = num + "";
      while (s.length < size) s = "'" + s;
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

   

  getEISExportListHandler() {
      const vm = this
        //to get today's date
       let dateHolder2 = this.formData.year + "-" + this.padRJ(this.formData.month, 2) + "-" + this.daysInMonth(this.formData.month, this.formData.year);
       let date = moment(dateHolder2).format("YYYY-MM-DD");

       console.log('date', date)
       console.log('eisexprecid', this.formData.eisexprecid)
       console.log('refnum', this.formData.refnum)
 
       this.$store.dispatch('get', {url: `/eislampiranexport/${this.$store.getters.userId}` + "/" +  this.formData.eisexprecid 
      + "/" + this.formData.refnum + "/" + date + "/" + date })
      .then((res) => {

       // console.log('abc')
        vm.eisdataholder = res.data.rec;

        console.log('eisdata', vm.eisdataholder)

        if (this.eisdataholder.length == 0){
          vm.isErr = true;
          vm.errMsg = this.$t('alleis.norectodownload')
        } else {
          vm.getData();
        }
      })
     // .then(function (res) {
    

        // if (vm.eisdataholder.length > 0){
        //   console.log('vm.finalize', vm.notFinalize)
        //  if(vm.notFinalize == true){
        //     console.log('vm.finalize', vm.notFinalize)
        //     vm.isErr = true;
        //     vm.errMsg = "Selected Payout has not been finalized"

        //  } else {
         // 
        // }
     // }
        // eisdataholder.forEach(function (o) {
        //   var existing = vm.eisdata.filter(function (i) {
        //     return i.staffid === o.staffid;
        //   })[0];
        //   if (!existing || existing == undefined) {
        //     vm.eisdata.push(o);
        //   }         
        // });

        // console.log('vm.notFinalize', vm.notFinalize)
        // console.log ('vm.eisdata', vm.eisdata.length)
        // if(vm.notFinalize != false && vm.eisdata.length == 0 || vm.notFinalize == true){
        //   console.log('not', vm.notFinalize)
        //   vm.isErr = true;
        //   vm.errMsg = "Selected Payout has not been finalized"

        // } else if (vm.eisdata.length > 0 || vm.eisdata.length == 0) {
         
       // }
      
      .catch(function (err) {
        vm.isErr = true;
        vm.errMsg = err;
        vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
      });
    },

     async getData() {

      console.log('abc')
      const vm = this

      let item = vm.eisdataholder;
      let body = [];
      let footer = []
      let holdpaycontamt = []
      let compnyname = '';

      // console.log('item', item)

      for (var i = 0; i < item.length; i++) {

         let refnoholder = ``
        //var refnoholder2 = ``
    
        if (item[i].kad_pengenalan != null) refnoholder = item[i].kad_pengenalan.split("-").join("")
        let contamtholder = item[i].amount.toFixed(2);
       
        body.push({
          "Employer EIS Number": this.padSpaceLJ(vm.formData.refnum, 13),
          "ID Number": this.padLJ2(refnoholder, 12),
          "Employee Name": this.padSpaceLJ(item[i].psnname, 45),
          "Contribution Month": this.padSpaceRJ((this.formData.month + "/" + this.formData.year), 8),
          "Contribution Amount": contamtholder,
          
      
        });
          holdpaycontamt.push(parseFloat(contamtholder.replace(/,/g, "")));
          compnyname = item[i].compnyname;
     
      }

       let header = {
         
        "Employer name" : compnyname,
        "Employer Code": vm.formData.refnum, 
       }

      if (holdpaycontamt) {
        let gettotal = holdpaycontamt.reduce(function (a, b) {
             return a + b;
           }, 0);

           gettotal = gettotal.toFixed(2);
           vm.total = gettotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
       footer.push({
        "Employer EIS Number": "",
           "ID Number": "",
           "Employee Name": "",
           "Contribution Month": "Total Amount",
           "Contribution Amount": this.padRJ(this.total,20),
 
      });

      this.titleData =
        this.padRJ(vm.formData.month, 2) + "_" + vm.formData.year + "_EIS";
    
      if (body.length > 0) {
        const csvData = this.objectToCSV(header, body, footer);
        this.download(csvData, this.titleData);
      
       }
  
    },



    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

  },

  mounted() {
    this.getEISSubRecs();
  },
};
</script>