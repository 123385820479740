<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3 class="blue--text mb-4"> {{$t('reportsparam.parameters')}} </h3>
          </v-col>

        </v-row>

        <br>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n6">
              <v-text-field
                outlined
                :label="this.$t('setuppolicymaster.policyno')"
                type="text"
                v-model="formData.policyno"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
             <v-col cols="12" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsexportstaffinsurancerec.staffinsuranceexportformat')"
                type="text"
                v-model="formData.expdesc"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

           <v-row>
             <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('reportsexportstaffinsurancerec.assignmenteffectivedatefrom')"
                    type="date"
                    ref="fromDate"
                    v-model="formData.fromDate"
                    readonly
                    dense
                    background-color="#ffeab2"
                ></v-text-field>

            </v-col>

             <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('reportsexportstaffinsurancerec.assignmenteffectivedateto')"
                    type="date"
                    ref="untilDate"
                    v-model="formData.untilDate"
                    readonly
                    dense
                    background-color="#ffeab2"
                ></v-text-field>

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                <v-checkbox
                    outlined
                    ref="excludesalary"
                    v-model="formData.excludesalary"
                    :label="this.$t('reportsexportstaffinsurancerec.excludebasicsal')"
                    readonly
                    dense
                ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
              <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                  <v-checkbox
                      outlined
                      ref="dependentdetail"
                      v-model="formData.dependentdetail"
                      :label="this.$t('reportsexportstaffinsurancerec.employeebankandemail_dependent')"
                      readonly
                      dense
                  ></v-checkbox>
              </v-col>
          </v-row>

            <v-row>
                    <v-toolbar flat>
                    <v-toolbar-title
                        >
                    </v-toolbar-title> 
                    <v-spacer></v-spacer>

                            <v-text-field
                            v-model="search"
                            append-icon="search"
                            style="width: 20px"
                            class="pr-5 pt-3"
                            hide-details>
                        </v-text-field>
                </v-toolbar>
                </v-row>

          <v-row>
                        <v-col cols="12" sm="12">
                        <v-data-table
                            v-model="selected"
                            :search="search"
                            @input="changePayoutHandler()"
                            :headers="dataHeader"
                            :items="this.dataItems"
                            :options="tableOpt"
                            hide-default-footer
                            :single-select="singleSelect"
                            item-key="no"
                            show-select
                        >
                        </v-data-table>
                        </v-col>
                    </v-row>

        <v-row >
          <div class="pt-5 pl-4 mt-n6">
            <v-btn 
                v-model="goBtn" 
                color="warning" 
                style="width: 100px; margin-bottom: 20px;"  
                @click="abc" 
                >
               {{$t('button.export')}}
            </v-btn>

          </div>

          <div class="pt-5 pl-4 mt-n6">
            <v-btn v-model="goBtn" color="warning" style="width: 100px; margin-bottom: 20px;" @click="backHandler">
               {{$t('button.back')}}
            </v-btn>
          </div>
       </v-row>
   

      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "vue";
//import VueExcelXlsx from "vue-excel-xlsx";
import XLSX from 'xlsx/xlsx';
//import JsonExcel from "vue-json-excel";

import ErrorDialog from "../../../components/common/ErrorDialog";

//Vue.use(VueExcelXlsx);

export default {
  components: {

    "app-error": ErrorDialog,
  //  "vue-excel-xlsx": VueExcelXlsx,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('exportstaffinsurancerecords'),

       dataHeader:
          [
            { text: this.$t('statutoryexportcommonused.no'),    value: 'no',    width: '70px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.movdate'),             value: 'movdate',    width: '200px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.movtype'),         value: 'insurancemovtypedesc',  width: '200px', sortable: true},
            { text: this.$t('columns.staffcode'),         value: 'staffcode',  width: '150px', sortable: true},
            { text: this.$t('columns.staffname'),             value: 'staffname',    width: '300px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.stafficno'),            value: 'stafficno',  width: '150px', sortable: true},
            { text: this.$t('recfamdetails.membername'),             value: 'dependentname',    width: '300px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.membericno'),            value: 'depicno',  width: '150px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.memberpassportno'),      value: 'dependentpassportno',    width: '150px', sortable: true},
            { text: this.$t('setupreport_exportmapping.relationship'),      value: 'relationship',    width: '150px', sortable: true},
            { text: this.$t('columns.company'),          value: 'compnycode',    width: '150px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.gtlcvrage'),     value: 'gtlcoveragevalue',    width: '150px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.ghscvrage'),     value: 'ghscoveragevalue',    width: '150px', sortable: true},
            { text: this.$t('reportsexportstaffinsurancerec.obcvrage'),      value: 'outpatientcoveragevalue',  width: '150px', sortable: true},
          ],


      formData: {
        inspolicyid: this.$route.params.formData.inspolicyid,
        fromDate: this.$route.params.formData.fromDate,
        untilDate: this.$route.params.formData.untilDate,
        policyno: this.$route.params.formData.policyno,
        expdesc: this.$route.params.formData.expdesc,
        excludesalary: this.$route.params.formData.excludesalary,
        dependentdetail: this.$route.params.formData.dependentdetail,

      },

       props: {
            columns: {
                type: Array,
                default: () => []
            },
            data: {
                type: Array,
                default: () =>  []
            },
            fileName: {
                type: String,
                default: 'excel'
            },
            sheetName: {
                type: String,
                default: 'SheetName'
            },
            fileType: {
                type: String,
                default: 'xlsx',
                validator: (val) => ['xlsx', 'xls'].includes(val)
            }
       },

      tableOpt: {
        itemsPerPage: -1,
      },

      singleSelect: false,
      selected: [],
      search: '',

      items: [],
      dataItems: [],

      dataItems2 : [],
      columns : [
                    {
                        label: this.$t('reportsexportstaffinsurancerec.movcode'),
                        field: "insurancemovtypecode",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.employeename'),
                        field: "staffname",
                      
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.employeeidtype'),
                        field: "emptypeid",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.empicnopassno'),
                        field: "stafficno",
                    },
                    {
                        label: this.$t('setupreport_exportmapping.relationship'),
                        field: "extrelationshipname",
                    },

                       {
                        label: this.$t('columns.gender'),
                        field: "extgendercode",
                    },
                    {
                        label: this.$t('columns.birthdate'),
                        field: "depdob",
                      
                    },
                    {
                        label: this.$t('recpersonalinfo.nationality'),
                        field: "extnationalitycode",
                    },
                    {
                        label: this.$t('recfamdetails.membername'),
                        field: "dependentname",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.memberidtype'),
                        field: "deptypeid",
                    },

                       {
                        label: this.$t('reportsexportstaffinsurancerec.membericpassportno'),
                        field: "depicno",
                    },
                    {
                        label: this.$t('columns.effectivedate'),
                        field: "movdate",
                      
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.employeeid'),
                        field: "staffcode",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.occupation'),
                        field: "posdesc",
                    },
    
                       {
                        label: this.$t('reportsexportstaffinsurancerec.employeecat'),
                        field: "gtlcoveragevalue",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.monthlysal'),
                        field: "basicsal",
                      
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.ghsplan'),
                        field: "ghscoveragevalue",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.outpatientplan'),
                        field: "outpatientcoveragevalue",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.bankcode'),
                        field: "extbankcode",
                    },

                    {
                        label: this.$t('bankpaymentlistingreport.bankname'),
                        field: "extbankname",
                    },
                    {
                        label: this.$t('bankpaymentlistingreport.bankaccountno'),
                        field: "depaccountno",
                      
                    },
                    {
                        label:  this.$t('columns.email'),
                        field: "depemail",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.subsidiaryname'),
                        field: "compnycode",
                    },
                    {
                        label: this.$t('reportsexportstaffinsurancerec.costcentre'),
                        field: "null",
                        value: null,
                    },

                    {
                        label:  this.$t('columns.department'),
                        field: "deptname",
                    },
                    {
                        label:  this.$t('columns.division'),
                        field: "divnname",
                    },

                    {
                        label:  this.$t('columns.branch'),
                        field: "brchname",
                    },
                    {
                        label:  this.$t('columns.remark'),
                        field: "null",
                    },                 
                ],

      extgendercode : '' ,
      extbankcode : '',
      extrelationshipname : '',
      extnationalitycode : '',

      goBtn: false,
      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {


     exportExcel() {
                let createXLSLFormatObj = [];
                let newXlsHeader = [];
                const vm = this;

            if(this.formData.inspolicyid.length > 0){

                if (vm.columns.length === 0){
                    console.log("Add columns!");
                    return;
                }
                if (vm.formData.inspolicyid.length === 0){
                    console.log("Add data!");
                    return;
                }
                vm.columns.map(column => {
                    newXlsHeader.push(column.label);
                });
                createXLSLFormatObj.push(newXlsHeader);
                vm.formData.inspolicyid.map(value => {
                    let innerRowData = [];
                    vm.columns.map(val => {
                      let fieldValue = value[val.field];
                      if (val.field.split('.').length > 1) {
                        fieldValue = this.getNestedValue(value, val.field);
                      }
                      if (val.dataFormat && typeof val.dataFormat === 'function') {
                            innerRowData.push(val.dataFormat(fieldValue));
                        } else {
                            innerRowData.push(fieldValue);
                        }
                    });
                    createXLSLFormatObj.push(innerRowData);
                });
                let fileName = "Staff Insurance Records" + ".xlsx";
                let ws_name = vm.sheetName;
                let wb = XLSX.utils.book_new(),
                    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
                XLSX.writeFile(wb, fileName);

                
                let itemNew = this.formData;
                let item2 = this.dataItems;
                let product = this.formData.inspolicyid

                this.$router.push({name: "exportstaffinsurancelog", params: {itemNew, item2, product}, });

            } else {

              vm.isErr = true;
              vm.errMsg = this.$t('reportsexportstaffinsurancerec.messagedata')

            }
              
       },

            getNestedValue(object, string) {
                string = string.replace(/\[(\w+)\]/g, '.$1');
                string = string.replace(/^\./, '');
                let a = string.split('.');
                for (let i = 0, n = a.length; i < n; ++i) {
                  let k = a[i];
                  if (k in object) {
                    object = object[k];
                  } else {
                    return;
                  }
                }
                return object;
            
    },

    abc(){
      const vm = this;

     // let item = vm.dataItems;
      let selecteditem = this.formData.inspolicyid
      let newdata = [];

      if (this.formData.inspolicyid.length > 0){

      for (var i = 0; i < selecteditem.length; i++) {

        if ((this.formData.dependentdetail == true && selecteditem[i].dependentid == null) ||
              selecteditem[i].dependentid == null){
                 
                 if (selecteditem[i].staffgencode != null && selecteditem[i].staffgenname != null){
                    newdata.push(
                      selecteditem[i].extgendercode
                    )
                 }

                 if (selecteditem[i].staffnatname != null && selecteditem[i].staffnatcode != null){
                   newdata.push(selecteditem[i].extnationalitycode)
                 }

                 if (selecteditem[i].staffbankid != null && selecteditem[i].staffbankcode !=null
                  && selecteditem[i].staffbankname != null){
                    newdata.push(
                    selecteditem[i].extbankname,
                    selecteditem[i].extbankcode)
                  }
          }

          if((this.formData.dependentdetail == false && selecteditem[i].dependentid == null)){

            if (selecteditem[i].staffgencode != null && selecteditem[i].staffgenname != null){
                    newdata.push(
                      selecteditem[i].extgendercode
                    )
                 }

                 if (selecteditem[i].staffnatname != null && selecteditem[i].staffnatcode != null){
                   newdata.push(selecteditem[i].extnationalitycode)
                 }

                 if (selecteditem[i].staffbankid != null && selecteditem[i].staffbankcode !=null
                  && selecteditem[i].staffbankname != null){
                    newdata.push(
                    selecteditem[i].extbankname,
                    selecteditem[i].extbankcode)
                  }
          }

          if (selecteditem[i].dependentid != null){

            if(selecteditem[i].dependentnationality != null){
              newdata.push(selecteditem[i].extnationalitycode)
            }

            if (selecteditem[i].depgender != null){

              newdata.push(
                selecteditem[i].extgendercode
              )
            }

            if(selecteditem[i].depbankcode != null && selecteditem[i].depbankname != null){

               newdata.push(
                    selecteditem[i].extbankname,
                    selecteditem[i].extbankcode)

            }

            if (selecteditem[i].relationship != null){

              newdata.push(
                  selecteditem[i].extrelationshipname
                )
            }
          }    
      }
      
      } 

    var value = true;

     for (var k = 0; k < newdata.length; k++) {
      
      if (newdata[k] == null ){
            value = false;
         
        }  
      }

      if(value == false){
           vm.isErr = true;
           vm.errMsg = this.$t('reportsexportstaffinsurancerec.mappingmessage')
      }
      else {
            this.exportExcel()

         }

         

        // newdata.push(
        //   selecteditem[i].extgendercode,
        //   selecteditem[i].extbankcode,
        //   selecteditem[i].extbankname,
        //   selecteditem[i].extrelationshipname,
        //   selecteditem[i].extnationalitycode
        // );   


    },
        
    backHandler() {

        this.$router.push({name: "exportstaffinsuranceparameter" });
    },

    loadExportStaffInsurance() {
       const vm = this;

      this.$store.dispatch('post', {url: `/exportstaffinsurance/${this.$store.getters.userId}`,
          payload: vm.formData})
          .then(res => {
            vm.loading = false;

            vm.dataItems = res.data.rec;

           // console.log('dataItems',vm.dataItems)

            for(let i = 0; i < vm.dataItems.length; i++){
               vm.dataItems[i].no = i + 1;
               vm.dataItems[i].emptypeid = null;
               vm.dataItems[i].insurancemovtypecode = null;
               vm.dataItems[i].deptypeid = null;
               vm.dataItems[i].movdate = moment(vm.dataItems[i].movdate).format("DD-MM-YYYY");
               vm.dataItems[i].depdob = moment(vm.dataItems[i].depdob).format("DD-MM-YYYY");
               vm.dataItems[i].staffdob = moment(vm.dataItems[i].staffdob).format("DD-MM-YYYY");


            if ( this.formData.excludesalary == true){
                 let newbasicsal = 0;
                 vm.dataItems[i].basicsal = newbasicsal;
            } else {
                vm.dataItems[i].basicsal = parseFloat(vm.dataItems[i].basicsal).toFixed(2);

            }

            if ((this.formData.dependentdetail == true && vm.dataItems[i].dependentid == null) ||
              vm.dataItems[i].dependentid == null){
              vm.dataItems[i].depemail = vm.dataItems[i].staffemail;
              vm.dataItems[i].extbankcode = vm.dataItems[i].staffextbankcode;
              vm.dataItems[i].extbankname = vm.dataItems[i].staffextbankname;
              vm.dataItems[i].depaccountno = vm.dataItems[i].staffaccountno;

              this.dataItems[i].relationship = 'SELF';
              this.dataItems[i].extrelationshipname = 'SELF';
              this.dataItems[i].extrelationshipcode = 'SELF';
              this.dataItems[i].extgendercode = this.dataItems[i].staffextgendercode;
              this.dataItems[i].depdob = this.dataItems[i].staffdob;
              this.dataItems[i].depicno = this.dataItems[i].idno;
              this.dataItems[i].dependentname = this.dataItems[i].staffname;
              this.dataItems[i].deptypeid = 'NRIC';
              this.dataItems[i].depicno = this.dataItems[i].stafficno;
              this.dataItems[i].extnationalitycode = this.dataItems[i].staffextnationalitycode;
            
            } else if (this.formData.dependentdetail == true && vm.dataItems[i].dependentid != null){
              vm.dataItems[i].depemail = vm.dataItems[i].staffemail;
              vm.dataItems[i].extbankcode = vm.dataItems[i].staffextbankcode;
              vm.dataItems[i].extbankname = vm.dataItems[i].staffextbankname;
              vm.dataItems[i].depaccountno = vm.dataItems[i].staffaccountno;

            }  else if ((this.formData.dependentdetail == false && vm.dataItems[i].dependentid == null) ||
              vm.dataItems[i].dependentid == null){
              vm.dataItems[i].depemail = vm.dataItems[i].staffemail;
              vm.dataItems[i].extbankcode = vm.dataItems[i].staffextbankcode;
              vm.dataItems[i].extbankname = vm.dataItems[i].staffextbankname;
              vm.dataItems[i].depaccountno = vm.dataItems[i].staffaccountno;
              this.dataItems[i].relationship = 'SELF';
              this.dataItems[i].extrelationshipname = 'SELF';
              this.dataItems[i].extrelationshipcode = 'SELF';
              this.dataItems[i].extgendercode = this.dataItems[i].staffextgendercode;
              this.dataItems[i].depdob = this.dataItems[i].staffdob;
              this.dataItems[i].depicno = this.dataItems[i].idno;
              this.dataItems[i].dependentname = this.dataItems[i].staffname;
              this.dataItems[i].deptypeid = 'NRIC';
              this.dataItems[i].depicno = this.dataItems[i].stafficno;
              this.dataItems[i].extnationalitycode = this.dataItems[i].staffextnationalitycode;
            
            }
            //else if(vm.dataItems[i].dependentid == null){

            //   this.dataItems[i].relationship = 'SELF';
            //   this.dataItems[i].depgender = this.dataItems[i].staffextgendername;
            //   this.dataItems[i].depdob = this.dataItems[i].staffdob;
            //   this.dataItems[i].depicno = this.dataItems[i].idno;
            //   this.dataItems[i].dependentname = this.dataItems[i].staffname;
            //   this.dataItems[i].deptypeid = 'NRIC';
            //   this.dataItems[i].depicno = this.dataItems[i].stafficno;
            //   this.dataItems[i].depnationality = this.dataItems[i].staffextnationalitycode;
            //   vm.dataItems[i].depemail = vm.dataItems[i].staffemail;
            //   vm.dataItems[i].depbankcode = vm.dataItems[i].staffextbankcode;
            //   vm.dataItems[i].depbankname = vm.dataItems[i].staffextbankname;
            //   vm.dataItems[i].depaccountno = vm.dataItems[i].staffaccountno;
            // } 
            // else {
            //   this.dataItems[i].depgender = this.dataItems[i].extgendercode;
            //   this.dataItems[i].depbankcode = this.dataItems[i].extbankcode;
            //   this.dataItems[i].depbankname = this.dataItems[i].extbankname;
            //   this.dataItems[i].relationship = this.dataItems[i].extrelationshipname;
            //   this.dataItems[i].depnationality = this.dataItems[i].extnationalitycode;
            
            // }

             if (this.dataItems[i].extgendercode == null ){

              vm.isErr = true;
              vm.errMsg = this.$t('reportsexportstaffinsurancerec.gendermessage')

             }

              if ( this.dataItems[i].extbankcode == null ){

              vm.isErr = true;
              vm.errMsg = this.$t('reportsexportstaffinsurancerec.bankmessage')
             }

              if (this.dataItems[i].extrelationshipname == null ){

              vm.isErr = true;
              vm.errMsg = this.$t('reportsexportstaffinsurancerec.relationshipmessage')
             }

              if (this.dataItems[i].extnationalitycode == null ){

              vm.isErr = true;
              vm.errMsg = this.$t('reportsexportstaffinsurancerec.nationalitymessage')

             }

            if ( vm.dataItems[i].insurancemovtypeid == '1' || vm.dataItems[i].insurancemovtypeid == '5'){
                 this.dataItems[i].insurancemovtypecode = 'A'
          
            }

             if ( vm.dataItems[i].insurancemovtypeid == '2' || vm.dataItems[i].insurancemovtypeid == '6'){
                this.dataItems[i].insurancemovtypecode = 'C';
           
          }

           if ( vm.dataItems[i].insurancemovtypeid == '3' || vm.dataItems[i].insurancemovtypeid == '7'){
                this.dataItems[i].insurancemovtypecode= 'T'
                
          }

           if ( vm.dataItems[i].insurancemovtypeid == '4' || vm.dataItems[i].insurancemovtypeid == '8'){
               this.dataItems[i].insurancemovtypecode = 'D'
                
          }

          if (vm.dataItems[i].staffnatid == '1'){
                this.dataItems[i].emptypeid = 'NRIC'
              } else {
                this.dataItems[i].stafficno = null
              }


          if(vm.dataItems[i].dependentnationality == 'MY'){
            this.dataItems[i].deptypeid = 'NRIC'
          } else{
            this.dataItems[i].depicno = this.dataItems[i].dependentpassportno;
          }
          }
        })

    },

      changePayoutHandler() {
            this.formData.inspolicyid = this.selected
        },


      
  
  // download(){

  //     const vm = this
  //     let item = vm.dataItems;
  //     let data = [];
   
      
  //     for (var i = 0; i < item.length; i++) {
  //        data.push({
  //               "Movement Code": item[i].insurancemovtypecode,
  //               "Employee Name": item[i].staffname,
  //               "Employee ID Type": item[i].emptypeid,
  //               "Employee IC No / Passport No": item[i].stafficno,
  //               "Relationship": item[i].relationship,
  //               "Gender": item[i].depgender,
  //               "Date of Birth": item[i].depdob,
  //               "Nationality Code": item[i].depnationality,
  //               "Member Name": item[i].dependentname,
  //               "Member ID Type": item[i].deptypeid,
  //               "Member IC No / Passport No": item[i].depicno,
  //               "Effective Date":item[i].movdate,
  //               "Employee ID": item[i].staffcode,
  //               "Occupation": item[i].posdesc,
  //               "Employee Category": item[i].gtlcoveragevalue,
  //               "Monthly Salary": item[i].basicsal,
  //               "GHS Plan": item[i].ghscoveragevalue,
  //               "OutPatient Plan": item[i].outpatientcoveragevalue,
  //               "Bank Code": item[i].depbankcode,
  //               "Bank Name": item[i].depbankname,
  //               "Bank Account No": item[i].depaccountno,
  //               "Email Address": item[i].depemail,
  //               "Subsidiary Name": item[i].compnycode,
  //               "Cost Centre": null,
  //               "Department": item[i].deptname,
  //               "Division": item[i].divnname,
  //               "Branch": item[i].brchname,
  //               "Remarks": null,
  //        });
  //   //  }        
  //          this.exportExcel();
            
  //      //}
  //     }

      

  //       // let itemNew = this.formData;
  //       // let item2 = this.dataItems;
  //       // let product = this.formData.inspolicyid

  //       // this.$router.push({name: "exportstaffinsurancelog", params: {itemNew, item2, product}, });
      
       

         
  // },
  },



  mounted() {

  this.loadExportStaffInsurance();
  },
};
</script>