<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="4" md="4" xs="12" sm="12" class="pb-n5 text-right"> </v-col>
      <v-col cols="4" md="4" xs="12" sm="12" class="pb-n5 text-right">
        <div v-if="!isSubmitted">
          <div>
            <v-text-field
              outlined
              :label="this.$t('reccontactdetails.email')"
              type="email"
              :rules="[rules.required]"
              v-model="formData.email"
              @input="updateValue"
              dense
            ></v-text-field>
          </div>
          <div class="text-left">
            <p>
              {{$t('forgotpassword.emailregistered')}}<br />
              {{$t('forgotpassword.assistance')}}
            </p>
          </div>
        </div>
        <div style="text-align: center; font-size: 16px" v-if="isSubmitted">
          <p>
           {{$t('forgotpassword.emailsentto')}}
            <span style="font-weight: bold">{{ formData.email }}</span
            >.<br />{{$t('forgotpassword.checkemail')}}
          </p>
        </div>
        <div class="text-center pt-5">
          <v-row>
            <v-col>
              <v-btn style="width: 100px" v-on:click="BackButtonHandler()"
                >{{$t('button.back')}}</v-btn
              >
            </v-col>
            <v-col v-if="!isSubmitted">
              <v-btn
                color="#fd9941"
                style="width: 100px; color: white"
                v-on:click="buttonHandler()"
                >{{$t('button.send')}}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="4" md="4" xs="12" sm="12" class="pb-n5 text-right"> </v-col>
    </v-row>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </v-container>
</template>
<style>
.v-content {
  height: 100%;
}
</style>

<script>
import ErrorDialog from "./ErrorDialog";
// import moment from "moment";
//import GenericForm from '../../../generic/GridForm';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    //'app-genericform': GenericForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      formData: {
        email: "",
      },
      isErr: false,
      errMsg: "",
      isSubmitted: false,
      db: this.$route.params.db,
    };
  },

  methods: {
    BackButtonHandler() {
      const vm = this;
      // this.$router.replace({ name: "login" });
      this.$router.replace("/" + this.$i18n.locale + "/home/" + vm.db);
      
    },
    buttonHandler() {
      const vm = this;
      this.$store.dispatch('post', {
        url:  "/forgotpassword", 
        payload: {
          email: vm.formData.email
        }, 
        headers: {db: vm.db}})
        .then((res) => {
          console.log(res);
          if (res.data.status != 201) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
            this.isSubmitted = false;
          } else {
            this.isSubmitted = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateValue() {
      this.$emit("input", {
        email: this.formData.email,
      });
    },
  },
  mounted() {},
};
</script>