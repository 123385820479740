<template>
    <div>
        <monthlyeissocso-form v-if="this.id == 25"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <!-- <monthlysocsohlb-form v-if="this.id == 9"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlysocsoambank-form v-if="this.id == 14"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlysocsocimb-form v-if="this.id == 21"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        /> -->

        
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlyEISSocsoForm from '../../../components/payroll/monthlyeissocsoexport/MonthlyEISSocso';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlyeissocso-form': MonthlyEISSocsoForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('alleissocso.mnsocsoandeis'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },
}

</script>