<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="3" sm="3" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('allbank.organizationrec')"
                type="text"
                v-model="formData.organizationrec"
                maxlength="3"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.organizationname')"
                type="text"
                v-model="formData.organizationname"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.organizationshortname')"
                type="text"
                v-model="formData.organizationshortname"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="3" sm="3" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.organizationidbybank')"
                type="text"
                v-model="formData.organizationbankid"
                maxlength="5"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.paymentdate')"
                type="date"
                v-model="formData.paymentdate"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                v-model="formData.sameacc"
                                :label="Combine same account no."
                                hide-details
                            ></v-checkbox>
                            <v-checkbox
                                v-model="formData.checkname"
                                :label="Check Name"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row> -->
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="exportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter: (value) => value.length < 3 || this.$t('reportsparam.counter3'),
      },
      pageTitle: 'MBB-EMAS-01',

      parameter: this.parentObject.parameter,
      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        organizationrec: "",
        organizationname: "",
        organizationshortname: "",
        paymentdate: "",
        organizationbankid: "",
        paymentexpdate: "",
        useridpaymentexpdate: "",
        creditingdate: "",
        reference: "",
        description: "",
        sameacc: "",
        checkname: "",
        stamp: "",
        userid: "",
      },
      bank: [
        {
          id: 26,
          bankcode: "MBB",
          bankdesc: "Malayan Banking Berhad",
          mbbcode: "001",
        },
        {
          id: 16,
          bankcode: "CIMB",
          bankdesc: "CIMB Bank",
          mbbcode: "006",
        },
        {
          id: 4,
          bankcode: "ABMB",
          bankdesc: "Alliance Bank Malaysia Berhad",
          mbbcode: "003",
        },
        {
          id: 31,
          bankcode: "RHB",
          bankdesc: "RHB Bank",
          mbbcode: "010",
        },
        {
          id: 30,
          bankcode: "PBB",
          bankdesc: "Public Bank Berhad",
          mbbcode: "009",
        },
        {
          id: 20,
          bankcode: "HLBB",
          bankdesc: "Hong Leong Bank Berhad",
          mbbcode: "008",
        },
        {
          id: 1,
          bankcode: "AFFIN",
          bankdesc: "Affin Bank Berhad",
          mbbcode: "002",
        },
        {
          id: 5,
          bankcode: "AMBANK",
          bankdesc: "AmBank (M) Berhad",
          mbbcode: "004",
        },
        {
          id: 14,
          bankcode: "BNPPARIBAS",
          bankdesc: "BNP Paribas Malaysia Berhad",
          mbbcode: "029",
        },
        {
          id: 6,
          bankcode: "BANGKOK",
          bankdesc: "Bangkok Bank Berhad",
          mbbcode: "",
        },
        {
          id: 9,
          bankcode: "BAMB",
          bankdesc: "Bank of America Malaysia Berhad",
          mbbcode: "022",
        },
        {
          id: 10,
          bankcode: "BCMB",
          bankdesc: "Bank of China (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 15,
          bankcode: "CCBMB",
          bankdesc: "China Construction Bank (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 17,
          bankcode: "CITI",
          bankdesc: "Citibank Berhad",
          mbbcode: "013",
        },
        {
          id: 18,
          bankcode: "DBMB",
          bankdesc: "Deutsche Bank (Malaysia) Berhad",
          mbbcode: "020",
        },
        {
          id: 21,
          bankcode: "HSBC",
          bankdesc: "HSBC Bank Malaysia Berhad",
          mbbcode: "015",
        },
        {
          id: 22,
          bankcode: "IIBMB",
          bankdesc: "India International Bank (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 23,
          bankcode: "ICBCMB",
          bankdesc: "Industrial and Commercial Bank of China (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 24,
          bankcode: "JPMCBB",
          bankdesc: "J.P. Morgan Chase Bank Berhad",
          mbbcode: "024",
        },
        {
          id: 28,
          bankcode: "MUFGMB",
          bankdesc: "MUFG Bank (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 27,
          bankcode: "MBMB",
          bankdesc: "Mizuho Bank (Malaysia) Berhad",
          mbbcode: "",
        },
        {
          id: 29,
          bankcode: "OCBC",
          bankdesc: "OCBC Bank (Malaysia) Berhad",
          mbbcode: "014",
        },
        {
          id: 32,
          bankcode: "SCB",
          bankdesc: "Standard Chartered Bank Malaysia Berhad",
          mbbcode: "017",
        },
        {
          id: 33,
          bankcode: "SMBCMB",
          bankdesc: "Sumitomo Mitsui Banking Corporation Malaysia Berhad",
          mbbcode: "026",
        },
        {
          id: 34,
          bankcode: "BNSB",
          bankdesc: "The Bank of Nova Scotia Berhad",
          mbbcode: "",
        },
        {
          id: 36,
          bankcode: "UOB",
          bankdesc: "United Overseas Bank (Malaysia) Bhd.",
          mbbcode: "019",
        },
        {
          id: 7,
          bankcode: "BIM",
          bankdesc: "Bank Islam Malaysia",
          mbbcode: "004",
        },
        {
          id: 19,
          bankcode: "EON",
          bankdesc: "EON Bank",
          mbbcode: "007",
        },
        {
          id: 8,
          bankcode: "MUAM",
          bankdesc: "Bank Muamalat (Malaysia)",
          mbbcode: "012",
        },
        {
          id: 12,
          bankcode: "RAKYAT",
          bankdesc: "Bank Rakyat",
          mbbcode: "016",
        },
        {
          id: 13,
          bankcode: "BSN",
          bankdesc: "Bank Simpanan Nasional",
          mbbcode: "018",
        },
        {
          id: 35,
          bankcode: "RBSB",
          bankdesc: "The Royal Bank of Scotland Berhad",
          mbbcode: "021",
        },
        {
          id: 25,
          bankcode: "KFHM",
          bankdesc: "Kuwait Finance House Malaysia",
          mbbcode: "023",
        },
        {
          id: 2,
          bankcode: "AGRO",
          bankdesc: "Agro Bank (Bank Pertanian Malaysia)",
          mbbcode: "025",
        },
        {
          id: 11,
          bankcode: "BTMMB",
          bankdesc: "Bank of Tokyo-Mitsubishi UFJ (M) Berhad",
          mbbcode: "027",
        },
        {
          id: 3,
          bankcode: "ALRAJHI",
          bankdesc: "Al Rajhi Bank ",
          mbbcode: "028",
        },
      ],

      staffilterlist: [],
      staffaccount: [],
      companyaccount: [],
      psnpayroll: [],
      titleData: null,

      companyrecnum: "",
      paymentmode: null,
      bankcode: null,
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],

      currency: null,
      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    getPsnPayrollListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/psnpayroll/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.psnpayroll = res.data.rec;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getCompanybankaccountListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/bankaccount/all"})
        .then(function (res) {
          vm.companyaccount = res.data.rec;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getStaffbankaccountListHandler(checkval) {
      const vm = this;
      
      this.$store.dispatch('get', {url: `/staffbankaccount/all/${this.$store.getters.userId}`})
        .then(res => {
          let staffaccholder = res.data.rec;
          
          let dateHolder = vm.data.year + "-" + 
                           vm.padRJ(vm.data.month, 2) + "-" + 
                           vm.daysInMonth(vm.data.month, vm.data.year);
          
          let date = moment(dateHolder).format("YYYY-MM-DD");
        
          for (let i = 0; i < staffaccholder.length; i++) {
            if (staffaccholder[i].startdate <= date && date <= staffaccholder[i].enddate ) {
                  vm.staffaccount.push(staffaccholder[i])
            }
          }

          if (checkval) {
            vm.staffaccount = vm.staffaccount.filter((el) => {
                return vm.staffilterlist.some((f) => {
                    return f.staffid === el.staffid;
                });
            });
          }
          
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    loadStaffFilter() {
      const vm = this;

      this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
        .then(res => {
            vm.staffilterlist = res.data.rec;
            let checkval = false;
            
            for (const key in this.parameter) {
              if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            }
            this.getStaffbankaccountListHandler(checkval)
            this.getSalHdListHandler(checkval)
            
        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    getSalHdListHandler(checkval) {
      const vm = this;

      this.$store.dispatch('get', {url: "/ppsummarylist/all"})
        .then(function (res) {
          vm.salhdItems = res.data.rec;
          
          if (checkval) {
            vm.salhdItems = vm.salhdItems.filter((el) => {
                return vm.staffilterlist.some((f) => {
                    return f.staffid === el.staffid;
                });
            });
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    objectToTxt(header, data, footer) {
      const csvRows = [];

      const headers = Object.keys(data[0]);
      const getHeader = header;
      csvRows.push(getHeader);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(footer);
      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getData() {
      const vm = this;

      let body = [];
      let getPayout = this.data.salexprecdetail;
      let netpay = null;
      let holdpay = [];
      let pay = null;
      let getAmt;
      let finalAmt;
      let totalAmt;
      let getAcc = this.companyaccount.find(
        (item) => item.accountdesc === this.data.bank
      );
      let allacc = [];
      let selacc = [];
      let selaccbank = [];
      let selaccmode = [];
      let selaccactive = [];
      let selaccpay = [];

      if (this.salhdItems[0].exchratecode) this.currency = this.salhdItems[0].exchratecode;

      
        for (let l = 0; l < getPayout.length; l++) {
          for (let k = 0; k < this.salhdItems.length; k++) {
            if (getPayout[l].payoutctrlid === this.salhdItems[k].poid) {
              for (let j = 0; j < this.staffaccount.length; j++) {
                if (allacc.indexOf(this.salhdItems[k]) === -1) {
                  allacc.push(this.salhdItems[k]);
                }
                if (this.staffaccount[j].staffid === this.salhdItems[k].staffid) {
                  if (selacc.indexOf(this.salhdItems[k]) === -1) {
                    selacc.push(this.salhdItems[k]);
                  }

                  for (let a = 0; a < this.bank.length; a++) {
                    if (this.bank[a].id == this.staffaccount[j].bankid) {
                      if (selaccbank.indexOf(this.salhdItems[k]) === -1) {
                        selaccbank.push(this.salhdItems[k]);
                      }
                    
                      for (let i = 0; i < this.psnpayroll.length; i++) {
                        if (
                          this.psnpayroll[i].staffid === this.staffaccount[j].staffid &&
                          this.psnpayroll[i].paymodecode === "BANK"&&
                            moment(this.formData.paymentdate).isBetween(this.psnpayroll[i].startdate, this.psnpayroll[i].enddate)
                          ) {
                          if (selaccmode.indexOf(this.salhdItems[k]) === -1) {
                            selaccmode.push(this.salhdItems[k]);
                          }

                          if (this.psnpayroll[i].pystatcode === "ACTIVE") {

                            if (selaccactive.indexOf(this.salhdItems[k]) === -1) {
                              selaccactive.push(this.salhdItems[k]);
                            }

                            if (this.salhdItems[k].netpay > 0) {
                              if (selaccpay.indexOf(this.salhdItems[k]) === -1) {
                                selaccpay.push(this.salhdItems[k]);
                              }

                              pay = this.salhdItems[k].netpay;
                              netpay = this.salhdItems[k].netpay.toFixed(2);
                              
                              let getpay = netpay.split(".").join("");
                              this.staffaccount[j].newic = "";
                              this.staffaccount[j].oldic = "";
                              this.staffaccount[j].passport = "";
                              
                              if (this.staffaccount[j].iddetails) {
                                if (this.staffaccount[j].idtypeid == 1) {
                                  this.staffaccount[j].newic = this.staffaccount[
                                    j
                                  ].iddetails
                                    .split("-")
                                    .join("");
                                }
                                

                                if (this.staffaccount[j].idtypeid == 2) {
                                  this.staffaccount[j].oldic = this.staffaccount[
                                    j
                                  ].iddetails
                                    .split("-")
                                    .join("");
                                }

                                if (this.staffaccount[j].idtypeid == 3) {
                                  this.staffaccount[j].passport = this.staffaccount[
                                    j
                                  ].iddetails
                                    .split("-")
                                    .join("");
                                }
                              }

                              if (this.staffaccount[j].bankid == 26) {
                                this.paymentmode = "015";
                                this.bankcode = "001";
                              } else {
                                this.paymentmode = "012";
                                this.bank.forEach((element) => {
                                  if (element.id === this.staffaccount[j].bankid) {
                                    this.bankcode = element.mbbcode;
                                  }
                                });
                              }

                              body.push({
                                "Record Type": "D",
                                "Company Record Number": this.padSpaceLJ(
                                  this.companyrecnum,
                                  8
                                ),
                                "Payee Name": this.padSpaceLJ(
                                  this.staffaccount[j].accountname,
                                  40
                                ),
                                "Old IC": this.padSpaceLJ(
                                  this.staffaccount[j].oldic,
                                  12
                                ),
                                "New IC": this.padSpaceLJ(
                                  this.staffaccount[j].newic,
                                  12
                                ),
                                "Passport Number": this.padSpaceLJ(
                                  this.staffaccount[j].passport,
                                  12
                                ),
                                "Payment Mode": this.padRJ(this.paymentmode, 3),
                                "Bank Code": this.padRJ(this.bankcode, 3),
                                "Credit/Beneficiary A/C": this.padSpaceLJ(
                                  this.staffaccount[j].staffaccountno,
                                  20
                                ),
                                "Credit Amount": this.padRJ(
                                  getpay.replace(/\D/g, ""),
                                  15
                                ),
                                "Receiving Bank": this.padSpaceLJ("", 40),
                                "Payment Reference": this.padSpaceLJ("", 20),
                                "Payment Description": this.padSpaceLJ("", 40),
                                Address: this.padSpaceLJ("", 120),
                                "Processed Status": this.padSpaceLJ("", 2),
                                Reason: this.padSpaceLJ("", 30),
                                "GIRO ID Validation": this.padSpaceLJ("", 1),
                                Filler: this.padSpaceLJ("", 1),
                              });

                              holdpay.push(pay);
                              this.exCount++;
                            }
                          }
                        }
                      }

                    } 
                  }
                }
              }
            }
          }
        }



      let noacc = allacc.filter((e) => !selacc.includes(e));
      let noaccbank = selacc.filter((e) => !selaccbank.includes(e));
      let notpay = selaccbank.filter((e) => !selaccmode.includes(e));
      let notactive = selaccmode.filter((e) => !selaccactive.includes(e));
      let nopay = selaccactive.filter((e) => !selaccpay.includes(e));

      this.processedCount = allacc.length;
      this.successCount = selaccpay.length;
      this.errCount = allacc.length - selaccpay.length;

      if (noacc) {
        for (let i = 0; i < noacc.length; i++) {
          noacc[i].errormsg = this.$t('allbank.noaccfound');
          this.accErr.push(noacc[i]);
        }
      }

      if (noaccbank) {
        for (let i = 0; i < noaccbank.length; i++) {
          noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
          this.accErr.push(noaccbank[i]);
        }
      }

      if (notpay) {
        for (let i = 0; i < notpay.length; i++) {
          notpay[i].errormsg = this.$t('allbank.differentpaymentmethod');
          this.accErr.push(notpay[i]);
        }
      }

      if (notactive) {
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
      }

      if (nopay) {
        for (let i = 0; i < nopay.length; i++) {
          nopay[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopay[i]);
        }
      }

      if (totalAmt < 0) {
        this.negErrMsg = this.$t('setupreport_exportmapping.cannotinsertnegativevalue');
      }

      if (holdpay) {
        totalAmt = holdpay.reduce(function (a, b) {
          return a + b;
        }, 0);
        getAmt = totalAmt.toFixed(2);
        finalAmt = getAmt.split(".").join("");
      }

      this.gettotalAmt = getAmt;

      let getDate = "";

      if (this.formData.paymentdate) {
        getDate = moment(this.formData.paymentdate).format("DDMMYYYY");
      }

      let getHeader =
        "H" +
        "APS" +
        "P" +
        this.padRJ(this.formData.organizationrec, 3) +
        this.padRJ(this.formData.organizationbankid, 5) +
        this.padSpaceLJ(this.formData.organizationshortname, 13) +
        this.padSpaceLJ(this.formData.organizationname, 40) +
        this.padRJ(getAcc.accountno, 12) +
        this.padSpaceLJ(getDate, 8) +
        this.padSpaceLJ(this.formData.useridpaymentexpdate, 20) +
        this.padSpaceLJ(this.formData.creditingdate, 8) +
        this.padRJ(finalAmt, 15) +
        this.padSpaceLJ(this.formData.reference, 14) +
        this.padSpaceLJ(this.formData.description, 40) +
        this.padSpaceLJ("S", 1) +
        this.padSpaceLJ("", 1) +
        this.padSpaceLJ("", 1) +
        this.padSpaceLJ("", 3) +
        this.padSpaceLJ("", 12) +
        this.padSpaceLJ("", 12) +
        this.padRJ(0, 8) +
        this.padRJ(0, 6) +
        this.padSpaceLJ("", 40) +
        this.padSpaceLJ("", 1) +
        this.padSpaceLJ("", 40) +
        this.padSpaceLJ("", 18) +
        this.padSpaceLJ("", 2) +
        this.padSpaceLJ("", 52);

      let footer =
        "T" +
        this.padRJ(this.successCount, 15) +
        this.padRJ(finalAmt, 15) +
        this.padRJ(0, 30) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padRJ(0, 15) +
        this.padSpaceLJ("", 199);

      this.titleData =
        this.padRJ(vm.data.month, 2) +
        "_" +
        vm.data.year +
        "_" +
        "MAYBANK" +
        "_" +
        getAcc.accountno;

      if (body.length > 0) {
        const csvData = this.objectToTxt(getHeader, body, footer);
        this.download(csvData, this.titleData);
      }
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmt = this.gettotalAmt;
        item.accounterr = this.accErr;
        item.negErrMss = this.negErrMsg;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        item.currency = this.currency;
        this.$router.push({ name: "salaryexportlog", params: { item } });
      }
    },

    backHandler() {
      let param = this.parameter

      this.$router.push({ name: "salaryexport", params: { param } });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    this.loadStaffFilter();
    this.getCompanybankaccountListHandler();
    this.getPsnPayrollListHandler();
  },
};
</script>