<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
// import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('mmtaxperiodsetup.taxperiodsetuplist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                {text: this.$t('mmtaxperiodsetup.taxperiod'), value: 'taxperiod', width: '150px'},
                {text: this.$t('trainingsetup.startyear'), value: 'startyear', width: '150px'},
                {text: this.$t('setupclaim.startmonth'), value: 'startmonth', width: '150px'},
                {text: this.$t('mmtaxperiodsetup.endyear'), value: 'endyear', width: '150px'},
                {text: this.$t('mmtaxperiodsetup.endmonth'), value: 'endmonth', width: '150px'},
                {text: this.$t('mmtaxperiodsetup.fiscalyear'), value: 'tpmonth', width: '180px'},
                {text: this.$t('mmtaxperiodsetup.thresoldmonth'), value: 'thpmth', width: '150px'},
                {text: this.$t('mmtaxperiodsetup.thresoldamount'), value: 'thpamt', width: '150px'},
            ],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadTaxPeriodSetup() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxperiodsetup/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                // for (let i = 0; i < vm.dataItems.length; i++) {
                //     if (vm.dataItems[i].startdate) {
                //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                //     }

                //     if (vm.dataItems[i].enddate) {
                //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                //     }
                // }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'mmtaxperiodsetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'mmtaxperiodsetupnew'});
        }
    },

    mounted() {
        this.loadTaxPeriodSetup();
    }
}
</script>