<template>
    <div>
      <app-gridview
        v-bind:parentData="{
          tabHeaders: this.dataHeader,
          tabItems: this.dataItems,
          pageTitle: this.pageTitle,
          search: this.search,
          isAdvanceEdit: true,
        }"
        v-on:AdvanceEditItem="advanceEditItemHandler($event)"
        v-on:EditItem="editItemHandler($event)"
        v-on:DeleteItem="deleteItemHandler($event)"
        v-on:NewPressed="newRecordHandler"
      ></app-gridview>
    </div>
  </template>
  <script>
//    import moment from 'moment';
  
  import GridView from "../../../components/common/GridView";
  
  export default {
    components: {
      "app-gridview": GridView,
    },
  
    data() {
      return {
        pageTitle: 'Kpi Setting Exercise List',
        dataHeader: [
  
          { text: '', value: "action", sortable: false, width: "150px" },
          { text: 'Exercise', value: "exercode", sortable: false, width: "50px" },
          { text: 'Exercise Description', value: "appexercisecode", width: "120px" },
          { text: 'Start Date', value: "appexercisedesc", width: "200px" },
          { text: 'End Date', value: "startdate", width: "200px" },
          { text: 'Status', value: "enddate", width: "200px" },
          { text: 'Records #', value: "assessmenttypedesc", width: "200px" },
          { text: 'Pending Records', value: "rankingsetdesc", width: "200px" },
          { text: 'Open Records', value: "remarks", width: "200px" },
          { text: 'Locked Records', value: "remarks", width: "200px" },
  
         
        ],
        dataItems: [],
        loading: false,
        errMsg: "",
         search: this.$route.params.search,
      };
    },
  
    methods: {
      getAppraisalExerciseHandler() {

        this.dataItems = [
          {
            exercode: 'T2'
          },
          {
            exercode: 'T1'
          }
        ]
        // const vm = this;
  
        // this.loading = !this.loading;
  
        // this.$store.dispatch('get', {url: "/appraisalexercise/all"})
        //   .then(function (res) {
        //     vm.dataItems = res.data.rec;
  
        //      for (let i = 0; i < vm.dataItems.length; i++) {
        //               if (vm.dataItems[i].startdate) {
        //                   vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
        //               }
  
        //               if (vm.dataItems[i].enddate) {
        //                   vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
        //               }
        //            }
  
  
        //     vm.loading = !vm.loading;
        //   })
        //   .catch(function (err) {
        //     vm.errMsg = err;
        //   });
      },
  
      advanceEditItemHandler(item) {
        console.log(item);
        // this.$router.push({ name: "kpisettingedit", params: { id: item.id, search: item.search  } });
        this.$router.push({ name: "kpisettingstaff", params: { id: 1 } });
      },

      editItemHandler(item) {
        console.log(item);
        // this.$router.push({ name: "kpisettingedit", params: { id: item.id, search: item.search  } });
        this.$router.push({ name: "kpisettingedit", params: { id: 1 } });
      },
  
      newRecordHandler() {
        this.$router.push({ name: "kpisettingnew" });
      },
    },
    mounted() {
      this.getAppraisalExerciseHandler();
    },
  };
  </script>