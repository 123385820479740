<template>
    <div>
        <monthlytax-form v-if="this.id == 5"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxhlb-form v-if="this.id == 11"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxambank-form v-if="this.id == 16"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxcimb-form v-if="this.id == 23"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxaffin-form v-if="this.id == 35"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxpublic-form v-if="this.id == 30"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlytaxhsbc-form v-if="this.id == 41"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlyTaxForm from '../../../components/payroll/monthlytaxexport/MonthlyTax';
import MonthlyTaxHLBForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxHLB';
import MonthlyTaxAmbankForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxAmbank';
import MonthlyTaxCIMBForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxCIMB';
import MonthlyTaxPUBLICForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxPublic';
import MonthlyTaxAffinForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxAffin';
import MonthlyTaxHSBForm from '../../../components/payroll/monthlytaxexport/MonthlyTaxHSBC';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlytax-form': MonthlyTaxForm,
        'monthlytaxhlb-form': MonthlyTaxHLBForm,
        'monthlytaxambank-form': MonthlyTaxAmbankForm,
        'monthlytaxcimb-form': MonthlyTaxCIMBForm,
        'monthlytaxpublic-form': MonthlyTaxPUBLICForm,
        'monthlytaxaffin-form': MonthlyTaxAffinForm,
        'monthlytaxhsbc-form': MonthlyTaxHSBForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('alltax.monthlytax'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },

}

</script>