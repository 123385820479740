<template>
    <div>
        <v-container>
            <v-form ref="form" enctype="multipart/form-data">
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span
                            v-if="this.staffData.staffid"
                            class="page_title_value"
                            >{{ this.pageTitleValue }}</span
                        >
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 pt-6"
                >
                    <v-row>
                        <v-col cols="8" sm="8" class="mt-n4">
                            <input type="hidden" v-model="staffData.stfstamp" />
                            <input type="hidden" v-model="staffData.movstamp" />

                            <!-- Staff Here -->
                            <div>
                                <v-row v-if="!this.isEdit && !this.staffData.lockstaff">
                                    <v-col cols="11" sm="11" class="pb-n4">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.name')"
                                            type="text"
                                            :rules="[rules.required]"
                                            hide-selected
                                            :items="this.personList"
                                            v-model="staffData.psnid"
                                            @input="updateValue"
                                            @change="
                                                changePersonHandler(
                                                    staffData.psnid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row v-else>
                                    <v-col cols="9" sm="9" class="pb-n4">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.name')"
                                            type="text"
                                            :rules="[rules.required]"
                                            hide-selected
                                            :items="this.personList"
                                            v-model="staffData.psnid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2" sm="2" class="pb-n4" align="center">
                                        <v-avatar size="40" tile>
                                            <img
                                            :src="`${publicPath}images/print_letter.png`"
                                            @click="isPrint = !isPrint"
                                            />
                                        </v-avatar>
                                    </v-col>
                                </v-row>

                                <div>
                                    <v-row>
                                        <v-col
                                            cols="3"
                                            sm="3"
                                            v-if="!this.staffData.lockrecdate"
                                            class="pb-n4 mt-n8"
                                        >
                                            <v-text-field
                                                outlined
                                                :label="this.$t('columns.recorddate')" 
                                                type="date"
                                                v-model="staffData.recdate"
                                                @input="changeHireDate"
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="3"
                                            sm="3"
                                            v-else
                                            class="pb-n4 mt-n8"
                                        >
                                            <v-text-field
                                                outlined
                                                :label="this.$t('columns.recorddate')"
                                                type="date"
                                                v-model="staffData.recdate"
                                                readonly
                                                background-color="#ffeab2"
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="3"
                                            sm="3"
                                            v-if="
                                                this.isSalMov &&
                                                !this.staffData.lockrecdate
                                            "
                                            class="pb-n4 mt-n8"
                                        >
                                            <!--v-text-field
                                                outlined
                                                label="Actual Date"
                                                type="date"
                                                v-model="staffData.actualdate"
                                                @input="updateValue"
                                                dense
                                            ></v-text-field-->
                                        </v-col>

                                        <!--v-col
                                            cols="3"
                                            sm="3"
                                            v-else
                                            class="pb-n4 mt-n8"
                                        >
                                            <v-text-field
                                                outlined
                                                label="Actual Date"
                                                type="date"
                                                v-model="staffData.actualdate"
                                                readonly
                                                background-color="#ffeab2"
                                                dense
                                            ></v-text-field>
                                        </v-col-->

                                        <v-col
                                            cols="3"
                                            sm="3"
                                            v-if="
                                                this.isEdit &&
                                                !this.staffData.lockrecdate
                                            "
                                            class="pb-n4 mt-n8"
                                        >
                                            <v-btn
                                                dense
                                                @click="newMovementHandler"
                                                ><v-icon>add</v-icon></v-btn
                                            >
                                        </v-col>
                                        <v-col
                                            cols="3"
                                            sm="3"
                                            v-else
                                            class="pb-n4 mt-n8"
                                        >
                                        </v-col>
                                    </v-row>
                                </div>

                                <v-row>
                                    <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                        <v-text-field
                                            outlined
                                            :label="this.$t('recstaffdetails.employmentnumber')"
                                            type="number"
                                            ref="empno"
                                            :rules="[rules.required]"
                                            v-model="staffData.empno"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        sm="6"
                                        v-if="
                                            this.isNewHire &&
                                            !this.staffData.lockrecdate
                                        "
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('recstaffdetails.positionid')"
                                            type="text"
                                            :rules="[rules.required]"
                                            hide-selected
                                            :items="this.positionList"
                                            v-model="staffData.positionid"
                                            @input="updateValue"
                                            @change="
                                                changePositionHandler(
                                                    staffData.positionid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        sm="6"
                                        v-else-if="
                                            this.isJobMov &&
                                            !this.staffData.lockrecdate
                                        "
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('recstaffdetails.positionid')"
                                            type="text"
                                            :rules="[rules.required]"
                                            hide-selected
                                            :items="this.positionList"
                                            v-model="staffData.positionid"
                                            @input="updateValue"
                                            @change="
                                                changePositionHandler(
                                                    staffData.positionid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        sm="6"
                                        v-else
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('recstaffdetails.positionid')"
                                            type="text"
                                            :items="this.positionList"
                                            v-model="staffData.positionid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="positionCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="!this.staffData.lockstaff">
                                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                        <v-text-field
                                            outlined
                                            :label="this.$t('columns.staffcode')"
                                            type="text"
                                            :rules="[rules.required]"
                                            v-model="staffData.staffcode"
                                            @input="updateValue"
                                            dense
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n10">
                                        <v-checkbox
                                            v-model="staffData.headcount"
                                            :label="this.$t('recstaffdetails.headcount')"
                                            @change="updateValue"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-row v-else>
                                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                        <v-text-field
                                            outlined
                                            :label="this.$t('columns.staffcode')"
                                            type="text"
                                            :rules="[rules.required]"
                                            v-model="staffData.staffcode"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n10">
                                        <v-checkbox
                                            v-model="staffData.headcount"
                                            :label="this.$t('recstaffdetails.headcount')"
                                            disabled
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </div>

                            <div>
                                <v-row>
                                    <v-col
                                        cols="9"
                                        sm="9"
                                        v-if="
                                            !this.isNewHire &&
                                            !this.isFiltered &&
                                            !this.staffData.lockrecdate
                                        "
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.recordtype')"
                                            type="text"
                                            :items="movementList"
                                            hide-selected
                                            v-model="staffData.movtypeid"
                                            @input="updateValue"
                                            @change="
                                                changeMovementHandler(
                                                    staffData.movtypeid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col
                                        cols="9"
                                        sm="9"
                                        v-else-if="
                                            !this.isNewHire &&
                                            this.isFiltered &&
                                            !this.staffData.lockrecdate
                                        "
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.recordtype')"
                                            type="text"
                                            :items="filteredMovementList"
                                            hide-selected
                                            v-model="staffData.movtypeid"
                                            @input="updateValue"
                                            @change="
                                                changeMovementHandler(
                                                    staffData.movtypeid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col
                                        cols="9"
                                        sm="9"
                                        v-else
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.recordtype')"
                                            type="text"
                                            :items="movementList"
                                            hide-selected
                                            v-model="staffData.movtypeid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="movementCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="!this.staffData.lockrecdate">
                                    <v-col cols="7" sm="7" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.reason')"
                                            type="text"
                                            :items="reasonList"
                                            hide-selected
                                            v-model="staffData.reasonid"
                                            @input="updateValue"
                                            @change="
                                                changeReasonHandler(
                                                    staffData.reasonid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="reasonCode"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-dialog
                                            v-model="reasonDialog"
                                            max-width="500px"
                                            persistent
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn dense v-on="on">
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <app-genericform
                                                    v-bind:parentData="{
                                                        tabItems: reasonItems,
                                                        formTitle: `${this.$t('columns.reason')}`, 
                                                        formId: 'reason',
                                                    }"
                                                    v-on:input="
                                                        itemData = $event
                                                    "
                                                    v-on:saveReason="
                                                        saveReasonHandler
                                                    "
                                                    v-on:deleteReason="
                                                        deleteReasonHandler(
                                                            $event
                                                        )
                                                    "
                                                />

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="warning"
                                                        text
                                                        @click="
                                                            reasonDialog = false
                                                        "
                                                    >
                                                        {{$t('button.close')}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>

                                <v-row v-else>
                                    <v-col cols="7" sm="7" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.reason')"
                                            type="text"
                                            :items="reasonList"
                                            hide-selected
                                            v-model="staffData.reasonid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="reasonCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <!-- LocMove Here -->
                            <div
                                v-if="
                                    this.isLocMov && !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col
                                        v-if="isNewHire"
                                        cols="9"
                                        sm="9"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.company')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.companyList"
                                            hide-selected
                                            v-model="staffData.compnyid"
                                            @input="updateValue"
                                            @change="
                                                changeCompanyHandler(
                                                    staffData.compnyid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col
                                        v-else
                                        cols="9"
                                        sm="9"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.company')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.companyList"
                                            hide-selected
                                            v-model="staffData.compnyid"
                                            readonly
                                            background-color="#ffeab2"
                                            @change="
                                                changeCompanyHandler(
                                                    staffData.compnyid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="companyCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.branch')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.branchList"
                                            hide-selected
                                            v-model="staffData.brchid"
                                            @input="updateValue"
                                            @change="
                                                changeBranchHandler(
                                                    staffData.brchid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="branchCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.division')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.divisionList"
                                            hide-selected
                                            v-model="staffData.divnid"
                                            @input="updateValue"
                                            @change="
                                                changeDivisionHandler(
                                                    staffData.divnid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="divisionCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.department')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.departmentList"
                                            hide-selected
                                            v-model="staffData.deptid"
                                            @input="updateValue"
                                            @change="
                                                changeDepartmentHandler(
                                                    staffData.deptid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="departmentCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.section')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.sectionList"
                                            hide-selected
                                            v-model="staffData.sectioid"
                                            @input="updateValue"
                                            @change="
                                                changeSectionHandler(
                                                    staffData.sectioid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="sectionCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.unit')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.unitList"
                                            hide-selected
                                            v-model="staffData.unitid"
                                            @input="updateValue"
                                            @change="
                                                changeUnitHandler(
                                                    staffData.unitid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="unitCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.company')"
                                            type="text"
                                            ref="company"
                                            :items="this.companyList"
                                            v-model="staffData.compnyid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="companyCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.branch')"
                                            type="text"
                                            ref="rectype"
                                            :items="this.branchList"
                                            v-model="staffData.brchid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="branchCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.division')"
                                            type="text"
                                            ref="rectype"
                                            :items="this.divisionList"
                                            v-model="staffData.divnid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="divisionCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.department')"
                                            type="text"
                                            ref="rectype"
                                            :items="this.departmentList"
                                            v-model="staffData.deptid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="departmentCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.section')"
                                            type="text"
                                            ref="rectype"
                                            :items="this.sectionList"
                                            v-model="staffData.sectioid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="sectionCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.unit')"
                                            type="text"
                                            ref="rectype"
                                            :items="this.unitList"
                                            v-model="staffData.unitid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="unitCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <!-- JobMove Here -->
                            <div
                                v-if="
                                    this.isJobMov && !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.job')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.jobList"
                                            hide-selected
                                            v-model="staffData.jobid"
                                            @input="updateValue"
                                            @change="
                                                changeJobHandler(
                                                    staffData.jobid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="jobCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.grade')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.gradeList"
                                            hide-selected
                                            v-model="staffData.grdid"
                                            @input="updateValue"
                                            @change="
                                                changeGradeHandler(
                                                    staffData.grdid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="gradeCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.employmenttype')"
                                            type="text"
                                            :rules="[rules.required]"
                                            :items="this.emptypeList"
                                            hide-selected
                                            v-model="staffData.emptypeid"
                                            @input="updateValue"
                                            @change="
                                                changeEmpTypeHandler(
                                                    staffData.emptypeid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="emptypeCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.job')"
                                            type="text"
                                            :items="this.jobList"
                                            v-model="staffData.jobid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="jobCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.grade')"
                                            type="text"
                                            :items="this.gradeList"
                                            v-model="staffData.grdid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.grade')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="gradeCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.employmenttype')"
                                            type="text"
                                            :items="this.emptypeList"
                                            v-model="staffData.emptypeid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="emptypeCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <!-- SalMove Here -->
                            <div
                                v-if="
                                    this.isSalMov && !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8"
                                            v-show=falses>
                                        <v-text-field
                                            outlined
                                            :label="this.$t('recstaffdetails.basicsalary')"
                                            type="number"
                                            v-model="staffData.basicsal"
                                            @input="updateValue"
                                            @change="calcNewBasic"
                                            dense
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.currency')"
                                            type="text"
                                            :items="this.currencyList"
                                            hide-selected
                                            :rules="[rules.required]"
                                            v-model="staffData.crcyid"
                                            @input="updateValue"
                                            @change="
                                                changeCurrencyHandler(
                                                    staffData.crcyid
                                                )
                                            "
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="currencyCode"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-dialog
                                            v-model="currencyDialog"
                                            max-width="500px"
                                            persistent
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn dense v-on="on">
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <app-genericform
                                                    v-bind:parentData="{
                                                        tabItems: currencyItems,
                                                        formTitle: `${this.$t('columns.currency')}`, 
                                                        formId: 'currency',
                                                    }"
                                                    v-on:input="
                                                        itemData = $event
                                                    "
                                                    v-on:saveCurrency="
                                                        saveCurrencyHandler
                                                    "
                                                    v-on:deleteCurrency="
                                                        deleteCurrencyHandler(
                                                            $event
                                                        )
                                                    "
                                                />

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="warning"
                                                        text
                                                        @click="
                                                            currencyDialog = false
                                                        "
                                                    >
                                                        {{$t('button.close')}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('recstaffdetails.basicfreq')"
                                            type="text"
                                            :items="this.salfreqList"
                                            hide-selected
                                            v-model="staffData.basicfreqid"
                                            @input="updateValue"
                                            @change="
                                                changeSalFreqHandler(
                                                    staffData.basicfreqid
                                                )
                                            "
                                            dense
                                            :rules="[rules.required]"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            v-model="salfreqCode"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="!this.isNewHire" v-show=falses>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.adjustedamount')"
                                            type="number"
                                            outlined
                                            v-model="staffData.adjustamt"
                                            @change="changeAdjAmt"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.adjustedpercent')"
                                            type="number"
                                            outlined
                                            v-model="staffData.adjustpct"
                                            @change="changeAdjPct"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8" v-show=falses>
                                        <v-text-field
                                            outlined
                                            :label="this.$t('recstaffdetails.newbasicsalary')"
                                            type="number"
                                            v-model="staffData.newbasicsal"
                                            @change="updateValue"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8" v-show=falses>
                                        <v-text-field
                                            outlined
                                            :label="this.$t('recstaffdetails.basicsalary')"
                                            type="number"
                                            v-model="staffData.basicsal"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('columns.currency')"
                                            type="text"
                                            :items="this.currencyList"
                                            v-model="staffData.crcyid"
                                            :rules="[rules.required]"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="currencyCode"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="9" sm="9" class="pb-n4 mt-n8">
                                        <v-autocomplete
                                            outlined
                                            :label="this.$t('recstaffdetails.basicfreq')"
                                            type="text"
                                            :items="this.salfreqList"
                                            v-model="staffData.basicfreqid"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('columns.code')"
                                            v-model="salfreqCode"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-show=falses>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.adjustedamount')"
                                            type="number"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="staffData.adjustamt"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.adjustedpercent')"
                                            type="number"
                                            outlined
                                            readonly
                                            background-color="#ffeab2"
                                            v-model="staffData.adjustpct"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8" v-show=falses>
                                        <v-text-field
                                            outlined
                                            :label="this.$t('recstaffdetails.newbasicsalary')"
                                            type="number"
                                            v-model="staffData.newbasicsal"
                                            readonly
                                            background-color="#ffeab2"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <v-row>
                                <v-col cols="11" sm="11" class="pb-n5 mt-n8">
                                <v-file-input 
                                    ref="attach"
                                    :label="this.$t('recstaffdetails.attachment')" 
                                    outlined 
                                    v-model="fileHolder"
                                    @change="fileToBase64"
                                    dense></v-file-input>
                                </v-col>
                            </v-row>

                            <div v-if="this.staffData.attach">
                                <v-row>
                                <v-col cols="4" sm="6" class="pb-8 mt-n5">
                                    <p>
                                    <a 
                                        v-bind:href="this.staffData.attach" 
                                        v-bind:download="this.staffData.attachname"
                                    >{{staffData.attachname}}</a>
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteItemHandler"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    </p>
                                </v-col>
                                </v-row>
                            </div>

                            <v-row v-if="!this.staffData.lockrecdate">
                                <v-col cols="11" sm="11" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.remark')"
                                        id="psnname"
                                        type="text"
                                        ref="psnname"
                                        v-model="staffData.psnname"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <v-col cols="11" sm="11" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.remark')"
                                        id="psnname"
                                        type="text"
                                        ref="psnname"
                                        readonly
                                        v-model="staffData.psnname"
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="4" sm="4">
                            <!-- Profile Pic Here -->
                            <v-row>
                                <v-col class="pa-0" cols="10">
                                    <div class="text-center">
                                        <v-avatar
                                            class="profile"
                                            size="164"
                                            tile
                                        >
                                            <v-img
                                                v-if="!this.staffData.pic"
                                                :src="`${publicPath}images/avatar-1577909_960_720.png`"
                                            >
                                            </v-img>
                                            <v-img
                                                v-else
                                                :src="this.staffData.pic"
                                            >
                                            </v-img>
                                        </v-avatar>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Dates Related Here -->
                            <div
                                v-if="
                                    this.isNewHire &&
                                    !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-12"
                                    >
                                        <v-text-field
                                            dense
                                           :label="this.$t('recstaffdetails.hiredate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.hiredate"
                                            @input="updateValue"
                                            @change="updateServiceDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.servicedate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.servicedate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div
                                v-else-if="
                                    this.isNewHire &&
                                    !this.isEdit &&
                                    !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-12"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.hiredate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.hiredate"
                                            @input="updateValue"
                                            @change="updateServiceDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.servicedate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.servicedate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-12"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.hiredate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.hiredate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.servicedate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.servicedate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div>
                                <v-row>
                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            readonly
                                            :label="this.$t('recstaffdetails.serviceyear')"
                                            type="number"
                                            background-color="#ffeab2"
                                            outlined
                                            v-model="serviceYear"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            readonly
                                            :label="this.$t('recstaffdetails.servicemonth')"
                                            type="number"
                                            background-color="#ffeab2"
                                            outlined
                                            v-model="serviceMonth"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div
                                v-if="
                                    this.isConfirmation &&
                                    !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.probationmonth')"
                                            type="number"
                                            outlined
                                            v-model="staffData.probmth"
                                            @input="updateValue"
                                            @change="changeProbMonth"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.dueconfirmation')"
                                            type="date"
                                            outlined
                                            v-model="staffData.dueconfirmdate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.extendedprobation')"
                                            type="number"
                                            outlined
                                            v-model="staffData.exprobmth"
                                            @input="updateValue"
                                            @change="changeExProbMonth"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.dueextendedconfirmation')"
                                            type="date"
                                            outlined
                                            v-model="staffData.exdueconfirmdate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.confirmationdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.confirmdate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.probationmonth')"
                                            type="number"
                                            outlined
                                            v-model="staffData.probmth"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.dueconfirmation')"
                                            type="date"
                                            outlined
                                            v-model="staffData.dueconfirmdate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.extendedprobation')"
                                            type="number"
                                            outlined
                                            v-model="staffData.exprobmth"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.dueextendedconfirmation')"
                                            type="date"
                                            outlined
                                            v-model="staffData.exdueconfirmdate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.confirmationdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.confirmdate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div
                                v-if="
                                    this.isCessation &&
                                    !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.resignationnotidate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.resigndate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.resignationnoticeperiod')"
                                            type="number"
                                            outlined
                                            v-model="staffData.resignnotprd"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.lastworkingdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.lworkdate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.officallastdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.olastdate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.cessationdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.cessadate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.resignationnotidate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.resigndate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                           :label="this.$t('recstaffdetails.resignationnoticeperiod')"
                                            type="number"
                                            outlined
                                            v-model="staffData.resignnotprd"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.lastworkingdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.lworkdate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.officallastdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.olastdate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.cessationdate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.cessadate"
                                            background-color="#ffeab2"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <!-- Contract Move Here -->
                            <div
                                v-if="
                                    this.isContractMov &&
                                    !this.staffData.lockrecdate
                                "
                            >
                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.contractperiod_months')" 
                                            type="number" 
                                            outlined
                                            v-model="staffData.contrprd"
                                            @change="changeContractPeriod"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.contractexpirydate')"
                                            type="date"
                                            outlined
                                            v-model="staffData.contrenddate"
                                            @input="updateValue"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-else>
                                <v-row>
                                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                                        <v-text-field
                                            dense
                                            :label="this.$t('recstaffdetails.contractperiod_months')"
                                            type="number"
                                            outlined
                                            readonly
                                            v-model="staffData.contrprd"
                                            background-color="#ffeab2"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="10"
                                        sm="10"
                                        class="pb-n4 mt-n8"
                                    >
                                        <v-text-field
                                            dense
                                            :label="this.$t('reporalertlisting.contractexpirydate')"
                                            type="date"
                                            outlined
                                            readonly
                                            v-model="staffData.contrenddate"
                                            background-color="#ffeab2"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
        <div v-if="isPrint">
            <app-print
            v-bind:parentObject="{
                pageTitleValue: this.pageTitleValue,
                isPrint: this.isPrint,
                movementid: this.staffData.movementid,
            }"
            v-on:print="printHandler($event)"
            v-on:cancelPrint="isPrint = !isPrint"
            />
      </div>
    </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";

import GenericForm from "../../generic/GridForm";
import PrintDialog from "../../../components/common/PrintDialog";

export default {
    props: {
        parentObject: Object,
    },

    components: {
        "app-genericform": GenericForm,
        "app-print": PrintDialog,
    },

    computed: {
        serviceYear: {
            get: function () {
                var years = 0;

                if (this.staffData.servicedate) {
                    years = moment().diff(this.staffData.servicedate, "years");
                    if (!years) {
                        years = 0;
                    }
                }

                return years;
            },
        },

        serviceMonth: {
            get: function () {
                var months = 0;

                if (this.staffData.servicedate) {
                    var totalMonths = moment().diff(
                        this.staffData.servicedate,
                        "months"
                    );
                    months = totalMonths % 12;
                    if (!months) {
                        months = 0;
                    }
                }

                return months;
            },
        },
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                },
                number: (value) =>
                    value > -0.01 ||
                    this.$t('reportsparam.invalidnumber'),
            },

            staffData: {
                movementid: this.parentObject.data.movementid,
                staffcode: this.parentObject.data.staffcode,
                empno: this.parentObject.data.empno,
                hiredate: this.parentObject.data.hiredate,
                servicedate: this.parentObject.data.servicedate,
                probmth: this.parentObject.data.probmth,
                dueconfirmdate: this.parentObject.data.dueconfirmdate,
                exprobmth: this.parentObject.data.exprobmth,
                exdueconfirmdate: this.parentObject.data.exdueconfirmdate,
                confirmdate: this.parentObject.data.confirmdate,
                resigndate: this.parentObject.data.resigndate,
                resignnotprd: this.parentObject.data.resignnotprd,
                lworkdate: this.parentObject.data.lworkdate,
                olastdate: this.parentObject.data.olastdate,
                cessadate: this.parentObject.data.cessadate,
                headcount: this.parentObject.data.headcount,
                stfstamp: this.parentObject.data.stfstamp,
                movtypeid: this.parentObject.data.movtypeid,
                staffid: this.parentObject.data.staffid,
                psnid: this.parentObject.data.psnid,
                recdate: this.parentObject.data.recdate,
                recenddate: this.parentObject.data.recenddate,
                actualdate: this.parentObject.data.actualdate,
                reasonid: this.parentObject.data.reasonid,
                compnyid: this.parentObject.data.compnyid,
                brchid: this.parentObject.data.brchid,
                divnid: this.parentObject.data.divnid,
                deptid: this.parentObject.data.deptid,
                sectioid: this.parentObject.data.sectioid,
                unitid: this.parentObject.data.unitid,
                positionid: this.parentObject.data.positionid,
                emptypeid: this.parentObject.data.emptypeid,
                grdid: this.parentObject.data.grdid,
                jobid: this.parentObject.data.jobid,
                crcyid: this.parentObject.data.crcyid,
                prevbasicsal: this.parentObject.data.prevbasicsal,
                basicsal: this.parentObject.data.basicsal,
                basicfreqid: this.parentObject.data.basicfreqid,
                adjustamt: this.parentObject.data.adjustamt,
                adjustpct: this.parentObject.data.adjustpct,
                attach: this.parentObject.data.attach,
                attachname: this.parentObject.data.attachname,
                newbasicsal: this.parentObject.data.newbasicsal,
                contrprd: this.parentObject.data.contrprd,
                contrenddate: this.parentObject.data.contrenddate,
                remark: this.parentObject.data.remark,
                lockrecdate: this.parentObject.data.lockrecdate,
                lockstaff: this.parentObject.data.lockstaff,
                movstamp: this.parentObject.data.movstamp,
                pic: this.parentObject.data.pic,
                search: this.parentObject.data.search,
                userid: this.parentObject.data.userid,

				movtypedesc: this.parentObject.data.movtypedesc,
				psnname: this.parentObject.data.psnname,
				reasondesc: this.parentObject.data.reasondesc,
				compnyname: this.parentObject.data.compnyname,
				brchname: this.parentObject.data.brchname,
				deptname: this.parentObject.data.deptname,
				sectioname: this.parentObject.data.sectioname,
				unitname: this.parentObject.data.unitname,
				posdesc: this.parentObject.data.posdesc,
				emptypedesc: this.parentObject.data.emptypedesc,
				grddesc: this.parentObject.data.grddesc,
				jobtitle: this.parentObject.data.jobtitle,
				crcycode: this.parentObject.data.crcycode,
				idno: this.parentObject.data.idno,
            },

            itemData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0,
            },

            isNewHire: this.parentObject.isNewHire,
            isLocMov: this.parentObject.isLocMov,
            isJobMov: this.parentObject.isJobMov,
            isSalMov: this.parentObject.isSalMov,
            isContractMov: this.parentObject.isContrMov,
            isConfirmation: this.parentObject.isConfirmation,
            isCessation: this.parentObject.isCessation,
            isNewMov: false,
            positionCode: "",
            movementCode: "",
            reasonCode: "",
            companyCode: "",
            branchCode: "",
            divisionCode: "",
            departmentCode: "",
            sectionCode: "",
            unitCode: "",
            jobCode: "",
            gradeCode: "",
            emptypeCode: "",
            currencyCode: "",
            salfreqCode: "",
            positionList: [],
            movementList: [],
            reasonList: [],
            companyList: [],
            branchList: [],
            divisionList: [],
            departmentList: [],
            sectionList: [],
            jobList: [],
            gradeList: [],
            emptypeList: [],
            currencyList: [],
            salfreqList: [],
            unitList: [],
            personList: [],
            positions: [],
            positionassignment: [],
            usedPositions: [],
            defPosition: [],
            movements: [],
            movementDetails: [],
            reasons: [],
            companies: [],
            branches: [],
            divisions: [],
            departments: [],
            sections: [],
            units: [],
            jobs: [],
            grades: [],
            emptypes: [],
            currencies: [],
            salfreqs: [],
            persons: [],
            reasonItems: [],
            currencyItems: [],
            pageTitleValue: this.parentObject.pageTitleValue,
            fileHolder: null,
            isFiltered: false,
            filteredMovements: null,
            filteredMovementList: [],
            reasonDialog: false,
            currencyDialog: false,
            isEdit: this.parentObject.isEdit,
            errMsg: "",
            isErr: false,
            search: this.parentObject.searchnew,
            isPrint: false,
        };
    },

    methods: {
        updateServiceDate() {
            this.staffData.servicedate = this.staffData.hiredate;

            this.updateValue();
        },

        deleteItemHandler() {
          this.staffData.attach = ''
          this.staffData.attachname = ''
        },

        fileToBase64() {
          if (this.fileHolder) {
            var file = this.$refs.attach.internalValue;
            this.staffData.attachname = this.fileHolder.name
            
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.staffData.attach = b64
            }

            reader.readAsDataURL(file);
          } else {
              this.staffData.attach = null
          }
        },

        newMovementHandler() {
            this.isNewHire = false;
            this.isLocMov = false;
            this.isJobMov = false;
            this.isSalMov = false;
            this.isContractMov = false;
            this.isConfirmation = false;
            this.isCessation = false;

            this.isNewMov = true;
            this.isEdit = false;

            this.staffData.lockstaff = true;

            this.filteredMovements = this.movements.filter(
                (item) => item.movcode !== "HIRE"
            );

            for (let i = 0; i < this.filteredMovements.length; i++) {
                this.filteredMovementList.push({
                    text: this.filteredMovements[i].movdesc,
                    value: this.filteredMovements[i].id,
                });
            }

            this.isFiltered = true;

            this.positionList = [];
            for (let i = 0; i < this.positions.length; i++) {
                if (this.staffData.positionid) {
                    if (this.positions[i].id == this.staffData.positionid) {
                        this.positionList.push({
                            text: this.positions[i].poscode + ' ' + this.positions[i].posdesc,
                            value: this.positions[i].id,
                        });
                    }
                }

                if (this.positions[i].isassigned == "N") {
                    this.positionList.push({
                        text: this.positions[i].poscode + ' ' + this.positions[i].posdesc,
                        value: this.positions[i].id,
                    });
                }
            }

            this.staffData.movtypeid = 0;
            this.changeMovementHandler(this.staffData.movtypeid);
        },

        saveReasonHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url:
                            "/reason/" +
                            this.itemData.itemid,
                        payload: {
                            reasoncode: vm.itemData.itemcode,
                            reasondesc: vm.itemData.itemdesc,
                            stamp: vm.itemData.itemstamp,
                        }}
                    )
                    .then((res) => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.reasons = [];
                            vm.reasonList = [];
                            vm.reasonItems = [];

                            vm.loadReasons();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: "/reason/create", payload: {
                        reasoncode: vm.itemData.itemcode,
                        reasondesc: vm.itemData.itemdesc,
                    }})
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.reasons = [];
                            vm.reasonList = [];
                            vm.reasonItems = [];

                            vm.loadReasons();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        deleteReasonHandler($event) {
            let vm = this;

            if (confirm("Remove " + $event.desc + "?")) {
                this.$store.dispatch('delete', {url:
                            "/reason/" +
                            $event.id +
                            "/" +
                            this.$store.getters.userId
                    })
                    .then((res) => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.reasons = [];
                            vm.reasonList = [];
                            vm.reasonItems = [];

                            vm.loadReasons();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        saveCurrencyHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url:
                            "/currency/" +
                            this.itemData.itemid,
                        payload: {
                            crcycode: vm.itemData.itemcode,
                            crcydesc: vm.itemData.itemdesc,
                            stamp: vm.itemData.itemstamp,
                        }}
                    )
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.currencies = [];
                            vm.currencyList = [];
                            vm.currencyItems = [];

                            vm.loadCurrencies();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: "/currency/create", payload: {
                        crcycode: vm.itemData.itemcode,
                        crcydesc: vm.itemData.itemdesc,
                    }})
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.currencies = [];
                            vm.currencyList = [];
                            vm.currencyItems = [];

                            vm.loadCurrencies();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        deleteCurrencyHandler($event) {
            let vm = this;

            if (confirm("Remove " + $event.desc + "?")) {
                this.$store.dispatch('delete', {url:
                            "/currency/" +
                            $event.id +
                            "/" +
                            this.$store.getters.userId
                        })
                    .then((res) => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.currencies = [];
                            vm.currencyList = [];
                            vm.currencyItems = [];

                            vm.loadCurrencies();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        changeProbMonth() {
            let recdate = new Date(this.staffData.recdate);
            let dueConfirm = moment(recdate).add(
                parseInt(this.staffData.probmth),
                "M"
            );
            this.staffData.dueconfirmdate = moment(dueConfirm).format(
                "YYYY-MM-DD"
            );
            this.updateValue();
        },

        changeContractPeriod() {	
            let recdate = new Date(this.staffData.recdate);	
            let dueConfirm = moment(recdate).add(	
                parseInt(this.staffData.contrprd),	
                "M"	
            );	
            this.staffData.contrenddate = moment(dueConfirm).format(	
                "YYYY-MM-DD"	
            );	
            this.updateValue();	
        },

        changeExProbMonth() {
            if (this.staffData.exprobmth == ''){
                this.staffData.exprobmth = null;
            } 

            if (this.staffData.exdueconfirmdate == ''){
                this.staffData.exdueconfirmdate = null;
            }

            let dueconfirmdate = new Date(this.staffData.dueconfirmdate);
            let dueExConfirm = moment(dueconfirmdate).add(
                parseInt(this.staffData.exprobmth) ,
                "M"
            );
            this.staffData.exdueconfirmdate = moment(dueExConfirm).format(
                "YYYY-MM-DD"
            );
            
            this.updateValue();
        },

        changeHireDate() {
            if (this.isNewHire) {
                this.staffData.hiredate = this.staffData.recdate;
                this.staffData.servicedate = this.staffData.hiredate;
                this.changeProbMonth();
            } else {
                this.staffData.recenddate = moment(this.staffData.recdate)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");
            }

            this.updateValue();
        },

        changePositionHandler(id) {
            let item = this.positions.find((item) => item.id === id);

            this.positionCode = item.poscode;

            if (this.isNewHire || this.isJobMov) {
                // populate all related fields
                if (item.compnyid) {
                    this.staffData.compnyid = item.compnyid;
                    this.changeCompanyHandler(item.compnyid);
                }

                if (item.brchid) {
                    this.staffData.brchid = item.brchid;
                    this.changeBranchHandler(item.brchid);
                }

                if (item.divnid) {
                    this.staffData.divnid = item.divnid;
                    this.changeDivisionHandler(item.divnid);
                }

                if (item.deptid) {
                    this.staffData.deptid = item.deptid;
                    this.changeDepartmentHandler(item.deptid);
                }

                if (item.sectioid) {
                    this.staffData.sectioid = item.sectioid;
                    this.changeSectionHandler(item.sectioid);
                }

                if (item.unitid) {
                    this.staffData.unitid = item.unitid;
                    this.changeUnitHandler(item.unitid);
                }

                if (item.jobid) {
                    this.staffData.jobid = item.jobid;
                    this.changeJobHandler(item.jobid);
                }

                if (item.grdid) {
                    this.staffData.grdid = item.grdid;
                    this.changeGradeHandler(item.grdid);
                }

                if (item.emptypeid) {
                    this.staffData.emptypeid = item.emptypeid;
                    this.changeEmpTypeHandler(item.emptypeid);
                }
                this.changeProbMonth();
                this.updateValue();
            }
        },

        changePersonHandler(id) {
            let item = this.persons.find((item) => item.id === id);

            if (this.isNewHire && !this.isEdit) {
                let iCount = item.empcount;

                if (iCount) {
                    iCount++;
                    this.staffData.empno = iCount;
                } else {
                    this.staffData.empno = 1;
                }
            } 

            this.staffData.pic = item.pic;
            this.pageTitleValue = item.psnname;
            this.updateValue();
        },

        changeMovementHandler(id) {
            if (id == 0) {
                this.movementCode = "";
            } else {
                let item = this.movements.find((item) => item.id === id);

                this.movementCode = item.movcode;

                //this.loadMovementDetail(this.movementCode);

                this.setMovementDetail(id);
            }
        },

        changeReasonHandler(id) {
            let item = this.reasons.find((item) => item.id === id);

            this.reasonCode = item.reasoncode;
        },

        changeCompanyHandler(id) {
            let item = this.companies.find((item) => item.id === id);

            this.companyCode = item.compnycode;
        },

        changeBranchHandler(id) {
            let item = this.branches.find((item) => item.id === id);

            this.branchCode = item.brchcode;
        },

        changeDivisionHandler(id) {
            let item = this.divisions.find((item) => item.id === id);

            this.divisionCode = item.divncode;
        },

        changeDepartmentHandler(id) {
            let item = this.departments.find((item) => item.id === id);

            this.departmentCode = item.deptcode;
        },

        changeSectionHandler(id) {
            let item = this.sections.find((item) => item.id === id);

            this.sectionCode = item.sectiocode;
        },

        changeUnitHandler(id) {
            let item = this.units.find((item) => item.id === id);

            this.unitCode = item.unitcode;
        },

        changeJobHandler(id) {
            let item = this.jobs.find((item) => item.id === id);

            this.jobCode = item.jobcode;
        },

        changeGradeHandler(id) {
            let item = this.grades.find((item) => item.id === id);

            this.gradeCode = item.grdcode;

            this.staffData.probmth = item.prbmth;
            //this.changeProbMonth();
        },

        changeEmpTypeHandler(id) {
            let item = this.emptypes.find((item) => item.id === id);

            this.emptypeCode = item.emptype;
        },

        changeAdjAmt() {
            let adjustpct =
                parseFloat(this.staffData.adjustamt) /
                parseFloat(this.staffData.basicsal);
            this.staffData.adjustpct = adjustpct * 100;

            this.staffData.newbasicsal =
                parseFloat(this.staffData.basicsal) +
                parseFloat(this.staffData.adjustamt);
            this.updateValue();
        },

        changeAdjPct() {
            let adjustamt =
                parseFloat(this.staffData.basicsal) *
                (parseFloat(this.staffData.adjustpct) / 100);
            this.staffData.adjustamt = adjustamt;

            this.staffData.newbasicsal =
                parseFloat(this.staffData.basicsal) +
                parseFloat(this.staffData.adjustamt);
            this.updateValue();
        },

        calcNewBasic() {
            this.staffData.newbasicsal = parseFloat(this.staffData.basicsal);
            this.updateValue();
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find((item) => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changeSalFreqHandler(id) {
            let item = this.salfreqs.find((item) => item.id === id);

            this.salfreqCode = item.basicfreqcode;
        },

        updateValue() {
            if (this.staffData.exdueconfirmdate == ''){
                this.staffData.exdueconfirmdate = null;
            }
            
          //  console.log('exdue', this.staffData.exdueconfirmdate)
            this.$emit("input", {
                movementid: this.staffData.movementid,
                staffcode: this.staffData.staffcode,
                empno: this.staffData.empno,
                hiredate: this.staffData.hiredate,
                servicedate: this.staffData.servicedate,
                probmth: this.staffData.probmth,
                dueconfirmdate: this.staffData.dueconfirmdate,
                exprobmth: this.staffData.exprobmth,
                exdueconfirmdate: this.staffData.exdueconfirmdate,
                confirmdate: this.staffData.confirmdate,
                resigndate: this.staffData.resigndate,
                resignnotprd: this.staffData.resignnotprd,
                lworkdate: this.staffData.lworkdate,
                olastdate: this.staffData.olastdate,
                cessadate: this.staffData.cessadate,
                headcount: this.staffData.headcount,
                stfstamp: this.staffData.stfstamp,
                movtypeid: this.staffData.movtypeid,
                staffid: this.staffData.staffid,
                psnid: this.staffData.psnid,
                recdate: this.staffData.recdate,
                recenddate: this.staffData.recenddate,
                actualdate: this.staffData.actualdate,
                reasonid: this.staffData.reasonid,
                compnyid: this.staffData.compnyid,
                brchid: this.staffData.brchid,
                divnid: this.staffData.divnid,
                deptid: this.staffData.deptid,
                sectioid: this.staffData.sectioid,
                unitid: this.staffData.unitid,
                positionid: this.staffData.positionid,
                emptypeid: this.staffData.emptypeid,
                grdid: this.staffData.grdid,
                jobid: this.staffData.jobid,
                crcyid: this.staffData.crcyid,
                prevbasicsal: this.staffData.prevbasicsal,
                basicsal: this.staffData.basicsal,
                basicfreqid: this.staffData.basicfreqid,
                attach: this.staffData.attach,
                attachname: this.staffData.attachname,
                adjustamt: this.staffData.adjustamt,
                adjustpct: this.staffData.adjustpct,
                newbasicsal: this.staffData.newbasicsal,
                contrprd: this.staffData.contrprd,
                contrenddate: this.staffData.contrenddate,
                remark: this.staffData.remark,
                lockrecdate: this.staffData.lockrecdate,
                lockstaff: this.staffData.lockstaff,
                movstamp: this.staffData.movstamp,
                pic: this.staffData.pic,
                search: this.staffData.search,
                userid: this.staffData.userid,

                movtypedesc: this.staffData.movtypedesc,
                psnname: this.staffData.psnname,
                reasondesc: this.staffData.reasondesc,
                compnyname: this.staffData.compnyname,
                brchname: this.staffData.brchname,
                deptname: this.staffData.deptname,
                sectioname: this.staffData.sectioname,
                unitname: this.staffData.unitname,
                posdesc: this.staffData.posdesc,
                emptypedesc: this.staffData.emptypedesc,
                grddesc: this.staffData.grddesc,
                jobtitle: this.staffData.jobtitle,
                crcycode: this.staffData.crcycode,
                idno: this.staffData.idno,
            });
        },

        newHandler() {
            this.$router.push({ name: "staffnew" });
        },

        saveHandler() {
            this.updateValue()
            if (!this.$refs.form.validate()) {
                this.isErr = true;
                this.errMsg = "Some Information is missing";
            } else {
                if (
                    moment(this.staffData.recdate) < moment(this.staffData.hiredate)
                ) {
                    this.isErr = !this.isErr;
                    this.errMsg = "Record date cannot be earlier than Hire Date";
                    this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });

                    return;
                }

                if (this.isNewMov) {
                    this.$emit("newmovesave");
                } else {
                    this.$emit("save");
                }
            }
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            if (this.isNewHire || this.isEdit) {
                this.$router.push({ name: "stafflisths" , params: { search: this.search}});
            }

            if (this.isNewMov && !this.isEdit) {
                //refresh the page
                this.$router.go(0);
            }
        },

        // loadPositionAssignment(id) {
        //     const vm = this;
        //     console.log('id', id)

        //     axios
        //         .get', {url: "/positionassignment/" + id)
        //         .then((res) => {
        //             vm.positionassignment = res.data.rec;
        //             console.log('vm.positionassignment', vm.positionassignment)

        //             vm.positionList.push({
        //                 text: vm.positionassignment[0].posdesc,
        //                 value: vm.positionassignment[0].positionid,
        //             });

        //             this.changePositionHandler(this.staffData.positionid)
        //         })
        //         .catch((err) => {
        //             vm.isErr = !vm.isErr;
        //             vm.errMsg = err;
        //             vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        //         });
        // },

        loadPositions() {
            const vm = this;

            this.$store.dispatch('get', {url: "/position/all"})
                .then((res) => {
                    vm.positions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if ((res.data.rec[i].isdisable == "N" && res.data.rec[i].isassigned == "N") || 
                            res.data.rec[i].id == this.staffData.positionid) {
                            vm.positionList.push({
                                text: this.positions[i].poscode + ' ' + res.data.rec[i].posdesc,
                                value: res.data.rec[i].id,
                            });
                        }
                    }
                    if (this.staffData.positionid) this.changePositionHandler(this.staffData.positionid)

                    // if (this.staffData.positionid) {
                    //     this.loadPositionAssignment(this.staffData.positionid)
                    // }
                    
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadMovementTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: "/movtype/all"})
                .then((res) => {
                    vm.movements = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.movementList.push({
                            text: "Select Service Type",
                            value: 0,
                        });

                        if (res.data.rec[i].isdisable == "N") {
                            vm.movementList.push({
                                text: res.data.rec[i].movdesc,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.movtypeid) {
                        vm.changeMovementHandler(vm.staffData.movtypeid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadReasons() {
            const vm = this;

            this.$store.dispatch('get', {url: "/reason/all"})
                .then((res) => {
                    vm.reasons = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.reasonList.push({
                            text: res.data.rec[i].reasondesc,
                            value: res.data.rec[i].id,
                        });

                        vm.reasonItems.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].reasoncode,
                            desc: res.data.rec[i].reasondesc,
                            stamp: res.data.rec[i].stamp,
                        });
                    }

                    if (vm.staffData.reasonid) {
                        vm.changeReasonHandler(vm.staffData.reasonid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadCompanies() {
            const vm = this;
            this.$store.dispatch('get', {url: "/company/all"})
                .then((res) => {
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.companyList.push({
                                text: res.data.rec[i].compnyname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.compnyid) {
                        vm.changeCompanyHandler(vm.staffData.compnyid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadBranches() {
            const vm = this;
            this.$store.dispatch('get', {url: "/branch/all"})
                .then((res) => {
                    vm.branches = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.branchList.push({
                                text: res.data.rec[i].brchname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.brchid) {
                        vm.changeBranchHandler(vm.staffData.brchid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadDivisions() {
            const vm = this;
            this.$store.dispatch('get', {url: "/division/all"})
                .then((res) => {
                    vm.divisions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.divisionList.push({
                                text: res.data.rec[i].divnname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.divnid) {
                        vm.changeDivisionHandler(vm.staffData.divnid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadDepartments() {
            const vm = this;
            this.$store.dispatch('get', {url: "/department/all"})
                .then((res) => {
                    vm.departments = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.departmentList.push({
                                text: res.data.rec[i].deptname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.deptid) {
                        vm.changeDepartmentHandler(vm.staffData.deptid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadSections() {
            const vm = this;
            this.$store.dispatch('get', {url: "/section/all"})
                .then((res) => {
                    vm.sections = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.sectionList.push({
                                text: res.data.rec[i].sectioname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.sectioid) {
                        vm.changeSectionHandler(vm.staffData.sectioid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadUnits() {
            const vm = this;
            this.$store.dispatch('get', {url: "/unit/all"})
                .then((res) => {
                    vm.units = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.unitList.push({
                                text: res.data.rec[i].unitname,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.unitid) {
                        vm.changeUnitHandler(vm.staffData.unitid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadJobs() {
            const vm = this;
            this.$store.dispatch('get', {url: "/job/all"})
                .then((res) => {
                    vm.jobs = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N" || res.data.rec[i].id == vm.staffData.jobid) {
                            vm.jobList.push({
                                text: res.data.rec[i].jobtitle,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.jobid) {
                        vm.changeJobHandler(vm.staffData.jobid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadGrades() {
            const vm = this;
            this.$store.dispatch('get', {url: "/grade/all"})
                .then((res) => {
                    vm.grades = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == "N") {
                            vm.gradeList.push({
                                text: res.data.rec[i].grddesc,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.grdid) {
                        vm.changeGradeHandler(vm.staffData.grdid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadEmpTypes() {
            const vm = this;
            this.$store.dispatch('get', {url: "/emptype/all"})
                .then((res) => {
                    vm.emptypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].id == vm.staffData.emptypeid) {
                            vm.emptypeList.push({
                                text: res.data.rec[i].emptype,
                                value: res.data.rec[i].id,
                            });
                        }

                        if (res.data.rec[i].isdisable == "N") {
                            vm.emptypeList.push({
                                text: res.data.rec[i].emptype,
                                value: res.data.rec[i].id,
                            });
                        }
                    }

                    if (vm.staffData.emptypeid) {
                        vm.changeEmpTypeHandler(vm.staffData.emptypeid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadCurrencies() {
            const vm = this;
            this.$store.dispatch('get', {url: "/currency/all"})
                .then((res) => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push({
                            text: res.data.rec[i].crcydesc,
                            value: res.data.rec[i].id,
                        });

                        vm.currencyItems.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].crcycode,
                            desc: res.data.rec[i].crcydesc,
                            stamp: res.data.rec[i].stamp,
                        });
                    }

                    if (vm.staffData.crcyid) {
                        vm.changeCurrencyHandler(vm.staffData.crcyid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadSalFreq() {
            const vm = this;

            this.$store.dispatch('get', {url: "/salfreq/all"})
                .then((res) => {
                    vm.salfreqs = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.salfreqList.push({
                            text: res.data.rec[i].basicfreqdesc,
                            value: res.data.rec[i].id,
                        });
                    }

                    if (vm.staffData.basicfreqid) {
                        vm.changeSalFreqHandler(vm.staffData.basicfreqid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadPersons() {
            const vm = this;
            let endPoint = "";

            if (this.isNewHire) {
                endPoint = `/psnlist/${this.$store.getters.userId}`;
            } else {
                endPoint = `/person/all/${this.$store.getters.userId}`;
            }

            this.$store.dispatch('get', {url: endPoint})
                .then((res) => {
                    vm.persons = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.personList.push({
                            text: res.data.rec[i].psnname,
                            value: res.data.rec[i].id,
                        });
                    }

                    if (vm.staffData.psnid) {
                        vm.changePersonHandler(vm.staffData.psnid);
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadDropDowns() {
            this.loadPositions();
            this.loadMovementTypes();
            this.loadReasons();
            this.loadCompanies();
            this.loadBranches();
            this.loadDivisions();
            this.loadDepartments();
            this.loadSections();
            this.loadUnits();
            this.loadJobs();
            this.loadGrades();
            this.loadEmpTypes();
            this.loadCurrencies();
            this.loadSalFreq();
            this.loadPersons();
        },

        loadMovementDetails() {
            const vm = this;

            this.$store.dispatch('get', {url: "/movtyped/all"})
                .then((res) => {
                    vm.movementDetails = res.data.rec;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        setMovementDetail(id) {
            let item = this.movementDetails.find((item) => item.id === id);

            if (item.ishire == "Y") {
                this.isNewHire = true;
            } else {
                this.isNewHire = false;
            }

            if (item.islocmov == "Y") {
                this.isLocMov = true;
            } else {
                this.isLocMov = false;
            }

            if (item.isjobmov == "Y") {
                this.isJobMov = true;
            } else {
                this.isJobMov = false;
            }

            if (item.issalmov == "Y") {
                this.isSalMov = true;

                if (this.isNewMov) {
                    if (this.parentObject.data.prevbasicsal > 0) {
                        //this.staffData.basicsal = this.parentObject.data.prevbasicsal;
                        this.staffData.basicsal = this.staffData.newbasicsal;
                        this.updateValue();
                    }
                }
            } else {
                this.isSalMov = false;
            }

            if (item.iscontrmov == "Y") {
                this.isContractMov = true;
            } else {
                this.isContrMov = false;
            }

            if (item.isconfirmation == "Y") {
                this.isConfirmation = true;
            } else {
                this.isConfirmation = false;
            }

            if (item.iscessation == "Y") {
                this.isCessation = true;
            } else {
                this.isCessation = false;
            }
        },
        
        async printHandler(id) {
            const vm = this;
            vm.isPrint = !vm.isPrint;
            let showDate = new Date().toISOString().slice(0,10);
            let res = await this.$store.dispatch('get', {url: "/letter/" + id});       
            if (res.status == 200) {
                const pageCnt = res.data.rec[0].detail.length;
                const doc = new jsPDF('p', 'pt', 'letter');
                doc.canvas.height = 72 * 11;
                doc.canvas.width = 72 * 8.5;
                
                for(let i = 0; i < pageCnt; i ++) {
                    let e = res.data.rec[0].detail[i];
                    let cntnt = e.content;
                    cntnt = cntnt.replaceAll(`{{CURDATE()}}`, showDate);
                    Object.keys(vm.staffData).forEach( key => {
                        cntnt = cntnt.replaceAll(`{{${key}}}`, vm.staffData[key] ? vm.staffData[key] : '');
                    });
                    await new Promise((resolve) => {
                        doc.fromHTML(cntnt, 50, 30, {
                            'width': 520,
                        }, async function(){
                            if (pageCnt > e.pagenumber){
                            doc.addPage();
                            } else {
                                doc.output('dataurlnewwindow');
                            }
                            return resolve();
                        })
                    })
                }
            } else {
                vm.isErr = !vm.isErr;
            }
        },
    },

    created() {
        this.loadMovementDetails();
    },

    mounted() {
        this.loadDropDowns();

        if (this.parentObject.isEdit) {
            this.duplicateStaffData = this.staffData;

            if (this.parentObject.data.lockstaff == "N") {
                this.staffData.lockstaff = false;
            } else {
                this.staffData.lockstaff = true;
            }

            if (this.parentObject.data.lockrecdate == "N") {
                this.staffData.lockrecdate = false;
            } else {
                this.staffData.lockrecdate = true;
            }
        }
    },
};
</script>