var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading)?_c('div',[_c('app-form',{attrs:{"parentObject":{
        data: this.staffData,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
        isEdit: this.editMode,
        isNewHire: this.isNewHire,
        isLocMov: this.isLocMov,
        isJobMov: this.isJobMov,
        isSalMov: this.isSalMov,
        isContrMov: this.isContrMov,
        isConfirmation: this.isConfirmation,
      }},on:{"input":function($event){_vm.staffData = $event},"save":_vm.saveHandler,"profile":function($event){_vm.staffData.pic = $event},"error":_vm.errorHandler}}),(_vm.isErr)?_c('div',[_c('app-error',{attrs:{"parentError":{
          data: this.errMsg,
          isErr: this.isErr,
        }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }