<template>
<div v-if="!loading">
  <!-- <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="180"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading> -->
  <!-- <div v-if="!loading2"> -->
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
       v-on:cancel="cancelHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
<!-- </div> -->
</template>

<script>
//import moment from "moment";
// Import component
// import Loading from "vue-loading-overlay";
// // Import stylesheet
// import "vue-loading-overlay/dist/vue-loading.css";

import CaseSubjectMaintenanceForm from "../../../components/industrialrelations/casesubjectmaintenance/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": CaseSubjectMaintenanceForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
   // Loading,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: "Edit Case Staff Maintenance ",
      pageTitleValue: "",
      formData: [], 
      // formData: {
      // //    ircaseid: null,
      // //    casesubdetail: [{
      // //     documentdetail: []
      // // }],
      //  },

       casesubid: [],
     // casesubdetail: [],
     // documentdetail: [],
      loading : false,
      headLoading: true,
      loading2: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadCaseSubtHandler() {
      const vm = this;
      vm.loading = true;

      this.$store.dispatch('get', {url: "/casesubmaintenance/" + this.id})
        .then((res) => {

          vm.loading = false;
          vm.formData = res.data.rec[0];
          vm.formData.id = res.data.rec[0].id;
          vm.formData.ircaseid = res.data.rec[0].ircaseid;
         // vm.formData = res.data.rec[0];


          vm.pageTitleValue = res.data.rec[0].caseid;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.userid = this.$store.getters.userId;

          vm.loadCaseSubDetailHandler();

        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    
    loadCaseSubDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/casesubmaintenancedetail/" + this.id})
        .then((res) => {
          vm.formData.casesubdetail = res.data.rec;
          console.log('detail', vm.formData.casesubdetail)
  
          
          for (let i = 0; i < vm.formData.casesubdetail.length; i++) {
            
            Object.assign(vm.formData.casesubdetail[i], { action: 2 });
            console.log('casesub', vm.formData.casesubdetail[i])
            
            this.casesubid.push(vm.formData.casesubdetail[i].id)

          }

            console.log('casesubid', this.casesubid)
           vm.loading = false;
          // vm.loadCaseSubDTHandler();
        })
        
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

      loadCaseSubDTHandler() {

         const vm = this;

      this.$store.dispatch('get', {url: "/casesubmaintenancedt/" + this.id + "/" + this.casesubid})
        .then((res) => {
          vm.formData.casesubdetail.documentdetail = res.data.rec;
          
          console.log('documentdetail', vm.formData.casesubdetail.documentdetail)
          for (let i = 0; i < vm.formData.casesubdetail.documentdetail.length; i++) {
            
            Object.assign(vm.formData.casesubdetail.documentdetail[i], { action: 2 });
          }

          vm.loading2 = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      //console.log('formdata', payload: this.formData})
      this.$store.dispatch('put', {url: "/claimlimit/" + this.id, 
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "casesubmaintenancelist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "casesubmaintenancelist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/claimlimit/" +
            vm.id +
            "/" +
            this.formData.userid
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "casesubmaintenancelist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "casesubmaintenancenew" });
    },
  },

  mounted() {
    this.loadCaseSubtHandler(); //header
    //this.loadCaseSubDetailHandler(); //data table item
   // this.loadClaimLimitTravelDetailHandler(); //crudtable items
  },
};
</script>