<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:undo="undoHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import PayrollFinalizationForm from "../../../components/payroll/payrollfinalization/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": PayrollFinalizationForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('finizepayroll.payrollfinalizationedit'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadPayoutHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutctrl/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];
          vm.formData.userid = this.$store.getters.userId;

          vm.formData.startdate = moment(vm.formData.startdate).format(
            "YYYY-MM-DD"
          );
          vm.formData.enddate = moment(vm.formData.enddate).format(
            "YYYY-MM-DD"
          );

           vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    undoHandler() {
      const vm = this;
      vm.formData.pystatuscode = 20;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (this.formData.allowinput === "N") {
        this.formData.allowinput = 0;
      } else {
        this.formData.allowinput = 1;
      }

      if (this.formData.ssrel === "N") {
        this.formData.ssrel = 0;
      } else {
        this.formData.ssrel = 1;
      }

      this.$store.dispatch('put', {url: "/payoutctrl/" + this.formData.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "payrollfinalizationlist" , params: { search: this.search}});
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadPayoutHandler();
  },
};
</script>