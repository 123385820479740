<template>

    <div>

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-row>
            <v-col cols="1" sm="2" md="2">
                <v-btn block color="primary" @click="goBack(details)">
                {{$t('button.back')}}
                </v-btn>
            </v-col>
            <v-col cols="10" sm="10" md="10">
                <h3 class="blue--text mt-2">{{$t('taskmobile.claimapprovaldetails')}}</h3>
            </v-col>            
        </v-row>

        <app-claim-approval-details 
            v-bind:claimApprovalRecord="this.claimApprovalRecord"
            v-bind:staffApproveList="this.staffApproveList"
        />

    </div>

</template>

<script>

import moment from 'moment'
import ClaimApprovalInfo from '../../sscomponents/claimapprovaldetails/ClaimApprovalInfo.vue'
import Loading from "vue-loading-overlay";

export default {

    components: {
        "app-claim-approval-details": ClaimApprovalInfo,
        Loading
    },

    data() {
        return {
            claimApprovalRecord: null,
            leaveClaimInfo: [],
            staffApproveList: [],
            loading: false,
        }
    },

    methods: {
        goBack(info) {
            this.$router.push({name: 'selfserviceclaimapproval', params: { itemfromapprovaldetails: info }});
        },

        loadApprovalInfo(){

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('get', {url: "/claimrecord/wftran/" + this.claimApprovalRecord.clmrecordid})
            .then((res) => {

                const result = res.data;

                if (result.status === 200) {

                    vm.leaveClaimInfo = result.rec

                    if (vm.leaveClaimInfo.length > 0) {

                        for (var i = 0; i < vm.leaveClaimInfo.length; i++) {

                            if (vm.leaveClaimInfo[i].aprdate) {
                                vm.leaveClaimInfo[i].aprdate = moment(vm.leaveClaimInfo[i].aprdate).format("YYYY-MM-DD HH:mm:ss");
                            }

                        }

                    }

                }

                vm.staffApproveList = vm.leaveClaimInfo.filter(val => val.wfstatus == 'DONE')

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        }

    },

    computed: {
        details: function() {
            return this.claimApprovalRecord;
        }
    },

    mounted() {
       this.claimApprovalRecord = this.$route.params.item;
       this.loadApprovalInfo();
    }
}

</script>