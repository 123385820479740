<template>
    <div id="app-adminscreen">
        <v-menu offset-y>

            <template v-slot:activator="{ on }">
                <v-btn
                    text
                    v-on="on"
                >
                {{ $t('switchrole') }}
                </v-btn>
            </template>

            <v-list>

                <v-list-item
                v-for="screen in screenList"
                :key="screen.id"
                router
                :to="screen.route"
                >
                    <v-list-item-title
                    style="color: black; font-size: 14px; padding-top: -5px"
                    >{{ screen.value }}
                    </v-list-item-title>
                </v-list-item>

            </v-list>

        </v-menu>
    </div>
</template>

<script>

export default {
    data() {
        return {
            screenList: [
                {id: 1, value: "Admin Home", route: "/" + this.$i18n.locale + "/dashboard" }, // Admin Screen 
                {id: 2, value: "Self Service Home", route: "/" + this.$i18n.locale + "/selfservice/home/" } // Self-service screen
            ]
        }
    }
}

</script>