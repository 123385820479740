<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="deleteHandler"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>

import ServiceRecordForm from "../../../components/settings/servicerecord/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": ServiceRecordForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
       search: this.$route.params.search,
      pageTitle: this.$t('setupservicerectype.editservicerectype'),
      pageTitleValue: "",
      formData: {
        movtypeid: null,
        movtypedid: null,
        movcode: null,
        movdesc: null,
        ishire: false,
        islocmov: false,
        isjobmov: false,
        issalmov: false,
        iscontrmov: false,
        isconfirmation: false,
        iscessation: false,
        remark: null,
        isdisable: false,
        movstamp: 0,
        movdstamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (this.formData.isdisable == false) {
        this.formData.isdisable = 0;
      } else {
        this.formData.isdisable = 1;
      }

      if (this.formData.ishire == false) {
        this.formData.ishire = 0;
      } else {
        this.formData.ishire = 1;
      }

      if (this.formData.islocmov == false) {
        this.formData.islocmov = 0;
      } else {
        this.formData.islocmov = 1;
      }

      if (this.formData.isjobmov == false) {
        this.formData.isjobmov = 0;
      } else {
        this.formData.isjobmov = 1;
      }

      if (this.formData.issalmov == false) {
        this.formData.issalmov = 0;
      } else {
        this.formData.issalmov = 1;
      }

      if (this.formData.iscontrmov == false) {
        this.formData.iscontrmov = 0;
      } else {
        this.formData.iscontrmov = 1;
      }

      if (this.formData.isconfirmation == false) {
        this.formData.isconfirmation = 0;
      } else {
        this.formData.isconfirmation = 1;
      }

      if (this.formData.iscessation == false) {
        this.formData.iscessation = 0;
      } else {
        this.formData.iscessation = 1;
      }

      var payload = this.formData;

      this.$store.dispatch('put', {url: "/movtype/" + vm.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "servicerecordlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "servicerecordlist" });
    },

    deleteHandler($event) {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/movtype/" +
              this.id +
              "/" +
              this.formData.userid})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "servicerecordlist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "servicerecordnew" });
    },

    loadServiceRecordHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/movtype/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData.movtypeid = res.data.rec[0].id;
            vm.formData.movcode = res.data.rec[0].movcode;
            vm.formData.movdesc = res.data.rec[0].movdesc;
            vm.formData.remark = res.data.rec[0].remark;
            vm.formData.isdisable = res.data.rec[0].isdisable;
            vm.formData.movstamp = res.data.rec[0].stamp;
            vm.formData.userid = vm.$store.getters.userId;

            vm.pageTitleValue = vm.formData.movdesc;

            if (vm.formData.isdisable === "N") {
              vm.formData.isdisable = false;
            } else {
              vm.formData.isdisable = true;
            }

            vm.loadServiceDetailHandler(res.data.rec[0].id);
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadServiceDetailHandler(id) {
      const vm = this;

      this.$store.dispatch('get', {url: "/movtyped/" + id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData.movtypedid = res.data.rec[0].id;

            if (res.data.rec[0].ishire === "N") {
              vm.formData.ishire = false;
            } else {
              vm.formData.ishire = true;
            }

            if (res.data.rec[0].islocmov === "N") {
              vm.formData.islocmov = false;
            } else {
              vm.formData.islocmov = true;
            }

            if (res.data.rec[0].isjobmov === "N") {
              vm.formData.isjobmov = false;
            } else {
              vm.formData.isjobmov = true;
            }

            if (res.data.rec[0].issalmov === "N") {
              vm.formData.issalmov = false;
            } else {
              vm.formData.issalmov = true;
            }

            if (res.data.rec[0].iscontrmov === "N") {
              vm.formData.iscontrmov = false;
            } else {
              vm.formData.iscontrmov = true;
            }

            if (res.data.rec[0].isconfirmation === "N") {
              vm.formData.isconfirmation = false;
            } else {
              vm.formData.isconfirmation = true;
            }

            if (res.data.rec[0].iscessation === "N") {
              vm.formData.iscessation = false;
            } else {
              vm.formData.iscessation = true;
            }

            vm.formData.movdstamp = res.data.rec[0].stamp;

            vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadServiceRecordHandler();
  },
};
</script>