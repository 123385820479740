<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <!-- <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff"> -->
                        <v-col cols="12" sm="8" md="8" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                dense
                                v-model="formData.staffid"
                                :items="staffList"
                                @change="changeStaffHandler(formData.staffid)"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.staffno')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffno"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.companyname"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppersonpcbbfinformation.taxablegross')"
                                type="number"
                                ref="taxgross"
                                v-model="formData.taxgross"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmpsntaxbf.taxpaid')"
                                type="number"
                                ref="taxpaid"
                                v-model="formData.taxpaid"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmpsntaxbf.taxmonth')"
                                type="number"
                                ref="months"
                                v-model="formData.months"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmpsntaxbf.ssbpaid')"
                                type="number"
                                ref="ssbpaid"
                                v-model="formData.ssbpaid"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmpsntaxbf.taxperiod')"
                                type="text"
                                dense
                                v-model="formData.taxperiodid"
                                :items="taxperiodList"
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        // 'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                staffid: this.parentObject.data.staffid,
                staffno: this.parentObject.data.staffno,
                companyname: this.parentObject.data.companyname,
                taxgross: this.parentObject.data.taxgross,
                taxpaid: this.parentObject.data.taxpaid,
                ssbpaid: this.parentObject.data.ssbpaid,
                months: this.parentObject.data.months,
                taxperiodid: this.parentObject.data.taxperiodid,
                taxperiod: this.parentObject.data.taxperiod,
                tpstartyear: this.parentObject.data.tpstartyear,
                tpstartmonth: this.parentObject.data.tpstartmonth,
                tpendyear: this.parentObject.data.tpendyear,
                tpendmonth: this.parentObject.data.tpendmonth,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            selected: [],
            affectItems: [],

            staffList: [],
            staff: [],

            taxperiodList: [],
            taxperiod: [],

            errMsg: '',

            ssbSetupDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        loadStaffListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.staff = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadTaxPeriodHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmpsntaxbftaxperiod/all'})
                .then(res => {
                    vm.taxperiod = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxperiodList.push(
                            {
                                text: res.data.rec[i].taxperiod,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeStaffHandler(id) {
            let item = this.staff.find(item => item.id === parseInt(id));
            this.formData.staffno = item.staffcode;
            this.formData.companyname = item.compnyname;
        },

        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                staffno: this.formData.staffno,
                companyname: this.formData.companyname,
                taxgross: this.formData.taxgross,
                taxpaid: this.formData.taxpaid,
                ssbpaid: this.formData.ssbpaid,
                months: this.formData.months,
                taxperiodid: this.formData.taxperiodid,
                taxperiod: this.formData.taxperiod,
                tpstartyear: this.formData.tpstartyear,
                tpstartmonth: this.formData.tpstartmonth,
                tpendyear: this.formData.tpendyear,
                tpendmonth: this.formData.tpendmonth,
                stamp: this.formData.stamp,
                userid: this.$store.getters.userId
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmpsntaxbflist', params: { search: this.search}});
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = !this.isErr;
            this.errMsg = '';
        }
        
    },

    mounted() {
        this.loadStaffListHandler();
        this.loadTaxPeriodHandler();
    }
}
</script>