<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.leavecode')"
                                type="text"
                                ref="annual"
                                :rules="[rules.required]"
                                v-model="formData.leavecode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="annualleave"
                                :rules="[rules.required]"
                                v-model="formData.leavedesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.parentleave')"
                                type="text"
                                ref="parentleave"
                                :items="parentleaveList"
                                v-model="formData.parentid"
                                @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.periodtype')"
                                type="text"
                                ref="periodtype"
                                :rules="[rules.required]"
                                :items="ptypeList"
                                v-model="formData.ptypeid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.month')"
                                type="text"
                                ref="month"
                                :items="startmthList"
                                v-model="formData.startmth"
                                @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.unittype')"
                                type="text"
                                ref="unittype"
                                :rules="[rules.required]"
                                :items="unittypeList"
                                v-model="formData.unittype"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.displayby')"
                                type="text"
                                ref="display"
                                :rules="[rules.required]"
                                :items="dispunitList"
                                v-model="formData.dispunit"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.hourperdayconversion')"
                                type="text"
                                ref="hpdc"
                                v-model="formData.hrperday"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n3">
                            {{$t('setupleavesetup.controls')}}
                        </v-col>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n5">
                            <v-checkbox :label="this.$t('setupleavesetup.paidleave')" v-model="formData.ispaid" @change="updateValue()"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.earnleave')" v-model="formData.isearn" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.onceinemployment')" v-model="formData.isonce" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.medicalleave')" v-model="formData.ismed" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.includeoffday')" v-model="formData.isincloff" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.includerestday')" v-model="formData.isinclrest" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.includehol')" v-model="formData.isinclhol" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.onlyconfirmedstaffcanapply')" v-model="formData.isconfirmed" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.reasoniscompulsory')" v-model="formData.isreasoncompul" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.reliefpersoniscompulsory')" v-model="formData.isreliefcompul" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.cliniciscompulsory')" v-model="formData.iscliniccompu" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.parentleave')" v-model="formData.isparent" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.encash')" v-model="formData.isencash" @change="updateValue()" class="mt-n4"></v-checkbox>
                            <v-checkbox :label="this.$t('setupleavesetup.nollimit')" v-model="formData.isnolimit" @change="updateValue()" class="mt-n4"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-5 pl-0 mt-n8">
                            <v-subheader class="blue--text">{{$t('setupleavesetup.apppolicy')}}</v-subheader>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.minunit_transaction')"
                                type="text"
                                ref="minUnit"
                                v-model="formData.minpertran"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.maxunit_transaction')"
                                type="text"
                                ref="maxUnit"
                                v-model="formData.maxpertran"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.earlynotice')"
                                type="text"
                                ref="earlyNotice"
                                v-model="formData.earlynotice"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-5 pl-0 mt-n8">
                            <v-subheader class="blue--text">{{$t('setupleavesetup.carryforwardpolicy')}}</v-subheader>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-radio-group 
                                v-model="radiogroup" 
                                row
                                @change="changeCarryForwardHandler(radiogroup)">
                                <v-radio
                                    :label="this.$t('setupleavesetup.nocarryforward')"
                                    value="ncf"
                                ></v-radio>
                                <v-radio
                                    :label="this.$t('setupleavesetup.followcfrules')"
                                    value="fcf"
                                ></v-radio>
                                <v-radio
                                    :label="this.$t('setupleavesetup.useoverridingcfrules')"
                                    value="ocf"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.maxunittocf')"
                                type="text"
                                ref="maxunitcf"
                                v-model="formData.maxunitcf"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'ncf' || radiogroup == 'fcf'"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.maxpercenttocf_balance')"
                                type="text"
                                ref="maxBalancePercent"
                                v-model="formData.maxperccf"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'ncf' || radiogroup == 'fcf'"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.maxpercenttocf_entitlement')"
                                type="text"
                                ref="maxEntitlement"
                                v-model="formData.maxpercentcf"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'ncf' || radiogroup == 'fcf'"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.bfvalidmonth')"
                                type="text"
                                ref="validMonth"
                                v-model="formData.bfmth"
                                @input="updateValue"
                                dense
                                :disabled="radiogroup == 'ncf' || radiogroup == 'fcf'"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-5 pl-0 mt-n8">
                            <v-subheader class="blue--text">{{$t('setupleavesetup.depedencies')}}</v-subheader>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleavesetup.selectleave')"
                                type="text"
                                ref="parentleave"
                                :items="dependleaveList"
                                v-model="formData.dependleaveid"
                                @input="updateValue"
                                @change="changeLeaveTypeHandler(formData.dependleaveid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.code')"
                                type="text"
                                ref="alcode"
                                v-model="alCode"
                                @input="updateValue"
                                background-color="#ffeab2"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleavesetup.minunit')"
                                type="text"
                                ref="minunit"
                                v-model="formData.dependminunit"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            
            formData: {
                leavecode: this.parentObject.data.leavecode,
                leavedesc: this.parentObject.data.leavedesc,
                parentid: this.parentObject.data.parentid,
                ptypeid: this.parentObject.data.ptypeid,
                startmth: this.parentObject.data.startmth,
                unittype: this.parentObject.data.unittype,
                dispunit: this.parentObject.data.dispunit,
                hrperday: this.parentObject.data.hrperday,
                remark: this.parentObject.data.remark,
                ispaid: this.parentObject.data.ispaid,
                isearn: this.parentObject.data.isearn,
                isonce: this.parentObject.data.isonce,
                ismed: this.parentObject.data.ismed,
                isincloff: this.parentObject.data.isincloff,
                isinclrest: this.parentObject.data.isinclrest,
                isinclhol: this.parentObject.data.isinclhol,
                isconfirmed: this.parentObject.data.isconfirmed,
                isreasoncompul: this.parentObject.data.isreasoncompul,
                isreliefcompul: this.parentObject.data.isreliefcompul,
                iscliniccompu: this.parentObject.data.iscliniccompu,
                isparent: this.parentObject.data.isparent,
                isencash: this.parentObject.data.isencash,
                isnolimit: this.parentObject.data.isnolimit,
                minpertran: this.parentObject.data.minpertran,
                maxpertran: this.parentObject.data.maxpertran,
                earlynotice: this.parentObject.data.earlynotice,
                cancf: this.parentObject.data.cancf,
                maxunitcf: this.parentObject.data.maxunitcf,
                maxperccf: this.parentObject.data.maxperccf,
                maxpercentcf: this.parentObject.data.maxpercentcf,
                bfmth: this.parentObject.data.bfmth,
                usecfpolicy: this.parentObject.data.usecfpolicy,
                dependleaveid: this.parentObject.data.dependleaveid,
                dependminunit: this.parentObject.data.dependminunit,
                search: this.parentObject.data.search,
                rectimestamp: this.parentObject.data.rectimestamp,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            leaveType: [],
            parentleaveList: [],
            ptypeList: [],
            startmthList: [
                {
                    text: "January",
                    value: 1,
                },
                {
                    text: "February",
                    value: 2,
                },
                {
                    text: "March",
                    value: 3,
                },
                {
                    text: "April",
                    value: 4,
                },
                {
                    text: "May",
                    value: 5,
                },
                {
                    text: "June",
                    value: 6,
                },
                {
                    text: "July",
                    value: 7,
                },
                {
                    text: "August",
                    value: 8,
                },
                {
                    text: "September",
                    value: 9,
                },
                {
                    text: "October",
                    value: 10,
                },
                {
                    text: "November",
                    value: 11,
                },
                {
                    text: "December",
                    value: 12,
                },
            ],
            unittypeList: ['Hour', 'Day'],
            dispunitList: ['Hour', 'Day'],
            dependleaveList: [],
            radiogroup: null,
            
            alCode: '',
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadPeriodType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/periodtype/all'})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.ptypeList.push(
                            {
                                text: res.data.rec[i].ptype,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadLeaveType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/leavesetup/all'})
                .then(res => {
                    vm.leaveType = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.parentleaveList.push(
                            {
                                text: res.data.rec[i].leavedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.dependleaveList.push(
                            {
                                text: res.data.rec[i].leavedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (this.formData.dependleaveid != null) {
                        this.changeLeaveTypeHandler(this.formData.dependleaveid);
                    }

                    this.changeRadioHandler()
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeLeaveTypeHandler(id) {
            var item = this.leaveType.find(item => item.id === id);
            this.alCode = item.leavecode;
        },

        changeCarryForwardHandler(item) {
            if (item == 'ncf') {
                this.formData.cancf = false
                this.formData.usecfpolicy = false
            }
            if (item == 'fcf') {
                this.formData.cancf = true
                this.formData.usecfpolicy = true
            }
            if (item == 'ocf') {
                this.formData.cancf = true
                this.formData.usecfpolicy = false
                this.formData.maxunitcf = null
                this.formData.maxperccf = null
                this.formData.maxpercentcf = null
                this.formData.bfmth = null
            }
        },

        changeRadioHandler() {
            if (this.formData.cancf == false && this.formData.usecfpolicy == false) {
                this.radiogroup = 'ncf'
            }
            if (this.formData.cancf == true && this.formData.usecfpolicy == true) {
                this.radiogroup = 'fcf'
            }
            if (this.formData.cancf == true && this.formData.usecfpolicy == false) {
                this.radiogroup = 'ocf'
            }
        },

        updateValue() {
            this.$emit('input', {
                leavecode: this.formData.leavecode,
                leavedesc: this.formData.leavedesc,
                parentid: this.formData.parentid,
                ptypeid: this.formData.ptypeid,
                startmth: this.formData.startmth,
                unittype: this.formData.unittype,
                dispunit: this.formData.dispunit,
                hrperday: this.formData.hrperday,
                remark: this.formData.remark,
                ispaid: this.formData.ispaid,
                isearn: this.formData.isearn,
                isonce: this.formData.isonce,
                ismed: this.formData.ismed,
                isincloff: this.formData.isincloff,
                isinclrest: this.formData.isinclrest,
                isinclhol: this.formData.isinclhol,
                isconfirmed: this.formData.isconfirmed,
                isreasoncompul: this.formData.isreasoncompul,
                isreliefcompul: this.formData.isreliefcompul,
                iscliniccompu: this.formData.iscliniccompu,
                isparent: this.formData.isparent,
                isencash: this.formData.isencash,
                isnolimit: this.formData.isnolimit,
                minpertran: this.formData.minpertran,
                maxpertran: this.formData.maxpertran,
                earlynotice: this.formData.earlynotice,
                cancf: this.formData.cancf,
                maxunitcf: this.formData.maxunitcf,
                maxperccf: this.formData.maxperccf,
                maxpercentcf: this.formData.maxpercentcf,
                bfmth: this.formData.bfmth,
                usecfpolicy: this.formData.usecfpolicy,
                dependleaveid: this.formData.dependleaveid,
                dependminunit: this.formData.dependminunit,
                search: this.formData.search,
                rectimestamp: this.formData.rectimestamp,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({ name: "leavesetuplist", params: { search: this.search} });
        },

    },

    mounted() {
        this.loadLeaveType();
        this.loadPeriodType();
    },
    
}
</script>