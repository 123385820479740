<template>
    <div>
        <app-gridview
            v-bind:parentData="{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
    components: {
        "app-gridview": GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupprocesslistmaster.processmasterlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: "action", sortable: false, width: "100px" },
                { text: this.$t('setupprocesslistmaster.processlistcode'), value: "proclistmascode", width: "200px"},
                { text: this.$t('setupprocesslistmaster.processlisttype'), value: "proclistmasdesc", width: "200px"},
                { text: this.$t('columns.effectivedate'), value: "startdate", width: "200px" },
                { text: this.$t('setupposition.invaliddate'), value: "enddate", width: "200px" },
                { text: this.$t('columns.remark'), value: "remark", width: "200px" }
            ],
            dataItems: [],
            errMsg: "",
            search: this.$route.params.search,
        };
    },

    methods: {
        loadPayProcess() {
            const vm = this;

            this.$store.dispatch('get', {url: "/proclistmas/all"})
                .then(res => {
                    vm.dataItems = res.data.rec;

                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].startdate) {
                            vm.dataItems[i].startdate = moment(
                                vm.dataItems[i].startdate
                            ).format("YYYY-MM-DD");
                        }

                        if (vm.dataItems[i].enddate) {
                            vm.dataItems[i].enddate = moment(
                                vm.dataItems[i].enddate
                            ).format("YYYY-MM-DD");
                        }
                    }
                })
                .catch(err => {
                    vm.errMsg = err;
                });
        },

        editItemHandler(item) {
            this.$router.push({ name: "payprocessedit", params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({ name: "payprocessnew" });
        }
    },

    mounted() {
        this.loadPayProcess();
    }
};
</script>