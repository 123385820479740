<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getAmbankExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import XLSX from 'xlsx/xlsx';
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      titleData: this.parentObject.data.socsosubrecpy[0].payoutdesc,

      totalContAmt: null,
      totalRefno: null,

      accErr: [],
      socsodata: [],

      loading: false,


      processedCount: null,
      successCount: null,
      errCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    
    getAmbankExportListHandler() {
      this.loading = true;
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/defaultsocsoexport/all/${this.$store.getters.userId}`
          + "/" +  this.data.socsoexprecid
          + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.loading = false;

        vm.socsodata = res.data.rec;
        
        vm.exportHandler(); 
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },


    async getData() {
      const vm = this
      let body = []
      let holdpaycontamt = []
      let newsocsodetail = []
      let dataholder = vm.socsodata
      let socsodetail = this.filter(dataholder);

      this.processedCount = dataholder.length;
      this.successCount = socsodetail.length;
      this.errCount = dataholder.length - socsodetail.length;
     // let getyear = this.data.year.toString()

      socsodetail.forEach(function (o) {
        var existing = newsocsodetail.filter(function (i) {
          return i.staffcode === o.staffcode;
        })[0];
        if (!existing || existing == undefined) {
          newsocsodetail.push(o);
        } else {
          existing.amount += o.amount;
        }
      });

       console.log('newsocsodetail', newsocsodetail)

         body.push(
        
         {header: 'SOCSO Employer Number', key: this.padSpaceLJ(this.data.refnum, 12)},
         {header: 'Business Registration No', key: this.padSpaceLJ(newsocsodetail[0].regno,20) },
         {header: 'Staff IC No', key: 'Staff Name', header1: 'Contribution Month', 
          header2: 'Contribution Amount', header3: 'Employment Date', header4: 'Employment Status'},
    
       );

      newsocsodetail.forEach(element => {
        let dateHolder1 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.padRJ(1, 2);
        let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
              
        let date1 = moment(dateHolder1).format("YYYY-MM-DD");
        let date2 = moment(dateHolder2).format("YYYY-MM-DD"); 

        let monthcontribution;
        let dateget = ''
        let emptstatus = ''
      
        let icnoholder = ''
       // let regnoholder = ''
        if (element.icno != null) icnoholder = element.icno.split("-").join("")
      //  if (element.regno != null) regnoholder = element.regno.split("-").join("")

         if (date1 <= element.hiredate && date2 >= element.hiredate) {
          dateget = moment(element.hiredate).format("DDMMYYYY") 
          emptstatus = "B";
          }
                        
          if (date1 <= element.cessadate && date2 >= element.cessadate) { 
            dateget = moment(element.cessadate).format("DDMMYYYY");
              emptstatus = "H";
              }
        monthcontribution = this.padRJ(vm.data.month, 2) + vm.data.year;
        let contamtholder = element.amount.toFixed(2);
       // contamtholder = contamtholder.split(".").join("");
          body.push(

           {header: this.padSpaceLJ(icnoholder, 12), key:this.padSpaceLJ(element.psnname, 150), 
           header1: this.padSpaceLJ(monthcontribution, 6), header2: this.padSpaceLJ(contamtholder, 14),
           header3: this.padSpaceLJ(dateget, 8), header4: this.padSpaceLJ(emptstatus, 1)}
         );

        //  body.push({
        //   "Employer Code": this.padSpaceLJ(this.data.refnum, 12),
        //   "MyCoID/SSM Number": this.padSpaceLJ(regnoholder, 20),
        //   "ID Number": this.padSpaceLJ(icnoholder, 12),
        //   "Employee Name": this.padSpaceLJ(element.psnname, 150),
        //   "Month Contribution": this.padRJ(monthcontribution, 6),
        //   "Contribution Amount": this.padRJ(contamtholder, 14),
        //   "Employment Date": this.padSpaceRJ(dateget, 8),
        //   "Employment Status": this.padSpaceRJ(emptstatus, 1),
        // });

        holdpaycontamt.push(element.amount);
      });

      if (holdpaycontamt) {
        let totalPayAmtHolder = holdpaycontamt.reduce(function (a, b) {return a + b}, 0);
        this.totalContAmt = totalPayAmtHolder.toFixed(2);
      }

      if (body.length > 0) {

         const wd =  XLSX.utils.json_to_sheet((body), { origin: 'A2', skipHeader: true});
        const wb = XLSX.utils.book_new();
        console.log('wb', wb)
        XLSX.utils.book_append_sheet(wb, wd,  'Brg8A');
        XLSX.writeFile(wb, 'Brg8A' + '.xlsx');
      }
      //   const txtData = this.objectToTxt(body);
      //   this.download(txtData, this.titleData);
      // }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.amount != 0
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToTxt(data) {
      const csvRows = [];

      const headers = Object.keys(data[0]);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalContAmt = this.totalContAmt;
        item.accounterr = this.accErr;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;

        if (!this.isErr) {
          this.$router.push({ name: "monthlysocsoexportlog", params: { item } });
        }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlysocsoexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

    mounted() {
      //  this.getAmbankExportListHandler();
    },
};
</script>