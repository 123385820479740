<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                 <v-row v-if="!this.formData.lockstaff">
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                @input="updateValue"
                :items="staffDetailsList"
                @change="changeStaffIDandCompanyHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                readonly
                :items="staffDetailsList"
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.staffcode')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffcode"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                v-model="formData.compnyname"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

                    <!-- <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="Staff Name"
                                dense
                                ref="staffid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                :items="staffList"
                                hide-selected
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="Staff Name"
                                dense
                                ref="staffid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                :items="staffList"
                                readonly
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="Staff Code"
                                dense
                                v-model="formData.staffid"
                                :items="staff1List"
                                hide-selected
                                readonly
                                background-color="#ffeab2"
                                append-icon=""
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="Company Name"
                                dense
                                v-model="formData.staffid"
                                :items="staff2List"
                                hide-selected
                                readonly
                                background-color="#ffeab2"
                                append-icon=""
                                @input="updateValue"
                            ></v-autocomplete>
                        </v-col>
                    </v-row> -->

                    <v-row>
                        <!-- value of co.bankaccount must be taken from BankAccount.vue -->
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                dense
                                :label="this.$t('setupcompanybankaccount.companybankaccount')"
                                ref="bankaccountid"
                                outlined
                                v-model="formData.bankaccountid"
                                :rules="[rules.required]"
                                :items="bankaccount0List"
                                hide-selected
                                @change="changeBankaccountHandler(formData.bankaccountid)"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                readonly
                                :label="this.$t('setupstaffbankaccount.companybank')"
                                v-model="formData.bankaccountid"
                                hide-selected
                                :items="bankaccount2List"
                                dense
                                append-icon=" "
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-select
                                :label="this.$t('setupstaffbankaccount.accounttype')"
                                outlined
                                ref="svtypeid"
                                dense
                                hide-selected
                                @input="updateValue"
                                :items="savingtypeList"
                                v-model="formData.svtypeid"
                                @change="changeSavingtypeHandler(formData.svtypeid)"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                dense
                                readonly
                                :label="this.$t('setupstaffbankaccount.companyaccountno')"
                                outlined
                                v-model="formData.bankaccountid"
                                :items="bankaccount1List"
                                hide-selected
                                append-icon=" "
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>            
                    </v-row>
                    
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: currencyItems,
                                            formTitle: `${this.$t('columns.currency')}`,
                                            formId: 'currency'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCurrency="saveCurrencyHandler"
                                        v-on:deleteCurrency="deleteCurrencyHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="currencyDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <!-- bank to credit should be the same as bank, whilst bank itself will follow the compnyid-->
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupcompanybankaccount.bank')"
                                type="text"
                                ref="bankid"
                                hide-selected
                                :items="bankList"
                                v-model="formData.bankid"
                                @input="updateValue"
                                @change="changeBankHandler(formData.bankid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="bankCode"
                            ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="bankDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: bankItems,
                                            formTitle: 'Bank',
                                            formId: 'bank'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveBank="saveBankHandler"
                                        v-on:deleteBank="deleteBankHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="bankDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col> -->
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupstaffbankaccount.accountname')"
                                ref="accountname"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.accountname"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupstaffbankaccount.staffaccountno')"
                                ref="staffaccountno"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffaccountno"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!--start and end date is for when the transaction should be made-->
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                ref="startdate"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                ref="enddate"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                                class="removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-select
                                :label="this.$t('setupstaffbankaccount.idtype')"
                                type="text"
                                outlined
                                dense
                                hide-selected
                                @input="updateValue"
                                :items="idtypeList"
                                v-model="formData.idtypeid"
                                @change="changeIDtypeHandler(formData.idtypeid)"
                            ></v-select>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupstaffbankaccount.iddetails')"
                                type="text"
                                @input="updateValue"
                                dense
                                :rules="[rules.required]"
                                v-model="formData.iddetails"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupstaffbankaccount.allocation')"
                                ref="allocation"
                                type="number"
                                prefix= "%"
                                v-model="formData.allocation"
                                @input="updateValue"
                                :rules="[rules.percentage]"
                                dense
                                value="200"
                                class= "removeSpin"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupstaffbankaccount.allocationamount')"
                                ref="allocationamount"
                                type="number"
                                v-model="formData.allocationamount"
                                @input="updateValue"
                                dense
                                class= "removeSpin"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- precedence is like a priority, used only if there's more 2 same record-->
                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupprocesslistmaster.precedence')"
                                ref="precedence"
                                type="number"
                                class= "removeSpin"
                                v-model="formData.precedence"
                                @input="updateValue"
                                dense
                                value="1"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                ref="remark"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm
    },

    data() {
        return {
            rules: {
                required: value => !!value || 'Required.',
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            formData: {
                 psnid: this.parentObject.data.psnid,
                 staffid: this.parentObject.data.staffid,
                 staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                compnycode: this.parentObject.data.compnycode,
                compnyname: this.parentObject.data.compnyname,
                bankaccountid: this.parentObject.data.bankaccountid,
                svtypeid: this.parentObject.data.svtypeid,
                crcyid: this.parentObject.data.crcyid,
                bankid: this.parentObject.data.bankid,
                accountname: this.parentObject.data.accountname,
                staffaccountno: this.parentObject.data.staffaccountno,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                idtypeid: this.parentObject.data.idtypeid,
                iddetails: this.parentObject.data.iddetails,
                allocation: this.parentObject.data.allocation,
                allocationamount: this.parentObject.data.allocationamount,
                precedence: this.parentObject.data.precedence,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            idno: '',

            currencyCode: '',
            staffCode: '',
            bankaccountCode: '',
            bankCode: '',
            savingtypeCode: '',
            idtypeCode: '',

            currencyList: [],
            staffList: [],
            staff1List: [],
            staff2List: [],
            bankList: [],
            bankaccount0List: [],
            bankaccount1List: [],
            bankaccount2List: [],
            idtypeList: [],
            savingtypeList: [],

            currencies: [],
            banks: [],
            staffs: [],
            idtypes: [],
            savingtypes: [],
            bankaccounts: [],

            companiesitemsList: [],
            companies: [],
            person: [],
            staffdetails: [],
            staffDetailsList: [],

            currencyItems: [],
            bankItems: [],

            currencyDialog: false,
            bankDialog: false,

            currencyLoading: true,
            bankLoading: true,

            errMsg: '',
            isErr: false,

            icdetails: [],
            search: this.parentObject.searchnew
        }
    },

    methods: {

         //load companies list
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companiesitemsList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //change staffid and compnyid after selectin psnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyname = item.compnyname;
      this.formData.compnyid = item.compnyid;
      this.changeRefno();
    },


     //load staff details list
    loadStaffDetailsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    // load person list
    loadPersonListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.person = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

        // load handler is for loading the contents of items from the database
        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadStaffListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.staffs = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )

                     //   console.log('stafflist', vm.staffList)
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staff1List.push(
                            {
                                text: res.data.rec[i].staffcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staff2List.push(
                            {
                                text: res.data.rec[i].compnycode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (vm.formData.staffid) {
                        vm.changeStaffHandler(vm.formData.staffid);
                    }    
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadSavingtypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/savingtype/all'})
                .then(res => {
                    vm.savingtypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.savingtypeList.push(
                            {
                                text: res.data.rec[i].savingtypecode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (vm.formData.svtypeid) {
                        vm.changeSavingtypeHandler(vm.formData.svtypeid);

                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadIDtypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/idtype/all'})
                .then(res => {
                    vm.idtypes = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.idtypeList.push(
                            {
                                text: res.data.rec[i].idtypecode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                })
                   .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
                
        },

        loadBankaccountListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/bankaccount/all'})
                .then(res => {
                    vm.bankaccounts = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankaccount0List.push(
                            {
                                text: res.data.rec[i].accountcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankaccount1List.push(
                            {
                                text: res.data.rec[i].accountno,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankaccount2List.push(
                            {
                                text: res.data.rec[i].compnybankname,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (vm.formData.bankaccountid) {
                        vm.changeBankaccountHandler(vm.formData.bankaccountid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadBankListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/bank/all'})
                .then(res => {
                    vm.banks = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankList.push(
                            {
                                text: res.data.rec[i].bankname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.bankItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].bankcode,
                                desc: res.data.rec[i].bankname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.bankLoading = false;

                    if (vm.formData.bankid) {
                        vm.changeBankHandler(vm.formData.bankid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //save handler is if the user wants to save a new item into the database
        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/currency/' + this.itemData.itemid, payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/currency/create', payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveBankHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/bank/' + this.itemData.itemid, payload: {
                    bankcode: this.itemData.itemcode,
                    bankname: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/bank/create', payload: {
                bankcode: this.itemData.itemcode,
                bankname: this.itemData.itemdesc

                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //change handler is to show what has been loaded by the load handler
        changeStaffHandler(id) {
            let item = this.staffs.find(item => item.id === id);

            this.staffCode = item.staffcode;
            this.changeRefno();
        },

        changeSavingtypeHandler(id) {
            let item = this.savingtypes.find(item => item.id === id);

            this.savingtypeCode = item.savingtypecode;
        },

        changeIDtypeHandler(id) {
            let item = this.idtypes.find(item => item.id == id);

            this.idtypeCode = item.idtypecode;
            this.changeRefno();

        },

         changeRefno() {
            if ((this.formData.idtypeid == 1) && this.formData.psnid
            ) {
                let item = this.person.find(item => item.id === this.formData.psnid);

                this.idno = item.idno.split("-").join("");
                 
                this.formData.iddetails = this.idno
                console.log('idno', item.idno)

            } else {
                this.formData.iddetails = null;
            }
        },

        changeBankaccountHandler(id) {
            let item = this.bankaccounts.find(item => item.id === id);

            this.bankaccountCode = item.accountcode;
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changeBankHandler(id) {
            let item = this.banks.find(item => item.id === id);
            
            this.bankCode = item.bankcode;
        },

        //delete handler is for the user to delete any items from the database
        deleteCurrencyHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/currency/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        deleteBankHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/bank/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.banks = [];
                        vm.bankList = [];
                        vm.bankItems = [];

                        this.loadBankListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        updateValue() {

            //    console.log('updatee', this.formData)
            
            this.$emit('input', {
                 psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                bankaccountid: this.formData.bankaccountid,
                bankid: this.formData.bankid,
                svtypeid: this.formData.svtypeid,
                crcyid: this.formData.crcyid,
                accountname: this.formData.accountname,
                staffaccountno: this.formData.staffaccountno,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                idtypeid: this.formData.idtypeid,
                iddetails: this.formData.iddetails,
                allocation: this.formData.allocation,
                allocationamount: this.formData.allocationamount,
                precedence: this.formData.precedence,
                remark: this.formData.remark,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

        
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'staffbankaccountlist', params: { search: this.search}});
        },

        genCurrencyTabItems() {
            for (let i = 0; i < this.currencies.length; i++) {
                this.currencyItems.push(
                    {
                        id: this.currencies[i].id,
                        code: this.currencies[i].crcycode,
                        desc: this.currencies[i].crcydesc,
                        stamp: this.currencies[i].stamp
                    }
                )
            }
        },

        genBankTabItems() {
            for (let i = 0; i < this.banks.length; i++) {
                this.bankItems.push(
                    {
                        id: this.banks[i].id,
                        code: this.banks[i].bankcode,
                        desc: this.banks[i].bankname,
                        stamp: this.banks[i].stamp
                    }
                )
            }
        },

        

    },

    mounted() {
       this.loadCurrencyListHandler();
     //  this.loadStaffListHandler();
       this.loadBankaccountListHandler();
       this.loadSavingtypeListHandler();
       this.loadIDtypeListHandler();
       this.loadBankListHandler();
       this.loadPersonListHandler();
      this.loadStaffDetailsListHandler();
      // this.ICDetailsHandler();


       if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }

    }
}
</script>

<style>

.removeSpin input[type='number'] {
    -moz-appearance:textfield;
    -moz-appearance:autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

</style>