<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-card>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforconfirmationdate')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueConfirm"
                        :items="this.dataItemsDueconfirm"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDueconfirm"
                              :key="item.id"
                            >
                              <td>{{ item.duedays }}</td>
                              <td>{{ item.dueconfirmdate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                             
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

               
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueextendedconfirmationdate')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderExtendedDueConfirm"
                        :items="this.dataItemsExtendedDueconfirm"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsExtendedDueconfirm"
                              :key="item.id"
                            >
                              <td>{{ item.duedays }}</td>
                              <td>{{ item.exdueconfirmdate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforcessationdate')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueCessation"
                        :items="this.dataItemsDuecessation"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuecessation"
                              :key="item.id"
                            >
                              <td>{{ item.cessadays }}</td>
                              <td>{{ item.cessadate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.lworkdate }}</td>
                              <td>{{ item.olastdate }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforcontractexpiry')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueContract"
                        :items="this.dataItemsDuecontract"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuecontract"
                              :key="item.id"
                            >
                              <td>{{ item.contractdays }}</td>
                              <td>{{ item.contrenddate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                              <td>{{ item.contrprd }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.duefordocexpiry')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueDocument"
                        :items="this.dataItemsDuedocument"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuedocument"
                              :key="item.id"
                            >
                              <td>{{ item.documentdays }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.doctypecode }}</td>
                              <td>{{ item.docno }}</td>
                              <td>{{ item.startdate }}</td>
                              <td>{{ item.enddate }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text">{{$t('reporalertlisting.duefordob')}}</v-subheader>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueBirthday"
                        :items="this.dataItemsDuebirthday"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuebirthday"
                              :key="item.id"
                            >
                              <td>{{ item.birthdaydays }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.dob }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.age }}</td>
                              <td>{{ item.gendername }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforwanthree')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueWork3years"
                        :items="this.dataItemsDuework3years"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuework3years"
                              :key="item.id"
                            >
                              <td>{{ item.work3yearsdays }}</td>
                              <td>{{ item.servicedate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                              <td>{{ item.positionid }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforwanfive')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueWork5years"
                        :items="this.dataItemsDuework5years"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuework5years"
                              :key="item.id"
                            >
                              <td>{{ item.work5yearsdays }}</td>
                              <td>{{ item.servicedate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                              <td>{{ item.positionid }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-subheader class="blue--text"
                    >{{$t('reporalertlisting.dueforwanten')}}</v-subheader
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="16">
                      <v-data-table
                        :headers="dataHeaderDueWork10years"
                        :items="this.dataItemsDuework10years"
                        hide-default-footer
                        item-key="id"
                      >
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="item in dataItemsDuework10years"
                              :key="item.id"
                            >
                              <td>{{ item.work10yearsdays }}</td>
                              <td>{{ item.servicedate }}</td>
                              <td>{{ item.staffcode }}</td>
                              <td>{{ item.psnname }}</td>
                              <td>{{ item.hiredate }}</td>
                              <td>{{ item.compnycode }}</td>
                              <td>{{ item.deptcode }}</td>
                              <td>{{ item.brchcode }}</td>
                              <td>{{ item.positionid }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-container>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      pageTitle: this.$t('setupalert.alertlist'),

      dataHeaderDueConfirm: [
        { text: this.$t('reporalertlisting.dueindays'), value: "duedays", width: "150px" },
        { text: this.$t('reporalertlisting.dueforconfirmationdate'), value: "dueconfirmdate", width: "200px"},
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "psnname", width: "200px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "150px" },
        // {
        //   text: "Extended Confirmation Date",
        //   value: "exdueconfirmdate",
        //   width: "220px",
        // },
      ],

      dataHeaderExtendedDueConfirm: [
        { text: this.$t('reporalertlisting.dueindays'), value: "duedays", width: "150px" },
        { text: this.$t('reporalertlisting.extendedconfirmationdate'), value: "exdueconfirmdate", width: "220px"},
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "psnname", width: "200px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "150px" },
       
      ],

      dataHeaderDueCessation: [
        { text: this.$t('reporalertlisting.dueindays'), value: "cessadays", width: "120px" },
        { text: this.$t('reporalertlisting.dueforcessationdate'), value: "cessadate", width: "190px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('reporalertlisting.lastworkingdate'), value: "lworkdate", width: "180px" },
        { text: this.$t('reporalertlisting.officallastdate'), value: "olastdate", width: "180px" },
      ],

      dataHeaderDueContract: [
        { text: this.$t('reporalertlisting.dueindays'), value: "contractdays", width: "120px" },
        { text: this.$t('reporalertlisting.contractexpirydate'), value: "contrenddate", width: "190px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "120px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "150px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "150px" },
        { text: this.$t('reporalertlisting.contractduration'), value: "contrprd", width: "160px" },
      ],

      dataHeaderDueDocument: [
        { text: this.$t('reporalertlisting.dueindays'), value: "documentdays", width: "120px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('reporalertlisting.doctype'), value: "doctypecode", width: "150px" },
        { text: this.$t('reporalertlisting.docno'), value: "docno", width: "150px" },
        { text: this.$t('reporalertlisting.docstartdate'), value: "startdate", width: "180px" },
        { text: this.$t('reporalertlisting.docenddate'), value: "enddate", width: "180px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "120px" },
      ],

      dataHeaderDueBirthday: [
        { text: this.$t('reporalertlisting.dueindays'), value: "birthdaydays", width: "120px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.dob'), value: "dob", width: "140px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('reporalertlisting.age'), value: "age", width: "100px" },
        { text: this.$t('columns.gender'), value: "gendername", width: "100px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "120px" },
      ],

      dataHeaderDueWork3years: [
        { text: this.$t('reporalertlisting.dueindays'), value: "work3yearsdays", width: "120px" },
        { text: this.$t('reporalertlisting.servicedate'), value: "servicedate", width: "150px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "100px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "100px" },
        { text: this.$t('columns.position'), value: "positionid", width: "120px" },
      ],

      dataHeaderDueWork5years: [
        { text: this.$t('reporalertlisting.dueindays'), value: "work5yearsdays", width: "120px" },
        { text: this.$t('reporalertlisting.servicedate'), value: "servicedate", width: "150px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "100px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "100px" },
        { text: this.$t('columns.position'), value: "positioncode", width: "120px" },
      ],

      dataHeaderDueWork10years: [
        { text: this.$t('reporalertlisting.dueindays'), value: "work10yearsdays", width: "120px" },
        { text: this.$t('reporalertlisting.servicedate'), value: "servicedate", width: "150px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "100px" },
        { text: this.$t('columns.name'), value: "psnname", width: "150px" },
        { text: this.$t('recstaffdetails.hiredate'), value: "hiredate", width: "150px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "120px" },
        { text: this.$t('columns.department'), value: "deptcode", width: "120px" },
        { text: this.$t('columns.branch'), value: "brchcode", width: "100px" },
        { text: this.$t('columns.position'), value: "positioncode", width: "120px" },
      ],

      panel: [this.$route.params.panel - 1],
      dataItemsDueconfirm: [],
      dataItemsExtendedDueconfirm: [],
      dataItemsDuecessation: [],
      dataItemsDuecontract: [],
      dataItemsDuedocument: [],
      dataItemsDuebirthday: [],
      dataItemsDuework3years: [],
      dataItemsDuework5years: [],
      dataItemsDuework10years: [],
      loading: false,
      errMsg: "",
      isErr: false,
    };
  },

  methods: {
    getDueconfirmdateHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/dueconfirmdate/all/${this.$store.getters.userId}`})

        .then((res) => {
          vm.dataItemsDueconfirm = res.data.rec;

          for (let i = 0; i < vm.dataItemsDueconfirm.length; i++) {
            if (vm.dataItemsDueconfirm[i].dueconfirmdate) {
              vm.dataItemsDueconfirm[i].dueconfirmdate = moment(
                vm.dataItemsDueconfirm[i].dueconfirmdate
              ).format("YYYY-MM-DD");
            }

            // if (vm.dataItemsDueconfirm[i].exdueconfirmdate) {
            //   vm.dataItemsDueconfirm[i].exdueconfirmdate = moment(
            //     vm.dataItemsDueconfirm[i].exdueconfirmdate
            //   ).format("YYYY-MM-DD");
            // }

            if (vm.dataItemsDueconfirm[i].hiredate) {
              vm.dataItemsDueconfirm[i].hiredate = moment(
                vm.dataItemsDueconfirm[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDueconfirm[i].dueconfirmdate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDueconfirm[i].duedays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDueconfirm[i].duedays);
          }

          vm.loading = !vm.loading;
          this.getExtendedDueconfirmdateHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

     getExtendedDueconfirmdateHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/exdueconfirmdate/all/${this.$store.getters.userId}`})

        .then((res) => {
     
          vm.dataItemsExtendedDueconfirm = res.data.rec;
        
           for (let i = 0; i < vm.dataItemsExtendedDueconfirm.length; i++) {

            if (vm.dataItemsExtendedDueconfirm[i].exdueconfirmdate) {
              vm.dataItemsExtendedDueconfirm[i].exdueconfirmdate = moment(
                vm.dataItemsExtendedDueconfirm[i].exdueconfirmdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsExtendedDueconfirm[i].hiredate) {
              vm.dataItemsExtendedDueconfirm[i].hiredate = moment(
                vm.dataItemsExtendedDueconfirm[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date3 = moment().format("YYYY-MM-DD");
            var date4 = moment(vm.dataItemsExtendedDueconfirm[i].exdueconfirmdate).format(
              "YYYY-MM-DD"
            );
            console.log(date3);
            console.log(date4);
            vm.dataItemsExtendedDueconfirm[i].duedays = moment(date3).diff(
              moment(date4),
              "days"
            );
            console.log(vm.dataItemsExtendedDueconfirm[i].duedays);
          }

          vm.loading = !vm.loading;
          this.getDuecessationdateHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },


    getDuecessationdateHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duecessationdate/all/${this.$store.getters.userId}`})

        .then((res) => {
          vm.dataItemsDuecessation = res.data.rec;
          console.log("dataitem", vm.dataItemsDuecessation);

          for (let i = 0; i < vm.dataItemsDuecessation.length; i++) {
            if (vm.dataItemsDuecessation[i].cessadate) {
              vm.dataItemsDuecessation[i].cessadate = moment(
                vm.dataItemsDuecessation[i].cessadate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuecessation[i].hiredate) {
              vm.dataItemsDuecessation[i].hiredate = moment(
                vm.dataItemsDuecessation[i].hiredate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuecessation[i].lworkdate) {
              vm.dataItemsDuecessation[i].lworkdate = moment(
                vm.dataItemsDuecessation[i].lworkdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuecessation[i].olastdate) {
              vm.dataItemsDuecessation[i].olastdate = moment(
                vm.dataItemsDuecessation[i].olastdate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDuecessation[i].cessadate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuecessation[i].cessadays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuecessation[i].cessadays);
          }

          vm.loading = !vm.loading;
          this.getDuecontractexpiryHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuecontractexpiryHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duecontractexpiry/all/${this.$store.getters.userId}`})

        .then((res) => {
          vm.dataItemsDuecontract = res.data.rec;
          console.log("dataitem", vm.dataItemsDuecontract);

          for (let i = 0; i < vm.dataItemsDuecontract.length; i++) {
            if (vm.dataItemsDuecontract[i].contrenddate) {
              vm.dataItemsDuecontract[i].contrenddate = moment(
                vm.dataItemsDuecontract[i].contrenddate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuecontract[i].hiredate) {
              vm.dataItemsDuecontract[i].hiredate = moment(
                vm.dataItemsDuecontract[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDuecontract[i].contrenddate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuecontract[i].contractdays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuecontract[i].contractdays);
          }

          vm.loading = !vm.loading;
          this.getDuedocumentexpiryHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuedocumentexpiryHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duedocumentexpiry/all/${this.$store.getters.userId}`})

        .then((res) => {
          vm.dataItemsDuedocument = res.data.rec;
          console.log("dataitem", vm.dataItemsDuedocument);

          for (let i = 0; i < vm.dataItemsDuedocument.length; i++) {
            if (vm.dataItemsDuedocument[i].startdate) {
              vm.dataItemsDuedocument[i].startdate = moment(
                vm.dataItemsDuedocument[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuedocument[i].enddate) {
              vm.dataItemsDuedocument[i].enddate = moment(
                vm.dataItemsDuedocument[i].enddate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDuedocument[i].enddate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuedocument[i].documentdays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuedocument[i].documentdays);
          }

          vm.loading = !vm.loading;
          this.getDuebirthdayHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuebirthdayHandler() {
  const vm = this;

  this.loading = !this.loading;
  this.$store.dispatch('get', {url: `/duebirthday/all/${this.$store.getters.userId}`})
    .then((res) => {
      vm.dataItemsDuebirthday = res.data.rec;
      console.log("dataitem", vm.dataItemsDuebirthday);

      for (let i = 0; i < vm.dataItemsDuebirthday.length; i++) {
        if (vm.dataItemsDuebirthday[i].dob) {
          vm.dataItemsDuebirthday[i].dob = moment(
            vm.dataItemsDuebirthday[i].dob
          ).format("YYYY-MM-DD");
        }

        if (vm.dataItemsDuebirthday[i].enddate) {
          vm.dataItemsDuebirthday[i].enddate = moment(
            vm.dataItemsDuebirthday[i].enddate
          ).format("YYYY-MM-DD");
        }

        var date4 = moment(vm.dataItemsDuebirthday[i].dob).format("YYYY-MM-DD");
        console.log(date4);

        // Calculate days until next birthday
        const today = moment();
        const nextBirthday = moment(date4).year(today.year());

        if (today.isAfter(nextBirthday)) {
          nextBirthday.add(1, 'year');
        }

        nextBirthday.add(1, 'day'); // Add one more day for the correct due day
        vm.dataItemsDuebirthday[i].birthdaydays = nextBirthday.diff(today, 'days');
        console.log(vm.dataItemsDuebirthday[i].birthdaydays);

        var date3 = moment().format("YYYY-MM-DD");
        console.log(date3);

        // Calculate age
        vm.dataItemsDuebirthday[i].age = moment(date3).diff(moment(date4), 'years');
        console.log(vm.dataItemsDuebirthday[i].age);
      }

          vm.loading = !vm.loading;
          this.getDuework3yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework3yearsHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duework3years/all/${this.$store.getters.userId}`}
        )

        .then((res) => {
          vm.dataItemsDuework3years = res.data.rec;
          console.log("dataitem", vm.dataItemsDuework3years);

          for (let i = 0; i < vm.dataItemsDuework3years.length; i++) {
            if (vm.dataItemsDuework3years[i].servicedate) {
              vm.dataItemsDuework3years[i].servicedate = moment(
                vm.dataItemsDuework3years[i].servicedate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuework3years[i].hiredate) {
              vm.dataItemsDuework3years[i].hiredate = moment(
                vm.dataItemsDuework3years[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDuework3years[i].servicedate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuework3years[i].work3yearsdays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuework3years[i].work3yearsdays);
          }

          vm.loading = !vm.loading;
          this.getDuework5yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework5yearsHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duework5years/all/${this.$store.getters.userId}`}
        )

        .then((res) => {
          vm.dataItemsDuework5years = res.data.rec;
          console.log("dataitem", vm.dataItemsDuework5years);

          for (let i = 0; i < vm.dataItemsDuework5years.length; i++) {
            if (vm.dataItemsDuework5years[i].servicedate) {
              vm.dataItemsDuework5years[i].servicedate = moment(
                vm.dataItemsDuework5years[i].servicedate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuework5years[i].hiredate) {
              vm.dataItemsDuework5years[i].hiredate = moment(
                vm.dataItemsDuework5years[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(vm.dataItemsDuework5years[i].servicedate).format(
              "YYYY-MM-DD"
            );
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuework5years[i].work5yearsdays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuework5years[i].work5yearsdays);
          }

          vm.loading = !vm.loading;
          this.getDuework10yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework10yearsHandler() {
      const vm = this;

      this.loading = !this.loading;
      this.$store.dispatch('get', {url: `/duework10years/all/${this.$store.getters.userId}`}
        )

        .then((res) => {
          vm.dataItemsDuework10years = res.data.rec;
          console.log("dataitem", vm.dataItemsDuework10years);

          for (let i = 0; i < vm.dataItemsDuework10years.length; i++) {
            if (vm.dataItemsDuework10years[i].servicedate) {
              vm.dataItemsDuework10years[i].servicedate = moment(
                vm.dataItemsDuework10years[i].servicedate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItemsDuework10years[i].hiredate) {
              vm.dataItemsDuework10years[i].hiredate = moment(
                vm.dataItemsDuework10years[i].hiredate
              ).format("YYYY-MM-DD");
            }

            var date1 = moment().format("YYYY-MM-DD");
            var date2 = moment(
              vm.dataItemsDuework10years[i].servicedate
            ).format("YYYY-MM-DD");
            console.log(date1);
            console.log(date2);
            vm.dataItemsDuework10years[i].work10yearsdays = moment(date1).diff(
              moment(date2),
              "days"
            );
            console.log(vm.dataItemsDuework10years[i].work10yearsdays);
          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.getDueconfirmdateHandler();
  },
};
</script>