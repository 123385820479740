<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import Form from "../../../components/attendance/workhourspayitemmapping/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": Form,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('workhourspayitemmappingsetup.createnewworkhourspayitemmapping'),
      pageTitleValue: "",
      formData: {
        id: null,
        mapset: null,
        mapsetdesc: null,
        dataItems: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    saveHandler() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/workhourspayitemmapping/create",
          payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            this.isErr = true;
            this.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workhourspayitemmappinglist" });
          }
        })
        .catch((err) => {
          this.isErr = true;
          this.errMsg = err;
        });
    },
    cancelHandler() {
      this.$router.push({ name: "workhourspayitemmappinglist" });
    },
  },
};
</script>
