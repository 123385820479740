<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmsetup.ssbsetup')"
                                type="text"
                                dense
                                v-model="formData.ssbgroupid"
                                :items="ssbSetupList"
                                @change="changeSsbSetupHandler(formData.ssbgroupid)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('mmsetup.ssbsetup')"
                                type="text"
                                dense
                                v-model="formData.ssbgroupid"
                                :items="ssbSetupList"
                               readonly
                               background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5">
                            <v-dialog v-model="ssbSetupDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: ssbSetupItems,
                                            formTitle: `${this.$t('mmsetup.ssbsetup')}`,
                                            formId: 'ssbsetup'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveSsbSetup="saveSsbSetupHandler"
                                        v-on:deleteSsbSetup="deleteSsbSetupHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="ssbSetupDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.ssbgroupdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>       
                    
                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.ssbsetupdetail
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from './CRUDTable';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable, 
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                ssbgroupid: this.parentObject.data.ssbgroupid,
                ssbgroupcode: this.parentObject.data.ssbgroupcode,
                ssbgroupdesc: this.parentObject.data.ssbgroupdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                ssbsetupdetail: this.parentObject.data.ssbsetupdetail,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            tabHeader: [
                { text: this.$t('columns.actions'),             value: 'action',        width: '100px', sortable: false},
                { text: this.$t('mmssbsetup.uptowages'),        value: 'uptowages',     width: '200px', sortable: true},
                { text: this.$t('mmssbsetup.employerrate'),     value: 'employerrate',  width: '200px', sortable: true},
                { text: this.$t('mmssbsetup.workerrate'),       value: 'workerrate',    width: '200px', sortable: true},
                { text: this.$t('mmssbsetup.employermax'),    value: 'employermax', width: '200px', sortable: true},
                { text: this.$t('mmssbsetup.workermax'),      value: 'workermax',   width: '150px', sortable: true},
            ],

            selected: [],
            affectItems: [],
            ssbSetupList: [],
            ssbSetupItems: [],
            ssbSetup: [],

            errMsg: '',

            ssbSetupDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadSsbSetupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmssbsetup/all'})
                .then(res => {
                    vm.ssbSetup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.ssbSetupList.push(
                            {
                                text: res.data.rec[i].ssbgroupcode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.ssbSetupItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].ssbgroupcode,
                                desc: res.data.rec[i].ssbgroupdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        addItemHandler($event) {
            this.formData.ssbsetupdetail=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                ssbgroupid: this.formData.ssbgroupid,
                ssbgroupcode: this.formData.ssbgroupcode,
                ssbgroupdesc: this.formData.ssbgroupdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                ssbsetupdetail: this.formData.ssbsetupdetail,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveSsbSetupHandler() {
            const vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/mmssbsetup/' + this.itemData.itemid, payload: {
                ssbgroupcode: this.itemData.itemcode,
                ssbgroupdesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.ssbSetup = [];
                        vm.ssbSetupItems = [];
                        vm.ssbSetupList = [];

                        this.loadSsbSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/mmssbsetup/create', payload: {
                ssbgroupcode: this.itemData.itemcode,
                ssbgroupdesc: this.itemData.itemdesc,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.ssbSetup = [];
                        vm.ssbSetupItems = [];
                        vm.ssbSetupList = [];

                        this.loadSsbSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteSsbSetupHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/mmssbsetup/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.ssbSetup = [];
                        vm.ssbSetupItems = [];
                        vm.ssbSetupList = [];

                        this.loadSsbSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        changeSsbSetupHandler(id) {
            let item = this.ssbSetup.find(item => item.id === parseInt(id));
            
            this.formData.ssbgroupcode = item.ssbgroupcode;
            this.formData.ssbgroupdesc = item.ssbgroupdesc;
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmssbsetuplist', params: { search: this.search}});
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = !this.isErr;
            this.errMsg = '';
        }
        
    },

    mounted() {
        this.loadSsbSetupListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>