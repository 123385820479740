<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:profile="formData[0].picture = $event"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import AnnouncementForm from "../../../components/settings/announcement/Form";
import ErrorDialog from '../../../components/common/ErrorDialog';
import ConfirmDialog from '../../../components/common/ConfirmDialog';

export default {
    components: {
        'app-form': AnnouncementForm,
        'app-error': ErrorDialog,
        'app-confirm': ConfirmDialog
    },

    data() {
        return {
            id: this.$route.params.id,
            pageTitle: this.$t('recannouncementsetup.editannouncement'),
            pageTitleValue : '',
            formData: [],
            errMsg: '',
            isErr: false,
            loading: true,
            editMode: true,
            isConfirm: false,
             search: this.$route.params.search,
        }

    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },
        
        saveHandler() {
            const vm = this;

            if (this.formData[0].suspended == false) {
                this.formData[0].suspended = 0;
            } else {
                this.formData[0].suspended = 1;
            }
            

            var payload = this.formData[0];
            console.log(payload);
            this.$store.dispatch('put', {url: '/announcement/' + vm.id, payload: payload})
                .then(res => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    }
                    else {
                        this.$router.push({name: 'announcementlist', params: { search: this.search}});
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        deleteHandler($event) {
            const vm = this;

            this.isConfirm = !this.isConfirm

            if ($event) {
                
                this.$store.dispatch('delete', {url: '/announcement/' + this.id + '/' + this.formData[0].userid})
                .then (res => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    }  else {
                        this.$router.push({name: 'announcementlist'});        
                    }
                })
                .catch (err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }
        },

        createHandler() {
            this.$router.push({name: 'announcementnew'});
        },

        loadAnnouncementHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/announcement/' + vm.id})
                .then(res => {
                    if (res.status == 200) {
                        vm.formData = res.data.rec;
                        console.log(vm.formData);
                        vm.pageTitleValue = vm.formData[0].AnnouncementForm;

                        if (vm.formData[0].suspended === 'N') {
                            vm.formData[0].suspended = false;
                        } else {
                            vm.formData[0].suspended = true
                        }

                        if (res.data.rec[0].startdate) {
                          vm.formData[0].startdate = moment(
                            res.data.rec[0].startdate
                             ).format("YYYY-MM-DD");
                        }

                        if (res.data.rec[0].enddate) {
                          vm.formData[0].enddate = moment(
                            res.data.rec[0].enddate
                             ).format("YYYY-MM-DD");
                        }


                        vm.formData[0].userid = vm.$store.getters.userId;

                        vm.loading = !vm.loading;
                    } else {
                        vm.isErr = !vm.isErr;    
                    }
                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },
    },

    mounted() {
        this.loadAnnouncementHandler();
    }
    
}
</script>