<template>
  <div v-if="!loading">
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>
        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn @click="backButton()" color="orange" class="white--text"
              >{{$t('button.back')}}</v-btn
            >
          </v-row>
        </v-col>
        <!-- custom-form overflow-y-auto -->
        <v-container>
          <v-row>
            <v-col>
              <pivot
                :data="data"
                v-model="fields"
                :showSettings="defaultShowSettings"
                :reducer="reducer"
              >
              </pivot
              >`
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { Pivot } from "vue-pivot-table-plus";
import _ from "lodash";
import "@/assets/css/pivot.css";
//import Pivot from '@marketconnect/vue-pivot-table'

export default {
  components: {
    pivot: Pivot,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      getid: this.$route.query.id,
      getparam: this.$route.query.param,

      // dateForm: {
      getdate: this.$route.query.date,
        // getyeararr: this.$route.query.yeararr,
      // },

      currentkey: {
        row: [],
        cols: [],
      },
      comparing: false,

      data: [],
      //data to be post for pivot settings
      formData: {
        userid: this.$store.getters.userId,
        funcname: this.$route.meta.funcname,
        rowFields: [],
        colFields: [],
      },
      fields: {
        availableFields: [],
        rowFields: [],
        colFields: [],
        fieldsOrder: {},
      },
      // eslint-disable-next-line no-unused-vars
      //   reducer: (sum, item) => sum + item.aprvamt,

      defaultShowSettings: true,
      tableHeight: "1000px",

      pageTitle: this.$t('payrollpivotreport.payrollpivotdetail'),
      loading: true,

      detailList: [],
      payoutctrlList: [],
    };
  },
  methods: {
    reducer(sum, item) {
      return parseFloat(Number(sum) + item.aprvamt).toFixed(2);
    },
    loadDetailListHandler() {
      const vm = this;

      if (
        this.getparam.brchid != null ||
        this.getparam.compnyid != null ||
        this.getparam.deptid != null ||
        this.getparam.emptypeid != null ||
        this.getparam.gradeid != null ||
        this.getparam.staffid != null
      ) {
        this.$store.dispatch('post', {url: "/ppdetaillist/select",
            payload: vm.getparam})
          .then((res) => {
            vm.detailList = res.data.rec;
            this.processDetailData(vm.detailList);
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
          });
      } else {
        this.$store.dispatch('get', {url: "/ppdetaillist/all"})
          .then((res) => {
            vm.detailList = res.data.rec;
            this.processDetailData(vm.detailList);
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
          });
      }
    },

    processDetailData(detaillist) {
      let getdetaillist = [];
      let getsalhd = [];

      if (this.getid) {
        getdetaillist = detaillist.filter((x) => x.poid == this.getid);
      } else {
        getdetaillist = detaillist;
      }

      for (let i = 0; i < getdetaillist.length; i++) {
        if (getdetaillist[i].pistartdate) {
          getdetaillist[i].pistartdate = moment(
            getdetaillist[i].pistartdate
          ).format("YYYY-MM-DD");
        }

        if (getdetaillist[i].pienddate) {
          getdetaillist[i].pienddate = moment(
            getdetaillist[i].pienddate
          ).format("YYYY-MM-DD");
        }
        getsalhd.push(getdetaillist[i]);
      }

      // console.log(getsalhd);

      for (const key in getsalhd[0]) {
        if (
          key == "staffid" ||
          key == "staffcode" ||
          key == "psnname" ||
          key == "payitemcode" ||
          key == "payitemdesc" ||
          key == "payitemtypecode" ||
          key == "payitemtypedesc" ||
          key == "payitemcatcode" ||
          key == "payitemcatdesc" ||
          key == "aprvamt" ||
          key == "aprvqty" ||
          key == "reqamt" ||
          key == "reqqty" ||
          key == "srcapprvamt" ||
          key == "srccrcyid" ||
          key == "pistartdate" ||
          key == "pienddate" ||
          key == "justification" ||
          key == "compnycode" ||
          key == "compnydesc" ||
          key == "brchcode" ||
          key == "brchdesc" ||
          key == "deptcode" ||
          key == "deptdesc" ||
          key == "divcode" ||
          key == "divdesc" ||
          key == "sectiocode" ||
          key == "sectiodesc" ||
          key == "unitcode" ||
          key == "unitdesc" ||
          key == "gradecode" ||
          key == "gradedesc" ||
          key == "emptypecode" ||
          key == "emptypedesc" ||
          key == "jobcode" ||
          key == "jobdesc" ||
          key == "positioncode" ||
          key == "positiondesc" ||
          key == "grosspay" ||
          key == "netpay"
        ) {
          // eslint-disable-next-line no-unused-vars
          var getter;
          let obj = {};

          const keyR = "getter";
          const keylabel = "label";

          obj[keyR] = getter = (item) => item[key];
          obj[keylabel] = key;

          this.fields.availableFields.push(obj);
        }
      }

      getsalhd.forEach((element) => {
        let getobj = {};
        for (const key in element) {
          if (
            key == "staffid" ||
            key == "staffcode" ||
            key == "psnname" ||
            key == "payitemcode" ||
            key == "payitemdesc" ||
            key == "payitemtypecode" ||
            key == "payitemtypedesc" ||
            key == "payitemcatcode" ||
            key == "payitemcatdesc" ||
            key == "aprvamt" ||
            key == "aprvqty" ||
            key == "reqamt" ||
            key == "reqqty" ||
            key == "srcapprvamt" ||
            key == "srccrcyid" ||
            key == "pistartdate" ||
            key == "pienddate" ||
            key == "justification" ||
            key == "compnycode" ||
            key == "compnydesc" ||
            key == "brchcode" ||
            key == "brchdesc" ||
            key == "deptcode" ||
            key == "deptdesc" ||
            key == "divcode" ||
            key == "divdesc" ||
            key == "sectiocode" ||
            key == "sectiodesc" ||
            key == "unitcode" ||
            key == "unitdesc" ||
            key == "gradecode" ||
            key == "gradedesc" ||
            key == "emptypecode" ||
            key == "emptypedesc" ||
            key == "poid" ||
            key == "jobcode" ||
            key == "jobdesc" ||
            key == "positioncode" ||
            key == "positiondesc" ||
            key == "grosspay" ||
            key == "netpay"
          ) {
            getobj[key] = element[key];
          }
        }
        this.data.push(getobj);
      });
      this.loadPayoutCtrlListHandler();
    },

    loadPayoutCtrlListHandler() {
      const vm = this;

      if (
        this.getdate.monthFrom != null ||
        this.getdate.yearFrom != null ||
        this.getdate.monthTo != null ||
        this.getdate.yearTo != null
      ) {
        this.$store.dispatch('post', {url: "/payoutctrl/select", payload: vm.getdate})
          .then((res) => {
            vm.payoutctrlList = res.data.rec;
            this.processPayoutCtrlData(vm.payoutctrlList);
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
          });
      } else {
        this.$store.dispatch('get', {url: "/payoutctrl/all"})
          .then((res) => {
            vm.payoutctrlList = res.data.rec;
            this.processPayoutCtrlData(vm.payoutctrlList);
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
          });
      }
    },

    processPayoutCtrlData(payoutctrllist) {
      const vm = this;
      let getpyctrl = [];

      for (let i = 0; i < payoutctrllist.length; i++) {
        if (payoutctrllist[i].startdate) {
          payoutctrllist[i].startdate = moment(
            payoutctrllist[i].startdate
          ).format("YYYY-MM-DD");
        }

        if (payoutctrllist[i].enddate) {
          payoutctrllist[i].enddate = moment(payoutctrllist[i].enddate).format(
            "YYYY-MM-DD"
          );
        }
        getpyctrl.push(payoutctrllist[i]);
      }

      //   console.log("getpyctrl", getpyctrl);

      for (const key in getpyctrl[0]) {
        if (
          key == "payoutcode" ||
          key == "payoutdesc" ||
          key == "pyyear" ||
          key == "source" ||
          key == "pymonth" ||
          key == "crcycode" ||
          key == "groupcode"
        ) {
          // eslint-disable-next-line no-unused-vars
          var getter;
          let obj = {};

          const keyR = "getter";
          const keylabel = "label";

          obj[keyR] = getter = (item) => item[key];
          obj[keylabel] = key;

          this.fields.availableFields.push(obj);
        }
      }

      this.fields.availableFields = this.fields.availableFields.filter(
        (ar) => !this.formData.rowFields.find((rm) => rm.label === ar.label)
      );

      this.fields.availableFields = this.fields.availableFields.filter(
        (ar) => !this.formData.colFields.find((rm) => rm.label === ar.label)
      );

      getpyctrl.forEach((element1) => {
        //console.log('element1', element1)
        this.data.forEach((element2) => {
          if (element1.id == element2.poid) {
            for (const key in element1) {
              if (
                key == "payoutcode" ||
                key == "payoutdesc" ||
                key == "pyyear" ||
                key == "source" ||
                key == "pymonth" ||
                key == "crcycode" ||
                key == "groupcode"
              ) {
                element2[key] = element1[key];
              }
            }
          }
        });
      });

      this.data = this.data.filter((x) => {
        for (const key in x) {
          if (key == "pyyear") return true;
        }
      });

      Object.freeze(this.data);
      vm.loading = false;
    },

    backButton() {
      this.$router.push({ name: "payrollpivotfilter" });
    },
    checkRowArrayIsSame() {
      return _.isEqual(this.formData.rowFields, this.fields.rowFields);
    },
    checkColArrayIsSame() {
      return _.isEqual(this.formData.colFields, this.fields.colFields);
    },
    // function to load pivotsettings
    loadUserPivotSettings() {
      console.log("load user pivot settings");
      const vm = this;
      this.$store.dispatch('post', {url: "/pivotsettings/get", payload: vm.formData})
        .then((res) => {
          let rows = res.data.rec[0].rows;
          let cols = res.data.rec[0].cols;
          if (rows != null && rows != "") {
            rows = JSON.parse(rows);
            for (var i = 0; i < rows.length; i++) {
              let key = rows[i].label;

              // eslint-disable-next-line no-unused-vars
              let getter;
              let obj = {};

              const keyR = "getter";
              const keylabel = "label";
              obj[keyR] = getter = (item) => item[key];
              obj[keylabel] = key;
              this.formData.rowFields.push(obj);
            }
            this.fields.rowFields = this.formData.rowFields;
          }
          if (cols != null && cols != "") {
            cols = JSON.parse(cols);
            for (var j = 0; j < cols.length; j++) {
              let key = cols[j].label;

              // eslint-disable-next-line no-unused-vars
              let getter;
              let obj = {};

              const keyC = "getter";
              const keylabel = "label";
              obj[keyC] = getter = (item) => item[key];
              obj[keylabel] = key;

              this.formData.colFields.push(obj);
            }
            this.fields.colFields = this.formData.colFields;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },
  //function for pivot settings
  beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line no-unused-vars
    let ato = to;
    if (from.name == "payrollpivotdetail") {
      if (this.checkColArrayIsSame() && this.checkRowArrayIsSame()) {
        next();
      } else {
        this.formData.rowFields = this.fields.rowFields;
        this.formData.colFields = this.fields.colFields;
        this.$dialog
          .confirm(this.$t('doyouwanttosaveit'), {
            okText: "Save",
            cancelText: "Close",
            backdropClose: false,
          })
          .then(() => {
            console.log("Clicked on proceed");
            const vm = this;
            this.$store.dispatch('post', {url: "/pivotsettings/insert", payload: this.formData})
              .then((res) => {
                console.log(res);
                next();
              })
              .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
              });
          })
          .catch(function () {
            console.log("Clicked on cancel");
            next();
          });
      }
    } else {
      next();
    }
  },
  mounted() {
    // this.loadPayoutCtrlListHandler();
    this.loadUserPivotSettings();
    this.loadDetailListHandler();
  },
};
</script>