<template>
<div>
  <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="180"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
  <div v-if="!loading2">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
       v-on:cancel="cancelHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</div>
</template>

<script>
import moment from "moment";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ClaimLimitSetup from "../../../components/claim/claimlimitsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": ClaimLimitSetup,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
    Loading,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupclaimlimit.editclaimlimitsetup'),
      pageTitleValue: "",
      formData: [],
      claimlimitdetail: [],
      travellinglimitdetail: [],
      loading : false,
      headLoading: true,
      loading2: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadClaimLimitHandler() {
      const vm = this;
      vm.loading = true;

      this.$store.dispatch('get', {url: "/claimlimit/" + this.id})
        .then((res) => {

          vm.loading = false;
          vm.formData = res.data.rec[0];


          if (res.data.rec[0].startdate) {
              vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
              vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].prorate == "N") {
            vm.formData.prorate = false;
          } else {
            vm.formData.prorate = true;
          }

         // vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.formData.userid = this.$store.getters.userId;
         // vm.pageTitleValue = res.data.rec[0].payitemdesc;

         vm.headLoading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    // loadClaimLimitDetailHandler() {
    //   const vm = this;

    //   axios
    //     .get', {url: "/claimlimitdetail/" + this.id})
    //     .then((res) => {
    //       vm.formData.claimlimitdetail = res.data.rec;
    //         console.log('claimlimit', vm.formData.claimlimitdetail)

    //       for (let i = 0; i < vm.formData.claimlimitdetail.length; i++) {
    //         Object.assign(vm.formData.claimlimitdetail[i], {
    //           action: 2,
    //         });
    //       }

    //       vm.loading = false;
    //     })
    //     .catch((err) => {
    //       vm.isErr = !vm.isErr;
    //       vm.errMsg = err;
    //     });
    // },

    
    loadClaimLimitDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/claimlimitdetail/" + this.id})
        .then((res) => {
          vm.formData.claimlimitdetail = res.data.rec;

          
          for (let i = 0; i < vm.formData.claimlimitdetail.length; i++) {
            
            Object.assign(vm.formData.claimlimitdetail[i], { action: 2 });
          }


          // vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

      loadClaimLimitTravelDetailHandler() {

         const vm = this;

      this.$store.dispatch('get', {url: "/travelclaimlimitdetail/" + this.id})
        .then((res) => {
          vm.formData.travellinglimitdetail = res.data.rec;

          for (let i = 0; i < vm.formData.travellinglimitdetail.length; i++) {
            
            
            Object.assign(vm.formData.travellinglimitdetail[i], { action: 2 });
          }

          vm.loading2 = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
      // const vm = this;

      // axios
      //   .get', {url: "/travelclaimlimitdetail/" + this.id})
      //   .then((res) => {
      //     vm.formData.travellinglimitdetail = res.data.rec;

      //     console.log('travelling', vm.formData.travellinglimitdetail)

      //     for (let i = 0; i < vm.formData.travellinglimitdetail.length; i++) {
      //       Object.assign(vm.formData.travellinglimitdetail[i], {
      //         action: 2,
      //       });
      //     }

      //     vm.loading = false;
      //   })
      //   .catch((err) => {
      //     vm.isErr = !vm.isErr;
      //     vm.errMsg = err;
      //   });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      //console.log('formdata', payload: this.formData})
      this.$store.dispatch('put', {url: "/claimlimit/" + this.id, 
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "claimlimitsetuplist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "claimlimitsetuplist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/claimlimit/" +
            vm.id +
            "/" +
            this.formData.userid
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "claimlimitsetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "claimlimitsetupnew" });
    },
  },

  mounted() {
    this.loadClaimLimitHandler(); //header
    this.loadClaimLimitDetailHandler(); //data table item
    this.loadClaimLimitTravelDetailHandler(); //crudtable items
  },
};
</script>