<template>
    <div>
        <v-toolbar
            color="#ECEFF1"
        >
            <h2 style="color: #3f6892; padding-left: 10px">{{ this.pageTitle }}</h2>
            <v-spacer></v-spacer>

            <v-toolbar-items>
                - <v-btn text
                    @click="getCSV"

                    class="mr-5"
                >
                    <v-icon>mdi-export</v-icon>
                </v-btn> 
                 <!-- <v-btn text
                    @click="onNewPressed"
                    class="mr-5"
                >
                    <v-icon>add</v-icon>
                </v-btn>  -->
                 <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 250px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
            </v-toolbar-items>
        </v-toolbar>

        <v-data-table 
            dense loading
            :headers="this.dataHeader"
            :items="this.wizardItems"
            :search="search"
            :items-per-page="10"
            class="elevation-2 ; font-weight-light;text--secondary;"
            style="font-size:small"
            multi-sort
            fixed-header
            item-key = "wizardcode"
        >
            <template v-slot:body>
                    <tbody>
                        <tr v-for="item in wizardItems" :key="item">
                            <td @click="loadPageHandler(item.id) "> {{item.wizardcode}} </td>
                            <td >{{ item.wizarddesc }} </td>
                        </tr>  
                    </tbody>
              </template>

        </v-data-table>
         <div v-if="isErr">
                <app-error
                    v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                    }"
                    v-on:closeError="isErr = $event"
                />
      </div> 
    </div> 
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        //parentData: Object,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            pageTitle: this.$t('columns.wizardlist'),
            dataHeader: [
                { text: this.$t('columns.wizardname'), value: 'wizardcode', width: '100px' },
                { text: this.$t('columns.wizarddescription'), value: 'wizarddesc', width: '100px' },
            ],

            getid: this.$route.params.id,
            formData: {
                wizardcode: null,
                wizarddesc: null,
                userid: this.$store.getters.userId
            },

            
            wizardItems: [],
            dataItems: [],
            getId: [],
            loading: false,
            isErr: false,
            errMsg: '',
            search: '',

        };
    },

    methods: {

        onNewPressed() {
            this.$router.push({name: 'wizardnew'});
        },

        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadWizardList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/wizardsetup/all'})
            .then (res => {
              
              vm.wizardItems = res.data.rec;
              vm.loading = false;
              console.log('wizard', vm.wizardItems)
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        loadPageHandler(id) {
            let wizard = this.getid           
            this.$router.push({name: 'wizardstep', params: {id, wizard}});
        },

         objectToCSV(data) {
             const csvRows = []
            
             //get header
             const headers = Object.keys(data[0])
             //const headers = this.titles
             csvRows.push(headers.join(','))
            
             //loop over the rows
             for (const row of data) {
                 const values = headers.map(header => {
                     const escaped = ('' + row[header]).replace(/"/g, '\\"')
                     return `"${escaped}"`
                 })
                 csvRows.push(values.join(','))
             }
             return csvRows.join('\n')
         },
             download(data) {
             const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv;charset=utf-8;'})
             const url = window.URL.createObjectURL(blob)
             const a = document.createElement('a')
             a.setAttribute('hidden', '')
             a.setAttribute('href', url)
             a.setAttribute('download', this.pageTitle + '.csv')
             document.body.appendChild(a)
             a.click()
             document.body.removeChild(a)
         },
         async getCSV() {
             let titles = this.dataHeader
             let item = this.wizardItems
             let data = []
             let obj2 = {}
            
                 titles.forEach(element1 => {
                 let x = element1.text
                 obj2[x]= element1.value
                 });
                    
                 item.forEach(element1 => {
                     let obj = {}
                     titles.forEach(element2 => {
                         if(element2.value != 'action'){
                         let key = element2.text
                         obj[key] = element1[element2.value]
                         }
                     });
                         data.push(obj)
                 });
            
                 const csvData = this.objectToCSV(data)
                 this.download(csvData)             
        }       
    },

     mounted() {
     this.loadWizardList();
   },
};
</script>
