<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>
                
                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <div class="ma-4">
                        <v-row>
                            <v-col cols="6" sm="6" class="blue--text ml-2">
                                {{$t('recbatchusercreationlog.batchusercreationprocesslog')}}
                            </v-col>
                        </v-row>

                        <v-container>
                            <v-row>
                                <v-col cols="6" sm="6" class="orange lighten-5">
                                    <v-row>
                                        <v-col cols="4" sm="4">
                                            <v-text-field
                                                :label="this.$t('recbatchusercreationlog.recordcreate')"
                                                type="text"
                                                v-model="listErr.success"
                                                class="custom"
                                                readonly
                                                dense
                                                background-color="#FFF3E0"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4">
                                            <v-text-field
                                                :label="this.$t('recbatchusercreationlog.warning_usercreation')"
                                                type="text"
                                                v-model="listErr.warning"
                                                class="custom"
                                                readonly
                                                dense
                                                background-color="#FFF3E0"
                                            ></v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                    
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-row>
                            <v-col cols="12" sm="6" class="blue--text ml-2">
                                {{$t('recbatchusercreationlog.recordcreated')}}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="1" sm="1" class="ml-n4">
                                <div style="width: 50%; max-height: 50%" >
                                    <v-img @click="printPDFSuccess" :src="`${publicPath}images/export_pdf.png`"/>
                                </div>
                            </v-col>
                            <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                                <div style="width: 50%">
                                    <v-img @click="getCSVSuccess" :src="`${publicPath}images/export_csv.png`"/>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    :headers="dataHeaderRecords"
                                    :items="this.dataItemRecords"
                                    :options="tableOpt"
                                    hide-default-footer
                                    item-key="errorlog"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" class="blue--text ml-2">
                                {{$t('statutoryexportcommonused.errorlog')}}
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="1" sm="1" class="ml-n4">
                                <div style="width: 50%; max-height: 50%" >
                                    <v-img @click="printPDFError" :src="`${publicPath}images/export_pdf.png`"/>
                                </div>
                            </v-col>
                            <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                                <div style="width: 50%">
                                    <v-img @click="getCSVError" :src="`${publicPath}images/export_csv.png`"/>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    :headers="dataHeaderErrors"
                                    :items="this.dataItemErrors"
                                    :options="tableOpt"
                                    hide-default-footer
                                    item-key="errorlog"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </div>
                </v-container>

                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="returnHandler"> 
                            {{$t('button.return')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
        <!-- <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div> -->
    </div>
</template>

<script>
import jsPDF from 'jspdf';
//import moment from "moment";
// import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    // components: {
    //     'app-error': ErrorDialog,
    // },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            listErr: {
                warning: this.$route.params.error.length,
                success: this.$route.params.created.length,
            },

            dataHeaderRecords: [
                { text: this.$t('statutoryexportcommonused.no'), value: 'index', width: '80px', sortable: true},
                { text: this.$t('columns.staffname'), value: 'psnname', width: '300px', sortable: true},
                { text: this.$t('columns.staffcode'), value: 'staffcode', width: '150px', sortable: true},
                { text: this.$t('recfamdetails.icno'), value: 'idno', width: '300px', sortable: true},
                { text: this.$t('login.password'), value: 'passwd', width: '300px', sortable: true},
            ],
            recordsHolder: this.$route.params.created,
            dataItemRecords: [],

            dataHeaderErrors: [
                { text: this.$t('statutoryexportcommonused.no'), value: 'index', width: '80px', sortable: true},
                { text: this.$t('columns.staffname'), value: 'psnname', width: '300px', sortable: true},
                { text: this.$t('columns.staffcode'), value: 'staffcode', width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.errormessage'), value: 'errormsg', width: '300px', sortable: true},
            ],
            errorsHolder: this.$route.params.error,
            dataItemErrors: [],

            tableOpt: {
                itemsPerPage: -1
            },

            userTypeList: [],
            pageTitle:  this.$t('batchusercreation'),
            isErr: false,
        }
    },

    methods: {

        getResponse() {
            if (this.recordsHolder.length > 0) {
                for (let i = 0; i < this.recordsHolder.length; i++) {
                    this.dataItemRecords.push({
                        index: i+1,
                        psnname: this.recordsHolder[i].psnname,
                        staffcode: this.recordsHolder[i].staffcode,
                        idno: this.recordsHolder[i].idno,
                        passwd: this.recordsHolder[i].passwd
                    })
                }
            }

            
            if (this.errorsHolder.length > 0) {
                for (let i = 0; i < this.errorsHolder.length; i++) {
                    this.dataItemErrors.push({
                        index: i+1,
                        psnname: this.errorsHolder[i].psnname,
                        staffcode: this.errorsHolder[i].staffcode,
                        errormsg: this.errorsHolder[i].errormsg,
                    })
                }
            }
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data, title) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSVSuccess() {
            if (this.recordsHolder.length > 0) {
                let itemSummary = this.recordsHolder

                let dataSummary = []
                
                for (var i = 0; i < itemSummary.length; i++) {
                    itemSummary[i].index = i
                    if(itemSummary[i].psnname === null || itemSummary[i].psnname === undefined) {
                        itemSummary[i].psnname = ''
                    }
                    if(itemSummary[i].staffcode === null || itemSummary[i].staffcode === undefined) {
                        itemSummary[i].staffcode = ''
                    }
                    dataSummary.push({
                        'No': itemSummary[i].index+1,
                        'Name': itemSummary[i].psnname,
                        'Staff ID': itemSummary[i].staffcode,
                        'User ID': itemSummary[i].idno,
                        'Password': itemSummary[i].passwd,
                    })
                }
                
                const csvData = this.objectToCSV(dataSummary)
                this.download(csvData, this.titleSummary)
            }
            
        },

        async getCSVError() {
            if (this.errorsHolder.length > 0) {
                let itemSummary = this.errorsHolder

                let dataSummary = []
                
                for (var i = 0; i < itemSummary.length; i++) {
                    itemSummary[i].index = i
                    if(itemSummary[i].psnname === null || itemSummary[i].psnname === undefined) {
                        itemSummary[i].psnname = ''
                    }
                    if(itemSummary[i].staffcode === null || itemSummary[i].staffcode === undefined) {
                        itemSummary[i].staffcode = ''
                    }
                    dataSummary.push({
                        'No': itemSummary[i].index+1,
                        'Name': itemSummary[i].psnname,
                        'Staff ID': itemSummary[i].staffcode,
                        'Error Message': itemSummary[i].errormsg
                    })
                }
                const csvData = this.objectToCSV(dataSummary)
                this.download(csvData, this.titleSummary)
            }
            
        },

        printPDFSuccess() {
            if (this.recordsHolder.length > 0) {
                const doc = new jsPDF({
                    orientation: 'landscape'
                })
                let item = this.recordsHolder

                var head = [                
                    { title: 'No', dataKey: 'index' },
                    { title: 'Name', dataKey: 'psnname' },
                    { title: 'Staff ID', dataKey: 'staffcode' },
                    { title: 'User ID', dataKey: 'idno' },
                    { title: 'Password', dataKey: 'passwd' },
                ]

                let body = []

                for (var i = 0; i < item.length; i++) {
                    body.push({
                        index: item[i].index,
                        psnname: item[i].psnname,
                        staffcode: item[i].staffcode,
                        idno: item[i].idno,
                        passwd: item[i].passwd,
                    })
                }
                doc.text('test', 10, 10)
                doc.autoTable(head, body)
                doc.save('test' + '.pdf')
            }
        },

        printPDFError() {
            if (this.errorsHolder.length > 0) {
                const doc = new jsPDF({
                    orientation: 'landscape'
                })
                let item = this.errorsHolder

                var head = [                
                    { title: 'No', dataKey: 'index' },
                    { title: 'Name', dataKey: 'psnname' },
                    { title: 'Staff ID', dataKey: 'staffcode' },
                    { title: 'Error Message', dataKey: 'errormsg' },
                ]

                let body = []

                for (var i = 0; i < item.length; i++) {
                    body.push({
                        index: item[i].index,
                        psnname: item[i].psnname,
                        staffcode: item[i].staffcode,
                        errormsg: item[i].errormsg,
                    })
                }
                doc.text('test', 10, 10)
                doc.autoTable(head, body)
                doc.save('test' + '.pdf')
            }
        },

        getUserTypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/usertype/all'})
                .then(function (res) {
                    vm.exportformatItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.userTypeList.push(
                            {
                                text: res.data.rec[i].type,
                                value: res.data.rec[i].id
                            }
                        )
                    } 
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });
        },

        returnHandler() {
            this.$router.push({name: 'batchusercreationparameter'});
        }

    },

    mounted() {
        this.getUserTypeListHandler()
        this.getResponse()
    }
}
</script>
