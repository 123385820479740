<template>
  <div>
      <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <!-- <v-row>
            <v-col cols="6" sm="6" class="pb-n5">
              <v-text-field
                outlined
                :label="File Processing Date"
                type="date"
                v-model="formData.fpdate"
                dense
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactpersonname')"
                type="text"
                v-model="formData.conpsnname"
                maxlength='40'
                :rules="[rules.required, rules.counter1]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.contactphonenumber')"
                type="number"
                v-model="formData.conpsnno"
                maxlength='20'
                :rules="[rules.required, rules.counter3]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-autocomplete
                  outlined
                  :label="this.$t('statutoryexportcommonused.statecode')"
                  type="text"
                  ref="staff"
                  v-model="formData.statecode"
                  :items="statecodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
           </v-row>
           <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('allepf.paymentindocator')"
                type="text"
                v-model="formData.pindicator"
                :items="pindicatorList"
                :rules="[rules.required]"
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allepf.sequenceno')"
                type="number"
                v-model="formData.seqno"
                maxlength='2'
                :rules="[rules.required, rules.counter2]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

           <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-autocomplete
                  outlined
                  :label="this.$t('allepf.testingmode')"
                  type="text"
                  v-model="formData.testmode"
                  :items="testmodeList"
                  :rules="[rules.required]"
                  dense
                  clearable
                ></v-autocomplete>
            </v-col>
           </v-row>


        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getCIMBExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
     Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter2: (value) => value.length < 2 || this.$t('reportsparam.counter2'),
        counter1: (value) => value.length < 40 || this.$t('reportsparam.counter40'),
        counter3: (value) => value.length < 20 || this.$t('reportsparam.counter20'),
      },

      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        conpsnname: '',
        conpsnno: '',
        statecode: '',
        pindicator: '',
        seqno: '',
        testmode: '',
      },
    
      titleData: null,

      filler: "",
      totalAmtEmployer: null,
      totalAmtMember: null,
      totalRefno: null,

       statecodeList: [
        {
            text: 'Johor',
            value: '01',
        },
        {
            text: 'Kedah',
            value: '02',
        },
        {
            text: 'Kelantan',
            value: '03',
        },
        {
            text: 'Melaka',
            value: '04',
        },
        {
            text: 'Negeri Sembilan',
            value: '05',
        },
        {
            text: 'Pahang',
            value: '06',
        },
        {
            text: 'Pulau Pinang',
            value: '07',
        },
        {
            text: 'Perak',
            value: '08',
        },
        {
            text: 'Perlis',
            value: '09',
        },
        {
            text: 'Selangor',
            value: '10',
        },
        {
            text: 'Terengganu',
            value: '11',
        },
        {
            text: 'Sabah',
            value: '12',
        },
        {
            text: 'Sarawak',
            value: '13',
        },
        {
            text: 'Wilayah Persekutuan (Kuala Lumpur)',
            value: '14',
        },
        {
            text: 'Wilayah Persekutuan (Labuan)',
            value: '15',
        },
        {
            text: 'Wilayah Persekutuan (Putrajaya)',
            value: '16',
        }
      ],
      pindicatorList: [
          {
              text: 'N',
              value: 'N',
          },
          {
              text: 'S',
              value: 'S',
          }
      ],

       testmodeList: [
          {
              text: 'N',
              value: 'N',
          },
          {
              text: 'Y',
              value: 'Y',
          }
      ],

      loading: false,

      accErr: [],
      
      epfdata: [],

      processedCount: null,
      successCount: null,
      errCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    getCIMBExportListHandler() {
      this.loading = true;
      const vm = this

      let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/ambankepfexport/all/${this.$store.getters.userId}`
            + "/" +  this.data.epfexprecid
            + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.epfdata = res.data.rec;

         vm.exportHandler(); 

      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },
   
   objectToTxt(header, data, footer) {
      const csvRows = [];

      const headers = Object.keys(data[0]);
      const getHeader = header;
      csvRows.push(getHeader);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(footer);
      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

     async getData() {
      const vm = this
      let data = []
      let icnoholder = ''
      let holdAmtEmployer = ''
      let holdAmtMember = ''
      let getAmtRefno = ''
      let rec = 0
      let Hrecordid = '01'
      let subsource = 'ITB'
      let filler = ''
      
      let newepfdetail = []
      let holdpayEmployer = []
      let holdpayMember = []
      let holdpayRefno = []
      let dataholder = vm.epfdata
      let dataholderval = dataholder;//.filter(el => el.stfamount)
      let epfdetail = this.filter(dataholderval);

      this.processedCount = dataholderval.length;
      this.successCount = epfdetail.length;
      this.errCount = dataholderval.length - epfdetail.length;
      let yearholder = this.data.year
      let monthholder = this.data.month + 1
      if (monthholder > 12) {
        monthholder = 1
        yearholder++
      }

      let getyear = yearholder.toString()
      let getdate = this.padRJ(monthholder, 2) + getyear
      let transactiondate = moment().format('YYYYMMDD')
      let transactiontime = moment().format('hhmmss')
      let filerefnum = (transactiondate) + (transactiontime)

       let header = 
        this.padSpace(Hrecordid, 2)+ 
        this.padSpace("EPF MONTHLY FORM A", 20)+
        this.padRJ(this.data.refnum, 19)+
        this.padSpace(getdate, 6)+
        this.padSpace(subsource, 3)+
        this.padRJ("03", 9)+
        this.padRJ(this.formData.statecode, 3)+
        this.padSpaceLJ(this.formData.conpsnname, 40)+
        this.padSpaceLJ(this.formData.conpsnno, 20)+
        this.padSpace(this.formData.pindicator, 1)+
        this.padRJ(this.formData.seqno, 2)+
        this.padSpace(transactiondate, 8)+
        this.padLJ(transactiontime, 8)+
        this.padLJ(filerefnum, 16)+
        this.padSpace(this.formData.testmode, 1)
       
      epfdetail.forEach(function (o) {
        var existing = newepfdetail.filter(function (i) {
          return i.staffid === o.staffid;
        })[0];
        if (!existing || existing == undefined) {
          newepfdetail.push(o);
        } else {
          existing.coamount += o.coamount;
          existing.stfamount += o.stfamount;
          existing.stfepfwage += o.stfepfwage;
        }
      });

      newepfdetail.forEach(element => {
        if (element.icno != null) icnoholder = element.icno.split("-").join("")
        
        let coamountholder = element.coamount.toFixed(2);
        let stfamountholder = element.stfamount.toFixed(2);
        let stfepfwageholder = element.stfepfwage.toFixed(2);
        let psnnameHolder = element.psnname.substring(0,40)

        coamountholder = coamountholder.split(".").join("");
        stfamountholder = stfamountholder.split(".").join("");
        stfepfwageholder = stfepfwageholder.split(".").join("");

        let refnoh = ''
        if (element.refno) refnoh = element.refno

        data.push({
          "Record ID": '02',
          "Employee EPF Number": this.pad(refnoh, 19),
          "Employee IC Number": this.padSpaceLJ(icnoholder, 15),
          "Employee Name": this.padSpaceLJ(psnnameHolder, 40),
          "Employee Name Line 2": this.padSpaceLJ(filler , 40),
          "Employee Identifying Initials": this.padSpaceLJ(element.staffcode, 20),
          "Employer Contribution": this.padRJ(coamountholder, 15),
          "Employee Contribution": this.padRJ(stfamountholder, 15),
          "Employee Wage": this.padRJ(stfepfwageholder, 15),
        });

        holdpayEmployer.push(element.coamount);
        holdpayMember.push(element.stfamount);
        if (element.refno) holdpayRefno.push(parseInt(element.refno));
        rec++;
      });

      if (holdpayEmployer.length > 0) {
        let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
        holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
      }

      if (holdpayMember.length > 0) {
        let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtMember = totalAmtMemHolder.toFixed(2);
        holdAmtMember = this.totalAmtMember.split(".").join("");
      }

      if (holdpayRefno.length > 0) {
        getAmtRefno = holdpayRefno.reduce(function (a, b) {
            return a + b;
        }, 0);

        console.log('holdAmtEmployer', holdAmtEmployer)
        console.log('holdAmtMember', holdAmtMember)
        console.log('sumofrefno', getAmtRefno)
      }

       let footer =
        "99" +
        this.padRJ(rec, 7) +
        this.padRJ(holdAmtEmployer, 15) +
        this.padRJ(holdAmtMember, 15) +
        this.padRJ(getAmtRefno, 21) ;
        
      this.titleData = this.pad(vm.data.month, 2) + "_" + vm.data.year + "_EPF";

    if (data.length > 0) {
        const csvData = this.objectToTxt(header, data, footer);
        this.download(csvData, this.titleData);
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.coamount != 0 || e.stfamount != 0 && e.stfepfwage != 0 
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },


    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padSpace(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      this.getData();
      let item = [];
      item.totalAmtEmployer = this.totalAmtEmployer;
      item.totalAmtMember = this.totalAmtMember;
      item.accounterr = this.accErr;
      item.successCount = this.successCount;
      item.processedCount = this.processedCount;
      item.errCount = this.errCount;

      if (!this.isErr) {
        this.$router.push({ name: "monthlyepfexportlog", params: { item } });
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlyepfexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    // this.getPersonStatutaryInfoListHandler();
    // this.getSalaryStatutaryOtherListHandler();
    // this.getPsnPayrollListHandler();
  },
};
</script>