<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ExemptionForm from "../../../components/payroll/exemption/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": ExemptionForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setuptaxableexemption.createnewtaxableexemption'),
      pageTitleValue: "",
      formData: {
        taxexemptionid: null,
        exemptioncode: null,
        exemptiondesc: null,
        startdate: null,
        enddate: null,
        exemptionamount: null,
        crcyid: null,
        remark: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/taxexemptiondt/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "exemptionlist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "exemptionlist" });
    },
  },
};
</script>