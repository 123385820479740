<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle }}
            <span class="page_title_value">{{
              this.parentObject.pageTtileValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
         <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                :items="staffDetailsList"
                                @input="updateValue"
                                @change="
                                    changeStaffIDandCompanyHandler(
                                        formData.staffid
                                    )
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                         <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.staffid"
                                readonly
                                dense
                                :items="staffDetailsList"
                                background-color="#ffeab2"
                               
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.staffcode')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffcode"
                dense
                @input="updateValue"
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                v-model="formData.compnyid"
                dense
                @input="updateValue"
                :items="companiesitemsList"
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('reporalertlisting.doctype')"
                type="text"
                :rules="[rules.required]"
                :items="documentTypesList"
                dense
                @input="updateValue"
                v-model="formData.doctypeid"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reporalertlisting.docno')"
                type="text"
                v-model="formData.docno"
                @input="updateValue"
                dense
                class="numberfield"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                ref="startdate"
                :rules="[rules.required]"
                v-model="formData.startdate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                ref="enddate"
                :rules="[rules.required]"
                v-model="formData.enddate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n5 mt-n8">
              <v-file-input
                ref="attach"
                :label="this.$t('recstaffdetails.attachment')"
                outlined
                v-model="fileHolder"
                @change="fileToBase64"
                dense
              ></v-file-input>
            </v-col>
          </v-row>

          <div v-if="this.formData.attach">
            <v-row>
              <v-col cols="4" sm="6" class="pb-8 mt-n5">
                <p>
                  <a
                    v-bind:href="this.formData.attach"
                    v-bind:download="this.formData.attachname"
                    >{{ formData.attachname }}</a
                  >
                  <v-icon small class="ml-3 mr-2" @click="deleteItemHandler">
                    mdi-delete
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col cols="10" sm="10" class="pb-n8 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                ref="remark"
                v-model="formData.remark"
                @input="updateValue"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
//import moment from 'moment';

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
        number: (value) =>
          value > -0.01 || this.$t('reportsparam.invalidnumber'),
      },

      formData: {
        psnid: this.parentObject.data.psnid,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        staffdocid : this.parentObject.data.staffdocid,
        doctypeid: this.parentObject.data.doctypeid,
        docno: this.parentObject.data.docno,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        attach: this.parentObject.data.attach,
        attachname: this.parentObject.data.attachname,
        remark: this.parentObject.data.remark,
        stamp: this.parentObject.data.stamp,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        //additional line
        userid: this.parentObject.data.userid,
      },
      fileHolder: null,

      isNewDocument: false,

      staffdetails: [],
      staffDetailsList: [],
      companiesitemsList: [],
      documenttypes: [],
      documentTypesList: [],

      pageTitleValue: this.parentObject.pageTitleValue,
      isFiltered: false,
      filteredMovements: null,
      filteredMovementList: [],
      reasonDialog: false,
      currencyDialog: false,
      isEdit: this.parentObject.isEdit,
      errMsg: "",
      isErr: false,
       search: this.parentObject.searchnew
    };
  },

  methods: {
    fileToBase64() {
      if (this.fileHolder) {
        var file = this.$refs.attach.internalValue;
        this.formData.attachname = this.fileHolder.name;

        var reader = new FileReader();

        reader.onloadend = () => {
          //var b64 = reader.result.replace(/^data:.+base64,/, '');
          var b64 = reader.result;
          this.formData.attach = b64;
        };

        reader.readAsDataURL(file);
      }
    },

    loadDocumentTypesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/documenttype/all"})
        .then((res) => {
          vm.documenttypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.documentTypesList.push({
              text: res.data.rec[i].doctypecode,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    // loadStaffDetailsListHandler() {
    //   const vm = this;

    //   axios
    //     .get(
    //       `${this.$store.getters.apiURL}/staff/all/${this.$store.getters.userId}`
    //     )
    //     .then((res) => {
    //       vm.staffdetails = res.data.rec;
    //       this.onChange();

    //       for (let i = 0; i < res.data.rec.length; i++) {
    //         vm.staffDetailsList.push({
    //           text: res.data.rec[i].psndetails,
    //           value: res.data.rec[i].psnid,
    //         });
    //       }
    //       console.log("staffDetailsList", vm.staffDetailsList);
    //     })
    //     .catch((err) => {
    //       vm.isErr = true;
    //       vm.errMsg = err;
    //       vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //     });
    // },

     loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companiesitemsList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeStaffIDandCompanyHandler(id) {
      console.log("id", id);
      console.log("staffdetails", this.staffdetails);
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffcode = item.staffcode;
      this.formData.staffid = item.id;
      this.formData.psnname = item.psnname;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      //set compnyid to compnyid of item
      for (let i = 0; i < this.companiesitemsList.length; i++) {
        if (item.compnyid == this.companiesitemsList[i].value) {
          this.formData.compnyid = this.companiesitemsList[i].value;
        } else {
          //do nothing
        }
      }
    },

    updateValue() {
      this.$emit("input", {
        psnid: this.formData.psnid,
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        staffdocid: this.formData.staffdocid,
        doctypeid: this.formData.doctypeid,
        docno: this.formData.docno,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        attach: this.formData.attach,
        attachname: this.formData.attachname,
        lockstaff: this.formData.lockstaff,
        remark: this.formData.remark,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
      //console.log('staffdocid', this.formData.staffdocid)
    },

    newHandler() {
      this.$router.push({ name: "documentrecordnew" });
    },

    saveHandler() {
      this.updateValue();
      this.$emit("save");
    },

    deleteHandler() {
      this.updateValue();
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "documentrecordlist", params: { search: this.search} });
    },

    deleteItemHandler() {
      this.formData.attach = "";
      this.formData.attachname = "";
    },

    onChange() {
      if (this.isEdit) {
        let item = this.staffdetails.find(
          (item) => item.staffcode === this.formData.staffcode
        );

        this.formData.psnid = item.psnid;
      }
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadCompaniesListHandler();
    this.loadDocumentTypesListHandler();

    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>