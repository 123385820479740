<template>
    <div>
        <v-container>
            <v-form>
               <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text ml-2">
                            {{$t('statutoryexportcommonused.exportprocesslog')}}
                        </v-col>
                    </v-row>

                    <v-container>
                        <v-row>
                            <v-col cols="6" sm="6" class="orange lighten-5">
                                <v-row>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('latenessdeduction.totalrecordsprocessed')"
                                            type="text"
                                            v-model="logList.processedCount"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('glcode.debittotal')"
                                            type="text"
                                            v-model="logList.debitAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('glcode.credittotal')"
                                            type="text"
                                            v-model="logList.creditAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4"></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-container>
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px; margin-bottom: 20px;" 
                            @click="returnHandler"> 
                            {{$t('button.return')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
import 'jspdf-autotable';

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },
            pageTitle: this.$t('statutoryexportcommonused.exportprocesslog'),
            logList: {
                totalContAmt: null,
                processedCount: null,
                debitAmt: null,
                creditAmt: null
            },
            item: this.$route.params,
            errMsg: '',
            isErr: false,
        }
    },

    methods: {
        loadLog() {
            if (this.item) {
                this.logList.totalContAmt = this.item.totalContAmt
                this.logList.processedCount = this.item.processedCount
                this.logList.debitAmt = this.item.debitAmt
                this.logList.creditAmt = this.item.creditAmt
            }
        },

        returnHandler() {
            this.$router.push({name: 'glexportparameter'});
        },
    },

    mounted() {
        this.loadLog();
    }
}
</script>