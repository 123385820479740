<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.recordtype')"
                                type="text"
                                ref="movcode"
                                :rules="[rules.required]"
                                v-model="formData.movcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.movdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="10" sm="10">
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{$t('setupservicerectype.newhire')}}</th>
                                            <th>{{$t('setupservicerectype.locationmov')}}</th>
                                            <th>{{$t('setupservicerectype.jobmov')}}</th>
                                            <th>{{$t('setupservicerectype.salmov')}}</th>
                                            <th>{{$t('setupservicerectype.contractmov')}}</th>
                                            <th>{{$t('setupservicerectype.confirmation')}}</th>
                                            <th>{{$t('setupservicerectype.cessation')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.ishire" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.islocmov" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.isjobmov" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.issalmov" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.iscontrmov" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.isconfirmation" @change="updateValue"></v-checkbox></td>
                                        <td class="pt-3"><v-checkbox class="mx-3" v-model="formData.iscessation" @change="updateValue"></v-checkbox></td>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            formData: {
                movtypeid: this.parentObject.data.movtypeid,
                movtypedid: this.parentObject.data.movtypedid,
                movcode: this.parentObject.data.movcode,
                movdesc: this.parentObject.data.movdesc,
                ishire: this.parentObject.data.ishire,
                islocmov: this.parentObject.data.islocmov,
                isjobmov: this.parentObject.data.isjobmov,
                issalmov: this.parentObject.data.issalmov,
                iscontrmov: this.parentObject.data.iscontrmov,
                isconfirmation: this.parentObject.data.isconfirmation,
                iscessation: this.parentObject.data.iscessation,
                remark: this.parentObject.data.remark,
                isdisable: this.parentObject.data.isdisable,
                movstamp: this.parentObject.data.movstamp,
                movdstamp: this.parentObject.data.movdstamp,
                search: this.parentObject.data.search,
                userid: this.parentObject.data.userid

            },
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            search: this.parentObject.searchnew
        }
    },

    methods: {
        updateValue() {
            this.$emit('input', {
                movtypeid: this.formData.movtypeid,
                movtypedid: this.formData.movtypedid,
                movcode: this.formData.movcode,
                movdesc: this.formData.movdesc,
                ishire: this.formData.ishire,
                islocmov: this.formData.islocmov,
                isjobmov: this.formData.isjobmov,
                issalmov: this.formData.issalmov,
                iscontrmov: this.formData.iscontrmov,
                isconfirmation: this.formData.isconfirmation,
                iscessation: this.formData.iscessation,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                movstamp: this.formData.movstamp,
                movdstamp: this.formData.movdstamp,
                search: this.formData.search,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'servicerecordlist', params: { search: this.search}});
        }
    }
}
</script>