<template>
    <div id="`eissublist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('alleis.monthlyeissubmissionrec'),
            dataHeader: [
                { text: this.$t('columns.actions'),                       value: 'action',        width: '80px', sortable: false},
                { text: this.$t('alleis.eissubmissionrec'),        value: 'eissubreccode',     width: '100px'},
                { text: this.$t('columns.description'),                  value: 'eissubrecdesc',     width: '200px' },
                { text: this.$t('reportsparam.year'),                         value: 'recyear',              width: '90px' },
                { text: this.$t('reportsparam.month'),                        value: 'recmonth',              width: '100px' },
                { text: this.$t('statutoryexportcommonused.approvalname'),                value: 'aprname',              width: '200px' },
                { text: this.$t('statutoryexportcommonused.approvalicno'),               value: 'apricno',              width: '100px' },
                { text: this.$t('statutoryexportcommonused.approvaltel'),                 value: 'aprtel',              width: '100px' },
                { text: this.$t('statutoryexportcommonused.approvalemail'),               value: 'apremail',              width: '100px' },
                { text: this.$t('statutoryexportcommonused.approvaldesignation'),         value: 'aprdesignation',              width: '130px' },
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/eisubmissionrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'eissubedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'eissubnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>