<template>
  <div id="`psnpayrolllist`">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import GridView from "../../../components/common/GridView";
// import moment from "moment";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('cntryss.cntryss'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "80px" },
        { text: this.$t('reportsparam.year'), value: "year", width: "100px" },
        { text: this.$t('columns.country'), value: "cntryname", width: "200px" },
      ],

      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    //Handler for getting the records
    getCntrySSListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: `/cntryss/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "cntryssedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "cntryssnew" });
    },
  },

  mounted() {
    this.getCntrySSListHandler();
  },
};
</script>

                