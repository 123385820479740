<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline green white"
                    error-title
                >
                <span style="color: white">{{$t('resetpassword.success')}}</span>
                </v-card-title>

                <v-card-text>
                    {{messageSuccess}}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="onOKPress"
                    >
                        {{$t('vdatatable.ok')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            dialog: this.parentObject.isSuccess,
            messageSuccess: this.parentObject.messageSuccess,
        }
    },

    methods: {
        onOKPress() {
            this.dialog = !this.dialog;
        },

    }
}
</script>