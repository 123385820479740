<template>
    <div id="menucard">
        <v-layout row wrap>
            <v-flex xs12 sm6 md 4 lg3 
                v-for="card in this.menuCards"
                :key="card.id"
            >
                <v-card color="#F4F4F4">
                    <v-card-text>
                        <p class="text-h6 text--primary">{{card.title}}</p>
                        <div class="text--primary">{{card.content}}</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="blue" @click="routesMenu(card.routes)">{{card.buttonWord}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menuCards: [
                { id: 1, title: `${this.$t('selfservicescreen.mytask')}`, content: this.$t('selfservicescreen.actiontext'), buttonWord: this.$t('selfservicescreen.buttontext'), routes: 'selfservicemytask' }
            ]
        }
    },

    methods: {
        routesMenu(route){
            this.$router.push({name: route});
        }
    }
}

</script>