<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row v-if="!this.formData.lockstaff">
                    <v-col cols="10" sm="10" class="pb-n4">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.staffname')"
                            type="text"
                            :rules="[rules.required]"
                            hide-selected
                            v-model="formData.psnid"
                            @input="updateValue"
                            :items="personList"
                            dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col cols="10" sm="10" class="pb-n4">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.staffname')"
                            type="text"
                            :rules="[rules.required]"
                            hide-selected
                            v-model="formData.psnid"
                            readonly
                            :items="personList"
                            dense
                            background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                   
                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('qualificationlist.level')"
                            type="text"
                            ref="levelid"
                            :rules="[rules.required]"
                            :items="LevelList"
                            hide-selected
                            v-model="formData.levelid"
                            @input="updateValue"
                            @change="changeQuaLevelHandler(formData.levelid)"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="levelCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="levelDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: LevelItems,
                                        formTitle: `${this.$t('qualificationlist.level')}`,
                                        formId: 'qualevel'
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveQuaLevel="saveQuaLevelHandler"
                                    v-on:deleteQuaLevel="deleteQuaLevelHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="levelDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('qualificationlist.field')"
                            type="text"
                            ref="fieldid"
                            :items="FieldList"
                            hide-selected
                            v-model="formData.fieldid"
                            @input="updateValue"
                            @change="changeQuaFieldHandler(formData.fieldid)"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="fieldCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="fieldDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: FieldItems,
                                        formTitle: `${this.$t('qualificationlist.field')}`,
                                        formId: 'quafield'
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveQuaField="saveQuaFieldHandler"
                                    v-on:deleteQuaField="deleteQuaFieldHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="fieldDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('qualificationlist.major')"
                            type="text"
                            ref="majorid"
                            :items="MajorList"
                            hide-selected
                            v-model="formData.majorid"
                            @input="updateValue"
                            @change="changeQuaMajorHandler(formData.majorid)"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="majorCode"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="majorDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: MajorItems,
                                        formTitle: `${this.$t('qualificationlist.major')}`,
                                        formId: 'quamajor'
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveQuaMajor="saveQuaMajorHandler"
                                    v-on:deleteQuaMajor="deleteQuaMajorHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="majorDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('qualificationlist.institution')"
                            type="text"
                            ref="institutionid"
                            :rules="[rules.required]"
                            :items="InstitutionList"
                            hide-selected
                            v-model="formData.institutionid"
                            @input="updateValue"
                            @change="changeQuaInstitutionHandler(formData.institutionid)"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="institutionCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="institutionDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: InstitutionItems,
                                        formTitle: `${this.$t('qualificationlist.institution')}`,
                                        formId: 'quainstitution'
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveQuaInstitution="saveQuaInstitutionHandler"
                                    v-on:deleteQuaInstitution="deleteQuaInstitutionHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="institutionDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            id="startdate"
                            type="date"
                            ref="startdate"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            id="enddate"
                            type="date"
                            ref="enddate"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n12">
                        <v-checkbox
                            ref="isOversea"
                            v-model="formData.isOversea"
                            :label="this.$t('qualificationlist.isoversea')"
                            @change="updateValue"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('qualificationlist.resultorgrade')"
                            type="text"
                            ref="resultid"
                            :items="ResultList"
                            hide-selected
                            v-model="formData.resultid"
                            @input="updateValue"
                            @change="changeQuaResultHandler(formData.resultid)"
                            dense
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="resultCode"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-dialog v-model="resultDialog" max-width="500px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: ResultItems,
                                        formTitle: `${this.$t('qualificationlist.result')}`,
                                        formId: 'quaresult'
                                    }"
                                    v-on:input="itemData = $event"
                                    v-on:saveQuaResult="saveQuaResultHandler"
                                    v-on:deleteQuaResult="deleteQuaResultHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="resultDialog = false"
                                    >
                                    {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('qualificationlist.cgpa')"
                            type="text"
                            ref="cgpa"
                            v-model="formData.cgpa"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="10" sm="10" class="pb-n5 mt-n8">
                    <v-file-input
                        ref="attach"
                        :label="this.$t('qualificationlist.supportingdocument')"
                        outlined
                        v-model="fileHolder"
                        @change="fileToBase64"
                        @input="updateValue"
                        dense
                        multiple
                    ></v-file-input>
                    </v-col>
                </v-row>

                <div v-if="this.formData.attach">
                    <v-row v-if="!this.formData.lockstaff">
                     
                        <v-col cols="4" sm="6" class="pb-8 mt-n8">
                            <tr v-for="item in this.attachnew" :key="item.id">
                        
                                <p>
                                    <a
                                        v-bind:href="item.attach"
                                        v-bind:download="item.attachname"
                                    
                                        >{{item.attachname}}</a
                                    >
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteAttachmentHandlerNew(item.id)"
                                        >
                                        mdi-delete
                                    </v-icon>
                                </p>
                            </tr>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="4" sm="6" class="pb-8 mt-n5">
                            <tr v-for="item in this.formData.documents" :key="item.id">
                        
                                <p>
                                    <a
                                        v-bind:href="item.attach"
                                        v-bind:download="item.attachname"
                                        
                                        >{{item.attachname}}</a
                                    >
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteAttachmentHandler(item.id)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </p>
                            </tr>
                        </v-col>
                    </v-row>
                </div>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.editMode

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';
import GenericForm from '../../generic/GridForm';
//import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-genericform': GenericForm,
        //'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            formData: {
                psnid: this.parentObject.data.psnid,
                levelid: this.parentObject.data.levelid,
                fieldid: this.parentObject.data.fieldid,
                majorid: this.parentObject.data.majorid,
                institutionid: this.parentObject.data.institutionid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                isOversea: this.parentObject.data.isOversea,
                resultid: this.parentObject.data.resultid,
                cgpa: this.parentObject.data.cgpa,
                attach: this.parentObject.data.attach,
                attachname: this.parentObject.data.attachname,
                documents : this.parentObject.data.documents,
                docid: this.parentObject.data.docid,
                docdid: this.parentObject.data.docdid,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                userid: this.parentObject.data.userid
            },
          
           itemData: {
                // itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            psnidList: [],
            personList: [],
            LevelList: [],
            FieldList: [],
            MajorList: [],
            InstitutionList: [],
            ResultList: [],
            
            Levels: [],
            Fields: [],
            Majors: [],
            Institutions: [],
            Results: [],
            

            LevelItems: [],
            FieldItems: [],
            MajorItems: [],
            InstitutionItems: [],
            ResultItems: [],

            levelDialog: false,
            fieldDialog: false,
            majorDialog: false,
            institutionDialog: false,
            resultDialog: false,

            fileHolder: [],
            filename: [],
            attachnew: [],
            uploadDocuments : null,

            levelCode: '',
            fieldCode: '',
            majorCode: '',
            institutionCode: '',
            resultCode: '',
            // document: [],
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.editMode,
            search: this.parentObject.searchnew,

        }
    },

    methods: {

    
    download() {
    //  const blob = new Blob([], { type: "text/csv;charset=utf-8;" });
    //  const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      document.body.appendChild(a);

        for( var n = 0; n < this.formData.attach.length; n++ )
    {
        var download = this.formData.attach[n];
        a.setAttribute( 'href', download.path );
        a.setAttribute( 'download', download.name );

        a.click();
    }

      document.body.removeChild(a);
    },

        async fileToBase64() {
       
          if (this.fileHolder) {
                var file = this.$refs.attach.internalValue;

             for (let i = 0; i < file.length; i++){
               const filePromises = file.map((file) => {
                console.log('fileee', file)
                return new Promise((resolve, reject) => {
                const reader = new FileReader();
                //console.log('reader', reader)
                reader.onload = async () => {
                
                try {

                    if (file.size > '5000000'){
                     alert('File size is bigger than 5MB. Please upload new file.');
                    return;
                } else {

                    const response = await this.formData.attach.push(
                            reader.result,
                        );
                        // Resolve the promise with the response value
                        resolve(response);
                        console.log('response', response)
                        this.formData.attachname.push(file.name);
                        console.log('filename', this.formData.attachname)
                }                 
                        } catch (err) {
                        reject(err);
                        }
                };
                
                 reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                    });
             });
              
              const fileInfos = await Promise.all(filePromises);
                this.updateValue();
            
            for (let j = 0; j < this.formData.attach.length; j++){
                    this.attachnew.push({
                        attach: this.formData.attach[j],
                        attachname: this.formData.attachname[j]
                    })
                }

                  for (let k = 0; k < this.formData.attach.length; k++){
                    this.formData.documents.push({
                        attach: this.formData.attach[k],
                        attachname: this.formData.attachname[k]
                    })
                }

                // Profit
                return fileInfos;
             }
         }


           
       },

    deleteAttachmentHandler(id) {
         const vm = this;
         console.log('id',id)
        
        // console.log('itemindex', itemIndex)
        //  console.log(this.formData.attach)

      //  if (this.parentObject.editMode){

            if (confirm(this.$t('qualificationlist.confirmremove'))) {
            //  for (let k = 0; k<this.formData.attach.length; k++){
            //       this.formData.attach[k] = "";
            //       console.log('attach1111', this,this.formData.attach[k])
            //      // console.log('attachname1`1111', this,this.formData.attachname[k])
            //   }

            for (let i = 0; i < this.formData.documents.length; i++){
            // this.formData.attach[i] = "";
            // this.formData.attachname[i] = "";
            this.$store.dispatch('delete', {url: "/quadocumentdelete/" + id + "/" + this.formData.docid + "/" +
            this.formData.userid})
             .then((res) => {
                if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                } 

           
            })
             .catch((err) => {
                 vm.isErr = !vm.isErr;
                  vm.errMsg = err;
             });
            }             
                
            let itemIndex = this.formData.documents.indexOf(id);
            this.formData.documents.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
           
        } 
        else {
        //    let itemIndex2 = this.attachnew.indexOf(item);
        //    this.attachnew.splice(itemIndex2, 1);

        //     for (let k = 0; k<this.attachnew.length; k++){
        //          this.formData.attach[k] = "";
        //          this.formData.attachname[k] = "";
        //      }
  
        //     //this.filename = "";
        }

            console.log('attach1', this.formData.attach)
        },

         deleteAttachmentHandlerNew(id) {
        // const vm = this;
         
           let itemIndex2 = this.attachnew.indexOf(id);
           this.attachnew.splice(itemIndex2, 1);
           this.formData.attach.splice(itemIndex2, 1)
           this.formData.attach.splice(itemIndex2, 1)
           this.formData.attach.splice(itemIndex2, 1)
           this.formData.attach.splice(itemIndex2, 1)
           this.formData.attach.splice(itemIndex2, 1)

            console.log('attachnew', this.formData.attach)
            for (let k = 0; k<this.formData.attach.length; k++){
                 this.formData.attach[k] = "";
                 this.formData.attachname[k] = "";
                 //this.formData.docid[k] = "";
             }
  

            console.log('attach1', this.formData.attach)
        },


        // changeCaseStatus() {
        //     const vm = this;

        //     this.$store.dispatch('get', {
        //         url: '/ircasestatus/all'
        //     })
        //     .then(res => {

        //         vm.casestatusItems = res.data.rec;

        //         for (let i = 0; i < vm.casestatusItems.length; i++) {
        //             vm.casestatusList.push({
        //                 text: vm.casestatusItems[i].casestatusdesc,
        //                 value: vm.casestatusItems[i].id,
        //             });
        //         }

        //         // if (this.formData.casestatusid) {
        //         // this.changeCaseStatusHandler(this.formData.casestatusid);
        //         // }

        //     })
        //     .catch(err => {
        //         vm.errMsg = err;
        //     });
        // },

        // changeCaseStatusHandler(id) {
        //     var item = this.casestatusItems.find(item => item.id === id);
        //     this.formData.casestatusid = item.casestatusid
        //     //this.clmtypedesc = item.clmtypedesc;
        // },

        loadPersonListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.person = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.personList.push(
                            {
                                text: res.data.rec[i].psnname,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getQuaLevelListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/qualevel/all'})
                .then(res => {
                    vm.Levels = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.LevelList.push(
                            {
                                text: res.data.rec[i].leveldesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.LevelItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].levelcode,
                                desc: res.data.rec[i].leveldesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.levelid) {
                        vm.changeQuaLevelHandler(vm.formData.levelid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getQuaFieldListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/quafield/all'})
                .then(res => {
                    vm.Fields = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.FieldList.push(
                            {
                                text: res.data.rec[i].fielddesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.FieldItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].fieldcode,
                                desc: res.data.rec[i].fielddesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.fieldid) {
                        vm.changeQuaFieldHandler(vm.formData.fieldid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getQuaMajorListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/quamajor/all'})
                .then(res => {
                    vm.Majors = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.MajorList.push(
                            {
                                text: res.data.rec[i].majordesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.MajorItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].majorcode,
                                desc: res.data.rec[i].majordesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.majorid) {
                        vm.changeQuaMajorHandler(vm.formData.majorid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getQuaInstitutionListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/quainstitution/all'})
                .then(res => {
                    vm.Institutions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.InstitutionList.push(
                            {
                                text: res.data.rec[i].institutiondesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.InstitutionItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].institutioncode,
                                desc: res.data.rec[i].institutiondesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.institutionid) {
                        vm.changeQuaInstitutionHandler(vm.formData.institutionid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getQuaResultListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/quaresult/all'})
                .then(res => {
                    vm.Results = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.ResultList.push(
                            {
                                text: res.data.rec[i].resultdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.ResultItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].resultcode,
                                desc: res.data.rec[i].resultdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.resultid) {
                        vm.changeQuaResultHandler(vm.formData.resultid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeQuaLevelHandler(id) {
            let item = this.Levels.find(item => item.id == id);

            this.levelCode = item.levelcode;
        },

        changeQuaFieldHandler(id) {
            let item = this.Fields.find(item => item.id == id);

            this.fieldCode = item.fieldcode;
        },

        changeQuaMajorHandler(id) {
            let item = this.Majors.find(item => item.id == id);

            this.majorCode = item.majorcode;
        },

        changeQuaInstitutionHandler(id) {
            let item = this.Institutions.find(item => item.id == id);

            this.institutionCode = item.institutioncode;
        },

        changeQuaResultHandler(id) {
            let item = this.Results.find(item => item.id == id);

            this.resultCode = item.resultcode;
        },

        saveQuaLevelHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/qualevel/' + this.itemData.itemid, payload: {
                    levelcode: vm.itemData.itemcode,
                    leveldesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Levels = [];
                        vm.LevelList = [],
                        vm.LevelItems = [],

                        this.getQuaLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/qualevel/create', payload: {
                    levelcode: vm.itemData.itemcode,
                    leveldesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Levels = [];
                        vm.LevelList = [],
                        vm.LevelItems = [],

                        this.getQuaLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveQuaFieldHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/quafield/' + this.itemData.itemid, payload: {
                    fieldcode: vm.itemData.itemcode,
                    fielddesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Fields = [];
                        vm.FieldList = [],
                        vm.FieldItems = [],

                        this.getQuaFieldListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/quafield/create', payload: {
                    fieldcode: vm.itemData.itemcode,
                    fielddesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Fields = [];
                        vm.FieldList = [],
                        vm.FieldItems = [],

                        this.getQuaFieldListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveQuaMajorHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/quamajor/' + this.itemData.itemid, payload: {
                    majorcode: vm.itemData.itemcode,
                    majordesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Majors = [];
                        vm.MajorList = [],
                        vm.MajorItems = [],

                        this.getQuaMajorListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/quamajor/create', payload: {
                    majorcode: vm.itemData.itemcode,
                    majordesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Majors = [];
                        vm.MajorList = [],
                        vm.MajorItems = [],

                        this.getQuaMajorListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveQuaInstitutionHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/quainstitution/' + this.itemData.itemid, payload: {
                    institutioncode: vm.itemData.itemcode,
                    institutiondesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Institutions = [];
                        vm.InstitutionList = [],
                        vm.InstitutionItems = [],

                        this.getQuaInstitutionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/quainstitution/create', payload: {
                    institutioncode: vm.itemData.itemcode,
                    institutiondesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Institutions = [];
                        vm.InstitutionList = [],
                        vm.InstitutionItems = [],

                        this.getQuaInstitutionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveQuaResultHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/quaresult/' + this.itemData.itemid, payload: {
                    resultcode: vm.itemData.itemcode,
                    resultdesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Results = [];
                        vm.ResultList = [],
                        vm.ResultItems = [],

                        this.getQuaResultListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/quaresult/create', payload: {
                    resultcode: vm.itemData.itemcode,
                    resultdesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.Results = [];
                        vm.ResultList = [],
                        vm.ResultItems = [],

                        this.getQuaResultListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteQuaLevelHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/qualevel/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.Levels = [];
                        vm.LevelList= [],
                        vm.LevelItems = [],

                        this.getQuaLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteQuaFieldHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/quafield/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.Fields = [];
                        vm.FieldList = [],
                        vm.FieldItems = [],

                        this.getQuaFieldListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteQuaMajorHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/quamajor/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.Majors = [];
                        vm.MajorList = [],
                        vm.MajorItems = [],

                        this.getQuaMajorListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteQuaInstitutionHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/quainstitution/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.Institutions = [];
                        vm.InstitutionList = [],
                        vm.InstitutionItems = [],

                        this.getQuaInstitutionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteQuaResultHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/quaresult/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.Results = [];
                        vm.ResultList = [],
                        vm.ResultItems = [],

                        this.getQuaResultListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        updateValue() {
            this.$emit('input', {
                psnid: this.formData.psnid,
                levelid: this.formData.levelid,
                fieldid: this.formData.fieldid,
                majorid: this.formData.majorid,
                institutionid: this.formData.institutionid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                isOversea: this.formData.isOversea,
                resultid: this.formData.resultid,
                cgpa: this.formData.cgpa,
                attach: this.formData.attach,
                attachname: this.formData.attachname,
                documents: this.formData.documents,
                remark: this.formData.remark,
                stamp: this.formData.stamp,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                userid: this.formData.userid,
                docid: this.formData.docid,
                docdid: this.formData.docdid
            });

           // console.log('docdid', this.formData.attach.id)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'qualificationlist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadPersonListHandler();
        this.getQuaLevelListHandler();
        this.getQuaFieldListHandler();
        this.getQuaMajorListHandler();
        this.getQuaInstitutionListHandler();
        this.getQuaResultListHandler();

            if(this.parentObject.editMode){
                this.duplicateStaffData = this.formData;

                if(this.parentObject.data.lockstaff == "N"){
                    this.formData.lockstaff = false;
                } else {
                    this.formData.lockstaff = true;
                }
            }
    },
}
</script>