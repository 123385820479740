<template>
    <div>
        <app-form
            v-bind:parentObject="{
            data: this.formData,
            pageTitle: this.pageTitle,
            errMsg: this.errMsg,
            }"
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:profile="formData.pic = $event"
            v-on:error="errorHandler"
        />

        <div v-if="isErr">
            <app-error
            v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>

import EdocForm from "../../../components/settings/edocument/Form.vue";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    components: {
        "app-form": EdocForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            pageTitle: 'Create New E-Document',
            pageTitleValue: "",

            formData: {
                doctype: null,
                title: null,
                startdate: null,
                enddate: null,
                desc: null,
                attach: [],
                attachname: [],
                attachnew: [],
                edocdetails: [],
                isdisable: false,
                stamp: 0,
                userid: this.$store.getters.userId,
            },

            errMsg: "",
            isErr: false
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        // saveHandler($event) {
        saveHandler() {

            // console.log($event)
            
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                return false;
            }

            // Convert boolean to integer. 1 = true, 0 = false
            if (this.formData.isdisable == false) {
                this.formData.isdisable = 1;
            } else {
                this.formData.isdisable = 0;
            }

            // if($event){
            //     this.formData.attachnew = $event
            // }

            console.log('Save', this.formData)

            this.$store.dispatch('post', {url: "/edoc/create", payload: this.formData})
            .then((res) => {
                if (res.data.error) {
                vm.isErr = !vm.isErr;
                vm.errMsg = res.data.error;
                } else {
                this.$router.push({ name: "edoclist" });
                }
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });

        },
    },
};

</script>