<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
    />
  </div>
</template>

<script>
import Form from "../../../components/attendance/generateperiodicattnincentive/Form";

export default {
  components: {
    "app-form": Form,
  },

  data() {
    return {
      pageTitle: this.$t('genearateperiodicattendanceincentive'),
      pageTitleValue: "",
      formData: {},
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
  },
};
</script>
