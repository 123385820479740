<template>
    <div>
        <monthlysocso-form v-if="this.id == 3"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlysocsohlb-form v-if="this.id == 9"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <monthlysocsoambank-form v-if="this.id == 14"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlysocsocimb-form v-if="this.id == 21"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlysocsoaffin-form v-if="this.id == 34"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlysocsopublic-form v-if="this.id == 28"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        <monthlysocsohsbc-form v-if="this.id == 40"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />

        
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlySocsoForm from '../../../components/payroll/monthlysocsoexport/MonthlySocso';
import MonthlySocsoHLB from '../../../components/payroll/monthlysocsoexport/MonthlySocsoHLB';
import MonthlySocsoAmbank from '../../../components/payroll/monthlysocsoexport/MonthlySocsoAmbank';
import MonthlySocsoCIMB from '../../../components/payroll/monthlysocsoexport/MonthlySocsoCIMB';
import MonthlySocsoPUBLIC from '../../../components/payroll/monthlysocsoexport/MonthlySocsoPublic';
import MonthlySocsoAffin from '../../../components/payroll/monthlysocsoexport/MonthlySocsoAffin';
import MonthlySocsoHSBC from '../../../components/payroll/monthlysocsoexport/MonthlySocsoHSBC';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlysocso-form': MonthlySocsoForm,
        'monthlysocsohlb-form': MonthlySocsoHLB,
        'monthlysocsoambank-form': MonthlySocsoAmbank,
        'monthlysocsocimb-form': MonthlySocsoCIMB,
        'monthlysocsopublic-form': MonthlySocsoPUBLIC,
        'monthlysocsoaffin-form': MonthlySocsoAffin,
        'monthlysocsohsbc-form': MonthlySocsoHSBC,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('allsocso.monthlysocso'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },
}

</script>