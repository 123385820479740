<template>

    <div id="AlertEvent">

        <v-dialog v-model="subDialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    {{ subDialogTitle }}
                </v-card-title>
                <v-card-text>
                    {{ subDialogText }}
                </v-card-text>
                <v-text-field
                    v-if="this.subDialogReject"
                    v-model="rejRemark"
                    :label="this.$t('selfserviceleaveapproval.approrrej')"
                    class="ml-8 mr-8 mt-5" outlined dense background-color="white">
                </v-text-field>
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <app-formbuttons v-if="this.subDialogReject"
                        v-bind:confirmAction="this.confirmAction"
                        v-on:YesPressed="saveHandlerBulk"
                        v-on:CancelPressed="cancelHandler"
                    />
                    <app-formbuttons v-else
                        v-bind:confirmAction="this.confirmAction"
                        v-on:YesPressed="saveHandler"
                        v-on:CancelPressed="cancelHandler"
                    />
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
    
</template>

<script>

export default {

    props: {
        subDialog: Boolean,
        subDialogTitle: String,
        subDialogText: String,
        subDialogId: Number,
        subDetailTask: [Array, Object],
        subDialogReject: Boolean
    },

    data() {
        return {
            confirmAction: true,
            rejRemark: ''
        }
    },
    
    methods: {
        saveHandler(){

            this.$emit("saveAction", this.subDialogId, this.subDetailTask);
            
        },

        saveHandlerBulk(){ // For Reject Bulk Remark

            if(this.rejRemark){
                this.$emit("saveActionBulk", this.subDialogId, this.subDetailTask, this.rejRemark);
            } else {
                console.error(this.$t('selfservicemytask.remcolrequired'))
            }

        },

        cancelHandler(){
            this.rejRemark = ''
            this.$emit("cancelAction");
        }
    }
}

</script>