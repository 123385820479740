<template>
  <v-content>
    <v-container fluid fill-height grid-list-lg wrap class="py-2 pl-3 pr-0">
      <v-layout :column="$vuetify.breakpoint.xsOnly">
        <!--  
                            database-overview

                            The database overview pane can be hidden by clicking the list icon
                            on the editor tab pane.

                            We also set the height and width based on the breakpoint. This could be
                            done with pure CSS media queries, but vuetify provides us the means to 
                            do it right here with JS.
                        -->

        <!-- Vertical navbar -->
        <!-- Admin Navbar -->
        <div v-if="adminMenuAvailable && !this.$route.name.includes('selfservice')" class="vertical-nav bg-white" id="sidebar">
          <div class="py-2 px-3 bg-light">
            <div class="media d-flex align-items-center">
              <div class="media-body">
                <h2 class="mb-0">{{$t('transact2hrmenu')}}</h2>
              </div>
            </div>
          </div>
          <app-menu />
        </div>

        <!-- Non Admin Self Service Navbar -->
        <div v-else class="vertical-nav bg-white" id="sidebar">
          <div class="ml-4 mr-4 mt-4">
            <v-card>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-img 
                    v-if="this.personPic"
                    class="ml-2 mr-n4 rounded-circle"  
                    :src="this.personPic">
                  </v-img>
                  <v-img 
                    v-else
                    class="ml-2 mr-n4 rounded-circle"  
                    :src="`${publicPath}images/avatar-1577909_960_720.png`">
                  </v-img>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-row class="mt-3 mb-3 ml-2 mr-2">
                    <p style="font-size: 14px">{{`${this.$t('selfservicescreen.welcome')}, ${personName}`}}</p>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="6" md="6"></v-col>
                    <v-col cols="6" sm="6" md="6">
                      <div class="mt-4 mb-2 mr-2">
                        <v-icon @click="backHome()"> mdi-home </v-icon>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
        <!-- End vertical navbar -->

        <!-- Page content holder -->
        <div class="page-content px-5" id="content" style="margin-top: 110px">
          <!-- position: fixed; -->
          <div style="position: relative; width: 100%">

            <!-- Admin Button Toggle Menu Sidebar -->
            <v-btn
              v-if="adminMenuAvailable && !this.$route.name.includes('selfservice')"
              id="sidebarCollapse"
              type="button"
              class="btn btn-light bg-white rounded-pill shadow-sm px-4 mt-2"
              @click="toggleClass()"
            >
              <v-icon small class="mr-2"> mdi-menu </v-icon>
              <small class="text-uppercase font-weight-bold">{{$t('Menu')}}</small>
            </v-btn>

            <!-- Non Admin Button Toggle Profile Picture Sidebar -->
            <v-btn
              v-else
              id="sidebarCollapse"
              type="button"
              class="btn btn-light bg-white rounded-pill shadow-sm px-4 mt-2"
              @click="toggleClass()"
            >
              <v-icon small class="mr-2"> mdi-account </v-icon>
              <small class="text-uppercase font-weight-bold">{{$t('settingsswitchprofilemobile.profile')}}</small>
            </v-btn>
          </div>
          <!-- Demo content -->
          <!-- margin-top: 50px -->
          <div style="position: relative" class="mt-5">
            <router-view />
          </div>
        </div>
        <!-- End demo content -->
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import Menu from "./components/common/Menu";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "jquery/dist/jquery.slim.min.js";
// import "popper.js/dist/umd/popper.min.js";
import "jquery/dist/jquery.min.js";
import $ from "jquery";
export default {
  components: {
    "app-menu": Menu,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      showMenuOverview: true,
      adminMenuAvailable: null,
      personName: null,
      personPic: null,
    };
  },

  // watch : {
  //   currentPath() {
  //     console.log(this.$route);
  //   }
  // },

  methods: {
    toggleClass() {
      // $("#sidebarCollapse").on("click", function () {
      $("#sidebar, #content").toggleClass("active");
      // });
    },
    backHome() {
      this.$router.push({name: 'selfservicehome'});
    }
  },

  mounted() {

    // Check if admint or not
    this.adminMenuAvailable = this.$store.getters.userType == 1 ? true : false;
    console.log("Admin ?", this.adminMenuAvailable); 

    // Assign Person Name
    this.personName = this.$store.getters.personName;

    // Assign Person Pic
    this.personPic = this.$store.getters.personPic;

    //toggle sidebar
    // $("#sidebarCollapse").on("click", function () {
    //   $("#sidebar, #content").toggleClass("active");
    // });

  },
};
</script>