<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{pageTitle}}
          </h2>
        </v-row>

        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}</v-btn
            >
          </v-row>
        </v-col>

         <v-container 
        style="overflow: auto"
         >
          <v-row>
            <v-toolbar flat>
              <v-toolbar-title
                >{{$t('attendancesummarybystafffrom')}}
                <span class="blue--text">
                    {{ this.formData.getFromDate }}</span
                  >
                  {{$t('columns.to')}}
                  <span class="blue--text">
                    {{ this.formData.getUntilDate }}</span
                  >
              </v-toolbar-title>
              <v-spacer></v-spacer>

                <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>

              <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 20px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
          </v-toolbar>
        </v-row>

          <v-row class="mt-6">
            <v-col cols="20" sm="20">
              <v-data-table
                :headers="dataHeader"
                :search="search"
                :items="dataItems"
                :items-per-page="10"
                class="font-weight-light;text--secondary;"
                :sort-by="['compnycode']"
                fixed-header
                no-data-text="No records found."
              >
              </v-data-table>
            </v-col>
          </v-row>

       
        </v-container>
      </v-form>
   </v-container>
  </div>
</template>


<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {

  components: {
    Loading,
  },

  data() {
    return {

      publicPath: process.env.BASE_URL,

      pageTitle: this.$t('attendancesummarybystaff'),

      formData: {
        getFromDate: this.$route.params.fromDate,
        getUntilDate: this.$route.params.untilDate,
        getCompanyId: this.$route.params.companyid,
      },

      dataHeader: [
        { text: this.$t('columns.staffno'), value: 'staffcode'},
        { text: this.$t('columns.name'), value: 'psnname'},
        { text: this.$t('setuptotal.totalworkhours'), value: "twh"},
        { text: this.$t('setuptotal.totalnormaldayothours'), value: "tnoth"},
        { text: this.$t('setuptotal.totaloffdayothours'), value: "toth"},
        { text: this.$t('setuptotal.totalrestdayothours'), value: "troth" },
        { text: this.$t('setuptotal.totalholidayothours'), value: "thoth" },
        { text: this.$t('setuptotal.totalothours'), value: "tothrs" },
        { text: this.$t('setuptotal.totalshiftallowance'), value: "tsa" },
        { text: this.$t('setuptotal.totalotallowance'), value: "tota" },
        { text: this.$t('setuptotal.totalabsentdays'), value: "tad" },
        { text: this.$t('columns.company'), value: "compnycode" },
      ],

      search: '',
      dataItems: [],

      goBtn: false,
      loading: false,

    };
  },

  methods: {
    backButton() {
      this.$router.push({ name: "attendancesummarybystafffilter" });
    },

    loadAttendanceSummaryByStaff(){
            
      const vm = this;

      vm.loading = true;

        this.$store.dispatch('post', {url: "/attendancesummary/all", payload: {param: vm.formData} })
         .then((res) => {

            vm.dataItems = res.data.rec;

            vm.loading = false;

          })
          .catch(function (err) {
               vm.errMsg = err;
          });

     },

    printPDF() {
       const doc = new jsPDF({
         orientation: "landscape",
       });

      var head = [
        { title: this.$t('columns.staffno'), dataKey: 'staffcode'},
        { title: this.$t('columns.name'), dataKey: 'psnname'},
        { title: this.$t('setuptotal.totalworkhours'), dataKey: "twh"},
        { title: this.$t('setuptotal.totalnormaldayothours'), dataKey: "tnoth"},
        { title: this.$t('setuptotal.totaloffdayothours'), dataKey: "toth"},
        { title: this.$t('setuptotal.totalrestdayothours'), dataKey: "troth" },
        { title: this.$t('setuptotal.totalholidayothours'), dataKey: "thoth" },
        { title: this.$t('setuptotal.totalothours'), dataKey: "tothrs" },
        { title: this.$t('setuptotal.totalshiftallowance'), dataKey: "tsa" },
        { title: this.$t('setuptotal.totalotallowance'), dataKey: "tota" },
        { title: this.$t('setuptotal.totalabsentdays'), dataKey: "tad" },
        { title: this.$t('columns.company'), dataKey: "compnycode" },
       ];

       let body = [];
        for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          staffcode: this.dataItems[i].staffcode,
          psnname: this.dataItems[i].psnname,
          twh : this.dataItems[i].twh,
          tnoth: this.dataItems[i].tnoth,
          toth: this.dataItems[i].toth,
          troth: this.dataItems[i].troth,
          thoth: this.dataItems[i].thoth,
          tothrs: this.dataItems[i].tothrs,
          tsa: this.dataItems[i].tsa,
          tota: this.dataItems[i].tota,
          tad: this.dataItems[i].tad,
          compnycode: this.dataItems[i].compnycode,
        });
      }
         doc.text(
        `Attendance Summary by Staff from ${this.formData.getFromDate} to ${this.formData.getUntilDate}`,
        10,
        10
      );
 
      doc.autoTable(head, body);
      doc.save("Attendance Summary by Staff.pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        "Attendance Summary by Staff.csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [`${this.$t('columns.staffno')}`]: item[i].staffcode,
          [`${this.$t('columns.name')}`]: item[i].psnname,
          [`${this.$t('setuptotal.totalworkhours')}`]: item[i].twh,
          [`${this.$t('setuptotal.totalnormaldayothours')}`]: item[i].tnoth,
          [`${this.$t('setuptotal.totaloffdayothours')}`]: item[i].toth,
          [`${this.$t('setuptotal.totalrestdayothours')}`]: item[i].troth,
          [`${this.$t('setuptotal.totalholidayothours')}`]: item[i].thoth,
          [`${this.$t('setuptotal.totalothours')}`]: item[i].tothrs,
          [`${this.$t('setuptotal.totalshiftallowance')}`]: item[i].tsa,
          [`${this.$t('setuptotal.totalotallowance')}`]: item[i].tota,
          [`${this.$t('setuptotal.totalabsentdays')}`]: item[i].tad,
          [`${this.$t('columns.company')}`]: item[i].compnycode,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },

 },


  mounted() {

    this.loadAttendanceSummaryByStaff();
  
  },

};
</script>

<style>
.dateTitle {
  font-size: 12px;
  color: #ffffff;
  height: 0px;
  background-color: orange;
  align-content: center;
  justify-content: center;
}

.scroller {
  height: 100%;
  width: 100%;
}
</style>