var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-form',[_c('v-row',[_c('h2',{staticClass:"pb-5"},[_vm._v(_vm._s(this.parentObject.pageTitle)),_c('span',{staticClass:"page_title_value"},[_vm._v(_vm._s(this.parentObject.pageTitleValue))])])]),_c('v-container',{staticClass:"custom-form overflow-y-auto px-5",attrs:{"id":"scroll-target"}},[_c('v-row',[(!this.formData.lockstaff)?_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('recexchangerateset.exchangeset'),"type":"text","dense":"","items":_vm.exchangerateList},model:{value:(_vm.formData.exchrateid),callback:function ($$v) {_vm.$set(_vm.formData, "exchrateid", $$v)},expression:"formData.exchrateid"}})],1):_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('recexchangerateset.exchangeset'),"type":"text","dense":"","items":_vm.exchangerateList,"readonly":"","background-color":"#ffeab2"},model:{value:(_vm.formData.exchrateid),callback:function ($$v) {_vm.$set(_vm.formData, "exchrateid", $$v)},expression:"formData.exchrateid"}})],1),_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"2","sm":"2"}},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dense":""}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}]),model:{value:(_vm.exchangerateDialog),callback:function ($$v) {_vm.exchangerateDialog=$$v},expression:"exchangerateDialog"}},[_c('v-card',[_c('app-genericform',{attrs:{"parentData":{
                                    tabItems: _vm.exchangerateItems,
                                    formTitle: `${this.$t('recexchangerateset.exchangeset')}`,
                                    formId: 'exchangeset'
                                }},on:{"input":function($event){_vm.itemData = $event},"saveExchangeSet":_vm.saveExchangeSetHandler,"deleteExchangeset":_vm.deleteExchangeSetHandler}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.exchangerateDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.startdate'),"type":"date","dense":""},model:{value:(_vm.formData.startdate),callback:function ($$v) {_vm.$set(_vm.formData, "startdate", $$v)},expression:"formData.startdate"}})],1),_c('v-col',{staticClass:"mt-n8",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.enddate'),"type":"date","dense":""},model:{value:(_vm.formData.enddate),callback:function ($$v) {_vm.$set(_vm.formData, "enddate", $$v)},expression:"formData.enddate"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb4-4",attrs:{"col":"10","sm":"10"}},[_c('app-table',{attrs:{"parentObject":{
                            tabHeader: this.tabHeader,
                            detailItem: this.formData.exchangeratedetail
                        }},on:{"input":_vm.addItemHandler}})],1)],1)],1),_c('app-formbuttons',{attrs:{"EditBool":this.parentObject.isEdit},on:{"NewPressed":_vm.newHandler,"SavePressed":_vm.saveHandler,"DeletePressed":_vm.deleteHandler,"CancelPressed":_vm.cancelHandler}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }