<template>
    <div> 
        <v-container>
            <v-form>
                 <v-row>
                    <h2 class="pb-5"> {{this.parentObject.pageTitle}}<span class='page_title_value'>{{this.parentObject.pageTtileValue}}</span></h2>
                </v-row>

                <v-container 
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebate.rebatetype')"
                            type="text"
                            ref="rebatetypeid"
                            :items="taxableRebateList"
                            v-model="formData.taxdedttypeid"
                            @input="updateValue"
                            dense
                            :rules="[rules.required]"
                            @change="
                                changeTaxableRebateHandler(formData.taxdedttypeid)
                            "
                        ></v-autocomplete>
                    </v-col>
               </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.description')"
                            type="text"
                            v-model="formData.dedtdesc"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            type="date"
                            ref="startdate"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            type="date"
                            ref="enddate"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setuptaxablerebategroup.rebatelimitamount')"
                            type="number"
                            ref="rebatelimitamount"
                            v-model="formData.maxamt"
                            @input="updateValue"
                            class="numberfield"
                            dense
                            :rules="[rules.required]"
                        > 
                        </v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                    <v-autocomplete
                        outlined
                        :label="this.$t('columns.currency')"
                        type="text"
                        ref="crcyid"
                        :rules="[rules.required]"
                        v-model="formData.currencyid"
                        @input="updateValue"
                        dense
                        :items="currencyItemsList"
                        @change="changeCurrencyHandler(formData.currencyid)"
                    ></v-autocomplete>
                    </v-col>

                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="currencyCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                      <v-textarea
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            ref="remark"
                            maxlength="200"
                            v-model="formData.remark"
                            @input="updateValue"
                            dense
                            counter> 
                        </v-textarea>
                    </v-col>
                </v-row>


                </v-container>
                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },
  
    data() {
        return {
            formData: {
                taxdedttypeid: this.parentObject.data.taxdedttypeid,
                dedtcode: this.parentObject.data.dedtcode,
                dedtdesc: this.parentObject.data.dedtdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                currencyid: this.parentObject.data.currencyid,
                maxamt: this.parentObject.data.maxamt,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            rules:  {
               required:value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length <= 200 || 'Max 200 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },

            //taxableRebateCodes: [],
            taxableRebateList: [],
            currencyItemsList: [],
            currencyCode: '',
            currencyLoading: true,
            taxdedtLoading: true,
            taxablerebatetypes: [],
            itemData: null,

            errMsg: "",
            isErr: false,
             search: this.parentObject.searchnew

        } //end of return 
            
    },  //end of data 

   

    methods: {
        updateValue() {
            this.$emit('input', {
                taxdedttypeid: this.formData.taxdedttypeid,
                dedtcode: this.formData.dedtcode,
                dedtdesc: this.formData.dedtdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                currencyid: this.formData.currencyid,
                maxamt: this.formData.maxamt,
                remark: this.formData.remark,  
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
             console.log('formdt', this.formData)
        },

          loadTaxableRebateHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxablerebatelimittype/all'})
                .then(res => {
                    vm.taxablerebatetypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxableRebateList.push(
                            {
                                text: res.data.rec[i].dedtcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.taxdedtLoading = false;

                    if (vm.formData.taxdedttypeid) {
                        vm.changeTaxableRebateHandler(vm.formData.taxdedttypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeTaxableRebateHandler(id) {
            let item = this.taxablerebatetypes.find(item => item.id === id);
            this.formData.dedtcode = item.dedtcode;
            this.formData.dedtdesc = item.dedtdesc
        },

        
        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyItemsList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.currencyid) {
                        vm.changeCurrencyHandler(vm.formData.currencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);
            this.currencyCode = item.crcycode;
        },
        
        newHandler() {
            this.$emit('new');
        },
        saveHandler() {
            this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmtaxablerebatelimitlist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadCurrencyListHandler();
        this.loadTaxableRebateHandler();
    }
} 
</script>

<style>

.numberfield input[type='number'] {
    -moz-appearance:textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}


</style>

