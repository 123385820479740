<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
            
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:error="errorHandler"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>    
    </div>
</template>

<script>

import HolidayForm from '../../../components/leave/holiday/Form';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-form': HolidayForm,
        'app-error': ErrorDialog
    },

    data() {
        return {
            pageTitle: this.$t('setupholidaysetup.newholiday'),
            pageTitleValue : '',
            formData: {
                holcode: null,
                holtype: null,
                holid: null,
                holdesc: null,               
                repeatday: 0,
                holidaydetail:[],
                stamp: 0,
                userid: this.$store.getters.userId,                
                   
            },
            
            errMsg: '',
            isErr: false,
            editMode: false
        }
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        saveHandler() {
            const vm = this;

            // Convert boolean to integer. 1 = true, 0 = false
            if (this.formData.repeatday == false) {
                this.formData.repeatday = 0;
            } else {
                this.formData.repeatday = 1;
            }
            

            

            this.$store.dispatch('post', {url: '/holiday/create',payload: vm.formData})
             
                .then(res => {

                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } else {
                        this.$router.push({name: 'holidaylist'});
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        cancelHandler() {
            this.$router.push({name: 'holidaylist'});
        },
    }
}
</script>