<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }} </h2>
                </v-row>

                <!-- <v-row> -->
                    <!-- cols="6" 
                        align-self="center" -->
                    <!-- <v-col 
                        class="pa-0"
                        cols="10"
                    > -->
                        <div class="text-center">
                        
                            <v-img
                                v-if="this.picture"
                                :src="this.picture"
                                max-height="50"
                                max-width="220"
                            >
                            </v-img>
                            <v-img v-else
                                :src="`${publicPath}images/company.png`"
                                max-height="50"
                                max-width="220"
                            >
                            </v-img>
                        
                        </div>
                    <!-- </v-col>
                </v-row> -->

                <v-row>
                    <!-- cols="6" sm="6" class="mt-6" -->
                    <v-col 
                        cols="10"  
                        class="pb-n4 ml-n6"
                        align-self="center"
                    >
                        <v-file-input
                            label="Change System Logo"
                            ref="profileimg"
                            outlined
                            :accept=this.$store.getters.profileAcceptType
                            dense
                            show-size
                            style="font-size: 14px"
                            prepend-icon="mdi-camera"
                            @change="imgToBase64">
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row>
                    <div class="pt-3 pb-6">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="updateHandler"> 
                            {{$t('button.update')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default { 

    data() {
        return {
        
            pageTitle: "Change System Logo",
            picture: null,
            id: null,
            publicPath: process.env.BASE_URL,
            width: '',
        
        }
    },

    methods: {

        imgToBase64() {
            var file = this.$refs.profileimg.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.picture = b64;

                var image = new Image();
                    image.src = reader.result;

                    image.onload = function() {
                        // access image size here 
                        this.width = this.width;
                    };
                // });

                // this.$emit('profile', b64);

                 //file size in bytes. (5000000bytes = 5mb)
                if (file.size > '5000000'){
                     alert('File size is bigger than 5MB. Please upload new picture.');
                    return;
                }
            }
            reader.readAsDataURL(file); 
        },

        updateHandler() {

            const vm = this;

            // if(this.picture !== null) {

                this.$store.dispatch('put', {url: "/systemlogo/edit", payload: {picture: vm.picture, id: vm.id}})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                    } else {
                        location.reload();
                    }
                })
                .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                });

            // } else {

                // this.$store.dispatch('post', {url: "/systemlogo/new", payload: {picture: vm.picture}})
                // .then((res) => {
                // if (res.data.error) {
                //     vm.isErr = true;
                //     vm.errMsg = res.data.error;
                // } else {
                //     location.reload();
                // }
                // })
                // .catch((err) => {
                // vm.isErr = true;
                // vm.errMsg = err;
                // });
                
            // }  

        },

        loadLogo() {
            const vm = this;

            this.$store.dispatch('get', {url: "/systemlogo/all"})
            .then(res => {
                if(res.data.rec[0].image) {
                    vm.id = res.data.rec[0].id;
                    vm.picture = res.data.rec[0].image;
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        }

    },

    mounted() {
        this.loadLogo();
    }

}

</script>