<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                    {{ this.parentObject.pageTitle 
                    }}<span class="page_title_value">
                    {{ this.parentObject.pageTitleValue }}
                    </span>
                    </h2>
                </v-row>

                <v-col class="mt-n4 mb-2"> 
                    <v-row> 
                        <v-btn
                        v-model="goBtn"
                        @click="backButton()"
                        color="orange"
                        class="white--text"
                        >{{$t('button.back')}}</v-btn>
                    </v-row>
                </v-col>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-toolbar flat >
                        <v-toolbar-title>
                            {{$t('nonbankpaymentlistingreport.bankpaymentlistingforpaymentmode')}} <span class="blue--text"> {{this.data.formData.paymode}} </span> 
                                <div v-if="this.showDate" class="title-1 ">
                                    {{$t('nonbankpaymentlistingreport.payoutyear')}}
                                    <span class="blue--text"> 
                                        {{this.data.formData.pyyear}}</span>, 
                                           {{$t('statutoryexportcommonused.payoutmonth')}} 
                                            <span class="blue--text"> 
                                                {{this.data.formData.pymonth}}</span>
                                </div>
                            <div v-if="this.showID" class="title-1 "> 
                                {{$t('statutoryexportcommonused.payoutid')}} <span class="blue--text"> 
                                    {{this.data.formData.payoutcode}}</span>
                                </div>   
                                </v-toolbar-title>

                                <v-spacer></v-spacer>

                                <v-avatar class="mr-5" size="40" tile>
                                <img
                                    :src="`${publicPath}images/export_pdf.png`"
                                    @click="printPDF"
                                >
                                </v-avatar>

                                <v-avatar size="40" tile>
                                <img
                                    :src="`${publicPath}images/export_csv.png`"
                                    @click="getCSV"
                                >
                                </v-avatar>
                            </v-toolbar>
                </v-row>
                <v-row>
                            
                    <v-col >
                        <v-data-table
                            hide-default-footer
                            :headers="dataHeader"
                            :items="dataItems"
                            :items-per-page="1000"
                            multi-sort
                            :no-data-text="this.$t('vdatatable.norecordsfound')"
                        ></v-data-table>

                        <v-toolbar flat color="#faead8" dense   >
                        <v-col cols="9" sm="9" class="mr-9"></v-col>
                        <v-col cols="2" sm="2" >{{this.$t('bankpaymentlistingreport.total')}}</v-col>
                        <v-col cols="2" sm="2" class="ml-n6">
                                <span class="black--text"> {{this.total}} </span>
                        </v-col>
                        </v-toolbar>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-container>
    </div>
</template>

<script>

// import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            data: {
            
            formData: {
                
                 pyyear: this.$route.params.formData.pyyear,
                 pymonth: this.$route.params.formData.pymonth,
                 payoutcode: this.$route.params.formData.payoutcode,
                 paymode: this.$route.params.formData.paymode
            },

             parameters: {
                     brchid: this.$route.params.param.branchid,
                     deptid: this.$route.params.param.departmentid,
                     compnyid: this.$route.params.param.companyid,
                     emptypeid: this.$route.params.param.employmenttypeid,
                     gradeid: this.$route.params.param.gradeid,
                     staffid: this.$route.params.param.staffid,
                 },
            },

            dataHeader: [
                {text: this.$t('columns.no'),                    width: '7%',  value: 'id'},
                {text: this.$t('columns.name'),                  width: '15%', value: 'psnname'},
                {text: this.$t('columns.staffid'),              width: '9%', value: 'staffcode'},
                {text: this.$t('bankpaymentlistingreport.icnoorpassportno'),   width: '16%', value: 'idno'},
                {text: this.$t('columns.company'),               width: '15%', value: 'compnyname'},
                {text: this.$t('columns.department'),            width: '15%', value: 'deptname'},
                {text: this.$t('columns.currency'),              width: '15%', value: 'crcydesc'},
                {text: this.$t('setuppersonpayrolldirectassignment.amount'),                width: '18%', value: 'netpay'},

            ],

             tableOpt: {
                itemsPerPage: -1
            },

            showID: false,
            showDate: false,
            showTotal: false,
            total: null,
            netpay: null,


            dataItems: [],

            yearList: [],
            monthList: [],
            codeList: [],

            goBtn: false,

           
        }
    },

    methods: {


        backButton(){
            this.$router.push({name: 'parameternonbank'});
        },

        loadStaff(){

            this.goBtn = true;
            
            const vm = this;
                this.$store.dispatch('post', {url: `/nonbankpayment/${this.$store.getters.userId}`,
                payload: this.data})
                 .then(function (res){
                     vm.dataItems = res.data.rec;
                     let getamount = [];

                 for (let i = 0; i < vm.dataItems.length; i++) {
                         vm.dataItems[i].id = i + 1;
                         console.log('data', vm.dataItems)
                        
                        getamount.push(parseFloat(vm.dataItems[i].netpay.replace(/,/g, ""))
                        );
                    }
                        let gettotal = getamount.reduce(function (a, b) {
                        return a + b;
                        }, 0);

                        gettotal = gettotal.toFixed(2);

                        vm.total = gettotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                         vm.showTotal = true;
                     })
             .catch(function (err) {
                vm.errMsg = err;
                });
        },      

        printPDF() {

            const doc = new jsPDF({
                orientation: "landscape"
            })

            var head = [
                { title: this.$t('columns.no'),                      dataKey: 'id' },
                { title: this.$t('columns.name'),                    dataKey: 'psnname' },
                { title: this.$t('columns.staffid'),                dataKey: 'staffcode' },
                { title: this.$t('bankpaymentlistingreport.icnoorpassportno'),     dataKey: 'idno' },
                { title: this.$t('columns.company'),                 dataKey: 'compnyname' },
                { title: this.$t('columns.department'),              dataKey: 'deptname' },
                { title: this.$t('columns.currency'),                dataKey: 'crcydesc' },
                { title: this.$t('setuppersonpayrolldirectassignment.amount'),                  dataKey: 'netpay' },
                { title: this.$t('bankpaymentlistingreport.total'),                   dataKey: 'total'},
            ];

            let body = [];

            for (var i = 0; i < this.dataItems.length; i++) {

                body.push({
                    id : this.dataItems[i].id,
                    psnname : this.dataItems[i].psnname,
                    staffcode : this.dataItems[i].staffcode,
                    idno : this.dataItems[i].idno,
                    compnyname : this.dataItems[i].compnyname,
                    deptname : this.dataItems[i].deptname,
                    crcydesc : this.dataItems[i].crcydesc,
                    netpay : this.dataItems[i].netpay
                });

            }
                body.push({
                total: this.total,
            });
            
            doc.text(this.$t('nonbankpaymentlistingreport.nonbnkpymtlist'), 10, 10)
            doc.text(this.formData.paymode.toString(), 86, 10)
            
            if(this.formData.payoutcode){
                doc.text(this.$t('statutoryexportcommonused.payoutid'), 112, 10)
                doc.text(this.formData.payoutcode.toString(), 138, 10)
            } else {

                doc.text(this.$t('statutoryexportcommonused.payoutyear'), 112, 10)
                doc.text(this.formData.pyyear.toString(), 145 , 10) 
                doc.text(this.$t('statutoryexportcommonused.payoutmonth'), 159, 10)
                doc.text(this.formData.pymonth.toString(), 195, 10)
            }

            doc.autoTable(head, body)
            doc.save(this.$t('nonbankpaymentlistingreport.nonbankpaymentlisting') + "-" + this.data.formData.payoutcode + ".pdf");
        },

        objectToCSV(data) {
            const csvRows = [];

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download',this.$t('nonbankpaymentlistingreport.nonbankpaymentlisting') + "-" + this.data.formData.payoutcode + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let item = this.dataItems;
            let data = [];

            for (var i = 0; i < item.length; i++) {

                data.push({
                    [`${this.$t('columns.no')}`]: item[i].id,
                    [`${this.$t('columns.name')}`]: item[i].psnname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffcode,
                    [`${this.$t('bankpaymentlistingreport.icnoorpassportno')}`]: item[i].idno,
                    [`${this.$t('columns.company')}`]: item[i].compnyname,
                    [`${this.$t('columns.department')}`]: item[i].deptname,
                    [`${this.$t('columns.currency')}`]: item[i].crcydesc,
                    [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: item[i].netpay,
                    [`${this.$t('bankpaymentlistingreport.total')}`] : "",
                });
            }

                data.push ({
                    [`${this.$t('columns.no')}`]: "",
                    [`${this.$t('columns.name')}`]: "",
                    [`${this.$t('columns.staffid')}`]: "",
                    [`${this.$t('bankpaymentlistingreport.icnoorpassportno')}`]: "",
                    [`${this.$t('columns.company')}`]: "",
                    [`${this.$t('columns.department')}`]: "",
                    [`${this.$t('columns.currency')}`]: "",
                    [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: "",
                    [`${this.$t('bankpaymentlistingreport.total')}`] : this.total,

                });

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },
    },

    mounted() {   

    },

    created(){
        const vm = this;
        vm.formData = this.$route.params.formData;
        console.log("formdata received" , vm.formData)

        if(vm.formData.payoutcode){
            this.showID = true;
        } else {
            this.showDate = true;
        }
        
        this.loadStaff();
    }
    
}
</script>

<style>

.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}

.transparent{
    margin-bottom: 1px;
}

</style>