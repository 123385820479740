<template>
  <div>
    <div v-if="!loading">
      <taxablerebate-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MMPersonTaxableRebateRegForm from "../../../components/payroll/mmpersontaxablerebatereg/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

import moment from "moment";

export default {
  components: {
    "taxablerebate-form": MMPersonTaxableRebateRegForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('mmpersontaxablerebatereg.editmmpersontaxablerebatereglist'),
      pageTitleValue: "",
      formData: [],
      errMsg: "",
      isErr: false,
      editMode: true,
      loading: true,
      isConfirm: false,
    };
  },

  methods: {
    //delete record
    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      //add div and change v-on delete
      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/mmpersontaxablerebatreg/" +
              this.id +
              "/" +
              this.formData.userid,
            payload: { data: this.formData }})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "mmpersontaxablerebatereglist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "mmpersontaxablerebateregnew" });
    },

    cancelHandler() {
      this.$router.push({ name: "mmpersontaxablerebatereglist" });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //update record
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      var payload = this.formData;

      this.$store.dispatch('put', {url: "/mmpersontaxablerebatreg/" + vm.id,
          payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mmpersontaxablerebatereglist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //get record by id
    loadTaxableRebateHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/mmpersontaxablerebatreg/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData = res.data.rec[0];

            console.log('vm.formData', vm.formData)

            vm.formData.startdate = moment(vm.formData.startdate).format(
              "YYYY-MM-DD"
            );
            vm.formData.enddate = moment(vm.formData.enddate).format(
              "YYYY-MM-DD"
            );

            vm.formData.userid = this.$store.getters.userId;

            vm.loading = false;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadTaxableRebateHandler();
  },
};
</script>
