<template>
    <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>
</template>


<script>

import GridView from '../../../components/common/GridView'; 

export default {
    
    components: {
        'app-gridview': GridView
    
    },  

    data() {

         return {
            pageTitle: this.$t('setupcompanyreferencenumber.companyreferencenumberlist'),

            //tab headers 
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('setupcompanyreferencenumber.vendorcategory'), value: 'categorycode', sortable: false },
                { text: this.$t('setupcompanyreferencenumber.referencenumber'), value: 'refnum', width: '150px' },
                { text: this.$t('setupcompanyreferencenumber.referencecode'), value: 'refcode', width: '200px' },
                { text: this.$t('setupcompanyreferencenumber.vendor'), value: 'vendordesc', width: '200px' },
                { text: this.$t('columns.addressline1'), value: 'vendoraddr1', width: '200px' },
                { text: this.$t('columns.addressline2'), value: 'vendoraddr2', width: '200px' },
                { text: this.$t('columns.addressline3'), value: 'vendoraddr3', width: '200px' },
                { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                { text: this.$t('setupcompanyreferencenumber.addressid'), value: 'adrmascode', width: '200px' },
                { text: this.$t('columns.addressline1'), value: 'adrmasaddr1', width: '200px' },
                { text: this.$t('columns.addressline2'), value: 'adrmasaddr2', width: '200px' },
                { text: this.$t('columns.addressline3'), value: 'adrmasaddr3', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' },
             
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }

    }, 

    methods: {
         editItemHandler(item) {
            this.$router.push({name: 'refnumedit', params: {id: item.id, search: item.search}  });
        },

        newRecordHandler() {
            this.$router.push({name: 'refnumnew'});
        }, 

        //get all records
        getRefNumListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/refnum/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

               

        },


    },

    mounted() {
        this.getRefNumListHandler();

    }
}
</script>