<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2>{{ this.pageTitle }}</h2>
        </v-row>
        <!-- custom-form overflow-y-auto -->
        <v-container id="scroll-target" class="px-5">
          <v-card>
            <div class="ma-4">
              <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
              <v-row>
                <v-col cols="6" sm="6" class="pb-n5 ml-3">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.payout')"
                    type="text"
                    ref="payout"
                    hide-selected
                    v-model="payoutid"
                    :items="payoutctrlList"
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" sm="1" class="pt-5 pb-n5 ml-4">
                  <p>{{$t('payrollpivotreport.from')}} :</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.year')"
                    type="text"
                    v-model="dateParam.yearFrom"
                    :items="yearList"
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 ml-2">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.month')"
                    type="text"
                    v-model="dateParam.monthFrom"
                    :items="monthList"
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" sm="1" class="pt-5 pb-n5 mt-n8 ml-4">
                  <p>{{$t('payrollpivotreport.to')}} :</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.year')"
                    type="text"
                    v-model="dateParam.yearTo"
                    :items="yearList"
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 ml-2 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.month')"
                    type="text"
                    v-model="dateParam.monthTo"
                    :items="monthList"
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <app-parameter
            class="mt-5 mb-3"
            v-on:input="parameters = $event"
            v-bind:parentObject="{
              filter: this.showFilter,
            }"
          />
        </v-container>
        <div
          style="
            display: flex;
            align-items: flex-end;
            width: 100%;
            margin-top: 25px;
            padding-right: 25px;
          "
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-process" @click="onProcess"
            >{{$t('button.process')}}</v-btn
          >
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

import FilterParameter from "../../../components/common/FilterParameter";

export default {
  components: {
    "app-parameter": FilterParameter,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },

      parameters: {
        staffid: null,
        gradeid: null,
        employmenttypeid: null,
        companyid: null,
        branchid: null,
        departmentid: null,
      },

      showFilter: {
        staffid: true,
        gradeid: true,
        employmenttypeid: true,
        companyid: true,
        branchid: true,
        departmentid: true,
      },

      dateParam: {
        monthFrom: null,
        monthTo: null,
        yearFrom: null,
        yearTo: null,
        yearArr: [],
      },

      // yearArr: [],

      payoutid: null,

      pageTitle: this.$t('payrollpivotreport.payrollpivot'),
      yearList: [],
      monthList: [],
      detailList: [],
      payoutctrlList: [],
      payoutctrls: [],
    };
  },

  methods: {
    loadPayoutListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutctrl/all"})
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            if (vm.payoutctrls[i].startdate) {
              vm.payoutctrls[i].startdate = moment(
                vm.payoutctrls[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.payoutctrls[i].enddate) {
              vm.payoutctrls[i].enddate = moment(
                vm.payoutctrls[i].enddate
              ).format("YYYY-MM-DD");
            }

            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });

            vm.yearList.push({
              text: vm.payoutctrls[i].pyyear,
              value: vm.payoutctrls[i].pyyear
            });     
                  
            vm.monthList.push({
              text: vm.payoutctrls[i].pymonth,
              value: vm.payoutctrls[i].pymonth
            });

            this.findUnique(vm.yearList);
            this.findUnique(vm.monthList);  

          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    onProcess() {
      const id = this.payoutid;
      const param = {
        brchid: this.parameters.branchid,
        compnyid: this.parameters.companyid,
        deptid: this.parameters.departmentid,
        emptypeid: this.parameters.employmenttypeid,
        gradeid: this.parameters.gradeid,
        staffid: this.parameters.staffid,
        userid: this.$store.getters.userId,
      };

      // const date = this.dateParam;

      this.findInBetweenArr(this.yearList, this.dateParam.yearFrom, this.dateParam.yearTo);

      const date = this.dateParam;

      // const yeararr = this.yearArr;

      this.$router.push({
        name: "payrollpivotdetail",
        query: { id, param, date }, //, yeararr 
      });
    },

    findUnique(arr) {
      const uniqueIds = [];

        const unique = arr.filter(element => {
        const isDuplicate = uniqueIds.includes(element.value);

        if (!isDuplicate) {
          uniqueIds.push(element.value);
          return true;
        }

        return false;
      });

      // Sort 
      if( arr == this.yearList ) {
        this.yearList = unique.sort((a, b) => a.value - b.value);
      } else if( arr == this.monthList ) {
        this.monthList = unique.sort((a, b) => a.value - b.value);
      }
      
    },

    findInBetweenArr(arr, x, y) {

      let output = [];

      for (let i = 0; i < arr.length; i++){

        if(arr[i].value > x && arr[i].value < y){
          output.push(parseInt(arr[i].value));

        }
      }

      this.dateParam.yearArr = output;

    }

  },

  mounted() {
    this.loadPayoutListHandler();
  },
};
</script>