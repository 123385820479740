<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaim.claimcode')"
                                type="text"
                                ref="clmtype"
                                v-model="formData.clmtype"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaim.claimdescription')"
                                type="text"
                                ref="clmtypedesc"
                                v-model="formData.clmtypedesc"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                           <v-autocomplete
                                outlined
                                :label="this.$t('setupclaim.selectclaimcategory')"
                                type="text"
                                ref="clmtypecat"
                                :items="clmtypecatList"
                                hide-selected
                                v-model="formData.clmtypecatid"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaim.selectparentclaim')"
                                    type="text"
                                    ref="parentclmid"

                                    v-model="formData.parentclmid"
                                    dense
                                    :items="parentclmList"
                                    @input="updateValue"
                                    @change="changeParentClaim(formData.parentclmid)"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaim.parentclaimdescription')"
                                    type="text"
                                    ref="parentclmtypedesc"
                                    v-model="parentclmtypedesc"
                                    dense
                                    readonly
                                    clearable
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                           <v-autocomplete
                                outlined
                                :label="this.$t('setupclaim.selectclaimlimitperiod')"
                                type="text"
                                ref="clmprdtypeid"
                                :items="clmprdtypeList"
                                hide-selected
                                v-model="formData.clmprdtypeid"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupclaim.startmonth')"
                                type="text"
                                ref="startmth"
                                :items="startmthList"
                                hide-selected
                                :disabled="formData.clmprdtypeid == '1' "
                                v-model="formData.startmth"
                                @input="updateValue"

                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="clmviapayroll"
                                v-model="formData.clmviapayroll"
                                :label="this.$t('setupclaim.reimburseviapayroll')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.selectpayitem')"
                                    type="text"
                                    ref="payitemid"
                                    :disabled="formData.clmviapayroll == '0' "
                                    v-model="formData.payitemid"
                                    dense
                                    clearable
                                    :items="payItemsList"
                                    @input="updateValue"
                                    @change="changePayItem(formData.payitemid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    background-color="#ffeab2"
                                    :label="this.$t('setupclaim.payitemdescription')"
                                    type="text"
                                    ref="payitemdesc"
                                    v-model="payitemdesc"
                                    :disabled="formData.clmviapayroll == '0' "
                                    dense
                                    readonly
                                    clearable
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaim.maximumbackdateddays')"
                                type="text"
                                ref="backdateddays"
                                v-model="formData.backdateddays"

                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaim.defaulttax')"
                                type="text"
                                ref="taxrate"
                                v-model="formData.taxrate"

                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>


                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>
                       </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.exchangerate')"
                            type="text"
                            :items="exchangeRateList"
                            :rules="[rules.required]"
                            v-model="formData.exchrateid"
                            @change="changeExchrateHandler(formData.exchrateid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="exchageRateCode"
                        ></v-text-field>
                    </v-col>
                </v-row>
                   

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="selfservice"
                                v-model="formData.selfservice"
                                :label="this.$t('setupclaim.publishtoselfserviceportal')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="confirmedstaff"
                                v-model="formData.confirmedstaff"
                                :label="this.$t('setupclaim.confirmedstaffonly')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                     </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="onceinemplymt"
                                v-model="formData.onceinemplymt"
                                :label="this.$t('setupclaim.onceinemployment')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
//import moment from 'moment';
//import VueTimepicker from "vue2-timepicker";
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
        //'app-genericform': GenericForm,
       // "vue-timepicker": VueTimepicker,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                clmtype: this.parentObject.data.clmtype,
                clmtypedesc: this.parentObject.data.clmtypedesc,
                clmtypecatid: this.parentObject.data.clmtypecatid,
                clmtypecat: this.parentObject.data.clmtypecat,
                parentclmid: this.parentObject.data.parentclmid,
                parentclmtypedesc: this.parentObject.data.parentclmtypedesc,
                parentclmtype: this.parentObject.data.parentclmtype,
                clmprdtypeid: this.parentObject.data.clmprdtypeid,
                prdtype: this.parentObject.data.prdtype,
                startmth: this.parentObject.data.startmth,
                clmviapayroll: this.parentObject.data.clmviapayroll,
                payitemid: this.parentObject.data.payitemid,
                payitemcode: this.parentObject.data.payitemcode,
                payitemdesc: this.parentObject.data.payitemdesc,
                backdateddays: this.parentObject.data.backdateddays,
                taxrate: this.parentObject.data.taxrate,
                selfservice: this.parentObject.data.selfservice,
                confirmedstaff: this.parentObject.data.confirmedstaff,
                onceinemplymt: this.parentObject.data.onceinemplymt,
                crcyid: this.parentObject.data.crcyid,
                exchrateid: this.parentObject.data.exchrateid,
                isdisable: this.parentObject.data.isdisable,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            startmthList: [
                {
                    text: "January",
                    value: 1,
                },
                {
                    text: "February",
                    value: 2,
                },
                {
                    text: "March",
                    value: 3,
                },
                {
                    text: "April",
                    value: 4,
                },
                {
                    text: "May",
                    value: 5,
                },
                {
                    text: "June",
                    value: 6,
                },
                {
                    text: "July",
                    value: 7,
                },
                {
                    text: "August",
                    value: 8,
                },
                {
                    text: "September",
                    value: 9,
                },
                {
                    text: "October",
                    value: 10,
                },
                {
                    text: "November",
                    value: 11,
                },
                {
                    text: "December",
                    value: 12,
                },
            ],

            clmtypecatList: [],
            clmtypeItems: [],
            ClaimCategoryLoading: true,

            clmprdtypeItems: [],
            clmprdtypeList: [],
            ClaimLimitLoading: true,

            payItems: [],
            payItemsList: [],
            payitemdesc: '',

            currencyCode: '',
            currencyList: [],
            currencyItems: [],

            parentclmItems: [],
            parentclmList: [],
          //  clmtypedesc: '',
            parentclmtypedesc: '',

            exchageRateCode: "",
            exchangeRate: [],
            exchangeRateList: [],



            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {

        // changeShiftHandler(){

        //     if (this.formData.clmprdtypeid == '3' ||
        //         this.formData.clmprdtypeid == '4'){

        //          this.formData.startmth = null
        //          this.formData.startmth = ""
        //       console.log('this', this.changeShiftHandler())
        //     }
        // },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        // vm.currencyItems.push(
                        //     {
                        //         id: res.data.rec[i].id,
                        //         code: res.data.rec[i].crcycode,
                        //         desc: res.data.rec[i].crcydesc,
                        //         stamp: res.data.rec[i].stamp
                        //     }
                        // )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

         loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/exchrate/all'})
                .then(res => {
                    vm.exchangeRate = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangeRateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        // vm.currencyItems.push(
                        //     {
                        //         id: res.data.rec[i].id,
                        //         code: res.data.rec[i].crcycode,
                        //         desc: res.data.rec[i].crcydesc,
                        //         stamp: res.data.rec[i].stamp
                        //     }
                        // )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.exchrateid) {
                        vm.changeExchrateHandler(vm.formData.exchrateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeExchrateHandler(id) {
            let item = this.exchangeRate.find(item => item.id === id);

            this.exchageRateCode = item.exchratecode;
        },

        LoadClaimCategoryType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypecategory/all'})
                .then(res => {
                    vm.clmtypeItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.clmtypecatList.push(
                            {
                                text: res.data.rec[i].clmtypecat,
                                value: res.data.rec[i].id
                            }
                        )}

                    vm.ClaimCategoryLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        LoadClaimLimitCategoryType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimlimitperiod/all'})
                .then(res => {
                    vm.clmprdtypeItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.clmprdtypeList.push(
                            {
                                text: res.data.rec[i].prdtype,
                                value: res.data.rec[i].id
                            }
                        )}

                    vm.ClaimLimitLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },


        changePayItem() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payitemclaim/all'})
            .then(res => {

                vm.payItems = res.data.rec;

                for (let i = 0; i < vm.payItems.length; i++) {
                    vm.payItemsList.push({
                        text: vm.payItems[i].payitemcode,
                        value: vm.payItems[i].id,
                    });
                }
                if (this.formData.payitemid) {
                this.changePayItemHandler(this.formData.payitemid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changePayItemHandler(id) {
            var item = this.payItems.find(item => item.id === id);
            this.payitemcode = item.payitemcode;
            this.payitemdesc = item.payitemdesc;
        },

        changeParentClaim() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'})
            .then(res => {

                vm.parentclmItems = res.data.rec;

                for (let i = 0; i < vm.parentclmItems.length; i++) {
                    vm.parentclmList.push({
                        text: vm.parentclmItems[i].clmtype,
                        value: vm.parentclmItems[i].id,
                    });
                }

                if (this.formData.parentclmid) {
                this.changeParentClaimHandler(this.formData.parentclmid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeParentClaimHandler(id) {
            var item = this.parentclmItems.find(item => item.id === id);
            this.parentclmtype = item.clmtype
            this.parentclmtypedesc = item.clmtypedesc;
        },


        updateValue() {

        this.$emit('input', {

                clmtype: this.formData.clmtype,
                clmtypedesc: this.formData.clmtypedesc,
                clmtypecatid: this.formData.clmtypecatid,
                clmtypecat: this.formData.clmtypecat,
                parentclmid: this.formData.parentclmid,
                parentclmtypedesc: this.formData.parentclmtypedesc,
                parentclmtype: this.formData.parentclmtype,
                clmprdtypeid: this.formData.clmprdtypeid,
                prdtype: this.formData.prdtype,
                startmth: this.formData.startmth,
                clmviapayroll: this.formData.clmviapayroll,
                payitemid: this.formData.payitemid,
                payitemcode: this.formData.payitemcode,
                payitemdesc: this.formData.payitemdesc,
                backdateddays: this.formData.backdateddays,
                taxrate: this.formData.taxrate,
                crcyid: this.formData.crcyid,
                exchrateid: this.formData.exchrateid,
                selfservice: this.formData.selfservice,
                confirmedstaff: this.formData.confirmedstaff,
                onceinemplymt: this.formData.onceinemplymt,
                isdisable: this.formData.isdisable,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'claimtypesetuplist', params: { search: this.search}});
        },

    },

    mounted() {

        this.changePayItem();
        this.changeParentClaim();
        this.LoadClaimCategoryType();
        this.LoadClaimLimitCategoryType();
        this.loadCurrencyListHandler();
        this.loadExchangeRate();
    }



}
</script>