<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import LeaveSetupForm from "../../../components/leave/leavesetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LeaveSetupForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupleavesetup.editleavesetup'),
      pageTitleValue: "",
      formData: {
        leavecode: null,
        leavedesc: null,
        parentid: null,
        ptypeid: null,
        startmth: null,
        unittype: null,
        dispunit: null,
        hrperday: null,
        remark: null,
        ispaid: null,
        isearn: null,
        isonce: null,
        ismed: null,
        isincloff: null,
        isinclrest: null,
        isinclhol: null,
        isconfirmed: null,
        isreasoncompul: null,
        isreliefcompul: null,
        iscliniccompu: null,
        isparent: null,
        isencash: null,
        isnolimit: null,
        minpertran: null,
        maxpertran: null,
        earlynotice: null,
        cancf: null,
        maxunitcf: null,
        maxperccf: null,
        maxpercentcf: null,
        bfmth: null,
        usecfpolicy: null,
        dependleaveid: null,
        dependminunit: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadLeaveType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/leavesetup/" + this.id})
        .then((res) => {
          console.log('rec', res.data.rec[0])
          
          if (res.data.rec[0].ispaid == "N") {
            vm.formData.ispaid = false;
          } else {
            vm.formData.ispaid = true;
          }

          if (res.data.rec[0].isearn == "N") {
            vm.formData.isearn = false;
          } else {
            vm.formData.isearn = true;
          }
          
          if (res.data.rec[0].isonce == "N") {
            vm.formData.isonce = false;
          } else {
            vm.formData.isonce = true;
          }

          if (res.data.rec[0].ismed == "N") {
            vm.formData.ismed = false;
          } else {
            vm.formData.ismed = true;
          }

          if (res.data.rec[0].isincloff == "N") {
            vm.formData.isincloff = false;
          } else {
            vm.formData.isincloff = true;
          }

          if (res.data.rec[0].isinclrest == "N") {
            vm.formData.isinclrest = false;
          } else {
            vm.formData.isinclrest = true;
          }

          if (res.data.rec[0].isinclhol == "N") {
            vm.formData.isinclhol = false;
          } else {
            vm.formData.isinclhol = true;
          }

          if (res.data.rec[0].isconfirmed == "N") {
            vm.formData.isconfirmed = false;
          } else {
            vm.formData.isconfirmed = true;
          }

          if (res.data.rec[0].isreasoncompul == "N") {
            vm.formData.isreasoncompul = false;
          } else {
            vm.formData.isreasoncompul = true;
          }

          if (res.data.rec[0].isreliefcompul == "N") {
            vm.formData.isreliefcompul = false;
          } else {
            vm.formData.isreliefcompul = true;
          }

          if (res.data.rec[0].iscliniccompu == "N") {
            vm.formData.iscliniccompu = false;
          } else {
            vm.formData.iscliniccompu = true;
          }

          if (res.data.rec[0].isparent == "N") {
            vm.formData.isparent = false;
          } else {
            vm.formData.isparent = true;
          }

          if (res.data.rec[0].isencash == "N") {
            vm.formData.isencash = false;
          } else {
            vm.formData.isencash = true;
          }

          if (res.data.rec[0].isnolimit == "N") {
            vm.formData.isnolimit = false;
          } else {
            vm.formData.isnolimit = true;
          }

          if (res.data.rec[0].cancf == "N") {
            vm.formData.cancf = false;
          } else {
            vm.formData.cancf = true;
          }

          if (res.data.rec[0].usecfpolicy == "N") {
            vm.formData.usecfpolicy = false;
          } else {
            vm.formData.usecfpolicy = true;
          }

          vm.formData.leavecode = res.data.rec[0].leavecode;
          vm.formData.leavedesc = res.data.rec[0].leavedesc;
          vm.formData.parentid = res.data.rec[0].parentid;
          vm.formData.ptypeid = res.data.rec[0].ptypeid;
          vm.formData.startmth = res.data.rec[0].startmth;
          vm.formData.unittype = res.data.rec[0].unittype;
          vm.formData.dispunit = res.data.rec[0].dispunit;
          vm.formData.hrperday = res.data.rec[0].hrperday;
          vm.formData.remark = res.data.rec[0].remark;
          vm.formData.minpertran = res.data.rec[0].minpertran;
          vm.formData.maxpertran = res.data.rec[0].maxpertran;
          vm.formData.earlynotice = res.data.rec[0].earlynotice;
          vm.formData.maxunitcf = res.data.rec[0].maxunitcf;
          vm.formData.maxperccf = res.data.rec[0].maxperccf;
          vm.formData.maxpercentcf = res.data.rec[0].maxpercentcf;
          vm.formData.bfmth = res.data.rec[0].bfmth;
          vm.formData.dependleaveid = res.data.rec[0].dependleaveid;
          vm.formData.dependminunit = res.data.rec[0].dependminunit;
          vm.formData.stamp = res.data.rec[0].stamp;

          vm.pageTitleValue = res.data.rec[0].leavedesc;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (this.formData.ispaid == false) {
        this.formData.ispaid = 0;
      } else {
        this.formData.ispaid = 1;
      }

      if (this.formData.isearn == false) {
        this.formData.isearn = 0;
      } else {
        this.formData.isearn = 1;
      }

      if (this.formData.isonce == false) {
        this.formData.isonce = 0;
      } else {
        this.formData.isonce = 1;
      }

      if (this.formData.ismed == false) {
        this.formData.ismed = 0;
      } else {
        this.formData.ismed = 1;
      }

      if (this.formData.isincloff == false) {
        this.formData.isincloff = 0;
      } else {
        this.formData.isincloff = 1;
      }

      if (this.formData.isinclrest == false) {
        this.formData.isinclrest = 0;
      } else {
        this.formData.isinclrest = 1;
      }

      if (this.formData.isinclhol == false) {
        this.formData.isinclhol = 0;
      } else {
        this.formData.isinclhol = 1;
      }

      if (this.formData.isconfirmed == false) {
        this.formData.isconfirmed = 0;
      } else {
        this.formData.isconfirmed = 1;
      }

      if (this.formData.isreasoncompul == false) {
        this.formData.isreasoncompul = 0;
      } else {
        this.formData.isreasoncompul = 1;
      }

      if (this.formData.isreliefcompul == false) {
        this.formData.isreliefcompul = 0;
      } else {
        this.formData.isreliefcompul = 1;
      }

      if (this.formData.iscliniccompu == false) {
        this.formData.iscliniccompu = 0;
      } else {
        this.formData.iscliniccompu = 1;
      }

      if (this.formData.isparent == false) {
        this.formData.isparent = 0;
      } else {
        this.formData.isparent = 1;
      }

      if (this.formData.isencash == false) {
        this.formData.isencash = 0;
      } else {
        this.formData.isencash = 1;
      }

      if (this.formData.isnolimit == false) {
        this.formData.isnolimit = 0;
      } else {
        this.formData.isnolimit = 1;
      }

      if (this.formData.cancf == false) {
        this.formData.cancf = 0;
      } else {
        this.formData.cancf = 1;
      }

      if (this.formData.usecfpolicy == false) {
        this.formData.usecfpolicy = 0;
      } else {
        this.formData.usecfpolicy = 1;
      }

      if (this.formData.minpertran == "") {
        this.formData.minpertran = null;
      }

      if (this.formData.maxpertran == "") {
        this.formData.maxpertran = null;
      }

      if (this.formData.earlynotice == "") {
        this.formData.earlynotice = null;
      }

      if (this.formData.maxpercentcf == "") {
        this.formData.maxpercentcf = null;
      }
        
      if (this.formData.maxperccf == "") {
        this.formData.maxperccf = null;
      }
        
      if (this.formData.maxunitcf == "") {
        this.formData.maxunitcf = null;
      }
        
      if (this.formData.bfmth == "") {
        this.formData.bfmth = null;
      }
        
      if (this.formData.dependminunit == "") {
        this.formData.dependminunit = null;
      }
      
      this.$store.dispatch('put', {url: "/leavesetup/" + this.id,
          payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavesetuplist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/leavesetup/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leavesetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "leavesetupnew" });
    },

    cancelHandler() {
      this.$router.push({ name: "leavesetuplist" });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadLeaveType();
  },
};
</script>