<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle 
                        }}<span class="page_title_value">{{
                             this.parentObject.pageTitleValue 
                             }}</span>
                             </h2>
                </v-row>

                <v-col class="mt-n4 mb-2"> 
                    <v-row> 
                        <v-btn
                        v-model ="goBtn"
                        @click="backButton()"
                        color="orange"
                        class="white--text"
                        >{{$t('button.back')}}</v-btn>
                    </v-row>
                </v-col>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-toolbar flat >
                        <v-toolbar-title>
                            {{$t('cashdenominationlistingreport.cashdenominationlistingforyearmonthandpauyoutid')}}
                            <span class="blue--text"> 
                                {{this.data.formData.dateYear}}</span>
                                 {{this.$t('reportsparam.month')}} , <span class="blue--text"> 
                                     {{this.data.formData.dateMonth}}</span> 
                                     {{$t('cashdenominationlistingreport.andpayoutid')}} <span class="blue--text"> 
                                         {{this.data.formData.payoutcode}}</span>
                            <div class="title-1 "> 
                                <span class="blue--text"> 
                                    {{this.data.formData.compnyname}}</span> 
                                    </div>                         
                        </v-toolbar-title>
                            <v-spacer></v-spacer>
                        
                        <v-avatar
                        class="profile mr-5"
                        size="40"
                        tile>
                        <img
                            :src="`${publicPath}images/export_pdf.png`"
                            @click="printPDF"
                        >
                        </v-avatar>

                        <v-avatar
                        class="profile"
                        size="40"
                        tile>
                        <img
                            :src="`${publicPath}images/export_csv.png`"
                            @click="getCSV"
                        >
                        </v-avatar>
                                
                    </v-toolbar>
                </v-row>

                <v-row>
                            
                    <v-col >
                        <v-data-table
                            hide-default-footer
                            :headers="dataHeader"
                            :items="dataItems"
                            :items-per-page="1000"
                            multi-sort
                           :no-data-text="this.$t('vdatatable.norecordsfound')"
                        >
                        
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in dataItems" :key="item">
                                    <td >
                                        {{item.salhdid}}
                                    </td>
                                    <td >
                                        {{item.compnycode}}
                                    </td>

                                    <td >
                                        {{item.psnname}}
                                    </td>

                                    <td >
                                        {{item.staffcode}}
                                    </td>
                                
                                    <td >
                                        <v-layout justify-end>{{item.amount}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm100}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm50}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm20}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm10}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm5}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.rm1}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.sen50}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.sen20}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.sen10}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.sen5}}</v-layout>
                                    </td>

                                    <td >
                                        <v-layout justify-center>{{item.sen1}}</v-layout>
                                    </td>
                                </tr>
                                <tr class="total">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><v-layout justify-end>{{total}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm100}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm50}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm20}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm10}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm5}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountRm1}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountSen50}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountSen20}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountSen10}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountSen5}}</v-layout></td>
                                    <td><v-layout justify-center>{{totalCountSen1}}</v-layout></td>
                                </tr>

                            </tbody>
                        </template>
                        </v-data-table>

                        <!-- <v-toolbar
                            flat
                            color="#faead8"
                            dense
                        >
                        
                        </v-toolbar> -->
                    </v-col>
                </v-row>

                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
    
    props: {
        parentObject: Object,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

        data: {
            
            formData: {
                compnyname: this.$route.params.formData.compnyname,
                payoutcode: this.$route.params.formData.payoutcode,
            },

            parameters: {
                brchid: this.$route.params.param.branchid,
                deptid: this.$route.params.param.departmentid,
                emptypeid: this.$route.params.param.employmenttypeid,
                gradeid: this.$route.params.param.gradeid,
                staffid: this.$route.params.param.staffid,
            },
      },

            dataHeader: [
                {text: this.$t('columns.no'),                    width: '70px',  value: 'salhdid'},
                {text: this.$t('columns.company'),               width: '150px', value: 'compnycode'},
                {text: this.$t('columns.name'),                  width: '150px', value: 'psnname'},
                {text: this.$t('columns.staffid'),              width: '100px', value: 'staffcode'},
                {text: this.$t('previewpayroll.netpay'),               width: '100px', value: 'amount', align: 'end'},
                {text: 'RM 100',                width: '100px',  value: 'rm100', align: 'center'},
                {text: 'RM 50',                 width: '100px',  value: 'rm50', align: 'center'},
                {text: 'RM 20',                 width: '100px',  value: 'rm20', align: 'center'},
                {text: 'RM 10',                 width: '100px',  value: 'rm10', align: 'center'},
                {text: 'RM 5',                  width: '100px',  value: 'rm5', align: 'center'},
                {text: 'RM 1',                  width: '100px',  value: 'rm1', align: 'center'},
                {text: 'RM 0.50',               width: '100px',  value: 'sen50', align: 'center'},
                {text: 'RM 0.20',               width: '100px',  value: 'sen20', align: 'center'},
                {text: 'RM 0.10',               width: '100px',  value: 'sen10', align: 'center'},
                {text: 'RM 0.05',               width: '100px',  value: 'sen5', align: 'center'},
                {text: 'RM 0.01',               width: '100px',  value: 'sen1', align: 'center'},
            ],

            tableOpt: {
                itemsPerPage: -1
            },

            dataItems: [],

            dateYear: null,
            dateMonth: null,

            showID: false,
            showDate: false,

            total: this.$t('bankpaymentlistingreport.total'),
            totalCountRm100: 0,
            totalCountRm50: 0,
            totalCountRm20: 0,
            totalCountRm10: 0,
            totalCountRm5: 0,
            totalCountRm1: 0,

            totalCountSen50: 0,
            totalCountSen20: 0,
            totalCountSen10: 0,
            totalCountSen5: 0,
            totalCountSen1: 0,

            goBtn: false,
        };
    },

    methods: {

        backButton(){
            this.$router.push({name: 'parametercashdenomination'});
        },

        loadStaffCash() {

            this.goBtn = true;
            
            const vm = this;

            this.$store.dispatch('post', {
                url: `/postCashDenomination/${this.$store.getters.userId}`,  
                payload: this.data
            })
            .then(function (res) {
                
                vm.dataItems = res.data.rec;
          
                    vm.dateYear = vm.dataItems[0].poyr
                    vm.dateMonth = vm.dataItems[0].pomth
                    
                for (let i = 0; i < vm.dataItems.length; i++) {

                    vm.dataItems[i].salhdid = i + 1;

                    }

                    vm.cashDenom();
                    vm.cashDenomSen();
                    vm.format();
                    
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        cashDenom(){

            
            var value = [100, 50, 20, 10, 5, 1];  // the denomination you want to find.
            
            var count = 0, amount = 0

            const vm = this

            for (let i = 0; i < vm.dataItems.length; i++) {

                // vm.dataItems[i].amount is the netpay from salhd
                amount = vm.dataItems[i].amount
                
                
                for(var j = 0; j < value.length; j++){

                    count = amount/value[j]
                    
                    if(count >= 1){
                        
                        if(value[j] == 100){
                            
                            this.totalCountRm100 += Math.floor(count)
                            vm.dataItems[i].rm100 = Math.floor(count)
                            

                        } else if(value[j] == 50){

                            this.totalCountRm50 += Math.floor(count)
                            vm.dataItems[i].rm50 = Math.floor(count)

                        } else if(value[j] == 20){

                            this.totalCountRm20 += Math.floor(count)
                            vm.dataItems[i].rm20 = Math.floor(count)

                        } else if(value[j] == 10){

                            this.totalCountRm10 += Math.floor(count)
                            vm.dataItems[i].rm10 = Math.floor(count)

                        } else if(value[j] == 5){

                            this.totalCountRm5 += Math.floor(count)
                            vm.dataItems[i].rm5 = Math.floor(count)
                            
                        } else{

                            this.totalCountRm1 += Math.floor(count)
                            vm.dataItems[i].rm1 = Math.floor(count)

                            }
                    }

                    amount = count * value[j]
                    amount = amount % value[j]

                }
                    
                }

        },

        cashDenomSen(){

            var value = [50, 20, 10, 5, 1];  // the denomination you want to find.
            
            var count = 0, amount = 0;

            const vm = this

            for (let i = 0; i < vm.dataItems.length; i++) {

                // vm.dataItems[i].amount is the netpay from salhd
                amount = vm.dataItems[i].amount - Math.floor(vm.dataItems[i].amount)
                amount = parseFloat(amount.toFixed(2))
                amount = amount * 100

                for(var j = 0; j < value.length; j++){

                    count = amount/value[j]
                    
                    if(count >= 1){
                        
                        if(value[j] == 50){
                            
                            this.totalCountSen50 += Math.floor(count)
                            vm.dataItems[i].sen50 = Math.floor(count)

                        } else if(value[j] == 20){

                            this.totalCountSen20 += Math.floor(count)
                            vm.dataItems[i].sen20 = Math.floor(count)

                        } else if(value[j] == 10){

                            this.totalCountSen10 += Math.floor(count)
                            vm.dataItems[i].sen10 = Math.floor(count)

                        } else if(value[j] == 5){

                            this.totalCountSen5 += Math.floor(count)
                            vm.dataItems[i].sen5 = Math.floor(count)

                        } else{

                            this.totalCountSen1 += Math.floor(count)
                            vm.dataItems[i].sen1 = Math.floor(count)

                        }
                    }

                    amount = count * value[j]
                    amount = amount % value[j]

                }
                    
                }

                

        },

        printPDF() {

            const doc = new jsPDF({
                orientation: 'landscape'
            })

            var head = [
                { title: this.$t('columns.no'),                      dataKey: 'salhdid' },
                { title: this.$t('columns.company'),                 dataKey: 'compnycode' },
                { title: this.$t('columns.name'),                    dataKey: 'psnname' },
                { title: this.$t('columns.staffid'),              dataKey: 'staffcode' },
                { title: this.$t('previewpayroll.netpay'),                 dataKey: 'amount' },
                { title: 'RM 100',                  dataKey: 'rm100' },
                { title: 'RM 50',                   dataKey: 'rm50' },
                { title: 'RM 20',                   dataKey: 'rm20' },
                { title: 'RM 10',                   dataKey: 'rm10' },
                { title: 'RM 5',                    dataKey: 'rm5' },
                { title: 'RM 1',                    dataKey: 'rm1' },
                { title: 'RM 0.50',                 dataKey: 'sen50' },
                { title: 'RM 0.20',                 dataKey: 'sen20' },
                { title: 'RM 0.10',                 dataKey: 'sen10' },
                { title: 'RM 0.5',                  dataKey: 'sen5' },
                { title: 'RM 0.1',                  dataKey: 'sen1' }
            ]

            let body = []

            for (var i = 0; i < this.dataItems.length; i++) {

                body.push({
                    salhdid : this.dataItems[i].salhdid,
                    compnycode : this.dataItems[i].compnycode,
                    psnname : this.dataItems[i].psnname,
                    staffcode : this.dataItems[i].staffcode,
                    amount : this.dataItems[i].amount,
                    rm100 : this.dataItems[i].rm100,
                    rm50 : this.dataItems[i].rm50,
                    rm20 : this.dataItems[i].rm20,
                    rm10 : this.dataItems[i].rm10,
                    rm5 : this.dataItems[i].rm5,
                    rm1 : this.dataItems[i].rm1,
                    sen50: this.dataItems[i].sen50,
                    sen20: this.dataItems[i].sen20,
                    sen10: this.dataItems[i].sen10,
                    sen5: this.dataItems[i].sen5,
                    sen1: this.dataItems[i].sen1,
                })

            }

            body.push({
                    amount: "Total",
                    rm100 : this.totalCountRm100,
                    rm50 : this.totalCountRm50,
                    rm20 : this.totalCountRm20,
                    rm10 : this.totalCountRm10,
                    rm5 : this.totalCountRm5,
                    rm1 : this.totalCountRm1,
                    sen50 : this.totalCountSen50,
                    sen20 : this.totalCountSen20,
                    sen10 : this.totalCountSen10,
                    sen5 : this.totalCountSen5,
                    sen1 : this.totalCountSen1,
                })
            
            doc.text(this.$t('cashdenominationlistingreport.cashdenominationlisting'), 10, 10)
            
            // if(this.formData.payoutcode){
            //     doc.text('Payout ID', 74, 10)
            //     doc.text(this.formData.payoutcode.toString(), 100, 10)
            // } else {

            //     doc.text('Payout Year', 74, 10)
            //     doc.text(this.formData.pyyear.toString(), 107 , 10)
            //     doc.text('Payout Month', 122, 10)
            //     doc.text(this.formData.pymonth.toString(), 158, 10)
            // }

            doc.autoTable(head, body)
            doc.save('Cash Denomination Listing.pdf')
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', 'Cash Denomination.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let item = this.dataItems
            let data = []


            for (var i = 0; i < item.length; i++) {

                data.push({
                    [`${this.$t('columns.no')}`]: item[i].salhdid,
                    [`${this.$t('columns.company')}`]: item[i].compnycode,
                    [`${this.$t('columns.name')}`]: item[i].psnname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffcode,
                    [`${this.$t('previewpayroll.netpay')}`]: item[i].amount,
                    'RM 100': item[i].rm100,
                    'RM 50': item[i].rm50,
                    'RM 20': item[i].rm20,
                    'RM 10': item[i].rm10,
                    'RM 5': item[i].rm5,
                    'RM 1': item[i].rm1,
                    'RM 0.50': item[i].sen50,
                    'RM 0.20': item[i].sen20,
                    'RM 0.10': item[i].sen10,
                    'RM 0.05': item[i].sen5,
                    'RM 0.01': item[i].sen1
                })
            }

            data.push({
                    [`${this.$t('columns.no')}`]: '',
                    [`${this.$t('columns.company')}`]: '',
                    [`${this.$t('columns.name')}`]: '',
                    [`${this.$t('columns.staffid')}`]: '',
                    [`${this.$t('previewpayroll.netpay')}`]: 'Total',
                    'RM 100': this.totalCountRm100,
                    'RM 50': this.totalCountSen50,
                    'RM 20': this.totalCountRm20,
                    'RM 10': this.totalCountRm10,
                    'RM 5': this.totalCountRm10,
                    'RM 1': this.totalCountRm1,

                    'RM 0.50': this.totalCountSen50,
                    'RM 0.20': this.totalCountSen20,
                    'RM 0.10': this.totalCountSen10,
                    'RM 0.05': this.totalCountSen5,
                    'RM 0.01': this.totalCountSen1,
                })

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        format() {
            console.log("Format",this.dataItems.length)
            for (let i = 0; i < this.dataItems.length; i++) {
                if (this.dataItems[i].amount !== null) {
                    let getSubTotalPos = this.dataItems[i].amount.toFixed(2);
                    this.dataItems[i].amount = this.CommaFormatted(getSubTotalPos);
                }
            }
        },

        CommaFormatted(amount) {
            var delimiter = ","; // replace comma if desired
            var a = amount.split('.',2)
            var d = a[1];
            var i = parseInt(a[0]);
            if(isNaN(i)) { return ''; }
            var minus = '';
            if(i < 0) { minus = '-'; }
            i = Math.abs(i);
            var n = new String(i);
            var b = [];
            while(n.length > 3) {
                var nn = n.substr(n.length-3);
                b.unshift(nn);
                n = n.substr(0,n.length-3);
            }
            if(n.length > 0) { b.unshift(n); }
            n = b.join(delimiter);
            if(d.length < 1) { amount = n; }
            else { amount = n + '.' + d; }
            amount = minus + amount;
            return amount;
        },

    },

    

    mounted() {
        
    },

    created(){
        const vm = this;
        vm.formData = this.$route.params.formData;
        console.log("formdata received" , vm.formData)

        if(vm.formData.payoutcode){
            this.showID = true;
        } else {
            this.showDate = true;
        }

        this.loadStaffCash();       
        
    }
    
}
</script>

<style>

.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}

.scroller {
  height: 100%;
}

.total {
    background-color: #FFF3E0;
}
</style>

                        