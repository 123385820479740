<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Affect Item"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.affectitemcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                label="Description"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.affectitemdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                label="Effective Until"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                v-model="formData.ishidden"
                                label="Hidden"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                label="Remark"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                label="Processing Logic"
                                type="text"
                                v-model="formData.logicscrpt"
                                @input="updateValue"
                                rows=20
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';

//import GenericForm from '../../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
                affectitemcode: this.parentObject.data.affectitemcode,
                affectitemdesc: this.parentObject.data.affectitemdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                affectvarcode: this.parentObject.data.affectvarcode,
                ishidden: this.parentObject.data.ishidden,
                remark: this.parentObject.data.remark,
                logicscrpt: this.parentObject.data.logicscrpt,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            affectVars: [],
            affectVarList: [],
            affectVarCode: '',
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        updateValue() {
            this.$emit('input', {
                affectitemcode: this.formData.affectitemcode,
                affectitemdesc: this.formData.affectitemdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                affectvarcode: this.formData.affectvarcode,
                ishidden: this.formData.ishidden,
                remark: this.formData.remark,
                logicscrpt: this.formData.logicscrpt,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'affectitemlist', params: { search: this.search}});
        },
    },

    mounted() {
    }
}
</script>