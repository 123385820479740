<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
//import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('trainingsetup.targettraininghourslisting'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('trainingsetup.startyear'), value: "startyear", width: "200px" },
        { text: this.$t('setupclaim.startmonth'), value: "startmonth", width: "200px" },
        { text: this.$t('claimutilizationtrendanalysis.untilyear'), value: "endyear", width: "200px" },
        { text: this.$t('trainingsetup.untilmonth'), value: "endmonth", width: "200px" },
        { text: this.$t('trainingsetup.targethours'), value: "minhours", width: "200px" },
        { text: this.$t('trainingsetup.trainingtargetgroup'), value: "trntargetgrpdesc", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getTargetTrainingHoursHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/targettraininghours/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          // for (let i = 0; i < vm.dataItems.length; i++) {
          //     if (vm.dataItems[i].startdate) {
          //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
          //       }

          //     if (vm.dataItems[i].enddate) {
          //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
          //       }
          // }

          vm.loading = !vm.loading;

        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item){
      this.$router.push({ name: "targettraininghoursedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "targettraininghoursnew" });
    },
  },

  mounted() {
    this.getTargetTrainingHoursHandler();
  },
};
</script>