<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="8" sm="8" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('generateunpaidleaverecord.leavetype')"
                                type="text"
                                ref="parentLeave"
                                :items="leaveTypeList"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.leavetypeid"
                                @input="updateValue"
                                @change="changeLeaveTypeHandler(formData.leavetypeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4">
                            <v-text-field
                                readonly
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="leaveCode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row v-else>
                        <v-col cols="8" sm="8" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('generateunpaidleaverecord.leavetype')"
                                type="text"
                                ref="parentLeave"
                                :items="leaveTypeList"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.leavetypeid"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4">
                            <v-text-field
                                readonly
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="leaveCode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.payitem')"
                                type="text"
                                ref="parentLeave"
                                :items="payItemList"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.payitemid"
                                @input="updateValue"
                                @change="changePayitemHandler(formData.payitemid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                readonly
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="itemCode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
        <div v-if="isErr">
          <app-error
            v-bind:parentError="{
              data: this.errMsg,
              isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
          />
        </div>
    </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {
                leavetypeid: this.parentObject.data.leavetypeid,
                payitemid: this.parentObject.data.payitemid,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            leaveCode: null,
            itemCode: null,

            leaveTypeList: [],
            payItemList: [],

            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadLeaveTypeHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/leavesetup/all'})
                .then(res => {

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.leaveTypeList.push(
                            {
                                text: res.data.rec[i].leavedesc,
                                value: res.data.rec[i].id,
                                code: res.data.rec[i].leavecode
                            }
                        )
                    } 

                    if (vm.formData.leavetypeid) {
                        vm.changeLeaveTypeHandler(vm.formData.leavetypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPayitemHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payitem/codes'})
                .then(res => {

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemList.push(
                            {
                                text: res.data.rec[i].payitemdesc,
                                value: res.data.rec[i].id,
                                code: res.data.rec[i].payitemcode
                            }
                        )

                    }

                    if (vm.formData.payitemid) {
                        vm.changePayitemHandler(vm.formData.payitemid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeLeaveTypeHandler(id) {
            let item = this.leaveTypeList.find(item => item.value === id);

            this.leaveCode = item.code;
        },

        changePayitemHandler(id) {
            let item = this.payItemList.find(item => item.value === id);

            this.itemCode = item.code;
        },


        updateValue() {
            this.$emit('input', {
                leavetypeid: this.formData.leavetypeid,
                payitemid: this.formData.payitemid,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            if (this.$refs.form.validate()) this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        
        cancelHandler() {

          this.$router.push({ name: "leavetopayrollpostingsetuplist", params: { search: this.search} });
   
        },
    },

    mounted() {
        this.loadLeaveTypeHandler();
        this.loadPayitemHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
    
}
</script>