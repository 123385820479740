<template>

    <div id="StaffListEvent">

    <loading
    :active.sync="loading"
    :can-cancel="false"
    :is-full-page="true"
    :height="150"
    :width="150"
    :color="'#FFFF00'"
    :background-color="'#000000'"
    :opacity="0.5"
    ></loading>

    <v-dialog v-model="this.subDialogStaffList" max-width="500px">

      <v-card>

        <v-card-actions class="pt-3 pb-6">
            <v-btn color="primary" @click="$emit('backAction')">{{$t('button.back')}}</v-btn>
        </v-card-actions>

        <v-card-text class="mb-n2"><b>{{$t('selfservicemytask.selanostaff')}}</b></v-card-text>

        <v-card-text>
          <v-text-field @input="setFilteredPersons($event)" :label="this.$t('reportstaffenquiry.search')" append-icon="search"></v-text-field>
          
          <v-list>
            <v-list-item v-for="item in filterData" :key="item.id">
              <v-list-item-content>
                <p>{{ item.staffcode }} - {{ item.psnname }}</p>
              </v-list-item-content>
              <v-checkbox v-model="selectedItem" :value="item" @change="uncheckOther"></v-checkbox>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions>
            <v-btn class="mx-auto mb-3" color="success" @click="confirm(filterData)">{{$t('stafflistmobile.confirm')}}</v-btn>
        </v-card-actions>
        
        
      </v-card>
      
      <app-alert-error
        v-bind:errorDialog="errorDialog"
        v-bind:errorMessage="errorMessage"
        v-on:OkPressed="dismissHandler"
      />

    </v-dialog>

  </div>

</template>

<script>

import Loading from "vue-loading-overlay";
import moment from 'moment';
import ErrorMessageSelService from "./ErrorMessage.vue";

export default{

    components: {
        Loading,
        "app-alert-error": ErrorMessageSelService
    },

    props:{
        subDialogStaffList: Boolean,
        subDialogStaffListParam: [Array, Object]
    },

    data(){
        return{

            loading: false,
            staffList: [],
            filterData: [],
            selectedItem: null,

            errorDialog: false,
            errorMessage: ''
        }
    },

    methods:{

        // Error Message Popup Dismiss
        dismissHandler(){
            this.errorDialog = false
            this.errorMessage = ''
        },

        // Checkbox limit only one can be select
        uncheckOther(){
            this.staffList = this.staffList.map(item => {
                item.active = item === this.selectedItem
                return item;
            });
        },

        loadStaffList(){
            const vm = this;

            vm.loading = true;
            this.$store.dispatch('get', {url: "/staff/alls"})
            .then((res) => {
                const result = res.data;

                if (result.status === 200) {
                    // FIlter active staff
                    let staffHolder = result.rec.filter(val => val.cessadate > moment().format("YYYY-MM-DD"))
                    let stafflistholder = staffHolder.filter(val => val.psnid != this.$store.getters.psnId)

                    for (let i = 0; i < stafflistholder.length; i++) {
                        stafflistholder[i].store = stafflistholder[i].staffcode + ' ' + stafflistholder[i].psnname;
                    }

                    vm.filterData = stafflistholder
                    vm.staffList = stafflistholder

                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        setFilteredPersons(ev){
            let val = ev;
            if (val && val.trim() !== '') {
                this.filterData = this.staffList.filter((event) => {
                    return event.store.toLowerCase().indexOf(val.toLowerCase()) > -1;
                });
            } else {
                this.filterData = this.staffList
            }
        },

        confirm(value){
            
            // Filter only active / choose staff for approval
            let choosenPerson = value.filter(val => (val.active == true));

            if(choosenPerson.length > 0 ){
                this.$emit('choosenPerson', choosenPerson[0].id, this.subDialogStaffListParam)
            } else {
                this.errorDialog = true,
                this.errorMessage = this.$t('selfservicemytask.selatleastoneapproveperson')
                // console.error(this.$t('selfservicemytask.selatleastoneapproveperson'))
            }

        }

    },

    mounted(){
        this.loadStaffList();
    }
}

</script>
