<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }} <span class="page_title_value">{{ this.payoutctrls.payoutdesc }}</span></h2>
                </v-row>

                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="12" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                v-model="payoutctrls.payoutcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                v-model="payoutctrls.payoutdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.year')"
                                type="number"
                                v-model="payoutctrls.pyyear"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.month')"
                                type="number"
                                v-model="payoutctrls.pymonth"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-toolbar-title class="subtitle-2">{{$t('payeeinputprocess.expand')}}</v-toolbar-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>

                            <v-row class="pt-4">
                                <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstartdate')"
                                        type="date"
                                        v-model="payoutctrls.startdate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutenddate')"
                                        type="date"
                                        v-model="payoutctrls.enddate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstatus')"
                                        readonly
                                        type="text"
                                        v-model="payoutctrls.pystatusdesc"
                                        dense
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                    <input type="text" hidden v-model="payoutctrls.pystatuscode" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('setuppayrolltype.payrolltype')"
                                        type="text"
                                        v-model="payoutctrls.payrolltypedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payrollgroup')"
                                        type="text"
                                        v-model="payoutctrls.grouppdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.exchangerate')"
                                        type="text"
                                        v-model="payoutctrls.exchratedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutfrequency')"
                                        type="text"
                                        v-model="payoutctrls.salfreqdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payeelist')"
                                        type="text"
                                        v-model="payoutctrls.payeelistdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-toolbar
                        color="#ECEFF1" class="mt-8"
                    >
                        <h2 style=margin-left:10px;>{{ titleSummary }}</h2>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn text
                                class="mr-5"
                                @click="printPDFSummary"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                            <v-btn text
                                class="mr-5"
                                @click="getCSVSummary"
                            >
                                <v-icon>mdi-export</v-icon>
                            </v-btn>
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                style="width: 250px"
                                class="pr-5 pt-3"
                                hide-details>
                            </v-text-field>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-row>
                        <v-col class="mt-6 ml-2 mb-n6">
                            <h2 class="pb-5">{{$t('previewexception.netpaytotal')}} :<span class="page_title_value"> {{ this.totalzeronetpay }}</span></h2>
                        </v-col>
                        <v-col class="mt-6 ml-2 mb-n6">
                            <h2 class="pb-5">{{$t('previewexception.netpaylesszero')}} :<span class="page_title_value"> {{ this.totalnetpay }}</span></h2>
                        </v-col>
                        <v-col class="mt-6 ml-2 mb-n6">
                            <h2 class="pb-5"> {{$t('previewpayroll.recordscount')}} :<span class="page_title_value"> {{ this.totalperson }}</span></h2>
                        </v-col>
                    </v-row>
                    

                    <v-row>
                        <v-col col="12" sm="12">
                            <v-data-table
                             hide-default-footer
                                :headers="tabHeaderSummary"
                                class="font-weight-light;text--secondary;"
                                :items="summaryList"
                                :items-per-page="1000"
                                fixed-header
                                multi-sort
                                :sort-by="['code']"
                                :search="search"
                                
                                >
                                <!-- <template v-slot:top> -->
                                    <!-- <v-toolbar color="#ECEFF1" flat>
                                        <v-toolbar-title class="subtitle-1">{{titleSummary}}</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-col cols="1" sm="1" class="ml-n4">
                                            <div style="width: 50%; max-height: 50%" >
                                                <v-img @click="printPDFSummary" :src="`${publicPath}images/export_pdf.png`"/>
                                            </div>
                                        </v-col>
                                        <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                                            <div style="width: 50%">
                                                <v-img @click="getCSVSummary" :src="`${publicPath}images/export_csv.png`"/>
                                            </div>
                                        </v-col>
                                    </v-toolbar> -->
                                <!-- </template> -->
                                <!-- <template v-slot:body>
                                        <tbody>
                                            <tr v-for="item in summaryList" :key="item.id">
                                                <td >{{item.staffcode}} </td>
                                                <td @click="goDetailHandler(item.id)">{{item.psnname}} </td>
                                                <td >{{item.grosspay}} </td>
                                                <td >{{item.netpay}} </td>
                                                <td >{{item.pycmdstatusdesc}} </td>
                                                <td >{{item.pycalcstatusdesc}} </td>
                                            </tr>
                                        </tbody>
                                    </template> -->
                                <!-- <template v-slot:items >
                                </template> -->
                                <template v-slot:body="{items}">
                                    <tbody>
                                        <tr v-for="item in items" :key="item.id">
                                            <td @click="goDetailHandler(item.staffid, item.salhdid)">{{item.staffcode}} </td>
                                            <td @click="goDetailHandler(item.staffid, item.salhdid)">{{item.psnname}} </td>
                                            <td >{{item.grosspay}} </td>
                                            <td >{{item.netpay}} </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-container>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: this.$t('previewexception.payrollexceptionpayout'),
            pageTitleValue: '',
            formData: {
                pystatuscode: null
            },
            tabHeaderSummary: [
                { text: this.$t('columns.staffid'), value: 'staffcode', width: '100px', sortable: true},
                { text: this.$t('columns.name'), value: 'psnname', width: '200px', sortable: true },
                { text: this.$t('previewpayroll.grosspay'), value: 'grosspay', sortable: true, width: '100px' },
                { text: this.$t('previewpayroll.netpay'), value: 'netpay', width: '100px', sortable: true},
           ],
            
            tableOpt: {
                itemsPerPage: 10
            },

            data: {
                formData: {
                    poid: this.$route.params.id,
                },
            },

            payoutctrls: {
                allowinput: null,
                enddate: null,
                exchratecode: null,
                exchratedesc: null,
                exchrateid: null,
                grouppbid: null,
                grouppcode: null,
                grouppdesc: null,
                id: null,
                payeelistcode: null,
                payeelistdesc: null,
                payoutcode: null,
                payoutdesc: null,
                payrolltypecode: null,
                payrolltypedesc: null,
                payrolltypeid: null,
                pymonth: null,
                pystatuscode: null,
                pystatusdesc: null,
                pyyear: null,
                rectimestamp: null,
                remark: null,
                salfreqcode: null,
                salfreqdesc: null,
                salfreqid: null,
                ssrel: null,
                startdate: null,
                updatedby: null,
                valvaluecode: null,
                valvaluedesc: null,
                valvalueid: null
            },
            staff: [],
            payoutctrlList: [],
            summaryList: [],
            detailList: [],
            detailItemList: [],
            titleSummary: this.$t('previewpayroll.summary'),
            titleDetail: this.$t('payeeinputprocess.details'),
            
            search: '',
            errMsg: '',

            totalnetpay: 0,
            totalzeronetpay: 0,
            totalperson: 0,

            isErr: false,
            editMode: false
        }
    },

    methods: {
        
        loadPayrollPreviewSummary() {
            const vm = this;

            this.$store.dispatch('post', {
                url: `/payrollexception/summary/${this.$store.getters.userId}`, 
                payload: this.data
                })
            .then(function (res) {
                let payrollpreviewHolder = res.data.rec
                let netpayHolder = []
                let grosspayHolder = []
                let netzeropayHolder = []
                let personHolder = 0

                console.log('payrollexception', payrollpreviewHolder)

                for (let i = 0; i < payrollpreviewHolder.length; i++) {
                   // netpayHolder.push(parseFloat(payrollpreviewHolder[i].netpay))
                    grosspayHolder.push(parseFloat(payrollpreviewHolder[i].grosspay))
                    personHolder++

                    if(payrollpreviewHolder[i].netpay < 0){
                          netpayHolder.push(payrollpreviewHolder[i])
                  
                    }

                    if(payrollpreviewHolder[i].netpay == 0){
                          netzeropayHolder.push(payrollpreviewHolder[i])
                    }
                }

                
                vm.summaryList = payrollpreviewHolder
                vm.totalnetpay = netpayHolder.length
                vm.totalzeronetpay = netzeropayHolder.length
                vm.totalperson = personHolder

                console.log('netzero', netzeropayHolder.lengtth)

            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadPayoutListHandler() {
            const vm = this;

            if (this.data.formData.poid) {
                this.$store.dispatch('get', {
                    url: '/payoutctrl/' + this.data.formData.poid
                })
                .then (res => {

                    vm.payoutctrls = res.data.rec[0]

                    vm.payoutctrls.startdate = moment(vm.payoutctrls.startdate).format('YYYY-MM-DD');
                        
                    vm.payoutctrls.enddate = moment(vm.payoutctrls.enddate).format('YYYY-MM-DD');
                        
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                })
            }
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data, title) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSVSummary() {
            let itemSummary = this.summaryList

            let dataSummary = []
            
            for (var i = 0; i < itemSummary.length; i++) {
              
                dataSummary.push({
                    [`${this.$t('columns.staffid')}`]: itemSummary[i].staffcode,
                    [`${this.$t('columns.name')}`]: itemSummary[i].psnname,
                    [`${this.$t('previewpayroll.grosspay')}`]: itemSummary[i].grosspay,
                    [`${this.$t('previewpayroll.netpay')}`]: itemSummary[i].netpay
                })
            }


            const csvData = this.objectToCSV(dataSummary)
            this.download(csvData, this.titleSummary)
        },

        printPDFSummary() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.summaryList

            var head = [                
                { title: this.$t('columns.staffid'), dataKey: 'staffcode' },
                { title: this.$t('columns.name'), dataKey: 'psnname' },
                { title: this.$t('previewpayroll.grosspay'), dataKey: 'grosspay' },
                { title: this.$t('previewpayroll.netpay'), dataKey: 'netpay' }
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    staffcode: item[i].staffcode,
                    psnname: item[i].psnname,
                    grosspay: item[i].grosspay,
                    netpay: item[i].netpay
                })
            }
            doc.text(this.titleSummary, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.titleSummary + '.pdf')
        },

        goDetailHandler(staffid, id) {
            let formData = this.data.formData
            //let param = this.data.parameters
            
            this.$router.push({name: 'payrollexceptiondetail', params: { formData, staffid, id}});
        },
        
        backHandler() {
            this.$router.push({name: 'payrollexceptionfilter'});
        },

    },

    mounted() {
        this.loadPayoutListHandler();
        this.loadPayrollPreviewSummary();
    }
}
</script>