<template>
    <div>
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="150"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
        <v-container  fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
            <v-form>
                <v-row>
                    <div v-if="hidetable" class="pt-5 mt-n8 mb-6">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    v-model="goBtn"
                                    color="warning" 
                                    style="width: 100px"
                                    @click="backHandler" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="!hidetable" class="pt-5 mt-n8 mb-6">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    v-model="goBtn"
                                    color="warning" 
                                    style="width: 100px"
                                    @click="backHandlerTable" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
                

                <v-container
                    fluid
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitleGraph }} 
                        <span class="page_title_value">{{ this.pageTitleStartDate }}</span> {{$t('columns.to')}} 
                        <span class="page_title_value">{{ this.pageTitleEndDate }}</span>
                    </h2>
                </v-row>
                <v-row class="mb-8">
                        <v-col cols="1">
                            {{$t('reportsparam.filter')}}
                        </v-col>
                        <v-col class="page_title_value">
                            <div v-if="emptypedescholder"> {{ this.emptypedescholder }} </div>
                            <div v-if="compnynameholder"> {{ this.compnynameholder }} </div>
                            <div v-if="brchnameholder"> {{ this.brchnameholder }} </div>
                            <div v-if="deptnameholder"> {{ this.deptnameholder }} </div>
                            <div v-if="valuedescholder"> {{ this.valuedescholder }} </div>
                        </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card elevation="5" outlined>
                            <div v-if="!loading">
                                <app-charts  
                                    id="chart" 
                                    ref="realtimeChart" 
                                    type="bar" 
                                    width="100%" 
                                    :options="baroptions" 
                                    :series="barseries">
                                </app-charts>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-card  v-if="hidetable" elevation="5" outlined>
                            <div v-if="!loading">
                                <app-charts 
                                    ref= "linegraph" 
                                    type="line" 
                                    width="100%" 
                                   
                                    :options="lineoptions" 
                                    :series="lineseries">
                                </app-charts>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card  v-if="hidetable" elevation="5" outlined>
                            <div v-if="!loading">
                                <app-charts 
                                    ref="piechart" 
                                    type="pie" 
                                    width= "100%" 
                                    :options="donutoptions" 
                                    :series="donutseries">
                                </app-charts>
                            </div>
                        </v-card>
                    </v-col>
                </v-row> 

                <div class="row" v-if="!hidetable">
                    <div class="col-sm-12">
                        <v-toolbar>
                            <v-toolbar-title style="height: 50px; float: left; margin-top: 10px; padding-left: 20px" class="textBlue">
                                {{ title }} <span class="page_title_value">{{ this.pageTitleValue }}</span>
                            </v-toolbar-title>
                        
                            <v-spacer></v-spacer>
                            <v-avatar
                                class="mr-5"
                                size="40"
                                tile>
                                <img
                                    :src="`${publicPath}images/export_pdf.png`"
                                    @click="printPDF"
                                    style="height: 100%; float: right;"
                                >
                                </v-avatar>

                                <v-avatar
                                size="40"
                                tile>
                                <img
                                    :src="`${publicPath}images/export_csv.png`"
                                    @click="getCSV"
                                    style="height: 100%; float: right;"
                                >
                            </v-avatar>
                        </v-toolbar>
                    </div>
                </div>

                <div class="row" v-if="!hidetable">
                    <div class="col-sm-12 mt-n8">

                        <v-data-table
                            :headers="headers"
                            :items="staffdetails"
                            class="elavation-1 fixed header"
                            fixed-header
                            style="margin-top: 20px"
                        >
                            <template slot="items" slot-scope="props">
                                
                                <td class="text-xs-left">{{ props.item.psnname }}</td>
                                <td class="text-xs-left">{{ props.item.staffcode }}</td>
                                <td class="text-xs-left">{{ props.item.compnyname }}</td>
                                <td class="text-xs-left">{{ props.item.leavedesc }}</td>
                                <td class="text-xs-left">{{ props.item.startdate }}</td>
                                <td class="text-xs-left">{{ props.item.enddate }}</td>
                                <td class="text-xs-left">{{ props.item.lvdays }}</td>
                                <td class="text-xs-left">{{ props.item.startdatetime }}</td>
                                <td class="text-xs-left">{{ props.item.enddatetime }}</td>
                                <td class="text-xs-left">{{props.item.reasondesc}} </td>
                                <td class="text-xs-left">{{ props.item.posdesc  }}</td>
                                <td class="text-xs-center">{{ props.item.jobtitle  }}</td>
                                <td class="text-xs-center">{{ props.item.deptname  }}</td>
                                <td class="text-xs-left">{{ props.item.brchname }}</td>
                            </template>
                            <template slot="pageText" slot-scope="props">
                                {{$t('vdatatable.rowsperpage')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('reportsparam.of')}} {{ props.itemsLength }} 
                            </template>
                        </v-data-table>
                    </div>
                </div>
                </v-container>

            </v-form>
        </v-container>
        <!-- <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div> -->
        <div class="text-center" v-if="isErr">
        <v-dialog
            v-model="isErr"
            persistent
            width="500"
        >
            <v-card>
                <v-card-title
                    class="headline red white"
                    error-title
                >
                <span style="color: white">{{$t('vdatatable.error')}}</span>
                </v-card-title>

                <v-card-text v-html="errMsg">
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="onClosePress"
                    >
                    {{$t('vdatatable.ok')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable' 
import moment from "moment";
//import ErrorDialog from "../../../components/common/ErrorDialog";
import Loading from "vue-loading-overlay";


export default {
    components: {
        'app-charts': VueApexCharts,
        //"app-error": ErrorDialog,
        Loading
    },

    data() {

        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            data: {
                formData: {
                    startdate: this.$route.params.startdate,
                    enddate: this.$route.params.enddate,
                    userid: this.$store.getters.userId,
                },
                parameters: {
                    brchid: this.$route.params.parameters.branchid,
                    compnyid: this.$route.params.parameters.companyid,
                    deptid: this.$route.params.parameters.departmentid,
                    emptypeid: this.$route.params.parameters.employmenttypeid,
                    valvalueid: this.$route.params.parameters.valvalueid,
                },
            },
            parameter: null,

            baroptions: {
                chart: {
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    id: 'barchart',
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                            this.leaveTable(config.dataPointIndex)
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('generateunpaidleaverecord.leavetype') 
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('reportleavedashboard.numberofdays') 
                    }
                },
                noData: {
                    text: this.$t('vdatatable.nodataavailable'), 
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                title: {
                    text: this.$t('reportleavedashboard.leavetakenanalysis'), 
                    align: 'center',
                },
                subtitle: {
                    text: this.$t('reportleavedashboard.clickatrespectivebarviewleave'), 
                    align: 'center',
                    margin: -10,
                    // offsetX: 0,
                    // offsetY: 0,
                    // floating: false,
                    style: {
                        fontSize:  '12px',
                        fontWeight:  'normal',
                        fontFamily:  undefined,
                        color:  '#9699a2'
                    },
                }
            },
            barseries: [{
                name: this.$t('reportleavedashboard.leavedays'), 
                data: []
            }],

            lineoptions: {
                chart: {
                    id: 'linechart',
                    height: 450,
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    defaultLocale: this.$route.params.lang,
                    type: 'line',
                },
                xaxis: {
                    categories: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
                    title: {
                        text: this.$t('reportleavedashboard.leavedays') 
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('reportleavedashboard.numberofdays') 
                    },
                    ticks: {
                    stepSize: 100,
                    min: 0,
                    max: 100000,
                    tickAmount: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                markers: {
                    size: 1
                },
                noData: {
                    text: this.$t('vdatatable.nodataavailable'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                title: {
                    text: this.$t('reportleavedashboard.leavepatternbyday') , 
                    align: 'center',
                }
            },
            lineseries: [],

            donutseries: [],
            donutoptions: {
                chart : {
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    defaultLocale: this.$route.params.lang,
                },
                labels: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
                noData: {
                    text: this.$t('vdatatable.nodataavailable'), 
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                title: {
                    text: this.$t('reportleavedashboard.leavedaydistribution'), 
                    align: 'center'
                }
            },

            //table headers
            headers: [
                { text: this.$t('columns.name'), value: 'psnname', width: '200px' }, 
                { text: this.$t('columns.staffcode'), value: 'staffcode', width: '200px' },
                { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                { text: this.$t('generateunpaidleaverecord.leavetype'), value: 'leavedesc', width: '200px' },
                { text: this.$t('columns.startdate'), value: 'startdate', width: '200px' },
                { text: this.$t('columns.enddate'), value: 'enddate', width: '200px' },
                { text: this.$t('reportleavedashboard.leavedays'), value: 'lvdays', width: '200px' },
                { text: this.$t('reportprocessloglisting.starttime'), value: 'startdatetime', width: '200px' },
                { text: this.$t('reportprocessloglisting.endtime'), value: 'enddatetime', width: '200px' }, 
                { text: this.$t('recleaverec.leavereason'), value: 'reasondesc', width: '200px' },
                { text: this.$t('columns.position'), value: 'posdesc', width: '200px' },
                { text: this.$t('columns.job'), value: 'jobtitle', width: '200px' },
                { text: this.$t('columns.department'), value: 'deptname', width: '200px' },
                { text: this.$t('columns.branch'), value: 'brchname', width: '200px' },
            ],

            staffdetails: [],
            leavetypedata: [],
            leaveRecords: [],
            leaveRecordsFilter: [],
            totalStaffMov: [],

            bar_data_returned: [],
            line_data_returned: [],
            leavetypegroup: [],

            hidetable: true,
            goBtn: false,
            
            errMsg: '',
            leavetypeid: '',
            startdate: '',
            enddate: '',
            isErr: false,
            loading: true,
            getError: false,
            checkloading: 0,
            barchartitem: [],
            getErrmsg: [],
            bardata: [],
            emptypedescholder: null,
            compnynameholder: null,
            brchnameholder: null,
            deptnameholder: null,
            valuedescholder: null,

            //title for print to pdf 
            title: this.$t('reportleavedashboard.leavetakenfor') + " " + this.$route.params.startdate + this.$t('until') + this.$route.params.enddate, 
            pageTitleValue: '',
            pageTitleGraph: this.$t('reportleavedashboard.leavesummaryfor'), 
            pageTitleStartDate: this.$route.params.startdate,
            pageTitleEndDate: this.$route.params.enddate,
        }
    },

    
    methods: {

        filterParam(filter) {
            let query = {};
            for (const key in filter) {
                if (filter[key]) {
                    query[key] = filter[key];
                }
            }
            return query
        },

        filerData(data, query) {
            const filteredData = data.filter(item => {
                for (let key in query) {
                    if (item[key] === undefined || query[key] != item[key]) {
                        return false;
                    }
                }
                return true;
            });
            return filteredData;
        },

        employmentHandler() {
            const vm = this

            this.$store.dispatch('get', {url: "/emptype/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.emptypeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.emptypeid)[0]
                        vm.emptypedescholder = itemholder.emptypedesc
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        companyHandler() {
            const vm = this

            this.$store.dispatch('get', {url: "/company/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.compnyid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.compnyid)[0]
                        vm.compnynameholder = itemholder.compnyname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        branchHandler() {
            const vm = this

            this.$store.dispatch('get', {url: "/branch/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.brchid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.brchid)[0]
                        vm.brchnameholder = itemholder.brchname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        departmentHandler() {
            const vm = this

            this.$store.dispatch('get', {url: "/department/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.deptid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.deptid)[0]
                        vm.deptnameholder = itemholder.deptname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        valvalueHandler() {
            const vm = this

            this.$store.dispatch('get', {url: "/valvalue/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.valvalueid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.valvalueid)[0]
                        vm.valuedescholder = itemholder.valuedesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        loadLeaveType() {
            const vm = this;
            this.$store.dispatch('get', {url: "/leavetype/all"})
                .then(res => {
                    vm.leavetypedata = res.data.rec
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        loadStaffMov() {
            const vm = this;
            
            this.$store.dispatch('get', {url: `/staffmov/all/${this.$store.getters.userId}`})
                .then((res) => {
                    this.totalStaffMov = res.data.rec
                  //  this.loadChart();
                  this.commonChart();
                  
                  
                })
                .catch((err) => {
                    //req failure --> emit error
                    vm.isErrTrigger = true;
                    vm.errMsg = err;
                });
        },


        commonChart(){
           /// console.log('abc')
             const vm = this;
             vm.dataItems = []; 

            this.$store.dispatch('post', {url: `/leavedashboard/leavebar/${this.$store.getters.userId}`, payload: this.data})
            .then(res => {

            vm.dataItems = res.data.rec;
           // console.log('vm.dataItems', vm.dataItems)
            if(vm.dataItems.length > 0) {
            
            this.barChart(vm.dataItems)
            
            
            vm.loading = false; 
            } else {

                vm.isErr = true;
                vm.errMsg = this.$t('vdatatable.norecordsfound');
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                vm.loading = false;
            }
            
            this.commonChartNew();
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });


        },

        commonChartNew(){
           /// console.log('abc')
             const vm = this;
             vm.chartdataItems = []; 

            this.$store.dispatch('post', {url: `/leavedashboard/leaveline/${this.$store.getters.userId}`, payload: this.data})
            .then(res => {

            vm.chartdataItems = res.data.rec;
          //  console.log('vm.chartdataItems', vm.chartdataItems)
            if(vm.chartdataItems.length > 0) {
           // this.commonChartNew();
          //  this.barChart(vm.dataItems)
            this.lineLeave(vm.chartdataItems)
            this.donutLeave(vm.chartdataItems)
   
            vm.loading = false; 
            } else {

                vm.isErr = true;
                vm.errMsg = this.$t('vdatatable.norecordsfound');
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
           //      this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
           //      console.log(dialog);
           //      vm.loading = false;
            //    });

                vm.loading = false;
            }
            

            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });


        },


            barChart(item){
            const vm = this; 
            let leavenamenew = [];
            const element = [...new Set(item.map(item => item.leavetypeid))]
            //console.log(element)
              for (let i = 0; i < element.length; i++) {
                 let leavenameholder = vm.leavetypedata.filter(x => x.id == element[i])[0]             
                 leavenamenew.push(leavenameholder.leavedesc)
             }

               let leavedays = []
                 for (let j = 0; j < element.length; j++) {
                    let totaldayhol = item.filter(val => {
                       if(val.leavetypeid == element[j])
                       return val;
                        })

                // for (let k = 0; k < totaldayhol.length; j++) {
                //     totaldayhol[k].lvdays = parseFloat(totaldayhol[k].lvdays)
                
                //    }

                let gettotal = totaldayhol.map(item => item.lvdays).reduce((a, b) => a + b, 0)
                let gettotalnew = parseFloat(gettotal).toFixed(2);
                    leavedays.push(gettotalnew)
                 }

                vm.baroptions.xaxis.categories = leavenamenew                
                vm.barseries[0].data = leavedays

            this.$store.dispatch('get', {url: "/leavetype/leavetypeid/"+ this.data.formData.startdate + "/" + this.data.formData.enddate})
            .then(res => {

            vm.leavetypegroup = res.data.rec;

           // console.log("leavetypegroup", vm.leavetypegroup)
            });

            vm.loading = false; 
        },


        //     barChart(item){
        //     const vm = this; 
        //     let leavenamenew = [];
        //     let gettotalnew2 = [];
        //     const element = [...new Set(item.map(item => item.leavetypeid))]
        //     console.log('element', element)
        //       for (let i = 0; i < element.length; i++) {
        //          let leavenameholder = vm.leavetypedata.filter(x => x.id == element[i])[0]             
        //          leavenamenew.push(leavenameholder.leavedesc)
        //       }

        //      //  let leavedays = []
        //          for (let j = 0; j < element.length; j++) {
        //             let totaldayhol = item.filter(val => {
        //                if(val.leavetypeid == element[j])
        //                return val;
        //                 })

        //         for (let j = 0; j < totaldayhol.length; j++) {
        //             totaldayhol[j].lvdays = parseFloat(totaldayhol[j].lvdays)
        //             console.log('lvdays', totaldayhol[j].lvdays)
        //              }
                 
        //          let gettotal = totaldayhol.map(item => item.lvdays).reduce((a, b) => a + b, 0)
        //          console.log('gettotal', gettotal) 
        //          let gettotalnew = (parseFloat(gettotal).toFixed(2));
        //          //   leavedays.push(totaldayhol.length)
        //          gettotalnew2.push(gettotalnew)

        //             console.log('totaldayhol', totaldayhol)
        //             console.log('gettotalnew', gettotalnew)

        //          }
        //     //}
        //          vm.baroptions.xaxis.categories = leavenamenew
        //          console.log('xaxis', vm.baroptions.xaxis.categories)   
        //          vm.barseries[0].data = gettotalnew2
        //          console.log('yaxis', vm.barseries[0].data)   
              
        //     vm.loading = false; 
        // },


         lineLeave(itemnew) {
            const vm = this;
            const element = [...new Set(itemnew.map(itemnew => itemnew.leavetypeid))]
            let dayhol = [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ]

            let tickholder = []
            // vm.lineoptions.xaxis.categories =  [
            //     'Monday',
            //     'Tuesday',
            //     'Wednesday',
            //     'Thursday',
            //     'Friday',
            //     'Saturday',
            //     'Sunday'
            // ]

          //  console.log('dayname', vm.lineoptions.xaxis.categories)

            for (let i = 0; i < element.length; i++) {
                let itemleave = itemnew.filter(x => x.leavetypeid == element[i])
                let leavenameholder = vm.leavetypedata.filter(x => x.id == element[i])[0]

                let totalday = []
                for (let j = 0; j < dayhol.length; j++) {
                    let totaldayhol = itemleave.filter(val => {
                       if(moment(val.leavedate).format('dddd') == dayhol[j])
                       return val;

                    //   console.log('val', val)
                    })

                  //  console.log('totaldayhol', totaldayhol)
  
                    totalday.push(totaldayhol.length)
                    tickholder.push(totaldayhol.length)
                    
                }
                vm.lineseries.push({
                    name: leavenameholder.leavedesc,
                    data: totalday
                })
                
                if (i == element.length - 1) {
                    vm.checkloading++;
                    if (vm.checkloading == 3) vm.loading = false;
                }
            }
            let gettick = Math.max(...tickholder)
            vm.lineoptions.yaxis.ticks.tickAmount = gettick + 1
            vm.lineoptions.yaxis.ticks.max = gettick + 1

        },

        donutLeave(itemnew) {
            const vm = this;
            let dayhol = [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ]
            
            let dayitem = []
            for (let i = 0; i < dayhol.length; i++) {
                let totaldayhol = itemnew.filter(val => {
                    if(moment(val.leavedate).format('dddd') == dayhol[i])
                    return val;
                })
                dayitem.push(totaldayhol.length)
                if (i == dayhol.length - 1) {
                    vm.checkloading++;
                    if (vm.checkloading == 3) vm.loading = false;
                }
            }
           // vm.donutoptions.labels = [...dayhol]
            vm.donutseries = dayitem
        },


        leaveTable(dataPointIndex) {
            const vm = this
            
            this.leavetypeid = vm.leavetypegroup[dataPointIndex].leavetypeid
            this.leavedesc = vm.baroptions.xaxis.categories[dataPointIndex]

           // console.log('leavetypeid', this.leavetypeid)
         

            this.$store.dispatch('post', {url: `/leavedashboard/leavetypeid/${this.$store.getters.userId}/${this.leavetypeid}`, payload: this.data})
            .then(res => {

            vm.staffdetails = res.data.rec;

           // console.log('vm.staffdetails', vm.staffdetails)


             for (let i = 0; i < vm.staffdetails.length; i++) {
                if (vm.staffdetails[i].startdate) vm.staffdetails[i].startdate = moment(vm.staffdetails[i].startdate).format('YYYY-MM-DD')
                if (vm.staffdetails[i].startdate) vm.staffdetails[i].enddate = moment(vm.staffdetails[i].enddate).format('YYYY-MM-DD')
                if ( vm.staffdetails[i].startdatetime)  vm.staffdetails[i].startdatetime = moment( vm.staffdetails[i].startdatetime).format('HH:mm')
                if ( vm.staffdetails[i].enddatetime)  vm.staffdetails[i].enddatetime = moment( vm.staffdetails[i].enddatetime).format('HH:mm')
                
                 if (vm.staffdetails[i].startdate < vm.data.formData.startdate) {
                     vm.staffdetails[i].startdate = vm.data.formData.startdate
                 }

                 if (vm.staffdetails[i].enddate > vm.data.formData.enddate) {
                     vm.staffdetails[i].enddate = vm.data.formData.enddate
                 }
            }


            vm.hidetable = false
            })
        },

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            let titleholder = this.$t('reportheadcountanalysis.stafflistfor') + this.pageTitleValue; 
            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', titleholder + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let item = this.staffdetails
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [this.$t('columns.name')]: item[i].psnname,
                    [this.$t('columns.staffcode')]: item[i].staffcode,
                    [this.$t('columns.company')]: item[i].compnyname,
                    [this.$t('generateunpaidleaverecord.leavetype')]: item[i].leavedesc,
                    [this.$t('columns.startdate')]: item[i].startdate,
                    [this.$t('columns.enddate')]: item[i].enddate,
                    [this.$t('reportleavedashboard.leavedays')]: item[i].lvdays,
                    [this.$t('reportprocessloglisting.starttime')]: item[i].startdatetime,
                    [this.$t('reportprocessloglisting.endtime')]: item[i].enddatetime,
                    [this.$t('recleaverec.leavereason')]: item[i].reasondesc,
                    [this.$t('columns.position')]: item[i].posdesc,
                    [this.$t('columns.job')]: item[i].jobtitle,
                    [this.$t('columns.department')]: item[i].deptname,
                    [this.$t('columns.branch')]: item[i].brchname                  
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.staffdetails

            var head = [                
                { title: this.$t('columns.name'), dataKey: 'psnname' },
                { title: this.$t('columns.staffcode'), dataKey: 'staffcode' },
                { title: this.$t('columns.company'), dataKey: 'compnyname' },
                { title: this.$t('generateunpaidleaverecord.leavetype'), dataKey: 'leavedesc' },
                { title: this.$t('columns.startdate'), dataKey: 'startdate' },
                { title: this.$t('columns.enddate'), dataKey: 'enddate' },
                { title: this.$t('reportleavedashboard.leavedays'), dataKey: 'lvdays' },
                { title: this.$t('reportprocessloglisting.starttime'), dataKey: 'startdatetime' },
                { title: this.$t('reportprocessloglisting.endtime'), dataKey: 'enddatetime' },
                { title: this.$t('recleaverec.leavereason'), dataKey: 'reasondesc' },
                { title: this.$t('columns.position'), dataKey: 'posdesc' },
                { title: this.$t('columns.job'), dataKey: 'jobtitle' },
                { title: this.$t('columns.department'), dataKey: 'deptname' },
                { title: this.$t('columns.branch'), dataKey: 'brchname'},
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
               
                body.push({
                       psnname: item[i].psnname,
                        staffcode: item[i].staffcode,
                        compnyname: item[i].compnyname,
                        leavedesc: item[i].leavedesc,
                        startdate: item[i].startdate,
                        enddate: item[i].enddate,
                        lvdays: item[i].lvdays,
                        startdatetime: item[i].startdatetime,
                        enddatetime: item[i].enddatetime,
                        reasondesc: item[i].reasondesc,
                        posdesc: item[i].posdesc,
                        jobtitle: item[i].jobtitle,
                        deptname: item[i].deptname,
                        brchname: item[i].brchname
                    })
            }

            let titleholder = this.$t('reportheadcountanalysis.stafflistfor') + this.pageTitleValue;
            doc.text(titleholder, 10, 10) 
            doc.autoTable(head, body)
            doc.save(titleholder + '.pdf')
        },

        onClosePress() {
            this.isErr = ! this.isErr;
        },

        backHandler() {
            let formData = this.data.formData
            let param = this.data.parameters

            this.$router.push({name: 'leavedashboardfilter', params: { formData, param } });
        },

        backHandlerTable() {
            this.hidetable = true
        },

        loadParameters() {
            this.companyHandler();
            this.departmentHandler();
            this.branchHandler();
            this.employmentHandler();
            this.valvalueHandler();
        }
    },

    created(){
        if (typeof(this.$route.params.parameters) == 'string') {
            let paramHolder = JSON.parse(this.$route.params.parameters)
            this.data.parameters = {
                brchid: paramHolder.branchid,
                compnyid: paramHolder.companyid,
                deptid: paramHolder.departmentid,
                emptypeid: paramHolder.employmenttypeid,
                valvalueid: paramHolder.valvalueid,
            }
        }
    },

    mounted() {
        this.loadStaffMov();
      //  this.loadChart();
        this.loadLeaveType();
        this.loadParameters();
       // this.linegraph();
       // this.piechart();
    }
    
}
</script>


<style scoped>
.textBlue {
    color:  #004080;
}
</style>
