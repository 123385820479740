<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div> 

    
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('recuser.userlist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value:'action', sortable:false},
                {text: this.$t('recuser.loginid'), value: 'userid', width: '150px'},
                {text: this.$t('columns.staffname'), value: 'psnname', width: '200px'},
                {text: this.$t('columns.usertype'), value: 'usertype', width: '200px'},
                {text: this.$t('columns.startdate'), value: 'startdate', width: '200px'},
                {text: this.$t('columns.enddate'), value: 'enddate', width: '200px'},
                {text: this.$t('recuser.lastlogin'), value: 'lastaccess', width: '200px'},
                {text: this.$t('recuser.lastlogindate'), value: 'lastaccdays', width: '200px'},
                {text: this.$t('recuser.passwordlastchanged'), value: 'pwdlastchg', width: '200px'},
                {text: this.$t('recuser.passwordchangeddays'), value: 'pwdchgdays', width: '200px'},
                {text: this.$t('columns.status'), value: 'status', width: '200px'},
                {text: this.$t('recuser.activate'), value: 'activate', width: '150px'}
            ],
            dataItems: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        loadSecuser() {
            const vm = this;

            this.$store.dispatch('get', {url: '/secuser/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].lastaccess) {
                        vm.dataItems[i].lastaccess = moment(vm.dataItems[i].lastaccess).format('YYYY-MM-DD HH:mm:ss');
                    }

                    if (vm.dataItems[i].pwdlastchg) {
                        vm.dataItems[i].pwdlastchg = moment(vm.dataItems[i].pwdlastchg).format('YYYY-MM-DD HH:mm:ss');
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'secuseredit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'secusernew'});
        }
    },

    mounted() {
        this.loadSecuser();
    }
}
</script>