<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value"> {{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                       <v-row>
                        <v-col cols="7" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.latenessdeductionbatch')"
                                type="text"
                                ref="latenessbatchdesc"
                                v-model="formData.latenessbatchdesc"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.batchcode')"
                                type="text"
                                ref="latenessbatchcode"
                                v-model="formData.latenessbatchcode"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.batchfromdate')"
                                type="date"
                                ref="fromdate"
                                v-model="formData.fromdate"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.batchtodate')"
                                type="date"
                                ref="todate"
                                v-model="formData.todate"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.staffname')"
                                type="text"
                                ref="name"
                                v-model="formData.name"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="compnyname"
                                v-model="formData.compnyname"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnycode"
                                v-model="formData.compnycode"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.date')"
                                type="date"
                                ref="latedate"
                                v-model="formData.latedate"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.durationmin')"
                                type="text"
                                ref="duration"
                                v-model="formData.duration"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.genamount')"
                                type="decimal"
                                ref="genamount"
                                v-model="formData.genamount"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('latenessdeduction.finalamount')"
                                type="decimal"
                                ref="finalamount"
                                v-model="formData.finalamount"
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('payeeinputprocess.justification')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                dense
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="7" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('latenessdeduction.currencycode')"
                                type="text"
                                ref="currencydesc"
                                v-model="formData.currencydesc"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="currency"
                                v-model="formData.currency"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('posted')"
                                type="text"
                                ref="posteddesc"
                                v-model="formData.posteddesc"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="7" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('reportsparam.payout')"
                                type="text"
                                ref="payoutdesc"
                                v-model="formData.payoutdesc"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('claimrecordlisting.payoutcode')"
                                type="text"
                                ref="payoutcode"
                                v-model="formData.payoutcode"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('trainingrecord.submittedon')"
                                type="text"
                                ref="rectimestamp"
                                v-model="formData.rectimestamp"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('recleaverec.submittedby')"
                                type="text"
                                ref="username"
                                v-model="formData.username"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="7" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('payrollprocessrun.payrollgroup')"
                                type="text"
                                ref="grouppdesc"
                                v-model="formData.grouppdesc"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                :label="this.$t('columns.code')"
                                type="text"
                                ref="grouppcode"
                                v-model="formData.grouppcode"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>

                <v-row justify="center" align="center">
                    <div class="pt-5 pb-4">
                    <v-btn 
                        color="success" 
                        style="margin-right:20px; width: 100px" 
                        @click="saveHandler">
                        {{$t('button.save')}} 
                    </v-btn>
                    <v-btn 
                        color="warning" 
                        style="width: 100px" 
                        @click="cancelHandler">
                        {{$t('button.cancel')}} 
                    </v-btn>
                </div>
            </v-row>

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import moment from "moment";

export default {
    props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },
            formData: {
                id: this.parentObject.data.id,

                attnlatenessbatchid: this.parentObject.data.attnlatenessbatchid,
                latenessbatchcode: this.parentObject.data.latenessbatchcode,
                latenessbatchdesc: this.parentObject.data.latenessbatchdesc,
                fromdate: moment(this.parentObject.data.fromdate).format("YYYY-MM-DD"),
                todate: moment(this.parentObject.data.todate).format("YYYY-MM-DD"),
                name: this.parentObject.data.name,
                compnycode: this.parentObject.data.compnycode,
                compnyname: this.parentObject.data.compnyname,
                latedate: moment(this.parentObject.data.latedate).format("YYYY-MM-DD"),
                duration: this.parentObject.data.duration,
                genamount: this.parentObject.data.genamount,
                finalamount: this.parentObject.data.finalamount,
                remark: this.parentObject.data.remark,
                currency: this.parentObject.data.currency,
                currencydesc: this.parentObject.data.currencydesc,
                posted: this.parentObject.data.posted,
                posteddesc: this.parentObject.data.posted == 'Y' ? 'Yes' : 'No',
                payoutcode: this.parentObject.data.payoutcode,
                payoutdesc: this.parentObject.data.payoutdesc,
                rectimestamp: this.parentObject.data.rectimestamp,
                username: this.parentObject.data.username,
                grouppcode: this.parentObject.data.grouppcode,
                grouppdesc: this.parentObject.data.grouppdesc,

                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {
        addItemHandler($event) {
            this.formData.policydetail =$event
        },

        updateValue() {

        this.$emit('input', {

                id: this.formData.id,
                finalamount: this.formData.finalamount,
                remark: this.formData.remark,
                attnlatenessbatchid: this.formData.attnlatenessbatchid,

                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        cancelHandler() {
            this.$router.push({ name: "latenessdeductiondetailslisting", params: { id: this.formData.attnlatenessbatchid, search: this.formData.search } });
        },

    },

    mounted() {
    }



}
</script>