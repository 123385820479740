<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupsalfreq.freq')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.basicfreqcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.basicfreqdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.type')"
                                :items="types"
                                v-model="formData.typeid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupsalfreq.conversionfactor')"
                                type="number"
                                v-model="formData.rate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            formData: {
                basicfreqcode: this.parentObject.data.basicfreqcode,
                basicfreqdesc: this.parentObject.data.basicfreqdesc,
                typeid: this.parentObject.data.typeid,
                rate: this.parentObject.data.rate,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            types: [],
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadSalaryFreqTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: '/salfreqtype/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.types.push(
                        {
                            text: res.data.rec[i].typecode,
                            value: res.data.rec[i].id
                        }
                    )
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        updateValue() {
            this.$emit('input', {
                basicfreqcode: this.formData.basicfreqcode,
                basicfreqdesc: this.formData.basicfreqdesc,
                typeid: this.formData.typeid,
                rate: this.formData.rate,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'basicfreqlist', params: { search: this.search}});
        }
    },

    created() {
        this.loadSalaryFreqTypes();
    }
}
</script>