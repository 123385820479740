<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuptax.taxsetup')"
                                type="text"
                                dense
                                v-model="formData.taxsetupid"
                                :items="taxSetupList"
                                @change="changeTaxSetupHandler(formData.taxsetupid)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuptax.taxsetup')"
                                type="text"
                                dense
                                v-model="formData.taxsetupid"
                                :items="taxSetupList"
                               readonly
                               background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5">
                            <v-dialog v-model="taxsetupDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: taxSetupItems,
                                            formTitle: `${this.$t('setuptax.taxsetup')}`,
                                            formId: 'taxsetup'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveTaxSetup="saveTaxSetupHandler"
                                        v-on:deleteTaxSetup="deleteTaxSetuppHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="taxsetupDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.pcbsetdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuptax.pcbprojection')"
                                type="text"
                                :items="pcbProjectionList"
                                v-model="formData.pcbprjid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row cols="2" sm="2" class="pb-n5 mt-n8">
                        <v-btn-toggle rounded group>
                            <v-btn 
                                    :label="this.$t('setuptax.ratepercentage')" 
                                    v-model="x"
                                    @click="x = !x"
                                    depressed
                                    color="white"
                                    class="blue--text"
                            >{{$t('setuptax.ratepercentage')}}</v-btn>

                            <v-btn 
                                    :label="this.$t('setuptax.raterep')"
                                    v-model="y"
                                    @click="y = !y"
                                    depressed
                                    color="white"
                                    class="blue--text"
                            >{{$t('setuptax.raterep')}}</v-btn>

                            <v-btn 
                                    :label="this.$t('setuptax.taxabledeductions')"
                                    v-model="z"
                                    @click="z = !z"
                                    depressed
                                    color="white"
                                    class="blue--text"
                            >{{$t('setuptax.taxabledeductions')}}</v-btn>
                        </v-btn-toggle>
                    </v-row>

                    <v-row>
                        <app-table
                        v-if="x"
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.taxsetdetail
                            }"

                            v-on:input="addItemHandler"
                        />

                    </v-row>

                    <v-row>
                        <app-table1
                            v-if="y"
                            v-bind:parentObject = "{
                                tabHeader1: this.tabHeader1,
                                detailItem1: this.formData.taxsetdetail1
                            }"

                            v-on:input="addItemHandler1"
                        />

                    </v-row>

                    <v-row>
                        <app-table2
                            v-if="z"
                            v-bind:parentObject = "{
                                tabHeader2: this.tabHeader2,
                                detailItem2: this.formData.taxsetdetail2
                            }"

                            v-on:input="addItemHandler2"
                        />

                    </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from './CRUDTable';
import CRUDTable1 from './CRUDTable1';
import CRUDTable2 from './CRUDTable2';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable, //crudtable is for taxsetupd
        'app-table1': CRUDTable1, //crudtable1 is for taxsetupdretexp
        'app-table2': CRUDTable2, //crudtable2 is for taxsetupddedt
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {

            //xyz is used for button above to hide/show fields, depends on the user click
            x: false,
            y: false,
            z: false,

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                taxsetupid: this.parentObject.data.taxsetupid,
                pcbsetcode: this.parentObject.data.pcbsetcode,
                pcbsetdesc: this.parentObject.data.pcbsetdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                pcbprjid: this.parentObject.data.pcbprjid,
                remark: this.parentObject.data.remark,
                taxsetdetail: this.parentObject.data.taxsetdetail,
                taxsetdetail1: this.parentObject.data.taxsetdetail1,
                taxsetdetail2: this.parentObject.data.taxsetdetail2,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            tabHeader: [
                { text: this.$t('columns.actions'),           value: 'action',        width: '100px', sortable: false},
                { text: this.$t('setuptax.pcbcat'),     value: 'pcbcatcode',    width: '100px', sortable: true},
                { text: this.$t('setuptax.pamt'),         value: 'pamt',          width: '120px', sortable: true},
                { text: this.$t('setuptax.mamt'),         value: 'mamt',          width: '120px', sortable: true},
                { text: this.$t('setuptax.ratepercentage'),           value: 'ratep',         width: '120px', sortable: true},
                { text: this.$t('setuptax.bamt'),  value: 'bamt',          width: '100px', sortable: true},
                // { text: 'CATEGORY DESC',  value: 'pcbcatdesc',    width: '190px', sortable: true},
            ],

            tabHeader1: [
                { text: this.$t('columns.actions'),         value: 'action',        width: '100px', sortable: false},
                { text: this.$t('setuptax.pcbcat'),   value: 'pcbcatcode',    width: '120px', sortable: true},
                { text: this.$t('setuptax.pamt'),       value: 'pamt',          width: '120px', sortable: true},
                { text: this.$t('setuptax.tamt'),       value: 'tamt',          width: '120px', sortable: true},
                { text: this.$t('setuptax.ratepercentage'),         value: 'ratep',         width: '120px', sortable: true},
            ],

            tabHeader2: [
                { text: this.$t('columns.actions'),                   value: 'action',        width: '100px', sortable: false},
                { text: this.$t('setuptax.pcbcat'),             value: 'pcbcatcode',    width: '120px', sortable: true},
                { text: this.$t('setuptax.spouse'),     value: 'spsdeduct',     width: '120px', sortable: true},
                { text: this.$t('setuptax.child'),  value: 'pchilddeduct',  width: '120px', sortable: true},
                { text: this.$t('setuptax.self'), value: 'indvdeduct',    width: '120px', sortable: true},
                // { text: 'DEDUCTION ENTITLEMENT',    value: 'dedent',        width: '210px', sortable: true},
                // { text: 'CATEGORY DESC', value: 'pcbcatdesc',    width: '150px', sortable: true},
            ],

            selected: [],
            affectItems: [],
            pcbProjections: [],
            pcbProjectionList: [],
            taxSetupList: [],
            taxSetupItems: [],
            taxSetup: [],

            errMsg: '',

            taxsetupDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadTaxSetupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/taxset/all'})
                .then(res => {
                    vm.taxSetup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxSetupList.push(
                            {
                                text: res.data.rec[i].pcbsetcode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.taxSetupItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].pcbsetcode,
                                desc: res.data.rec[i].pcbsetdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        // addItemHandler is to add item for texsetdetails which are items for taxsetupd
        addItemHandler($event) {
            this.formData.taxsetdetail=$event

            this.updateValue();
        },

        // addItemHandler is to add item for texsetdetails1 which are items for taxsetupdretexp
        addItemHandler1($event) {
            this.formData.taxsetdetail1=$event

            this.updateValue();
        },

        // addItemHandler is to add item for texsetdetails2 which are items for taxsetupddedt
        addItemHandler2($event) {
            this.formData.taxsetdetail2=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                taxsetupid: this.formData.taxsetupid,
                pcbsetcode: this.formData.pcbsetcode,
                pcbsetdesc: this.formData.pcbsetdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                pcbprjid: this.formData.pcbprjid,
                remark: this.formData.remark,
                taxsetdetail: this.formData.taxsetdetail,
                taxsetdetail1: this.formData.taxsetdetail1,
                taxsetdetail2: this.formData.taxsetdetail2,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveTaxSetupHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/taxset/' + this.itemData.itemid, payload: {
                pcbsetcode: this.itemData.itemcode,
                pcbsetdesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.taxSetup = [];
                        vm.taxSetupItems = [];
                        vm.taxSetupList = [];

                        this.loadTaxSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/taxset/create', payload: {
                pcbsetcode: this.itemData.itemcode,
                pcbsetdesc: this.itemData.itemdesc,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.taxSetup = [];
                        vm.taxSetupItems = [];
                        vm.taxSetupList = [];

                        this.loadTaxSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteTaxSetuppHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/taxset/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.taxSetup = [];
                        vm.taxSetupItems = [];
                        vm.taxSetupList = [];

                        this.loadTaxSetupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        changeTaxSetupHandler(id) {
            let item = this.taxSetup.find(item => item.id === parseInt(id));
            
            this.formData.pcbsetcode = item.pcbsetcode;
            this.formData.pcbsetdesc = item.pcbsetdesc;
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'taxsetlist', params: { search: this.search}});
        },

        closeHandler() {
            this.subDialog = false;
            this.isErr = !this.isErr;
            this.errMsg = '';
        },

        loadPCBProjection() {
            const vm = this;

            this.$store.dispatch('get', {url: '/pcbprj/all'})
            .then (res => {
                vm.pcbProjections = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.pcbProjectionList.push(
                        {
                            text: res.data.rec[i].pcbprjdesc,
                            value: res.data.rec[i].id
                        }
                    )
                } 

            })
            .catch (err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        check() {
            console.log('taxsetdetail', this.formData.taxsetdetail)
            console.log('taxsetdetail1', this.formData.taxsetdetail1)
            console.log('taxsetdetail2', this.formData.taxsetdetail2)
        }
    },

    mounted() {
        this.loadPCBProjection();
        this.loadTaxSetupListHandler();
        this.check();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>