<template>
    <div>
        <v-container>
            <v-form>
               <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="12" sm="6" class="blue--text ml-2">
                            {{$t('alleissocso.socsoandeisexportprocesslog')}}
                        </v-col>
                    </v-row>

                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" class="orange lighten-5">
                                <v-row>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('setupreport_exportmapping.processed_hashtag')"
                                            type="text"
                                            v-model="listErr.processed"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('setupreport_exportmapping.success_hashtag')"
                                            type="text"
                                            v-model="listErr.success"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('setupreport_exportmapping.error_hashtag')"
                                            type="text"
                                            v-model="listErr.errors"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row>
                                     <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('alleissocso.totcontamountsocso_employer')"
                                            type="text"
                                            v-model="listErr.totalCompnySocsoContAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('alleissocso.totcontamountsocso_employee')"
                                            type="text"
                                            v-model="listErr.totalStfSocsoContAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row>
                                    
                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('alleissocso.totcontamounteis_employer')"
                                            type="text"
                                            v-model="listErr.totalCompnyEisContAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="4" sm="4">
                                        <v-text-field
                                            :label="this.$t('alleissocso.totcontamounteis_employee')"
                                            type="text"
                                            v-model="listErr.totalStfEISContAmt"
                                            class="custom"
                                            readonly
                                            dense
                                            background-color="#FFF3E0"
                                        ></v-text-field>
                                    </v-col>


                                    <v-col cols="4" sm="4"></v-col>
                                    <v-col cols="4" sm="4"></v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text ml-2">
                            {{$t('statutoryexportcommonused.errorlog')}}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="1" sm="1" class="ml-n4">
                            <div style="width: 50%; max-height: 50%" >
                                <v-img @click="printPDF" :src="`${publicPath}images/export_pdf.png`"/>
                            </div>
                        </v-col>
                        <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                            <div style="width: 50%">
                                <v-img @click="getCSV" :src="`${publicPath}images/export_csv.png`"/>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12">
                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItems"
                            :options="tableOpt"
                            hide-default-footer
                            item-key="errorlog"
                        >
                        </v-data-table>
                        </v-col>
                    </v-row>

                </v-container>
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="returnHandler"> 
                            {{$t('button.return')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },
            pageTitle: this.$t('statutoryexportcommonused.exportprocesslog'),
            dataHeader: [
                { text: this.$t('statutoryexportcommonused.no'),               value: 'index',    width: '80px', sortable: true},
                { text: this.$t('columns.name'),             value: 'psnname',    width: '300px', sortable: true},
                { text: this.$t('columns.staffcode'),         value: 'staffcode',  width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.errormessage'),    value: 'errormsg',  width: '300px', sortable: true},
            ],

            dataItems: [],
            listErr: {
                processed: null,
                success: null,
                errors: null,
                totalStfSocsoContAmt: null,
                totalCompnySocsoContAmt: null,
                totalStfEISContAmt: null,
                totalCompnyEisContAmt : null,
            },
            item: this.$route.params.item,
            tableOpt: {
                itemsPerPage: -1
            },
            errMsg: '',
            isErr: false,
        }
    },

    methods: {
        getError() {
            if (this.item) {
                this.listErr.totalStfSocsoContAmt = this.item.totalStfSocsoContAmt
                this.listErr.totalCompnySocsoContAmt = this.item.totalCompnySocsoContAmt
                this.listErr.totalStfEISContAmt = this.item.totalStfEISContAmt
                this.listErr.totalCompnyEisContAmt = this.item.totalCompnyEisContAmt
                this.listErr.success = this.item.successCount
                this.listErr.processed = this.item.processedCount
                this.listErr.errors = this.item.errCount

                if (this.item.accounterr.length > 0) {
                    this.dataItems = this.item.accounterr
                    for (let i = 0; i < this.dataItems.length; i++) {
                        this.dataItems[i].index = i+1
                    }
                }
            }
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.$t('alleissocso.monthlysocsoandeisexport') + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            if (this.dataItems.length > 0) {
                let itemSummary = this.dataItems

                let dataSummary = []
                
                for (var i = 0; i < itemSummary.length; i++) {
                    itemSummary[i].index = i
                    if(itemSummary[i].psnname === null || itemSummary[i].psnname === undefined) {
                        itemSummary[i].psnname = ''
                    }
                    if(itemSummary[i].staffcode === null || itemSummary[i].staffcode === undefined) {
                        itemSummary[i].staffcode = ''
                    }
                    dataSummary.push({
                        [this.$t('statutoryexportcommonused.no')]: itemSummary[i].index+1,
                        [this.$t('columns.name')]: itemSummary[i].psnname,
                        [this.$t('columns.staffcode')]: itemSummary[i].staffcode,
                        [this.$t('statutoryexportcommonused.errormessage')]: itemSummary[i].errormsg
                    })
                }
                const csvData = this.objectToCSV(dataSummary)
                this.download(csvData)
            }
            
        },

        printPDF() {
            if (this.dataItems.length > 0) {
                const doc = new jsPDF({
                    orientation: 'landscape'
                })
                let item = this.dataItems

                var head = [                
                    { title: this.$t('statutoryexportcommonused.no'), dataKey: 'index' },
                    { title: this.$t('columns.name'), dataKey: 'psnname' },
                    { title: this.$t('columns.staffcode'), dataKey: 'staffcode' },
                    { title: this.$t('statutoryexportcommonused.errormessage'), dataKey: 'errormsg' },
                ]

                let body = []

                for (var i = 0; i < item.length; i++) {
                    body.push({
                        index: item[i].index,
                        psnname: item[i].psnname,
                        staffcode: item[i].staffcode,
                        errormsg: item[i].errormsg,
                    })
                }
                doc.text(this.$t('alleissocso.monthlysocsoandeisexport'), 10, 10)
                doc.autoTable(head, body)
                doc.save(this.$t('alleissocso.monthlysocsoandeisexport') + '.pdf')
            }
        },

        returnHandler() {
            this.$router.push({name: 'monthlyeissocsoexport'});
        },

    },

    mounted() {
        this.getError();
    }
}
</script>