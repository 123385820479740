<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('setuppayitem.payitemlist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false},
                {text: this.$t('workhourspayitemmappingsetup.payitemcode'), value: 'payitemcode', width: '160px'},
                {text: this.$t('columns.description'), value: 'payitemdesc', width: '200px'},
                {text: this.$t('setuppayitmetype.payitemtype'), value: 'payitemtypedesc', width: '200px'},
                {text: this.$t('columns.effectivefrom'), value: 'startdate', width: '160px'},
                {text: this.$t('columns.effectiveuntil'), value: 'enddate', width: '160px'},
                {text: this.$t('setupaffecttype.affecttype'), value: 'affecttypedesc', width: '200px'},
                {text: this.$t('setuppayitem.taxableexemption'), value: 'taxexemptiondesc', width: '200px'},
                {text: this.$t('setuppayitem.prorate'), value: 'proratedesc', width: '200px'},
                {text: this.$t('setuppayitem.round'), value: 'roundingdesc', width: '120px'},
                //{text: 'DECIMAL', value: 'decimalval', width: '120px'},
                {text: this.$t('columns.remark'), value: 'remark', width: '200px'}                           
            ],
            dataItems: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadPayItems() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitem/all'})
            .then(res => {
                vm.dataItems = res.data.rec;

                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'payitemedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'payitemnew'});
        }
    },

    mounted() {
        this.loadPayItems();
    }
}
</script>