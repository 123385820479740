<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}<span class="page_title_value">{{ this.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
            
                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="6" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('payrollprocessrun.payout')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="payoutid"
                                    :items="payoutctrlList"
                                    :rules="[rules.required]"
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                <!-- <app-parameter 
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                /> -->

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="goHandler"> 
                            {{$t('button.go')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';

//import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-parameter': FilterParameter
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            
            payoutid: null,
            pageTitle: this.$t('previewexception.payrollexceptiondashboard'),
            pageTitleValue: '',

            // parameters: {
            //     staffid: null,
            //     gradeid: null,
            //     employmenttypeid: null,
            //     companyid: null,
            //     branchid: null,
            //     departmentid: null,
            // },

            // showFilter: {
            //     staffid: true,
            //     gradeid: true,
            //     employmenttypeid: true,
            //     companyid: true,
            //     branchid: true,
            //     departmentid: true
            // },

            payout: [],
            payoutctrlList: [],
        }
    },

    methods: {

        loadPayoutListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrlnotfinalized/all'})
            .then (res => {
                vm.payoutctrls = res.data.rec

                for (let i = 0; i < vm.payoutctrls.length; i++) {
                    if (vm.payoutctrls[i].startdate) {
                        vm.payoutctrls[i].startdate = moment(vm.payoutctrls[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.payoutctrls[i].enddate) {
                        vm.payoutctrls[i].enddate = moment(vm.payoutctrls[i].enddate).format('YYYY-MM-DD');
                    }

                    vm.payoutctrlList.push(
                        {
                            text: vm.payoutctrls[i].payoutcode,
                            value: vm.payoutctrls[i].id
                        }
                    )
                }

            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        newHandler() {
            this.$emit('new');
        },

        goHandler() {
            if (this.$refs.form.validate()) {
                const id = this.payoutid
               // const param = this.parameters
                this.$router.push({name: 'payrollexceptionsummary', params: { id } });
            }
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

    },

    mounted() {
        this.loadPayoutListHandler()
    }
    
}
</script>