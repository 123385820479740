<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.position')"
                                id="poscode"
                                type="text"
                                ref="poscode"
                                :rules="[rules.required]"
                                v-model="formData.poscode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="posdesc"
                                :rules="[rules.required]"
                                v-model="formData.posdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivedate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupposition.invaliddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupposition.reportingposition')"
                                id="repposid"
                                type="text"
                                ref="repposid"
                                :items="this.reportingPositionList"
                                v-model="formData.repposid"
                                @input="updateValue"
                                @change="changeReportingPositionHandler(formData.repposid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                            outlined
                            readonly 
                            :label="this.$t('columns.code')"
                            v-model="reportingPositionCode"
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>

                            <v-tabs
                                fixed-tabs
                            >
                                <v-tab>{{this.$t('settings')}}</v-tab>
                                <v-tab>{{this.$t('setupposition.assignment')}}</v-tab>

                                <v-tab-item class="pt-4">
                                    <v-row>
                                        <v-col cols="5" sm="5">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.company')"
                                                :items="this.companyList"
                                                v-model="formData.compnyid"
                                                @input="updateValue"
                                                @change="changeCompanyHandler(formData.compnyid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="companyCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.branch')"
                                                :items="this.branchList"
                                                v-model="formData.brchid"
                                                @input="updateValue"
                                                @change="changeBranchHandler(formData.brchid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="branchCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.division')"
                                                :items="this.divisionList"
                                                v-model="formData.divnid"
                                                @input="updateValue"
                                                @change="changeDivisionHandler(formData.divnid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="divisionCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.department')"
                                                :items="this.departmentList"
                                                v-model="formData.deptid"
                                                @input="updateValue"
                                                @change="changeDepartmentHandler(formData.deptid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="departmentCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.section')"
                                                :items="this.sectionList"
                                                v-model="formData.sectioid"
                                                @input="updateValue"
                                                @change="changeSectionHandler(formData.sectioid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="sectionCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.unit')"
                                                :items="this.unitList"
                                                v-model="formData.unitid"
                                                @input="updateValue"
                                                @change="changeUnitHandler(formData.unitid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="unitCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.job')"
                                                :items="this.jobList"
                                                v-model="formData.jobid"
                                                @input="updateValue"
                                                @change="changeJobHandler(formData.jobid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="jobCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.grade')"
                                                :items="this.gradeList"
                                                v-model="formData.grdid"
                                                @input="updateValue"
                                                @change="changeGradeHandler(formData.grdid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2"  class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="gradeCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                            <v-autocomplete
                                                outlined
                                                dense
                                                :label="this.$t('columns.employmenttype')"
                                                :items="this.emptypeList"
                                                v-model="formData.emptypeid"
                                                @input="updateValue"
                                                @change="changeEmpTypeHandler(formData.emptypeid)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                            <v-text-field
                                                outlined
                                                dense
                                                readonly 
                                                background-color="#ffeab2"
                                                :label="this.$t('columns.code')"
                                                v-model="emptypeCode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-tab-item>
                                
                                <v-tab-item class="pt-4">
                                    <v-row>
                                        <v-col cols="6" sm="6">
                                            <v-text-field
                                                :label="this.$t('columns.staffid')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.staffcode"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.staffname')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.psnname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.company')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.compnyname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.branch')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.brchname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.division')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.divnname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.department')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.deptname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.section')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.sectioname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.unit')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.unitname"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.job')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.jobtitle"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.grade')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.grddesc"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                                            <v-text-field
                                                :label="this.$t('columns.employmenttype')"
                                                readonly
                                                dense
                                                background-color="#ffeab2"
                                                outlined
                                                v-model="assignments.emptypedesc"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>

                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            formData: {
                poscode: this.parentObject.data.poscode
                ,posdesc: this.parentObject.data.posdesc
                ,startdate: this.parentObject.data.startdate
                ,enddate: this.parentObject.data.enddate
                ,repposid: this.parentObject.data.repposid
                ,remark: this.parentObject.data.remark
                ,isdisable: this.parentObject.data.isdisable
                ,isassigned: this.parentObject.data.isassigned
                ,compnyid: this.parentObject.data.compnyid
                ,brchid: this.parentObject.data.brchid
                ,divnid: this.parentObject.data.divnid
                ,deptid: this.parentObject.data.deptid
                ,sectioid: this.parentObject.data.sectioid
                ,unitid: this.parentObject.data.unitid
                ,jobid: this.parentObject.data.jobid
                ,grdid: this.parentObject.data.grdid
                ,emptypeid: this.parentObject.data.emptypeid
                ,search: this.parentObject.data.search
                ,stamp: this.parentObject.data.stamp
                ,userid: this.parentObject.data.userid
            },
            assignments: {
                staffcode: ''
                ,psnname: ''
                ,compnyname: ''
                ,brchname: ''
                ,divnname: ''
                ,deptname: ''
                ,sectioname: ''
                ,unitname: ''
                ,jobtitle: ''
                ,grddesc: ''
                ,emptypedesc: ''
            },
            reportingPositionList:[],
            reportingPositions: [],
            reportingPositionItems: [],
            reportingPostions: this.parentObject.reportingPostions,
            reportingPositionCode: '',
            companyCode: '',
            branchCode: '',
            divisionCode: '',
            departmentCode: '',
            sectionCode: '',
            unitCode: '',
            jobCode: '',
            gradeCode: '',
            emptypeCode: '',
            positionList: [],
            companyList: [],
            branchList: [],
            divisionList: [],
            departmentList: [],
            sectionList: [],
            unitList: [],
            jobList: [],
            gradeList: [],
            emptypeList: [],
            search: this.parentObject.searchnew,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
        }
    },

    methods: {
        loadCompanies() {
            const vm = this;
            this.$store.dispatch('get', {url: '/company/all'})
                .then(res => {
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companyList.push(
                            {
                                text: res.data.rec[i].compnyname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.compnyid) {
                        vm.changeCompanyHandler(vm.formData.compnyid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadBranches() {
            const vm = this;
            this.$store.dispatch('get', {url: '/branch/all'})
                .then(res => {
                    vm.branches = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.branchList.push(
                            {
                                text: res.data.rec[i].brchname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.brchid) {
                        vm.changeBranchHandler(vm.formData.brchid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadDivisions() {
            const vm = this;
            this.$store.dispatch('get', {url: '/division/all'})
                .then(res => {
                    vm.divisions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.divisionList.push(
                            {
                                text: res.data.rec[i].divnname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.divnid) {
                        vm.changeDivisionHandler(vm.formData.divnid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadDepartments() {
            const vm = this;
            this.$store.dispatch('get', {url: '/department/all'})
                .then(res => {
                    vm.departments = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.departmentList.push(
                            {
                                text: res.data.rec[i].deptname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.deptid) {
                        vm.changeDepartmentHandler(vm.formData.deptid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadSections() {
            const vm = this;
            this.$store.dispatch('get', {url: '/section/all'})
                .then(res => {
                    vm.sections = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.sectionList.push(
                            {
                                text: res.data.rec[i].sectioname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.sectioid) {
                        vm.changeSectionHandler(vm.formData.sectioid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadUnits() {
            const vm = this;
            this.$store.dispatch('get', {url: '/unit/all'})
                .then(res => {
                    vm.units = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.unitList.push(
                            {
                                text: res.data.rec[i].unitname,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.unitid) {
                        vm.changeUnitHandler(vm.formData.unitid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadJobs() {
            const vm = this;
            this.$store.dispatch('get', {url: '/job/all'})
                .then(res => {
                    vm.jobs = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.jobList.push(
                            {
                                text: res.data.rec[i].jobtitle,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.jobid) {
                        vm.changeJobHandler(vm.formData.jobid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadGrades() {
            const vm = this;
            this.$store.dispatch('get', {url: '/grade/all'})
                .then(res => {
                    vm.grades = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.gradeList.push(
                            {
                                text: res.data.rec[i].grddesc,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.grdid) {
                        vm.changeGradeHandler(vm.formData.grdid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadEmpTypes() {
            const vm = this;
            this.$store.dispatch('get', {url: '/emptype/all'})
                .then(res => {
                    vm.emptypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.emptypeList.push(
                            {
                                text: res.data.rec[i].emptype,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                    if (vm.formData.emptypeid) {
                        vm.changeEmpTypeHandler(vm.formData.emptypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadReportingPosition() {
            const vm = this;

            this.$store.dispatch('get', {url: '/position/all'})
                .then(res => {
                    vm.reportingPositions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.reportingPositionList.push(
                            {
                                text: res.data.rec[i].poscode + ' ' + res.data.rec[i].posdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (this.formData.repposid != null) {
                        this.changeReportingPositionHandler(this.formData.repposid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadAssignments() {
            const vm = this;
            this.$store.dispatch('get', {url: '/staff/position/' + this.parentObject.data.id})
                .then(res => {
                    var result = res.data.rec;

                    vm.assignments = {
                        staffcode: result[0].staffcode
                        ,psnname: result[0].psnname
                        ,compnyname: result[0].compnyname
                        ,brchname: result[0].brchname
                        ,divnname: result[0].divnname
                        ,deptname: result[0].deptname
                        ,sectioname: result[0].sectioname
                        ,unitname: result[0].unitname
                        ,jobtitle: result[0].jobtitle
                        ,grddesc: result[0].grddesc
                        ,emptypedesc: result[0].emptypedesc
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCompanyHandler(id) {
            let item = this.companies.find(item => item.id === id);

            this.companyCode = item.compnycode;
        },

        changeBranchHandler(id) {
            let item = this.branches.find(item => item.id === id);

            this.branchCode = item.brchcode;
        },

        changeDivisionHandler(id) {
            let item = this.divisions.find(item => item.id === id);

            this.divisionCode = item.divncode;
        },

        changeDepartmentHandler(id) {
            let item = this.departments.find(item => item.id === id);

            this.departmentCode = item.deptcode;
        },

        changeSectionHandler(id) {
            let item = this.sections.find(item => item.id === id);

            this.sectionCode = item.sectiocode;
        },

        changeUnitHandler(id) {
            let item = this.units.find(item => item.id === id);

            this.unitCode = item.unitcode;
        },

        changeJobHandler(id) {
            let item = this.jobs.find(item => item.id === id);

            this.jobCode = item.jobcode;

            // Auto populate defaults.
            this.formData.grdid = item.gradeid;
            this.changeGradeHandler(item.gradeid);

            this.formData.emptypeid = item.emptypeid;
            this.changeEmpTypeHandler(item.emptypeid);
        },

        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id === id);

            this.gradeCode = item.grdcode;
        },

        changeEmpTypeHandler(id) {
            let item = this.emptypes.find(item => item.id === id);

            this.emptypeCode = item.emptype;
        },

        loadDropDowns() {
            this.loadReportingPosition();
            this.loadCompanies();
            this.loadBranches();
            this.loadDivisions();
            this.loadDepartments();
            this.loadSections();
            this.loadUnits();
            this.loadGrades();
            this.loadEmpTypes();
            this.loadJobs();
        },

        changeReportingPositionHandler(id) {
            var item = this.reportingPositions.find(item => item.id === id);
            this.reportingPositionCode = item.posdesc;
        },

        updateValue() {
            this.$emit('input', {
                poscode: this.formData.poscode,
                posdesc: this.formData.posdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                repposid: this.formData.repposid,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                isassigned: this.formData.isassigned,
                compnyid: this.formData.compnyid,
                brchid: this.formData.brchid,
                divnid: this.formData.divnid,
                deptid: this.formData.deptid,
                sectioid: this.formData.sectioid,
                unitid: this.formData.unitid,
                jobid: this.formData.jobid,
                grdid: this.formData.grdid,
                emptypeid: this.formData.emptypeid,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'positionlist', params: { search: this.search}});
        }
    },

    created() {
        this.loadDropDowns();

        if (this.parentObject.data.isassigned == 1) {
            this.loadAssignments();
        }
        
    }
}
</script>