    <template>
    <div>
        <v-dialog v-model="leaveentitlementdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="3" sm="3" md="3" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupleavesetup.maxunittocf')"
                            type="number"
                            dense
                            v-model="selections.maxunitcf"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupleavesetup.maxbalancetocf')"
                            type="number"
                            dense
                            v-model="selections.maxperccf"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" sm="3" md="3" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupleavesetup.maxenttocf')"
                            type="number"
                            dense
                            v-model="selections.maxpercentcf"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupleavesetup.validmonthstocf')"
                            type="number"
                            dense
                            v-model="selections.bfmth"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3" sm="3" md="3" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupleavesetup.uptoyearsservice')"
                            type="number"
                            dense
                            v-model="selections.uptosrv"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.company')"
                            type="text"
                            dense
                            v-model="selections.compnyid"
                            :items="companyList"
                            
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.branch')"
                            type="text"
                            dense
                            v-model="selections.brchid"
                            :items="branchList"
                            
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.division')"
                            type="text"
                            dense
                            v-model="selections.divnid"
                            :items="divisionList"
                            
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.department')"
                            type="text"
                            dense
                            v-model="selections.deptid"
                            :items="departmentList"
                          
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.section')"
                            type="text"
                            dense
                            v-model="selections.sectioid"
                            :items="sectionList"
                            
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.unit')"
                            type="text"
                            dense
                            v-model="selections.unitid"
                            :items="unitList"
                          
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.employmenttype')"
                            type="text"
                            dense
                            v-model="selections.emptypeid"
                            :items="emptypeList"
                            
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.grade')"
                            type="text"
                            dense
                            v-model="selections.gradeid"
                            :items="gradeList"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.gradecategory')"
                            type="text"
                            dense
                            v-model="selections.gradecatid"
                            :items="gradecatList"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.job')"
                            type="text"
                            dense
                            v-model="selections.jobid"
                            :items="jobList"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" sm="5" md="5" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.position')"
                            type="text"
                            dense
                            v-model="selections.positionid"
                            :items="positionList"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <!-- <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeLeaveEntitlementPolicy">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveHandler">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            :items-per-page="10"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
        <div v-if="isErr">
          <app-error
            v-bind:parentError="{
              data: this.errMsg,
              isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
          />
        </div>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-error": ErrorDialog,
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },


            isBaitulmal: false,
            leaveentitlementdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                maxunitcf: null, 
                maxperccf: null, 
                maxpercentcf: null, 
                bfmth: null,
                uptosrv: null,
                compnyid: null,
                brchid: null,
                divnid: null,
                deptid: null,
                sectioid: null,
                unitid: null,
                emptypeid: null,
                gradeid: null,
                gradecatid: null,
                jobid: null,
                positionid: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId
            },
            companyList: [],
            branchList: [],
            divisionList: [],
            departmentList: [],
            sectionList: [],
            unitList: [],
            emptypeList: [],
            gradeList: [],
            gradecatList: [],
            jobList: [],
            positionList: [],

            isEdit: false,
            isCreate: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        loadCompanyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/company/allfilter/${this.$store.getters.userId}`})
                .then((res) => {
                let company = res.data.rec;

                    for (let i = 0; i < company.length; i++) {
                        vm.companyList.push({
                            text: company[i].compnyname,
                            value: company[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadBranchListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/branch/all"})
                .then((res) => {
                    let branch = res.data.rec;

                    for (let i = 0; i < branch.length; i++) {
                        vm.branchList.push({
                            text: branch[i].brchname,
                            value: branch[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadDivisionListHandler() {
        const vm = this;

        this.$store.dispatch('get', {url: "/division/all"})
            .then((res) => {
                let division = res.data.rec;

                for (let i = 0; i < division.length; i++) {
                    vm.divisionList.push({
                        text: division[i].divnname,
                        value: division[i].id,
                    });
                }
            })
            .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        },

        loadDepartmentListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/department/all"})
                .then((res) => {
                    let department = res.data.rec;

                    for (let i = 0; i < department.length; i++) {
                        vm.departmentList.push({
                            text: department[i].deptname,
                            value: department[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadSectionListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/section/all"})
                .then((res) => {
                let section = res.data.rec;

                    for (let i = 0; i < section.length; i++) {
                        vm.sectionList.push({
                            text: section[i].sectioname,
                            value: section[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadUnitListHandler() {
        const vm = this;

        this.$store.dispatch('get', {url: "/unit/all"})
            .then((res) => {
                let unit = res.data.rec;

                for (let i = 0; i < unit.length; i++) {
                    vm.unitList.push({
                        text: unit[i].unitname,
                        value: unit[i].id,
                    });
                }
            })
            .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        },

        loadEmploymentTypeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/emptypecat/all"})
                .then((res) => {
                    let emptypecat = res.data.rec;

                    for (let i = 0; i < emptypecat.length; i++) {
                        vm.emptypeList.push({
                            text: emptypecat[i].emptypecatdesc,
                            value: emptypecat[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadGradeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/grade/all"})
                .then((res) => {
                    let grade = res.data.rec;

                    for (let i = 0; i < grade.length; i++) {
                        vm.gradeList.push({
                            text: grade[i].grddesc,
                            value: grade[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadGradeCatListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/gradecat/all"})
                .then((res) => {
                    let gradecat = res.data.rec;

                    for (let i = 0; i < gradecat.length; i++) {
                        vm.gradecatList.push({
                            text: gradecat[i].grdcatdesc,
                            value: gradecat[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadJobListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/job/all"})
                .then((res) => {
                    let job = res.data.rec;

                    for (let i = 0; i < job.length; i++) {
                        vm.jobList.push({
                            text: job[i].jobtitle,
                            value: job[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        loadPositionListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/position/all"})
                .then((res) => {
                    let position = res.data.rec;

                    for (let i = 0; i < position.length; i++) {
                        vm.positionList.push({
                            text: position[i].posdesc,
                            value: position[i].id,
                        });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        modifyItemHandler(item) {
            // assign loaded data to selections array
            this.selections = item;
            this.selections.userid = this.$store.getters.userId

            this.leaveentitlementdialog = true;
        },

        deleteItemHandler(item) {

            const vm = this;
            if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
                return false;
            }
            if (confirm(`Remove ${item.maxunitcf} ?`)) {
                this.$store.dispatch('delete', {url:
                        "/leavecarryforward/" +
                        item.id +
                        "/" +
                        this.$store.getters.userId})
                    .then((res) => {
                        if (res.data.error) {
                            vm.isErr = !vm.isErr;
                            vm.errMsg = res.data.error;
                        } else {
                            this.closeLeaveEntitlementPolicy();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = err;
                    });
            }
        },

        closeLeaveEntitlementPolicy() {
            this.$emit('onload')
            this.leaveentitlementdialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },
        
        saveHandler() {
            const vm = this;
             if (this.selections.uptosrv == null || this.selections.maxunitcf == null ){
                 this.$dialog.alert(this.$t('fillinmaxunitcf')).then((dialog) => {
                console.log(dialog);
             }); }
             else {

            if (this.selections.id) {
                if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
                    return false;
                }
                this.$store.dispatch('put', {url: `/leavecarryforward/${vm.selections.id}`,
                        payload: this.selections})
                    .then((res) => {
                        if (res.data.error) {
                            vm.isErr = !vm.isErr;
                            vm.errMsg = res.data.error;
                        } else {
                            this.closeLeaveEntitlementPolicy();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                    });
            } else {
                if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                    return false;
                }
                this.$store.dispatch('post', {url: `/leavecarryforward/create`,
                        payload: this.selections})
                    .then((res) => {
                        if (res.data.error) {
                            vm.isErr = !vm.isErr;
                            vm.errMsg = res.data.error;
                        } else {
                            this.closeLeaveEntitlementPolicy();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                    });
            }
             }
        },

        resetSelections() {
            this.selections = {
                id: null,
                maxunitcf: null, 
                maxperccf: null, 
                maxpercentcf: null, 
                bfmth: null,
                uptosrv: null,
                compnyid: null,
                brchid: null,
                divnid: null,
                deptid: null,
                sectioid: null,
                unitid: null,
                emptypeid: null,
                gradeid: null,
                gradecatid: null,
                jobid: null,
                positionid: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }
        },
    },

    created() {
        this.loadCompanyListHandler();
        this.loadBranchListHandler();
        this.loadDivisionListHandler();
        this.loadDepartmentListHandler();
        this.loadSectionListHandler();
        this.loadUnitListHandler();
        this.loadEmploymentTypeListHandler();
        this.loadGradeListHandler();
        this.loadGradeCatListHandler();
        this.loadJobListHandler();
        this.loadPositionListHandler();
    }
}
</script>