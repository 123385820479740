<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
          <!-- <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2">Export Process Log</v-col>
          </v-row>-->

          <v-container>
            <v-row>
              <v-col cols="6" sm="6" class="orange lighten-5">
                <v-row>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="this.$t('reportlog.processed2')"
                      type="text"
                      v-model="listErr.processed"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="this.$t('reportlog.staffprocessed')"
                      type="text"
                      v-model="listErr.staffprocessed"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="this.$t('reportlog.errors')"
                      type="text"
                      v-model="listErr.errors"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-text-field
                      :label="this.$t('reportlog.deleted')"
                      type="text"
                      v-model="listErr.deleted"
                      class="custom"
                      readonly
                      dense
                      background-color="#FFF3E0"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.dataItems"
                :options="tableOpt"
                hide-default-footer
                item-key="errorlog"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="returnHandler">{{$t('previewpayroll.return')}}</v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      pageTitle: this.$t('previewpayroll.payrollprocesslog'),
      dataHeader: [
        { text: this.$t('columns.no'), value: "index", width: "100px", sortable: true },
        { text: this.$t('columns.name'), value: "name", width: "300px", sortable: true },
        {
          text: this.$t('columns.staffcode'),
          value: "staffid",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('statutoryexportcommonused.errormessage'),
          value: "message",
          width: "300px",
          sortable: true,
        },
      ],
      dataItems: [],
      listErr: {
        processed: null,
        staffprocessed: null,
        deleted: null,
        errors: null,
      },
      item: this.$route.params.item,
      tableOpt: {
        itemsPerPage: -1,
      },
      errMsg: "",
      isErr: false,
    };
  },

  methods: {
    getError() {
      const vm = this;
      this.$store.dispatch('get', {url: "/payroll/process/payrollprocesslog"})
        .then((res) => {
          console.log(res);

          if (res.data.result.hdr.length > 0) {
            this.listErr.processed = res.data.result.hdr[0].totalprocessed;
            this.listErr.staffprocessed = res.data.result.hdr[0].staffprocessed;
            this.listErr.deleted = res.data.result.hdr[0].deleted;
            this.listErr.errors = res.data.result.hdr[0].errors;
          }

          if (res.data.result.details.length > 0) {
            this.dataItems = res.data.result.details;
            for (let i = 0; i < this.dataItems.length; i++) {
              this.dataItems[i].index = i + 1;
            }
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });

      // this.listErr.processed = this.item.processed;
      // this.listErr.staffprocessed = this.item.staffprocessed;
      // this.listErr.deleted = this.item.deleted;
      // this.listErr.errors = this.item.errors;

      // if (this.item.accounterr.length > 0) {
      //   this.dataItems = this.item.accounterr;
      //   for (let i = 0; i < this.dataItems.length; i++) {
      //     this.dataItems[i].index = i + 1;
      //   }
      // }

      // if (this.item.negErrMsg) {
      //   this.dataItems.push({
      //     errormsg: this.item.negErrMsg,
      //   });
      //   for (let i = 0; i < this.dataItems.length; i++) {
      //     this.dataItems[i].index = i + 1;
      //   }
      // }
    },

    returnHandler() {
      this.$router.push({ name: "payrollrunprocess" });
    },
  },
  mounted() {
    this.getError();
  },
};
</script>