<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}<span class="page_title_value">{{ this.header.companyname }}</span></h2>
                </v-row>

                <v-col class="mt-n4 mb-2"> 
                    <v-row> 
                        <v-btn
                        @click="backButton()"
                        color="orange"
                        class="white--text"
                        >{{$t('button.back')}}</v-btn>
                    </v-row>
                </v-col>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                  <v-toolbar flat >
                    <v-toolbar-title>
                        <div class="title-1 "> <span class="blue--text"> {{this.header.payoutname}}</span></div> 
                        <div> {{$t('payrollsymmarybycompanyreport.payrollsumforyear')}}
                            <span v-if="this.data.formData.pyyear" class="blue--text"> {{this.data.formData.pyyear}}</span> 
                            <span v-if="this.data.formData.pymonth" >, {{$t('reportsparam.month')}}  <span class="blue--text"> {{this.data.formData.pymonth}} </span></span></div>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-avatar class="mr-5" size="40" tile>
                      <img
                          :src="`${publicPath}images/export_pdf.png`"
                          @click="printPDF">
                    </v-avatar>

                    <v-avatar size="40" tile>
                      <img
                          :src="`${publicPath}images/export_csv.png`"
                          @click="getCSV">
                    </v-avatar>
                      
                  </v-toolbar>
                </v-row>


                <v-row>
                    <v-col class="mt-n6">
                        <!-- <v-data-table
                            :headers="dataHeader"
                            hide-default-footer
                            no-data-text="I need to hide this"
                        >
                            <template slot="no-data">
                                <div/>
                            </template>

                        </v-data-table> -->

                        <v-row class="blue--text mt-8 pl-8 mb-2">
                            {{$t('payrollsymmarybycompanyreport.earning')}}
                        </v-row>

                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItemsEarning"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in dataItemsEarning" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td>{{item.payitemcode}}</td>
                                    <td><v-layout justify-end>{{item.amount}}</v-layout></td> 
                                </tr>
                            </tbody>
                        </template></v-data-table>

                        <v-toolbar flat color="#DCDCDC" dense>
                            <v-col cols="9" sm="9"></v-col>
                            <v-col cols="2" sm="2" class="ml-8">{{$t('payrollsymmarybycompanyreport.subtotalforearning')}}</v-col>
                            <v-col cols="2" sm="2" class="ml-n2">
                                {{this.earningTotal}}
                            </v-col>
                        </v-toolbar>

                        <v-row class="blue--text mt-8 pl-8 mb-2">
                            {{$t('payrollsymmarybycompanyreport.deduction')}}

                        </v-row>

                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItemsDeduction"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in dataItemsDeduction" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td>{{item.payitemcode}}</td>
                                    <td><v-layout justify-end>{{item.amount}}</v-layout></td>
                                </tr>
                            </tbody>
                        </template></v-data-table>

                        <v-toolbar flat color="#DCDCDC" dense>
                            <v-col cols="9" sm="9"></v-col>
                            <v-col cols="2" sm="2" class="ml-6">{{$t('payrollsymmarybycompanyreport.subtotalfordeduction')}}</v-col>
                            <v-col cols="2" sm="2" class="ml-0">
                                    {{this.deductionTotal}}
                            </v-col>
                        </v-toolbar>

                        <v-row class="blue--text mt-8 pl-8 mb-2">
                           {{$t('payrollsymmarybycompanyreport.staffstatdeduct')}}

                        </v-row>

                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItemsStaffS"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in dataItemsStaffS" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td>{{item.payitemcode}}</td>
                                    <td><v-layout justify-end>{{item.amount}}</v-layout></td> 
                                </tr>
                            </tbody>
                        </template></v-data-table>

                        <v-toolbar flat color="#DCDCDC" dense>
                            <v-col cols="8" sm="8"></v-col>
                            <v-col cols="3" sm="3" class="ml-6">{{$t('payrollsymmarybycompanyreport.subtotalforstaffstatutorydeduction')}}</v-col>
                            <v-col cols="2" sm="2" class="ml-1">
                                    {{this.staffdeductionTotal}}
                            </v-col>
                        </v-toolbar>

                        <v-row class="blue--text mt-8 pl-8 mb-2">
                           {{$t('payrollsymmarybycompanyreport.compstatdeduct')}}
                        </v-row>
                    
                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItemsEarning"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in dataItemsCompanyS" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td>{{item.payitemcode}}</td>
                                    <td><v-layout justify-end>{{item.amount}}</v-layout></td> 
                                </tr>
                            </tbody>
                        </template></v-data-table>

                        <v-toolbar flat color="#DCDCDC" dense>
                            <v-col cols="8" sm="8"></v-col>
                            <v-col cols="4" sm="4" class="ml-n2">{{$t('payrollsymmarybycompanyreport.subtotalforcompanystatutorydeduction')}}</v-col>
                            <v-col cols="2" sm="2" class="ml-n12">
                                    {{this.companydeductionTotal}}
                            </v-col>
                        </v-toolbar>

                        <v-toolbar flat color="#faead8" dense>
                            <v-col cols="10" sm="10"></v-col>
                            <v-col cols="2" sm="2" class="ml-n12">{{$t('previewpayroll.netpay')}}</v-col>
                            <v-col cols="2" sm="2" class="ml-n6">
                                {{this.netTotal}}
                            </v-col>
                        </v-toolbar>

                    </v-col>
                </v-row>

                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            pageTitle: this.$t('payrollsymmarybycompanyreport.payrollsummarybycompany'),
            pageTitleValue: '',
            
            data: {
                formData: {
                    pyyear: this.$route.params.formData.pyyear,
                    pymonth: this.$route.params.formData.pymonth,
                    poid: this.$route.params.formData.poid,
                    compnyid: this.$route.params.formData.compnyid
                },

                parameters: {
                    brchid: this.$route.params.param.branchid,
                    //compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    gradeid: this.$route.params.param.gradeid,
                    staffid: this.$route.params.param.staffid,
                },
            },

            header: {
                companyname: null,
                payoutname: null,
            },

            dataHeader: [
                {text: this.$t('columns.no'),          width: '20px',  value: 'salhdid'},
                {text: this.$t('setuppayitem.payitem'),    width: '400px', value: 'payitemdesc'},
                {text: this.$t('setuppersonpayrolldirectassignment.amount'),      width: '50px',  value: 'amount', align: 'end'}
            ],

            tableOpt: {
                itemsPerPage: -1
            },

            earningTotal:  null,
            deductionTotal:  null,
            staffdeductionTotal: null,
            companydeductionTotal: null,
            netTotal: null,

            dataItemsEarning: [],
            dataItemsDeduction: [],
            dataItemsStaffS: [],
            dataItemsCompanyS: [],

            checkearning:  false,
            checkdeduction:  false,
            checkstaffdeduction: false,
            checkcompanydeduction: false,
        }
    },

    methods: {

        backButton(){
            this.$router.push({name: 'parametercompanysummary'});
        },

        loadEarning() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollsummarycompanyEarning/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                vm.dataItemsEarning = res.data.rec;
                let getamount = []

                for (let i = 0; i < vm.dataItemsEarning.length; i++) {
                    vm.dataItemsEarning[i].id = i + 1;
                    getamount.push(parseFloat(vm.dataItemsEarning[i].amount.replace(/,/g, '')));
                }
                

                let getearning = getamount.reduce(function(a, b) {
                    return a + b;
                }, 0);

                getearning = getearning.toFixed(2)

                vm.earningTotal = getearning.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                vm.checkearning = true
            })
            .catch(function (err) {
                vm.errMsg = err;
            });
        },

        loadDeduction() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollsummarycompanyDeduction/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                vm.dataItemsDeduction = res.data.rec;
                let getamount = []

                for (let i = 0; i < vm.dataItemsDeduction.length; i++) {
                    vm.dataItemsDeduction[i].id = i + 1;
                    getamount.push(parseFloat(vm.dataItemsDeduction[i].amount.replace(/,/g, '')));
                }

                let getdeduction = getamount.reduce(function(a, b) {
                    return a + b;
                }, 0);

                getdeduction = getdeduction.toFixed(2)

                vm.deductionTotal = getdeduction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                vm.checkdeduction = true
            })
            .catch(function (err) {
                vm.errMsg = err;
            });
        },

        loadStaffS() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollsummarycompanyStaffS/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                vm.dataItemsStaffS = res.data.rec;
                let getamount = []

                for (let i = 0; i < vm.dataItemsStaffS.length; i++) {
                    vm.dataItemsStaffS[i].id = i + 1;
                    getamount.push(parseFloat(vm.dataItemsStaffS[i].amount.replace(/,/g, '')));
                }

                let getstaffdeduction = getamount.reduce(function(a, b) {
                    return a + b;
                }, 0);

                getstaffdeduction = getstaffdeduction.toFixed(2)

                vm.staffdeductionTotal = getstaffdeduction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                vm.checkstaffdeduction = true
            })
            .catch(function (err) {
                vm.errMsg = err;
            });
        },

        loadCompanyS() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollsummarycompanyCompanyS/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                vm.dataItemsCompanyS = res.data.rec;
                let getamount = []

                for (let i = 0; i < vm.dataItemsCompanyS.length; i++) {
                    vm.dataItemsCompanyS[i].id = i + 1;
                    getamount.push(parseFloat(vm.dataItemsCompanyS[i].amount.replace(/,/g, '')));
                }

                let getcompanydeduction = getamount.reduce(function(a, b) {
                    return a + b;
                }, 0);

                getcompanydeduction = getcompanydeduction.toFixed(2)

                vm.companydeductionTotal = getcompanydeduction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                vm.checkcompanydeduction = true
            })
            .catch(function (err) {
                vm.errMsg = err;
            });
        },
        
        loadNetTotal() {
            setTimeout(() => {
                const vm = this
                if (vm.checkearning == true && vm.checkdeduction == true &&
                    vm.checkstaffdeduction == true && vm.checkcompanydeduction == true) {
                    const vm = this;

                    let getamount = [
                        parseFloat(vm.earningTotal.replace(/,/g, '')),
                        parseFloat(vm.deductionTotal.replace(/,/g, '')),
                        parseFloat(vm.staffdeductionTotal.replace(/,/g, '')),
                    ]

                    let getnettotal = getamount.reduce(function(a, b) {
                        return a - b;
                    });

                    getnettotal = getnettotal.toFixed(2)

                    vm.netTotal = getnettotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
            }, 1000);
        },

        loadCompany() {
            const vm = this;
            this.$store.dispatch('get', {url: '/company/' + vm.data.formData.compnyid})
            .then(res => {
                let company = res.data.rec[0];
                vm.header.companyname = company.compnyname
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadPayoutControl() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/" + vm.data.formData.poid})
            .then((res) => {
                let payout = res.data.rec[0];
                vm.header.payoutname = payout.payoutdesc
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        printPDF() {

            const doc = new jsPDF({
                orientation: 'landscape'
            })

            var head = [
                { title: this.$t('columns.no'),         dataKey: 'salhdid' },
                { title: this.$t('payrollsymmarybycompanyreport.type'),       dataKey: 'type' },
                { title: this.$t('setuppayitem.payitem'),   dataKey: 'payitemdesc' },
                { title: this.$t('setuppersonpayrolldirectassignment.amount'),     dataKey: 'amount' },
                { title: this.$t('bankpaymentlistingreport.total'),      dataKey: 'total' },
                { title: this.$t('payrollsymmarybycompanyreport.nettotal'),  dataKey: 'nettotal' },
            ]

            let body = []

            for (var i = 0; i < this.dataItemsEarning.length; i++) {
                body.push({
                    salhdid : this.dataItemsEarning[i].id,
                    type : "Earning",
                    payitemdesc : this.dataItemsEarning[i].payitemcode,
                    amount : this.dataItemsEarning[i].amount
                })
            }
            body.push({
                total : this.earningTotal,
            })

            for (i = 0; i < this.dataItemsDeduction.length; i++) {
                body.push({
                    salhdid : this.dataItemsDeduction[i].id,
                    type : "Deduction",
                    payitemdesc : this.dataItemsDeduction[i].payitemcode,
                    amount : this.dataItemsDeduction[i].amount
                })
            }
            body.push({
                total : this.deductionTotal,
            })

            for (i = 0; i < this.dataItemsStaffS.length; i++) {
                body.push({
                    salhdid : this.dataItemsStaffS[i].id,
                    type : "Staff Statutory",
                    payitemdesc : this.dataItemsStaffS[i].payitemcode,
                    amount : this.dataItemsStaffS[i].amount
                })
            }
            body.push({
                total : this.staffdeductionTotal,
            })

            for (i = 0; i < this.dataItemsCompanyS.length; i++) {
                body.push({
                    salhdid : this.dataItemsCompanyS[i].id,
                    type : "Company Statutory",
                    payitemdesc : this.dataItemsCompanyS[i].payitemcode,
                    amount : this.dataItemsCompanyS[i].amount
                })
            }
            body.push({
                total : this.companydeductionTotal,
            })
            
            body.push({
                nettotal : this.netTotal,
            })
            
            doc.text(this.$t('payrollsymmarybycompanyreport.payrollsummarybycompany') + this.header.companyname + '-' +  this.header.payoutname, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.$t('payrollsymmarybycompanyreport.payrollsummarybycompany') + this.header.companyname + '-' + this.header.payoutname + '.pdf')
        },

        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.$t('payrollsymmarybycompanyreport.payrollsummarybycompany') + this.header.companyname + '-' + this.header.payoutname + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let itemE = this.dataItemsEarning
            let itemD = this.dataItemsDeduction
            let itemS = this.dataItemsStaffS
            let itemC = this.dataItemsCompanyS
            let data = []

            for (var i = 0; i < itemE.length; i++) {

            data.push({
                [`${this.$t('columns.no')}`]: itemE[i].id,
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: "Earning",
                [`${this.$t('setuppayitem.payitem')}`]: itemE[i].payitemcode,
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemE[i].amount,
                 [`${this.$t('bankpaymentlistingreport.total')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
                })
            }

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: '',
                [`${this.$t('setuppayitem.payitem')}`]: '',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: '',
                 [`${this.$t('bankpaymentlistingreport.total')}`]: this.earningTotal,
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
            })

            for ( i = 0; i < itemD.length; i++) {
                data.push({
                    [`${this.$t('columns.no')}`]: itemD[i].id,
                    [`${this.$t('payrollsymmarybycompanyreport.type')}`]: "Deduction",
                    [`${this.$t('setuppayitem.payitem')}`]: itemD[i].payitemcode,
                     [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemD[i].amount,
                     [`${this.$t('bankpaymentlistingreport.total')}`]: '',
                    [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
                })
            }

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: '',
                [`${this.$t('setuppayitem.payitem')}`]: '',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: '',
                 [`${this.$t('bankpaymentlistingreport.total')}`]: this.deductionTotal,
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
            })

            for ( i = 0; i < itemS.length; i++) {
                data.push({
                    [`${this.$t('columns.no')}`]: itemS[i].id,
                    [`${this.$t('payrollsymmarybycompanyreport.type')}`]: "Staff Statutory Deduction",
                    [`${this.$t('setuppayitem.payitem')}`]: itemS[i].payitemcode,
                     [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemS[i].amount,
                     [`${this.$t('bankpaymentlistingreport.total')}`]: '',
                    [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
                })
            }

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: '',
                [`${this.$t('setuppayitem.payitem')}`]: '',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: '',
                 [`${this.$t('bankpaymentlistingreport.total')}`]: this.staffdeductionTotal,
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
            })

            for ( i = 0; i < itemC.length; i++) {
                data.push({
                   [`${this.$t('columns.no')}`]: itemC[i].id,
                    [`${this.$t('payrollsymmarybycompanyreport.type')}`]: "Company Statutory Deduction",
                    [`${this.$t('setuppayitem.payitem')}`]: itemC[i].payitemcode,
                     [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: itemC[i].amount,
                     [`${this.$t('bankpaymentlistingreport.total')}`]: '',
                    [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
                })
            }

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: '',
                [`${this.$t('setuppayitem.payitem')}`]: '',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: '',
                 [`${this.$t('bankpaymentlistingreport.total')}`]: this.companydeductionTotal,
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: ''
            })

            data.push({
                [`${this.$t('columns.no')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.type')}`]: '',
                 [`${this.$t('setuppayitem.payitem')}`]: '',
                 [`${this.$t('setuppersonpayrolldirectassignment.amount')}`]: '',
                [`${this.$t('bankpaymentlistingreport.total')}`]: '',
                [`${this.$t('payrollsymmarybycompanyreport.nettotal')}`]: this.netTotal
            })

            let csvData = this.objectToCSV(data)
            this.download(csvData)
        },

    },

    mounted() {
        this.loadEarning();
        this.loadDeduction();
        this.loadStaffS();
        this.loadCompanyS();
        this.loadNetTotal();
        this.loadCompany();
        this.loadPayoutControl();
    }
    
}
</script>

<style>

.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}

.transparent{
    margin-bottom: 1px;
}

</style>