<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>
        <v-card>
        <v-row align="center"
          justify="space-around">
          <h2 class="pb-5">{{this.wizardcodename}}</h2>
        </v-row>

        <v-row align="center"
        justify="space-around"
        >
          <h3 class="pb-5">{{this.wizarddescname}}</h3>
        </v-row>
        </v-card>
        <br/>

         <v-stepper v-model="e1">
           <v-stepper-header>
             <template v-for ="item in wizardStep" >
               <v-stepper-step
               :key="item.seqno"
               :complete ="e1>item"
               :step="item.seqno"
               :key-item= "seqno"
               editable
               >
               {{$t('reporwizardlisting.step')}} {{item.seqno}}
               </v-stepper-step>

              <v-divider
              v-if ="item.seqno !== steps"
              :key ="item.seqno"
              ></v-divider>
             </template>
           </v-stepper-header>

           <v-stepper-items>
             <v-stepper-content
             v-for="item in wizardStep"
             :key="item.seqno"
             :step="item.seqno"
             > 
             <br/>
             <br/>
                  <v-row
                    style="font-size:13px"
                    align="start"
                    class="mx-0 pb-6"
                    height="200px" >
                      {{item.taskdesc}} 

                  </v-row> 
                       
             <br/>

                  <v-row
                  style="font-size:13px; font-weight: bold"
                    align="start"
                    class="mx-0 pb-3"
                    height="200px"
                        v-on:click ="loadDetailHandlerLink(item.link)"> {{item.funcname}}
                  </v-row>

              </v-stepper-content>
            </v-stepper-items>
          </v-stepper> 
              
          <br/>
          <br/>
          <v-row
            align="center"
            justify="space-around">
              <v-btn
              color="primary"
              style="width: 100px" 
              @click="prevStep()"
              >{{$t('button.previous')}}</v-btn>

            <v-btn 
              color="success" 
              style="width: 100px"
              @click="nextStep()" 
              >{{$t('button.next')}}</v-btn>

            <v-btn
              color="warning" 
              style="width: 100px" 
              @click="resetStep()"
              >{{$t('button.reset')}}</v-btn>
            </v-row>      
      </v-form>
    </v-container>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div>
      <v-content>
        <name/>
      </v-content>
    </div>
  </div>
</template>

<script>
import VStepper from 'vue-stepper-component';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
     "name" : VStepper
  },

  data() {
    return {      
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

     e1: 0,
     steps: this.seqno,
     getid: this.$route.params.id,
     wizardcodename: ' ',
     wizarddescname: ' ',
     


       selections: [{
        wizardid: null,
        wizardcode: null,
        wizarddesc: null,
        seqno: null,
        taskdesc: null,
        link: null,
        funcname: null,
        userid: this.$store.getters.userId
       }],

      wizardItems: [],
      dataItems: [],
      wizardStep: [],
      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  watch:{

         steps(val){
            if (this.e1>val){
               this.e1 = val
               console.log('e1', this.e1)
            }
          },
       },

  methods: {

        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadWizardList() {
            const vm = this;
          if(this.getid)
             this.$store.dispatch('get', {url: '/wizardsetupd/'+ this.getid})
            .then (res => {
              vm.wizardItems = res.data.rec;
              vm.steps = vm.wizardItems.length;
              vm.wizardcodename = res.data.rec[0].wizardcode;
              vm.wizarddescname = res.data.rec[0].wizarddesc;
             
               for (let i = 0; i < res.data.rec.length; i++){
                
                 vm.wizardStep.push(
                             {
                                 seqno: res.data.rec[i].seqno,
                                 taskdesc: res.data.rec[i].taskdesc,
                                 link: res.data.rec[i].link,
                                 funcname: res.data.rec[i].funcname
                             })
                  vm.loading = false;
               }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        //push to next step
        nextStep() {
          this.e1++;
        },

        //push to previous step
        prevStep() {
          this.e1--;
        },
         //reset to the first step
        resetStep(){
            this.e1 = 1

        },

        loadDetailHandlerLink(link) {
            this.$router.push({name: link});
        },

         backHandler() {
            this.$router.push({name: 'wizardlisting'});
        },

   },

   mounted() {
     this.loadWizardList();
   },
};
</script>