<template>
    <div>
        <v-container>
            <v-form>
                
                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>
                
                <v-container 
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                    
                >

                
                <v-row> 
                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat>
                            
                                <v-toolbar-title>{{$t('payslipreport.payslipfor')}} <span class="textBlue"> {{this.formData.year}}/{{this.month}}{{this.payoutreceived}}  </span> 
                                <div class="subtitle-2 blue--text">{{this.personinfos[0].compnyname}}</div>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                
                            </v-toolbar>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat >
                                <v-toolbar-title> <span class="blue--text"> {{this.personinfos[0].staffcode}} - {{this.personinfos[0].staffname}} </span>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="printPDF">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                                
                            </v-toolbar>
                    </v-col>
                </v-row>

                <v-row >
                    <v-col  col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat class="payslipTitle">
                            <v-toolbar-title style="margin-top:20px" class="payslipFont"> 
                                {{this.month}} - {{this.formData.year}}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            
                                <v-toolbar-title style="margin-top:20px" class="payslipFont"> {{$t('payslipreport.payslip')}}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                
                            </v-toolbar>
                    </v-col>
                </v-row>


                <v-row>
                    
                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-data-table
                            :headers="tabHeader"
                            :items="this.detailItems"
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        <template v-slot:top> 

                            <div style="margin: 20px">
                                <v-row class="pb4-4 mt-n5" >
                                    <v-col cols="1" sm="1" >
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.name') "
                                            type="text"
                                            dense
                                            disabled>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            type="text"
                                            v-model="staffdetail.staffname"
                                            dense
                                            disabled>
                                        </v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row class="pb4-4 mt-n10 " >
                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('recfamdetails.icno') "
                                            type="text"
                                            ref="icno"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail.icno"
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.efpno') "
                                            type="text"
                                            ref="staffid"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail.epfrefno"
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="pb4-4 mt-n10" >
                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.staffid') "
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail.staffcode"
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.taxno') "
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            
                                            type="text"
                                            v-model="staffdetail.taxrefno"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="pb4-4 mt-n10" >
                                    <v-col cols="" sm="1" >
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.company') "
                                            type="text"
                                            
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail.compnyname"
                                            type="text"
                                            
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.socsono') "
                                            type="text"
                                            
                                            dense
                                            disabled
                                            >
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail.socsorefno"
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </div>


                        </template>
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in detailItems" :key="item">
                                    <td >
                                        {{item.no}}
                                    </td>
                                    <td >
                                        {{item.itemType}}
                                    </td>
                                
                                    <td >
                                        {{item.amountPositive}}
                                    </td>
                               
                                    <td >
                                        {{item.amountNegative}}
                                    </td>
                           
                                </tr>
                                <tr class="difference">
                                    <td></td>
                                    <td></td>
                                    <td>{{amountTotalPositive}}</td>
                                    <td>{{amountTotalNegative}}</td>
                                </tr>

                                <tr class="difference">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{amountDifference}}</td>
                                </tr>

                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-card  elevation="5"  outlined height="200px" > 
                            <div class="blue--text" style="margin: 20px">{{$t('payslipreport.compcontribution')}}</div>

                            <v-row>
                    <v-col col="12" sm="12" class="pb4-4 mt-n8" >
                        <v-data-table
                            :headers="companyTabHeader"
                            
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        
                        <template v-slot:body>
                            <tbody>
                                

                                <tr>
                                    <td>{{$t('payslipreport.myeiser')}}</td> 
                                    <td>7.90</td> 
                                    <td>7.90</td> 
                                </tr>

                                <tr>
                                    <td>{{$t('payslipreport.myepfer')}}</td>
                                    <td>1524.00</td> 
                                    <td>1524.00</td> 
                                </tr>

  
                                <tr>
                                    <td>{{$t('payslipreport.mysocsoer')}}</td>  
                                    <td>69.05</td> 
                                    <td>69.05</td>   
                                </tr>
                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card  elevation="5"  outlined height="200px"> 
                            <div class="blue--text" style="margin: 20px">{{$t('payslipreport.message')}}</div>
                            
                            <v-row>
                            <v-col col="12" sm="12" class="pb4-4 mt-n10" >
                                <v-textarea class="custom" style="padding: 20px"
            
                                    v-model="staffdetail.message"
            
                                ></v-textarea>
                            </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                

                <v-row>
                    <v-col col="12" sm="12" >
                        <v-toolbar flat >
                                <v-toolbar-title> <span class="blue--text"> {{this.personinfos[4].staffcode}} - {{this.personinfos[4].staffname}} </span>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="printPDF">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                                
                            </v-toolbar>
                    </v-col>
                </v-row>

                <v-row >
                    <v-col  col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat class="payslipTitle">
                            <v-toolbar-title style="margin-top:20px" class="payslipFont"> 
                                {{this.month}} - {{this.formData.year}}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            
                                <v-toolbar-title style="margin-top:20px" class="payslipFont"> {{$t('payslipreport.payslip')}}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                
                            </v-toolbar>
                    </v-col>
                </v-row>


                <v-row>
                    
                    <v-col col="12" sm="12" >
                        <v-data-table
                            :headers="tabHeader"
                            :items="this.detailItems"
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        <template v-slot:top> 

                            <div style="margin: 20px">
                                <v-row class="pb4-4 mt-n5" >
                                    <v-col cols="1" sm="1" >
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.name') "
                                            type="text"
                                            dense
                                            disabled>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            type="text"
                                            v-model="staffdetail1.staffname"
                                            dense
                                            disabled>
                                        </v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row class="pb4-4 mt-n10 " >
                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('recfamdetails.icno') "
                                            type="text"
                                            ref="icno"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail1.icno"
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.efpno') "
                                            type="text"
                                            ref="staffid"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail1.epfrefno"
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="pb4-4 mt-n10" >
                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.staffid') "
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail1.staffcode"
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.taxno') "
                                            type="text"
                                            ref="psnname"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            
                                            type="text"
                                            v-model="staffdetail1.taxrefno"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="pb4-4 mt-n10" >
                                    <v-col cols="" sm="1" >
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('columns.company') "
                                            type="text"
                                            
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col sm="6">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail1.compnyname"
                                            type="text"
                                            
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="1" sm="1">
                                        <v-text-field
                                            class="custom" 
                                            :label="this.$t('payslipreport.socsono') "
                                            type="text"
                                            
                                            dense
                                            disabled
                                            >
                                           
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="" sm="3">
                                        <v-text-field
                                            class="custom" 
                                            v-model="staffdetail1.socsorefno"
                                            type="text"
                                            dense
                                            disabled>
                                           
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                            </div>


                        </template>
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in detailItems1" :key="item">
                                    <td >
                                        {{item.no}}
                                    </td>
                                    <td >
                                        {{item.itemType}}
                                    </td>
                                
                                    <td >
                                        {{item.amountPositive}}
                                    </td>
                               
                                    <td >
                                        {{item.amountNegative}}
                                    </td>
                           
                                </tr>
                                <tr class="difference">
                                    <td></td>
                                    <td></td>
                                    <td>{{amountTotalPositive}}</td>
                                    <td>{{amountTotalNegative}}</td>
                                </tr>

                                <tr class="difference">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{amountDifference}}</td>
                                </tr>

                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <v-card  elevation="5"  outlined height="200px" > 
                            <div class="blue--text" style="margin: 20px">{{$t('payslipreport.compcontribution')}}</div>

                            <v-row>
                    <v-col col="12" sm="12" class="pb4-4 mt-n8" >
                        <v-data-table
                            :headers="companyTabHeader"
                            
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >
                        
                        <template v-slot:body>
                            <tbody>
                                

                                <tr>
                                    <td>{{$t('payslipreport.myeiser')}}</td> 
                                    <td>7.90</td> 
                                    <td>7.90</td> 
                                </tr>

                                <tr>
                                    <td>{{$t('payslipreport.myepfer')}}</td>
                                    <td>660.00</td> 
                                    <td>660.00</td> 
                                </tr>

  
                                <tr>
                                    <td>{{$t('payslipreport.mysocsoer')}}</td>  
                                    <td>69.95</td> 
                                    <td>69.95</td>   
                                </tr>
                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card  elevation="5"  outlined height="200px" > 
                            <div class="blue--text" style="margin: 20px">{{$t('payslipreport.compcontribution')}}</div>

                            <v-row>
                            <v-col col="12" sm="12" class="pb4-4 mt-n10" >
                                <v-textarea class="custom" style="padding: 20px"
            
                                    v-model="staffdetail1.message"
            
                                ></v-textarea>
                            </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>

                
                </v-container>


                

            </v-form>
        </v-container>
    </div>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },


            tableOpt: {
                itemsPerPage: -1
            },

            staffdetail: {
                staffname: this.parentObject.data.staffname,
                icno: this.parentObject.data.icno,
                epfrefno: this.parentObject.data.epfrefno,
                staffcode: this.parentObject.data.staffcode,
                taxrefno: this.parentObject.data.taxrefno,
                compnyname: this.parentObject.data.compnyname,
                soscorefno: this.parentObject.data.socsorefno,
                message: this.parentObject.data.message,
            },

            staffdetail1: {
                staffname: this.parentObject.data.staffname,
                icno: this.parentObject.data.icno,
                epfrefno: this.parentObject.data.epfrefno,
                staffcode: this.parentObject.data.staffcode,
                taxrefno: this.parentObject.data.taxrefno,
                compnyname: this.parentObject.data.compnyname,
                soscorefno: this.parentObject.data.socsorefno,
                message: this.parentObject.data.message,
            },

          
            

            detailItems: [
                {
                    no: '1',
                    itemType: this.$t('payslipreport.monthlybasicsal'),
                    amountPositive: 11869.59,
                    amountNegative: null,
                },
                {
                    no: '2',
                    itemType: this.$t('payslipreport.carallowance'),
                    amountPositive: 800.00,
                    amountNegative: null,
                },
                {
                    no: '3',
                    itemType: this.$t('payslipreport.monthlytaxdeduct'),
                    amountPositive: 1695.55,
                    amountNegative: null,
                },
                {
                    no: '4',
                    itemType: this.$t('payslipreport.staffeis'),
                    amountPositive: 7.90,
                    amountNegative: null,
                },
                {
                    no: '5',
                    itemType: this.$t('payslipreport.staffepf'),
                    amountPositive: 1397.00,
                    amountNegative: null,
                },
                
                {
                    no: '7',
                    itemType: this.$t('payslipreport.staffsocso'),
                    amountPositive: 19.75,
                    amountNegative: null,
                },
                
            ],

            detailItems1: [
                {
                    no: '1',
                    itemType: this.$t('payslipreport.monthlybasicsal'),
                    amountPositive: 8000.00,
                    amountNegative: null,
                },
                {
                    no: '2',
                    itemType: this.$t('payslipreport.carallowance'),
                    amountPositive: 550.00,
                    amountNegative: null,
                },
                {
                    no: '3',
                    itemType: this.$t('payslipreport.monthlytaxdeduct'),
                    amountPositive: 610.85,
                    amountNegative: null,
                },
                {
                    no: '4',
                    itemType: this.$t('payslipreport.staffeis'),
                    amountPositive: 7.90,
                    amountNegative: null,
                },
                {
                    no: '5',
                    itemType: this.$t('payslipreport.staffepf'),
                    amountPositive: 880.00,
                    amountNegative: null,
                },
                
                {
                    no: '7',
                    itemType: this.$t('payslipreport.staffsocso'),
                    amountPositive: 19.75,
                    amountNegative: null,
                },
                
            ],


            tabHeader: [
                { text: this.$t('columns.no'), value: 'no', sortable: false, width: '5px' },
                { text: this.$t('payslipreport.item'), value: 'itemType', width: '700px', sortable: false},
                { text: this.$t('payslipreport.amt+ve'), value: 'amountPositive', sortable: false},
                { text: this.$t('payslipreport.amt-ve'), value: 'amountNegative', sortable: false },
            ],

            companyTabHeader: [
                { text: '', width: '200px', sortable: false},
                { text: this.$t('payslipreport.monthtodate'), value: 'monthtodate', sortable: false},
                { text: this.$t('payslipreport.monthtoyear'), value: 'monthtoyear', sortable: false },
            ],

            personinfos: [],
            staffids: [],


            payoutreceived: '',  
            month: '',
            amountTotalPositive: null,
            amountTotalNegative: null,
            amountDifference: null, 
            errMsg: '',
            isErr: false,
        }
    },

    methods: {
     
        backHandler() {
            this.$router.push({name: 'parameterpayslip'});
        },

        
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.detailItems

            var head = [                
                { title: this.$t('columns.no'), dataKey: 'no' },
                { title: this.$t('payslipreport.item'), dataKey: 'itemType' },
                { title: this.$t('payslipreport.amt+ve'), dataKey: 'amountPositive' },
                { title: this.$t('payslipreport.amt-ve'), dataKey: 'amountNegative' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    no: item[i].no,
                    item: item[i].itemType,
                    amountPositive: item[i].amountPositive,
                    amountNegative: item[i].amountNegative,

                })
            }
            doc.text(this.title, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.$t('payrolldashboardreport.payrollsummaryfor') + this.title + '.pdf')
        },

        format() {
            console.log(this.detailItems.length)
            
            for (let i = 0; i < this.detailItems.length; i++) {
                if (this.detailItems[i].amountPositive !== null) {
                    let getAmountPos = this.detailItems[i].amountPositive.toFixed(2);
                    this.detailItems[i].amountPositive = this.CommaFormatted(getAmountPos);
                }
            }

            for (let i = 0; i < this.detailItems.length; i++) {
                if (this.detailItems[i].amountNegative !== null) {
                    let getAmountNeg = this.detailItems[i].amountNegative.toFixed(2);
                    this.detailItems[i].amountNegative = this.CommaFormatted(getAmountNeg);
                }
            }
        },

        getTotal () {
            let subTotalPosArr =  [];
            let subTotalNegArr = [];

            for (let i = 0; i < this.detailItems.length; i++ ) {
                subTotalPosArr.push(this.detailItems[i].amountPositive)
            }
            let getSubTotalPos = subTotalPosArr.reduce(function(a, b) {
                return a + b;
            }, 0);
            let cValuePos = getSubTotalPos.toFixed(2);
            this.amountTotalPositive = this.CommaFormatted(cValuePos);
            console.log(this.amountTotalPositive)


            for (let i = 0; i < this.detailItems.length; i++ ) {
                subTotalNegArr.push(this.detailItems[i].amountNegative)
            }
            let subTotalNeg = subTotalNegArr.reduce(function(a, b) {
                return a + b;
            }, 0);
            let cValueNeg = subTotalNeg.toFixed(2);
            this.amountTotalNegative = this.CommaFormatted(cValueNeg);
            console.log(this.amountTotalNegative)

            let cValueDiff = (cValuePos - cValueNeg).toFixed(2);
            this.amountDifference = this.CommaFormatted(cValueDiff);
            console.log("value diff", cValueDiff)

        },

        CommaFormatted(amount) {
            var delimiter = ","; // replace comma if desired
            var a = amount.split('.',2)
            var d = a[1];
            var i = parseInt(a[0]);
            if(isNaN(i)) { return ''; }
            var minus = '';
            if(i < 0) { minus = '-'; }
            i = Math.abs(i);
            var n = new String(i);
            var b = [];
            while(n.length > 3) {
                var nn = n.substr(n.length-3);
                b.unshift(nn);
                n = n.substr(0,n.length-3);
            }
            if(n.length > 0) { b.unshift(n); }
            n = b.join(delimiter);
            if(d.length < 1) { amount = n; }
            else { amount = n + '.' + d; }
            amount = minus + amount;
            return amount;
        },

        // load  all person statutory info
        loadPersonStatutoryInfoHandler() {
        const vm = this;

        this.$store.dispatch('get', {url: "/personstatutoryinfo/all"})
            .then(res => {

                this.personinfos = res.data.rec;
                console.log("person ", res.data.rec)

                

                for (let i = 0; i < this.personinfos.length; i ++ ) {
                    if (!this.staffids.includes(this.personinfos[i].staffid)) {
                        this.staffids.push(this.personinfos[i].staffid)
                    }
                   
                   
                }

                this.staffdetail.staffname = this.personinfos[0].staffname;
                this.staffdetail.icno = this.personinfos[0].icno;
                this.staffdetail.epfrefno = this.personinfos[0].refno;
                this.staffdetail.staffcode = this.personinfos[0].staffcode;
                this.staffdetail.taxrefno = this.personinfos[1].refno;
                this.staffdetail.compnyname = this.personinfos[0].compnyname;
                this.staffdetail.socsorefno = this.personinfos[2].refno;

                this.staffdetail1.staffname = this.personinfos[4].staffname;
                this.staffdetail1.icno = this.personinfos[4].icno;
                this.staffdetail1.epfrefno = this.personinfos[7].refno;
                this.staffdetail1.staffcode = this.personinfos[4].staffcode;
                this.staffdetail1.taxrefno = this.personinfos[4].refno;
                this.staffdetail1.compnyname = this.personinfos[4].compnyname;
                this.staffdetail1.socsorefno = this.personinfos[5].refno;

                console.log("staffids", this.staffids)

            })
            .catch(err => {
            // request unsuccessfull - emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        },


    },


    mounted() {
        this.getTotal()
        this.format()
        
    },

    created(){
        const vm = this;
        vm.formData = this.$route.params.formData;
        console.log("formdata received" , vm.formData)

        if (vm.formData.month && vm.formData.month < 10) {
            vm.month = "0" + vm.formData.month
        }

        if (vm.formData.payout) {
            vm.payoutreceived = "/" + vm.formData.payout;
        }

        if (vm.formData.message) {
            vm.staffdetail1.message = vm.formData.message;
            vm.staffdetail.message = vm.formData.message;
        }

        

        this.loadPersonStatutoryInfoHandler();

        
        
    }
    
}
</script>

<style scoped>
.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: rgb(180, 118, 3);
    align-content: center;
    justify-content: center;
}
.dateContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 20px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    margin: 0px;
    min-height: 55px;
}
.payoutTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: grey;
    align-content: center;
    justify-content: center;
}
.payoutContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 10px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 50px;
}
.narTitle {
    align-content: center;
    justify-content: center;
    font-size: 12px;
    max-height: 30px;
}
.paidTitle {
    align-content: center;
    justify-content: left;
    font-size: 12px;
    max-height: 30px;
}
.paidContent {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    justify-content: center;
    color: #980000;
    margin: 0px;
    min-height: 70px;
}
.payslipFont {
    font-weight: 700;
    font-size: 24px;
    justify-content: center;
    color: #000000;
    margin: 0px;
    min-height: 54px;
    text-align: center;
}
.textBlue {
    color:  #004080;
}
.text1 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#004080;
}
.text2 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#653565;
}
.difference {
    background-color: #FFF3E0;
}
.payslipTitle {
    background-color:      #FECB9C 
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
    
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.textfield{
    text-align: center;
    color: black;
}

</style>
