<template>
    <div>

 <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>

    </div>
   
</template>


<script>

// import axios for sending requests and getting responses to server 
// import moment for date formatting 

// import moment from 'moment';
import GridView from '../../../components/common/GridView'; 

export default {
    
    components: {
        'app-gridview': GridView
    
    },  

    data() {

         return {
            pageTitle: this.$t('reccontactdetails.contactdetailslist'), 
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false },
                { text: this.$t('columns.name'), value: 'psnname', width: '150px' },
                { text: this.$t('columns.staffcode'), value: 'staffcode', width: '120px' },
                { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                // { text: 'EFFECTIVE DATE', value: 'effectivedate', width: '200px' },
                // { text: 'INVALID DATE', value: 'invaliddate', width: '200px' },
                { text: this.$t('columns.email'), value: 'email', width: '200px' },
                { text: this.$t('reccontactdetails.hp_no'), value: 'hpno', width: '150px' },
                { text: this.$t('reccontactdetails.alternativeemail'), value: 'alternativeemail', width: '200px' },
                { text: this.$t('reccontactdetails.externalno'), value: 'externalno', width: '200px' },
                { text: this.$t('reccontactdetails.correspondanceaddress_1'), value: 'correspondanceaddr1', width: '200px' },
                { text: this.$t('reccontactdetails.correspondanceaddress_2'), value: 'correspondanceaddr2', width: '200px' },
                { text: this.$t('reccontactdetails.correspondanceaddress_3'), value: 'correspondanceaddr3', width: '200px' },
                { text: this.$t('columns.city'), value: 'cityname', width: '200px' },
                { text: this.$t('columns.country'), value: 'cntryname', width: '200px' },
                { text: this.$t('columns.postcode'), value: 'poscode', width: '120px' },
                { text: this.$t('columns.telephone'), value: 'tel', width: '120px' },
                { text: this.$t('reccontactdetails.permanentaddress_1'), value: 'permanentaddr1', width: '200px' },
                { text: this.$t('reccontactdetails.permanentaddress_2'), value: 'permanentaddr2', width: '200px' },
                { text: this.$t('reccontactdetails.permanentaddress_3'), value: 'permanentaddr3', width: '200px' },
                { text: this.$t('columns.city'), value: 'permanentcityname', width: '200px' },
                { text: this.$t('columns.country'), value: 'permanentcntryname', width: '200px' },
                { text: this.$t('columns.postcode'), value: 'permanentposcode', width: '120px' },
                { text: this.$t('columns.telephone'), value: 'permanenttel', width: '120px' },
                { text: this.$t('reccontactdetails.emergencycontact_1'), value: 'emergencycontact1', width: '200px' },
                { text: this.$t('reccontactdetails.contact_1no'), value: 'contactno1', width: '120px' },
                { text: this.$t('reccontactdetails.emergencycontact_2'), value: 'emergencycontact2', width: '200px' },
                { text: this.$t('reccontactdetails.contact_2no'), value: 'contactno2', width: '120px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' },


            ],

            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }

    }, 

    methods: {

        // get all contact details records 
        getContactDetailsListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/contactdetails/all'})
                .then(function (res) {

                    // request --> success 
                    // response loaded into dataItems 

                    vm.dataItems = res.data.rec;

                    // moment to format date 
                    // for (let i = 0; i < vm.dataItems.length; i++) {
                    //     if (vm.dataItems[i].effectivedate) {
                    //         vm.dataItems[i].effectivedate = moment(vm.dataItems[i].effectivedate).format('YYYY-MM-DD');
                    //     }

                    //     if (vm.dataItems[i].invaliddate) {
                    //         vm.dataItems[i].invaliddate = moment(vm.dataItems[i].invaliddate).format('YYYY-MM-DD');
                    //     }
                    // }
                   
                    vm.loading = !vm.loading;
                   
                })
                // error - request --> unsuccessful 
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },
       
        editItemHandler(item) {
            this.$router.push({name: 'contactdetailsedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'contactdetailsnew'});
        }, 

    },

    mounted() {
       this.getContactDetailsListHandler();
    }
}
</script>