<template>
    <div>
      <div v-if="!loading">
        <app-form
            v-bind:parentObject="{
            data: this.formData[0],
            searchnew: this.search,
            pageTitle: this.pageTitle,
            pageTitleValue: this.pageTitleValue,
            errMsg: this.errMsg,
            isEdit: this.editMode,
            }"
            v-on:input="formData[0] = $event"
            v-on:new="createHandler"
            v-on:save="saveHandler"
            v-on:delete="isConfirm = !isConfirm"
            v-on:error="errorHandler"
        />

        <div v-if="isErr">
            <app-error
            v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>

        <div v-if="isConfirm">
            <app-confirm
            v-bind:parentObject="{
                pageTitleValue: this.pageTitleValue,
                isConfirm: this.isConfirm,
            }"
            v-on:confirm="deleteHandler($event)"
            v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import EdocForm from "../../../components/settings/edocument/Form.vue";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
    components: {
        "app-form": EdocForm,
        "app-error": ErrorDialog,
        "app-confirm": ConfirmDialog,
    },

    data() {
        return {
        id: this.$route.params.id,
        search: this.$route.params.search,
        pageTitle: 'Edit E-Document ',
        pageTitleValue: "",
        formData: [],
        errMsg: "",
        isErr: false,
        loading: true,
        editMode: true,
        isConfirm: false,
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        saveHandler() {
            
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
                return false;
            }

            if (this.formData[0].isdisable) {
                this.formData[0].isdisable = 0;
            } else {
                this.formData[0].isdisable = 1;
            }

            this.formData[0].userid = this.$store.getters.userId;

            var payload = this.formData[0];
            console.log('Save Detail', payload);

            this.$store.dispatch('put', {url: "/edoc/" + vm.id, payload: payload})
            .then((res) => {
                console.log("SUccess")
            if (res.data.error) {
                vm.isErr = !vm.isErr;
                vm.errMsg = res.data.error;
            } else {
                this.$router.push({ name: "edoclist", params: { search: this.search} });
            }
            })
            .catch((err) => {
                console.log("Fail")
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
            });

        },

        deleteHandler($event) {

            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
                return false;
            }

            this.isConfirm = !this.isConfirm;
            console.log(this.formData[0]);

            if ($event) {
                this.$store.dispatch('delete', {url:"/edoc/" + this.id + "/" + this.formData[0].updatedby})
                .then((res) => {
                    if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                    } else {
                    this.$router.push({ name: "edoclist" });
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }

        },

        createHandler() {
            this.$router.push({ name: "edocnew" });
        },

        loadEdocHandler() {

            const vm = this;
            console.log('id', this.id)

            this.$store.dispatch('get', {url: "/edoc/" + vm.id})
            .then((res) => {

            if (res.status == 200) {
                
                vm.formData = res.data.rec;

                console.log('ALL DATA', vm.formData)

                vm.pageTitleValue = vm.formData[0].doctypecode;
                vm.formData[0].doctype = vm.formData[0].doctypeid;
                vm.formData[0].desc = vm.formData[0].description;
                vm.formData[0].attachnew = vm.formData[1];
                vm.formData[0].attach = null;
                vm.formData[0].attachname = null;

                if (vm.formData[0].startdate) {
                    vm.formData[0].startdate = moment(vm.formData[0].startdate).format('YYYY-MM-DD');
                }

                if (vm.formData[0].enddate) {
                    vm.formData[0].enddate = moment(vm.formData[0].enddate).format('YYYY-MM-DD');
                }

                if (vm.formData[0].isdisable === 0) {
                    vm.formData[0].isdisable = true;
                } else {
                    vm.formData[0].isdisable = false;
                }

                vm.formData[0].updatedby = vm.$store.getters.userId;

                console.log('Form Data', vm.formData[0]);

                // setTimeout(() => {
                //     vm.loadEdocDetailsHandler();  
                // }, 3000);

                vm.loadEdocDetailsHandler();

                // vm.loading = !vm.loading;

            } else {
                vm.isErr = !vm.isErr;
            }
            })
            .catch((err) => {
                console.log(err);
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        loadEdocDetailsHandler() {
            const vm = this;

            console.log("Test");

            this.$store.dispatch('get', {url: "/edocdetails/" + this.id})
            .then((res) => {

            // vm.formData[0].edocdetails = res.data.rec;
            let items = res.data.rec;

            console.log(items);

            vm.formData[0].edocdetails = [];

            for (let i = 0; i < items.length; i++) {
                vm.formData[0].edocdetails.push(items[i]);
            }

            console.log(vm.formData[0])

            vm.loading = false;
            
            })
            .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
            });
        },

    },

    mounted() {
        this.loadEdocHandler();
    },
};
</script>