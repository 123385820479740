<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('recuser.loginid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.userid"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                         <v-col cols="4" sm="4" class="pb-n5">
                            <v-card-text v-if="isErr">
                                <span style="font-weight: bold; color: red">{{errMsg}}</span>
                            </v-card-text>
                         </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.staffname')"
                                type="text"
                                ref="psnid"
                                :items="psnidList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="formData.psnid"
                                @input="updateValue"
                                @change="changeidnoHandler(formData.psnid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                           <v-autocomplete
                                outlined
                                :label="this.$t('columns.usertype')"
                                type="text"
                                ref="usertype"
                                :items="usertypeList"
                                hide-selected
                                v-model="formData.usertypeid"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                             <v-text-field
                                outlined
                                id="lastaccess"
                                v-model="formData.lastaccess"
                                type="text"
                                :label="this.$t('recuser.lastlogin')"
                                readonly
                                background-color="#ffeab2"
                                dense
                        ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                id="lastaccdays"
                                v-model="formData.lastaccdays"
                                type="number"
                                :label= "this.$t('recuser.lastlogindate')"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                id="pwdlastchg"
                                v-model="formData.pwdlastchg"
                                type="text"
                                :label="this.$t('recuser.passwordlastchanged')"
                                readonly
                                background-color="#ffeab2"
                                dense
                        ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                id = "pwdchgdays"
                                v-model="formData.pwdchgdays"
                                type="number"
                                :label="this.$t('recuser.passwordchangeddays')"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="activate"
                                v-model="formData.activate"
                                :label="this.$t('recuser.activate')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if ="isEdit==true">
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-btn
                                color="success" 
                                style="width: 250px" 
                                v-on:click ="buttonHandler()"
                                @change="updateValue">{{$t('button.resetpassword')}}</v-btn>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.editMode

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import moment from 'moment';

//import GenericForm from '../../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                userid: this.parentObject.data.userid,
                psnname: this.parentObject.data.psnname,
                psnid: this.parentObject.data.psnid,
                usertypeid: this.parentObject.data.usertypeid,
                usertype: this.parentObject.data.usertype,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                lastaccess: this.parentObject.data.lastaccess,
                lastaccdays: this.parentObject.data.lastaccdays,
                pwdlastchg: this.parentObject.data.pwdlastchg,
                pwdchgdays: this.parentObject.data.pwdchgdays,
                activate: this.parentObject.data.activate,
                status: this.parentObject.data.status,
                passwd: this.parentObject.data.passwd,
                idno: this.parentObject.data.idno,
                chgsrc: this.parentObject.data.chgsrc,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                id: this.parentObject.data.id,
                
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            idno: null,
            usertypeList:[],
            usertypeItems: [],
            usertypeid: [],
            psnidList:[],
            psnidItems:[],
            psnname:[],
            psnid:[],
            psnidcode: null,
            PersonNameLoading: true,
            UserTypeLoading: true,
            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.editMode,
            search: this.parentObject.searchnew
            
        }
    },

    methods: {

            LoadUserType() {
            const vm = this;

            this.$store.dispatch('get', {url: '/usertype/all'})
                .then(res => {
                    vm.usertypeid = res.data.rec;
                    console.log('item',vm.usertypeid)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.usertypeList.push(
                            {
                                text: res.data.rec[i].type,
                                value: res.data.rec[i].id
                            }
                        )}

                    vm.UserTypeLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        LoadPersonName() {
            const vm = this;

            this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
                .then(res => {
                    vm.psnid = res.data.rec;
                    console.log('item',vm.psnid)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.psnidList.push(
                            {
                                text: res.data.rec[i].psnname,
                                value: res.data.rec[i].id
                            }
                        )}

                    vm.PersonNameLoading = false;
                    vm.psnid = res.data.rec;

                    if (this.formData.psnid) {
                       this.changeidnoHandler(this.formData.psnid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeidnoHandler(id) {
            let item = this.psnid.find(item => item.id === id);
            this.psnidcode = item.idno;
        },

        statusHandler() {
                if (this.formData.status=='Barred' && this.isEdit == true ) {
                    this.errMsg = "**BARRED due to maximum attempt violation";
                    this.isErr = true;
                    console.log(this.formData.status)
                } else if (this.formData.status=='Dormant' && this.isEdit == true ) {
                    this.errMsg = "**DORMANT due to long inactivity";
                    this.isErr = true;
                    console.log(this.formData.status)
                } else if (this.formData.status!=='Active' && this.isEdit == true ) {
                    this.errMsg = "**INACTIVE user";
                    this.isErr = true;
                    console.log(this.formData.status)
                } else{
                    this.isErr = false;
                }
        },

        buttonHandler(){
             {
                if(confirm("Do you want to reset password?")){
                    this.formData.status = 'Active';
                     this.formData.lastaccess = moment(this.lastaccess).format('YYYY-MM-DD HH:mm:ss');
                    this.formData.pwdlastchg = moment(this.pwdlastchg).format('YYYY-MM-DD HH:mm:ss');
                if (!this.psnidcode){
                    this.formData.passwd = this.formData.userid
                } else {
                    this.formData.passwd= this.psnidcode.split('-').join("");
                }
            }
            }

        },

        //to get usertypeid of the logged in user
        loadSecuser() {
            const vm = this;
            
            this.$store.dispatch('get', {url: '/secuser/' + this.formData.id})
            .then (res => {

               let usertypeid = res.data.rec[0].usertypeid;
                                
                vm.loading = false;
                this.chgsrcHandler(usertypeid);
            })
            .catch (err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        //update chgsrc based on the logged in usertypeid
        chgsrcHandler(usertypeid){
                if (usertypeid== "1" ){
                    this.formData.chgsrc = "R"
                } else {
                    this.formData.chgsrc = "S"
                }   
                    this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                userid: this.formData.userid,
                psnname: this.formData.psnname,
                psnid: this.formData.psnid,
                usertypeid: this.formData.usertypeid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                lastaccess: this.formData.lastaccess,
                lastaccdays: this.formData.lastaccdays,
                pwdlastchg: this.formData.pwdlastchg,
                pwdchgdays: this.formData.pwdchgdays,
                activate: this.formData.activate,
                status: this.formData.status,
                passwd: this.formData.passwd,
                idno: this.formData.idno,
                chgsrc: this.formData.chgsrc,
                search: this.formData.search,
                stamp: this.formData.stamp,
                id: this.parentObject.data.id,
            });
        },

         newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'secuserlist',  params: { search: this.search}});
        },
    },

    mounted() {
        this.LoadUserType();
        this.LoadPersonName();
        this.loadSecuser();
        this.statusHandler();
    }
}
</script>