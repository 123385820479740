<template>
  <div>
    <tp1-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import tp1Form from "../../../components/payroll/tp1/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "tp1-form": tp1Form,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setuptp1.createnewtp1'),
      pageTitleValue: "",
      formData: {
        psnid: null,
        staffid: null,
        compnyid: null,
        rebatetypeid: null,
        rebatetypecode: null,
        rebategroupid: null,
        amount: null,
        crcyid: null,
        crcycode: null,
        dyear: null,
        dmonth: null,
        remark: null,
        attach: null,
        attachname: null,
        submittedby: null,
        submissiondatetime: moment().format("YYYY-MM-DD"),
        source: "Admin",
        stamp: null,
        // additional line
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    //insert new record
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      //set correct date format using moment
      if (vm.formData.declaremonthyear) {
        vm.formData.declaremonthyear = moment(
          vm.formData.declaremonthyear
        ).format("YYYY-MM");
      }

      this.$store.dispatch('post', {url: "/tp1/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "tp1list" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
    cancelHandler() {
      this.$router.push({ name: "tp1list" });
    },
  },
};
</script>