<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:new="createHandler"
      v-on:error="isErr = $event.isErr; errMsg = $event.errMsg"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import AppForm from "../../../components/payroll/mmmonthlytaxsubmissionrec/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": AppForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('alltax.editmonthlytaxsubmissionrec'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      dataItems: [],
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //load statutory report by id
    loadStatutoryReport() {
      const vm = this;

      this.$store.dispatch('get', {url: "/mmtaxsubmissionrecord/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          vm.pageTitleValue = res.data.rec[0].taxsubreccode;
          vm.formData.userid = this.$store.getters.userId;

          vm.loadTaxSubRecPayout();

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //load tax sub rec payout
    loadTaxSubRecPayout() {
      const vm = this;

      this.$store.dispatch('get', {url: "/mmtaxsubmissionrecordpy/" + this.id})
        .then((res) => {
          vm.formData.taxSelectedPayouts = res.data.rec;

          for (let i = 0; i < vm.formData.taxSelectedPayouts.length; i++) {
            Object.assign(vm.formData.taxSelectedPayouts[i], { action: 2 });
          }

          vm.loadTaxSubRecRef();

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //load tax sub rec ref
    loadTaxSubRecRef() {
      const vm = this;

      this.$store.dispatch('get', {url: "/mmtaxsubmissionrecordref/" + this.id})
        .then((res) => {
          vm.formData.taxSubDetail = res.data.rec;

          for (let i = 0; i < vm.formData.taxSubDetail.length; i++) {
            if (vm.formData.taxSubDetail[i].rcpdate) {
              vm.formData.taxSubDetail[i].rcpdate = moment(
                vm.formData.taxSubDetail[i].rcpdate
              ).format("YYYY-MM-DD");
            }

            vm.formData.taxSubDetail[i].no = i + 1;
          }

          for (let i = 0; i < vm.formData.taxSubDetail.length; i++) {
            Object.assign(vm.formData.taxSubDetail[i], { action: 2 });
             Object.assign(vm.formData.taxSubDetail[i], { no: i + 1 });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      //update record
      this.$store.dispatch('put', {url: "/mmtaxsubmissionrecord/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mmmonthlytaxsubmissionrecordlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          //emit error --> req failure
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/mmtaxsubmissionrecord/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mmmonthlytaxsubmissionrecordlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "mmmonthlytaxsubmissionrecordlist" });
    },

    createHandler() {
      this.$router.push({ name: "mmmonthlytaxsubmissionrecordnew" });
    },
  },

  mounted() {
    this.loadStatutoryReport();
    // this.loadTaxSubRecPayout();
    // this.loadTaxSubRecRef();
  },
};
</script>