<template>
    <div>
        <v-dialog v-model="rankingdialog" max-width="900px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
           >+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('Setuprankingset.coveragesetup')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                    <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('Setuprankingset.rank')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="selections[0].rankingcode"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>

                 

                      <v-row>

                      <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('Setuprankingset.rankdescription')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="selections[0].rankingdesc"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>

                      <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('Setuprankingset.fromscore')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="selections[0].fromscore"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                
                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('Setuprankingset.untilscore')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="selections[0].untilscore"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>

             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeRankingInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveRankingInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            rankingdialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                rankingsetid: null,
                rankingcode: null,
                fromscore: null,
                rankingdesc: null,
                untilscore: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],

            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,
      
       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.rankingdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveRankingInput() {

            
             if (this.selections[0].rankingcode == null || this.selections[0].fromscore == null
             || this.selections[0].rankingdesc == null || this.selections[0].untilscore == null){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.rankingdialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        closeRankingInput() {
            this.rankingdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.rankingdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                rankingsetid: null,
                rankingcode: null,
                fromscore: null,
                rankingdesc: null,
                untilscore: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>