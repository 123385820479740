<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                
                <v-row> 
                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat>
                            <v-toolbar-title>{{this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear')}}
                                
                                <span>{{$t('reportsparam.year')}} </span><span class="blue--text"> {{this.data1.formData.year}}</span>
                                <span v-if="this.data1.formData.month">, {{$t('reportsparam.month')}} <span class="blue--text">{{this.data1.formData.month}} </span></span> 
                                <span>{{this.header.payoutname1}} </span> {{$t('reportstaffenquiry.and')}} 
                                <span>{{$t('reportsparam.year')}} </span><span class="blue--text"> {{this.data2.formData.year}} </span>
                                <span v-if="this.data2.formData.month">, {{$t('reportsparam.month')}} <span class="blue--text">{{this.data2.formData.month}} </span></span>
                                <span>{{this.header.payoutname2}} </span>

                                <!-- <span class="blue--text"> {{this.data1.formData.year}}{{this.data1.formData.month}}{{this.header.payoutname1}} </span> And 
                                <span class="blue--text"> {{this.data2.formData.year}}{{this.data2.formData.month}}{{this.header.payoutname2}} </span>
                            -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </v-col>

                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-toolbar flat>
                            <v-toolbar-title>{{$t('setuppayitem.payitem')}} -  <span class="blue--text"> {{this.header.payitemname}}  </span>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-avatar class="mr-5" size="40" tile>
                                <img :src="`${publicPath}images/export_pdf.png`" @click="printPDF" >
                            </v-avatar>

                            <v-avatar size="40" tile>
                                <img :src="`${publicPath}images/export_csv.png`" @click="getCSV" >
                            </v-avatar>
                        </v-toolbar>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col col="12" sm="12" class="pb4-4 mt-n8">
                        <v-data-table
                            :headers="tabHeader"
                            :items="this.detailItems"
                            class="elavation-1"
                            fixed-header
                            :sort-by="['code']"
                            :options="tableOpt"
                            hide-default-footer
                        >

                        <template v-slot:body>
                            <tbody>
                                <tr v-for="item in detailItems" :key="item.id">
                                    <td>{{item.id}}</td>
                                    <td>{{item.staffcode}}</td>
                                    <td>{{item.psnname}}</td>
                                    <td><v-layout justify-end>{{item.amount}}</v-layout></td> 
                                    <td><v-layout justify-end>{{item.amount2}}</v-layout></td>
                                    <td><v-layout justify-end>{{item.amountVariance}}</v-layout></td>
                                    <td><v-layout justify-end>{{item.percentageVariance}}</v-layout></td>
                                </tr>
                            </tbody>
                        </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
           

            tableOpt: {
                itemsPerPage: -1
            },

            data1: {
                formData: {
                    month: this.$route.params.formData.month1,
                    payout: this.$route.params.formData.payout1,
                    year: this.$route.params.formData.year1,
                },
                parameters: {
                    brchid: this.$route.params.param.branchid,
                    compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    gradeid: this.$route.params.param.gradeid,
                    staffid: this.$route.params.param.staffid,
                },
                payitemid: this.$route.params.payitemid
            },

            data2: {
                formData: {
                    month: this.$route.params.formData.month2,
                    payout: this.$route.params.formData.payout2,
                    year: this.$route.params.formData.year2
                },
                parameters: {
                    brchid: this.$route.params.param.branchid,
                    compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    gradeid: this.$route.params.param.gradeid,
                    staffid: this.$route.params.param.staffid,
                },
                payitemid: this.$route.params.payitemid
            },

            header: {
                payoutname1: null,
                payoutname2: null,
                payitemname: null
            },
            
            detailItems: [],
            
            tabHeader: [
                { text: this.$t('columns.no'), value: 'no', sortable: false, width: '5px' },
                { text: this.$t('columns.staffid'), value: 'staffcode', width: '100px', sortable: false},
                { text: this.$t('columns.staffname'), value: 'psnname', width: '300px', sortable: false},
                { text: this.$t('payrollreconciliationreport.amount1'), value: 'amount1', sortable: false, align: 'end'},
                { text: this.$t('payrollreconciliationreport.amount2'), value: 'amount2', sortable: false, align: 'end' },
                { text: this.$t('payrollreconciliationreport.varianceamt'), value: 'amountVariance', sortable: false, align: 'end' },
                { text: this.$t('payrollreconciliationreport.variancepercentage'), value: 'percentageVariance', sortable: false, align: 'end' },
            ],
            month1: '',
            year1: '',
            payout1: '',
            month2: '',
            year2: '',
            payout2: '',
            payitem: '',       
            errMsg: '',
            title: '',
            isErr: false,
            staffpayout1: [],
            staffpayout2: [],
            salpayitemsvalueaddedforpayout1: [],
            salpayitemsvalueaddedforpayout2: [],
            mergedarrayforpayout1andpayout2: [],
            staffyear1: [],
            staffyear2: [],
            salpayitemsvalueaddedforyear1: [],
            salpayitemsvalueaddedforyear2: [],
            mergedarrayforyear1andyear2: [],
            staffyear1month1: [],
            staffyear2month2: [],
            salpayitemsvalueaddedforyear1month1: [],
            salpayitemsvalueaddedforyear2month2: [],
            mergedarrayforyear1month1andyear2month2: [],
            detail1: [],
            detail2: [],
            detailtotal: [],

        }
    },

    methods: {
        
        //go back to reconciliation page
        backHandler() {
            let formData = {
                year1: this.data1.formData.year,
                month1: this.data1.formData.month,
                payout1: this.data1.formData.payout,
                year2: this.data2.formData.year,
                month2: this.data2.formData.month,
                payout2: this.data2.formData.payout,
            }

            let param = {
                staffid: this.data1.parameters.staffid,
                gradeid: this.data1.parameters.gradeid,
                employmenttypeid: this.data1.parameters.employmenttypeid,
                companyid: this.data1.parameters.companyid,
                branchid: this.data1.parameters.branchid,
                departmentid: this.data1.parameters.departmentid
            }
            this.$router.push({name: 'payrollreconciliation', params: { formData, param  }});
        },

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            if (this.header.payoutname1 && this.header.payoutname2) {
                a.setAttribute('download', this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.header.payoutname1 + this.$t('reportstaffenquiry.and') +  this.header.payoutname2 + this.$t('reportsparam.for') + this.header.payitemname + '.csv')
            } else {
                a.setAttribute('download', this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.data1.formData.year + '_' + this.data1.formData.month + this.$t('reportstaffenquiry.and') +  this.data2.formData.year + '_' + this.data2.formData.month + this.$t('reportsparam.for') + this.header.payitemname + '.csv')
            }

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let item = this.detailItems
            let data = []

            for (var i = 0; i < item.length; i++) {
                data.push({
                    [`${this.$t('columns.no')}`]: item[i].id,
                    [`${this.$t('columns.staffid')}`]: item[i].staffcode,
                    [`${this.$t('columns.staffname')}`]: item[i].psnname,
                    [`${this.$t('payrollreconciliationreport.amount1')}`]: item[i].amount,
                    [`${this.$t('payrollreconciliationreport.amount2')}`]: item[i].amount2,
                    [`${this.$t('payrollreconciliationreport.varianceamt')}`]: item[i].amountVariance,
                    [`${this.$t('payrollreconciliationreport.variancepercentage')}`]: item[i].percentageVariance,
                    
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.detailItems

            var head = [                
                { title: this.$t('columns.no'), dataKey: 'id' },
                { title: this.$t('columns.staffid'), dataKey: 'staffcode' },
                { title: this.$t('columns.staffname'), dataKey: 'psnname' },
                { title: this.$t('payrollreconciliationreport.amount1'), dataKey: 'amount' },
                { title: this.$t('payrollreconciliationreport.amount2'), dataKey: 'amount2' },
                { title: this.$t('payrollreconciliationreport.varianceamt'), dataKey: 'amountVariance' },
                { title: this.$t('payrollreconciliationreport.variancepercentage'), dataKey: 'percentageVariance' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    id: item[i].id,
                    staffcode: item[i].staffcode,
                    psnname: item[i].psnname,
                    amount: item[i].amount,
                    amount2: item[i].amount2,
                    amountVariance: item[i].amountVariance,
                    percentageVariance: item[i].percentageVariance,
                
                })
            }
            if (this.header.payoutname1 && this.header.payoutname2) {
                doc.text(this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.header.payoutname1 + this.$t('reportstaffenquiry.and') +  this.header.payoutname2 + this.$t('reportsparam.for') + this.header.payitemname, 10, 10)
                doc.autoTable(head, body)
                doc.save(this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.header.payoutname1 + this.$t('reportstaffenquiry.and') +  this.header.payoutname2 + this.$t('reportsparam.for') + this.header.payitemname + '.pdf')
            } else {
                doc.text(this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.data1.formData.year + '_' + this.data1.formData.month + this.$t('reportstaffenquiry.and') +  this.data2.formData.year + '_' + this.data2.formData.month + this.$t('reportsparam.for') + this.header.payitemname, 10, 10)
                doc.autoTable(head, body)
                doc.save(this.$t('payrollreconciliationreport.payrollreconciliationbetweenyear') + this.data1.formData.year + '_' + this.data1.formData.month + this.$t('reportstaffenquiry.and') +  this.data2.formData.year + '_' + this.data2.formData.month + this.$t('reportsparam.for') + this.header.payitemname + '.pdf')
            }
        },

        loadPayItemsHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payitem/code/' + vm.data1.payitemid})
                .then(res => {
                    let payitem = res.data.rec[0];   
                    vm.header.payitemname = payitem.payitemdesc
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPayoutControl1() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/" + vm.data1.formData.payout})
            .then((res) => {
                let payout = res.data.rec[0];
                vm.header.payoutname1 = payout.payoutdesc
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        loadPayoutControl2() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/" + vm.data2.formData.payout})
            .then((res) => {
                let payout = res.data.rec[0];
                vm.header.payoutname2 = payout.payoutdesc
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        loadDetailListHandler1() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollreconciliation/details/${this.$store.getters.userId}`, payload: vm.data1})
            .then (res => {
                vm.detail1 = res.data.rec
                for (let i = 0; i < vm.detail1.length; i++) {
                    vm.detail1[i].amtid = 1
                    vm.detailtotal.push(vm.detail1[i])
                }
                this.loadDetailListHandler2();
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
            
        },

        loadDetailListHandler2() {
            const vm = this;

            this.$store.dispatch('post', {url: `/payrollreconciliation/details/${this.$store.getters.userId}`, payload: vm.data2})
            .then (res => {
                vm.detail2 = res.data.rec
                for (let i = 0; i < vm.detail2.length; i++) {
                    vm.detail2[i].amtid = 2
                    vm.detailtotal.push(vm.detail2[i])
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
            
        },

        loadAmount() {
          setTimeout(() => {
              const vm = this
              var amountSum = [];
              var record = [];

              for (let i = 0; i < vm.detailtotal.length; i++) {
                vm.detailtotal[i].id = i + 1
              }

              vm.detailtotal.forEach(function (o) {
                var existing = amountSum.filter(function (i) {
                  return i.staffid === o.staffid;
                })[0];
                if (!existing || existing == undefined) {
                  if (o.amtid == 1) {
                      o.amount2 = '0';
                  } else {
                      o.amount2 = o.amount
                      o.amount = '0'
                  }
                  amountSum.push(o);
                } else {
                  existing.amount2 = o.amount;
                }
              });

              amountSum.forEach(element => {
                if (element.amount.indexOf(',') > -1) { 
                  element.amount = parseFloat(element.amount.replace(/,/g, ''))
                } else {
                  element.amount = parseFloat(element.amount)
                }

                if (element.amount2.indexOf(',') > -1) { 
                  element.amount2 = parseFloat(element.amount2.replace(/,/g, ''))
                } else {
                  element.amount2 = parseFloat(element.amount2)
                }
                
                element.amountVariance = element.amount - element.amount2
                element.amountVariance = Math.abs(element.amountVariance)

                element.amount = element.amount.toFixed(2)
                element.amount2 = element.amount2.toFixed(2)
                element.amountVariance = element.amountVariance.toFixed(2)
               // console.log('elementVariance', element.amountVariance)

                if (element.amountVariance != '0.00'){
                     record.push(element);

                     //console.log('amountVariance', element.amountVariance)
                     
                 }

               });

              record.forEach(element => {
                element.percentageVariance = (element.amountVariance/element.amount) * 100;
                 
                if (isNaN(element.percentageVariance)) {element.percentageVariance = 0}
                element.percentageVariance = element.percentageVariance.toFixed(2)

                 if ((element.percentageVariance == 'Infinity' )){
                      element.percentageVariance = '100.00'
                   //   console.log('elemet', element.percentageVariance)
                  }

                  //console.log('element', element)
                  //console.log('variancepercentage', typeof(element.percentageVariance))
                
              });

              vm.detailItems = record

            }, 1000);
        },

    }, //end of methods

    created() {
      this.loadDetailListHandler1();
      this.loadAmount();
      this.loadPayoutControl1();
      this.loadPayoutControl2();
      this.loadPayItemsHandler();
    }
    
}
</script>

<style scoped>

.textBlue {
    color:  #004080;
}


</style>
