var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-form',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"pb-5"},[_vm._v(" "+_vm._s(this.parentObject.pageTitle)+" "),_c('span',{staticClass:"page_title_value"},[_vm._v(_vm._s(this.parentObject.pageTitleValue))])])])],1),_c('v-row',[_c('v-col',{staticClass:"mt-n6 mb-n8",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('workhourspayitemmappingsetup.mappingset'),"type":"text","rules":[_vm.rules.required],"dense":""},on:{"input":_vm.updateValue},model:{value:(_vm.formData.mapset),callback:function ($$v) {_vm.$set(_vm.formData, "mapset", $$v)},expression:"formData.mapset"}})],1),_c('v-col',{staticClass:"mb-n8",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('workhourspayitemmappingsetup.mappingdescription'),"type":"text","rules":[_vm.rules.required],"dense":""},on:{"input":_vm.updateValue},model:{value:(_vm.formData.mapsetdesc),callback:function ($$v) {_vm.$set(_vm.formData, "mapsetdesc", $$v)},expression:"formData.mapsetdesc"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-n8",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('app-table',{attrs:{"parentObject":{
              tabHeader: this.tabHeader,
              detailItem: this.formData.dataItems,
              startdate: _vm.formData.startdate,
              enddate: _vm.formData.enddate,
            }},on:{"input":_vm.addItemHandler,"delete":_vm.delItemHandler}})],1)],1)],1),_c('app-formbuttons',{attrs:{"EditBool":this.parentObject.isEdit},on:{"NewPressed":_vm.newHandler,"SavePressed":_vm.saveHandler,"DeletePressed":_vm.deleteHandler,"CancelPressed":_vm.cancelHandler}})],1),(_vm.isErrTrigger)?_c('div',[_c('app-error',{attrs:{"parentError":{
        data: this.errMsg,
        isErr: this.isErrTrigger,
      }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }