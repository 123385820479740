<template>
    <div>
      
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4">
                                <v-autocomplete
                                    outlined
                                    label="Select Case ID"
                                    type="text"
                                    ref="ircaseid"
                                    :rules="[rules.required]"
                                    v-model="formData.ircaseid"
                                    dense
                                    :items="caseIDList"
                                    @input="updateValue"
                                    @change="changeIRCaseHandler(formData.ircaseid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="2" sm="2" class="pb-n4" align="center">
                                        <v-avatar size="40" tile>
                                            <img
                                            :src="`${publicPath}images/print_letter.png`"
                                            @click="isPrint = !isPrint"
                                            />
                                        </v-avatar>
                                    </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentshortdesc')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.shortdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentdate')"
                                type="text"
                                ref="incidentdate"
                                v-model="formData.incidentdate"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                      <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.casesubdetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>  

                     <!-- <v-row class="blue--text ">
                            <h3>Case Subject Supporting Document Control </h3>
                        </v-row> -->

                <!-- <v-row >
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table2 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader2,
                                detailItem: this.formData.documentdetail
                            }"
        
                            v-on:input="addItemHandler2"
                        />
                    </v-col>
                </v-row>  -->
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
        <div v-if="isPrint">
            <app-print
            v-bind:parentObject="{
                pageTitleValue: this.pageTitleValue,
                isPrint: this.isPrint,
                ircaseid: this.formData.ircaseid,
            }"
            v-on:print="printHandler($event)"
            v-on:cancelPrint="isPrint = !isPrint"
            />
      </div>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';
import PrintDialog from "../../../components/common/PrintDialog";
import jsPDF from "jspdf";
//import CRUDTable2 from './CRUDTable2';
import moment from "moment";


export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        "app-print": PrintDialog,
       // 'app-table2' : CRUDTable2,
 
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: '',
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                casesubdetail: this.parentObject.data.casesubdetail,
               // documentdetail: this.parentObject.data.documentdetail,
                ircaseid: this.parentObject.data.ircaseid,
                shortdesc: this.parentObject.data.shortdesc,
                longdesc: this.parentObject.data.longdesc,
                incidentdate: this.parentObject.data.incidentdate,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: 'ACTION', value: 'action', width: '100px', sortable: false},
                { text: 'STAFF NAME', value: 'staffname', width: '200px', sortable: false},
                { text: 'STAFF CODE', value: 'staffcode', width: '200px', sortable: false },
                { text: 'COMPANY NAME', value: 'compnyname', width: '200px', sortable: false },
                { text: 'INVOLVEMENT LEVEL', value: 'involvementlvlcode', width: '200px', sortable: false },
               // { text: 'OUTCOME', value: 'outcomecode', width: '200px', sortable: false },
                { text: 'TERMINATION', value: 'termination', width: '200px', sortable: false },
                { text: 'EFFECTIVE DATE', value: 'effectivedate', width: '200px', sortable: false },
            ],

            // tabHeader2: [
            //    { text: 'ACTION', value: 'action', width: '100px', sortable: false},
            //    { text: 'DOCUMENT DESCRIPTION', value: 'attachdesc', width: '200px', sortable: true},
            // ],

            caseIDItems: [],
            caseIDList: [],

            selected: [],

            hidetable: false,

            errMsg: '',
            isErr: false,
            editMode: false,
            loading: false,
            isPrint: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.casesubdetail =$event

         console.log('this.formData.casesubdetail', this.formData.casesubdetail)
        },

        //   addItemHandler2($event) {
        //      this.formData.documentdetail =$event
        //  },


        // changePayoutHandler() {
        //     this.formData.documentdetail = this.selected
        //     this.updateValue()
        // },

        loadCaseID() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/ircasemaintenance/all'
            })
            .then(res => {

                vm.caseIDItems = res.data.rec;

                for (let i = 0; i < vm.caseIDItems.length; i++) {
                    vm.caseIDList.push({
                        text: vm.caseIDItems[i].caseid,
                        value: vm.caseIDItems[i].id,
                    });
                }

                if (this.formData.ircaseid) {
                this.changeIRCaseHandler(this.formData.ircaseid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeIRCaseHandler(id) {
            var item = this.caseIDItems.find(item => item.id === id);
            this.formData.shortdesc = item.shortdesc;
            this.formData.incidentdate = moment(item.incidentdate).format("DD-MM-YYYY");
            this.formData.longdesc = item.longdesc;
         //   this.formData.ircaseid = item.caseid;
           

        },

         async printHandler(id) {
            const vm = this;
            vm.isPrint = !vm.isPrint;
            let showDate = new Date().toISOString().slice(0,10);
            let res = await this.$store.dispatch('get', {url: "/letter/" + id});       
            if (res.status == 200) {
                const pageCnt = res.data.rec[0].detail.length;
                const doc = new jsPDF('p', 'pt', 'letter');
                doc.canvas.height = 72 * 11;
                doc.canvas.width = 72 * 8.5;
                
                for(let i = 0; i < pageCnt; i ++) {
                    let e = res.data.rec[0].detail[i];
                    console.log('e', e)
                    let cntnt = e.content;
                    cntnt = cntnt.replaceAll(`{{CURDATE()}}`, showDate);
                    Object.keys(vm.formData).forEach( key => {
                        cntnt = cntnt.replaceAll(`{{${key}}}`, vm.formData[key] ? vm.formData[key] : '');
                    });
                    await new Promise((resolve) => {
                        doc.fromHTML(cntnt, 50, 30, {
                            'width': 520,
                        }, async function(){
                            if (pageCnt > e.pagenumber){
                            doc.addPage();
                            } else {
                                doc.output('dataurlnewwindow');
                            }
                            return resolve();
                        })
                    })
                }
            } else {
                vm.isErr = !vm.isErr;
            }
        },

                
        updateValue() {

          //  console.log('travellingg' , this.formData.documentdetail)
            console.log('casesubdetail', this.formData.casesubdetail)
          //  console.log('patterndetail', this.formData.patterndetail)
            this.$emit('input', {
                id: this.formData.id,
                casesubdetail: this.formData.casesubdetail,
              //  documentdetail: this.formData.documentdetail,
                ircaseid: this.formData.ircaseid,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'casesubmaintenancelist', params: { search: this.search}});
        },


    },

    mounted(){
        //this.changePayItem();
        this.loadCaseID();
        //this.loading = true;
            
        }
}
</script>