import { render, staticRenderFns } from "./SectionNew.vue?vue&type=template&id=5237557a&"
import script from "./SectionNew.vue?vue&type=script&lang=js&"
export * from "./SectionNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports