<template>
    <div>
        <v-dialog v-model="epfdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupepf.epfgroupitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.company')"
                            type="text"
                            :items="companyList"
                            v-model="selections[0].compnyid"
                            @change="changeCompanyHandler(selections[0].compnyid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.employmenttype')"
                            type="text"
                            :items="emptypeList"
                            v-model="selections[0].emptypeid"
                            @change="changeEmptypeHandler(selections[0].emptypeid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="3" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('reporalertlisting.age')"
                            type="number"
                            class="numberfield"
                            v-model="selections[0].age"
                            :rules="[rules.required]"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.gender')"
                            type="text"
                            v-model="selections[0].genderid"
                            :items="genderList"
                            @change="changeGenderHandler(selections[0].genderid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.grade')"
                            type="text"
                            v-model="selections[0].gradeid"
                            :items="gradeList"
                            dense
                            @change="changeGradeHandler(selections[0].gradeid)" 
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row> 
                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.serviceperiod')"
                            type="text"
                            v-model="selections[0].serviceperiod"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.wages')"
                            type="number"
                            v-model="selections[0].wages"
                            dense
                            class="numberfield"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>  
                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eepercentage')"
                            type="number"
                            class="numberfield"
                            v-model="selections[0].ee_percent"
                            dense
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eeamount')"
                            type="number"
                            class="numberfield"
                            v-model="selections[0].ee_amount"
                            dense
                           
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>  
                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.erpercentage')"
                            type="number"
                            class="numberfield"
                            v-model="selections[0].er_percent"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eramount')"
                            type="number"
                            class="numberfield"
                            v-model="selections[0].er_amount"
                            dense
                            
                        ></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>


            <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeEPF">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveEPF">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>
        </v-data-table>

         <!-- <div v-if="isErr">
            <app-error v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>
        <div v-if="isSuccess">
            <app-success v-bind:parentObject="{
                isSuccess: this.isSuccess,
                messageSuccess: this.messageSuccess,
            }"
            />
        </div>
        <div v-if="isConfirm">
            <app-confirm v-bind:parentObject="{
                messageConfirm: this.messageConfirm,
                isConfirm: this.isConfirm,
            }"
            v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>-->
    </div> 
</template>

<script>
import compareArray from '../../../util/comparearray';
//import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        //"app-error": ErrorDialog
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            isEPF: false,
            epfdialog: false,
            detailItem: this.parentObject.detailItem,

            selections: [{
                id: null,
                epfgroupid: null,
                compnyid: null,
                compnycode: null,
                compnyname: null,
                emptypeid: null,
                emptype: null,
                emptypedesc: null,
                age: null,
                genderid: null,
                gendercode: null,
                gendername: null,
                gradeid: null,
                grdcode: null,
                grddesc: null,
                serviceperiod: null,
                wages: null,
                ee_percent: null,
                ee_amount: null,
                er_percent: null,
                er_amount: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            //arrays to store various data objects from db 
            genders: [],
            genderList: [],
            companies: [],
            companyList: [],
            emptypes: [],
            emptypeList: [],
            grades: [],
            gradeList: [],
            holditem: [],
            hold: [],
            itemHolder: [],

            //error msg and dialog, check for duplicates vars

            isConfirm: false,
            confirmDelete: false,
            isSuccess: false,
            
            isExisting: false,
            modify: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

             this.selections[0] = item;
             this.epfdialog = true;


             if (!this.isExisting){
                 // duplicate this.detailItem for duplicate checking
                
                 //find index 
                
                 var index = this.detailItem.findIndex( x => x.id == item.id);

                 this.detailItem[index].epfgroupid = item.epfgroupid;
                 this.detailItem[index].compnyid = item.compnyid;
                 this.detailItem[index].compnycode = item.compnycode;
                 this.detailItem[index].compnyname = item.compnyname;
                 this.detailItem[index].emptypeid = item.emptypeid;
                 this.detailItem[index].emptype = item.emptype;
                 this.detailItem[index].emptypedesc = item.emptypedesc;
                 this.detailItem[index].age = item.age;
                 this.detailItem[index].genderid = item.genderid;
                 this.detailItem[index].gendercode = item.gendercode;
                 this.detailItem[index].gendername = item.gendername;
                 this.detailItem[index].gradeid = item.gradeid;
                 this.detailItem[index].grdcode = item.grdcode;
                 this.detailItem[index].grddesc = item.grddesc;
                 this.detailItem[index].serviceperiod = item.serviceperiod;
                 this.detailItem[index].wages = item.wages;
                 this.detailItem[index].ee_percent = item.ee_percent;
                 this.detailItem[index].ee_amount = item.ee_amount;
                 this.detailItem[index].er_percent = item.er_percent;
                 this.detailItem[index].er_amount = item.er_amount;
               //  this.detailItem[index].nationalityid = item.nationalityid;




             } else {
                 this.selections[0].action = 2;

                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
             }

            // this.selections = [{
            //     id: item.id,
            //     epfgroupid: item.epfgroupid,
            //     compnyid: item.compnyid,
            //     compnycode: item.compnycode,
            //     compnyname: item.compnyname,
            //     emptypeid: item.emptypeid,
            //     emptype: item.emptype,
            //     emptypedesc: item.emptypedesc,
            //     age: item.age,
            //     genderid: item.genderid,
            //     gendercode: item.gendercode,
            //     gendername: item.gendername,
            //     gradeid: item.gradeid,
            //     grdcode: item.grdcode,
            //     grddesc: item.grddesc,
            //     serviceperiod: item.serviceperiod,
            //     wages: item.wages,
            //     ee_percent: item.ee_percent,
            //     ee_amount: item.ee_amount,
            //     er_percent: item.er_percent,
            //     er_amount: item.er_amount,

            // }]
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

            //this.resetSelections();
        },

        closeEPF() {
            this.epfdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;
            
            // reset the form fields
            this.resetSelections();
            
        },

        saveEPF() {

            if (this.selections[0].age == null || this.selections[0].wages == null || this.selections[0].ee_percent == null
                || this.selections[0].age == "" || this.selections[0].wages == "" || this.selections[0].ee_percent == "" ){

                    this.errMsg = this.$t('setupepf.epfmsg');
                    this.isErr = true;
                    this.epfdialog = true;
            
         }  else {

             this.isErr = false;
             
             if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
         

            this.$emit("input", this.detailItem);

            //this.modify = false;
            this.epfdialog = false;

            this.resetSelections();
        }
            
         
        //  else {

        //      this.isErr = false;

        //     if (!this.isExisting && !this.modify) {
        //         this.selections[0].id = null;
        //         this.selections[0].action = 1;
        //         this.detailItem.push(Object.assign({}, this.selections[0]));
        //     }
        //     else {
        //         this.detailItem.forEach((element) => {
        //             if (element.id == this.selections[0].id) {
        //                 (element.epfgroupid = this.selections[0].epfgroupid),
        //                 (element.compnyid = this.selections[0].compnyid),
        //                 (element.emptypeid = this.selections[0].emptypeid),
        //                 (element.age = this.selections[0].age),
        //                 (element.genderid = this.selections[0].genderid),
        //                 (element.gradeid = this.selections[0].gradeid),
        //                 (element.serviceperiod = this.selections[0].serviceperiod),
        //                 (element.wages = this.selections[0].wages),
        //                 (element.ee_percent = this.selections[0].ee_percent),
        //                 (element.ee_amount = this.selections[0].ee_amount),
        //                 (element.er_percent = this.selections[0].er_percent),
        //                 (element.er_amount = this.selections[0].er_amount);
        //             }
        //         });
        //     }

        //     }

             

        //     //  if (!this.isExisting) {
        //     //      this.selections[0].id = null;
        //     //      this.selections[0].action = 1;

        //     //      this.detailItem.push(Object.assign({}, this.selections[0]));
        //     //  } else {
        //     //      if (this.compareArray(this.detailItem, this.selections[0])) {
        //     //          console.log(this.compareArray(this.detailItem, this.selections[0]))
        //     //      }
                
        //     //  }
            
        //     this.$emit('input', this.detailItem);

        //     this.modify = false;
        //     this.isExisting = false;
        //     this.epfdialog = false;

        //      // reset the form fields
        //     this.resetSelections();

            // this.isErr = false;
            // // indicate value of 1 as insert, 2 as modify

            // if (this.modify) {
            //     console.log('check1')
            //     this.resetSelections();

            // } else if (!this.isExisting && !this.modify) {
            //     console.log('check2')
            //     this.selections[0].id = null;
            //     this.selections[0].action = 1;

            //     this.detailItem.push(Object.assign({}, this.selections[0]));
            // } else {
            //     console.log('check3')
            //     if (this.compareArray(this.detailItem, this.selections[0])) {
            //         this.errMsg = "Duplicate items not allowed";
            //         this.isErr = true;

            //         return;
            //     }
                
            // }

            // this.$emit('input', this.detailItem);

            // this.modify = false;
            // this.epfdialog = false;

            // // reset the form fields
            // this.resetSelections();
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                epfgroupid: null,
                compnyid: null,
                compnycode: null,
                compnyname: null,
                emptypeid: null,
                emptype: null,
                emptypedesc: null,
                age: null,
                genderid: null,
                gendercode: null,
                gendername: null,
                gradeid: null,
                grdcode: null,
                grddesc: null,
                serviceperiod: null,
                wages: null,
                ee_percent: null,
                ee_amount: null,
                er_percent: null,
                er_amount: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },


        //load  data objects from db, stored in arrays - companies, emptypes, grades, genders 
    
        loadCompanies() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/company/all'})
            .then (res => {
                vm.companies = res.data.rec;

                for (let i = 0; i < vm.companies.length; i++) {
                    vm.companyList.push(
                        {
                            text: vm.companies[i].compnyname,
                            value: vm.companies[i].id
                        }
                    )
                }
            })
            .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },
    
       

        loadEmpTypes() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/emptype/all'})
            .then (res => {
                vm.emptypes = res.data.rec;

                for (let i = 0; i < vm.emptypes.length; i++) {
                    vm.emptypeList.push(
                        {
                            text: vm.emptypes[i].emptypedesc,
                            value: vm.emptypes[i].id
                        }
                    )
                }
            })
            .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadGrades() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/grade/all'})
            .then (res => {
                vm.grades = res.data.rec;

                for (let i = 0; i < vm.grades.length; i++) {
                    vm.gradeList.push(
                        {
                            text: vm.grades[i].grddesc,
                            value: vm.grades[i].id
                        }
                    )
                }
            })
            .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadGenders() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/gender/all'})
            .then (res => {
                vm.genders = res.data.rec;

                for (let i = 0; i < vm.genders.length; i++) {
                    vm.genderList.push(
                        {
                            text: vm.genders[i].gendername,
                            value: vm.genders[i].id
                        }
                    )
                }
            })
            .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        
        //set selections[0].compnyname to display in table details
        changeCompanyHandler(id) {
            let item = this.companies.find(item => item.id == id)

            this.selections[0].compnyname = item.compnyname;
            this.selections[0].compnycode = item.compnycode;
        },

        //set selections[0].gender to display in table details
        changeGenderHandler(id) {
            let item =  this.genders.find(item => item.id == id);

            this.selections[0].gendername = item.gendername;

        },

         //set selections[0].emtypedesc to display in table details
        changeEmptypeHandler(id) {
            let item = this.emptypes.find(item => item.id == id)

            this.selections[0].emptypedesc = item.emptypedesc;
            this.selections[0].emptype = item.emptype;
        },

         //set selections[0].grddesc to display in table details
        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id == id)

            this.selections[0].grddesc = item.grddesc;
            this.selections[0].grdcode = item.grdcode;
        },

      
    },

    created() {
        this.loadCompanies();
        this.loadEmpTypes();
        this.loadGrades();
        this.loadGenders();
    
    }
}
</script>