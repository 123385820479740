<template>
    <div>
        <v-dialog v-model="claimrecorddialog" max-width="1200px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
            this.parentObject.clmtypeid>+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('claimrecordlisting.medicalclaimitem')}}</span>
              <!--<span class="headline mb-4">Medical Claim Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row> 

                     <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptdate')"
                            type="date"
                            v-model="selections[0].receiptdate"
                            :rules="[rules.required]"
                            dense
                            clearable
                        ></v-text-field>
                    </v-col>

                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptno')"
                            type="text"
                            v-model="selections[0].receiptno"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                   
                </v-row>

                 <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('claimrecordlisting.receiptcurrency')"
                                type="text"
                                ref="crcydesc"
                                :rules="[rules.required]"
                                :items="currencyList"
                                clearable
                                hide-selected
                                v-model="selections[0].crcyid"
                                @change="changeCurrencyHandler(selections[0].crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="selections[0].crcycode"
                            ></v-text-field>
                        </v-col>
                 </v-row>

                <v-row>
                     <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.claimamountbeforetax')"
                            type="number"
                            v-model="selections[0].receiptclmamtb4tax"
                            @change="inputClaimAmount"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.taxamount')"
                            type="number"
                            v-model="selections[0].receiptclmtaxamt"
                            dense
                            @change="inputClaimTaxAmount"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.totalclaimamount')"
                            type="number"
                            v-model="selections[0].receiptclmtotalamt"
                            :rules="[rules.required]"
                            dense
                            @change="inputClaimTotalAmount"
                        ></v-text-field>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.approvedclaimamountbeforetax')"
                            type="number"
                            v-model="selections[0].receiptaprvamtb4tax"
                            dense
                            @change="inputApproveAmount"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.approvedtaxamount')"
                            type="number"
                            v-model="selections[0].receiptaprvtaxamt"
                            dense
                            @change="inputApproveTaxAmount"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.approvedtotalamount')"
                            type="number"
                            v-model="selections[0].receiptaprvamt"
                            :rules="[rules.required]"
                            dense
                            @change="inputApproveTotalAmount"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.claimtypecurrency')"
                            type="text"
                            ref="clmtypecrcyid"
                            v-model="selections[0].clmtypecrycdesc"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedclaimamountbeforetax')"
                            type="number"
                            v-model="selections[0].clmamtb4tax"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedtaxamount')"
                            type="number"
                            v-model="selections[0].clmtaxamt"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedtotalclaimamount')"
                            type="number"
                            v-model="selections[0].clmtotalamt"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedapprovedclaimamountbeforetax')"
                            type="number"
                            v-model="selections[0].aprvamtb4tax"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedapprovedtaxamount')"
                            type="number"
                            v-model="selections[0].aprvtaxamt"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.convertedapprovedtotalamount')"
                            type="number"
                            v-model="selections[0].aprvamt"
                            dense
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('panelclinic')"
                            type="text"
                            ref="panelclinicdesc"
                            clearable
                            v-model="selections[0].panelclinicid"
                            dense
                            :items="panelcliniclist"
                        
                            @change="changePanelClinic(selections[0].panelclinicid)"
                        ></v-autocomplete>
                    </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('claimrecordlisting.panelcliniccode')"
                                type="text"
                                ref="panelcliniccode"
                                v-model="selections[0].panelcliniccode"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="10" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.nanpanelclinic')"
                            type="text"
                            ref="nonpanelclinic"
                            v-model="selections[0].clinicname"
                            dense
                        
                        ></v-text-field>
                        </v-col>
                    </v-row>

          
                    <v-row>
                        <v-col cols="4" sm="10" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.claimrecordremarkoptional')"
                            type="text"
                            ref="clmremark"
                            v-model="selections[0].clmremark"
                            dense
                        
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('claimrecordlisting.chargecode')"
                                type="text"
                                ref="chargecodedesc"
                                clearable
                                :items="chargeList"
                                hide-selected
                                v-model="selections[0].chargecodeid"
                                @change="changeCodeHandler(selections[0].chargecodeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="chargeCode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="chargeDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: chargeItems,
                                            formTitle: this.$t('claimrecordlisting.chargecode'),
                                            formId: 'chargecode'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveChargeCode="saveCodeHandler"
                                        v-on:deleteChargeCode="deleteCodeHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="chargeDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col cols="4" sm="10" class="pb-n5 mt-n8">
                    <div class="text-center">
                        <v-file-input
                        ref="attach"
                        :label="this.$t('claimrecordlisting.browseforattachment')"
                        outlined
                        v-model="fileHolder"
                        @change="fileToBase64"
                        dense
                        ></v-file-input>
                    </div>
                    </v-col>
                </v-row>

          <div v-if="this.selections[0].receiptattach">
            <v-row>
              <v-col cols="4" sm="6" class="pb-8 mt-n5">
                <p>
                  <a
                    v-bind:href="this.selections[0].receiptattach"
                    v-bind:download="this.selections[0].receiptattach"
                    >{{$t('button.downloadattachment')}}</a
                  >
                  <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteAttachmentHandler"
                  >
                    mdi-delete
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </div>

                    <v-row>
                         <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.status')"
                            type="text"
                            ref="transtatus"
                            clearable
                            :rules="[rules.required]"
                            v-model="selections[0].transtatusid"
                            dense
                            :items="transtatusList"
                            @change="changeTranStatusHandler(selections[0].transtatusid)"
                        ></v-autocomplete>
                        </v-col> 
                    
                           <v-col cols="2" sm="2" class="pb-n4 mt-n8" >
                                    <v-checkbox
                                        disabled
                                        ref="posted"
                                        v-model="selections[0].posted"
                                        :label="this.$t('recleaverec.postedtopayroll')"
                                    >
                                    </v-checkbox>
                           </v-col> 

                              <v-col cols="2" sm="2" class="pb-n4 mt-n8" >
                                    <v-checkbox
                                        ref="ishistory"
                                        v-model="selections[0].ishistory"
                                        :label="this.$t('claimrecordlisting.historydata')"
                                    >
                                    </v-checkbox>
                                </v-col>                           

                               <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('claimrecordlisting.payoutcode')"
                                        type="text"
                                        :rules="[rules.required]"
                                        v-model="selections[0].payoudesc"
                                        dense
                                        readonly
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                </v-col>
                        </v-row>

                    
                    <v-row>
                        <v-col cols="4" sm="10" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.approvalremarkoptional')"
                            type="text"
                            ref="aprvremark"
                            v-model="selections[0].aprvremark"
                            dense
                        
                        ></v-text-field>
                        </v-col>
                    </v-row>

             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeMedicalClaimInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveMedicalClaimInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            claimrecorddialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            clmtypeid: this.parentObject.clmtypeid,
            staffid : this.parentObject.staffid,
            selections: [{
                clmtypeid: null,
                id: null,
                clmrecordid: null,
                receiptno: null,
                receiptdate: null ,
                clmtotalamt: null,
                clmtaxamt: 0,
                clmamtb4tax: null,
                aprvamt: null,
                aprvtaxamt: 0,
                aprvamtb4tax: null,
                clmtypecrcyid: null,
                clmtypecrycdesc: null,
                exchrateid : null,
                receiptclmtotalamt: null,
                receiptclmtaxamt: 0,
                receiptclmamtb4tax: null,
                receiptaprvamt: null,
                receiptaprvtaxamt: 0,
                receiptaprvamtb4tax: null,
                receiptattach: null,
                distance: null,
                vehtypeid: null,
                vehtype: null,
                fromloc: null,
                toloc: null,
                clmremark: null,
                aprvremark: null,
                chargecodeid: null,
                chargecodedesc: null,
                chargecode: null,
                transtatusid: null,
                transtatus: null,
                posted: false,
                ishistory: false,
                poid: null,
                payoudesc: null,
                panelclinicid: null,
                panelcliniccode: null,
                panelclinicdesc: null,
                clinicname: null,
                crcyid: null,
                crcycode: null,
                crcydesc:null,
                rate:null,
                sqlAll: null,
                sqlAll2: null,
                staffid: null,
                oktoclaim: null,
               // receiptref: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],
            

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            fileHolder: null,

            panelcliniccode: '',

            currencyCode: '',
            currencies: [],
            currencyList: [],
            currencyItems: [],
            currencyLoading: true,

            chargeCode: '',

            chargeList: [],
            charges: [],
            chargeItems: [],

            clmtypeItems: [],
            clmtypeList: [],

            exchangeItems: [],
           
            chargeDialog: false,
            chargeLoading: true,

            transtatusItems: [],
            transtatusList: [],
            transtatusLoading: true,
            
            cliniccode: '',
            panelclinic: [],
            panelcliniclist: [],

            claimbalancedetail: [],
            staffnameItems:[],

             itemHolder: [],
            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,
          loadCheckClaimBalance() {
            let data = {
                receiptdate: this.selections[0].receiptdate,
                staffid: this.parentObject.staffid,
                clmtypeid: this.parentObject.clmtypeid,
                userid: this.$store.getters.userId,
            };

            const vm = this;
            this.$store.dispatch('post', { url: "/claim/claimbalancenew", payload: data})
                .then((res) => {
                    //req success --> push res into array
                    vm.claimbalancedetail = res.data.rec;

                    vm.selections[0].oktoclaim = res.data.rec[0].oktoclaim;
                    vm.selections[0].staffid = res.data.rec[0].staffid;
                    vm.selections[0].clmtypeid = res.data.rec[0].clmtypeid;

                    // if the receipt currency and claim currency is similar
                    if(((parseInt(this.selections[0].aprvamt) > this.selections[0].oktoclaim) ||
                    (parseInt(this.selections[0].receiptaprvamt) > this.selections[0].oktoclaim)) && 
                    (this.selections[0].crcyid == this.selections[0].clmtypecrcyid)){
                        this.selections[0].aprvamt = this.selections[0].oktoclaim

                        let convertamount =  parseFloat(this.selections[0].aprvamt) *
                                            parseFloat(1);

                        this.selections[0].receiptaprvamt = parseFloat(convertamount.toFixed(2));

                        //calculate the converted approve before tax amount if oktoclaim is used as converted approved total amount
                        let newaprvamtb4tax = (parseFloat(this.selections[0].aprvamt) / parseFloat(this.selections[0].clmtotalamt)) * parseFloat(this.selections[0].clmamtb4tax)
                        this.selections[0].aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                        this.selections[0].receiptaprvamtb4tax = this.selections[0].aprvamtb4tax

                        //calculate the converted approve tax amount if oktoclaim is used as converted approved total amount
                        let newtaxamt = (parseFloat(this.selections[0].aprvamt) - parseFloat(newaprvamtb4tax))
                        this.selections[0].aprvtaxamt = parseFloat(newtaxamt.toFixed(2));
                    }
                    // if the receipt currency and claim currency is different
                    else if (((parseInt(this.selections[0].aprvamt) > this.selections[0].oktoclaim) ||
                    (parseInt(this.selections[0].receiptaprvamt) > this.selections[0].oktoclaim)) && 
                    (this.selections[0].crcyid != this.selections[0].clmtypecrcyid)){
                        this.selections[0].aprvamt = this.selections[0].oktoclaim

                        let convertamount =  parseFloat(this.selections[0].aprvamt) /
                                            parseFloat(this.selections[0].rate);

                        this.selections[0].receiptaprvamt = parseFloat(convertamount.toFixed(2));

                        //calculate the converted approve before tax amount if oktoclaim is used as converted approved total amount

                        let newaprvamtb4tax = (parseFloat(this.selections[0].aprvamt) / parseFloat(this.selections[0].clmtotalamt)) * parseFloat(this.selections[0].clmamtb4tax)
                        this.selections[0].aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                        this.selections[0].receiptaprvamtb4tax = this.selections[0].aprvamtb4tax

                        //calculate the converted approve tax amount if oktoclaim is used as converted approved total amount
                        let newtaxamt = (parseFloat(this.selections[0].aprvamt) - parseFloat(newaprvamtb4tax))
                        this.selections[0].aprvtaxamt = parseFloat(newtaxamt.toFixed(2));
                    
                        //if the claim amount is within the oktoclaim amount

                    } else {
                        if (parseInt(this.selections[0].receiptaprvamt) > this.selections[0].receiptclmtotalamt) {
                            console.log('1')
                            this.selections[0].aprvamt = this.selections[0].receiptclmtotalamt
                            this.selections[0].receiptaprvamt = this.selections[0].receiptclmtotalamt

                            let newaprvamtb4tax = (parseFloat(this.selections[0].aprvamt) / parseFloat(this.selections[0].clmtotalamt)) * parseFloat(this.selections[0].clmamtb4tax)

                            this.selections[0].aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                            this.selections[0].receiptaprvamtb4tax = this.selections[0].aprvamtb4tax 

                        }
                    }
            }).catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        },

        getExchangeRate(){

            const vm = this;

            this.$store.dispatch('get', {
                url: "/exchratecurrency/" + this.selections[0].crcyid  + "/" + this.selections[0].clmtypecrcyid  
            + "/" + this.selections[0].exchrateid + "/" + this.selections[0].receiptdate 
            + "/" + this.selections[0].receiptdate
            }).then(res => {

                vm.exchangeItems = res.data.rec;

                if (res.data.rec.length > 0 ) {
                        vm.selections[0].rate = res.data.rec[0].rate;
                        vm.selections[0].sqlAll = res.data.rec[0].sqlAll;
                        vm.selections[0].sqlAll2 = res.data.rec[0].sqlAll2;
 
                if(this.selections[0].sqlAll == '1') {

                    this.loadCheckClaimBalance();

                        if(this.selections[0].receiptclmamtb4tax != "" ){

                        let convertedrate = this.selections[0].rate 

                        let currentAmount =  parseFloat(this.selections[0].receiptclmamtb4tax) *
                                            parseFloat(convertedrate);

                        this.selections[0].clmamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.selections[0].aprvamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.selections[0].rate = convertedrate;
                        }

                    if(this.selections[0].receiptclmtaxamt != "" || this.selections[0].receiptclmtaxamt != null){

                        let convertedrate = this.selections[0].rate 

                        let currentAmount2 =  parseFloat(this.selections[0].receiptclmtaxamt) *
                                            parseFloat(convertedrate);

                        this.selections[0].clmtaxamt = parseFloat(currentAmount2.toFixed(2));
                        this.selections[0].aprvtaxamt = parseFloat(currentAmount2.toFixed(2));
                        this.selections[0].rate = convertedrate;
                        }
 
                    if(this.selections[0].receiptclmtotalamt != ""  ){
                        let convertedrate = this.selections[0].rate 

                        let currentAmount3 =  parseFloat(this.selections[0].receiptclmtotalamt) *
                                            parseFloat(convertedrate);
            
                        this.selections[0].clmtotalamt = parseFloat(currentAmount3.toFixed(2));
                        this.selections[0].aprvamt = parseFloat(currentAmount3.toFixed(2));
                        this.selections[0].rate = convertedrate;

                        }
                }

                else if (this.selections[0].sqlAll2 == '2') {

                    this.loadCheckClaimBalance();
                   
                        if(this.selections[0].receiptclmamtb4tax != "" ){

                        let convertedrate = parseFloat(1 / this.selections[0].rate);

                        let currentAmount =  parseFloat(this.selections[0].receiptclmamtb4tax) *
                                            parseFloat(convertedrate);

                        this.selections[0].clmamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.selections[0].aprvamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.selections[0].rate = convertedrate;
                        }

                        if(this.selections[0].receiptclmtaxamt != "" ){

                            let convertedrate = parseFloat(1 / this.selections[0].rate);

                            let currentAmount2 =  parseFloat(this.selections[0].receiptclmtaxamt) *
                                                  parseFloat(convertedrate);

                            this.selections[0].clmtaxamt = parseFloat(currentAmount2.toFixed(2));
                            this.selections[0].aprvtaxamt = parseFloat(currentAmount2.toFixed(2));
                            this.selections[0].rate = convertedrate;
                            }
   
                        if(this.selections[0].receiptclmtotalamt != "" ){

                            let convertedrate = parseFloat(1 / this.selections[0].rate);

                            let currentAmount3 =  parseFloat(this.selections[0].receiptclmtotalamt) *
                                                parseFloat(convertedrate);

                            this.selections[0].clmtotalamt = parseFloat(currentAmount3.toFixed(2));
                            this.selections[0].aprvamt = parseFloat(currentAmount3.toFixed(2));
                            this.selections[0].rate = convertedrate;
                            }
                        }  
                } else {

                        if (res.data.rec.length == 0) {
                        // alert if no exchange items are found
                            this.$dialog.alert("Unable to find exchange rate. Please setup exchange rate at Exchange Rate Set before save record").then((dialog) => {
                            console.log(dialog); 
                        });
                    } 
                }
            })
       },

        changeParentClaim() {
            const vm = this;
            this.$store.dispatch('get', {
                url: "/claimtype/" + this.clmtypeid
            })
            .then(res => {
                vm.clmtypeItems = res.data.rec;
                
                vm.selections[0].clmtypecrcyid = res.data.rec[0].crcyid;
                vm.selections[0].exchrateid = res.data.rec[0].exchrateid;
                vm.selections[0].clmtypecrycdesc = res.data.rec[0].crcydesc;
                vm.selections[0].clmtypeid = res.data.rec[0].clmtypeid;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeStaffClaim() {
            const vm = this;
            this.$store.dispatch('get', {
                url: "/staff/" + this.staffid
            })
            .then(res => {
                vm.staffnameItems = res.data.rec;
                vm.selections[0].staffid = res.data.rec[0].staffid;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },


        claimConversion(){
            this.loadCheckClaimBalance();
            if(this.selections[0].receiptclmamtb4tax != "" ){
                let currentAmount =  parseFloat(this.selections[0].receiptclmamtb4tax) * 1;
                this.selections[0].clmamtb4tax = currentAmount.toFixed(2);
            }

            if(this.selections[0].receiptclmtaxamt != "" ){
                let currentAmount2 =  parseFloat(this.selections[0].receiptclmtaxamt) * 1;
                this.selections[0].clmtaxamt = currentAmount2.toFixed(2);
            }
        
            if(this.selections[0].receiptclmtotalamt != "" ){
                let currentAmount3 =  parseFloat(this.selections[0].receiptclmtotalamt) * 1;
                this.selections[0].clmtotalamt = currentAmount3.toFixed(2);
            }

            if(this.selections[0].receiptaprvamtb4tax != "" ){
                let currentAmount =  parseFloat(this.selections[0].receiptaprvamtb4tax) * 1;
                this.selections[0].aprvamtb4tax = currentAmount.toFixed(2);
            }

            if(this.selections[0].receiptaprvtaxamt != "" ){
                let currentAmount2 =  parseFloat(this.selections[0].receiptaprvtaxamt) * 1;
                this.selections[0].aprvtaxamt = currentAmount2.toFixed(2);
            }
        
            if(this.selections[0].receiptaprvamt != "" ){
                let currentAmount3 =  parseFloat(this.selections[0].receiptaprvamt) * 1;
                this.selections[0].aprvamt = currentAmount3.toFixed(2);
            }
        },

        inputClaimAmount(){
            if(this.selections[0].receiptclmtaxamt != "" || this.selections[0].receiptclmtaxamt != null)
            {
                let currentAmount = parseFloat(this.selections[0].receiptclmamtb4tax)-
                                    parseFloat(this.selections[0].receiptclmtaxamt);
                this.selections[0].receiptclmtotalamt = currentAmount.toFixed(2);
            } else {
                return false
            }
            
            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
                this.claimConversion();   
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();
            } 
        },

        inputClaimTaxAmount(){
            if(this.selections[0].receiptclmamtb4tax != "" || this.selections[0].receiptclmamtb4tax != null ){
                let currentAmount = parseFloat(this.selections[0].receiptclmamtb4tax)-
                                    parseFloat(this.selections[0].receiptclmtaxamt);

                this.selections[0].receiptclmtotalamt = currentAmount.toFixed(2);
            } else {
                return false
            }
            
            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
                this.claimConversion();   
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();      
            }
        },

        inputClaimTotalAmount() {
            if(this.selections[0].receiptclmtaxamt != "" || this.selections[0].receiptclmtaxamt != null ){
                let currentAmount = parseFloat(this.selections[0].receiptclmtotalamt)+
                                    parseFloat(this.selections[0].receiptclmtaxamt);

                this.selections[0].receiptclmamtb4tax = currentAmount.toFixed(2);
            } else {
                return false
            }

            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
                this.claimConversion();   
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();      
            }
        },

        inputApproveAmount(){
            if(this.selections[0].receiptaprvtaxamt != "" || this.selections[0].receiptaprvtaxamt != null ){
                let currentAmount =  parseFloat(this.selections[0].receiptaprvamtb4tax)-
                                     parseFloat(this.selections[0].receiptaprvtaxamt);

                this.selections[0].receiptaprvamt = currentAmount.toFixed(2);
            } else {
                return false
            }

            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
                this.claimConversion();   
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();      
            } 
        },

        inputApproveTaxAmount(){
            if(this.selections[0].receiptaprvamtb4tax != "" || this.selections[0].receiptaprvamtb4tax != null ){
                let currentAmount =  parseFloat(this.selections[0].receiptaprvamtb4tax)-
                                     parseFloat(this.selections[0].receiptaprvtaxamt);

                this.selections[0].receiptaprvamt = currentAmount.toFixed(2);
            } else {
                return false
            }

            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
               this.claimConversion();
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();
            }
        },

        inputApproveTotalAmount(){
            if(this.selections[0].receiptaprvtaxamt != "" || this.selections[0].receiptaprvtaxamt != null ){
                let currentAmount =  parseFloat(this.selections[0].receiptaprvamt)+
                                     parseFloat(this.selections[0].receiptaprvtaxamt);

                this.selections[0].receiptaprvamtb4tax = currentAmount.toFixed(2);
            } else {
                return false
            }

            if(this.selections[0].crcyid == this.selections[0].clmtypecrcyid){
                this.claimConversion();   
            } else if (this.selections[0].crcyid != this.selections[0].clmtypecrcyid) {
                this.getExchangeRate();
            }
        },

        fileToBase64() {
            if (this.fileHolder) {
                var file = this.$refs.attach.internalValue;
                this.selections[0].receiptattach = this.fileHolder.name;

                var reader = new FileReader();

                reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.selections[0].receiptattach = b64;
                };

                reader.readAsDataURL(file);
            }
        },

         deleteAttachmentHandler() {
            this.selections[0].receiptattach = "";
            this.selections[0].receiptattach = "";
        },

    loadPanelClinicHandler() {
      const vm = this;
      this.$store.dispatch('get', {
          url: "/clinic/all"
        })
        .then((res) => {
          //req success --> push res into array
          vm.panelclinic = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.panelcliniclist.push({
              text: res.data.rec[i].clinicdesc,
              code: res.data.rec[i].cliniccode,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },


     changePanelClinic(id) {
      let item = this.panelclinic.find((item) => item.id === id);
      this.panelclinicid = item.id;
      this.selections[0].panelcliniccode = item.cliniccode;
      this.selections[0].panelclinicdesc = item.clinicdesc;
    },

    loadCurrencyListHandler() {
        const vm = this;

            this.$store.dispatch('get', {
                url: '/currency/all'
            })
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {

                        // if (res.data.rec[i].crcydesc === "Malaysian Ringgit" ) 
                        // {
                        //     this.selections[0].crcyid = res.data.rec[i].id;
                        // }
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                code: res.data.rec[i].crcycode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.selections[0].crcyid) {
                        vm.changeCurrencyHandler(vm.selections[0].crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.selections[0].crcycode = item.crcycode;
            this.selections[0].crcydesc = item.crcydesc;
        },

        loadTranStatusListHandler() {
        const vm = this;

            this.$store.dispatch('get', {
                url: '/transtatus/all'
            })
                .then(res => {
                    vm.transtatusItems = res.data.rec;

                        for (let i = 0; i < res.data.rec.length; i++) {
                    
                    if (res.data.rec[i].transtatus === 'APPROVED' && 
                    !vm.selections[0].transtatusid ) 
                         {
                             this.selections[0].transtatusid = res.data.rec[i].id;
                         }
                        vm.transtatusList.push(
                            {
                                text: res.data.rec[i].transtatus,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.transtatusLoading = false;

                    if (vm.selections[0].transtatusid) {
                        vm.changeTranStatusHandler(vm.selections[0].transtatusid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeTranStatusHandler(id) {
            let item = this.transtatusItems.find(item => item.id === id);

            this.transtatusid = item.id;
            this.selections[0].transtatus = item.transtatus;
        },

        loadCodeListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/chargecode/all'
            })
                .then(res => {
                    vm.charges = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.chargeList.push(
                            {
                                text: res.data.rec[i].chargecodedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.chargeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].chargecode,
                                desc: res.data.rec[i].chargecodedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.chargeLoading = false;

                    if (vm.selections[0].chargecodeid) {
                        vm.changeCodeHandler(vm.selections[0].chargecodeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is to show the loaded contents
        changeCodeHandler(id) {
            let item = this.charges.find(item => item.id === id);

            this.chargeCode = item.chargecode;
            this.selections[0].chargecodedesc = item.chargecodedesc; 
        },

         //save handler is for if the user wants to save a new item into the database
        saveCodeHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/chargecode/' + this.itemData.itemid, 
                    payload: {
                        chargecode: this.itemData.itemcode,
                        chargecodedesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.$store.getters.userId,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.charges = [];
                        vm.chargeItems = [];
                        vm.chargeList = [];

                        this.loadCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/chargecode/create', 
                    payload: {
                        chargecode: this.itemData.itemcode,
                        chargecodedesc: this.itemData.itemdesc,
                        userid: this.$store.getters.userId,
                    }
                })
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.charges = [];
                        vm.chargeItems = [];
                        vm.chargeList = [];

                        this.loadCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        //delete handler is for the user to delete an item from the database
        deleteCodeHandler($event) {
        const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/chargecode/' + $event.id + '/' + this.$store.getters.userId
                })
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.charges = [];
                        vm.chargeList = [];
                        vm.chargeItems = [];

                        this.loadCodeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

          genCurrencyTabItems() {
            for (let i = 0; i < this.charges.length; i++) {
                this.chargeItems.push(
                    {
                        id: this.charges[i].id,
                        code: this.charges[i].chargecode,
                        desc: this.charges[i].chargecodedesc,
                        stamp: this.charges[i].stamp
                    }
                )
            }
        },


       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

            if (item.posted == "Y") {
                item.posted = true;
            }
            else {
                item.posted = false;
            }
            if (item.ishistory == "Y") {
                item.ishistory = true;
            }
            else {
                item.ishistory = false;
            }

            this.selections[0] =  item;
            this.claimrecorddialog = true;

        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveMedicalClaimInput() {

        if (this.selections[0].aprvamtb4tax == null || this.selections[0].aprvamt == null){

              this.$dialog.alert(this.$t('pleasesetupexchangeratesave')).then((dialog) => {
                console.log(dialog);
             });
        } else {
           this.isErr = false;

           if (this.selections[0].posted) {
                this.selections[0].posted = "Y";
            }
            else {
                this.selections[0].posted = "N";
            }
            if (this.selections[0].ishistory) {
                this.selections[0].ishistory = "Y";
            }
            else {
                this.selections[0].ishistory = "N";
            }

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            
            this.$emit('input', this.detailItem);
            this.claimrecorddialog = false;
            
            // reset the form fields
            this.resetSelections();
            }
        },

        closeMedicalClaimInput() {
            this.claimrecorddialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.claimrecorddialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
          
            this.selections = [{
                clmtypeid: this.selections[0].clmtypeid,
                id: null,
                clmrecordid: null,
                receiptno: null,
                receiptdate: null ,
                clmtotalamt: null,
                clmtaxamt: 0,
                clmamtb4tax: null,
                aprvamt: null,
                aprvtaxamt: 0,
                aprvamtb4tax: null,
                clmtypecrcyid: this.selections[0].clmtypecrcyid,
                clmtypecrycdesc: this.selections[0].clmtypecrycdesc,
                exchrateid : this.selections[0].exchrateid,
                receiptclmtotalamt: null,
                receiptclmtaxamt: 0,
                receiptclmamtb4tax: null,
                receiptaprvamt: null,
                receiptaprvtaxamt: 0,
                receiptaprvamtb4tax: null,
                receiptattach: null,
                distance: null,
                vehtypeid: null,
                vehtype: null,
                fromloc: null,
                toloc: null,
                clmremark: null,
                aprvremark: null,
                chargecodeid: null,
                chargecodedesc: null,
                chargecode: null,
                transtatusid: this.selections[0].transtatusid,
                transtatus: this.selections[0].transtatus,
                posted: false,
                ishistory: false,
                poid: null,
                payoudesc: null,
                panelclinicid: null,
                panelcliniccode: null,
                panelclinicdesc: null,
                clinicname: null,
                crcyid: null,
                crcycode: null,
                crcydesc:null,
                rate:null,
                sqlAll: null,
                sqlAll2: null,
                oktoclaim: null,
                staffid: this.selections[0].staffid,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

   
        },          
    },

    created() {

   
      
       this.loadCurrencyListHandler();
       this.loadPanelClinicHandler();
       this.loadTranStatusListHandler();
       this.loadCodeListHandler();
       this.changeParentClaim();
       this.changeStaffClaim();

      
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>