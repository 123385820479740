<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

import ListingForm from "../../../components/latenessdeduction/listing/Form.vue";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": ListingForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('latenessdeduction.title'),
      pageTitleValue: "",
      formData: {
        id: null,
        listingdetail: [],
        
        attnlatenessbatchid: null,
        latenessbatchcode: null,
        latenessbatchdesc: null,
    
        fromdate: null,
        todate: null,
    
        name: null,
        compnyname: null,

        latedate: null,
        duration: null,
        genamount: null,
        finalamount: null,

        remark: null,

        currency: null,
        currencydesc: null,

        posted: null,

        payoutcode: null,
        payoutdesc: null,

        rectimestamp: null,
        username: null,
        
        grouppcode: null,
        grouppdesc: null,

        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadListing() {
      const vm = this;

      this.$store.dispatch('get', {url: "/attnlatenessbatchd/" + this.id})
        .then((res) => {
          vm.pageTitleValue = res.data.rec[0].latenessbatchdesc + ' - ' + res.data.rec[0].staffcode;
          vm.formData.id = res.data.rec[0].id;

          vm.formData.attnlatenessbatchid = res.data.rec[0].attnlatenessbatchid;
          vm.formData.latenessbatchcode = res.data.rec[0].latenessbatchcode;
          vm.formData.latenessbatchdesc = res.data.rec[0].latenessbatchdesc;
          vm.formData.fromdate = res.data.rec[0].fromdate;
          vm.formData.todate = res.data.rec[0].todate;
          vm.formData.name = res.data.rec[0].name;
          vm.formData.compnycode = res.data.rec[0].compnycode;
          vm.formData.compnyname = res.data.rec[0].compnyname;
          vm.formData.latedate = res.data.rec[0].latedate;
          vm.formData.duration = res.data.rec[0].duration;
          vm.formData.genamount = res.data.rec[0].genamount;
          vm.formData.finalamount = res.data.rec[0].finalamount;
          vm.formData.remark = res.data.rec[0].remark;
          vm.formData.currency = res.data.rec[0].currency;
          vm.formData.currencydesc = res.data.rec[0].currencydesc;
          vm.formData.posted = res.data.rec[0].posted;
          vm.formData.payoutcode = res.data.rec[0].payoutcode;
          vm.formData.payoutdesc = res.data.rec[0].payoutdesc;
          vm.formData.rectimestamp = res.data.rec[0].rectimestamp;
          vm.formData.username = res.data.rec[0].username;
          vm.formData.grouppcode = res.data.rec[0].grouppcode;
          vm.formData.grouppdesc = res.data.rec[0].grouppdesc;

          vm.formData.stamp = res.data.rec[0].stamp;
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/attnlatenessbatchd/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "latenessdeductiondetailslisting", params: { id: vm.formData.attnlatenessbatchid, search: vm.formData.search } });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadListing();
  },
};
</script>