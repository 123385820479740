<template>
    <div id="newgridview">
        <div class="btn-process">
            <v-btn color="primary" style="width: 180px" @click="onClick">
                {{$t('button.retrieveallpayitem')}}
            </v-btn>
        </div>

        <div style="margin-top: 20px"></div>

        <v-toolbar color="#ECEFF1">
            <h2 style="color: #3f6892; padding-left: 10px">
                Update Pay Item GL Code
            </h2>
            <v-spacer></v-spacer>
    
            <v-toolbar-items>
            <v-btn text @click="getCSVHandler" class="mr-5">
                <v-icon>mdi-export</v-icon>
            </v-btn>
            <v-text-field
                v-model="search"
                append-icon="search"
                style="width: 250px"
                class="pr-5 pt-3"
                hide-details
            >
            </v-text-field>
            </v-toolbar-items>
        </v-toolbar>
  
        <v-data-table
            dense
            loading
            :headers="tabHeaders"
            :items="this.payitemglList"
            :search="search"
            :items-per-page="10"
            class="elevation-2 ; font-weight-light;text--secondary;"
            style="font-size: small"
            multi-sort
            fixed-header
        >
            <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="ml-5 mr-2" @click="onClickDialog(item)">
                mdi-pen
            </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="subDialog" max-width="600px" persistent>

            <v-card>
                <v-card-title>
                    {{ subDialogTitle }} 
                    <!-- <span
                        class="page_title_value"
                        v-if="subDialog"
                        >{{ subDialogTitleValue }}</span
                    > -->
                    <span class="page_title_value" style="padding-left: 10px;"> {{
                        this.subDialogTitleValue
                    }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :label="$t('glcode.debitglcode')"
                                    v-model="
                                        formData.debitgl
                                    "
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    :label="$t('glcode.creditglcode')"
                                    v-model="
                                        formData.creditgl
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>                               
                    </v-container>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <app-formbuttons
                        v-on:SavePressed="saveDialogHandler()"
                        v-on:CancelPressed="cancelDialogHandler"
                    />
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row justify="start" align="center">
            <div class="pt-5 pb-4 pl-3" style="margin-bottom: 20px">
                <v-btn 
                    color="success" 
                    style="margin-right:20px; width: 100px" 
                    @click="saveHandler()">
                    {{$t('button.save')}} 
                </v-btn>
            </div>
        </v-row>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
  </template>
  
  <script>
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    components: {
        "app-error": ErrorDialog,
    },

    props: {
      parentData: Object,
    },

    data() {
      return {
        search: "",
        formTitle: this.$t('glcode.glcode'),

        subDialogTitle: "",
        subDialogTitleValue: "",
        subDialog: false,
        payitemglList: [],
        errMsg: "",
        isErr: false,
        isConfirm: false,

        formData: {
            payitemid: null,
            debitgl: null,
            creditgl: null,
            refpayitemid: null,
        },

        payitemglSaveList: [],

        tabHeaders: [
            { text: this.$t('columns.actions'), value: "action", sortable: false },
            { text: this.$t('workhourspayitemmappingsetup.payitemcode'), value: "payitemcode" },
            { text: this.$t('setupclaim.payitemdescription'), value: "payitemdesc" },
            { text: this.$t('glcode.debitglcode'), value: "debitgl" },
            { text: this.$t('glcode.creditglcode'), value: "creditgl" }
        ],
      };
    },
  
    methods: {

        loadGlCodeSetup() {
            let vm = this;
            
            this.$store.dispatch('get', {url: '/payglcode/all'})
                .then(res => {
                    vm.payitemglList = res.data.rec;
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        onClick() {
            let vm = this;
            
            this.$store.dispatch('get', {url: '/payglcodebypayitemid/all'})
                .then(res => {
                    vm.payitemglList = res.data.rec;
                    for (let i = 0; i < vm.payitemglList.length; i++) {
                        if (!vm.payitemglList[i].updatedby) {
                            vm.payitemglList[i].action = 1
                        } else {
                            vm.payitemglList[i].action = 2
                        }
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },

        onClickDialog(item) {
            this.subDialogTitleValue = item.payitemcode
            this.subDialogTitle = this.$t('reportsparam.createnew') + " " + this.formTitle;
            this.formData.refpayitemid = item.refpayitemid
            this.formData.debitgl = item.debitgl
            this.formData.creditgl = item.creditgl
            this.formData.payitemid = item.payitemid

            this.subDialog = !this.subDialog
        },
  
        objectToCSV(data) {
            const csvRows = [];
    
            //get header
            const headers = Object.keys(data[0]);
            //const headers = this.titles
            csvRows.push(headers.join(","));
    
            //loop over the rows
            for (const row of data) {
            const values = headers.map((header) => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(","));
            }
            return csvRows.join("\n");
        },
      
        downloadHandler(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute("download", this.parentData.pageTitle + ".csv");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

        async getCSVHandler() {
            let titles = this.parentData.tabHeaders;
            let item = this.parentData.tabItems;
            let data = [];
            let obj2 = {};
    
            titles.forEach((element1) => {
            let x = element1.text;
            obj2[x] = element1.value;
            });
    
            item.forEach((element1) => {
            let obj = {};
            titles.forEach((element2) => {
                if (element2.value != "action") {
                let key = element2.text;
                obj[key] = element1[element2.value];
                }
            });
            data.push(obj);
            });
    
            const csvData = this.objectToCSV(data);
            this.downloadHandler(csvData);
        },

        saveDialogHandler() {
            const index = this.payitemglList.findIndex(obj => obj.payitemid === this.formData.payitemid);
            if (index !== -1) {
                if (!this.formData.payitemid)  {
                    this.formData.action = 1
                } else {
                    this.formData.action = 2
                }
                Object.assign(this.payitemglList[index], this.formData);
            }
            this.subDialog = false;
        },

        cancelDialogHandler() {
            this.subDialog = false;
        },

        saveHandler() {
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
                return false;
            }

            this.$store.dispatch('put', {url: "/payglcode/" + this.$store.getters.userId, payload: this.payitemglList})
            .then((res) => {
                if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                } else {
                    // this.$router.push({ name: "affectitemlist", params: { search: this.search}  });
                    this.onClick()
                }
            }).catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },
    },
  
    mounted(){
        this.search = '';
        this.loadGlCodeSetup();
    },
  };
  </script>
  
  <style lang="scss">
  table {
    background: #f8f9f9;
  }
  
  .v-data-table td {
    font-size: 14px;
    height: 50px;
  }
  
  .class-on-data-table table {
    table-layout: fixed;
  }
  </style>