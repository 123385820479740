<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{this.parentObject.pageTitle}}
                        <span
                            class="page_title_value"
                        >{{this.parentObject.pageTtileValue}}</span>
                    </h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="10" sm="10" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                :rules="[rules.required]"
                                hide-selected
                                v-model="formData.staffid"
                                dense
                                @input="updateValue"
                                :items="staffDetailsList"
                                @change="changeStaffIDandCompanyHandler(formData.staffid)"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="10" sm="10" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                readonly
                                :items="staffDetailsList"
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffcode')"
                                type="text"
                                ref="staffid"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                @input="updateValue"
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyid"
                                dense
                                @input="updateValue"
                                :items="companiesitemsList"
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n4 mt-n8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('recdisciplinaryrec.disciplinarytype')"
                            type="text"
                            ref="discplinid"
                            :rules="[rules.required]"
                            :items="disciplinTypesList"
                            dense
                            @input="updateValue"
                            @change="changeDisciplinHandler(formData.disciplinid)"
                            v-model="formData.disciplinid"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="disciplintypeDialogue" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: disciplinTypeItems,
                                            formTitle: `${this.$t('recdisciplinaryrec.disciplinarytype')}`,
                                            formId: 'disciplin'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveDisciplin="saveDisciplinHandler"
                                        v-on:deleteDisciplin="deleteDisciplinHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="disciplintypeDialogue = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="disciplinTypeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('recdisciplinaryrec.severitylevel')"
                            type="text"
                            :rules="[rules.required]"
                            :items="severeLevelList"
                            dense
                            @input="updateValue"
                            @change="changeSevereLevelHandler(formData.severelevelid)"
                            v-model="formData.severelevelid"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="serverelevelDialogue" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: severeLevelItems,
                                            formTitle: `${this.$t('recdisciplinaryrec.severitylevel')}`,
                                            formId: 'severelevel'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveSevereLevel="saveSevereLevelHandler"
                                        v-on:deleteSevereLevel="deleteSevereLevelHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="serverelevelDialogue = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="severeLevelCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('recdisciplinaryrec.casedesc')"
                                type="text"
                                ref="casedesc"
                                :rules="[rules.required]"
                                v-model="formData.casedesc"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.date')"
                                type="date"
                                :rules="[rules.required]"
                                v-model="formData.casedate"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="8" sm="10" class="pb-n5 mt-n8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('recdisciplinaryrec.officerincharge')"
                            type="text"
                            :items="staffDetailsList"
                            dense
                            @input="updateValue"
                            v-model="formData.officerid"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('recdisciplinaryrec.caseoutcome')"
                            type="text"
                            :rules="[rules.required]"
                            :items="disciplinOutcomeList"
                            dense
                            @input="updateValue"
                            @change="changeDisciplinOutcomeHandler(formData.dsoutcomeid)"
                            v-model="formData.dsoutcomeid"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="disciplinOutcomeDialogue" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: disciplinOutcomeItems,
                                            formTitle: `${this.$t('recdisciplinaryrec.disciplinoutcome')}`,
                                            formId: 'dsoutcome'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveDisciplinOutcome="saveDisciplinOutcomeHandler"
                                        v-on:deleteDisciplinOutcome="deleteDisciplinOutcomeHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="disciplinOutcomeDialogue = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="disciplinOutcomeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n5 mt-n8">
                            <v-file-input 
                                ref="supportdoc"
                                :label="this.$t('recdisciplinaryrec.supportingdoc')" 
                                outlined 
                                v-model="fileHolder"
                                @change="fileToBase64"
                                dense
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <div v-if="this.formData.supportdoc">
                        <v-row>
                            <v-col cols="4" sm="6" class="pb-8 mt-n5">
                                <p>
                                    <a 
                                        v-bind:href="this.formData.supportdoc" 
                                        v-bind:download="this.formData.supportdocname"
                                    >
                                        {{formData.supportdocname}}
                                    </a>
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteItemHandler"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </p>
                            </v-col>
                        </v-row>
                    </div>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                                rows="1"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')},
                number: value => value > -0.01 || this.$t('reportsparam.invalidnumber')
            },

            formData: {
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                disciplinid: this.parentObject.data.disciplinid,
                severelevelid: this.parentObject.data.severelevelid,
                casedesc: this.parentObject.data.casedesc,
                casedate: this.parentObject.data.casedate,
                officerid: this.parentObject.data.officerid,
                dsoutcomeid: this.parentObject.data.dsoutcomeid,
                supportdoc: this.parentObject.data.supportdoc,
                supportdocname: this.parentObject.data.supportdocname,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,

                //additional line
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            
            fileHolder: null,

            isNewDocument: false,

            staffdetails: [],
            staffDetailsList: [],
            companiesitemsList: [],

            disciplinTypes: [],
            disciplinTypesList: [],
            disciplinTypeItems: [],
            disciplinTypeCode: '',
            severeLevel: [],
            severeLevelList: [],
            severeLevelItems: [],
            severeLevelCode: '',
            disciplinOutcome: [],
            disciplinOutcomeList: [],
            disciplinOutcomeItems: [],
            disciplinOutcomeCode: '',
            dsoutcomeList: [],
            officerInChargeList: [],

            disciplintypeDialogue: false,
            serverelevelDialogue: false,
            disciplinOutcomeDialogue: false,

            pageTitleValue: this.parentObject.pageTitleValue,
            isFiltered: false,
            filteredMovements: null,
            filteredMovementList: [],
            reasonDialog: false,
            currencyDialog: false,
            isEdit: this.parentObject.isEdit,
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        fileToBase64() {
          if (this.fileHolder) {
            var file = this.$refs.supportdoc.internalValue;
            this.formData.supportdocname = this.fileHolder.name
            
            var reader = new FileReader();

            reader.onloadend = () => {
                var b64 = reader.result;
                this.formData.supportdoc = b64
            }

            reader.readAsDataURL(file);
          }
        },

        // staff

        loadStaffDetailsListHandler() {
          const vm = this;

          this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
            .then(res => {
              vm.staffdetails = res.data.rec;
              this.onChange();

              for (let i = 0; i < res.data.rec.length; i++) {
                vm.staffDetailsList.push({
                  text: res.data.rec[i].psndetails,
                  value: res.data.rec[i].id
                });
              }
            })
            .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        },

        loadCompaniesListHandler() {
          const vm = this;

          this.$store.dispatch('get', {url: "/company/all"})
            .then(res => {
              vm.companies = res.data.rec;

              for (let i = 0; i < res.data.rec.length; i++) {
                vm.companiesitemsList.push({
                  text: res.data.rec[i].compnyname,
                  value: res.data.rec[i].id
                });
              }
            })
            .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        },

        changeStaffIDandCompanyHandler(id) {
          let item = this.staffdetails.find(item => item.id === id);

          //set staffid to staffid
          this.formData.staffcode = item.staffcode;
          this.formData.staffid = item.id;
          this.formData.psnname = item.psnname;
        
          //set compnyid to compnyid of item
          for (let i = 0; i < this.companiesitemsList.length; i++) {
            if (item.compnyid == this.companiesitemsList[i].value) {
            
                this.formData.compnyid = this.companiesitemsList[i].value;
            }
            else {
                //do nothing
            }
          }
        },

        // disciplin type

        getDisciplinListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/disciplinaryrecord/disciplin'})
                .then(res => {
                    vm.disciplinTypes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.disciplinTypesList.push(
                            {
                                text: res.data.rec[i].disciplindesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.disciplinTypeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].disciplincode,
                                desc: res.data.rec[i].disciplindesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.disciplinid) {
                        vm.changeDisciplinHandler(vm.formData.disciplinid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveDisciplinHandler() {
            let vm = this;
            
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/disciplinaryrecord/disciplin/' + this.itemData.itemid, payload: {
                    disciplincode: vm.itemData.itemcode,
                    disciplindesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.disciplinTypes = [],
                        vm.disciplinTypesList = [],
                        vm.disciplinTypeItems = [],

                        this.getDisciplinListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;    
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/disciplinaryrecord/disciplin/create', payload: {
                    disciplincode: vm.itemData.itemcode,
                    disciplindesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.disciplinTypes = [],
                        vm.disciplinTypesList = [],
                        vm.disciplinTypeItems = [],

                        this.getDisciplinListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteDisciplinHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/disciplinaryrecord/disciplin/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.disciplinTypes = [],
                        vm.disciplinTypesList = [],
                        vm.disciplinTypeItems = [],

                        this.getDisciplinListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        changeDisciplinHandler(id) {
            let item = this.disciplinTypes.find(item => item.id == id);

            this.disciplinTypeCode = item.disciplincode;
        },

        // severe level

        getSevereLevelListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/disciplinaryrecord/severelevel'})
                .then(res => {
                    vm.severeLevel = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.severeLevelList.push(
                            {
                                text: res.data.rec[i].sevleveldesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.severeLevelItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].sevlevelcode,
                                desc: res.data.rec[i].sevleveldesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.severelevelid) {
                        vm.changeSevereLevelHandler(vm.formData.severelevelid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveSevereLevelHandler() {
            let vm = this;
            
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/disciplinaryrecord/severelevel/' + this.itemData.itemid, payload: {
                    sevlevelcode: vm.itemData.itemcode,
                    sevleveldesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.severeLevel = [],
                        vm.severeLevelList = [],
                        vm.severeLevelItems = [],

                        this.getSevereLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;    
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/disciplinaryrecord/severelevel/create', payload: {
                    sevlevelcode: vm.itemData.itemcode,
                    sevleveldesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.severeLevel = [],
                        vm.severeLevelList = [],
                        vm.severeLevelItems = [],

                        this.getSevereLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteSevereLevelHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/disciplinaryrecord/severelevel/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.severeLevel = [],
                        vm.severeLevelList = [],
                        vm.severeLevelItems = [],

                        this.getSevereLevelListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        changeSevereLevelHandler(id) {
            let item = this.severeLevel.find(item => item.id == id);

            this.severeLevelCode = item.sevlevelcode;
        },

        // disciplin outcome

        getDisciplinOutcomeListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/disciplinaryrecord/dsoutcome'})
                .then(res => {
                    vm.disciplinOutcome = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.disciplinOutcomeList.push(
                            {
                                text: res.data.rec[i].outcomedesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.disciplinOutcomeItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].outcomecode,
                                desc: res.data.rec[i].outcomedesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.dsoutcomeid) {
                        vm.changeDisciplinOutcomeHandler(vm.formData.dsoutcomeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveDisciplinOutcomeHandler() {
            let vm = this;
            
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/disciplinaryrecord/dsoutcome/' + this.itemData.itemid, payload: {
                    outcomecode: vm.itemData.itemcode,
                    outcomedesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.disciplinOutcome = [],
                        vm.disciplinOutcomeList = [],
                        vm.disciplinOutcomeItems = [],

                        this.getDisciplinOutcomeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;    
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/disciplinaryrecord/dsoutcome/create', payload: {
                    outcomecode: vm.itemData.itemcode,
                    outcomedesc: vm.itemData.itemdesc,
                    userid: vm.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.disciplinOutcome = [],
                        vm.disciplinOutcomeList = [],
                        vm.disciplinOutcomeItems = [],

                        this.getDisciplinOutcomeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteDisciplinOutcomeHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/disciplinaryrecord/dsoutcome/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.disciplinOutcome = [],
                        vm.disciplinOutcomeList = [],
                        vm.disciplinOutcomeItems = [],

                        this.getDisciplinOutcomeListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        changeDisciplinOutcomeHandler(id) {
            let item = this.disciplinOutcome.find(item => item.id == id);

            this.disciplinOutcomeCode = item.outcomecode;
        },

        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                disciplinid: this.formData.disciplinid,
                severelevelid: this.formData.severelevelid,
                casedesc: this.formData.casedesc,
                casedate: this.formData.casedate,
                officerid: this.formData.officerid,
                dsoutcomeid: this.formData.dsoutcomeid,
                supportdoc: this.formData.supportdoc,
                supportdocname: this.formData.supportdocname,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
            });
        },

        newHandler() {
            this.$router.push({name: 'disciplinarynew'});
        },

        saveHandler() {
            this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
            this.updateValue()
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'disciplinarylist', params: { search: this.search}});
        },

        deleteItemHandler() {
            this.formData.supportdoc = ''
            this.formData.supportdocname = ''
        },

        onChange() {
            if (this.isEdit) {
                let item = this.staffdetails.find(item => item.staffcode === this.formData.staffcode);
            
                this.formData.staffid = item.id;
            }
        },
    },

    mounted() {
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
        this.getDisciplinListHandler();
        this.getSevereLevelListHandler();
        this.getDisciplinOutcomeListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>