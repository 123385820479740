<template>
    <div>
      <div v-if="!loading">
        <app-form
            v-bind:parentObject="{
            data: this.formData[0],
            searchnew: this.search,
            pageTitle: this.pageTitle,
            pageTitleValue: this.pageTitleValue,
            errMsg: this.errMsg,
            isEdit: this.editMode,
            }"
            v-on:input="formData[0] = $event"
            v-on:new="createHandler"
            v-on:save="saveHandler"
            v-on:delete="isConfirm = !isConfirm"
            v-on:error="errorHandler"
        />

        <div v-if="isErr">
            <app-error
            v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>

        <div v-if="isConfirm">
            <app-confirm
            v-bind:parentObject="{
                pageTitleValue: this.pageTitleValue,
                isConfirm: this.isConfirm,
            }"
            v-on:confirm="deleteHandler($event)"
            v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
        </div>
    </div>
</template>

<script>
// import moment from 'moment';

import intgsetupForm from "../../../components/settings/intgsetup/Form.vue";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
    components: {
        "app-form": intgsetupForm,
        "app-error": ErrorDialog,
        "app-confirm": ConfirmDialog,
    },

    data() {
        return {
        id: this.$route.params.id,
        search: this.$route.params.search,
        pageTitle: 'Edit Integration Setup ',
        pageTitleValue: "",
        formData: [],
        errMsg: "",
        isErr: false,
        loading: true,
        editMode: true,
        isConfirm: false,
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        saveHandler() {
            
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
                return false;
            }

            this.formData[0].userid = this.$store.getters.userId;

            var payload = this.formData[0];
            console.log('Save Detail', payload);

            this.$store.dispatch('put', {url: "/intgsetup/" + vm.id, payload: payload})
            .then((res) => {
                console.log("SUccess")
            if (res.data.error) {
                vm.isErr = !vm.isErr;
                vm.errMsg = res.data.error;
            } else {
                this.$router.push({ name: "intgsetuplist", params: { search: this.search} });
            }
            })
            .catch((err) => {
                console.log("Fail")
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
            });

        },

        deleteHandler($event) {

            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
                return false;
            }

            this.isConfirm = !this.isConfirm;
            console.log(this.formData[0]);

            if ($event) {
                this.$store.dispatch('delete', {url:"/intgsetup/" + this.id})
                .then((res) => {
                    if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                    } else {
                    this.$router.push({ name: "intgsetuplist" });
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }

        },

        createHandler() {
            this.$router.push({ name: "intgsetupnew" });
        },

        loadintgsetupHandler() {

            const vm = this;
            console.log('id', this.id)

            this.$store.dispatch('get', {url: "/intgsetup/" + vm.id})
            .then((res) => {

            if (res.status == 200) {

                vm.formData = res.data.rec;

                vm.formData[0].updatedby = vm.$store.getters.userId;
                vm.formData[0].intsettingd = res.data.rec.reduce(function (a, b) {
                    if (b.intsettingdid){
                        a.push({
                            id: b.intsettingdid,
                            action: b.action,
                            companyid: b.companyid,
                            compnycode: b.compnycode,
                            compnyname: b.compnyname,
                            refid: b.refid,
                            refkey1: b.refkey1,
                            refkey2: b.refkey2,
                        });
                    }
                    return a;
                }, []);
                vm.loading = false;

            } else {
                vm.isErr = !vm.isErr;
            }
            })
            .catch((err) => {
                console.log(err);
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },
    },

    mounted() {
        this.loadintgsetupHandler();
    },
};
</script>