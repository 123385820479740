<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="12" sm="6" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.selectpayitem')"
                                type="text"
                                v-model="formData.payitemid"
                                hide-selected
                                :items="payItemList"
                                :rules="[rules.required]"
                                @change="changePayitemHandler(formData.payitemid)"
                                background-color="white"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                ref = "payitemcode"
                                type="text"
                                v-model="payitemcode"
                                dense
                            ></v-text-field>
                        </v-col>

                    </v-row>

                     <v-row v-else>
                        <v-col cols="12" sm="6" class="pb-n4">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.selectpayitem')"
                                type="text"
                                v-model="formData.payitemid"
                                :items="payItemList"
                                :rules="[rules.required]"
                                background-color="#ffeab2"
                                readonly
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n4">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                ref = "payitemcode"
                                type="text"
                                v-model="payitemcode"
                                dense
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('periodicincentivesetup.minumumattendance')"
                                type="text"
                                id = "minattpercent"
                                ref= "minattpercent"
                                :rules="[rules.required]"
                                v-model="formData.minattpercent"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="nolateness"
                                v-model="formData.nolateness"
                                :label="this.$t('periodicincentivesetup.nolateness')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>

                         <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="noearlyleaving"
                                v-model="formData.noearlyleaving"
                                :label="this.$t('periodicincentivesetup.noearlyleaving')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>

                         <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="noleaverecord"
                                v-model="formData.noleaverecord"
                                :label="this.$t('periodicincentivesetup.noleaverecord')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.periodicdetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            pageTitle: this.$t('periodicattendanceincentivedetailinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                periodicdetail: this.parentObject.data.periodicdetail,
                payitemid: this.parentObject.data.payitemid,
                payitemcode: this.parentObject.data.payitemcode,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                minattpercent: this.parentObject.data.minattpercent,
                nolateness: this.parentObject.data.nolateness,
                noearlyleaving: this.parentObject.data.noearlyleaving,
                noleaverecord: this.parentObject.data.noleaverecord,
                lockstaff:this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.logic'), value: 'logicname', width: '200px', sortable: false},
                { text: this.$t('periodicincentivesetup.workdaygroup'), value: 'workdaygroupdesc', width: '200px', sortable: false },
                { text: this.$t('columns.company'), value: 'compnyname', width: '200px', sortable: false },
                { text: this.$t('columns.branch'), value: 'brchname', width: '200px', sortable: false },
                { text: this.$t('columns.department'), value: 'deptname', width: '200px', sortable: false },
                { text: this.$t('columns.division'), value: 'divnname', width: '200px', sortable: false },
                { text: this.$t('columns.section'), value: 'sectioname', width: '200px', sortable: false },
                { text: this.$t('columns.unit'), value: 'unitname', width: '200px', sortable: false },
                { text: this.$t('columns.employmenttype'), value: 'emptypedesc', width: '200px', sortable: false },
                { text: this.$t('columns.grade'), value: 'grddesc', width: '200px', sortable: false },
                { text: this.$t('columns.job'), value: 'jobtitle', width: '200px', sortable: false },
                { text: this.$t('columns.position'), value: 'posdesc', width: '200px', sortable: false },
    
            ],

            payItemList: [],
            datapayItems: [],
            payitemcode: '',

            errMsg: '',
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.perioddetail =$event
        },

        
        changePayItem() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/periodpayitem/all'
            })
            .then(res => {

                vm.datapayItems = res.data.rec;

                for (let i = 0; i < vm.datapayItems.length; i++) {
                    vm.payItemList.push({
                        text: vm.datapayItems[i].payitemdesc,
                        code: vm.datapayItems[i].payitemcode,
                        value: vm.datapayItems[i].id,
                    });
                }

                if (this.formData.payitemid) {
                this.changePayitemHandler(this.formData.payitemid);
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changePayitemHandler(id) {
            var item = this.datapayItems.find(item => item.id === id);
            this.payitemcode = item.payitemcode;
        },
            
        updateValue() {
          //  console.log('patterndetail', this.formData.patterndetail)
            this.$emit('input', {
                id: this.formData.id,
                periodicdetail: this.formData.periodicdetail,
                payitemid: this.formData.payitemid,
                payitemcode: this.formData.payitemcode,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                minattpercent: this.formData.minattpercent,
                nolateness: this.formData.nolateness,
                noearlyleaving: this.formData.noearlyleaving,
                noleaverecord: this.formData.noleaverecord,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'periodshiftinclist', params: { search: this.search}});
        },


    },

    mounted(){
        this.changePayItem();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
            
        }
}
</script>