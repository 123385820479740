<template>
    <div>
        <monthlyssb-form v-if="this.id == 1"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlySSBForm from '../../../components/payroll/mmmonthlyssbexport/MonthlySSBPaye.vue';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlyssb-form': MonthlySSBForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('mmmonthlyssb.mmmonthlyssbexport'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },

}

</script>