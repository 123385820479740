<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3 class="blue--text mb-4"> {{$t('reportsparam.parameters')}} </h3>
          </v-col>

        </v-row>

        <br>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('setuppolicymaster.policyno')"
                type="text"
                v-model="formData.inspolicyid"
                :items="InsurancePolicyList"
                :rules="[rules.required]"
                @change="changeInsurancePolicyHandler(formData.inspolicyid)"
                @input="updateValue"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
             <v-col cols="12" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsexportstaffinsurancerec.staffinsuranceexportformat')"
                type="text"
                v-model="formData.expdesc"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

           <v-row>
             <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('reportsexportstaffinsurancerec.assignmenteffectivedatefrom')"
                    type="date"
                    ref="fromDate"
                    :rules="[rules.required]"
                    v-model="formData.fromDate"
                    dense
                    @input="updateValue"
                ></v-text-field>
              
            </v-col>

                <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('reportsexportstaffinsurancerec.assignmenteffectivedateto')"
                    type="date"
                    ref="untilDate"
                    :rules="[rules.required]"
                    v-model="formData.untilDate"
                    dense
                    @input="updateValue"
                ></v-text-field>
              
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                <v-checkbox
                    outlined
                    ref="excludesalary"
                    v-model="formData.excludesalary"
                    :label="this.$t('reportsexportstaffinsurancerec.excludebasicsal')"
                    dense
                    @input="updateValue"
                ></v-checkbox>
            </v-col>
          </v-row>
            
          <v-row>
              <v-col cols="12" sm="6" class="pb-n4 mt-n8">
                  <v-checkbox
                      outlined
                      ref="dependentdetail"
                      v-model="formData.dependentdetail"
                      :label="this.$t('reportsexportstaffinsurancerec.employeebankandemail_dependent')"
                      @input="updateValue"
                      dense
                  ></v-checkbox>
              </v-col>
          </v-row>

        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px" @click="nextHandler">
              {{$t('button.next')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('exportstaffinsurancerecords'),
    
      formData: {
        inspolicyid: null,
        fromDate: null,
        untilDate: null,
        policyno: null,
        expdesc: null,
        excludesalary: false,
        dependentdetail: false,
      
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      InsurancePolicyList: [],
      grpinspolicyitems: [],


      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {

      changeInsurancePolicy() {
            const vm = this;

            this.$store.dispatch('get', {url: '/groupinsurancepolicy/all'})
            .then(res => {

                vm.grpinspolicyitems = res.data.rec;

                for (let i = 0; i < vm.grpinspolicyitems.length; i++) {
                    vm.InsurancePolicyList.push({
                        text: vm.grpinspolicyitems[i].policyno,
                        value: vm.grpinspolicyitems[i].id,
                    });
                }

                if (this.formData.inspolicyid) {
                this.changeInsurancePolicyHandler(this.formData.inspolicyid);
                }  
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeInsurancePolicyHandler(id) {
            var item = this.grpinspolicyitems.find(item => item.id === id);

            this.formData.policyno = item.policyno;
            this.formData.expdesc = item.expdesc;

      },

    nextHandler() {

      const formData = this.formData

        if(this.formData.fromDate > this.formData.untilDate || this.formData.untilDate < this.formData.fromDate ){

              this.$dialog.alert(this.$t('reportsexportstaffinsurancerec.datemessage')).then((dialog) => {
              console.log(dialog);
          });

            } else if ((this.formData.inspolicyid != null && this.formData.fromDate != null && this.formData.untilDate != null))  {
                 this.$router.push({name: 'exportstaffinsurancedetail', params: { formData } });

             } else {
                this.$refs.form.validate()
             }
      
    },


       updateValue() {
            this.$emit("input", {
                 inspolicyid: this.formData.inspolicyid,
                 policyno: this.formData.policyno,
                 fromDate: this.formData.fromDate,
                 untilDate: this.formData.untilDate,
                 expdesc: this.formData.expdesc,
                 excludesalary: this.formData.excludesalary,
                 dependentdetail: this.formData.dependentdetail,
                  
            });
        },
  },

  mounted() {
  this.changeInsurancePolicy();
  },
};
</script>