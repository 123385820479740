<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('allbank.salaryexportrec')"
                                type="text"
                                v-model="formData.salexprecid"
                                :items="salaryExpRecList"
                                :rules="[rules.required]"
                                @change="changeSalaryExportRecord(formData.salexprecid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('bank')"
                                type="text"
                                v-model="formData.bank"
                                readonly
                                dense
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="mb-n1 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
                                type="text"
                                v-model="formData.year"
                                readonly
                                dense
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6" sm="6" class="mb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('bankpaymentlistingreport.payoutmonth')"
                                type="text"
                                v-model="formData.month"
                                readonly
                                dense
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text ml-2">
                            {{$t('statutoryexportcommonused.payouts')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12">
                        <v-data-table
                            :headers="dataHeader"
                            :items="this.payoutItems"
                            :options="tableOpt"
                            hide-default-footer
                            item-key="payoutcode"
                        >
                        </v-data-table>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5 mt-6">
                            <v-autocomplete
                                outlined
                                v-model="formData.salexpformatid"
                                :label="this.$t('allbank.salaryexportformat')"
                                type="text"
                                :items="this.exportFmtList"
                                :rules="[rules.required]"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
                
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px; 
                            margin-bottom: 20px;"
                            @click="backHandler">
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px;
                            margin-bottom: 20px;" 
                            @click="nextHandler"> 
                            {{$t('button.next')}}
                        </v-btn>
                    </div>
                </v-row>
            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('allbank.monthlysalaryexportprocess'),
            dataHeader: [
                { text: this.$t('statutoryexportcommonused.payoutid'),            value: 'payoutcode',    width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.payoutdescription'),   value: 'payoutdesc',    width: '300px', sortable: true},
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',  width: '300px', sortable: true}
            ],
            formData: {
                salexprecid: null,
                bank: null,
                year: null,
                month: null,
                salexprecdetail: [],
                salexpformatid: null
            },

            parameters: {
                brchid: this.$route.params.param.brchid,
                compnyid: this.$route.params.param.compnyid,
                deptid: this.$route.params.param.deptid,
                emptypeid: this.$route.params.param.emptypeid,
                gradeid: this.$route.params.param.gradeid,
                staffid: this.$route.params.param.staffid,
                pomth: null,
                poyr: null,
            },

            tableOpt: {
                itemsPerPage: -1
            },

            items: [],
            dataItems: [],
            salaryExpRecList: [],
            payoutItems: [],
            exportformatItems: [],
            exportFmtList: [],

            noPayout: false,
            notFinalize: false,
             data2: [],
            isErr: false,
            errMsg: ''
        }
    },

    methods: {

        getSalaryExportRecordListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/salaryexportrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.salaryExpRecList.push(
                            {
                                text: res.data.rec[i].salexpreccode,
                                value: res.data.rec[i].id
                            }
                        )
                    } 

                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        getPayoutListHandler(id) {
            const vm = this;

            this.$store.dispatch('get', {url: '/salaryexportrecordd/' + id})
                .then((res) => {
                    vm.payoutItems = res.data.rec;
                    vm.formData.salexprecdetail = vm.payoutItems
                   
                        for (let i = 0; i < vm.payoutItems.length; i++) {
                            if (vm.payoutItems[i].pystatuscode != 99) {
                                this.notFinalize = false
                            } else {
                                this.notFinalize = true
                            }

                               vm.data2.push(this.notFinalize)
                        }


                })
                .catch((err) => {
                  vm.isErr = !vm.isErr;
                  vm.errMsg = err;
                });
        },

        getExportFormattListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/exportformat/all'})
                .then(function (res) {
                    vm.exportformatItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].exptypeid == 1) {
                            vm.exportFmtList.push(
                                {
                                    text: res.data.rec[i].expdesc,
                                    value: res.data.rec[i].id
                                }
                            )
                        }
                    } 
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });
        },

        changeSalaryExportRecord(id) {
            let itemExportRecord = this.dataItems.find(item => item.id === id);

            this.formData.salexprecid = itemExportRecord.id
            this.formData.bank = itemExportRecord.accountdesc
            this.formData.year = itemExportRecord.salexpyear
            this.formData.month = itemExportRecord.salexpmonth
            this.parameters.pomth = itemExportRecord.salexpmonth
            this.parameters.poyr = itemExportRecord.salexpyear

            this.getPayoutListHandler(id)
        },

        nextHandler() {
            let item = this.formData
            let id = this.formData.salexpformatid
            let salexprecid = this.formData.salexprecid
            let parameter = this.parameters
            // var newvalue = true;
    
            // for (let i = 0; i < this.data2.length; i++) {
            //     if (!this.data2[i])
            //     {
            //     newvalue = false;
            //     }
            // }
      
            if (!this.$refs.form.validate()) {
                this.isErr =  true;
                this.errMsg = this.$t('vdatatable.someinfoismissing')
            } else if (this.noPayout) {
                this.isErr =  true;
                this.errMsg = this.$t('vdatatable.payoutismissing')
            } else {
               if (this.notFinalize == false) {
                    this.isErr =  true;
                    this.errMsg = this.$t('vdatatable.somepayoutnotfinalize')
                } else {
                    this.$router.push({name: 'salaryexportparam', params: { id, salexprecid, item, parameter }});
                }
            }
        },

        backHandler() {
            this.$router.push({ name: "salaryexportparameter" });
        },

    },

    mounted() {
        this.getSalaryExportRecordListHandler();
        this.getExportFormattListHandler();
    }
}
</script>