<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value"> {{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                            outlined
                            label="Year"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.year"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.country')"
                            type="text"
                            hide-selected
                            dense
                            :items="countryDetailsList"
                            v-model="formData.country"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col col="12" sm="12" class="pb4-4">
                            <app-table 
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.formData.countryspecificstatddetail
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />

            </v-form>
        </v-container>
    </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object,
       // timeHolder: Object
    },

    components: {
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {
                id: this.parentObject.data.id,
                countryspecificstatddetail: this.parentObject.data.countryspecificstatddetail,
                year: this.parentObject.data.year,
                country: this.parentObject.data.country,

                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.type'), value: 'type', width: '100px', sortable: false},
                { text: this.$t('columns.release'), value: 'reldesc', width: '100px', sortable: false},
    
            ],
            
            years: [],
            yearList: [],
            countryDetailsList: [],
            countryIdList: [],
            countryCompanyList: [],

            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew

        }
    },

    methods: {
        addItemHandler($event) {
            this.formData.countryspecificstatddetail =$event
        },

        loadYear(){
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
                .then(res => {
                    vm.years = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )                        
                    }

                    if (vm.formData.pyyear) {
                        vm.changeYearHandler(vm.formData.pyyear);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeYearHandler(id) {
            this.years.find(item => item.id === id);
            
        },

        loadCountryDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/country/all`})
                .then((res) => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.countryDetailsList.push({
                    text: res.data.rec[i].cntryname,
                    value: res.data.rec[i].id,
                    });
                }

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.countryIdList.push({
                    text: res.data.rec[i].cntrycode,
                    value: res.data.rec[i].id,
                    });
                }
            })
                .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },



        updateValue() {

        this.$emit('input', {

                id: this.formData.id,
                countryspecificstatddetail: this.formData.countryspecificstatddetail,

                year: this.formData.year,
                country: this.formData.country,

                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'cntrysslist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadYear();
        this.loadCountryDetailsListHandler();
    }



}
</script>