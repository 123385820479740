<template>
  <div id="companygrid">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
// import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setupaddressmaster.addressmasterlist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.address'), value: "adrmascode", width: "120px" },
        { text: this.$t('columns.description'), value: "adrmasdesc", width: "200px" },
        { text: this.$t('setupaddressmaster.taxno'), value: "taxno", width: "120px" },
        { text: this.$t('setupcompany.regno'), value: "regno", width: "120px" },
        // { text: 'EFFECTIVE DATE', value: 'startdate', width: '200px' },
        // { text: 'INVALID DATE', value: 'enddate', width: '200px' },
        { text: this.$t('columns.addressline1'), value: "addr1", width: "200px" },
        { text: this.$t('columns.addressline2'), value: "addr2", width: "200px" },
        { text: this.$t('columns.addressline3'), value: "addr3", width: "200px" },
        { text: this.$t('columns.city'), value: "cityname", width: "200px" },
        { text: this.$t('columns.country'), value: "cntryname", width: "120px" },
        { text: this.$t('columns.postcode'), value: "postcode", width: "120px" },
        { text: this.$t('columns.telephone'), value: "tel", width: "200px" },
        { text: this.$t('columns.website'), value: "www", width: "200px" },
        { text: this.$t('columns.email'), value: "email", width: "200px" },
        { text: this.$t('columns.company'), value: "compnyname", width: "250px" },
        { text: this.$t('columns.disable'), value: "isdisable", width: "120px" },
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getAddressMasterListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/address/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          // for (let i = 0; i < vm.dataItems.length; i++) {
          //     if (vm.dataItems[i].startdate) {
          //         vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
          //     }

          //     if (vm.dataItems[i].enddate) {
          //         vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
          //     }
          // }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "addressedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "addressnew" });
    },
  },
  mounted() {
    this.getAddressMasterListHandler();
  },
};
</script>