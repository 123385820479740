<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2>{{ this.pageTitle }}</h2>
        </v-row>
        <!-- custom-form overflow-y-auto -->
        <v-container id="scroll-target" class="px-5">
          <v-card>
            <div class="ma-4">
              <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
              
              <v-row v-if="switchA">
                <v-col cols="6" sm="6" class="pb-n5 ml-3">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.payout')"
                    type="text"
                    ref="payout"
                    hide-selected
                    v-model="payoutid"
                    :items="payoutctrlList"
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="switchB">
                <v-col cols="1" sm="1" class="pt-5 pb-n5 ml-4">
                  <p>{{$t('reportsparam.from')}}</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.year')"
                    type="text"
                    v-model="yearFrom"
                    :items="yearList"
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1" sm="1" class="pt-5 pb-n5 ml-4">
                  <p>{{$t('reportsparam.to')}}</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 ml-2">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.year')"
                    type="text"
                    v-model="yearTo"
                    :items="yearList"
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="switchB">
                <v-col cols="1" sm="1" class="pt-5 pb-n5 mt-n8 ml-4">
                  <p>{{$t('reportsparam.from')}}</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.month')"
                    type="text"
                    v-model="monthFrom"
                    :items="monthList"
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1" sm="1" class="pt-5 pb-n5 mt-n8 ml-4">
                  <p>TO :</p>
                </v-col>
                <v-col cols="2" sm="2" class="pb-n5 ml-2 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('reportsparam.month')"
                    type="text"
                    v-model="monthTo"
                    :items="monthList"
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

            </div>
          </v-card>

        </v-container>
          <v-spacer></v-spacer>
          <v-btn 
            color="warning" 
            style="width: 100px"
            @click="onProcess" 
            > 
            {{$t('button.go')}}
          </v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {

      yearFrom: '',
      yearTo: '',
      monthFrom: '',
      monthTo: '',
      yearArr: [],

      payoutid: '',

      pageTitle: this.$t('payrollenquirybyservicemovementreport.payrollenquirybyservicemovement'),
      yearList: [],
      monthList: [],
      payoutctrlList: [],

      switchA: true,
      switchB: true,

    };
  },

  watch: {

    payoutid() {
        if(this.payoutid != undefined || this.payoutid != null || this.payoutid != '') {
            this.switchB = !this.switchB;
        } else if(this.payoutid == undefined || this.payoutid == null || this.payoutid == '') {
            this.switchB = !this.switchB;
        }
    },

    yearFrom() {
        if(this.yearFrom != undefined || this.yearFrom != null || this.yearFrom != '') {
            this.switchA = !this.switchA;
        } else if(this.yearFrom != undefined || this.yearFrom != null || this.yearFrom != '') {
            this.switchA = !this.switchA;
        }
    }

  },

  methods: {

    onProcess() {

      if(this.payoutid != '') {

        const id = this.payoutid;
        this.$router.push({
            name: "payrollenquirybysmdetail",
            query: { id },
        });

      } else if(this.monthFrom != '' && this.monthTo != '' && this.yearFrom != '' && this.yearTo != ''){

        const yearfrom = this.yearFrom;
        const yearto = this.yearTo;
        const monthfrom = this.monthFrom;
        const monthto = this.monthTo;

        this.findInBetweenArr(this.yearList, yearfrom, yearto);

        const yeararr = this.yearArr;

        this.$router.push({
            name: "payrollenquirybysmdetail",
            query: { yearfrom, yearto, monthfrom, monthto, yeararr },
        });

      } else if(this.yearFrom != '' && this.yearTo != '') {

        const yearfrom = this.yearFrom; 
        const yearto = this.yearTo;
        this.$router.push({
            name: "payrollenquirybysmdetail",
            query: { yearfrom, yearto },
        });

      } else {
        console.log("Fill in the parameter");
      }

    },

    loadPayout() {
      const vm = this;

      this.$store.dispatch('get', {
      url: '/payoutctrl/all'})
      .then(res => {

          // vm.payoutList = res.data.rec;
          let data = res.data.rec;

          for (let i = 0; i < data.length; i++) {

              vm.payoutctrlList.push(
                  {
                      text: data[i].payoutcode,
                      value: data[i].payoutcode
                  }
              )

              vm.yearList.push(
                  {
                      text: data[i].pyyear,
                      value: data[i].pyyear
                  }
              )        
              
              vm.monthList.push(
                  {
                      text: data[i].pymonth,
                      value: data[i].pymonth
                  }
              )
          }

          this.findUnique(vm.yearList);
          this.findUnique(vm.monthList);

          
      })
      .catch(err => {
          vm.isErr = true;
          vm.errMsg = err;
      });                                   
    },

    findUnique(arr) {
      const uniqueIds = [];

        const unique = arr.filter(element => {
        const isDuplicate = uniqueIds.includes(element.value);

        if (!isDuplicate) {
          uniqueIds.push(element.value);
          return true;
        }

        return false;
      });

      // Sort 
      if( arr == this.yearList ) {
        this.yearList = unique.sort((a, b) => a.value - b.value);
      } else if( arr == this.monthList ) {
        this.monthList = unique.sort((a, b) => a.value - b.value);
      }
      
    },

    findInBetweenArr(arr, x, y) {

      let output = [];

      for (let i = 0; i < arr.length; i++){

        if(arr[i].value > x && arr[i].value < y){
          output.push(parseInt(arr[i].value));

        }
      }

      this.yearArr = output;

    }

  },

  mounted() {
    this.loadPayout();
  },
};
</script>