<template>
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">

                <v-row>
                    <div class="pt-2 pl-8 pb-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 70px;"
                            @click="backHandler" 
                            > 
                            Back
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form px-5"
                >

                    <div>
                        <v-row>
                            <h3 class="pt-2 pb-3 pl-3">{{ this.parentObject.pageTitle }}</h3>
                        </v-row>

                        <v-row>
                            <v-col cols="8" class="pb-n5">
                                <v-text-field
                                    outlined
                                    label="Description"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Goal"
                                    ref="asofdate1"
                                    type="date"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" class="mt-n6 mb-2 big-text-field">
                                <v-text-field
                                    outlined
                                    label="KPI"
                                    ref="asofdate1"
                                    readonly
                                    :rules="[rules.required]"
                                    v-model="formData.test"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" class="pb-n4 mt-n8 ml-3">
                                <v-row>
                                    <v-col cols="4" sm="4" class="pl-0">
                                        <v-text-field
                                            outlined
                                            label="Target"
                                            type="text"
                                            :rules="[rules.required]"
                                            dense
                                            class="v-input"
                                            readonly
                                            background-color="#FFFDD0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4" sm="4" class="px-0">    
                                        <v-text-field
                                            outlined
                                            label="Weightage"
                                            type="text"
                                            :rules="[rules.required]"
                                            dense
                                            class="v-input"
                                            readonly
                                            background-color="#FFFDD0"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>

                    <div>
                        <v-row>
                            <app-table
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.ratingscalesetdetail
                                }"

                                v-on:input="addItemHandler"
                            />
                        </v-row>
                    </div>
                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import CRUDTable from './CRUDTableCommonKPI';

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-table': CRUDTable,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                asofdate1: null,
                asofdate2: null,
                compnyid: null,
                compnycode: null,
                test: 38,

            },

            companyname: [],
            companyList: [],
            errMsg: "",
            isErr: false,

            ratingscalesetdetail: [
                {ratingcode: 1},
            ],

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: 'Review date', value: 'ratingcode', width: '200px', sortable: true},
                { text: 'Achievement', value: 'label', width: '200px', sortable: true},
                { text: 'Progress', value: 'label', width: '200px', sortable: true},
                { text: 'Comment', value: 'label', width: '200px', sortable: true},
            ],
        }
    },

    methods: {

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
            let item = this.companyname.find((item) => item.id === id);
            this.formData.compnyid = item.id;
            this.formData.compnycode = item.compnycode;

        },

        backHandler(){
            this.$router.push({name: 'kpisettingedit', params: { id: 1 } });
        },

        updateValue() {
            this.$emit("input", {
                 asofdate1: this.formData.asofdate1,
                 asofdate2: this.formData.asofdate2,
                 compnyid: this.formData.compnyid,
                 compnycode: this.formData.compnycode,
                
            });
        },
    }, 

    mounted() { 
      this.loadCompanyListHandler();
     
    },
    
}
</script>

<style scoped>
.big-text-field .v-input__slot{
    height: 100px;
}

#input-35 {
    margin-top: -50px;
}
</style>
