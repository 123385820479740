<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row v-if="!this.formData.lockstaff">
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.name')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              @input="updateValue"
              @change="changeStaffIDandCompanyHandler(formData.staffid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.name')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              readonly
              background-color="#ffeab2"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.staffid')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.staffcode"
              dense
              readonly
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.company')"
              type="text"
              ref="compnyid"
              v-model="formData.compnyname"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('staffdailyrosterrecord.shiftdate')"
              type="date"
              ref="shiftdate"
              :rules="[rules.required]"
              v-model="formData.shiftdate"
              @input="updateShiftdate"
              @change="updateShiftdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('preapprovedotrecord.otstartdate')"
              type="date"
              ref="otstartdate"
              :rules="[rules.required]"
              v-model="formData.otstartdate"
              @change="updateValue"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('staffdailyrosterrecord.shift')"
              type="text"
              v-model="formData.shiftcode"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('setuprestdaypatternsetup.daytype')"
              type="text"
              ref="leavedays"
              v-model="formData.daytypecode"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" md="4" class="mt-n8">
            <!-- <vue-timepicker
                auto-scroll
                drop-direction="down"
                v-model="formData.otstart"
                format="HH:mm"
                placeholder="Start OT Time"
                input-class="time-picker"
                :minute-interval="15"
                close-on-complete
                input-width="100%"
                @input="inputStarttimeHandler"
              ></vue-timepicker> -->
            <v-text-field
              outlined
              :label="this.$t('preapprovedotrecord.startottime')"
              type="number"
              v-model="formData.otstart"
              @input="inputStarttimeHandler"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('setupforshift.othours')"
              type="number"
              v-model="formData.othrs"
              @input="inputOthrs"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4" class="mt-n8">
            <v-text-field
              outlined
              :label="this.$t('preapprovedotrecord.otcompletiontime')"
              type="number"
              v-model="formData.otend"
              readonly
              append-icon=""
              background-color="#ffeab2"
              dense
            ></v-text-field>
            <!-- <vue-timepicker
                auto-scroll
                drop-direction="down"
                v-model="formData.otend"
                format="HH:mm"
                placeholder="OT Completion Time "
                input-class="time-picker"
                :minute-interval="15"
                close-on-complete
                input-width="100%"
                @input="inputEndtimeHandler"
              ></vue-timepicker> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" sm="10" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('preapprovedotrecord.justify')"
              type="text"
              ref="justify"
              v-model="formData.justify"
              dense
              @input="updateValue"
            ></v-text-field>
            <!-- :rules="[rules.required]" -->
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('columns.status')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.transtatusid"
              dense
              :items="leavestatuslist"
              @change="changeLeaveStatus(formData.transtatusid)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="4" class="mt-n8">
            <v-checkbox
              outlined
              v-model="formData.earlyot"
              :label="this.$t('preapprovedotrecord.earlyot')"
              dense
              @change="updateValue"
            ></v-checkbox>
          </v-col>
          <!-- @input="updateValue" -->
        </v-row>

        <v-row>
          <v-col cols="4" sm="10" class="pb-n4 mt-n8">
            <div>
              <v-form>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                        >{{$t('preapprovedotrecord.entryandapprovalinfo')}}</v-subheader
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <v-row>
                          <v-col cols="4" sm="10" class="blue--text">
                            {{$t('preapprovedotrecord.entryinfo')}}</v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{$t('recleaverec.submittedby')}}</v-col
                          >
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{ formData.entrybyusername }}</v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{$t('preapprovedotrecord.submissiontime')}}</v-col
                          >
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{ formData.entrytime }}</v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{$t('setuptp1.source')}}</v-col
                          >
                          <v-col
                            cols="4"
                            sm="4"
                            class="mt-n3"
                            style="padding: 8px 12px"
                            >{{ formData.src }}</v-col
                          >
                        </v-row>
                      </div>

                      <div>
                        <v-row>
                          <v-col cols="4" sm="10" class="blue--text"
                            >{{$t('preapprovedotrecord.approvalinfo')}}</v-col
                          >
                        </v-row>
                        <div
                          v-for="(item, index) in leaveApprInfo"
                          :key="index"
                          class="pb-5"
                        >
                          <!-- <v-row>
                              <v-col cols="4" sm="4" class="mt-n3"
                                >Source</v-col
                              >
                              <v-col cols="4" sm="4" class="mt-n3">{{
                                item.
                              }}</v-col>
                            </v-row> -->
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.actiontime')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ item.aprdate }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.actby')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ item.staffcode }} - {{ item.psnname }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.status')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ item.aprstatus }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.approvalremark')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ item.remark }}</v-col
                            >
                          </v-row>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <!-- </v-container> -->

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import ErrorDialog from "../../../components/common/ErrorDialog";
import moment from "moment";
// import VueTimepicker from "vue2-timepicker";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    // "vue-timepicker": VueTimepicker,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },
      currentShiftDate: null,
      formData: {
        id: this.parentObject.data.id,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnyname: this.parentObject.data.compnyname,
        compnycode: this.parentObject.data.compnycode,
        daytypeid: this.parentObject.data.daytypeid,
        daytypecode: this.parentObject.data.daytypecode,
        shiftdate: this.parentObject.data.shiftdate,
        otstartdate: this.parentObject.data.otstartdate,
        shiftid: this.parentObject.data.shiftid,
        shiftcode: this.parentObject.data.shiftcode,
        otstart: this.parentObject.data.otstart,
        otend: this.parentObject.data.otend,
        othrs: this.parentObject.data.othrs,
        justify: this.parentObject.data.justify,
        transtatusid: this.parentObject.data.transtatusid,
        transtatus: this.parentObject.data.transtatus,
        earlyot: this.parentObject.data.earlyot,
        entrytime: this.parentObject.data.entrytime,
        entrybid: this.parentObject.data.entrybid,
        entrybyusername: this.parentObject.data.entrybyusername,
        appsrcid: this.parentObject.data.appsrcid,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        src: this.parentObject.data.src,
        stamp: this.parentObject.data.stamp,
        userid: this.$store.getters.userId,
      },

      shiftdetail: [],
      daytypedetail: [],

      //arrays to store data objects from load list handlers
      leaveApprInfo: [],
      staffdetails: [],
      staffDetailsList: [],
      companies: [],
      companiesitemsList: [],
      leavestatus: [],
      leavestatuslist: [],
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        id: this.formData.id,
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        compnyname: this.formData.compnyname,
        compnycode: this.formData.compnycode,
        daytypeid: this.formData.daytypeid,
        daytypecode: this.formData.daytypecode,
        shiftdate: this.formData.shiftdate,
        otstartdate: this.formData.otstartdate,
        shiftid: this.formData.shiftid,
        shiftcode: this.formData.shiftcode,
        otstart: this.formData.otstart,
        otend: this.formData.otend,
        othrs: this.formData.othrs,
        justify: this.formData.justify,
        transtatusid: this.formData.transtatusid,
        transtatus: this.formData.transtatus,
        earlyot: this.formData.earlyot,
        lockstaff: this.formData.lockstaff,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.$store.getters.userId,
      });
    },

    loadShiftData() {
      let data = {
        shiftdate: this.formData.shiftdate,
        // staffid: this.formData.staffid,
        userid: this.formData.userid,
      };

      const vm = this;
      this.$store.dispatch('post', {
          url:'/shiftdata/getshiftdata',
          payload: data
        })
        .then((res) => {
          //req success --> push res into array
          vm.shiftdetail = res.data.rec;
          this.loadDayTypeData();
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadDayTypeData() {
      let data = {
        shiftdate: this.formData.shiftdate,
        // staffid: this.formData.staffid,
        userid: this.formData.userid,
      };

      const vm = this;
      this.$store.dispatch('post', {
          url:'/daytypedata/getdaytype',
          payload: data
        })
        .then((res) => {
          //req success --> push res into array
          vm.daytypedetail = res.data.rec;
          this.changeDaytypeShifttypeHnadler();
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    updateShiftdate() {
      this.formData.otstartdate = this.formData.shiftdate;
      this.updateValue();
      this.loadShiftData();
    },
    //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
          url: `/staff/all/${this.$store.getters.userId}`
        })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            // if (vm.formData.staffid) {
            //   this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            // }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadLeaveStatusListHandler() {
      const vm = this;
      console.log(vm.formData.transtatusid);
      this.$store.dispatch('get', {
          url: '/leave/status'
        })
        .then((res) => {
          //req success --> push res into array
          vm.leavestatus = res.data.rec;
          console.log('leavestatus', vm.leavestatus)
          for (let i = 0; i < res.data.rec.length; i++) {
            if (
              res.data.rec[i].transtatus === "APPROVED" &&
              !vm.formData.transtatusid
            ) {
              this.formData.transtatusid = res.data.rec[i].id;
            }

            vm.leavestatuslist.push({
              text: res.data.rec[i].transtatus,
              value: res.data.rec[i].id,
            });
          }
          console.log('formData', vm.formData.transtatusid)
          if (vm.formData.transtatusid) {
            console.log(vm.formData);
            this.changeLeaveStatus(vm.formData.transtatusid);
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeLeaveStatus(id) {
      console.log('id', id)
      let item = this.leavestatus.find((item) => item.id === id);
      this.formData.transtatusid = item.id;
      this.formData.transtatus = item.transtatus;
      this.updateValue();
    },

    //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      this.updateValue();
      this.loadShiftData();
    },

    changeDaytypeShifttypeHnadler() {
      if (this.formData.staffid && this.formData.shiftdate) {
        let daytypeitem = this.daytypedetail.filter(
          (item) => item.staffid === this.formData.staffid
        );

        if (daytypeitem.length > 0) {
          let datefilter = daytypeitem[0].daytype.find(
            (item) => item.shiftdate === this.formData.shiftdate
          );
          this.formData.daytypecode = datefilter.daytypecode;
          this.formData.daytypeid = datefilter.daytypeid;
        }

        let stfdailyscheduleitem = this.shiftdetail.stfdailyschedule.filter(
          (item) => item.staffid === this.formData.staffid
        );

        let wsstaffassign = this.shiftdetail.wsstaffassign.filter(
          (item) => item.staffid === this.formData.staffid
        );

        let wsgrpassign = this.shiftdetail.wsgrpassign.filter(
          (item) => item.staffid === this.formData.staffid
        );

        if (stfdailyscheduleitem.length > 0) {
          this.formData.shiftcode = stfdailyscheduleitem[0].shiftcode;
          this.formData.shiftid = stfdailyscheduleitem[0].shiftid;
        } else if (wsstaffassign.length > 0) {
          this.formData.shiftcode = wsstaffassign[0].shiftcode;
          this.formData.shiftid = wsstaffassign[0].shiftid;
        } else if (wsgrpassign.length > 0) {
          this.formData.shiftcode = wsgrpassign[0].shiftcode;
          this.formData.shiftid = wsgrpassign[0].shiftid;
        }
      }
    },

    inputStarttimeHandler() {
      if (this.formData.othrs && this.formData.otstart != "") {
        let currenttime = moment(this.formData.otstart, "HHmm").add(
          this.formData.othrs,
          "hours"
        );
        this.formData.otend = currenttime.format("HHmm");
      }
      this.updateValue();
    },

    inputOthrs() {
      if (this.formData.othrs && this.formData.otstart != "") {
        let currenttime = moment(this.formData.otstart, "HHmm").add(
          this.formData.othrs,
          "hours"
        );
        this.formData.otend = currenttime.format("HHmm");
      }
      this.updateValue();
    },
    updateHours() {
      if (this.formData.otstart != "" && this.formData.otend != "") {
        let start = this.formData.otstart.split(":");
        let end = this.formData.otend.split(":");
        this.formData.othrs = parseInt(end[0]) - parseInt(start[0]);
      }
    },
    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "preapprovedotlist" , params: { search: this.search}});
    },

    loadLeaveAprInfo() {
      if (this.formData.id) {
      this.$store.dispatch('get', {
          url: '/leaverecord/wftran' + this.formData.id
        })
          .then((res) => {
            this.leaveApprInfo = res.data.rec;
            for (var i = 0; i < this.leaveApprInfo.length; i++) {
              this.leaveApprInfo[i].aprdate = moment(
                this.leaveApprInfo[i].aprdate
              ).format("YYYY-MM-DD HH:mm:ss");
            }
          });
      }
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadLeaveStatusListHandler();
    this.loadLeaveAprInfo();

     if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>