<template>
    <div>
        <v-btn color="primary" dark class="mb-2" @click.stop="newItem">+</v-btn>
        <v-dialog v-model="formDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('columns.selectpayitem')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setuppayitem.payitem')"
                                    type="text"
                                    :items="payItemList"
                                    v-model="selections[0].payitemid"
                                    @change="
                                        changePayItemHandler(
                                            selections[0].payitemid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="4" md="4">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupprocesslistmaster.precedence')"
                                    type="number"
                                    v-model="selections[0].precd"
                                    @input="changePrecedenceHandler"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red;">{{
                        errMsg
                    }}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeFormDialog"
                        >{{$t('button.cancel')}}</v-btn
                    >

                    <v-btn
                        v-if="allowSave"
                        color="blue darken-1"
                        text
                        @click="saveItem"
                        >{{$t('button.save')}}</v-btn
                    >
                    <v-btn
                        v-else
                        disabled
                        color="blue darken-1"
                        text
                        @click="saveItem"
                        >{{$t('button.save')}}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="this.detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object,
    },

    data() {
        return {
            formDialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [
                {
                    id: null,
                    proclistmasid: parseInt(this.$route.params.id),
                    payitemid: null,
                    payitemcode: null,
                    payitemdesc: null,
                    precd: null,
                    rectimestamp: null,
                    updatedby: null,
                    action: 0,
                },
            ],

            detailList: [],

            selected: [],

            payItems: [],
            payItemList: [],
            isNew: false,
            isExisting: false,
            // isNewItem: true,
            // isModExisting: false,
            // isModNewInsert: false,

            tmpDetailItem: null,

            oItem: null,
            oPrecd: null,

            isErr: false,
            allowSave: false,
            withDuplicates: false,
            errMsg: "",
        };
    },

    methods: {
        loadPayItems() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitem/codes"})
                .then((res) => {
                    vm.payItems = res.data.rec;

                    for (let i = 0; i < vm.payItems.length; i++) {
                        vm.payItemList.push({
                            text: vm.payItems[i].payitemdesc,
                            value: vm.payItems[i].id,
                        });
                    }
                });
        },

        dupDetailItemHandler() {
            let origDetailItem = [...this.detailItem];
            let tmpDetailItem = [];

            origDetailItem.forEach((item) => {
                tmpDetailItem.push({
                    id: item.payitemid,
                    precd: item.precd,
                });
            });

            return tmpDetailItem;
        },

        modifyItemHandler(item) {
            this.isExisting = true;

            this.selections[0] = item;
            this.oItem = item.payitemid;
            this.oPrecd = item.precd;

            this.openFormDialog();
        },

        deleteItemHandler(item) {
            // get the index of the item
            let itemIndex = this.detailItem.indexOf(item);

            // remove item from detailItem
            this.detailItem.splice(itemIndex, 1);
        },

        checkDupItem(val) {
            if (this.tmpDetailItem.some((item) => item.id == val)) {
                return true;
            }

            return false;
        },

        checkDupPrecd(val) {
            if (this.tmpDetailItem.some((item) => item.precd == val)) {
                return true;
            }

            return false;
        },

        checkAllFields() {
            // convert current selections to int
            let currItemId = parseInt(this.selections[0].payitemid);
            let currPercd = parseInt(this.selections[0].precd);

            if (this.selections[0].payitemid && this.selections[0].precd) {
                let isDupItem = false;
                let isDupPrecd = false;

                if (this.isExisting) {
                    // check item
                    if (this.oItem !== currItemId) {
                        isDupItem = this.checkDupItem(currItemId);

                        if (isDupItem) {
                            this.duplicateError("item");
                            return;
                        }
                    }

                    // check precd
                    if (this.oPrecd !== currPercd) {
                        isDupPrecd = this.checkDupPrecd(currPercd);

                        if (isDupPrecd) {
                            this.duplicateError("precedence");
                            return;
                        }
                    }
                } else {
                    // check item
                    isDupItem = this.checkDupItem(currItemId);

                    if (isDupItem) {
                        this.duplicateError("item");
                        return;
                    }

                    // check precd
                    isDupPrecd = this.checkDupPrecd(currPercd);

                    if (isDupPrecd) {
                        this.duplicateError("precedence");
                        return;
                    }
                }

                this.allowSave = true;
            }
        },

        changePayItemHandler(id) {
            this.isErr = false;

            let item = this.payItems.find((item) => item.id == id);
            this.selections[0].payitemcode = item.payitemcode;
            this.selections[0].payitemdesc = item.payitemdesc;

            this.checkAllFields();
        },

        changePrecedenceHandler() {
            this.isErr = false;

            this.checkAllFields();
        },

        newItem() {
            // set the form to empty if it is new
            this.isNew = true;

            if (this.isNew) {
                this.resetSelections();
            }

            this.openFormDialog();
        },

        openFormDialog() {
            this.tmpDetailItem = this.dupDetailItemHandler();

            this.formDialog = true;
        },

        closeFormDialog() {
            // this function is the cancel button event

            // set the form to empty
            this.resetSelections();
            // revert detailItem to the state before modify
            this.detailItem = [...this.detailItem];

            this.formDialog = false;
            this.isErr = false;
        },

        duplicateError(typeStr) {
            this.isErr = true;
            this.errMsg = "Duplicate " + typeStr + " not allowed";
            this.allowSave = false;
        },

        saveItem() {
            this.isErr = false;

            if (this.isNew) {
                this.detailItem.push(Object.assign({}, this.selections[0]));

                for (let i = 0; i < this.detailItem.length; i++) {
                    if (this.detailItem[i].action == 0) {
                        this.detailItem[i].action = 1;
                    }
                }
            }
            this.$emit("input", this.detailItem);
            this.formDialog = false;
        },

        resetSelections() {
            this.selections = [
                {
                    id: null,
                    proclistmasid: parseInt(this.$route.params.id),
                    payitemid: null,
                    payitemcode: null,
                    payitemdesc: null,
                    precd: null,
                    rectimestamp: null,
                    updatedby: null,
                    action: 0,
                },
            ];
        },
    },

    created() {
        this.loadPayItems();
    },
};
</script>
