<template>
    <div>
        <v-dialog v-model="taxDialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('alltax.monnthlytaxsubmissionitem')}} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.refnocode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].refcodeid"
                            @change="changeRefcodeHandler(selections[0].refcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                            type="text"
                            v-model="selections[0].refnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>

                   
                <!-- <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('alltax.hqtaxcode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].hqtaxcodeid"
                            @change="changeTaxCodeHandler(selections[0].hqtaxcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('alltax.hqtaxno')"
                            type="text"
                            v-model="selections[0].hqtaxnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row> -->

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptno')"
                            type="text"
                            v-model="selections[0].rcpno"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptdate')"
                            type="date"
                            v-model="selections[0].rcpdate"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('statutoryexportcommonused.receiptreference')"
                            type="text"
                            v-model="selections[0].rcpref"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeTaxDialog">{{$t('button.cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="saveTax">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="editDialog" max-width="800px">
         
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('alltax.monnthlytaxsubmissionitem')}} </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.refnocode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].refcodeid"
                            @change="changeRefcodeHandler(selections[0].refcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                            type="text"
                            v-model="selections[0].refnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>

                   
                <!-- <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('alltax.hqtaxcode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].hqtaxcodeid"
                            @change="changeTaxCodeHandler(selections[0].hqtaxcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('alltax.hqtaxno')"
                            type="text"
                            v-model="selections[0].hqtaxnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row> -->

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptno')"
                            type="text"
                            v-model="selections[0].rcpno"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptdate')"
                            type="date"
                            v-model="selections[0].rcpdate"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                     <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('statutoryexportcommonused.receiptreference')"
                            type="text"
                            v-model="selections[0].rcpref"
                            dense
                        ></v-text-field>
                    </v-col>
                    
                </v-row>

             
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeTaxDialog">{{$t('button.cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="editTax">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            isPayRate: false,
            taxDialog: false,
            editDialog: false, 
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                taxsubmissionrecordid: null,
                no: null,
                refcodeid: null,
                refcode: null, 
                refnum: null, 
                hqtaxcodeid: null, 
                hqtaxcode: null,
                hqtaxnum: null, 
                rcpno: null,
                rcpdate: null,
                rcpref: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            //arrays to store various data objects from db 
            refnums: [],
            refnumList: [],
            

            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
            isDisabled: false,
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
 
            if(item.action == 2) {
                this.isExisting = true; 
                this.isDisabled = true;   
            }

            this.selections[0] = item;
            this.editDialog = true;

            if (!this.isExisting) {
                //find index 
                var index = this.detailItem.findIndex( x => x.refcodeid == item.refcodeid);
                this.detailItem[index].rcpno = item.rcpno;
                this.detailItem[index].rcpdate = item.rcpdate;
                this.detailItem[index].rcpref = item.rcpref;
            } else {
                this.selections[0].action = 2;

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }
           
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

            for (let i =0; i < this.detailItem.length; i++) {
                this.detailItem[i].no = i + 1;
            }

            this.resetSelections();
        },

        closeTaxDialog() {
            this.taxDialog = false;
            this.editDialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },

        saveTax() {
            this.isErr = false;

            // if (this.selections[0].hqtaxcodeid == null && this.selections[0].refcodeid != null ){
            //     this.$dialog.alert(this.$t('allsalaryexportrecord.fillinhqtaxcode')).then((dialog) => {
            // console.log(dialog);
            // });
            // } else if (this.selections[0].refcodeid == null && this.selections[0].hqtaxcodeid != null ){
            //      this.$dialog.alert(this.$t('allsalaryexportrecord.fillintherefnocode')).then((dialog) => {
            //     console.log(dialog);
            //  });
            // }
                
            // else if (this.selections[0].hqtaxcodeid == null || this.selections[0].refcodeid == null){

            //   this.$dialog.alert(this.$t('allsalaryexportrecord.fillinhqtaxandref')).then((dialog) => {
            //     console.log(dialog);
            //  });
            // }

            if (this.selections[0].refcodeid == null){
                this.$dialog.alert(this.$t('allsalaryexportrecord.fillintherefnocode')).then((dialog) => {
                    console.log(dialog);
                });
            } else if (!this.isExisting) {
                this.selections[0].action = 1 ;

                if (this.detailItem.some( rec => rec.refcodeid == this.selections[0].refcodeid)) {
                    
                    this.errMsg = this.$t('alleissocso.onlyuniqitemallow');
                    this.isErr = true;
                    this.taxDialog = true; 
                } else {
                    this.selections[0].action = 1;
                    // console.log("selectioin", this.selections[0])
                    // console.log("detail item", this.detailItem)
                    this.detailItem.push(Object.assign({}, this.selections[0]));

                    for (let i = 0; i < this.detailItem.length; i++) {
                        this.detailItem[i].no = i + 1;
                    }  

                    this.taxDialog = false;
                    this.$emit('input', this.detailItem);

                    // reset the form fields
                    this.resetSelections();
                }
            } else {
                this.selections[0].action = 2;
                //this.resetSelections();

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }
            

        },

        editTax() {
            this.isExisting = false;
            this.editDialog = false;
            this.resetSelections();
            this.isErr =false; 
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                taxsubmissionrecordid: null,
                no: null,
                refcodeid: null,
                refcode: null, 
                refnum: null, 
                hqtaxcodeid: null, 
                hqtaxcode: null,
                hqtaxnum: null,
                rcpno: null,
                rcpdate: null,
                rcpref: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

        //load  data objects from db, stored in arrays - pay item, pay item type , ea , tp1, cp22, cp21,  
    
        loadRefnum() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/refnum/all'})
            .then (res => {
                vm.refnums = res.data.rec;
                
                for (let i = 0; i < vm.refnums.length; i++) {
                    vm.refnumList.push(
                        {
                            text: vm.refnums[i].refcode,
                            value: vm.refnums[i].id
                        }
                    )
                }

            });
        },
         
        //set selections[0].rcpref to display in table details
        changeRefcodeHandler(id) {
            let item = this.refnums.find(item => item.id == id)
            
            this.selections[0].refcode = item.refcode;
            this.selections[0].refnum = item.refnum;
        },

        changeTaxCodeHandler(id){
            let item = this.refnums.find(item => item.id == id)

            this.selections[0].hqtaxcode = item.refcode;
            this.selections[0].hqtaxnum = item.refnum;
        },

          
    },

    created() {
        this.loadRefnum();
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>