<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('latenessdeduction.policy'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('latenessdeduction.latenessparamcode'), value: "latenessparamcode", width: "120px" },
        { text: this.$t('latenessdeduction.latenessparamdesc'), value: "latenessparamdesc", width: "200px" },
        { text: this.$t('latenessdeduction.fromdate'), value: "fromdate", width: "120px" },
        { text: this.$t('latenessdeduction.todate'), value: "todate", width: "120px" },
        { text: this.$t('latenessdeduction.currencycode'), value: "currencycode", width: "120px" },
        { text: this.$t('latenessdeduction.payitemcode'), value: "payitemcode", width: "120px" },
        { text: this.$t('latenessdeduction.frequencyfactor'), value: "frequencyfactor", width: "120px" },
        { text: this.$t('latenessdeduction.remark'), value: "remark", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getPolicyListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/latenessdeductionpolicy/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].fromdate) {
              vm.dataItems[i].fromdate = moment(vm.dataItems[i].fromdate).format('YYYY-MM-DD');
            }
            if (vm.dataItems[i].todate) {
              vm.dataItems[i].todate = moment(vm.dataItems[i].todate).format('YYYY-MM-DD');
            }
          }
          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "latenessdeductionpolicyedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "latenessdeductionpolicynew" });
    },
  },
  mounted() {
    this.getPolicyListHandler();
  },
};
</script>