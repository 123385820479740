<template>
  <v-app id="app">
    <div v-if="!this.$route.meta.HideHeader">
      <app-header />
      <app-layout />
    </div>
    <div class="fill-height" v-else>
      <v-content>
        <router-view />
      </v-content>
    </div>
    <app-footer />
  </v-app>
</template>


<script>
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Layout from "./Layout";

export default {
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-layout": Layout,
  },

  methods: {
    getApiUrlHandler() {
      if (process.env.NODE_ENV === "development") {
        this.$store.dispatch("retrvDevApi").catch((error) => {
          console.log(error);
        });
      } else {
        this.$store.dispatch("retrvProdApi").catch((error) => {
          console.log(error);
        });
      }
    },
    checkislogin() {
      if (typeof this.$session.get("access_token") == "undefined") {
        // this.$store.dispatch("deleteToken");
        // this.$router.replace("/" + this.$i18n.locale + "/login");
      }
    },
    resetTokenHandler() {
      this.$store.dispatch("deleteToken");
    },

    getFileUploadConfig() {
      this.$store.dispatch("retriveFileUploadConfig").catch((error) => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.getApiUrlHandler();
    this.getFileUploadConfig();
    // this.checkislogin();
    //this.resetTokenHandler();
  },
};
</script>
