<template>
    <div>
        <v-dialog v-model="eDocdialog" max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('setuppayrate.payrateitem')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    clearable
                                    :items="companyList"
                                    v-model="selections[0].compnyid"
                                    @change="
                                        changeCompanyHandler(
                                            selections[0].compnyid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.branch')"
                                    type="text"
                                    clearable
                                    :items="branchList"
                                    v-model="selections[0].brchid"
                                    @change="
                                        changeBranchHandler(
                                            selections[0].brchid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.division')"
                                    type="text"
                                    ref = "divncode"
                                    clearable
                                    :items="divisionList"
                                    v-model="selections[0].divnid"
                                    @change="
                                        changeDivisionHandler(
                                            selections[0].divnid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.department')"
                                    type="text"
                                    clearable
                                    :items="departmentList"
                                    v-model="selections[0].deptid"
                                    @change="
                                        changeDepartmentHandler(
                                            selections[0].deptid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.section')"
                                    type="text"
                                    clearable
                                    :items="sectionList"
                                    v-model="selections[0].sectioid"
                                    @change="
                                        changeSectionHandler(
                                            selections[0].sectioid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.unit')"
                                    type="text"
                                    clearable
                                    :items="unitList"
                                    v-model="selections[0].unitid"
                                    @change="
                                        changeUnitHandler(selections[0].unitid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.employmenttype')"
                                    type="text"
                                    clearable
                                    :items="emptypeList"
                                    v-model="selections[0].emptypeid"
                                    @change="
                                        changeEmptypeHandler(
                                            selections[0].emptypeid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.grade')"
                                    type="text"
                                    clearable
                                    :items="gradeList"
                                    v-model="selections[0].grdid"
                                    @change="
                                        changeGradeHandler(selections[0].grdid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.job')"
                                    type="text"
                                    clearable
                                    :items="jobList"
                                    v-model="selections[0].jobid"
                                    @change="
                                        changeJobHandler(selections[0].jobid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red">{{
                        errMsg
                    }}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeEdoc"
                        >{{$t('button.cancel')}}</v-btn
                    >
                    <v-btn color="green darken-1" text @click="saveEdoc"
                        >{{$t('button.save')}}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="this.detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>

export default {

    props: {
        parentObject: Object
    },

    data() {
        return {
            eDocdialog: false,
            detailItem: this.parentObject.detailItem,
            modifyIndex: null,
            selections: [
                {
                    id: null,
                    edocid: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname: null,
                    brchid: null,
                    brchcode: null,
                    brchname: null,
                    divnid: null,
                    divncode: null,
                    divnname: null,
                    deptid: null,
                    deptcode: null,
                    deptname: null,
                    sectioid: null,
                    sectiocode: null,
                    sectioname: null,
                    unitid: null,
                    unitcode: null,
                    unitname: null,
                    emptypeid: null,
                    emptype: null,
                    emptypedesc: null,
                    grdid: null,
                    grdcode: null,
                    grddesc: null,
                    jobid: null,
                    jobcode: null,
                    jobtitle: null,
                    stamp: 0,
                    action: 0
                }
            ],

            companies: [],
            companyList: [],

            branches: [],
            branchList: [],

            divisions: [],
            divisionList: [],

            departments: [],
            departmentList: [],

            sections: [],
            sectionList: [],

            units: [],
            unitList: [],

            emptypes: [],
            emptypeList: [],

            grades: [],
            gradeList: [],

            jobs: [],
            jobList: [],

            isExisting: false,
            isErr: false,
            errMsg: ""
        };
    },

    methods: {

        modifyItemHandler(item) {
            this.modifyIndex = null;
            this.modifyIndex = this.detailItem.indexOf(item);
            this.selections[0] = item;
            this.eDocdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeEdoc() {
            this.eDocdialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },

        saveEdoc() {
            this.isErr = false;

            if(this.modifyIndex !== null){ // If Modify Item

                this.detailItem.splice(this.modifyIndex, 1, Object.assign({}, this.selections[0])); // Replace object in (what index ?, 1 array element, with what value ?)

            } else {

                this.detailItem.push(Object.assign({}, this.selections[0]))

            }

            console.log(this.detailItem);
            this.$emit("input", this.detailItem);

            this.eDocdialog = false;
            this.modifyIndex = null;
            this.resetSelections();
        },

        resetSelections() {
            this.selections = [
                {
                    id: null,
                    edocid: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname: null,
                    brchid: null,
                    brchcode: null,
                    brchname: null,
                    divnid: null,
                    divncode: null,
                    divnname: null,
                    deptid: null,
                    deptcode: null,
                    deptname: null,
                    sectioid: null,
                    sectiocode: null,
                    sectioname: null,
                    unitid: null,
                    unitcode: null,
                    unitname: null,
                    emptypeid: null,
                    emptype: null,
                    emptypedesc: null,
                    grdid: null,
                    grdcode: null,
                    grddesc: null,
                    jobid: null,
                    jobcode: null,
                    jobtitle: null,
                    stamp: 0,
                    action: 0
                }
            ];
        },

        loadCompanies() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"}).then(res => {
                vm.companies = res.data.rec;

                for (let i = 0; i < vm.companies.length; i++) {
                    vm.companyList.push({
                        text: vm.companies[i].compnyname,
                        value: vm.companies[i].id
                    });
                }
                if (vm.selections[0].compnyid) {
                    vm.changeCompanyHandler(vm.selections[0].compnycode);
                }
            });
        },

        loadBranches() {
            const vm = this;

            this.$store.dispatch('get', {url: "/branch/all"}).then(res => {
                vm.branches = res.data.rec;

                for (let i = 0; i < vm.branches.length; i++) {
                    vm.branchList.push({
                        text: vm.branches[i].brchname,
                        value: vm.branches[i].id
                    });
                }

                if (vm.selections[0].brchid) {
                        vm.changeBranchHandler(vm.selections[0].brchid);
                    }

            });
        },

        loadDivisions() {
            const vm = this;

            this.$store.dispatch('get', {url: "/division/all"})
                .then(res => {
                    vm.divisions = res.data.rec;

                    for (let i = 0; i < vm.divisions.length; i++) {
                        vm.divisionList.push({
                            text: vm.divisions[i].divnname,
                            value: vm.divisions[i].id
                        });
                    }
                });

                 if (vm.selections[0].divnid) {
                        vm.changeDivisionHandler(vm.selections[0].divnid);
                    }
        },

        loadDepartments() {
            const vm = this;

            this.$store.dispatch('get', {url: "/department/all"})
                .then(res => {
                    vm.departments = res.data.rec;

                    for (let i = 0; i < vm.departments.length; i++) {
                        vm.departmentList.push({
                            text: vm.departments[i].deptname,
                            value: vm.departments[i].id
                        });
                    }
                    if (vm.selections[0].deptid) {
                        vm.changeDepartmentHandler(vm.selections[0].deptid);
                    }
                });
        },

        loadSections() {
            const vm = this;

            this.$store.dispatch('get', {url: "/section/all"}).then(res => {
                vm.sections = res.data.rec;

                for (let i = 0; i < vm.sections.length; i++) {
                    vm.sectionList.push({
                        text: vm.sections[i].sectioname,
                        value: vm.sections[i].id
                    });
                }

                if (vm.selections[0].sectioid) {
                        vm.changeSectionHandler(vm.selections[0].sectioid);
                    }
            });
        },

        loadUnits() {
            const vm = this;

            this.$store.dispatch('get', {url: "/unit/all"}).then(res => {
                vm.units = res.data.rec;

                for (let i = 0; i < vm.units.length; i++) {
                    vm.unitList.push({
                        text: vm.units[i].unitname,
                        value: vm.units[i].id
                    });
                }

                if (vm.selections[0].unitid) {
                        vm.changeUnitHandler(vm.selections[0].unitid);
                    }
            });
        },

        loadEmpTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: "/emptype/all"}).then(res => {
                vm.emptypes = res.data.rec;

                for (let i = 0; i < vm.emptypes.length; i++) {
                    vm.emptypeList.push({
                        text: vm.emptypes[i].emptypedesc,
                        value: vm.emptypes[i].id
                    });
                }

                if (vm.selections[0].emptypeid) {
                        vm.changeEmptypeHandler(vm.selections[0].emptypeid);
                    }
            });
        },

        loadGrades() {
            const vm = this;

            this.$store.dispatch('get', {url: "/grade/all"}).then(res => {
                vm.grades = res.data.rec;

                for (let i = 0; i < vm.grades.length; i++) {
                    vm.gradeList.push({
                        text: vm.grades[i].grddesc,
                        value: vm.grades[i].id
                    });
                }

                if (vm.selections[0].grdid) {
                        vm.changeGradeHandler(vm.selections[0].grdid);
                    }
            });
        },

        loadJobs() {
            const vm = this;

            this.$store.dispatch('get', {url: "/job/all"}).then(res => {
                vm.jobs = res.data.rec;

                for (let i = 0; i < vm.jobs.length; i++) {
                    vm.jobList.push({
                        text: vm.jobs[i].jobtitle,
                        value: vm.jobs[i].id
                    });
                }

                if (vm.selections[0].jobid) {
                        vm.changeJobHandler(vm.selections[0].jobid);
                    }
            });
        },

        changeCompanyHandler(id) {
            let item = this.companies.find(item => item.id == id);

            this.selections[0].compnyname = item.compnyname;
            this.selections[0].compnycode = item.compnycode;
        },

        changeBranchHandler(id) {
            let item = this.branches.find(item => item.id == id);

            this.selections[0].brchname = item.brchname;
            this.selections[0].brchcode = item.brchcode;
        },

        changeDivisionHandler(id) {
            let item = this.divisions.find(item => item.id == id);

            this.selections[0].divnname = item.divnname;
            this.selections[0].divncode = item.divncode;
        },

        changeDepartmentHandler(id) {
            let item = this.departments.find(item => item.id == id);

            this.selections[0].deptname = item.deptname;
            this.selections[0].deptcode = item.deptcode;
        },

        changeSectionHandler(id) {
            let item = this.sections.find(item => item.id == id);

            this.selections[0].sectioname = item.sectioname;
            this.selections[0].sectiocode = item.sectiocode;
        },

        changeUnitHandler(id) {
            let item = this.units.find(item => item.id == id);

            this.selections[0].unitname = item.unitname;
            this.selections[0].unitcode = item.unitcode;
        },

        changeEmptypeHandler(id) {
            let item = this.emptypes.find(item => item.id == id);

            this.selections[0].emptypedesc = item.emptypedesc;
            this.selections[0].emptype = item.emptype;
        },

        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id == id);

            this.selections[0].grddesc = item.grddesc;
            this.selections[0].grdcode = item.grdcode;
        },

        changeJobHandler(id) {
            let item = this.jobs.find(item => item.id == id);

            this.selections[0].jobtitle = item.jobtitle;
            this.selections[0].jobcode = item.jobcode;
        },

        // editDetailItem() {

        //     const vm = this;

        //     for (let i = 0; i < vm.detailItem.length; i++) {
        //         vm.changeCompanyHandler(vm.detailItem[i].compnycode);
        //         vm.changeBranchHandler(vm.detailItem[i].brchid);
        //         vm.changeDivisionHandler(vm.detailItem[i].divnid);
        //         vm.changeDepartmentHandler(vm.detailItem[i].deptid);
        //         vm.changeSectionHandler(vm.detailItem[i].sectioid);
        //         vm.changeUnitHandler(vm.detailItem[i].unitid);
        //         vm.changeEmptypeHandler(vm.detailItem[i].emptypeid);
        //         vm.changeGradeHandler(vm.detailItem[i].grdid);
        //         vm.changeJobHandler(vm.detailItem[i].jobid);
        //     }

        // }

    },

    created() {

        this.loadCompanies();
        this.loadBranches();
        this.loadDivisions();
        this.loadDepartments();
        this.loadSections();
        this.loadUnits();
        this.loadEmpTypes();
        this.loadGrades();
        this.loadJobs();

        // if (this.detailItem){
        //     await this.editDetailItem();
        // }

        console.log("detail", this.detailItem);

    },

    

};
</script>