<template>
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5 pl-7">{{ this.parentObject.pageTitle }}<span class="page_title_value pl-2">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-row>
                    <div class="pt-2 pl-7 pb-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 70px;"
                            @click="backHandler" 
                            > 
                            Back
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <div>
                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Exercise code"
                                ref="asofdate1"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Exercise description"
                                ref="asofdate1"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                            <v-text-field
                                outlined
                                label="Period start date"
                                ref="asofdate1"
                                type="date"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                            <v-text-field
                                outlined
                                label="Period end date"
                                ref="asofdate1"
                                type="date"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                            <v-text-field
                                outlined
                                label="Status"
                                ref="asofdate1"
                                readonly
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    label="Service record as at date"
                                    type="date"
                                    ref="asofdate1"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Hire cut off date"
                                    type="date"
                                    ref="asofdate1"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>


                            <v-col cols="5" sm="5" class="pb-n5 mt-n6">
                                <h4 class="pt-3 grey--text font-weight-light">Staff with Hire Date (inclusive) onwards will not be included for objective setting exercise record creation.</h4>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="3" sm="3" class="pb-n5 mt-n6">
                                <v-text-field
                                    outlined
                                    label="Cessation cut off date"
                                    type="date"
                                    ref="asofdate1"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-text-field>
                            </v-col>


                            <v-col cols="5" sm="5" class="pb-n5 mt-n6">
                                <h4 class="pt-3 grey--text font-weight-light">Staff with Cessation Date (inclusive) onwards will not be included for objective setting exercise record creation.</h4>
                            </v-col>

                        </v-row>

                        <v-row>
                            <v-col cols="1" sm="1" class="pb-n5 mt-n6">
                                <v-checkbox
                                    class="ml-n6"
                                    ref="asofdate1"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                ></v-checkbox>
                            </v-col>


                            <v-col cols="7" sm="7" class="pb-n5 mt-n6 ml-n10">
                                <h4 class="pt-3 grey--text font-weight-light">Override existing records for the same exercise . Once checked, all data saved for the record under same exercise for the staff will be removed!</h4>
                            </v-col>

                        </v-row>
                    </div>
                </v-card>

                 <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5 pl-7">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="primary" 
                            style="width: 100px; margin-bottom: 20px;"
                            @click="generateHandler" 
                            > 
                            Generate
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-parameter': FilterParameter,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {

                asofdate1: null,
                asofdate2: null,
                compnyid: null,
                compnycode: null,

            },
            
                parameters: {
                 staffid: null,
                 staffcode: null,
                 branchid: null,
                 brchname: null,
                 sectionid: null,
                 sectioname: null,
                 unitid: null,
                 unitname: null,
                 divisionid: null,
                 divname: null,
                 departmentid: null,
                 deptname: null,
                 workgroupid: null,
                 valudesc2:null,
                
             },

             showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                gradeid: true,
                employmenttypeid: true,
                staffid: true,
             },

            companyname: [],
            companyList: [],
            errMsg: "",
            isErr: false
        }
    },

    methods: {

        //load leavetype list view
        loadCompanyListHandler() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/company/all'
                })
                .then((res) => {
                //req success --> push res into array
                vm.companyname = res.data.rec;

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push({
                    text: res.data.rec[i].compnyname,
                    value: res.data.rec[i].id,
                    });

                    if (vm.formData.compnyid) {
                    this.changeCompany(vm.formData.compnyid);
                    }
                }
                })
                .catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
            },


        changeCompany(id) {
                let item = this.companyname.find((item) => item.id === id);
                this.formData.compnyid = item.id;
                this.formData.compnycode = item.compnycode;

            },


            generateHandler(){

            // const formData = this.formData
            // const param = this.parameters

            // if(this.formData.asofdate1 > this.formData.asofdate2 || this.asofdate2 < this.formData.asofdate1 ){

            //     this.$dialog.alert("Attendance From Date should be earlier than Attendance Until Date").then((dialog) => {
            //     console.log('dailog', dialog);
            //  });

            // } else if ((this.formData.asofdate1 != null && this.formData.asofdate2 != null && this.formData.compnyid != null))  {
                
                 this.$router.push({name: 'kpisettinggeneratestaff'});

            //  } else {
            //     this.$refs.form.validate()
            //  }   
        },

        backHandler(){
            // this.$router.push({name: 'kpisettingnew', params: { formData, param } });
            this.$router.push({name: 'kpisettingnew'});
        },

        updateValue() {
            this.$emit("input", {
                 asofdate1: this.formData.asofdate1,
                 asofdate2: this.formData.asofdate2,
                 compnyid: this.formData.compnyid,
                 compnycode: this.formData.compnycode,
                
            });
        },
    }, 

    mounted() { 
      this.loadCompanyListHandler();
     
    },
    
}
</script>
