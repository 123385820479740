<template>
  <div id="`grouplist`">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('rechruserfunctionaccess.hruserfunctionaccesscntrllist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", width: "50px", sortable: false },
        { text: this.$t('columns.user'), value: "username", width: "150px" },
        { text: this.$t('columns.staffname'), value: "psnname", width: "150px" },
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
       search: this.$route.params.search,
    };
  },

  methods: {
    loadFunctionAccess() {
      const vm = this;
      this.$store.dispatch('get', {url: "/functionaccess/all"})
        .then((res) => {
          vm.dataItems = res.data.rec;
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "functionaccessedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "functionaccessnew" });
    },
  },

  mounted() {
    this.loadFunctionAccess();
  },
};
</script>