<template>

    <div id="ErrorMessageSelService">

        <v-dialog v-model="errorDialog" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    {{ errorMessage }}
                </v-card-title>
                <!-- <v-card-text>
                    {{ errorMessage }}
                </v-card-text> -->
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                        <v-row justify="center" align="center">
                            <div class="pt-5 pb-4">
                                <v-btn
                                    color="warning" 
                                    style="margin-right:20px; width: 100px" 
                                    @click="onOkPressed">
                                    {{$t('vdatatable.ok')}} 
                                </v-btn>
                            </div>
                        </v-row>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
    
</template>

<script>

export default {

    props: {
        errorDialog: Boolean,
        errorMessage: String,
    },

    methods: {
        onOkPressed() {
            this.$emit('OkPressed');
        }
    }
}

</script>