<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:new="newHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import GroupAssignmentForm from "../../../components/personnel/groupassignment/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": GroupAssignmentForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recgrpassignment.editgrpassignment'),
      pageTitleValue: "",
      formData: {
        psnname: null,
        psnid: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        startdate: null,
        enddate: null,
        override: null,
        remark: null,
        groupitems: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      deletedItems: [],
      insertedItems: [],
      groupassignmentdvalues: [],
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //load record by id
    loadGroupAssignment() {
      const vm = this;

      this.$store.dispatch('get', {url: "/groupassignment/" + this.id})
        .then((res) => {
          vm.formData.psnid = res.data.rec[0].psnid;
          vm.formData.staffid = res.data.rec[0].staffid;
          vm.formData.staffcode = res.data.rec[0].staffcode;
          vm.formData.compnyid = res.data.rec[0].compnyid;
          vm.formData.compnyname = res.data.rec[0].compnyname;
          vm.formData.startdate = res.data.rec[0].startdate;
          vm.formData.enddate = res.data.rec[0].enddate;
          vm.formData.remark = res.data.rec[0].remark;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;

          //set correct date format using moment
          if (vm.formData.startdate) {
            vm.formData.startdate = moment(vm.formData.startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (vm.formData.enddate) {
            vm.formData.enddate = moment(vm.formData.enddate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].override == "Y") {
            vm.formData.override = true;
          } else {
            vm.formData.override = false;
          }

          /*
                 let items = JSON.parse(res.data.rec[0].affectitems)

                
                for (let i = 0; i < items.length; i++) {
                    vm.formData.affectitems.push(items[i]);
                }
                */

          vm.pageTitleValue = res.data.rec[0].psnname;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //load group detail associated with the group id
    loadGroupAssignmentDetail() {
      const vm = this;

      this.$store.dispatch('get', {url: "/groupassignmentd/" + this.id})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            this.formData.groupitems.push({
              id: res.data.rec[i].id,
              gtypedesc: res.data.rec[i].gtypedesc,
              valuecode: res.data.rec[i].valvaluecode,
              valuedesc: res.data.rec[i].valvaluecodedesc,
              action: 0,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    newHandler() {
      this.$router.push({ name: "groupassignmentnew" });
    },

    //update record
    saveHandler() {
      const vm = this;

      if (this.formData.override == true) {
        this.formData.override = 1;
      } else {
        this.formData.override = 0;
      }

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/groupassignment/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "groupassignmentlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    //delete group
    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/groupassignment/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "groupassignmentlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "groupassignmentlist" });
    },
  },

  mounted() {
    this.loadGroupAssignment();
    this.loadGroupAssignmentDetail();
  },
};
</script>