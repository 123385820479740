var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","width":"75%","float":"left","padding-left":"0px"}},[_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',[_c('h2',{staticClass:"pb-5"},[_vm._v(_vm._s(this.pageTitle))])]),_c('v-container',{staticClass:"custom-form overflow-y-auto px-5",attrs:{"id":"scroll-target"}},[_c('v-card',[_c('div',{staticClass:"ma-4"},[_c('v-subheader',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.$t('reportsparam.parameters')))]),_c('v-row',[_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{ref:"startdate",attrs:{"outlined":"","label":this.$t('columns.fromdate'),"type":"date","hide-selected":"","dense":"","rules":[_vm.rules.required],"clearable":""},model:{value:(_vm.formData.startdate),callback:function ($$v) {_vm.$set(_vm.formData, "startdate", $$v)},expression:"formData.startdate"}})],1),_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{ref:"enddate",attrs:{"outlined":"","label":this.$t('columns.todate'),"type":"date","hide-selected":"","dense":"","rules":[_vm.rules.required],"clearable":""},model:{value:(_vm.formData.enddate),callback:function ($$v) {_vm.$set(_vm.formData, "enddate", $$v)},expression:"formData.enddate"}})],1)],1)],1)]),_c('app-parameter',{staticClass:"mt-5 mb-3",attrs:{"parentObject":{
                    filter: this.showFilter,
                }},on:{"input":function($event){_vm.parameters = {
                    companyid: $event.companyid,
                    branchid: $event.branchid,
                    departmentid: $event.departmentid,
                    employmenttypeid: $event.employmenttypeid,
                    valvalueid: $event.valvalueid
                }}}})],1),_c('v-row',[_c('div',{staticClass:"pt-5"},[_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"warning"},on:{"click":_vm.goHandler}},[_vm._v(" "+_vm._s(_vm.$t('button.go'))+" ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }