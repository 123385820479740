<template>
  <div>
      <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
        </v-container>

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="getHLBExportListHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from 'xlsx/xlsx';
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      //pageTitle: 'MBB-EMAS-01',

      id: this.parentObject.id,
      data: this.parentObject.data,
      salstatutoryother: null,
      psnstatutoryinfo: [],
      psnpayroll: [],
      titleData: this.parentObject.data.eissubrecpy[0].payoutdesc,
      statecodeList: [
        {
            text: 'Johor',
            value: '01',
        },
        {
            text: 'Kedah',
            value: '02',
        },
        {
            text: 'Kelantan',
            value: '03',
        },
        {
            text: 'Melaka',
            value: '04',
        },
        {
            text: 'Negeri Sembilan',
            value: '05',
        },
        {
            text: 'Pahang',
            value: '06',
        },
        {
            text: 'Pulau Pinang',
            value: '07',
        },
        {
            text: 'Perak',
            value: '08',
        },
        {
            text: 'Perlis',
            value: '09',
        },
        {
            text: 'Selangor',
            value: '10',
        },
        {
            text: 'Terengganu',
            value: '11',
        },
        {
            text: 'Sabah',
            value: '12',
        },
        {
            text: 'Sarawak',
            value: '13',
        },
        {
            text: 'Wilayah Persekutuan (Kuala Lumpur)',
            value: '14',
        },
        {
            text: 'Wilayah Persekutuan (Labuan)',
            value: '15',
        },
        {
            text: 'Wilayah Persekutuan (Putrajaya)',
            value: '16',
        }
      ],

      filetypeList: [
        {
          text: 'EIS',
          value: 'EIS',
        },
      ],

      companyrecnum: "",
      bankcode: "011",
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",
      filler: "",
      totalContAmt: null,
      totalRefno: null,

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],
      eisdata: [],

      loading: false,

      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {
    
    getHLBExportListHandler() {

       this.loading = true;
      const vm = this
      
      let dateHolder2 = this.data.year + "-" + this.pad(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let date = moment(dateHolder2).format("YYYY-MM-DD");
      
      this.$store.dispatch('get', {
          url:  `/hlbeisexport/all/${this.$store.getters.userId}`
       + "/" +  this.data.eisexprecid
       + "/" + this.data.refnum + "/" + date + "/" + date
      })
      .then(function (res) {
        vm.loading = false;
        vm.eisdata = res.data.rec;

         vm.exportHandler(); 

        // eisdataholder = eisdataholder.filter(e => {
        //   for (let i = 0; i < vm.data.eissubrecpy.length; i++) {
        //     if (e.poid == vm.data.eissubrecpy[i].pyctrlid) {
        //       return e
        //     }
        //   }
        // })
        // eisdataholder = eisdataholder.filter(e => e.stfamount != 0)

        // for (let i = 0; i < eisdataholder.length; i++) {
        //   vm.eisdata.push(eisdataholder[i])
        // }

      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },
    //   const vm = this
      
    //   axios.get(`${this.$store.getters.apiURL}/hlbeisexport/all/${this.$store.getters.userId}`)
    //   .then(function (res) {
    //     let eisdataholder = res.data.rec;
    //     console.log('eisdataholder', eisdataholder)
    //     eisdataholder = eisdataholder.filter(e => {
    //       for (let i = 0; i < vm.data.eissubrecpy.length; i++) {
    //         if (e.poid == vm.data.eissubrecpy[i].pyctrlid) {
    //           return e
    //         }
    //       }
    //     })
    //     eisdataholder = eisdataholder.filter(e => e.stfamount != 0)

    //     for (let i = 0; i < eisdataholder.length; i++) {
    //       vm.eisdata.push(eisdataholder[i])
    //       console.log('eisdata', vm.eisdata)
    //     }
    //   })
    //   //     e.poid == vm.data.eissubrecpy[0].pyctrlid)

    //   //   eisdataholder.forEach(function (o) {
    //   //     var existing = vm.eisdata.filter(function (i) {
    //   //       return i.staffid === o.staffid;
    //   //     })[0];
    //   //     if (!existing || existing == undefined) {
    //   //       vm.eisdata.push(o);

    //   //       console.log('eisdata', vm.eisdata)
    //   //     }
    //   //   });
    //   // })
    //   .catch(function (err) {
    //     vm.errMsg = err;
    //   });
    // },

    async getData() {
      const vm = this
      let icnoholder = ''
      let body = []
      let holdpaycontamt = []
      let neweisdetail = []
      let dataholder = vm.eisdata
      let eisdetail = this.filter(dataholder);

      this.processedCount = dataholder.length;
      this.successCount = eisdetail.length;
      this.errCount = dataholder.length - eisdetail.length;
      
      body.push(
        {
          header1: 'Employee EIS Number', 
          header2: 'Employee IC No', 
          header3: 'Employee Name', 
          header4: 'Contribution Amount'
        },
      );

      eisdetail.forEach(function (o) {
        var existing = neweisdetail.filter(function (i) {
          return i.staffcode === o.staffcode;
        })[0];
        if (!existing || existing == undefined) {
          neweisdetail.push(o);
        } else {
          existing.amount += o.amount;
        }
      });

      neweisdetail.forEach(element => {
        if (element.icno != null) icnoholder = element.icno.split("-").join("")
        
        body.push(
          {
            header1: element.refno, 
            header2: icnoholder, 
            header3: element.psnname, 
            header4: element.amount.toFixed(2),
          }
        );
        holdpaycontamt.push(element.amount);
      });

      if (holdpaycontamt) {
        let totalPayAmtHolder = holdpaycontamt.reduce(function (a, b) {return a + b}, 0);
        this.totalContAmt = totalPayAmtHolder.toFixed(2);
      }

      if (body.length > 0) {
        const wd =  XLSX.utils.json_to_sheet((body), { origin: 'A1', skipHeader: true});
        const wb = XLSX.utils.book_new();
        console.log('wb', wb)
        XLSX.utils.book_append_sheet(wb, wd,  this.titleData);
        XLSX.writeFile(wb, this.titleData + '.xlsx');
      }
    },

    filter(item) {
      let checkdet = this.detailfilter(item)
      let checkactive = this.activefilter(checkdet)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    detailfilter(item) {
        let dateHolder = this.data.year + "-" + this.pad(this.data.month, 2) + "-" 
        + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let dataitem = item.filter(e => e.refnum == this.data.refnum)
        dataitem = dataitem.filter(e => {
            return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
        })
        let noacc = item.filter((e) => !dataitem.includes(e));
        for (let i = 0; i < noacc.length; i++) {
            noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
            this.accErr.push(noacc[i]);
        }
        return dataitem
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => {
          return e.amount != 0
      })
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },

    objectToCSV(header, data) {
        const csvRows = []

        for (const key in header) {
            let headobj = key + ',' + header[key]
            csvRows.push(headobj) 
        }
        csvRows.push([])

        // get header
        const headers = Object.keys(data[0])
        csvRows.push(headers.join(','))

        // loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','))
        }
        return csvRows.join('\n')
    },

    download(data, title) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalContAmt = this.totalContAmt;
        item.accounterr = this.accErr;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;

        if (!this.isErr) {
          this.$router.push({ name: "monthlyeisexportlog", params: { item } });
        }
      }
    },

    backHandler() {
      this.$router.push({ name: "monthlyeisexport" });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

    mounted() {
       // this.getHLBExportListHandler();
    },
};
</script>