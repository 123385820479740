<template>
    <div v-if="!loading">
        <app-form
            v-bind:parentObject="{
                data: this.formData,
                searchnew: this.search,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode,
            }"
            v-on:new="createHandler"
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:cancel="cancelHandler"
            v-on:delete="isConfirm = !isConfirm"
        />
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <div v-if="isConfirm">
            <app-confirm
                v-bind:parentObject="{
                    pageTitleValue: this.pageTitleValue,
                    isConfirm: this.isConfirm,
                }"
                v-on:confirm="deleteHandler($event)"
                v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
    </div>
</template>

<script>

import AssetForm from "../../../components/personnel/asset/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
    components: {
        "app-form": AssetForm,
        "app-error": ErrorDialog,
        "app-confirm": ConfirmDialog,
    },

    data() {
        return {
            id: this.$route.params.id,
            pageTitle: this.$t('recassetrec.editassetrec'),
            pageTitleValue: "",
            formData: [],
            loading: true,
            errMsg: "",
            isErr: false,
            isConfirm: false,
            editMode: true,
             search: this.$route.params.search,
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadAssetHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/asset/" + this.id})
                .then((res) => {
                    vm.formData = res.data.rec[0];

                    if (res.data.rec[0].trackdetails == "N") {
                        vm.formData.trackdetails = false;
                    } 
                    else {
                        vm.formData.trackdetails = true;
                    }
                    if (res.data.rec[0].isdisable == "N") {
                        vm.formData.isdisable = false; 
                    } 
                    else {
                        vm.formData.isdisable = true;
                    }

                    vm.formData.userid = this.$store.getters.userId;
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        loadAssetDetailHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/assetd/" + this.id})
                .then((res) => {
                    vm.formData.assetdetail = res.data.rec;

                    for (let i = 0; i < vm.formData.assetdetail.length; i++) {
                        Object.assign(vm.formData.assetdetail[i], {
                            action: 2,
                        });
                    }

                    vm.loading = false;
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        saveHandler() {
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
                return false;
            }

            this.$store.dispatch('put', {url: "/asset/" + vm.id, payload: this.formData})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } 
                    else {
                        this.$router.push({ name: "assetlist", params: { search: this.search}  });
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        cancelHandler() {
            this.$router.push({ name: "assetlist" });
        },

        deleteHandler() {
            const vm = this;

            if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
                return false;
            }

            this.$store.dispatch('delete', {url: "/asset/" + vm.id + "/" + this.formData.userid})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } 
                    else {
                        this.$router.push({ name: "assetlist" });
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },

        createHandler() {
            this.$router.push({ name: "assetnew" });
        },
    },

    mounted() {
        this.loadAssetHandler();
        this.loadAssetDetailHandler();
    },
};
</script>