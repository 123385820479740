<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{$t('personandstaffdetailssummary')}}
          </h2>
        </v-row>
        
        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}
            </v-btn>
          </v-row>
        </v-col>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-toolbar-title>
              <div>
                <span class="green--text">
                  {{ this.staffItems[0].psndetails }}
                </span>
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-avatar class="profile mr-5" size="40" tile>
              <img
                :src="`${publicPath}images/export_pdf.png`"
                @click="printPDF"
              />
            </v-avatar>
          </v-row>

          <br><br>

          <v-row>
            <div>
              <table class="table">
                <tr>
                  <td class="blue--text bold basic1 t-cells">{{$t('reporpersonandstaffdetailssummary.basicinfo')}}</td>
                  <td class="basic2"></td>
                  <td class="basic3"></td>
                  <td class="basic4"></td>
                  <td rowspan="6" class="basic5">
                    <v-avatar class="profile mr-5" size="160" tile>
                      <img
                        :src="this.personItems[0].pic"
                      />
                    </v-avatar>
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.name')}}</td>
                  <td>
                    {{ this.personItems[0].psnname }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('recpersonalinfo.idno')}}</td>
                  <td>
                    {{ this.personItems[0].idno }}
                  </td>
                  <td class="bold right">{{$t('recpersonalinfo.salutation')}}</td>
                  <td>
                    {{ this.personItems[0].salutname }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.dob')}}</td>
                  <td>
                    {{ this.personItems[0].dob }}
                    {{ this.ageYear }}Y
                    {{ this.ageMonth }}M
                  </td>
                  <td class="bold right">{{$t('recpersonalinfo.maritalstatus')}}</td>
                  <td>
                    {{ this.personItems[0].marstatname }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.gender')}}</td>
                  <td>
                    {{ this.personItems[0].gendername }}
                  </td>
                  <td class="bold right">{{$t('recpersonalinfo.marriagedate')}}</td>
                  <td>
                    {{ this.personItems[0].mrgdate }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('recpersonalinfo.nationality')}}</td>
                  <td>
                    {{ this.personItems[0].ntlyname }}
                  </td>
                  <td class="bold right">{{$t('recpersonalinfo.race')}} & {{$t('recpersonalinfo.religion')}}</td>
                  <td>
                    {{ this.personItems[0].racename }}
                     &amp; 
                    {{ this.personItems[0].rlgnname }}
                  </td>
                </tr>
              </table>
            </div>
          </v-row>

          <br><br>
          
          <v-row>
            <div>
              <table class="table">
                <tr>
                  <td class="blue--text bold basic1 t-cells">{{$t('reporpersonandstaffdetailssummary.contactinfo')}}</td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.email')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].email }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.hp_no')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].hpno }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.correspondanceaddress')}}</td>
                  <td v-if="showConDet">
                    <span v-if="showAddr1A">{{ this.contactItems[0].correspondanceaddr1 }}, </span>
                    <span v-if="showAddr1B">{{ this.contactItems[0].correspondanceaddr2 }}, </span>
                    <span v-if="showAddr1C">{{ this.contactItems[0].correspondanceaddr3 }}, </span>
                    {{ this.contactItems[0].poscode }}
                    {{ this.contactItems[0].cityname }}
                    {{ this.contactItems[0].cntryname }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.permanentaddress')}}</td>
                  <td v-if="showConDet">
                    <span v-if="showAddr2A">{{ this.contactItems[0].permanentaddr1 }}, </span>
                    <span v-if="showAddr2B">{{ this.contactItems[0].permanentaddr2 }}, </span>
                    <span v-if="showAddr2C">{{ this.contactItems[0].permanentaddr3 }}, </span>
                    {{ this.contactItems[0].permanentposcode }}
                    {{ this.contactItems[0].permanentcityname }}
                    {{ this.contactItems[0].permanentcntryname }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.emergencycontact_1')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].emergencycontact1 }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.emergencycontact_2')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].emergencycontact2 }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.emergencycontactno_1')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].contactno1 }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reccontactdetails.emergencycontactno_2')}}</td>
                  <td v-if="showConDet">
                    {{ this.contactItems[0].contactno1 }}
                  </td>
                </tr>
              </table>
            </div>
          </v-row>

          <br><br>

          <v-row>
            <div>
              <table class="table">
                <tr>
                  <td class="blue--text bold basic1 t-cells">{{$t('reporpersonandstaffdetailssummary.staffinfo')}}</td>
                  <td class="basic2"></td>
                  <td class="basic3"></td>
                  <td class="basic4"></td>
                  <td class="basic5"></td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.company')}}</td>
                  <td v-if="showStaff">
                    {{ this.staffItems[0].compnyname }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.staffcode')}}</td>
                  <td v-if="showStaff">
                    {{ this.staffItems[0].staffcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('recstaffdetails.hiredate')}}</td>
                  <td v-if="showStaff">
                    {{ this.staffItems[0].hiredate }}
                  </td>
                  <td class="bold right">{{$t('reporalertlisting.servicedate')}}</td>
                  <td v-if="showStaff">
                    {{ this.staffItems[0].servicedate }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('reporalertlisting.dueforconfirmationdate')}}</td>
                  <td v-if="showStaff">
                    {{ this.staffItems[0].dueconfirmdate }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.branch')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].brchname }} -
                    {{ this.staffmovItems[0].brchcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.division')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].divnname }} -
                    {{ this.staffmovItems[0].divncode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.department')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].deptname }} -
                    {{ this.staffmovItems[0].deptcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.section')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].sectioname }} -
                    {{ this.staffmovItems[0].sectiocode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.unit')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].unitname }} -
                    {{ this.staffmovItems[0].unitcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.position')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].posdesc }} -
                    {{ this.staffmovItems[0].poscode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.grade')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].grddesc }} -
                    {{ this.staffmovItems[0].grdcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.employmenttype')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].emptypedesc }} -
                    {{ this.staffmovItems[0].emptype }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('columns.job')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].jobtitle }} -
                    {{ this.staffmovItems[0].jobcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('recstaffdetails.basicsalary')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].crcycode }}
                    {{ this.staffmovItems[0].newbasicsal }}
                  </td>
                  <td class="bold right">{{$t('salfreq')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].basicfreqcode }}
                  </td>
                </tr>
                <tr>
                  <td class="bold t-cells">{{$t('recstaffdetails.contractperiod')}}</td>
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].contrprd }}
                  </td>
                  <td class="bold right">{{$t('reporalertlisting.contractexpirydate')}}</td> 
                  <td v-if="showStaffMov">
                    {{ this.staffmovItems[0].contrenddate }}
                  </td>
                </tr>
              </table>
            </div>
          </v-row>

          <br><br>

          <v-row>
            <div>
              <table class="table">
                <tr>
                  <td class="blue--text bold t-cells" colspan="2">{{$t('reporpersonandstaffdetailssummary.statutoryandbankacc')}}</td>
                </tr>
                <tr v-for="item in personstatItems" :key="item">
                  <td class="bold basic1 t-cells">
                    {{ item.vendorcatdesc }}
                  </td>
                  <td class="basic1">
                    {{ item.refno }}
                  </td>
                  <td class="basic1">
                    {{ item.refcode }} - {{ item.refnum }}
                  </td>
                  <td></td>
                </tr>
                <tr v-if="showStaffBank">
                  <td class="bold t-cells">{{$t('setupcompanybankaccount.bank')}}</td>
                  <td>
                    {{ this.staffbankaccItems[0].bankcode }} - {{ this.staffbankaccItems[0].staffaccountno }}
                  </td>
                  <td>
                    {{ this.staffbankaccItems[0].bankaccountcode }} - {{ this.staffbankaccItems[0].bankaccountno }}
                  </td>
                  <td></td>
                </tr>
                <tr v-if="personstatItems == false && showStaffBank == false">
                  <td>
                    {{$t('recstaffdetails.nodatafound')}} 
                  </td>
                </tr>
              </table>
            </div>
          </v-row>

          <br><br>
          
          <v-row>
            <div>
              <table class="table">
                <tr>
                  <td class="blue--text bold basic1 t-cells">{{$t('reporpersonandstaffdetailssummary.grpassignmentinfo')}}</td>
                  <td class="basic2"></td>
                </tr>
                <tr v-for="item in groupassignmentdItems" :key="item">
                  <td class="bold basic1 t-cells">
                    {{ item.gtypedesc }}
                  </td>
                  <td class="basic1">
                    {{ item.valvaluecodedesc }} - {{ item.valvaluecode }}
                  </td>
                  <td></td>
                </tr>
                <tr v-if="showGroupAssign == false">
                  <td>
                    {{$t('recstaffdetails.nodatafound')}} 
                  </td>
                </tr>
              </table>
            </div>
          </v-row>

        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      data: {
        formData: {
          asatdate: this.$route.params.formData.asatdate,
          id: this.$route.params.formData.id,
        }
      },

      ageYear: null,
      ageMonth: null,

      staffItems: [],
      personItems: [],
      contactItems: [],
      staffmovItems: [],
      personstatItems: [],
      staffbankaccItems: [],
      groupassignmentdItems: [],

      goBtn: false,

      showStaff: true,
      showPerson: true,
      showConDet: true,
      showPersonStat: true,
      showStaffBank: true,
      showGroupAssign: true,
      showStaffMov: true,

      showAddr1A: true,
      showAddr1B: true,
      showAddr1C: true,
      showAddr2A: true,
      showAddr2B: true,
      showAddr2C: true,
    }
  },
  
  methods: {
    backButton() {
      this.$router.push({ name: "parameterpersonandstaff" });
    },

    loadStaff() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/staff/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.staffItems = res.data.rec;

            for(let i = 0; i < vm.staffItems.length; i++) {
              vm.staffItems[i].hiredate = moment(vm.staffItems[i].hiredate).format("DD-MM-YYYY");
              vm.staffItems[i].servicedate = moment(vm.staffItems[i].servicedate).format("DD-MM-YYYY");
              vm.staffItems[i].dueconfirmdate = moment(vm.staffItems[i].dueconfirmdate).format("DD-MM-YYYY");
            }

            if(vm.staffItems.length == 0) {
              vm.showStaff = false;
            }
          })
    },

    loadPerson() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/person/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.personItems = res.data.rec;

            for(let i = 0; i < vm.personItems.length; i++) {
              vm.personItems[i].dob = moment(vm.personItems[i].dob).format("DD-MM-YYYY");
              var dobYear = moment(vm.personItems[i].dob, "DD-MM-YYYY").format("YYYY");
              var dobMonth = moment(vm.personItems[i].dob, "DD-MM-YYYY").format("MM");
              var currentYear = moment(new Date()).format("YYYY");
              var currentMonth = moment(new Date()).format("MM");
              vm.ageYear = currentYear - dobYear;
              vm.ageMonth = currentMonth - dobMonth;

              if(vm.ageMonth < 0){
                vm.ageYear -= 1;
                vm.ageMonth += 12;
              }
            }

            if(vm.personItems.length == 0) {
              vm.showPerson = false;
            }
          })
    },

    loadContactDetails() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/contactdetails/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.contactItems = res.data.rec;

            if(vm.contactItems.length < 1) {
              vm.showConDet = false;
            }
            else{
              if(vm.contactItems[0].correspondanceaddr1 == null) {
                vm.showAddr1A = false;
              }
              if(vm.contactItems[0].correspondanceaddr2 == null) {
                vm.showAddr1B = false;
              }
              if(vm.contactItems[0].correspondanceaddr3 == null) {
                vm.showAddr1C = false;
              }
              if(vm.contactItems[0].permanentaddr1 == null) {
                vm.showAddr2A = false;
              }
              if(vm.contactItems[0].permanentaddr2 == null) {
                vm.showAddr2B = false;
              }
              if(vm.contactItems[0].permanentaddr3 == null) {
                vm.showAddr2C = false;
              }
            }
          })
    },

    loadStaffMov() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/staffmov/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.staffmovItems = res.data.rec;

            if(vm.staffmovItems.length < 1) {
              vm.showStaffMov = false;
            }

            for(let i = 0; i < vm.staffmovItems.length; i++) {
              vm.staffmovItems[i].newbasicsal = vm.staffmovItems[i].newbasicsal.toFixed(2);
              vm.staffmovItems[i].newbasicsal = vm.staffmovItems[i].newbasicsal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          })
    },

    loadPersonStatInfo() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/personstatutoryinfo/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.personstatItems = res.data.rec;

            if(vm.personstatItems.length < 1) {
              vm.showPersonStat = false;
            }
          })
    },

    loadStaffBankAccount() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/staffbankaccount/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.staffbankaccItems = res.data.rec;

            if(vm.staffbankaccItems.length < 1) {
              vm.showStaffBank = false;
            }
          })
    },

    loadGroupAssignmentD() {
      this.goBtn = true;
      const vm = this;

      this.$store.dispatch('post', {url: `/personandstaff/groupassignmentd/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.groupassignmentdItems = res.data.rec;

            if(vm.groupassignmentdItems.length < 1) {
              vm.showGroupAssign = false;
            }

            for(let i = 0; i < vm.groupassignmentdItems.length; i++) {
              const word = vm.groupassignmentdItems[i].gtypedesc;
              vm.groupassignmentdItems[i].gtypedesc = word.charAt(0) + word.substring(1).toLowerCase();
            }
          })
    },

    printPDF() {
      const doc = new jsPDF({
        orientation: "portrait",
      });

      doc.setFontSize(12); //set font size to 10
      doc.setTextColor(76, 175, 80); //set font color to green

      doc.text(this.staffItems[0].psndetails.toString(), 10, 10);

      doc.setFontSize(8); //set font size to 6
      doc.setFontType("bold"); //set font type to bold
      doc.setTextColor(33, 150, 243); //set font color to blue

      doc.text(this.$t('reporpersonandstaffdetailssummary.basicinfo'), 10, 20);

      doc.setTextColor(0, 0, 0); //set font type to black

      doc.text(this.$t('columns.name'), 10, 26);
      doc.text(this.$t('recfamdetails.icno'), 10, 32);
      doc.text(this.$t('columns.dob'), 10, 38);
      doc.text(this.$t('columns.gender'), 10, 44);
      doc.text(this.$t('recpersonalinfo.nationality'), 10, 50);

      doc.setFontType("normal"); //set font type to normal

      doc.text(this.checkEmpty(this.personItems[0].psnname), 50, 26);
      doc.text(this.checkEmpty(this.personItems[0].idno), 50, 32);
      doc.text(this.checkEmpty(this.personItems[0].dob), 50, 38);
      doc.text(this.checkEmpty(this.personItems[0].gendername), 50, 44);
      doc.text(this.checkEmpty(this.personItems[0].ntlyname), 50, 50);

      doc.setFontType("bold"); //set font type to bold

      doc.text(this.$t('recpersonalinfo.salutation'), 120, 32, "right");
      doc.text(this.$t('recpersonalinfo.maritalstatus'), 120, 38, "right");
      doc.text(this.$t('recpersonalinfo.marriagedate'), 120, 44, "right");
      doc.text(this.$t('recpersonalinfo.raceandreligion'), 120, 50, "right");

      doc.setFontType("normal"); //set font type to normal

      doc.text(this.checkEmpty(this.personItems[0].salutname), 125, 32);
      doc.text(this.checkEmpty(this.personItems[0].marstatname), 125, 38);
      doc.text(this.checkEmpty(this.personItems[0].mrgdate), 125, 44);
      doc.text(this.checkEmpty(this.personItems[0].racename) + " & " + this.checkEmpty(this.personItems[0].rlgnname), 125, 50);

      if(this.showPerson == false) {
        doc.addImage(this.personItems[0].pic, 167, 20, 32, 32);
      }

      doc.setFontType("bold"); //set font type to bold
      doc.setTextColor(33, 150, 243); //set font color to blue

      doc.text("Contact Details", 10, 60);

      doc.setTextColor(0, 0, 0); //set font type to black

      doc.text(this.$t('columns.email'), 10, 66);
      doc.text(this.$t('reccontactdetails.hp_no'), 10, 72);
      doc.text(this.$t('reccontactdetails.correspondanceaddress'), 10, 78);
      doc.text(this.$t('reccontactdetails.permanentaddress'), 10, 84);
      doc.text(this.$t('reccontactdetails.emergencycontact_1'), 10, 90);
      doc.text(this.$t('reccontactdetails.emergencycontact_2'), 10, 96);
      doc.text(this.$t('reccontactdetails.emergencycontactno_1'), 10, 102);
      doc.text(this.$t('reccontactdetails.emergencycontactno_2'), 10, 108);

      doc.setFontType("normal"); //set font type to normal

      if(this.showConDet == true){
        doc.text(this.checkEmpty(this.contactItems[0].email), 50, 66);
        doc.text(this.checkEmpty(this.contactItems[0].hpno), 50, 72);
        doc.text(this.checkEmpty(this.contactItems[0].correspondanceaddr1 + ", ") + this.checkEmpty(this.contactItems[0].correspondanceaddr2 + ", ") + this.checkEmpty(this.contactItems[0].correspondanceaddr3 + ", ") + this.checkEmpty(this.contactItems[0].poscode + " ") + this.checkEmpty(this.contactItems[0].cityname + " ") + this.checkEmpty(this.contactItems[0].cntryname + " "), 50, 78);
        doc.text(this.checkEmpty(this.contactItems[0].permanentaddr1 + ", ") + this.checkEmpty(this.contactItems[0].permanentaddr2 + ", ") + this.checkEmpty(this.contactItems[0].permanentaddr3 + ", ") + this.checkEmpty(this.contactItems[0].permanentposcode + " ") + this.checkEmpty(this.contactItems[0].permanentcityname + " ") + this.checkEmpty(this.contactItems[0].permanentcntryname + " "), 50, 84);
        doc.text(this.checkEmpty(this.contactItems[0].emergencycontact1), 50, 90);
        doc.text(this.checkEmpty(this.contactItems[0].emergencycontact2), 50, 96);
        doc.text(this.checkEmpty(this.contactItems[0].contactno1), 50, 102);
        doc.text(this.checkEmpty(this.contactItems[0].contactno2), 50, 108);
      }

      doc.setFontType("bold"); //set font type to bold
      doc.setTextColor(33, 150, 243); //set font color to blue

      doc.text(this.$t('reporpersonandstaffdetailssummary.staffinfo'), 10, 118);

      doc.setTextColor(0, 0, 0); //set font type to black

      doc.text(this.$t('columns.company'), 10, 124);
      doc.text(this.$t('columns.staffcode'), 10, 130);
      doc.text(this.$t('recstaffdetails.hiredate'), 10, 136);
      doc.text(this.$t('reporalertlisting.dueforconfirmationdate'), 10, 142);
      doc.text(this.$t('columns.branch'), 10, 148);
      doc.text(this.$t('columns.division'), 10, 154);
      doc.text(this.$t('columns.department'), 10, 160);
      doc.text(this.$t('columns.section'), 10, 166);
      doc.text(this.$t('columns.unit'), 10, 172);
      doc.text(this.$t('columns.position'), 10, 178);
      doc.text(this.$t('columns.grade'), 10, 184);
      doc.text(this.$t('columns.employmenttype'), 10, 190);
      doc.text(this.$t('columns.job'), 10, 196);
      doc.text(this.$t('recstaffdetails.basicsalary'), 10, 202);
      doc.text(this.$t('recstaffdetails.contractperiod'), 10, 208);

      doc.setFontType("normal"); //set font type to normal

      if(this.showStaff == true) {
        doc.text(this.checkEmpty(this.staffItems[0].compnyname), 50, 124);
        doc.text(this.checkEmpty(this.staffItems[0].staffcode), 50, 130);
        doc.text(this.checkEmpty(this.staffItems[0].hiredate), 50, 136);
        doc.text(this.checkEmpty(this.staffItems[0].dueconfirmdate), 50, 142);
      }

      if(this.showStaffMov == true) {
        doc.text(this.checkEmptyDash(this.staffmovItems[0].brchname, this.staffmovItems[0].brchcode), 50, 148);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].divnname, this.staffmovItems[0].divncode), 50, 154);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].deptname, this.staffmovItems[0].deptcode), 50, 160);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].sectioname, this.staffmovItems[0].sectiocode), 50, 166);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].unitname, this.staffmovItems[0].unitcode), 50, 172);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].posdesc, this.staffmovItems[0].poscode), 50, 178);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].grddesc, this.staffmovItems[0].grdcode), 50, 184);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].emptypedesc, this.staffmovItems[0].emptype), 50, 190);
        doc.text(this.checkEmptyDash(this.staffmovItems[0].jobtitle, this.staffmovItems[0].jobcode), 50, 196);
        doc.text(this.checkEmptyMoney(this.staffmovItems[0].crcycode, this.staffmovItems[0].newbasicsal), 50, 202);
        doc.text(this.checkEmpty(this.staffmovItems[0].contrprd), 50, 208);
      }

      doc.setFontType("bold"); //set font type to bold

      doc.text(this.$t('reporalertlisting.servicedate'), 120, 136, "right");
      doc.text(this.$t('salfreq'), 120, 202, "right");
      doc.text(this.$t('reporalertlisting.contractexpirydate'), 120, 208, "right");

      doc.setFontType("normal"); //set font type to normal
      
      if(this.showStaff == true) {
        doc.text(this.checkEmpty(this.staffItems[0].servicedate), 125, 136);
      }

      if(this.showStaffMov == true) {
        doc.text(this.checkEmpty(this.staffmovItems[0].basicfreqcode), 125, 202);
        doc.text(this.checkEmpty(this.staffmovItems[0].contrenddate), 125, 208);
      }

      doc.setFontType("bold"); //set font type to bold
      doc.setTextColor(33, 150, 243); //set font color to blue

      doc.text(this.$t('reporpersonandstaffdetailssummary.statutoryandbankacc'), 10, 218);

      doc.setTextColor(0, 0, 0); //set font type to black

      var number = 218;

      for(let i = 0; i < this.personstatItems.length; i++) {
        number += 6;
        doc.setFontType("bold"); //set font type to bold
        doc.text(this.checkEmpty(this.personstatItems[i].vendorcatdesc), 10, number);
        doc.setFontType("normal"); //set font type to normal
        doc.text(this.checkEmpty(this.personstatItems[i].refno), 50, number);
        doc.text(this.checkEmptyDash(this.personstatItems[i].refcode, this.personstatItems[i].refnum), 100, number);
      }

      for(let i = 0; i < this.staffbankaccItems.length; i++) {
        number += 6;
        doc.setFontType("bold"); //set font type to bold
        doc.text(this.$t('bank'), 10, number);
        doc.setFontType("normal"); //set font type to normal
        doc.text(this.checkEmptyDash(this.staffbankaccItems[i].bankcode, this.staffbankaccItems[i].staffaccountno), 50, number);
        doc.text(this.checkEmptyDash(this.staffbankaccItems[i].bankaccountcode, this.staffbankaccItems[i].bankaccountno), 100, number);
      }

      if(this.showPersonStat == false && this.showStaffBank == false) {
        number += 6;
        doc.setFontType("normal"); //set font type to normal
        doc.text(this.$t('recstaffdetails.nodatafound'), 10, number);
      }

      doc.setFontType("bold"); //set font type to bold
      doc.setTextColor(33, 150, 243); //set font color to blue

      number += 10;

      doc.text(this.$t('reporpersonandstaffdetailssummary.grpassignmentinfo'), 10, number);

      doc.setTextColor(0, 0, 0); //set font type to black

      for(let i = 0; i < this.groupassignmentdItems.length; i++) {
        number += 6;
        doc.setFontType("bold"); //set font type to bold
        doc.text(this.checkEmpty(this.groupassignmentdItems[i].gtypedesc), 10, number);
        doc.setFontType("normal"); //set font type to normal
        doc.text(this.checkEmptyDash(this.groupassignmentdItems[i].valvaluecodedesc, this.groupassignmentdItems[i].valvaluecode), 50, number);
      }

      if(this.showGroupAssign == false) {
        number += 6;
        doc.setFontType("normal"); //set font type to normal
        doc.text(this.$t('recstaffdetails.nodatafound'), 10, number);
      }

      doc.save(this.staffItems[0].psndetails.toString() + this.formData.asatdate.toString() + '" ' + this.$t('personandstaffdetailssummary') + ' "' ); 
    },

    checkEmpty(variable) {
      if(variable == null) {
        return "";
      }
      else if(variable == null + ", ") {
        return "";
      }
      else if(variable == null + " ") {
        return "";
      }
      else {
        return variable;
      }
    },

    checkEmptyDash(variable1, variable2) {
      if(variable1 == null && variable2 == null) {
        return "";
      }
      else if(variable1 != null && variable2 == null) {
        return variable1;
      }
      else if(variable1 == null && variable2 != null) {
        return variable2;
      }
      else {
        return variable1 + " - " + variable2;
      }
    },

    checkEmptyMoney(variable1, variable2) {
      if(variable1 == null && variable2 == null) {
        return "";
      }
      else if(variable1 != null && variable2 == null) {
        return variable1;
      }
      else if(variable1 == null && variable2 != null) {
        return variable2;
      }
      else {
        return variable1 + " " + variable2;
      }
    }
  },

  mounted() {},

  created() {
    const vm = this;
    vm.formData = this.$route.params.formData;

    this.loadStaff();
    this.loadPerson();
    this.loadContactDetails();
    this.loadStaffMov();
    this.loadPersonStatInfo();
    this.loadStaffBankAccount();
    this.loadGroupAssignmentD();
  },
};
</script>

<style>
.bold{
  font-weight: 550;
  font-size: 90%;
}

.right{
  text-align: right;
  padding-right: 10px;
}

.table{
  background-color: white;
  width: 1130px;
}

.t-cells{
  height: 25px;
}

.basic1{
  width: 145px;
}

.basic2{
  width: 260px;
}

.basic3{
  width: 120px;
}

.basic4{
  width: 292px;
}

.basic5{
  width: 312px;
  text-align: right;
}
</style>