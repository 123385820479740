<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupprocesslistmaster.processlist')"
                                type="text"
                                :items="proclistmasList"
                                :rules="[rules.required]"
                                v-model="formData.proclistmasid"
                                @change="
                                    changeProclistmasHandler(
                                        formData.proclistmasid
                                    )
                                "
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                         <v-col cols="6" sm="6" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupprocesslistmaster.processlist')"
                                type="text"
                                :items="proclistmasList"
                                :rules="[rules.required]"
                                v-model="formData.proclistmasid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-dialog
                                v-model="proclistmasDialog"
                                max-width="600px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform
                                        v-bind:parentData="{
                                            tabItems: proclistmasList,
                                            formTitle: `${this.$t('setupprocesslistmaster.processlistmaster')}`,
                                            formId: 'proclistmas'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveProcListMas="
                                            saveProcListMasHandler
                                        "
                                        v-on:deleteProcListMas="
                                            deleteProcListMasHandler($event)
                                        "
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="proclistmasDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.proclistmasdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.payprocessdetail
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from "../../generic/GridForm";
import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-genericform": GenericForm,
        "app-table": CRUDTable
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                }
            },

            formData: {
                proclistmasid: this.parentObject.data.proclistmasid,
                proclistmascode: this.parentObject.data.proclistmascode,
                proclistmasdesc: this.parentObject.data.proclistmasdesc,
                proclistmasdtid: this.parentObject.data.proclistmasdtid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                lockstaff: this.parentObject.data.lockstaff,
                payprocessdetail: this.parentObject.data.payprocessdetail,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayitem.payitem'),
                    value: "payitemcode",
                    width: "200px",
                    sortable: true
                },
                {
                    text: this.$t('setupclaim.payitemdescription'),
                    value: "payitemdesc",
                    width: "400px",
                    sortable: true
                },
                {
                    text: this.$t('setupprocesslistmaster.precedence'),
                    value: "precd",
                    width: "100px",
                    sortable: true
                }
            ],

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            errMsg: "",
            isErr: false,
            isEdit: this.parentObject.isEdit,

            proclistmasList: [],
            proclistmasDialog: false,
             search: this.parentObject.searchnew
        };
    },

    methods: {
        addItemHandler($event) {
            this.formData.payprocessdetail = $event;

            this.updateValue();
        },

        loadProcListMas() {
            const vm = this;

            this.$store.dispatch('get', {url: "/proclistmas/codes"})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.proclistmasList.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].proclistmascode,
                            desc: res.data.rec[i].proclistmasdesc,
                            text: res.data.rec[i].proclistmasdesc,
                            value: res.data.rec[i].id,
                            dtid: res.data.rec[i].proclistmasdtid,
                            stamp: res.data.rec[i].stamp
                        });
                    }

                    if (vm.proclistmasid) {
                        this.changeProclistmasHandler(
                            vm.formData.proclistmasid
                        );
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeProclistmasHandler(id) {
            let item = this.proclistmasList.find(
                item => item.value === parseInt(id)
            );

            this.formData.proclistmasdesc = item.text;
            this.formData.proclistmascode = item.code;
            this.formData.proclistmasdtid = item.dtid;
        },

        saveProcListMasHandler() {
            const vm = this;

            let payload = {
                proclistmascode: this.itemData.itemcode,
                proclistmasdesc: this.itemData.itemdesc,
                userid: this.formData.userid
            };

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: `/proclistmascode/${vm.itemData.itemid}`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.proclistmasList = [];

                            this.loadProcListMas();
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            } else {
                this.$store.dispatch('post', {url: `/proclistmascode/create`,
                        payload: payload})
                    .then(res => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.proclistmasList = [];

                            this.loadProcListMas();
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        deleteProcListMasHandler($event) {
            const vm = this;

            if (confirm(`Remove ${$event.desc} ?`)) {
                this.$store.dispatch('delete', {url: `/proclistmascode/${$event.id}/${this.formData.userid}`})
                    .then(res => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.proclistmasList = [];

                            this.loadProcListMas();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        updateValue() {
            this.$emit("input", {
                proclistmasid: this.formData.proclistmasid,
                proclistmascode: this.formData.proclistmascode,
                proclistmasdesc: this.formData.proclistmasdesc,
                proclistmasdtid: this.formData.proclistmasdtid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                lockstaff: this.formData.lockstaff,
                payprocessdetail: this.formData.payprocessdetail,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "payprocesslist", params: { search: this.search} });
        }
    },

    created() {
        this.loadProcListMas();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
};
</script>