<template>
    <div> 
        <v-container>
            <v-form>
                 <v-row>
                    <h2 class="pb-5"> {{this.parentObject.pageTitle}}<span class='page_title_value'>{{this.parentObject.pageTtileValue}}</span></h2>
                </v-row>

                <v-container 
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="12" sm="6" md="6" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebategroup.rebategroup')"
                            type="text"
                            dense
                            v-model="formData.rebategroupid"
                            :items="rebateGroupList"
                            @change="changeRebateGroupHandler(formData.rebategroupid)"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebategroup.rebategroup')"
                            type="text"
                            dense
                            v-model="formData.rebategroupid"
                            :items="rebateGroupList"
                            readonly
                            background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5">
                        <v-dialog v-model="rebategroupDialog" max-width="600px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: rebateGroupItems,
                                        formTitle: `${this.$t('setuptaxablerebategroup.rebategroupsetup')}`,
                                        formId: 'rebategroupsetup'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveRebateGroup="saveRebateGroupHandler"
                                    v-on:deleteRebateGroup="deleteRebateGroupHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="rebategroupDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.description')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="formData.rebategroupdesc"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.startdate')"
                            type="date"
                            ref="startdate"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.enddate')"
                            type="date"
                            ref="enddate"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setuptaxablerebategroup.rebatelimitamount')"
                            type="number"
                            ref="rebatelimitamount"
                            v-model="formData.rebatelimitamount"
                            @input="updateValue"
                            class="numberfield"
                            dense
                            :rules="[rules.required]"
                        > 
                        </v-text-field>
                       
                    </v-col>

                    <v-col cols="4" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.currency')"
                                type="text"
                                ref="currencyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                v-model="formData.currencyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.currencyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="1" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.currencycode"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: currencyItems,
                                            formTitle: `${this.$t('columns.currency')}`,
                                            formId: 'currency'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCurrency="saveCurrencyHandler"
                                        v-on:deleteCurrency="deleteCurrencyHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="currencyDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                </v-row>

                 <v-row>
                    <v-col cols="4" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setuptaxablerebategroup.frequency')"
                            type="number"
                            ref="frequency"
                            :rules="[rules.required]"
                            v-model="formData.frequency"
                            @input="updateValue"
                            dense
                            class="numberfield"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                        <v-select
                            outlined
                            :label="this.$t('setuptaxablerebategroup.control')"
                            type="text"
                            v-model="formData.controlid"
                            ref="controlid"
                            dense
                            :items="controlitemsList"
                            @input="updateValue"> 
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="8" class="pb-n4 mt-n8">
                      <v-textarea
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            ref="remark"
                            maxlength="200"
                            v-model="formData.remark"
                            @input="updateValue"
                            dense
                            counter> 
                        </v-textarea>
                    </v-col>
                </v-row>
                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            formData: {
                rebategroupid: this.parentObject.data.rebategroupid,
                rebategroupcode: this.parentObject.data.rebategroupcode,
                rebategroupdesc: this.parentObject.data.rebategroupdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                rebatelimitamount: this.parentObject.data.rebatelimitamount,
                currencyid: this.parentObject.data.currencyid,
                currencycode: this.parentObject.data.currencycode,
                frequency: this.parentObject.data.frequency,
                controlid: this.parentObject.data.controlid,
                remark: this.parentObject.data.remark,
                stamp: this.parentObject.data.stamp,
                lockstaff: this.parentObject.data.lockstaff,
                userid: this.parentObject.data.userid,   
            },

            rules:  {
               required:value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length <= 200 || 'Max 200 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },  
             
            },

            rebategroupDialog: false,
            currencyDialog: false,
            itemData: null,

            errMsg: "",
            isErr: false,

            currencies: [],
            currencyList: [],
            currencyItems: [], 
            controls: [],
            controlitemsList: [], 
            rebateGroup: [],
            rebateGroupList: [],
            rebateGroupItems: [],
             search: this.parentObject.searchnew

        } //end of return 
            
    },  //end of data 

   

    methods: {
        updateValue() {
            this.$emit('input', {
                rebategroupid: this.formData.rebategroupid,
                rebategroupcode: this.formData.rebategroupcode,
                rebategroupdesc: this.formData.rebategroupdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                rebatelimitamount: this.formData.rebatelimitamount,
                currencyid: this.formData.currencyid,
                currencycode: this.formData.currencycode,
                frequency: this.formData.frequency,
                controlid: this.formData.controlid,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,  
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        //load handlers 

        loadRebateGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/taxablerebategroup/codes'})
                .then(res => {
                    vm.rebateGroup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.rebateGroupList.push(
                            {
                                text: res.data.rec[i].rebategroupcode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.rebateGroupItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].rebategroupcode,
                                desc: res.data.rec[i].rebategroupdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.currencyid) {
                        vm.changeCurrencyHandler(vm.formData.currencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        
        loadControlListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/control/all'})
                .then(res => {
                    vm.controls = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.controlitemsList.push(
                            {
                                text: res.data.rec[i].controldesc,
                                value: res.data.rec[i].id
                                
                            }
                        )

                    }         

                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/currency/' + this.itemData.itemid, payload: {
                    crcycode: this.itemData.itemcode,
                    crcydesc: this.itemData.itemdesc,    
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/currency/create', payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCurrencyHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/currency/' + $event.id})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        saveRebateGroupHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/taxablerebategroup/code/' + this.itemData.itemid, payload: {
                rebategroupcode: this.itemData.itemcode,
                rebategroupdesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.rebateGroup = [];
                        vm.rebateGroupItems = [];
                        vm.rebateGroupList = [];

                        this.loadRebateGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/taxablerebategroup/code/create', payload: {
                rebategroupcode: this.itemData.itemcode,
                rebategroupdesc: this.itemData.itemdesc,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.rebateGroup = [];
                        vm.rebateGroupItems = [];
                        vm.rebateGroupList = [];

                        this.loadRebateGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteRebateGroupHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/taxablerebategroup/code/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.rebateGroup = [];
                        vm.rebateGroupItems = [];
                        vm.rebateGroupList = [];

                        this.loadRebateGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        // change handlers 
        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);
            this.formData.currencycode = item.crcycode;
        },

        changeRebateGroupHandler(id) {
            let item = this.rebateGroup.find(item => item.id === parseInt(id));
            
            this.formData.rebategroupcode = item.rebategroupcode;
            this.formData.rebategroupdesc = item.rebategroupdesc;
        },

        
        newHandler() {
            this.$emit('new');
        },
        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'taxablerebategroupsetuplist', params: { search: this.search}});
        },

    }, // end of methods 

    mounted() {
        this.loadCurrencyListHandler();
        this.loadControlListHandler();
        this.loadRebateGroupListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
} 
</script>

<style>

.numberfield input[type='number'] {
    -moz-appearance:textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}


</style>

