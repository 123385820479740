<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        editMode: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import IRCaseMaintenanceForm from "../../../components/industrialrelations/ircasemaintenance/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": IRCaseMaintenanceForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('ircasemaintenance.editircasemaintenance'),
      pageTitleValue: "",
      //formData: [],
       formData: {
         recorddate: null,
         incidentdate: null,
         caseid: null,
         shortdesc: null,
         longdesc: null,
         incidentcatid: null,
         incidentcatcode: null,
         reportedbyid: null,
         reportedbycode: null,
         remarks: null,
         casestatusid: null,
         statusremarks: null,
         attach: [],
         attachname: [],
         attachdesc: null,
         docid: null,
         documents: [],
         docdid: [],
         stamp: 0,
       //  lockstaff:"Y",
         userid: this.$store.getters.userId,
       },
      document: [],
      documentdetail: [],
      
      
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadIRMaintenance() {
      const vm = this;

      this.$store.dispatch('get', {url: "/ircasemaintenanceedit/" + this.id})
        .then((res) => {

          if (res.data.rec[0].recorddate) {
            vm.formData.recorddate = moment(res.data.rec[0].recorddate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].incidentdate) {
            vm.formData.incidentdate = moment(res.data.rec[0].incidentdate).format(
              "YYYY-MM-DD"
            );
          }
          
          vm.formData.id = res.data.rec[0].id;
          vm.formData.caseid = res.data.rec[0].caseid;
          vm.formData.shortdesc = res.data.rec[0].shortdesc;
          vm.formData.longdesc = res.data.rec[0].longdesc;
          vm.formData.incidentcatid = res.data.rec[0].incidentcatid;
          vm.formData.incidentcatcode = res.data.rec[0].incidentcatcode;
          vm.formData.longdesc = res.data.rec[0].longdesc;
          vm.formData.reportedbyid = res.data.rec[0].reportedbyid;
          vm.formData.reportedbycode = res.data.rec[0].reportedbycode;
          vm.formData.remarks = res.data.rec[0].remarks;
          vm.formData.casestatusid = res.data.rec[0].casestatusid;
          vm.formData.statusremarks = res.data.rec[0].statusremarks;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;
      
          vm.pageTitleValue = res.data.rec[0].caseid;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.userid = this.$store.getters.userId;

          //vm.loading = false;
          vm.loadIRMaintenanceDocument();
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadIRMaintenanceDocument() {
      const vm = this;

      this.$store.dispatch('get', {url: "/irdocument/" + this.id})
        .then((res) => {
          vm.document = res.data.rec;
          console.log('document', vm.document)
          
         vm.formData.attachdesc = res.data.rec[0].attachdesc;    
          // console.log('vm.attachdesc', vm.formData.attachdesc)
          //vm.loading = false;
          this.formData.docid = res.data.rec[0].id;
          console.log('docid', this.formData.docid)
          vm.loadIRMaintenanceDocumentDetail();
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadIRMaintenanceDocumentDetail() {
      const vm = this;

      this.$store.dispatch('get', {url: "/irdocumentdetail/" + this.id})
        .then((res) => {

          vm.documentdetail = res.data.rec;
          console.log('document', vm.documentdetail)

          for (let i = 0; i < vm.documentdetail.length; i++) {
            vm.formData.documents.push(vm.documentdetail[i])
            vm.formData.docdid.push(vm.documentdetail[i].id)
          //  Object.assign(vm.formData.attach = vm.documentdetail[i].attach);
          }

          console.log('documents', vm.formData.documents)
          console.log('vm.formData.docdid', vm.formData.docdid)
          
         // vm.formData.attach = res.data.rec.attach;    
          
          console.log('vm.attach', vm.formData.attach)
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/ircasemaintenance/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "ircasemaintenancelist" , params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      console.log('docid2', this.docid)
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/ircasemaintenance/" +
            this.id +
            "/" +
            this.formData.userid+
            "/" +
            this.formData.docid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "ircasemaintenancelist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "ircasemaintenancenew" });
    },
  },

  mounted() {
    this.loadIRMaintenance();
  },
};
</script>