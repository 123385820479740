<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:error="errorHandler"
      v-bind:timeHolder="{
          starttime:this.starttimeHolder,
          endtime: this.endtimeHolder,
          latetime: this.latetimeHolder,
          earlytime: this.earlytimeHolder,
          otstart: this.otStartHolder,
          earlyotstart: this.earlyOTStartHolder
        }"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ShiftSetupForm from "../../../components/attendance/shiftsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": ShiftSetupForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupforshift.createnewshift'),
      pageTitleValue: "",
      formData: {
        shiftcode: null,
        shiftdesc: null,
        fixed: false,
        staggered: false,
        flexi: false,
        starttime: "",
        endtime: "",
        latetime: "",
        earlytime: "",
        otstart: "",
        earlyotstart: "",
        breaktime: null,
        otbuffer: null,
        shifthours: null,
        otinshift: null,
        isbreakpaid: false,
        earlyot: false,
        isotpreapproved: false,
        sftallwpayitemid: null,
        otallwpayitemid: null,
        isfullshift: false,
        minothours: null,
        onnorm: false,
        onoff: false,
        onrest: false, 
        onhol: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      starttimeHolder: null,
      endtimeHolder: null, 
      latetimeHolder: null,
      earlytimeHolder: null,
      otStartHolder: null,
      earlyOTStartHolder: null,

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      // Convert boolean to integer. 1 = true, 0 = false
      if (
        this.formData.isbreakpaid == false ||
        this.formData.isbreakpaid == null
      ) {
        this.formData.isbreakpaid = 0;
      } else {
        this.formData.isbreakpaid = 1;
      }

      if (
        this.formData.earlyot == false || 
        this.formData.earlyot == null
      ) {
        this.formData.earlyot = 0;
      } else {
        this.formData.earlyot = 1;
      }

      if (
        this.formData.isotpreapproved == false || 
        this.formData.isotpreapproved == null
      ) {
        this.formData.isotpreapproved = 0;
      } else {
        this.formData.isotpreapproved = 1;
      }

      if (
        this.formData.isfullshift == false ||
        this.formData.isfullshift == null
      ) {
        this.formData.isfullshift = 0;
      } else {
        this.formData.isfullshift = 1;
      }

      if (
        this.formData.onnorm == false ||
        this.formData.onnorm == null
      ) {
        this.formData.onnorm = 0;
      } else {
        this.formData.onnorm = 1;
      }

      if (
        this.formData.onoff == false || 
        this.formData.onoff == null
      ) {
        this.formData.onoff = 0;
      } else {
        this.formData.onoff = 1;
      }

      if (
        this.formData.onrest == false ||
        this.formData.onrest == null
      ) {
        this.formData.onrest = 0;
      } else {
        this.formData.onrest = 1;
      }

      if (
        this.formData.onhol == false ||
        this.formData.onhol == null
      ) {
        this.formData.onhol = 0;
      } else {
        this.formData.onhol = 1;
      }

        if (
        this.formData.fixed == false ||
        this.formData.fixed == null
      ) {
        this.formData.fixed = 0;
      } else {
        this.formData.fixed = 1;
      }

      if (
        this.formData.staggered == false ||
        this.formData.staggered == null
      ) {
        this.formData.staggered = 0;
      } else {
        this.formData.staggered = 1;
      }

      if (
        this.formData.flexi == false ||
        this.formData.flexi == null
      ) {
        this.formData.flexi = 0;
      } else {
        this.formData.flexi = 1;
      }

      this.$store.dispatch('post', {url: "/shiftsetup/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "shiftsetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    cancelHandler() {
      this.$router.push({ name: "shiftsetuplist" });
    },
  },
};
</script>