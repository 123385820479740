<template>

    <div id="TaskGroup">
        
        <!-- Leave Task -->
        <v-list v-if="leaveTask.length > 0 ">
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-col cols="9" sm="9" md="9">
                            <v-list-item-title>{{$t('selfservicemytask.leaveapplication')}}</v-list-item-title>
                        </v-col>
                        <v-col cols="3" sm="3" md="3">
                            <v-select
                                :items="actionButton"
                                class="mb-n6 mt-2"
                                :label="actionButtonLabel"
                                @input="$emit('leave-action', $event, leaveTask)"
                                dense
                                outlined
                                @click.stop
                                clearable
                            ></v-select>
                        </v-col>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(item, index) in leaveTask" :key="index" dense :class="{ 'dark-bg': index % 2 !== 0 }">
                <v-list-item-content @click="$emit('leave-route-details', item)">{{item.psnname}} {{item.ttype}} {{$t('homemobile.timerelatedon')}} {{item.entrytime}}</v-list-item-content>
                    <v-list-item-action>
                        <v-checkbox v-model="item.active"></v-checkbox>
                    </v-list-item-action>
                </v-list-item>
            </v-list-group> 
        </v-list>

        <!-- Claim Task -->
        <v-list v-if="claimTask.length > 0">
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-content>
                        <!-- <v-col cols="9" sm="9" md="9"> -->
                        <v-col cols="9" sm="9" md="9">
                            <v-list-item-title>{{$t('selfservicemytask.claimapplication')}}</v-list-item-title>
                        </v-col>
                        <!-- <v-col cols="3" sm="3" md="3">
                            <v-select
                                :items="actionButton"
                                class="mb-n6 mt-2"
                                :label="actionButtonLabel"
                                @input="$emit('claim-action', $event, claimTask)"
                                dense
                                outlined
                                @click.stop
                                clearable
                            ></v-select>
                        </v-col> -->
                    </v-list-item-content>
                </template>

                <v-list-item v-for="(item, index) in claimTask" :key="index" dense :class="{ 'dark-bg': index % 2 !== 0 }">
                <!-- <v-list-item-content @click="loadClaimDetail(item)">{{item.claimdetails[0].staffname}} {{item.ttype}} {{$t('homemobile.timerelatedon')}} {{item.claimdetails[0].entrytime}}</v-list-item-content> -->
                <v-list-item-content @click="$emit('claim-route', item)">{{item.claimdetails[0].staffname}} {{item.ttype}} {{$t('homemobile.timerelatedon')}} {{item.claimdetails[0].entrytime}}</v-list-item-content>
                    <!-- <v-list-item-action>
                        <v-checkbox v-model="item.active"></v-checkbox>
                    </v-list-item-action> -->
                </v-list-item>
            </v-list-group> 
        </v-list>

        <h3 v-if="leaveTask.length < 1 && claimTask.length < 1" class="pl-4 pt-3 pb-2">{{$t('homemobile.notask')}}</h3>

    </div>

</template>

<script>

export default {
    
    props: {
        leaveTask: Array,
        claimTask: Array,
        actionButton: Array,
        actionButtonLabel: String
    }

}
</script>