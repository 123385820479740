<template>
    <div id="`eissublist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
//import moment from 'moment';


import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupreport_exportmapping.mapreport_exportlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '80px', sortable: false},
                { text: this.$t('setupreport_exportmapping.mapcode'), value: 'mapcode', width: '150px'},
                { text: this.$t('setupreport_exportmapping.mapdesc'), value: 'mapdesc', width: '200px' },
                { text: this.$t('setupreport_exportmapping.exportformat'), value: 'expdesc', width: '90px' },    
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/reportexportmapping/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

   
                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'reportexportmappingedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'reportexportmappingnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>