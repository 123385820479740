<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :items="leavetypeList"
                                :rules="[rules.required]"
                                v-model="formData.leavetypeid"
                                @input="updateValue"
                                @change="
                                    changeLeaveTypeHandler(
                                        formData.leavetypeid
                                    )
                                "
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.leavecode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="2" sm="2" class="pb-n5">
                            <v-dialog
                                v-model="leaveEntitlementDialog"
                                max-width="600px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform
                                        v-bind:parentData="{
                                            tabItems: leaveentitlementList,
                                            formTitle: 'Leave Entitlement',
                                            formId: 'leaveentitlement',
                                            leaveTypeSetup: leavetypeList
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveLeaveEntitlement="
                                            saveLeaveEntitlementHandler
                                        "
                                        v-on:deleteLeaveEntitlement="
                                            deleteLeaveEntitlementHandler($event)
                                        "
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="leaveEntitlementDialog = false"
                                        >
                                            Close
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col> -->
                    </v-row>

                    <v-row v-else>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :items="leavetypeList"
                                :rules="[rules.required]"
                                v-model="formData.leavetypeid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.leavecode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!--v-row>
                        <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                label="Exchange Rate"
                                type="text"
                                :rules="[rules.required]"
                                :items="exchrateList"
                                hide-selected
                                v-model="formData.exchrateid"
                                @change="
                                    changeExchRateHandler(formData.exchrateid)
                                "
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mb-n8 mt-n8">
                            <v-text-field
                                dense
                                label="Code"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="exchrateCode"
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.roundingoption')"
                                type="text"
                                :items="roundingList"
                                :rules="[rules.required]"
                                v-model="formData.roundingid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="6" class="pb-n4 mb-n8 mt-n8">
                            <v-text-field
                                dense
                                label=""
                                type="number"
                                outlined
                                v-model="formData.roundto"
                                @input="updateValue"
                                :disabled="formData.roundingid != 4 "
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.prorateby')"
                                type="text"
                                :items="prorateList"
                                :rules="[rules.required]"
                                v-model="formData.lvprorateid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.allocationpolicy')"
                                type="text"
                                :items="allocationList"
                                :rules="[rules.required]"
                                v-model="formData.allocateid"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.leaveentitlementdetail,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment';

//import GenericForm from "../../generic/GridForm";
import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object,
    },

    components: {
        //"app-genericform": GenericForm,
        "app-table": CRUDTable,
    },

    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                },
            },

            formData: {
                leavetypeid: this.parentObject.data.leavetypeid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                roundingid: this.parentObject.data.roundingid,
                roundto: this.parentObject.data.roundto,
                lvprorateid: this.parentObject.data.lvprorateid,
                allocateid: this.parentObject.data.allocateid,
                lockstaff: this.parentObject.data.lockstaff,
                leaveentitlementdetail: this.parentObject.data.leaveentitlementdetail,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.leavegrp'),
                    value: "valuecode",
                    width: "250px",
                    sortable: true,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.uptoserviceyears'),
                    value: "uptosrv",
                    width: "180px",
                    sortable: true,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.entitlement'),
                    value: "entitleunit",
                    width: "150px",
                    sortable: true,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.maxchildcount'),
                    value: "maxchild",
                    width: "150px",
                    sortable: true,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.contractentitlementpermonth'),
                    value: "cntrunitpermth",
                    width: "150px",
                    sortable: true,
                },
                {
                    text: this.$t('setupleaveentitlementpolicysetup.leavegrpdesc'),
                    value: "valuedesc",
                    width: "300px",
                    sortable: true,
                },
            ],

            itemData: {
                itemid: null,
                leavetypeid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0,
            },

            leaveEntitlementDialog: false,
            leaveentitlementList: [],
            leavetypeList: [],
            roundingList: [],
            prorateList: [],
            allocationList: [],

            errMsg: "",
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        };
    },

    methods: {
        loadLeaveType() {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/leavesetup/all`})
                .then((res) => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.leavetypeList.push({
                            text: res.data.rec[i].leavedesc,
                            code: res.data.rec[i].leavecode,
                            value: res.data.rec[i].id,
                        });
                    }
                    this.changeLeaveTypeHandler(vm.formData.leavetypeid);
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadLeaveEntitlement() {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/leaveentitlement/all`})
                .then((res) => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.leaveentitlementList.push({
                            id: res.data.rec[i].id,
                            text: res.data.rec[i].leavedesc,
                            value: res.data.rec[i].id,
                            code: res.data.rec[i].leavecode,
                            desc: res.data.rec[i].leavedesc,
                            stamp: res.data.rec[i].stamp,
                        });
                    }
                    
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        loadRounding() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/rounding/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.roundingList.push({
                        text: res.data.rec[i].roundingdesc,
                        value: res.data.rec[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadProrate() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/prorate/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.prorateList.push({
                        text: res.data.rec[i].proratemtd,
                        value: res.data.rec[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadAllocationPolicy() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/allocationpolicy/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.allocationList.push({
                        text: res.data.rec[i].allocatemtd,
                        value: res.data.rec[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        // loadLeaveEntitlementDt() {
        //     const vm = this;

        //     axios.get(this.$store.getters.apiURL + '/leaveentitlementdt/all')
        //     .then(res => {
        //         vm.dataItems = res.data.rec;

        //         for (let i = 0; i < vm.dataItems.length; i++) {
        //             if (vm.dataItems[i].startdate) {
        //                 vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
        //             }

        //             if (vm.dataItems[i].enddate) {
        //                 vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
        //             }
        //         }

        //         if (vm.formData.leavetypeid) {
        //             this.changeLeaveTypeHandler(
        //                 vm.formData.leavetypeid
        //             );
        //         }
        //     })
        //     .catch(err => {
        //         vm.errMsg = err;
        //     });
        // },

        // changeLeaveEntitlementHandler(id) {
        //     let item = this.leaveentitlementList.find(
        //         (item) => item.value === parseInt(id)
        //     );

        //     this.formData.leavecode = item.code;
        //     this.formData.leavetypeid = item.value;
        // },

        changeLeaveTypeHandler(id) {
            let item = this.leavetypeList.find(
                (item) => item.value === parseInt(id)
            );

            this.formData.leavecode = item.code;
            this.formData.leavetypeid = item.value;
        },

        saveLeaveEntitlementHandler() {
            const vm = this;

            let payload = {
                leavetypeid: this.itemData.leavetypeid,
                userid: this.formData.userid,
                stamp: this.itemData.itemstamp
            };

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: `/leaveentitlement/${vm.itemData.itemid}`,
                    payload: payload
                    })
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.leaveentitlementList = [];

                            this.loadLeaveEntitlement();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            } else {
                this.$store.dispatch('post', {
                    url: `/leaveentitlement/create`,
                    payload: payload
                    })
                    .then((res) => {
                        if (res.data.status == 201) {
                            // reset all arrays
                            vm.leaveentitlementList = [];

                            this.loadLeaveEntitlement();
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        deleteLeaveEntitlementHandler($event) {
            const vm = this;

            if (confirm(`Remove ${$event.desc} ?`)) {
                this.$store.dispatch('delete', {
                    url: `/leaveentitlement/${$event.id}/${vm.formData.userid}`
                    })
                    .then((res) => {
                        if (res.data.status == 200) {
                            // reset all arrays
                            vm.leaveentitlementList = [];

                            this.loadLeaveEntitlement();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg,
                            });
                        }
                    })
                    .catch((err) => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg,
                        });
                    });
            }
        },

        addItemHandler($event) {
            this.formData.leaveentitlementdetail = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit("input", {
                leavetypeid: this.formData.leavetypeid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                roundingid: this.formData.roundingid,
                roundto: this.formData.roundto,
                lvprorateid: this.formData.lvprorateid,
                allocateid: this.formData.allocateid,
                lockstaff: this.formData.lockstaff,
                leaveentitlementdetail: this.formData.leaveentitlementdetail,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "leaveentitlementpolicylist", params: { search: this.search} });
        },
    },

    mounted() {
        //this.loadLeaveEntitlement();
        this.loadLeaveType();
        //this.loadLeaveEntitlementDt();
        this.loadRounding();
        this.loadProrate();
        this.loadAllocationPolicy();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }

    },
};
</script>