<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{$t('servicemovlisting')}}
          </h2>
        </v-row>
        
        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}
            </v-btn>
          </v-row>
        </v-col>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-toolbar flat>
              <v-toolbar-title>
                <div>
                  <span class="green--text">
                    {{ this.data.formData.movtypecode }}
                  </span>
                  {{$t('reportservicemovlisting.listingbetween')}}
                  <span class="green--text">
                    {{ this.data.formData.fromDate }}
                  </span>
                  {{$t('columns.to')}}
                  <span class="green--text">
                    {{ this.data.formData.untilDate }}
                  </span>
                </div>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>
            </v-toolbar>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                hide-default-footer
                :headers="dataHeader"
                :items="dataItems"
                :items-per-page="1000"
                multi-sort
                no-data-text="No records found."
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      data: {
        formData: {
          fromDate: this.$route.params.formData.fromDate,
          untilDate: this.$route.params.formData.untilDate,
          movtypecode: this.$route.params.formData.movtypecode
        },

        parameters: {
          compnyid: this.$route.params.param.companyid,
          brchid: this.$route.params.param.branchid,
          deptid: this.$route.params.param.departmentid,
          emptypeid: this.$route.params.param.employmenttypeid,
          jobid: this.$route.params.param.jobid
        },
      },

      dataHeader: [
        { text: this.$t('statutoryexportcommonused.no'), width: "86", value: "id" },
        { text: this.$t('columns.date'), width: "124", value: "recdate" },
        { text: this.$t('columns.staffcode'), width: "112", value: "staffcode" },
        { text: this.$t('columns.name'), width: "220", value: "psnname" },
        { text: this.$t('columns.reason'), width: "115", value: "reasoncode" },
        { text: this.$t('columns.company'), width: "123", value: "compnycode" },
        { text: this.$t('columns.position'), width: "140", value: "posdesc" },
        { text: this.$t('columns.job'), width: "100", value: "jobcode" },
        { text: this.$t('columns.branch'), width: "110", value: "brchcode" },
        { text: this.$t('columns.department'), width: "140", value: "deptcode" },
        { text: this.$t('columns.division'), width: "120", value: "divncode" },
        { text: this.$t('columns.section'), width: "120", value: "sectiocode" },
        { text: this.$t('columns.unit'), width: "100", value: "unitcode" },
        { text: this.$t('columns.employmenttype'), width: "180", value: "emptype" },
      ],

      tableOpt: {
        itemsPerPage: -1,
      },

      dataItems: [],
      goBtn: false,
    };
  },

  methods: {
    backButton() {
      this.$router.push({ name: "parameterservicemovementlisting" });
    },

    loadServiceMovementListing() {
      this.goBtn = true;

      const vm = this;
      this.$store.dispatch('post', {url: `/servicemovementlisting/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.dataItems = res.data.rec;

            for(let i = 0; i < vm.dataItems.length; i++){
              vm.dataItems[i].id = i + 1;
              vm.dataItems[i].recdate = moment(vm.dataItems[i].recdate).format("YYYY-MM-DD");
            }
          })
    },

    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var head = [
        { title: this.$t('statutoryexportcommonused.no'), dataKey: "id" },
        { title: this.$t('columns.date'), dataKey: "recdate" },
        { title: this.$t('columns.staffcode'), dataKey: "staffcode" },
        { title: this.$t('columns.name'), dataKey: "psnname" },
        { title: this.$t('columns.reason'), dataKey: "reasoncode" },
        { title: this.$t('columns.company'), dataKey: "compnycode" },
        { title: this.$t('columns.position'), dataKey: "posdesc" },
        { title: this.$t('columns.job'), dataKey: "jobcode" },
        { title: this.$t('columns.branch'), dataKey: "brchcode" },
        { title: this.$t('columns.department'), dataKey: "deptcode" },
        { title: this.$t('columns.division'), dataKey: "divncode" },
        { title: this.$t('columns.section'), dataKey: "sectiocode" },
        { title: this.$t('columns.unit'), dataKey: "unitcode" },
        { title: this.$t('columns.employmenttype'), dataKey: "emptype" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          id: this.dataItems[i].id,
          recdate: this.dataItems[i].recdate,
          staffcode: this.dataItems[i].staffcode,
          psnname: this.dataItems[i].psnname,
          reasoncode: this.dataItems[i].reasoncode,
          compnycode: this.dataItems[i].compnycode,
          posdesc: this.dataItems[i].posdesc,
          jobcode: this.dataItems[i].jobcode,
          brchcode: this.dataItems[i].brchcode,
          deptcode: this.dataItems[i].deptcode,
          divncode: this.dataItems[i].divncode,
          sectiocode: this.dataItems[i].sectiocode,
          unitcode: this.dataItems[i].unitcode,
          emptype: this.dataItems[i].emptype,
        });
      }
      
      doc.text(this.formData.movtypecode.toString() + this.$t('reportservicemovlisting.listingbetween') + this.formData.fromDate.toString() + '" ' + this.$t('columns.to') + ' "' + this.formData.untilDate.toString(), 10, 10);
      doc.autoTable(head, body);
      doc.save(this.data.formData.movtypecode + this.$t('reportservicemovlisting.listingbetween') + this.data.formData.fromDate + '" ' + this.$t('columns.to') + ' "' +  this.data.formData.fromDate + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv;charset=utf-8;'});
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute('download', this.data.formData.movtypecode + this.$t('reportservicemovlisting.listingbetween') + this.data.formData.fromDate + '" ' + this.$t('columns.to') + ' "' + this.data.formData.fromDate + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [this.$t('statutoryexportcommonused.no')]: item[i].id,
          [this.$t('columns.date')]: item[i].recdate,
          [this.$t('columns.staffcode')]: item[i].staffcode,
          [this.$t('columns.name')]: item[i].psnname,
          [this.$t('columns.reason')]: item[i].reasoncode,
          [this.$t('columns.company')]: item[i].compnycode,
          [this.$t('columns.position')]: item[i].posdesc,
          [this.$t('columns.job')]: item[i].jobcode,
          [this.$t('columns.branch')]: item[i].brchcode,
          [this.$t('columns.department')]: item[i].deptcode,
          [this.$t('columns.division')]: item[i].divncode,
          [this.$t('columns.section')]: item[i].sectiocode,
          [this.$t('columns.unit')]: item[i].unitcode,
          [this.$t('columns.employmenttype')]: item[i].emptype,
        });
      }

      data.push({
        'No': "",
        'Date': "",
        [this.$t('columns.staffcode')]: "",
        'Name': "",
        'Reason': "",
        'Company': "",
        'Position': "",
        'Job': "",
        'Branch': "",
        'Department': "",
        'Division': "",
        'Section': "",
        'Unit': "",
        'Employment Type': "",
      });

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
  },

  mounted() {},

  created() {
    const vm = this;
    vm.formData = this.$route.params.formData;

    this.loadServiceMovementListing();
  },
};
</script>