import { render, staticRenderFns } from "./ContactDetailsNew.vue?vue&type=template&id=29510492&"
import script from "./ContactDetailsNew.vue?vue&type=script&lang=js&"
export * from "./ContactDetailsNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports