<template>
    <div>
        
        <v-container  fluid style="height: 100%; width:100%; float: left; padding-left: 0px">

                <v-row>
                    <div class="pt-5 mt-n8">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="backHandler" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
                

                <!-- <v-container
                    fluid
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                > -->

                <v-row>
                    <v-col cols="12"> 
                    <v-card elevation="5" outlined>
                        <apexchart  v-if="!loading && hide" id="chart" ref="barchartcompany" type="bar"  width="100%" :options="chartOptions" :series="series"></apexchart>
                    </v-card>
                    </v-col>

                    <v-col cols="12"> 
                    <v-card elevation="5" outlined>
                        <apexchart  v-if="!hide" id="chart" ref="barchartdepartment" type="bar"  width="100%" :options="chartOptionsDepartment" :series="seriesDepartment"></apexchart>
                    </v-card>
                    </v-col>
                    
                    <v-col cols="6">
                        <v-card v-if="hide" elevation="5"  outlined> 
                            <apexchart ref="piechart"  type="pie" width="100%" :options="piechartOptions" :series="pieseries"></apexchart>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card v-if="hide" elevation="5"  outlined> 
                            <apexchart ref="donuchart" type="donut" width="100%" :options="donutchartOptionsNationality" :series="donutseriesNationality"></apexchart>
                        </v-card>
                    </v-col>
                </v-row>  

                <div class="row" v-if="!hidetable">
                    <div class="col-sm-12">

                        <v-toolbar>
                            <v-toolbar-title style="height: 50px; float: left; margin-top: 20px; margin-left:20px" class="textBlue">
                                {{ title }}
                            </v-toolbar-title>
                        
                        <v-spacer></v-spacer>
                        <v-avatar
                                class="mr-5"
                                size="40"
                                tile>
                                <img
                                    :src="`${publicPath}images/export_pdf.png`"
                                    @click="printPDF"
                                    style="height: 40px; float: right; margin-top:10px;margin-bottom:10px;"
                                >
                                </v-avatar>

                                <v-avatar
                                size="40"
                                tile>
                                <img
                                    :src="`${publicPath}images/export_csv.png`"
                                    @click="getCSV"
                                    style="height: 40px; float: right;"
                                >
                                </v-avatar>

                        </v-toolbar>

                        
                        
                    </div>
                </div>

                <div class="row" v-if="!hidetable">
                    <div class="col-sm-12 mt-n8">

                        <v-data-table
                            :headers="headers"
                            :items="staffdetails"
                            v-bind:pagination.sync="pagination"
                            class="elavation-1 fixed header"
                            fixed-header
                            style="margin-top: 20px"
                        >
                            <template slot="items" slot-scope="props">
                                
                                <td class="text-xs-left">{{ props.item.psnname }}</td>
                                <td class="text-xs-left">{{ props.item.staffcode }}</td>
                                <td class="text-xs-left">{{ props.item.deptname  }}</td>
                                <td class="text-xs-left">{{ props.item.posdesc  }}</td>
                                <td class="text-xs-center">{{ props.item.jobtitle  }}</td>
                                <td class="text-xs-center">{{ props.item.grddesc  }}</td>
                                <td class="text-xs-center">{{ props.item.emptypedesc  }}</td>
                                <td class="text-xs-left">{{ props.item.brchname }}</td>
                                <td class="text-xs-left">{{ props.item.divnname }}</td>
                                <td class="text-xs-center">{{ props.item.sectioname  }}</td>
                                <td class="text-xs-left">{{ props.item.unitname }}</td>
                            </template>
                            <template slot="pageText" slot-scope="props">
                                Rows Per Page {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }}
                            </template>
                        </v-data-table>

                    </div>
                </div>
<!-- 
                </v-container> -->
        </v-container>    
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable' 


export default {
    components: {
        'apexchart': VueApexCharts,
        
    },

    data() {

        return {
            publicPath: process.env.BASE_URL,
            bar_data_returned: [],
            pie_data_returned: [],
            pie_data_returned_nationality: [],
            dept_data_returned: [], 
            staffdetails: [],
           
            //for bar chart - headcount dist. by company 
            chartOptions: {
                chart: {
                    id: 'company-chart',
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                        console.log( "data pt index",config.dataPointIndex);
                        this.drilldown(config.dataPointIndex);
                    },
                    },
                },
                title: {
                    text: this.$t('reportheadcountanalysis.headcountanalysisbycompanyasat') + this.asatdate, 
                    align: 'center',
                    margin: 10,
                    style: {
                        fontsize: '16px',
                        color: ' #004080',
                        fontweight: 'light',
                        fontfamily: 'Lato-Bold'
                    },
                },
                subtitle: {
                    text: this.$t('reportheadcountanalysis.clickatrespectivebarviewheadcount'), 
                    align: 'center',
                    style: {
                        fontsize: '100px',
                        color: 'grey',
                        fontfamily: 'Lato-Bold'
                    },
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: true
                    }
                },
                xaxis: {
                    categories: []
                }
            },
            series: [{
                name: 'series-1',
                data: []
            }],

            //for bar chart - dist. by dept 
            chartOptionsDepartment: {
                chart: {
                id: "dept-bar",
                events: {
                        dataPointSelection: (event, chartContext, config) => {
                        console.log( config.dataPointIndex);
                        this.getStaffDetails(config.dataPointIndex);
                    },
                    },
                
                },
                title: {
                    text: this.$t('reportheadcountanalysis.headcountasat_double'), 
                    align: 'center',
                    margin: 10,
                    style: {
                        fontsize: '16px',
                        color: 'grey',
                        fontfamily: 'Lato-Bold'
                    },
                },
                subtitle: {
                    text: "",
                    align: 'center',
                    style: {
                        fontsize: '100px',
                        color: ' #004080',
                        fontfamily: 'Lato-Bold'
                    },
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                    }
                },
                xaxis: {
                    categories: []
                },
            },
            seriesDepartment: [{
                data: []
            }],

            //for pie chart - headcount dist. by gender
            pieseries: [],
            piechartOptions: {

                    chart: {
                        type: 'pie',
                        toolbar:{
                             show: true,
                             offsetX: 0,
                             offsetY: 0,

                             tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                                },

                                export : {
                                    csv: {
                                        filename: this.$t('reportheadcountanalysis.overallheadcountdistributionbygender'), 
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                        }
                                    },

                                    svg: {
                                        filename: this.$t('reportheadcountanalysis.overallheadcountdistributionbygender'),
                                    },

                                    png: {
                                         filename: this.$t('reportheadcountanalysis.overallheadcountdistributionbygender'),
                                    }
                                },

                                autoSelected: 'zoom' 
                        } 
                    },
                    title: {
                        text: this.$t('reportheadcountanalysis.overallheadcountdistributionbygender'),
                        margin: 20,
                        align: 'center',
                        style: {
                            fontsize: '14px',
                            color: ' #004080',
                            fontfamily: 'Lato-Bold'
                        },
                    },
                    plotOptions: {
                        customScale: 1.5
                    },
                    labels: [],
                    responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                        width: 200
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                    }]


                },

            //for donut chart - headcount dist. by nationality
            donutseriesNationality: [],
            donutchartOptionsNationality: {
                chart: {
                    type: 'donut',

                    toolbar:{
                             show: true,
                             offsetX: 0,
                             offsetY: 0,

                             tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                                },

                                export : {
                                    csv: {
                                        filename: "this.$t('reportheadcountanalysis.overallheadcountdistributionbynationality')", 
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                        }
                                    },

                                    svg: {
                                        filename: "this.$t('reportheadcountanalysis.overallheadcountdistributionbynationality')",
                                    },

                                    png: {
                                         filename: "this.$t('reportheadcountanalysis.overallheadcountdistributionbynationality')",
                                    }
                                },

                                autoSelected: 'zoom' 
                        }

                    
                },
                title: {
                    text: this.$t('reportheadcountanalysis.overallheadcountdistributionbynationality'),
                    margin: 20,
                    paddding: 20,
                    align: 'center',
                    style: {
                        fontsize: '14px',
                        color: ' #004080',
                        fontfamily: 'Lato-Bold'
                    },
                },
                plotOptions: {
                    customScale: 1.5
                },
                labels: [],
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                    position: 'bottom'
                    }
                }
                }]
            },

            //table headers
            headers: [
                    
                    { text: this.$t('columns.name'), value: 'psnname', width: '200px' }, 
                    { text: this.$t('columns.staffcode'), value: 'staffcode', width: '200px' },
                    { text: this.$t('columns.department'), value: 'deptname', width: '200px' },
                    { text: this.$t('columns.position'), value: 'posdesc', width: '200px' },
                    { text: this.$t('columns.job'), value: 'jobtitle', width: '200px' },
                    { text: this.$t('columns.grade'), value: 'grddesc', width: '200px' },
                    { text: this.$t('columns.employmenttype'), value: 'emptypedesc', width: '200px' },
                    { text: this.$t('columns.branch'), value: 'brchname', width: '200px' },
                    { text: this.$t('columns.division'), value: 'divnname', width: '200px' },
                    { text: this.$t('columns.section'), value: 'sectioname', width: '200px' },
                    { text: this.$t('columns.unit'), value: 'unitname', width: '200px' },
            ],

            //load bar chart and error message variable
            isErr: false,
            loading: true,

            //hide or show charts & tables variables
            hide : true,
            hidetable: true,

            //title for print to pdf 
            title: this.$t('reportheadcountanalysis.stafflistfor'), 

            compnyid : '', 
            compnyname: '',
            departmentid: '',
            departmentname: '',
        }
    
    },

    
    methods: {

        //load bar chart - headcount distribution by company
        companychart(){
            const vm = this; 


            //add as at date parameter to title of bar chart
            vm.chartOptions = {
                ...vm.chartOptions, 
                ...{
                    title: {
                        text: this.$t('reportheadcountanalysis.headcountanalysisbycompanyasat') + vm.asatdate, 
                        align: 'center',
                        margin: 10,
                        style: {
                            fontsize: '16px',
                            color: ' #004080'
                        }, 
                    }, 
                    
                }
            };

            // get data for bar chart - headcount distribution by company
            this.$store.dispatch('get', {url: "/headcountdistribution/headcountbycompany/" + vm.asatdate})
            .then(res => {

            vm.bar_data_returned = res.data.rec;

            console.log("companies", res.data.rec)

            let apiData = res.data.rec; 

            apiData.forEach(element => {
                
                vm.chartOptions.xaxis.categories.push(element.company_name)
                vm.series[0].data.push(element.total_company)
            });

   
            vm.loading = false; 
            

            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },
        
        //load gender dist pie chart 
        genderchart(){
            const vm = this; 

             //get data for pie chart - gender distribution
            this.$store.dispatch('get', {url: "/headcountdistribution/headcountdistributionbygender/" + vm.asatdate})
            .then(res => {

            vm.pie_data_returned = res.data.rec;
            
            //console.log("piedatareturned", vm.pie_data_returned)

            let pieapiData = res.data.rec; 

            //console.log("apidata index 0", pieapiData)

            
            pieapiData.forEach(element => {

                //console.log("element", element)
                vm.piechartOptions.labels.push(element.gender_name);
                vm.pieseries.push(element.total_gender);

                // console.log(vm.piechartOptions.labels)
                // console.log(vm.pieseries[0])


            });
            
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

        //load nationality dist. pie chart 
        nationalitychart(){

            const vm = this;
            //get data for pie chart - gender distribution
            this.$store.dispatch('get', {url: "/headcountdistribution/headcountdistributionbynationality/" + vm.asatdate})
            .then(res => {

            vm.pie_data_returned_nationality = res.data.rec;
            
            //console.log("piedatareturned", vm.pie_data_returned_nationality)

            let pieapiData = res.data.rec; 


            pieapiData.forEach(element => {

                //console.log ('element', element)
            
                if(element.nationality_name == null){
                      vm.donutchartOptionsNationality.labels.push('Unknown');
                      vm.donutseriesNationality.push(element.total_nationality);

    
                  } else {

                        vm.donutchartOptionsNationality.labels.push(element.nationality_name);
                        vm.donutseriesNationality.push(element.total_nationality);
             }

             
            });

            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

        //drill down to dept chart
        drilldown(dataPointIndex){

            try {
                
                //console.log("datareturnedcompany", this.bar_data_returned[dataPointIndex].companyid)
            this.compnyid = this.bar_data_returned[dataPointIndex].companyid;
            this.compnyname = this.bar_data_returned[dataPointIndex].company_name;

            //console.log("companyid", companyid)

            const vm = this;

            //add as at date parameter to title
            vm.chartOptionsDepartment = {
                ...vm.chartOptionsDepartment, 
                ...{
                    title: {
                        text: this.$t('reportheadcountanalysis.headcountasat') + this.asatdate, 
                        align: 'center',
                        margin: 10,
                        style: {
                            fontsize: '16px',
                            color: 'grey'
                        }, 
                    }, 
                    subtitle: {
                        text: '' + this.compnyname,
                        align: 'center',
                        margin: 10,
                        style: {
                            fontsize: '100px',
                            color: ' #004080'
                        }, 
                    }
                }
            };

            this.$store.dispatch('get', {url: "/headcountdistribution/headcountbydepartment/" + vm.compnyid + "/" + vm.asatdate})
            .then(res => {

            vm.dept_data_returned = res.data.rec;

            console.log("data returned", res.data.rec)

            let apiData = res.data.rec; 

            apiData.forEach(element => {
                
                vm.chartOptionsDepartment.xaxis.categories.push(element.department_name)
                vm.seriesDepartment[0].data.push(element.total_department)
                
            });


            // this.$refs.barchartcompany.updateSeries(this.seriesDepartment, true);
            // this.$refs.barchartcompany.updateOptions(this.chartOptionsDepartment, false, true);
            
            vm.hide = false;
           
            vm.loading = false; 
            

            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });

            } catch (err) {
                this.err = err.message;
            }

        },

        //get staff details for department
        getStaffDetails(dataPointIndex){
                
            console.log("datareturneddept", this.dept_data_returned[dataPointIndex].departmentid)

            //get deptid
            this.departmentid = this.dept_data_returned[dataPointIndex].departmentid;
            this.departmentname = this.dept_data_returned[dataPointIndex].department_name;

            const vm = this;

            //pass asatdate & compnyid & deptid as params 
            this.$store.dispatch('get', {url: "/headcountdistribution/staffdetails/" + vm.asatdate + "/" + vm.compnyid + "/" + vm.departmentid})
            .then(res => {

            vm.staffdetails = res.data.rec;

            vm.title = this.$t('reportheadcountanalysis.stafflistfor') + vm.departmentname; 

            //show table 
            vm.hidetable = false;
            vm.loading = false; 
            
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });

        },

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        async getCSV() {
            let item = this.staffdetails
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [this.$t('columns.name')]: item[i].psnname,
                    [this.$t('columns.staffcode')]: item[i].staffcode,
                    [this.$t('columns.company')]: item[i].compnyname,
                    [this.$t('columns.department')]: item[i].deptname ,
                    [this.$t('columns.position')]: item[i].posdesc,
                    [this.$t('columns.job')]: item[i].jobtitle,
                    [this.$t('columns.grade')]: item[i].grddesc,
                    [this.$t('columns.employmenttype')]: item[i].emptypedesc,
                    [this.$t('columns.branch')]: item[i].brchname,
                    [this.$t('columns.division')]: item[i].divnname,
                    [this.$t('columns.section')]: item[i].sectioname,
                    [this.$t('columns.unit')]: item[i].unitname,
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.staffdetails

            console.log("item", item)

            var head = [                
                { title: this.$t('columns.name'), dataKey: 'psnname' },
                { title: this.$t('columns.staffcode'), dataKey: 'staffcode' },
                { title: this.$t('columns.company'), dataKey: 'compnyname' },
                { title: this.$t('columns.department'), dataKey: 'deptname' },
                { title: this.$t('columns.position'), dataKey: 'posdesc' },
                { title: this.$t('columns.job'), dataKey: 'jobtitle' },
                { title: this.$t('columns.grade'), dataKey: 'grddesc' },
                { title: this.$t('columns.employmenttype'), dataKey: 'emptypedesc' },
                { title: this.$t('columns.branch'), dataKey: 'brchname' },
                { title: this.$t('columns.division'), dataKey: 'divnname' },
                { title: this.$t('columns.section'), dataKey: 'sectioname' },
                { title: this.$t('columns.unit'), dataKey: 'unitname' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
               
                body.push({
                        psnname: item[i].psnname,
                        staffcode: item[i].staffcode,
                        compnyname: item[i].compnyname,
                        deptname: item[i].deptname,
                        posdesc: item[i].posdesc,
                        jobtitle: item[i].jobtitle,
                        grddesc: item[i].grddesc,
                        emptypedesc: item[i].emptypedesc,
                        brchname: item[i].brchname,
                        divnname: item[i].divnname,
                        sectioname: item[i].sectioname,
                        unitname: item[i].unitname
                    })
            }

            console.log('title', this.title)
            this.title = this.$t('reportheadcountanalysis.stafflistfor') + this.departmentname + '" ' + this.$t('reportleavedashboard.asat') + ' "' + this.asatdate ;
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        //back handler - go back to parameterHeadcount.vue
        backHandler(){
            this.$router.push({name: 'parameterheadcount'});
        }


        
    },

    created() {

        const vm = this;
        vm.asatdate = this.$route.params.asatdate;
        vm.companychart();
        vm.genderchart();
        vm.nationalitychart();
    },
    
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}
</style>


