<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:cancel="cancelHandler"
        v-on:error="errorHandler"
        v-bind:timeHolder="{
          starttime:this.starttimeHolder,
          endtime: this.endtimeHolder,
          latetime: this.latetimeHolder,
          earlytime: this.earlytimeHolder,
          otstart: this.otStartHolder,
          earlyotstart: this.earlyOTStartHolder
         
         
        }"
     
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import moment from 'moment';


import ShiftSetupForm from "../../../components/attendance/shiftsetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": ShiftSetupForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupforshift.editshiftsetup'),
      pageTitleValue: "",
      formData: {
        shiftcode: null,
        shiftdesc: null,
        fixed: null,
        staggered: null,
        flexi: null,
        starttime:"",
        endtime: "",
        latetime: "",
        earlytime: "",
        otstart: "",
        earlyotstart: "",
        breaktime: null,
        otbuffer: null,
        shifthours: null,
        otinshift: null,
        isbreakpaid: null,
        earlyot: null,
        isotpreapproved: null,
        sftallwpayitemid: null,
        otallwpayitemid: null,
        isfullshift: null,
        minothours: null,
        onnorm: null,
        onoff: null,
        onrest: null, 
        onhol: null,
        stamp: 0,
        userid: this.$store.getters.userId,

      },
      starttimeHolder: null,
      endtimeHolder: null, 
      latetimeHolder: null,
      earlytimeHolder: null,
      otStartHolder: null,
      earlyOTStartHolder: null,

      
      errMsg: "",
      isErr: false,
      loading: true,
      editMode: false,
      isConfirm: false,
    };
  },

  methods: {

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

       timeConversion2(val){
                 let timeHolder = {
                  HH:val.split(":")[0],
                  mm:val.split(":")[1],
                  ss:"00"
                 }
                 return timeHolder

         },


    loadShiftSetupHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/shiftsetup/" + vm.id})
        .then((res) => {
        
          console.log('rec', res.data.rec[0])
          
          if (res.data.rec[0].isbreakpaid == "N") {
            vm.formData.isbreakpaid = false;
          } else {
            vm.formData.isbreakpaid = true;
          }

          if (res.data.rec[0].earlyot == "N") {
            vm.formData.earlyot = false;
          } else {
            vm.formData.earlyot = true;
          }

          if (res.data.rec[0].isotpreapproved == "N") {
            vm.formData.isotpreapproved = false;
          } else {
            vm.formData.isotpreapproved = true;
          }
          
          if (res.data.rec[0].isfullshift == "N") {
            vm.formData.isfullshift = false;
          } else {
            vm.formData.isfullshift = true;
          }

          if (res.data.rec[0].onnorm == "N") {
            vm.formData.onnorm = false;
          } else {
            vm.formData.onnorm = true;
          }

          if (res.data.rec[0].onoff == "N") {
            vm.formData.onoff = false;
          } else {
            vm.formData.onoff = true;
          }

          if (res.data.rec[0].onrest == "N") {
            vm.formData.onrest = false;
          } else {
            vm.formData.onrest = true;
          }

          if (res.data.rec[0].onhol == "N") {
            vm.formData.onhol = false;
          } else {
            vm.formData.onhol = true;
          }

          if (res.data.rec[0].fixed == "N") {
            vm.formData.fixed = false;
          } else {
            vm.formData.fixed = true;
          }

          if (res.data.rec[0].staggered == "N") {
            vm.formData.staggered = false;
          } else {
            vm.formData.staggered = true;
          }

          if (res.data.rec[0].flexi == "N") {
            vm.formData.flexi = false;
          } else {
            vm.formData.flexi = true;
          }

        
          vm.formData.shiftcode = res.data.rec[0].shiftcode;
          vm.formData.shiftdesc = res.data.rec[0].shiftdesc;
          if(res.data.rec[0].starttime)vm.starttimeHolder = this.timeConversion2(res.data.rec[0].starttime);
          if(res.data.rec[0].endtime)vm.endtimeHolder = this.timeConversion2(res.data.rec[0].endtime);
          // console.log('starttime', this.formData.starttime)
          // console.log('endtime', this.formData.endtime)
          if(res.data.rec[0].latetime)vm.latetimeHolder = this.timeConversion2(res.data.rec[0].latetime);
          if(res.data.rec[0].earlytime)vm.earlytimeHolder = this.timeConversion2(res.data.rec[0].earlytime);
          if(res.data.rec[0].otstart)vm.otStartHolder = this.timeConversion2(res.data.rec[0].otstart);
          if(res.data.rec[0].earlyotstart)vm.earlyOTStartHolder = this.timeConversion2(res.data.rec[0].earlyotstart);
          vm.formData.breaktime = res.data.rec[0].breaktime;
          vm.formData.otbuffer = res.data.rec[0].otbuffer;
          vm.formData.shifthours = res.data.rec[0].shifthours;
          vm.formData.otinshift = res.data.rec[0].otinshift;
          vm.formData.sftallwpayitemid = res.data.rec[0].sftallwpayitemid;
          vm.formData.otallwpayitemid = res.data.rec[0].otallwpayitemid;
          vm.formData.minothours = res.data.rec[0].minothours;
          vm.formData.stamp = res.data.rec[0].stamp;

          vm.pageTitleValue = res.data.rec[0].shiftdesc;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (this.formData.isbreakpaid == false) {
        this.formData.isbreakpaid = 0;
      } else {
        this.formData.isbreakpaid = 1;
      }

      if (this.formData.earlyot == false) {
        this.formData.earlyot = 0;
      } else {
        this.formData.earlyot = 1;
      }

       if (this.formData.isotpreapproved == false) {
        this.formData.isotpreapproved = 0;
      } else {
        this.formData.isotpreapproved = 1;
      }

      if (this.formData.isfullshift == false) {
        this.formData.isfullshift = 0;
      } else {
        this.formData.isfullshift = 1;
      }

      if (this.formData.onnorm == false) {
        this.formData.onnorm = 0;
      } else {
        this.formData.onnorm = 1;
      }

      if (this.formData.onoff == false) {
        this.formData.onoff = 0;
      } else {
        this.formData.onoff = 1;
      }

      if (this.formData.onrest == false) {
        this.formData.onrest = 0;
      } else {
        this.formData.onrest = 1;
      }

       if (this.formData.fixed == false) {
        this.formData.fixed = 0;
      } else {
        this.formData.fixed = 1;
      }

      if (this.formData.staggered == false) {
        this.formData.staggered = 0;
      } else {
        this.formData.staggered = 1;
      }

      if (this.formData.flexi == false) {
        this.formData.flexi = 0;
      } else {
        this.formData.flexi = 1;
      }

      this.$store.dispatch('put', {url: "/shiftsetup/" + vm.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "shiftsetuplist" , params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    

    cancelHandler() {
      this.$router.push({ name: "shiftsetuplist" });
    },

    deleteHandler($event) {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/shiftsetup/" +
              this.id +
              "/" +
              this.formData.userid
          })
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "shiftsetuplist" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "shiftsetupnew" });
    },
   
  },

  mounted() {
    this.editMode = true;
    this.loadShiftSetupHandler();
  },
};
</script>