<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5">
            <v-text-field
              outlined
              :label="this.$t('latenessdeduction.batchcode')"
              type="text"
              ref="latenessbatchcode"
              v-model="formData.latenessbatchcode"
              :rules="[rules.required]"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="8" class="pb-n4 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('latenessdeduction.batchdesc')"
              type="text"
              ref="latenessbatchdesc"
              v-model="formData.latenessbatchdesc"
              :rules="[rules.required]"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendancefromdate')"
              type="date"
              ref="attnfromdate"
              :rules="[rules.required]"
              v-model="formData.attnfromdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendanceuntildate')"
              type="date"
              ref="attntodate"
              :rules="[rules.required]"
              v-model="formData.attntodate"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-autocomplete
            outlined
            :label="this.$t('latenessdeduction.latenessdedecutionpolicy')"
            type="text"
            clearable
            :items="latenessDeductionPolicyList"
            :rules="[rules.required]"
            v-model="formData.latenesspolicyid"
            @change="changeLatenessDeductionPolicyHandler(formData.latenesspolicyid)"
            dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n4 mt-n8">
            <v-text-field
            dense
            :label="this.$t('columns.code')"
            outlined
            readonly
            background-color="#ffeab2"
            v-model="latenessparamcode"
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-autocomplete
            outlined
            :label="this.$t('payrollprocessrun.exchangerate')"
            type="text"
            clearable
            :items="exchangeRateList"
            :rules="[rules.required]"
            v-model="formData.exchratesetid"
            @change="changeExchrateHandler(formData.exchratesetid)"
            dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n4 mt-n8">
            <v-text-field
            dense
            :label="this.$t('columns.code')"
            outlined
            readonly
            background-color="#ffeab2"
            v-model="exchageRateCode"
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-autocomplete
            outlined
            :label="this.$t('latenessdeduction.periodic')"
            type="text"
            clearable
            :items="logicList"
            :rules="[rules.required]"
            v-model="formData.logicid"
            @change="changeLogicHandler(formData.logicid)"
            dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n4 mt-n8">
            <v-text-field
            dense
            :label="this.$t('columns.code')"
            outlined
            readonly
            background-color="#ffeab2"
            v-model="logicCode"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pb-n5 mt-n6">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="blue--text"
                  >{{$t('reportsparam.filtercriteria')}}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row class="mt-5">
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.company')"
                        type="text"
                        ref="company"
                        v-model="formData.company"
                        dense
                        :items="companyList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.division')"
                        type="text"
                        ref="division"
                        v-model="formData.division"
                        dense
                        :items="divisionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('periodicincentivesetup.workdaygroup')"
                        type="text"
                        ref="workdaygroup"
                        v-model="formData.workdaygroup"
                        dense
                        :items="workdaygrouplist"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.branch')"
                        type="text"
                        ref="description"
                        v-model="formData.branch"
                        dense
                        :items="branchList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.section')"
                        type="text"
                        ref="section"
                        v-model="formData.section"
                        dense
                        :items="sectionList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.employmenttype')"
                        type="text"
                        ref="employmentType"
                        v-model="formData.employmentType"
                        dense
                        :items="emptypeList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.department')"
                        type="text"
                        ref="department"
                        v-model="formData.department"
                        dense
                        :items="departmentList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.unit')"
                        type="text"
                        ref="unit"
                        v-model="formData.unit"
                        dense
                        :items="unitList"
                        clearable
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                      <v-autocomplete
                        outlined
                        :label="this.$t('columns.staff')"
                        type="text"
                        ref="staff"
                        v-model="formData.staff"
                        dense
                        :items="staffDetailsList"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn color="primary" @click="onPost" style="width: 150px">
              {{$t('button.generate')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<style>
.ExceptionClass td {
  color: red !important;
}
</style>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      search: "",
      showDate: new Date(),
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      formData: {
        latenessbatchcode: null,
        latenessbatchdesc: null,
        attnfromdate: moment().format("YYYY-MM-DD"),
        attntodate: moment().format("YYYY-MM-DD"),
        latenesspolicyid: null,
        exchratesetid: null,
        logicid: null,
        company: null,
        division: null,
        workdaygroup: null,
        branch: null,
        section: null,
        employmentType: null,
        department: null,
        unit: null,
        staff: null,
        userid: this.$store.getters.userId,
      },

      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,

      latenessDeductionPolicyList: [],
      latenessDeductionPolicy: [],
      exchangeRateList: [],
      exchangeRate: [],
      logicList:[],
      logicItems:[],

      staffdetails: [],
      staffDetailsList: [],
      divisions: [],
      divisionList: [],
      companies: [],
      companyList: [],
      sections: [],
      sectionList: [],
      emptypes: [],
      emptypeList: [],
      branches: [],
      branchList: [],
      units: [],
      unitList: [],
      departments: [],
      departmentList: [],
      grades: [],
      gradeList: [],
      positions: [],
      positionList: [],
      workdaygroup: [],
      workdaygrouplist: [],
    };
  },

  methods: {
    onPost() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.loading = true;
      this.$store.dispatch('post', {
        url: "/latenessdeductiongenerate",
        payload: this.formData
      })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 201) {
            console.log(res.data);
            this.$router.push({
              name: "latenessdeductiongeneratelog",
              params: {
                processed: res.data.rec.processed,
                inserted: res.data.rec.inserted,
                amount: res.data.rec.amount,
              },
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isErr = true;
          this.errMsg = err;
        });
    },
    loadWorkdayGroupListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/groupp_grouppb_workday/all"
      })
        .then((res) => {
          console.log(res);
          vm.workdaygroup = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.workdaygrouplist.push({
              text: res.data.rec[i].valuedesc,
              value: res.data.rec[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load divisions
    loadDivisionListHandler() {
      const vm = this;

      this.$store.dispatch('get', { 
        url: "/division/all"
      })
        .then((res) => {
          vm.divisions = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load companies
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', { 
        url: "/company/all"
      })
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load section
    loadSectionListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/section/all"
      })
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    //load emptypes
    loadEmpTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/emptype/all"
      })
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptypedesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load branches
    loadBanchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/branch/all"
      })
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load units
    loadUnitListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/unit/all"
      })
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load depts
    loadDeptListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/department/all"
      })
        .then((res) => {
          vm.departments = res.data.rec;
         // console.log('dept',res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load grades
    loadGradeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/grade/all"
      })
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //error - unsucessfull request
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
          url: `/staff/all/${this.$store.getters.userId}`
        })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            // if (vm.formData.staffid) {
            //   this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            // }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    loadLatenessDeductionPolicy() {
            const vm = this;
            this.$store.dispatch('get', {
                url: '/latenessdeductionpolicy/all'})
                .then(res => {
                    vm.latenessDeductionPolicy = res.data.rec;
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.latenessDeductionPolicyList.push(
                            {
                                text: res.data.rec[i].latenessparamdesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    vm.currencyLoading = false;

                    if (vm.formData.latenesspolicyid) {
                        vm.changeLatenessDeductionPolicyHandler(vm.formData.latenesspolicyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
    changeLatenessDeductionPolicyHandler(id) {
      let item = this.latenessDeductionPolicy.find(item => item.id === id);
      this.latenessparamcode = item.latenessparamcode;
    },
    loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/exchrate/all'})
                .then(res => {
                    vm.exchangeRate = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangeRateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    vm.currencyLoading = false;

                    if (vm.formData.exchratesetid) {
                        vm.changeExchrateHandler(vm.formData.exchratesetid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
    changeExchrateHandler(id) {
      let item = this.exchangeRate.find(item => item.id === id);
      this.exchageRateCode = item.exchratecode;
    },
    
    loadLogic(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/latenessdeduction/logic'
            })
            .then(res => {

                vm.logicItems = res.data.rec;

                for (let i = 0; i < vm.logicItems.length; i++) {
                    vm.logicList.push({
                        text: vm.logicItems[i].logicname,
                        code: vm.logicItems[i].logiccode,
                        value: vm.logicItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeLogicHandler(id){
            let item = this.logicItems.find(item => item.id == id);
            this.logicCode = item.logiccode;

        },
  },

  mounted() {
    this.loadLatenessDeductionPolicy();
    this.loadExchangeRate();
    this.loadLogic();
    this.loadStaffDetailsListHandler();
    this.loadGradeListHandler();
    this.loadDeptListHandler();
    this.loadUnitListHandler();
    this.loadBanchListHandler();
    this.loadEmpTypeListHandler();
    this.loadSectionListHandler();
    this.loadCompaniesListHandler();
    this.loadDivisionListHandler();
    this.loadWorkdayGroupListHandler();

  //  this.loadLeaveReasonListHandler();
  },
};
</script>