<template>
    <div id="`salaryexportlist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('allsalaryexportrecord.salaryexportrecordlist'),
            dataHeader: [
                { text: this.$t('columns.actions'),                       value: 'action',            width: '100px', sortable: false},
                { text: this.$t('allbank.salaryexportrec'),         value: 'salexpreccode',     width: '220px' },
                { text: this.$t('columns.description'),                  value: 'salexprecdesc',     width: '150px' },
                { text: this.$t('reportsparam.year'),                         value: 'salexpyear',        width: '100px' },
                { text: this.$t('reportsparam.month'),                        value: 'salexpmonth',       width: '100px' },
                { text: this.$t('setupcompanybankaccount.bank'),                         value: 'accountdesc',       width: '100px' },
                { text: this.$t('allbank.companybankacc'),         value: 'accountcode',     width: '220px' },
                { text: this.$t('setupcompanybankaccount.accountno'),                  value: 'accountno',         width: '200px' },
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/salaryexportrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                    console.log('dataitems', vm.dataItems)

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'salaryexportrecordedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'salaryexportrecordnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>