<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="checkDelete($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import moment from "moment";

import LeaveRecordForm from "../../../components/leave/leaverecord/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LeaveRecordForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recleaverec.editleaverec'),
      pageTitleValue: "",
      formData: {
        id: null,
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnyname: null,
        leavetypeid: null,
        leavecode: null,
        startdate: null,
        enddate: null,
        partialday: [],
        reasonid: null,
        reasoncode: null,
        clinicid: null,
        cliniccode: null,
        reliefstaffid: null,
        lvstatusid: null,
        lvstatuscode: null,
        posted: false,
        attachment: null,
        attachname: null,
        aprvremark: null,
        lvremark: null,
        entrytime: null,
        entrybid: null,
        entrybyusername: null,
        appsrcid: null,
        src: null,
        leavehours: null,
        leavedays: null,
        nonpanelclinic: null,
       // leavedate: [],
       // daytypeid: [],
        leaverecorddetail:[],
        stamp: 0,
        userid: this.$store.getters.userId,
        ishistory: false,
        fromweb: true
      },
      cur_stamp: 0,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      loading: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //insert new record
    saveHandler() {
      const vm = this;

        vm.formData.id = vm.id;
        vm.formData.stamp = vm.cur_stamp;

        if (this.formData.posted == true) {
          this.formData.posted = 1;
        } else {
          this.formData.posted = 0;
        }

        if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
          return false;
        }

          this.$store.dispatch('put', {
              url: "/leaverecord/" + vm.id,
              payload: vm.formData
            })
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "leaverecordlist", params: { search: this.search}  });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
    },

    loadLeaveRecord() {
      const vm = this;
      vm.loading = true;
      this.$store.dispatch('get', {
        url: "/leaverecord/" + vm.id
        })
        .then((res) => {
          
          vm.formData.id = res.data.rec.id;
          vm.formData.staffid = res.data.rec.staffid;
          vm.formData.staffcode = res.data.rec.staffcode;
          vm.formData.leavetypeid = res.data.rec.leavetypeid;
          vm.formData.startdate = moment(res.data.rec.startdate).format(
            "YYYY-MM-DD"
          );
          vm.formData.enddate = moment(res.data.rec.enddate).format(
            "YYYY-MM-DD"
          );

          vm.formData.reasonid = res.data.rec.reasonid;
          vm.formData.reasoncode = res.data.rec.reasoncode;
          vm.formData.clinicid = res.data.rec.clinicid;
          vm.formData.cliniccode = res.data.rec.cliniccode;
          vm.formData.reliefstaffid = res.data.rec.reliefstaffid;
          vm.formData.lvstatusid = res.data.rec.lvstatusid;
        //  vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.formData.posted = res.data.rec.posted == "Y" ? true : false;
          vm.formData.attachment = res.data.rec.attachment;
          vm.formData.attachname = "";
          vm.formData.aprvremark = res.data.rec.aprvremark;
          vm.formData.lvremark = res.data.rec.lvremarks;
          vm.formData.entrytime = moment(res.data.rec.entrytime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          vm.formData.nonpanelclinic = res.data.rec.nonpanelclinic;
          vm.formData.entrybid = res.data.rec.entrybid;
          vm.formData.entrybyusername = res.data.rec.entrybyusername;
          vm.formData.appsrcid = res.data.rec.appsrcid;
          vm.formData.src = res.data.rec.src;
          vm.formData.stamp = res.data.rec.stamp;
          vm.cur_stamp = res.data.rec.stamp;

          vm.formData.userid = this.$store.getters.userId;
          
          vm.formData.ishistory = res.data.rec.ishistory == "Y" ? true : false;

          vm.formData.leavehours = 0;
          // let hours = 0;
          vm.formData.leavedays = res.data.rec.lvdays;
          for (var i = 0; i < res.data.rec.partialday.length; i++) {
            let partial = {
              action: 1,
              date: moment(res.data.rec.partialday[i].startdatetime).format(
                "YYYY-MM-DD"
              ),
              starttime: moment(
                res.data.rec.partialday[i].startdatetime
              ).format("HH:mm"),
              endtime: moment(res.data.rec.partialday[i].enddatetime).format(
                "HH:mm"
              ),
              partialqty: res.data.rec.partialday[i].partialqty,
            };
            vm.formData.partialday.push(partial);
            // hours += parseInt(res.data.rec.partialday[i].partialqty);
          }

          // let leaveitem = this.leavetype.find(
          //   (item) => item.id === res.data.rec.leavetypeid
          // );
          // if (leaveitem) {
          //   let leavehour = hours / leaveitem.hrperday;
          //   this.formData.leavehours =
          //     leaveitem.hrperday * (res.data.rec.leaveday - leavehour);
          //   this.formData.leavedays = res.data.rec.leaveday - leavehour;
          // } else {
          //   this.formData.leavehours = hours;
          //   this.formData.leavedays = res.data.rec.leaveday;
          // }

          // vm.formData.leavedays = parseInt(vm.formData.leavehours / 8);
          vm.loading = false;
          //console.log(vm.formData);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "leaverecordlist" });
    },

    newHandler() {
      this.$router.push({ name: "leaverecordnew" });
    },

    async deleteHandler() {
   //   let checkDel = await this.checkDelete()

    //  console.log('checkDel', checkDel)
      
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

    //  if (!checkDel) return false;
      this.$store.dispatch('delete', {
        url: "/leaverecord/" + vm.id
        })
        .then((res) => {
          console.log('res', res)
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leaverecordlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    async checkDelete() {
      console.log('abc')
      const vm = this;
      //let resDel = false;
      this.$store.dispatch('get', {
        url: `/wftranbyrecordid/` + vm.id
        })
      .then(res => {
          let item = res.data.rec;
          console.log('item', item)
          if (item.length > 0) {
            vm.isErr = true;
            vm.errMsg = 'Cannot delete active record transaction';
           // resDel = false;
          } else {
            this.deleteHandler();
           // resDel = true;
          }
          //return resDel
      })
      .catch(err => {
          vm.isErr = true;
          vm.errMsg = err;
      });
    }
  },
  mounted() {
    this.loadLeaveRecord();
  },
};
</script>