<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:cancel="cancelHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import LeaveEntitlementPolicy from "../../../components/leave/leaveentitlementpolicy/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": LeaveEntitlementPolicy,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('setupleaveentitlementpolicysetup.newentitlementpolicy'),
      pageTitleValue: "",
      formData: {
        leavetypeid: null,
        startdate: null,
        enddate: null,
        roundingid: null,
        roundto: null,
        lvprorateid: null,
        allocateid: null,
        leaveentitlementdetail: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
    
      this.$store.dispatch('post', {url: "/leaveentitlementdt/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leaveentitlementpolicylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "leaveentitlementpolicylist" });
    },
  },
};
</script>