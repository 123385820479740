<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import TaxForm from "../../../components/payroll/taxset/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": TaxForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setuptax.edittaxsetup'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
    
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadHeaderHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/taxsetdt/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          if (res.data.rec[0].startdate) {
            vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
            vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }

           vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.pageTitleValue = res.data.rec[0].pcbsetdesc;
          vm.formData.userid = this.$store.getters.userId;

          this.loadDetailHandler(vm.formData.id);

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDetailHandler(id) {
      const vm = this;
      console.log("id", id);

      this.$store.dispatch('get', {url: "/taxsetd/" + id})
        .then((res) => {
          vm.formData.taxsetdetail = res.data.rec;
          console.log("1", vm.formData.taxsetdetail);

          for (let i = 0; i < vm.formData.taxsetdetail.length; i++) {
            Object.assign(vm.formData.taxsetdetail[i], { action: 2 });
          }

          this.loadDetail1Handler(id);

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDetail1Handler(id) {
      const vm = this;

      this.$store.dispatch('get', {url: "/taxsetr/" + id})
        .then((res) => {
          vm.formData.taxsetdetail1 = res.data.rec;
          console.log("2", vm.formData.taxsetdetail1);

          for (let i = 0; i < vm.formData.taxsetdetail1.length; i++) {
            Object.assign(vm.formData.taxsetdetail1[i], { action: 2 });
          }

          this.loadDetail2Handler(id);
          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDetail2Handler(id) {
      const vm = this;

      this.$store.dispatch('get', {url: "/taxsetded/" + id})
        .then((res) => {
          vm.formData.taxsetdetail2 = res.data.rec;
          console.log("3", vm.formData.taxsetdetail2);

          for (let i = 0; i < vm.formData.taxsetdetail2.length; i++) {
            Object.assign(vm.formData.taxsetdetail2[i], { action: 2 });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "taxsetnew" });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/taxsetdt/" + this.id, payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "taxsetlist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "taxsetlist" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/taxsetdt/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "taxsetlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadHeaderHandler();
  },
};
</script>