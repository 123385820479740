<template>
    <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('mmmonthlyssb.ssbsubmissionrec')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.ssbsubreccode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.ssbsubrecdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('reportsparam.year')"
                                :rules="[rules.required]"
                                v-model="formData.recyear"
                                @input="updateValue"
                                dense
                                :items="yearList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('reportsparam.month')"
                                :rules="[rules.required]"
                                v-model="formData.recmonth"
                                @input="updateValue"
                                dense
                                :items="monthList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5 mt-n8">
                            <v-btn icon color="orange">
                                <v-icon @click="loadPayoutList()">mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('statutoryexportcommonused.payouts')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-data-table
                            :headers="dataHeader"
                            :items="this.dataItems"
                            :options="tableOpt"
                            hide-default-footer
                            :single-select="singleSelect"
                            item-key="payoutcode"
                            show-select
                            @input="changePayoutHandler()"
                            v-model="selected"
                        >
                        </v-data-table>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text mt-8">
                            {{$t('statutoryexportcommonused.referencenumberandpaymentinfo')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.ssbSubDetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        Loading,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            formData: {
                ssbsubreccode: this.parentObject.data.ssbsubreccode,
                ssbsubrecdesc: this.parentObject.data.ssbsubrecdesc,
                recyear:  this.parentObject.data.recyear,
                recmonth: this.parentObject.data.recmonth,
                myntlyid: this.parentObject.data.myntlyid,
                ssbSubDetail:this.parentObject.data.ssbSubDetail,
                ssbSelectedPayouts: this.parentObject.data.ssbSelectedPayouts,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            tabHeader: [
                { text: this.$t('columns.actions'),       value: 'action', width: '100px', sortable: false},
                // { text: 'No.',          value: 'no' ,  width: '80px', sortable: true},
                { text: this.$t('statutoryexportcommonused.refcode'), value: 'refcode',  width: '150px', sortable: true},
                { text: this.$t('setuppersonstatutorynumberinfo.refno'),value: 'refnum',   width: '150px', sortable: true},
                // { text: this.$t('allssb.hqssbcode'),  value: 'hqssbcode',  width: '100px', sortable: true},
                // { text: this.$t('allssb.hqssbno'),  value: 'hqssbnum',  width: '120px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptno'),  value: 'rcpno',  width: '130px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptdate'), value: 'rcpdate',  width: '130px', sortable: true},
                { text: this.$t('statutoryexportcommonused.receiptref'),  value: 'rcpref',  width: '130px', sortable: true},
            ],

            singleSelect: false,
            dataItems: [],
            dataHeader: [
                { text: this.$t('statutoryexportcommonused.payoutid'),            value: 'payoutcode',    width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.payoutdescription'),   value: 'payoutdesc',    width: '300px', sortable: true},
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',  width: '300px', sortable: true}
            ],

            tableOpt: {
                itemsPerPage: -1
            },

            // to know which payouts has been selected 
            selected: [],
            monthList: [],
            yearList: [],
            payoutList: [],
            nationalities: [],
            nationalityList: [],

            loading: false,
            payoutLoading: false,

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        loadPayout() {
            const vm = this;

             vm.loading = true;

            this.$store.dispatch('get', {
                url: '/payoutctrl/all'})
                .then(res => {
                    vm.payoutList = res.data.rec;
                    vm.loading = false

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )        
                        
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )
                    }
                    this.loadPayoutEdit();

                    // if (vm.formData.recyear) {
                    //     vm.changeYearHandler(vm.formData.recyear);
                    // }

                    // if (vm.formData.recmonth) {
                    //     vm.changeMonthHandler(vm.formData.recmonth);
                    // }

                    
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadPayoutList() {
            this.payoutLoading = true;
            let payoutYear = [];
            if (!this.formData.recyear && !this.formData.recmonth) {
                this.dataItems = this.payoutList
            } else if (this.formData.recyear) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    if (this.payoutList[i].pyyear === this.formData.recyear) {
                        payoutYear.push(this.payoutList[i])
                    }
                }
                if (this.formData.recmonth) {
                    this.dataItems = []
                    for (let j = 0; j < payoutYear.length; j++) {
                        if (payoutYear[j].pymonth === this.formData.recmonth) {
                            this.dataItems.push(payoutYear[j])
                        }
                    }
                }
            }
        },

        loadPayoutEdit() {
            let getItem = []
            if (this.isEdit) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    for (let j = 0; j < this.formData.ssbSelectedPayouts.length; j++) {
                        if (this.payoutList[i].id === this.formData.ssbSelectedPayouts[j].poid) {
                            getItem[j] = this.payoutList[i]
                            getItem[j].exId = this.formData.ssbSelectedPayouts[j].poid
                        }
                    }
                }
                this.loadPayoutList()
                this.selected = getItem
                this.formData.ssbSelectedPayouts = []
            }
        },
     
        loadNationality(){
            const vm = this;
            this.$store.dispatch('get', {url: '/country/all'})
                .then(function (res) {
                    vm.nationalities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.nationalityList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )                        
                    }
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });
        },

        changeYearHandler(id) {
            this.years.find(item => item.id === id);
            
        },
     
        changeMonthHandler(id) {
            this.months.find(item => item.id === id);
            
        },

        changePayoutHandler() {
            this.formData.ssbSelectedPayouts = this.selected;
            this.updateValue();
        },

        addItemHandler($event) {
            this.formData.ssbSubDetail=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                ssbsubreccode: this.formData.ssbsubreccode,
                ssbsubrecdesc: this.formData.ssbsubrecdesc,
                recyear: this.formData.recyear,
                recmonth: this.formData.recmonth,
                myntlyid: this.formData.myntlyid,
                aprname: this.formData.aprname,
                apricno: this.formData.apricno,
                aprdesignation: this.formData.aprdesignation,
                apremail: this.formData.apremail,
                aprtel: this.formData.aprtel,
                ssbSubDetail: this.formData.ssbSubDetail,
                ssbSelectedPayouts: this.formData.ssbSelectedPayouts,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            const vm = this;
            if (this.formData.ssbSelectedPayouts.length > 0) {
                this.$emit('save');
            } else {
                vm.$emit('error', {isErr: true, errMsg: 'Please select a payout'});
            }
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmmonthlyssbsubmissionrecordlist', params: { search: this.search}});
        }
    },

    mounted() {
        
        this.loadNationality();
        this.loadPayout();

    }
}
</script>