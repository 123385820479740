<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                      <v-row>
                        <v-col cols="4" sm="8" class="pb-n4">
                            <v-text-field
                                outlined
                                :label="this.$t('setupreport_exportmapping.mapcode')"
                                type="text"
                                ref="mapcode"
                                :rules="[rules.required]"
                                v-model="formData.mapcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="4" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupreport_exportmapping.mapdesc')"
                                type="text"
                                ref="mapdesc"
                                :rules="[rules.required]"
                                v-model="formData.mapdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupreport_exportmapping.exportformat')"
                                type="text"
                                ref= "expformatid"
                                :items="expformatList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.expformatid"
                                @change ="changeGroupHandler(formData.expformatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                   
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                               :label="this.$t('columns.code')"
                                id="expformatcode"
                                type="text"
                                ref="expformatcode" 
                                background-color="#ffeab2"
                                readonly
                                v-model="expformatcode" 
                                dense                       
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            Training Expenses
                        </v-col>
                    </v-row> -->
                    <br>

                    <v-row cols="1" sm="1" class="pb-n5 mt-n8">
                            <v-btn-toggle rounded group>
                            <v-btn
                                v-model="genderBtn"
                                @click="genderBtn = !genderBtn"
                                depressed
                                color="white"
                                class="blue--text"
                                @change="collapseHandler()"
                                >{{$t('columns.gender')}}</v-btn
                            >

                            <v-btn
                                v-model="nationalityBtn"
                                @click="nationalityBtn = !nationalityBtn"
                                depressed
                                color="white"
                                class="blue--text"
                                @change="collapseHandler2()"
                                >{{$t('recpersonalinfo.nationality')}}</v-btn
                            >

                            <v-btn
                                v-model="relationshipBtn"
                                @click="relationshipBtn = !relationshipBtn"
                                depressed
                                color="white"
                                class="blue--text"
                                @change="collapseHandler3()"
                                >{{$t('setupreport_exportmapping.relationship')}}</v-btn
                            >

                            <v-btn
                                v-model="maritalstatusBtn"
                                @click="maritalstatusBtn = !maritalstatusBtn"
                                depressed
                                color="white"
                                class="blue--text"
                                @change="collapseHandler4()"
                                >{{$t('recpersonalinfo.maritalstatus')}}</v-btn
                            >
        
                             <v-btn
                                v-model="bankBtn"
                                @click="bankBtn = !bankBtn"
                                depressed
                                color="white"
                                class="blue--text"
                                @change="collapseHandler5()"
                                >{{$t('setupcompanybankaccount.bank')}}</v-btn
                            >
                            </v-btn-toggle>
                     </v-row> 

                    <v-row v-if="genderBtn">
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeadergender,
                                detailItem: this.formData.reportexportmappingdetail,
                                maptypeid : this.formData.maptypeid
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>

                     <v-row v-if="nationalityBtn">
                        <app-table2
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeadernationality,
                                detailItem: this.formData.reportexportmappingdetail,
                                 maptypeid : this.formData.maptypeid
                            }"

                            v-on:input="addItemHandlernationality"
                        />
                    </v-row>

                     <v-row v-if="relationshipBtn">
                        <app-table3
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeaderrelationship,
                                detailItem: this.formData.reportexportmappingdetail,
                                 maptypeid : this.formData.maptypeid
                            }"

                            v-on:input="addItemHandlerrelationship"
                        />
                    </v-row>

                     <v-row v-if="maritalstatusBtn">
                        <app-table4
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeadermaritalstatus,
                                detailItem: this.formData.reportexportmappingdetail,
                                 maptypeid : this.formData.maptypeid
                            }"

                            v-on:input="addItemHandlermaritalstatus"
                        />
                    </v-row>

                     <v-row v-if="bankBtn">

                         <app-table5
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeaderbank,
                                detailItem: this.formData.reportexportmappingdetail,
                                 maptypeid : this.formData.maptypeid
                            }"

                            v-on:input="addItemHandlerbank"
                        />
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />
            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTableGender from './CRUDTableGender';
import CRUDTableNationality from './CRUDTableNationality';
import CRUDTableRelationship from './CRUDTableRelationship';
import CRUDTableMaritalStatus from './CRUDTableMaritalStatus';
import CRUDTableBank from './CRUDTableBank';
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTableGender,
        'app-table2': CRUDTableNationality,
        'app-table3': CRUDTableRelationship,
        'app-table4': CRUDTableMaritalStatus,
        'app-table5': CRUDTableBank,
        //'app-genericform': GenericForm,

    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                    }
            },

            formData: {
                mapcode:this.parentObject.data.mapcode,
                mapdesc:this.parentObject.data.mapdesc,
                expformatid:this.parentObject.data.expformatid,
                maptypeid: this.parentObject.data.maptypeid,
                reportexportmappingdetail: this.parentObject.data.reportexportmappingdetail,
                search: this.parentObject.data.search,
               // lockstaff: this.parentObject.data.lockstaff,
                stamp:this.parentObject.data.stamp,
                userid:this.parentObject.data.userid
            },

            expformatList:[],
            expformat: [],
            expformatcode: '',

            maptypes: [],

            singleSelect: false,
            dataItems: [],
        
            tableOpt: {
                itemsPerPage: -1
            },

            genderBtn: false,
            nationalityBtn: false,
            relationshipBtn:false,
            maritalstatusBtn: false,
            bankBtn: false,
           // maptypeid:'',

            hidetable: false,
            hidetable1: false,
            hidetable2:false,
            hidetable3: false,
            search: this.parentObject.searchnew,


            selected: [],
            affectItems: [],

            tabHeadergender: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('setupreport_exportmapping.maptypedesc'), value: 'maptypedesc', width: '200px'},
                { text: this.$t('setupreport_exportmapping.internalcode'),      value: 'intcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.internaldesc'),            value: 'intdesc',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externalcode'),          value: 'extcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externaldesc'),          value: 'extdesc',          width: '200px', sortable: true},       

                         
            ],

             tabHeadernationality: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('setupreport_exportmapping.maptypedesc'), value: 'maptypedesc', width: '200px'},
                { text: this.$t('setupreport_exportmapping.internalcode'),      value: 'intcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.internaldesc'),            value: 'intdesc',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externalcode'),          value: 'extcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externaldesc'),          value: 'extdesc',          width: '200px', sortable: true},                    
            ],

             tabHeaderrelationship: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('setupreport_exportmapping.maptypedesc'), value: 'maptypedesc', width: '200px'},
                { text: this.$t('setupreport_exportmapping.internalcode'),      value: 'intcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.internaldesc'),            value: 'intdesc',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externalcode'),          value: 'extcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externaldesc'),          value: 'extdesc',          width: '200px', sortable: true},                    
            ],

             tabHeadermaritalstatus: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('setupreport_exportmapping.maptypedesc'), value: 'maptypedesc', width: '200px'},
                { text: this.$t('setupreport_exportmapping.internalcode'),      value: 'intcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.internaldesc'),            value: 'intdesc',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externalcode'),          value: 'extcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externaldesc'),          value: 'extdesc',          width: '200px', sortable: true},                 
            ],

            tabHeaderbank: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('setupreport_exportmapping.maptypedesc'), value: 'maptypedesc', width: '200px'},
                { text: this.$t('setupreport_exportmapping.internalcode'),      value: 'intcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.internaldesc'),            value: 'intdesc',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externalcode'),          value: 'extcode',          width: '200px', sortable: true},
                { text: this.$t('setupreport_exportmapping.externaldesc'),          value: 'extdesc',          width: '200px', sortable: true},              
            ],

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit
        }
    },

    methods: {


          loadExportFormatList() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/exportformatinsurance/all'})
                .then(res => {
                    vm.expformat = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.expformatList.push(
                                {
                                    text: res.data.rec[i].expdesc,
                                    value: res.data.rec[i].id
                                }
                            )}
                    }

                    if (this.formData.expformatid) {
                        this.changeGroupHandler(this.formData.expformatid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeGroupHandler(id) {
            let item = this.expformat.find(item => item.id === id);

            this.expdesc = item.expdesc;
            this.expformatcode = item.expcode;
        },

        collapseHandler(){
             if(this.genderBtn === true)
             {
               this.formData.maptypeid = '1'
             } 
        },
             
        collapseHandler2(){  
            if(this.nationalityBtn === true) {
                    this.formData.maptypeid = '2'
             } 
        },
      
        collapseHandler3(){
            if (this.relationshipBtn === true){
                   this.formData.maptypeid = '3'
            }  
        },

        collapseHandler4(){
            if (this.maritalstatusBtn === true ){
                    this.formData.maptypeid = '4'
            }            
        },

         collapseHandler5(){
            if (this.bankBtn === true ){
                    this.formData.maptypeid = '5'
            }            
        },



        addItemHandler($event) {
            this.formData.reportexportmappingdetail=$event
            this.formData.maptypeid = '1'
    
            this.updateValue();
        },

        
        addItemHandlernationality($event) {
            this.formData.reportexportmappingdetail=$event
            this.formData.maptypeid = '2'
    
            this.updateValue();
        },

          addItemHandlerrelationship($event) {
            this.formData.reportexportmappingdetail=$event
            this.formData.maptypeid = '3'
    
            this.updateValue();
        },

        
        addItemHandlermaritalstatus($event) {
            this.formData.reportexportmappingdetail=$event
            this.formData.maptypeid = '4'
    
            this.updateValue();
        },


        addItemHandlerbank($event) {
            this.formData.reportexportmappingdetail=$event
            this.formData.maptypeid = '5'
    
            this.updateValue();
        },



        updateValue() {
          //  console.log('formdata', this.formData)
            this.$emit('input', {

                mapcode:this.formData.mapcode,
                mapdesc:this.formData.mapdesc,
                expformatid:this.formData.expformatid,
                maptypeid: this.formData.maptypeid,
                reportexportmappingdetail: this.formData.reportexportmappingdetail,
                 search: this.formData.search,
               // lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
             this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
             this.updateValue()
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'reportexportmappinglist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadExportFormatList();
       // this.loadMapTypeList();
        
    }
}
</script>