<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
 import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('ircasemaintenance.ircasemaintenancelist'),
      dataHeader: [

        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('ircasemaintenance.daterecorded'), value: "recorddate", width: "200px" },
        { text: this.$t('ircasemaintenance.incidentdate'), value: "incidentdate", width: "200px" },
        { text: this.$t('ircasemaintenance.caseid'), value: "caseid", width: "200px" },
        { text: this.$t('ircasemaintenance.incidentshortdesc'), value: "shortdesc", width: "200px" },
        { text: this.$t('ircasemaintenance.incidentlongdesc'), value: "longdesc", width: "200px" },
        { text: this.$t('ircasemaintenance.incidentcat'), value: "incidentcatdesc", width: "200px" },
        { text: this.$t('ircasemaintenance.reportedby'), value: "reportedbyname", width: "200px" },
        { text: this.$t('columns.remark'), value: "remarks", width: "200px" },
        { text: this.$t('ircasemaintenance.casestatus'), value: "casestatusdesc", width: "200px" },
        { text: this.$t('ircasemaintenance.statusremarks'), value: "statusremarks", width: "200px" },

       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
       search: this.$route.params.search,
    };
  },

  methods: {
    getAppraisalExerciseHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/ircasemaintenance/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
                  if (vm.dataItems[i].recorddate) {
                      vm.dataItems[i].recorddate = moment(vm.dataItems[i].recorddate).format('YYYY-MM-DD');
                    }

                  if (vm.dataItems[i].incidentdate) {
                      vm.dataItems[i].incidentdate = moment(vm.dataItems[i].incidentdate).format('YYYY-MM-DD');
                    }
            }


          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "ircasemaintenanceedit", params: { id: item.id, search: item.search  } });
    },

    newRecordHandler() {
      this.$router.push({ name: "ircasemaintenancenew" });
    },
  },
  mounted() {
    this.getAppraisalExerciseHandler();
  },
};
</script>