<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

        <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >                

                    <v-row>                                                

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupholidaysetup.holidaycode')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.holcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>    
                        </v-col>
                    </v-row>

                    <v-row>                                                

                        <v-col cols="6" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupholidaysetup.holidayname')"
                                type="text"
                                ref="holdesc"
                                :rules="[rules.required]"
                                v-model="formData.holdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupholidaysetup.holidaytype')"
                                type="text"
                                ref="holtype"
                                v-model="formData.holtype"
                                :rules="[rules.required]"
                                :items="['Gazetted','Non-Gazetted']"                                                               
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="repeatday"
                                v-model="formData.repeatday"
                                :label="this.$t('setupholidaysetup.samedateeveryyear')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col col="12" sm="6" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.holidaydetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>

                </v-container>
                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>


import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object,
    },

    components: {
        
        "app-table": CRUDTable,
    },

    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                },
            },

            formData: {
                id: this.parentObject.data.id,
                holcode: this.parentObject.data.holcode,
                holid: this.parentObject.data.holid,
                holdesc: this.parentObject.data.holdesc,               
                holtype: this.parentObject.data.holtype,
                holidaydetail: this.parentObject.data.holidaydetail,
                repeatday: this.parentObject.data.repeatday,
                search: this.parentObject.data.search,
                userid: this.parentObject.data.userid,
                stamp: this.parentObject.data.stamp
            },
            
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '5px', sortable: false},
                { text: this.$t('columns.date'), value: 'holdate', width: '100px', sortable: false},

            ],

            errMsg: '',
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew
        }

    },
    
    

    
    methods: {

        addItemHandler($event) {
            this.formData.holidaydetail =$event
            console.log(this.formData.holidaydetail)
        },

        getholidayList() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/holiday/all"})
                .then(res => {
                    vm.holiday = res.data.rec;
                    console.log("vm.holiday",vm.holiday)
                    
                    
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
               
        

        changeHolidayListHandler(id) {
            let item = this.holiday.find(item => item.id === id);

            this.holidayList = item.holtype;
        },

        
       

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                holcode: this.formData.holcode,
                holid: this.formData.holid,
                holdesc: this.formData.holdesc,               
                holtype: this.formData.holtype,
                holidaydetail: this.formData.holidaydetail,
                repeatday: this.formData.repeatday,
                search: this.formData.search,
                userid: this.formData.userid,
                stamp: this.formData.stamp
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            console.log(this.formData)
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'holidaylist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getholidayList();
        
    }

    
}
</script>