<template>
  <div>
    <app-input-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import Wizard from "../../../components/settings/wizard/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-input-form": Wizard,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupwizardsetup.newwizard'),
      pageTitleValue: "",
      formData: {
        wizardcode: null,
        wizarddesc: null,
        wizarddetail: [],
        disable: false,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      if (this.formData.disable == false || this.formData.disable == null) {
        this.formData.disable = 0;
      } else {
        this.formData.disable = 1;
      }
      this.$store.dispatch('post', {url: "/wizardsetup/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "wizardlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "wizardlist" });
    },
  },
};
</script>