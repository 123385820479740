<template>
    <div id="companygrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>


<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupleavetopayrollpostingsetup.leavetopayrollpostingsetuplist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false },
                { text: this.$t('generateunpaidleaverecord.leavetype'), value: 'ltleavedesc', width: '200px' },
                { text: this.$t('setuppayitem.payitem'), value: 'payitemdesc', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' },
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadLeavePayrollPosting() {
            const vm = this;

            this.$store.dispatch('get', {url: '/leavepayrollposting/all'})
            .then(res => {
                vm.dataItems = res.data.rec;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },
        
        editItemHandler(item) {
            this.$router.push({name: 'leavetopayrollpostingsetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'leavetopayrollpostingsetupnew'});
        }
    },

    mounted() {
        this.loadLeavePayrollPosting();
    }
}
</script>