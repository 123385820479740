<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{ this.pageTitle }}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <!-- <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                    outlined
                    :label="Recipient Reference"
                    type="text"
                    v-model="formData.recref"
                    :rules="[rules.required, rules.counter]"
                    maxlength='16'
                    dense
                ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="3" sm="3" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('allbank.organizationcode')"
                type="text"
                v-model="formData.organizationcode"
                maxlength="5"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('statutoryexportcommonused.organizationname')"
                type="text"
                v-model="formData.organizationname"
                maxlength="40"
                :rules="[rules.required, rules.counter1]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" sm="3" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.creditingdate')"
                type="date"
                v-model="formData.creditingdate"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('allbank.paymentdesc')"
                type="text"
                v-model="formData.paymentdesc"
                maxlength="40"
                :rules="[rules.counter3]"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

        </v-container>

        

        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="backHandler">
              {{$t('button.back')}}
            </v-btn>
          </div>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px" @click="exportHandler">
              {{$t('button.export')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import moment from "moment";

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        counter2: (value) => value.length < 5 || this.$t('reportsparam.counter5'),
        counter1: (value) => value.length < 40 || this.$t('reportsparam.counter40'),
        counter3: (value) => value.length < 20 || this.$t('reportsparam.counter20'),
      },
      pageTitle: 'CIMB',

      parameter: this.parentObject.parameter,
      id: this.parentObject.id,
      data: this.parentObject.data,
      formData: {
        recordtypeheader: "01",                     
        organizationcode: "",
        organizationname: "",
        creditingdate: "",
        paymentdesc: "",
        securitycode: "0000000000000000",
        filler: "",
      },
      fileHolder: null,
      file: null,

      paymentModeList: ['FT', 'IBG'],
      bank: [
        {
          id: 4,
          bnmcode: 12,
          bankcode: "ALBB",
          bankdesc: "ALLIANCE BANK",
        },
        {
          id: 1,
          bnmcode: 32,
          bankcode: "ABB",
          bankdesc: "AFFIN BANK BERHAD",
        },
        {
          id: 2,
          bnmcode: 49,
          bankcode: "AGRO",
          bankdesc: "AGRO BANK BERHAD",
        },
        {
          id: 3,
          bnmcode: 53,
          bankcode: "ALRAJHI",
          bankdesc: "AL-RAJHI BANKING CORPORATION (M) BERHAD",
        },
        {
          id: 5,
          bnmcode: "08",
          bankcode: "AMBB",
          bankdesc: "AMBANk(M)BERHAD",
        },
        {
          id: 6,
          bnmcode: "04",
          bankcode: "BBB",
          bankdesc: "BANGKOK BANK BERHAD",
        },
        {
          id: 16,
          bnmcode: 35,
          bankcode: "CIMB",
          bankdesc: "CIMB BANK BERHAD",
        },
        {
          id: 7,
          bnmcode: 45,
          bankcode: "BIMB",
          bankdesc: "BANK ISLAM MALAYSIA BERHAD",
        },
        {
          id: 12,
          bnmcode: "02",
          bankcode: "BRB",
          bankdesc: "BANK RAKYAT BERHAD",
        },
        {
          id: 8,
          bnmcode: 41,
          bankcode: "BMMB",
          bankdesc: "BANK MUAMALAT MALAYSIA BERHAD",
        },
        {
          id: 14,
          bnmcode: 60,
          bankcode: "BNP",
          bankdesc: "BNP PARIBAS MALAYSIA BERHAD",
        },
        {
          id: 9,
          bnmcode: "07",
          bankcode: "BOFA",
          bankdesc: "BANK OF AMERICA MALAYSIA BERHAD",
        },
        {
          id: 10,
          bnmcode: 42,
          bankcode: "BOCB",
          bankdesc: "BANK OF CHINA",
        },
        {
          id: 13,
          bnmcode: 10,
          bankcode: "BSN",
          bankdesc: "BANK SIMPANAN NASIONAL",
        },
        {
          id: 15,
          bnmcode: 65,
          bankcode: "CCBM",
          bankdesc: "CHINA CONSTRUCTION BANK (MALAYSIA) BERHAD",
        },
        {
          id: 17,
          bnmcode: 17,
          bankcode: "CBB",
          bankdesc: "CITIBANK",
        },
        {
          id: 18,
          bnmcode: 19,
          bankcode: "DB",
          bankdesc: "DEUTSCHE BANK MSIA BHD",
        },
        {
          id: 20,
          bnmcode: 24,
          bankcode: "HLBB",
          bankdesc: "HONG LEONG BANK BERHAD/ HONG LEONG FINANCE",
        },
        {
          id: 21,
          bnmcode: 22,
          bankcode: "HSBC",
          bankdesc: "HSBC BANK BERHAD",
        },
        {
          id: 23,
          bnmcode: 59,
          bankcode: "ICBK",
          bankdesc: "INDUST & COMM BANK OF CHINA (M) BERHAD",
        },
        {
          id: 24,
          bnmcode: 48,
          bankcode: "JPMCBB",
          bankdesc: "JP MORGAN CHASE BANK BHD",
        },
        {
          id: 25,
          bnmcode: 47,
          bankcode: "KFH",
          bankdesc: "KUWAIT FINANCE HOUSE",
        },
        {
          id: 26,
          bnmcode: 27,
          bankcode: "MBB",
          bankdesc: "MALAYAN BANKING BERHAD",
        },
        {
          id: 27,
          bnmcode: 73,
          bankcode: "MIZUHO",
          bankdesc: "MIZUHO BANK (M) BERHAD",
        },
        {
          id: 29,
          bnmcode: 29,
          bankcode: "OCBC",
          bankdesc: "OCBC BANK BERHAD",
        },
        {
          id: 30,
          bnmcode: 33,
          bankcode: "PBB",
          bankdesc: "PUBLIC BANK BERHAD/ PUBLIC FINANCE BERHAD",
        },
        {
          id: 31,
          bnmcode: 18,
          bankcode: "RHB",
          bankdesc: "RHB BANK BERHAD",
        },
        {
          id: 32,
          bnmcode: 14,
          bankcode: "SCB",
          bankdesc: "STANDARD CHARTERED BANK BERHAD",
        },
        {
          id: 33,
          bnmcode: 51,
          bankcode: "SMBC",
          bankdesc: "SUMITOMO MITSUI BANKING CORPORATION (M) BERHAD",
        },
        {
          id: 36,
          bnmcode: 26,
          bankcode: "UOB",
          bankdesc: "UNITED OVERSEAS BANK (M) BHD",
        },
        {
          id: 11,
          bnmcode: 52,
          bankcode: "TMB",
          bankdesc: "BANK OF TOKYO-MITSUBISHI UFJ (MALAYSIA) BERHAD",
        },
        {
          id: 35,
          bnmcode: 46,
          bankcode: "RBSB",
          bankdesc: "THE ROYAL BANK OF SCOTLAND BHD",
        },
        {
          id: 37,
          bnmcode: 75,
          bankcode: "MBSB",
          bankdesc: "MBSB",
        },

      ],

      staffilterlist: [],
      staffaccount: [],
      companyaccount: [],
      psnpayroll: [],
      titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
      payrolldata: [],

      companyrecnum: "",
      paymentmode: null,
      bankcode: null,
      bankid: null,
      receivingbank: "",
      paymentref: "",
      paymentdesc: "",
      addr: "",
      filler: "",

      accErr: [],
      negErrMsg: [],
      salhdErr: [],
      gettotalAmt: [],

      currency: null,
      processedCount: null,
      successCount: null,
      errCount: null,
      exCount: null,
      errMsg: "",
      isErr: false,
      isEdit: this.parentObject.isEdit,
    };
  },

  methods: {

    getAmbankExportListHandler() {
      const vm = this
      
     this.$store.dispatch('get', {url: `/ambanksalexport/all/${this.$store.getters.userId}`})
      .then(res => {
        let dateHolder = vm.data.year + "-" + 
                         vm.padRJ(vm.data.month, 2) + "-" + 
                         vm.daysInMonth(vm.data.month, vm.data.year);
          
        let date = moment(dateHolder).format("YYYY-MM-DD");
        let payrolldataholder = res.data.rec;
        payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)

        payrolldataholder.forEach(function (o) {
          var existing = vm.payrolldata.filter(function (i) {
            return i.staffid === o.staffid;
          })[0];
          if (!existing || existing == undefined) {
            if (o.bankid) {
              if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                    vm.payrolldata.push(o);
              }
            } else {
              vm.payrolldata.push(o);
            }
          }
        });
        this.loadStaffFilter()
      })
      .catch(function (err) {
        vm.errMsg = err;
      });
    },

    loadStaffFilter() {
      const vm = this;

      this.$store.dispatch('post', {
          url:  `/stafffilter/${this.$store.getters.userId}`, 
          payload: this.parameter})
        .then(res => {
            vm.staffilterlist = res.data.rec;
            let checkval = false;
            for (const key in this.parameter) {
              if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
            }

            if (checkval) {
              vm.payrolldata = vm.payrolldata.filter((el) => {
                  return vm.staffilterlist.some((f) => {
                      return f.staffid === el.staffid;
                  });
              });
            }
            
        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    async getData() {
      const vm = this
      let body = []
      let holdpayment = []
      let bankaccount = {}
      let dataholder = vm.payrolldata
      let recordtypebody = '02'
      let transactiontype = '2'
    //  let paymentdesc = ''

      let payrolldetail = this.filter(dataholder);

      this.processedCount = dataholder.length;
      this.successCount = payrolldetail.length;
      this.errCount = dataholder.length - payrolldetail.length;
      this.currency = payrolldetail[0].exchratecode;

      payrolldetail.forEach(element => {
        let validationvalue = ''
        bankaccount = vm.bank.find(e => e.id == element.bankid)

        if (element.iddetails != null) validationvalue = element.iddetails.split("-").join("")
        let staffbankacc = element.staffaccountno.split("-").join("")
        let psnnameHolder = element.accountname.substring(0,40)

        let netpayholder = element.netpay.toFixed(2);
        netpayholder = netpayholder.split(".").join("");
        if (bankaccount.id == 16) bankaccount.bankcode = 'CIMB'

        body.push({
          "Record Type": this.padRJ(recordtypebody, 2),
          "BNM Code": this.padLJ(bankaccount.bnmcode, 7),
          "Account Number": this.padSpaceLJ(staffbankacc, 16),
          "Beneficiary Name": this.padSpaceLJ(psnnameHolder, 40),
          "Payment Amount": this.padRJ(netpayholder, 11),
          "Reference Number": this.padSpaceLJ(element.staffcode, 30),
          "Beneficiary ID": this.padSpaceLJ(validationvalue, 20),
          "Transaction Type": this.padSpaceRJ(transactiontype,1),
          "Payment Description": this.padSpaceLJ(this.formData.paymentdesc, 20)
        });

        holdpayment.push(element.netpay)
       // rec++
      });

      if (holdpayment) {
        let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
        this.gettotalAmt = totalAmt.toFixed(2);
      }


      let getDate = "";
      let totalAmtHolder = this.gettotalAmt.split(".").join("")
      let recordtypefooter = '03'
      let recnum = payrolldetail.length

      if (this.formData.creditingdate) {
        getDate = moment(this.formData.creditingdate).format("DDMMYYYY");
      }

      let header =
        this.padRJ(this.formData.recordtypeheader, 2) +
        this.padRJ(this.formData.organizationcode, 5) +
        this.padSpaceLJ(this.formData.organizationname, 40) +
        this.padSpaceLJ(getDate, 8) +
        this.padRJ(this.formData.securitycode, 16) +
        this.padSpaceLJ(this.formData.filler, 2);


       let footer = 
         this.padRJ(recordtypefooter, 2) +
         this.padRJ(recnum, 6) +
         this.padRJ(totalAmtHolder, 13)
        
      if (body.length > 0) {
        const txtData = this.objectToTxt(header, body, footer);
        this.download(txtData, this.titleData);
      }

      console.log('length', payrolldetail)
    },

    filter(item) {
      let checkacc = this.accfilter(item)
      let checkaccbank = this.accBankfilter(checkacc)
      let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
      let checkactive = this.activefilter(checkapaymentmethod)
      let checkpayment = this.paymentfilter(checkactive)
      return checkpayment
    },

    accfilter(item) {
      let data = item.filter(e => e.bankid)
      console.log('bankid', data)
     // console.log('bank', bankid)
      let noacc = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noacc.length; i++) {
        noacc[i].errormsg = this.$t('allbank.noaccfound');
        this.accErr.push(noacc[i]);
      }
      return data
    },

    accBankfilter(item) {
      let data = item.filter(e => {
        for (let i = 0; i < this.bank.length; i++) {
          if (this.bank[i].id == e.bankid) return e
        }
      })
      let noaccbank = item.filter((e) => !data.includes(e));
      for (let i = 0; i < noaccbank.length; i++) {
        noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
        this.accErr.push(noaccbank[i]);
      }
      return data
    },

    paymentmethodfilter(item) {
      let data = item.filter(e => e.paymodecode == 'BANK')
      let diffpayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < diffpayment.length; i++) {
        diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
        this.accErr.push(diffpayment[i]);
      }
      return data
    },

    activefilter(item) {
      let data = item.filter(e => e.pystatcode == 'ACTIVE')
      let notactive = item.filter((e) => !data.includes(e));
      for (let i = 0; i < notactive.length; i++) {
        notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
        this.accErr.push(notactive[i]);
      }
      return data
    },

    paymentfilter(item) {
      let data = item.filter(e => e.netpay > 0)
      let nopayment = item.filter((e) => !data.includes(e));
      for (let i = 0; i < nopayment.length; i++) {
        nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
        this.accErr.push(nopayment[i]);
      }
      return data
    },


   objectToTxt(header, data, footer) {
      const csvRows = [];

      const headers = Object.keys(data[0]);
      const getHeader = header;
      csvRows.push(getHeader);

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `${escaped}`;
        });
        csvRows.push(values.join(""));
      }

      csvRows.push(footer);
      return csvRows.join("\n");
    },

    download(data, title) {
      // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", title + ".txt");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    padLJ(num, size) {
      var s = num + "";
      while (s.length < size) s = s + "0";
      return s;
    },

    padSpaceRJ(char, size) {
      var s = char + "";
      while (s.length < size) s = " " + s;
      return s;
    },

    padSpaceLJ(char, size) {
      var s = char + "";
      while (s.length < size) s = s + " ";
      return s;
    },

    exportHandler() {
      if (!this.$refs.form.validate()) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.someinfoismissing');
      } else {
        this.getData();
        let item = [];
        item.totalAmt = this.gettotalAmt;
        item.accounterr = this.accErr;
        item.negErrMss = this.negErrMsg;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
        item.currency = this.currency;
        this.$router.push({ name: "salaryexportlog", params: { item } });
      }
    },

    backHandler() {
      let param = this.parameter

      this.$router.push({ name: "salaryexport", params: { param } });
    },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },
  },

  mounted() {
    this.getAmbankExportListHandler();
  },
};
</script>