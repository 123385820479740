<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >



                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 ">
                           <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 ">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.statutoryDetail
                            }"

                            v-on:input="addItemHandler"
                        />

                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';

import CRUDTable from './CRUDTable';


export default {
    props: {
        parentObject: Object,
        value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    }
    },

    components: {
        'app-table': CRUDTable
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),

                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
               
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                statutoryDetail: this.parentObject.data.statutoryDetail,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            
            //app-table headers 
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setuppayitem.payitem'), value: 'payitemcode', width: '150px', sortable: true},
                { text: this.$t('setupbaitulmal.ea'), value:  'eafield_id', width: '80px', sortable: true},
                { text: this.$t('setupbaitulmal.tp1'), value: 'tp1field_id', width: '80px', sortable: true},
                { text: this.$t('setupbaitulmal.cp22'), value: 'cp22field_id', width: '90px', sortable: true},
                { text: this.$t('setupbaitulmal.cp21'), value: 'cp21field_id', width: '90px', sortable: true},
                { text: this.$t('setupbaitulmal.payslip'), value: 'payslip', width: '100px', sortable: true},
                { text: this.$t('setupclaim.payitemdescription'), value: 'payitemdesc', width: '200px', sortable: true},
                { text: this.$t('setupbaitulmal.payitemtypedesc'), value: 'payitemtypedesc', width: '250px', sortable: true},
            
            ],

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew

           
        }
    },

    
    methods: {
        
        addItemHandler($event) {
            this.formData.statutoryDetail=$event

            this.updateValue();
        },  

        updateValue() {

            this.$emit('input', {
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                statutoryDetail: this.formData.statutoryDetail,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },


        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        
        cancelHandler() {
            this.$router.push({name: 'statutoryreportsetuplist', params: { search: this.search}});
        },
    },

    mounted() {
      
    }
}
</script>