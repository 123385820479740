<template>
    <div>
        <!-- <app-form
            v-bind:parentObject="{
                data: this.formData,
                errMsg: this.errMsg,
            }"
            v-on:input="formData = $event"
        /> -->
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="3" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('reportsparam.asatdate')"
                                    type="date"
                                    ref="asatdate"
                                    :rules="[rules.required]"
                                    v-model="formData.asatdate"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.name')"
                                    type="text"
                                    ref="id"
                                    hide-selected
                                    v-model="formData.id"
                                    dense
                                    :items="codeList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                <!-- <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                /> -->

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                    >
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog"

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-error': ErrorDialog,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                asatdate: null,
                id: null,
            },

            codeList: [],
            errMsg: "",
            isErr: false
        }
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadPersonDetails(){
            const vm = this;

            this.$store.dispatch('get', {url: '/personandstaff/all'})
                .then(res => {
                    vm.ids = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++){
                        vm.codeList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    if (vm.formData.id){
                        vm.changePersonDetailsHandler(vm.formData.id);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                })
        },

        changePersonDetailsHandler(id){
            this.ids.find(item => item.id === id);
        },

        goHandler(){
            const formData = this.formData

            if((this.formData.asatdate != null && this.formData.id != null)){
                this.$router.push({
                    name: 'detailspersonandstaff', params: { formData }
                })
            }  else {

                this.$refs.form.validate()           
            }
        },

        updateValue(){
            this.$emit("input", {
                id: this.formData.id
            })
        },
    },

    mounted() {
        this.loadPersonDetails();
    },
    
}
</script>