<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
         searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import groupForm from "../../../components/settings/group/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": groupForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
       search: this.$route.params.search,
      pageTitle: this.$t('setupgrp.editgrp'),
      pageTitleValue: "",
      formData: {
        gtypeid: null,
        isDisable: null,
        valvalues: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      deletedItems: [],
      insertedItems: [],
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

     createHandler() {
      this.$router.push({ name: "groupnew" });
    },

    loadGroup() {
      const vm = this;

      this.$store.dispatch('get', {url: "/group/" + this.id})
        .then((res) => {
          vm.formData.gtypeid = res.data.rec[0].gtypeid;
          if (res.data.rec[0].isDisable == "Y") {
            vm.formData.isDisable = true;
          } else {
            vm.formData.isDisable = false;
          }

          vm.formData.stamp = res.data.rec[0].stamp;

          vm.pageTitleValue = res.data.rec[0].gtypedesc;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadGroupDetail() {
      const vm = this;
      this.$store.dispatch('get', {url: "/groupb/" + this.id})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            this.formData.valvalues.push({
              id: res.data.rec[i].id,
              code: res.data.rec[i].valuecode,
              desc: res.data.rec[i].valuedesc,
              action: 0,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/group/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grouplist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/group/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grouplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    addItemHandler($event) {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      const payload = {
        affecttypeid: this.id,
        affectitemid: $event.id,
        userid: this.formData.userid,
      };

      this.$store.dispatch('post', {url: "/groupb/create", payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteItemHandler($event) {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/groupb/" +
            $event +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "grouplist" });
    },
  },

  mounted() {
    this.loadGroup();
    this.loadGroupDetail();
  },
};
</script>