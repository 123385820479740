<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.daterecorded')"
                                type="date"
                                ref="recorddate"
                                :rules="[rules.required]"
                                v-model="formData.recorddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentdate')"
                                type="date"
                                ref="incidentdate"
                                :rules="[rules.required]"
                                v-model="formData.incidentdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                     </v-row>
                   
                   <v-row>
                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.caseid')"
                                type="text"
                                ref="caseid"
                                :rules="[rules.required]"
                                v-model="formData.caseid"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentshortdesc')"
                                type="text"
                                ref="shortdesc"
                                :rules="[rules.required]"
                                v-model="formData.shortdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.incidentlongdesc')"
                                type="text"
                                ref="longdesc"
                                v-model="formData.longdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('ircasemaintenance.incidentcat')"
                                type="text"
                                ref="incidentcatid"
                                :rules="[rules.required]"
                                :items="incidentcatList"
                                hide-selected
                                v-model="formData.incidentcatid"
                                @input="updateValue"
                                @change="changeIncidentCatHandler(formData.incidentcatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="irincidentcatcode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="incidentcatDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: incidentcatItems,
                                            formTitle: `${this.$t('ircasemaintenance.incidentcat')}`,
                                            formId: 'incidentcat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveIncidentCat="saveIncidentCatHandler"
                                        v-on:deleteIncidentCat="deleteIncidentCatHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="incidentcatDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row >
                        <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                            <v-autocomplete
                            outlined
                            :label="this.$t('columns.name')"
                            type="text"
                            ref="psnid"
                            :rules="[rules.required]"
                            v-model="formData.reportedbyid"
                            dense
                            :items="staffDetailsList"
                            @input="updateValue"
                            @change="changeStaffIDandCompanyHandler(formData.reportedbyid)"
                            clearable
                            ></v-autocomplete>
                        </v-col>
                        
                        <!-- <v-col cols="4" sm="4" class="pb-n5 mt-n6">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col> -->
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row>
                      <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remarks"
                                v-model="formData.remarks"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row >
                        <v-col cols="8" sm="3" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('ircasemaintenance.casestatus')"
                                    type="text"
                                    ref="casestatusid"
                                    :rules="[rules.required]"
                                    v-model="formData.casestatusid"
                                    dense
                                    :items="casestatusList"
                                    @input="updateValue"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                    </v-row>

                     <v-row>
                      <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                       <v-text-field
                                outlined
                                :label="this.$t('ircasemaintenance.statusremarks')"
                                type="text"
                                ref="statusremarks"
                                v-model="formData.statusremarks"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                <v-row>
                    <v-col cols="4" sm="8" class="pb-n5 mt-n8">
                   
                        <v-file-input
                        ref="attach"
                        :label="this.$t('claimrecordlisting.browseforattachment')"
                        outlined
                        v-model="fileHolder"
                        @change="fileToBase64"
                        @input="updateValue" 
                        dense
                        multiple
                        ></v-file-input>
                    </v-col>
                </v-row>

                <div v-if="this.formData.attach">
                  <v-row v-if="!this.formData.lockstaff">
                     
                    <v-col cols="4" sm="6" class="pb-8 mt-n8">
                         <tr v-for="item in this.attachnew" :key="item.id">
                        
                        <p>
                        <a
                            v-bind:href="item.attach"
                            v-bind:download="item.attachname"
                            
                            >{{item.attachname}}</a
                        >
                        <v-icon
                            small
                            class="ml-3 mr-2"
                            @click="deleteAttachmentHandlerNew(item.id)"
                        >
                            mdi-delete
                        </v-icon>
                        </p>
                         </tr>
                    </v-col>
                    </v-row>

                    <v-row v-else>
                    <v-col cols="4" sm="6" class="pb-8 mt-n5">
                        <tr v-for="item in this.formData.documents" :key="item.id">
                        
                        <p>
                        <a
                            v-bind:href="item.attach"
                            v-bind:download="item.attach"
                            
                            >{{item.attachname}}</a
                        >
                        <v-icon
                            small
                            class="ml-3 mr-2"
                            @click="deleteAttachmentHandler(item.id)"
                        >
                            mdi-delete
                        </v-icon>
                        </p>
                        </tr>
                    </v-col>
                    </v-row>

                   
                        
                </div>

                      <v-row>

                      <v-col cols="4" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                                :label="this.$t('ircasemaintenance.documentdesc')"
                                type="text"
                                ref="attachdesc"
                                v-model="formData.attachdesc"
                                @input="updateValue"
                                dense
                        ></v-text-field>
                    </v-col>
                    </v-row>              
                    
                     </v-container> 

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.editMode

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';
import GenericForm from '../../generic/GridForm';
//import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
       'app-genericform': GenericForm,
        //'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail')
                }
            },
            formData: {
                id: this.parentObject.data.id,
                recorddate: this.parentObject.data.recorddate,
                incidentdate: this.parentObject.data.incidentdate,
                caseid: this.parentObject.data.caseid,
                shortdesc: this.parentObject.data.shortdesc,
                longdesc: this.parentObject.data.longdesc,
                incidentcatid: this.parentObject.data.incidentcatid,
                reportedbyid: this.parentObject.data.reportedbyid,
                compnyname: this.parentObject.data.compnyname,
                remarks: this.parentObject.data.remarks,
                casestatusid: this.parentObject.data.casestatusid,
                statusremarks: this.parentObject.data.statusremarks,
                attach: this.parentObject.data.attach,
                attachdesc: this.parentObject.data.attachdesc,
                attachname: this.parentObject.data.attachname,
                docid: this.parentObject.data.docid,
                docdid: this.parentObject.data.docdid,
                documents: this.parentObject.data.documents,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            irincidentcatcode: '',
            incidentcatList: [],
            incidentcats: [],
            incidentcatItems: [],
            incidentcatDialog: false,
            incidentcatLoading: true,

            staffdetails: [],
            staffDetailsList: [],

            casestatusItems: [],
            casestatusList: [],

            fileHolder: [],
            filename: [],
            attachnew: [],
            uploadDocuments : null,

      
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.editMode,
            search: this.parentObject.searchnew,

        }
    },

    methods: {

    
    download() {
    //  const blob = new Blob([], { type: "text/csv;charset=utf-8;" });
    //  const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      document.body.appendChild(a);

        for( var n = 0; n < this.formData.attach.length; n++ )
    {
        var download = this.formData.attach[n];
        a.setAttribute( 'href', download.path );
        a.setAttribute( 'download', download.name );

        a.click();
    }

      document.body.removeChild(a);
    },

        async fileToBase64() {
       
          if (this.fileHolder) {
                var file = this.$refs.attach.internalValue;

             for (let i = 0; i < file.length; i++){
               const filePromises = file.map((file) => {
                console.log('fileee', file)
                return new Promise((resolve, reject) => {
                const reader = new FileReader();
                //console.log('reader', reader)
                reader.onload = async () => {
                
                try {

                    const response = await this.formData.attach.push(
                            reader.result,
                        );
                        // Resolve the promise with the response value
                        resolve(response);
                        console.log('response', response)
                        this.formData.attachname.push(file.name);
                        console.log('filename', this.formData.attachname)
                                             
                        } catch (err) {
                        reject(err);
                        }


                try {

                    const response = await this.formData.documents.push(
                            reader.result,
                        );
                        // Resolve the promise with the response value
                        resolve(response);
                        console.log('response', response)
                        this.formData.attachname.push(file.name);
                        console.log('filename', this.formData.attachname)
                                             
                        } catch (err) {
                        reject(err);
                        }
                };
                
                 reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                    });
             });
              
              const fileInfos = await Promise.all(filePromises);
                this.updateValue();
            
            for (let j = 0; j < this.formData.attach.length; j++){
                    this.attachnew.push({
                        attach: this.formData.attach[j],
                        attachname: this.formData.attachname[j]
                    })
                }

                //   for (let k = 0; k < this.formData.attach.length; k++){
                //     this.formData.documents.push({
                //         attach: this.formData.attach[k],
                //         attachname: this.formData.attachname[k]
                //     })
                // }

                // Profit
                return fileInfos;
             }
         }


           
       },

    deleteAttachmentHandler(id) {
         const vm = this;
         console.log('id',id)
        
        // console.log('itemindex', itemIndex)
        //  console.log(this.formData.attach)

      //  if (this.parentObject.editMode){

            if (confirm(this.$t('ircasemaintenance.removemessage'))) {
            //  for (let k = 0; k<this.formData.attach.length; k++){
            //       this.formData.attach[k] = "";
            //       console.log('attach1111', this,this.formData.attach[k])
            //      // console.log('attachname1`1111', this,this.formData.attachname[k])
            //   }

            for (let i = 0; i < this.formData.documents.length; i++){
            this.$store.dispatch('delete', {url: "/irdocumentdelete/" + id + "/" + this.formData.docid + "/" +
            this.formData.userid})
             .then((res) => {
                if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                } 

           
            })
             .catch((err) => {
                 vm.isErr = !vm.isErr;
                  vm.errMsg = err;
             });
            }             
                
            let itemIndex = this.formData.documents.indexOf(id);
            this.formData.documents.splice(itemIndex, 1);
            this.formData.attach.splice(itemIndex, 1);
           
        } 
        // else {
        //    let itemIndex2 = this.attachnew.indexOf(item);
        //    this.attachnew.splice(itemIndex2, 1);

        //     for (let k = 0; k<this.attachnew.length; k++){
        //          this.formData.attach[k] = "";
        //          this.formData.attachname[k] = "";
        //      }
  
        //     //this.filename = "";
       //  }

            console.log('attach1', this.formData.attach)
        },

         deleteAttachmentHandlerNew(id) {
        // const vm = this;
         
           let itemIndex2 = this.attachnew.indexOf(id);
           this.attachnew.splice(itemIndex2, 1);
           this.formData.attach.splice(itemIndex2, 1)

            console.log('attachnew', this.formData.attach)
            for (let k = 0; k<this.formData.attach.length; k++){
                 this.formData.attach[k] = "";
                 this.formData.attachname[k] = "";
                 //this.formData.docid[k] = "";
             }
  

            console.log('attach1', this.formData.attach)
        },


        changeCaseStatus() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/ircasestatus/all'
            })
            .then(res => {

                vm.casestatusItems = res.data.rec;
                console.log('casestatusitems', vm.casestatusItems)

                for (let i = 0; i < vm.casestatusItems.length; i++) {
                    vm.casestatusList.push({
                        text: vm.casestatusItems[i].casestatusdesc,
                        value: vm.casestatusItems[i].id,
                    });
                }

                // if (this.formData.casestatusid) {
                // this.changeCaseStatusHandler(this.formData.casestatusid);
                // }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        // changeCaseStatusHandler(id) {
        //     var item = this.casestatusItems.find(item => item.id === id);
        //     this.formData.casestatusid = item.casestatusid
        //     //this.clmtypedesc = item.clmtypedesc;
        // },

         //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/staff/alls"
            })
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.reportedbyid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyname = item.compnyname;
            // this.formData.compnyid = item.compnyid;
            // this.formData.compnyname = item.compnyname;

            this.updateValue();
        },

        
        // loading the content of currency from the database, providing the name of the currency on the dropdown box
        loadIncidentCatListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/incidentcat/all'})
                .then(res => {
                    vm.incidentcats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.incidentcatList.push(
                            {
                                text: res.data.rec[i].irincidentcatdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.incidentcatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].irincidentcatcode,
                                desc: res.data.rec[i].irincidentcatdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.incidentcatLoading = false;

                    if (vm.formData.incidentcatid) {
                        vm.changeIncidentCatHandler(vm.formData.incidentcatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeIncidentCatHandler(id) {
            let item = this.incidentcats.find(item => item.id === id);

            this.irincidentcatcode = item.irincidentcatcode;
        },

        saveIncidentCatHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/incidentcat/' + this.itemData.itemid, 
                    payload: {
                        irincidentcatcode: this.itemData.itemcode,
                        irincidentcatdesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.incidentcats = [];
                        vm.incidentcatItems = [];
                        vm.incidentcatList = [];

                        this.loadIncidentCatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/incidentcat/create',  
                    payload: {
                        irincidentcatcode: this.itemData.itemcode,
                        irincidentcatdesc: this.itemData.itemdesc,
                        userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.incidentcats = [];
                        vm.incidentcatItems = [];
                        vm.incidentcatList = [];

                        this.loadIncidentCatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteIncidentCatHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/incidentcat/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.incidentcats = [];
                        vm.incidentcatList = [];
                        vm.incidentcatItems = [];

                        this.loadIncidentCatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genIncidentCatTabItems() {
            for (let i = 0; i < this.incidentcats.length; i++) {
                this.incidentcatItems.push(
                    {
                        id: this.incidentcats[i].id,
                        code: this.incidentcats[i].irincidentcatcode,
                        desc: this.incidentcats[i].irincidentcatdesc,
                        stamp: this.incidentcats[i].stamp
                    }
                )
            }
        },

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                recorddate: this.formData.recorddate,
                incidentdate: this.formData.incidentdate,
                caseid: this.formData.caseid,
                shortdesc: this.formData.shortdesc,
                longdesc: this.formData.longdesc,
                incidentcatid: this.formData.incidentcatid,
                reportedbyid: this.formData.reportedbyid,
                compnyname: this.formData.compnyname,
                remarks: this.formData.remarks,
                casestatusid: this.formData.casestatusid,
                statusremarks: this.formData.statusremarks,
                attach: this.formData.attach,
                attachdesc: this.formData.attachdesc,
                attachname: this.formData.attachname,
                docid: this.formData.docid,
                docdid: this.formData.docdid,
                 lockstaff:this.formData.lockstaff,
                 documents: this.formData.documents,
                //ircasemaintenancedetail: this.formData.ircasemaintenancedetail,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

           // console.log('docdid', this.formData.attach.id)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'ircasemaintenancelist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadIncidentCatListHandler();
        this.loadStaffDetailsListHandler();
        this.changeCaseStatus();
        console.log('formdatamounted', this.formData)
       
       if (this.parentObject.editMode){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }

    }
    
}
</script>