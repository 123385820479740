<template>
    <div>
        <v-container>
            <form enctype="multipart/form-data">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="8" sm="8"  class="mt-n4 pt-6">

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.personid')"
                                        id="psncode"
                                        type="text"
                                        ref="psncode"
                                        :rules="[rules.required]"
                                        v-model="formData.psncode"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="11" sm="11" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.name')"
                                        id="psnname"
                                        type="text"
                                        ref="psnname"
                                        :rules="[rules.required]"
                                        v-model="formData.psnname"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="11" sm="11" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.name_2')"
                                        id="psnname2"
                                        type="text"
                                        ref="psnname2"
                                        v-model="formData.psnname2"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.firstname')"
                                        id="fname"
                                        type="text"
                                        ref="fname"
                                        v-model="formData.fname"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="3" sm="3" class="pl-n10 ml-n5 pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.middlename')"
                                        id="mname"
                                        type="text"
                                        ref="mname"
                                        v-model="formData.mname"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="3" sm="3" class="pb-n4 ml-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.lastname')"
                                        id="lname"
                                        type="text"
                                        ref="lname"
                                        v-model="formData.lname"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.salutation')"
                                        type="text"
                                        ref="salutid"
                                        :items="salutationList"
                                        hide-selected
                                        v-model="formData.salutid"
                                        @input="updateValue"
                                        @change="changeSalutationHandler(formData.salutid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="salutationCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="salutationDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: salutationItems,
                                                    formTitle: `${this.$t('recpersonalinfo.salutation')}`,
                                                    formId: 'salutation'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveSalutation="saveSalutationHandler"
                                                v-on:deleteSalutation="deleteSalutationHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="salutationDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">                                    
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.gender')"
                                        type="text"
                                        ref="genderid"
                                        :items="genderList"
                                        hide-selected
                                        v-model="formData.genderid"
                                        @input="updateValue"
                                        @change="changeGenderHandler(formData.genderid)"
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="genderCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="genderDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: genderItems,
                                                    formTitle: `${this.$t('columns.gender')}`,
                                                    formId: 'gender'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveGender="saveGenderHandler"
                                                v-on:deleteGender="deleteGenderHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="genderDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.dob')"
                                        type="date"
                                        ref="dob"
                                        v-model="formData.dob"
                                        :rules="[rules.required]"
                                        @change="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        readonly
                                        :label="this.$t('recpersonalinfo.age_year')"
                                        type="number"
                                        ref="agey"
                                        background-color="#ffeab2"
                                        v-model="ageYear"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        readonly
                                        :label="this.$t('recpersonalinfo.age_month')"
                                        id="agem"
                                        type="number"
                                        ref="agem"
                                        background-color="#ffeab2"
                                        v-model="ageMonth"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.idno')"
                                        type="text"
                                        ref="idno"
                                        v-model="formData.idno"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.oldidno')"
                                        id="oldidno"
                                        type="text"
                                        ref="oldidno"
                                        v-model="formData.oldidno"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="3" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.maritalstatus')"
                                        type="text"
                                        ref="marstatid"
                                        :items="marStatList"
                                        hide-selected
                                        v-model="formData.marstatid"
                                        @input="updateValue"
                                        @change="changeMarStatHandler(formData.marstatid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                 <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="marStatCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="marStatDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: marStatItems,
                                                    formTitle: `${this.$t('recpersonalinfo.maritalstatus')}`,
                                                    formId: 'marital'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveMarital="saveMarStatHandler"
                                                v-on:deleteMarital="deleteMarStatHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="marStatDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 ml-n15 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recpersonalinfo.marriagedate')"
                                        id="mrgdate"
                                        type="date"
                                        ref="mrgdate"
                                        v-model="formData.mrgdate"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.race')"
                                        type="text"
                                        ref="raceid"
                                        :items="raceList"
                                        hide-selected
                                        v-model="formData.raceid"
                                        @input="updateValue"
                                        @change="changeRaceHandler(formData.raceid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                 <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="raceCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="raceDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: raceItems,
                                                    formTitle: `${this.$t('recpersonalinfo.race')}`,
                                                    formId: 'race'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveRace="saveRaceHandler"
                                                v-on:deleteRace="deleteRaceHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="raceDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.religion')"
                                        type="text"
                                        ref="religionid"
                                        :items="religionList"
                                        hide-selected
                                        v-model="formData.religionid"
                                        @input="updateValue"
                                        @change="changeReligionHandler(formData.religionid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                 <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="religionCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="religionDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: religionItems,
                                                    formTitle: `${this.$t('recpersonalinfo.religion')}`,
                                                    formId: 'religion'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveReligion="saveReligionHandler"
                                                v-on:deleteReligion="deleteReligionHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="religionDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.nationality')"
                                        type="text"
                                        ref="nationid"
                                        :items="countryList"
                                        hide-selected
                                        v-model="formData.nationid"
                                        @input="updateValue"
                                        @change="changeNationHandler(formData.nationid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                 <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="nationCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="nationDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: countryItems,
                                                    formTitle: `${this.$t('recpersonalinfo.nationality')}`,
                                                    formId: 'nation'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveNation="saveCountryHandler"
                                                v-on:deleteCountry="deleteCountryHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="nationDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('recpersonalinfo.countryoforigin')"
                                        type="text"
                                        ref="cntryoid"
                                        :items="countryList"
                                        hide-selected
                                        v-model="formData.cntryoid"
                                        @input="updateValue"
                                        @change="changeOriginHandler(formData.cntryoid)"
                                        dense
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                 <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="cntryoCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog v-model="cntryoDialog" max-width="500px" persistent>
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform 
                                                v-bind:parentData = "{
                                                    tabItems: countryItems,
                                                    formTitle: `${this.$t('recpersonalinfo.countryoforigin')}`,
                                                    formId: 'origin'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveOrigin="saveCountryHandler"
                                                v-on:deleteCountry="deleteCountryHandler($event)"
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="cntryoDialog = false"
                                                >
                                                {{$t('button.close')}}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <!--v-row>
                                <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                                    <v-file-input 
                                        label="Attachment" 
                                        v-model="formData.attach"
                                        @change="updateValue"
                                        ref="file"
                                        :accept=this.$store.getters.attachmentAcceptType
                                        show-size
                                        outlined 
                                        style="font-size: 14px"
                                        dense>
                                    </v-file-input>
                                </v-col>
                            </v-row-->

                        </v-col>

                        <v-col cols="4" sm="4">
                            
                            <v-row>
                                <v-col
                                    class="pa-0"
                                    cols="10"
                                >
                                    <div class="text-center">
                                    <v-avatar
                                        class="profile"
                                        size="164"
                                        tile
                                    >
                                        <v-img 
                                            v-if="!this.formData.pic"
                                            :src="`${publicPath}images/avatar-1577909_960_720.png`">
                                        </v-img>
                                        <v-img v-else
                                            :src="this.formData.pic"
                                        >
                                        </v-img>
                                    </v-avatar>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="10"  
                                    class="pb-n4 ml-n6"
                                    align-self="center"
                                >
                                    <div class="text-center">
                                        <v-file-input
                                            :label="this.$t('recpersonalinfo.changeprofpic')"
                                            ref="profileimg"
                                            outlined
                                            :accept=this.$store.getters.profileAcceptType
                                            dense
                                            show-size
                                            style="font-size: 14px"
                                            prepend-icon="mdi-camera"
                                            @change="imgToBase64">
                                        </v-file-input>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                psncode: this.parentObject.data.psncode,
                psnname: this.parentObject.data.psnname,
                psnname2: this.parentObject.data.psnname2,
                salutid: this.parentObject.data.salutid,
                fname: this.parentObject.data.fname,
                genderid: this.parentObject.data.genderid,
                mname: this.parentObject.data.mname,
                dob: this.parentObject.data.dob,
                lname: this.parentObject.data.lname,
                idno: this.parentObject.data.idno,
                oldidno: this.parentObject.data.oldidno,
                marstatid: this.parentObject.data.marstatid,
                mrgdate: this.parentObject.data.mrgdate,
                raceid: this.parentObject.data.raceid,
                religionid: this.parentObject.data.religionid,
                nationid: this.parentObject.data.nationid,
                cntryoid: this.parentObject.data.cntryoid,
                // attach: this.parentObject.data.attach,
                pic: this.parentObject.data.pic,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemid: null,
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            salutationList: [],
            genderList: [],
            marStatList: [],
            raceList: [],
            religionList: [],
            countryList: [],
            //nationList: [],
            //cntryoList: [],
            salutations: [],
            genders: [],
            marStats: [],
            races: [],
            religions: [],
            countries: [],
            //nations: [],
            //cntryos: [],
            salutationItems: [],
            genderItems: [],
            marStatItems: [],
            raceItems: [],
            religionItems: [],
            countryItems: [],
            //nationItems: [],
            //cntryoItems: [],
            salutationDialog: false,
            genderDialog: false,
            marStatDialog: false,
            raceDialog: false,
            religionDialog: false,
            nationDialog: false,
            cntryoDialog: false,
            salutationCode: '',
            genderCode: '',
            marStatCode: '',
            raceCode: '',
            religionCode: '',
            nationCode: '',
            cntryoCode: '',
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    computed: {
        ageYear: {
            get: function() {
                var years = 0;

                if (this.formData.dob) {
                    years =   moment().diff(this.formData.dob, 'years');
                    if (!years) {
                        years = 0;
                    }
                }
                
                return years;
            }
        },

        ageMonth: {
            get: function() {
                var months = 0;

                if (this.formData.dob) {
                    var totalMonths = moment().diff(this.formData.dob, 'months'); 
                    months = totalMonths % 12;
                    if (!months) {
                        months = 0;
                    }
                }
                
                return months;
            }
        }
    },

    methods: {
        imgToBase64() {
            var file = this.$refs.profileimg.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.pic = b64;

                this.$emit('profile', b64);

                 //file size in bytes. (5000000bytes = 5mb)
                if (file.size > '5000000'){
                     alert('File size is bigger than 5MB. Please upload new picture.');
                    return;
                }
            }
            reader.readAsDataURL(file); 
        },

        fileToBase64() {
            var file = this.$refs.file.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.attach = b64;
            }

            reader.readAsDataURL(file);
        },

        getSalutListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/salutation/all'})
                .then(res => {
                    vm.salutations = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.salutationList.push(
                            {
                                text: res.data.rec[i].salutname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.salutationItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].salutcode,
                                desc: res.data.rec[i].salutname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.salutid) {
                        vm.changeSalutationHandler(vm.formData.salutid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getGenderListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/gender/all'})
                .then(res => {
                    vm.genders = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.genderList.push(
                            {
                                text: res.data.rec[i].gendername,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.genderItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].gendercode,
                                desc: res.data.rec[i].gendername,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                    
                    if (vm.formData.genderid) {
                        vm.changeGenderHandler(vm.formData.genderid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getMarStatListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/maritalstat/all'})
                .then(res => {
                    vm.marStats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.marStatList.push(
                            {
                                text: res.data.rec[i].marstatname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.marStatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].marstatcode,
                                desc: res.data.rec[i].marstatname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.marstatid) {
                        vm.changeMarStatHandler(vm.formData.marstatid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getRaceListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/race/all'})
                .then(res => {
                    vm.races = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.raceList.push(
                            {
                                text: res.data.rec[i].racename,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.raceItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].racecode,
                                desc: res.data.rec[i].racename,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.raceid) {
                        vm.changeRaceHandler(vm.formData.raceid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getReligionListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/religion/all'})
                .then(res => {
                    vm.religions = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.religionList.push(
                            {
                                text: res.data.rec[i].rlgnname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.religionItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].rlgncode,
                                desc: res.data.rec[i].rlgnname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.religionid) {
                        vm.changeReligionHandler(vm.formData.religionid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        getCountryListHandler() {
            let vm = this;

            this.$store.dispatch('get', {url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    if (vm.formData.nationid) {
                        vm.changeNationHandler(vm.formData.nationid);
                    }

                    if (vm.formData.cntryoid) {
                        vm.changeOriginHandler(vm.formData.cntryoid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeSalutationHandler(id) {
            let item = this.salutations.find(item => item.id == id);

            this.salutationCode = item.salutcode;
        },

        changeGenderHandler(id) {
            let item = this.genders.find(item => item.id == id);

            this.genderCode = item.gendercode;
        },

        changeMarStatHandler(id) {
            let item = this.marStats.find(item => item.id == id);

            this.marStatCode = item.marstatcode;
        },

        changeRaceHandler(id) {
            let item = this.races.find(item => item.id == id);

            this.raceCode = item.racecode;
        },

        changeReligionHandler(id) {
            let item = this.religions.find(item => item.id == id);

            this.religionCode = item.rlgncode;
        },
        
        changeNationHandler(id) {
            let item = this.countries.find(item => item.id == id);

            this.nationCode = item.cntrycode;
        },

        changeOriginHandler(id) {
            let item = this.countries.find(item => item.id == id);

            this.cntryoCode = item.cntrycode;
        },

        saveSalutationHandler() {
            let vm = this;
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/salutation/' + this.itemData.itemid, payload: {
                    salutcode: vm.itemData.itemcode,
                    salutname: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/salutation/create', payload: {
                    salutcode: vm.itemData.itemcode,
                    salutname: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteSalutationHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/salutation/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.salutations = [];
                        vm.salutationList = [],
                        vm.salutationItems = [],

                        this.getSalutListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveGenderHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/gender/' + this.itemData.itemid, payload: {
                    gendercode: vm.itemData.itemcode,
                    gendername: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/gender/create', payload: {
                    gendercode: vm.itemData.itemcode,
                    gendername: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteGenderHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/gender/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.genders = [];
                        vm.genderList = [],
                        vm.genderItems = [],

                        this.getGenderListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveMarStatHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/maritalstat/' + this.itemData.itemid, payload: {
                    marstatcode: vm.itemData.itemcode,
                    marstatname: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/maritalstat/create', payload: {
                    marstatcode: vm.itemData.itemcode,
                    marstatname: vm.itemData.itemdesc
            }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteMarStatHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/maritalstat/' + $event.id + '/' + this.$store.getters.userI})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.marStats = [];
                        vm.marStatList = [],
                        vm.marStatItems = [],

                        this.getMarStatListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveRaceHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/race/' + this.itemData.itemid, payload: {
                    racecode: vm.itemData.itemcode,
                    racename: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.races = [];
                        vm.raceList = [],
                        vm.raceItems = [],

                        this.getRaceListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/race/create', payload: {
                    racecode: vm.itemData.itemcode,
                    racename: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.races = [];
                        vm.raceList = [],
                        vm.raceItems = [],

                        this.getRaceListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteRaceHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/race/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.races = [];
                        vm.raceList = [],
                        vm.raceItems = [],

                        this.getRaceListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveReligionHandler() {
            let vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/religion/' + this.itemData.itemid, payload: {
                    rlgncode: vm.itemData.itemcode,
                    rlgnname: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.religions = [];
                        vm.religionList = [],
                        vm.religionItems = [],

                        this.getReligionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/religion/create', payload: {
                    rlgncode: vm.itemData.itemcode,
                    rlgnname: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.religions = [];
                        vm.religionList = [],
                        vm.religionItems = [],

                        this.getReligionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteReligionHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/religion/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.religions = [];
                        vm.religionList = [],
                        vm.religionItems = [],

                        this.getReligionListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCountryHandler() {
            let vm = this;
            
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/country/' + this.itemData.itemid, payload: {
                    cntrycode: vm.itemData.itemcode,
                    cntryname: vm.itemData.itemdesc,
                    stamp: vm.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.countries = [];
                        vm.countryList = [],
                        vm.countryItems = [],

                        this.getCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/country/create', payload: {
                    cntrycode: vm.itemData.itemcode,
                    cntryname: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // reset all arrays
                        vm.countries = [];
                        vm.countryList = [],
                        vm.countryItems = [],

                        this.getCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCountryHandler($event) {
            let vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/country/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    console.log(res.data.status)
                    if (res.data.status == 200) {
                        // reset all arrays
                        vm.countries = [];
                        vm.countryList = [],
                        vm.countryItems = [],

                        this.getCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        updateValue() {
            this.$emit('input', {
                psncode: this.formData.psncode,
                psnname: this.formData.psnname,
                psnname2: this.formData.psnname2,
                salutid: this.formData.salutid,
                fname: this.formData.fname,
                genderid: this.formData.genderid,
                mname: this.formData.mname,
                dob: this.formData.dob,
                lname: this.formData.lname,
                idno: this.formData.idno,
                oldidno: this.formData.oldidno,
                marstatid: this.formData.marstatid,
                mrgdate: this.formData.mrgdate,
                raceid: this.formData.raceid,
                religionid: this.formData.religionid,
                nationid: this.formData.nationid,
                cntryoid: this.formData.cntryoid,
                // attach: this.formData.attach,
                pic: this.formData.pic,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'personlist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getSalutListHandler();
        this.getGenderListHandler();
        this.getMarStatListHandler();
        this.getRaceListHandler();
        this.getReligionListHandler();
        this.getCountryListHandler();
        //this.getNationListHandler();
        //this.getCountryOrigHandler();
    }
}
</script>