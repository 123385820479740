<template>
    <div>
        <v-dialog v-model="epfveDialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupepfve.epfveitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="8" md="8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.company')"
                            type="text"
                            :items="companyList"
                            v-model="selections[0].compnyid"
                            @change="changeCompanyHandler(selections[0].compnyid)"
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                        <v-text-field
                            outlined
                            :label="this.$t('reporalertlisting.age')"
                            type="number"
                            v-model="selections[0].age"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.employmenttype')"
                            type="text"
                            :items="emptypeList"
                            v-model="selections[0].emptypeid"
                            @change="changeEmptypeHandler(selections[0].emptypeid)"
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.gender')"
                            type="text"
                            :items="genderList"
                            v-model="selections[0].genderid"
                            @change="changeGenderHandler(selections[0].genderid)"
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.grade')"
                            type="text"
                            :items="gradeList"
                            v-model="selections[0].gradeid"
                            @change="changeGradeHandler(selections[0].gradeid)"
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.serviceperiod')"
                            type="text"
                            v-model="selections[0].servprd"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.wages')"
                            type="number"
                            v-model="selections[0].wages"
                            dense
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.eepercentage')"
                            type="number"
                            v-model="selections[0].eeamt"
                            :rules="[rules.percentage]"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupepf.erpercentage')"
                            type="number"
                            v-model="selections[0].eramt"
                            :rules="[rules.percentage]"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>


              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeEpfve">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveEpfve">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
      
    </div>
</template>

<script>
//import moment from 'moment';
import compareArray from '../../../util/comparearray';
//import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  "app-error": ErrorDialog
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            isEpfve: false,
            epfveDialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                epfveid: null,
                compnyid: null,
                compnycode: null,
                compnyname: null,
                emptypeid: null,
                emptype: null,
                age: null,
                gradeid: null,
                grdcode: null,
                grddesc: null,
                genderid: null,
                gendercode: null,
                gendername: null,
                servprd: null,
                wages: null,
                eeamt: null,
                eramt: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            companies: [],
            companyList: [],

            emptypes: [],
            emptypeList: [],

            grades: [],
            gradeList: [],

            genders: [],
            genderList: [],

             isConfirm: false,
            confirmDelete: false,
            isSuccess: false,

            isExisting: false,
             modify: false,
            isErr: false,
            errMsg: ''
        }
    },

    // computed: {
    //     ageYear: {
    //         get: function() {
    //             var years = 0;

    //             if (this.selections[0].age) {
    //                 years =   moment().diff(this.selections[0].age, 'years');
    //                 if (!years) {
    //                     years = 0;
    //                 }
    //             }

    //             return years;
    //         }
    //     }

    // },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }
            if(item.action == 2) {
                this.isExisting = true;
            }

            this.selections[0] = item;
            this.epfveDialog = true;

              if (!this.isExisting){
                 // duplicate this.detailItem for duplicate checking
                
                 //find index 
                
                 var index = this.detailItem.findIndex( x => x.id == item.id);


                 this.detailItem[index].epfveid = item.epfveid;
                 this.detailItem[index].compnyid = item.compnyid;
                 this.detailItem[index].compnycode = item.compnycode;
                 this.detailItem[index].compnyname = item.compnyname;
                 this.detailItem[index].emptypeid = item.emptypeid;
                 this.detailItem[index].emptype = item.emptype;
                 this.detailItem[index].age = item.age;               
                 this.detailItem[index].gradeid = item.gradeid;
                 this.detailItem[index].grdcode = item.grdcode;
                 this.detailItem[index].grddesc = item.grddesc;
                 this.detailItem[index].genderid = item.genderid;
                 this.detailItem[index].gendercode = item.gendercode;
                 this.detailItem[index].gendername = item.gendername;
                 this.detailItem[index].servprd = item.servprd;
                 this.detailItem[index].wages = item.wages;
                 this.detailItem[index].eeamt = item.eeamt;
                 this.detailItem[index].ee_amount = item.ee_amount;
                 this.detailItem[index].eramt = item.eramt;
                 
             } else {
                 this.selections[0].action = 2;

                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
             }

          //  this.epfveDialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeEpfve() {
            this.epfveDialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },


        // Saving the header details into the database
        saveEpfve() {

        if (this.selections[0].wages == null || this.selections[0].wages == ""){

                    this.errMsg = this.$t('fillincolumnwages');
                    this.isErr = true;
                    this.epfveDialog = true;
         } else {

             this.isErr = false;

              if (this.selections[0].wages)
                this.selections[0].wages = parseFloat(this.selections[0].wages);

            if (this.selections[0].eeamt)
                this.selections[0].eeamt = parseFloat(this.selections[0].eeamt);

            if (this.selections[0].eramt)
                this.selections[0].eramt = parseFloat(this.selections[0].eramt);
             
             if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
         

            this.$emit("input", this.detailItem);

            //this.modify = false;
            this.epfveDialog = false;

            this.resetSelections();
        }
         
         
         
        //  else {
        //     this.isErr = false;
        //     // indicate value of 1 as insert, 2 as modify

        //     if (this.selections[0].wages)
        //         this.selections[0].wages = parseFloat(this.selections[0].wages);

        //     if (this.selections[0].eeamt)
        //         this.selections[0].eeamt = parseFloat(this.selections[0].eeamt);

        //     if (this.selections[0].eramt)
        //         this.selections[0].eramt = parseFloat(this.selections[0].eramt);

        //     if (!this.isExisting && !this.modify) {
        //         this.selections[0].id = null;
        //         this.selections[0].action = 1;
        //         this.detailItem.push(Object.assign({}, this.selections[0]));
        //     } else {
        //         if (this.compareArray(this.detailItem, this.selections[0])) {
        //         console.log(this.compareArray(this.detailItem, this.selections[0]));
        //         }
        //     }

            // } else {

            //     for (let i = 0; i < this.detailItem.length; i++) {
            //         if (this.detailItem[i].id == this.selections[0].id) {
            //             this.detailItem[i].epfveid = this.selections[0].epfveid,
            //             this.detailItem[i].compnyid = this.selections[0].compnyid,
            //             this.detailItem[i].compnycode = this.selections[0].compnycode,
            //             this.detailItem[i].compnyname = this.selections[0].compnyname,
            //             this.detailItem[i].emptypeid = this.selections[0].emptypeid,
            //             this.detailItem[i].emptype = this.selections[0].emptype,
            //             this.detailItem[i].age = this.selections[0].age,
            //             this.detailItem[i].gradeid = this.selections[0].gradeid,
            //             this.detailItem[i].grdcode = this.selections[0].grdcode,
            //             this.detailItem[i].grddesc = this.selections[0].grddesc,
            //             this.detailItem[i].genderid = this.selections[0].genderid,
            //             this.detailItem[i].gendercode = this.selections[0].gendercode,
            //             this.detailItem[i].gendername = this.selections[0].gendername,
            //             this.detailItem[i].servprd = this.selections[0].servprd,
            //             this.detailItem[i].wages = this.selections[0].wages,
            //             this.detailItem[i].eeamt = this.selections[0].eeamt,
            //             this.detailItem[i].eramt = this.selections[0].eramt
            //         }
            //     }
            
        //  }

        //     this.$emit('input', this.detailItem);

        
        //     this.epfveDialog = false;

        //     // reset the form fields
        //     this.resetSelections();
        },

        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                epfveid: null,
                compnyid: null,
                compnycode: null,
                compnyname: null,
                emptypeid: null,
                emptype: null,
                emptypedesc: null,
                gradeid: null,
                grdcode: null,
                grddesc: null,
                age: null,
                genderid: null,
                gendercode: null,
                gendername: null,
                servprd: null,
                wages: null,
                eeamt: null,
                eramt: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

        //The load handler below is for loading the content of each items from the database
        loadCompanies() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/company/all'})
            .then (res => {
                vm.companies = res.data.rec;

                for (let i = 0; i < vm.companies.length; i++) {
                    vm.companyList.push(
                        {
                            text: vm.companies[i].compnyname,
                            value: vm.companies[i].id
                        }
                    )
                }
            });
        },

        loadEmpTypes() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/emptype/all'})
            .then (res => {
                vm.emptypes = res.data.rec;

                for (let i = 0; i < vm.emptypes.length; i++) {
                    vm.emptypeList.push(
                        {
                            text: vm.emptypes[i].emptype,
                            value: vm.emptypes[i].id
                        }
                    )
                }
            });
        },

        loadGrades() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/grade/all'})
            .then (res => {
                vm.grades = res.data.rec;

                for (let i = 0; i < vm.grades.length; i++) {
                    vm.gradeList.push(
                        {
                            text: vm.grades[i].grddesc,
                            value: vm.grades[i].id
                        }
                    )
                }
            });
        },

        loadGenders() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/gender/all'})
            .then (res => {
                vm.genders = res.data.rec;

                for (let i = 0; i < vm.genders.length; i++) {
                    vm.genderList.push(
                        {
                            text: vm.genders[i].gendername,
                            value: vm.genders[i].id
                        }
                    )
                }
            });
        },

        //Change Handler is to show the loaded contents from the database
        changeCompanyHandler(id) {
            let item = this.companies.find(item => item.id == id)

            this.selections[0].compnyname = item.compnyname;
        },

        changeEmptypeHandler(id) {
            let item = this.emptypes.find(item => item.id == id)

            this.selections[0].emptype = item.emptype;
        },

        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id == id)

            this.selections[0].grddesc = item.grddesc;
        },

        changeGenderHandler(id) {
            let item = this.genders.find(item => item.id == id)

            this.selections[0].gendername = item.gendername;
        },

        changeSalfreqHandler(id) {
            let item = this.salaryfreqs.find(item => item.id == id)

            this.selections[0].salfreqdesc = item.basicfreqdesc;
        }
    },

    created() {
        this.loadCompanies();
        this.loadEmpTypes();
        this.loadGrades();
        this.loadGenders();
    }

}
</script>