<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.staffData,
          pageTitle: this.pageTitle,
          errMsg: this.errMsg,
          isEdit: this.editMode,
          isNewHire: this.isNewHire,
          isLocMov: this.isLocMov,
          isJobMov: this.isJobMov,
          isSalMov: this.isSalMov,
          isContrMov: this.isContrMov,
          isConfirmation: this.isConfirmation,
        }"
        v-on:input="staffData = $event"
        v-on:save="saveHandler"
        v-on:profile="staffData.pic = $event"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>

import StaffForm from "../../../components/personnel/staff/FormHideSalary";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": StaffForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('recstaffdetails.newstaff'),
      pageTitleValue: "",
      staffData: {
        staffcode: null,
        empno: null,
        hiredate: null,
        servicedate: null,
        probmth: null,
        dueconfirmdate: null,
        exprobmth: null,
        exdueconfirmdate: null,
        confirmdate: null,
        resigndate: null,
        resignnotprd: null,
        lworkdate: null,
        olastdate: null,
        cessadate: "9999-01-01",
        headcount: true,
        stfstamp: 0,
        movtypeid: null,
        staffid: null,
        psnid: null,
        recdate: null,
        recenddate: "9999-01-01",
        actualdate: null,
        reasonid: null,
        compnyid: null,
        brchid: null,
        divnid: null,
        deptid: null,
        sectioid: null,
        unitid: null,
        positionid: null,
        emptypeid: null,
        grdid: null,
        jobid: null,
        crcyid: null,
        prevbasicsal: 0,
        basicsal: 0,
        basicfreqid: null,
        attach: null,
        attachname: null,
        adjustamt: 0,
        adjustpct: 0,
        newbasicsal: 0,
        contrprd: null,
        contrenddate: null,
        remark: null,
        lockrecdate: false,
        movstamp: 0,
        pic: null,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
      isNewHire: true,
      isLocMov: false,
      isJobMov: false,
      isSalMov: false,
      isContrMov: false,
      isConfirmation: false,
      isCessation: false,
      loading: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      // Convert boolean to integer. 1 = true, 0 = false
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      if (this.staffData.headcount == false) {
        this.staffData.headcount = 0;
      } else {
        this.staffData.headcount = 1;
      }

      const vm = this;

      this.$store.dispatch('post', {url: "/staff/create", payload: this.staffData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "stafflisths" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadNewHireMovementDetail() {
      const vm = this;

      this.$store.dispatch('get', {url: "/movtypedc/HIRE"})
        .then((res) => {
          if (res.data.rec[0].ishire == "Y") vm.isNewHire = true;
          if (res.data.rec[0].islocmov == "Y") vm.isLocMov = true;
          if (res.data.rec[0].isjobmov == "Y") vm.isJobMov = true;
          if (res.data.rec[0].issalmov == "Y") vm.isSalMov = true;
          if (res.data.rec[0].iscontrmov == "Y") vm.isContrMov = true;

          vm.staffData.movtypeid = res.data.rec[0].movtypeid;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadNewHireMovementDetail();
  },
};
</script>