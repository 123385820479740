<template>
    <div>
        <app-form
            v-bind:parentObject="{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode,
            }"
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:error="errorHandler"
        />

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
import AssetForm from "../../../components/personnel/asset/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    components: {
        "app-form": AssetForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            pageTitle: this.$t('recassetrec.newassetrec'),
            pageTitleValue: "",
            formData: {
                assettype: null,
                assetdesc: null,
                qty: null,
                trackdetails: false,
                isdisable: false,
                remark: null,
                stamp: 0,
                assetdetail: [],
                userid: this.$store.getters.userId,
            },
            errMsg: "",
            isErr: false,
            editMode: false,
        };
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        saveHandler() {
            const vm = this;
            if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                return false;
            }
            if (this.formData.trackdetails == false || this.formData.trackdetails == null) {
                this.formData.trackdetails = 0;
            } 
            else {
                this.formData.trackdetails = 1;
            }

            if (this.formData.isdisable == false || this.formData.isdisable == null) {
                this.formData.isdisable = 0;
            } 
            else {
                this.formData.isdisable = 1;
            }

            this.$store.dispatch('post', {url: "/asset/create", payload: this.formData})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } 
                    else {
                        this.$router.push({ name: "assetlist" });
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
        },
    
        cancelHandler() {
            this.$router.push({ name: "assetlist" });
        },
    },
};
</script>