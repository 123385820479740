<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                label= "Document Type"
                                type="text"
                                ref="doctype"
                                :items="docTypeList"
                                v-model="formData.doctype"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="documentTypeDialog" max-width="600px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: docTypeItems,
                                            formTitle: `Document Type`,
                                            formId: 'doctype'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveDocType="saveDocTypeHandler"
                                        v-on:deleteDocType="deleteDocTypeHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="documentTypeDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                label= "Title"
                                id="title"
                                type="text"
                                ref="title"
                                :rules="[rules.required]"
                                v-model="formData.title"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="desc"
                                v-model="formData.desc"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n5 mt-n8">
                        <v-file-input
                            ref="attach"
                            :label="this.$t('qualificationlist.supportingdocument')"
                            outlined
                            v-model="fileHolder"
                            @change="fileToBase64"
                            @input="updateValue"
                            dense
                            multiple
                        ></v-file-input>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="4" sm="6" class="pb-8 mt-n8">
                            <tr v-for="(item, index) in this.formData.attachnew" :key="index">
                        
                                <p>
                                    <a
                                        v-bind:href="item.attach"
                                        v-bind:download="item.attachname"
                                    
                                        >{{item.attachname}}</a
                                    >
                                    <v-icon
                                        small
                                        class="ml-3 mr-2"
                                        @click="deleteAttachmentHandlerNew(item)"
                                        >
                                        mdi-delete
                                    </v-icon>
                                </p>
                            </tr>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="pb4-4">
                            <app-table
                                v-bind:parentObject="{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.formData.edocdetails
                                }"
                                v-on:input="addItemHandler"
                            />
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from './CRUD.vue';
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable,
        'app-genericform': GenericForm
    },

    data() {
        return {
            formData: {
                doctype: this.parentObject.data.doctype,
                title: this.parentObject.data.title,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                desc: this.parentObject.data.desc,
                attach: this.parentObject.data.attach,
                attachname: this.parentObject.data.attachname,
                isdisable: this.parentObject.data.isdisable,
                attachnew: this.parentObject.data.attachnew,
                edocdetails: this.parentObject.data.edocdetails,
                // search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            rules: { required: value => !!value || this.$t('reportsparam.required') },
            docTypeList: [],

            fileHolder:[],
            // attachnew: [],
            filecount: 0,
            docTypeItems: [],
            documentTypeDialog: false,

            errMsg: '',
            isErr: false,

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('columns.company'),
                    value: "compnycode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.branch'),
                    value: "brchcode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.division'),
                    value: "divncode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.department'),
                    value: "deptcode",
                    width: "140px",
                    sortable: true
                },
                {
                    text: this.$t('columns.section'),
                    value: "sectiocode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.unit'),
                    value: "unitcode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.employmenttype'),
                    value: "emptype",
                    width: "200px",
                    sortable: true
                },
                {
                    text: this.$t('columns.grade'),
                    value: "grddesc",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.job'),
                    value: "jobtitle",
                    width: "150px",
                    sortable: true
                }
            ],
            // search: this.parentObject.searchnew
        }
    },

    methods: {

        getDocType(){

            
            const vm = this;

            this.$store.dispatch('get', {url: '/documenttype/all'})
            .then(res => {

                let data = res.data.rec;
                let doctype = data.filter(x => x.src == 'edoc')

                for (let i = 0; i < doctype.length; i++) {

                    vm.docTypeList.push(
                        {
                            text: doctype[i].doctypecode,
                            value: doctype[i].id
                        }
                    )

                    if(doctype[i].src == 'edoc') {
                        vm.docTypeItems.push(
                            {
                                id: doctype[i].id,
                                code: doctype[i].doctypecode,
                                desc: doctype[i].src,
                                stamp: doctype[i].stamp
                            }
                        )
                    }

                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });

        },

        async fileToBase64(){

            console.log(this.fileHolder);
            if (this.fileHolder) {

                var file = this.$refs.attach.internalValue;

                for (let i = 0; i < file.length; i++){

                    const filePromises = file.map((file) => {

                        console.log('fileee', file)
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            console.log('reader', reader)
                            reader.onload = async () => {
                            
                                try {

                                    console.log(this.formData);

                                    this.formData.attach = [];
                                    this.formData.attachname = [];

                                    const response = await this.formData.attach.push(
                                        reader.result,
                                    );
                                    // Resolve the promise with the response value
                                    resolve(response);
                                    console.log('response', response)
                                    this.formData.attachname.push(file.name);
                                    console.log('filename', this.formData.attachname)
                                                        
                                } catch (err) {
                                    reject(err);
                                }
                            };
                            
                            reader.onerror = (error) => {
                                reject(error);
                            };
                            reader.readAsDataURL(file);
                        });
                    });
                
                    const fileInfos = await Promise.all(filePromises);
                    this.fileHolder = null; // Clear v-model file
                    this.updateValue();
                
                    for (let j = 0; j < this.formData.attach.length; j++){

                        this.formData.attachnew.push({
                            attachid: this.filecount + 1,
                            attach: this.formData.attach[j],
                            attachname: this.formData.attachname[j]
                        })

                        this.filecount++

                    }

                    // Profit
                    return fileInfos;
                }
            }
        },

        saveDocTypeHandler() {
            const vm = this;

            console.log(this.itemData)

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/edoctype/' + this.itemData.itemid, 
                    payload: {
                        doctypecode: this.itemData.itemcode,
                        doctypedesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.docTypeItems = [];
                        vm.docTypeList = [];

                        this.getDocType();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/edoctype/create', 
                    payload: {
                        doctypecode: this.itemData.itemcode,
                        doctypedesc: this.itemData.itemdesc,
                        userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.docTypeItems = [];
                        vm.docTypeList = [];
                        this.getDocType();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteDocTypeHandler($event) {
            const vm = this;
            
            if (confirm('Remove ' + $event.code + '?')) {

                console.log($event)
                this.$store.dispatch('delete', {
                    url: '/edoctype/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        console.log('check')
                        // resets all arrays
                        vm.docTypeItems = [];
                        vm.docTypeList = [];

                        this.getDocType();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        download() {
            //  const blob = new Blob([], { type: "text/csv;charset=utf-8;" });
            //  const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");

            document.body.appendChild(a);

                for( var n = 0; n < this.formData.attach.length; n++ ){
                    var download = this.formData.attach[n];
                    a.setAttribute( 'href', download.path );
                    a.setAttribute( 'download', download.name );

                    a.click();
                }

            document.body.removeChild(a);
        },

        deleteAttachmentHandlerNew(item){

            console.log(item)

            this.formData.attachnew = this.formData.attachnew.filter(val => val.attachid != item.attachid)

            console.log(this.formData.attachnew);

            this.updateValue();

        },

        addItemHandler($event) {
            this.formData.edocdetails = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                doctype: this.formData.doctype,
                title: this.formData.title,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                desc: this.formData.desc,
                attach: this.formData.attach,
                attachname: this.formData.attachname,
                attachnew: this.formData.attachnew,
                edocdetails: this.formData.edocdetails,
                isdisable: this.formData.isdisable,
                // search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            // console.log(this.formData)
            // this.$emit('save', this.formData.attachnew);
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            // this.$router.push({name: 'edoclist', params: { search: this.search}});
            this.$router.push({name: 'edoclist'});
        }
    },

    mounted() {
        this.getDocType()
        console.log("edocdetails", this.formData)
    }
}
</script>