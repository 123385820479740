<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import PolicyForm from "../../../components/latenessdeduction/policy/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": PolicyForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('latenessdeduction.editpolicy'),
      pageTitleValue: "",
      formData: {
        id: null,
        policydetail: [],

        latenessparamcode: null,
        latenessparamdesc: null,
        fromdate: null,
        todate: null,
        dedtpayitemid: null,
        currencyid: null,
        frequencyid: null,
        remark: null,

        payitemcode: null,
        payitemdesc: null,
        currencycode: null,
        currencydesc: null,
        frequencycode: null,
        frequencydesc: null,
        frequencyfactor: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadPolicy() {
      const vm = this;

      this.$store.dispatch('get', {url: "/latenessdeductionpolicy/" + this.id})
        .then((res) => {

          vm.formData.id = res.data.rec[0].id;
          vm.formData.policydetail = res.data.rec[0].policydetail.map(e => {
            e.byamount = e.byamount == 'Y' ? true: false; 
            e.byfactor = e.byfactor == 'Y' ? true: false; 
            return e;
          });
          vm.formData.latenessparamcode = res.data.rec[0].latenessparamcode;
          vm.formData.latenessparamdesc = res.data.rec[0].latenessparamdesc;
          vm.formData.fromdate = moment(res.data.rec[0].fromdate).format('YYYY-MM-DD')
          vm.formData.todate = moment(res.data.rec[0].todate).format('YYYY-MM-DD');
          vm.formData.dedtpayitemid = res.data.rec[0].dedtpayitemid;
          vm.formData.currencyid = res.data.rec[0].currencyid;
          vm.formData.frequencyid = res.data.rec[0].frequencyid;
          vm.formData.remark = res.data.rec[0].remark;

          vm.formData.payitemcode = res.data.rec[0].payitemcode;
          vm.formData.payitemdesc = res.data.rec[0].payitemdesc;
          vm.formData.currencycode = res.data.rec[0].currencycode;
          vm.formData.currencydesc = res.data.rec[0].currencydesc;
          vm.formData.frequencycode = res.data.rec[0].frequencycode;
          vm.formData.frequencydesc = res.data.rec[0].frequencydesc;
          vm.formData.frequencyfactor = res.data.rec[0].frequencyfactor;
          
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.pageTitleValue = res.data.rec[0].latenessparamdesc;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/latenessdeductionpolicy/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "latenessdeductionpolicylist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/latenessdeductionpolicy/" +
            this.id +
            "/" +
            this.formData.userid
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "latenessdeductionpolicylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "latenessdeductionpolicynew" });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadPolicy();
  },
};
</script>