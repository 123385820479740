<template>
    <div id="`eislist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupeis.eissetup'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '80px', sortable: false},
                { text: this.$t('setupeis.eisgroup'),   value: 'eiscode', width: '150px'},
                { text: this.$t('columns.description'),   value: 'eisdesc', width: '200px'},
                { text: this.$t('columns.effectivefrom'),       value: 'startdate',     width: '160px' },
                { text: this.$t('columns.effectiveuntil'),         value: 'enddate',       width: '160px' },
                { text: this.$t('columns.remark'),           value: 'remark',       width: '200px' },
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/eisdt/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].startdate) {
                            vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.dataItems[i].enddate) {
                            vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                        }
                    }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'eisedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'eisnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>