<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}<span class="page_title_value">{{ this.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.year')"
                                    type="text"
                                    ref="year"
                                    hide-selected
                                    v-model="formData.pyyear"
                                    dense
                                    :items="yearList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="2" sm="2" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.month')"
                                    type="text"
                                    ref="month"
                                    hide-selected
                                    v-model="formData.pymonth"
                                    dense
                                    :items="monthList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportsparam.payout')"
                                    type="text"
                                    ref="payout"
                                    hide-selected
                                    v-model="formData.poid"
                                    dense
                                    :items="payoutList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="goHandler"> 
                            {{$t('button.go')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from "moment";
import FilterParameter from '../../../components/common/FilterParameter';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            parameters: {
                staffid: null,
                gradeid: null,
                employmenttypeid: null,
                companyid: null,
                branchid: null,
                departmentid: null
            },

            formData: {
                pyyear: moment().year(),
                pymonth: null,
                poid: null,
            },

            showFilter: {
                staffid: true,
                gradeid: true,
                employmenttypeid: true,
                companyid: true,
                branchid: true,
                departmentid: true
            },

            yearList: [],
            monthList: [],
            payoutList: [],

            pageTitle: this.$t('payrolldashboardreport.payrolldashboard'),
            pageTitleValue: '',
        }
    },

    methods: {

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2000 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },

        loadPayoutListHandler(){
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
            .then(res => {
                let payouts = res.data.rec;

                for (let i = 0; i < payouts.length; i++) {
                    vm.payoutList.push(
                        {
                            text: res.data.rec[i].payoutcode,
                            value: res.data.rec[i].id
                        }
                    )                        
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        goHandler() {
            const formData = this.formData
            const param = {
                brchid: this.parameters.branchid,
                compnyid: this.parameters.companyid,
                deptid: this.parameters.departmentid,
                emptypeid: this.parameters.employmenttypeid,
                gradeid: this.parameters.gradeid,
                staffid: this.parameters.staffid,
            }

            if ((this.formData.pyyear != null && this.formData.pymonth != null) || this.formData.poid != null)  {
                this.$router.push({name: 'payrolldashboardsummary', params: { formData, param } });
            } else {
                this.$refs.form.validate()
            }
        },
    },

    mounted() {
        this.yearGeneration();
        this.monthGeneration();
        this.loadPayoutListHandler();
    },
    
}
</script>