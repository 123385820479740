<template>
    <div>
        <div style="overflow:auto;">
        <div class="btn-process" style="float:right;">
            <v-container>
                <v-row>
                    <v-col>

                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            style="width: 200px"
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col>

                        <v-btn color="primary" style="width: 200px" @click="onClick">
                            {{$t('button.genallcombination')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-dialog v-model="glcodereportdialog" max-width="800px">
          <template v-slot:activator="{ on }" >
            <v-btn style="float:left;" color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('periodicincentivesetup.periodicattendanceincentiveinputitem')}}</span>
              <!--<span class="headline mb-4">Periodic Attendance Incentive Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('glcode.reportby')"
                            type="text"
                            id = "reportby"
                            ref= "reportby"
                            :rules="[rules.required]"
                            v-model="selections.reportby"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('glcode.incode')"
                            type="text"
                            id = "incode"
                            ref= "incode"
                            :rules="[rules.required]"
                            v-model="selections.incode"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('glcode.indesc')"
                            type="text"
                            id = "indesc"
                            ref= "indesc"
                            :rules="[rules.required]"
                            v-model="selections.indesc"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('glcode.excode')"
                            type="text"
                            id = "excode"
                            ref= "excode"
                            :rules="[rules.required]"
                            v-model="selections.excode"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('glcode.exdesc')"
                            type="text"
                            id = "exdesc"
                            ref= "exdesc"
                            :rules="[rules.required]"
                            v-model="selections.exdesc"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeGlCodeReportingInput">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveGlCodeReportingInput">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </div>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
            :search="search"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
        <!-- <div v-if="isErrBox">
            <app-error
            v-bind:parentError="{
                data: this.errMsgBox,
                isErr: this.isErrBox,
            }"
            v-on:closeError="isErrBox = $event"
            />
        </div> -->
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },
            //    tableOpt: {
            //     itemsPerPage: -1
            // },
            glcodereportdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: {
                id: null,
                reportby: null,
                incode: null,
                indesc: null,
                excode: null,
                exdesc: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            },
            itemHolder: [],

            search: '',
            modifyNew: false,
            modifyExisting: false,
            isErr: false,
            errMsg: '',
            isErrBox: false,
            errMsgBox: ''
        }
    },

 

    methods: {
        compareArray,

        removeDuplicates(arrayOfObjects, arrayToCompare) {
            const uniqueNames = new Set(arrayOfObjects.map(obj => obj.reportby));
            return arrayToCompare.filter(name => !uniqueNames.has(name));
        },

        onClick() {
            const vm = this;

            if (!this.parentObject.payglmappolicyid) {
                this.$dialog.alert(this.$t('glcode.glmappingpolicymissing')).then((dialog) => {
                    console.log(dialog);
                });
            } else {
                this.$store.dispatch('get', {url: "/reportingby/" + this.parentObject.payglmappolicyid})
                .then((res) => {
                    const uniqueReportingBy = this.removeDuplicates(vm.detailItem, res.data.rec);
                    for (let i = 0; i < uniqueReportingBy.length; i++) {
                        vm.detailItem.push({reportby: uniqueReportingBy[i], action: 1})
                    }
                })
                .catch((err) => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                });
            }
        },
       
        modifyItemHandler(item) {
        // assign loaded data to selections array
            if(item.action == 1) {
                this.modifyNew = true;    
            } else {
                this.modifyExisting = true
            }

            this.itemHolder = item
            this.selections = this.itemHolder;
            //this.selections.userid = this.$store.getters.userId

            this.glcodereportdialog = true;
        },
        
        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },


        saveGlCodeReportingInput() {
            this.isErr = false;

            if (this.modifyNew || !this.modifyExisting) {
                this.selections.id = null;
                this.selections.action = 1;

                this.detailItem.push(Object.assign({}, this.selections));
            } else {
                var index = this.detailItem.findIndex(x => x.id == this.selections.id);

                this.detailItem[index].reportby = this.selections.reportby;
                this.detailItem[index].incode = this.selections.incode;
                this.detailItem[index].indesc = this.selections.indesc;
                this.detailItem[index].excode = this.selections.excode;
                this.detailItem[index].exdesc = this.selections.exdesc;
            }

            this.$emit('input', this.detailItem);
            this.closeGlCodeReportingInput()
        },
        
        closeGlCodeReportingInput() {
            this.glcodereportdialog = false;
            this.isErr = false;
            this.modifyExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        
        resetSelections() {
            this.selections = {
                id: null,
                reportby: null,
                incode: null,
                indesc: null,
                excode: null,
                exdesc: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }
        },
    },
}
</script>