<template>
  <div>
    <div id="dashboard">
      <v-container>
        <v-row>
          <v-col>
            <v-card
              max-width="500px"
              elevation="10"
              shaped
              :items="this.wizardItems"
              item-key="wizardcode"
            >
              <div class="ma-4">
                <br />
                <v-row align="center" justify="space-around">
                  <v-btn width="400px" class="primary" v-on:click="loadPage()">
                    {{$t('dashboard.guide')}}
                  </v-btn>
                </v-row>
                <br />
                <v-row>
                  <v-col cols="4" sm="4" md="12" class="pb-n5">
                    <v-card-actions
                      class="blue--text"
                      style="font-size: 13px"
                      v-for="item in wizardItems"
                      :key="item.id"
                      v-on:click="loadPageHandler(item.id)"
                    >
                      {{ item.wizardcode }}
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              max-width="500px"
              elevation="10"
              shaped
              :items="this.alertItems"
              item-key="alerttype"
            >
              <div class="ma-4">
                <br />
                <v-row align="center" justify="space-around">
                  <v-btn
                    width="400px"
                    class="purple lighten-3"
                    v-on:click="loadAlert()"
                  >
                  {{$t('alert')}}
                  </v-btn>
                </v-row>
                <br />
                <v-row>
                  <v-col cols="4" sm="4" md="12" class="pb-n5">
                    <v-card-actions
                      class="purple--text"
                      style="font-size: 13px"
                      v-for="item in alertItems"
                      :key="item.id"
                      v-on:click="loadAlertHandler(item.id)"
                    >
                      ({{ item.length }}) {{ item.alerttype }}

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },
  data() {
    return {
      getid: this.$route.params.id,
      getpanel: this.$route.params.panel,
      formData: {
        wizardcode: null,
        wizarddesc: null,
        alerttype: null,
        alerttypeid: null,
        length: null,
        userid: this.$store.getters.userId,
      },

      wizardItems: [],
      alertItems: [],

      dataItemsDueconfirm: [],
      dataItemsExtendedDueconfirm: [],
      dataItemsDuecessation: [],
      dataItemsDuecontract: [],
      dataItemsDuedocument: [],
      dataItemsDuebirthday: [],
      dataItemsDuework3years: [],
      dataItemsDuework5years: [],
      dataItemsDuework10years: [],

      isErr: false,
      errMsg: "",
    };
  },
  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadWizardList() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  "/wizardsetup/all"})
        .then((res) => {
          vm.wizardItems = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPageHandler(id) {
      let wizard = this.getid;
      this.$router.push({ name: "wizardstep", params: { id, wizard } });
    },

    getDueconfirmdateHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/dueconfirmdate/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDueconfirm = res.data.rec;

          vm.alertItems.push({
            id: 1,
            alerttype: this.$t('reporalertlisting.dueforconfirmationdate'),
            length: res.data.rec.length,
          });
          this.getExtendedDueconfirmdateHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getExtendedDueconfirmdateHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/exdueconfirmdate/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsExtendedDueconfirm = res.data.rec;

          vm.alertItems.push({
            id: 2,
            alerttype: this.$t('reporalertlisting.dueextendedconfirmationdate'),
            length: res.data.rec.length,
          });
          this.getDuecessationdateHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuecessationdateHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/duecessationdate/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDuecessation = res.data.rec;

          vm.alertItems.push({
            id: 3,
            alerttype: this.$t('reporalertlisting.dueforcessationdate'),
            length: res.data.rec.length,
          });
          this.getDuecontractexpiryHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuecontractexpiryHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/duecontractexpiry/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDuecontract = res.data.rec;

          vm.alertItems.push({
            id: 4,
            alerttype: this.$t('reporalertlisting.dueforcontractexpiry'),
            length: res.data.rec.length,
          });
          this.getDuedocumentexpiryHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuedocumentexpiryHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/duedocumentexpiry/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDuedocument = res.data.rec;

          vm.alertItems.push({
            id: 5,
            alerttype: this.$t('reporalertlisting.duefordocexpiry'),
            length: res.data.rec.length,
          });
          this.getDuebirthdayHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuebirthdayHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/duebirthday/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDuebirthday = res.data.rec;

          vm.alertItems.push({
            id: 6,
            alerttype: this.$t('reporalertlisting.duefordob'),
            length: res.data.rec.length,
          });
          this.getDuework3yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework3yearsHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: `/duework3years/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          vm.dataItemsDuework3years = res.data.rec;

          vm.alertItems.push({
            id: 7,
            alerttype: this.$t('reporalertlisting.dueforwanthree'),
            length: res.data.rec.length,
          });
          this.getDuework5yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework5yearsHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url: `/duework5years/all/${this.$store.getters.userId}`
      })

        .then((res) => {
          vm.dataItemsDuework5years = res.data.rec;

          vm.alertItems.push({
            id: 8,
            alerttype: this.$t('reporalertlisting.dueforwanfive'),
            length: res.data.rec.length,
          });
          this.getDuework10yearsHandler();
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    getDuework10yearsHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/duework10years/all/${this.$store.getters.userId}`
      })

        .then((res) => {
          vm.dataItemsDuework10years = res.data.rec;

          vm.alertItems.push({
            id: 9,
            alerttype: this.$t('reporalertlisting.dueforwanten'),
            length: res.data.rec.length,
          });

          console.log("alert8", vm.alertItems);
        })
        .catch((err) => {
          vm.errMsg = err;
        });
    },

    loadAlertHandler(panel) {
      let alert = this.getpanel;
      this.$router.push({ name: "alertlisting", params: { panel, alert } });
    },

    loadPage() {
      this.$router.push({ name: "wizardlisting" });
    },

    loadAlert() {
      this.$router.push({ name: "alertlisting" });
    },
  },

  mounted() {
    this.loadWizardList();

    this.getDueconfirmdateHandler();
  },
};
</script>