<template>
    <div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
      ></loading>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="getHLBExportListHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
//   import XLSX from 'xlsx/xlsx';
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
      Loading,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
        },
        //pageTitle: 'MBB-EMAS-01',
  
        id: this.parentObject.id,
        data: this.parentObject.data,
        salstatutoryother: null,
        psnstatutoryinfo: [],
        psnpayroll: [],
        titleData: this.parentObject.data.socsosubrecpy[0].payoutdesc,
        statecodeList: [
          {
              text: 'Johor',
              value: '01',
          },
          {
              text: 'Kedah',
              value: '02',
          },
          {
              text: 'Kelantan',
              value: '03',
          },
          {
              text: 'Melaka',
              value: '04',
          },
          {
              text: 'Negeri Sembilan',
              value: '05',
          },
          {
              text: 'Pahang',
              value: '06',
          },
          {
              text: 'Pulau Pinang',
              value: '07',
          },
          {
              text: 'Perak',
              value: '08',
          },
          {
              text: 'Perlis',
              value: '09',
          },
          {
              text: 'Selangor',
              value: '10',
          },
          {
              text: 'Terengganu',
              value: '11',
          },
          {
              text: 'Sabah',
              value: '12',
          },
          {
              text: 'Sarawak',
              value: '13',
          },
          {
              text: 'Wilayah Persekutuan (Kuala Lumpur)',
              value: '14',
          },
          {
              text: 'Wilayah Persekutuan (Labuan)',
              value: '15',
          },
          {
              text: 'Wilayah Persekutuan (Putrajaya)',
              value: '16',
          }
        ],
  
        filetypeList: [
          {
            text: '8A',
            value: '8A',
          },
          {
            text: '8B',
            value: '8B',
          }
        ],
  
        companyrecnum: "",
        bankcode: "011",
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
        filler: "",
        totalContAmt: null,
        totalRefno: null,
        loading : false,
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
        socsodata: [],
  
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
      
      getHLBExportListHandler() {
  
        this.loading = true;
        const vm = this
  
        let dateHolder2 = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder2).format("YYYY-MM-DD");
        
        this.$store.dispatch('get', {
            url:  `/hsbcsocsoexport/all/${this.$store.getters.userId}`
            + "/" +  this.data.socsoexprecid
            + "/" + this.data.refnum + "/" + date + "/" + date
        })
        .then(function (res) {
          vm.loading = false;
  
          vm.socsodata = res.data.rec;
          
           vm.exportHandler(); 
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      async getData() {
      const vm = this
      // let holdAmtEmployer = ''
      // let holdAmtMember = ''
      let body = []
      let newsocsodetail = []
      let holdpayEmployer = []
      let holdpayMember = []
      let holdpayRefno = []
      let dataholder = vm.socsodata
      let dataholderval = dataholder.filter(el => el.amount)
      let socsodetail = this.filterSOCSO(dataholderval);

      this.processedCount = dataholderval.length;
      this.successCount = socsodetail.length;
      this.errCount = dataholderval.length - socsodetail.length;
      // let yearholder = this.data.year
      // let monthholder = this.data.month + 1
      // if (monthholder > 12) {
      //   monthholder = 1
      //   yearholder++
      // }
      // let getyear = yearholder.toString()
      // let getdate = this.padRJ(monthholder, 2) + getyear
      // let currentdate = moment().format('YYYYMMDD')
      // let currenttime = moment().format('hhmmss')
      let contdateholder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
      let contdate = moment(contdateholder).format("MMYYYY");
      
      socsodetail.forEach(function (o) {
        var existing = newsocsodetail.filter(function (i) {
            return i.staffid === o.staffid;
        })[0];
        if (!existing || existing == undefined) {
            newsocsodetail.push(o);
        } else {
            existing.amount += o.amount;
        }
      });
      
      newsocsodetail.forEach(element => {
        let amt = 0
        amt = element.amount.toFixed(2)

        //let idno = ''
        //element.icno ? idno = element.icno : idno = element.refno

        body.push({
          "Employer Code": this.padSpaceRJ(this.data.refnum.replace(/-/g, ''), 12),
          "SSM Number": this.padSpaceLJ(element.regno.replace(/-/g, ''), 20),
          "ID No": this.padSpaceLJ(element.refno.replace(/-/g, ''), 12),
          "Employee Name": this.padSpaceLJ(element.psnname, 150),
          "Month Conribution": this.padRJ(contdate, 4),
          "Employee Amount": this.padRJ(amt.split(".").join(""), 14),
          "Space": this.padSpaceLJ(' ', 9),
        });

        holdpayEmployer.push(element.amount);
        if (element.refno) holdpayRefno.push(parseInt(element.refno));
      });

      if (holdpayEmployer.length > 0) {
        let totalAmtEmpHolder = holdpayEmployer.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtEmployer = totalAmtEmpHolder.toFixed(2);
        // holdAmtEmployer = this.totalAmtEmployer.split(".").join("");
      }

      if (holdpayMember.length > 0) {
        let totalAmtMemHolder = holdpayMember.reduce(function (a, b) {return a + b}, 0);
        this.totalAmtMember = totalAmtMemHolder.toFixed(2);
        // holdAmtMember = this.totalAmtMember.split(".").join("");
      }

      // if (holdpayRefno.length > 0) {
      //   getAmtRefno = holdpayRefno.reduce(function (a, b) {
      //       return a + b;
      //   }, 0);
      // }

      if (body.length > 0) {
        const txtData = this.objectToTxt(body);
        this.download(txtData);
      }
    },
  
      filterSOCSO(item) {
        let checkdet = this.detailfilter(item)
        let checkactive = this.activefilter(checkdet)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      detailfilter(item) {
          let dateHolder = this.data.year + "-" + this.padRJ(this.data.month, 2) + "-" 
          + this.daysInMonth(this.data.month, this.data.year);
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let dataitem = item.filter(e => e.refnum == this.data.refnum)
          dataitem = dataitem.filter(e => {
              return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
          })
          let noacc = item.filter((e) => !dataitem.includes(e));
          for (let i = 0; i < noacc.length; i++) {
              noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
              this.accErr.push(noacc[i]);
          }
          return dataitem
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => {
            return e.amount != 0
        })
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToTxt(data) {
        const csvRows = [];

        const dataheaders = Object.keys(data[0]);

        //loop over the rows
        for (const row of data) {
            const values = dataheaders.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"');
            return `${escaped}`;
            });
            csvRows.push(values.join(""));
        }

        return csvRows.join("\n");
      },
  
      download(data) {
        const newFile = this.convertLFtoCR_LF(data)
        // const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text" });
        const blob = new Blob([newFile], { type: "text/plain;charset=utf-8" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", 'P' + this.data.refnum.replace(/-/g, '') + '_' + this.padRJ(this.data.month, 2) + this.data.year + ".txt");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },

      convertLFtoCR_LF(fileContent) {
        const lines = fileContent.split("\n");
        const newLines = [];
        
        for (let i = 0; i < lines.length; i++) {
          newLines.push(lines[i].concat("\r\n"));
        }
        
        return newLines.join("");
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalContAmt = this.totalContAmt;
          item.accounterr = this.accErr;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
  
          if (!this.isErr) {
            this.$router.push({ name: "monthlysocsoexportlog", params: { item } });
          }
        }
      },
  
      backHandler() {
        this.$router.push({ name: "monthlysocsoexport" });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
      mounted() {
        //  this.getHLBExportListHandler();
      },
  };
  </script>