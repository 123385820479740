<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.pageTitle }}
              <span class="page_title_value">{{ this.pageTitleValue }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n8">
            <v-file-input
              :label="this.$t('processclockdatarecord.file')"
              ref="importfile"
              v-model="formData.importfile"
              outlined
              dense
              show-size
              prepend-icon=""
              accept=".txt, .xlsx"
              style="font-size: 14px"
              :rules="[rules.required]"
            >
            </v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('processclockdatarecord.inoutindicator')"
              type="text"
              v-model="formData.indicator"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('processclockdatarecord.startperioddate')"
              type="date"
              ref="startdate"
              :rules="[rules.required]"
              v-model="formData.startdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('processclockdatarecord.endperioddate')"
              type="date"
              ref="enddate"
              :rules="[rules.required]"
              v-model="formData.enddate"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('processclockdatarecord.selectimportfileformat')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.logicid"
              dense
              :items="logiclist"
              @change="ChangeLogic(formData.logicid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" class="mt-n8">
            <v-checkbox
              outlined
              v-model="formData.isOverridePending"
              :label="this.$t('processclockdatarecord.overridependingrecordsforsameperiod')"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="mt-n8">
            <v-checkbox
              outlined
              v-model="formData.isOverrideAll"
              :label="this.$t('processclockdatarecord.overrideallrecordsforsameperiod')"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row style="align-content: center; align-items: center">
          <v-col cols="10" sm="10" class="pb-n5 mt-n8"
            ><div>{{$t('processclockdatarecord.otrounding')}}</div></v-col
          >
          <v-col cols="3" sm="3" class="pb-n5 mt-n3">
            <v-autocomplete
              outlined
              :label="this.$t('processclockdatarecord.selectrounding')"
              type="text"
              :rules="[rules.required]"
              v-model="formData.otrounding"
              dense
              :items="roundinglist"
            ></v-autocomplete>
          </v-col>
          <v-col cols="1" sm="1" class="pb-n5 mt-n8">{{$t('processclockdatarecord.tonearest')}}</v-col>
          <v-col cols="3" sm="3" class="pb-n5 mt-n3">
            <v-text-field
              outlined
              :label="this.$t('processclockdatarecord.minutes')"
              type="number"
              v-model="formData.roundingmin"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" sm="1" class="pb-n5 mt-n8">{{$t('processclockdatarecord.minutes')}}</v-col>
        </v-row>

        <v-row>
          <v-col cols="10" class="pb-n5 mt-n8">
            <v-btn
              color="#6ACB9A"
              style="width: 100px; color: white"
              v-on:click="processHandler()"
              >{{$t('button.process')}}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialogue from "../../../components/common/ErrorDialog";
import moment from "moment";
import Loading from "vue-loading-overlay";

export default {
  components: {
    "app-error": ErrorDialogue,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        requiredRouding: (value) => !!value || this.$t('reportsparam.required'),
      },
      roundinglist: [
        { text: this.$t('processclockdatarecord.donotround'), value: "do not round" },
        { text: this.$t('processclockdatarecord.rounddown'), value: "round down" },
        { text: this.$t('processclockdatarecord.roundup'), value: "round up" },
      ],
      pageTitle: this.$t('processclockdata'),
      pageTitleValue: "",
      formData: {
        importfile: null,
        indicator: null,
        startdate: moment().format("YYYY-MM-DD"),
        enddate: moment().format("YYYY-MM-DD"),
        logicid: null,
        logiccode: null,
        isOverridePending: true,
        isOverrideAll: null,
        otrounding: null,
        roundingmin: null,
        userid: this.$store.getters.userId,
      },
      logiclist: [],
      logic: [],
      errMsg: "",
      isErr: false,
      editMode: false,
      loading: false,
    };
  },

  methods: {
    processHandler() {
      const vm = this;

      if (this.formData.importfile == null) {
        this.$dialog.alert(this.$t('selectfileimport')).then((dialog) => {
        console.log(dialog);
        });

      /*} else if(this.formData.indicator == null){

        this.$dialog.alert(this.$t('fillinoutindicator')).then((dialog) => {
        console.log(dialog);
        });
      */
      } else if (this.formData.logicid == null){
          this.$dialog.alert(this.$t('selectimportfileformat')).then((dialog) => {
          console.log(dialog);
        });
      } else if (this.formData.otrounding == null){
          this.$dialog.alert(this.$t('selecttheroundingformat')).then((dialog) => {
          console.log(dialog);
        });
      } else {

      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }

      console.log(vm.formData);

      let data = new FormData();
      data.append("importfile", this.formData.importfile);
      data.append("indicator", this.formData.indicator);
      data.append("startdate", this.formData.startdate);
      data.append("enddate", this.formData.enddate);
      data.append("logicid", this.formData.logicid);
      data.append("logiccode", this.formData.logiccode);
      data.append("isOverridePending", this.formData.isOverridePending);
      data.append("isOverrideAll", this.formData.isOverrideAll);
      data.append("otrounding", this.formData.otrounding);
      data.append("roundingmin", this.formData.roundingmin);
      data.append("userid", this.$store.getters.userId);
      data.append("headers", {
            "Content-Type": "multipart/form-data",
          });
      this.loading = true;
      this.$store.dispatch('post', {url: "/processclockdata/process", payload: data})
        .then((res) => {
          this.loading = false;
          console.log(res.data);
          if (res.data.status == 201) {
            this.$dialog
              .alert(this.$t('piarabrpl'))
              .then((dialog) => {
                console.log(dialog);
              });
            // this.$router.push({
            //   name: "processclockdatalog",
            //   params: {
            //     staffcount: res.data.res.staffcount,
            //     processed: res.data.res.processed,
            //     uploaded: res.data.res.uploaded,
            //   },
            // });
          } else {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          this.loading = false;
          vm.isErr = true;
          vm.errMsg = err;
        });
      }
    },
    loadLogicHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: "/processclockdata/getfileformat"})
        .then(function (res) {
          vm.logic = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.logiclist.push({
              text: res.data.rec[i].logicname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },
    ChangeLogic(id) {
      let item = this.logic.find((item) => item.id === id);
      this.formData.logicid = item.id;
      this.formData.logiccode = item.logiccode;
    },
  },
  mounted() {
    this.loadLogicHandler();
  },
};
</script>
