<template>
    <div>
        <!-- <app-form
            v-bind:parentObject="{
                data: this.formData,
                errMsg: this.errMsg,
            }"
            v-on:input="formData = $event"
        /> -->
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="3" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.fromdate')"
                                    type="date"
                                    ref="fromDate"
                                    :rules="[rules.required]"
                                    v-model="formData.fromDate"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="3" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.untildate')"
                                    type="date"
                                    ref="untilDate"
                                    :rules="[rules.required]"
                                    v-model="formData.untilDate"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="6" class="pb-n5 ">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportservicemovlisting.movetype')"
                                    type="text"
                                    ref="movtypecode"
                                    hide-selected
                                    v-model="formData.movtypecode"
                                    dense
                                    :items="codeList"
                                    :rules="[rules.required]"
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                    >
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>

import FilterParameter from '../../../components/common/FilterParameter'
import ErrorDialog from "../../../components/common/ErrorDialog"

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
        'app-error': ErrorDialog,
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                fromDate: null,
                untilDate: null,
                movtypecode: null
            },
            
            parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                employmenttypeid: null,
                jobid: null
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                employmenttypeid: true,
                jobid: true
            },

            codeList: [],
            errMsg: "",
            isErr: false
        }
    },

    methods: {
        // errorHandler($event) {
        //     this.isErr = $event.isErr;
        //     this.errMsg = $event.errMsg;
        // },

        loadMovTypeCode(){
            const vm = this;

            this.$store.dispatch('get', {url: '/servicemovementlisting/all'})
                .then(res => {
                    vm.movtypecodes = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++){
                        vm.codeList.push(
                            {
                                text: res.data.rec[i].movtypedesc,
                                value: res.data.rec[i].movtypecode
                            }
                        )
                    }

                    if (vm.formData.movtypecode){
                        vm.changeMovtypecodeHandler(vm.formData.movtypecode);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                })
        },

        changeMovtypecodeHandler(id){
            this.movtypecodes.find(item => item.id === id);
        },

        goHandler(){
             const vm = this;

            const formData = this.formData
            const param = this.parameters
           

           // if(this.formData.fromDate < this.formData.untilDate){
                if(this.formData.fromDate != null && this.formData.untilDate != null && this.formData.movtypecode != null){
                    this.$router.push({
                        name: 'detailsservicemovementlisting', params: { formData, param }
                    });
                }

                else {

                this.$refs.form.validate()           
            }
           // }

            this.$store.dispatch('post', {url: "/servicemovementlisting/validate", payload: vm.formData})
                .then(res => {
                    if(res.data.error){
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    }
                })
        },

        updateValue(){
            this.$emit("input", {
                movtypecode: this.formData.movtypecode
            })
        },
    },

    mounted() {
        this.loadMovTypeCode();
    },
    
}
</script>