<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5"></v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.unit')"
                                id="unitcode"
                                type="text"
                                ref="unitcode"
                                :rules="[rules.required]"
                                v-model="formData.unitcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="unitname"
                                :rules="[rules.required]"
                                v-model="formData.unitname"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            formData: {
                unitcode: this.parentObject.data.unitcode,
                unitname: this.parentObject.data.unitname,
                // startdate: this.parentObject.data.startdate,
                // enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                isdisable: this.parentObject.data.isdisable,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

             search: this.parentObject.searchnew
        }
    },

    methods: {
        updateValue() {
            this.$emit('input', {
                unitcode: this.formData.unitcode,
                unitname: this.formData.unitname,
                // startdate: this.formData.startdate,
                // enddate: this.formData.enddate,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'unitlist', params: { search: this.search}});
        }
    }
}
</script>