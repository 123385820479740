<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">{{this.pageTitle}}</h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-card>
            <div class="ma-4">
              <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
              <v-row>
                <v-col cols="4" sm="4" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.year1')"
                    type="text"
                    ref="year"
                    hide-selected
                    v-model="formData.year1"
                    dense
                    :items="yearList"
                    :rules="[rules.required]"
                    clearable
                    :disabled="isDisabledYearMonth"
                    @change="payoutController(formData.year1)"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="2" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.month1')"
                    type="text"
                    ref="month"
                    hide-selected
                    v-model="formData.month1"
                    dense
                    :items="monthList"
                    clearable
                    :disabled="isDisabledYearMonth"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="6" class="pb-n5">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.payout1')"
                    type="text"
                    ref="payout"
                    hide-selected
                    v-model="formData.payout1"
                    dense
                    :items="payoutList"
                    :rules="[rules.required]"
                    clearable
                    @change="yearAndMonthContoller(formData.payout1)"
                    :disabled="isDisablePayout"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.year2')"
                    type="text"
                    ref="year"
                    hide-selected
                    v-model="formData.year2"
                    dense
                    :items="yearList"
                    :rules="[rules.required]"
                    @change="payoutController(formData.year1)"
                    clearable
                    :disabled="isDisabledYearMonth"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="2" class="pb-n5 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.month2')"
                    type="text"
                    ref="month"
                    hide-selected
                    v-model="formData.month2"
                    dense
                    :items="monthList"
                    clearable
                    :disabled="isDisabledYearMonth"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="6" class="pb-n5 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('payrollreconciliationreport.payout2')"
                    type="text"
                    ref="payout"
                    hide-selected
                    v-model="formData.payout2"
                    dense
                    :rules="[rules.required]"
                    :items="payoutList"
                    clearable
                    :disabled="isDisablePayout"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <app-parameter 
              class="mt-5 mb-3"
              v-on:input="parameters = $event"
              v-bind:parentObject="{
                  filter: this.showFilter,
              }"
          />
        </v-container>

        <v-row>
          <div class="pt-5">
            <v-row>
              <v-col>
                <v-btn color="warning" style="width: 100px" @click="goHandler">
                  {{$t('button.go')}}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>

import FilterParameter from '../../../components/common/FilterParameter'

export default {
  props: {
    parentObject: Object
  },

  components: {
    'app-parameter': FilterParameter,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      pageTitle: this.$t('payrollreconciliationreport.payrollreconciliation'),

      formData: {
        year1: null,
        month1: null,
        payout1: null,
        year2: null,
        month2: null,
        payout2: null,
      },

      parameters: {
          staffid: null,
          gradeid: null,
          employmenttypeid: null,
          companyid: null,
          branchid: null,
          departmentid: null
      },

      showFilter: {
          staffid: true,
          gradeid: true,
          employmenttypeid: true,
          companyid: true,
          branchid: true,
          departmentid: true
      },

      yearList: [],
      monthList: [],
      payouts: [],
      payoutList: [],
      isDisabledYearMonth: false,
      isDisablePayout: false,
    };
  },

  methods: {

    yearGeneration() {
      for (let i = 0; i < 200; i++) {
          this.yearList.push(2000 + i)
      }
    },

    monthGeneration() {
      for (let i = 1; i <= 12; i++) {
          this.monthList.push(i)
      }
    },

    yearAndMonthContoller(item) {
      if (!item) {
        this.isDisabledYearMonth = false
      } else {
        this.isDisabledYearMonth = true
      }
    },

    payoutController(item) {
      if (!item) {
        this.isDisablePayout = false
      } else {
        this.isDisablePayout = true
      }
    },

    //load payroll groups
    loadPayoutListHandler(){
        const vm = this;

        this.$store.dispatch('get', {url: '/payoutctrl/all'})
        .then(res => {
            let payouts = res.data.rec;

            for (let i = 0; i < payouts.length; i++) {
                vm.payoutList.push(
                    {
                        text: res.data.rec[i].payoutcode,
                        value: res.data.rec[i].id
                    }
                )                        
            }
        })
        .catch(err => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
        });
    },

    

    goHandler() {
      const formData = this.formData
      const param = this.parameters

      if (this.formData.year1 != null && this.formData.year2 != null)  {
          if ((this.formData.year1 == this.formData.year2 &&
              this.formData.month1 == this.formData.month2) || 
              (this.formData.year1 == this.formData.year2 &&
              (this.formData.month1 == null || this.formData.month2 == null))) {
              alert(this.$t('payrollreconciliationreport.alermsg1'));
          } else {
              this.$router.push({name: 'payrollreconciliation', params: { formData, param }});
          }
       } else if (this.formData.payout1 != null && this.formData.payout2 != null) {
          if (this.formData.payout1 == this.formData.payout2) {
              alert(this.$t('payrollreconciliationreport.alermsg1'));
          } else {
              this.$router.push({name: 'payrollreconciliation', params: { formData, param }});
          }
       } else {
          this.$refs.form.validate()
       }
    },
  },

  mounted() {
    this.yearGeneration();
    this.monthGeneration();
    this.loadPayoutListHandler();
  },
};
</script>