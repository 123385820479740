<template>
    <div>
        <v-dialog v-model="exportreportdialog" max-width="1200px">

      <template v-slot:activator="{ on }">
           <v-row>
          <v-col cols="1">
            <v-btn color="primary" dark class="mb-2" v-on="on"
            this.parentObject.maptypeid>+</v-btn>
          </v-col>

          <v-col>
          </v-col>
          <v-col cols="2">
              <v-text-field
                  v-model="search"
                  append-icon="search"
                  style="width: 300px"
                  class="pr-5 pt-3 "
                  hide-details
              ></v-text-field>
          </v-col>
        </v-row>

         </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">Bank Record</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                  <v-row>
                       <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('setupreport_exportmapping.maptypedesc')"
                            type="text"
                            v-model="selections[0].maptypedesc"
                            dense
                        ></v-text-field>
                    </v-col>
                  </v-row>
                   
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupreport_exportmapping.internalcode')"
                                type="text"
                                ref="bankcode"
                                :rules="[rules.required]"
                                :items="bankList"
                                clearable
                                hide-selected
                                v-model="selections[0].intcode"
                                @change="changeBankHandler(selections[0].intcode)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                               outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('setupreport_exportmapping.internaldesc')"
                                type="text"
                                v-model="selections[0].intdesc"
                                dense
                            ></v-text-field>
                        </v-col>
                 </v-row>           

                     <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupreport_exportmapping.externalcode')"
                            type="text"
                            v-model="selections[0].extcode"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>

                     </v-row>

                     
                     <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setupreport_exportmapping.externaldesc')"
                            type="text"
                            v-model="selections[0].extdesc"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>

                     </v-row>
             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeExportreportInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveExportreportInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            :search="search"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            exportreportdialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            maptypeid: this.parentObject.maptypeid,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                maptypeid: null,
                maptypedesc: null,
                intcode: null,
                intdesc: null,
                extcode: null,
                extdesc:null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],  

             itemHolder: [],

             bankList: [],
             bank: [],
             bankcode: '',
             bankname: '',

             maptypes: [],
            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
            search: '',
        }
    },

    methods: {
        compareArray,

        
        loadMapTypeList(){

            const vm = this;

            this.$store.dispatch('get', {
                url:  '/maptype/' + this.parentObject.maptypeid})
                .then(res => {
                    vm.maptypes = res.data.rec;

                    this.selections[0].maptypedesc = res.data.rec[0].maptypedesc;
                    this.selections[0].maptypeid = res.data.rec[0].id;

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });

        },

        loadBank() {
           const vm = this;

            this.$store.dispatch('get', {
                url:  '/bank/all'})
                .then(res => {
                    vm.bank = res.data.rec;

                   // console.log('maptype', vm.maptypes)

                    for (let i = 0; i < vm.bank.length; i++) {
                    vm.bankList.push({
                        text: res.data.rec[i].bankcode,
                        code: res.data.rec[i].bankname,
                        value: res.data.rec[i].bankcode,
                        
                    });
                }

                 if (vm.selections[0].intcode){
                         vm.changeBankHandler(vm.selections[0].intcode);
                     }

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });

    
        },

        changeBankHandler(bankcode) {
              var item = this.bank.find(item => item.bankcode === bankcode);

              this.selections[0].intcode = item.bankcode;
              this.selections[0].intdesc = item.bankname;
        },


       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.exportreportdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveExportreportInput() {

            
             if (this.selections[0].intcode == null ) {
                 this.$dialog.alert(this.$t('setupreport_exportmapping.intcodemessage')).then((dialog) => {
                console.log(dialog);
             });
             }
              else if(this.selections[0].intdesc == null) {
                 this.$dialog.alert(this.$t('setupreport_exportmapping.intdescmessage')).then((dialog) => {
                console.log(dialog);
             });}
             else if (this.selections[0].extcode == null){
                 this.$dialog.alert(this.$t('setupreport_exportmapping.extcodemessage')).then((dialog) => {
                console.log(dialog);
             });
             } else if (this.selections[0].extdesc == null ){

              this.$dialog.alert(this.$t('setupreport_exportmapping.extdescmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
          //  }

            this.$emit('input', this.detailItem);
            this.exportreportdialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
      
        },

        closeExportreportInput() {
            this.exportreportdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.exportreportdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
               // clmtypeid: this.selections[0].clmtypeid,
                id: null,
                maptypeid: this.parentObject.maptypeid,
                maptypedesc: this.selections[0].maptypedesc,
                intcode: null,
                intdesc: null,
                extcode: null,
                extdesc:null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {

        this.loadMapTypeList();
        this.loadBank();
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>