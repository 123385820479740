<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">
            {{ this.pageTitle
            }}<span class="page_title_value">{{ this.pageTitleValue }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="8" sm="8" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('payrollprocessrun.payout')"
                type="text"
                :rules="[rules.required]"
                :items="payoutctrlList"
                v-model="poid"
                @change="changePayout(poid)"
                dense
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.description')"
                type="text"
                v-model="payoutdesc"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsparam.year')"
                type="number"
                v-model="payoutyear"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('reportsparam.month')"
                type="number"
                v-model="payoutmonth"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('payrollcommandstep.selectcommand')"
                type="text"
                :rules="[rules.required]"
                :items="payoutstatusList"
                v-model="pycmdstatuscode"
                dense
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-toolbar-title class="subtitle-2">{{$t('payeeinputprocess.expand')}}</v-toolbar-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-4">
                      <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payoutstartdate')"
                          type="date"
                          v-model="startdate"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payoutenddate')"
                          type="date"
                          v-model="enddate"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payoutstatus')"
                          readonly
                          type="text"
                          v-model="pystatusdesc"
                          dense
                          background-color="#ffeab2"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('setuppayrolltype.payrolltype')"
                          type="text"
                          v-model="payrolltypedesc"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payrollgroup')"
                          type="text"
                          v-model="grouppdesc"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.exchangerate')"
                          type="text"
                          v-model="exchratedesc"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payoutfrequency')"
                          type="text"
                          v-model="salfreqdesc"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('payrollprocessrun.payeelist')"
                          type="text"
                          v-model="payeelistdesc"
                          readonly
                          background-color="#ffeab2"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <v-row>
                <v-col class="pl-5">
                  <v-toolbar-title class="subtitle-2">FILTER</v-toolbar-title>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="Staff Code"
                    type="text"
                    ref="staff"
                    :items="staffcodeList"
                    v-model="staffid"
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="Name"
                    type="text"
                    ref="name"
                    :items="nameList"
                    v-model="staffid"
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4" sm="4" class="pb-4">
                  <v-autocomplete
                    outlined
                    :label="Payroll Group"
                    type="text"
                    ref="payrollgroup"
                    :items="payrollgroupList"
                    v-model="payrollgroupid"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row> -->
          
          <v-row class="pt-4">
            <v-col class="pb-n5 mt-n8">
              <app-parameter 
                v-on:input="parameters = $event"
                v-bind:parentObject="{
                  filter: this.showFilter,
                }"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <div class="pt-5 pl-4">
            <v-btn color="warning" style="width: 100px;margin-bottom: 20px;" @click="nextHandler">
              {{$t('button.next')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import FilterParameter from '../../../components/common/FilterParameter'

export default {
  // props: {
  //     parentObject: Object
  // },
  components: {
    'app-parameter': FilterParameter,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('payrollcommandstep.payrollcommand'),
      pageTitleValue: "",

      poid: null,
      payoutdesc: null,
      startdate: null,
      enddate: null,
      payoutyear: null,
      payoutmonth: null,
      pycmdstatuscode: null,
      pystatusdesc: null,
      payrolltypedesc: null,
      grouppdesc: null,
      exchratedesc: null,
      salfreqdesc: null,
      payeelistdesc: null,
      staffid: null,
      payrollgroupid: null,

      payoutstatusList: [],
      payoutctrls: [],
      payoutctrlList: [],
      salhdItems: [],
      staffcodeList: [],
      nameList: [],
      payrollgroupList: [],
      pycommandStatus: [],

      errMsg: "",
      isErr: false,
      editMode: false,

      parameters: {
          staffid: null,
          gradeid: null,
          employmenttypeid: null,
          companyid: null,
          branchid: null,
          departmentid: null
      },
      showFilter: {
          staffid: true,
          gradeid: true,
          employmenttypeid: true,
          companyid: true,
          branchid: true,
          departmentid: true
      },
    };
  },

  methods: {
    loadPyCommandStatusListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  '/pycommandstatus/all'
        })
        .then((res) => {
          vm.pycommandStatus = res.data.rec;

          for (let i = 0; i < vm.pycommandStatus.length; i++) {
            if (vm.pycommandStatus[i].pycmdstatuscode != 10) {
              vm.payoutstatusList.push({
                text: vm.pycommandStatus[i].pycmdstatusdesc,
                value: vm.pycommandStatus[i].pycmdstatuscode,
              });
            }
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPayoutListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  '/payoutcontrol/all'
        })
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            if (vm.payoutctrls[i].startdate) {
              vm.payoutctrls[i].startdate = moment(
                vm.payoutctrls[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.payoutctrls[i].enddate) {
              vm.payoutctrls[i].enddate = moment(
                vm.payoutctrls[i].enddate
              ).format("YYYY-MM-DD");
            }

            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    getSalHdListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
        url:  `/ppsummarylist/allfilter/${this.$store.getters.userId}`
        })
        .then(function (res) {
          vm.salhdItems = res.data.rec;
          console.log("vm.salhdItems", vm.salhdItems);

          for (let i = 0; i < vm.salhdItems.length; i++) {
            vm.staffcodeList.push({
              text: vm.salhdItems[i].staffcode,
              value: vm.salhdItems[i].staffid,
            });

            vm.nameList.push({
              text: vm.salhdItems[i].psnname,
              value: vm.salhdItems[i].staffid,
            });

            vm.payrollgroupList.push({
              text: vm.salhdItems[i].payrollgroupdesc,
              value: vm.salhdItems[i].payrollgroupid,
            });
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    changePayout(id) {
      let item = this.payoutctrls.find((item) => item.id == id);

      this.payoutdesc = item.payoutdesc;
      this.startdate = item.startdate;
      this.enddate = item.enddate;
      this.payoutyear = item.pyyear;
      this.payoutmonth = item.pymonth;

      this.pystatusdesc = item.pystatusdesc;
      this.payrolltypedesc = item.payrolltypedesc;
      this.grouppdesc = item.grouppdesc;
      this.exchratedesc = item.exchratedesc;
      this.salfreqdesc = item.salfreqdesc;
      this.payeelistdesc = item.payeelistdesc;
    },

    nextHandler() {
      let param = this.parameters
      param.poid = this.poid;
      param.pycmdstatuscode = this.pycmdstatuscode;

      if (!this.isErr && this.$refs.form.validate()) {
        this.$router.push({ name: "payrollcommandprocess", params: param });
      }
    },
  },

  mounted() {
    this.loadPayoutListHandler();
    this.getSalHdListHandler();
    this.loadPyCommandStatusListHandler();
  },
};
</script>