<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        editMode: this.editMode,
        disable: this.disable,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:new="createHandler"
      v-on:error="errorHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import PayeeInputForm from "../../../components/payroll/payeeinput/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": PayeeInputForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('payeeinputprocess.editpayeeinput'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      disable: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadPayeeInputHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payeeinput/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];

          vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.formData.userid = this.$store.getters.userId;
          this.loadPayeeInputDetailHandler(vm.formData.id);

          //vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadPayeeInputDetailHandler(id) {
      const vm = this;

      this.$store.dispatch('get', {url: "/payeeinputd/" + id})
        .then((res) => {
          vm.formData.payeeinputdetail = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            if (res.data.rec[i].startdate) {
              vm.formData.payeeinputdetail[i].startdate = moment(
                res.data.rec[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (res.data.rec[i].enddate) {
              vm.formData.payeeinputdetail[i].enddate = moment(
                res.data.rec[i].enddate
              ).format("YYYY-MM-DD");
            }
          }

          for (let i = 0; i < vm.formData.payeeinputdetail.length; i++) {
            Object.assign(vm.formData.payeeinputdetail[i], { action: 3 });
          }
      

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadPayoutHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/payoutctrl/" + this.id})
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          vm.payoutctrls[0].startdate = moment(
            vm.payoutctrls[0].startdate
          ).format("YYYY-MM-DD");
          vm.payoutctrls[0].enddate = moment(vm.payoutctrls[0].enddate).format(
            "YYYY-MM-DD"
          );

          vm.formData.payoutdesc = vm.payoutctrls[0].payoutdesc;
          vm.formData.startdate = vm.payoutctrls[0].startdate;
          vm.formData.enddate = vm.payoutctrls[0].enddate;
          vm.formData.payoutyear = vm.payoutctrls[0].pyyear;
          vm.formData.payoutmonth = vm.payoutctrls[0].pymonth;
          vm.formData.pystatuscode = vm.payoutctrls[0].pystatuscode;
          vm.formData.pystatusdesc = vm.payoutctrls[0].pystatusdesc;
          vm.formData.payrolltypedesc = vm.payoutctrls[0].payrolltypedesc;
          vm.formData.grouppdesc = vm.payoutctrls[0].grouppdesc;
          vm.formData.exchratedesc = vm.payoutctrls[0].exchratedesc;
          vm.formData.salfreqdesc = vm.payoutctrls[0].salfreqdesc;
          vm.formData.payeelistdesc = vm.payoutctrls[0].payeelistdesc;

          if (this.formData.pystatusdesc == "FINALIZED"){
            vm.disable = true;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    saveHandler() {
      const vm = this; 
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if(vm.disable == true ){

        this.$dialog.alert("Finalized records are not allowed to edit").then((dialog) => {
          console.log(dialog);
        });

      } else {

      this.$store.dispatch('put', {url: "/payeeinput/" + vm.formData.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "payeeinputlist" , params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
      }
    },

    cancelHandler() {
      this.$router.push({ name: "payeeinputlist" });
    },

    createHandler() {
      this.$router.push({ name: "payeeinputnew" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

        if(vm.disable == true ){

         this.$dialog.alert("Finalized records are not allowed to delete").then((dialog) => {
           console.log(dialog);
         });

       } else {

        
      this.$store.dispatch('delete', {url:
            "/payeeinput/" +
            vm.formData.payoutctrlid +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "payeeinputlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
      }
    },
    
  },

  mounted() {
    this.loadPayeeInputHandler();
    this.loadPayoutHandler();
  },
};
</script>