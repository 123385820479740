<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle
                        }}<span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.payitem')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payItemList"
                                v-model="formData.payitemid"
                                @change="changePayItemHandler"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="6" sm="6" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppayitem.payitem')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payItemList"
                                v-model="formData.payitemid"
                                readonly
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mb-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="payItemCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppayitmetype.payitemtype')"
                                type="text"
                                readonly
                                background-color="#ffeab2"
                                :rules="[rules.required]"
                                v-model="payItemTypeDesc"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8 mb-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="payItemTypeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.payratedetail
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                }
            },

            formData: {
                payitemid: this.parentObject.data.payitemid,
                payitemtypeid: this.parentObject.data.payitemtypeid,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                lockstaff: this.parentObject.data.lockstaff,
                payratedetail: this.parentObject.data.payratedetail,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            selected: [],
            payItems: [],
            payItemList: [],
            payItemCode: "",
            payItemTypeCode: "",
            payItemTypeDesc: "",
             search: this.parentObject.searchnew,

            tabHeader: [
                {
                    text: this.$t('columns.actions'),
                    value: "action",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('columns.company'),
                    value: "compnycode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.branch'),
                    value: "brchcode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.division'),
                    value: "divncode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.department'),
                    value: "deptcode",
                    width: "140px",
                    sortable: true
                },
                {
                    text: this.$t('columns.section'),
                    value: "sectiocode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.unit'),
                    value: "unitcode",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.employmenttype'),
                    value: "emptype",
                    width: "200px",
                    sortable: true
                },
                {
                    text: this.$t('columns.grade'),
                    value: "grddesc",
                    width: "120px",
                    sortable: true
                },
                {
                    text: this.$t('columns.job'),
                    value: "jobtitle",
                    width: "150px",
                    sortable: true
                },
                {
                    text: this.$t('setupepf.serviceperiod'),
                    value: "servprd",
                    width: "100px",
                    sortable: true
                },
                {
                    text: this.$t('setuppayrate.salary'),
                    value: "salary",
                    width: "100px",
                    sortable: true
                },
                {
                    text: this.$t('payrollcommandstep.salaryfrequency'),
                    value: "basicfreqcode",
                    width: "100px",
                    sortable: true
                },
                {
                    text: this.$t('columns.logic'),
                    value: "logicscript",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate1'),
                    value: "rate1",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate2'),
                    value: "rate2",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate3'),
                    value: "rate3",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate4'),
                    value: "rate4",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate5'),
                    value: "rate5",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate6'),
                    value: "rate6",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate7'),
                    value: "rate7",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate8'),
                    value: "rate8",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate9'),
                    value: "rate9",
                    width: "100px",
                    sortable: false
                },
                {
                    text: this.$t('setuppayrate.rate10'),
                    value: "rate10",
                    width: "100px",
                    sortable: false
                }
            ],
            affectItems: [],

            errMsg: "",
            isErr: false,
            isEdit: this.parentObject.isEdit
        };
    },

    methods: {
        loadPayItemsHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payitem/codes"})
                .then(res => {
                    vm.payItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemList.push({
                            text: res.data.rec[i].payitemdesc,
                            value: res.data.rec[i].id
                        });
                    }

                    if (vm.formData.payitemid) {
                        vm.changePayItemHandler(vm.formData.payitemid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changePayItemHandler(id) {
            let item = this.payItems.find(item => item.id === id);
            
            this.payItemCode = item.payitemcode;
            this.payItemTypeDesc = item.payitemtypedesc;
            this.payItemTypeCode = item.payitemtypecode;
            this.formData.payitemtypeid = item.payitemtypeid;

            this.updateValue();
        },

        addItemHandler($event) {
            this.formData.payratedetail = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit("input", {
                payitemid: this.formData.payitemid,
                payitemtypeid: this.formData.payitemtypeid,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                lockstaff: this.formData.lockstaff,
                payratedetail: this.formData.payratedetail,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "payratelist", params: { search: this.search} });
        }
    },

    mounted() {
        this.loadPayItemsHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
};
</script>