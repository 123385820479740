<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.employmenttype')"
                                id="emptype"
                                type="text"
                                ref="emptype"
                                :rules="[rules.required]"
                                v-model="formData.emptype"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="emptypedesc"
                                :rules="[rules.required]"
                                v-model="formData.emptypedesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.employmenttypecategory')"
                                type="text"
                                ref="emptypecatid"
                                :rules="[rules.required]"
                                :items="empTypeCatList"
                                v-model="formData.emptypecatid"
                                @input="updateValue"
                                @change="changeEmpTypeCatHandler(formData.emptypecatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="empTypeCatCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="empTypeCatDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: empTypeCatItems,
                                            formTitle: `${this.$t('columns.employmenttypecategory')}`,
                                            formId: 'emptypecat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveEmpTypeCat="saveEmpTypeCatHandler"
                                        v-on:deleteEmpTypeCat="deleteEmpTypeCatHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="empTypeCatDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.addongrplist,
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
            
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from "./CRUDTable";
import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable,
        'app-genericform': GenericForm,
    },

    data() {
        return {
            formData: {
                emptype: this.parentObject.data.emptype,
                addongrplist: this.parentObject.data.addongrplist,
                emptypedesc: this.parentObject.data.emptypedesc,
                emptypecatid: this.parentObject.data.emptypecatid,
                remark: this.parentObject.data.remark,
                isdisable: this.parentObject.data.isdisable,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('Add On Group'), value: 'addongrp', width: '100px', sortable: false},
                { text: this.$t('Value'), value: 'addongrpval', width: '200px', sortable: false}
    
            ],
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            empTypeCatCode: '',
            empTypeCatList: [],
            empTypeCats: [],
            empTypeCatItems: [],
            empTypeCatLoading: true,
            empTypeCatDialog: false,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            errMsg: '',
            isErr: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        addItemHandler($event) {
            this.formData.addongrplist =$event
        },
        
        getEmpTypeCatList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/emptypecat/all'})
                .then(res => {
                    vm.empTypeCats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {                        
                        vm.empTypeCatList.push(
                            {
                                text: res.data.rec[i].emptypecatdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.empTypeCatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].emptypecat,
                                desc: res.data.rec[i].emptypecatdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.empTypeCatLoading = false;

                    if (vm.formData.emptypecatid) {
                        vm.changeEmpTypeCatHandler(vm.formData.emptypecatid);
                    }

                    vm.empTypeCats = res.data.rec;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeEmpTypeCatHandler(id) {
            let item = this.empTypeCats.find(item => item.id === id);

            this.empTypeCatCode = item.emptypecat;
        },

        saveEmpTypeCatHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/emptypecat/' + this.itemData.itemid, payload: {
                    emptypecat: this.itemData.itemcode,
                    emptypecatdesc: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.empTypeCats = [];
                        vm.empTypeCatList = [];
                        vm.empTypeCatItems = [];

                        this.getEmpTypeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/emptypecat/create', payload: {
                    emptypecat: vm.itemData.itemcode,
                    emptypecatdesc: vm.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.empTypeCats = [];
                        vm.empTypeCatList = [];
                        vm.empTypeCatItems = [];

                        this.getEmpTypeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteEmpTypeCatHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc+ '?')) {
                this.$store.dispatch('delete', {url: '/emptypecat/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.empTypeCats = [];
                        vm.empTypeCatList = [];
                        vm.empTypeCatItems = [];

                        this.getEmpTypeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        updateValue() {
            this.$emit('input', {
                emptype: this.formData.emptype,
                emptypedesc: this.formData.emptypedesc,
                emptypecatid: this.formData.emptypecatid,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
                addongrplist: this.formData.addongrplist,
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'emptypelist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getEmpTypeCatList();
        
    }
}
</script>