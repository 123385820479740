<template>
    <div>
        <v-dialog v-model="payRatedialog" max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('setuppayrate.payrateitem')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    clearable
                                    :items="companyList"
                                    v-model="selections[0].compnyid"
                                    @change="
                                        changeCompanyHandler(
                                            selections[0].compnyid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.branch')"
                                    type="text"
                                    clearable
                                    :items="branchList"
                                    v-model="selections[0].brchid"
                                    @change="
                                        changeBranchHandler(
                                            selections[0].brchid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.division')"
                                    type="text"
                                    ref = "divncode"
                                    clearable
                                    :items="divisionList"
                                    v-model="selections[0].divnid"
                                    @change="
                                        changeDivisionHandler(
                                            selections[0].divnid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.department')"
                                    type="text"
                                    clearable
                                    :items="departmentList"
                                    v-model="selections[0].deptid"
                                    @change="
                                        changeDepartmentHandler(
                                            selections[0].deptid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.section')"
                                    type="text"
                                    clearable
                                    :items="sectionList"
                                    v-model="selections[0].sectioid"
                                    @change="
                                        changeSectionHandler(
                                            selections[0].sectioid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.unit')"
                                    type="text"
                                    clearable
                                    :items="unitList"
                                    v-model="selections[0].unitid"
                                    @change="
                                        changeUnitHandler(selections[0].unitid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.employmenttype')"
                                    type="text"
                                    clearable
                                    :items="emptypeList"
                                    v-model="selections[0].emptypeid"
                                    @change="
                                        changeEmptypeHandler(
                                            selections[0].emptypeid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.grade')"
                                    type="text"
                                    clearable
                                    :items="gradeList"
                                    v-model="selections[0].grdid"
                                    @change="
                                        changeGradeHandler(selections[0].grdid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.job')"
                                    type="text"
                                    clearable
                                    :items="jobList"
                                    v-model="selections[0].jobid"
                                    @change="
                                        changeJobHandler(selections[0].jobid)
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupepf.serviceperiod')"
                                    type="text"                                    
                                    v-model="selections[0].servprd"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.salary')"
                                    type="number"
                                    v-model="selections[0].salary"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="4" class="mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('payrollcommandstep.salaryfrequency')"
                                    type="text"
                                    clearable
                                    :items="salaryfreqList"
                                    v-model="selections[0].basicfreqid"
                                    @change="
                                        changeSalfreqHandler(
                                            selections[0].basicfreqid
                                        )
                                    "
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                 <v-row>
                   <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.logic')"
                            type="text"
                            clearable
                            :items="logicList"
                            v-model="selections[0].logicscriptid"
                            @change="changeLogicHandler(selections[0].logicscriptid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].logicscript"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row> 

                        <!-- <v-row>
                            <v-col cols="12" sm="12" md="12" class="mt-n8">
                                <v-textarea
                                    outlined
                                    label="Logic"
                                    type="text"
                                    v-model="selections[0].logicscript"
                                    dense
                                ></v-textarea>
                            </v-col>
                        </v-row> -->

                        <v-row>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate1')"
                                    type="number"
                                    v-model="selections[0].rate1"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate2')"
                                    type="number"
                                    v-model="selections[0].rate2"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate3')"
                                    type="number"
                                    v-model="selections[0].rate3"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate4')"
                                    type="number"
                                    v-model="selections[0].rate4"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate5')"
                                    type="number"
                                    v-model="selections[0].rate5"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate6')"
                                    type="number"
                                    v-model="selections[0].rate6"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate7')"
                                    type="number"
                                    v-model="selections[0].rate7"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate8')"
                                    type="number"
                                    v-model="selections[0].rate8"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate9')"
                                    type="number"
                                    v-model="selections[0].rate9"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" sm="2" md="2" class="mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setuppayrate.rate10')"
                                    type="number"
                                    v-model="selections[0].rate10"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red">{{
                        errMsg
                    }}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closePayRate"
                        >{{$t('button.cancel')}}</v-btn
                    >
                    <v-btn color="green darken-1" text @click="savePayRate"
                        >{{$t('button.save')}}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import compareArray from "../../../util/comparearray";

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            isPayRate: false,
            payRatedialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [
                {
                    id: null,
                    payrateid: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname: null,
                    brchid: null,
                    brchcode: null,
                    brchname: null,
                    divnid: null,
                    divncode: null,
                    divnname: null,
                    deptid: null,
                    deptcode: null,
                    deptname: null,
                    sectioid: null,
                    sectiocode: null,
                    sectioname: null,
                    unitid: null,
                    unitcode: null,
                    unitname: null,
                    emptypeid: null,
                    emptype: null,
                    emptypedesc: null,
                    grdid: null,
                    grdcode: null,
                    grddesc: null,
                    jobid: null,
                    jobcode: null,
                    jobtitle: null,
                    servprd: null,
                    salary: null,
                    basicfreqid: null,
                    basicfreqcode: null,
                    basicfreqdesc: null,
                    logicscriptid: null,
                    logicscript: null,
                    rate1: null,
                    rate2: null,
                    rate3: null,
                    rate4: null,
                    rate5: null,
                    rate6: null,
                    rate7: null,
                    rate8: null,
                    rate9: null,
                    rate10: null,
                    rectimestamp: null,
                    updatedby: null,
                    stamp: 0,
                    action: 0
                }
            ],

            companies: [],
            companyList: [],

            branches: [],
            branchList: [],

            divisions: [],
            divisionList: [],

            departments: [],
            departmentList: [],

            sections: [],
            sectionList: [],

            units: [],
            unitList: [],

            emptypes: [],
            emptypeList: [],

            grades: [],
            gradeList: [],

            jobs: [],
            jobList: [],

            salaryfreqs: [],
            salaryfreqList: [],

            logicList: [],
            logicItems: [],

            isExisting: false,
            isErr: false,
            errMsg: "",

            // origDetailItem: [],
            tmpDetailItemRate: null
        };
    },

    methods: {
        compareArray,

        loadLogic(){
             const vm = this;

            this.$store.dispatch('get', {url: '/logic/all'})
            .then(res => {

                vm.logicItems = res.data.rec;

                for (let i = 0; i < vm.logicItems.length; i++) {
                    vm.logicList.push({
                        text: vm.logicItems[i].logicname,
                        value: vm.logicItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeLogicHandler(id){
            let item = this.logicItems.find(item => item.id == id);

            this.selections[0].logicscript = item.logiccode;
           // this.selections[0].clmgrpdesc = item.valuedesc;

        },

        dupDetailItemHandler(typeStr) {
            let origDetailItem = [...this.detailItem];
            let tmpDetailItem = [];
            this.tmpDetailItemRate = [];

            origDetailItem.forEach(item => {
                let compnyid = 0;
                let brchid = 0;
                let divnid = 0;
                let deptid = 0;
                let sectioid = 0;
                let unitid = 0;
                let emptypeid = 0;
                let grdid = 0;
                let jobid = 0;
                let basicfreqid = 0;
                let srvprd = 0;
                let salary = 0;

                let rate1 = 0;
                let rate2 = 0;
                let rate3 = 0;
                let rate4 = 0;
                let rate5 = 0;
                let rate6 = 0;
                let rate7 = 0;
                let rate8 = 0;
                let rate9 = 0;
                let rate10 = 0;

                // assign each item value into var
                if (item.compnyid) compnyid = item.compnyid;
                if (item.brchid) brchid = item.brchid;
                if (item.divnid) divnid = item.divnid;
                if (item.deptid) deptid = item.deptid;
                if (item.sectioid) sectioid = item.sectioid;
                if (item.unitid) unitid = item.unitid;
                if (item.emptypeid) emptypeid = item.emptypeid;
                if (item.grdid) grdid = item.grdid;
                if (item.jobid) jobid = item.jobid;
                if (item.basicfreqid) basicfreqid = item.basicfreqid;
                if (item.servprd) srvprd = item.servprd;
                if (item.salary) salary = item.salary;

                if (item.rate1) rate1 = item.rate1;
                if (item.rate2) rate2 = item.rate2;
                if (item.rate3) rate3 = item.rate3;
                if (item.rate4) rate4 = item.rate4;
                if (item.rate5) rate5 = item.rate5;
                if (item.rate6) rate6 = item.rate6;
                if (item.rate7) rate7 = item.rate7;
                if (item.rate8) rate8 = item.rate8;
                if (item.rate9) rate9 = item.rate9;
                if (item.rate10) rate10 = item.rate10;

                // conver to string and concatenate
                let concatVal =
                    compnyid.toString() +
                    brchid.toString() +
                    divnid.toString() +
                    deptid.toString() +
                    sectioid.toString() +
                    unitid.toString() +
                    emptypeid.toString() +
                    grdid.toString() +
                    jobid.toString() +
                    basicfreqid.toString() +
                    srvprd.toString() +
                    salary.toString();

                let concatValRate =
                    concatVal +
                    rate1.toString() +
                    rate2.toString() +
                    rate3.toString() +
                    rate4.toString() +
                    rate5.toString() +
                    rate6.toString() +
                    rate7.toString() +
                    rate8.toString() +
                    rate9.toString() +
                    rate10.toString();

                tmpDetailItem.push({ catedValue: concatVal });
                this.tmpDetailItemRate.push({ catedValue: concatValRate });
            });

            if (typeStr === "n") {
                return tmpDetailItem;
            }

            if (typeStr === "e") {
                return this.tmpDetailItemRate;
            }
        },

        modifyItemHandler(item) {
            // assign loaded data to selections array

            //if (item.action == 2) {
            this.isExisting = true;
            //}

            //if (item.action == 1) {
            this.isExisting = true;
            //}

            this.selections[0] = item;

            // duplicate detailItem to tmpDetailItem

            this.dupDetailItemHandler("e");

            this.payRatedialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closePayRate() {
            this.payRatedialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },

        savePayRate() {
            this.isErr = false;
            // indicate value of 1 as insert, 2 as modify

            // convert all rates to int
            if (this.selections[0].rate1)
                this.selections[0].rate1 = parseFloat(this.selections[0].rate1);
            if (this.selections[0].rate2)
                this.selections[0].rate2 = parseFloat(this.selections[0].rate2);
            if (this.selections[0].rate3)
                this.selections[0].rate3 = parseFloat(this.selections[0].rate3);
            if (this.selections[0].rate4)
                this.selections[0].rate4 = parseFloat(this.selections[0].rate4);
            if (this.selections[0].rate5)
                this.selections[0].rate5 = parseFloat(this.selections[0].rate5);
            if (this.selections[0].rate6)
                this.selections[0].rate6 = parseFloat(this.selections[0].rate6);
            if (this.selections[0].rate7)
                this.selections[0].rate7 = parseFloat(this.selections[0].rate7);
            if (this.selections[0].rate8)
                this.selections[0].rate8 = parseFloat(this.selections[0].rate8);
            if (this.selections[0].rate9)
                this.selections[0].rate9 = parseFloat(this.selections[0].rate9);
            if (this.selections[0].rate10)
                this.selections[0].rate10 = parseFloat(
                    this.selections[0].rate10
                );

            let selCompnyid = 0;
            let selBrchid = 0;
            let selDivnid = 0;
            let selDeptid = 0;
            let selSectioid = 0;
            let selUnitid = 0;
            let selEmptypeid = 0;
            let selGrdid = 0;
            let selJobid = 0;
            let selBasicfreqid = 0;
            let selSrvprd = 0;
            let selSalary = 0;

            if (this.selections[0].compnyid)
                selCompnyid = this.selections[0].compnyid;
            if (this.selections[0].brchid)
                selBrchid = this.selections[0].brchid;
            if (this.selections[0].divnid)
                selDivnid = this.selections[0].divnid;
            if (this.selections[0].deptid)
                selDeptid = this.selections[0].deptid;
            if (this.selections[0].sectioid)
                selSectioid = this.selections[0].sectioid;
            if (this.selections[0].unitid)
                selUnitid = this.selections[0].unitid;
            if (this.selections[0].emptypeid)
                selEmptypeid = this.selections[0].emptypeid;
            if (this.selections[0].grdid) selGrdid = this.selections[0].grdid;
            if (this.selections[0].jobid) selJobid = this.selections[0].jobid;
            if (this.selections[0].basicfreqid)
                selBasicfreqid = this.selections[0].basicfreqid;
            if (this.selections[0].servprd)
                selSrvprd = this.selections[0].servprd;
            if (this.selections[0].salary)
                selSalary = this.selections[0].salary;

            let catSelection =
                selCompnyid.toString() +
                selBrchid.toString() +
                selDivnid.toString() +
                selDeptid.toString() +
                selSectioid.toString() +
                selUnitid.toString() +
                selEmptypeid.toString() +
                selGrdid.toString() +
                selJobid.toString() +
                selBasicfreqid.toString() +
                selSrvprd.toString() +
                selSalary.toString();

            if (this.isExisting == false) {
                let tmpDetailItem = this.dupDetailItemHandler("n");

                if (
                    tmpDetailItem.some(item => item.catedValue == catSelection)
                ) {
                    //console.log("object found");
                    this.errMsg = this.$t('duplicateitemsnotallowed');
                    this.isErr = true;

                    return;
                }

                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                let selRate1 = 0;
                let selRate2 = 0;
                let selRate3 = 0;
                let selRate4 = 0;
                let selRate5 = 0;
                let selRate6 = 0;
                let selRate7 = 0;
                let selRate8 = 0;
                let selRate9 = 0;
                let selRate10 = 0;

                if (this.selections[0].rate1)
                    selRate1 = this.selections[0].rate1;
                if (this.selections[0].rate2)
                    selRate2 = this.selections[0].rate2;
                if (this.selections[0].rate3)
                    selRate3 = this.selections[0].rate3;
                if (this.selections[0].rate4)
                    selRate4 = this.selections[0].rate4;
                if (this.selections[0].rate4)
                    selRate5 = this.selections[0].rate5;
                if (this.selections[0].rate5)
                    selRate6 = this.selections[0].rate6;
                if (this.selections[0].rate7)
                    selRate7 = this.selections[0].rate7;
                if (this.selections[0].rate8)
                    selRate8 = this.selections[0].rate8;
                if (this.selections[0].rate9)
                    selRate9 = this.selections[0].rate9;
                if (this.selections[0].rate10)
                    selRate10 = this.selections[0].rate10;

                let catSelectionRate =
                    catSelection +
                    selRate1.toString() +
                    selRate2.toString() +
                    selRate3.toString() +
                    selRate4.toString() +
                    selRate5.toString() +
                    selRate6.toString() +
                    selRate7.toString() +
                    selRate8.toString() +
                    selRate9.toString() +
                    selRate10.toString();

                if (
                    this.tmpDetailItemRate.some(
                        item => item.catedValue == catSelectionRate
                    )
                ) {
                    //console.log("object found");
                    this.errMsg = this.$t('duplicateitemsnotallowed');
                    this.isErr = true;

                    return;
                }
            }

            this.$emit("input", this.detailItem);

            this.payRatedialog = false;

            // reset the form fields
            this.resetSelections();
        },

        resetSelections() {
            this.selections = [
                {
                    id: null,
                    payrateid: null,
                    compnyid: null,
                    compnycode: null,
                    compnyname: null,
                    brchid: null,
                    brchcode: null,
                    brchname: null,
                    divnid: null,
                    divncode: null,
                    divnname: null,
                    deptid: null,
                    deptcode: null,
                    deptname: null,
                    sectioid: null,
                    sectiocode: null,
                    sectioname: null,
                    unitid: null,
                    unitcode: null,
                    unitname: null,
                    emptypeid: null,
                    emptype: null,
                    emptypedesc: null,
                    grdid: null,
                    grdcode: null,
                    grddesc: null,
                    jobid: null,
                    jobcode: null,
                    jobtitle: null,
                    srvprd: null,
                    salary: null,
                    basicfreqid: null,
                    basicfreqcode: null,
                    basicfreqdesc: null,
                 //  logicscriptid: null,
                    logicscript: null,
                    rate1: null,
                    rate2: null,
                    rate3: null,
                    rate4: null,
                    rate5: null,
                    rate6: null,
                    rate7: null,
                    rate8: null,
                    rate9: null,
                    rate10: null,
                    rectimestamp: null,
                    updatedby: null,
                    stamp: 0,
                    action: 0
                }
            ];
        },

        loadCompanies() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"}).then(res => {
                vm.companies = res.data.rec;

                for (let i = 0; i < vm.companies.length; i++) {
                    vm.companyList.push({
                        text: vm.companies[i].compnyname,
                        value: vm.companies[i].id
                    });
                }
                if (vm.selections[0].compnyid) {
                        vm.changeCompanyHandler(vm.selections[0].compnycode);
                    }
            });
        },

        loadBranches() {
            const vm = this;

            this.$store.dispatch('get', {url: "/branch/all"}).then(res => {
                vm.branches = res.data.rec;

                for (let i = 0; i < vm.branches.length; i++) {
                    vm.branchList.push({
                        text: vm.branches[i].brchname,
                        value: vm.branches[i].id
                    });
                }

                if (vm.selections[0].brchid) {
                        vm.changeBranchHandler(vm.selections[0].brchid);
                    }

            });
        },

        loadDivisions() {
            const vm = this;

            this.$store.dispatch('get', {url: "/division/all"})
                .then(res => {
                    vm.divisions = res.data.rec;

                    for (let i = 0; i < vm.divisions.length; i++) {
                        vm.divisionList.push({
                            text: vm.divisions[i].divnname,
                            value: vm.divisions[i].id
                        });
                    }
                });

                 if (vm.selections[0].divnid) {
                        vm.changeDivisionHandler(vm.selections[0].divnid);
                    }
        },

        loadDepartments() {
            const vm = this;

            this.$store.dispatch('get', {url: "/department/all"})
                .then(res => {
                    vm.departments = res.data.rec;

                    for (let i = 0; i < vm.departments.length; i++) {
                        vm.departmentList.push({
                            text: vm.departments[i].deptname,
                            value: vm.departments[i].id
                        });
                    }
                    if (vm.selections[0].deptid) {
                        vm.changeDepartmentHandler(vm.selections[0].deptid);
                    }
                });
        },

        loadSections() {
            const vm = this;

            this.$store.dispatch('get', {url: "/section/all"}).then(res => {
                vm.sections = res.data.rec;

                for (let i = 0; i < vm.sections.length; i++) {
                    vm.sectionList.push({
                        text: vm.sections[i].sectioname,
                        value: vm.sections[i].id
                    });
                }

                if (vm.selections[0].sectioid) {
                        vm.changeSectionHandler(vm.selections[0].sectioid);
                    }
            });
        },

        loadUnits() {
            const vm = this;

            this.$store.dispatch('get', {url: "/unit/all"}).then(res => {
                vm.units = res.data.rec;

                for (let i = 0; i < vm.units.length; i++) {
                    vm.unitList.push({
                        text: vm.units[i].unitname,
                        value: vm.units[i].id
                    });
                }

                if (vm.selections[0].unitid) {
                        vm.changeUnitHandler(vm.selections[0].unitid);
                    }
            });
        },

        loadEmpTypes() {
            const vm = this;

            this.$store.dispatch('get', {url: "/emptype/all"}).then(res => {
                vm.emptypes = res.data.rec;

                for (let i = 0; i < vm.emptypes.length; i++) {
                    vm.emptypeList.push({
                        text: vm.emptypes[i].emptypedesc,
                        value: vm.emptypes[i].id
                    });
                }

                if (vm.selections[0].emptypeid) {
                        vm.changeEmptypeHandler(vm.selections[0].emptypeid);
                    }
            });
        },

        loadGrades() {
            const vm = this;

            this.$store.dispatch('get', {url: "/grade/all"}).then(res => {
                vm.grades = res.data.rec;

                for (let i = 0; i < vm.grades.length; i++) {
                    vm.gradeList.push({
                        text: vm.grades[i].grddesc,
                        value: vm.grades[i].id
                    });
                }

                if (vm.selections[0].grdid) {
                        vm.changeGradeHandler(vm.selections[0].grdid);
                    }
            });
        },

        loadJobs() {
            const vm = this;

            this.$store.dispatch('get', {url: "/job/all"}).then(res => {
                vm.jobs = res.data.rec;

                for (let i = 0; i < vm.jobs.length; i++) {
                    vm.jobList.push({
                        text: vm.jobs[i].jobtitle,
                        value: vm.jobs[i].id
                    });
                }

                if (vm.selections[0].jobid) {
                        vm.changeCompanyHandler(vm.selections[0].jobid);
                    }
            });
        },

        loadSalaryFreqs() {
            const vm = this;

            this.$store.dispatch('get', {url: "/salfreq/all"}).then(res => {
                vm.salaryfreqs = res.data.rec;

                for (let i = 0; i < vm.salaryfreqs.length; i++) {
                    vm.salaryfreqList.push({
                        text: vm.salaryfreqs[i].basicfreqdesc,
                        value: vm.salaryfreqs[i].id
                    });
                }
               
               if (vm.selections[0].basicfreqid) {
                        vm.changeSalfreqHandler(vm.selections[0].basicfreqid);
                    }
                
            });
        },

        changeCompanyHandler(id) {
            let item = this.companies.find(item => item.id == id);

            this.selections[0].compnyname = item.compnyname;
            this.selections[0].compnycode = item.compnycode;
        },

        changeBranchHandler(id) {
            let item = this.branches.find(item => item.id == id);

            this.selections[0].brchname = item.brchname;
            this.selections[0].brchcode = item.brchcode;
        },

        changeDivisionHandler(id) {
            let item = this.divisions.find(item => item.id == id);

            this.selections[0].divnname = item.divnname;
            this.selections[0].divncode = item.divncode;
        },

        changeDepartmentHandler(id) {
            let item = this.departments.find(item => item.id == id);

            this.selections[0].deptname = item.deptname;
            this.selections[0].deptcode = item.deptcode;
        },

        changeSectionHandler(id) {
            let item = this.sections.find(item => item.id == id);

            this.selections[0].sectioname = item.sectioname;
            this.selections[0].sectiocode = item.sectiocode;
        },

        changeUnitHandler(id) {
            let item = this.units.find(item => item.id == id);

            this.selections[0].unitname = item.unitname;
            this.selections[0].unitcode = item.unitcode;
        },

        changeEmptypeHandler(id) {
            let item = this.emptypes.find(item => item.id == id);

            this.selections[0].emptypedesc = item.emptypedesc;
            this.selections[0].emptype = item.emptype;
        },

        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id == id);

            this.selections[0].grddesc = item.grddesc;
            this.selections[0].grdcode = item.grdcode;
        },

        changeJobHandler(id) {
            let item = this.jobs.find(item => item.id == id);

            this.selections[0].jobtitle = item.jobtitle;
            this.selections[0].jobcode = item.jobcode;
        },

        changeSalfreqHandler(id) {
            let item = this.salaryfreqs.find(item => item.id == id);

            this.selections[0].basicfreqdesc = item.basicfreqdesc;
            this.selections[0].basicfreqcode = item.basicfreqcode;
        }
    },

    created() {
        this.loadCompanies();
        this.loadBranches();
        this.loadDivisions();
        this.loadDepartments();
        this.loadSections();
        this.loadUnits();
        this.loadEmpTypes();
        this.loadGrades();
        this.loadJobs();
        this.loadSalaryFreqs();
        this.loadLogic();
    }
};
</script>
