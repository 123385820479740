<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('claimlimitadjustmentrecord.claimlimitadjustmentlist'),
      dataHeader: [

        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.staffid'), value: "staffno", width: "120px" },
        { text: this.$t('columns.staffname'), value: "staffname", width: "120px" },
        { text: this.$t('attendancereportbypeiordbycompanyreport.companyname'), value: "compnyname", width: "200px" },
        { text: this.$t('setupclaimlimit.claimtypedescription'), value: "clmtypedesc", width: "200px" },
        { text: this.$t('claimlimitadjustmentrecord.claimlimitadjustmenttype'), value: "clmlimitadjtype", width: "250px" },
        { text: this.$t('claimlimitadjustmentrecord.adjustmentamount'), value: "adjamount", width: "200px" },
        { text: this.$t('claimlimitadjustmentrecord.valuedate'), value: "valuedate", width: "200px" },
        { text: this.$t('claimlimitadjustmentrecord.claimadjustmentreasondescription'), value: "clmadjreasondesc", width: "200px" },
        { text: this.$t('columns.remark'), value: "remark", width: "200px" },
        
        // { text: "ACTIONS", value: "action", sortable: false, width: "50px" },
        // { text: "CODE", value: "cliniccode", width: "120px" },
        // { text: "DESCRIPTION", value: "clinicdesc", width: "200px" },
        // { text: "ADDRESS 1", value: "add1", width: "200px" },
        // { text: "ADDRESS 2", value: "add2", width: "200px" },
        // { text: "ADDRESS 3", value: "add3", width: "200px" },
        // { text: "CITY", value: "cityname", width: "200px" },
        // { text: "COUNTRY", value: "cntryname", width: "120px" },
        // { text: "POSTCODE", value: "poscode", width: "120px" },
        // { text: "TELEPHONE", value: "tel", width: "200px" },
        // { text: "EMAIL", value: "email", width: "200px" },
        // { text: "DESCRIPTION", value: "clinicdesc", width: "200px" },
        // { text: "ADDRESS 1", value: "add1", width: "200px" },
        // { text: "ADDRESS 2", value: "add2", width: "200px" },
        // { text: "ADDRESS 3", value: "add3", width: "200px" },
        // { text: "CITY", value: "cityname", width: "200px" },
        // { text: "COUNTRY", value: "cntryname", width: "120px" },
        // { text: "POSTCODE", value: "poscode", width: "120px" },
        // { text: "TELEPHONE", value: "tel", width: "200px" },
        // { text: "EMAIL", value: "email", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getClaimLimitAdjustmentListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/claimlimitadjustment/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].valuedate) {
                        vm.dataItems[i].valuedate = moment(vm.dataItems[i].valuedate).format('YYYY-MM-DD');
                    }

                }
  

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "claimlimitadjustmentedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "claimlimitadjustmentnew" });
    },
  },
  mounted() {
    this.getClaimLimitAdjustmentListHandler();
  },
};
</script>