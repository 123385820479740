<template>
  <div>
    <v-container>
      <v-row>
        <h2 class="pb-5">
          {{ this.pageTitle }}
        </h2>
      </v-row>

      <v-form>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.personinfo')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlist"
                    hide-selected
                    v-model="formData.personAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.personFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".zip"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="PersonSubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-form class="mt-5">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.staffdetails')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlistStaff"
                    hide-selected
                    v-model="formData.staffAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.staffFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".csv"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="StaffSubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-form class="mt-5">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.staffmov')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlistStaff"
                    hide-selected
                    v-model="formData.staffmovAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.staffFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".csv"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="StaffMovSubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-form class="mt-5">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.staffbankacc')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlist"
                    hide-selected
                    v-model="formData.staffBankAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.staffBankFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".csv"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="StaffBankSubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-form class="mt-5">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.personpayrollinstruction')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlist"
                    hide-selected
                    v-model="formData.psnPayrollAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.psnPayrollFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".csv"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="PsnPayrollSubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-form class="mt-5">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                >{{$t('recimport.personstatutoryinfo')}}</v-subheader
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-8" />
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8">
                  <v-autocomplete
                    outlined
                    :label="this.$t('columns.actions')"
                    type="text"
                    ref="action"
                    :items="actionlist"
                    hide-selected
                    v-model="formData.personStatutoryAction"
                    @input="updateValue"
                    dense
                    clearable
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <div class="text-center">
                    <v-file-input
                      :label="this.$t('payeeinputprocess.choosefiles')"
                      ref="importfile"
                      v-model="formData.personStatutoryFile"
                      outlined
                      dense
                      show-size
                      prepend-icon=""
                      accept=".csv"
                      style="font-size: 14px"
                      :rules="[rules.required]"
                    >
                    </v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="pb-n4 mt-n8" align-self="center">
                  <v-btn
                    color="#fd9941"
                    style="width: 100px; color: white"
                    v-on:click="personStatutorySubmit()"
                    >{{$t('button.submit')}}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>


<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-error": ErrorDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      pageTitle: this.$t('import'),
      actionlist: [
        { text: this.$t('reportsparam.createnew'), value: "Create" },
        {
          text: this.$t('reportsparam.updexist'),
          value: "Update",
        },
      ],
      actionlistStaff: [{ text: this.$t('reportsparam.createnew'), value: "Create" }],
      errMsg: "",
      isErr: false,
      formData: {
        personAction: "",
        personFile: "",
        staffAction: "",
        staffmovAction: "",
        staffFile: "",
        staffBankAction: "",
        staffBankFile: "",
        psnPayrollAction: "",
        psnPayrollFile: "",
        personStatutoryAction: "",
        personStatutoryFile: "",
      },
    };
  },
  methods: {
    updateValue() {
      this.$emit("input", {
        action: this.formData.action,
      });
    },
    PersonSubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.personAction);
      data.append("file", this.formData.personFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/person", payload: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    StaffSubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.staffAction);
      data.append("file", this.formData.staffFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/staff", payload: data, 
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    StaffMovSubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.staffmovAction);
      data.append("file", this.formData.staffFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/staffmov", payload: data, 
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    StaffBankSubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.staffBankAction);
      data.append("file", this.formData.staffBankFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/staffbank", payload: data, 
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    PsnPayrollSubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.psnPayrollAction);
      data.append("file", this.formData.psnPayrollFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/psnpayroll", payload: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully.").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
    personStatutorySubmit() {
      console.log(this.formData);

      let data = new FormData();
      data.append("action", this.formData.personStatutoryAction);
      data.append("file", this.formData.personStatutoryFile);
      data.append("userid", this.$store.getters.userId);

      this.$store.dispatch('post', {url: "/import/personstatutory", payload: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == 201) {
            this.$dialog.alert("Data Imported Successfully.").then((dialog) => {
              console.log(dialog);
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },
  },
  mounted() {},
};
</script>