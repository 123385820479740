<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle }}
          </h2>
        </v-col>
      </v-row>

      <!-- <v-container id="scroll-target" class="custom-form overflow-y-auto px-5"> -->
      <v-row>
        <v-col cols="10" sm="10" class="mt-n8">
          <v-row>
            <v-col cols="8" sm="8" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.grouptype')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.gtypeid"
                :items="groupList"
                @input="updateValue"
                dense
                :readonly="this.parentObject.isDisabled"
                @change="changeGroupHandler(formData.gtypeid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb4-4">
              <app-table
                v-bind:parentObject="{
                  tabHeader: this.tabHeader,
                  gtypeid: this.formData.gtypeid,
                  detailItem: this.formData.detailItem,
                }"
                v-on:input="addItemHandler"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row align="center">
          <div class="pt-5 col-sm-8 col-8">
            <v-btn color="success" style="width: 100px" @click="saveHandler">
              Save
            </v-btn>
          </div>
        </v-row> -->

      <app-formbuttons
        v-bind:EditBool="this.parentObject.isEdit"
        v-on:NewPressed="newHandler"
        v-on:SavePressed="saveHandler"
        v-on:DeletePressed="deleteHandler"
        v-on:CancelPressed="cancelHandler"
      />
      <!-- </v-container> -->
    </v-container>
  </div>
</template>

<script>
import CRUDTable from "./CRUDTable";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-table": CRUDTable,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      tabHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "auto" },
        { text: this.$t('columns.group'), value: "valuecode", width: "auto", sortable: false },
        {
          text: this.$t('columns.company'),
          value: "compnycode",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('columns.branch'), value: "brchcode", width: "auto", sortable: false },
        {
          text: this.$t('columns.department'),
          value: "deptcode",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('columns.division'), value: "divncode", width: "auto", sortable: false },
        {
          text: this.$t('columns.section'),
          value: "sectiocode",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('columns.unit'), value: "unitcode", width: "auto", sortable: false },
        { text: this.$t('columns.job'), value: "jobcode", width: "auto", sortable: false },
        {
          text: this.$t('columns.employmenttype'),
          value: "emptype",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('columns.grade'), value: "grdcode", width: "auto", sortable: false },
        { text: this.$t('columns.position'), value: "poscode", width: "auto", sortable: false },
      ],
      subDialog: false,
      errMsg: "",
      isErr: false,
       search: this.parentObject.searchnew,
      formData: {
        gtypeid: this.parentObject.data.gtypeid,
        detailItem: this.parentObject.data.detailItem,
         search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
      },
      groupList: [],
    };
  },
  methods: {
    updateValue() {
      this.$emit("input", {
        gtypeid: this.formData.gtypeid,
        detailItem: this.formData.detailItem,
         search: this.formData.search,
      });
    },
    changeGroupHandler(id) {
      console.log(id);
      // const vm = this;
      // axios
      //   .get(this.$store.getters.apiURL + "/mapping/getmapping/" + id)
      //   .then((res) => {
      //     console.log(res);
      //     console.log(this.formData.detailItem);
      //     for (let i = 0; i < res.data.rec.length; i++) {
      //       vm.formData.detailItem.push(
      //         Object.assign(
      //           {},
      //           {
      //             gtypeid: res.data.rec[i].gtypeid,
      //             valvalueid: res.data.rec[i].valvalueid,
      //             valuecode: res.data.rec[i].valvaluecode,
      //             valuedesc: res.data.rec[i].valvaluedesc,
      //             compnyid: res.data.rec[i].compnyid,
      //             compnycode: res.data.rec[i].compnycode,
      //             brchid: res.data.rec[i].brchid,
      //             brchcode: res.data.rec[i].brchcode,
      //             divnid: res.data.rec[i].divnid,
      //             divncode: res.data.rec[i].divncode,
      //             deptid: res.data.rec[i].deptid,
      //             deptcode: res.data.rec[i].deptcode,
      //             sectioid: res.data.rec[i].sectioid,
      //             sectiocode: res.data.rec[i].sectiocode,
      //             unitid: res.data.rec[i].unitid,
      //             unitcode: res.data.rec[i].unitcode,
      //             emptypeid: res.data.rec[i].emptypeid,
      //             emptype: res.data.rec[i].emptypecode,
      //             grdid: res.data.rec[i].gradeid,
      //             grdcode: res.data.rec[i].gradecode,
      //             jobid: res.data.rec[i].jobid,
      //             jobcode: res.data.rec[i].jobcode,
      //             positionid: res.data.rec[i].positionid,
      //             poscode: res.data.rec[i].positioncode,
      //             updatedby: this.$store.getters.userId,
      //             action: 0,
      //           }
      //         )
      //       );
      //     }
      //   })
      //   .catch((err) => {
      //     vm.isErr = true;
      //     vm.errMsg = err;
      //     vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
      //   });
    },
    loadGroupListHandler() {
      const vm = this;
      console.log(vm.formData);
      this.$store.dispatch('get', {url: "/group/all"})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.groupList.push({
              text: res.data.rec[i].gtypedesc,
              value: res.data.rec[i].gtypeid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    addItemHandler($event) {
      this.formData.detailItem = $event;
    },
    saveHandler() {

      if (this.formData.detailItem.length == '0') {
        //  this.isErr = true;
       this.$dialog.alert(this.$t('grouptablecannotbeempty')).then((dialog) => {
                console.log(dialog);
             });
          
      } else if (this.formData.detailItem.length > 0) {
      this.updateValue();
      this.$emit("save");
      }
      console.log('detailItem', this.formData.detailItem.length)
    },
    newHandler() {
      this.$emit("new");
    },
    deleteHandler() {
      this.$emit("delete");
    },
    cancelHandler() {
      this.$router.push({ name: "mappinglist",  params: { search: this.search} });
    },
  },
  mounted() {
    this.loadGroupListHandler();
  },
};
</script>