<template>
    <div>
      <v-container>
        <v-form ref="form">
          <v-row>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-row>
              <v-col cols="4" sm="4" class="pb-n5">
                <v-text-field
                  outlined
                  :label="this.$t('allbank.paymentdate')"
                  type="date"
                  v-model="formData.paymentdate"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" class="pb-n5">
                  <v-text-field
                      outlined
                      :label="this.$t('vdatatable.receiptreference')"
                      type="text"
                      v-model="formData.recref"
                      :rules="[rules.required, rules.counter]"
                      maxlength='20'
                      dense
                  ></v-text-field>
              </v-col>
            </v-row>
            <!--<v-row>
              <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                  <v-text-field
                      outlined
                      :label="this.$t('allbank.otherpaymentdetails')"
                      type="text"
                      v-model="formData.othpaydet"
                      :rules="[rules.counter]"
                      maxlength='20'
                      dense
                  ></v-text-field>
              </v-col>
            </v-row>-->
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="exportHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  import XLSX from 'xlsx/xlsx';
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
          counter: (value) => value.length != 20 || this.$t('reportsparam.counter20'),
        },
        pageTitle: 'PUBLIC-01',
  
        parameter: this.parentObject.parameter,
        id: this.parentObject.id,
        data: this.parentObject.data,
        formData: {
          paymentdate: "",
          recref: '',
          //othpaydet: ''
        },
        fileHolder: null,
        file: null,
  
        paymentModeList: ['PBB', 'IBG'],
        bank: [
          {
            id: 1,
            bankcode: "PHBMMYKL",
            bankdesc: "AFFIN BANK BERHAD",
            bankgencode: 1
          },
          {
            id: 37,
            bankcode: "AIBBMYKL",
            bankdesc: "AFFIN ISLAMIC BANK BERHAD",
            bankgencode: 75
          },
          {
            id: 2,
            bankcode: "AGOBMYK1",
            bankdesc: "AGROBANK BERHAD",
            bankgencode: 2
          },
          {
            id: 38,
            bankcode: "ALSRMYK1",
            bankdesc: "ALLIANCE ISLAMIC BANK MALAYSIA BERHAD",
            bankgencode: 95
          },
          {
            id: 3,
            bankcode: "RJHIMYKL",
            bankdesc: "AL RAJHI BANK (MALAYSIA) BERHAD",
            bankgencode: 3
          },
          {
            id: 4,
            bankcode: "MFBBMYKL",
            bankdesc: "ALLIANCE BANK MALAYSIA BERHAD",
            bankgencode: 4
          },
          {
            id: 5,
            bankcode: "ARBKMYKL",
            bankdesc: "AMBANK (M) BERHAD",
            bankgencode: 5
          },
          {
            id: 39,
            bankcode: "AISLMYKL",
            bankdesc: "AMISLAMIC BANK (M) BERHAD",
            bankgencode: 48
          },
          {
            id: 6,
            bankcode: "BKKBMYKL",
            bankdesc: "BANGKOK BANK BERHAD",
            bankgencode: 6
          },
          {
            id: 7,
            bankcode: "BIMBMYKL",
            bankdesc: "BANK ISLAM MALAYSIA BERHAD",
            bankgencode: 7
          },
          {
            id: 40,
            bankcode: "BKRMMYKL",
            bankdesc: "BANK KERJASAMA RAKYAT MALAYSIA BERHAD",
            bankgencode: 53
          },
          {
            id: 8,
            bankcode: "BMMBMYKL",
            bankdesc: "BANK MUAMALAT (MALAYSIA) BERHAD ",
            bankgencode: 8
          },
          {
            id: 9,
            bankcode: "BOFAMY2X",
            bankdesc: "BANK OF AMERICA (MALAYSIA) BERHAD",
            bankgencode: 9
          },
          {
            id: 10,
            bankcode: "BKCHMYKL",
            bankdesc: "BANK OF CHINA (MALAYSIA) BERHAD",
            bankgencode: 10
          },
          {
            id: 28,
            bankcode: "BOTKMYKX",
            bankdesc: "MUFG BANK (MALAYSIA) BERHAD",
            bankgencode: 11
          },
          {
            id: 13,
            bankcode: "BSNAMYK1",
            bankdesc: "BANK SIMPANAN NASIONAL BERHAD",
            bankgencode: 13
          },
          {
            id: 14,
            bankcode: "BNPAMYKL",
            bankdesc: "BNP PARIBAS MALAYSIA BERHAD",
            bankgencode: 14
          },
          {
            id: 15,
            bankcode: "PCBCMYKL",
            bankdesc: "CHINA CONSTRUCTION BANK (MALAYSIA) BERHAD",
            bankgencode: 15
          },
          {
            id: 16,
            bankcode: "CIBBMYKL",
            bankdesc: "CIMB BANK BERHAD",
            bankgencode: 16
          },
          {
            id: 41,
            bankcode: "CTBBMYKL",
            bankdesc: "CIMB ISLAMIC BANK BERHAD",
            bankgencode: 63
          },
          {
            id: 17,
            bankcode: "CITIMYKL",
            bankdesc: "CITIBANK BERHAD",
            bankgencode: 17
          },
          {
            id: 18,
            bankcode: "DEUTMYKL",
            bankdesc: "DEUTSCHE BANK (M) BERHAD",
            bankgencode: 18
          },
          {
            id: 20,
            bankcode: "HLBBMYKL",
            bankdesc: "HONG LEONG BANK BERHAD",
            bankgencode: 20
          },
          {
            id: 42,
            bankcode: "HLIBMYKL",
            bankdesc: "HONG LEONG ISLAMIC BANK BERHAD",
            bankgencode: 70
          },
          {
            id: 21,
            bankcode: "HBMBMYKL",
            bankdesc: "HSBC BANK MALAYSIA BERHAD",
            bankgencode: 21
          },
          {
            id: 43,
            bankcode: "HMABMYKL",
            bankdesc: "HSBC AMANAH MALAYSIA BERHAD",
            bankgencode: 71
          },
          {
            id: 23,
            bankcode: "ICBKMYKL",
            bankdesc: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD",
            bankgencode: 23
        },
        {
            id: 24,
            bankcode: "CHASMYKX",
            bankdesc: "JP MORGAN CHASE BANK BERHAD",
            bankgencode: 24
        },
        {
            id: 25,
            bankcode: "KFHOMYKL",
            bankdesc: "KUWAIT FINANCE HOUSE (MALAYSIA) BERHAD",
            bankgencode: 25
          },
          {
            id: 26,
            bankcode: "MBBEMYKL",
            bankdesc: "MALAYAN BANKING BERHAD",
            bankgencode: 26
          },
          {
            id: 44,
            bankcode: "MBISMYKL",
            bankdesc: "MALAYAN ISLAMIC BERHAD",
            bankgencode: 44
          },
          {
            id: 37,
            bankcode: "AFBQMYKL",
            bankdesc: "MBSB BANK BERHAD",
            bankgencode: 37
          },
          {
            id: 27,
            bankcode: "MHCBMYKA",
            bankdesc: "MIZUHO CORPORATE BANK (MALAYSIA) BERHAD",
            bankgencode: 27
          },
          {
            id: 29,
            bankcode: "OCBCMYKL",
            bankdesc: "OCBC BANK (MALAYSIA) BERHAD",
            bankgencode: 29
          },
          {
            id: 45,
            bankcode: "OABBMYKL",
            bankdesc: "OCBC AL-AMIN BANK BERHAD",
            bankgencode: 84
          },
          {
            id: 31,
            bankcode: "RHBBMYKL",
            bankdesc: "RHB BANK BERHAD",
            bankgencode: 31
          },
          {
            id: 46,
            bankcode: "RHBAMYKL",
            bankdesc: "RHB ISLAMIC BANK BERHAD",
            bankgencode: 89
          },
          {
            id: 32,
            bankcode: "SCBLMYKX",
            bankdesc: "STANDARD CHARTERED BANK (MALAYSIA) BERHAD",
            bankgencode: 32
          },
          {
            id: 47,
            bankcode: "SCSRMYK1",
            bankdesc: "STANDARD CHARTERED SAADIQ (MALAYSIA) BERHAD",
            bankgencode: 92
          },
          {
            id: 33,
            bankcode: "SMBCMYKL",
            bankdesc: "SUMITOMO MITSUI BANKING CORPORATION MALAYSIA BERHAD",
            bankgencode: 33
          },
          {
            id: 36,
            bankcode: "UOVBMYKL",
            bankdesc: "UNITED OVERSEAS BANK (MALAYSIA) BERHAD",
            bankgencode: 36
          },
        ],
  
        staffilterlist: [],
        staffaccount: [],
        companyaccount: [],
        psnpayroll: [],
        //titleData: this.parentObject.data.salexprecdetail[0].payoutdesc,
        titleData: null,
        payrolldata: [],
  
        companyrecnum: "",
        paymentmode: null,
        bankcode: null,
        bankid: null,
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
        filler: "",
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
  
        currency: null,
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
  
      getPublicExportListHandler() {
        const vm = this
        
        this.$store.dispatch('get', {url: `/publicsalexport/all/${this.$store.getters.userId}`})
        .then(res => {
          let dateHolder = vm.data.year + "-" + 
                           vm.padRJ(vm.data.month, 2) + "-" + 
                           vm.daysInMonth(vm.data.month, vm.data.year);
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let payrolldataholder = res.data.rec;
  
          payrolldataholder = payrolldataholder.filter(e => e.poid == vm.data.salexprecdetail[0].payoutctrlid)
          
          payrolldataholder.forEach(function (o) {
            var existing = vm.payrolldata.filter(function (i) {
              return i.staffid === o.staffid;
            })[0];
            if (!existing || existing == undefined) {
              if (o.bankid) {
                if (moment(o.startdate).format('YYYY-MM-DD') <= date && date <= moment(o.enddate).format('YYYY-MM-DD')) {
                      vm.payrolldata.push(o);
                }
              } else {
                vm.payrolldata.push(o);
              }
            }
          });
          this.loadStaffFilter()
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      loadStaffFilter() {
        const vm = this;
  
        this.$store.dispatch('post', {url: `/stafffilter/${this.$store.getters.userId}`, payload: this.parameter})
          .then(res => {
              vm.staffilterlist = res.data.rec;
              let checkval = false;
              for (const key in this.parameter) {
                if (this.parameter[key] && key != 'pomth' && key != 'poyr') checkval = true
              }
  
              if (checkval) {
                vm.payrolldata = vm.payrolldata.filter((el) => {
                    return vm.staffilterlist.some((f) => {
                        return f.staffid === el.staffid;
                    });
                });
              }
  
          })
          .catch(err => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
          });
      },
  
      getCompanybankaccountListHandler() {
        const vm = this;
  
        this.$store.dispatch('get', {url: "/bankaccount/all"})
          .then(function (res) {
            vm.companyaccount = res.data.rec;
          })
          .catch(function (err) {
            vm.errMsg = err;
          });
      },
  
      async getData() {
        const vm = this
        let body = []
        let holdpayment = []
        let bankaccount = {}
        let paymentmode = ''
        let getAmt;
        //let validationcode = ''
        let dataholder = vm.payrolldata
        let payrolldetail = this.filterAcc(dataholder);
        let getAcc = vm.companyaccount.find(
          (item) => item.accountdesc === vm.data.bank
        );
  
        this.processedCount = dataholder.length;
        this.successCount = payrolldetail.length;
        this.errCount = dataholder.length - payrolldetail.length;
        this.currency = payrolldetail[0].exchratecode;
  

        body.push({
            "Payment Type/ Mode : PBB/IBG/REN": "(M) - Char: 3 - A",
            "Bene Account No.": "(M) - Char:20 - N",
            "BIC": "(M) - Char: 11 - A",
            "Bene Full Name": "(M) - Char: 120 - A",
            "ID Type: For Intranak & IBG NI,OI,BR,PL,ML,PP  FOR Rentas NI,OI,BR,OT": "(O) - Char: 2 - A",
            "Bene Identificatiion No/ Passport": "(O) - Char: 29 - AN",
            "Payment Amount (with 2 decimal points)": "(M) - Char: 18 - N",
            "Recipient Reference (shown in sender and bene statement)": "(M) - Char: 20 - AN",
            "Other Payment Detail": "(O) - Char: 20 - AN",
            "Bene Email 1": "(O) - Char: 70 - AN",
            "Bene Email 2": "(O) - Char: 70 - AN",
            "Bene Mobile No.1": "(O) - Char: 15 - N",
            "Bene Mobile No.2": "(O) - Char: 15 - N",
            "Joint Bene Name" : "(O) - Char: 120 - A",
            "Joint Beneficiary Identification No.": "(O) - Char: 29 - AN",
            "Joint ID Type: For Intrabank & IBG NI, OI, BR, PL, ML, PP For Rentas NI, OI, BR, OT": "(O) - Char: 2 - A",
            "E-mail Content Line 1": "(O) - Char: 40 - AN",
            "E-mail Content Line 2": "(O) - Char: 40 - AN",
            "E-mail Content Line 3": "(O) - Char: 40 - AN",
            "E-mail Content Line 4": "(O) - Char: 40 - AN",
            "E-mail Content Line 5": "(O) - Char: 40 - AN",
          });

        payrolldetail.forEach(element => {
          //let email = ''
          //let validationvalue = ''
          
          if (element.bankid == 30) {
            paymentmode = 'PBB'
            bankaccount.bankcode = 'PBBEMYKL'
          } else {
            bankaccount = vm.bank.find(e => e.id == element.bankid)
            paymentmode = 'IBG'
          } 
  
          //if (element.email != null) email = element.email
          //if (element.idtypeid == 1) validationcode = 'New IC No.'
          //if (element.idtypeid == 2) validationcode = 'Old IC No.'
          //if (element.iddetails != null) validationvalue = element.iddetails.split("-").join("")
  
          body.push({
            "Payment Type/ Mode : PBB/IBG/REN": paymentmode,
            "Bene Account No.": element.staffaccountno,
            "BIC": bankaccount.bankcode,
            "Bene Full Name": element.accountname,
            "ID Type: For Intranak & IBG NI,OI,BR,PL,ML,PP  FOR Rentas NI,OI,BR,OT": "",
            "Bene Identificatiion No/ Passport": "",
            "Payment Amount (with 2 decimal points)": element.netpay.toFixed(2),
            "Recipient Reference (shown in sender and bene statement)": vm.formData.recref,
            "Other Payment Detail": "",
            "Bene Email 1": "",
            "Bene Email 2": "",
            "Bene Mobile No.1": "",
            "Bene Mobile No.2": "",
            "Joint Bene Name" :"",
            "Joint Beneficiary Identification No.": "",
            "Joint ID Type: For Intrabank & IBG NI, OI, BR, PL, ML, PP For Rentas NI, OI, BR, OT": "",
            "E-mail Content Line 1": "",
            "E-mail Content Line 2": "",
            "E-mail Content Line 3": "",
            "E-mail Content Line 4": "",
            "E-mail Content Line 5": "",
          });
  
          holdpayment.push(element.netpay)
        });
  
        if (holdpayment) {
          let totalAmt = holdpayment.reduce(function (a, b) {return a + b}, 0);
          //this.gettotalAmt = totalAmt.toFixed(2);
          getAmt = totalAmt.toFixed(2);
        }
  
        this.gettotalAmt = getAmt;
        
        let getDate = "";
        if (this.formData.paymentdate) {
        getDate = moment(this.formData.paymentdate).format("DD/MM/YYYY");
        }
  
        let getheader = [
        ["Payment Date: (DD/MM/YYYY) ",
        this.padSpaceLJ(getDate, 10)] //can show >> problem at footer
        ];
  
        // let getfooter = [
        // ["Total", "" , "" , "" ,
        // this.padRJ(getAmt)
        // ]];
        body.push({
            "Payment Type/ Mode : PBB/IBG/REN": "Total:",
            "Bene Account No.": "",
            "BIC": "",
            "Bene Full Name": "",
            "ID Type: For Intranak & IBG NI,OI,BR,PL,ML,PP  FOR Rentas NI,OI,BR,OT": "",
            "Bene Identificatiion No/ Passport": "",
            "Payment Amount (with 2 decimal points)": this.padRJ(getAmt),
          });
  
        let getdate = "";
        if (this.formData.paymentdate) {
        getdate = moment(this.formData.paymentdate).format("DDMMYY");
        }
  
        this.titleData =
          getAcc.accountno+
          "PR" +
          this.padSpaceLJ(getdate, 6) +
          "01";
  
        if (body.length > 0) {
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(body, { origin: 'A2', skipHeader: false });
          XLSX.utils.sheet_add_aoa(ws, getheader/*, getfooter*/); 
          XLSX.utils.book_append_sheet(wb, ws, this.titleData);
          XLSX.writeFile(wb, this.titleData + '.xlsx');
        }
      },
  
      filterAcc(item) {
        let checkacc = this.accfilter(item)
        let checkaccbank = this.accBankfilter(checkacc)
        let checkapaymentmethod = this.paymentmethodfilter(checkaccbank)
        let checkactive = this.activefilter(checkapaymentmethod)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      accfilter(item) {
        let data = item.filter(e => e.bankid)
        let noacc = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noacc.length; i++) {
        noacc[i].errormsg = this.$t('allbank.noaccfound');
          this.accErr.push(noacc[i]);
        }
        return data
      },
  
      accBankfilter(item) {
        let data = item.filter(e => {
          for (let i = 0; i < this.bank.length; i++) {
            if (this.bank[i].id == e.bankid || e.bankid == 30) return e
          }
        })
        let noaccbank = item.filter((e) => !data.includes(e));
        for (let i = 0; i < noaccbank.length; i++) {
          noaccbank[i].errormsg = this.$t('allbank.bankaccnotinlist');
          this.accErr.push(noaccbank[i]);
        }
        return data
      },
  
      paymentmethodfilter(item) {
        let data = item.filter(e => e.paymodecode == 'BANK')
        let diffpayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < diffpayment.length; i++) {
          diffpayment[i].errormsg = this.$t('allbank.differentpaymentmethod');
          this.accErr.push(diffpayment[i]);
        }
        return data
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => e.netpay > 0)
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToCSV(header, data, footer) {
      //objectToCSV(data) {
          const csvRows = []
  
          // get header
          const headers = Object.keys(data[0]);
          const getHeader = header;
          csvRows.push(getHeader);
          //csvRows.push(headers.join(',')) 
  
          // loop over the rows
          for (const row of data) {
              const values = headers.map(header => {
                  const escaped = ('' + row[header]).replace(/"/g, '\\"')
                  return `"${escaped}"`
              })
              csvRows.push(values.join(','))
          }
          const getfooter = footer;
          csvRows.push(getfooter);
          return csvRows.join('\n')
      },
  
      download(data, title) {
        const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".csv");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      padLJ(num, size) {
        var s = num + "";
        while (s.length < size) s = s + "0";
        return s;
      },
  
      padSpaceRJ(char, size) {
        var s = char + "";
        while (s.length < size) s = " " + s;
        return s;
      },
  
      padSpaceLJ(char, size) {
        var s = char + "";
        while (s.length < size) s = s + " ";
        return s;
      },
  
      exportHandler() {
        if (!this.$refs.form.validate()) {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        } else {
          this.getData();
          let item = [];
          item.totalAmt = this.gettotalAmt;
          item.accounterr = this.accErr;
          item.negErrMss = this.negErrMsg;
          item.successCount = this.successCount;
          item.processedCount = this.processedCount;
          item.errCount = this.errCount;
          item.currency = this.currency;
          this.$router.push({ name: "salaryexportlog", params: { item } });
        }
      },
  
      backHandler() {
        let param = this.parameter
  
        this.$router.push({ name: "salaryexport", params: { param } });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  
    mounted() {
      this.getCompanybankaccountListHandler();
      this.getPublicExportListHandler();
    },
  };
  </script>