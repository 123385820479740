<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('allbank.salaryexportrec')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.salexpreccode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.salexprecdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.year')"
                                v-model="formData.salexpyear"
                                @input="changeYearHandler()"
                                dense
                                :items="yearList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.month')"
                                v-model="formData.salexpmonth"
                                @input="changeMonthHandler()"
                                dense
                                :items="monthList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5 mt-n7">
                            <v-btn icon color="orange">
                                <v-icon @click="loadPayoutList()">mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('column.status')"
                                ref="status"
                                :rules="[rules.required]"
                                v-model="formData.status"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('intgsetup.lastexportdate')"
                                type="date"
                                ref="statustimestamp"
                                :rules="[rules.required]"
                                v-model="formData.statustimestamp"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            <v-tabs>
                                <v-tab>{{$t('statutoryexportcommonused.payouts')}}</v-tab>
                                <v-tab>{{$t('columns.reference')}}</v-tab>

                                <!-- tab items for assigned -->
                                <v-tab-item>
                                        <div class="col-sm-12">
                                                <v-data-table
                                                    v-model="selected"
                                                    @input="changePayoutHandler()"
                                                    :headers="dataHeader"
                                                    :items="this.dataItems"
                                                    :options="tableOpt"
                                                    hide-default-footer
                                                    :single-select="singleSelect"
                                                    item-key="payoutcode"
                                                    show-select
                                                >
                                                </v-data-table>
                                        </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-dialog v-model="refdialog" max-width="800px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                        <span class="headline mb-4">{{$t('columns.reference')}}</span>
                                        </v-card-title>
                                        <v-card-text>
                                        <v-container>
                                            <v-form ref="form">
                                            <v-row>
                                                <v-col cols="8" sm="3" class="pb-n5 mt-n8">
                                                    <v-text-field
                                                        outlined
                                                        :label="this.$t('Date')"
                                                        type="date"
                                                        v-model="selections[0].date"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                                
                                                <v-col cols="8" sm="7" class="pb-n5 mt-n8">
                                                    <v-text-field
                                                        outlined
                                                        :label="this.$t('recfamdetails.refno')"
                                                        type="text"
                                                        v-model="selections[0].refno"
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                    <v-col cols="8" sm="10" class="pb-n5 mt-n8">
                                                        <v-text-field
                                                            outlined
                                                            :label="this.$t('columns.remark')"
                                                            type="text"
                                                            v-model="selections[0].remark"
                                                            dense
                                                        ></v-text-field>
                                                    </v-col>
                                            </v-row>
                                            </v-form>
                                        </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="closeRefInput">{{$t('button.cancel')}}</v-btn>
                                        <v-btn color="green darken-1" text @click="saveRefInput">{{$t('button.save')}}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                        <div class="col-sm-12">
                                                <v-data-table
                                                    v-model="selected"
                                                    :headers="refHeader"
                                                    :items="this.formData.saleexportrecordref"
                                                    :options="tableOpt"
                                                    hide-default-footer
                                                >
                                                    <template v-slot:item.action="{ item }">
                                                        <v-icon
                                                            small
                                                            class="mr-2"
                                                            @click="modifyItemHandler(item)"
                                                        >
                                                            mdi-pen
                                                        </v-icon>
                                                        <v-icon
                                                            small
                                                            class="ml-3 mr-2"
                                                            @click="deleteItemHandler(item)"
                                                        >
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                </v-data-table>
                                        </div>
                                </v-tab-item>
                            </v-tabs>
                        </v-col>
                    </v-row>


                    <!-- <v-row>
                        <v-col cols="6" sm="6" class="grey--text mt-6">
                            Company Bank Account
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5 mt-6">
                            <v-autocomplete
                                outlined
                                :label="this.$t('allbank.companybankacc')"
                                :rules="[rules.required]"
                                v-model="formData.bankaccountid"
                                @input="updateValue"
                                dense
                                :items="bankList"
                                hide-selected
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupstaffbankaccount.companybank')"
                                v-model="formData.bankaccountid"
                                @input="updateValue"
                                dense
                                :items="bankList2"
                                readonly
                                append-icon=" "
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('allbank.companybankaccno')"
                                v-model="formData.bankaccountid"
                                @input="updateValue"
                                dense
                                :items="bankList1"
                                readonly
                                append-icon=" "
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object
    },

    components: {
        
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            formData: {
                salexpreccode:  this.parentObject.data.salexpreccode,
                saleexportrecorddetail: this.parentObject.data.saleexportrecorddetail,
                salexprecdesc:  this.parentObject.data.salexprecdesc,
                salexpyear:  this.parentObject.data.salexpyear,
                salexpmonth:    this.parentObject.data.salexpmonth,
                bankaccountid:    this.parentObject.data.bankaccountid,
                stamp:      this.parentObject.data.stamp,
                userid:     this.parentObject.data.userid,
                saleexportrecordref: this.parentObject.data.saleexportrecordref,
                status:     this.parentObject.data.status,
                statustimestamp:     this.parentObject.data.statustimestamp
            },

            singleSelect: false,
            dataItems: [],
            dataHeader: [
                { text: this.$t('reportsparam.payout'),            value: 'payoutcode',    width: '150px', sortable: true},
                { text: this.$t('payrollenquiryreport.payoutdesription'),   value: 'payoutdesc',    width: '300px', sortable: true},
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',  width: '300px', sortable: true}
            ],
            refHeader: [
                { text: this.$t('columns.actions'),value: "action",width: "100px",sortable: false,},
                { text: this.$t('columns.date'), value: 'date', type:"date",    width: '150px', sortable: true},
                { text: this.$t('recfamdetails.refno'),   value: 'refno',    width: '300px', sortable: true},
                { text: this.$t('columns.remark'),        value: 'remark',  width: '300px', sortable: true}
            ],
            selections: [{
                id: null,
                salexprecid: null,
                date: null,
                refno: null,
                action:0,
                stamp: 0,
            }],

            tableOpt: {
                itemsPerPage: -1
            },

            //not sure what this does
            selected: [],
            affectItems: [],
            

            monthList: [],
            yearList: [],
            bankList: [],
            bankList1: [],
            bankList2: [],
            payoutList: [],
            banks: [],
            months: [],
            years: [],

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew,
             refdialog: false,
             modify: false
        }
    },

    methods: {

        loadPayout() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
                .then(res => {
                    vm.payoutList = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )        
                        
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )
                    }
                    this.loadPayoutEdit();

                    if (vm.formData.salexpyear) {
                        vm.changeYearHandler(vm.formData.salexpyear);
                    }

                    if (vm.formData.salexpmonth) {
                        vm.changeMonthHandler(vm.formData.salexpmonth);
                    }

                    
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeYearHandler(id) {
            this.years.find(item => item.id === id);
        },

        changeMonthHandler(id) {
            this.months.find(item => item.id === id);
        },

        changePayoutHandler() {
            this.formData.saleexportrecorddetail = this.selected
            this.updateValue()
        },

        loadBank(){
            const vm = this;

            this.$store.dispatch('get', {url: '/bankaccount/all'})
                .then(res => {
                    vm.banks = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankList.push(
                            {
                                text: res.data.rec[i].accountcode,
                                value: res.data.rec[i].id
                            }
                        )                        
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankList1.push(
                            {
                                text: res.data.rec[i].accountno,
                                value: res.data.rec[i].id
                            }
                        )                        
                    }

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.bankList2.push(
                            {
                                text: res.data.rec[i].compnybankname,
                                value: res.data.rec[i].id
                            }
                        )                        
                    }

                    if (vm.formData.companybankid) {
                        vm.chanegBankHandler(vm.formData.companybankid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        chanegBankHandler(id) {
            this.banks.find(item => item.id === id);
            
        },

        loadPayoutList() {
            let payoutYear = [];
            if (!this.formData.salexpyear && !this.formData.salexpmonth) {
                this.dataItems = this.payoutList
            } else if (this.formData.salexpyear) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    if (this.payoutList[i].pyyear === this.formData.salexpyear) {
                        payoutYear.push(this.payoutList[i])
                    }
                }
                if (this.formData.salexpmonth) {
                    this.dataItems = []
                    for (let j = 0; j < payoutYear.length; j++) {
                        if (payoutYear[j].pymonth === this.formData.salexpmonth) {
                            this.dataItems.push(payoutYear[j])
                        }
                    }
                }
            }
        },

        loadPayoutEdit() {
            let getItem = []
            if (this.isEdit) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    for (let j = 0; j < this.formData.saleexportrecorddetail.length; j++) {
                        if (this.payoutList[i].id === this.formData.saleexportrecorddetail[j].payoutctrlid) {
                            getItem[j] = this.payoutList[i]
                            getItem[j].exId = this.formData.saleexportrecorddetail[j].id
                        }
                    }
                }
                this.loadPayoutList()
                this.selected = getItem
                this.formData.saleexportrecorddetail = []
            }
        },

        updateValue() {
            this.$emit('input', {
                salexpreccode: this.formData.salexpreccode,
                salexprecdesc: this.formData.salexprecdesc,
                salexpyear: this.formData.salexpyear,
                salexpmonth: this.formData.salexpmonth,
                bankaccountid: this.formData.bankaccountid,
                saleexportrecorddetail: this.formData.saleexportrecorddetail,
                stamp: this.formData.stamp,
                userid: this.formData.userid,
                saleexportrecordref: this.formData.saleexportrecordref,
                status: this.formData.status,
                statustimestamp: this.formData.statustimestamp
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'salaryexportrecordlist', params: { search: this.search}});
        },
        
         modifyItemHandler(item) {
            this.refdialog = true;
            this.selections = [item];
         },  

         closeRefInput() {
            this.selections = [{
                id: null,
                salexprecid: null,
                date: null,
                refno: null,
                action:0,
                stamp: 0,
            }];
            this.refdialog = false;
        },

        saveRefInput() {

             if (this.selections[0].date == null || this.selections[0].refno == null){

              this.$dialog.alert(this.$t('fillinclaimgroupandmax')).then((dialog) => {
                console.log(dialog);
             });
            } else {
            
            this.isErr = false;
            
            if (!this.selections[0].id && this.selections[0].action == 0) {
                this.selections[0].action = 1;
                this.formData.saleexportrecordref.push(Object.assign({}, this.selections[0]));
            }
            this.selections = [{
                id: null,
                salexprecid: null,
                date: null,
                refno: null,
                action:0,
                stamp: 0,
            }]; 
            this.refdialog = false;
            }
            
        },

    },

    mounted() {
        this.loadBank();
        this.loadPayout();
    }
}
</script>