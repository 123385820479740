    <template>
    <div>
        <v-dialog v-model="wizarddialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupwizardsetup.wizardinputitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row>
                    
                    <v-col cols="12" sm="6" md="6" class="mt-n88">
                        <v-text-field
                            outlined
                            :label="this.$t('setupwizardsetup.number')"
                            type="text"
                            dense
                            v-model="selections[0].seqno"
                            :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="10" sm="6" md="6" class="mt-n8">
                        <v-textarea
                            style="font-size:13px"
                            outlined
                            :label="this.$t('setupwizardsetup.taskdesc')"
                            type="text"
                            dense
                            v-model="selections[0].taskdesc"
                            :rules="[rules.required, rules.counter]"
                            maxlength = "65535"
                        ></v-textarea>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                                    :label="this.$t('setupwizardsetup.taskscreen')"
                                    type="text"
                                    ref= "funcname"
                                    :items="screenList"
                                    hide-selected
                                    :rules="[rules.required]"
                                    v-model="selections[0].link"
                                    @change="changeFunctionHandler(selections[0].link)"
                                    dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeWizardInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveWizardInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            class="elavation-1"
            :items="detailItem"
            fixed-header
            :sort-by="['code']"
            
            >
        <template v-slot:body>
            <tbody>
                <tr v-for="item in detailItem" :key="item">
                <td>
                  <v-icon 
                  small class="mr-2" 
                  @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteItemHandler(item)"
                >
                    mdi-delete
                </v-icon>
                </td>
                    <td> {{ item.seqno }} </td>
                    <td >{{ item.taskdesc }} </td>
                    <td @click="loadDetailHandler(item.link)">{{item.funcname}} </td>
                </tr>  
            </tbody>
        </template>
        </v-data-table>
    
    </div>
</template>

<script>

import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
    },

    data() {
        return {
            wizarddialog: false,
            detailItem: this.parentObject.detailItem,
        
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length != 65535 || this.$t('reportsparam.counter'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
             },


            selections: [
                {
                seqno: null,
                taskdesc: null,
                link: null,
                funcname: null,
                rectimestamp: null,
                updatedby: null,
                action:0 , 
                stamp: 0,
                
                }
            ],

            itemHolder: [],
            detailList: [],

            screenList: [],
            linkName: [],
            link: [],
            funcname: [],

            seqnos: [],
            seqno: [],
            arr: [],

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }

        
    },

    methods: {
        compareArray,

        loadScreenLinkHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/screen/all"}).then(res => {
                vm.linkName = res.data.rec;
              
                for (let i = 0; i < vm.linkName.length; i++) {
                    vm.screenList.push({
                        text: vm.linkName[i].funcname,
                        value: vm.linkName[i].link
                    });
                }
            });
          
        },

        changeFunctionHandler(link){
            let item = this.linkName.find(item => item.link == link);

            this.selections[0].funcname = item.funcname;
        },

       loadDetailHandler(link) {
            
            this.$router.push({name: link});
        },

    //     seqnoHandler(val, seqnos, arr){
          
    //       console.log('seqno', seqnos)
    //          for(let i = 0; i < seqnos; i++){
    //             if(arr[i].seqno === val.seqno){
    //                 this.isErr = false;
    //             }
    //             else{
    //                 this.isErr = true;
    //              }
    //          }
    // },

        modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

            this.itemHolder = item
            this.selections[0] =  this.itemHolder;

            this.wizarddialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveWizardInput() {

            if (this.selections[0].seqno == null || this.selections[0].link == null || this.selections[0].taskdesc == null){

              this.$dialog.alert(this.$t('fillintasknumtakdesctaskscreen')).then((dialog) => {
                console.log(dialog);
             });
            } else {
           this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            this.$emit('input', this.detailItem);
            this.wizarddialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
        },

        closeWizardInput() {
            this.wizarddialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {   
            
            if (this.selections[0].seqno == null || this.selections[0].link == null){

              this.$dialog.alert(this.$t('fillintasknumtaskscreen')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }

            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.wizarddialog = false;

            // reset the form fields
            this.resetSelections();
            }
        },
        
        resetSelections() {
            this.selections = [{
                
                wizardid: null,
                seqno: null,
                taskdesc: null,
                link: null,
                funcname : null,
                rectimestamp: null,
                updatedby: null,
                action: 0
            }]
        },

    },

    mounted() {
        this.loadScreenLinkHandler();
        //this.seqnoHandler();
    }
}
</script>