<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('workhourspayitemmappingsetup.workhourspayitemmappinglist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('workhourspayitemmappingsetup.mapset'), value: "mapset", width: "200px" },
        { text: this.$t('workhourspayitemmappingsetup.mapsetdescription'), value: "mapsetdesc", width: "200px" },
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getWorkHoursPayItemMappingHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/workhourspayitemmapping/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;
          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({
        name: "workhourspayitemmappingedit",
        params: { id: item.id, search: item.search },
      });
    },

    newRecordHandler() {
      this.$router.push({ name: "workhourspayitemmappingnew" });
    },
  },

  mounted() {
    this.getWorkHoursPayItemMappingHandler();
  },
};
</script>