<template>
    <div>
        <kbz-form v-if="this.id == 44"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                salexprecid: this.salexprecid,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />
        <maybank-form v-if="this.id == 1"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />
        <maybank-form2 v-if="this.id == 42"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />
        <hlb-form v-if="this.id == 7"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />
        <ambank-form v-if="this.id == 12"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />
        <rhb-form v-if="this.id == 17"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <rhb-smart-form v-if="this.id == 26"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <cimb-form v-if="this.id == 19"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <public-form v-if="this.id == 31"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <affin-form v-if="this.id == 32"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <hsbc-form v-if="this.id == 37"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                parameter: this.parameter,
                errMsg: this.errMsg,
            }"
            
            v-on:input="formData = $event"
        />

        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import KBZForm from '../../../components/payroll/salaryexport/KBZForm';
import MaybankForm from '../../../components/payroll/salaryexport/MaybankForm';
import MaybankForm2 from '../../../components/payroll/salaryexport/MaybankForm2';
import HLBForm from '../../../components/payroll/salaryexport/HLBForm';
import AmbankForm from '../../../components/payroll/salaryexport/AmbankForm';
import RHBForm from '../../../components/payroll/salaryexport/RHBForm.vue';
import RHBSmartForm from '../../../components/payroll/salaryexport/RHBSmartForm.vue';
import CIMBForm from '../../../components/payroll/salaryexport/CIMBForm';
import PublicForm from '../../../components/payroll/salaryexport/PublicForm';
import AffinForm from '../../../components/payroll/salaryexport/AffinForm';
import HSBCForm from '../../../components/payroll/salaryexport/HSBCForm';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'kbz-form': KBZForm,
        'maybank-form': MaybankForm,
        'maybank-form2': MaybankForm2,
        'hlb-form': HLBForm,
        'ambank-form': AmbankForm,
        'rhb-form': RHBForm,
        'rhb-smart-form': RHBSmartForm,
        'cimb-form': CIMBForm,
        'public-form': PublicForm,
        'affin-form': AffinForm,
        'hsbc-form': HSBCForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            item: this.$route.params.item,
            id: this.$route.params.id,
            parameter: this.$route.params.parameter,
            salexprecid: this.$route.params.salexprecid,
            
            errMsg: '',
            isErr: false,
        }
    },

}

</script>