<template>
    <div>
        <app-form
            v-bind:parentObject="{
                data: this.formData,
                errMsg: this.errMsg,
            }"
            v-on:input="formData = $event"
        />
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-5">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="3" >
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.fromdate')"
                                    type="date"
                                    :rules="[rules.required]"
                                    v-model="formData.fromDate"
                                    dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="3" >
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.untildate')"
                                    type="date"
                                    :rules="[rules.required]"
                                    v-model="formData.untilDate"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                         <v-row>
                            <v-col cols="6" sm="6" md="10">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.staffname')"
                                    type="text"
                                    dense
                                    :items="staffDetailsList"
                                    v-model="formData.staffid"
                                ></v-autocomplete>
                                </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" sm="6" md="6" class="pb-n5 mt-n8" >
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('reportauditlisting.actioncode')"
                                    type="text"
                                    ref= "action"
                                    :items="actionList"
                                    hide-selected
                                    v-model="formData.action"
                                    dense
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>    
                            <v-col cols="4" sm="8" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('reportauditlisting.tablename')"
                                    type="text"
                                    v-model="formData.tablename"
                                    dense
                                    clearable
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                    >
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog"

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-error': ErrorDialog
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                fromDate: null,
                untilDate: null,
                staffid: null,
                action: null,
                tablename: null
            },
            
            parameters: {
                tablename: null,
                entdate: null,
                id: null,
                usertype: null,
                enttype:null
            },

            showFilter: {
                tablename: true,
                entdate: true,
                id: true,
                usertype: true,
                enttype:true
            },

            codeList: [],
            actionList: [ 'C', 'M', 'D'],
            errMsg: "",
            isErr: false,
            staffDetailsList: [],
            staffIdList: [],
        }
    },

    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
                .then((res) => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.staffDetailsList.push({
                    text: res.data.rec[i].psndetails,
                    value: res.data.rec[i].id,
                    });
                }

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.staffIdList.push({
                    text: res.data.rec[i].staffcode,
                    value: res.data.rec[i].id,
                    });
                }

                //   for (let i = 0; i < res.data.rec.length; i++) {
                //     vm.staffCompanyList.push({
                //       text: res.data.rec[i].compnyname,
                //       value: res.data.rec[i].id,
                //     });
                //   }
                })
                .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
    },

         loadTableName(){
             const vm = this;

             this.$store.dispatch('get', {url: '/auditlisting/all'})
                 .then(res => {
                     vm.tablename = res.data.rec;

                     for (let i = 0; i < res.data.rec.length; i++){
                         vm.codeList.push(
                             {
                                 text: res.data.rec[i].tablename,
                                 
                             }
                         )
                     }

                     if (vm.formData.tablename){
                         vm.changeTablenameHandler(vm.formData.tablename);
                     }
                 })
                 .catch(err => {
                     vm.isErr = true;
                     vm.errMsg = err;
                     vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                 })
         },

        changeTablenameHandler(id){
            this.tablename.find(item => item.id === id);
        },

        goHandler(){
            const formData = this.formData
            const param = this.parameters
            const vm = this

            this.$store.dispatch('post', {url: "/auditlisting/validate", payload: vm.formData})
                .then(res => {
                    console.log(res.data);
                    if(res.data.error){
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } else {
                        this.$router.push({
                        name: 'detailsauditlisting', params: { formData, param }
                    });
                    }
                })
                .catch(err => {
                console.log(err);    
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        updateValue(){
            this.$emit("input", {
                tablename: this.formData.tablename
            })
        },
    },

    mounted() {
        this.loadTableName();
        this.loadStaffDetailsListHandler();

    },
    
}
</script>