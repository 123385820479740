<template>
    <div>
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="150"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
        <v-container fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
            <v-form>

                
                <v-row v-if="this.$route.params.formData.clmtypedesc != null">
                    <h2 class="pb-5">{{ this.pageTitle }} </h2>
                </v-row>

                <v-row v-else>
                    <h2 class="pb-5">{{ this.pageTitleNew }} </h2>
                </v-row>

                  <v-row class="mb-8">
                        <v-col cols="1">
                            {{$t('columns.filter')}}
                        </v-col>
                        <v-col class="page_title_value">
                            <div v-if="emptypedescholder"> {{ this.emptypedescholder }} </div>
                            <div v-if="compnynameholder"> {{ this.compnynameholder }} </div>
                            <div v-if="brchnameholder"> {{ this.brchnameholder }} </div>
                            <div v-if="deptnameholder"> {{ this.deptnameholder }} </div>
                            <div v-if="valuedescholder"> {{ this.valuedescholder }} </div>
                            <div v-if="sectionameholder"> {{ this.sectionameholder }} </div>
                            <div v-if="divisionameholder"> {{ this.divisionameholder }} </div>
                            <div v-if="unitnameholder"> {{ this.unitnameholder }} </div>
                            <div v-if="gradenameholder"> {{ this.gradenameholder }} </div>
                        
                        </v-col>
                </v-row>

                 <v-row>
                    <div >
                        <v-row>
                            <v-col>
                                <v-btn 
                                    v-model="goBtn"
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="backHandler" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                </v-row>
            
            <v-container 
            fluid id="scroll-target" 
            class="custom-form overflow-y-auto px-5"
            >

                <v-row justify="center" align="center">

                    <v-col cols="10" sm = "10">
                        <v-card elevation="5" outlined>
                            <div>
                                <apexchart
                                    ref= "linegraph" 
                                    type="line" 
                                    width="100%" 
                                    :options="lineoptions" 
                                    :series="lineseries">
                                </apexchart>
                            </div>
                        </v-card>
                    </v-col> 
                </v-row> 
                    <br>
                    <br>
                        <v-row justify="center" align="center">
                               
                                <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="TotalClaim" color="red">
                                            {{$t('claimutilizationtrendanalysis.totalclaimrecord')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="TotalClaimContent">
                                                 {{this.dataItems.length}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="ClaimAmt">
                                            {{$t('claimutilizationtrendanalysis.totalclaimamount')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="ClaimAmtContent">
                                                {{this.$route.params.formData.reportingcrcycode}} {{this.total}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="Average">
                                            {{$t('claimutilizationtrendanalysis.averagepermonth')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="AverageContent">
                                                {{this.$route.params.formData.reportingcrcycode}} {{this.averagemonth}} 
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                 <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="AverageClaim">
                                            {{$t('claimutilizationtrendanalysis.averageclaim')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="AverageClaimContent">
                                               {{this.$route.params.formData.reportingcrcycode}} {{this.average}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                
                           
                        </v-row>
         
                        <v-spacer></v-spacer>
                        <br>
                        <br>
                    

                <v-container 
                style="overflow: auto"
                >
                <v-row>
                    <v-toolbar flat>
                    <v-toolbar-title
                        >{{$t('claimutilizationtrendanalysis.stafflistforclaimutilizationtrendanalysis')}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                        <v-avatar class="profile mr-5" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_pdf.png`"
                        @click="printPDF"
                        />
                    </v-avatar>

                    <v-avatar class="profile" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_csv.png`"
                        @click="getCSV"
                        />
                    </v-avatar>

                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            style="width: 20px"
                            class="pr-5 pt-3"
                            hide-details>
                        </v-text-field>
                </v-toolbar>
                </v-row>


                         <v-row>
                                <v-col cols="20" sm="20" >
                                <v-data-table
                                    :headers="dataHeader"
                                    :search ="search"
                                    :items="dataItems"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                    no-data-text="No records found for the selected claim utilization period."
                                >
                                </v-data-table>
                                </v-col>
                            </v-row>
                </v-container>
            </v-container>
            </v-form>
        </v-container>    
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import Loading from "vue-loading-overlay";

export default {
    components: {
        'apexchart': VueApexCharts,
        Loading
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
           
            pageTitle: this.$t('claimutilizationtrendanalysis.claimutilizationtrendanlysisfor') + this.$route.params.formData.clmtypedesc + this.$t('payrollpivotreport.from') + this.$route.params.formData.fromYear + " / " + this.$route.params.formData.month1
            + " Until " + this.$route.params.formData.untilYear + " / " + this.$route.params.formData.month2,

            pageTitleNew: this.$t('claimutilizationtrendanalysis.claimutilizationtrendanlysis') + " " + this.$t('payrollpivotreport.from') + " " + this.$route.params.formData.fromYear + " / " + this.$route.params.formData.month1
            + this.$t('until') + this.$route.params.formData.untilYear + " / " + this.$route.params.formData.month2,
            pageTitleValue: "",
            search: '',

            data: {
                formData: {
                    fromYear: this.$route.params.formData.fromYear,
                    month1: this.$route.params.formData.month1,
                    untilYear: this.$route.params.formData.untilYear,
                    month2: this.$route.params.formData.month2,
                    clmtypeid: this.$route.params.formData.clmtypeid,
                    clmtypedesc: this.$route.params.formData.clmtypedesc,
                    reportingcrcyid: this.$route.params.formData.reportingcrcyid,
                    reportingcrcycode:this.$route.params.formData.reportingcrcycode,
                    exchrateid: this.$route.params.formData.exchrateid,
                    userid: this.$store.getters.userId,
                },

                  parameters: {
                    brchid: this.$route.params.param.branchid,
                    compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    divnid: this.$route.params.param.divisionid,
                    sectioid: this.$route.params.param.sectionid,
                    unitid: this.$route.params.param.unitid,
                    gradeid: this.$route.params.param.gradeid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    claimgroupid: this.$route.params.param.claimgroupid, 
                },

            },

            dataItems: [],
            piedata:[],
            chartItems: [],
            total: null,
            length: null,
            average: null,
            averagemonth: null,

            parameter: null,

            //for line graph
            lineoptions: {
                chart: {
                    height: 450,
                    id: 'linechart',
                    locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                    defaultLocale: this.$route.params.lang,  
                    type: 'line',
                },
                
                dataLabels: {
                    enabled: true,
                },
  
                stroke: {
                    curve: 'smooth'
                },

                 title: {
                    // text: "Claim Utilization Trend Analysis For " + this.$route.params.formData.clmtypedesc + " From " + this.$route.params.formData.fromYear + " / " + this.$route.params.formData.month1
                    //         + " Until " + this.$route.params.formData.untilYear + " / " + this.$route.params.formData.month2,
                    // align: 'center',
                },

                 markers: {
                    size: 1
                },

                xaxis: {
                    categories: ['Jan',
                        'Feb', 
                        'Mac', 
                        'April', 
                        'May', 
                        'June', 
                        'July', 
                        'Aug', 
                        'Sept',
                        'Oct', 
                        'Nov', 
                        'Dec'],
                    // title: {
                    //     text: 'Claim Dates'
                    // }
                },

                yaxis: {
                    title: {
                        text: this.$t('claimutilizationtrendanalysis.claimapprovedamount')
                    },
                    // min: 0,
                    // max: 0,
                    // tickAmount: 0,
                },

            
               
                noData: {
                    text: this.$t('claimutilizationtrendanalysis.nodata'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
               
            },
            lineseries: [], 

            //table header 
            dataHeader: [
                    { text: this.$t('statutoryexportcommonused.no'), width: "50px", value: "no" },
                    { text: this.$t('columns.name'), value: 'staffname', width: '300px' },
                    { text: this.$t('columns.staffid'), value: 'staffno', width: '100px' },
                    { text: this.$t('claimreport.claimtype'), value: 'clmtypdesc', width: '200px' },
                    { text: this.$t('claimrecordlisting.receiptdate'), value: 'receiptdate', width: '200px' },
                    { text: this.$t('payeeinputprocess.approvedamount'), value: 'aprvamt', width: '190px' },
                    { text: this.$t('columns.currency'), value: 'clmtypecrcycode', width: '190px' },
                    { text: this.$t('claimutilizationtrendanalysis.reportedapprovedamount'), value: 'repaprvamt', width: '240px' },
                    { text: this.$t('claimutilizationtrendanalysis.reportingcurrency'), value: 'reportingcrcycode', width: '190px' },
                    { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                    { text: this.$t('columns.branch'), value: 'brchname', width: '200px' },
                    { text: this.$t('columns.division'), value: 'divnname', width: '200px' },
                    { text: this.$t('columns.department'), value: 'deptname', width: '200px' },
                    { text: this.$t('columns.section'), value: 'sectioname', width: '200px' },
                    { text: this.$t('columns.unit'), value: 'unitname', width: '200px' },
                    { text: this.$t('columns.position'), value: 'posdesc', width: '200px' },
                    { text: this.$t('columns.grade'), value: 'grddesc', width: '200px' },
            ],

            //load error message variable
            isErr: false,
            loading: true,
            checkloading:true,

            goBtn: true,
            title: this.$t('reportheadcountanalysis.stafflistfor'),

            clmtypeid: '',
            clmtypdesc: '',
            clmtypecrcyid: '',

            emptypedescholder: null,
            compnynameholder: null,
            brchnameholder: null,
            deptnameholder: null,
            valuedescholder: null,
            sectionameholder: null,
            divisionameholder: null,
            unitnameholder: null,
            gradenameholder: null,

            exchangeItems:[],
            // newdata:[],
            // dataItems2: [],
            clmItems: [],

            rate: null,
            sqlAll: null,
            sqlAll2: null,
            repaprvamt: null,
        }
    },

    methods: {

        employmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/emptype/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.emptypeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.emptypeid)[0]
                        vm.emptypedescholder = itemholder.emptypedesc
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          companyHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/company/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.compnyid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.compnyid)[0]
                        vm.compnynameholder = itemholder.compnyname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        branchHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/branch/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.brchid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.brchid)[0]
                        vm.brchnameholder = itemholder.brchname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        departmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/department/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.deptid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.deptid)[0]
                        vm.deptnameholder = itemholder.deptname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        valvalueHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/valvalue/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.claimgroupid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.claimgroupid)[0]
                        vm.valuedescholder = itemholder.valuedesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        sectionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/emptype/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.sectioid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.sectioid)[0]
                        vm.sectionameholder = itemholder.sectioname
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          unitHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/company/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.unitid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.unitid)[0]
                        vm.unitnameholder = itemholder.unitname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        divisionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/branch/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.divnid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.divnid)[0]
                        vm.divisionameholder = itemholder.divnname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        gradeHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/grade/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradeid)[0]
                        vm.gradenameholder = itemholder.grddesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    TrendChart(){
    const vm = this;

    vm.loading = true

    let dateHolder1 = this.data.formData.fromYear + "-" + this.padRJ(this.data.formData.month1, 2) + "-" + this.padRJ(1, 2);
    let dateHolder2 = this.data.formData.untilYear + "-" + this.padRJ(this.data.formData.month2, 2) + "-" + this.daysInMonth(this.data.formData.month2, this.data.formData.untilYear);
    let startdate = moment(dateHolder1).format("YYYY-MM-DD");
    let enddate = moment(dateHolder2).format("YYYY-MM-DD");

    let month1 = moment(dateHolder1).format("M");
    let month2 = moment(dateHolder2).format("M");


 
        this.$store.dispatch('post', {
            url: `/claimutilizationtrend/${this.$store.getters.userId}` + "/" + startdate + "/" + enddate, 
            payload: this.data})
        .then(res => {

            vm.dataItems = res.data.rec;
           console.log('data', vm.dataItems)

            vm.title = this.$t('claimutilizationtrendanalysis.stafflistofclaimutilizationtrendanalysis')

            vm.loading = false

            for (let j = 0; j < vm.dataItems.length; j++) {
                    vm.dataItems[j].no = j + 1;
                   // vm.dataItems[j].receiptdate = moment(vm.dataItems[j].receiptdate).format("DD-MM-YYYY");
                    vm.dataItems[j].aprvamt = parseFloat(vm.dataItems[j].aprvamt.toFixed(2));
                    vm.dataItems[j].reportingcrcycode = vm.$route.params.formData.reportingcrcycode;
                    vm.dataItems[j].reportingcrcyid = vm.$route.params.formData.reportingcrcyid;
                    vm.dataItems[j].clmtypecrcyid = parseInt(vm.dataItems[j].clmtypecrcyid);
                     vm.dataItems[j].sqlAll = parseInt(vm.dataItems[j].sqlAll);


              if(vm.dataItems[j].clmtypecrcyid == vm.dataItems[j].reportingcrcyid ){

                    let convertedrate = 1
                     let currentAmount =  parseFloat(vm.dataItems[j].aprvamt) *
                                        parseFloat(convertedrate);
                                
                    vm.dataItems[j].repaprvamt = parseFloat(currentAmount.toFixed(2));
                   //   vm.dataItems[j].repaprvamt = vm.dataItems[j].repaprvamt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


                }

                 if( vm.dataItems[j].sqlAll == '1' && vm.dataItems[j].clmtypecrcyid != vm.dataItems[j].reportingcrcyid){

                      let convertedrate =   vm.dataItems[j].rate
                      let currentAmount =  parseFloat(vm.dataItems[j].aprvamt) *
                                           parseFloat(convertedrate);

                    vm.dataItems[j].repaprvamt = parseFloat(currentAmount.toFixed(2));
                  //  vm.dataItems[j].repaprvamt = vm.dataItems[j].repaprvamt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                 } 
                 
                let gettotal = vm.dataItems.map(({repaprvamt}) => repaprvamt).reduce((a, b) => a + b, 0)
                    gettotal = gettotal.toFixed(2);

                vm.total = gettotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                vm.length = parseInt(this.dataItems.length);
                vm.average1 = parseFloat(gettotal/vm.length).toFixed(2);

                vm.average = vm.average1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

               let monthDiff = (month2 - month1) + 1;

               vm.averagemonth1 = parseFloat(gettotal/monthDiff).toFixed(2);
                vm.averagemonth = vm.averagemonth1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                     
            }
                this.lineClaim(vm.dataItems);
            })

            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });

    },


      loadClaimType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'})
            .then(res => {

                vm.clmItems = res.data.rec;

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },


     lineClaim(item) {
  
         const vm = this;
         const element = [...new Set(item.map(item => item.clmtypeid))]

        // let receipt = ['Jan',
        //                 'Feb', 
        //                 'Mac', 
        //                 'April', 
        //                 'May', 
        //                 'June', 
        //                 'July', 
        //                 'Aug', 
        //                 'Sept',
        //                 'Oct', 
        //                 'Nov', 
        //                 'Dec'];
         
         let lineHolder = [];

         for (var i = 0; i < element.length; i++) {
             
             const claimHolder = item.find(val => val.clmtypeid == element[i])

             lineHolder.push({
                 name:claimHolder.clmtype,
                 id:claimHolder.clmtypeid

             })

         }
            
            for(var m = 0; m < lineHolder.length; m++){


                let dataHolder = [];

                   for(var l = 1; l < 13; l++){

                      const claimData = item.filter(val => val.clmtypeid == lineHolder[m].id && moment(val.receiptdate).format('M') == l)
                      
                      const claimMap = claimData.map(val => val.repaprvamt)

                      const total = claimMap.reduce((a, b) => a + b, 0)

                     dataHolder.push(total)
             }

             lineHolder[m].data = dataHolder;
         }

            vm.lineseries = lineHolder
   
         //    vm.lineoptions.xaxis.categories = receipt
         
     },
        

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        // get CSV for tabHeader

        async getCSV() {
            let item = this.dataItems;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [`${this.$t('statutoryexportcommonused.no')}`]: item[i].no,
                    [`${this.$t('columns.name')}`]: item[i].staffname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffno,
                    [`${this.$t('claimreport.claimtype')}`]: item[i].clmtypdesc,
                    [`${this.$t('claimrecordlisting.receiptdate')}`]: item[i].receiptdate,
                    [`${this.$t('payeeinputprocess.approvedamount')}`]: item[i].aprvamt,
                    [`${this.$t('columns.currency')}`]: item[i].clmtypecrcycode,
                    [`${this.$t('claimutilizationtrendanalysis.reportedapprovedamount')}`]: item[i].repaprvamt,
                    [`${this.$t('claimutilizationtrendanalysis.reportingcurrency')}`]: item[i].reportingcrcycode,
                    [`${this.$t('columns.company')}`]: item[i].compnyname,
                    [`${this.$t('columns.branch')}`]: item[i].brchname,
                    [`${this.$t('columns.division')}`]: item[i].divnname,
                    [`${this.$t('columns.department')}`]: item[i].deptname,
                    [`${this.$t('columns.section')}`]: item[i].sectioname,
                    [`${this.$t('columns.unit')}`]: item[i].unitname,
                    [`${this.$t('columns.position')}`]: item[i].posdesc,
                    [`${this.$t('columns.grade')}`]: item[i].grddesc,
         
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // get CSV fortabHeader2

       

        // print PDF for tabheader
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
           // let item = this.positionassignmentdetailsassigned

            var head = [  
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },              
                { title: this.$t('columns.name'), dataKey: 'staffname' },
                { title: this.$t('columns.staffid'), dataKey: 'staffno' },
                { title: this.$t('claimreport.claimtype'), dataKey: 'clmtypdesc' },
                { title: this.$t('claimrecordlisting.receiptdate'), dataKey: 'receiptdate' },
                { title: this.$t('payeeinputprocess.approvedamount'), dataKey: 'aprvamt' },
                { title: this.$t('columns.currency'), dataKey: 'clmtypecrcycode' },
                { title: this.$t('claimutilizationtrendanalysis.reportedapprovedamount'), dataKey: 'repaprvamt' },
                { title: this.$t('claimutilizationtrendanalysis.reportingcurrency'), dataKey: 'reportingcrcycode' },
                { title: this.$t('columns.company'), dataKey: 'compnyname' },
                { title: this.$t('columns.branch'), dataKey: 'brchname' },
                { title: this.$t('columns.division'), dataKey: 'divnname' },
                { title: this.$t('columns.department'), dataKey: 'deptname' },
                { title: this.$t('columns.section'), dataKey: 'sectioname' },
                { title: this.$t('columns.unit'), dataKey: 'unitname' },
                { title: this.$t('columns.position'), dataKey: 'posdesc' },
                { title: this.$t('columns.grade'), dataKey: 'grddesc' },
                
            ]

            let body = []

            for (var i = 0; i < this.dataItems.length; i++) {
                body.push({
                    no: this.dataItems[i].no,
                    staffname: this.dataItems[i].staffname,
                    staffno: this.dataItems[i].staffno,
                    clmtypdesc: this.dataItems[i].clmtypdesc,
                    receiptdate: this.dataItems[i].receiptdate,
                    aprvamt: this.dataItems[i].aprvamt,
                    clmtypecrcycode: this.dataItems[i].clmtypecrcycode,
                    repaprvamt: this.dataItems[i].repaprvamt,
                    reportingcrcycode: this.dataItems[i].reportingcrcycode,
                    compnyname: this.dataItems[i].compnyname,
                    brchname: this.dataItems[i].brchname,
                    divnname: this.dataItems[i].divnname,
                    deptname: this.dataItems[i].deptname,
                    sectioname: this.dataItems[i].sectioname,
                    unitname: this.dataItems[i].unitname,
                    posdesc: this.dataItems[i].posdesc,
                    grddesc: this.dataItems[i].grddesc
            
                })
            }

            this.title = "Claim Utilization Trend Analysis ";
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        //back handler - go back to PositionAssignmentAnalysisParameter.vue
        backHandler(){
            this.$router.push({name: 'claimutilizationtrendanalysisparameter'});
        },
        
        loadParameters() {

            this.companyHandler();
            this.departmentHandler();
            this.branchHandler();
            this.employmentHandler();
            this.valvalueHandler();
            this.sectionHandler();
            this.divisionHandler();
            this.unitHandler();
            this.gradeHandler();
        }
    },

    created() {

          if (typeof(this.$route.params.parameters) == 'string') {
            let paramHolder = JSON.parse(this.$route.params.parameters)
            this.data.parameters = {
                brchid: paramHolder.branchid,
                compnyid: paramHolder.companyid,
                deptid: paramHolder.departmentid,
                emptypeid: paramHolder.employmenttypeid,
                claimgroupid: paramHolder.claimgroupid,
                divnid:paramHolder.divisionid,
                sectioid:paramHolder.sectionid,
                unitid:paramHolder.unitid,
                grdid:paramHolder.gradeid
            }
          }

        
    },   

    mounted() {
     this.loadParameters();
     this.TrendChart();
     this.loadClaimType();
     
     //this.getExchangeRate();
  
    }
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}

.TotalClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}
.TotalClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.ClaimAmt {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: green;
    align-content: center;
    justify-content: center;
}
.ClaimAmtContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.Average {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: blue;
    align-content: center;
    justify-content: center;
}
.AverageContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
  
    min-height: 60px;
}

.AverageClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: red;
    align-content: center;
    justify-content: center;
}
.AverageClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}
</style>