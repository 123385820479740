<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle }}
            <span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>
        <v-row>
          <v-dialog v-model="leaveentitlementdialog" max-width="800px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on"
                >{{$t('staffdailyrosterrecord.newassignment')}}</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{$t('staffdailyrosterrecord.selectstaffdaytypeandshift')}}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="6" sm="6" md="6" class="mb-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('staffdailyrosterrecord.shiftdate')"
                          type="date"
                          ref="date"
                          :rules="[rules.required]"
                          v-model="selections[0].shiftdate"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          outlined
                          :label="this.$t('columns.name')"
                          type="text"
                          ref="psnid"
                          :rules="[rules.required]"
                          v-model="selections[0].staffid"
                          dense
                          :items="staffDetailsList"
                          @change="
                            changeStaffIDandCompanyHandler(
                              selections[0].staffid
                            )
                          "
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('columns.staffid')"
                          type="text"
                          v-model="selections[0].staffcode"
                          dense
                          readonly
                          background-color="#ffeab2"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="6" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('columns.company')"
                          type="text"
                          ref="compnyid"
                          v-model="selections[0].compnyname"
                          dense
                          readonly
                          append-icon=""
                          background-color="#ffeab2"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" md="8" class="pb-n5 mt-n8">
                        <v-autocomplete
                          outlined
                          :label="this.$t('attendancerecordsandprocesses.selectshift')"
                          type="text"
                          ref="psnid"
                          :rules="[rules.required]"
                          v-model="selections[0].shiftid"
                          dense
                          :items="shiftdetailsList"
                          @change="changeShiftHandler(selections[0].shiftid)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('attendancerecordsandprocesses.shiftcode')"
                          type="text"
                          ref="compnyid"
                          v-model="selections[0].shiftcode"
                          dense
                          readonly
                          append-icon=""
                          background-color="#ffeab2"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" sm="8" md="8" class="pb-n5 mt-n8">
                        <v-autocomplete
                          outlined
                          :label="this.$t('workhourspayitemmappingsetup.selectdaytype')"
                          type="text"
                          ref="psnid"
                          :rules="[rules.required]"
                          v-model="selections[0].daytypeid"
                          dense
                          :items="daytypedetailsList"
                          @change="
                            changeDayTypeHandler(selections[0].daytypeid)
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                          outlined
                          :label="this.$t('workhourspayitemmappingsetup.daytypecode')"
                          type="text"
                          v-model="selections[0].daytypecode"
                          dense
                          readonly
                          append-icon=""
                          background-color="#ffeab2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <!-- <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{ errMsg }}</span>
              </v-card-text> -->

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeModal"
                  >{{$t('button.cancel')}}</v-btn
                >
                <v-btn color="green darken-1" text @click="saveRecord"
                  >{{$t('button.save')}}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row id="calendar">
          <v-col cols="12" style="padding: 0px">
            <calendar-view
              :show-date="showDate"
              class="theme-default"
              @click-date="onClickDate"
              :events="calendaritems"
            >
              <template #header="{ headerProps }">
                <calendar-view-header
                  :header-props="headerProps"
                  @input="setShowDate"
                />
              </template>
            </calendar-view>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="2" style="font-size: 14px; padding: 0px">{{
            this.currentSelectedDate
          }}</v-col>
          <v-col
            cols="4"
            style="
              font-size: 14px;
              padding: 0px;
              text-align: left;
              cursor: pointer;
            "
          >
            <div @click="ShowThisMonth()">
              {{$t('thismonths')}}: {{ this.currentMonth }}
            </div>
          </v-col>
          <v-col cols="6" sm="6" style="padding: 10px 0px 0px">
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>
              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                style="width: 200px"
                class="pr-5 pt-3"
                hide-details
              >
              </v-text-field>
            </v-toolbar>
          </v-col>
          <v-col cols="12" sm="12" style="padding: 0px">
            <v-data-table
              :search="search"
              append-icon="search"
              :headers="tabHeader"
              :items="dataItems"
              class="elavation-1"
              fixed-header
              :sort-by="['code']"
            >
              <!-- <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="subtitle-2"
                    >Partial Day</v-toolbar-title
                  >
                </v-toolbar>
              </template> -->
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                  mdi-pen
                </v-icon>
                <v-icon
                  small
                  class="ml-3 mr-2"
                  @click="deleteItemHandler(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<style>
#calendar {
  color: #2c3e50;
  height: 60vh;
  width: 100%;
}

.theme-default .cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
  /* color: yellow; */
  background: none;
  border: none;
  color: red;
  font-size: 14px;
}
</style>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import ErrorDialog from "../../../components/common/ErrorDialog";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
// The next two lines are optional themes
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import moment from "moment";
export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
    CalendarView,
    CalendarViewHeader,
  },

  data() {
    return {
      search: "",
      showDate: new Date(),
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
        },
      },

      tabHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "auto" },
        { text: this.$t('statutoryexportcommonused.no'), value: "Count", width: "auto", sortable: false },
        {
          text: this.$t('columns.name'),
          value: "psnname",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('columns.staffid'),
          value: "staffcode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('columns.company'),
          value: "compnycode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('staffdailyrosterrecord.shiftdate'),
          value: "shiftdate",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('setuprestdaypatternsetup.daytype'),
          value: "daytypecode",
          width: "auto",
          sortable: false,
        },
        {
          text: this.$t('staffdailyrosterrecord.shift'),
          value: "shiftcode",
          width: "auto",
          sortable: false,
        },
      ],
      dataItems: [],

      formData: {},
      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,

      calendaritems: [],
      currentSelectedDate: "",
      currentMonth: moment().format("MM-YYYY"),
      staffdetails: [],
      staffDetailsList: [],
      shiftdetails: [],
      shiftdetailsList: [],
      daytypedetailsList: [],
      daytypedetails: [],

      leaveentitlementdialog: false,
      detailItem: this.parentObject.detailItem,
      selections: [
        {
          id: null,
          shiftdate: new Date().toISOString().slice(0, 10),
          staffid: "",
          staffcode: "",
          compnyid: "",
          compnyname: "",
          shiftid: "",
          shiftcode: "",
          daytypeid: "",
          daytypecode: "",
          action: 0,
          stamp: 0,
          userid: this.$store.getters.userId,
        },
      ],

      modify: false,
      isExisting: false,
    };
  },

  methods: {
    //load staffdetails list view
    loadRecortList(date) {
      const vm = this;
      vm.currentSelectedDate = date;
      vm.dataItems = [];
      var data = {
        date: date,
      };

      this.$store.dispatch('post', {
        url: "/staffdailyroster/bydate",
        payload: data
      })
        .then(function (res) {
          console.log(res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.dataItems.push({
              action: 1,
              Count: i + 1,
              id: res.data.rec[i].id,
              psnname: res.data.rec[i].psnname,
              staffid: res.data.rec[i].staffid,
              staffcode: res.data.rec[i].staffcode,
              compnyid: res.data.rec[i].compnyid,
              compnycode: res.data.rec[i].compnycode,
              compnyname: res.data.rec[i].compnyname,
              shiftdate: moment(res.data.rec[i].shiftdate).format("YYYY-MM-DD"),
              daytypeid: res.data.rec[i].daytypeid,
              daytypecode: res.data.rec[i].daytypecode,
              shiftid: res.data.rec[i].shiftid,
              shiftcode: res.data.rec[i].shiftcode,
              stamp: res.data.rec[i].stamp,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    ShowThisMonth() {
      const vm = this;
      vm.dataItems = [];
      var data = {
        date: moment().format("YYYY-MM"),
      };
      this.$store.dispatch('post', {
        url: "/staffdailyroster/month",
        payload: data
      })
        .then(function (res) {
          console.log(res.data.rec);

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.dataItems.push({
              action: 1,
              Count: i + 1,
              id: res.data.rec[i].id,
              psnname: res.data.rec[i].psnname,
              staffid: res.data.rec[i].staffid,
              staffcode: res.data.rec[i].staffcode,
              compnyid: res.data.rec[i].compnyid,
              compnycode: res.data.rec[i].compnycode,
              compnyname: res.data.rec[i].compnyname,
              shiftdate: moment(res.data.rec[i].shiftdate).format("YYYY-MM-DD"),
              daytypeid: res.data.rec[i].daytypeid,
              daytypecode: res.data.rec[i].daytypecode,
              shiftid: res.data.rec[i].shiftid,
              shiftcode: res.data.rec[i].shiftcode,
              stamp: res.data.rec[i].stamp,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    loadDaytypeDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/daytype/all"
      })
        .then(function (res) {
          vm.daytypedetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.daytypedetailsList.push({
              text: res.data.rec[i].daytypedesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    loadShiftDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/shiftsetup/all"
      })
        .then(function (res) {
          vm.shiftdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.shiftdetailsList.push({
              text: res.data.rec[i].shiftdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch(function (err) {
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },

    loadRecordCountList() {
      const vm = this;
      vm.calendaritems = [];
      this.$store.dispatch('get', {
        url: "/staffdailyroster/count"
      })
        .then((res) => {
          //req success --> push res into array
          for (let i = 0; i < res.data.rec.length; i++) {
            res.data.rec[i].startDate = moment(
              res.data.rec[i].startDate
            ).format("YYYY-MM-DD");
            vm.calendaritems.push(res.data.rec[i]);
          }

          console.log(vm.calendaritems);
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErrTrigger = true;
          vm.errMsg = err;
        });
    },

    setShowDate(d) {
      this.showDate = d;
    },
    onClickDate(d) {
      // console.log(moment(d).format("YYYY-DD-MM"));
      this.loadRecortList(moment(d).format("YYYY-MM-DD"));
    },

    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].staffid = item.id;
      this.selections[0].staffcode = item.staffcode;
      this.selections[0].compnyid = item.compnyid;
      this.selections[0].compnyname = item.compnyname;
    },

    changeShiftHandler(id) {
      let item = this.shiftdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].shiftid = item.id;
      this.selections[0].shiftcode = item.shiftcode;
    },
    changeDayTypeHandler(id) {
      let item = this.daytypedetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.selections[0].daytypeid = item.id;
      this.selections[0].daytypecode = item.daytypecode;
    },

    modifyItemHandler(item) {
      // assign loaded data to selections array

      if (item.action == 1) {
        this.modify = true;
      }

      if (item.action == 2) {
        this.isExisting = true;
      }

      this.selections = [
        {
          id: item.id,
          shiftdate: item.shiftdate,
          staffid: item.staffid,
          staffcode: item.staffcode,
          compnyid: item.compnyid,
          compnyname: item.compnyname,
          shiftid: item.shiftid,
          shiftcode: item.shiftcode,
          daytypeid: item.daytypeid,
          daytypecode: item.daytypecode,
          action: item.action,
          stamp: item.stamp,
          userid: this.$store.getters.userId,
        },
      ];

      this.leaveentitlementdialog = true;
    },

    deleteItemHandler(item) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {
        url: "/staffdailyroster/" + item.id
      })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.loadRecordCountList();
            this.loadRecortList(this.currentSelectedDate);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    closeModal() {
      this.leaveentitlementdialog = false;
      this.isErr = false;
      this.isExisting = false;
      this.modify = false;

      // reset the form fields
      this.resetSelections();
    },

    saveRecord() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      if (this.$refs.form.validate()) {
        this.isErr = false;
        // indicate value of 1 as insert, 2 as modify
        console.log(this.selections);
        console.log(this.modify);
        console.log(this.isExisting);

        if (!this.isExisting && !this.modify) {
          //   this.selections[0].id = null;
          //   this.selections[0].action = 1;
          //   this.detailItem.push(Object.assign({}, this.selections[0]));

          this.$store.dispatch('post', {
            url: "/staffdailyroster/create",
            payload: this.selections[0]
          })
            .then((res) => {
              if (res.data.error) {
                this.isErrTrigger = true;
                this.errMsg = res.data.error;
              } else {
                this.loadRecordCountList();
                // if (res.data.rec != "") {
                //   this.$dialog.alert(res.data.rec).then((dialog) => {
                //     console.log(dialog);
                //     this.$router.push({ name: "leaverecordlist" });
                //   });
                // } else {
                //   this.$router.push({ name: "leaverecordlist" });
                // }
              }
            })
            .catch((err) => {
              this.isErrTrigger = true;
              this.errMsg = err;
            });
        } else {
          this.$store.dispatch('put', {
            url: "/staffdailyroster/" + this.selections[0].id,
            payload: this.selections[0]
          })
            .then((res) => {
              if (res.data.error) {
                this.isErrTrigger = true;
                this.errMsg = res.data.error;
              } else {
                this.loadRecordCountList();
                this.loadRecortList(this.currentSelectedDate);
              }
            })
            .catch((err) => {
              this.isErrTrigger = true;
              this.errMsg = err;
            });
        }
        this.modify = false;
        this.isExisting = false;
        this.leaveentitlementdialog = false;

        // reset the form fields
        this.resetSelections();
      }
    },
    resetSelections() {
      this.selections = [
        {
          id: null,
          shiftdate: new Date().toISOString().slice(0, 10),
          staffid: "",
          staffcode: "",
          compnyid: "",
          compnyname: "",
          shiftid: "",
          shiftcode: "",
          daytypeid: "",
          daytypecode: "",
          action: 0,
          stamp: 0,
          userid: this.$store.getters.userId,
        },
      ];
    },

    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var head = [
        { title: this.$t('columns.name'), dataKey: "psnname" },
        { title: this.$t('columns.staffid'), dataKey: "staffcode" },
        { title: this.$t('columns.company'), dataKey: "compnycode" },
        { title: this.$t('staffdailyrosterrecord.shiftdate'), dataKey: "shiftdate" },
        { title: this.$t('setuprestdaypatternsetup.daytype'), dataKey: "daytypecode" },
        { title: this.$t('staffdailyrosterrecord.shift'), dataKey: "shiftcode" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          psnname: this.dataItems[i].psnname,
          staffcode: this.dataItems[i].staffcode,
          compnycode: this.dataItems[i].compnycode,
          shiftdate: this.dataItems[i].shiftdate,
          daytypecode: this.dataItems[i].daytypecode,
          shiftcode: this.dataItems[i].shiftcode,
        });
      }

      //   doc.text(
      //     `Staff Leave Balance - ${this.formData.staffcode} as of ${this.formData.asofdate}`,
      //     10,
      //     10
      //   );

      doc.autoTable(head, body);
      doc.save("Staff Daily Roster " + "_" + this.currentSelectedDate + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        "Staff Daily Roster " + "_" + this.currentSelectedDate + ".csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [`${this.$t('columns.name')}`]: item[i].psnname,
          [`${this.$t('columns.staffid')}`]: item[i].staffcode,
          [`${this.$t('columns.company')}`]: item[i].compnycode,
          [`${this.$t('staffdailyrosterrecord.shiftdate')}`]: item[i].shiftdate,
          [`${this.$t('setuprestdaypatternsetup.daytype')}`]: item[i].daytypecode,
          [`${this.$t('staffdailyrosterrecord.shift')}`]: item[i].shiftcode,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadShiftDetailsListHandler();
    this.loadDaytypeDetailsListHandler();
    this.loadRecordCountList();
    this.loadRecortList(moment().format("YYYY-MM-DD"));
  },
};
</script>