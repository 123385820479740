<template>
  <div v-if="!loading">
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('payrollprocessrun.payout')"
              type="text"
              :rules="[rules.required]"
              :items="payoutctrlList"
              v-model="formData.payoutctrlid"
              @change="changePayout(formData.payoutctrlid)"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="8" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutdescription')"
              type="text"
              v-model="payoutdesc"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutyear')"
              type="number"
              v-model="pyyear"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('statutoryexportcommonused.payoutmonth')"
              type="number"
              v-model="pymonth"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('postpayroll.payrollgroupdescription')"
              type="text"
              v-model="grouppdesc"
              readonly
              background-color="#ffeab2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendancefromdate')"
              type="date"
              ref="fromdate"
              :rules="[rules.required]"
              v-model="formData.fromdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('generateunpaidleaverecord.attendanceuntildate')"
              type="date"
              ref="untildate"
              :rules="[rules.required]"
              v-model="formData.untildate"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="8" class="pb-n4 mt-n8">
            <v-autocomplete
              outlined
              :label="this.$t('postpayroll.selectworkhourspayitemmapping')"
              type="text"
              ref="workhourpayitemmapping"
              :items="WorkHourPayItemMappingList"
              :rules="[rules.required]"
              hide-selected
              v-model="formData.mapsetid"
              @change="changeMappingHandler(formData.mapsetid)"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" sm="2" class="pb-n4 mt-n8">
            <v-text-field
              readonly
              outlined
              background-color="#ffeab2"
              :label="this.$t('columns.code')"
              type="text"
              v-model="formData.mapset"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" class="pb-n4 mt-n8">
            <v-radio-group
              v-model="formData.showtype"
              :rules="[rules.required]"
              row
            >
              <v-radio :label="this.$t('previewpayroll.summary')" value="summary"></v-radio>
              <v-radio :label="this.$t('postpayroll.breakdown')" value="breakdown"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-n6">
            <v-btn color="primary" @click="onPost" style="width: 150px">
              {{$t('button.post')}}
            </v-btn>
            <v-btn
              color="primary"
              @click="onUnPost"
              style="width: 150px"
              class="ml-5"
            >
              {{$t('button.undoposting')}}
            </v-btn>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ErrorDialog from "../../../components/common/ErrorDialog";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    "app-error": ErrorDialog,
    // Loading,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('postpayroll.attendanceposting'),
      // item: this.$route.params.item,
      formData: {
        mapsetid: null,
        mapset: null,
        payoutctrlid: null,
        showtype: null,
        fromdate: moment().format("YYYY-MM-DD"),
        untildate: moment().format("YYYY-MM-DD"),
        userid: this.$store.getters.userId,
      },

      items: [],
      payoutctrls: [],
      process: [],
      payoutctrlList: [],
      WorkHourPayItemMapping: [],
      WorkHourPayItemMappingList: [],

      accErr: [],
      allRecord: [],
      payoutdesc: "",
      pymonth: "",
      pyyear: "",
      grouppdesc: "",
      errMsg: "",
      isErr: false,
      loading: true,
    };
  },
  methods: {
    onPost() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.loading = true;
      this.$store.dispatch('post', {url: "/attendanceposting/attendancepayroll", payload: this.formData})
        .then((res) => {
          this.loading = false;
          if (res.data.status == 201) {
            console.log(res.data);
            this.$router.push({
              name: "attendancepostinglog",
              params: {
                staffcount: res.data.rec.staffcount,
                processed: res.data.rec.processed,
                posted: res.data.rec.posted,
              },
            });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },

    onUnPost() {
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('post', {url:
            "/attendanceposting/attendancepayrollunpost", payload: this.formData})
        .then((res) => {
          if (res.data.status == 201) {
            console.log(res.data);
            this.$dialog
              .alert(this.$t('setupalert.recdelsuccessandposted')
              )
              .then((dialog) => {
                console.log(dialog);
              });
          } else {
            this.isErr = true;
            this.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isErr = true;
          this.errMsg = err;
        });
    },

    getPayoutRecords() {
      const vm = this;

      // let errCount = [];

      this.$store.dispatch('get', {url: "/payoutrecord/all"})
        .then((res) => {
          vm.payoutctrls = res.data.rec;

          for (let i = 0; i < vm.payoutctrls.length; i++) {
            vm.payoutctrlList.push({
              text: vm.payoutctrls[i].payoutcode,
              value: vm.payoutctrls[i].id,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    changePayout(id) {
      let item = this.payoutctrls.find((item) => item.id == id);

      this.payoutcode = item.payoutcode;
      this.payoutdesc = item.payoutdesc;
      this.pyyear = item.pyyear;
      this.pymonth = item.pymonth;
      this.grouppdesc = item.grouppdesc;
    },

    loadMapsetHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: "/workhourspayitemmapping/all"})
        .then((res) => {
          vm.WorkHourPayItemMapping = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.WorkHourPayItemMappingList.push({
              text: res.data.rec[i].mapsetdesc,
              value: res.data.rec[i].id,
              code: res.data.rec[i].mapset,
            });
          }

          if (vm.formData.mapsetid) {
            vm.changeMappingHandler(vm.formData.mapsetid);
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeMappingHandler(id) {
      let item = this.WorkHourPayItemMappingList.find(
        (item) => item.value === id
      );
      this.formData.mapset = item.code;
    },
  },

  mounted() {
    this.getPayoutRecords();
    this.loadMapsetHandler();
  },
};
</script>

<style scoped>
.btn-process {
  margin-right: 120px;
  width: 100px;
}
</style>