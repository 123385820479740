<template>
    <div>
        <v-dialog v-model="epfDialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('allepf.monthlyepfsubmissionitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.refnocode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].refcodeid"
                            @change="changeRefcodeHandler(selections[0].refcodeid)"
                            dense
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                            type="text"
                            v-model="selections[0].refnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly
                           
                        ></v-text-field>
                    </v-col>
                </v-row>

                   
                <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.reportyear')"
                            type="text"
                            v-model="selections[0].repyear"
                            dense
                            :items="years"
                           :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.reportmonth')"
                            type="text"
                            v-model="selections[0].repmonth"
                            dense
                            :items="months"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptno')"
                            type="number"
                            v-model="selections[0].rcpno"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptdate')"
                            type="date"
                            v-model="selections[0].rcpdate"
                            dense
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                     <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('statutoryexportcommonused.receiptreference')"
                            type="text"
                            v-model="selections[0].rcpref"
                            dense
                        ></v-text-field>
                    </v-col>
                    
                </v-row>

             
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEPFDialog">{{$t('button.cancel')}} </v-btn>
              <v-btn color="blue darken-1" text @click="saveEPF">{{$t('button.save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog v-model="editDialog" max-width="800px">
          
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('allepf.monthlyepfsubmissionitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.refnocode')"
                            type="text"
                            :items="refnumList"
                            v-model="selections[0].refcodeid"
                            @change="changeRefcodeHandler(selections[0].refcodeid)"
                            dense
                            :rules="[rules.required]"
                            readonly="isDisabled"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" >
                        <v-text-field
                            outlined
                            :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                            type="text"
                            v-model="selections[0].refnum"
                            dense
                            append-icon=""
                            background-color="#ffeab2"
                            readonly

                        ></v-text-field>
                    </v-col>
                </v-row>

                   
                <v-row>
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.reportyear')"
                            type="text"
                            v-model="selections[0].repyear"
                            dense
                            :items="years"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('allepf.reportmonth')"
                            type="text"
                            v-model="selections[0].repmonth"
                            dense
                            :items="months"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row> 
                      <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptno')"
                            type="number"
                            v-model="selections[0].rcpno"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6 mt-n8" >
                        <v-text-field
                            outlined
                            :label="this.$t('claimrecordlisting.receiptdate')"
                            type="date"
                            v-model="selections[0].rcpdate"
                            dense
                            clearable
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                     <v-col cols="12" sm="6" md="6 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('statutoryexportcommonused.receiptreference')"
                            type="text"
                            v-model="selections[0].rcpref"
                            dense
                        ></v-text-field>
                    </v-col>
                    
                </v-row>

             
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEPFDialog">{{$t('button.cancel')}}</v-btn>
              <v-btn color="blue darken-1" text @click="editEPF">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header

            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            isPayRate: false,
            epfDialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                epfsubrecid: null,
                no: null,
                refcodeid: null,
                refcode: null, 
                refnum: null, 
                repyear: null, 
                repmonth: null,
                rcpno: null,
                rcpdate: null ,
                rcpref: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],

            

            //arrays to store various data objects from db 
            refnums: [],
            refnumList: [],
            months: [1, 2, 3, 4, 5 , 6 , 7 , 8 , 9 , 10, 11 , 12],
            years: [],
            

            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
            isDisabled: false,
        }
    },

    methods: {
        compareArray,

        loadYears() {
            for (let i= 0; i < 200; i++) {
                var year = 2020;
                this.years.push(year + i);
            }
        },

        modifyItemHandler(item) {
      
            if(item.action == 2) {
                this.isExisting = true; 
                this.isDisabled = true;    
            }

            this.selections[0] = item;
            this.editDialog = true;


            if (!this.isExisting){
                // duplicate this.detailItem for duplicate checking
                
                //find index 

                var index = this.detailItem.findIndex( x => x.refcodeid == item.refcodeid);
                console.log("item", item)
                console.log("index", index)
                console.log("detail tiem", this.detailItem)

                this.detailItem[index].repyear = item.repyear;
                this.detailItem[index].repmonth = item.repmonth;
                this.detailItem[index].rcpno = item.rcpno;
                this.detailItem[index].rcpdate = item.rcpdate;
                this.detailItem[index].rcpref = item.rcpref;

                //this.resetSelections();

            } else {
                this.selections[0].action = 2;

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }

            //this.editDialog = true;
            console.log(item)
           
        },        

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

            for (let i =0; i < this.detailItem.length; i++) {
                this.detailItem[i].no = i + 1;
            }

            this.resetSelections();

            
        },

        closeEPFDialog() {
            this.epfDialog = false;
            this.editDialog = false;
            this.isErr = false;

            // reset the form fields
            this.resetSelections();
        },

        saveEPF() {

             if (this.selections[0].refcodeid == null)
              {
                this.$dialog.alert(this.$t('allsalaryexportrecord.fillintherefnocode')).then((dialog) => {
                  console.log(dialog);
              });
              } else if(this.selections[0].repyear == null) {
                this.$dialog.alert(this.$t('allsalaryexportrecord.fillintheyear')).then((dialog) => {
                  console.log(dialog);
              });
              } else if(this.selections[0].repmonth == null ){
               this.$dialog.alert(this.$t('allsalaryexportrecord.fillinthemonth')).then((dialog) => {
                  console.log(dialog);
              });
            
            } else {

            this.isErr = false;
       
        
            if (!this.isExisting) {

                this.selections[0].action = 1;

                if (this.detailItem.some( rec => rec.refcodeid  == this.selections[0].refcodeid)) {
                        
                        this.errMsg = this.$t('alleissocso.onlyuniqitemallow');
                        this.isErr = true;
                        this.epfDialog = true; 
                        
                } else {

                        this.selections[0].action = 1;
                        console.log("selectioin", this.selections[0])
                        console.log("detail item", this.detailItem)
                        this.detailItem.push(Object.assign({}, this.selections[0]));

                        for (let i = 0; i < this.detailItem.length; i++) {
                            this.detailItem[i].no = i + 1;
                        }  

                        this.epfDialog = false;
                        this.$emit('input', this.detailItem);

                         // reset the form fields
                        this.resetSelections();
                    
                    }
        
            } else {
                this.selections[0].action = 2;
                //this.resetSelections();

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            } 
            }
        },

        editEPF() {
            this.isExisting = false;
            this.editDialog = false;
            this.resetSelections();
            this.isErr =false; 
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                epfsubrecid: null,
                refcodeid: null,
                refcode: null, 
                refnum: null, 
                repyear: null, 
                repmonth: null,
                rcpno: null,
                rcpdate: null,
                rcpref: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

 
        loadRefnum() {
            const vm = this;

            console.log("here ")
            this.$store.dispatch('get', {
                url: '/refnum/all'})
            .then (res => {
                vm.refnums = res.data.rec;
                
                for (let i = 0; i < vm.refnums.length; i++) {
                    vm.refnumList.push(
                        {   
                            refnum: vm.refnums[i].refnum,
                            text: vm.refnums[i].refcode,
                            value: vm.refnums[i].id
                        }
                    )
                }

            });
        },
         

        changeRefcodeHandler(id) {
            let item = this.refnums.find(item => item.id == id)
            
            this.selections[0].refcode = item.refcode;
            this.selections[0].refnum = item.refnum;
            console.log('refnum', this.selections[0].refnum)
            

        },

          
    },

    created() {
        this.loadRefnum();
        this.loadYears();
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>