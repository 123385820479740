<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import TaxPeriodSetupForm from "../../../components/payroll/mmtaxperiodsetup/Form";
//import PayItemTypeForm from "../../../components/payroll/payitemtype/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": TaxPeriodSetupForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('mmtaxperiodsetup.edittaxperiodsetuplist'),
      pageTitleValue: "",
      formData: [],
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadPayItemTypeHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/mmtaxperiodsetup/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];
        
          vm.formData.thresholdamt = res.data.rec[0].thpamt;
          vm.formData.months = res.data.rec[0].tpmonth;
          vm.formData.thresholdmth = res.data.rec[0].thpmth;

         // vm.pageTitleValue = res.data.rec[0].payitemtypedesc;
          vm.formData.userid = this.$store.getters.userId;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
    

      this.$store.dispatch('put', {url: "/mmtaxperiodsetup/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mmtaxperiodsetuplist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/mmtaxperiodsetup/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mmtaxperiodsetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    newHandler() {
      this.$router.push({ name: "mmtaxperiodsetupnew" });
    },

    cancelHandler() {
      this.$router.push({ name: "mmtaxperiodsetuplist" });
    },
  },

  mounted() {
    this.loadPayItemTypeHandler();
  },
};
</script>