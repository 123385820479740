import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Vue2Editor from "vue2-editor";
// import myConfig from './config.json';

Vue.use(Vuex);
Vue.use(Vue2Editor);

export const store = new Vuex.Store({
    state: {
        loggedIn: localStorage.getItem("access_token") || null,
        API_URL: localStorage.getItem("API_URL") || null,
        RPTSVR_URL: localStorage.getItem("RPTSVR_URL") || null,
        userLastAccess: localStorage.getItem("last_access") || null,
        userName: localStorage.getItem("user_name") || null,
        userId: localStorage.getItem("user_id") || null,
        staffId: localStorage.getItem("staff_id") || null,
        attachmentAcceptType: localStorage.getItem("attachment_type") || null,
        attachmentMaxSize: localStorage.getItem("attachment_size") || null,
        profileAcceptType: localStorage.getItem("profile_type") || null,
        profileMaxSize: localStorage.getItem("profile_size") || null,
        db: localStorage.getItem("db") || null,
        dbname: localStorage.getItem("dbname") || null,
        token: localStorage.getItem("token") || null,
        psnname: localStorage.getItem("psnname") || null,
        pic: localStorage.getItem("pic") || null,
        usertype: localStorage.getItem("usertype") || null,
        psnid: localStorage.getItem("psnid") || null
    },

    getters: {
        apiURL(state) {
            return state.API_URL;
        },

        db(state) {
            return state.db;
        },

        dbname(state) {
            return state.dbname;
        },

        rptSvrURL(state) {
            return state.RPTSVR_URL;
        },

        loggedIn(state) {
            return state.loggedIn;
        },

        lastAccess(state) {
            return state.userLastAccess;
        },

        userName(state) {
            return state.userName;
        },

        userId(state) {
            return state.userId;
        },

        staffId(state) {
            return state.staffId;
        },

        attachmentAcceptType(state) {
            return state.attachmentAcceptType;
        },

        attachmentMaxSize(state) {
            return state.attachmentMaxSize;
        },

        profileAcceptType(state) {
            return state.profileAcceptType;
        },

        profileMaxSize(state) {
            return state.profileMaxSize;
        },

        personName(state) {
            return state.psnname;
        },

        personPic(state) {
            return state.pic;
        },

        userType(state) {
            return state.usertype;
        },

        psnId(state) {
            return state.psnid;
        },
    },

    mutations: {
        initialiseStore(state) {
            if (typeof (store._vm.$session.get("access_token")) !== 'undefined') {
                state.userLastAccess = store._vm.$session.get("last_access");
                this.commit("setStateLoggedIn", JSON.parse(store._vm.$session.get("access_token")));
            }
        },
        ClearStore(state) {
            localStorage.removeItem("access_token");
            this.commit("resetStateLoggedIn", state);
        },
        setStateLoggedIn(state, token) {
            state.loggedIn = token;
            state.userId = token.userid;
            state.staffId = token.staffid;
            state.userName = token.username;
            state.userLastAccess = token.lastaccess;
            state.psnname = token.psnname;
            state.pic = token.pic;
            state.usertype = token.usertype;
            state.psnid = token.psnid;
        },
        setStateDB(state, db) {
            state.db = db;
        },
        setStateDBname(state, dbname) {
            state.dbname = dbname;
        },

        setStateApiURL(state, url) {
            state.API_URL = url;
        },

        setStateRptSvrURL(state, url) {
            state.RPTSVR_URL = url;
        },

        resetStateLoggedIn(state) {
            state.loggedIn = null;
            state.userName = null;
            state.userId = null;
            state.staffId = null;
            state.userLastAccess = null;
            state.db = null;
            state.dbname = null;
            state.psnname = null;
            state.pic = null;
            state.usertype = null;
            state.psnid = null;
        },

        setStateAttachmentType(state, item) {
            state.attachmentAcceptType = item;
        },

        setStateAttachmentSize(state, item) {
            state.attachmentMaxSize = item;
        },

        setStateProfileType(state, item) {
            state.profileAcceptType = item;
        },

        setStateProfileSize(state, item) {
            state.profileMaxSize = item;
        },
    },

    actions: {
        retrvAccessToken(context, credentials) {
            return new Promise((resolve, reject) => {
                console.log('check', credentials.ip)
                axios
                .post(this.state.API_URL + "/login", {
                    username: credentials.username,
                    password: credentials.password,
                    ip: credentials.ip,
                    origin: 'browser',
                }, {
                    headers: { db: credentials.db }
                }
                ).then((res) => {
                    if (res.data.status == 201) {
                        const token = res.data;

                        localStorage.setItem("access_token", JSON.stringify(token));
                        localStorage.setItem("last_access", token.lastaccess);
                        localStorage.setItem("db", credentials.db);
                        localStorage.setItem("dbname", token.dbname);
                        localStorage.setItem("token", token.token);
                        localStorage.setItem("psnname", token.psnname);
                        localStorage.setItem("pic", token.pic);
                        localStorage.setItem("usertype", token.usertype);
                        localStorage.setItem("psnid", token.psnid);
                        localStorage.setItem("userid", token.userid);
                        store._vm.$session.set("access_token", JSON.stringify(token));
                        store._vm.$session.set("last_access", token.lastaccess);
                        store._vm.$session.set("db", credentials.db);
                        store._vm.$session.set("dbname", token.dbname);
                        store._vm.$session.set("token", token.token);
                        store._vm.$session.set("psnname", token.psnname);
                        store._vm.$session.set("pic", token.pic);
                        store._vm.$session.set("usertype", token.usertype);
                        store._vm.$session.set("psnid", token.psnid);
                        store._vm.$session.set("userid", token.userid);
                        context.commit("setStateLoggedIn", token);
                        context.commit("setStateDB", credentials.db);
                        context.commit("setStateDBname", token.dbname);
                    }
                    resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteToken(context) {
            store._vm.$session.remove("access_token");
            store._vm.$session.remove("last_access");
            store._vm.$session.remove("db");
            store._vm.$session.remove("dbname");
            store._vm.$session.remove("token");
            store._vm.$session.remove("userid");
            store._vm.$session.clear("access_token");
            store._vm.$session.clear("last_access");
            store._vm.$session.clear("db");
            store._vm.$session.clear("dbname");
            store._vm.$session.clear("token");
            store._vm.$session.clear("userid");
            localStorage.removeItem("access_token");
            context.commit("resetStateLoggedIn");
        },
        retrvProdApi(context) {

            // const url = myConfig.PROD_API;
            // const rptUrl = myConfig.PROD_RPT_SERVER;

            // localStorage.setItem("API_URL", url);
            // context.commit("setStateApiURL", url);
            // localStorage.setItem("RPTSVR_URL", rptUrl);
            // context.commit("setStateRptSvrURL", rptUrl);

            return new Promise((resolve, reject) => {
                axios
                    .get("/static/config.json")
                    .then((res) => {
                        const url = res.data.PROD_API;
                        const rptUrl = res.data.PROD_RPT_SERVER;

                        localStorage.setItem("API_URL", url);
                        context.commit("setStateApiURL", url);
                        localStorage.setItem("RPTSVR_URL", rptUrl);
                        context.commit("setStateRptSvrURL", rptUrl);
                        resolve(res);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        },

        retrvDevApi(context) {

            // const url = myConfig.DEV_API;
            // const rptUrl = myConfig.DEV_RPT_SERVER;

            // localStorage.setItem("API_URL", url);
            // context.commit("setStateApiURL", url);
            // localStorage.setItem("RPTSVR_URL", rptUrl);
            // context.commit("setStateRptSvrURL", rptUrl);
            return new Promise((resolve, reject) => {
                axios
                    .get("/static/config.json")
                    .then((res) => {
                        const url = res.data.DEV_API;
                        const rptUrl = res.data.DEV_RPT_SERVER;

                        localStorage.setItem("API_URL", url);
                        context.commit("setStateApiURL", url);
                        localStorage.setItem("RPTSVR_URL", rptUrl);
                        context.commit("setStateRptSvrURL", rptUrl);
                        resolve(res);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            });
        },

        retriveFileUploadConfig(context) {

            // const file_type = myConfig.ATTACHMENT_FILE_TYPE;
            // const file_maxsize = myConfig.ATTACHMENT_FILE_MAX_SIZE;
            // const profile_type = myConfig.PROFILE_FILE_TYPE;
            // const profile_maxsize = myConfig.PROFILE_FILE_MAX_SIZE;

            // localStorage.setItem("attachment_type", file_type);
            // context.commit("setStateAttachmentType", file_type);
            // localStorage.setItem("attachment_size", file_maxsize);
            // context.commit("setStateAttachmentSize", file_maxsize);
            // localStorage.setItem("profile_type", profile_type);
            // context.commit("setStateProfileType", profile_type);
            // localStorage.setItem("profile_size", profile_maxsize);
            // context.commit("setStateProfileSize", profile_maxsize);

            return new Promise((resolve, reject) => {
                axios
                    .get("/static/config.json")
                    .then((res) => {
                        const file_type = res.data.ATTACHMENT_FILE_TYPE;
                        const file_maxsize = res.data.ATTACHMENT_FILE_MAX_SIZE;
                        const profile_type = res.data.PROFILE_FILE_TYPE;
                        const profile_maxsize = res.data.PROFILE_FILE_MAX_SIZE;

                        localStorage.setItem("attachment_type", file_type);
                        context.commit("setStateAttachmentType", file_type);
                        localStorage.setItem("attachment_size", file_maxsize);
                        context.commit("setStateAttachmentSize", file_maxsize);
                        localStorage.setItem("profile_type", profile_type);
                        context.commit("setStateProfileType", profile_type);
                        localStorage.setItem("profile_size", profile_maxsize);
                        context.commit("setStateProfileSize", profile_maxsize);

                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        get(context, body) {
            return new Promise((resolve, reject) => {
                axios.get(this.state.API_URL + body.url, {
                            headers: { Authorization: `Bearer ${store._vm.$session.get("token")}`,
                            db: store._vm.$session.get("db"),
                            userid: store._vm.$session.get("userid") }
                    }).then((res) => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },

        post(context, body) {
           //console.log(body);
            return new Promise((resolve, reject) => {
                //console.log(body)
                axios.post(this.state.API_URL + body.url, body.payload, {
                            headers: body.headers ? Object.assign({ Authorization: `Bearer ${store._vm.$session.get("token")}`, db: store._vm.$session.get("db"),userid: store._vm.$session.get("userid") }, body.headers) 
                            : { Authorization: `Bearer ${store._vm.$session.get("token")}`, db: store._vm.$session.get("db"),userid: store._vm.$session.get("userid") }
                            , responseType: body.responseType ? body.responseType : "json"
                    }).then((res) => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },
        postForBlob(context, body) {
            //console.log(body);
             return new Promise((resolve, reject) => {
                 //console.log(body)
                 axios.post(this.state.API_URL + body.url, body.payload, {
                             headers: body.headers ? Object.assign({ Authorization: `Bearer ${store._vm.$session.get("token")}`, db: store._vm.$session.get("db"),userid: store._vm.$session.get("userid") }, body.headers) 
                             : { Authorization: `Bearer ${store._vm.$session.get("token")}`, db: store._vm.$session.get("db"),userid: store._vm.$session.get("userid") }
                             , responseType: "blob"
                            }).then((res) => {
                         resolve(res);
                     }).catch((error) => {
                         reject(error);
                     });
             });
         },

        put(context, body) {
            return new Promise((resolve, reject) => {
                axios.put(this.state.API_URL + body.url, body.payload, {
                            headers: { Authorization: `Bearer ${store._vm.$session.get("token")}`,
                            db: store._vm.$session.get("db"),
                            userid: store._vm.$session.get("userid") }
                    }).then((res) => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },

        delete(context, body) {
            return new Promise((resolve, reject) => {
                axios.delete( this.state.API_URL + body.url, {
                            headers: { Authorization: `Bearer ${store._vm.$session.get("token")}`,
                            db: store._vm.$session.get("db"),
                            userid: store._vm.$session.get("userid") }
                    }).then((res) => {
                        resolve(res);
                    }).catch((error) => {
                        reject(error);
                    });
            });
        },
    },
});
