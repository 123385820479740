<template>
    <div id="`valvaluelist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>


<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupgrpvalidval.grpvalidvallist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width:'50px'},
                { text: this.$t('columns.valuecode'), value: 'valuecode',width:'150px'},
                { text: this.$t('columns.valuedescription'), value: 'valuedesc',width:'200px'}
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {

        getValueListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/valvalue/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'valvalueedit', params: { id: item.id, search: item.search  } });
        },

        newRecordHandler() {
            this.$router.push({name: 'valvaluenew'});
        }
    },

    mounted() {
       this.getValueListHandler(); 
    }  
}
</script>