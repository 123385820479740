<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.staffData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
          isNewHire: this.isNewHire,
          isLocMov: this.isLocMov,
          isJobMov: this.isJobMov,
          isSalMov: this.isSalMov,
          isContrMov: this.isContrMov,
          isConfirmation: this.isConfirmation,
          isCessation: this.isCessation
        }"
        v-on:input="staffData = $event"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:newmovesave="newMovementHandler"
        v-on:profile="statffData.pic = $event"
        v-on:pageTitleValue="pageTitleValue = $event"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import StaffForm from "../../../components/personnel/staff/FormHideSalary";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": StaffForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recstaffdetails.editstaff'),
      pageTitleValue: "",
      staffData: {
        staffcode: null,
        empno: null,
        hiredate: null,
        servicedate: null,
        probmth: null,
        dueconfirmdate: null,
        exprobmth: null,
        exdueconfirmdate: null,
        confirmdate: null,
        resigndate: null,
        resignnotprd: null,
        lworkdate: null,
        olastdate: null,
        cessadate: null,
        headcount: null,
        stfstamp: 0,
        movtypeid: null,
        staffid: null,
        psnid: null,
        recdate: null,
        recenddate: null,
        actualdate: null,
        reasonid: null,
        compnyid: null,
        brchid: null,
        divnid: null,
        deptid: null,
        sectioid: null,
        unitid: null,
        positionid: null,
        emptypeid: null,
        grdid: null,
        jobid: null,
        crcyid: null,
        prevbasicsal: 0,
        basicsal: 0,
        basicfreqid: null,
        adjustamt: 0,
        adjustpct: 0,
        newbasicsal: 0,
        contrprd: null,
        contrenddate: null,
        attach: null,
        attachname: null,
        remark: null,
        lockrecdate: null,
        movstamp: 0,
        pic: null,
        userid: this.$store.getters.userId,

        movtypedesc: null,
        psnname: null,
        reasondesc: null,
        compnyname: null,
        brchname: null,
        deptname: null,
        sectioname: null,
        unitname: null,
        posdesc: null,
        emptypedesc: null,
        grddesc: null,
        jobtitle: null,
        crcycode: null,
        idno: null,
      },
      errMsg: "",
      isErr: false,
      editMode: true,
      isNewHire: false,
      isLocMov: false,
      isJobMov: false,
      isSalMov: false,
      isContrMov: false,
      isConfirmation: false,
      isCessation: false,
      loading: true,
      currentMovementId: null,
      isConfirm: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      // Convert boolean to integer. 1 = true, 0 = false
      if (this.staffData.headcount == false) {
        this.staffData.headcount = 0;
      } else {
        this.staffData.headcount = 1;
      }

      let vm = this;

      this.$store.dispatch('put', {url: "/staffmov/" + this.id,
          payload: this.staffData})
        .then((res) => {
          if (res.data.status == 201) {
            vm.$router.push({ name: "stafflisths", params: { search: this.search}  });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/staffmov/" +
            this.id +
            "/" +
            this.staffData.staffid +
            "/" +
            this.staffData.userid})
        .then((res) => {
          if (res.data.status == 200) {
            vm.$router.push({ name: "stafflisths" });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    newMovementHandler() {
      let vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/staffmov/create", payload: {
          movementid: vm.staffData.movementid,
          movtypeid: vm.staffData.movtypeid,
          staffid: vm.staffData.staffid,
          psnid: vm.staffData.psnid,
          recdate: vm.staffData.recdate,
          recenddate: vm.staffData.recenddate,
          actualdate: vm.staffData.actualdate,
          reasonid: vm.staffData.reasonid,
          compnyid: vm.staffData.compnyid,
          brchid: vm.staffData.brchid,
          divnid: vm.staffData.divnid,
          deptid: vm.staffData.deptid,
          sectioid: vm.staffData.sectioid,
          unitid: vm.staffData.unitid,
          positionid: vm.staffData.positionid,
          emptypeid: vm.staffData.emptypeid,
          grdid: vm.staffData.grdid,
          jobid: vm.staffData.jobid,
          crcyid: vm.staffData.crcyid,
          prevbasicsal: vm.staffData.prevbasicsal,
          basicsal: vm.staffData.basicsal,
          basicfreqid: vm.staffData.basicfreqid,
          adjustamt: vm.staffData.adjustamt,
          adjustpct: vm.staffData.adjustpct,
          newbasicsal: vm.staffData.newbasicsal,
          contrprd: vm.staffData.contrprd,
          contrenddate: vm.staffData.contrenddate,
          attach: vm.staffData.attach,
          attachname: vm.staffData.attachname,
          remark: vm.staffData.remark,
          lockrecdate: vm.staffData.lockrecdate,
          lockstaff: vm.staffData.lockstaff,
          movstamp: vm.staffData.movstamp,
          userid: vm.staffData.userid,
          currentmovementid: vm.currentMovementId,

          probmth: vm.staffData.probmth,
          dueconfirmdate: vm.staffData.dueconfirmdate,
          exprobmth: vm.staffData.exprobmth,
          exdueconfirmdate: vm.staffData.exdueconfirmdate,
          confirmdate: vm.staffData.confirmdate,

          resigndate: vm.staffData.resigndate,
          resignnotprd: vm.staffData.resignnotprd,
          lworkdate: vm.staffData.lworkdate,
          olastdate: vm.staffData.olastdate,
          cessadate: vm.staffData.cessadate,
          
          movtypedesc: vm.staffData.movtypedesc,
          psnname: vm.staffData.psnname,
          reasondesc: vm.staffData.reasondesc,
          compnyname: vm.staffData.compnyname,
          brchname: vm.staffData.brchname,
          deptname: vm.staffData.deptname,
          sectioname: vm.staffData.sectioname,
          unitname: vm.staffData.unitname,
          posdesc: vm.staffData.posdesc,
          emptypedesc: vm.staffData.emptypedesc,
          grddesc: vm.staffData.grddesc,
          jobtitle: vm.staffData.jobtitle,
          crcycode: vm.staffData.crcycode,
          idno: vm.staffData.idno,
        }})
        .then((res) => {
          if (res.data.status == 201) {
            vm.$router.push({ name: "stafflisths" });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadStaff() {
      const vm = this;

      this.$store.dispatch('get', {url: "/staffmov/" + this.id})
        .then((res) => {
          var hiredate,
            servicedate,
            recdate,
            recenddate,
            actualdate,
            dueconfirmdate,
            exdueconfirmdate,
            confirmdate,
            resigndate,
            lworkdate,
            olastdate,
            cessadate,
            contrenddate;
          var headcount;

          if (res.data.rec[0].headcount == 0) {
            headcount = false;
          } else {
            headcount = true;
          }

          if (res.data.rec[0].hiredate)
            hiredate = moment(res.data.rec[0].hiredate).format("YYYY-MM-DD");
          if (res.data.rec[0].servicedate)
            servicedate = moment(res.data.rec[0].servicedate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].recdate)
            recdate = moment(res.data.rec[0].recdate).format("YYYY-MM-DD");
          if (res.data.rec[0].recenddate)
            recenddate = moment(res.data.rec[0].recenddate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].actualdate)
            actualdate = moment(res.data.rec[0].actualdate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].dueconfirmdate)
            dueconfirmdate = moment(res.data.rec[0].dueconfirmdate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].exdueconfirmdate)
            exdueconfirmdate = moment(res.data.rec[0].exdueconfirmdate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].confirmdate)
            confirmdate = moment(res.data.rec[0].confirmdate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].resigndate)
            resigndate = moment(res.data.rec[0].resigndate).format(
              "YYYY-MM-DD"
            );
          if (res.data.rec[0].lworkdate)
            lworkdate = moment(res.data.rec[0].lworkdate).format("YYYY-MM-DD");
          if (res.data.rec[0].olastdate)
            olastdate = moment(res.data.rec[0].olastdate).format("YYYY-MM-DD");
          if (res.data.rec[0].cessadate)
            cessadate = moment(res.data.rec[0].cessadate).format("YYYY-MM-DD");
          if (res.data.rec[0].contrenddate)
            contrenddate = moment(res.data.rec[0].contrenddate).format(
              "YYYY-MM-DD"
            );

          vm.staffData.movementid = res.data.rec[0].id;
          vm.staffData.staffcode = res.data.rec[0].staffcode;
          vm.staffData.empno = res.data.rec[0].empno;
          vm.staffData.hiredate = hiredate;
          vm.staffData.servicedate = servicedate;
          vm.staffData.probmth = res.data.rec[0].probmth;
          vm.staffData.dueconfirmdate = dueconfirmdate;
          vm.staffData.exprobmth = res.data.rec[0].exprobmth;
          vm.staffData.exdueconfirmdate = exdueconfirmdate;
          vm.staffData.confirmdate = confirmdate;
          vm.staffData.resigndate = resigndate;
          vm.staffData.resignnotprd = res.data.rec[0].resignnotprd;
          vm.staffData.lworkdate = lworkdate;
          vm.staffData.olastdate = olastdate;
          vm.staffData.cessadate = cessadate;
          vm.staffData.headcount = headcount;
          vm.staffData.pic = res.data.rec[0].pic;
          vm.staffData.stfstamp = res.data.rec[0].staffstamp;
          vm.staffData.movtypeid = res.data.rec[0].movtypeid;

          vm.oldMovtypeid = res.data.rec[0].movtypeid;

          vm.staffData.staffid = res.data.rec[0].staffid;
          vm.staffData.psnid = res.data.rec[0].psnid;
          vm.staffData.recdate = recdate;
          vm.staffData.recenddate = recenddate;
          vm.staffData.actualdate = actualdate;
          vm.staffData.reasonid = res.data.rec[0].reasonid;
          vm.staffData.compnyid = res.data.rec[0].compnyid;
          vm.staffData.brchid = res.data.rec[0].brchid;
          vm.staffData.divnid = res.data.rec[0].divnid;
          vm.staffData.deptid = res.data.rec[0].deptid;
          vm.staffData.sectioid = res.data.rec[0].sectioid;
          vm.staffData.unitid = res.data.rec[0].unitid;
          vm.staffData.positionid = res.data.rec[0].positionid;
          vm.staffData.emptypeid = res.data.rec[0].emptypeid;
          vm.staffData.grdid = res.data.rec[0].grdid;
          vm.staffData.jobid = res.data.rec[0].jobid;
          vm.staffData.crcyid = res.data.rec[0].crcyid;

          vm.staffData.prevbasicsal = res.data.rec[0].prevbasicsal;
          vm.staffData.basicsal = res.data.rec[0].basicsal;

          //if (res.data.rec[0].prevbasicsal != 0) {
          //    vm.staffData.basicsal = res.data.rec[0].prevbasicsal;
          //} else {
          //    vm.staffData.basicsal = res.data.rec[0].basicsal;
          //}
          //vm.staff.prevbasicsal = res.data.rec[0].prevbasicsal;

          vm.staffData.basicfreqid = res.data.rec[0].basicfreqid;
          vm.staffData.adjustamt = res.data.rec[0].adjustamt;
          vm.staffData.adjustpct = res.data.rec[0].adjustpct;
          vm.staffData.newbasicsal = res.data.rec[0].newbasicsal;
          vm.staffData.contrprd = res.data.rec[0].contrprd;
          vm.staffData.contrenddate = contrenddate;
          vm.staffData.attach = res.data.rec[0].attach;
          vm.staffData.attachname = res.data.rec[0].attachname;
          vm.staffData.remark = res.data.rec[0].remark;
          vm.staffData.lockrecdate = res.data.rec[0].lockrecdate;
          vm.staffData.lockstaff = res.data.rec[0].lockstaff;
          vm.staffData.movstamp = res.data.rec[0].movstamp;

          vm.currentMovementId = res.data.rec[0].movtypeid;
          vm.pageTitleValue = res.data.rec[0].psnname;

          vm.staffData.movtypedesc = res.data.rec[0].movtypedesc;
          vm.staffData.psnname = res.data.rec[0].psnname;
          vm.staffData.reasondesc = res.data.rec[0].reasondesc;
          vm.staffData.compnyname = res.data.rec[0].compnyname;
          vm.staffData.brchname = res.data.rec[0].brchname;
          vm.staffData.deptname = res.data.rec[0].deptname;
          vm.staffData.sectioname = res.data.rec[0].sectioname;
          vm.staffData.unitname = res.data.rec[0].unitname;
          vm.staffData.posdesc = res.data.rec[0].posdesc;
          vm.staffData.emptypedesc = res.data.rec[0].emptypedesc;
          vm.staffData.grddesc = res.data.rec[0].grddesc;
          vm.staffData.jobtitle = res.data.rec[0].jobtitle;
          vm.staffData.crcycode = res.data.rec[0].crcycode;
          vm.staffData.idno = res.data.rec[0].idno;

          vm.loading = false;
        })
        .catch((err) => {
          console.log(err);
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadStaff();
  },
};
</script>
