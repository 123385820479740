<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:profile="formData.pic = $event"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PersonForm from "../../../components/personnel/person/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": PersonForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('recpersonalinfo.newperson'),
      pageTitleValue: "",
      formData: {
        psncode: null,
        psnname: null,
        psnname2: null,
        salutid: null,
        fname: null,
        genderid: null,
        mname: null,
        dob: null,
        lname: null,
        idno: null,
        oldidno: null,
        marstatid: null,
        mrgdate: null,
        raceid: null,
        religionid: null,
        nationid: null,
        cntryoid: null,
        // attach: null,
        pic: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/person/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "personlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
};
</script>