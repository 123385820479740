    <template>
    <div>
        <v-dialog v-model="travellimitdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('setupclaimlimit.travellingcliaminputitem')}}</span>
              <!--<span class="headline mb-4">Travelling Claim Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                
                 <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupclaimlimit.claimgroup')"
                            type="text"
                            ref="clmgrpid"
                            dense
                            :rules="[rules.required]"
                            :items="claimList"
                            hide-selected
                            v-model="selections[0].clmgrpid"
                            @change="changeClaimHandler(selections[0].clmgrpid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].clmgrpdesc"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                 <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupclaimlimit.vehichletype')"
                            type="text"
                            ref="vehtypeid"
                            dense
                            hide-selected
                            :rules="[rules.required]"
                            :items="vehicleList"
                            v-model="selections[0].vehtypeid"
                             @change="changeVehicleHandler(selections[0].vehtypeid)"
                        ></v-autocomplete>
                    </v-col>
                </v-row> 

                <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.uptokm')"
                                type="text"
                                ref="uptokm"
                                :rules="[rules.required]"
                                v-model="selections[0].uptokm"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.ratekm')"
                                type="decimal"
                                ref="rateperkm"
                                  :rules="[rules.required]"
                                v-model="selections[0].rateperkm"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeTravelLimitInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveTravelLimitInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },

            travellimitdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                clmgrpid: null,
                clmgrp: null,
                clmgrpdesc: null,
                vehtypeid: null,
                vehtype: null,
                uptokm: null,
                rateperkm: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }],

            claimList:[],
            claimItems:[],

            vehicleItems: [],
            vehicleList: [],

          //  VehicleTypeLoading: true,
   
            itemHolder: [],
            detailList: [],

            vehichleLoading:true,
            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

    methods: {
        compareArray,

         loadClaim(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/groupp_grouppb_claim/all'
            })
            .then(res => {

                vm.claimItems = res.data.rec;

                for (let i = 0; i < vm.claimItems.length; i++) {
                    vm.claimList.push({
                        text: vm.claimItems[i].valuecode,
                        code: vm.claimItems[i].valuedesc,
                        value: vm.claimItems[i].valvalueid,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeClaimHandler(valvalueid){
            let item = this.claimItems.find(item => item.valvalueid == valvalueid);

            this.selections[0].clmgrp = item.valuecode;
            this.selections[0].clmgrpdesc = item.valuedesc;

        },

        loadVehicle(){
              const vm = this;

             this.$store.dispatch('get', {
                 url: '/vehicletype/all'
            })
             .then(res => {

                 vm.vehicleItems = res.data.rec;
                 console.log('vehicle', vm.vehicleItems)

                 for (let i = 0; i < res.data.rec.length; i++) {
                     vm.vehicleList.push(
                         {
                         text: res.data.rec[i].vehtype,
                         code: res.data.rec[i].vehtype,
                         value: res.data.rec[i].id,
                     }
                     
                    )
                    console.log('list',vm.vehicleList)
                 }

                 vm.vehichleLoading = false;
             })
             .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                
             });
         },

          changeVehicleHandler(id){
             let item = this.vehicleItems.find(item => item.id == id);

             // this.selections[0].vehtypeid = item.id;
              this.selections[0].vehtype = item.vehtype;

        },

        modifyItemHandler(item) {
          // assign loaded data to selections array
           
            if(item.action == 1) {
                  this.modify = true;    
             }

             if(item.action == 2) {
                  this.isExisting = true;    
            }

              this.selections[0] =  item;
              this.travellimitdialog = true;

               if (!this.isExisting){
                // duplicate this.detailItem for duplicate checking
                
                //find index 
                
                var index = this.detailItem.findIndex( x => x.clmgrpid == item.clmgrpid);

                this.detailItem[index].clmgrpdesc = item.clmgrpdesc;
                this.detailItem[index].vehtypeid = item.vehtypeid;
                this.detailItem[index].rateperkm = item.rateperkm;
                this.detailItem[index].uptokm = item.uptokm;

                //this.resetSelections();

            } else {
                this.selections[0].action = 2;

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }

          },


        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },


           saveTravelLimitInput() {

            if (this.selections[0].clmgrpid == null || this.selections[0].vehtypeid == null
            || this.selections[0].uptokm == null || this.selections[0].rateperkm == null){

              this.$dialog.alert("Fill in the required columns").then((dialog) => {
                console.log(dialog);
             });
            } else {

              this.isErr = false;

              if(!this.isExisting && this.modify){
                  this.resetSelections();
              } else if (!this.isExisting) {
                   this.selections[0].id = null;
                   this.selections[0].action = 1;

                   this.detailItem.push(Object.assign({}, this.selections[0]));
              } else {
                   if (this.compareArray(this.detailItem, this.selections[0])) {
                       console.log(this.compareArray(this.detailItem, this.selections[0]))
                   }
                
               }
              // console.log('detailitem', this.detailItem)
               this.$emit('input', this.detailItem);
               this.travellimitdialog = false;
            

              // reset the form fields
               this.resetSelections();
            }
           },

        
        closeTravelLimitInput() {
            this.travellimitdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

    

        
        resetSelections() {
            console.log('selections',this.selections[0])
            this.selections = [{
                id: null,
                clmgrpid: null,
                clmgrp: null,
                clmgrpdesc: null,
                vehtypeid: null,
                vehtype: null,
                uptokm: null,
                rateperkm: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },
    },

    created() {
        this.loadClaim();
        this.loadVehicle();
    }
}
</script>   


       