<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";
export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setuppersonstatutorynumberinfo.personstatutorynumberinfolist'),

      //tab headers
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "80px" },
        { text: this.$t('columns.staffname'), value: "staffname", width: "200px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "150px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "150px" },
        { text: this.$t('setupcompanyreferencenumber.vendorcategory'), value: "vendorcatcode", width: "120px" },
        { text: this.$t('setuppersonstatutorynumberinfo.refno'), value: "refno", width: "200px" },
        { text: this.$t('setuppersonstatutorynumberinfo.coref'), value: "refcode", width: "160px" },
        { text: this.$t('columns.startdate'), value: "startdate", width: "150px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "150px" },
        { text: this.$t('setuppersonstatutorynumberinfo.spouserefno'), value: "spouserefno", width: "200px" },
        { text: this.$t('setuppersonstatutorynumberinfo.spousebranch'), value: "spousebranchname", width: "200px" },
        { text: this.$t('columns.remark'), value: "remark", width: "200px" },
      ],

      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    //get all records
    getPersonStatutoryNumberInfoList() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: `/personstatutoryinfo/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;
          console.log("dataItems", vm.dataItems);

          //set corrct date format using moment
          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format(
                "YYYY-MM-DD"
              );
            }
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "personstatutoryinfoedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "personstatutoryinfonew" });
    },
  },

  mounted() {
    this.getPersonStatutoryNumberInfoList();
  },
};
</script>