<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            />
        </div>    
    </div>
</template>

<script>
    import ServiceMovementListingForm from '../../../components/personnel/servicemovementlisting/Form_Parameter';
    import ErrorDialog from '../../../components/common/ErrorDialog';

    export default {
        components: {
            'app-form': ServiceMovementListingForm,
            'app-error': ErrorDialog
        },

        data() {
            return{
                pageTitle: this.$t('servicemovlisting'), 
                pageTitleValue: '',
                
                formData: {
                },
                
                dataItems:[],

                goBtn: false,

                yearList: [],
                monthList: [],
                exchangeList: [],
                currencyList: [],
                codeList: [],

                errMsg: '',
                isErr: false,
                editMode: false
            }
        },

        methods: {
        }
    }
</script>