<template>
    <div>
      
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaimlimit.selectclaimtype')"
                                    type="text"
                                    ref="clmtype"
                                    :rules="[rules.required]"
                                    v-model="formData.clmtypeid"
                                    dense
                                    :items="clmtypeList"
                                    @input="updateValue"
                                    @change="changeParentClaim(formData.clmtypeid)"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="8" sm="5" class="pb-n4">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypedesc"
                                    v-model="clmtypedesc"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimcategorydescription')"
                                    type="text"
                                    ref="clmtypecat"
                                    v-model="clmtypecat"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="prorate"
                                v-model="formData.prorate"
                                :label="this.$t('setupclaimlimit.proroatefornewjoiner')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                     <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.claimlimitdetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row> 

                     <v-row v-if="hidetable" class="blue--text ">
                            <h3>{{$t('setupclaimlimit.travellingrelatedclaimcontrol')}}</h3>
                        </v-row>

                <v-row v-if="hidetable">
                 <!-- <v-row v-if="formData.clmtypeid = '3'" > -->
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table2 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader2,
                                detailItem: this.formData.travellinglimitdetail
                            }"
                            @input="changePayoutHandler()"
                            v-model="selected"
                            v-on:input="addItemHandler2"
                        />
                    </v-col>
                </v-row> 
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';
import CRUDTable2 from './CRUDTable2'


export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-table2' : CRUDTable2,
 
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            pageTitle: this.$t('claimlimitsetupdetailinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                claimlimitdetail: this.parentObject.data.claimlimitdetail,
                travellinglimitdetail: this.parentObject.data.travellinglimitdetail,
                clmtypeid: this.parentObject.data.clmtypeid,
                clmtypedesc: this.parentObject.data.clmtypedesc,
                //clmtypecat: this.parentObject.data.clmtypecat,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                prorate: this.parentObject.data.prorate,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setupclaimlimit.claimgroup'), value: 'clmgrp', width: '200px', sortable: false},
                { text: this.$t('setupclaimlimit.uptoservice'), value: 'uptosrv', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.maxperiod'), value: 'maxperprd', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.maxtransaction'), value: 'maxpertran', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.maxtransactionperiod'), value: 'maxtranperprd', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.claimable'), value: 'clmpercent', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.claimgroupdescription'), value: 'clmgrpdesc', width: '200px', sortable: false },
            ],

            tabHeader2: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setupclaimlimit.claimgroup'), value: 'clmgrp', width: '200px', sortable: false},
                { text: this.$t('setupclaimlimit.vehichletype'), value: 'vehtype', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.uptokm'), value: 'uptokm', width: '200px', sortable: false },
                { text: this.$t('setupclaimlimit.ratekm'), value: 'rateperkm', width: '200px', sortable: false },
            ],

            payItemList: [],
            datapayItems: [],
            payitemcode: '',

            clmtypeItems: [],
            clmtypeList: [],
            clmtypedesc: '',
            clmtypecat: '',

            selected: [],

            hidetable: false,

            errMsg: '',
            isErr: false,
            editMode: false,
            loading: false,
            search: this.parentObject.searchnew
        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.claimlimitdetail =$event
         //   this.formData.travellinglimitdetail =$event
        },

          addItemHandler2($event) {
             this.formData.travellinglimitdetail =$event
         },


        changePayoutHandler() {
            this.formData.travellinglimitdetail = this.selected
            this.updateValue()
        },

        // loadPayoutEdit() {
        //     let getItem = []
        //     if (this.isEdit) {
        //         for (let i = 0; i < this.payoutList.length; i++) {
        //             for (let j = 0; j < this.formData.eissubrecpay.length; j++) {
        //                 if (this.payoutList[i].pyctrlid === this.formData.eissubrecpay[j].pyctrlid) {
        //                     getItem[j] = this.payoutList[i]
        //                     getItem[j].exId = this.formData.eissubrecpay[j].id
                            
        //                 }
        //             }
        //         }
                
        //         this.loadPayoutList()
        //         this.selected = getItem
        //         this.formData.travellinglimitdetail = []
        //     }
        // },



          changeParentClaim() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypesweb/all'
            })
            .then(res => {

                vm.clmtypeItems = res.data.rec;

                for (let i = 0; i < vm.clmtypeItems.length; i++) {
                    vm.clmtypeList.push({
                        text: vm.clmtypeItems[i].clmtype,
                        value: vm.clmtypeItems[i].id,
                    });
                }

                if (this.formData.clmtypeid) {
                this.changeParentClaimHandler(this.formData.clmtypeid);
                }

               
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeParentClaimHandler(id) {
            var item = this.clmtypeItems.find(item => item.id === id);
            this.clmtypeid = item.clmtype;
            this.clmtypedesc = item.clmtypedesc;
            this.clmtypecat = item.clmtypecat;
            this.clmtypecatid = item.clmtypecatid;

            if(this.clmtypecatid == '2')
            {
                this.hidetable = true;
            }

             else {
                this.hidetable = false;
             }

        },

                
        updateValue() {

            console.log('travellingg' , this.formData.travellinglimitdetail)
            console.log('claim', this.formData.claimlimitdetail)
          //  console.log('patterndetail', this.formData.patterndetail)
            this.$emit('input', {
                id: this.formData.id,
                claimlimitdetail: this.formData.claimlimitdetail,
                travellinglimitdetail: this.formData.travellinglimitdetail,
                clmtypeid: this.formData.clmtypeid,
                clmtypedesc: this.formData.clmtypedesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
               // clmtypecat: this.formData.clmtypecat,
                prorate: this.formData.prorate,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'claimlimitsetuplist', params: { search: this.search}});
        },


    },

    mounted(){
        //this.changePayItem();
        this.changeParentClaim();
        //this.loading = true;
            
        }
}
</script>