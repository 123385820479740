<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row>
          <v-col cols="12" sm="6" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('allsocso.socsosubmissionrec')"
              type="text"
              v-model="formData.socsoexprecid"
              :items="socsoExpRecList"
              :rules="[rules.required]"
              @change="changeSocsoExportRecord(formData.socsoexprecid)"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" class="mb-n1 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
              type="text"
              v-model="formData.year"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3" class="mb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('bankpaymentlistingreport.payoutmonth')"
              type="text"
              v-model="formData.month"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}} </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="dataHeader"
              :items="this.payoutItems"
              :options="tableOpt"
              hide-default-footer
              item-key="payoutcode"
            >
            </v-data-table>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="6" class="pb-n5 mt-3">
            <v-text-field
              outlined
              :label="Reference No."
              type="text"
              v-model="formData.refnum"
              readonly
              dense
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row> -->

        
          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-3">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                type="text"
                v-model="formData.refnum"
                :rules="[rules.required]"
                :items="socsoSubRefRecList"
                dense            
              ></v-autocomplete>
            </v-col>
          </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="pb-n5 mt-n8">
            <v-autocomplete
              outlined
              v-model="formData.socsoexpformatid"
              :label="this.$t('allsocso.socsoexportformat')"
              type="text"
              :items="this.exportFmtList"
              :rules="[rules.required]"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px; margin-bottom: 20px;" @click="nextHandler">
              {{$t('button.next')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('allsocso.socsoexportprocess'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        {
          text: this.$t('statutoryexportcommonused.payoutdescription'),
          value: "payoutdesc",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('payrollprocessrun.payoutstatus'),
          value: "pystatusdesc",
          width: "300px",
          sortable: true,
        },
      ],
      formData: {
        socsoexprecid: null,
        refnum: null,
        year: null,
        month: null,
        myntlycode: null,
        socsosubrecpy: [],
        socsosubrecref: [],
        socsoexpformatid: null,
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      socsoExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      socsoSubRefRecList: [],

      notFinalize: false,
       data2: [],
      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    getSocsoSubRecs() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/socsosubmissionrecord/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.socsoExpRecList.push({
              text: res.data.rec[i].socsosubreccode,
              value: res.data.rec[i].id,
            });
          }
         
          vm.loading = !vm.loading;
 

        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getSocsoSubRecPayout(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/socsosubmissionrecordpy/" + id})
        .then((res) => {
          vm.payoutItems = res.data.rec;
          vm.formData.socsosubrecpy = vm.payoutItems;

          for (let i = 0; i < vm.payoutItems.length; i++) {
            if (vm.payoutItems[i].pystatuscode != 99) {
              this.notFinalize = false;
            } else {
              this.notFinalize = true;
            }
               vm.data2.push(this.notFinalize)
          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getSocsoSubRecRef(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/socsosubmissionrecordref/" + id})
        .then((res) => {
          vm.formData.socsosubrecref = res.data.rec;

          //set the socsoSubRefRecList array null (to avoid getting value from previous array)

          vm.socsoSubRefRecList = [];

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.socsoSubRefRecList.push({
              text: res.data.rec[i].refcode + ' - ' + res.data.rec[i].refnum,
              value: res.data.rec[i].refnum,
            });
          }


          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getExportFormattListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/exportformat/all"})
        .then(function (res) {
          vm.exportformatItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            if (res.data.rec[i].exptypeid == 3) {
              vm.exportFmtList.push({
                text: res.data.rec[i].expdesc,
                value: res.data.rec[i].id,
              });
            }
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    changeSocsoExportRecord(id) {
      let itemExportRecord = this.dataItems.find((item) => item.id === id);
      console.log('itemExportRecord', itemExportRecord)
      this.formData.socsoexprecid = itemExportRecord.id;
      this.formData.year = itemExportRecord.recyear;
      this.formData.month = itemExportRecord.recmonth;
      this.formData.myntlycode = itemExportRecord.myntlycode;

      this.getSocsoSubRecPayout(id);
      this.getSocsoSubRecRef(id);
    },

    nextHandler() {
      let item = this.formData;

      let id = this.formData.socsoexpformatid;

      var newvalue = true;
      console.log('data2', this.data2)
      for (let i = 0; i < this.data2.length; i++) {
               
        if (!this.data2[i])
        {
          newvalue = false;
        }
      }
      
      if (newvalue == false) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.payoutnotfinalized');
      } else {
        if (this.$refs.form.validate()) {
          this.$router.push({
            name: "monthlysocsoexportparam",
            params: { id, item },
          });
        } else {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        }
      }
    },
  },

  mounted() {
    this.getSocsoSubRecs();
    this.getExportFormattListHandler();
  },
};
</script>