    <template>
    <div>
        <v-dialog v-model="dependentdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
            this.parentObject.psnid
            this.parentObject.staffid>+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('recgrpinsuranceassignment.adddependent')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">

                    <!-- <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            label="Staff Name"
                            type="text"
                            ref="psnid"
                            dense
                            :rules="[rules.required]"
                            v-model="this.parentObject.staffname"
                            readonly
                            background-color="#ffeab2"
                        ></v-text-field>
                    </v-col>
                </v-row> -->

                  <v-row>
                       <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.staffname')"
                            type="text"
                            v-model="selections[0].staffname"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="12" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('recgrpinsuranceassignment.dependentname')"
                            type="text"
                            ref="memberid"
                            dense
                            :rules="[rules.required]"
                            :items="memberList"
                            v-model="selections[0].memberid"
                            @change="changeMemberHandler(selections[0].memberid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    
                    <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('setupreport_exportmapping.relationship')"
                            type="text"
                            v-model="selections[0].memberdesc"
                            dense
                        ></v-text-field>
                    </v-col>

                      <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.gender')"
                            type="text"
                            v-model="selections[0].gendername"
                            dense
                        ></v-text-field>
                    </v-col>

                      <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.dob')"
                            type="text"
                            v-model="selections[0].dob"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>

                       <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('recfamdetails.icno')"
                            type="text"
                            v-model="selections[0].icno"
                            dense
                        ></v-text-field>
                    </v-col>

                      <v-col cols="4" sm="4" md="4" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('recpersonalinfo.maritalstatus')"
                            type="text"
                            v-model="selections[0].marstatname"
                            dense
                        ></v-text-field>
                    </v-col>
                
                    <v-col cols="4" sm="4" md="4" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('recgrpinsuranceassignment.passportno')"
                                type="text"
                                ref="uptosrv"
                                v-model="selections[0].passportno"
                                :rules="[rules.required]"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
             
                <v-row>
                   <v-col cols="8" sm="7" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('recpersonalinfo.nationality')"
                            type="text"
                            ref="nationalityid"
                            dense
                            :rules="[rules.required]"
                            :items="nationalityList"
                            v-model="selections[0].nationalityid"
                            @change="changeNationHandler(selections[0].nationalityid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].nationalitycode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                    <v-row>
                   <v-col cols="8" sm="7" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupcompanybankaccount.bank')"
                            type="text"
                            ref="bankid"
                            dense
                            :items="bankList"
                            v-model="selections[0].bankid"
                            @change="changeBankHandler(selections[0].bankid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].bankcode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="7" md="7" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompanybankaccount.accountno')"
                                type="text"
                                ref="accountno"
                                v-model="selections[0].accountno"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="7" md="7" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reccontactdetails.email')"
                                type="text"
                                ref="email"
                                v-model="selections[0].email"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
            
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeDependentInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveDependentInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import moment from 'moment';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || 'Value is 1-100'
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            dependentdialog: false,
            detailItem: this.parentObject.detailItem,
            staffid: this.parentObject.staffid,
            psnid: this.parentObject.psnid,
            staffname: this.parentObject.staffname,

            selections: [{
                id: null,
                psnid: this.parentObject.psnid,
                psnname: null,
                staffid: null,
                staffname: null,
                staffcode: null,
                memberid: null,
                dependentname: null,
                memberdesc: null,
                membertypeid: null,
                dob: null,
                icno: null,
                genderid: null,
                gendername: null,
                marstatid: null,
                marstatname:null,
                passportno: null,
                nationalityid: null,
                nationalitycode:null,
                nationalityname: null,
                bankid: null,
                bankcode: null,
                bankname: null,
                accountno: null,
                email: null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }],

            memberList:[],
            memberItems:[],

            nationItems: [],
            nationalityList: [],
       
            itemHolder: [],
            detailList: [],

            staffdetails: [],

            bank: [],
            bankList: [],


            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
       compareArray,

        loadBank() {
           const vm = this;

            this.$store.dispatch('get', {
                url:  '/bank/all'})
                .then(res => {
                    vm.bank = res.data.rec;

                   // console.log('maptype', vm.maptypes)

                    for (let i = 0; i < vm.bank.length; i++) {
                    vm.bankList.push({
                        text: res.data.rec[i].bankname,
                       // code: res.data.rec[i].bankcode,
                        value: res.data.rec[i].id,
                        
                    });
                }

                 if (vm.selections[0].bankid){
                         vm.changeBankHandler(vm.selections[0].bankid);
                     }

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });

    
        },

        changeBankHandler(id) {
              var item = this.bank.find(item => item.id === id);

              this.selections[0].bankcode = item.bankcode;
              this.selections[0].bankname = item.bankname;
        },

        loadStaff() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  "/staff/" + this.parentObject.staffid})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    console.log('staff', vm.staffdetails)

                    vm.selections[0].staffid = res.data.rec[0].id;
                    vm.selections[0].staffname = res.data.rec[0].psnname;
                    vm.selections[0].staffcode = res.data.rec[0].staffcode;
                    vm.selections[0].psnid = res.data.rec[0].psnid;


                    console.log(vm.selections[0].psnid)

                    this.loadMember();

                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

    
         loadMember(){
             const vm = this;

            this.$store.dispatch('get', {
                url:  "/dependentlist/" + this.selections[0].psnid})
            .then((res) => {

                console.log('psnid', this.selections[0].psnid)
                vm.memberItems = res.data.rec;

                console.log('member', vm.memberItems)

                for (let i = 0; i < vm.memberItems.length; i++) {
                    vm.memberList.push({
                        text: vm.memberItems[i].membername,
                        value: vm.memberItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

         changeMemberHandler(id){
             let item = this.memberItems.find(item => item.id == id);

             this.selections[0].dependentname = item.membername;
             this.selections[0].psnname = item.psnname;
             this.selections[0].dob = moment(item.dob).format("DD-MM-YYYY");
             this.selections[0].icno = item.icno;
             this.selections[0].gendername = item.gendername;
             this.selections[0].marstatname = item.marstatname;
             this.selections[0].memberdesc = item.memberdesc;

         },

        

          loadNation(){
             const vm = this;

            this.$store.dispatch('get', {
                url:  '/country/all'})
            .then(res => {

                vm.nationItems = res.data.rec;

                for (let i = 0; i < vm.nationItems.length; i++) {
                    vm.nationalityList.push({
                        text: vm.nationItems[i].cntryname,
                        value: vm.nationItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeNationHandler(id){
            let item = this.nationItems.find(item => item.id == id);

            this.selections[0].nationalitycode = item.cntrycode;
            this.selections[0].nationalityname = item.cntryname;
           // this.selections[0].clmgrpdesc = item.valuedesc;

        },

        
         modifyItemHandler(item) {

             if(item.action == 1) {
                  this.modify = true;    
             }
      
             if(item.action == 2) {
                this.isExisting = true;  
             }

             this.selections[0] = item;
             this.dependentdialog = true;

             if (!this.isExisting){
                 // duplicate this.detailItem for duplicate checking
                
                 //find index 
                
                 var index = this.detailItem.findIndex( x => x.memberid == item.memberid);

                 this.detailItem[index].passportno = item.passportno;
                 this.detailItem[index].nationalityid = item.nationalityid;
                 this.detailItem[index].bankid = item.bankid;
                 this.detailItem[index].accountno = item.accountno;
                 this.detailItem[index].email = item.email;
               //  this.detailItem[index].nationalityid = item.nationalityid;




             } else {
                 this.selections[0].action = 2;

                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
             }

             //this.editDialog = true;
           
         },        


        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },
       
        closeDependentInput() {
            this.dependentdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        saveDependentInput() {

              if (this.selections[0].memberid == null)
              {
                this.$dialog.alert(this.$t('recgrpinsuranceassignment.message1')).then((dialog) => {
                  console.log(dialog);
              });
              } else if(this.selections[0].nationalityid == null) {
                this.$dialog.alert(this.$t('recgrpinsuranceassignment.message2')).then((dialog) => {
                  console.log(dialog);
              });
              } else if(this.selections[0].passportno == null ){
               this.$dialog.alert(this.$t('recgrpinsuranceassignment.message3')).then((dialog) => {
                  console.log(dialog);
              });
            
            } else {
            
            this.isErr = false;
            
            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } 
            
            
            else if (!this.isExisting) {
                this.selections[0].action = 1;

                if (this.detailItem.some( rec => rec.memberid  == this.selections[0].memberid)) {
                        
                        this.errMsg = this.$t('recgrpinsuranceassignment.message4');
                        this.isErr = true;
                        this.dependentdialog = true; 

                        console.log('dependentdialog',this.dependentdialog)
                        
                } else {
                        this.selections[0].id = null;
                        this.selections[0].action = 1;

                        this.detailItem.push(Object.assign({}, this.selections[0]));

                          this.$emit("input", this.detailItem);

                        //this.modify = false;
                        this.dependentdialog = false;

                        this.resetSelections();
                } 
            
                        // else {
                        //     if (this.compareArray(this.detailItem, this.selections[0])) {
                        //     console.log(this.compareArray(this.detailItem, this.selections[0]));
                        //     }
            } else {
                this.selections[0].action = 2;
                //this.resetSelections();

                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
            }
        


             this.$emit("input", this.detailItem);

             //this.modify = false;
             this.dependentdialog = false;

             this.resetSelections();
            // }
             }

        
        },

        // else if (!this.isExisting) {


                
        //         this.selections[0].id = null;
        //         this.selections[0].action = 1;

        //         this.detailItem.push(Object.assign({}, this.selections[0]));
        //     } else {
        //         if (this.compareArray(this.detailItem, this.selections[0])) {
        //         console.log(this.compareArray(this.detailItem, this.selections[0]));
        //         }
        //     }
       
        resetSelections() {
            this.selections = [{
                id: null,
                memberid: null,
                dependentname: null,
                psnid: this.parentObject.psnid,
                staffid: this.selections[0].staffid,
                staffname: this.selections[0].staffname,
                memberdesc: null,
                menbertypeid: null,
                dob: null,
                icno: null,
                genderid: null,
                gendername: null,
                marstatid: null,
                marstatname:null,
                passportno: null,
                nationalityid: null,
                nationalitycode:null,
                nationalityname: null,
                bankid: null,
                bankcode: null,
                bankname: null,
                accountno: null,
                email: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

    },

    mounted(){
      

    },

    created() { 
        this.loadNation();
      //  this.loadMember();
         this.loadStaff();
         this.loadBank();
    }
}
</script>   


       