<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setuprestdaypatternsetup.patternname')"
                                type="text"
                                id = "patterncode"
                                ref = "patterncode"
                                :rules="[rules.required]"
                                v-model="formData.patterncode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                id = "patterndesc"
                                ref= "patterndesc"
                                :rules="[rules.required]"
                                v-model="formData.patterndesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="alternate"
                                v-model="formData.alternate"
                                :label="this.$t('setuprestdaypatternsetup.alternate')"
                                @change="updateValue"
                                dense
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuprestdaypatternsetup.beginoffweek')"
                                type="text"
                                hide-selected
                                v-model="formData.beginoffweek"
                                @change="updateValue"
                                dense
                                :items="weeks"
                                :rules="[rules.required]"
                                :disabled="formData.alternate != true "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.patterndetail
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-col>
                </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import axios from 'axios';

//import GenericForm from '../../generic/GridForm';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('setuprestdaypatternsetup.restdayaptterninputdashboard'), 
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                patterndetail: this.parentObject.data.patterndetail,
                patterncode: this.parentObject.data.patterncode,
                patterndesc: this.parentObject.data.patterndesc,
                alternate: this.parentObject.data.alternate,
                beginoffweek: this.parentObject.data.beginoffweek,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('setuprestdaypatternsetup.day'), value: 'wday', width: '200px', sortable: false},
                { text: this.$t('setuprestdaypatternsetup.daytypedesc'), value: 'daytypedesc', width: '400px', sortable: false },
                { text: this.$t('setuprestdaypatternsetup.daytype'), value: 'daytypecode', width: '200px', sortable: false },
                { text: this.$t('setuprestdaypatternsetup.fallsongazettedhol'), value: 'gzactiontype', width: '200px', sortable: false },
                { text: this.$t('setuprestdaypatternsetup.fallsonnongazettedhol'), value: 'ngzactiontype', width: '200px', sortable: false }
            ],

            errMsg: '',
            isErr: false,
            editMode: false,
            search: this.parentObject.searchnew,
            weeks: [{text: '1',value: 1},
            {text: '2',value: 2}]
        }
    },

    methods: {          
        addItemHandler($event) {
            this.formData.patterndetail =$event
        },
            
        updateValue() {
          //  console.log('patterndetail', this.formData.patterndetail)
          if(!this.formData.alternate){
            this.formData.beginoffweek = null;
          }
            this.$emit('input', {
                id: this.formData.id,
                patterndetail: this.formData.patterndetail,
                patterncode: this.formData.patterncode,
                patterndesc: this.formData.patterndesc,
                alternate: this.formData.alternate,
                beginoffweek: this.formData.beginoffweek,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.updateValue();
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'restdaylist', params: { search: this.search}});
        },

    }
}
</script>