<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row>
                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupsocso.socsoscheme')"
                                type="text"
                                dense
                                v-model="formData.socsoid"
                                :items="socsoGroupList"
                                @change="changeSocsoGroupHandler(formData.socsoid)"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupsocso.socsoscheme')"
                                type="text"
                                dense
                                v-model="formData.socsoid"
                                :items="socsoGroupList"
                                readonly
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5">
                            <v-dialog v-model="socsogroupDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: socsoGroupItems,
                                            formTitle: `${this.$t('setupsocso.socsogroupsetup')}`,
                                            formId: 'socsogroupsetup'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveSocsoGroup="saveSocsoGroupHandler"
                                        v-on:deleteSocsoGroup="deleteSocsoGroupHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="socsogroupDialog = false"
                                        >
                                            {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.description')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.socsodesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table 
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.socsodetails
                            }"

                            v-on:input="addItemHandler"
                        />

                    </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                socsoid:    this.parentObject.data.socsoid,
                socsocode:  this.parentObject.data.socsocode,
                socsodesc:  this.parentObject.data.socsodesc,
                startdate:  this.parentObject.data.startdate,
                enddate:    this.parentObject.data.enddate,
                remark:     this.parentObject.data.remark,
                socsodetails:this.parentObject.data.socsodetails,
                stamp:      this.parentObject.data.stamp,
                userid:     this.parentObject.data.userid,
                lockstaff: this.parentObject.data.lockstaff,
            },

            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '50px', sortable: false},
                { text: this.$t('setupsocso.uptowages'), value: 'wagesamt' , width: '160px', sortable: true},
                { text: this.$t('setupepf.eeamount'), value: 'eeamt', width: '120px', sortable: true},
                { text: this.$t('setupepf.eramount'), value: 'eramt', width: '120px', sortable: true}
            ],

            selected: [],
            affectItems: [],
            socsoGroupList: [],
            socsoGroupItems: [],
            socsoGroup: [],

            errMsg: '',

            socsogroupDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadSocsoGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/socso/all'})
                .then(res => {
                    vm.socsoGroup = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.socsoGroupList.push(
                            {
                                text: res.data.rec[i].socsocode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.socsoGroupItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].socsocode,
                                desc: res.data.rec[i].socsodesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        addItemHandler($event) {
            console.log('$event', $event)
            this.formData.socsodetails=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                socsoid: this.formData.socsoid,
                socsocode: this.formData.socsocode,
                socsodesc: this.formData.socsodesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                socsodetails: this.formData.socsodetails,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveSocsoGroupHandler() {
            const vm = this;
            console.log('itemid', this.itemData.itemid)

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/socso/' + this.itemData.itemid, payload: {
                socsocode: this.itemData.itemcode,
                socsodesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp,
                userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.socsoGroup = [];
                        vm.socsoGroupItems = [];
                        vm.socsoGroupList = [];

                        this.loadSocsoGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/socso/create', payload: {
                socsocode: this.itemData.itemcode,
                socsodesc: this.itemData.itemdesc,
                userid: this.formData.userid
                }})
                .then(res => {
                    console.log('res', res)
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.socsoGroup = [];
                        vm.socsoGroupItems = [];
                        vm.socsoGroupList = [];

                        this.loadSocsoGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        console.log('err', vm.errMsg)
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteSocsoGroupHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/socso/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.socsoGroup = [];
                        vm.socsoGroupItems = [];
                        vm.socsoGroupList = [];

                        this.loadSocsoGroupListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        changeSocsoGroupHandler(id) {
            let item = this.socsoGroup.find(item => item.id === parseInt(id));
            
            this.formData.socsocode = item.socsocode;
            this.formData.socsodesc = item.socsodesc;
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'socsolist', params: { search: this.search}});
        }
    },

    mounted () {
        this.loadSocsoGroupListHandler();
        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>