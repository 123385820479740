<template>
<div>
    <div>
        <app-form
            v-bind:parentObject="{
                data: this.formData,
                pageTitle: this.pageTitle,
                errMsg: this.errMsg,
                isEdit: this.editMode,
            }"
            v-on:input="formData = $event"
            v-on:save="saveHandler"
            v-on:error="errorHandler"
        />
        
        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</div>
</template>
<script>

import AssetAssignmentForm from "../../../components/personnel/assetassignment/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    components: {
        "app-form": AssetAssignmentForm,
        "app-error": ErrorDialog,
    },
    
    data() {
        return {
            pageTitle: this.$t('recassetassignmentrec.newassetassignmentrec'),
            formData: {
                staffid: null,
                staffcode: null,
                compnyid: null,
                assetid: null,
                assettype: null,
                assetdesc: null,
                assetdid: null,
                unitno: null,
                serialno: null,
                assetitemdesc: null,
                assigndate: null,
                returndate: null,
                qty: null,
                returnconditionid: null,
                assetcondition: null,
                assetconditiondesc: null,
                assigncasestatusid: null,
                assigncasestatus: null,
                remark: null,
                stamp: null,
                userid: this.$store.getters.userId,
            },
            errMsg: "",
            isErr: false,
            editMode: false,
        };
    },
    
    methods: {
        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },
    
        saveHandler() {
            const vm = this;
            if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
                return false;
            }

            this.$store.dispatch('post', {url: "/assetassignment/create", payload: this.formData})
                .then((res) => {
                    if (res.data.error) {
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } 
                    else {
                        this.$router.push({ name: "assetassignmentlist" });
                    }
                })
                .catch((err) => {
                    vm.isErr = true;
                    vm.errMsg = err;
                });
        },
    
        cancelHandler() {
            this.$router.push({ name: "assetassignmentlist" });
        },
    },
};
</script>