    <template>
  <div>
    <v-dialog :disabled="this.posted" v-model="leaveentitlementdialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('recleaverec.partialdays')}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    outlined
                    :label="this.$t('columns.date')"
                    type="date"
                    ref="date"
                    :rules="[rules.required]"
                    v-model="selections[0].date"
                    dense
                    @change="CheckDate"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" md="6" class="mt-n8">
                  <vue-timepicker
                    auto-scroll
                    v-if="leaveentitlementdialog"
                    drop-direction="down"
                    v-model="selections[0].starttime"
                    format="HH:mm"
                    :placeholder="this.$t('reportprocessloglisting.starttime')"
                    input-class="time-picker"
                    :minute-interval="15"
                    close-on-complete
                    input-width="100%"
                  ></vue-timepicker>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" md="6" class="mt-n3">
                  <vue-timepicker
                    auto-scroll
                    v-if="leaveentitlementdialog"
                    drop-direction="up"
                    v-model="selections[0].endtime"
                    format="HH:mm"
                    :placeholder="this.$t('reportprocessloglisting.starttime')"
                    input-class="time-picker"
                    :minute-interval="15"
                    close-on-complete
                    input-width="100%"
                    @input="inputEndtimeHandler"
                  ></vue-timepicker>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-text v-if="isErr" class="mt-n12">
          <span style="font-weight: bold; color: red">{{ errMsg }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closePartialDay"
            >{{$t('button.cancel')}}</v-btn
          >
          <v-btn color="green darken-1" text @click="savePartialDay"
            >{{$t('button.save')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="this.parentObject.tabHeader"
      :items="detailItem"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="subtitle-2">{{$t('recleaverec.partialdays')}}</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<style>
.v-toolbar__content {
  padding-left: 0px;
}
.vue__time-picker {
  width: 100% !important;
}
.time-picker {
  height: 40px !important;
  width: 100% !important;
  /* border: thin solid currentColor; */
  border-radius: 5px;
}
</style>
<script>
import compareArray from "../../../util/comparearray";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "vue-timepicker": VueTimepicker,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred'),
      },

      leaveentitlementdialog: false,
      detailItem: this.parentObject.detailItem,
      startdate: this.parentObject.startdate,
      enddate: this.parentObject.enddate,
      posted: this.parentObject.posted,
      selections: [
        {
          date: "",
          starttime: "",
          endtime: "",
          partialqty: 0,
          stamp: 0,
          action: 0,
        },
      ],

      modify: false,
      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    compareArray,

    modifyItemHandler(item) {
      // assign loaded data to selections array

      if (item.action == 1) {
        this.modify = true;
      }

      if (item.action == 2) {
        this.isExisting = true;
      }

      this.selections = [
        {
          date: item.date,
          starttime: item.starttime,
          partialqty: item.partialqty,
          endtime: item.endtime,
        },
      ];

      this.leaveentitlementdialog = true;
    },

    deleteItemHandler(item) {
      let itemIndex = this.detailItem.indexOf(item);
      this.detailItem.splice(itemIndex, 1);

      for (let i = 0; i < this.detailItem.length; i++) {
        let start = this.detailItem[i].starttime.split(":");
        let end = this.detailItem[i].endtime.split(":");
        this.detailItem[i].partialqty = parseInt(end[0]) - parseInt(start[0]);
        this.detailItem[i].date = this.selections[0].date;
      }
      this.$emit("delete", this.detailItem);
    },

    closePartialDay() {
      this.leaveentitlementdialog = false;
      this.isErr = false;
      this.isExisting = false;
      this.modify = false;

      // reset the form fields
      this.resetSelections();
    },
    savePartialDay() {
      if (this.$refs.form.validate()) {
        this.isErr = false;
        // indicate value of 1 as insert, 2 as modify

        if (this.selections[0].starttime > this.selections[0].endtime) {
          this.$dialog
            .alert(this.$t('statutoryexportcommonused.endtimesmaller'))
            .then((dialog) => {
              console.log(dialog);
            });
          return false;
        }

        console.log(this.isExisting);
        console.log(this.modify);

        if (!this.isExisting && !this.modify) {
          this.selections[0].id = null;
          this.selections[0].action = 1;

          let start = this.selections[0].starttime.split(":");
          let end = this.selections[0].endtime.split(":");
          this.selections[0].partialqty = parseInt(end[0]) - parseInt(start[0]);

          this.detailItem.push(Object.assign({}, this.selections[0]));
        } else {
          for (let i = 0; i < this.detailItem.length; i++) {
            if (this.detailItem[i].date == this.selections[0].date) {
              this.detailItem[i].starttime = this.selections[0].starttime;
              this.detailItem[i].endtime = this.selections[0].endtime;

              let start = this.detailItem[i].starttime.split(":");
              let end = this.detailItem[i].endtime.split(":");
              this.detailItem[i].partialqty =
                parseInt(end[0]) - parseInt(start[0]);
              this.detailItem[i].date = this.selections[0].date;
            }
          }
        }
        console.log("detailItem", this.detailItem);

        this.$emit("input", this.detailItem);

        this.modify = false;
        this.isExisting = false;
        this.leaveentitlementdialog = false;

        // reset the form fields
        this.resetSelections();
      }
    },

    inputEndtimeHandler() {
      if (this.selections[0].starttime > this.selections[0].endtime) {
        this.$dialog
          .alert(this.$t('statutoryexportcommonused.endtimesmaller'))
          .then((dialog) => {
            console.log(dialog);
          });
      }
    },

    resetSelections() {
      this.selections = [
        {
          date: "",
          starttime: "",
          endtime: "",
          partialqty: 0,
          stamp: 0,
          action: 0,
        },
      ];
    },
    CheckDate() {
      console.log(this.startdate);
      console.log(this.enddate);
      if (this.startdate && this.enddate) {
        if (
          moment(this.selections[0].date).format("YYYY-MM-DD") <
            moment(this.startdate).format("YYYY-MM-DD") ||
          moment(this.selections[0].date).format("YYYY-MM-DD") >
            moment(this.enddate).format("YYYY-MM-DD")
        ) {
          this.$dialog
            .alert(this.$t('statutoryexportcommonused.datemustbetween'))
            .then((dialog) => {
              console.log(dialog);
            });
        }
      } else {
        this.$dialog
          .alert(this.$t('statutoryexportcommonused.datemustbetween'))
          .then((dialog) => {
            console.log(dialog);
          });
      }
    },
  },

  created() {
    this.resetSelections();
  },
};
</script>