<template>
    <div  style="height: 100%; width:75%; float: left; padding-left: 0px">
        <v-container>
            <v-form ref="form">

                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.fromdate')"
                                    type="date"
                                    ref="startdate"
                                    hide-selected
                                    v-model="formData.startdate"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-text-field>
                            </v-col>

                            <v-col cols="4" sm="4" class="pb-n5 ">
                                <v-text-field
                                    outlined
                                    :label="this.$t('columns.todate')"
                                    type="date"
                                    ref="enddate"
                                    hide-selected
                                    v-model="formData.enddate"
                                    dense
                                    :rules="[rules.required]"
                                    clearable
                                ></v-text-field>
                            </v-col>

                        </v-row>
                    </div>
                </v-card>

                <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = {
                        companyid: $event.companyid,
                        branchid: $event.branchid,
                        departmentid: $event.departmentid,
                        employmenttypeid: $event.employmenttypeid,
                        valvalueid: $event.valvalueid
                    }"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="goHandler"> 
                            {{$t('button.go')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>
    

<script>
import FilterParameter from '../../../components/common/FilterParameter';

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            pageTitle: this.$t('leavedashboard'),  

            parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                employmenttypeid: null,
                valvalueid: null
            },

            formData:{
                startdate: null,
                enddate: null,
            },

            showFilter: {             
                companyid: true,
                branchid: true,
                departmentid: true,
                employmenttypeid: true,
                valvalueid: true
            },
        }
    },

    methods: {

        goHandler(){
            if ((this.formData.startdate != null && this.formData.enddate != null))  {
                this.$router.push({name: 'leavedashboardsummary', params: { 
                    startdate: this.formData.startdate, 
                    enddate: this.formData.enddate, 
                    //parameters: this.parameters} });
                    parameters: JSON.stringify(this.parameters)} });
            } else {
                this.$refs.form.validate()
            }
        },
    },
}
</script>