<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.pageTitle }}
              <span class="page_title_value">{{
                this.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <div class="pb-3">
            <v-btn 
                color="warning" 
                style="width: 100px" 
                @click="backHandler"> 
                {{$t('button.back')}}
            </v-btn>
          </div>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >

        <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
          >{{$t('isonleave.leaveinfo')}}</v-subheader
        >

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              style="padding: 8px 12px"
              >{{$t('columns.fromdate')}}</v-col 
            >
            <v-col
              cols="4"
              sm="4"
              style="padding: 8px 12px"
              >{{ formData.startdate }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('columns.todate')}}</v-col 
            >
            <v-col
              cols="4"
              sm="4"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{ formData.enddate }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('columns.reason')}}</v-col 
            >
            <v-col
              cols="4"
              sm="4"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{ formData.reasondesc }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('columns.relperson')}}</v-col 
            >
            <v-col
              cols="4"
              sm="4"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{ formData.reliefname }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('columns.clinic')}}</v-col 
            >
            <v-col
              cols="4"
              sm="4"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{ formData.clinicdesc }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('columns.remark')}}</v-col
            >
            <v-col
              cols="4"
              sm="4"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{ formData.lvremarks }}</v-col
            >
          </v-row>

          <v-row style="padding-left: 10px">
            <v-col
              cols="2"
              sm="2"
              class="mt-n3"
              style="padding: 8px 12px"
              >{{$t('recstaffdetails.attachment')}}</v-col 
            >
            
            <div v-if="this.formData.attachment">
            
              <v-col cols="4" sm="4" class="mt-n3">
                <p>
                  <a
                    v-bind:href="this.formData.attachment"
                    v-bind:download="'Download'"
                    ><img
                    :src="`${publicPath}images/inactive_report.png`"
                    style="width: 60%">
                    </a>
                </p>
              </v-col>
          </div>

          </v-row>
          

          <v-row>
            <v-col cols="4" sm="10" class="pb-n4">
              <div>
                <v-form>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                          >{{$t('recleaverec.entry_apprvlinfo')}}</v-subheader 
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          <v-row>
                            <v-col cols="4" sm="10" class="blue--text"
                              >{{$t('recleaverec.leaveentryinfo')}}</v-col 
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('recleaverec.submittedby')}}</v-col 
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.entrybyusername }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.submissiontime')}}</v-col 
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.entrytime }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('setuptp1.source')}}</v-col 
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.src }}</v-col
                            >
                          </v-row>
                        </div>

                        <div>
                          <v-row>
                            <v-col cols="4" sm="10" class="blue--text"
                              >{{$t('recleaverec.leaveapprvlinfo')}}</v-col 
                            >
                          </v-row>
                          <div
                            v-for="(item, index) in leaveApprInfo"
                            :key="index"
                            class="pb-5"
                          >
                            <!-- <v-row>
                              <v-col cols="4" sm="4" class="mt-n3"
                                >Source</v-col
                              >
                              <v-col cols="4" sm="4" class="mt-n3">{{
                                item.
                              }}</v-col>
                            </v-row> -->
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.actiontime')}}</v-col 
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.aprdate }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.actby')}}</v-col 
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.staffcode }} -
                                {{ item.psnname }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('columns.status')}}</v-col 
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.aprstatus }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.apprvlremark')}}</v-col 
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.remark }}</v-col
                              >
                            </v-row>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

export default {

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      pageTitle: this.$route.params.item.leavedesc,
      pageTitleValue: this.$route.params.item.staffcode + ' - ' + this.$route.params.item.psnname,
      data: this.$route.params.item,

      formData: {
        id: this.$route.params.item.id,
        appsrcid: this.$route.params.appsrcid,
        aprvremark: this.$route.params.item.aprvremark,
        attachment: this.$route.params.item.attachment,
        cliniccode: this.$route.params.item.cliniccode,
        clinicdesc: this.$route.params.item.clinicdesc,
        clinicid: this.$route.params.item.clinicid,
        enddate: moment(this.$route.params.item.enddate).format('DD-MM-YYYY'),
        entrybyid: this.$route.params.item.entrybyid,
        entrybyusername: this.$route.params.item.entrybyusername,
        entrytime: moment(this.$route.params.item.entrytime).format('DD-MM-YYYY hh:mm'),
        ishistory: this.$route.params.item.ishistory,
        leavecode: this.$route.params.item.leavecode,
        leavedesc: this.$route.params.item.leavedesc,
        leavetypeid: this.$route.params.item.leavetypeid,
        lvdays: this.$route.params.item.lvdays,
        lvlstatusholder: this.$route.params.item.lvlstatusholder,
        lvremarks: this.$route.params.item.lvremarks,
        lvstatusid: this.$route.params.item.lvstatusid,
        poid: this.$route.params.item.poid,
        posted: this.$route.params.item.posted,
        psnid: this.$route.params.item.psnid,
        psnname: this.$route.params.item.psnname,
        reasoncode: this.$route.params.item.reasoncode,
        reasondesc: this.$route.params.item.reasondesc,
        reasonid: this.$route.params.item.reasonid,
        rectimestamp: this.$route.params.item.rectimestamp,
        reliefname: this.$route.params.item.reliefname,
        reliefpsnid: this.$route.params.item.reliefpsnid,
        reliefstaffcode: this.$route.params.item.reliefstaffcode,
        reliefstaffid: this.$route.params.item.reliefstaffid,
        src: this.$route.params.item.src,
        staffcode: this.$route.params.item.staffcode,
        staffid: this.$route.params.item.staffid,
        startdate: moment(this.$route.params.item.startdate).format('DD-MM-YYYY')
      },

      //arrays to store data objects from load list handlers
      leaveApprInfo: [],
    };
  },

  methods: {

    loadLeaveAprInfo() {
      if (this.formData.id) {
        this.$store.dispatch('get', {url:
              "/leaverecord/wftran/" +
              this.formData.id})
          .then((res) => {
            this.leaveApprInfo = res.data.rec;
            
            if (this.leaveApprInfo.length > 0) {
              for (var i = 0; i < this.leaveApprInfo.length; i++) {
                this.leaveApprInfo[i].aprdate = moment(
                  this.leaveApprInfo[i].aprdate
                ).format("YYYY-MM-DD HH:mm:ss");
              }
            }
          });
      }
    },

    // downloadDoc(val) {
    //   this.$store.dispatch('get(val, { responseType: 'blob' })
    //   .then(response => {
    //     const blob = new Blob([response.data], { type: 'application/pdf' })
    //     const link = document.createElement('a')
    //     link.href = URL.createObjectURL(blob)
    //     link.download = 'test'
    //     link.click()
    //     URL.revokeObjectURL(link.href)
    //   }).catch(console.error)
    // },

    backHandler() {
      this.$router.push({name: 'whoisonleavedashboard'});
    },
  },

  mounted() {
    this.loadLeaveAprInfo();
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>