<template>
    <div id="`qualificationlist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('qualificationlist.qualificationlist'),
            dataHeader: [
                { text: this.$t('columns.actions'),           value: 'action',            sortable: false},
                { text: this.$t('columns.name'),             value: 'psnname',           width: '150px'},
                { text: this.$t('qualificationlist.level'),            value: 'leveldesc',         width: '150px' },
                { text: this.$t('qualificationlist.field'),            value: 'fielddesc',         width: '150px' },
                { text: this.$t('qualificationlist.major'),            value: 'majordesc',         width: '150px' },
                { text: this.$t('qualificationlist.institution'),      value: 'institutiondesc',   width: '150px' },
                { text: this.$t('columns.startdate'),        value: 'startdate',         width: '150px' },
                { text: this.$t('columns.enddate'),          value: 'enddate',           width: '150px' },
                { text: this.$t('qualificationlist.isoversea'),        value: 'isOversea',         width: '150px' },
                { text: this.$t('qualificationlist.resultorgrade'),     value: 'resultdesc',        width: '150px' },
                { text: this.$t('qualificationlist.cgpa'),             value: 'cgpa',              width: '150px' },
                { text: this.$t('columns.remark'),           value: 'remark',            width: '200px' },
                
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        };
    },

    methods: {
        getQualificationListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/quarecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;
                    console.log('quarecord', vm.dataItems)
                    

                    for (let i = 0; i < vm.dataItems.length; i++) {

                        if (vm.dataItems[i].startdate) {
                            vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.dataItems[i].enddate) {
                            vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                        }
                    }

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'qualificationedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'qualificationnew'});
        }
    },

    mounted() {
        this.getQualificationListHandler();
    },
};
</script>