<template>
    <app-menucard/>
</template>

<script>

import MenuCard from '../../sscomponents/home/MenuCard.vue';

export default {

    components: {
        'app-menucard': MenuCard,
    },

    data() {
        return {}
    }
}

</script>