<template>
    <div>
        <v-container>
            <v-form>

                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                     
                            <v-card-text v-if="isErr">
                                <span style="font-weight: bold; color: red">{{errMsg}}</span>
                            </v-card-text>
                         
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payoutctrlList"
                                v-model="formData.payoutctrlid"
                                @change="changePayout(formData.payoutctrlid)"
                                @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                :rules="[rules.required]"
                                :items="payoutctrlList"
                                v-model="formData.payoutctrlid"
                                readonly
                                background-color="#ffeab2"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                       
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                v-model="payoutdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.year')"
                                type="number"
                                v-model="payoutyear"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.month')"
                                type="number"
                                v-model="payoutmonth"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-toolbar-title class="subtitle-2">{{$t('payeeinputprocess.expand')}}</v-toolbar-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>

                            <v-row class="pt-4">
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstartdate')"
                                        type="date"
                                        v-model="startdate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutenddate')"
                                        type="date"
                                        v-model="enddate"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutstatus')"
                                        readonly
                                        type="text"
                                        v-model="pystatusdesc"
                                        dense
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                    <input type="text" hidden v-model="pystatuscode" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('setuppayrolltype.payrolltype')"
                                        type="text"
                                        v-model="payrolltypedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payrollgroup')"
                                        type="text"
                                        v-model="grouppdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.exchangerate')"
                                        type="text"
                                        v-model="exchratedesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payoutfrequency')"
                                        type="text"
                                        v-model="salfreqdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('payrollprocessrun.payeelist')"
                                        type="text"
                                        v-model="payeelistdesc"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-row>
                        <v-col>
                            <v-toolbar-title class="pl-5 pt-4 subtitle-2">{{$t('payeeinputprocess.details')}}</v-toolbar-title>
                        </v-col>
                    </v-row>
                    

                    <v-row>
                        <v-col col="12" sm="12" class="pb4-4">
                            <app-table 
                                v-bind:parentObject = "{
                                    tabHeader: this.tabHeader,
                                    detailItem: this.formData.payeeinputdetail
                                }"
                                v-on:input="addItemHandler"
                            />
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.editMode
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                 />
            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';

import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: this.$t('payeeinputprocess.payeeinputdashboard'),
            pageTitleValue: '',
            formData: {
                id: this.parentObject.data.id,
                payoutctrlid: this.parentObject.data.payoutctrlid,
                payeeinputdetail: this.parentObject.data.payeeinputdetail,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('columns.staffid'), value: 'staffcode', width: '60px', sortable: false},
                { text: this.$t('columns.name'), value: 'psnname', width: '200px', sortable: false },
                { text: this.$t('setuppayitem.payitem'), value: 'payitemcode', width: '100px', sortable: false},
                { text: this.$t('columns.startdate'), value: 'startdate', width: '110px', sortable: false},
                { text: this.$t('columns.enddate'), value: 'enddate', width: '110px', sortable: false },
                { text: this.$t('payeeinputprocess.action'), value: 'actioncode', width: '110px', sortable: false },
                { text: this.$t('payeeinputprocess.requestedamount'), value: 'aprvamt', width: '50px', sortable: false},
                { text: this.$t('payeeinputprocess.requestedquantity'), value: 'reqqty', width: '50px', sortable: false },
                { text: this.$t('payeeinputprocess.approvedamount'), value: 'aprvamt', sortable: false, width: '50px' },
                { text: this.$t('payeeinputprocess.approvedquantity'), value: 'aprvqty', width: '50px', sortable: false},
                { text: this.$t('columns.currency'), value: 'crcycode', width: '10px', sortable: false },
                { text: this.$t('payeeinputprocess.justification'), value: 'justification', sortable: false, width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px', sortable: false},
            ],

            payoutdesc: this.parentObject.data.payoutdesc,
            startdate: this.parentObject.data.startdate,
            enddate: this.parentObject.data.enddate,
            payoutyear: this.parentObject.data.payoutyear,
            payoutmonth: this.parentObject.data.payoutmonth,
            pystatuscode: this.parentObject.data.pystatuscode,
            pystatusdesc: this.parentObject.data.pystatusdesc,
            payrolltypedesc: this.parentObject.data.payrolltypedesc,
            grouppdesc: this.parentObject.data.grouppdesc,
            exchratedesc: this.parentObject.data.exchratedesc,
            salfreqdesc: this.parentObject.data.salfreqdesc,
            payeelistdesc: this.parentObject.data.payeelistdesc,
            lockstaff: this.parentObject.data.lockstaff,
            csv: null,
            input: null,
            attach: null,
            
            payoutctrls: [],
            payoutctrlList: [],
            //search: "",
            errMsg: '',
            isErr: false,
          //  editMode: false,
            isEdit: this.parentObject.editMode,
            search: this.parentObject.searchnew
        }
    },

    methods: {

         loadStatusHandler() {
                if (this.pystatusdesc == "FINALIZED" && this.isEdit == true ) {
                    this.errMsg = this.$t('payeeinputprocess.payeeinputmsg1');
                    this.isErr = true;
                   // console.log(this.pystatusdesc)
                } else{
                    this.isErr = false;
                }
        },

        loadPayoutListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutcontrolinput/all'})
            .then (res => {
                vm.payoutctrls = res.data.rec

                for (let i = 0; i < vm.payoutctrls.length; i++) {
                    if (vm.payoutctrls[i].startdate) {
                        vm.payoutctrls[i].startdate = moment(vm.payoutctrls[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.payoutctrls[i].enddate) {
                        vm.payoutctrls[i].enddate = moment(vm.payoutctrls[i].enddate).format('YYYY-MM-DD');
                    }

                    vm.payoutctrlList.push(
                        {
                            text: vm.payoutctrls[i].payoutcode,
                            value: vm.payoutctrls[i].id
                        }
                    )
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        changePayout(id) {
            let item = this.payoutctrls.find(item => item.id == id)

            this.payoutdesc = item.payoutdesc
            this.startdate = item.startdate
            this.enddate = item.enddate
            this.payoutyear = item.pyyear
            this.payoutmonth = item.pymonth
            this.pystatuscode = item.pystatuscode
            this.pystatusdesc = item.pystatusdesc
            this.payrolltypedesc = item.payrolltypedesc
            this.grouppdesc = item.grouppdesc
            this.exchratedesc = item.exchratedesc
            this.salfreqdesc = item.salfreqdesc
            this.payeelistdesc = item.payeelistdesc
        },

        addItemHandler($event) {
            this.formData.payeeinputdetail=$event
            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                payoutctrlid: this.formData.payoutctrlid,
                pystatuscode: this.formData.pystatuscode,
                pystatusdesc: this.formData.pystatusdesc,
                payeeinputdetail: this.formData.payeeinputdetail,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'payeeinputlist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadPayoutListHandler();
        this.loadStatusHandler();

       /// console.log('statushandler', this.loadStatusHandler())

        if (this.parentObject.editMode){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>