<template>
    
    <div>

        <div v-if="isErr">
            <app-error
                v-bind:parentError="{
                    data: this.errMsg,
                    isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>

        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ pageTitle }}</h2>
                </v-row>
                    
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-card>
                        <div class="ma-5">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>

                            <v-row class="ml-1">
                                <v-col cols="4" sm="3" >
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.fromdate')"
                                        type="date"
                                        :rules="[rules.required]"
                                        v-model="formData.fromDate"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="3" >
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.untildate')"
                                        type="date"
                                        :rules="[rules.required]"
                                        v-model="formData.untilDate"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" class="pb-n5 ml-3">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    hide-selected
                                    v-model="formData.companyid"
                                    :items="companyList"
                                    dense
                                    clearable
                                ></v-autocomplete>
                                </v-col>
                            </v-row>

                        </div>
                    </v-card>

                </v-container>


                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                    >
                                    {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>

</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog"

export default {

    components: {
        'app-error': ErrorDialog
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },

            pageTitle: this.$t('attendanceenquiry'),

            formData: {
                fromDate: null,
                untilDate: null,
                companyid: null,
            },
            
            companyList: [],

            errMsg: "",
            isErr: false,
        }
    },

    methods: {

        errorHandler($event) {
            this.isErr = $event.isErr;
            this.errMsg = $event.errMsg;
        },

        loadCompany() {

            const vm = this;

            this.$store.dispatch('get', {url: "/attendanceenquiry/company"})
            .then(res => { 

                let data = res.data.rec;

                for (let i = 0; i < data.length; i++) {
                    vm.companyList.push(
                        {
                            text: data[i].compnyname,
                            value: data[i].compnycode
                        }
                    )
                }

            }).catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            })
        },

        goHandler(){

            const formData = this.formData
            const fromDate = formData.fromDate
            const untilDate = formData.untilDate
            const companyid = formData.companyid

            const vm = this

            this.$store.dispatch('post', {url: "/attendanceenquiry/validate", payload: vm.formData})
                .then(res => {
                    console.log(res.data);
                    if(res.data.error){
                        vm.isErr = !vm.isErr;
                        vm.errMsg = res.data.error;
                    } else if( fromDate != undefined && untilDate != undefined && companyid != undefined) {
                        this.$router.push({
                        name: 'attendanceenquirydetail', params: { fromDate, untilDate, companyid }
                        });
                    } else if( fromDate != undefined && untilDate != undefined ) {
                        this.$router.push({
                        name: 'attendanceenquirydetail', params: { fromDate, untilDate }
                        });
                    }
                })
                .catch(err => {
                console.log(err);    
                vm.isErr = true;
                vm.errMsg = err;
            })
        },
    },

    mounted() {
        this.loadCompany();
    },
    
}
</script>