<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import FunctionAccessForm from "../../../components/settings/functionaccess/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": FunctionAccessForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      pageTitle: this.$t('rechruserfunctionaccess.edithruserfunctionaccesscntrl'),
      pageTitleValue: "",
      formData: {
        hrUserid: null,
        secuserid: null,
        userid: this.$store.getters.userId,
        functionlist: {},
        functionkey: [],
        groupvalue: [],
        stamp: 0,
      },
      loading: true,

      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
       search: this.$route.params.search,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    loadRecord() {
      const vm = this;
      this.$store.dispatch('get', {url: "/functionaccess/record/" + vm.id})
        .then((res) => {
          vm.formData.hrUserid = res.data.rec.userid.toString();
          vm.formData.stamp = res.data.rec.stamp;
          vm.formData.functionkey = res.data.rec.key;
          vm.formData.functionlist = res.data.rec.value;
          vm.formData.lockstaff = res.data.rec.lockstaff;
          vm.pageTitleValue = res.data.rec.username;
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    newHandler() {
      this.$router.push({ name: "functionaccessnew" });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('post', {url: "/functionaccess/edit/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "functionaccesslist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url: "/functionaccess/delete/" + vm.id})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "functionaccesslist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    addItemHandler($event) {
      const vm = this;

      const payload = {
        affecttypeid: this.id,
        affectitemid: $event.id,
        userid: this.formData.userid,
      };

      this.$store.dispatch('post', {url: "/groupb/create", payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteItemHandler($event) {
      const vm = this;

      this.$store.dispatch('delete', {url:
            "/groupb/" +
            $event +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "functionaccesslist" });
    },
  },

  mounted() {
    this.loadRecord();
  },
};
</script>