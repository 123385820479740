<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>

        <v-row class="mt-12">
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('columns.fromdate')"
              type="date"
              ref="fromdate"
              v-model="formData.fromdate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-text-field
              outlined
              :label="this.$t('columns.untildate')"
              type="date"
              ref="untildate"
              v-model="formData.untildate"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5 mt-n6">
            <v-btn
              color="#fd9941"
              style="color: white; text-align: center"
              v-on:click="loadDataHandler()"
            >
              <v-icon small> mdi-reload </v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-toolbar flat>

            <v-toolbar-title>
              {{$t('processloglisting')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-avatar class="profile mr-5" size="40" tile>
            <img
                :src="`${publicPath}images/export_pdf.png`"
                @click="printPDF"
            />
            </v-avatar>

            <v-avatar class="profile" size="40" tile>
            <img
                :src="`${publicPath}images/export_csv.png`"
                @click="getCSV"
            />
            </v-avatar>

            <v-text-field
                v-model="search"
                append-icon="search"
                style="width: 20px"
                class="pr-5 pt-3"
                hide-details>
            </v-text-field>
            
        </v-toolbar>

        <v-data-table
        dense loading
        :headers="this.dataHeader"
        :search="search"
        :items="this.dataItems"
        :items-per-page="10"
        class="elevation-2 ; font-weight-light;text--secondary;"
        style="font-size:small"
        multi-sort
        fixed-header
        >
            <template v-slot:headers="dataHeader">
            <tr>
                <th v-for="header in dataHeader" :key="header.text">{{ header.text }}</th>
            </tr>
            </template>

            <template #item="{ item }">
              <tr :class="item.IsException ? 'ExceptionClass' : ''" @click="loadPageHandler(item.id)">
                <td>{{ item.id }}</td>
                <td>{{ item.processname }}</td>
                <td>{{ item.starttime }}</td>
                <td>{{ item.endtime }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.username }}</td>
              </tr>
            </template>

        </v-data-table>

  </div>
</template>


<script>
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import { font } from "../../../font/russian-language/PTSans-Regular-normal";

export default {

  components: {
    Loading,
  },

  data() {
    return {

      publicPath: process.env.BASE_URL,

      pageTitle: this.$t('processloglisting'),

      dataHeader: [
        { text: this.$t('reportprocessloglisting.id'), value: 'id'},
        { text: this.$t('reportprocessloglisting.processname'), value: 'processname'},
        { text: this.$t('reportprocessloglisting.starttime'), value: 'starttime'},
        { text: this.$t('reportprocessloglisting.endtime'), value: 'endtime'},
        { text: this.$t('columns.status'), value: "status"},
        { text: this.$t('reportprocessloglisting.username'), value: "username" },
      ],

      formData: {
        fromdate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
        untildate: new Date().toISOString().slice(0, 10),
      },

      getid: this.$route.params.id,

      search: '',
      dataItems: [],

      goBtn: false,
      loading: false,

    };
  },

  methods: {

    loadProcessLogListing(){
            
      const vm = this;

      vm.loading = true;

        this.$store.dispatch('post', {url: "/processloglisting/all", payload: {param: vm.formData} })
         .then((res) => {

            for (let i = 0; i < res.data.rec.length; i++) {

              let IsException = false;

              if (res.data.rec[i].starttime) {
                res.data.rec[i].starttime = moment(res.data.rec[i].starttime).format('YYYY-MM-DD - HH:mm:ss');
              }
              if (res.data.rec[i].endtime) {
                res.data.rec[i].endtime = moment(res.data.rec[i].endtime).format('YYYY-MM-DD - HH:mm:ss');
              }
              if (res.data.rec[i].status == "Error") {
                IsException = true;
              }

              let data = {
                id: res.data.rec[i].id,
                processname: res.data.rec[i].processname,
                starttime: res.data.rec[i].starttime,
                endtime: res.data.rec[i].endtime,
                status: res.data.rec[i].status,
                username: res.data.rec[i].username,
                IsException: IsException,
              };

              vm.dataItems.push(data);

            }

            vm.loading = false;

        })
        .catch(function (err) {
            vm.errMsg = err;
        });

    },

    loadPageHandler(value) {
        let id = value;
        this.$router.push({name: 'processloglistingdetail', params: {id}});
    },

    loadDataHandler() {

        const vm = this;
        vm.dataItems = [];

        vm.loading = true;

        this.$store.dispatch('post', {url: "/processloglisting/filterdate", payload: {param: vm.formData} })
        .then((res) => {

            for (let i = 0; i < res.data.rec.length; i++) {
              
              let IsException = false;

              if (res.data.rec[i].starttime) {
                res.data.rec[i].starttime = moment(res.data.rec[i].starttime).format('YYYY-MM-DD - HH:mm:ss');
              }
              if (res.data.rec[i].endtime) {
                res.data.rec[i].endtime = moment(res.data.rec[i].endtime).format('YYYY-MM-DD - HH:mm:ss');
              }
              if (res.data.rec[i].status == "Error") {
                IsException = true;
              }

              let data = {
                id: res.data.rec[i].id,
                processname: res.data.rec[i].processname,
                starttime: res.data.rec[i].starttime,
                endtime: res.data.rec[i].endtime,
                status: res.data.rec[i].status,
                username: res.data.rec[i].username,
                IsException: IsException,
              };

              vm.dataItems.push(data);

            }

            vm.loading = false;

        })
        .catch(function (err) {
            vm.errMsg = err;
        });

    },

    printPDF() {
       const doc = new jsPDF({
         orientation: "landscape",
       });

      //  let a = this.$t('processloglisting');
      //  const doc = new jsPDF();

      //   const myFont = `'${font}'`
      //   console.log(myFont)

      //   // // add the font to jsPDF
      //   doc.addFileToVFS("PTSans-Regular.ttf", myFont);
      //   doc.addFont("PTSans-Regular.ttf", "PTSans-Regular", "normal");
      //   doc.setFont("PTSans-Regular");

        var head = [
          { title: this.$t('reportprocessloglisting.id'), dataKey: 'id'},
          { title: this.$t('reportprocessloglisting.processname'), dataKey: 'processname'},
          { title: this.$t('reportprocessloglisting.starttime'), dataKey: "starttime"},
          { title: this.$t('reportprocessloglisting.endtime'), dataKey: "endtime"},
          { title: this.$t('columns.status'), dataKey: "status"},
          { title: this.$t('reportprocessloglisting.username'), dataKey: "username" },
        ];

        let body = [];
        for (var i = 0; i < this.dataItems.length; i++) {
          body.push({
            id: this.dataItems[i].id,
            processname: this.dataItems[i].processname,
            starttime : this.dataItems[i].starttime,
            endtime: this.dataItems[i].endtime,
            status: this.dataItems[i].status,
            username: this.dataItems[i].username,
          });
        }
      
      doc.text(this.$t('processloglisting'),10,10);
 
      doc.autoTable(head, body);
      doc.save(`${this.$t('processloglisting')}.pdf`);

      // doc.autoTable({
      //   columns:[
      //   { title: this.$t('reportprocessloglisting.id'), dataKey: 'id'},
      //   { title: this.$t('reportprocessloglisting.processname'), dataKey: 'processname'},
      //   { title: this.$t('reportprocessloglisting.starttime'), dataKey: "starttime"},
      //   { title: this.$t('reportprocessloglisting.endtime'), dataKey: "endtime"},
      //   { title: this.$t('columns.status'), dataKey: "status"},
      //   { title: this.$t('reportprocessloglisting.username'), dataKey: "username" },
      //  ],
      //   body:body,
      //   margin:{top:35},
      //   didDrawPage:function(){
      //   doc.text(a, 20, 30);
      // }})

      // doc.save(`${a}.pdf`);

    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        `${this.$t('processloglisting')}.csv`
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
         [this.$t('reportprocessloglisting.id')]: item[i].id,
         [this.$t('reportprocessloglisting.processname')]: item[i].processname,
         [this.$t('reportprocessloglisting.starttime')]: item[i].starttime,
         [this.$t('reportprocessloglisting.endtime')]: item[i].endtime,
         [this.$t('columns.status')]: item[i].status,
         [this.$t('reportprocessloglisting.username')]: item[i].username,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },

 },

  mounted() {

    this.loadProcessLogListing();
  
  },

};
</script>

<style>
.ExceptionClass td {
  color: red !important;
}
</style>