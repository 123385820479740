<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('workschedulebystaffprocess.workschedulegroupbystaffforaperiod'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.name'), value: "psnname", width: "200px" },
        { text: this.$t('columns.staffno'), value: "staffcode", width: "200px" },
        { text: this.$t('columns.company'), value: "compnyname", width: "200px" },
        { text: this.$t('columns.startdate'), value: "startdate", width: "200px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "200px" },
        { text: this.$t('attendancerecordsandprocesses.shiftcode'), value: "shiftcode", width: "200px" },
        { text: this.$t('setupforshift.shiftdescription'), value: "shiftdesc", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getWorkScheduleStaffHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/workschedulestaff/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
              if (vm.dataItems[i].startdate) {
                  vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                }

              if (vm.dataItems[i].enddate) {
                  vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                }
          }

          vm.loading = !vm.loading;

        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "workschedulestaffedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "workschedulestaffnew" });
    },
  },

  mounted() {
    this.getWorkScheduleStaffHandler();
  },
};
</script>