<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
    
                    <v-row>
                      <v-col cols="4" sm="4" class="pb-n5">
                        <v-text-field

                                outlined
                                :label="this.$t('columns.fromdate')"
                                type="date"
                                ref="fromDate"
                                :rules="[rules.required]"
                                v-model="formData.fromDate"
                                @input="updateValue"
                                dense
                        ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                        <v-text-field
                                outlined
                                :label="this.$t('columns.untildate')"
                                type="date"
                                ref="untilDate"
                                :rules="[rules.required]"
                                v-model="formData.untilDate"
                                @input="updateValue"
                                dense
                        ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('trainingsetup.trainingtargetgroup')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.trntargetgrpid"
                            dense
                            :items="targettraininggroupList"
                            clearable
                            @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>
   
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('trainingexpensesanalysisreport.coursecategory')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.trncoursecatid"
                            dense
                            :items="coursecatgroupList"
                            clearable
                            
                            @input="updateValue"
                            @change="updateValue"
                        ></v-autocomplete>
                        </v-col>

                    </v-row>

                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('trainingexpensesanalysisreport.reportingcategory')"
                                type="text"
                                ref="reportingcrcyid"
                                clearable
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.reportingcrcyid"
                                @change="changeCurrencyHandler(formData.reportingcrcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>


                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.reportingcrcycode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('payrollprocessrun.exchangerate')"
                            type="text"
                            clearable
                            :items="exchangeRateList"
                            :rules="[rules.required]"
                            v-model="formData.exchrateid"
                            @change="changeExchrateHandler(formData.exchrateid)"
                            dense
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="exchageRateCode"
                        ></v-text-field>
                    </v-col>
                </v-row>
                        </div>
                    </v-card>

                     <app-parameter 
                    class="mt-5 mb-3"
                    v-on:input="parameters = $event"
                    v-bind:parentObject="{
                        filter: this.showFilter,
                    }"
                />

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="goHandler" 
                                > 
                                {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment'
import FilterParameter from '../../../components/common/FilterParameter'

export default {
     props: {
        parentObject: Object
    },

    components: {
        'app-parameter': FilterParameter,
    },

    data() {
        return{

              rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },

            formData:{
               /// asatdate: new Date().toISOString().slice(0,10),
                fromDate: null,
                untilDate: null,
                trntargetgrpid: null,
                trncoursecatid: null,
                reportingcrcyid: null,
                reportingcrcycode: null,
                exchrateid: null,
         
                title: this.$t('trainingexpensesanalysis')
            },

              parameters: {
                companyid: null,
                branchid: null,
                departmentid: null,
                deptname: null,
                divisionid: null,
                sectionid: null,
                unitid: null,
                gradeid: null,
                employmenttypeid: null,  
              //  targettraininggroupid: null,    
                jobid: null,    
                positionid: null,   
                gradecatid: null,  
            },

            showFilter: {
                companyid: true,
                branchid: true,
                departmentid: true,
                divisionid: true,
                sectionid: true,
                unitid: true,
                gradeid: true,
                employmenttypeid: true,  
              //  targettraininggroupid: true,
                jobid: true,
                positionid: true,
                gradecatid: true,
            },

            targettraininggroupList: [],
            targettraininggroup:[],

            coursecatgroup: [],
            coursecatgroupList: [],

            currencyList: [],
            exchangeRateList: [],
            currencyCode: '',
            exchageRateCode: '',
         


            isErr: false,
            errMsg: [],
        }
    },

    methods: {

    
            loadTargetTrainingGroupListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/groupp_grouppb_training/all"})
                .then((res) => {
                vm.targettraininggroup = res.data.rec;

                for (let i = 0; i < vm.targettraininggroup.length; i++) {
                    vm.targettraininggroupList.push({
                    text: vm.targettraininggroup[i].valuedesc,
                    value: vm.targettraininggroup[i].valvalueid,
                 });
                }
                })
                .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
                });
            },

            
            loadCourseCategoryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/trainingcoursecat/all"})
                .then((res) => {
                vm.coursecatgroup = res.data.rec;

                for (let i = 0; i < vm.coursecatgroup.length; i++) {
                    vm.coursecatgroupList.push({
                    text: vm.coursecatgroup[i].coursecatname,
                    value: vm.coursecatgroup[i].id,
                    });
                }
                })
                .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
                });
            },


                 loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.reportingcrcyid) {
                        vm.changeCurrencyHandler(vm.formData.reportingcrcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.formData.reportingcrcycode = item.crcycode;
        },

         loadExchangeRate() {
            const vm = this;

            this.$store.dispatch('get', {url: '/exchrate/all'})
                .then(res => {
                    vm.exchangeRate = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.exchangeRateList.push(
                            {
                                text: res.data.rec[i].exchratedesc,
                                value: res.data.rec[i].id
                            }
                        )

                    }

                    vm.currencyLoading = false;

                    if (vm.formData.exchrateid) {
                        vm.changeExchrateHandler(vm.formData.exchrateid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeExchrateHandler(id) {
            let item = this.exchangeRate.find(item => item.id === id);

            this.exchageRateCode = item.exchratecode;
        },

         goHandler() {

            //  this.$router.push({name: 'trainingexpensesanalysisdetails'})

             const formData = this.formData
             const param = this.parameters

             if ((this.formData.fromDate != null && this.formData.untilDate != null &&
             this.formData.reportingcrcyid != null && this.formData.exchrateid != null) )  {
             

            this.$router.push({name: 'trainingexpensesanalysisdetails', params: { formData, param } });

            } else {
                this.$refs.form.validate()
            }
        }, 
        
            updateValue() {
            this.$emit("input", {

                fromDate: this.formData.fromDate,
                untilDate: this.formData.untilDate,
                trntargetgrpid: this.formData.trntargetgrpid,
                trncoursecatid: this.formData.trncoursecatid,
                reportingcrcyid: this.formData.reportingcrcyid,
                reportingcrcycode: this.formData.reportingcrcycode,
                exchrateid: this.formData.exchrateid,
                          
            });
        },
    },
    mounted() {

        this.loadTargetTrainingGroupListHandler();
        this.loadCourseCategoryListHandler();
        this.loadCurrencyListHandler();
        this.loadExchangeRate();
     
        // this.yearGeneration();
        // this.monthGeneration();
    }
}
</script>