<template>
  <div>
    <app-input-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
        isEdit: this.editMode,
        isDisabled: this.isDisabled,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>


<script>
import MappingForm from "../../../components/settings/mapping/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-input-form": MappingForm,
    "app-error": ErrorDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      pageTitle: this.$t('setupmapping.newgrpmappinginstruction'),
      errMsg: "",
      isErr: false,
      formData: {
        gtypeid: null,
        detailItem: [],
        userid: this.$store.getters.userId,
        stamp: 0,
      },
      editMode: false,
      isDisabled: false,
    };
  },
  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      vm.formData.userid = this.$store.getters.userId;
      this.$store.dispatch('post', {url: "/mapping/create", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mappinglist" });
            // this.$dialog.alert("Data Successfully Saved.").then((dialog) => {
            //   console.log(dialog);
            // });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
  mounted() {},
};
</script>