<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>

import DataAccessForm from "../../../components/settings/dataaccess/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": DataAccessForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      pageTitle: this.$t('rechruserdataaccess.edithruserdataaccesscntrl'),
      pageTitleValue: "",
      formData: {
        hrUserid: null,
        userid: this.$store.getters.userId,
        companyvalue: [],
        groupvalue: [],
        stamp: null,
      },
      recordstamp: 0,
      loading: true,
      //   deletedItems: [],
      //   insertedItems: [],
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
       search: this.$route.params.search,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    loadHrUser() {
      const vm = this;
      vm.loading = true;
      this.$store.dispatch('get', {url: "/dataaccess/hruser/" + vm.id})
        .then((res) => {
          console.log(res);
          vm.formData.hrUserid = res.data.rec[0].id.toString();
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.formData.lockstaff = res.data.rec[0].lockstaff;
          vm.pageTitleValue = res.data.rec[0].userid;
          vm.recordstamp = res.data.rec[0].stamp;
          vm.loading = false;

          console.log(vm.formData);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    loadCompany() {
      const vm = this;
      this.$store.dispatch('get', {url: "/dataaccess/company/" + vm.id})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            this.formData.companyvalue.push({
              id: res.data.rec[i].companyid,
              code: res.data.rec[i].companycode,
              desc: res.data.rec[i].companyname,
              action: 0,
            });
          }
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    loadGroup() {
      const vm = this;
      this.$store.dispatch('get', {url: "/dataaccess/group/" + vm.id})
        .then((res) => {
          for (let i = 0; i < res.data.rec.length; i++) {
            this.formData.groupvalue.push({
              id: res.data.rec[i].valvalueid,
              code: res.data.rec[i].valvaluecode,
              desc: res.data.rec[i].valvaluedesc,
              action: 0,
            });
          }
          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    newHandler() {
      this.$router.push({ name: "dataaccessnew" });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      if (vm.formData.stamp != vm.recordstamp) {
        vm.formData.stamp = vm.recordstamp;
      }

      this.$store.dispatch('post', {url: "/dataaccess/edit/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "dataaccesslist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url: "/dataaccess/delete/" + vm.id})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "dataaccesslist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    // addItemHandler($event) {
    //   const vm = this;

    //   const payload = {
    //     affecttypeid: this.id,
    //     affectitemid: $event.id,
    //     userid: this.formData.userid,
    //   };

    //   axios
    //     .post(this.$store.getters.apiURL + "/groupb/create", payload: payload)
    //     .then((res) => {
    //       if (res.data.error) {
    //         vm.isErr = !vm.isErr;
    //         vm.errMsg = res.data.error;
    //       }
    //     })
    //     .catch((err) => {
    //       vm.isErr = !vm.isErr;
    //       vm.errMsg = err;
    //     });
    // },

    // deleteItemHandler($event) {
    //   // const vm = this;

    //   // axios
    //   //   .delete(
    //   //     this.$store.getters.apiURL +
    //   //       "/groupb/" +
    //   //       $event +
    //   //       "/" +
    //   //       this.formData.userid
    //   //   )
    //   //   .then((res) => {
    //   //     if (res.data.error) {
    //   //       vm.isErr = !vm.isErr;
    //   //       vm.errMsg = res.data.error;
    //   //     }
    //   //   })
    //   //   .catch((err) => {
    //   //     vm.isErr = !vm.isErr;
    //   //     vm.errMsg = err;
    //   //   });
    // },

    cancelHandler() {
      this.$router.push({ name: "dataaccesslist" });
    },
  },

  mounted() {
    this.loadHrUser();
    this.loadCompany();
    this.loadGroup();
  },
};
</script>