<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
//import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('Setupratingscaleset.ratingscalesetlisting'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('Setupratingscaleset.ratingset'), value: "ratingscalecode", width: "200px" },
        { text: this.$t('Setupratingscaleset.ratingsetdesc'), value: "ratingscaledesc", width: "200px" },
        { text: this.$t('columns.disable'), value: "isdisable", width: "200px" },
        { text: this.$t('columns.remark'), value: "remarks", width: "200px" },

       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
       search: this.$route.params.search,
    };
  },

  methods: {
    getRatingScaleSetHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/ratingscaleset/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          console.log('data', vm.dataItems)

          vm.loading = !vm.loading;

        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "ratingscalesetedit", params: { id: item.id, search: item.search  } });
    },

    newRecordHandler() {
      this.$router.push({ name: "ratingscalesetnew" });
    },
  },

  mounted() {
    this.getRatingScaleSetHandler();
  },
};
</script>