<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import HolidayForm from "../../../components/leave/holiday/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": HolidayForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupholidaysetup.editholiday'),
      pageTitleValue: "",
      formData: [],
      headLoading: true,
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadHolidayHandler() {
      const vm = this;
 
      this.$store.dispatch('get', {url: "/holiday/" + this.id})
        .then((res) => {
          vm.formData = res.data.rec[0];
          console.log(vm.formData)
          if (res.data.rec[0].repeatday == "N") {
            vm.formData.repeatday = false;
          } else {
            vm.formData.repeatday = true;
          }

          vm.formData.userid = this.$store.getters.userId;
          vm.pageTitleValue = res.data.rec[0].holcode;

          // vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadHolidayDetailHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/holidayd/" + this.id})
        .then((res) => {
          vm.formData.holidaydetail = res.data.rec;
          
          for (let i = 0; i < res.data.rec.length; i++) {
            if (res.data.rec[i].holdate) {
              vm.formData.holidaydetail[i].holdate = moment(
                res.data.rec[i].holdate
              ).format("YYYY-MM-DD");
            }
          }

          for (let i = 0; i < vm.formData.holidaydetail.length; i++) {           
            Object.assign(vm.formData.holidaydetail[i], {
              action: 2,

            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      console.log(this.formData.id)

      this.$store.dispatch('put', {url: "/holiday/" + vm.formData.id,
          payload: vm.formData})
        
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "holidaylist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "holidaylist" });
    },

    deleteHandler() {
      const vm = this;



      this.$store.dispatch('delete', {url:
            "/holiday/" +
            vm.formData.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "holidaylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "holidaynew" });
    },
  },

  mounted() {
    this.loadHolidayHandler();
    this.loadHolidayDetailHandler();
  },
};
</script>