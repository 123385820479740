<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}<span class="page_title_value">{{ this.pageTitleValue }}</span></h2>
                </v-row>

                <v-row>
                    <div class="pb-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col>
                    <v-toolbar flat>
                        <v-toolbar-title>{{$t('payrolldashboardreport.payrollsummaryfor')}} <span class="blue--text"> {{data.formData.pyyear}}/{{data.formData.pymonth}}  </span></v-toolbar-title>
                    </v-toolbar>
                    <v-card>
                        <div class="ma-4">
                            <v-row>
                                <v-col class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="dateTitle">
                                            {{$t('reportsparam.year')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="dateContent">
                                                {{data.formData.pyyear}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="dateTitle">
                                            {{$t('reportsparam.month')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="dateContent">
                                                {{data.formData.pymonth}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n4 mb-n8 mr-n3">
                                    <v-card outlined>
                                        <v-card-title class="payoutTitle">
                                            {{$t('reportsparam.payout')}}
                                        </v-card-title>
                                        
                                        <v-card-text>
                                            <div class="payoutContent">
                                                {{payoutname}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="3" class="pb-n5 mr-n3">
                                    <v-card @click="goToDetail">
                                        <v-card-title class="paidTitle">
                                            {{$t('payrolldashboardreport.totalpaid')}}
                                        </v-card-title>
                                        <v-card-text>
                                            <div class="paidContent">
                                               {{payoutDetail.totalpaid}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n5 mr-n3">
                                    <v-card>
                                        <v-card-title class="paidTitle">
                                            {{$t('payrolldashboardreport.paxprocessed')}}
                                        </v-card-title>
                                        <v-card-text >
                                            <div class="payContent">
                                                {{totalStaff}}
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                                <v-col cols="2" class="pb-n5 ">
                                    <v-card>
                                        <!-- <v-card-title class="narTitle">
                                            N | A | R
                                        </v-card-title> -->
                                        <v-card-title class="narTitle">
                                            <v-row>
                                                <v-col class="mr-n2 ml-2">N</v-col>
                                                <v-col class="ml-n2 mr-n2">|</v-col>
                                                <v-col class="ml-n2 mr-n2">A</v-col>
                                                <v-col class="ml-n2 mr-n2">|</v-col>
                                                <v-col class="ml-n2">R</v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <div class="payContent">
                                                <!-- 1  <span class="textBlue">10</span>  11 -->
                                                <v-row>
                                                    <v-col class="mr-n2 ml-2 mt-n3">{{newJoiner}}</v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3"></v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3 textBlue">{{availableStaff}}</v-col>
                                                    <v-col class="ml-n2 mr-n2 mt-n3"></v-col>
                                                    <v-col class="ml-n2 mt-n3">{{resignStaff}}</v-col>
                                                </v-row>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row class="pt-10">
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('previewpayroll.grosspay')"
                                        type="text"
                                        ref="grossPay"
                                        hide-selected
                                        v-model="dashboardItems.grossPay"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('previewpayroll.netpay')"
                                        type="text"
                                        ref="netPay"
                                        hide-selected
                                        v-model="dashboardItems.netPay"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.companyfringe')"
                                        type="text"
                                        ref="companyFringe"
                                        hide-selected
                                        v-model="dashboardItems.companyFringe"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.basic')"
                                        type="text"
                                        ref="basic"
                                        hide-selected
                                        v-model="dashboardItems.basic"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.allowance')"
                                        type="text"
                                        ref="allowance"
                                        hide-selected
                                        v-model="dashboardItems.allowance"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.reimbursement')"
                                        type="text"
                                        ref="reimbursement"
                                        hide-selected
                                        v-model="dashboardItems.reimbursement"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.overtime')"
                                        type="text"
                                        ref="overtime"
                                        hide-selected
                                        v-model="dashboardItems.overtime"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.bonus')"
                                        type="text"
                                        ref="bonus"
                                        hide-selected
                                        v-model="dashboardItems.bonus"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.generaldeductions')"
                                        type="text"
                                        ref="generalDeductions"
                                        hide-selected
                                        v-model="dashboardItems.generalDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.staffstatutorydeductions')"
                                        type="text"
                                        ref="staffStatutoryDeductions"
                                        hide-selected
                                        v-model="dashboardItems.staffStatutoryDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-text-field
                                        :label="this.$t('payrolldashboardreport.companystatutorydeductions')"
                                        type="text"
                                        ref="companyStatutoryDeductions"
                                        hide-selected
                                        v-model="dashboardItems.companyStatutoryDeductions"
                                        dense
                                        class="custom"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </div>
                        
                    </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-4 mt-8">
                        <v-card>
                            <div>
                                <app-charts ref="realtimeChart" type="bar" :options="options" :series="series">

                                </app-charts>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="6" sm="6" class="pb-4 mt-8">
                        <v-card>
                            <div>
                                <app-charts type="donut" :options="donutOptions" :series="donutSeries">

                                </app-charts>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

                </v-container>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        'app-charts': VueApexCharts,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            pageTitle: this.$t('payrolldashboardreport.payrolldashboardsummary'),
            pageTitleValue: '',

            data: {
                formData: {
                    pyyear: this.$route.params.formData.pyyear,
                    pymonth: this.$route.params.formData.pymonth,
                    poid: this.$route.params.formData.poid,
                },

                parameters: {
                    brchid: this.$route.params.param.brchid,
                    compnyid: this.$route.params.param.compnyid,
                    deptid: this.$route.params.param.deptid,
                    emptypeid: this.$route.params.param.emptypeid,
                    gradeid: this.$route.params.param.gradeid,
                    staffid: this.$route.params.param.staffid,
                },
            },

            dashboardItemsHolder: {
                grossPay: null,
                netPay: null,
                companyFringe: null,
                basic: null,
                allowance: null,
                reimbursement: null,
                overtime: null,
                bonus: null,
                stat: null,
                ndedt: null,
                npay: null,
                generalDeductions: null,
                staffStatutoryDeductions: null,
                companyStatutoryDeductions: null,
            },
            payoutname: null,

            payoutDetail: {
                totalpaid: null,
            },

            dashboardItems: {
                grossPay: null,
                netPay: null,
                companyFringe: null,
                basic: null,
                allowance: null,
                reimbursement: null,
                overtime: null,
                bonus: null,
                stat: null,
                ndedt: null,
                npay: null,
                generalDeductions: null,
                staffStatutoryDeductions: null,
                companyStatutoryDeductions: null,
            },
            totalStaff: null,
            availableStaff: null,
            newJoiner: null,
            resignStaff: null,
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            tabHeader: [
                { text: this.$t('columns.no'), value: 'action', sortable: false, width: '5px' },
                { text: this.$t('setuppayitmetype.payitemtype'), value: 'code', width: '50px', sortable: false},
                { text: this.$t('setuppayitem.payitem'), value: 'desc', width: '200px', sortable: false },
                { text: this.$t('payslipreport.amt+ve'), value: 'action', sortable: false, width: '5px' },
                { text: this.$t('payrolldashboardreport.subttl+ve'), value: 'code', width: '50px', sortable: false},
                { text: this.$t('payslipreport.amt-ve'), value: 'desc', width: '50px', sortable: false },
                { text: this.$t('payrolldashboardreport.subttl-ve'), value: 'desc', width: '50px', sortable: false },
            ],
            options: {
                plotOptions: {
                    bar: {
                        distributed: true
                    }

                    
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: this.$t('columns.company')
                    }
                },
                yaxis: {
                    title: {
                        text: this.$t('setuppersonpayrolldirectassignment.amount')
                    },
                },
                title: {
                    text: this.$t('payrolldashboardreport.totalpaidoutbycompany')
                },
            },
            series: [{
                data: []
            }],



            donutOptions: {
                
                 chart: {
                    type: 'donut',

                    toolbar:{
                             show: true,
                             offsetX: 0,
                             offsetY: 0,

                             tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                                },

                                export : {
                                    csv: {
                                        filename: "Payroll Donut Chart",
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                        }
                                    },

                                    svg: {
                                        filename: "Payroll Donut Chart",
                                    },

                                    png: {
                                         filename: "Payroll Donut Chart",
                                    }
                                },

                                autoSelected: 'zoom' 
                        }    
                },

                labels: [
                    'Basic',
                    'Allowance',
                    'Reimbursement',
                    'Overtime',
                    'Bonus',
                    'Deductions',
                    'Statutory',
                    'Non-Payable',
                    'Non-Deductable',
                ],
            },
            donutSeries: [],
            tpbc: [],
            tpbch: [],
            
            errMsg: '',
            isErr: false,
        }
    },

    methods: {

        totalPaid() {
            const vm = this;

            let totalpaidholder = [
                vm.dashboardItemsHolder.netPay,
                vm.dashboardItemsHolder.companyFringe,
                vm.dashboardItemsHolder.staffStatutoryDeductions,
                vm.dashboardItemsHolder.companyStatutoryDeductions,
            ]

            let totalpaidsum = totalpaidholder.reduce(function(a, b) {
                return a + b;
            }, 0);

            vm.payoutDetail.totalpaid = totalpaidsum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        totalPaidbyCompany() {
            const vm = this

            let utpbch = []

            for (let i = 0; i < vm.tpbch.length; i++) {
                if (utpbch.indexOf(vm.tpbch[i]) === -1 ) utpbch.push(vm.tpbch[i])
            }

            vm.tpbc.forEach(element => {
                if (element.netpayamount) element.amount = parseFloat(element.netpayamount.replace(/,/g, ''))
                if (element.companyfringe) element.amount = parseFloat(element.companyfringe.replace(/,/g, ''))
                if (element.statutorydeduction) element.amount = parseFloat(element.statutorydeduction.replace(/,/g, ''))
                //if (element.statutorydeduction) element.amount = parseFloat(element.statutorydeduction.replace(/,/g, ''))
            });

            for (let i = 0; i < utpbch.length; i++) {
                let val = []
                
                vm.tpbc.forEach(element => {
                    if (utpbch[i] == element.compnydesc) {
                        val.push(element.amount)
                    }
                });

                let totalpaidsum = val.reduce(function(a, b) {
                    return a + b;
                }, 0);

                vm.series[0].data.push(totalpaidsum.toFixed(2))
                vm.options.xaxis.categories.push(utpbch[i])
            }
        },

        loadPayoutControl() {
            const vm = this;

            this.$store.dispatch('get', {url: "/payoutctrl/" + vm.data.formData.poid})
            .then((res) => {
                
                let payout = res.data.rec[0];
                vm.payoutname = payout.payoutdesc
                vm.data.formData.pyyear = payout.pyyear
                vm.data.formData.pymonth = payout.pymonth
            })
            .catch((err) => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
        },

        loadDonut() {
            const vm = this;
            
            setTimeout(() => {
                vm.donutSeries = [
                    vm.dashboardItemsHolder.basic,
                    vm.dashboardItemsHolder.allowance,
                    vm.dashboardItemsHolder.reimbursement,
                    vm.dashboardItemsHolder.overtime,
                    vm.dashboardItemsHolder.bonus,
                    vm.dashboardItemsHolder.generalDeductions,
                    vm.dashboardItemsHolder.stat,
                    vm.dashboardItemsHolder.ndedt,
                    vm.dashboardItemsHolder.npay,
                ]
                this.totalPaid();
                this.totalPaidbyCompany();
                this.updateSeriesLine();
            }, 1500);
        },

        updateSeriesLine() {
            this.$refs.realtimeChart.updateSeries([{
                data: this.series[0].data,
            }], false, true);
        },

        loadNewJoiner() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/newjoiner/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let newJoinerHolder = res.data.rec;
                vm.newJoiner = newJoinerHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadResign() {
            const vm = this;
            
            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/resign/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let resignHolder = res.data.rec;
                vm.resignStaff = resignHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
            
        },

        loadTotalStaff() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/totalstaff/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                let totalStaffHolder = res.data.rec;
                vm.totalStaff = totalStaffHolder.length
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        
        },

        loadAvailableStaff() {
            const vm = this;
            vm.availableStaff = vm.totalStaff - vm.newJoiner - vm.resignStaff
        },

        loadDelay() {
            setTimeout(() => {
                this.loadNewJoiner();
                this.loadResign();
            }, 300);
            setTimeout(() => {
                this.loadAvailableStaff();
            }, 800);
        },

        loadGrossPay() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/grosspay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/grosspay/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let grossPay = res.data.rec;
                    let grossPayHolder = []

                    grossPay.forEach(element => {
                        grossPayHolder.push(parseFloat(element.grosspayamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.grossPay = grossPayHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.grossPay = vm.dashboardItemsHolder.grossPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                } else {
                    vm.dashboardItemsHolder.grossPay = 0
                    vm.dashboardItems.grossPay =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadNetPay() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/netpay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/netpay/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let netPay = res.data.rec;
                    let netPayHolder = []
                    

                    netPay.forEach(element => {
                        netPayHolder.push(parseFloat(element.netpayamount.replace(/,/g, '')))
                        if (element.compnydesc) vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.netPay = netPayHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.netPay = vm.dashboardItemsHolder.netPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                } else {
                    vm.dashboardItemsHolder.netPay = 0
                    vm.dashboardItems.netPay =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadCompanyFringe() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/companyfringe ', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/companyfringe/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let companyFringe = res.data.rec;
                    let companyFringeHolder = []

                    companyFringe.forEach(element => {
                        companyFringeHolder.push(parseFloat(element.companyfringe.replace(/,/g, '')))
                        if (element.compnydesc) vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.companyFringe = companyFringeHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.companyFringe = vm.dashboardItemsHolder.companyFringe.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.companyFringe = 0
                    vm.dashboardItems.companyFringe =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadBasic() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/basic', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/basic/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let basic = res.data.rec;
                    let basicHolder = []

                    basic.forEach(element => {
                        basicHolder.push(parseFloat(element.basicamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.basic = basicHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.basic = vm.dashboardItemsHolder.basic.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.basic = 0
                    vm.dashboardItems.basic =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadAllowance() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/allowance', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/allowance/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let allowance = res.data.rec;
                    let allowanceHolder = []

                    allowance.forEach(element => {
                        allowanceHolder.push(parseFloat(element.allowanceamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.allowance = allowanceHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.allowance = vm.dashboardItemsHolder.allowance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.allowance = 0
                    vm.dashboardItems.allowance =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadReimbursement() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/reimbursement', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/reimbursement/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let reimbursement = res.data.rec;
                    let reimbursementHolder = []

                    reimbursement.forEach(element => {
                        reimbursementHolder.push(parseFloat(element.reimbursementamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.reimbursement = reimbursementHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.reimbursement = vm.dashboardItemsHolder.reimbursement.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.reimbursement = 0
                    vm.dashboardItems.reimbursement =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadOvertime() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/overtime', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/overtime/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let overtime = res.data.rec;
                    let overtimeHolder = []

                    overtime.forEach(element => {
                        overtimeHolder.push(parseFloat(element.overtimeamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.overtime = overtimeHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.overtime = vm.dashboardItemsHolder.overtime.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.overtime = 0
                    vm.dashboardItems.overtime =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadBonus() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/bonus', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/bonus/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let bonus = res.data.rec;
                    let bonusHolder = []

                    bonus.forEach(element => {
                        bonusHolder.push(parseFloat(element.bonusamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.bonus = bonusHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.bonus = vm.dashboardItemsHolder.bonus.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.bonus = 0
                    vm.dashboardItems.bonus =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadGeneralDeduction() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/generaldeduction', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/generaldeduction/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let generalDeductions = res.data.rec;
                    let generalDeductionsHolder = []

                    generalDeductions.forEach(element => {
                        generalDeductionsHolder.push(parseFloat(element.generaldeduction.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.generalDeductions = generalDeductionsHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.generalDeductions = vm.dashboardItemsHolder.generalDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.generalDeductions = 0
                    vm.dashboardItems.generalDeductions =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadStatutoryDeductionStaff() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/statutorydeductionstaff', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/statutorydeductionstaff/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let statutoryDeductionsStaff = res.data.rec;
                    let statutoryDeductionsStaffHolder = []

                    statutoryDeductionsStaff.forEach(element => {
                        statutoryDeductionsStaffHolder.push(parseFloat(element.statutorydeduction.replace(/,/g, '')))
                        if (element.compnydesc) vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.staffStatutoryDeductions = statutoryDeductionsStaffHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.staffStatutoryDeductions = vm.dashboardItemsHolder.staffStatutoryDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.staffStatutoryDeductions = 0
                    vm.dashboardItems.staffStatutoryDeductions =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadStatutoryDeductionCompany() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/statutorydeductioncompany', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/statutorydeductioncompany/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let statutoryDeductionsCompany = res.data.rec;
                    let statutoryDeductionsCompanyHolder = []

                    statutoryDeductionsCompany.forEach(element => {
                        statutoryDeductionsCompanyHolder.push(parseFloat(element.statutorydeduction.replace(/,/g, '')))
                        vm.tpbch.push(element.compnydesc)
                        vm.tpbc.push(element)
                    });

                    vm.dashboardItemsHolder.companyStatutoryDeductions = statutoryDeductionsCompanyHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.companyStatutoryDeductions = vm.dashboardItemsHolder.companyStatutoryDeductions.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.companyStatutoryDeductions = 0
                    vm.dashboardItems.companyStatutoryDeductions =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadStat() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/stat', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/stat/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let stat = res.data.rec;
                    let statHolder = []

                    stat.forEach(element => {
                        statHolder.push(parseFloat(element.statamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.stat = statHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.stat = vm.dashboardItemsHolder.stat.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.stat = 0
                    vm.dashboardItems.stat =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadNPay() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/npay', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/npay/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let npay = res.data.rec;
                    let npayHolder = []

                    npay.forEach(element => {
                        npayHolder.push(parseFloat(element.npayamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.npay = npayHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.npay = vm.dashboardItemsHolder.npay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.npay = 0
                    vm.dashboardItems.npay =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadNDedt() {
            const vm = this;

            //this.$store.dispatch('post', {url: '/payrolldashboard/basic', payload: this.data})
            this.$store.dispatch('post', {url: `/payrolldashboard/ndedt/${this.$store.getters.userId}`, payload: this.data})
            .then(function (res) {
                if (res.data.rec.length > 0) {
                    let ndedt = res.data.rec;
                    let ndedtHolder = []

                    ndedt.forEach(element => {
                        ndedtHolder.push(parseFloat(element.ndedtamount.replace(/,/g, '')))
                    });

                    vm.dashboardItemsHolder.ndedt = ndedtHolder.reduce(function(a, b) {
                        return a + b;
                    }, 0);

                    vm.dashboardItems.ndedt = vm.dashboardItemsHolder.ndedt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    
                } else {
                    vm.dashboardItemsHolder.ndedt = 0
                    vm.dashboardItems.ndedt =  '0.00'
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        goToDetail() {
            let formData = this.data.formData
            let param = this.data.parameters

            this.$router.push({name: 'payrolldashboarddetail', params: { formData, param }});
        },

        backHandler() {
            let formData = this.data.formData
            let param = this.data.parameters

            this.$router.push({name: 'payrolldashboardfilter', params: { formData, param } });
        },
    },

    mounted() {
        this.loadPayoutControl();
        this.loadGrossPay();
        this.loadNetPay();
        this.loadBasic();
        this.loadCompanyFringe();
        this.loadAllowance();
        this.loadReimbursement();
        this.loadOvertime();
        this.loadBonus();
        this.loadStat();
        this.loadNPay();
        this.loadNDedt();
        this.loadStatutoryDeductionStaff();
        this.loadStatutoryDeductionCompany();
        this.loadGeneralDeduction();
        this.loadDonut();
        this.loadTotalStaff();
        this.loadDelay();
    }
    
}
</script>

<style scoped>
.dateTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}
.dateContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    margin: 0px;
    min-height: 60px;
}
.payoutTitle {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: grey;
    align-content: center;
    justify-content: center;
}
.payoutContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}
.narTitle {
    align-content: center;
    justify-content: center;
    font-size: 12px;
    max-height: 30px;
}
.paidTitle {
    align-content: center;
    justify-content: left;
    font-size: 12px;
    max-height: 30px;
}
.paidContent {
    font-weight: 700;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    margin: 0px;
    min-height: 60px;
}
.payContent {
    font-weight: 700;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #980000;
    margin: 0px;
    min-height: 60px;
}
.textBlue {
    color:  #004080;
}
.text1 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#004080;
}
.text2 {
    width: 133px;
    height: 27px;
    font-family: Lato-Bold;
    font-size: 19px;
    color:#653565;
}
</style>