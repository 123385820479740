<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('mmtaxperiodsetup.taxperiod')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.taxperiod"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('trainingsetup.startyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.startyear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                            @input="updateValue"
                            @change="monthdifference"
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupclaim.startmonth')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.startmonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                            @input="updateValue"
                            @change="monthdifference"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('mmtaxperiodsetup.endyear')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.endyear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            @input="updateValue"
                            @change="monthdifference"
                            clearable
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('mmtaxperiodsetup.endmonth')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.endmonth"
                            dense
                            :items="monthList2"
                            clearable
                            :rules="[rules.required]"
                            @input="updateValue"
                            @change="monthdifference"
                        ></v-autocomplete>
                        </v-col>

                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmtaxperiodsetup.fiscalyear')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.months"
                                background-color="#ffeab2"
                                @input="updateValue"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                  
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmtaxperiodsetup.thresoldmonth')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.thresholdmth"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('mmtaxperiodsetup.thresoldamount')"
                                type="text"
                                v-model="formData.thresholdamt"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        parentObject: Object
    },

    components: {

    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
                taxperiod: this.parentObject.data.taxperiod,
                startyear: this.parentObject.data.startyear,
                startmonth: this.parentObject.data.startmonth,
                endyear: this.parentObject.data.endyear,
                endmonth: this.parentObject.data.endmonth,
                months: this.parentObject.data.months,
                thresholdamt: this.parentObject.data.thresholdamt,
                thresholdmth: this.parentObject.data.thresholdmth,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            yearList:[],
            monthList: [],
            monthList2: [],

            date1: '',
            date2: '',

            selected: [],
            subDialog: false,
            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },

        monthGeneration2() {
            for (let i = 1; i <= 12; i++) {
                this.monthList2.push(i)
            }
        },
        
        
        daysInMonth(month, year) {
          return new Date(month, year, 0).getDate();
        },
        
        monthdifference(){
            if (this.formData.startyear && this.formData.startmonth && this.formData.endyear && this.formData.endmonth){
                let dateHolder1 = this.formData.startyear + "-" + this.padRJ(this.formData.startmonth, 2) + "-" + this.padRJ(1, 2);
                //let dateHolder2 = this.formData.endyear + "-" + this.padRJ(this.formData.endmonth, 2) + "-" + this.padRJ(1, 2);
                let dateHolder2 = this.formData.endyear + "-" + this.padRJ(this.formData.endmonth, 2) + "-" + this.daysInMonth(this.formData.endmonth, this.formData.endyear);

                let date1 = moment(dateHolder1).format("MM-DD-YYYY");
                let date2 = new Date(dateHolder2)

                //to get number of months between the two dates.
                let start = new Date(date1);
                let end = new Date(date2);
                // console.log('dateholder1', dateHolder1)
                // console.log('date1', date1)
                // console.log('startdate', start)
                // console.log('enddate', end)

                const diffTime = Math.abs(end - start);
                const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30)) ;

                this.formData.months =  diffMonths - 1;
                
            } else {
                this.formData.months = '';
            }
            this.updateValue();
    },


    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

        updateValue() {
            this.$emit('input', {
                 taxperiod: this.formData.taxperiod,
                startyear: this.formData.startyear,
                startmonth: this.formData.startmonth,
                endyear: this.formData.endyear,
                endmonth: this.formData.endmonth,
                months: this.formData.months,
                thresholdamt: this.formData.thresholdamt,
                thresholdmth: this.formData.thresholdmth,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            console.log('formdt', this.formData)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        
        cancelHandler() {
            this.$router.push({name: 'mmtaxperiodsetuplist', params: { search: this.search}});
        },
    },

    mounted() {
        this.yearGeneration();
        this.monthGeneration();
        this.monthGeneration2();
    }
}
</script>