<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle }}
            <span class="page_title_value">{{
              this.parentObject.pageTtileValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row v-if="!this.formData.lockstaff">
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                @input="updateValue"
                :items="staffDetailsList"
                @change="changeStaffIDandCompanyHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                readonly
                :items="staffDetailsList"
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.staffid')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffcode"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                v-model="formData.compnyname"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="4" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.vendorcategory')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.vendorcatid"
                dense
                @input="updateValue"
                :items="vendorcatItemsList"
                @change="changeVendorCatCodeHandler(formData.vendorcatid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.vendorcatcode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonstatutorynumberinfo.refno')"
                type="text"
                v-model="formData.refno"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                ref="startdate"
                :rules="[rules.required]"
                v-model="formData.startdate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                ref="enddate"
                :rules="[rules.required]"
                v-model="formData.enddate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonstatutorynumberinfo.spouserefno')"
                type="text"
                v-model="formData.spouserefno"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="4" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setuppersonstatutorynumberinfo.spousebranch')"
                type="text"
                v-model="formData.spousebranchid"
                clearable
                dense
                @input="updateValue"
                :items="branchItemsList"
                @change="changeSpouseBranchCodeHandler(formData.spousebranchid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.spousebranchcode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                ref="remark"
                v-model="formData.remark"
                @input="updateValue"
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                :label="this.$t('setuppersonstatutorynumberinfo.assignment')"
                ref="assignment"
                disabled
                @input="updateValue"
                dense
                class="custom"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencecode')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.refcodeid"
                dense
                @input="updateValue"
                :items="refnumItemsList"
                @change="changeVendorDetails(formData.refcodeid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
              <v-text-field
                outlined
                :label="this.$t('setupcompanyreferencenumber.vendor')"
                type="text"
                v-model="formData.refcode_vendordesc"
                dense
                @input="updateValue"
                background-color="#ffeab2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setupcompanyreferencenumber.vendorcategory')"
                type="text"
                v-model="formData.refcode_vendorcatdesc"
                dense
                @input="updateValue"
                background-color="#ffeab2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonstatutorynumberinfo.vendorcompany')"
                type="text"
                v-model="formData.refcode_compnyname"
                dense
                @input="updateValue"
                background-color="#ffeab2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="8" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonstatutorynumberinfo.vendoraddress')"
                type="text"
                v-model="formData.refcode_vendoraddr"
                dense
                @input="updateValue"
                background-color="#ffeab2"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      formData: {
        psnid: this.parentObject.data.psnid,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnycode: this.parentObject.data.compnycode,
        compnyname: this.parentObject.data.compnyname,
        vendorcatid: this.parentObject.data.vendorcatid,
        vendorcatcode: this.parentObject.data.vendorcatcode,
        refno: this.parentObject.data.refno,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        spouserefno: this.parentObject.data.spouserefno,
        spousebranchid: this.parentObject.data.spousebranchid,
        spousebranchcode: this.parentObject.data.spousebranchcode,
        remark: this.parentObject.data.remark,
        refcodeid: this.parentObject.data.refcodeid,
        refcode_vendordesc: this.parentObject.data.refcode_vendordesc,
        refcode_vendorcatdesc: this.parentObject.data.refcode_vendorcatdesc,
        refcode_compnyname: this.parentObject.data.refcode_compnyname,
        refcode_vendoraddr: this.parentObject.data.refcode_vendoraddr,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,

        //additional line
        userid: this.parentObject.data.userid,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 200 || "Max 200 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },

      //arrays used to store data returned from db --> load handlers
      vendorcats: [],
      vendorcatItemsList: [],
      staffdetails: [],
      staffDetailsList: [],
      refnums: [],
      refnumItemsList: [],
      branches: [],
      branchItemsList: [],
      companiesitemsList: [],
      companies: [],
      person: [],

      //var to show or hide refcode_vendordesc, refcode_vendordesc cat, refcode_vendordesc company, vendoor addr
      showVendor: false,
      showVendorCat: false,
      showVendorComp: false,
      showVendorAddr: false,
      loading: false,
      isEdit: this.parentObject.isEdit,
      isErr: false,
      errMsg: "",
      search: this.parentObject.searchnew
    };
  },

  methods: {
    // load list handlers

    // load person list
    loadPersonListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/person/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.person = res.data.rec;
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load vendorcats list
    loadVendorCatListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/vendorcategory/all"})
        .then((res) => {
          //push response in vendorcats
          vm.vendorcats = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.vendorcatItemsList.push({
              //push id and desc for "vendorcatid" v-autocomplete field
              text: res.data.rec[i].categorydesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          // failed to respond --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load staff details list
    loadStaffDetailsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load ref num list
    loadRefNumListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/refnum/all"})
        .then((res) => {
          //push response in vendorcats
          vm.refnums = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.refnumItemsList.push({
              //push id and namne for "psnid" v-autocomplete field
              text: res.data.rec[i].refcode,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          // failed to respond --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load branches list
    loadBranchListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/vendor/all"})
        .then((res) => {
          //push response in vendorcats
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchItemsList.push({
              //push id and namne for "psnid" v-autocomplete field
              text: res.data.rec[i].vendordesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          // failed to respond --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load companies list
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companiesitemsList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //change staffid and compnyid after selectin psnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyname = item.compnyname;
      this.formData.compnyid = item.compnyid;
      this.changeRefno();
    },

    //change ref no
    changeRefno() {
      if (
        this.formData.psnid &&
        (this.formData.vendorcatid == 1 || this.formData.vendorcatid == 3)
      ) {
        let item = this.person.find((item) => item.id === this.formData.psnid);

        this.formData.refno = item.idno.split("-").join("");
      } else {
        this.formData.refno = null;
      }
    },

    //change refcode_vendorcatdesc code after selecting refcode_vendorcatdesc
    changeVendorCatCodeHandler(id) {
      let item = this.vendorcats.find((item) => item.id == id);

      this.formData.vendorcatcode = item.categorycode;
      this.changeRefno();
    },

    //change branch code afte r selectring branchc
    changeSpouseBranchCodeHandler(id) {
      let item = this.branches.find((item) => item.id == id);

      this.formData.spousebranchcode = item.vendorcode;
    },

    //change refcode_vendordesc details fields after selecting refcodeid
    changeVendorDetails(id) {
      let item = this.refnums.find((item) => item.id == id);

      this.formData.refcode_vendordesc = item.vendordesc;
      this.formData.refcode_vendorcatdesc = item.categorydesc;
      this.formData.refcode_compnyname = item.compnyname;

      var vendaddr = "";
      if (item.vendoraddr1 != null) {
        vendaddr += item.vendoraddr1;
      }

      if (item.vendoraddr2 != null) {
        vendaddr += item.vendoraddr2;
      }

      if (item.vendoraddr3 != null) {
        vendaddr += item.vendoraddr3;
      }
      this.formData.refcode_vendoraddr = vendaddr;
    },

    //change refcode_vendordesc category code after selecting category
    changeCategoryHandler(id) {
      let item = this.catitems.find((item) => item.id == id);
      //alert(item.code);
      this.formData.categorycode = item.code;
    },

    updateValue() {
      this.$emit("input", {
        psnid: this.formData.psnid,
        staffid: this.formData.staffid,
        compnyid: this.formData.compnyid,
        vendorcatid: this.formData.vendorcatid,
        vendorcatcode: this.formData.vendorcatcode,
        refno: this.formData.refno,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        spouserefno: this.formData.spouserefno,
        spousebranchid: this.formData.spousebranchid,
        spousebranchcode: this.formData.spousebranchcode,
        remark: this.formData.remark,
        refcodeid: this.formData.refcodeid,
        refcode_vendordesc: this.formData.refcode_vendordesc,
        refcode_vendorcatdesc: this.formData.refcode_vendorcatdesc,
        refcode_compnyname: this.formData.refcode_compnyname,
        refcode_vendoraddr: this.formData.refcode_vendoraddr,
        lockstaff: this.formData.lockstaff,
        search: this.formData.search,
        stamp: this.formData.stamp,

        //addtional line
        userid: this.formData.userid,
      });
    },

    checkdigit() {
      let refno = this.formData.refno.split("SG").join("");
      refno = refno.split("OG").join("");
      refno = refno.split("IG").join("");
      refno = refno.split("-").join("");

      if (refno.length == 11) {
        let partrefno = this.padRJ(refno.substring(8, 10), 2);
        let getrefno = this.checkRef(refno);

        if (partrefno != getrefno) {
          this.isErr = true;
          this.errMsg = "The reference number failed the check digit";
        } else {
          this.$emit("save");
        }
      } else {
        this.isErr = true;
        this.errMsg = "The reference number is not valid";
      }
    },

    checkRef(refno) {
      let getrefno = [];
      let J = 0;
      let m = 0;
      let m1 = 0;
      let u = 0;

      for (let i = 0; i < 8; i++) {
        getrefno.push(refno[i] * (9 - i));
      }

      J = getrefno.reduce(function (a, b) {
        return a + b;
      }, 0);

      m = J / 11;

      m1 = J - Math.floor(m) * 11;

      u = 11 - m1;

      if (u == 11) {
        return 0;
      } else {
        return this.padRJ(u.toString(), 2);
      }
    },

    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      console.log("vendorcatid", this.formData.vendorcatid);
      if (this.formData.vendorcatid == 4 && this.formData.refno) {
        this.checkdigit();
      } else {
        console.log("escape");
        this.$emit("save");
      }
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "personstatutoryinfolist", params: { search: this.search} });
    },
  },

  mounted() {
    this.loadVendorCatListHandler();
    this.loadStaffDetailsListHandler();
    this.loadRefNumListHandler();
    this.loadBranchListHandler();
    this.loadPersonListHandler();
    //this.loadCompaniesListHandler();

    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>


<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>

