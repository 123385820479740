<template>
    <div>
        <v-container>
            <v-form>
               <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="12" sm="6" class="blue--text ml-2">
                        {{$t('postpayroll.leavepostinglog')}}
                    </v-col>
                </v-row>

                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" class="orange lighten-5">
                            <v-row>
                                <v-col cols="4" sm="4">
                                    <v-text-field
                                        :label="this.$t('reportlog.processed')"
                                        type="text"
                                        v-model="listErr.processedCount"
                                        class="custom"
                                        readonly
                                        dense
                                        background-color="#FFF3E0"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4">
                                    <v-text-field
                                        :label="this.$t('reportlog.staffprocessed')"
                                        type="text"
                                        v-model="listErr.staffprocessedCount"
                                        class="custom"
                                        readonly
                                        dense
                                        background-color="#FFF3E0"
                                    ></v-text-field>
                                </v-col>
                            
                                <v-col cols="4" sm="4">
                                    <v-text-field
                                        :label="this.$t('reportlog.posted')"
                                        type="text"
                                        v-model="listErr.totalPosted"
                                        class="custom"
                                        readonly
                                        dense
                                        background-color="#FFF3E0"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4">
                                    <v-text-field
                                        :label="this.$t('reportlog.unposted')"
                                        type="text"
                                        v-model="listErr.totalUnPosted"
                                        class="custom"
                                        readonly
                                        dense
                                        background-color="#FFF3E0"
                                    ></v-text-field>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>

                </v-container>
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="returnHandler"> 
                            {{$t('button.return')}}
                        </v-btn>
                    </div>
                </v-row>

            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
//import jsPDF from 'jspdf';
//import 'jspdf-autotable';

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required')
            },
            pageTitle: this.$t('postpayroll.leavelog'),

            listErr: {
                processedCount: 0,
                staffprocessedCount: 0,
                totalPosted: 0,
                totalUnPosted: 0
            },
            item: this.$route.params.item,
            errMsg: '',
            isErr: false,
        }
    },

    methods: {
        getError() {
            if (this.item) {
                if (this.item.totalPosted) this.listErr.totalPosted = this.item.totalPosted
                if (this.item.totalUnPosted) this.listErr.totalUnPosted = this.item.totalUnPosted
                if (this.item.staffprocessedCount) this.listErr.staffprocessedCount = this.item.staffprocessedCount
                if (this.item.processedCount) this.listErr.processedCount = this.item.processedCount
            }
        },

        returnHandler() {
            this.$router.push({name: 'leavepayrollposting'});
        },

    },

    mounted() {
        this.getError();
    }
}
</script>