<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">
                        {{ this.parentObject.pageTitle }}
                        <span class="page_title_value">{{
                            this.parentObject.pageTitleValue
                        }}</span>
                    </h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="8" sm="9" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.workflowtype')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.wftypeid"
                                dense
                                clearable
                                :items="workflowTypeList"
                                @input="updateValue"
                                @change="
                                    changewftypeHandler(
                                        formData.wftypeid
                                    )
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="8" sm="9" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.workflowtype')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.wftypeid"
                                dense
                                :items="workflowTypeList"
                                readonly
                                clearable
                                background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="9" class="pb-n5 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('payeeinputprocess.details')"
                                type="text"
                                ref="detailid"
                                v-model="formData.detailid"
                                dense
                                :items="detailsList"
                                @input="updateValue"
                                @change="changeDetailsCodeHandler(formData.detailid)"
                                clearable
                                :disabled="wfdetailCheck == false"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="8" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="formData.detailcode"
                                :disabled="wfdetailCheck == false"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject="{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.workflowdetails,
                                wftypeid: this.formData.wftypeid,
                                detailid: this.formData.detailid
                            }"
                            v-on:input="addItemHandler"
                        />
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.parentObject.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </v-form>
        </v-container>
    </div>
</template>

<script>

import CRUDTable from "./CRUDTable";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-table": CRUDTable
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                }
            },

            formData: {
                wftypeid: this.parentObject.data.wftypeid,
                detailid: this.parentObject.data.detailid,
                detailcode: this.parentObject.data.detailcode,
                lockstaff: this.parentObject.data.lockstaff,
                workflowdetails: this.parentObject.data.workflowdetails,
                 search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },
            tabHeader: [
                {
                    text: this.$t('columns.code'),
                    value: "action",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: this.$t('columns.company'),
                    value: "compnyname",
                    sortable: false,
                    width: "200px"
                },
                {
                    text: this.$t('setupapprvlworkflow.nooflevels'),
                    value: "levelno",
                    sortable: false,
                    width: "110px"
                },
                {
                    text: this.$t('setupapprvlworkflow.autoapproved'),
                    value: "autoapr",
                    sortable: false,
                    width: "120px"
                },
                {
                    text: this.$t('setupapprvlworkflow.emailnoti'),
                    value: "email",
                    sortable: false,
                    width: "135px"
                },
                {
                    text: this.$t('setupapprvlworkflow.onlyhrapproval'),
                    value: "hronly",
                    sortable: false,
                    width: "135px"
                },
                {
                    text: this.$t('setupapprvlworkflow.firstreminderhour'),
                    value: "remindone",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: this.$t('setupapprvlworkflow.subsequentreminderhours'),
                    value: "remindnext",
                    sortable: false,
                    width: "200px"
                },
                {
                    text: this.$t('setupapprvlworkflow.maxreminder'),
                    value: "maxreminder",
                    sortable: false,
                    width: "200px"
                },
                {
                    text: this.$t('setupapprvlworkflow.dueaction'),
                    value: "wfdueaction",
                    sortable: false,
                    width: "200px"
                },
                {
                    text: this.$t('setupapprvlworkflow.hrasfinalapprover'),
                    value: "hrlast",
                    sortable: false,
                    width: "170px"
                },
            ],
            selections: [],

            workflowTypeItems: [],
            workflowTypeList: [],
            detailsItems: [],
            detailsList: [],
            wfdetailCheck: false,

            subDialog: false,
            errMsg: "",
            isErr: false,
            isErrTrigger: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        };
    },

    methods: {
        loadWorkflowTypeHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/workflow/type"})
                .then(res => {
                    vm.workflowTypeItems = res.data.rec;
                    
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.workflowTypeList.push({
                            text: res.data.rec[i].wftypedesc,
                            value: res.data.rec[i].id
                        });
                    }

                    //   if (this.formData.wftypeid) {
                    //    this.changewftypeHandler(this.formData.wftypeid);
                    // }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        changeDetailsListHandler(id) {
            const vm = this;
            vm.detailsList = []
            vm.detailsItems = []
            // this.formData.detailid = null
            this.formData.detailid = null
            this.formData.detailcode = null
            
            if (id == 3) {
                this.$store.dispatch('get', {url: "/leavetype/all"})
                    .then(res => {
                        vm.detailsItems = res.data.rec;
                        console.log('detailitems', vm.detailsItems)
                        
                        for (let i = 0; i < res.data.rec.length; i++) {
                            vm.detailsList.push({
                                text: res.data.rec[i].leavedesc,
                                value: res.data.rec[i].id
                            });
                        }
                        // this.changeDetailsCodeHandler(this.formData.detailid)
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                    });
            }

            if (id == 7) {
                this.$store.dispatch('get', {url: "/claimtypesweb/all"})
                    .then(res => {
                        vm.detailsItems = res.data.rec;
                        // console.log('detailItems', vm.detailsItems)
                        
                        for (let i = 0; i < res.data.rec.length; i++) {
                            vm.detailsList.push({
                                text: res.data.rec[i].clmtypedesc,
                                value: res.data.rec[i].id
                            });
                        }
                        // this.changeDetailsCodeHandler(this.formData.detailid)
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                    });
            }

            // if (id == 1 || id == 2) {
            //     vm.detailsItems = [];
            //     vm.detailsList = [];

            //     vm.formData.detailid = null;
            //     vm.formData.detailcode = null;
            // }
            
            // if (id == 3 || id == 4) {
            //     axios.get(`${this.$store.getters.apiURL}/leavetype/all`)
            //         .then(res => {
            //             vm.detailsItems = res.data.rec;
                        
            //             for (let i = 0; i < res.data.rec.length; i++) {
            //                 vm.detailsList.push({
            //                     text: res.data.rec[i].leavedesc,
            //                     value: res.data.rec[i].id
            //                 });
            //             }
            //         })
            //         .catch(err => {
            //             vm.isErr = true;
            //             vm.errMsg = err;
            //             vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            //         });
            // }
        },

        changeDetailsCodeHandler(id) {
            // console.log('formData', this.formData)
            let item = this.detailsItems.find(item => item.id == id);
            if (id == undefined) {
                this.formData.detailcode = null
                return
            }
            if (this.formData.wftypeid == 3) this.formData.detailcode = item.leavecode;
            if (this.formData.wftypeid == 7) this.formData.detailcode = item.clmtype;
        },

        changewftypeHandler(id) {
            if (id == undefined) {
                const vm = this;
                vm.detailsList = []
                vm.detailsItems = []
                this.formData.detailcode = null
            }
            else if (id == 3 || id == 7) {
                let item = this.workflowTypeItems.find(item => item.id === id);
                //if (item.id == 3 || item.id == 7) 
                this.wfdetailCheck = true
                this.changeDetailsListHandler(item.id);
            } else {
                this.wfdetailCheck = false
                this.changeDetailsListHandler(null);
            }
        },

        addItemHandler($event) {
            this.formData.workflowdetails = $event;

            this.updateValue();
        },

        updateValue() {
            this.$emit("input", {
                wftypeid: this.formData.wftypeid,
                detailid: this.formData.detailid,
                lockstaff: this.formData.lockstaff,
                workflowdetails: this.formData.workflowdetails,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit("new");
        },

        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "workflowlist",  params: { search: this.search} });
        }
    },

    mounted() {
        this.loadWorkflowTypeHandler();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
};
</script>