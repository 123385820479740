<template>
    <div>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
      ></loading>
      <v-container>
        <v-form>
          <v-row>
            <h2 class="pb-5">{{ this.parentObject.pageTitle }}</h2>
          </v-row>
  
          <v-container
            id="scroll-target"
            class="custom-form overflow-y-auto px-5"
          >
            <v-toolbar-title class="subtitle-2">{{$t('statutoryexportcommonused.noparamter')}}</v-toolbar-title>
          </v-container>
  
          <v-row>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="backHandler">
                {{$t('button.back')}}
              </v-btn>
            </div>
            <div class="pt-5 pl-4">
              <v-btn color="warning" style="width: 100px" @click="getExportListHandler">
                {{$t('button.export')}}
              </v-btn>
            </div>
          </v-row>
        </v-form>
      </v-container>
      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  import XLSX from 'xlsx/xlsx';
  
  import ErrorDialog from "../../../components/common/ErrorDialog";
  
  export default {
    props: {
      parentObject: Object,
    },
  
    components: {
      "app-error": ErrorDialog,
      Loading,
    },
  
    data() {
      return {
        rules: {
          required: (value) => !!value || this.$t('reportsparam.required'),
        },
        //pageTitle: 'MBB-EMAS-01',
  
        id: this.parentObject.id,
        data: this.parentObject.data,
        formData: {
          psnname: '',
          psnno: '',
          statecode: '',
          pindicator: '',
          seqno: ''
        },
        date_of_period: null,
        salstatutoryother: null,
        psnstatutoryinfo: [],
        psnpayroll: [],
        titleData: this.parentObject.data.ssbsubrecpy[0].payoutdesc,
        statecodeList: [
          {
              text: 'Johor',
              value: '01',
          },
          {
              text: 'Kedah',
              value: '02',
          },
          {
              text: 'Kelantan',
              value: '03',
          },
          {
              text: 'Melaka',
              value: '04',
          },
          {
              text: 'Negeri Sembila',
              value: '05',
          },
          {
              text: 'Pahang',
              value: '06',
          },
          {
              text: 'Pulau Pinang',
              value: '07',
          },
          {
              text: 'Perak',
              value: '08',
          },
          {
              text: 'Perlis',
              value: '09',
          },
          {
              text: 'Selangor',
              value: '10',
          },
          {
              text: 'Terengganu',
              value: '11',
          },
          {
              text: 'Sabah',
              value: '12',
          },
          {
              text: 'Sarawak',
              value: '13',
          },
          {
              text: 'Wilayah Persekutuan (Kuala Lumpur)',
              value: '14',
          },
          {
              text: 'Wilayah Persekutuan (Labuan)',
              value: '15',
          },
          {
              text: 'Wilayah Persekutuan (Putrajaya)',
              value: '16',
          }
        ],
  
        companyrecnum: "",
        bankcode: "011",
        receivingbank: "",
        paymentref: "",
        paymentdesc: "",
        addr: "",
        filler: "",
        totalAmtStfpcb: null,
        totalAmtCp38: null,
        totalRefno: null,
  
        accErr: [],
        negErrMsg: [],
        salhdErr: [],
        gettotalAmt: [],
        ssbdata: [],
        ssbdataholder: [],
        loading: false,
  
        processedCount: null,
        successCount: null,
        errCount: null,
        exCount: null,
        errMsg: "",
        isErr: false,
        isEdit: this.parentObject.isEdit,
      };
    },
  
    methods: {
      
      getExportListHandler() {
        this.loading = true;
        const vm = this
  
        let dateHolder2 = this.data.year + "-" + this.pad(this.data.month, 2) + "-" + this.daysInMonth(this.data.month, this.data.year);
        let date = moment(dateHolder2).format("YYYY-MM-DD");
        this.date_of_period = moment(dateHolder2).format("DD-MM-YYYY");
        
        this.$store.dispatch('get', {
            url:  `/mmssbexport/all/${this.$store.getters.userId}`
            + "/" +  this.data.ssbexprecid
            + "/" + this.data.refnum + "/" + date
            + "/" + this.data.year + "/" + this.data.month
        })
        .then(function (res) {
          vm.loading = false
          vm.ssbdataholder = res.data.rec;
          
          vm.exportHandler();
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      },
  
      async getData() {
        const vm = this
        let body = []
        let newssbdetail = []
        let dataholder = vm.ssbdataholder
        let ssbdetail = dataholder //this.filterData(dataholder);
  
        this.processedCount = dataholder.length;
        this.successCount = ssbdetail.length;
        this.errCount = dataholder.length - ssbdetail.length;
        // let refnumholder = vm.data.ssbsubrecref[0].refnum.replace(/\D/g, "");
  
        // let header = {
        //     "Month": this.data.month,
        //     "Year": this.data.year,
        // }
  
        ssbdetail.forEach(function (o) {
          var existing = newssbdetail.filter(function (i) {
            return i.staffcode === o.staffcode;
          })[0];
          if (!existing || existing == undefined) {
            newssbdetail.push(o);
          } else {
            existing.stfpcb += o.stfpcb;
            existing.cp38 += o.cp38;
          }
        });
        
        let subheader = {
          compnyname: newssbdetail[0].compnyname ? newssbdetail[0].compnyname : '',
          refnum: newssbdetail[0].refnum,
        }

        for (let i = 0; i < newssbdetail.length; i++) {
          body.push({
            "စဥ်": i+1,
            "အာမခံထားသူအမည်": newssbdetail[i].psnname,
            "အာမခံစီစစ်ရေးအမှတ်": newssbdetail[i].refno,
            "လုပ်ခလစာ": newssbdetail[i].affectedamt,
            "ကျန်းမာရေး နှင့်လူမှုရေး စောင့်ရှောက်မှုအာမခံစနစ် အလုပ်ရှင်": newssbdetail[i].hscis_er,
            "ကျန်းမာရေး နှင့်လူမှုရေး စောင့်ရှောက်မှုအာမခံစနစ် အလုပ်သမား": newssbdetail[i].ssbwk,
            "အလုပ်တွင်ထိခိုက်မှု အကျိုးခံစားခွင့် အာမခံစနစ် အလုပ်ရှင်": newssbdetail[i].eibis_er,
            "စုစုပေါင်း အလုပ်ရှင် ": newssbdetail[i].ssber,
            "စုစုပေါင်း အလုပ်သမား": newssbdetail[i].ssbwk,
            "စုစုပေါင်း စုစုပေါင်း": newssbdetail[i].total,
            "မှတ်ချက်": '',
            'A':'',
            'B':'',
            'C':''
          });
        }

        if (body.length > 0) {
          const ws =  XLSX.utils.json_to_sheet((body), { origin: 'A7'/*, skipHeader: true*/});
          const wb = XLSX.utils.book_new();
          const merge = [
            { s: { r: 5, c: 0 }, e: { r: 6, c: 0 }},
            { s: { r: 5, c: 1 }, e: { r: 6, c: 1 }},
            { s: { r: 5, c: 2 }, e: { r: 6, c: 2 }},
            { s: { r: 5, c: 3 }, e: { r: 6, c: 3 }},
            { s: { r: 5, c: 6 }, e: { r: 6, c: 6 }},
            { s: { r: 5, c: 10 }, e: { r: 6, c: 10 }},
            { s: { r: 5, c: 4 }, e: { r: 5, c: 5 }},
            { s: { r: 5, c: 7 }, e: { r: 5, c: 9 }}
          ];
          ws["!merges"] = merge;

          ws['M1'] = {t: 's', v: 'နည်းဥပဒေ ၆၆(က)၊ (ပုံစံ-၁၃)'};
          ws['G2'] = {t: 's', v: 'လူမှုဖူလုံရေးအဖွဲ့'};
          ws['E3'] = {t: 's', v: 'အာမခံထားသူများအတွက် လစဥ်ထည့်၀င်ကြေးပေးသွင်းငွေစာရင်း'};
          ws['A4'] = {t: 's', v: 'အလုပ်ဌာနအမည်'};
          ws['C4'] = {t: 's', v: subheader.compnyname};
          ws['K4'] = {t: 's', v: 'အလုပ်ဌာနအမှတ်ပုံတင်အမှတ်'};
          ws['N4'] = {t: 's', v: subheader.refnum};
          
          ws['A6'] = {t: 's', v: 'စဥ်'};
          ws['B6'] = {t: 's', v: 'အာမခံထားသူအမည်'};
          ws['C6'] = {t: 's', v: 'အာမခံစီစစ်ရေးအမှတ်'};
          ws['D6'] = {t: 's', v: 'လုပ်ခလစာ'};
          ws['E6'] = {t: 's', v: 'ကျန်းမာရေး နှင့်လူမှုရေး စောင့်ရှောက်မှုအာမခံစနစ်'};
          ws['E7'] = {t: 's', v: 'အလုပ်ရှင်'};
          ws['F7'] = {t: 's', v: 'အလုပ်သမား'};
          ws['G6'] = {t: 's', v: 'အလုပ်တွင်ထိခိုက်မှု အကျိုးခံစားခွင့် အာမခံစနစ် အလုပ်ရှင်'};
          ws['H6'] = {t: 's', v: 'စုစုပေါင်း'};
          ws['H7'] = {t: 's', v: 'အလုပ်ရှင်'};
          ws['I7'] = {t: 's', v: 'အလုပ်သမား'};
          ws['J7'] = {t: 's', v: 'စုစုပေါင်း'};
          ws['K6'] = {t: 's', v: 'မှတ်ချက်'};
          ws['L7'] = {t: 's', v: ''};
          ws['M7'] = {t: 's', v: ''};
          ws['N7'] = {t: 's', v: ''};
          console.log('wb', wb)
          XLSX.utils.book_append_sheet(wb, ws,  this.titleData);
          XLSX.writeFile(wb, this.titleData + '.xlsx');
        }
  
        // if (body.length > 0) {
        //   const csvData = this.objectToCSV(header, subheader, body);
        //   this.download(csvData, this.titleData);
        // }
      },
  
      filterData(item) {
        let checkdet = this.detailfilter(item)
        let checkactive = this.activefilter(checkdet)
        let checkpayment = this.paymentfilter(checkactive)
        return checkpayment
      },
  
      detailfilter(item) {
          let dateHolder = this.data.year + "-" + this.pad(this.data.month, 2) + "-" 
          + this.daysInMonth(this.data.month, this.data.year);
          let date = moment(dateHolder).format("YYYY-MM-DD");
          let dataitem = item.filter(e => e.refnum == this.data.refnum)
          dataitem = dataitem.filter(e => {
              return moment(e.startdate).format("YYYY-MM-DD") <= date && date <= moment(e.enddate).format("YYYY-MM-DD")
          })
          let noacc = item.filter((e) => !dataitem.includes(e));
          for (let i = 0; i < noacc.length; i++) {
              noacc[i].errormsg = this.$t('setupreport_exportmapping.nodetailinpersonstat');
              this.accErr.push(noacc[i]);
          }
          return dataitem
      },
  
      activefilter(item) {
        let data = item.filter(e => e.pystatcode == 'ACTIVE')
        let notactive = item.filter((e) => !data.includes(e));
        for (let i = 0; i < notactive.length; i++) {
          notactive[i].errormsg = this.$t('setupreport_exportmapping.paymentisnotactive');
          this.accErr.push(notactive[i]);
        }
        return data
      },
  
      paymentfilter(item) {
        let data = item.filter(e => {
            return e.stfpcb != 0 || e.cp38 != 0 
        })
        let nopayment = item.filter((e) => !data.includes(e));
        for (let i = 0; i < nopayment.length; i++) {
          nopayment[i].errormsg = this.$t('setupreport_exportmapping.nopayment');
          this.accErr.push(nopayment[i]);
        }
        return data
      },
  
      objectToCSV(header, subheader, data) {
          const csvRows = []
  
          csvRows.push(',,,,,,,,,,,,,,နည်းဥပဒေ ၆၆(က)၊ (ပုံစံ-၁၃)');
          csvRows.push(',,,,,,လူမှုဖူလုံရေးအဖွဲ့');
          csvRows.push(',,,,,အာမခံထားသူများအတွက် လစဥ်ထည့်၀င်ကြေးပေးသွင်းငွေစာရင်း');
          csvRows.push(`အလုပ်ဌာနအမည်,,${subheader.compnyname},,,,,,,,,,အလုပ်ဌာနအမှတ်ပုံတင်အမှတ်,,${subheader.refnum}`);
  
          csvRows.push([])
  
          // get header
          const headers = Object.keys(data[0])
          csvRows.push(headers.join(','))
  
          // loop over the rows
          for (const row of data) {
              const values = headers.map(header => {
                  const escaped = ('' + row[header]).replace(/"/g, '\\"')
                  return `"${escaped}"`
              })
              csvRows.push(values.join(','))
          }
          return csvRows.join('\n')
      },
  
      download(data, title) {
        const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
  
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", title + ".csv");
  
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
  
      pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
      },
  
      exportHandler() {
        this.getData();
        let item = [];
        item.totalAmtstfpcb = this.totalAmtStfpcb;
        item.totalAmtcp38 = this.totalAmtCp38;
        item.accounterr = this.accErr;
        item.successCount = this.successCount;
        item.processedCount = this.processedCount;
        item.errCount = this.errCount;
  
        if (!this.isErr) {
          this.$router.push({ name: "mmmonthlyssbexportlog", params: { item } });
        }
      },
  
      backHandler() {
        this.$router.push({ name: "mmmonthlyssbexport" });
      },
  
      daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
      },
    },
  };
  </script>