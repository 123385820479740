<template>
    <div>
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="150"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
        <v-container fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
            <v-form>

               
                <v-row justify="center" align="center">
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>
                
                  <v-row justify="center" align="center">
                        <v-col cols="1" >
                            {{$t('reportsparam.filter')}} 
                        
                        <v-col class="page_title_value">
                            <div v-if="emptypedescholder"> {{ this.emptypedescholder }} </div>
                            <div v-if="compnynameholder"> {{ this.compnynameholder }} </div>
                            <div v-if="brchnameholder"> {{ this.brchnameholder }} </div>
                            <div v-if="deptnameholder"> {{ this.deptnameholder }} </div>
                            <div v-if="valuedescholder"> {{ this.valuedescholder }} </div>
                            <div v-if="sectionameholder"> {{ this.sectionameholder }} </div>
                            <div v-if="divisionameholder"> {{ this.divisionameholder }} </div>
                            <div v-if="unitnameholder"> {{ this.unitnameholder }} </div>
                            <div v-if="gradenameholder"> {{ this.gradenameholder }} </div>
                        
                        </v-col>
                        </v-col>
                </v-row>
           

                 <v-row>
                    <div >
                        <!-- v-if="hidetable" class="pt-5 mt-n8"> -->
                        <v-row>
                            <v-col>
                                <v-btn 
                                    v-model="goBtn"
                                    color="warning" 
                                    style="width: 100px; margin-bottom: 20px;"
                                    @click="backHandler" 
                                    > 
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                </v-row>
            
            <v-container 
            fluid id="scroll-target" 
            class="custom-form overflow-y-auto px-5"
            >

                <v-row justify="center" align="center" >

                    <v-col cols="12">
                        <v-card class="d-flex justify-center" elevation="5" outlined> 
                            <div v-if="!loading">
                                <apexchart    
                                    ref="piechart" 
                                    type="pie" 
                                    width="250%" 
                                    :options="piechartOptions" 
                                    :series="pieseries">
                                </apexchart>
                            </div>
                        </v-card>
                    </v-col>
                </v-row> 
         

                       <v-row v-if="!hidetable">
                            <div class="col-sm-12">
                                <v-toolbar flat>
                                    
                                    <v-toolbar-title>{{$t('stafflistforclaimutilizationforperiod')}} </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-avatar class="mr-5" size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_pdf.png`"
                                                @click="printPDF"
                                                >
                                        </v-avatar>

                                        <v-avatar size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_csv.png`"
                                                @click="getCSV"
                                               >
                                        </v-avatar>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            style="width: 250px"
                                            class="pr-5 pt-3"
                                            hide-details>
                                        </v-text-field>
                                    </v-toolbar-items>
                                </v-toolbar>
                            
                        </div>
                       </v-row>

                         <v-row v-if="!hidetable">
                                <v-col >
                                <v-data-table
                                    :search="search"
                                    :headers="dataHeader"
                                    :items="dataItems"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                    no-data-text="No records found for the selected claim utilization period."
                                >

                                </v-data-table>
                                </v-col>
                            </v-row>

                        <v-row v-if="!hide">
                            <div class="col-sm-12">
                               <v-toolbar flat>
                                    
                                    <v-toolbar-title>{{$t('stafflistforclaimutilizationforperiodbyclaimtype')}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-avatar class="mr-5" size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_pdf.png`"
                                                @click="printPDF2"
                                                >
                                        </v-avatar>

                                        <v-avatar size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_csv.png`"
                                                @click="getCSV2"
                                               >
                                        </v-avatar>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            style="width: 250px"
                                            class="pr-5 pt-3"
                                            hide-details>
                                        </v-text-field>
                                    </v-toolbar-items>
                                </v-toolbar>
                            
                        </div>
                       </v-row>
                             
                        <v-row v-if="!hide">
                            <v-col >
                                <v-data-table
                                    :search="search"
                                    :headers="dataHeader2"
                                    :items="chartItems"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                    no-data-text="No records found for the selected claim utilization period."
                                >
                                </v-data-table>
                                </v-col>
                            </v-row>                     
            </v-container>
            </v-form>
        </v-container>    
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import Loading from "vue-loading-overlay";

export default {
    components: {
        'apexchart': VueApexCharts,
        Loading
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
           
            pageTitle: this.$t('claimutilizationfrom') + this.$route.params.formData.fromDate + this.$t('until') + this.$route.params.formData.untilDate,
            pageTitleValue: "",
            search: '',

            data: {
                formData: {
                    fromDate: this.$route.params.formData.fromDate,
                    untilDate: this.$route.params.formData.untilDate,
                    crcyid: this.$route.params.formData.crcyid,
                    exchrateid: this.$route.params.formData.exchrateid,
                    userid: this.$store.getters.userId,
                },

                  parameters: {
                    brchid: this.$route.params.param.branchid,
                    compnyid: this.$route.params.param.companyid,
                    deptid: this.$route.params.param.departmentid,
                    divnid: this.$route.params.param.divisionid,
                    sectioid: this.$route.params.param.sectionid,
                    unitid: this.$route.params.param.unitid,
                    gradeid: this.$route.params.param.gradeid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    claimgroupid: this.$route.params.param.claimgroupid, 
                },

            },

            dataItems: [],
            piedata:[],
            chartItems: [],

            parameter: null,

            //for pie chart
            pieseries: [],
            piechartOptions: {
                    chart: {
                        locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                        defaultLocale: this.$route.params.lang,  
                        type: 'pie',
                        events: {
                            dataPointSelection: (event, chartContext, config) => {
                            console.log(config.dataPointIndex)
                            this.claimChartTypeHeader(config.dataPointIndex)
                        }
                        },

                        toolbar:{
                             show: true,
                             offsetX: 0,
                             offsetY: 0,

                             tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                                },

                                export : {
                                    csv: {
                                        filename: this.$t('claimutilizationforperiod'),
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                        }
                                    },

                                    svg: {
                                        filename: this.$t('claimutilizationforperiod'),
                                    },

                                    png: {
                                         filename: this.$t('claimutilizationforperiod'),
                                    }
                                },

                                autoSelected: 'zoom' 
                        }
                    },
                    title: {
                        // text: "Claim Utilization for Period",
                        // margin: 20,
                        // align: 'center',
                        // style: {
                        //     fontsize: '14px',
                        //     color: ' #004080',
                        //     fontfamily: 'Lato-Bold'
                        // },
                    },
                    plotOptions: {
                        customScale: 1.5
                    },
                    labels: [],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            },
                        }
                    }],
                },

            //table header 
            dataHeader: [
                    { text: this.$t('statutoryexportcommonused.no'), width: "7%", value: "no" },
                    { text: this.$t('columns.name'), value: 'staffname', width: '300px' },
                    { text: this.$t('columns.staffid'), value: 'staffno', width: '100px' },
                    { text: this.$t('claimreport.claimtype'), value: 'clmtypdesc', width: '200px' },
                    { text: this.$t('claimrecordlisting.receiptdate'), value: 'receiptdate', width: '200px' },
                    { text: this.$t('payeeinputprocess.approvedamount'), value: 'aprvamt', width: '150px' },
                    { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                    { text: this.$t('columns.branch'), value: 'brchname', width: '200px' },
                    { text: this.$t('columns.division'), value: 'divnname', width: '200px' },
                    { text: this.$t('columns.department'), value: 'deptname', width: '200px' },
                    { text: this.$t('columns.section'), value: 'sectioname', width: '200px' },
                    { text: this.$t('columns.unit'), value: 'unitname', width: '200px' },
                    { text: this.$t('columns.position'), value: 'posdesc', width: '200px' },
                    { text: this.$t('columns.grade'), value: 'grddesc', width: '200px' },
            ],

              dataHeader2: [
                    { text: this.$t('statutoryexportcommonused.no'), width: "7%", value: "no" },
                    { text: this.$t('columns.name'), value: 'staffname', width: '300px' },
                    { text: this.$t('columns.staffid'), value: 'staffno', width: '100px' },
                    { text: this.$t('claimreport.claimtype'), value: 'clmtypdesc', width: '200px' },
                    { text: this.$t('claimrecordlisting.receiptdate'), value: 'receiptdate', width: '200px' },
                    { text: this.$t('payeeinputprocess.approvedamount'), value: 'aprvamt', width: '150px' },
                    { text: this.$t('columns.company'), value: 'compnyname', width: '200px' },
                    { text: this.$t('columns.branch'), value: 'brchname', width: '200px' },
                    { text: this.$t('columns.division'), value: 'divnname', width: '200px' },
                    { text: this.$t('columns.department'), value: 'deptname', width: '200px' },
                    { text: this.$t('columns.section'), value: 'sectioname', width: '200px' },
                    { text: this.$t('columns.unit'), value: 'unitname', width: '200px' },
                    { text: this.$t('columns.position'), value: 'posdesc', width: '200px' },
                    { text: this.$t('columns.grade'), value: 'grddesc', width: '200px' },
                   
             ],

            //load error message variable
            isErr: false,
            loading: true,
            checkloading:true,

            //hide or show charts & tables variables
            hide : true,
            hidetable: true,
            goBtn: true,
            title: this.$t('reportheadcountanalysis.stafflistfor'),
            // fromDate: '',
            // untilDate: '',
            // crcyid: '',
            // exchrateid: '',
            clmtypeid: '',
            clmtypdesc: '',
            emptypedescholder: null,
            compnynameholder: null,
            brchnameholder: null,
            deptnameholder: null,
            valuedescholder: null,
            sectionameholder: null,
            divisionameholder: null,
            unitnameholder: null,
            gradenameholder: null,
        }
    },

    methods: {

        employmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/emptype/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.emptypeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.emptypeid)[0]
                        vm.emptypedescholder = itemholder.emptypedesc
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          companyHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/company/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.compnyid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.compnyid)[0]
                        vm.compnynameholder = itemholder.compnyname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        branchHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/branch/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.brchid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.brchid)[0]
                        vm.brchnameholder = itemholder.brchname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        departmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/department/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.deptid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.deptid)[0]
                        vm.deptnameholder = itemholder.deptname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        valvalueHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/valvalue/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.claimgroupid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.claimgroupid)[0]
                        vm.valuedescholder = itemholder.valuedesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        sectionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/emptype/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.sectioid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.sectioid)[0]
                        vm.sectionameholder = itemholder.sectioname
                    }
                    
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

          unitHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/company/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.unitid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.unitid)[0]
                        vm.unitnameholder = itemholder.unitname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        divisionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/branch/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.divnid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.divnid)[0]
                        vm.divisionameholder = itemholder.divnname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        gradeHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: "/grade/all"})
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradeid)[0]
                        vm.gradenameholder = itemholder.grddesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

    //load pie chart 
        claimChart(){

           // this.goBtn = true;
            const vm = this;
            
            vm.loading = true

             //get data for pie chart
             this.$store.dispatch('post', {
                 url: `/claimutilizationperiod/${this.$store.getters.userId}`, 
                 payload: this.data})
            .then(res => {

             vm.piedata = res.data.rec;

            
             let pieapiData = res.data.rec;

             vm.loading = false

            console.log('data', this.data)
            console.log('piedata', vm.piedata)
            
            
            pieapiData.forEach(element => {

                vm.piechartOptions.labels.push(element.clmtypdesc);
                vm.pieseries.push(element.total);

                console.log("element", element)
               
                 console.log('labels',vm.piechartOptions.labels)
                 console.log('series',vm.pieseries)

            })
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

          claimChartHeader(){
            const vm = this; 

             //get data for pie chart - gender distribution
             this.$store.dispatch('post', {
                 url: `/claimutilizationperioddetail/${this.$store.getters.userId}`, 
                 payload: this.data})
            .then(res => {

            vm.dataItems = res.data.rec;

            console.log('data', vm.dataItems)

                  vm.title = this.$t('stafflistofclaimutilizationforperiod')

    
            for (let i = 0; i < vm.dataItems.length; i++) {
                    vm.dataItems[i].no = i + 1;
                    vm.dataItems[i].receiptdate = moment(vm.dataItems[i].receiptdate).format("DD-MM-YYYY")
                }

                vm.hidetable = false;
                vm.hide = true;
 
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

        claimChartTypeHeader(dataPointIndex){
            const vm = this; 

             this.clmtypeid = this.piedata[dataPointIndex].clmtypeid;
             this.clmtypdesc = this.piedata[dataPointIndex].clmtypdesc;
 
             //get data for pie chart - gender distribution
             this.$store.dispatch('get', {
                 url: `/claimutilizationtype/${this.$store.getters.userId}`+ "/" + this.data.formData.fromDate
             + "/" + this.data.formData.untilDate + "/" + this.data.formData.crcyid + "/" + this.data.formData.exchrateid + "/" + vm.clmtypeid})
            .then(res => {

            vm.chartItems = res.data.rec;
   
             for (let i = 0; i < vm.chartItems.length; i++) {
                     vm.chartItems[i].no = i + 1;
                     vm.chartItems[i].receiptdate = moment(vm.chartItems[i].receiptdate).format("DD-MM-YYYY")
                 }

                 vm.title = this.$t('reportheadcountanalysis.stafflistfor') + vm.clmtypdesc;

                 //show table 
                    vm.hide = false;
                    vm.hidetable = true;
                    vm.checkloading = false; 
 
            })
            .catch(err => {
                //error - unsucessfull request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },
       

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        // get CSV for tabHeader

        async getCSV() {
            let item = this.dataItems;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [`${this.$t('statutoryexportcommonused.no')}`]: item[i].no,
                    [`${this.$t('columns.name')}`]: item[i].staffname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffno,
                    [`${this.$t('claimreport.claimtype')}`]: item[i].clmtypdesc,
                    [`${this.$t('claimrecordlisting.receiptdate')}`]: item[i].receiptdate,
                    [`${this.$t('payeeinputprocess.approvedamount')}`]: item[i].aprvamt,
                    [`${this.$t('columns.company')}`]: item[i].compnyname,
                    [`${this.$t('columns.branch')}`]: item[i].brchname,
                    [`${this.$t('columns.division')}`]: item[i].divnname,
                    [`${this.$t('columns.department')}`]: item[i].deptname,
                    [`${this.$t('columns.section')}`]: item[i].sectioname,
                    [`${this.$t('columns.unit')}`]: item[i].unitname,
                    [`${this.$t('columns.position')}`]: item[i].posdesc,
                    [`${this.$t('columns.grade')}`]: item[i].grddesc,
         
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // get CSV fortabHeader2

        async getCSV2() {
            let item = this.chartItems;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [`${this.$t('statutoryexportcommonused.no')}`]: item[i].no,
                    [`${this.$t('columns.name')}`]: item[i].staffname,
                    [`${this.$t('columns.staffid')}`]: item[i].staffno,
                    [`${this.$t('claimreport.claimtype')}`]: item[i].clmtypdesc,
                    [`${this.$t('claimrecordlisting.receiptdate')}`]: item[i].receiptdate,
                    [`${this.$t('payeeinputprocess.approvedamount')}`]: item[i].aprvamt,
                    [`${this.$t('columns.company')}`]: item[i].compnyname,
                    [`${this.$t('columns.branch')}`]: item[i].brchname,
                    [`${this.$t('columns.division')}`]: item[i].divnname,
                    [`${this.$t('columns.department')}`]: item[i].deptname,
                    [`${this.$t('columns.section')}`]: item[i].sectioname,
                    [`${this.$t('columns.unit')}`]: item[i].unitname,
                    [`${this.$t('columns.position')}`]: item[i].posdesc,
                    [`${this.$t('columns.grade')}`]: item[i].grddesc,
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // print PDF for tabheader
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
           // let item = this.positionassignmentdetailsassigned

            var head = [  
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },              
                { title: this.$t('columns.name'), dataKey: 'staffname' },
                { title: this.$t('columns.staffid'), dataKey: 'staffno' },
                { title: this.$t('claimreport.claimtype'), dataKey: 'clmtypdesc' },
                { title: this.$t('claimrecordlisting.receiptdate'), dataKey: 'receiptdate' },
                { title: this.$t('payeeinputprocess.approvedamount'), dataKey: 'aprvamt' },
                { title: this.$t('columns.company'), dataKey: 'compnyname' },
                { title: this.$t('columns.branch'), dataKey: 'brchname' },
                { title: this.$t('columns.division'), dataKey: 'divnname' },
                { title: this.$t('columns.department'), dataKey: 'deptname' },
                { title: this.$t('columns.section'), dataKey: 'sectioname' },
                { title: this.$t('columns.unit'), dataKey: 'unitname' },
                { title: this.$t('columns.position'), dataKey: 'posdesc' },
                { title: this.$t('columns.grade'), dataKey: 'grddesc' },
                
            ]

            let body = []

            for (var i = 0; i < this.dataItems.length; i++) {
                body.push({
                    no: this.dataItems[i].no,
                    staffname: this.dataItems[i].staffname,
                    staffno: this.dataItems[i].staffno,
                    clmtypdesc: this.dataItems[i].clmtypdesc,
                    receiptdate: this.dataItems[i].receiptdate,
                    aprvamt: this.dataItems[i].aprvamt,
                    compnyname: this.dataItems[i].compnyname,
                    brchname: this.dataItems[i].brchname,
                    divnname: this.dataItems[i].divnname,
                    deptname: this.dataItems[i].deptname,
                    sectioname: this.dataItems[i].sectioname,
                    unitname: this.dataItems[i].unitname,
                    posdesc: this.dataItems[i].posdesc,
                    grddesc: this.dataItems[i].grddesc
            
                })
            }

            this.title = this.$t('claimutilizationforperiod');
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        // print PDF for tabheader2

        printPDF2() {
            const doc = new jsPDF({
            orientation: 'landscape'
            })

            var head = [     
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },              
                { title: this.$t('columns.name'), dataKey: 'staffname' },
                { title: this.$t('columns.staffid'), dataKey: 'staffno' },
                // { title: this.$t('claimreport.claimtype'), dataKey: 'clmtypdesc' },
                { title: this.$t('claimrecordlisting.receiptdate'), dataKey: 'receiptdate' },
                { title: this.$t('payeeinputprocess.approvedamount'), dataKey: 'aprvamt' },
                { title: this.$t('columns.company'), dataKey: 'compnyname' },
                { title: this.$t('columns.branch'), dataKey: 'brchname' },
                { title: this.$t('columns.division'), dataKey: 'divnname' },
                { title: this.$t('columns.department'), dataKey: 'deptname' },
                { title: this.$t('columns.section'), dataKey: 'sectioname' },
                { title: this.$t('columns.unit'), dataKey: 'unitname' },
                { title: this.$t('columns.position'), dataKey: 'posdesc' },
                { title: this.$t('columns.grade'), dataKey: 'grddesc' },
            ]

            let body = []

            for (var i = 0; i < this.chartItems.length; i++) {
                body.push({
                    no: this.chartItems[i].no,
                    staffname: this.chartItems[i].staffname,
                    staffno: this.chartItems[i].staffno,
                    clmtypdesc: this.chartItems[i].clmtypdesc,
                    receiptdate: this.chartItems[i].receiptdate,
                    aprvamt: this.chartItems[i].aprvamt,
                    compnyname: this.chartItems[i].compnyname,
                    brchname: this.chartItems[i].brchname,
                    divnname: this.chartItems[i].divnname,
                    deptname: this.chartItems[i].deptname,
                    sectioname: this.chartItems[i].sectioname,
                    unitname: this.chartItems[i].unitname,
                    posdesc: this.chartItems[i].posdesc,
                    grddesc: this.chartItems[i].grddesc
                })
            }

            this.title = this.$t('claimutilizationforperiodby') + this.clmtypdesc ;
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        //back handler - go back to PositionAssignmentAnalysisParameter.vue
        backHandler(){
            this.$router.push({name: 'claimutilizationperiodparameter'});
        },

        backHandlerTable() {
            this.hidetable = true
        },

        
        loadParameters() {

            this.companyHandler();
            this.departmentHandler();
            this.branchHandler();
            this.employmentHandler();
            this.valvalueHandler();
            this.sectionHandler();
            this.divisionHandler();
            this.unitHandler();
            this.gradeHandler();
     
             

        }



    },

    created() {
         const vm = this;
         vm.fromDate = this.$route.params.fromDate;
         vm.untilDate = this.$route.params.untilDate;

          if (typeof(this.$route.params.parameters) == 'string') {
            let paramHolder = JSON.parse(this.$route.params.parameters)
            this.data.parameters = {
                brchid: paramHolder.branchid,
                compnyid: paramHolder.companyid,
                deptid: paramHolder.departmentid,
                emptypeid: paramHolder.employmenttypeid,
                claimgroupid: paramHolder.claimgroupid,
                divnid:paramHolder.divisionid,
                sectioid:paramHolder.sectionid,
                unitid:paramHolder.unitid,
                grdid:paramHolder.gradeid
            }
          }

        
    },   

    mounted() {

     this.claimChartHeader();
     this.claimChart();
     this.loadParameters();
  
    }
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}
</style>