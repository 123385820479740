<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="12" sm="6" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.usertype')"
                                type="text"
                                ref="usertypeid"
                                :items="usertypeList"
                                hide-selected
                                v-model="formData.usertypeid"
                                :rules="[rules.required]"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppasswordpolicy.generation')"
                                type="text"
                                ref = "pwdgen"
                                :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                hide-selected=""
                                :rules="[rules.required]"
                                v-model="formData.pwdgen"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppasswordpolicy.minlength')"
                                type="number"
                                :rules="[rules.required]"
                                v-model.number="formData.pwdmin"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppasswordpolicy.maxlength')"
                                type="number"
                                :rules="[rules.required]"
                                v-model.number="formData.pwdmax"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppasswordpolicy.expirydays')"
                                type="number"
                                :rules="[rules.required]"
                                v-model.number ="formData.expirydays"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppasswordpolicy.longinactivitydays')"
                                type="number"
                                :rules="[rules.required]"
                                v-model.number="formData.inactivitydays"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setuppasswordpolicy.maxattempt_day')"
                                type="number"
                                :rules="[rules.required]"
                                v-model.number="formData.maximumattempt"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="3" class="pb-n4 mt-n10">
                            <v-checkbox
                                ref="alphanumeric"
                                v-model="formData.alphanumeric"
                                :label="this.$t('setuppasswordpolicy.alphanumeric')"
                                @change="updateValue"                               
                            ></v-checkbox>
                        </v-col>                                  
               
                        <v-col cols="4" sm="3" class="pb-n4 mt-n10">
                            <v-checkbox
                                ref="symbol"
                                v-model="formData.symbol"
                                :label="this.$t('setuppasswordpolicy.symbol')"
                                @change="updateValue"                               
                            ></v-checkbox>
                        </v-col>      

                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="3" class="pb-n4 mt-n10">
                            <v-checkbox
                                ref="repeat"
                                v-model="formData.repeatchar"
                                :label="this.$t('setuppasswordpolicy.repetitivecharacter')"
                                @change="updateValue"                              
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="4" sm="3" class="pb-n4 mt-n10">
                            <v-checkbox
                                ref="capital"
                                v-model="formData.capitalletter"
                                :label="this.$t('setuppasswordpolicy.capitalletter')"
                                @change="updateValue"                               
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                </v-container>

                
                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>


//import GenericForm from '../../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },

            formData: {
                usertypeid: this.parentObject.data.usertypeid,
                usertype: this.parentObject.data.usertype,
                pwdgen: this.parentObject.data.pwdgen,
                pwdmin: this.parentObject.data.pwdmin,
                pwdmax: this.parentObject.data.pwdmax,
                expirydays: this.parentObject.data.expirydays,
                inactivitydays: this.parentObject.data.inactivitydays,
                logicscrpt: this.parentObject.data.logicscrpt,
                maximumattempt: this.parentObject.data.maximumattempt,
                alphanumeric: this.parentObject.data.alphanumeric,
                symbol: this.parentObject.data.symbol,
                repeatchar: this.parentObject.data.repeatchar,
                capitalletter: this.parentObject.data.capitalletter,
                 search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            usertypeList:[],
            usertypeItems: [],
            usertypeid: [],
            UserTypeLoading: true,
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew

        }
    },


    methods: {

         LoadUserType() {
            const vm = this;

            this.$store.dispatch('get', {url: '/usertype/all'})
                .then(res => {
                    vm.usertypeid = res.data.rec;
                    console.log('item',vm.usertypeid)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.usertypeList.push(
                            {
                                text: res.data.rec[i].type,
                                value: res.data.rec[i].id
                            }
                        )

                        } 

                   vm.UserTypeLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        updateValue() {
            this.$emit('input', {
                usertypeid: this.formData.usertypeid,
                usertype: this.formData.usertype,
                pwdgen: this.formData.pwdgen,
                pwdmin: this.formData.pwdmin,
                pwdmax: this.formData.pwdmax,
                expirydays: this.formData.expirydays,
                inactivitydays: this.formData.inactivitydays,
                maximumattempt: this.formData.maximumattempt,
                alphanumeric: this.formData.alphanumeric,
                symbol: this.formData.symbol,
                repeatchar: this.formData.repeatchar,
                capitalletter: this.formData.capitalletter,
                logicscrpt: this.formData.logicscrpt,
                 search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

            console.log ('item', this.formData)
        },

       
        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'passwordpolicylist',  params: { search: this.search}});
        },
    },

    mounted() {
        this.LoadUserType();
    }
}
</script>