<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5"></v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row -->

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                id="compnycode"
                                type="text"
                                ref="compnycode"
                                :rules="[rules.required]"
                                v-model="formData.compnycode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompany.registrationno')"
                                type="text"
                                ref="regno"
                                v-model="formData.regno"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="compnyname"
                                :rules="[rules.required]"
                                v-model="formData.compnyname"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline1')"
                                type="text"
                                ref="addr1"
                                v-model="formData.addr1"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline2')"
                                type="text"
                                ref="addr2"
                                v-model="formData.addr2"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline3')"
                                type="text"
                                ref="addr3"
                                v-model="formData.addr3"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6">

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.city')"
                                type="text"
                                ref="cityid"
                                hide-selected
                                :items="cityList"
                                v-model="formData.cityid"
                                @input="updateValue"
                                @change="changeCityHandler(formData.cityid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="cityCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="cityDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: cityItems,
                                            formTitle: `${this.$t('columns.city')}`, 
                                            formId: 'city'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveCity="saveCityHandler"
                                        v-on:deleteCity="deleteCityHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="cityDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.country')"
                                type="text"
                                ref="cntryid"
                                :items="countryList"
                                hide-selected
                                v-model="formData.cntryid"
                                @input="updateValue"
                                @change="changeCountryHandler(formData.cntryid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="countryCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="countryDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: countryItems,
                                            formTitle: `${this.$t('columns.country')}`,
                                            formId: 'country'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCountry="saveCountryHandler"
                                        v-on:deleteCountry="deleteCountryHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="countryDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.postcode')"
                                type="text"
                                ref="postcode"
                                v-model="formData.postcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                                               
                    </v-row>

                    <v-row> 
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.telephone')"
                                type="text"
                                ref="tel"
                                v-model="formData.tel"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </v-col>

                 <v-col cols="4" sm="4">
                            
                            <v-row>
                              <v-col
                                    class="pa-0"
                                    cols="10"
                                >
                                    <div class="text-center">
                                  
                                        <v-img 
                                            v-if="!this.formData.picture"
                                            :src="`${publicPath}images/company.png`">
                                        </v-img>
                                        <v-img v-else
                                            :src="this.formData.picture"
                                        >
                                        </v-img>
                                 
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="10"  
                                    class="pb-n4 ml-n6"
                                    align-self="center"
                                >
                                    <div class="text-center">
                                        <v-file-input
                                            :label="this.$t('setupcompany.changecompanylogo')"
                                            ref="profileimg"
                                            outlined
                                            :accept=this.$store.getters.profileAcceptType
                                            dense
                                            show-size
                                            style="font-size: 14px"
                                            prepend-icon="mdi-camera"
                                            @change="imgToBase64"
                                            @input="updateValue">
                                        </v-file-input>
                                    </div>
                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>

                    
                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.website')"
                                type="text"
                                ref="www"
                                v-model="formData.www"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.email')"
                                type="text"
                                ref="email"
                                :rules="[rules.email]"
                                v-model="formData.email"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupcompany.defaultcurrency')"
                                type="text"
                                ref="crcyid"
                                :rules="[rules.required]"
                                :items="currencyList"
                                hide-selected
                                v-model="formData.crcyid"
                                @input="updateValue"
                                @change="changeCurrencyHandler(formData.crcyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="currencyCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="currencyDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: currencyItems,
                                            formTitle: `${this.$t('columns.currency')}`,
                                            formId: 'currency'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCurrency="saveCurrencyHandler"
                                        v-on:deleteCurrency="deleteCurrencyHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="currencyDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupcompany.parentcompany')"
                                type="text"
                                ref="parentcompnyid"
                                :items="parentCompanyList"
                                hide-selected
                                v-model="formData.parentcompnyid"
                                @input="updateValue"
                                @change="changeParentCompnyHandler(formData.parentcompnyid)"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="parentCompnyCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('setupcompany.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                    

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                    
                    />

            </v-form>
        </v-container>
    </div>
</template>

<script>
// import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {
                compnycode: this.parentObject.data.compnycode,
                compnyname: this.parentObject.data.compnyname,
                regno: this.parentObject.data.regno,
                //startdate: this.parentObject.data.startdate,
                //enddate: this.parentObject.data.enddate,
                addr1: this.parentObject.data.addr1,
                addr2: this.parentObject.data.addr2,
                addr3: this.parentObject.data.addr3,
                cityid: this.parentObject.data.cityid,
                cntryid: this.parentObject.data.cntryid,
                postcode: this.parentObject.data.postcode,
                tel: this.parentObject.data.tel,
                www: this.parentObject.data.www,
                email: this.parentObject.data.email,
                crcyid: this.parentObject.data.crcyid,
                parentcompnyid: this.parentObject.data.parentcompnyid,
                picture: this.parentObject.data.picture,
                isdisable: this.parentObject.data.isdisable,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            cityCode: '',
            countryCode: '',
            currencyCode: '',
            parentCompnyCode: '',
            countryList:[],
            cityList: [],
            parentCompanyList: [],
            currencyList: [],
            countries: [],
            cities: [],
            currencies: [],
            parentCompanies: [],
            cityItems: [],
            countryItems: [],
            currencyItems: [],
            cityDialog: false,
            countryDialog: false,
            currencyDialog: false,
            cityLoading: true,
            countryLoading: true,
            currencyLoading: true,
            errMsg: '',
            isErr: false,
            id: this.$route.params.id,

            search: this.parentObject.searchnew
        }
    },

    methods: {

          imgToBase64() {
            var file = this.$refs.profileimg.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.picture = b64;

                this.$emit('profile', b64);

                 //file size in bytes. (5000000bytes = 5mb)
                if (file.size > '5000000'){
                     alert('File size is bigger than 5MB. Please upload new picture.');
                    return;
                }
            }
            reader.readAsDataURL(file); 
        },

        fileToBase64() {
            var file = this.$refs.file.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.attach = b64;
            }

            reader.readAsDataURL(file);
        },

        loadCityListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/city/all'})
                .then(res => {
                    vm.cities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.cityList.push(
                            {
                                text: res.data.rec[i].cityname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.cityItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].citycode,
                                desc: res.data.rec[i].cityname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    vm.cityLoading = false;

                    if (vm.formData.cityid) {
                        vm.changeCityHandler(vm.formData.cityid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.currencyItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].crcycode,
                                desc: res.data.rec[i].crcydesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.crcyid) {
                        vm.changeCurrencyHandler(vm.formData.crcyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadParentCompanyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/company/all'})
                .then(res => {
                    vm.parentCompanies = res.data.rec;

                    for (let i = 0; i < vm.parentCompanies.length; i++)
                    {
                        vm.parentCompanyList.push(
                            {
                                text: res.data.rec[i].compnyname,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    
                    // const todayDate = moment();

                    // for (let i = 0; i < res.data.rec.length; i++) {
                    //     if (todayDate.isSameOrAfter(res.data.rec[i].startdate) && todayDate.isBefore(res.data.rec[i].enddate) && res.data.rec[i].isdisable == 'N') 
                    //     {
                    //         vm.parentCompanyList.push(
                    //             {
                    //                 text: res.data.rec[i].compnyname,
                    //                 value: res.data.rec[i].id
                    //             }
                    //         )
                    //     }
                    //}

                    if (vm.formData.parentcompnyid) {
                        vm.changeParentCompnyHandler(vm.formData.parentcompnyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.cityCode = item.citycode;
        },

        changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.countryCode = item.cntrycode;
        },

        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);

            this.currencyCode = item.crcycode;
        },

        changeParentCompnyHandler(id) {
            let item = this.parentCompanies.find(item => item.id === id);

            this.parentCompnyCode = item.compnycode;
        },

        saveCityHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/city/' + this.itemData.itemid, payload: {
                    citycode: this.itemData.itemcode,
                    cityname: this.itemData.itemdesc,    
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/city/create', payload: {
                citycode: this.itemData.itemcode,
                cityname: this.itemData.itemdesc,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCountryHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/country/' + this.itemData.itemid, payload: {
                cntrycode: this.itemData.itemcode,
                cntryname: this.itemData.itemdesc,stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/country/create', payload: {
                cntrycode: this.itemData.itemcode,
                cntryname: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCurrencyHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/currency/' + this.itemData.itemid, payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/currency/create', payload: {
                crcycode: this.itemData.itemcode,
                crcydesc: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyItems = [];
                        vm.currencyList = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCityHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/city/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCountryHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/country/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCurrencyHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/currency/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.currencies = [];
                        vm.currencyList = [];
                        vm.currencyItems = [];

                        this.loadCurrencyListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        updateValue() {
          
            this.$emit('input', {
                compnycode: this.formData.compnycode,
                compnyname: this.formData.compnyname,
                isdisable: this.formData.isdisable,
                regno: this.formData.regno,
                //startdate: this.formData.startdate,
                //enddate: this.formData.enddate,
                addr1: this.formData.addr1,
                addr2: this.formData.addr2,
                addr3: this.formData.addr3,
                cityid: this.formData.cityid,
                cntryid: this.formData.cntryid,
                postcode: this.formData.postcode,
                tel: this.formData.tel,
                www: this.formData.www,
                email: this.formData.email,
                crcyid: this.formData.crcyid,
                picture: this.formData.picture,
                parentcompnyid: this.formData.parentcompnyid,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });

        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {

          this.$router.push({ name: "companylist", params: { search: this.search} });
   
        },

        genCityTabItems() {
            for (let i = 0; i < this.cities.length; i++) {
                this.cityItems.push(
                    {
                        id: this.cities[i].id,
                        code: this.cities[i].citycode,
                        desc: this.cities[i].cityname,
                        stamp: this.cities[i].stamp
                    }
                )
            }
        },

        genCountryTabItems() {
            for (let i = 0; i < this.countries.length; i++) {
                this.cityItems.push(
                    {
                        id: this.countries[i].id,
                        code: this.countries[i].cntrycode,
                        desc: this.countries[i].cntryname,
                        stamp: this.countries[i].stamp
                    }
                )
            }
        },

        genCurrencyTabItems() {
            for (let i = 0; i < this.currencies.length; i++) {
                this.cityItems.push(
                    {
                        id: this.currencies[i].id,
                        code: this.currencies[i].crcycode,
                        desc: this.currencies[i].crcydesc,
                        stamp: this.currencies[i].stamp
                    }
                )
            }
        }
    },

    mounted() {
        this.loadCityListHandler();
        this.loadCountryListHandler();
        this.loadCurrencyListHandler();
        this.loadParentCompanyListHandler();
    }
    
}
</script>