<template>
  <v-container>
    <v-form>
      <v-row class="mt-8">
        <h2 class="pb-5 pt-5 pl-5" style="color: black">{{$t('button.resetpassword')}}</h2>
      </v-row>

      <v-container id="scroll-target" class="custom-form overflow-y-auto">
        <v-row>
          <v-col cols="4" sm="4" class="pb-n5">
            <v-text-field
              outlined
              :label="this.$t('resetpassword.newpassword')"
              type="password"
              :rules="[rules.required]"
              v-model="formData.newpassword"
              @change="checkpassword"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5">
            <v-card-text v-if="isErr">
              <div style="font-weight: bold; color: red" v-html="errMsg"></div>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="4" class="pb-n5">
            <v-text-field
              outlined
              :label="this.$t('resetpassword.confirmnewpassword')"
              type="password"
              :rules="[rules.required]"
              v-model="formData.confirmnewpassword"
              @change="checkconfirmpassword"
              @input="updateValue"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" class="pb-n5">
            <v-card-text v-if="isConfirmErr">
              <span style="font-weight: bold; color: red"
                >{{$t('resetpassword.alertconfirm')}}</span
              >
            </v-card-text>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="8" class="pb-n4 mt-1">
            <v-btn
              color="#fd9941"
              style="width: 100px; color: white"
              v-on:click="buttonHandler()"
              >{{$t('button.save')}}</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="isError">
          <app-error
            v-bind:parentError="{
              data: this.errmessage,
              isErr: this.isError,
            }"
            v-on:closeError="isError = $event"
          />
        </div>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
// import moment from "moment";
//import GenericForm from '../../../generic/GridForm';
import ErrorDialog from "./ErrorDialog";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    //'app-genericform': GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      token: this.$route.params.token,
      db: this.$route.params.db,
      id: null,
      formData: {
        newpassword: "",
        confirmnewpassword: "",
      },
      passpolicy: "",
      isError: false,
      errmessage: "",
      isErr: false,
      isConfirmErr: false,
      errMsg: "",
      isOldPasswordErr: false,
      errOldPasswordMsg: "",
    };
  },

  methods: {
    CheckPasswordPolicy() {
      const vm = this;
      console.log(vm.id);
      if (vm.formData.newpassword != "") {
        this.$store.dispatch('post', {
          url:  "/policy/chkpwdpolicyNew", 
          payload: {
            userid: vm.id,
            password: vm.formData.newpassword,
            db: vm.db,
          }})
          .then((res) => {
            console.log(res);
            if (res.data.status != 200) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },
    buttonHandler() {
      const vm = this;
      if (!this.isErr && vm.id) {
        this.$store.dispatch('post', {
          url:  "/policy/resetpassword", 
          payload: {
            userid: vm.id,
            newpassword: vm.formData.newpassword,
            db: vm.db,
          }})
          .then((res) => {
            console.log(res);
            if (res.data.status == 200) {
              this.$dialog
                .alert(
                  this.$t('forgotpassword.message1')
                )
                .then((dialog) => {
                  console.log(dialog);
                  let db = this.$store.getters.db;
                  this.$store.dispatch("deleteToken");
                  this.$router.replace("/" + this.$i18n.locale + "/home/" + db);
                });
            } else if (res.data.status == 401) {
              vm.isOldPasswordErr = true;
              vm.errOldPasswordMsg = res.data.error;
              vm.$emit("error", {
                isOldPasswordErr: vm.isOldPasswordErr,
                errOldPasswordMsg: vm.errOldPasswordMsg,
              });
            } else {
              vm.isError = !vm.isError;
              vm.errmessage = res.data.error;
              // this.$dialog.alert(res.data.error).then((dialog) => {
              //   console.log(dialog);
              // });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },
    updateValue() {
      this.isConfirmErr = false;
      this.$emit("input", {
        newpassword: this.formData.newpassword,
        confirmnewpassword: this.formData.confirmnewpassword,
      });
    },
    checkpassword() {
      this.isErr = false;
      this.errMsg = "";
      this.CheckPasswordPolicy();
    },
    checkconfirmpassword() {
      this.isConfirmErr = false;
      if (this.formData.confirmnewpassword != this.formData.newpassword) {
        this.isConfirmErr = true;
      }
    },
    checkToken() {
      const vm = this;
      this.$store.dispatch('post', {
        url:  "/checktoken", 
        payload: {
          token: vm.token,
          db: vm.db,
        }})
        .then((res) => {
          console.log(res);
          if (res.data.status != 201) {
            vm.isError = !vm.isError;
            vm.errmessage = res.data.error;
          } else {
            vm.id = res.data.id;
          }
        })
        .catch((err) => {
          vm.isError = !vm.isError;
          vm.errmessage = err;
        });
    },
  },

  mounted() {
    this.checkToken();
  },
};
</script>