<template>
    <div>

 <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>

    </div>
   
</template>


<script>


import GridView from '../../../components/common/GridView'; 
import moment from 'moment';

export default {
    
    components: {
        'app-gridview': GridView
    },  

    data() {

         return {
            pageTitle: this.$t('mmtaxablerebatelimit.taxablerebatelimitlist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width: '100px' },
                { text: this.$t('setuptaxablerebate.rebatetypecode'), value: 'dedtcode', width: '200px' },
                { text: this.$t('columns.startdate'), value: 'startdate', width: '200px' },
                { text: this.$t('columns.enddate'), value: 'enddate', width: '200px' },
                { text: this.$t('setuptaxablerebategroup.rebatelimitamount'), value: 'maxamt', width: '200px' },
                { text: this.$t('columns.currency'), value: 'crcycode', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '100px' },
               
            ],
            dataItems: [],

            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }

    }, 

    methods: {
        //get all records 
        getTaxableRebateListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/mmtaxablerebatelimit/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    for (let i = 0; i < vm.dataItems.length; i++) {
                        if (vm.dataItems[i].startdate) {
                            vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                        }

                        if (vm.dataItems[i].enddate) {
                            vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                        }
                    }
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },

        editItemHandler(item) {
            this.$router.push({name: 'mmtaxablerebatelimitedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'mmtaxablerebatelimitnew'});
        }, 


    },

    mounted() {
       this.getTaxableRebateListHandler();
    }
}
</script>