<template>
    <div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-col class="mt-n4 mb-2 pb-8 pt-6">
          <v-row>
            <v-btn
              @click="backButton()"
              color="orange"
              class="white--text"
            >{{$t('button.back')}}</v-btn
            >
          </v-row>
        </v-col>

        <v-row>
            <v-toolbar color="#ECEFF1">
                <h2 style="color: #3f6892; padding-left: 20px">
                {{ pageTitle }}
                </h2>
                <v-spacer></v-spacer>

                <v-avatar size="40" tile>
                <img
                    :src="`${publicPath}images/export_pdf.png`"
                    @click="exportPdf"
                />
                </v-avatar>
                <v-avatar size="40" tile>
                <img
                    :src="`${publicPath}images/export_png.png`"
                    @click="exportPng"
                />
                </v-avatar>
                <v-avatar size="40" tile>
                <img
                    :src="`${publicPath}images/export_txt.png`"
                    @click="buildHierarchy"
                />
                </v-avatar>

            </v-toolbar>
        </v-row>

        <v-row class="mt-4 mb-4">
            <div id="chart-container" ref="pdf"></div>
        </v-row>

        <v-dialog v-model="dialog" max-width="20%" @keydown.esc="cancel">
            <v-card>
                <v-img v-if="!pic" height="20%" :src="`${publicPath}images/avatar-1577909_960_720.png`" alt="No Profile Image" contain/>
                <v-img v-else height="20%" :src="pic" alt="Profile Image" contain/>
                <!-- <v-card-title>{{staffName}}</v-card-title>
                <v-card-title>{{staffTitle}}</v-card-title> -->
            </v-card>

            <!-- <v-card v-if="!pic">
                <v-img height="250" :src="`${publicPath}images/avatar-1577909_960_720.png`" alt="No Profile Image" contain/>
            </v-card>
            <v-card v-else>
                <v-img height="250" :src="pic" alt="Profile Image" contain/>
            </v-card>
            <v-card-title>Cafe Badilico</v-card-title> -->
        </v-dialog>

    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// import OrgChart from 'orgchart.js';
// import "orgchart.js/src/orgchart.css";
// import "orgchart.js/src/orgchart.js";
import OrgChart from '~/../../src/util/orgchart.js';
import "../../../util/orgchart.js/src/orgchart.css";

import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

export default {

    components: {
        Loading
    },

    data() {
        return {

            publicPath: process.env.BASE_URL,

            positionId: this.$route.params.positionid,
            asatdate: this.$route.params.asatdate,

            orgchart: null,

            ds: {
                id: null,
                name: null,
                title: null
            },

            dummy: [],
            childKeys: [],

            id: 1,
            obj: {},
            childData: [],
            arrCompare: [],

            loading: false,
            errMsg: '',
            isErr: false,

            // pageTitle: this.$t('title.orgchart') + this.$route.params.asatdate,
            pageTitle: this.$t('orgchart') + this.$route.params.asatdate,
            // pageTitle: this.$t('title.orgchart') + "Organization Chart As At " + this.$route.params.asatdate,

            pic: null,
            dialog: false,
            staffName: '',
            staffTitle: ''

        }

    },

    methods: {

        buildHierarchy() {

            this.loading = true;

            let text = JSON.stringify(this.orgchart.getHierarchy(), null, 2);
            let filename = this.$t('reportorgchart.orgchartasat') + ' ' + this.asatdate + '.txt';

            let element = document.createElement('a');
            element.setAttribute('href', 
            'data:text/plain;charset=utf-8, '
            + encodeURIComponent(text));
            element.setAttribute('download', filename);
            
            // Above code is equivalent to
            // <a href="path of file" download="file name">
            
            // document.body.appendChild(element);
            
            //onClick property
            element.click();
            
            // document.body.removeChild(element);   

            this.loading = false;

        },

        exportPng() {

            this.loading = true;

            let chartContainer = this.orgchart.chartContainer;
            // let mask = chartContainer.querySelector(':scope > .mask');
            let sourceChart = chartContainer.querySelector('.orgchart:not(.hidden)');
            sourceChart.style.border = 'none';
            // console.log(sourceChart);
            // flag = opts.direction === 'l2r' || opts.direction === 'r2l';
            // let style = getComputedStyle(sourceChart);
            // style.border;

            // console.log(style);

            // if (!mask) {
            // mask = document.createElement('div');
            // mask.setAttribute('class', 'mask');
            // // mask.innerHTML = `<i class="fa fa-circle-o-notch fa-spin spinner"></i>`;
            // chartContainer.appendChild(mask);
            // } else {
            // mask.classList.remove('hidden');
            // }
            chartContainer.classList.add('canvasContainer');

            html2canvas(sourceChart, {
            // 'width': flag ? sourceChart.clientHeight : sourceChart.clientWidth,
            // 'height': flag ? sourceChart.clientWidth : sourceChart.clientHeight,
            'width': sourceChart.clientWidth,
            'height': sourceChart.clientHeight,
            'onclone': function (cloneDoc) {
                let canvasContainer = cloneDoc.querySelector('.canvasContainer');
                canvasContainer.style.overflow = 'visible';
                canvasContainer.querySelector('.orgchart:not(.hidden)').transform = '';
            }
            })
            .then((canvas) => {

                // chartContainer.querySelector('.mask').classList.add('hidden');

                const link = document.createElement("a");
                link.setAttribute('download', this.$t('reportorgchart.orgchartasat') + ' ' + this.asatdate +'.png');
                link.setAttribute('href', canvas.toDataURL());
                link.click();

                sourceChart.style.border = '1px solid #000';

                this.loading = false;

            })
            .catch((err) => {
                console.error('Failed to export the curent orgchart!', err);
            })
            .finally(() => {
                chartContainer.classList.remove('canvasContainer');
            });     

        },

        exportPdf() {

            this.loading = true;

            let chartContainer = this.orgchart.chartContainer;
            // let mask = chartContainer.querySelector(':scope > .mask');
            let sourceChart = chartContainer.querySelector('.orgchart:not(.hidden)');
            sourceChart.style.border = 'none';

            // if (!mask) {
            // mask = document.createElement('div');
            // mask.setAttribute('class', 'mask');
            // chartContainer.appendChild(mask);
            // } else {
            // mask.classList.remove('hidden');
            // }
            chartContainer.classList.add('canvasContainer');

            html2canvas(sourceChart, {
            'width': sourceChart.clientWidth,
            'height': sourceChart.clientHeight,
            'onclone': function (cloneDoc) {
                let canvasContainer = cloneDoc.querySelector('.canvasContainer');

                canvasContainer.style.overflow = 'visible';
                canvasContainer.querySelector('.orgchart:not(.hidden)').transform = '';
            }
            })
            .then((canvas) => {

                // chartContainer.querySelector('.mask').classList.add('hidden');
                const imgData = canvas.toDataURL('image/png');

                const pdf = new jsPDF({
                // orientation: 'landscape',
                orientation: 'potrait',
                });

                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                
                pdf.addImage(imgData, 'PNG', 0, 15, pdfWidth, pdfHeight);

                pdf.text(`${this.$t('reportorgchart.orgchartasat')} ${this.asatdate} `, 5, 10);
                // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, null, null, 45);
                pdf.save(this.$t('reportorgchart.orgchartasat') + ' ' + this.asatdate + '.pdf');

                sourceChart.style.border = '1px solid #000';

                this.loading = false;

            })
            .catch((err) => {
                console.error('Failed to export the curent orgchart!', err);
            })
            .finally(() => {
                chartContainer.classList.remove('canvasContainer');
            });

        },

        loadParent() {

            const vm = this;

            vm.orgchart = null;

            vm.loading = true;

            this.$store.dispatch('get', { url: "/organizationzhart/parent/" + vm.positionId + "/" + vm.asatdate + "/" + vm.asatdate })
            .then(res => {

                let item = res.data.rec;

                if(item.length < 1) { // Vacant Parent

                    this.$store.dispatch('get', { url: "/organizationzhart/parentvacant/" + vm.positionId })
                    .then(res => {

                        let item = res.data.rec;

                        console.log(item);

                        vm.ds.id = 1; // By default, id would be 1 for any parent that we choose

                        for( let i = 0; i < item.length; i++) {

                            if(item[i].id) {
                                vm.ds.newid = (item[i].id);
                            }

                            if(item[i].name) {
                                vm.ds.name = item[i].name;
                            }

                            if(item[i].title == null) {
                                vm.ds.title = '-';
                            }

                            // if(item[i].title) {
                            //     vm.ds.title = (item[i].title);
                            // }

                            // if(item[i].pic == null) {
                            //     vm.ds.pic = null;
                            // }

                            // if(item[i].compnycode == null) {
                            //     vm.ds.compnycode = '-';
                            // }

                            // if(item[i].staffcode == null) {
                            //     vm.ds.staffcode = '-';
                            // }

                        }

                    })
                    .catch(err => {
                        this.isErr = true;
                        this.errMsg = err;
                    });

                } else { // Assign parent

                    vm.ds.id = 1; // By default, id would be 1 for any parent that we choose
                    
                    for( let i = 0; i < item.length; i++) {

                        if(item[i].id) {
                            vm.ds.newid = (item[i].id);
                        }

                        if(item[i].name) {
                            vm.ds.name = (item[i].name);
                        }

                        if(item[i].title == null) {
                            vm.ds.title = '-';
                        }

                        if(item[i].title) {
                            vm.ds.title = (item[i].title);
                        }

                        if(item[i].pic) {
                            vm.ds.pic = (item[i].pic);
                        }

                        if(item[i].compnycode) {
                            vm.ds.compnycode = (item[i].compnycode);
                        }

                        if(item[i].staffcode) {
                            vm.ds.staffcode = (item[i].staffcode);
                        }

                    }

                }

                this.loadChildAssign();

            })
            .catch(err => {
                this.isErr = true;
                this.errMsg = err;
            });

        },

        loadChildAssign() {

            const vm = this;

            this.$store.dispatch('get', {url: "/organizationzhart/childAssign/" + vm.asatdate + "/" + vm.asatdate + "/" + vm.positionId })
            .then(res => {

                let data = res.data.rec;
                // vm.dummy = res.data.rec;

                for(let i = 0; i < data.length; i++) {
                    
                    // data[i]['id'] = vm.id += 1; // incoming child data by increment +1
                    data[i]['id'] = ((vm.id += 1) == vm.positionId ? vm.id += 1 : vm.id )

                    if(data[i].newid) {
                        vm.obj[data[i].newid] = data[i]['id'];
                    }

                    vm.dummy.push(data[i]);
                }

                this.loadChildVacant();

            })
            .catch(err => {
                this.isErr = true;
                this.errMsg = err;
            });

        },

        loadChildVacant() {

            const vm = this;

            this.$store.dispatch('get', {url: "/organizationzhart/childVacant/" + vm.asatdate + "/" + vm.asatdate + "/" + vm.positionId })
            .then(res => {

                let data = res.data.rec;

                for(let i = 0; i < data.length; i++) {
                    
                    // if(data[i].title == null) {
                    //     data[i]['classname'] = 'middle-level';
                    // }
                    if(data[i].title == null) {
                        data[i]['title'] = '-';
                    }

                    data[i]['id'] = ((vm.id += 1) == vm.positionId ? vm.id += 1 : vm.id ); // incoming child data by increment +1

                    if(data[i].newid) {
                        vm.obj[data[i].newid] = data[i]['id'];
                    }

                    vm.dummy.push(data[i]);
                }

                // console.log('data', vm.dummy);
                // console.log('new obj', vm.obj);
                // console.log('parent ID', vm.positionId);

                this.changeId();
                
                
            })
            .catch(err => {
                this.isErr = true;
                this.errMsg = err;
            });

        },

        // Change repposID for each child based to its parent new increment id: ln 167(Assign Child), ln 204(Vacant Child)
        changeId() {

            const vm = this;
            const data = vm.dummy;
            const obj = vm.obj

            for(let i = 0; i < data.length; i++) {

                if(obj.hasOwnProperty(data[i].repposid)) { // 9
                    data[i].repposid = obj[data[i].repposid]; // Change repposid from 9 -> 4
                }

                if(data[i].repposid == vm.positionId) { // If the data reporting to root parent, by default change to 1
                    data[i].repposid = 1;
                }

                vm.childData.push(data[i]);

            }

            // console.log('Child Data' ,vm.childData);
            // console.log('RepposID Change' ,vm.obj);

            this.groupByChild(vm.childData);

        },

        // Group child by the new repposid
        groupByChild(data) {

            const vm = this;

            const child = data.reduce((child, item) => {
            const group = (child[item.repposid] || []);
            group.push(item);
            child[item.repposid] = group;
            return child;
            }, {});

            // console.log(child);

            // Store the grouping inside child object ln 264 -> {1: {...}, 2: {...}, 3: {...}}

            // Push Root Parent's Child , Repposid = 1, 2nd Level Structuring
            if(child.hasOwnProperty(1)) {
                
                vm.findNode(1, child[1] , vm.ds);

                for(let x = 0; x < child[1].length; x++) {

                    vm.arrCompare.push(child[1][x].id); // Store the id from 2nd Level to *temp arr* for 3rd and ++ Level Structuring

                }
                // console.log('Parent Child', child[1].id);

                delete child[1]; // Delete ln 267 -> {2: {...}, 3: {...}}

                // console.log(child);
                // console.log(vm.arrCompare);
            }

            // If child ln 264 still has data then run this process
            this.stillExist(child);

            // Identify parent repposid
            // let parentValue = String(vm.positionId);
            // // Change to value 1
            // if (parentValue !== '1' && child[parentValue] && !child['1']) {
            //     Object.defineProperty(child, '1',
            //         Object.getOwnPropertyDescriptor(child, parentValue));
            //     delete child[parentValue];
            // }

            // ----------------------------------------------------------------------------------------

            // // To identify the repposid that available
            // let keys = Object.keys(child); 

            // // Store the keys to a temp variable(childKeys)
            // for ( let i = 0; i < keys.length; i++) {
            //     vm.childKeys.push(parseInt(keys[i]));
            // }

            // // To find its parent according to the keys
            // for( let i = 0; i < vm.childKeys.length; i++) {
                
            //     // console.log(vm.childKeys[i]); // 77, 78, 96
            //     // console.log(child[String(vm.childKeys[i])]); // '77' : [{...}], '78' : [{...}]

            //     // this.findNode(Object keys from child group by, child[Object Keys], Object)
            //     this.findNode(vm.childKeys[i], child[String(vm.childKeys[i])] , vm.ds); // 1, {data...}, Obj

            // }

            // Build The Chart
            this.loadOrgChart();

        },

        stillExist(data) {

            const vm = this;

            // vm.arrCompare = all id from 2nd level Tree

            for( let i = 0; i < vm.arrCompare.length; i++) {

                if(data.hasOwnProperty(vm.arrCompare[i])) { // Repeat same process like ln 270.

                    vm.findNode(vm.arrCompare[i], data[vm.arrCompare[i]], vm.ds);

                    for(let x = 0; x < data[vm.arrCompare[i]].length; x++) {

                        vm.arrCompare.push(data[vm.arrCompare[i]][x].id);

                    }
                        
                }

            }

        },

        // Push children data inside parent based on id
        findNode(nodeId, json, o) { // 24, data push, object

            if(o.id == nodeId) {
                o['children'] = (json);
                o['className'] = 'has-children';
            }
            else { // if(o.children)
                if(o.children) {
                    for(var i=0; i<o.children.length; i++){
                        o.children[i] = this.findNode(nodeId, json, o.children[i]);
                    }
                }
            }
        
            return o;

        },

        // Build The Chart
        loadOrgChart() {

            const vm = this;

            this.orgchart = new OrgChart({
                'chartContainer': '#chart-container',
                'data': vm.ds,
                'toggleSiblingsResp': true,
                'nodeContent': 'title',
                'nodeId': 'id',
                'createNode': function(node, data) {

                    // let secondMenuIcon = document.createElement('i'),
                    let secondMenu = document.createElement('span');

                    // secondMenuIcon.setAttribute('class', 'fa fa-info-circle second-menu-icon');
                    // secondMenuIcon.addEventListener('click', (event) => {
                    // event.target.nextElementSibling.classList.toggle('hidden');
                    // });
                    secondMenu.setAttribute('class', 'companystaff');
                    // secondMenu.innerHTML = `<img class="avatar" src="../img/avatar/${data.id}.jpg">`;
                    secondMenu.innerHTML = data.compnycode && data.staffcode ? `${data.compnycode} - ${data.staffcode}` : ` - `;
                    // node.appendChild(secondMenuIcon)
                    node.appendChild(secondMenu);

                    node.querySelector('.titleorgchart').addEventListener('click', () => {
                        vm.openPic(data.pic, data.name, data.title);
                    });
                    node.querySelector('.content').addEventListener('click', () => {
                        vm.openPic(data.pic, data.name, data.title);
                    });
                    node.querySelector('.companystaff').addEventListener('click', () => {
                        vm.openPic(data.pic, data.name, data.title);
                    });
                }
            });

            vm.loading = false;

        },

        // Picture Popup
        openPic(picUrl, name, title) {
            this.dialog = true;
            this.pic = picUrl;
            this.staffName = name;
            this.staffTitle = title
        },

        cancel() {
            this.dialog = false;
        },
        
        backButton() {
            this.$router.push({ name: "orgchartfilter" });
        },

    },

    mounted() {
        this.loadParent();
    },

}
</script>

<style>
</style>



