<template>
    <div>
        <app-form 
            v-bind:parentObject = "{
                data: this.formData,
                pageTitle: this.pageTitle,
                pageTitleValue: this.pageTitleValue,
                errMsg: this.errMsg,
                isEdit: this.editMode
            }"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError ="{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
            />
        </div>    
    </div>
</template>

<script>
import AttendancePeriodForm from '../../../components/attendance/attendanceperiod/Form_Parameter';
import ErrorDialog from '../../../components/common/ErrorDialog';
//import moment from 'moment';


export default {
    components: {
        'app-form': AttendancePeriodForm,
        'app-error': ErrorDialog
    },

    data() {
            return{
                pageTitle: this.$t('attendancereportbyperiodbycompany'),
                pageTitleValue: '',
                
                formData: {
                    // asofdate1: moment().format("YYYY-MM-DD"),
                    // asofdate2: moment().format("YYYY-MM-DD"),
                    // compnyid: null,
                    // compnycode: null,
                    
                    },
                

                dataItems:[],

                goBtn: false,

               
                codeList: [],            

            errMsg: '',
            isErr:false,
            editMode: false 
            }
                },
        

    methods: {
        
        
    }
}

</script>