<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle
              }}<span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
          :style="{
            'max-height': `${filterSectionHeight}px`,
          }"
        > -->
        <v-card class="mt-n6">
          <div class="ma-4">
            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
            <v-row>
              <v-col cols="4" sm="3" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.year')"
                  type="text"
                  hide-selected
                  v-model="formData.recyear"
                  dense
                  :items="years"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="12" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('alltax.nameofofficer')"
                  type="text"
                  ref="nameofofficeer"
                  v-model="formData.nameofofficeer"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('statutoryexportcommonused.designation')"
                  type="text"
                  ref="designation"
                  v-model="formData.designation"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>

               <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('columns.email')"
                  type="text"
                  ref="email"
                  v-model="formData.email"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('alltax.telephoneno')"
                  type="text"
                  ref="telephoneno"
                  v-model="formData.telephoneno"
                  :rules="[rules.required]"
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>

               <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('recfamdetails.icno')"
                  type="text"
                  ref="icno"
                  v-model="formData.icno"
                 
                  dense
                  @input="updateValue"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card>
          <div class="ma-4">
            <v-subheader class="blue--text mb-5"
              >{{$t('alltax.reportgenerationcriteria')}}</v-subheader
            >
            <v-row>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-checkbox :label="this.$t('setupbaitulmal.ea')" v-model="formData.eaform"> </v-checkbox>
              </v-col>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-checkbox :label="this.$t('alltax.borang_e')" v-model="formData.borangeform">
                </v-checkbox>
              </v-col>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-checkbox :label="this.$t('alltax.cp8d')" v-model="formData.cp8dform">
                </v-checkbox>
              </v-col>
              <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                <v-checkbox
                  :label="this.$t('statutoryexportcommonused.includestaffwithzerotax')"
                  v-model="formData.inclzerotax"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <app-parameter
          class="mt-5 mb-3"
          v-on:input="parameters = $event"
          v-bind:parentObject="{
            filter: this.showFilter,
          }"
        />
        <!-- </v-container> -->

        <v-row>
          <v-col>
            <v-btn color="warning" style="width: 100px; margin-bottom: 20px;" @click="displayHandler">
              {{$t('button.print')}} 
            </v-btn>
            <v-btn
              color="warning"
              style="width: 200px; 
              margin-bottom: 20px;"
              class="ml-5"
              @click="emailHandler"
            >
            {{$t('button.generateeaandemail')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import JsZip from "jszip";
import FilterParameter from "../../../components/common/FilterParameter";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-parameter": FilterParameter,
    Loading,
  },
  computed: {
    // filterSectionHeight() {
    //   const filterSectionDOM = document.getElementById("menu");
    //   return filterSectionDOM ? filterSectionDOM.offsetHeight - 100 : 0;
    // },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      formData: {
        recyear: this.parentObject.data.recyear,
        inclzerotax: this.parentObject.data.inclzerotax,
        eaform: this.parentObject.data.eaform,
        borangeform: this.parentObject.data.borangeform,
        cp8dform: this.parentObject.data.cp8dform,
        nameofofficeer: this.parentObject.data.nameofofficeer,
        designation: this.parentObject.data.designation,
        telephoneno: this.parentObject.data.telephoneno,
        email: this.parentObject.data.email,
        icno: this.parentObject.data.icno,
        reporturl: "",
        userid: "",
        staffid: "",
        staff: "",
        companyid: "",
        branchid: "",
        departmentid: "",
      },
      parameters: {
        staffid: "",
        gradeid: "",
        employmenttypeid: "",
        companyid: "",
        branchid: "",
        departmentid: "",
      },
      showFilter: {
        staffid: true,
        gradeid: false,
        employmenttypeid: false,
        companyid: true,
        branchid: true,
        departmentid: true,
      },
      isErr: false,
      years: [],
      reportFile: "TaxEA.rptdesign",
      borangereportFile: "borang_e.rptdesign",
      loading: false,
    };
  },

  methods: {
    loadYears() {
      const vm = this;

      for (let i = 0; i < 200; i++) {
        vm.years.push(2020 + i);
      }
    },
    emailHandler() {
      const vm = this;
      this.clearFilter();

      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation) 
        // !CheckIsEmpty(this.formData.email)&&
        // !CheckIsEmpty(this.formData.icno)
      ) {
        this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
        this.formData.userid = this.$store.getters.userId;

        this.formData.staffid = this.parameters.staffid;
        this.formData.staff = this.parameters.staffid;
        this.formData.companyid = this.parameters.companyid;
        this.formData.branchid = this.parameters.branchid;
        this.formData.departmentid = this.parameters.departmentid;

        this.$store.dispatch('post', {
            url: "/reportemail/eaform", 
            payload: this.formData
          })
          .then((res) => {
            if (res.data.status == 201) {
              console.log(res.data.status);
              this.$dialog.alert(this.$t('columns.emailsuccessend')).then((dialog) => {
                console.log(dialog);
              });
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },
    displayHandler() {
      const vm = this;

      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation) &&
        (this.formData.eaform || this.formData.borangeform || this.formData.cp8dform)
        // !CheckIsEmpty(this.formData.email)&&
        // !CheckIsEmpty(this.formData.icno)
      ) {
        vm.loading = true;
        this.clearFilter();

        if (this.formData.eaform) {
          this.formData.reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf`;
          this.formData.userid = this.$store.getters.userId;

          this.formData.staffid = this.parameters.staffid;
          this.formData.staff = this.parameters.staffid;
          this.formData.companyid = this.parameters.companyid;
          this.formData.branchid = this.parameters.branchid;
          this.formData.departmentid = this.parameters.departmentid;

          this.$store.dispatch('post', {
              url: "/reportdisplay/eaform", 
              payload: this.formData,
              responseType: "blob"})
          .then((res) => {
              console.log(res.data);
              vm.loading = false;
              if (res.data && res.status == 200) {
                var fileURL = URL.createObjectURL(res.data);
                window.open(fileURL, "_blank");
              } else {
                vm.isErr = true;
                vm.errMsg = res.data.error;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
              }
            })
          .catch((err) => {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
        }

        if (this.formData.borangeform) {
             
          const reporturl =  `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.borangereportFile}&__format=pdf&Year=${this.formData.recyear}&officer=${this.formData.nameofofficeer}`
              + `&dbname=${this.$store.getters.dbname}`
              + `&designation=${this.formData.designation}&telephone=${this.formData.telephoneno}&email=${this.formData.email}&icno=${this.formData.icno}&userid=${this.$store.getters.userId}&companyid=${this.parameters.companyid}`;
          this.formData.userid = this.$store.getters.userId;
          this.formData.username = this.$store.getters.userName;
          
          this.$store.dispatch('post', {
              url: "/reportdisplay/url", 
              payload: {reporturl: reporturl, reportname: this.reportFile},
              responseType: "blob"})
          .then((res) => {
                  console.log(res.data);
                  vm.loading = false;
                  if (res.data && res.status == 200) {
                    var fileURL = URL.createObjectURL(res.data);
                    window.open(fileURL, "_blank");
                  } else {
                    vm.isErr = true;
                    vm.errMsg = res.data.error;
                    vm.loading = false;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                  }
                })
          .catch((err) => {
                  vm.isErr = true;
                  vm.errMsg = err;
                  vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
        }

        if (this.formData.cp8dform) {
          const vm = this;
          var yearrr = this.formData.recyear;
          this.formData.companyid = this.parameters.companyid;

          this.$store.dispatch('post', {
            url: `/payroll/cp8dreport/${this.$store.getters.userId}`, 
            payload: this.formData
          })
          .then(function (res) {
            var data = res.data.result;
            console.log(res);
            
            if (res.data.status == 200) {
              if (data.length > 0) {
                var cp8dZip = new JsZip();
                for (var i = 0; i < data.length; i++) {
                  cp8dZip.file(data[i].mf + ".txt", data[i].m);
                  cp8dZip.file(data[i].pf + ".txt", data[i].p.join("\n"));
                }
                cp8dZip
                .generateAsync({ type: "blob" })
                .then(function (content) {
                  // see FileSaver.js
                  download(content, `cp8d_${yearrr}.zip`);
                });
                vm.loading = false;
              }
            } else {
              vm.loading = false;
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch(function (err) {
            vm.loading = false;
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
        }
      }
    },
    goHandler() {
      if ((this.$refs.form.validate())){
      if (
        !CheckIsEmpty(this.formData.recyear) &&
        !CheckIsEmpty(this.formData.nameofofficeer) &&
        !CheckIsEmpty(this.formData.telephoneno) &&
        !CheckIsEmpty(this.formData.designation) 
        // !CheckIsEmpty(this.formData.email)&&
        // !CheckIsEmpty(this.formData.icno)
      ) {
        this.clearFilter();

        if (this.formData.eaform) {
          window.open(
            `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&Year=${this.formData.recyear}&officer=${this.formData.nameofofficeer}` +
              `&dbname=${this.$store.getters.dbname}` +
              `&designation=${this.formData.designation}&telephone=${this.formData.telephoneno}&userid=${this.$store.getters.userId}&companyid=${this.parameters.companyid}&staffid=${this.parameters.staffid}&branchid=${this.parameters.branchid}&deptid=${this.parameters.departmentid}`,
            "_blank" // <- This is what makes it open in a new window.
          );
        }

        if (this.formData.borangeform) {
          window.open(
            `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.borangereportFile}&__format=pdf&Year=${this.formData.recyear}&officer=${this.formData.nameofofficeer}
            &dbname=${this.$store.getters.dbname}
            &designation=${this.formData.designation}&telephone=${this.formData.telephoneno}&email=${this.formData.email}&icno=${this.formData.icno}&userid=${this.$store.getters.userId}&companyid=${this.parameters.companyid}`,
            "_blank" // <- This is what makes it open in a new window.
          );
        }
      }

        if (this.formData.cp8dform) {
          const vm = this;
          var yearrr = this.formData.recyear;
          this.formData.companyid = this.parameters.companyid;

          this.$store.dispatch('post', {
            url: `/payroll/cp8dreport/${this.$store.getters.userId}`, 
            payload: this.formData
          })
            .then(function (res) {
              var data = res.data.result;
              console.log(res);

              if (res.data.status == 200) {
                if (data.length > 0) {
                  var cp8dZip = new JsZip();
                  for (var i = 0; i < data.length; i++) {
                    cp8dZip.file(data[i].mf + ".txt", data[i].m);
                    cp8dZip.file(data[i].pf + ".txt", data[i].p.join("\n"));
                  }
                  cp8dZip
                    .generateAsync({ type: "blob" })
                    .then(function (content) {
                      // see FileSaver.js
                      download(content, `cp8d_${yearrr}.zip`);
                    });
                }
              } else {
                vm.isErr = true;
                vm.errMsg = res.data.error;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
              }
            })
            .catch(function (err) {
              vm.isErr = true;
              vm.errMsg = err;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        }
      }
    },
    updateValue() {
      this.$emit("input", {
        recyear: this.formData.recyear,
        inclzerotax: this.formData.inclzerotax,
        nameofofficeer: this.formData.nameofofficeer,
        designation: this.formData.designation,
        telephoneno: this.formData.telephoneno,
        email: this.formData.email,
        icno: this.formData.icno,
      });
    },
    clearFilter(){
      if (typeof this.parameters.staffid == "undefined" || this.parameters.staffid == null) {
        this.parameters.staffid = "";
      }
      if (typeof this.parameters.companyid == "undefined" || this.parameters.companyid == null) {
        this.parameters.companyid = "";
      }
      if (typeof this.parameters.branchid == "undefined" || this.parameters.branchid == null) {
        this.parameters.branchid = "";
      }
      if (typeof this.parameters.departmentid == "undefined" || this.parameters.departmentid == null) {
        this.parameters.departmentid = "";
      }
    }
  },

  mounted() {
    this.loadYears();
  },
};

function download(data, title) {
  console.log("download");
  console.log(data);
  const blob = new Blob([data], { type: "zip" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", title);

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function CheckIsEmpty(value) {
  var isEmpty = false;
  if (typeof value == "undefined" || value == "") {
    isEmpty = true;
  }
  return isEmpty;
}
</script>
<style lang="scss" scoped>
.v-subheader {
  padding: 0px !important;
}
</style>