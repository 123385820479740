    <template>
    <div>
        <v-dialog v-model="leaveentitlementdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setupleavesetup.depedencies')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                  <v-form ref="form">
                    <v-row>
                        <v-col cols="6" sm="6" md="6" >
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.leavegrp')"
                                type="text"
                                :items="groupList"
                                :rules="[rules.required]"
                                v-model="selections[0].lvgrpid"
                                @change="
                                        changeGroupHandler(
                                            selections[0].lvgrpid
                                        )
                                    "
                                dense
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" md="2" >
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="selections[0].valuecode"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" md="6" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.uptoserviceyears')"
                                type="number"
                                dense
                                v-model="selections[0].uptosrv"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.entitlement')"
                                type="number"
                                dense
                                v-model="selections[0].entitleunit"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" md="6" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.maxchildcount')"
                                type="number"
                                dense
                                v-model="selections[0].maxchild"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" md="6" class="mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.gender')"
                                type="text"
                                dense
                                :items="genderList"
                                v-model="selections[0].genderid"
                                @change="
                                    changeGenderHandler(
                                        selections[0].genderid
                                    )
                                "
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" md="2" class="mt-n8">
                            <v-text-field
                                outlined
                                background-color="#ffeab2"
                                readonly
                                :label="this.$t('columns.code')"
                                type="text"
                                v-model="selections[0].gendername"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupleaveentitlementpolicysetup.contractentitlementpermonth')"
                                type="number"
                                v-model="selections[0].cntrunitpermth"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                  </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeLeaveEntitlementPolicy">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveLeaveEntitlemtnPolicy">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title class="subtitle-2">{{$t('setupleaveentitlementpolicysetup.entitlementdetails')}}</v-toolbar-title>
            </v-toolbar>
        </template>
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },


            isBaitulmal: false,
            leaveentitlementdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                lvgrpid: null,
                valuecode: null,
                valuedesc: null,
                uptosrv: null,
                entitleunit: null,
                maxchild: null,
                genderid: null,
                gendername: null,
                cntrunitpermth: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }],
            groupList: [],
            genderList: [],

            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
        compareArray,

        loadGroup() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/lvgroup/all'})
            .then(res => {
                console.log('rec', res.data.rec)

                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.groupList.push({
                        text: res.data.rec[i].valvaluecodedesc,
                        code: res.data.rec[i].valvaluecode,
                        value: res.data.rec[i].valvalueid,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadGender() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/gender/all'})
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.genderList.push({
                        text: res.data.rec[i].gendername,
                        code: res.data.rec[i].gendercode,
                        value: res.data.rec[i].id,
                    });
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeGroupHandler(id) {
            let item = this.groupList.find(
                (item) => item.value === parseInt(id)
            );

            this.selections[0].valuecode = item.code;
            this.selections[0].valuedesc = item.text;
        },

        changeGenderHandler(id) {
            let item = this.genderList.find(
                (item) => item.value === parseInt(id)
            );
            this.selections[0].gendername = item.text;
        },

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }

            if(item.action == 2) {
                this.isExisting = true;
            }

            // this.selections = [{
            //     id: item.id,
            //     lvgrpid: item.lvgrpid,
            //     uptosrv: item.uptosrv,
            //     entitleunit: item.entitleunit,
            //     cntrunitpermth: item.cntrunitpermth,
            //     maxchild: item.maxchild,
            //     genderid: item.genderid
            // }]
            
            if (this.selections[0].lvgrpid) this.changeGroupHandler(this.selections[0].lvgrpid);
            if (this.selections[0].genderid) this.changeGenderHandler(this.selections[0].genderid);

            this.selections[0] = item;
            this.leaveentitlementdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeLeaveEntitlementPolicy() {
            this.leaveentitlementdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },
        // Saving baitulmal details into baitulmald
        saveLeaveEntitlemtnPolicy() {
            if (this.$refs.form.validate()) {
                this.isErr = false;
            // indicate value of 1 as insert, 2 as modify


            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
                
            } else {

                for (let i = 0; i < this.detailItem.length; i++) {
                    if (this.detailItem[i].id == this.selections[0].id) {
                        this.detailItem[i].lvgrpid = this.selections[0].lvgrpid
                        this.detailItem[i].valuecode = this.selections[0].valuecode
                        this.detailItem[i].uptosrv = this.selections[0].uptosrv
                        this.detailItem[i].entitleunit = this.selections[0].entitleunit
                        this.detailItem[i].cntrunitpermth = this.selections[0].cntrunitpermth
                        this.detailItem[i].maxchild = this.selections[0].maxchild
                        this.detailItem[i].genderid = this.selections[0].genderid
                    }
                }
            }
                console.log('detailItem', this.detailItem)
            
                this.$emit('input', this.detailItem);

                this.modify = false;
                this.isExisting = false;
                this.leaveentitlementdialog = false;

                // reset the form fields
                this.resetSelections();
            }
        },
        
        resetSelections() {
            this.selections = [{
                id: null,
                baitulmalid: null,
                wagesamt: null,
                amount: null,
                percentage: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },
    },

    created() {
        this.loadGroup();
        this.loadGender();
    }
}
</script>