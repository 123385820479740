<template>
    <div>
        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-container>

            <v-row>
                <v-btn 
                    
                    color="warning" 
                    style="width: 100px"
                    @click="backHandler" 
                    > 
                    {{$t('button.back')}}
                </v-btn>
            </v-row>

            <v-row>
                <h2 class="pb-3 mt-7">
                    {{ pageTitle }} for
                    <span
                    v-if="this.id"
                    class="page_title_value"
                    > {{ processname }}
                    </span>
                </h2>
            </v-row>

            <v-container
                id="scroll-target"
                class="custom-form overflow-y-auto px-5"
                v-if="this.id"
            >

                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            outlined
                            :label="this.$t('reportprocessloglisting.processname')"
                            type="text"
                            v-model="processname"
                            readonly
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n6">
                        <v-text-field
                            outlined
                            :label="this.$t('reportprocessloglisting.starttime')"
                            type="text"
                            v-model="starttime"
                            readonly
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="6" class="pb-n4 mt-n6">
                        <v-text-field
                            outlined
                            :label="this.$t('reportprocessloglisting.endtime')"
                            type="text"
                            v-model="endtime"
                            readonly
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" class="pb-n4 mt-n6">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.status')"
                            type="text"
                            v-model="status"
                            readonly
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="6" class="pb-n4 mt-n6">
                        <v-text-field
                            outlined
                            :label="this.$t('reportprocessloglisting.username')"
                            type="text"
                            v-model="username"
                            readonly
                            background-color="#ffeab2"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" class="pb-n4 mt-n6">
                        <v-textarea
                            outlined
                            :label="this.$t('reportprocessloglisting.log')"
                            type="text"
                            v-model="log"
                            readonly
                            auto-grow
                            background-color="#ffeab2"
                            dense
                        ></v-textarea>
                    </v-col>
                </v-row>

            </v-container>

        </v-container>

    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";

export default {

    components: {
        Loading,
    },

    data() {
        return {

            pageTitle: this.$t('reportprocessloglisting.processlogdetails'),

            id: this.$route.params.id,

            dataItems: [],

            processname: null,
            starttime: null,
            endtime: null,
            status: null,
            username: null,
            log: null,

            loading: false,

        }
    },

    methods: {

    loadProcessLogListing(){
            
      const vm = this;

      vm.loading = true;

        this.$store.dispatch('get', {url: "/processlogdetails/" + this.id})
         .then((res) => {

            vm.dataItems = res.data.rec;

            for (let i = 0; i < vm.dataItems.length; i++) {

                if (vm.dataItems[i].processname) {
                    vm.processname = (vm.dataItems[i].processname);
                }
                if (vm.dataItems[i].starttime) {
                    vm.starttime = (vm.dataItems[i].starttime = moment(vm.dataItems[i].starttime).format('YYYY-MM-DD - HH:mm:ss'));
                }
                if (vm.dataItems[i].endtime) {
                    vm.endtime = (vm.dataItems[i].endtime = moment(vm.dataItems[i].endtime).format('YYYY-MM-DD - HH:mm:ss'));
                }
                if (vm.dataItems[i].status) {
                    vm.status = (vm.dataItems[i].status);
                }
                if (vm.dataItems[i].username) {
                    vm.username = (vm.dataItems[i].username);
                }
                if (vm.dataItems[i].log) {
                    vm.log = (vm.dataItems[i].log);
                }
                
            }

            vm.loading = false;

        })
        .catch(function (err) {
            vm.errMsg = err;
        });

    },

    backHandler(){
        this.$router.push({name: 'processloglisting'});
    },

    },

    mounted() {
        this.loadProcessLogListing();
    },

}
</script>