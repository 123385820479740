<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('periodicincentivesetup.periodicattendanceincentivesetuplist'),
            dataHeader: [
                { text: this.$t('columns.actions'),  value: 'action',  width: '100px', sortable: false},
                { text: this.$t('setuppayitem.payitem'), value: 'payitemdesc', width: '200px' },
                { text: this.$t('columns.startdate'), value: 'startdate', width: '200px' },
                { text: this.$t('columns.enddate'), value: 'enddate', width: '200px' },
                { text: this.$t('periodicincentivesetup.minumumattendance'), value: 'minattpercent', width: '200px' },
                { text: this.$t('periodicincentivesetup.nolateness'), value: 'nolateness', width: '200px' },
                { text: this.$t('periodicincentivesetup.noearlyleaving'), value: 'noearlyleaving', width: '200px' },
                { text: this.$t('periodicincentivesetup.noleaverecord'), value: 'noleaverecord', width: '200px' },
            ],

            dataItems: [],
            getId: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {

        loadPeriodShiftIncList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/periodshiftinc/all'})
            .then (res => {
                vm.dataItems = res.data.rec;

                 for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                 }

            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        editItemHandler(item) {
            this.$router.push({name: 'periodshiftincedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'periodshiftincnew'});
        }
    },

    mounted() {
        this.loadPeriodShiftIncList()
    }
}
</script>