<template>

    <div>

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>
        
        <div>
            <h3 class="blue--text pl-4 pt-3 pb-2">{{$t('selfservicescreen.mytask')}}</h3>
        </div>

        <!-- <app-list v-bind:parentObject="{ leavetask: this.leavetaskHolder, claimtask: this.claimtaskHolder }" -->
        <app-task 
            v-bind:leaveTask="this.leavetaskHolder"
            v-bind:claimTask="this.claimtaskHolder"
            v-bind:actionButton="this.actions" 
            v-bind:actionButtonLabel="this.actionLabel"
            v-on:leave-action="this.leaveActionHandler"
            
            v-on:leave-route-details="loadLeaveDetail"
            v-on:claim-route="loadClaimDetail"
        />
        <!-- v-on:claim-action="claimActionHandler" -->

        <app-alert-event
            v-bind:subDialog="this.subDialog"
            v-bind:subDialogId="this.subDialogId"
            v-bind:subDialogTitle="this.subDialogTitle"
            v-bind:subDialogText="this.subDialogText"
            v-bind:subDetailTask="this.subDetailTask"
            v-bind:subDialogReject="this.subDialogReject"
            v-on:saveAction="this.saveAction"
            v-on:saveActionBulk="this.saveActionBulk"
            v-on:cancelAction="this.cancelAction"
        />

        <app-alert-error
            v-bind:errorDialog="errorDialog"
            v-bind:errorMessage="errorMessage"
            v-on:OkPressed="dismissHandler"
        />

    </div>
    
</template>

<script>

import Loading from "vue-loading-overlay";
import moment from 'moment';
import TaskGroup from '../../sscomponents/mytask/TaskGroup.vue';
import AlertEvent from '../../sscomponents/common/AlertEvent.vue'
import ErrorMessageSelService from '../../sscomponents/common/ErrorMessage.vue'


export default {

    components: {
        Loading,
        "app-task": TaskGroup,
        "app-alert-event": AlertEvent,
        "app-alert-error": ErrorMessageSelService
    },

    data() {
        return {
            loading: false,

            leavetaskHolder: [],
            claimtaskHolder: [],

            actions: [
                // {text: this.$t('taskapprovemobile.approve'), value: 1},
                {text: this.$t('taskapprovemobile.reject'), value: 2},
                {text: this.$t('taskapprovemobile.finalapprove'), value: 3}
            ],

            actionLabel: this.$t('columns.actions'),

            subDialog: false,
            subDialogTitle: '',
            subDialogText: '',
            subDialogId: null,
            subDetailTask: null,
            subDialogReject: false,

            selected: null,

            errorDialog: false,
            errorMessage: ''

        }
    },

    methods: {

        // Load Task
        loadTask() {

            let vm = this;

            this.$store.dispatch('get', {url: "/todolistworkflow/" + this.$store.getters.staffId})
            .then((res) => {

                vm.loading = true;

                let mytask = res.data.rec;
                let leaveHolder = [];
                let claimHolder = [];

                for(let i = 0; i < mytask.length; i++){

                    mytask[i].datetime = moment(mytask[i].wftranddate).format('YYYY-MM-DD HH:mm')
                    mytask[i].entrytime = moment(mytask[i].entrytime).format('YYYY-MM-DD HH:mm')

                    if (mytask[i].wftypeid == 3) {
                        mytask[i].ttype = `${this.$t('homemobile.submitted')} ` + mytask[i].leavedesc;
                        mytask[i].active = false;
                        leaveHolder.push(mytask[i])
                    }

                    if (mytask[i].wftypeid == 7) {
                        let wftranholder = claimHolder.filter(val => val.wftranid == mytask[i].wftranid)[0]
                        if (!wftranholder) {
                            claimHolder.push({
                                wftranid: mytask[i].wftranid,
                                clmtypeid: mytask[i].clmtypeid,
                                clmtype: mytask[i].clmtype,
                                clmtypdesc: mytask[i].clmtypdesc,
                                recordid: mytask[i].recordid,
                                ttype: `${this.$t('homemobile.submitted')} ` + mytask[i].clmtypdesc,
                                entrytime: mytask[i].entrytime,
                                claimdetails: [],
                                reqstaffid: mytask[i].reqstaffid,
                                maxreminder: mytask[i].maxreminder,
                                wfdueactionid: mytask[i].wfdueactionid,
                                remindnext: mytask[i].remindnext,
                                level: mytask[i].level,
                                hrlast: mytask[i].hrlast,
                                exception: mytask[i].exception,
                                active: false,
                            })
                        }
                        let index = claimHolder.findIndex(val => val.wftranid == mytask[i].wftranid)
                        claimHolder[index].claimdetails.push(mytask[i])
                    }

                }

                vm.leavetaskHolder = leaveHolder;
                vm.claimtaskHolder = claimHolder;

                vm.loading = false;

            })
            .catch((err) => {
                console.error(err)
            });
        },

        // Route To Leave Detail
        loadLeaveDetail(info) {
            if (info.attachment) {

                let stringh = info.attachment
                
                let ftypeHolder = stringh.match('application')
                let ftypeHolder2 = stringh.match('image')
                
                if(ftypeHolder){
                    if (ftypeHolder[0] == 'application'){ info.filetype = 'PDF' }
                }
                
                if(ftypeHolder2){
                    if (ftypeHolder2[0] == 'image'){ info.filetype = 'Image' }
                }

            }
            this.$router.push({name: 'selfserviceleaveapproval', params: { item: info }});
        },

        // Route To Claim Detail
        loadClaimDetail(info) {
            this.$router.push({name: 'selfserviceclaimapproval', params: { item: info }});
        },

        leaveActionHandler(event, param) {
            const vm = this;
            let activeLeaveItem = []
            
            // Loop through all leave task
            if(param){
                for(let i = 0; i < param.length; i++) {
                    // Check choosen leave task
                    if(param[i].active == true){
                        activeLeaveItem.push(param[i]);
                    }
                }
            } else {
                console.error('No Task');
            }
            

            // Check if had a choosen leave task to perform action
            if(activeLeaveItem.length > 0) {
                let activeLeave = activeLeaveItem
                if(event){
                    vm.checkAction(event, activeLeave);
                    console.log(activeLeave);
                }
            } else {
                if(event){
                    vm.errorDialog = true
                    vm.errorMessage = this.$t('norectoprocess')
                    // console.log(this.$t('norectoprocess'));
                }
            }

        },

        // claimActionHandler(event, param) {
        //     const vm = this;
        //     let activeClaimItem = []

        //     vm.isErr = true
        //     vm.errMsg = 'Test'

        //     // Loop through all leave task
        //     for(let i = 0; i < param.length; i++) {
        //         // Check choosen leave task
        //         if(param[i].active == true){
        //             activeClaimItem.push(param[i]);
        //         }
        //     }

        //     // Check if had a choosen leave task to perform action
        //     if(activeClaimItem.length > 0) {
        //         let activeClaim = activeClaimItem
        //         if(event){
        //             vm.checkAction(event, activeClaim);
        //             console.log(activeClaim);
        //         }
        //     } else {
        //         if(event){
        //             vm.isErr = true
        //             vm.errMsg = 'No Item Selected'
        //             console.log('No Item Selected')
        //         }
        //     }
        // },

        checkAction(action, param){
            
            // if(action == 1){ 
            //     console.log('Approve')
            //     this.subDialogId = 3 //Final Approve ID
            //     // Perform Action to Approve
            //     this.subDialog = true
            //     this.subDialogTitle = this.$t('selfservicemytask.confirmapprove')
            //     this.subDialogText = this.$t('selfservicemytask.approvetask')
            //     this.subDetailTask = param
            // }

            if(action == 2){ 

                console.log('Reject')
                this.subDialogId = 2 //Reject ID
                this.subDialogReject = true
                // Perform Action to Reject
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmrej') 
                this.subDialogText = this.$t('selfservicemytask.rejecttask')
                this.subDetailTask = param 
                console.log('Test', param)
            }
            
            if(action == 3){ 
                console.log('Final Approve')
                this.subDialogId = 1 //Final Approve ID
                // Perform Action to Final Approve
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmfinapprove')
                this.subDialogText = this.$t('selfservicemytask.approvefintask')
                this.subDetailTask = param
            }
        },

        saveAction($event, param) {
            console.log('Save')
            console.log($event);
            console.log(param);
            
            // Perform Action to final approve
            if($event == 1){
                this.loading = true;
                for(let i = 0; i < param.length; i++){
                    this.taskFinalApproval(param[i], i)
                }
                this.$router.push({name: 'selfservicemytask'});
                this.loading = false;
            }
            
        },

        saveActionBulk($event, param, remark){
            console.log('Reject Bulk')
            console.log($event);
            console.log(param);
            console.log(remark);

            // Bulk Reject Action
            if($event == 2){

                this.loading = true
                for( let i = 0; i < param.length; i++){

                    // Assign reject remark for each data
                    param[i].aprvremark = remark;

                    if(param[i].aprvremark){
                        this.taskApproval('REJECT', param[i], i)
                    } else {
                        console.error(this.$t('selfservicemytask.remcolrequired'))
                    }

                }
                this.$router.push({name: 'selfservicemytask'});
                this.loading = true

            }

            this.resetSubDialog();
        },

        cancelAction() {
            console.log('Cancel')
            this.resetSubDialog();
        },

        resetSubDialog(){
            this.subDialogId = null
            this.subDialog = false
            this.subDialogTitle = ''
            this.subDialogText = ''
            this.subDialogTask = null
            this.subDialogReject = false
        },

        taskFinalApproval(value, index){

            const vm = this;

            // URL for leave task
            if(value.leavetypeid){

                this.$store.dispatch('put', {url: "/finalapprovalworkflow/approve", payload: value})
                .then((res) => {
                    const result = res.data;
                    if (result.status === 200) {
                        console.log('Success')
                    } else {
                        console.error(`${result.error} ${this.$t('selfservicemytask.atindex')} ${index}`);
                    }
                }).catch((err) => {
                    vm.loading = false;
                    console.error(err)
                });

            }

            // URL for claim task
            // if(value.clmtypeid){

            //     let data = []
            //     data.push(value)
                
            //     this.$store.dispatch('put', {url: "/finalapprovalworkflow/claim", payload: data})
            //     .then((res) => {
            //         const result = res.data;
            //         if (result.status === 200) {
            //             vm.$router.push({name: 'selfservicemytask'});
            //         } else {
            //             console.error(result.error);
            //         }
            //     }).catch((err) => {
            //         vm.loading = false;
            //         console.error(err)
            //     });

            // }

        },

        taskApproval(text, value, index) {

            const vm = this;

            // URL for leave task
            if(value.leavetypeid){

                value.apraction = text
                
                this.$store.dispatch('put', {url: "/approvalworkflow/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: value})
                .then((res) => {
                    const result = res.data;
                    if (result.status === 200) {
                        console.log('Success')
                    } else {
                        console.error(`${result.error} ${this.$t('selfservicemytask.atindex')} ${index}`);
                    }
                }).catch((err) => {
                    vm.loading = false;
                    console.error(err)
                });
                
            }

        },

        dismissHandler(){
            this.errorDialog = false
            this.errorMessage = ''
        }

    },
    
    mounted() {
        this.loadTask();
    }

}

</script>

<style scoped>
.dark-bg {
    background-color: #F4F4F4;
}
.custom-bg {
    background-color: #1278D7;
}
</style>
