import { render, staticRenderFns } from "./ClaimDetails.vue?vue&type=template&id=5067a39b&scoped=true&"
import script from "./ClaimDetails.vue?vue&type=script&lang=js&"
export * from "./ClaimDetails.vue?vue&type=script&lang=js&"
import style0 from "./ClaimDetails.vue?vue&type=style&index=0&id=5067a39b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5067a39b",
  null
  
)

export default component.exports