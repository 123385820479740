<template>

    <v-container  fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
 
     <loading
         :active.sync="loading"
         :can-cancel="false"
         :is-full-page="true"
         :height="150"
         :width="150"
         :color="'#FFFF00'"
         :background-color="'#000000'"
         :opacity="0.5"
     ></loading>
       
       <v-row class="me-n5 mt-n4 mb-n8">
             <v-col
             cols="8"
             sm="8"
             class="ms-n2"
             >
                <v-btn 
                   color="warning" 
                   style="width: 100px"
                   @click="backHandler" 
                   > 
                   {{$t('button.back')}}
                </v-btn>
             </v-col>
 
             <v-col
             cols="4"
             sm="4"
             class="pe-0"
             >
             <v-autocomplete
                outlined
                :label="this.$t('reportstaffenquiry.hide_showcolumn')"
                type="text"
                v-model="hideColumn"
                :items="hideList"
                dense
                clearable
                multiple
             ></v-autocomplete>
             </v-col>
       </v-row>
 
       <v-row class="mt-2 mb-2">
       <v-expansion-panels>
          <v-expansion-panel>
             <v-expansion-panel-header>
             <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
             >{{$t('payrollenquiryreport.advancefilter')}}</v-subheader>
             </v-expansion-panel-header>
                   
                   <v-expansion-panel-content>
 
                      <div class="mt-8">
 
                         <v-row>
                            <v-col
                               cols="3"
                               sm="3"
                               class="pb-4 mt-n8"
                            >
                            <v-autocomplete
                               outlined
                               :label="this.$t('reportsparam.parameters')"
                               type="text"
                               v-model="columnName"
                               :items="columnList"
                               dense
                               clearable
                            ></v-autocomplete>
                            </v-col>
 
                            <v-col
                               cols="2"
                               sm="2"
                               class="pb-4 mt-n8"
                            >
                            <v-autocomplete
                               outlined
                              :label="this.$t('reportstaffenquiry.operation')"
                               type="text"
                               v-model="operationValue"
                               :items="operationList"
                               dense
                               clearable
                            ></v-autocomplete>
                            </v-col>
 
                            <v-col
                               cols="3"
                               sm="3"
                               class="pb-4 mt-n8"
                               v-if="switchSearchType === 'A'"
                            >
                            <v-autocomplete
                               outlined
                               :label="this.$t('reportstaffenquiry.data')"
                               type="text"
                               v-model="searchValue"
                               :items="searchList"
                               dense
                               clearable
                            ></v-autocomplete>
                            </v-col>
 
                            <v-col
                               cols="3"
                               sm="3"
                               class="pb-4 mt-n8"
                               v-else-if="switchSearchType === 'B'"
                            >
                            <v-text-field
                               :label="this.$t('reportstaffenquiry.search')"
                               v-model="userSearch"
                               style="width: 250px"
                               hide-details
                            ></v-text-field>
                            </v-col>
 
                            <!-- <v-col
                               cols="3"
                               sm="3"
                               class="pb-4 mt-n8"
                               v-else-if="switchSearchType === 'C'"
                            >
                            <v-text-field
                               outlined
                               :label="this.$t('setupwizardsetup.number')"
                               type="number"
                               v-model="numberValue"
                               dense
                            ></v-text-field>
                            </v-col> -->
 
                            <v-col
                               cols="3"
                               sm="3"
                               class="pb-4 mt-n8"
                               v-else-if="switchSearchType === 'D'"
                            >
                            <v-text-field
                               outlined
                               :label="this.$t('columns.date')"
                               type="date"
                               v-model="dateValue"
                               dense
                            ></v-text-field>
                            </v-col>
 
                            <v-tooltip bottom color="warning" v-if="refresh === false">
                            <template v-slot:activator="{ on, attrs }">
                                  <v-avatar class="mx-3 mt-n2" size="23" tile>
                                     <img
                                     :src="`${publicPath}images/reload.png`"
                                     @click="refreshTable"
                                     v-bind="attrs"
                                     v-on="on"
                                     />
                                  </v-avatar>
                            </template>
                            <span>{{$t('reportstaffenquiry.loaddata')}}</span>
                            </v-tooltip>
 
                            <v-tooltip bottom color="warning" v-if="refresh === false">
                            <template v-slot:activator="{ on, attrs }">
                                  <v-avatar class="mx-3 mt-n2" size="23" tile>
                                     <img
                                        :src="`${publicPath}images/and_orange.png`"
                                        @click="addSearchFilter"
                                        v-bind="attrs"
                                        v-on="on"
                                     />
                                  </v-avatar>
                            </template>
                            <span>{{$t('reportstaffenquiry.and')}}</span>
                            </v-tooltip>
 
                            <v-tooltip bottom color="warning" v-if="refresh === false">
                            <template v-slot:activator="{ on, attrs }">
                                  <v-avatar class="mx-3 mt-n2" size="23" tile>
                                     <img
                                        :src="`${publicPath}images/or.png`"
                                        @click="orFilter"
                                        v-bind="attrs"
                                        v-on="on"
                                     />
                                  </v-avatar>
                            </template>
                            <span>{{$t('reportstaffenquiry.or')}}</span>
                            </v-tooltip>
 
                            <v-tooltip bottom color="warning">
                            <template v-slot:activator="{ on, attrs }">
                                  <v-avatar class="mx-3 mt-n2" size="23" tile>
                                     <img
                                        :src="`${publicPath}images/bin_orange.png`"
                                        @click="clearTable"
                                        v-bind="attrs"
                                        v-on="on"
                                     />
                                  </v-avatar>
                            </template>
                            <span>{{$t('reportstaffenquiry.clearallfilter')}}</span>
                            </v-tooltip>
 
                         </v-row>
 
                         <v-row class="mx-3 mt-n8">
                            <h4>{{userSql}}</h4>
                         </v-row>
 
                         <v-row v-if="refresh === false">
                            <ul v-for="(content, index) in userArr" :key="index">
                               <v-tooltip bottom color="error">
                               <template v-slot:activator="{ on, attrs }">
                                  <li @click="removeFilter(content)"
                                  v-bind="attrs"
                                  v-on="on" 
                                  >{{content}}</li> 
                               </template>
                               <span>{{$t('reportstaffenquiry.remove')}}</span>
                               </v-tooltip>
                            </ul>
                         </v-row>
 
                      </div>  
 
                   </v-expansion-panel-content>
 
          </v-expansion-panel>
       </v-expansion-panels>
       </v-row>
 
       <br>
 
       <v-row>
       <v-toolbar color="#ECEFF1">
 
          <h2 style="color: #3f6892; padding-left: 20px">
          {{ pageTitle }}
          </h2>
          <v-spacer></v-spacer>
 
             <v-avatar size="40" tile>
                <img
                   :src="`${publicPath}images/export_csv.png`"
                   @click="getCSV"
                />
             </v-avatar>
             <v-avatar size="40" tile>
                <img
                   :src="`${publicPath}images/export_xls.png`"
                   @click="getXLSX"
                />
             </v-avatar>
             
             <v-col
                cols="3"
                sm="3"
                class="mt-6"
             >
             <v-autocomplete
                outlined
                :label="this.$t('reportstaffenquiry.grpby')"
                type="text"
                v-model="groupName"
                :items="groupList"
                background-color="white" 
                dense
                clearable
             ></v-autocomplete>
             </v-col>
 
             <v-tooltip bottom color="warning">
             <template v-slot:activator="{ on, attrs }">
                   <v-avatar class="mx-3" size="18" tile>
                      <img
                         :src="`${publicPath}images/reload.png`"
                         @click="addGroupBy"
                         v-bind="attrs"
                         v-on="on"
                      />
                   </v-avatar>
             </template>
             <span>{{$t('reportstaffenquiry.addgrp')}}</span>
             </v-tooltip>
 
             <v-tooltip bottom color="warning">
             <template v-slot:activator="{ on, attrs }">
                <v-avatar class="mx-3" size="18" tile>
                   <img
                      :src="`${publicPath}images/bin_orange.png`"
                      @click="clearGroupBy"
                      v-bind="attrs"
                      v-on="on"
                   />
                </v-avatar>
             </template>
             <span>{{$t('reportstaffenquiry.clearallgrp')}}</span>
             </v-tooltip>
 
       </v-toolbar>
       </v-row>
 
       <!-- --------------------------------- LIST SEARCH GROUP --------------------------------------- -->
       
       <v-row class="mt-4">
          <h4>{{$t('reportstaffenquiry.clearparameterused')}}</h4>
       </v-row>
 
       <v-row>
          <ul v-for="(content, index) in userGroup" :key="content">
             <v-tooltip bottom color="error">
             <template v-slot:activator="{ on, attrs }">
                <li @click="deleteGroupBy(index)"
                v-bind="attrs"
                v-on="on" 
                >{{content}}</li>
             </template>
             <span>{{$t('reportstaffenquiry.remove')}}</span>
             </v-tooltip>
          </ul>
       </v-row>
       
    <!-- --------------------------------- TABULATOR --------------------------------------- -->
       <v-row class="mt-4">
          <div ref="table"></div>
       </v-row>
 
    </v-container>
 </template>
 
 <script>
 import {TabulatorFull as Tabulator} from 'tabulator-tables';
 import moment from 'moment';
 import Loading from "vue-loading-overlay";
 
 export default {
 
    components: {
         Loading
    },
 
    data() {
       return {
 
          // Image Path
          publicPath: process.env.BASE_URL,
 
          // Param - leave enquiry as at
          pageTitle: this.$t('reportstaffenquiry.leaveenquiryasat') + this.$route.params.asatdate,
 
 
          // Tabulator Function
          tabulator: null, //variable to hold your table
          tableData: [], //data for table to display
         
          // Default Setting
          column: [
             {title: this.$t('columns.staffno'), field:"staffcode"}, 
             {title: this.$t('columns.staffname'), field:"psnname"},
             {title: this.$t('recpersonalinfo.idno'), field:"idno"},    
             {title: this.$t('columns.department'), field:"deptname"},
             {title: this.$t('columns.position'), field:"posdesc"},
             {title: this.$t('generateunpaidleaverecord.leavetype'), field:"leavedesc"},
             {title: this.$t('columns.startdate'), field:"startdate"},
             {title: this.$t('columns.enddate'), field:"enddate"},
             {title: this.$t('recleaverec.leavereason'), field:"reasondesc"},
             {title: this.$t('panelclinic'), field:"clinicdesc"},
             {title: this.$t('recleaverec.non_panelclinic'), field:"nonpanelclinic"},
             {title: this.$t('recleaverec.reliefstaffcode'), field:"reliefstaffcode"},
             {title: this.$t('recleaverec.reliefstaff'), field:"reliefname"},
             {title: this.$t('columns.status'), field:"transtatus"},
             {title: this.$t('recleaverec.leaverecremark'), field:"lvremarks"},
             {title: this.$t('recleaverec.apprvlremark'), field:"aprvremark"},
             {title: this.$t('recleaverec.submittedby'), field:"entrybyusername"},
             {title: this.$t('recleaverec.submissioninfo'), field:"src"},
             {title: this.$t('reportleavedashboard.leavedays'), field:"lvdays"},
             {title: this.$t('claimrecordlisting.historydata'), field:"ishistory"},
             {title: this.$t('setupleavesetup.minunit_transaction'), field:"minpertran"},
             {title: this.$t('setupleavesetup.maxunit_transaction'), field:"maxpertran"},
             {title: this.$t('setupleavesetup.earlynotice'), field:"earlynotice"},
             {title: this.$t('statutoryexportcommonused.payoutdescription'), field:"payoutdesc"},
             {title: this.$t('recleaverec.partialstarttime'), field:"startdatetime"},
             {title: this.$t('recleaverec.partialendtime'), field:"enddatetime"},
          ],
          operationList: [
             {text:'=' , value:'='},
             {text:'<' , value:'<'},
             {text:'<=' , value:'<='},
             {text:'>' , value:'>'},
             {text:'>=' , value:'>='},
             {text:'!=' , value:'!='},
             {text:'Like' , value:'like'},
          ],
          switchSearchType: 'A',
 
          // V-model
          columnName: '',
          operationValue: '',
          userSearch: '',
          dateValue: '',
          searchValue: '',
         //  numberValue: '',
 
          //:List
          columnList: [],
          searchList: [],
          hideList: [],
          hideColumn: [],
          
          // Group By Function
          userGroup: [],
          group: [],
          groupList: [],
          groupName: '',
 
          // Test Data
          test: [undefined, undefined, undefined],
          sqlConstruct: [],
          userSeen: [],
          userArr: [],
          dummy: [],
          userSql: '',
          refresh: false,
 
          loading: false,
          errMsg: ''
       }
    },
 
    watch: {
       columnName() {
 
          if(this.columnName === undefined) {
             this.searchList = [];
             this.searchValue = '';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'<' , value:'<'},
                   {text:'<=' , value:'<='},
                   {text:'>' , value:'>'},
                   {text:'>=' , value:'>='},
                   {text:'!=' , value:'!='},
                   {text:'Like' , value:'like'},
                ]
             if(this.operationValue === undefined || this.operationValue !== 'like') {
                this.switchSearchType = 'A';
                this.userSearch = '';
               //  this.numberValue = '';
                this.dateValue = '';
             } else if(this.operationValue === 'like') {
                this.switchSearchType = 'B'
                this.searchValue = '';
               //  this.numberValue = '';
                this.dateValue = '';
             }
          }
         //   else if(this.columnName === 'age' || this.columnName === 'sp' || this.columnName === 'salary') {
         //     this.switchSearchType = 'C';
         //     this.operationValue = undefined;
         //     this.operationList = [
         //        {text:'=' , value:'='},
         //        {text:'<' , value:'<'},
         //        {text:'<=' , value:'<='},
         //        {text:'>' , value:'>'},
         //        {text:'>=' , value:'>='},
         //     ]
         //     this.searchValue = '';
         //     this.userSearch = '';
         //     this.dateValue = '';
         //  } 
          else if( this.columnName === 'startdate' || this.columnName === 'enddate' ) {
             this.searchValue = '';
             this.userSearch = '';
            //  this.numberValue = '';
             this.operationValue = undefined;
             this.switchSearchType = 'D';
             this.operationList = [
                {text:'=' , value:'='},
                {text:'<' , value:'<'},
                {text:'<=' , value:'<='},
                {text:'>' , value:'>'},
                {text:'>=' , value:'>='},
             ]
          } else {
             this.operationValue = undefined;
             const vm = this;
 
             vm.loading = true;
             vm.searchList = [];
             
              this.$store.dispatch('get', {url: "/leaveenquiry/" + vm.asatdate + "/" + vm.activedate})
                .then((res) => {
                   let item = res.data.rec;
       
                   let data = item.map(val => val[vm.columnName]); // .map is to take only "data" for that column : item.map(psnname);
 
                   for( let i = 0; i < data.length; i++){
                      vm.searchList.push(data[i]);
                   }
 
                   vm.loading = false;
 
                })
                .catch(function (err) {
                   vm.errMsg = err;
                });
             
             if(this.operationValue === undefined) {
                this.switchSearchType = 'A';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'!=' , value:'!='},
                   {text:'Like' , value:'like'},
                ]
                this.userSearch = '';
               //  this.numberValue = '';
                this.dateValue = '';
             } else if (this.operationValue === 'like') {
                this.switchSearchType = 'B';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'!=' , value:'!='},
                   {text:'Like' , value:'like'},
                ]
                this.searchValue = '';
               //  this.numberValue = '';
                this.dateValue = '';
             } else {
                this.switchSearchType = 'A';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'!=' , value:'!='},
                   {text:'Like' , value:'like'},
                ]
                this.userSearch = '';
               //  this.numberValue = '';
                this.dateValue = '';
             }
          }
 
          if(this.test[0] !== ''){
             this.test.splice(0, 1, this.columnName);
          } else {
             this.test.splice(0, 0, this.columnName);
          }
       },
 
       operationValue() {
 
          if(this.operationValue === undefined || this.operationValue !== 'like') {
             if(this.columnName === undefined) {
                this.switchSearchType = 'A';
                this.userSearch = '';
               //  this.numberValue = '';
                this.dateValue = '';
             } 
            //  else if(this.columnName === 'age' || this.columnName === 'sp' || this.columnName === 'salary') {
            //     this.switchSearchType = 'C';
            //     this.operationList = [
            //        {text:'=' , value:'='},
            //        {text:'<' , value:'<'},
            //        {text:'<=' , value:'<='},
            //        {text:'>' , value:'>'},
            //        {text:'>=' , value:'>='},
            //     ]
            //     this.searchValue = '';
            //     this.userSearch = '';
            //     this.dateValue = '';
            //  } 
             else if( this.columnName === 'startdate' || this.columnName === 'enddate') {
                this.switchSearchType = 'D';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'<' , value:'<'},
                   {text:'<=' , value:'<='},
                   {text:'>' , value:'>'},
                   {text:'>=' , value:'>='},
                ]
                this.searchValue = '';
                this.userSearch = '';
               //  this.numberValue = '';
             } else {
                this.switchSearchType = 'A';
                this.operationList = [
                   {text:'=' , value:'='},
                   {text:'!=' , value:'!='},
                   {text:'Like' , value:'like'},
                ]
                this.userSearch = '';
               //  this.numberValue = '';
                this.dateValue = '';
             }
          } else {
             this.switchSearchType = 'B';
             this.searchValue = '';
            //  this.numberValue = '';
             this.dateValue = '';
          }
 
          if(this.test[1] !== '') {
             this.test.splice(1, 1, this.operationValue);
          } else {
             this.test.splice(1, 0, this.operationValue);
          }
       },
 
       searchValue() {
          if(this.searchValue !== '') {
             if(this.test[2] !== '') {
                this.test.splice(2, 1, "'"+ this.searchValue + "'");
             } else {
                this.test.splice(2, 0, "'"+ this.searchValue + "'");
             }
          } else {
             this.test[2] = undefined;
          }
 
       },
 
       userSearch() {
          if(this.userSearch !== '') {
             if(this.test[2] !== '') {
                this.test.splice(2, 1, "'"+ "%" + this.userSearch + "%" + "'");
             } else {
                this.test.splice(2, 0, "'"+ "%" + this.userSearch + "%" + "'");
             }
          } else {
             this.test[2] = undefined;
          }
 
       },
 
      //  numberValue() {
      //     this.spliceFunction(this.numberValue, this.test);
      //  },
 
       dateValue() {
 
          if(this.dateValue !== '') {
             if(this.test[2] !== '') {
                this.test.splice(2, 1, "'"+ this.dateValue + "'");
             } else {
                this.test.splice(2, 0, "'"+ this.dateValue + "'");
             }
          } else {
             this.test[2] = undefined;
          }
       },
 
       hideColumn() {
 
          for(let i = 0; i < this.column.length; i++){
             let arr = this.column[i].field;
             if(this.hideColumn.includes(arr)) {
                this.tabulator.hideColumn(arr);
             } else {
                this.tabulator.showColumn(arr);
             }
          }
 
       },
 
       userSeen() {
 
          this.userArr = [];
          
          if(this.refresh === false){
             this.userSql = '';
          }
 
          for(let i = 0; i < this.userSeen.length; i++) {
             
             if(this.userSeen[i] == ' AND ' || this.userSeen[i] == ' OR ') {
                console.log(this.userSeen[i]);
             } else {
                this.userArr.push(this.userSeen[i]);
             }
             
             this.userSql += this.userSeen[i];
          }
          
       }, 
 
    },
 
    methods: {
 
       // Column Name
       getColumnBy() {
          for(let i = 0; i < this.column.length; i++) {
             this.columnContent(this.groupList, i);
             this.columnContent(this.columnList, i);
             this.columnContent(this.hideList, i);
          }
       },
 
       addGroupBy() {
          if(this.groupName !== '') {
             this.userGroup.push(this.sqlSeenColumn(this.groupName));
             this.group.push(this.groupName); 
             this.tabulator.setGroupBy(this.group);
             this.groupName = '';
          }
       },
 
       clearGroupBy() {
          this.group = [];
          this.userGroup = [];
          this.tabulator.setGroupBy(this.group);
       },
 
       deleteGroupBy(x) {
          this.userGroup.splice(x,1);
          this.group.splice(x,1); // Removing Method
          this.tabulator.setGroupBy(this.group);
       },
 
       addSearchFilter() {
          
          if(this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined) {
             let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
             this.userSeen.push(userSeen);
             this.userSeen.push(' AND ');
            //  if(this.test[0] == 'age' || this.test[0] == 'sp'){
            //     let filter = this.sqlDateDiff(this.test[0], this.asatdate) + ' ' + this.test[1] + ' ' + this.test[2];
            //     this.sqlConstruct.push(filter);
            //  }
            
               let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
                this.sqlConstruct.push(filter);
             
             // this.sqlConstruct.push(filter);
             this.sqlConstruct.push(' AND ');
             this.resetValue();
          } else {
             alert(this.$t('reportstaffenquiry.fillinfilter'));
          }
 
       },
 
       orFilter() {
         
        if(this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined) {
             let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
             this.userSeen.push(userSeen);
             this.userSeen.push(' OR ');
            //  if(this.test[0] == 'age' || this.test[0] == 'sp'){
            //     let filter = this.sqlDateDiff(this.test[0], this.asatdate) + ' ' + this.test[1] + ' ' + this.test[2];
            //     this.sqlConstruct.push(filter);
            //  } 
             
               let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
                this.sqlConstruct.push(filter);
             
             // let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
             // this.sqlConstruct.push(filter);
             this.sqlConstruct.push(' OR ');
             this.resetValue();
          } else {
             alert(this.$t('reportstaffenquiry.fillinfilter'));
          }
 
       },
 
       deleteFilter(y) {
 
          if( this.userSeen[y] == ' AND ' || this.userSeen[y] == ' OR ' ) {
             console.log('Cannot be deleted');
          } else {
 
             if(this.userSeen.length < 3) {
                this.userSeen.splice(y,1); // Removing Method
                this.sqlConstruct.splice(y,1);
                this.userSeen.splice(y,1); 
                this.sqlConstruct.splice(y,1);
             } else {
                if( y === 0) {
                   this.userSeen.splice(y,1); // Removing Method
                   this.sqlConstruct.splice(y,1);
                   this.userSeen.splice(y,1); 
                   this.sqlConstruct.splice(y,1);
                } else {
                   this.userSeen.splice(y-1,1); // Removing Method
                   this.sqlConstruct.splice(y-1,1);
                   this.userSeen.splice(y-1,1); 
                   this.sqlConstruct.splice(y-1,1);
                }
                
             }
          }
 
       },
 
       removeFilter(x) {
          if(this.userSeen.includes(x)) {
             let i = this.userSeen.indexOf(x);
             this.deleteFilter(i);
          }
       },
 
       refreshTable() {
 
          if((this.test[0] !== undefined && this.test[1] !== undefined && this.test[2] !== undefined)) {
             let userSeen = this.sqlSeenColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
             this.userSeen.push(userSeen);
            //  if(this.test[0] == 'age' || this.test[0] == 'sp'){
            //     let filter = this.sqlDateDiff(this.test[0], this.asatdate) + ' ' + this.test[1] + ' ' + this.test[2];
            //     this.sqlConstruct.push(filter);
            //  } 
             
               let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
                this.sqlConstruct.push(filter);
             
             // let filter = this.sqlColumn(this.test[0]) + ' ' + this.test[1] + ' ' + this.test[2];
             // this.sqlConstruct.push(filter);
             this.sqlQuery(this.sqlConstruct);
             this.resetValue();
 
             this.refresh = !this.refresh;
             
             const vm = this;
 
             vm.loading = true;
             
             this.$store.dispatch('post', {url: "/leaveenquirydata/" + vm.asatdate + "/" + vm.asatdate + "/" + vm.activedate, payload: {dummy: vm.dummy}})
                .then((res) => {
                   let data = res.data.rec;
 
                   for (let i = 0; i < data.length; i++) {
                      if (data[i].startdate) {
                            data[i].startdate = moment(data[i].startdate).format('YYYY-MM-DD');
                      }
                   }

                   for (let i = 0; i < data.length; i++){
                     if (data[i].enddate) {
                            data[i].enddatedate = moment(data[i].enddate).format('YYYY-MM-DD');
                      }
                     }
 
                   vm.tabulator.setData(data);
                   vm.sqlConstruct = [];
                   vm.dummy = [];
                   vm.loading = false;
 
                })
                .catch(function (err) {
                   vm.errMsg = err;
                });
 
         } else if(this.sqlConstruct.length !== 0) {
             alert(this.$t('reportstaffenquiry.expectanotherfilter'));
         } else {
             alert(this.$t('reportstaffenquiry.fillinfilter'));
         }
 
       },
 
       clearTable() {
          this.userSeen = [];
          this.userArr = [];
          this.userSql = '';
          this.sqlConstruct = [];
          this.dummy = [];
          this.tabulator.setData(this.tableData);
          this.refresh = false;
       },
 
       // Repeated Code Function
 
       columnContent(x, y) {
          x.push(
                {text: this.column[y].title, value: this.column[y].field}
          );
       },
 
       spliceFunction(value, store) {
          if(value !== '') {
             if(store[2] !== '') {
                store.splice(2, 1, value);
             } else {
                store.splice(2, 0, value);
             }
          } else {
             store[2] = undefined;
          }
 
       },
 
       sqlColumn(x) {
          if(x === 'clmtypecat' || x === 'clmtypdesc' || x === 'compnyname') {
             return x = 'vc.' + x;
          } else if( x === 'cessadate' || x === 'recdate' || x === 'recenddate') {
            return x = 'vs.' + x;
          } else {
             return x;
          }
       },
 
       sqlSeenColumn(x) {
 
          for(let i = 0; i < this.column.length; i++) {
             let field =  this.column[i].field;
             let title = this.column[i].title;
 
             if( field === x ) {
                return x = title;
             }
          } 
 
       },
 
       sqlQuery(x) {
 
          for(let i = 0; i < x.length; i++) {
             this.dummy += x[i];
          }
 
       },
 
       resetValue() {
             this.columnName = undefined;
             this.operationValue = undefined;
             this.userSearch = undefined;
             this.dateValue = undefined;
             this.searchValue = undefined;
            //  this.numberValue = undefined;
       },
 
       // Axios Function
 
       getLeaveEnquiry() {
          const vm = this;
 
          this.loading = true;
 
         //  axios.get(this.$store.getters.apiURL + "/staffenquiry/" + vm.asatdate)
          this.$store.dispatch('get', {url: "/leaveenquiry/" + vm.asatdate + "/" + vm.activedate})
             .then((res) => {
                vm.tableData = res.data.rec;
 
                for (let i = 0; i < vm.tableData.length; i++) {
                   if (vm.tableData[i].startdate) {
                         vm.tableData[i].startdate = moment(vm.tableData[i].startdate).format('YYYY-MM-DD');
                   }
                }

                for (let i = 0; i < vm.tableData.length; i++) {
                   if (vm.tableData[i].enddate) {
                         vm.tableData[i].enddate = moment(vm.tableData[i].enddate).format('YYYY-MM-DD');
                   }
                }
 
                vm.tabulator = new Tabulator(vm.$refs.table, {
                
                reactiveData: true, // Reactive Data
                data: vm.tableData, // Table Data
                columns: vm.column, // Table columns      
                groupBy: vm.group, // Group By Function
 
                height:"350px",
                layout: "fitData",
                pagination: true,
                paginationSize: 10,
                paginationSizeSelector:[10, 25, 50, 100, true], 
                movableColumns: true,
 
                });
 
                // let language = require(`../../../locales/${this.$route.params.lang}.json`);
                // vm.tabulator.langs = language.tabulatorlang;
                // vm.tabulator.setLocale(this.$route.params.lang);
 
                vm.loading = false;
 
                // console.log(vm.tabulator);
 
             })
             .catch(function (err) {
                vm.errMsg = err;
             });
 
             this.getColumnBy();
       },
 
       getCSV() {
          this.tabulator.download("csv", this.$t('leaveenquiry') + ".csv", {bom:true});
       },
 
       getXLSX() {
          this.tabulator.download("xlsx", this.$t('leaveenquiry') + ".xlsx", {});
       },
 
       backHandler(){
          this.$router.push({name: 'leaveenquiryparameter'});
       },
    },
 
    created() {
       const vm = this;
       vm.asatdate = this.$route.params.asatdate;
       vm.activedate = this.$route.params.activedate;
       
       const xlsx = document.createElement('script');  
       xlsx.setAttribute('src',"https://oss.sheetjs.com/sheetjs/xlsx.full.min.js");
       document.head.appendChild(xlsx);
    },
 
    mounted() {
       this.getLeaveEnquiry();
    },
 }
 </script>
 
 <style scoped>
 @import  "./../../../../node_modules/tabulator-tables/dist/css/tabulator.min.css";
 </style>
 
 
 