<template>
    <div >
        <v-container>
            <v-form>
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                <v-row>
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('payrollprocessrun.payout')"
                                type="text"
                                v-model="payoutcode"
                                dense
                                clearable
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                v-model="payoutdesc"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.year')"
                                type="number"
                                v-model="pyyear"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('reportsparam.month')"
                                type="number"
                                v-model="pymonth"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-toolbar>
                        <h2>{{ this.pageTitle }}</h2>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                style="width: 400px"
                                class="pr-5 pt-3"
                                hide-details>
                            </v-text-field>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-data-table
                        v-model="selected"
                        :headers="this.dataHeader"
                        :items="this.dataItems"
                        :search="search"
                        class="elavation-1"
                        fixed-header
                        style="margin-top: 20px"
                        show-select
                    ></v-data-table>
                </v-container>
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="backHandler"> 
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="processHandler"> 
                            {{$t('button.update')}}
                        </v-btn>
                    </div>
                </v-row>
            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>    
    </div>
</template>

<script>
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog
    },

    data() {
        return {
            pageTitle: this.$t('payrollcommandstep.payrollcommandprocess'),

            dataHeader: [
                { text: this.$t('columns.staffcode'),               value: 'staffcode',             width: '100px' },
                { text: this.$t('columns.staffname'),               value: 'psnname',               width: '200px' },
                { text: this.$t('payrollprocessrun.payout'),                   value: 'payoutcode',            width: '100px' },
                { text: this.$t('columns.description'),              value: 'payoutdesc',            width: '400px' },
                { text: this.$t('reportsparam.year'),                     value: 'poyr',                  width: '100px' },
                { text: this.$t('reportsparam.month'),                    value: 'pomth',                 width: '100px' },
                { text: this.$t('payrollcommandstep.salaryfrequency'),         value: 'salfreqdesc',           width: '200px' },
                { text: this.$t('payrollprocessrun.payrollgroup'),            value: 'payrollgroupdesc',      width: '200px' },
                { text: this.$t('payrollprocessrun.exchangerate'),            value: 'exchratecode',          width: '100px' },
                { text: this.$t('payrollcommandstep.statusdescription'),       value: 'pystatusdesc',          width: '400px' },
                { text: this.$t('previewpayroll.grosspay'),                value: 'grosspay',              width: '200px' },
                { text: this.$t('previewpayroll.netpay'),                  value: 'netpay',                width: '200px' },
                { text: this.$t('payrollcommandstep.basicamount'),             value: 'basicamt',              width: '200px' },
                { text: this.$t('payrollcommandstep.daywork'),                value: 'dayswrk',               width: '100px' },
                { text: this.$t('payrollcommandstep.commandstatus'),           value: 'pycmdstatusdesc',       width: '100px' },
                { text: this.$t('payrollcommandstep.calculationstatus'),       value: 'pycalcstatusdesc',      width: '100px' },
            ],
            search: '',
            // item: this.$route.params.item,
            // param: this.$route.params.param,
            poid: this.$route.params.poid,
            formData: [],

            payoutcode: null,
            payoutdesc: null,
            pyyear: null,
            pymonth: null,
            dataItems: [],
            getPayCtrl: [],
            payoutCtrls: [],
            payout: [],
            getId: [],
            selected: [],
            salhd: [],
            salhdList: [],

            errMsg: '',
            isErr:false,

            data: this.$route.params,
        }
    },

    methods: {
        loadSalhdListHandler() {
            const vm = this;
            this.$store.dispatch('post', {
                url:  `/ppsummarylist/allfilter/${this.$store.getters.userId}`, 
                payload: vm.data
                })
            .then (res => {
                this.dataItems = res.data.rec;
                this.selected = this.dataItems;
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        loadPayoutListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url:  '/payoutcontrol/all'
                })
            .then (res => {
                let getPayout = res.data.rec

                for (let i = 0; i < getPayout.length; i++) {
                    if (getPayout[i].pystatuscode != 99) {
                        vm.payoutCtrls.push(getPayout[i])
                    }
                }
                this.changePayout(vm.payoutCtrls);

            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            })
        },

        changePayout(payout) {
            let item = payout.find(item => item.id == this.poid)
            
            this.payoutcode = item.payoutcode
            this.payoutdesc = item.payoutdesc
            this.pyyear = item.pyyear
            this.pymonth = item.pymonth
        },

        update() {
            const vm = this
            
            this.$store.dispatch('put', {
                url:  '/pycommandstatus/update', 
                payload: vm.formData
                })
            .then(res => {
                if (res.data.error) {
                    vm.isErr = true;
                    vm.errMsg = res.data.error;
                } else {
                    this.$router.push({name: 'payrollcommandfilter'});
                }
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        },

        processHandler() {
            this.formData = this.selected
            for (let i = 0; i < this.formData.length; i++) {
                this.formData[i].pycmdstatuscode = this.data.pycmdstatuscode
                this.formData[i].userid = this.$store.getters.userId
            }
            this.update()
        },

        backHandler() {
            this.$router.push({name: 'payrollcommandfilter'});
        },
    },

    mounted() {
        this.loadPayoutListHandler()
        this.loadSalhdListHandler()
    }
}
</script>