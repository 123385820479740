<template>
  <div cols="12">
    <v-dialog v-model="CrudTabledialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('setupmapping.grpmappingitem')}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  :label="this.$t('setupmapping.grpval')"
                  type="text"
                  dense
                  v-model="selections.valvalueid"
                  :items="groupList"
                  :rules="[rules.required]"
                  @change="changeValvalueHandler(selections.valvalueid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  dense
                  :label="this.$t('columns.code')"
                  outlined
                  readonly
                  background-color="#ffeab2"
                  v-model="selections.valuecode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.company')"
                  :items="this.companyList"
                  v-model="selections.compnyid"
                  @change="changeCompanyHandler(selections.compnyid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.compnycode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.branch')"
                  :items="this.branchList"
                  v-model="selections.brchid"
                  @change="changeBranchHandler(selections.brchid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.brchcode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.division')"
                  :items="this.divisionList"
                  v-model="selections.divnid"
                  @change="changeDivisionHandler(selections.divnid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.divncode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.department')"
                  :items="this.departmentList"
                  v-model="selections.deptid"
                  @change="changeDepartmentHandler(selections.deptid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.deptcode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.section')"
                  :items="this.sectionList"
                  v-model="selections.sectioid"
                  @change="changeSectionHandler(selections.sectioid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.sectiocode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.unit')"
                  :items="this.unitList"
                  v-model="selections.unitid"
                  @change="changeUnitHandler(selections.unitid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.unitcode"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.employmenttype')"
                  :items="this.emptypeList"
                  v-model="selections.emptypeid"
                  @change="changeEmpTypeHandler(selections.emptypeid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.emptype"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.job')"
                  :items="this.jobList"
                  v-model="selections.jobid"
                  @change="changeJobHandler(selections.jobid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.jobcode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.grade')"
                  :items="this.gradeList"
                  v-model="selections.grdid"
                  @change="changeGradeHandler(selections.grdid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.grdcode"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  hide-details
                  outlined
                  dense
                  :label="this.$t('columns.position')"
                  :items="this.positionList"
                  v-model="selections.positionid"
                  @change="changePositionsHandler(selections.positionid)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="2">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  readonly
                  background-color="#ffeab2"
                  :label="this.$t('columns.code')"
                  v-model="selections.poscode"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="isErr" class="">
          <span style="font-weight: bold; color: red">{{ errMsg }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeGroupMapping"
            >{{$t('button.cancel')}}</v-btn
          >
          <v-btn color="green darken-1" text @click="saveGroupMapping"
            >{{$t('button.save')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="this.parentObject.tabHeader"
      :items="detailItem"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-1 ml-1" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-1 mr-1" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import compareArray from "../../../util/comparearray";

export default {
  props: {
    parentObject: Object,
  },

  components: {},

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred'),
      },

      selections: {
        gtypeid: null,
        valvalueid: null,
        valuecode: null,
        valuedesc: null,
        compnyid: null,
        compnycode: null,
        brchid: null,
        brchcode: null,
        divnid: null,
        divncode: null,
        deptid: null,
        deptcode: null,
        sectioid: null,
        sectiocode: null,
        unitid: null,
        unitcode: null,
        emptypeid: null,
        emptype: null,
        grdid: null,
        grdcode: null,
        jobid: null,
        jobcode: null,
        positionid: null,
        poscode: null,
        updatedby: this.$store.getters.userId,
        action: 0,
      },

      itemHolder: [],
      CrudTabledialog: false,
      gtypeid: this.parentObject.gtypeid,
      detailItem: this.parentObject.detailItem,

      groupList: [],
      groupLists: [],
      companyList: [],
      companies: [],
      branches: [],
      branchList: [],
      divisionList: [],
      divisions: [],
      departments: [],
      departmentList: [],
      sections: [],
      sectionList: [],
      units: [],
      unitList: [],
      emptypes: [],
      emptypeList: [],
      jobs: [],
      jobList: [],
      grades: [],
      gradeList: [],
      positions: [],
      positionList: [],

      modify: false,
      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },
  watch: {
    CrudTabledialog(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up

        if (!this.parentObject.gtypeid) {
          this.isErr = true;
          this.errMsg = this.$t('setupgrp.plsselectgroup');
          this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
        }

        this.groupList = [];
        this.groupLists = [];
        this.gtypeid = this.parentObject.gtypeid;
        this.selections.gtypeid = this.gtypeid;
        this.loadValvalueListHandler();
      } else {
        console.log("Dialog was closed!");
      }
    },
  },
  methods: {
    compareArray,
    loadValvalueListHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: `/groupbtype/${vm.gtypeid}`})
        .then((res) => {
          vm.groupLists = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.groupList.push({
              text: res.data.rec[i].valuedesc,
              value: res.data.rec[i].valvalueid,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeValvalueHandler(id) {
      let item = this.groupLists.find((item) => item.valvalueid === id);
      this.selections.valuecode = item.valuecode;
      this.selections.valuedesc = item.valuedesc;
    },
    loadCompanies() {
      const vm = this;
      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
          //   if (vm.selections.compnyid) {
          //     vm.changeCompanyHandler(vm.formData.compnyid);
          //   }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeCompanyHandler(id) {
      let item = this.companies.find((item) => item.id === id);
      this.selections.compnycode = item.compnycode;
    },
    loadBranches() {
      const vm = this;
      this.$store.dispatch('get', {url: "/branch/all"})
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].id,
            });
          }

          //   if (vm.formData.brchid) {
          //     vm.changeBranchHandler(vm.formData.brchid);
          //   }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeBranchHandler(id) {
      let item = this.branches.find((item) => item.id === id);
      this.selections.brchcode = item.brchcode;
    },
    loadDivisions() {
      const vm = this;
      this.$store.dispatch('get', {url: "/division/all"})
        .then((res) => {
          vm.divisions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
          }

          //   if (vm.formData.divnid) {
          //     vm.changeDivisionHandler(vm.formData.divnid);
          //   }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeDivisionHandler(id) {
      let item = this.divisions.find((item) => item.id === id);
      this.selections.divncode = item.divncode;
    },
    loadDepartments() {
      const vm = this;
      this.$store.dispatch('get', {url: "/department/all"})
        .then((res) => {
          vm.departments = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].id,
            });
          }

          //   if (vm.formData.deptid) {
          //     vm.changeDepartmentHandler(vm.formData.deptid);
          //   }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeDepartmentHandler(id) {
      let item = this.departments.find((item) => item.id === id);
      this.selections.deptcode = item.deptcode;
    },
    loadSections() {
      const vm = this;
      this.$store.dispatch('get', {url: "/section/all"})
        .then((res) => {
          vm.sections = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
          }
          //   if (vm.formData.sectioid) {
          //     vm.changeSectionHandler(vm.formData.sectioid);
          //   }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeSectionHandler(id) {
      let item = this.sections.find((item) => item.id === id);
      this.selections.sectiocode = item.sectiocode;
    },
    loadUnits() {
      const vm = this;
      this.$store.dispatch('get', {url: "/unit/all"})
        .then((res) => {
          vm.units = res.data.rec;
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
          }
          // if (vm.formData.unitid) {
          //   vm.changeUnitHandler(vm.formData.unitid);
          // }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeUnitHandler(id) {
      let item = this.units.find((item) => item.id === id);
      this.selections.unitcode = item.unitcode;
    },
    loadJobs() {
      const vm = this;
      this.$store.dispatch('get', {url: "/job/all"})
        .then((res) => {
          vm.jobs = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.jobList.push({
              text: res.data.rec[i].jobtitle,
              value: res.data.rec[i].id,
            });
          }
          // if (vm.formData.jobid) {
          //   vm.changeJobHandler(vm.formData.jobid);
          // }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeJobHandler(id) {
      let item = this.jobs.find((item) => item.id === id);
      this.selections.jobcode = item.jobcode;
    },
    loadGrades() {
      const vm = this;
      this.$store.dispatch('get', {url: "/grade/all"})
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].id,
            });
          }
          // if (vm.formData.grdid) {
          //   vm.changeGradeHandler(vm.formData.grdid);
          // }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeGradeHandler(id) {
      let item = this.grades.find((item) => item.id === id);
      this.selections.grdcode = item.grdcode;
    },
    loadEmpTypes() {
      const vm = this;
      this.$store.dispatch('get', {url: "/emptype/all"})
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptype,
              value: res.data.rec[i].id,
            });
          }
          // if (vm.formData.emptypeid) {
          //   vm.changeEmpTypeHandler(vm.formData.emptypeid);
          // }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changeEmpTypeHandler(id) {
      let item = this.emptypes.find((item) => item.id === id);
      this.selections.emptype = item.emptype;
    },
    loadPositions() {
      const vm = this;
      this.$store.dispatch('get', {url: "/position/all"})
        .then((res) => {
          vm.positions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.positionList.push({
              text: res.data.rec[i].posdesc,
              value: res.data.rec[i].id,
            });
          }
          // if (vm.formData.grdid) {
          //   vm.changePositionsHandler(vm.formData.grdid);
          // }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    changePositionsHandler(id) {
      let item = this.positions.find((item) => item.id === id);
      this.selections.poscode = item.poscode;
    },

    modifyItemHandler(item) {
      console.log(item);
      // assign loaded data to selections array
      if (item.action == 1) {
        this.modify = true;
      }
      if (item.action == 2) {
        this.isExisting = true;
      }

      this.itemHolder = item;
      this.selections = this.itemHolder;

      this.CrudTabledialog = true;
    },

    deleteItemHandler(item) {
      let itemIndex = this.detailItem.indexOf(item);

      this.detailItem.splice(itemIndex, 1);
    },

    saveGroupMapping() {
       if (this.selections.valvalueid == null){

              this.$dialog.alert(this.$t('setupgrp.fillinthegroupcolumn')).then((dialog) => {
                console.log(dialog);
             });
            } else {
      this.isErr = false;

      if (!this.isExisting && this.modify) {
        this.resetSelections();
      } else if (!this.isExisting) {
        this.selections.id = null;
        this.selections.action = 1;

        this.detailItem.push(Object.assign({}, this.selections));
      } else {
        if (this.compareArray(this.detailItem, this.selections)) {
          console.log(this.compareArray(this.detailItem, this.selections));
        }
      }

      console.log(this.detailItem);
      this.$emit("input", this.detailItem);

      this.modify = false;
      this.CrudTabledialog = false;

      // reset the form fields
      this.resetSelections();
            }
    },

    closeGroupMapping() {
      this.CrudTabledialog = false;
      this.isErr = false;
      // reset the form fields
      this.resetSelections();
    },

    // // Saving payeeinput details into payeeinputd
    // saveGroupMappingHandler() {
    //   const vm = this;
    //   if (this.itemData.itemid) {
    //     axios
    //       .put(
    //         this.$store.getters.apiURL + "/currency/" + this.itemData.itemid,
    //         {
    //           crcycode: this.itemData.itemcode,
    //           crcydesc: this.itemData.itemdesc,
    //           stamp: this.itemData.itemstamp,
    //         }
    //       )
    //       .then((res) => {
    //         if (res.data.status == 201) {
    //           // resets all arrays
    //           vm.currencies = [];
    //           vm.currencyItems = [];
    //           vm.currencyList = [];

    //           this.loadCurrencyListHandler();
    //         } else {
    //           vm.isErr = true;
    //           vm.errMsg = res.data.error;
    //           vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //         }
    //       })
    //       .catch((err) => {
    //         vm.isErr = true;
    //         vm.errMsg = err;
    //         vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //       });
    //   } else {
    //     axios
    //       .post(this.$store.getters.apiURL + "/currency/create", {
    //         crcycode: this.itemData.itemcode,
    //         crcydesc: this.itemData.itemdesc,
    //       })
    //       .then((res) => {
    //         if (res.data.status == 201) {
    //           // resets all arrays
    //           vm.currencies = [];
    //           vm.currencyItems = [];
    //           vm.currencyList = [];

    //           this.loadCurrencyListHandler();
    //         } else {
    //           vm.isErr = true;
    //           vm.errMsg = res.data.error;
    //           vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //         }
    //       })
    //       .catch((err) => {
    //         vm.isErr = true;
    //         vm.errMsg = err;
    //         vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //       });
    //   }
    // },

    // deleteCurrencyHandler($event) {
    //   const vm = this;

    //   if (confirm("Remove " + $event.desc + "?")) {
    //     axios
    //       .delete(this.$store.getters.apiURL + "/currency/" + $event.id)
    //       .then((res) => {
    //         if (res.data.status == 200) {
    //           // resets all arrays
    //           vm.currencies = [];
    //           vm.currencyList = [];
    //           vm.currencyItems = [];

    //           this.loadCurrencyListHandler();
    //         } else {
    //           vm.isErr = true;
    //           vm.errMsg = res.data.error;
    //           vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //         }
    //       })
    //       .catch((err) => {
    //         vm.isErr = true;
    //         vm.errMsg = err;
    //         vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
    //       });
    //   }
    // },

    resetSelections() {
      this.isExisting = false;
      this.modify = false;
      this.selections = {
        gtypeid: null,
        valvalueid: null,
        valuecode: null,
        valuedesc: null,
        compnyid: null,
        compnycode: null,
        brchid: null,
        brchcode: null,
        divnid: null,
        divncode: null,
        deptid: null,
        deptcode: null,
        sectioid: null,
        sectiocode: null,
        unitid: null,
        unitcode: null,
        emptypeid: null,
        emptype: null,
        gradeid: null,
        grdcode: null,
        jobid: null,
        jobcode: null,
        positionid: null,
        poscode: null,
        updatedby: this.$store.getters.userId,
      };
    },
  },

  created() {
    this.loadCompanies();
    this.loadBranches();
    this.loadDivisions();
    this.loadDepartments();
    this.loadSections();
    this.loadUnits();
    this.loadEmpTypes();
    this.loadJobs();
    this.loadGrades();
    this.loadPositions();
  },
};
</script>