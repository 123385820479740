<template>
    <div>
        <v-dialog v-model="assetdialog" max-width="800px">
            <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{$t('recassetrec.assetitem')}}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recassetrec.unitno')"
                                        type="text"
                                        ref="unitno"
                                        :rules="[rules.required]"
                                        v-model="selections[0].unitno"
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recassetrec.serialno')"
                                        type="text"
                                        ref="serialno"
                                        v-model="selections[0].serialno"
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.description')"
                                        type="text"
                                        ref="assetitemdesc"
                                        :rules="[rules.required]"
                                        v-model="selections[0].assetitemdesc"
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('recassetrec.estval')"
                                        type="decimal"
                                        ref="estvalue"
                                        v-model="selections[0].estvalue"
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.status')"
                                        readonly
                                        background-color="#ffeab2"
                                        dense
                                        v-model="selections[0].assetstatuscode"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="2" md="6" >
                                    <v-checkbox
                                        ref="disposed"
                                        v-model="selections[0].disposed"
                                        :label="this.$t('recassetrec.disposed')"
                                    >
                                    </v-checkbox>
                                    <v-checkbox
                                        ref="undermaintenance"
                                        v-model="selections[0].undermaintenance"
                                        :label="this.$t('recassetrec.undermaintenance')"
                                    >
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-text v-if="isErr" class="mt-n12">
                    <span style="font-weight: bold; color: red">{{errMsg}}</span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeAsset">{{$t('button.cancel')}}</v-btn>
                    <v-btn color="green darken-1" text @click="saveAsset">{{$t('button.save')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="subtitle-2">{{$t('recassetrec.assetdetails')}}</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="modifyItemHandler(item)">
                    mdi-pen
                </v-icon>
                <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <div v-if="isErr">
            <app-error v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
            />
        </div>
        <div v-if="isSuccess">
            <app-success v-bind:parentObject="{
                isSuccess: this.isSuccess,
                messageSuccess: this.messageSuccess,
            }"
            />
        </div>
        <div v-if="isConfirm">
            <app-confirm v-bind:parentObject="{
                messageConfirm: this.messageConfirm,
                isConfirm: this.isConfirm,
            }"
            v-on:cancelConfirm="isConfirm = !isConfirm"
            />
        </div>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-error": ErrorDialog
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },
            selections: [{
                id: null,
                unitno: null,
                serialno: null,
                assetitemdesc: null,
                estvalue: null,
                assetstatusid: null,
                assetstatuscode: null,
                disposed: false,
                undermaintenance: false,
                action: 0
            }],
            assetdialog: false,
            detailItem: this.parentObject.detailItem,
            modify: false,
            isConfirm: false,
            confirmDelete: false,
            isSuccess: false,
            isExisting: false,
            isErr: false,
            errMsg: '',
            assetStatus: '',
        }
    },

    methods: {
        compareArray,

        modifyItemHandler(item) {
            // assign loaded data to selections array

            if(item.action == 1) {
                this.modify = true;
            }

            if(item.action == 2) {
                this.isExisting = true;
            }

            if (item.disposed == "Y") {
                item.disposed = true;
            }
            else {
                item.disposed = false;
            }
            if (item.undermaintenance == "Y") {
                item.undermaintenance = true;
            }
            else {
                item.undermaintenance = false;
            }
            
            this.selections = [{
                id: item.id,
                unitno: item.unitno,
                serialno: item.serialno,
                assetitemdesc: item.assetitemdesc,
                estvalue: item.estvalue,
                assetstatusid: item.assetstatusid,
                assetstatuscode: item.assetstatuscode,
                disposed: item.disposed,
                undermaintenance: item.undermaintenance,
            }]

            this.assetdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        closeAsset() {
            this.assetdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        saveAsset() {
            this.isErr = false;
            if (this.selections[0].disposed) {
                this.selections[0].disposed = "Y";
            }
            else {
                this.selections[0].disposed = "N";
            }
            if (this.selections[0].undermaintenance) {
                this.selections[0].undermaintenance = "Y";
            }
            else {
                this.selections[0].undermaintenance = "N";
            }

            if (this.selections[0].disposed == "Y" || this.selections[0].undermaintenance == "Y") {
                this.selections[0].assetstatusid = 3;
            }
            else {
                this.selections[0].assetstatusid = 1;
            }

            if (!this.isExisting && !this.modify) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
            }
            else {
                this.detailItem.forEach((element) => {
                    if (element.id == this.selections[0].id) {
                        (element.unitno = this.selections[0].unitno),
                        (element.serialno = this.selections[0].serialno),
                        (element.assetitemdesc = this.selections[0].assetitemdesc),
                        (element.estvalue = this.selections[0].estvalue),
                        (element.disposed = this.selections[0].disposed),
                        (element.undermaintenance = this.selections[0].undermaintenance),
                        (element.assetstatusid = this.selections[0].assetstatusid),
                        (element.rectimestamp = this.selections[0].rectimestamp),
                        (element.updatedby = this.selections[0].updatedby),
                        (element.stamp = this.selections[0].stamp);
                    }
                });
            }

            this.$emit("input", this.detailItem);

            this.modify = false;
            this.isExisting = false;
            this.assetdialog = false;

            // reset the form fields
            this.resetSelections();
        },

        resetSelections() {
            this.selections = [{
                id: null,
                unitno: null,
                serialno: null,
                assetitemdesc: null,
                estvalue: null,
                assetstatusid: null,
                disposed: false,
                undermaintenance: false,
                action: 0
            }]
        },
    },
}
</script>




