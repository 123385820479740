<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.pageTitle
              }}<span class="page_title_value">{{ this.pageTitleValue }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-card class="mt-n6">
          <div class="ma-4">
            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
            <v-row>
              <v-col cols="4" sm="4" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.year')"
                  type="text"
                  ref="year"
                  hide-selected
                  v-model="formData.year"
                  dense
                  :items="years"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" sm="2" class="pb-n5">
                <v-autocomplete
                  outlined
                  :label="this.$t('reportsparam.month')"
                  type="text"
                  ref="month"
                  hide-selected
                  v-model="formData.month"
                  dense
                  :items="months"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <app-parameter
          class="mt-5 mb-3"
          v-on:input="parameters = $event"
          v-bind:parentObject="{
            filter: this.showFilter,
          }"
        />
        <!-- </v-container> -->
        <v-row>
          <v-col>
            <v-btn color="warning" style="width: 100px" @click="displayReport">
              {{$t('button.go')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import FilterParameter from "../../../components/common/FilterParameter";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-parameter": FilterParameter,
    Loading,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      pageTitle: this.$t('staff12monthpayrollsummaryreport.staff12monthpayrollsummary'),
      pageTitleValue: "",
      formData: {
        year: null,
        month: null,
      },
      parameters: {
        staffid: "",
        gradeid: "",
        employmenttypeid: "",
        companyid: "",
        branchid: "",
        departmentid: "",
      },
      showFilter: {
        staffid: true,
        gradeid: false,
        employmenttypeid: false,
        companyid: true,
        branchid: true,
        departmentid: true,
      },
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      reportFile: "Staff12MonthPayrollSummary.rptdesign",
      loading: false,
    };
  },

  methods: {
    loadYears() {
      for (let i = 2020; i < 2200; i++) {
        this.years.push(i);
      }
    },
    goHandler() {
      if (this.formData.year != null && this.formData.month != null) {
        window.open(
          `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&year=${this.formData.year}&month=${this.formData.month}&userid=${this.$store.getters.userId}&companyid=${this.parameters.companyid}&staffid=${this.parameters.staffid}&branchid=${this.parameters.branchid}&deptid=${this.parameters.departmentid}&dbname=${this.$store.getters.dbname}`,
          "_blank" // <- This is what makes it open in a new window.
        );
      } else {
        
        ((this.$refs.form.validate()))
        // this.isErr = true;
        // this.errMsg = "Required Fields Not Filled";
        // this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
      }
    },
    
    displayReport() {
      const vm = this;
      vm.loading = true;
      const reporturl =  `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&__format=pdf&year=${this.formData.year}&month=${this.formData.month}&userid=${this.$store.getters.userId}&companyid=${this.parameters.companyid}&staffid=${this.parameters.staffid}&branchid=${this.parameters.branchid}&deptid=${this.parameters.departmentid}&dbname=${this.$store.getters.dbname}`;
      if (this.formData.year != null && this.formData.month != null) {
        this.$store.dispatch('post', {
          url: "/reportdisplay/url", 
          payload: {reporturl: reporturl, reportname: this.reportFile},
          responseType: "blob"})
          .then((res) => {
            console.log(res.data);
            vm.loading = false;
            if (res.data && res.status == 200) {
              var fileURL = URL.createObjectURL(res.data);
              window.open(fileURL, "_blank");
            } else {
              vm.isErr = true;
              vm.errMsg = res.data.error;
              vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            }
          })
          .catch((err) => {
            vm.isErr = true;
            vm.errMsg = err;
            vm.loading = false;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      } else {
        vm.loading = false;
        ((this.$refs.form.validate()))
        // this.isErr = true;
        // this.errMsg = "Required Fields Not Filled";
        // this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
      }
    },

    updateValue() {
      this.$emit("input", {
        year: this.formData.year,
        month: this.formData.month,
      });
    },
  },

  mounted() {
    this.loadYears();
  },
};
</script>