<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5"></v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.address')"
                                id="adrmascode"
                                type="text"
                                ref="adrmascode"
                                :rules="[rules.required]"
                                v-model="formData.adrmascode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupcompany.registrationno')"
                                type="text"
                                ref="regno"
                                v-model="formData.regno"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupaddressmaster.taxno')"
                                type="text"
                                ref="taxno"
                                v-model="formData.taxno"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="adrmasdesc"
                                :rules="[rules.required]"
                                v-model="formData.adrmasdesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline1')"
                                type="text"
                                ref="addr1"
                                v-model="formData.addr1"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline2')"
                                type="text"
                                ref="addr2"
                                v-model="formData.addr2"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.addressline3')"
                                type="text"
                                ref="addr3"
                                v-model="formData.addr3"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.city')"
                                type="text"
                                ref="cityid"
                                :items="cityList"
                                hide-selected
                                v-model="formData.cityid"
                                @input="updateValue"
                                @change="changeCityHandler(formData.cityid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="cityCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="cityDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: cityItems,
                                            formTitle: `${this.$t('columns.city')}`,
                                            formId: 'city'
                                        }"
                                        v-on:input="itemData = $event"
                                        v-on:saveCity="saveCityHandler"
                                        v-on:deleteCity="deleteCityHandler($event)"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="cityDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.country')"
                                type="text"
                                ref="cntryid"
                                :items="countryList"
                                v-model="formData.cntryid"
                                @input="updateValue"
                                @change="changeCountryHandler(formData.cntryid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="countryCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="countryDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: countryItems,
                                            formTitle: `${this.$t('columns.country')}`,
                                            formId: 'country'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveCountry="saveCountryHandler"
                                        v-on:deleteCountry="deleteCountryHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="countryDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.postcode')"
                                type="text"
                                ref="postcode"
                                v-model="formData.postcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.telephone')"
                                type="text"
                                ref="tel"
                                v-model="formData.tel"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.website')"
                                type="text"
                                ref="www"
                                v-model="formData.www"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.email')"
                                type="text"
                                ref="email"
                                :rules="[rules.email]"
                                v-model="formData.email"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                :items="companyList"
                                v-model="formData.compnyid"
                                @input="updateValue"
                                @change="changeCompnyHandler(formData.compnyid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="compnyCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
// import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {
                adrmascode: this.parentObject.data.adrmascode,
                adrmasdesc: this.parentObject.data.adrmasdesc,
                regno: this.parentObject.data.regno,
                taxno: this.parentObject.data.taxno,
                // startdate: this.parentObject.data.startdate,
                // enddate: this.parentObject.data.enddate,
                addr1: this.parentObject.data.addr1,
                addr2: this.parentObject.data.addr2,
                addr3: this.parentObject.data.addr3,
                cityid: this.parentObject.data.cityid,
                cntryid: this.parentObject.data.cntryid,
                postcode: this.parentObject.data.postcode,
                tel: this.parentObject.data.tel,
                www: this.parentObject.data.www,
                email: this.parentObject.data.email,
                compnyid: this.parentObject.data.compnyid,
                isdisable: this.parentObject.data.isdisable,
                 search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            cityCode: '',
            countryCode: '',
            compnyCode: '',
            countryList: [],
            cityList: [],
            companyList: [],
            countries: [],
            cities: [],
            companies: [],
            cityItems: [],
            countryItems: [],
            cityDialog: false,
            countryDialog: false,
            cityLoading: true,
            countryLoading: true,
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew

        }
    },

    methods: {
        loadCityListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/city/all'})
                .then(res => {
                    vm.cities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.cityList.push(
                            {
                                text: res.data.rec[i].cityname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.cityItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].citycode,
                                desc: res.data.rec[i].cityname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    } 

                    vm.cityLoading = false;

                    if (vm.formData.cityid) {
                        vm.changeCityHandler(vm.formData.cityid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadCompanyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/company/all'})
                .then(res => {
                    vm.companies = res.data.rec;
                    // const todayDate = moment();

                    for (let i = 0; i < res.data.rec.length; i++) {
                        // if (todayDate.isSameOrAfter(res.data.rec[i].startdate) && todayDate.isBefore(res.data.rec[i].enddate) && res.data.rec[i].isdisable == 'N') 
                        // {
                            vm.companyList.push(
                                {
                                    text: res.data.rec[i].compnyname,
                                    value: res.data.rec[i].id
                                }
                            )
                        // }
                    }

                    if (vm.formData.compnyid) {
                        vm.changeCompnyHandler(vm.formData.compnyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.cityCode = item.citycode;
        },

        changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.countryCode = item.cntrycode;
        },


        changeCompnyHandler(id) {
            let item = this.companies.find(item => item.id === id);

            this.compnyCode = item.compnycode;
        },

        saveCityHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/city/' + this.itemData.itemid, payload: {
                    citycode: this.itemData.itemcode,
                    cityname: this.itemData.itemdesc,    
                    stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/city/create', payload: {
                citycode: this.itemData.itemcode,
                cityname: this.itemData.itemdesc,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        saveCountryHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/country/' + this.itemData.itemid, payload: {
                cntrycode: this.itemData.itemcode,
                cntryname: this.itemData.itemdesc,stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/country/create', payload: {
                cntrycode: this.itemData.itemcode,
                cntryname: this.itemData.itemdesc
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteCityHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/city/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    console.log(res.data.status)
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.cities = [];
                        vm.cityList = [];
                        vm.cityItems = [];

                        this.loadCityListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        deleteCountryHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/country/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.countries = [];
                        vm.countryList = [];
                        vm.countryItems = [];

                        this.loadCountryListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        updateValue() {
            this.$emit('input', {
                adrmascode: this.formData.adrmascode,
                adrmasdesc: this.formData.adrmasdesc,
                isdisable: this.formData.isdisable,
                regno: this.formData.regno,
                taxno: this.formData.taxno,
                // startdate: this.formData.startdate,
                // enddate: this.formData.enddate,
                addr1: this.formData.addr1,
                addr2: this.formData.addr2,
                addr3: this.formData.addr3,
                cityid: this.formData.cityid,
                cntryid: this.formData.cntryid,
                postcode: this.formData.postcode,
                tel: this.formData.tel,
                www: this.formData.www,
                email: this.formData.email,
                compnyid: this.formData.compnyid,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'addresslist' , params: { search: this.search}});
        },

        genCityTabItems() {
            for (let i = 0; i < this.cities.length; i++) {
                this.cityItems.push(
                    {
                        id: this.cities[i].id,
                        code: this.cities[i].citycode,
                        desc: this.cities[i].cityname,
                        stamp: this.cities[i].stamp
                    }
                )
            }
        },

        genCountryTabItems() {
            for (let i = 0; i < this.countries.length; i++) {
                this.cityItems.push(
                    {
                        id: this.countries[i].id,
                        code: this.countries[i].cntrycode,
                        desc: this.countries[i].cntryname,
                        stamp: this.countries[i].stamp
                    }
                )
            }
        },
    },

    mounted() {
        this.loadCityListHandler();
        this.loadCountryListHandler();
        this.loadCompanyListHandler();
    }
    
}
</script>