<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('glcode.mappingpolicy')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.policycode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('glcode.mappingpolicydesc')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.policydesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-subheader class="blue--text mt-n3 ml-n2">
                        {{$t('glcode.reportingby')}}
                    </v-subheader>

                    <v-row>
                        <v-col cols="4" sm="10" class="ml-2">
                            {{$t('glcode.mappingpolicymsg1')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-2 ml-2">{{$t('glcode.level1')}}</v-col>
                        <v-col cols="6" sm="6" class="ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                :rules="[rules.required]"
                                v-model="formData.level1"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level2')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level2"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level3')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level3"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level4')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level4"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level5')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level5"
                                @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level6')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level6"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level7')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level7"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2" sm="2" class="mt-n6 ml-2">{{$t('glcode.level8')}}</v-col>
                        <v-col cols="6" sm="6" class="mt-n8 ml-2">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.selectfromlist')"
                                type="text"
                                :items="payItemGLMapParamsList"
                                v-model="formData.level8"
                                 @input="updateValue"
                                dense
                                clearable
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

export default {
    props: {
        parentObject: Object
    },

    components: {

    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },

            formData: {
                policycode: this.parentObject.data.policycode,
                policydesc: this.parentObject.data.policydesc,
                remark: this.parentObject.data.remark,
                level1: this.parentObject.data.level1,
                level2: this.parentObject.data.level2,
                level3: this.parentObject.data.level3,
                level4: this.parentObject.data.level4,
                level5: this.parentObject.data.level5,
                level6: this.parentObject.data.level6,
                level7: this.parentObject.data.level7,
                level8: this.parentObject.data.level8,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            selected: [],
            payItemGLMapParams: [],
            payItemGLMapParamsList: [],
            subDialog: false,
            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {
        loadPayGLMapParamListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/glmapparam/all'})
                .then(res => {
                    vm.payItemGLMapParams = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.payItemGLMapParamsList.push({
                            text: res.data.rec[i].paramgl,
                            value: res.data.rec[i].paramgl,
                        });
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        updateValue() {
            this.$emit('input', {
                policycode: this.formData.policycode,
                policydesc: this.formData.policydesc,
                remark: this.formData.remark,
                level1: this.formData.level1,
                level2: this.formData.level2,
                level3: this.formData.level3,
                level4: this.formData.level4,
                level5: this.formData.level5,
                level6: this.formData.level6,
                level7: this.formData.level7,
                level8: this.formData.level8,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
            console.log('formdt', this.formData)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },
        
        cancelHandler() {
            this.$router.push({name: 'glcodemappingpolicylist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadPayGLMapParamListHandler();
    }
}
</script>