var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elavation-1",attrs:{"headers":_vm.tabHeaders,"items":this.parentData.tabItems,"search":_vm.search,"append-icon":"search","fixed-header":"","sort-by":['code']},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-5 pt-3",staticStyle:{"width":"200px"},attrs:{"append-icon":"search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":""},on:{"click":_vm.changeSubDialogTitleCreate}},on),[_vm._v("+")])]}}]),model:{value:(_vm.subDialog),callback:function ($$v) {_vm.subDialog=$$v},expression:"subDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.subDialogTitle)+" "),_c('v-spacer'),(_vm.subDialog)?_c('span',{staticClass:"page_title_value"},[_vm._v(_vm._s(_vm.subDialogTitleValue))]):_vm._e()],1),_c('v-card-text',[_c('v-container',[(_vm.isPayItemSetup  || _vm.isTaxableRebateSetup)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.code')},on:{"input":_vm.updateValue},model:{value:(
                                                _vm.formDataWithType.itemcode
                                            ),callback:function ($$v) {_vm.$set(_vm.formDataWithType, "itemcode", $$v)},expression:"\n                                                formDataWithType.itemcode\n                                            "}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.description')},on:{"input":_vm.updateValue},model:{value:(
                                                _vm.formDataWithType.itemdesc
                                            ),callback:function ($$v) {_vm.$set(_vm.formDataWithType, "itemdesc", $$v)},expression:"\n                                                formDataWithType.itemdesc\n                                            "}})],1)],1):_vm._e(),(_vm.isLeaveEntitlement)?_c('v-row',[_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":_vm.$t('leave'),"items":_vm.leaveTypeSetup},on:{"input":_vm.updateValue,"change":function($event){return _vm.changeLeaveEntitlementHandler(
                                                    _vm.leavetypeid
                                                )}},model:{value:(_vm.leavetypeid),callback:function ($$v) {_vm.leavetypeid=$$v},expression:"leavetypeid"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('columns.code'),"background-color":"#ffeab2","readonly":""},model:{value:(_vm.formData.itemcode),callback:function ($$v) {_vm.$set(_vm.formData, "itemcode", $$v)},expression:"formData.itemcode"}})],1)],1):_vm._e(),(_vm.doctypecode)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.code')},on:{"input":_vm.updateValue},model:{value:(_vm.formData.itemcode),callback:function ($$v) {_vm.$set(_vm.formData, "itemcode", $$v)},expression:"formData.itemcode"}})],1)],1):_vm._e(),(_vm.addongrp)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.description')},on:{"input":_vm.updateValue},model:{value:(_vm.formData.itemdesc),callback:function ($$v) {_vm.$set(_vm.formData, "itemdesc", $$v)},expression:"formData.itemdesc"}})],1)],1):_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.code')},on:{"input":_vm.updateValue},model:{value:(_vm.formData.itemcode),callback:function ($$v) {_vm.$set(_vm.formData, "itemcode", $$v)},expression:"formData.itemcode"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('columns.description')},on:{"input":_vm.updateValue},model:{value:(_vm.formData.itemdesc),callback:function ($$v) {_vm.$set(_vm.formData, "itemdesc", $$v)},expression:"formData.itemdesc"}})],1)],1),(_vm.isPayItemSetup)?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":_vm.$t('typedescription'),"items":_vm.payItemTypes},on:{"change":function($event){return _vm.changePayItemTypeHandler(
                                                    _vm.formDataWithType.typeid
                                                )}},model:{value:(
                                                _vm.formDataWithType.typeid
                                            ),callback:function ($$v) {_vm.$set(_vm.formDataWithType, "typeid", $$v)},expression:"\n                                                formDataWithType.typeid\n                                            "}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","outlined":"","background-color":"#ffeab2","label":_vm.$t('typecode')},model:{value:(_vm.payItemTypeCode),callback:function ($$v) {_vm.payItemTypeCode=$$v},expression:"payItemTypeCode"}})],1)],1):_vm._e(),(_vm.isTaxableRebateSetup)?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":_vm.$t('typedescription'),"items":_vm.taxableRebate},on:{"change":function($event){return _vm.changeTaxableRebateHandler(
                                                    _vm.formDataWithType.typeid
                                                )}},model:{value:(
                                                _vm.formDataWithType.typeid
                                            ),callback:function ($$v) {_vm.$set(_vm.formDataWithType, "typeid", $$v)},expression:"\n                                                formDataWithType.typeid\n                                            "}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","outlined":"","background-color":"#ffeab2","label":_vm.$t('typecode')},model:{value:(_vm.taxableRebateCode),callback:function ($$v) {_vm.taxableRebateCode=$$v},expression:"taxableRebateCode"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('app-formbuttons',{on:{"SavePressed":_vm.saveHandler,"CancelPressed":_vm.cancelHandler}}),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:`item.action`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editHandler(item)}}},[_vm._v(" mdi-pen ")]),_c('v-icon',{staticClass:"ml-3 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteHandler(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }