<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData,
          searchnew: this.search,
          pageTitle: this.pageTitle,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData = $event"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>
      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import DisciplinaryRecordForm from "../../../components/personnel/disciplinaryrecordslisting/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": DisciplinaryRecordForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recdisciplinaryrec.editdisciplinaryrec'),
      pageTitleValue: "",
      formData: {
        staffid: null,
        staffcode: null,
        compnyid: null,
        disciplinid: null,
        severelevelid: null,
        casedesc: null,
        casedate: null,
        officerid: null,
        dsoutcomeid: null,
        supportdoc: null,
        supportdocname: null,
        remark: null,
        stamp: null,
        userid: this.$store.getters.userId,
      },
      disciplinaryrecordHolder: null,
      errMsg: "",
      isErr: false,
      editMode: true,
      loading: true,
      isConfirm: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      let vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/disciplinaryrecord/edit/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.status == 201) {
            vm.$router.push({ name: "disciplinarylist", params: { search: this.search}  });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/disciplinaryrecord/edit/" +
            this.disciplinaryrecordHolder.id +
            "/" +
            this.formData.userid,
          payload: { data: this.formData }})
        .then((res) => {
          if (res.data.status == 200) {
            vm.$router.push({ name: "disciplinarylist" });
          } else {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDisciplinaryRecordDetails() {
      const vm = this;

      this.$store.dispatch('get', {url: "/disciplinaryrecord/edit/" + this.id})
        .then((res) => {
          vm.disciplinaryrecordHolder = res.data.rec[0];

          
          this.loadDisciplinaryRecord(vm.disciplinaryrecordHolder);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadDisciplinaryRecord(item) {
      const vm = this;

      this.$store.dispatch('get', {url: "/disciplinaryrecord/edit/" + item.id})
        .then((res) => {
          vm.disciplinaryrecordHolder = res.data.rec[0];

          this.formData.staffid = vm.disciplinaryrecordHolder.staffid;
          this.formData.staffcode = vm.disciplinaryrecordHolder.staffcode;
          this.formData.compnyid = vm.disciplinaryrecordHolder.compnyid;
          this.formData.disciplinid = vm.disciplinaryrecordHolder.disciplinid;
          this.formData.severelevelid = vm.disciplinaryrecordHolder.severelevelid;
          this.formData.casedesc = vm.disciplinaryrecordHolder.casedesc;
          this.formData.casedate = moment(vm.disciplinaryrecordHolder.casedate).format("YYYY-MM-DD");
          this.formData.officerid = vm.disciplinaryrecordHolder.officerid;
          this.formData.dsoutcomeid = vm.disciplinaryrecordHolder.dsoutcomeid;
          this.formData.supportdoc = vm.disciplinaryrecordHolder.supportdoc;
          this.formData.supportdocname = vm.disciplinaryrecordHolder.supportdocname;
          this.formData.lockstaff = res.data.rec[0].lockstaff;
          this.formData.remark = vm.disciplinaryrecordHolder.remark;
          this.formData.stamp = vm.disciplinaryrecordHolder.stamp;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadDisciplinaryRecordDetails();
  },
};
</script>