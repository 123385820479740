<template>
    <div id="form-btn">

        <v-row justify="center" align="center">
            <div class="pt-5 pb-4">
                <v-btn 
                    v-if="this.WizardBool"
                    color="primary" 
                    style="margin-right:20px; width: 100px" 
                    @click="onNextPressed">
                    Next
                </v-btn>
                <v-btn
                    v-if="this.EditBool"
                    color="primary" 
                    style="margin-right:20px; width: 100px" 
                    @click="onNewPressed">
                    {{$t('button.new')}} 
                </v-btn>
                <v-btn 
                    v-if="!confirmAction"
                    color="success" 
                    style="margin-right:20px; width: 100px" 
                    @click="onSavePressed">
                    {{$t('button.save')}} 
                </v-btn>
                <v-btn 
                    v-if="confirmAction"
                    color="success" 
                    style="margin-right:20px; width: 100px" 
                    @click="onYesPressed">
                    {{$t('claimmessagemobile.yes')}} 
                </v-btn>
                <v-btn 
                    v-if="this.EditBool"
                    color="error" 
                    style="margin-right:20px; width: 100px" 
                    @click="onDeletePressed">
                    {{$t('button.delete')}} 
                </v-btn>
                <v-btn 
                    color="warning" 
                    style="width: 100px" 
                    @click="onCancelPressed">
                    {{$t('button.cancel')}} 
                </v-btn>
            </div>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        EditBool: Boolean,
        WizardBool: Boolean,
        confirmAction: Boolean
    },

    methods: {
        onNextPressed() {
            this.$emit('NextPressed');
        },

        onNewPressed() {
            this.$emit('NewPressed');
        },

        onSavePressed() {
            this.$emit('SavePressed');
        },

        onYesPressed() {
            this.$emit('YesPressed');
        },

        onDeletePressed() {
            this.$emit('DeletePressed');
        },

        onCancelPressed() {
            this.$emit('CancelPressed');
        }
    }
}
</script>