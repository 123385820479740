<template>
    <div>
        <v-dialog v-model="coveragedialog" max-width="900px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
           >+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('setuppolicymaster.cvragesetup')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                     <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setuppolicymaster.cvragetype')"
                                type="text"
                                ref="coverage"
                                clearable
                                :items="coverageTypeList"
                                hide-selected
                                :rules="[rules.required]"
                                v-model="selections[0].coverageid"
                                @change="changeCoverageTypeHandler(selections[0].coverageid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                     </v-row>

                    <v-row>

                      <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('setuppolicymaster.cvragevalue')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="selections[0].coveragevalue"
                            dense
                            class="v-input"
                        ></v-text-field>
                    </v-col>
                    </v-row>
             
            </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeCoverageInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveCoverageInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:[`item.action`]="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            coveragedialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                grpinsurancepolicyid: null,
                coverageid: null,
                coveragedesc: null,
                coveragevalue: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],
            

            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },

            coverageType: [],
            coverageTypeList: [],
            coverageTypeLoading: true,

            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,


    loadCoverageTypeHandler() {
        const vm = this;

            this.$store.dispatch('get', {
                url:  '/coveragetype/all'})
                .then(res => {
                    vm.coverageType = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {

                        vm.coverageTypeList.push(
                            {
                                text: res.data.rec[i].coverage,
                                value: res.data.rec[i].id
                            }
                        )
                    }
                    vm.coverageTypeLoading = false;

                    if (vm.selections[0].coverageid) {
                        vm.changeCoverageTypeHandler(vm.selections[0].coverageid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

           //change handler is to show the loaded contents
        changeCoverageTypeHandler(id) {
            let item = this.coverageType.find(item => item.id === id);

            this.selections[0].coveragedesc = item.coverage;
            //this.selections[0].trnexpensename = item.expensename; 
        },


      
       modifyItemHandler(item) {
            // assign loaded data to selections array
            if(item.action == 1) {
                this.modify = true;    
            }
            if(item.action == 2) {
                this.isExisting = true;    
            }

           // this.itemHolder = item
            this.selections[0] =  item;

            this.coveragedialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        saveCoverageInput() {

            console.log('detaill', this.detailItem)
            
             if (this.selections[0].coverageid == null || this.selections[0].coveragevalue == null){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                console.log('detailitem', this.detailItem)

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.coveragedialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        closeCoverageInput() {
            this.coveragedialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },



        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                grpinsurancepolicyid: null,
                coverageid: null,
                coveragedesc: null,
                coveragevalue: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {

       this.loadCoverageTypeHandler();
  
    }
}
</script>

<style>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>