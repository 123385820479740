var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.id == 25)?_c('monthlyeissocso-form',{attrs:{"parentObject":{
            data: this.item,
            id: this.id,
            errMsg: this.errMsg,
        }}}):_vm._e(),(_vm.isErr)?_c('div',[_c('app-error',{attrs:{"parentError":{
                data: this.errMsg,
                isErr: this.isErr
            }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }