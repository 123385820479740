<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                :items="staffDetailsList"
                                @input="updateValue"
                                @change="
                                    changeStaffIDandCompanyHandler(
                                        formData.staffid
                                    )
                                "
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="4" sm="10" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.name')"
                                type="text"
                                ref="psnid"
                                :rules="[rules.required]"
                                v-model="formData.staffid"
                                dense
                                :items="staffDetailsList"
                                readonly
                                background-color="#ffeab2"
                                
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.startdate')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.enddate')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                       <v-col cols="4" sm="4" class="pb-n4">
                           <v-autocomplete
                                outlined
                                :label="this.$t('attendancerecordsandprocesses.selectshift')"
                                id = "shiftid"
                                type="text"
                                ref= "shiftid"
                                :items="shiftList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.shiftid"
                                @change ="changeShiftHandler(formData.shiftid)"
                                dense
                        ></v-autocomplete>
                        </v-col>
                   
                       <v-col cols="4" sm="4" class="pb-n4">
                           <v-text-field
                                outlined
                                id="shiftcode"
                                v-model="shiftcode"
                                type="text"
                                :label="this.$t('attendancerecordsandprocesses.shiftcode')"
                                readonly
                                background-color="#ffeab2"
                                dense
                        ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail');
                }
            },
            formData: {
                psnid: this.parentObject.data.psnid,
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                compnyname: this.parentObject.data.compnyname,
                //groupid: this.parentObject.data.groupid,
               // wdaygrpdesc: this.parentObject.data.wdaygrpdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
              //  rdaypatterndesc: this.parentObject.data.rdaypatterndesc,
               // rdaypatterncode: this.parentObject.data.rdaypatterncode,
                shiftid: this.parentObject.data.shiftid,
                lockstaff:this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            groupList: [],
            groupdesc: '',
            valvalue: [],
            shift: [],
            shiftList: [],
            shiftcode: '',

            staffDetailsList: [],

            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew

        }
    },

    methods: {


        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/staff/alls"
            })
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;
                    console.log('staff', vm.staffdetails)
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);
            console.log('staffdetails', this.staffdetails)

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
        },


        loadShiftList() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/shiftsetup/all"
            })
                .then(res => {
                    vm.shift = res.data.rec;
                    console.log('shift', vm.shift)

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.shiftList.push(
                                {
                                    text: res.data.rec[i].shiftdesc,
                                    value: res.data.rec[i].id
                                }
                            )    
                        }
                    }

                    if (this.formData.shiftid) {
                        this.changeShiftHandler(this.formData.shiftid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeShiftHandler(id) {
            let item = this.shift.find(item => item.id === id);

            this.shiftcode = item.shiftcode;
        },


        updateValue() {
            this.$emit('input', {
                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                compnyname: this.formData.compnyname,
              //  wdaygrpdesc: this.formData.wdaygrpdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                shiftid: this.formData.shiftid,
               // rdaypatterncode: this.formData.rdaypatterncode,
               // rdaypatterndesc: this.formData.rdaypatterndesc,
                lockstaff:this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'workschedulestafflist', params: { search: this.search}});
        },
    },

    mounted() {
       // this.loadValvalueList();
        this.loadShiftList();
        this.loadStaffDetailsListHandler();

        console.log('abc')

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }


    }
    
}
</script>