<template>
  <div v-if="!loading">
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">{{ this.pageTitle }}</h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('alleis.eissubmissionrec')"
                type="text"
                v-model="formData.eisexprecid"
                :items="eisExpRecList"
                :rules="[rules.required]"
                @change="changeEISExportRecord(formData.eisexprecid)"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="3" class="mb-n1 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('nonbankpaymentlistingreport.payoutyear')"
                type="text"
                v-model="formData.year"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" class="mb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('bankpaymentlistingreport.payoutmonth')"
                type="text"
                v-model="formData.month"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="blue--text ml-2"> {{$t('statutoryexportcommonused.payouts')}} </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <v-data-table
                :headers="dataHeader"
                :items="this.payoutItems"
                :options="tableOpt"
                hide-default-footer
                item-key="payoutcode"
              >
              </v-data-table>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-3">
              <v-text-field
                outlined
                :label="Reference No."
                type="text"
                v-model="formData.refnum"
                :rules="[rules.required]"
                readonly
                dense
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row> -->

           <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-3">
              <v-autocomplete
                outlined
                :label="this.$t('setupcompanyreferencenumber.referencenumber')"
                type="text"
                v-model="formData.refnum"
                :rules="[rules.required]"
                :items="eisSubRefRecList"
                dense
            
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                v-model="formData.eisexpformatid"
                :label="this.$t('alleis.eisexportformat')"
                type="text"
                :items="this.exportFmtList"
                :rules="[rules.required]"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        <!-- </v-container> -->

        <v-row>
          <div class="pt-5 pl-4 mt-n6">
            <v-btn color="warning" style="width: 100px; margin-bottom: 20px;" @click="nextHandler">
              {{$t('button.next')}}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-error": ErrorDialog,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      pageTitle: this.$t('alleis.eisexportprocess'),
      dataHeader: [
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "150px", sortable: true },
        {
          text: this.$t('statutoryexportcommonused.payoutdescription'),
          value: "payoutdesc",
          width: "300px",
          sortable: true,
        },
        {
          text: this.$t('payrollprocessrun.payoutstatus'),
          value: "pystatusdesc",
          width: "300px",
          sortable: true,
        },
      ],
      formData: {
        eisexprecid: null,
        refnum: null,
        year: null,
        month: null,
        myntlycode: null,
        eissubrecpy: [],
        eissubrecref: [],
        eisexpformatid: null,
      },

      tableOpt: {
        itemsPerPage: -1,
      },

      items: [],
      dataItems: [],
      salaryExpRecList: [],
      eisExpRecList: [],
      payoutItems: [],
      exportformatItems: [],
      exportFmtList: [],
      eisSubRefRecList: [],

      notFinalize: false,
      data2: [],
      loading: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    getEISSubRecs() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eisubmissionrecord/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.eisExpRecList.push({
              text: res.data.rec[i].eissubreccode,
              value: res.data.rec[i].id,
            });
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    getEISSubRecPayout(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eissubmissionrecordpy/" + id})
        .then((res) => {
          vm.payoutItems = res.data.rec;
          vm.formData.eissubrecpy = vm.payoutItems;

          for (let i = 0; i < vm.payoutItems.length; i++) {
            if (vm.payoutItems[i].pystatuscode != 99) {
              this.notFinalize = false;
            } else {
              this.notFinalize = true;
            }

             vm.data2.push(this.notFinalize)

          }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getEISSubRecRef(id) {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/eissubmissionrecordref/" + id})
        .then((res) => {
          vm.formData.eissubrecref = res.data.rec;
          
          //set the eisSubRefRecList array null (to avoid getting value from previous array)
          vm.eisSubRefRecList = [];

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.eisSubRefRecList.push({
              text: res.data.rec[i].refcode + ' - ' + res.data.rec[i].refnum,
              value: res.data.rec[i].refnum,
            });
          }

          // if (res.data.rec[0] == undefined) {
          //   vm.formData.refnum = null;
          // } else {
          //   vm.formData.refnum = res.data.rec[0].refnum;
          // }

          vm.loading = !vm.loading;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    getExportFormattListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/exportformat/all"})
        .then(function (res) {
          vm.exportformatItems = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            if (res.data.rec[i].exptypeid == 4) {
              vm.exportFmtList.push({
                text: res.data.rec[i].expdesc,
                value: res.data.rec[i].id,
              });
            }
          }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    changeEISExportRecord(id) {
      let itemExportRecord = this.dataItems.find((item) => item.id === id);

      this.formData.eisexprecid = itemExportRecord.id;
      this.formData.year = itemExportRecord.recyear;
      this.formData.month = itemExportRecord.recmonth;
      this.formData.myntlycode = itemExportRecord.myntlycode;

      this.getEISSubRecPayout(id);
      this.getEISSubRecRef(id);
    },

    nextHandler() {
      let item = this.formData;

      let id = this.formData.eisexpformatid;
      var newvalue = true;
    
      for (let i = 0; i < this.data2.length; i++) {
         
        if (!this.data2[i])
        {
          newvalue = false;
        }
      }
      
      if (newvalue == false) {
        this.isErr = true;
        this.errMsg = this.$t('vdatatable.payoutnotfinalized');
      } else {
        if (this.$refs.form.validate()) {
          this.$router.push({
            name: "monthlyeisexportparam",
            params: { id, item },
          });
        } else {
          this.isErr = true;
          this.errMsg = this.$t('vdatatable.someinfoismissing');
        }
      }
    },
  },

  mounted() {
    this.getEISSubRecs();
    this.getExportFormattListHandler();
  },
};
</script>