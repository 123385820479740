<template>
    <div>
        <app-gridviewmaintenance
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItemHandler="deleteItemHandler($event)"
            v-on:NewPressedHandler="newRecordHandler"
        ></app-gridviewmaintenance>
    </div>
</template>

<script>
// import axios from 'axios';
import moment from 'moment';
import GridViewMaintenance from "../../../components/settings/workflowmaintenance/NewGrid";
//import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridviewmaintenance': GridViewMaintenance
    },  
    
    data() {
        return {
            pageTitle: this.$t('recworkflowmaintenance.workflowmaintenancelist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                {text: this.$t('columns.workflowtype'), value: 'wftypedesc', width: '200px'},
                {text: this.$t('recworkflowmaintenance.recid'), value: 'recordid', width: '150px'},
                {text: this.$t('columns.staffcode'), value: 'staffcode', width: '150px'},
                {text: this.$t('columns.staffname'), value: 'psnname', width: '250px'},
                {text: this.$t('columns.company'), value: 'compnyname', width: '200px'},
                {text: this.$t('columns.recorddate'), value: 'recdate', width: '200px'},
            ],
            dataItems: [],
            leaveTypeHolder: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        loadWorkflows() {
            const vm = this;

            this.$store.dispatch('get', {url: `/workflowmaintenancenew/all`})
            .then(function (res) {
                vm.dataItems = res.data.rec;
                console.log('dataitems', vm.dataItems)
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].recdate) {
                        vm.dataItems[i].recdate = moment(vm.dataItems[i].recdate).format('YYYY-MM-DD');
                    }
                }

                console.log('dataitems', vm.dataItems)

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadLeaveType() {
            const vm = this;
            
            this.$store.dispatch('get', {
                url: `/leavetype/all`
            })
            .then (function (res) {
                this.leaveTypeHolder = res.data.rec;
            })
            .catch (err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
            });
            
        },

        detailHandler() {
            const vm = this;
            for (let i = 0; i < vm.dataItems.length; i++) {
                if (vm.dataItems[i].wftypeid == 3) {
                    let item = this.leaveTypeHolder.filter(val => val.id == vm.dataItems[i].detailid)[0]
                    vm.dataItems[i].detaildesc = item.leavedesc
                }
            }
        },

        editItemHandler(item) {
            this.$router.push({name: 'workflowmaintenanceedit', params: { id: item.id, search: item.search  } });
        },

        newRecordHandler() {

          //  this.$router.push({name: 'workflowmaintenancenew'});
        }
    },

    mounted() {
        this.loadWorkflows();
    }
}
</script>