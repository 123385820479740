<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="newHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>
<script>
import Form from "../../../components/attendance/workhourspayitemmapping/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": Form,
    "app-error": ErrorDialogue,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('workhourspayitemmappingsetup.editworkhourspayitemmapping'),
      pageTitleValue: "",
      formData: {
        id: null,
        mapset: null,
        mapsetdesc: null,
        dataItems: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      cur_stamp: 0,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
      loading: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    //insert new record
    saveHandler() {
      const vm = this;

      vm.formData.id = vm.id;
      vm.formData.stamp = vm.cur_stamp;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/workhourspayitemmapping/" + vm.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workhourspayitemmappinglist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadWkHrsMap() {
      const vm = this;
      vm.loading = true;
      this.$store.dispatch('get', {url: "/workhourspayitemmapping/" + vm.id})
        .then((res) => {
          console.log(res.data.rec);
          vm.formData.id = res.data.rec.id;
          vm.formData.mapset = res.data.rec.mapset;
          vm.formData.mapsetdesc = res.data.rec.mapsetdesc;
          vm.formData.stamp = res.data.rec.stamp;
          vm.cur_stamp = res.data.rec.stamp;

          for (var i = 0; i < res.data.rec.dataItems.length; i++) {
            let detailsitem = {
              index: i,
              id: res.data.rec.dataItems[i].id,
              daytypeid: res.data.rec.dataItems[i].daytypeid,
              daytypecode: res.data.rec.dataItems[i].daytypecode,
              daytypedesc: res.data.rec.dataItems[i].daytypedesc,
              fromhours: res.data.rec.dataItems[i].fromhrs,
              uptohours: res.data.rec.dataItems[i].uptohrs,
              payitemid: res.data.rec.dataItems[i].payitemid,
              payitemcode: res.data.rec.dataItems[i].payitemcode,
              payitemdesc: res.data.rec.dataItems[i].payitemdesc,
              exclusive: res.data.rec.dataItems[i].isexclusive,
              IsExclusive:
                res.data.rec.dataItems[i].isexclusive == "Y" ? true : false,
              sourcehour: res.data.rec.dataItems[i].sourcehrs,
              mappedqty: res.data.rec.dataItems[i].mapqty,
              action: 1,
              stamp: res.data.rec.dataItems[i].stamp,
              userid: this.$store.getters.userId,
            };
            vm.formData.dataItems.push(detailsitem);
          }
          vm.loading = false;
          console.log(vm.formData);
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "workhourspayitemmappinglist" });
    },

    newHandler() {
      this.$router.push({ name: "workhourspayitemmappingnew" });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url: "/workhourspayitemmapping/" + vm.id})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "workhourspayitemmappinglist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
  },
  mounted() {
    this.loadWkHrsMap();
  },
};
</script>