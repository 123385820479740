<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5 pt-8"
                >


                 <v-row v-if="!this.formData.lockstaff">
                      <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('columns.name')"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="formData.staffid"
                        dense
                        :items="staffDetailsList"
                        @input="updateValue"
                        @change="changeStaffIDandCompanyHandler(formData.staffid)"
                        ></v-autocomplete>
                    </v-col>
                    </v-row>

                    <v-row v-else>
                    <v-col cols="4" sm="10" class="pb-n5 mt-n6">
                            <v-autocomplete
                            outlined
                            :label="this.$t('columns.name')"
                            type="text"
                            ref="psnid"
                            :rules="[rules.required]"
                            v-model="formData.staffid"
                            dense
                            :items="staffDetailsList"
                            readonly
                            background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                       <v-row >
                        <v-col v-if="!this.formData.lockstaff" cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('setupclaimlimit.selectclaimtype')"
                                    type="text"
                                    ref="clmtype"
                                    :rules="[rules.required]"
                                    v-model="formData.clmtypeid"
                                    dense
                                    :items="clmtypeList"
                                    @input="updateValue"
                                    @change="changeParentClaim(formData.clmtypeid)"
                                ></v-autocomplete>
                            </v-col>

                    <v-col v-else cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-autocomplete
                            outlined
                            :label="this.$t('setupclaimlimit.selectclaimtype')"
                            type="text"
                            ref="clmtype"
                            
                            v-model="formData.clmtypeid"
                            dense
                            :items="clmtypeList"
                            readonly
                            background-color="#ffeab2"
                            ></v-autocomplete>
                        </v-col>
                    

                            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypedesc"
                                    v-model="clmtypedesc"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>
                    </v-row>

                    <v-row>
                             <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('setupclaimlimit.claimtypedescription')"
                                    type="text"
                                    ref="clmtypecat"
                                    v-model="clmtypecat"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>

                             <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                <v-text-field
                                    outlined
                                    :label="this.$t('claimrecordlisting.entrydate')"
                                    type="text"
                                    ref="entrytime"
                                    v-model="formData.entrytime"
                                    dense
                                    readonly
                                    background-color="#ffeab2"
                                ></v-text-field>
                            </v-col>

                    </v-row>

                    <v-row v-if="hidetable">
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('claimrecordlisting.medicalclaim')}}
                        </v-col>
                    </v-row>

                    <v-row v-if="hidetable">
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.claimrecorddetail,
                                clmtypeid : this.clmtypeid,
                                staffid: this.formData.staffid
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>

                     <v-row v-if="hidetable1" >
                        <v-col  cols="6" sm="6" class="blue--text">
                            {{$t('claimrecordlisting.travellingclaim')}}
                        </v-col>
                    </v-row> 

                    <v-row v-if="hidetable1">
                        <app-table2
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader1,
                                detailItem: this.formData.claimrecorddetail,
                                clmtypeid : this.clmtypeid,
                                 staffid: this.formData.staffid
                            }"

                            v-on:input="addItemHandler"
                        />

                    </v-row>

                        <v-row v-if ="hidetable2">
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('claimrecordlisting.othersclaim')}}
                        </v-col>
                    </v-row>

                    <v-row v-if="hidetable2">
                        <app-table3
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader2,
                                detailItem: this.formData.claimrecorddetail,
                                clmtypeid : this.clmtypeid,
                                 staffid: this.formData.staffid
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row> 
                </v-container>

                <app-formbuttons
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />
            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import CRUDTable2 from './CRUDTable2';
import CRUDTable3 from './CRUDTable3';


export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
         'app-table2': CRUDTable2,
         'app-table3': CRUDTable3,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('periodicincentivesetup.invalidemail')
                    }
            },

            formData: {
                psnid:             this.parentObject.data.psnid,
                staffid:           this.parentObject.data.staffid,
                staffcode:         this.parentObject.data.staffcode,
                compnyid:          this.parentObject.data.compnyid,
                compnyname:        this.parentObject.data.compnyname,
                clmtypeid:         this.parentObject.data.clmtypeid,
                entrytime:         this.parentObject.data.entrytime,
                clmtypecatid:      this.parentObject.data.clmtypecatid,
                claimrecorddetail: this.parentObject.data.claimrecorddetail,
                lockstaff:         this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp:             this.parentObject.data.stamp,
                userid:            this.parentObject.data.userid
            },

            clmtypeid: '',
            staffdetails: [],
            staffDetailsList: [],

            clmtypeItems: [],
            clmtypeList: [],
            clmtypedesc: '',
            clmtypecat: '',
            clmtypecatid: '',

            chargeItems: [],
            chargeList: [],
            chargecodes: [],

            clmtypecatList: [],
            clmtypecatItems: [],
            ClaimCategoryLoading: true,

            companies: [],
            companiesitemsList: [],

            singleSelect: false,
            dataItems: [],
        
            tableOpt: {
                itemsPerPage: -1
            },


            selected: [],
            affectItems: [],

            tabHeader: [
                { text: this.$t('columns.actions'),            value: 'action',    width: '100px', sortable: false},
                { text: this.$t('claimrecordlisting.receiptdate'),     value: 'receiptdate',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptno'),       value: 'receiptno',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptcurrency'),          value: 'crcydesc',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimamount'),      value: 'receiptclmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.taxamount'),        value: 'receiptclmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.amountbeforetax'), value: 'receiptclmamtb4tax',          width: '200px', sortable: true},
                { text: this.$t('payeeinputprocess.approvedamount'),   value: 'receiptaprvamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedcurrency'),          value: 'clmtypecrycdesc',          width: '200px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedclaimamount'),      value: 'clmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedtaxamount'),        value: 'clmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedamountbeforetax'), value: 'clmamtb4tax',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedapprovedamount'),   value: 'aprvamt',          width: '150px', sortable: true},
                { text: this.$t('panelclinic'),      value: 'panelclinicdesc',          width: '150px', sortable: true},
                { text: this.$t('columns.clinic'),            value: 'clinicname',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimremark'),      value: 'clmremark',          width: '150px', sortable: true},
                { text: this.$t('preapprovedotrecord.approvalremark'),   value: 'aprvremark',          width: '150px', sortable: true},
                { text: this.$t('columns.status'),            value: 'transtatus',          width: '150px', sortable: true},
                { text: this.$t('recleaverec.postedtopayroll'),value: 'posted',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.historydata'),      value: 'ishistory',          width: '150px', sortable: true},
                { text: this.$t('payrollprocessrun.payout'),            value: 'payoudesc',          width: '200px', sortable: true},
            ],

             tabHeader1: [
                { text: this.$t('columns.actions'),       value: 'action',    width: '100px', sortable: false},
                { text: this.$t('claimrecordlisting.receiptdate'),  value: 'receiptdate',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptno'), value: 'receiptno',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptcurrency'),          value: 'crcydesc',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimamount'),      value: 'receiptclmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.taxamount'),        value: 'receiptclmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.amountbeforetax'), value: 'receiptclmamtb4tax',          width: '200px', sortable: true},
                { text: this.$t('payeeinputprocess.approvedamount'),   value: 'receiptaprvamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedcurrency'),          value: 'clmtypecrycdesc',          width: '200px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedclaimamount'),      value: 'clmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedtaxamount'),        value: 'clmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedamountbeforetax'), value: 'clmamtb4tax',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedapprovedamount'),   value: 'aprvamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.distanceinkm'),value: 'distance',          width: '150px', sortable: true},
                { text: this.$t('setupclaimlimit.vehichletype'), value: 'vehtype',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.fromlocation'),value: 'fromloc',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.tolocation'),value: 'toloc',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimremark'), value: 'clmremark',          width: '150px', sortable: true},
                { text: this.$t('recleaverec.apprvlremark'), value: 'aprvremark',          width: '150px', sortable: true},
                { text: this.$t('preapprovedotrecord.status'),value: 'transtatus',          width: '150px', sortable: true},
                { text: this.$t('recleaverec.postedtopayroll'), value: 'posted',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.historydata'),value: 'ishistory',          width: '150px', sortable: true},
                { text: this.$t('payrollprocessrun.payout'), value: 'payoudesc',          width: '200px', sortable: true},
            ],

             tabHeader2: [
                { text: this.$t('columns.actions'),       value: 'action',    width: '100px', sortable: false},
                { text: this.$t('claimrecordlisting.receiptdate'),  value: 'receiptdate',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptno'), value: 'receiptno',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.receiptcurrency'),          value: 'crcydesc',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimamount'),      value: 'receiptclmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.taxamount'),        value: 'receiptclmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.amountbeforetax'), value: 'receiptclmamtb4tax',          width: '200px', sortable: true},
                { text: this.$t('payeeinputprocess.approvedamount'),   value: 'receiptaprvamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedcurrency'),          value: 'clmtypecrycdesc',          width: '200px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedclaimamount'),      value: 'clmtotalamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedtaxamount'),        value: 'clmtaxamt',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedamountbeforetax'), value: 'clmamtb4tax',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.convertedapprovedamount'),   value: 'aprvamt',          width: '150px', sortable: true},
               //  { text: 'Receipt Attachment', value: 'receiptattach',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.claimremark'), value: 'clmremark',          width: '150px', sortable: true},
                { text: this.$t('recleaverec.apprvlremark'), value: 'aprvremark',          width: '150px', sortable: true},
                { text: this.$t('preapprovedotrecord.status'),value: 'transtatus',          width: '150px', sortable: true},
                { text: this.$t('recleaverec.postedtopayroll'), value: 'posted',          width: '150px', sortable: true},
                { text: this.$t('claimrecordlisting.historydata'),value: 'ishistory',          width: '150px', sortable: true},
                { text: this.$t('payrollprocessrun.payout'), value: 'payoudesc',          width: '200px', sortable: true},
            ],

            monthList: [],
            yearList: [],
            payoutList: [],
            months: [],
            years: [],

            hidetable: false,
            hidetable1: false,
            hidetable2: false,

            errMsg: '',
            isErr: false,
            isEdit: this.parentObject.isEdit,
            search: this.parentObject.searchnew
        }
    },

    methods: {

        LoadClaimCategoryType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypecategory/all'})
                .then(res => {
                    vm.clmtypecatItems = res.data.rec;

                    for (let i = 0; i < vm.clmtypecatItems.length; i++) {
                        vm.clmtypecatList.push(
                            {
                                text: vm.clmtypecatItems[i].clmtypecat,
                                value: vm.clmtypecatItems[i].id
                            }
                        )}

                    vm.ClaimCategoryLoading = false;
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         //load companies list
        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/staff/alls'})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
        },

             changeParentClaim() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/claimtypeparent/all'})
            .then(res => {

                vm.clmtypeItems = res.data.rec;

                for (let i = 0; i < vm.clmtypeItems.length; i++) {
                    vm.clmtypeList.push({
                        text: vm.clmtypeItems[i].clmtype,
                        value: vm.clmtypeItems[i].id,
                       // desc: res.data.rec[i].clmtypecat,
                    });
                }

                if (this.formData.clmtypeid) {
                this.changeParentClaimHandler(this.formData.clmtypeid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeParentClaimHandler(id) {
            var item = this.clmtypeItems.find(item => item.id === id);
           // console.log('item', item)
            this.clmtypeid = item.id;
            this.clmtypedesc = item.clmtypedesc;
            this.clmtypecat = item.clmtypecat;
            this.formData.clmtypecatid = item.clmtypecatid;
          //  this.clmtypecat = item.clmtypecat

             if(this.formData.clmtypecatid == '1' && this.formData.clmtypecatid != '2' && this.formData.clmtypecatid != '3')
             {
                
                this.hidetable = true;
             } else {
                    this.clmtypeid = this.clmtypeid;
                    this.formData.staffid = this.formData.staffid; 
                    this.hidetable = false;
             }

             if(this.formData.clmtypecatid == '2' && this.formData.clmtypecatid != '1' && this.formData.clmtypecatid != '3') {
                 this.hidetable1 = true;
             } else {
                    this.clmtypeid = this.clmtypeid;
                    this.formData.staffid = this.formData.staffid; 
                    this.hidetable1 = false;
             }

            //  console.log('hidetable2', this.hidetable2)  
             if (this.formData.clmtypecatid == '3' && this.formData.clmtypecatid != '2' && this.formData.clmtypecatid != '2'){
              
             this.hidetable2 = true;
             
             } else {
                    this.clmtypeid = this.clmtypeid; 
                    this.formData.staffid = this.formData.staffid; 
            //   console.log('clmtypeid', this.clmtypeid)  
                    this.hidetable2 = false;
             }    

        },

        addItemHandler($event) {
            this.formData.claimrecorddetail=$event
            this.clmtypeid = this.clmtypeid;

            this.updateValue();
        },

        updateValue() {
          //  console.log('formdata', this.formData)
            this.$emit('input', {

                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                compnyid: this.formData.compnyid,
                compnyname: this.formData.compnyname,
                clmtypeid: this.formData.clmtypeid,
                clmtypecatid: this.formData.clmtypecatid,
                entrytime: this.formData.entrytime,
                claimrecorddetail: this.formData.claimrecorddetail,
                lockstaff: this.formData.lockstaff,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        // check(){
        //     console.log('claimrecorddetail', this.formData.claimrecorddetail)
        // },

        cancelHandler() {
            this.$router.push({name: 'claimrecordlist', params: { search: this.search}});
        },

    },

    mounted() {
        //this.loadPayout();
        this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
        this.changeParentClaim();
        this.LoadClaimCategoryType();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }

      //  this.check()

      //  console.log('update', this.updateValue)
    }
}
</script>