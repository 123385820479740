<template>
<div>
  <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="180"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
  <div v-if="!loading2">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        editMode: this.editMode,
      }"
      v-on:new="createHandler"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
       v-on:cancel="cancelHandler"
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</div>
</template>

<script>
import moment from "moment";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import GrpInsAssignmentSetup from "../../../components/insurance/grpinsassignment/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": GrpInsAssignmentSetup,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
    Loading,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('recgrpinsuranceassignment.grpinsuranceassignmentedit'),
     // pageTitleValue: "",
      pageTitleValue: "",
      formData: [],
      dependentdetail: [],
      insurancedetail: [],
      loading : false,
      headLoading: true,
      loading2: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadGroupInsuranceAssignmentHandler() {
      const vm = this;
      vm.loading = true;

      this.$store.dispatch('get', {url: "/grpinsassignment/" + this.id})
        .then((res) => {

          vm.loading = false;
          vm.formData = res.data.rec[0];

          

          if (res.data.rec[0].startdate) {
              vm.formData.startdate = moment(res.data.rec[0].startdate).format(
              "YYYY-MM-DD"
            );
          }

          if (res.data.rec[0].enddate) {
              vm.formData.enddate = moment(res.data.rec[0].enddate).format(
              "YYYY-MM-DD"
            );
          }


         // vm.formData.lockstaff = res.data.rec[0].lockstaff;
         vm.formData.lockstaff = res.data.rec[0].lockstaff;

          vm.formData.userid = this.$store.getters.userId;
         // vm.pageTitleValue = res.data.rec[0].payitemdesc;

       
          
         vm.headLoading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

      
    loadDependentHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/dependentdetail/" + this.id})
        .then((res) => {
          vm.formData.dependentdetail = res.data.rec;

          
          for (let i = 0; i < vm.formData.dependentdetail.length; i++) {
              
              if (res.data.rec[i].dob) {
              vm.formData.dependentdetail[i].dob = moment(res.data.rec[i].dob).format(
              "YYYY-MM-DD");
            }
            
            Object.assign(vm.formData.dependentdetail[i], { action: 2 });
          }


          // vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    
    // loadDependentHandler() {
    //   const vm = this;

    //   axios
    //     .get', {url: "/dependentdetail/" + this.id})
    //     .then((res) => {

    //       vm.formData.dependentdetail = res.data.rec;

    //       for (let i = 0; i < vm.formData.dependentdetail.length; i++) {

    //          if (res.data.rec[i].dob) {
    //           vm.formData.dependentdetail[i].dob = moment(res.data.rec[i].dob).format(
    //           "YYYY-MM-DD");
    //         }

    //         Object.assign(vm.formData.dependentdetail[i], { action: 2 });
    //       }

    //     })
    //     .catch((err) => {
    //       vm.isErr = !vm.isErr;
    //       vm.errMsg = err;
    //     });
    // },


      loadInsuranceDetailHandler() {

         const vm = this;

      this.$store.dispatch('get', {url: "/insurancedetail/" + this.id})
        .then((res) => {
          vm.formData.insurancedetail = res.data.rec;

          for (let i = 0; i < vm.formData.insurancedetail.length; i++) {

             if (res.data.rec[i].movdate) {
               vm.formData.insurancedetail[i].movdate = moment(res.data.rec[i].movdate).format(
               "YYYY-MM-DD");
             }
            
            
            Object.assign(vm.formData.insurancedetail[i], { action: 2 });
          }

          vm.loading2 = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });

      },

    //   loadInsuranceDetailHandler() {

    //      const vm = this;

    //   axios
    //     .get', {url: "/insurancedetail/" + this.id})
    //     .then((res) => {
    //       vm.formData.insurancedetail = res.data.rec;

    //       console.log('insurancedetail', vm.formData.insurancedetail)

    //       for (let i = 0; i < vm.formData.insurancedetail.length; i++) {

    //          if (res.data.rec[i].movdate) {
    //           vm.formData.insurancedetail[i].movdate = moment(res.data.rec[i].movdate).format(
    //           "YYYY-MM-DD"
    //         );

    //      }

          
    //       }
            
    //         for (let i = 0; i < vm.formData.insurancedetail.length; i++) {
    //             console.log('yepaa',vm.formData.insurancedetail[i])
    //         Object.assign(vm.formData.insurancedetail[i], { action: 2 });
    //         Object.assign(vm.formData.insurancedetail[i], { no: i + 1 });
    //       }
          

    //       vm.loading2 = false;
    //     })
    //     .catch((err) => {
    //       vm.isErr = !vm.isErr;
    //       vm.errMsg = err;
    //     });
  
    // },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      //console.log('formdata', payload: this.formData})
      this.$store.dispatch('put', {url: "/grpinsassignment/" + this.id, 
          payload: vm.formData})
        .then((res) => {

        for (let i = 0; i < vm.formData.insurancedetail.length; i++) {

        if(vm.formData.insurancedetail[i].dependentmovement == true && vm.formData.insurancedetail[i].insurancemovtypeid == '3' ||
          vm.formData.insurancedetail[i].dependentmovement == true && vm.formData.insurancedetail[i].insurancemovtypeid == '4' ||
          vm.formData.insurancedetail[i].insurancemovtypeid == '3' || vm.formData.insurancedetail[i].insurancemovtypeid == '4' ||
          vm.formData.insurancedetail[i].dependentmovement == true) {
              this.$store.dispatch('post', {url: "/movementupdate/movement" , payload: vm.formData.insurancedetail[i]})
                .then((res) => { 

                   let dataholder = res.data.rec;

                    console.log('dataHolder', dataholder)
                })
   
             }
        }
          
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grpinsassignmentlist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });

    
    },

    cancelHandler() {
      this.$router.push({ name: "grpinsassignmentlist" });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/grpinsassignment/" +
            vm.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "grpinsassignmentlist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "grpinsassignmentnew" });
    },
  },

  mounted() {
    this.loadGroupInsuranceAssignmentHandler(); //header
    this.loadDependentHandler(); //data table item   
    this.loadInsuranceDetailHandler(); //crudtable items
  },
};
</script>