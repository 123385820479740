<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('setupepf.epfsetuplist'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width: '80px'},
                {text: this.$t('setupepf.epfgroup'), value: 'epfgroupcode', width: '150px'},
                {text: this.$t('columns.description'), value: 'epfgroupdesc', width: '200px'},
                {text: this.$t('columns.effectivefrom'), value: 'startdate', width: '150px'},
                {text: this.$t('columns.effectiveuntil'), value: 'enddate', width: '150px'},
                {text: this.$t('columns.remark'), value: 'remark', width: '200px'}
            ],
            dataItems: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {

        //get all epfgroups 
        loadEPFGroups() {
            const vm = this;

            this.$store.dispatch('get', {url: '/epfsetupdt/all'})
            .then(res => {

                //store data returned in arrays 
                vm.dataItems = res.data.rec;

                //set to correct date format using moment 
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'epfsetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'epfsetupnew'});
        }
    },

    mounted() {
        this.loadEPFGroups();
    }
}
</script>