<template>
    <div>
        <app-gridview
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>

import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },  
    
    data() {
        return {
            pageTitle: this.$t('alltax.monthlytaxsubmissionrec'),
            dataHeader: [
                {text: this.$t('columns.actions'), value: 'action', sortable: false, width: '100px'},
                {text: this.$t('alltax.taxsubmissionrec'), value: 'taxsubreccode', width: '150px'},
                {text: this.$t('columns.description'), value: 'taxsubrecdesc', width: '200px'},
                {text: this.$t('reportsparam.year'), value: 'recyear', width: '120px'},
                {text: this.$t('reportsparam.month'), value: 'recmonth', width: '120px'}
            ],
            dataItems: [],
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {

        //get all records 
        getTaxSubRecs() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/mmtaxsubmissionrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    //set to correct date format using moment 
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },
        

        editItemHandler(item) {
            this.$router.push({name: 'mmmonthlytaxsubmissionrecordedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'mmmonthlytaxsubmissionrecordnew'});
        }
    },

    mounted() {
        this.getTaxSubRecs();
    }
}
</script>