<template>
    <div>
        <monthlyepf-form v-if="this.id == 2"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />
        <monthlyepfhlb-form v-if="this.id == 8"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />
        <monthlyepfambank-form v-if="this.id == 13"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />
        <monthlyepfcimb-form v-if="this.id == 20"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />

        <monthlyepfaffin-form v-if="this.id == 33"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />

        <monthlyepfpublic-form v-if="this.id == 27"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />

        <monthlyepfhsbc-form v-if="this.id == 38"
            v-bind:parentObject = "{
                data: this.item,
                id: this.id,
                errMsg: this.errMsg,
                pageTitle: this.pageTitle,
            }"
            
            v-on:input="formData = $event"
        />
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>   
    </div>
</template>

<script>
import MonthlyEPFForm from '../../../components/payroll/monthlyepfexport/MonthlyEPF';
import MonthlyEPFHLBForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFHLB';
import MonthlyEPFAmbankForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFAmbank';
import MonthlyEPFCIMBForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFCIMB';
import MonthlyEPFPUBLICForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFPublic';
import MonthlyAffinForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFAffin';
import MonthlyHSBCForm from '../../../components/payroll/monthlyepfexport/MonthlyEPFHSBC';
import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'monthlyepf-form': MonthlyEPFForm,
        'monthlyepfhlb-form': MonthlyEPFHLBForm,
        'monthlyepfambank-form': MonthlyEPFAmbankForm,
        'monthlyepfcimb-form':MonthlyEPFCIMBForm,
        'monthlyepfpublic-form':MonthlyEPFPUBLICForm,
        'monthlyepfaffin-form':MonthlyAffinForm,
        'monthlyepfhsbc-form':MonthlyHSBCForm,
        'app-error': ErrorDialog
    },

    data() {
        return{
            pageTitle: this.$t('allepf.monthlyepf'),
            item: this.$route.params.item,
            id: this.$route.params.id,
            
            errMsg: '',
            isErr: false,
            editMode: false 
        }
    },

}

</script>