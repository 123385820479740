<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
import GridView from "../../../components/common/GridView";
import moment from "moment";
export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('preapprovedot'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.staffid'), value: "staffcode", width: "200px" },
        { text: this.$t('columns.name'), value: "psnname", width: "200px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "200px" },
        { text: this.$t('staffdailyrosterrecord.shiftdate'), value: "shiftdate", width: "200px" },
        { text: this.$t('preapprovedotrecord.otstart'), value: "otstart", width: "200px" },
        { text: this.$t('preapprovedotrecord.otend'), value: "otend", width: "200px" },
        { text: this.$t('setupforshift.othours'), value: "othrs", width: "200px" },
        { text: this.$t('columns.status'), value: "transtatus", width: "200px" },
        { text: this.$t('payeeinputprocess.justification'), value: "justify", width: "200px" },
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
       search: this.$route.params.search,
    };
  },

  methods: {
    getPreApprovedOtHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/preapprovedot/all"})
        .then(function (res) {
          // vm.dataItems = res.data.rec;
          for (var i = 0; i < res.data.rec.length; i++) {
            vm.dataItems.push({
              action: 1,
              id: res.data.rec[i].id,
              staffid: res.data.rec[i].staffid,
              staffcode: res.data.rec[i].staffcode,
              psnname: res.data.rec[i].psnname,
              compnyid: res.data.rec[i].compnyid,
              compnycode: res.data.rec[i].compnycode,
              shiftdate: moment(res.data.rec[i].shiftdate).format("YYYY-MM-DD"),
              otstart: moment(res.data.rec[i].otstart).format("HH:mm"),
              otend: moment(res.data.rec[i].otend).format("HH:mm"),
              othrs: res.data.rec[i].othrs,
              transtatus: res.data.rec[i].transtatus,
              justify: res.data.rec[i].justify,
            });
          }

          console.log(vm.dataItems);

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({
        name: "preapprovedotedit",
        params: { id: item.id, search: item.search },
      });
    },

    newRecordHandler() {
      this.$router.push({ name: "preapprovedotnew" });
    },
  },

  mounted() {
    this.getPreApprovedOtHandler();
  },
};
</script>