<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
//import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setupforshift.shiftsetuplist'),
      dataHeader: [

        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.code'), value: "shiftcode", width: "120px" },
        { text: this.$t('columns.description'), value: "shiftdesc", width: "200px" },
        { text: this.$t('setupforshift.fixedtime'), value: "fixed", width: "120px" },
        { text: this.$t('setupforshift.flexistaggered'), value: "staggered", width: "200px" },
        { text: this.$t('setupforshift.flexihours'), value: "flexi", width: "120px" },
        { text: this.$t('setupforshift.starttime'), value: "starttime", width: "200px" },
        { text: this.$t('setupforshift.endtime'), value: "endtime", width: "200px" },
        { text: this.$t('setupforshift.latecomertime'), value: "latetime", width: "200px" },
        { text: this.$t('setupforshift.earlyleavertime'), value: "earlytime", width: "200px" },
        { text: this.$t('setupforshift.otstarttime'), value: "otstart", width: "200px" },
        { text: this.$t('setupforshift.earlyotstarttime'), value: "earlyotstart", width: "200px" },
        { text: this.$t('setupforshift.breaktimeinminutes'), value: "breaktime", width: "200px" },
        { text: this.$t('setupforshift.otbufferinminutes'), value: "otbuffer", width: "200px" },
        { text: this.$t('setupforshift.shifthours'), value: "shifthours", width: "200px" },
        { text: this.$t('setupforshift.othours'), value: "otinshift", width: "200px" },
        { text: this.$t('setupforshift.paidbreaktime'), value: "isbreakpaid", width: "120px" },
        { text: this.$t('setupforshift.earlyOT'), value: "earlyot", width: "120px" },
        { text: this.$t('setupforshift.otneedspreapproval'), value: "isotpreapproved", width: "120px" },
        { text: this.$t('setupforshift.shiftallowance'), value: "sftallwpayitemdesc", width: "200px" },
        { text: this.$t('setupforshift.fulfillshifthours'), value: "isfullshift", width: "120px" },
        { text: this.$t('setupforshift.otallowance'), value: "otallwpayitemdesc", width: "200px" },
        { text: this.$t('setupforshift.minumumothours'), value: "minothours", width: "200px" },
        { text: this.$t('setupforshift.normal'), value: "onnorm", width: "120px" },
        { text: this.$t('setupforshift.off'), value: "onoff", width: "120px" },
        { text: this.$t('setupforshift.rest'), value: "onrest", width: "120px" },
        { text: this.$t('setupforshift.holiday'), value: "onhol", width: "120px" },
         
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getShiftSetupListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/shiftsetup/all"})
        .then(function (res) {
          //vm.dataItems = res.data.rec;

          //vm.loading = !vm.loading;
            for (let i = 0; i < res.data.rec.length; i++) {
              vm.dataItems.push({
                id: res.data.rec[i].id,
                shiftcode: res.data.rec[i].shiftcode,
                shiftdesc: res.data.rec[i].shiftdesc,
                fixed: res.data.rec[i].fixed,
                staggered: res.data.rec[i].staggered,
                flexi: res.data.rec[i].flexi,
                starttime: res.data.rec[i].starttime,
                endtime: res.data.rec[i].endtime,
               // starttime: moment(res.data.rec[i].starttime).format("HH:mm"),
               // endtime:  moment(res.data.rec[i].endtime).format("HH:mm"),
                latetime: res.data.rec[i].latetime,
                earlytime: res.data.rec[i].earlytime,
                otstart:  res.data.rec[i].otstart,
                earlyotstart:  res.data.rec[i].earlyotstart,
                breaktime:  res.data.rec[i].breaktime,
                otbuffer:  res.data.rec[i].otbuffer,
                shifthours:  res.data.rec[i].shifthours,
                otinshift: res.data.rec[i].otinshift,
                isbreakpaid: res.data.rec[i].isbreakpaid,
                earlyot: res.data.rec[i].earlyot,
                isotpreapproved: res.data.rec[i].isotpreapproved,
                sftallwpayitemid: res.data.rec[i].sftallwpayitemid,
                sftallwpayitemdesc: res.data.rec[i].sftallwpayitemdesc,
                isfullshift: res.data.rec[i].isfullshift,
                otallwpayitemid: res.data.rec[i].otallwpayitemid,
                otallwpayitemdesc: res.data.rec[i].otallwpayitemdesc,
                minothours: res.data.rec[i].minothours,
                onnorm: res.data.rec[i].onnorm,
                onoff: res.data.rec[i].onoff,
                onrest: res.data.rec[i].onrest,
                onhol: res.data.rec[i].onhol,
              });
            }
            
            vm.loading = !vm.loading;
                  //  if (vm.dataItems[i].starttime) {
                  //        vm.dataItems[i].starttime = moment(vm.dataItems[i].starttime).format('HH:mm');
                  //    }

                  //    if (vm.dataItems[i].endtime) {
                  //        vm.dataItems[i].endtime = moment(vm.dataItems[i].endtime).format('HH:mm');
                  //    }
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "shiftsetupedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "shiftsetupnew" });
    },
  },
  mounted() {
    this.getShiftSetupListHandler();
  },
};
</script>