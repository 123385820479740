<template>
  <div>
    <v-container>
      <v-form ref="form">
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle }}
            <span class="page_title_value">{{
              this.parentObject.pageTtileValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row v-if="!this.formData.lockstaff">
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                :items="staffDetailsList"
                @change="changeStaffIDandCompanyHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="4" sm="10" class="pb-n5">
              <v-autocomplete
                outlined
                :label="this.$t('columns.staffname')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                :items="staffDetailsList"
                readonly
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.staffid')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffcode"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                v-model="formData.compnyname"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" sm="4" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('setuptaxablerebate.rebatetype')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.rebatetypeid"
                dense
                @input="updateValue"
                :items="rebatetypeItemsList"
                @change="changeRebateTypeCode(formData.rebatetypeid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.rebatetypecode"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuptaxablerebategroup.rebategroup')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.rebategroupid"
                readonly
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppersonpayrolldirectassignment.amount')"
                type="number"
                ref="amount"
                v-model="formData.amount"
                @input="updateValue"
                dense
                class="numberfield"
              ></v-text-field>
            </v-col>

            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.currency')"
                type="text"
                ref="crcyid"
                :rules="[rules.required]"
                v-model="formData.crcyid"
                @input="updateValue"
                dense
                :items="currencyItemsList"
                @change="changeCurrencyCodeHandler(formData.crcyid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.crcycode"
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('setuptp1.declareyear')"
                outlined
                v-model="formData.dyear"
                @input="updateValue"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                dense
                :label="this.$t('setuptp1.declaremonth')"
                outlined
                v-model="formData.dmonth"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                readonly
                dense
                :label="this.$t('setuptp1.rebateutilized')"
                outlined
                background-color="#ffeab2"
                v-model="formData.rebateUtilized"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                readonly
                dense
                :label="this.$t('setuptp1.rebatelimit')"
                outlined
                background-color="#ffeab2"
                v-model="formData.rebateLimitAmt"
              ></v-text-field>
            </v-col>
            <v-col cols="1" sm="1" class="pb-n5 mt-n8">
              <v-btn icon color="orange">
                <v-icon @click="loadRebate()">mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text v-if="isErr" class="mt-n12 mb-1">
            <span style="font-weight: bold; color: red">{{ errMsg }}</span>
          </v-card-text>

          <v-row>
            <v-col cols="12" sm="12" class="pb-n4 mt-n8">
              <v-textarea
                outlined
                :label="this.$t('columns.remark')"
                type="text"
                ref="remark"
                v-model="formData.remark"
                @input="updateValue"
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <!-- <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-file-input
                                :label="Attachment"
                                v-model="formData.attach"
                                @change="updateValue"
                                ref="file"
                                :accept="
                                    this.$store.getters.attachmentAcceptType
                                "
                                show-size
                                outlined
                                style="font-size: 14px"
                                dense
                            ></v-file-input>
                        </v-col>
                    </v-row> -->

          <!-- <v-row>
            <v-col cols="4" sm="6" class="pb-n5 mt-n8">
              <v-file-input
                ref="attach"
                :label="Attachment"
                outlined
                v-model="fileHolder"
                :accept="this.$store.getters.attachmentAcceptType"
                @change="fileToBase64"
                style="font-size: 14px"
                dense
              ></v-file-input>
            </v-col>
          </v-row> -->

           <v-row>
            <v-col cols="4" sm="6" class="pb-n4">
              <v-file-input
                ref="attach"
                :label="this.$t('recstaffdetails.attachment')"
                outlined
                v-model="fileHolder"
                @change="fileToBase64"
                dense
              ></v-file-input>
              {{$t('setuptp1.msg1')}}
           </v-col>             
          </v-row>
          <br>
          <br>

          <div v-if="this.formData.attach">
            <v-row>
              <v-col cols="4" sm="6" class="pb-n5 mt-n8">
                <p>
                  <a
                    v-bind:href="this.formData.attach"
                    v-bind:download="this.formData.attach"
                    >{{$t('download.downloadattachment')}}</a
                  >
                  <v-icon small class="ml-3 mr-2" @click="deleteItemHandler">
                    mdi-delete
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col cols="2" sm="2" class="pb-n5 mt-n5">
              <v-btn
                rounded
                color="#ffeab2"
                small
                @click="showEntryInfo = !showEntryInfo"
                >{{$t('preapprovedotrecord.entryinfo')}}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5">
              <v-text-field
                readonly
                v-if="showEntryInfo"
                outlined
                :label="this.$t('recleaverec.submittedby')"
                ref="submittedby"
                type="text"
                :rules="[rules.required]"
                v-model="formData.submittedby"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                readonly
                v-if="showEntryInfo"
                outlined
                :label="this.$t('setuptp1.submitteddatetime')"
                type="date"
                v-model="formData.submissiondatetime"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="8" class="pb-n5 mt-n8">
              <v-text-field
                readonly
                v-if="showEntryInfo"
                outlined
                :label="this.$t('setuptp1.source')"
                type="text"
                v-model="formData.source"
                dense
                @input="updateValue"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    parentObject: Object,
  },

  components: {},

  data() {
    return {
      formData: {
        psnid: this.parentObject.data.psnid,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnyname: this.parentObject.data.compnyname,
        compnycode: this.parentObject.data.compnycode,
        rebatetypeid: this.parentObject.data.rebatetypeid,
        rebatetypecode: this.parentObject.data.rebatetypecode,
        rebategroupid: this.parentObject.data.rebategroupid,
        amount: this.parentObject.data.amount,
        crcyid: this.parentObject.data.crcyid,
        crcycode: this.parentObject.data.crcycode,
        // declaremonthyear: this.parentObject.data.declaremonthyear,
        // rebateutilized_limit: this.parentObject.data.rebateutilized_limit,
        dyear: this.parentObject.data.dyear,
        dmonth: this.parentObject.data.dmonth,
        rebateLimitAmt: this.parentObject.data.rebatelimitamount,
        rebateUtilized: this.parentObject.data.rebateUtilized,
        remark: this.parentObject.data.remark,
        attach: this.parentObject.data.attach,
       // attachname: this.parentObject.data.attachname,
        submittedby: this.parentObject.data.submittedby,
        submissiondatetime: this.parentObject.data.submittedon,
        lockstaff: this.parentObject.data.lockstaff,
        search: this.parentObject.data.search,
        source: this.parentObject.data.source,
        stamp: this.parentObject.data.stamp,

        //additional line
        userid: this.parentObject.data.userid,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 200 || "Max 200 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      fileHolder: null,
      totalUtilized: null,

      //arrays to store data objects returned from db --> load list handlers
      rebatetypes: [],
      rebatetypeItemsList: [],
      staffdetails: [],
      staffDetailsList: [],
      currencies: [],
      currencyItemsList: [],
      companiesitemsList: [],
      companies: [],

      //var to display or hide entry and approval info
      showEntryInfo: false,
      showVendorCat: false,
      isExceeded: false,

      isErr: false,
      errMsg: "",
      search: this.parentObject.searchnew
    };
  },

  // computed: {
  //     rebateUtilized: function() {
  //         let amt = this.formData.rebateLimitAmt - this.formData.amount;

  //         if (!amt) {
  //             return amt = ''
  //         } else {
  //             return amt;
  //         }

  //     }
  // },

  methods: {
    // load list handlers
    
    fileToBase64() {
      if (this.fileHolder) {
        //const img = document.createElement('xlxs');

        var file = this.$refs.attach.internalValue;
        this.formData.attach = this.fileHolder.name;

        //console.log('file', file.type)

        if (file.type == "text/csv" ){
          this.$dialog.alert("File format is not supported").then((dialog) => {
                console.log('dailog', dialog);
             });
           this.formData.attach = null;

           //console.log('attach', this.formData.attach)
        } else {
        
        var reader = new FileReader();

        reader.onloadend = () => {
          //var b64 = reader.result.replace(/^data:.+base64,/, '');
          var b64 = reader.result;
          this.formData.attach = b64;

          //console.log('format', b64)
        };

        reader.readAsDataURL(file);
      }   
      } else {
              this.formData.attach = null
      }
      
      //if(this.fileHolder)
    },

    deleteItemHandler() {
      this.formData.attach = '';
      this.formData.attach = '';

      console.log('attach', this.formData.attach)

      this.updateValue();
    },

    //load rebatetypes list
    loadRebateTypeListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/taxablerebatesetup/all"})
        .then((res) => {
          //push response in rebatetypes
          vm.rebatetypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.rebatetypeItemsList.push({
              //push id and desc for "rebatetypeid" v-autocomplete field
              text: res.data.rec[i].rebatetypedesc,
              value: res.data.rec[i].rebatetypeid,
            });
          }
        })
        .catch((err) => {
          // failed to respond --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load staff details list
    loadStaffDetailsListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load currency list
    loadCurrencyListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/currency/all"})
        .then((res) => {
          //push response in rebatetypes
          vm.currencies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.currencyItemsList.push({
              //push id and namne for "psnid" v-autocomplete field
              text: res.data.rec[i].crcydesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          // failed to respond --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load companies list
    loadCompaniesListHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/company/all"})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companiesitemsList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //change staffid and compnyid after selectin psnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.submittedby = this.formData.staffid;
      this.formData.compnyname = item.compnyname;
      this.formData.compnyid = item.compnyid;
      this.formData.submissiondatetime = moment().format("YYYY-MM-DD");

      this.updateValue();
    },

    loadRebate() {
      const vm = this;

      let getitem = vm.dataItems.filter((x) => {
        return (
          x.staffid == this.formData.staffid &&
          x.rebatetypeid == this.formData.rebatetypeid &&
          x.dyear == this.formData.dyear
        );
      });

      let getamt = [];

      getitem.forEach((x) => {
        getamt.push(x.amount);
      });

      this.formData.rebateUtilized = getamt.reduce(function (a, b) {
        return a + b;
      }, 0);

      this.checkRebate();
    },

    checkRebate() {
      if (this.formData.amount) {
        this.totalUtilized =
          parseInt(this.formData.rebateUtilized) +
          parseInt(this.formData.amount);
        if (this.totalUtilized > this.formData.rebateLimitAmt) {
          this.isErr = true;
          this.errMsg = "Rebate exceeded";
          this.isExceeded = true;
        } else {
          this.isErr = false;
          this.errMsg = "";
          this.isExceeded = false;
        }
      }
    },

    loadTp1ListHandler() {
      const vm = this;

      this.$store.dispatch('get', {
          // this.$store.getters.apiURL + "/tp1/all"
          url: `/tp1/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    //change rebatetype code after selecting vendorcat
    changeRebateTypeCode(id) {
      let item = this.rebatetypes.find((item) => item.id == id);

      this.formData.rebatetypecode = item.rebatetypecode;
      this.formData.rebategroupid = item.rebategroupdesc;
      this.formData.rebateLimitAmt = item.rebatelimitamount;

      this.updateValue();
    },

    // changeDeclarePeriod() {
    //     let item = this.rebatetypes.find(
    //         item => item.id == this.formData.rebatetypeid
    //     );
    //     //console.log('item month', moment(item.startdate).format('M'))

    //     let fromdt = moment(item.startdate);
    //     let todt = moment(item.enddate);

    //     let fromYear = fromdt.year();
    //     let toYear = todt.year();

    //     if (
    //         this.formData.dyear >= fromYear &&
    //         this.formData.dyear <= toYear
    //     ) {
    //         this.formData.rebateLimitAmt = item.rebatelimitamount;
    //     }

    //     this.updateValue();
    // },

    //change currency code after selectring branchc
    changeCurrencyCodeHandler(id) {
      let item = this.currencies.find((item) => item.id == id);

      this.formData.crcycode = item.crcycode;
    },

    updateValue() {
      this.$emit("input", {
        psnid: this.formData.psnid,
        staffid: this.formData.staffid,
        compnyid: this.formData.compnyid,
        rebatetypeid: this.formData.rebatetypeid,
        rebatetypecode: this.formData.vendorcatcode,
        rebategroupid: this.formData.rebategroupid,
        amount: this.formData.amount,
        crcyid: this.formData.crcyid,
        dyear: this.formData.dyear,
        dmonth: this.formData.dmonth,
        remark: this.formData.remark,
        attach: this.formData.attach,
       //attachname: this.formData.attachname,
        submittedby: this.formData.submittedby,
        submissiondatetime: this.formData.submissiondatetime,
        lockstaff: this.formData.lockstaff,
        search: this.formData.search,
        source: this.formData.source,
        stamp: this.formData.stamp,

        //addtional line
        userid: this.formData.userid,
      });
    },

    newHandler() {
      this.$emit("new");
    },
    saveHandler() {
      this.updateValue();
      this.loadRebate();
      if (!this.isErr) {
        this.$emit("save");
      }
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "tp1list", params: { search: this.search} });
    },
  },

  mounted() {
    this.loadRebateTypeListHandler();
    this.loadStaffDetailsListHandler();
    this.loadCurrencyListHandler();
    this.loadTp1ListHandler();
    //this.loadCompaniesListHandler();
    if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
  },
};
</script>


<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

