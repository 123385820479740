<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import RecordForm from "../../../components/leave/leavebalance/Form";
import ErrorDialogue from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": RecordForm,
    "app-error": ErrorDialogue,
  },

  data() {
    return {
      pageTitle: this.$t('leavebalancebystaff'),
      pageTitleValue: "",
      formData: {
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnyname: null,
        asofdate: moment().format("YYYY-MM-DD"),
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {},
};
</script>
