<template>
    <div id="companygrid">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>


<script>
import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupleavesetup.leavesetuplist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px'},
                { text: this.$t('columns.leavecode'), value: 'leavecode', sortable: false },
                { text: this.$t('columns.description'), value: 'leavedesc', width: '200px' },
                { text: this.$t('setupleavesetup.parentleave'), value: 'ltleavedesc', width: '200px' },
                { text: this.$t('setupleavesetup.periodtype'), value: 'ptype', width: '200px' },
                { text: this.$t('setupleavesetup.month'), value: 'startmth', width: '250px' },
                { text: this.$t('setupleavesetup.unittype'), value: 'unittype', width: '200px' },
                { text: this.$t('setupleavesetup.displayby'), value: 'dispunit', width: '200px' },
                { text: this.$t('setupleavesetup.hourperdayconversion'), value: 'hrperday', width: '200px' },
                { text: this.$t('columns.remark'), value: 'remark', width: '200px' },
            ],
            dataItems: [],
            loading: false,
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {
        loadLeaveType() {
            const vm = this;

            this.$store.dispatch('get', {url: '/leavesetup/all'})
            .then(res => {
                vm.dataItems = res.data.rec;
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        editItemHandler(item) {
            this.$router.push({name: 'leavesetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'leavesetupnew'});
        }
    },

    mounted() {
        this.loadLeaveType();
    }

}
</script>