<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>
                
            <v-container
                id="scroll-target"
                class="custom-form overflow-y-auto px-5"
            >
            
                <v-row>
                    <v-col cols="6" sm="6" class="pb-n5 ">
                        <v-autocomplete
                            outlined
                            :label="this.$t('columns.actions')"
                            type="text"
                            ref="aprstatid"
                            hide-selected
                            v-model="formData.aprstatid"
                            :items="aprstatusList"
                            :rules="[rules.required]"
                            dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                        <v-col cols="12" sm="12" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('recworkflowmaintenance.persontodelegate')"
                                type="text"
                                ref="aprid"
                                :items="personList"
                                v-model="formData.aprid"
                                @input="updateValue"
                                @change="personChangeHandler(formData.aprid)"
                                :disabled="formData.aprstatid != 4"
                                dense
                                :rules="[rules.required]"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                            >{{$t('reportsparam.filtercriteria')}}</v-subheader
                            >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="mb-8" />
                            <v-row>
                                <v-col cols="4" sm="4" class="pb-4 mt-n8" >
                                    <v-autocomplete
                                    outlined
                                    :label="this.$t('recworkflowmaintenancebatch.staff')"
                                    type="text"
                                    ref="staff"
                                    v-model="filterCriteria.staffid"
                                    :items="staffList"
                                    dense
                                    clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-4 mt-n8">
                                    <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.company')"
                                    type="text"
                                    ref="grade"
                                    v-model="filterCriteria.compnyid"
                                    :items="companyList"
                                    dense
                                    clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                    outlined
                                    :label="this.$t('columns.workflowtype')"
                                    type="text"
                                    ref="employmentType"
                                    v-model="filterCriteria.wftypeid"
                                    :items="wftypeList"
                                    dense
                                    clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                    {{$t('recworkflowmaintenancebatch.recdatebetween')}}
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.fromdate')"
                                        type="date"
                                        ref="startdate"
                                        v-model="filterCriteria.startdate"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="4" class="pb-n4 mt-n4">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.untildate')"
                                        type="date"
                                        ref="enddate"
                                        v-model="filterCriteria.enddate"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="refreshHandler"> 
                            {{$t('button.refreshlist')}}
                        </v-btn>
                    </div>
                </v-row>

                <v-row>
                    <v-col col="12" sm="12" class="pb4-4">
                        <v-data-table
                            v-model="selected"
                            @input="changeTableHandler()"
                            :headers="this.dataHeader"
                            class="elavation-1"
                            show-select
                            :items="workflowItems"
                            fixed-header
                            multi-sort
                            item-key="recordid"
                            :sort-by="['code']"
                            >
                            <!-- :options="tableOpt" -->
                            <!-- <template v-slot:top> -->
                                <!-- <v-toolbar color="#ECEFF1" flat>
                                    <v-toolbar-title class="subtitle-1">{{titleSummary}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-col cols="1" sm="1" class="ml-n4">
                                        <div style="width: 50%; max-height: 50%" >
                                            <v-img @click="printPDFSummary" :src="`${publicPath}images/export_pdf.png`"/>
                                        </div>
                                    </v-col>
                                    <v-col cols="1" sm="1" class="ml-n4 mr-n4">
                                        <div style="width: 50%">
                                            <v-img @click="getCSVSummary" :src="`${publicPath}images/export_csv.png`"/>
                                        </div>
                                    </v-col>
                                </v-toolbar> -->
                            <!-- </template> -->
                            <!-- <template v-slot:body>
                                    <tbody>
                                        <tr v-for="item in summaryList" :key="item.id">
                                            <td >{{item.staffcode}} </td>
                                            <td @click="goDetailHandler(item.id)">{{item.psnname}} </td>
                                            <td >{{item.grosspay}} </td>
                                            <td >{{item.netpay}} </td>
                                            <td >{{item.pycmdstatusdesc}} </td>
                                            <td >{{item.pycalcstatusdesc}} </td>
                                        </tr>
                                    </tbody>
                                </template> -->
                            <!-- <template v-slot:items >
                            </template> -->
                            <!-- <template v-slot:body="{items}">
                                <tbody>
                                    <tr v-for="item in items" :key="item.id">
                                        <td @click="goDetailHandler(item.staffid, item.salhdid)">{{item.staffcode}} </td>
                                        <td @click="goDetailHandler(item.staffid, item.salhdid)">{{item.psnname}} </td>
                                        <td >{{item.grosspay}} </td>
                                        <td >{{item.netpay}} </td>
                                        <td >{{item.pycmdstatusdesc}} </td>
                                        <td >{{item.pycalcstatusdesc}} </td>
                                    </tr>
                                </tbody>
                            </template> -->
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row>
                    <div class="pt-5">
                        <v-btn 
                            color="warning" 
                            style="width: 100px" 
                            @click="updateHandler"> 
                            {{$t('button.update')}}
                        </v-btn>
                    </div>
                </v-row>
            </v-container>
            
            </v-form>
        </v-container>
    </div>
</template>

<script>

// import axios from 'axios';
import moment from 'moment';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            
            payoutid: null,
            pageTitle: this.$t('recworkflowmaintenancebatch.pendingworkflowrecbatchaction'),
            pageTitleValue: '',
            aprstatid: null,
            formData: {
                aprstatid: null,
                aprid: null,
                workflows: []
            },

            filterCriteria: {
                staffid: null,
                compnyid: null,
                wftypeid: null,
                startdate: null,
                enddate: null
            },

            selected: [],
            personList: [],
            person: [],
            workflowItems: [],
            payout: [],
            payoutctrlList: [],
            workflowList: [],
            staffList: [],
            companyList: [],
            wftypeList: [],
            aprstatusList: [],
            aprstatus:[],

            dataHeader: [
                {text: this.$t('statutoryexportcommonused.no'), value: 'no', width: '70px'},
                {text: this.$t('columns.workflowtype'), value: 'wftypedesc', width: '200px'},
                {text: this.$t('recworkflowmaintenance.recid'), value: 'recordid', width: '150px'},
                {text: this.$t('columns.staffcode'), value: 'staffcode', width: '150px'},
                {text: this.$t('columns.staffname'), value: 'psnname', width: '250px'},
                {text: this.$t('columns.company'), value: 'compnyname', width: '200px'},
                {text: this.$t('columns.recorddate'), value: 'recdate', width: '200px'},
            ],
            isErr: false,
            errMsg: '',
        }
    },

    methods: {

        loadPerson() {
            const vm = this;
            this.$store.dispatch('get', {
                url: `/staff/all/${this.$store.getters.userId}`
                })
                .then(res => {
                    vm.person = res.data.rec;

                   // console.log('person', vm.person)
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.personList.push(
                            {
                                text: res.data.rec[i].psndetails,
                                value: res.data.rec[i].id
                            }
                        )

                      //  console.log('list', vm.personList)
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        personChangeHandler(id) {
          
        let item = this.person.find((item) => item.id == id);
        //console.log(item);
        this.formData.aprid = item.id;       
        },

         changePersonHandler(id) {
           if (id != 4) this.formData.aprid = null
        },

        

        loadStaff() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/staff/all/${this.$store.getters.userId}`
            })
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.staffList.push(
                        {
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        }
                    )
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadCompany() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/company/all`
            })
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.companyList.push(
                        {
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        }
                    )
                    
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadWorkflowType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/workflow/type`
            })
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    vm.wftypeList.push(
                        {
                            text: res.data.rec[i].wftypedesc,
                            value: res.data.rec[i].id
                        }
                    )
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadWorkflows() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/workflowmaintenancenew/all`
            })
            .then(res => {
              
                vm.workflowItems = res.data.rec;

                console.log('items', vm.workflowItems)

                  for (let i = 0; i < res.data.rec.length; i++) {
                    if (res.data.rec[i].recdate) {
                        res.data.rec[i].recdate = moment(res.data.rec[i].recdate).format('YYYY-MM-DD');
                    }
                    vm.workflowItems[i].no = i + 1;
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        loadApprovalStatus() {
            const vm = this;

            this.$store.dispatch('get', {
                url: `/selectedaprstatus/all`
            })
                .then(res => {
                    vm.aprstatus = res.data.rec;
                    for (let i = 0; i < res.data.rec.length; i++) {
                      //  if (res.data.rec[i].apraction) {
                            vm.aprstatusList.push(
                                {
                                    text: res.data.rec[i].aprstatus,
                                    value: res.data.rec[i].id
                                }
                            )
                        }
                  //  }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        refreshHandler() {
            const vm = this;

            this.$store.dispatch('post', {
                url: `/workflowmaintenance/param/${this.$store.getters.userId}`,
                payload: vm.filterCriteria
            })
            .then(res => {
                for (let i = 0; i < res.data.rec.length; i++) {
                    if (res.data.rec[i].recdate) {
                        res.data.rec[i].recdate = moment(res.data.rec[i].recdate).format('YYYY-MM-DD');
                    }
                }
                vm.workflowItems = res.data.rec;

                console.log('vm.workflowItems', vm.workflowItems)
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
                vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },

        changeTableHandler() {
            this.formData.workflows = this.selected
        },

        updateHandler() {
            const vm = this;

            this.$store.dispatch('put', {
                url: `/workflowmaintenancebatch/batch`,
                payload: vm.formData
            })
            .then(res => {
                console.log('res', res)

                if (this.formData.aprid == null && this.formData.aprstatid == '4'){
                     this.$dialog.alert(this.$t('delegatepersoncolumnisrequired')).then((dialog) => {
                     console.log('dailog', dialog);
                
                });
                }

                if (res.data.error) {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = res.data.error;
                } else {
                    this.refreshHandler()
                    this.$dialog.alert(this.$t('selectedpendingworkflowareupdated')).then((dialog) => {
                    console.log('dailog', dialog);
                
                });
                }
            })
            .catch(err => {
                vm.isErr = !vm.isErr;
                vm.errMsg = err;
              //  vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
            });
        },
    },

    mounted() {
        this.loadStaff();
        this.loadCompany();
        this.loadWorkflowType();
        this.loadWorkflows();
        this.loadApprovalStatus();
        this.loadPerson();
    }
    
}
</script>