<template>
  <div id="`staffbankaccountlist`">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import moment from "moment";

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setupstaffbankaccount.staffbankaccountlist'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false },
        { text: this.$t('columns.staffname'), value: "psnname", width: "200px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "200px"},
        { text: this.$t('setupstaffbankaccount.cobankacc'), value: "bankaccountcode", width: "200px" },
        { text: this.$t('setupstaffbankaccount.cobank'), value: "compnybankname", width: "200px" },
        { text: this.$t('setupstaffbankaccount.accounttype'), value: "savingtypecode", width: "200px" },
        { text: this.$t('setupstaffbankaccount.banktocredit'), value: "bankname", width: "200px" },
        { text: this.$t('setupstaffbankaccount.companyaccountno'), value: "bankaccountno", width: "250px" },
        { text: this.$t('columns.currency'), value: "crcydesc", width: "200px" },
        { text: this.$t('setupstaffbankaccount.accountname'), value: "accountname", width: "200px" },
        { text: this.$t('setupstaffbankaccount.staffaccountno'), value: "staffaccountno", width: "250px",},
        { text: this.$t('columns.startdate'), value: "startdate", width: "150px" },
        { text: this.$t('columns.enddate'), value: "enddate", width: "150px" },
        { text: this.$t('setupstaffbankaccount.idtype'), value: "idtypecode", width: "150px" },
        { text: this.$t('setupstaffbankaccount.iddetails'), value: "iddetails", width: "150px" },
        { text: this.$t('setupstaffbankaccount.allocation'), value: "allocation", width: "150px" },
        { text: this.$t('setupstaffbankaccount.allocationamount'), value: "allocationamount", width: "150px",},
        { text: this.$t('setupprocesslistmaster.precedence'), value: "precedence", width: "150px" },
        { text: this.$t('columns.remark'), value: "remark", width: "150px" },
      ],

      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getStaffbankaccountListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: `/staffbankaccount/all/${this.$store.getters.userId}`})
        .then(function (res) {
          vm.dataItems = res.data.rec;
          console.log(vm.dataItems);

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].startdate) {
              vm.dataItems[i].startdate = moment(
                vm.dataItems[i].startdate
              ).format("YYYY-MM-DD");
            }

            if (vm.dataItems[i].enddate) {
              vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format(
                "YYYY-MM-DD"
              );
            }
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "staffbankaccountedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "staffbankaccountnew" });
    },
  },

  mounted() {
    this.getStaffbankaccountListHandler();
  },
};
</script>