<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5"></v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.grade')"
                                id="grdcode"
                                type="text"
                                ref="grdcode"
                                :rules="[rules.required]"
                                v-model="formData.grdcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.description')"
                                type="text"
                                ref="grddesc"
                                :rules="[rules.required]"
                                v-model="formData.grddesc"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.gradecategory')"
                                type="text"
                                ref="grdcatid"
                                :items="gradeCatList"
                                v-model="formData.grdcatid"
                                @input="updateValue"
                                @change="changeGradeCatHandler(formData.grdcatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="gradeCatCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="gradeCatDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: gradeCatItems,
                                            formTitle: `${this.$t('columns.gradecategory')}`,
                                            formId: 'gradecat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveGradeCat="saveGradeCatHandler"
                                        v-on:deleteGradeCat="deleteGradeCatHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="gradeCatDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupgrade.probationmonths')"
                                type="number"
                                ref="prbmth"
                                v-model="formData.prbmth"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupgrade.resignationnotimonths')"
                                type="number"
                                ref="rsgnmth"
                                v-model="formData.rsgnmth"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            formData: {
                grdcode: this.parentObject.data.grdcode,
                grddesc: this.parentObject.data.grddesc,
                // startdate: this.parentObject.data.startdate,
                // enddate: this.parentObject.data.enddate,
                grdcatid: this.parentObject.data.grdcatid,
                prbmth: this.parentObject.data.prbmth,
                rsgnmth: this.parentObject.data.rsgnmth,
                remark: this.parentObject.data.remark,
                isdisable: this.parentObject.data.isdisable,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            gradeCatCode: '',
            gradeCatList: [],
            gradeCats: [],
            gradeCatItems: [],
            gradeCatLoading: true,
            gradeCatDialog: false,
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        getGradeCatList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/gradecat/all'})
                .then(res => {
                    vm.gradeCats = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.gradeCatList.push(
                            {
                                text: res.data.rec[i].grdcatdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.gradeCatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].grdcat,
                                desc: res.data.rec[i].grdcatdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.gradeCatLoading = false;

                    vm.gradeCats = res.data.rec;

                    if (this.formData.grdcatid) {
                       this.changeGradeCatHandler(this.formData.grdcatid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        saveGradeCatHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: '/gradecat/' + this.itemData.itemid, payload: {
                    grdcat: this.itemData.itemcode,
                    grdcatdesc: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.gradeCats = [];
                        vm.gradeCatList = [];
                        vm.gradeCatItems = [];

                        vm.getGradeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/gradecat/create', payload: {
                    grdcat: vm.itemData.itemcode,
                    grdcatdesc: vm.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.gradeCats = [];
                        vm.gradeCatList = [];
                        vm.gradeCatItems = [];

                        vm.getGradeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg}); 
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteGradeCatHandler($event) {
            const vm = this;

            if(confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/gradecat/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.gradeCats = [];
                        vm.gradeCatList = [];
                        vm.gradeCatItems = [];

                        vm.getGradeCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg}); 
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        changeGradeCatHandler(id) {
            let item = this.gradeCats.find(item => item.id === id);

            this.gradeCatCode = item.grdcat;
        },

        updateValue() {
            this.$emit('input', {
                grdcode: this.formData.grdcode,
                grddesc: this.formData.grddesc,
                // startdate: this.formData.startdate,
                // enddate: this.formData.enddate,
                grdcatid: this.formData.grdcatid,
                prbmth: this.formData.prbmth,
                rsgnmth: this.formData.rsgnmth,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'gradelist', params: { search: this.search}});
        }
    },

    mounted() {
        this.getGradeCatList();
    }
}
</script>