<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{$t('auditlisting')}}
          </h2>
        </v-row>
        
        <v-col class="mt-n4 mb-2">
          <v-row>
            <v-btn
              v-model="goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >{{$t('button.back')}}
            </v-btn>
          </v-row>
        </v-col>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-toolbar flat>
              <v-toolbar-title>
                <div>
                  <span class="blue--text">
                    {{ this.data.formData.tablename }}
                  </span>
                  {{$t('reportauditlisting.auditlistingbetween')}}
                  <span class="blue--text">
                    {{ this.data.formData.fromDate }}
                  </span>
                  {{$t('columns.to')}}
                  <span class="blue--text">
                    {{ this.data.formData.untilDate }}
                  </span>
                </div>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"

                  
                />
              </v-avatar>

              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"

                  
                />
              </v-avatar>
              <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 20px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
            </v-toolbar>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="dataHeader"
                :items="dataItems"
                :search="search"
                :items-per-page="10"
                class="font-weight-light;text--secondary;"
                style="font-size:small"
                multi-sort
                fixed-header
                no-data-text="No records found."
              >
              <template v-slot:[`item.action`]="{ item }">
                <v-icon
                   
                    small
                    class="ml-5 mr-2"
                    @click="View(item)"
                >
                    mdi-eye
                </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row no-gutters justify="space-between"> 
            <v-col cols="5">
            <v-card
            class="pa-2"
            >
            <v-card-title>
              <div>
                <h3 class="headline blue--text">{{$t('reportauditlisting.original')}}</h3>
              </div>
            </v-card-title>
            
            <v-card-text>
              {{ this.original }}
            </v-card-text>
            </v-card>
            </v-col>

 
            <v-col cols="5">
            <v-card
            class="pa-2"
            >
            <v-card-title>
              <div>
                <h3 class="headline blue--text">{{$t('reportauditlisting.changes')}}</h3>
              </div>

            </v-card-title>
            <v-card-text>
              {{ this.changes }}
            </v-card-text>
            </v-card>
            </v-col>
            

          </v-row>

        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
  

      data: {
        formData: {
          fromDate: this.$route.params.formData.fromDate,
          untilDate: this.$route.params.formData.untilDate,
          staffid : this.$route.params.formData.staffid,
          action : this.$route.params.formData.action,
          tablename: this.$route.params.formData.tablename
        },

        parameters: {
          tablename: this.$route.params.param.tablename,
          entdate: this.$route.params.param.entdate,
          usercode: this.$route.params.param.usercode,
          usertype: this.$route.params.param.usertype,
          enttype: this.$route.params.param.enttype,
          original: this.$route.params.param.original,
          changes: this.$route.params.param.changes
        },
      },

      dataHeader: [
        { text: this.$t('reportauditlisting.view'), width: "86", value: "action", sortable: "false" },
        { text: this.$t('reportauditlisting.table'), width: "124", value: "tablename" },
        { text: this.$t('columns.date'), width: "112", value: "entdate" },
        { text: this.$t('columns.user'), width: "100", value: "usercode" },
        { text: this.$t('columns.usertype'), width: "115", value: "usertype" },
        { text: this.$t('reportauditlisting.actiontype'), width: "10", value: "enttype" },
      ],

      tableOpt: {
        itemsPerPage: -1,
      },

      dataItems: [],
      search: '',
      goBtn: false,

      original: null,
      changes: null,
    };
  },

  methods: {
    backButton() {
      this.$router.push({ name: "parameterauditlisting" });
    },

    loadAuditListing() {
      this.goBtn = true;
      const vm = this;
      this.$store.dispatch('post', {url: `/auditlisting/${this.$store.getters.userId}`,
          payload: this.data})
          .then(res => {
            vm.dataItems = res.data.rec;

            /*vm.dataItems = vm.dataItems.map((o,i) => ({
            ...o, 
            id: i, 
            entdate: moment(o.entdate).format('YYYY-MM-DD HH:mm:ss') 
            }));
            this.$forceUpdate();*/
            
            for(let i = 0; i < vm.dataItems.length; i++){
              vm.dataItems[i].id = i + 1;
              vm.dataItems[i].entdate = moment(vm.dataItems[i].entdate).format('YYYY-MM-DD HH:mm:ss');
              vm.dataItems[i].original;
              vm.dataItems[i].changes;
              }
          })
          
    },

    View(item) {
        this.original = item.original;
        this.changes = item.changes;
      },


    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var head = [
        { title: this.$t('reportprocessloglisting.id'), dataKey: "id" },
        { title: this.$t('reportauditlisting.table'), dataKey: "tablename" },
        { title: this.$t('columns.date'), dataKey: "entdate" },
        { title: this.$t('columns.user'), dataKey: "usercode" },
        { title: this.$t('columns.usertype'), dataKey: "usertype" },
        { title: this.$t('reportauditlisting.actiontype'), dataKey: "enttype" },
        { title: this.$t('reportauditlisting.original'), dataKey: "original" },
        { title: this.$t('reportauditlisting.changes'), dataKey: "changes" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          id: this.dataItems[i].id,
          tablename: this.dataItems[i].tablename,
          entdate: this.dataItems[i].entdate,
          usercode: this.dataItems[i].usercode,
          usertype: this.dataItems[i].usertype,
          enttype: this.dataItems[i].enttype,
          original: this.dataItems[i].original,
          changes: this.dataItems[i].changes,
        });
      }
      
      doc.text(this.$t('columns.auditlisting'), 10, 10);

      if (this.formData.tablename){
        doc.text(this.$t('reportauditlisting.tablename'),64,10);
        doc.text(this.formData.tablename.toString(),74,10);
      }else{
        doc.text(this.$t('columns.fromdate'),74, 10);
        doc.text(this.formData.fromDate.toString(), 107, 10);
        doc.text(this.$t('columns.untildate'),150, 10);
        doc.text(this.formData.untilDate.toString(), 180, 10);
      }


      doc.autoTable(head, body);
      doc.save(this.$t('columns.auditlisting') + "-" + this.data.formData.tablename + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv;charset=utf-8;'});
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute('download', this.data.formData.tablename + '" ' + this.$t('reportauditlisting.auditlistingbetween') + ' "' + this.data.formData.fromDate + '" ' + this.$t('columns.to') + ' "' + this.data.formData.fromDate + ".csv");

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [this.$t('reportauditlisting.table')]: item[i].tablename,
          [this.$t('columns.date')]: item[i].entdate,
          [this.$t('columns.user')]: item[i].usercode,
          [this.$t('columns.usertype')]: item[i].usertype,
          [this.$t('reportauditlisting.actiontype')]: item[i].enttype,
          [this.$t('reportauditlisting.original')]: item[i].original,
          [this.$t('reportauditlisting.changes')]: item[i].changes,
        });
      }

      data.push({
        'Table': "",
        'Date': "",
        'User': "",
        'User Type': "",
        'Action Type': "",
        'Original': "",
        'Changes': "",
      });

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
  },

  mounted() {},

  created() {
    const vm = this;
    vm.formData = this.$route.params.formData;

    this.loadAuditListing();
    
  },
};
</script>