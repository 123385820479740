var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-form',[_c('v-row',[_c('h2',{staticClass:"pb-5"},[_vm._v(" "+_vm._s(this.parentObject.pageTitle)+" "),_c('span',{staticClass:"page_title_value"},[_vm._v(_vm._s(this.parentObject.pageTitleValue))])])]),_c('v-container',{staticClass:"custom-form overflow-y-auto px-5",attrs:{"id":"scroll-target"}},[(!this.formData.lockstaff)?_c('v-row',[_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"8","sm":"9"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('columns.workflowtype'),"type":"text","rules":[_vm.rules.required],"dense":"","clearable":"","items":_vm.workflowTypeList},on:{"input":_vm.updateValue,"change":function($event){return _vm.changewftypeHandler(
                                    _vm.formData.wftypeid
                                )}},model:{value:(_vm.formData.wftypeid),callback:function ($$v) {_vm.$set(_vm.formData, "wftypeid", $$v)},expression:"formData.wftypeid"}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"pb-n5",attrs:{"cols":"8","sm":"9"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":this.$t('columns.workflowtype'),"type":"text","rules":[_vm.rules.required],"dense":"","items":_vm.workflowTypeList,"readonly":"","clearable":"","background-color":"#ffeab2"},model:{value:(_vm.formData.wftypeid),callback:function ($$v) {_vm.$set(_vm.formData, "wftypeid", $$v)},expression:"formData.wftypeid"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-n5 mt-n8",attrs:{"cols":"8","sm":"9"}},[_c('v-autocomplete',{ref:"detailid",attrs:{"outlined":"","label":this.$t('payeeinputprocess.details'),"type":"text","dense":"","items":_vm.detailsList,"clearable":"","disabled":_vm.wfdetailCheck == false},on:{"input":_vm.updateValue,"change":function($event){return _vm.changeDetailsCodeHandler(_vm.formData.detailid)}},model:{value:(_vm.formData.detailid),callback:function ($$v) {_vm.$set(_vm.formData, "detailid", $$v)},expression:"formData.detailid"}})],1),_c('v-col',{staticClass:"pb-n5 mt-n8",attrs:{"cols":"8","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":this.$t('columns.code'),"type":"text","disabled":_vm.wfdetailCheck == false,"dense":"","readonly":"","background-color":"#ffeab2"},model:{value:(_vm.formData.detailcode),callback:function ($$v) {_vm.$set(_vm.formData, "detailcode", $$v)},expression:"formData.detailcode"}})],1)],1),_c('v-row',[_c('app-table',{attrs:{"parentObject":{
                            tabHeader: this.tabHeader,
                            detailItem: this.formData.workflowdetails,
                            wftypeid: this.formData.wftypeid,
                            detailid: this.formData.detailid
                        }},on:{"input":_vm.addItemHandler}})],1)],1),_c('app-formbuttons',{attrs:{"EditBool":this.parentObject.isEdit},on:{"NewPressed":_vm.newHandler,"SavePressed":_vm.saveHandler,"DeletePressed":_vm.deleteHandler,"CancelPressed":_vm.cancelHandler}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }