<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="1" sm="1" class="mt-n4 mb-2">
          <v-btn 
            color="orange" 
            class="white--text"
            v-on:click="BackButtonHandler()"
          >{{$t('button.back')}}</v-btn>
        </v-col>
        <v-col cols="1" sm="1" class="mt-n4 mb-2">
          <v-btn 
            color="red" 
            class="white--text"
            v-on:click="DeleteButtonHandler()"
          >{{$t('button.deletebatch')}}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
    ></app-gridview>
  </div>
</template>
<script>
import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      id: this.$route.params.id,
      pageTitle: this.$t('latenessdeduction.records'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('columns.staffno'), value: "staffcode", width: "120px" },
        { text: this.$t('columns.name'), value: "name", width: "200px" },
        { text: this.$t('columns.date'), value: "latedate", width: "120px" },
        { text: this.$t('columns.durationmin'), value: "duration", width: "120px" },
        { text: this.$t('latenessdeduction.genamount'), value: "genamount", width: "120px" },
        { text: this.$t('latenessdeduction.finalamount'), value: "finalamount", width: "120px" },
        { text: this.$t('columns.company'), value: "compnyname", width: "200px" },
        { text: this.$t('columns.branch'), value: "brchname", width: "200px" },
        { text: this.$t('columns.division'), value: "divnname", width: "200px" },
        { text: this.$t('columns.department'), value: "deptname", width: "200px" },
        { text: this.$t('columns.section'), value: "sectioname", width: "200px" },
        { text: this.$t('columns.unit'), value: "unitname", width: "200px" },
        { text: this.$t('columns.grade'), value: "grddesc", width: "200px" },
        { text: this.$t('columns.job'), value: "jobtitle", width: "200px" },
        { text: this.$t('columns.employmenttype'), value: "emptypedesc", width: "200px" },
        { text: this.$t('posted'), value: "posted", width: "50px" },
        { text: this.$t('reportsparam.payout'), value: "payoutcode", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getPolicyListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/attnlatenessbatch/" + this.id})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].fromdate) {
              vm.dataItems[i].fromdate = moment(vm.dataItems[i].fromdate).format('YYYY-MM-DD');
            }
            if (vm.dataItems[i].todate) {
              vm.dataItems[i].todate = moment(vm.dataItems[i].todate).format('YYYY-MM-DD');
            }
            if (vm.dataItems[i].latedate) {
              vm.dataItems[i].latedate = moment(vm.dataItems[i].latedate).format('YYYY-MM-DD');
            }
            vm.dataItems[i].posted = vm.dataItems[i].posted == 'Y' ? 'Yes' : 'No';
          }
          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "latenessdeductiondetailsedit", params: { id: item.id, search: item.search } });
    },
    BackButtonHandler() {
      this.$router.push({ name: "latenessdeductionlisting"});
      
    },
    DeleteButtonHandler() {
      const vm = this;
      this.loading = !this.loading;
      if (confirm(this.$t('latenessdeduction.confirmremove'))) {
        this.$store.dispatch('delete', {url: "/attnlatenessbatch/" + this.id})
        .then(function () {
          vm.loading = !vm.loading;
          vm.$router.push({ name: "latenessdeductionlisting"});
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
      }
      
    },

    // newRecordHandler() {
    //   this.$router.push({ name: "latenessdeductionpolicynew" });
    // },
  },
  mounted() {
    this.getPolicyListHandler();
  },
};
</script>