<template>
    <div> 
        <v-container>
            <v-form>
                 <v-row>
                    <h2 class="pb-5"> {{this.parentObject.pageTitle}}<span class='page_title_value'>{{this.parentObject.pageTtileValue}}</span></h2>
                </v-row>

                <v-container 
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

        <v-row >
          <v-col cols="4" sm="8" class="pb-n5">
            <v-autocomplete
              outlined
              :label="this.$t('columns.name')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              @input="updateValue"
              @change="changeStaffIDandCompanyHandler(formData.staffid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="4" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.staffid')"
              type="text"
              :rules="[rules.required]"
              v-model="staffcode"
              dense
              readonly
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="4" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.company')"
              type="text"
              ref="compnyid"
              v-model="compnyname"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

                <v-row>
                    <v-col cols="5" sm="5" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuptaxablerebate.rebatetype')"
                            type="text"
                            ref="rebateypeid"
                            :items="taxableRebateList"
                            v-model="formData.rebateypeid"
                            @input="updateValue"
                            dense
                            :rules="[rules.required]"
                            @change="
                                changeTaxableRebateHandler(formData.rebateypeid)
                            "
                        ></v-autocomplete>
                    </v-col>
             
                    <!-- <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.description')"
                            type="text"
                            v-model="rebateypecode"
                            dense
                        ></v-text-field>
                    </v-col> -->
                </v-row>


                <v-row>
                    <v-col cols="8" sm="8" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('mmtaxperiodsetup.taxperiod')"
                            type="text"
                            ref="taxperiodid"
                            :items="taxperiodList"
                            v-model="formData.taxperiodid"
                            @input="updateValue"
                            dense
                            :rules="[rules.required]"
                            @change="
                                changeTaxPeriodHandler(formData.taxperiodid)
                            "
                        ></v-autocomplete>
                    </v-col>
               </v-row>

                
                    <v-row>
                      <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsparam.year')"
                            type="text"
                            ref="year"
                            hide-selected
                            v-model="formData.rptyear"
                            dense
                            :items="yearList"
                            :rules="[rules.required]"
                            clearable
                            @input="updateValue"
                        ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('reportsparam.month')"
                            type="text"
                            ref="month"
                            hide-selected
                            v-model="formData.rptmonth"
                            dense
                            :items="monthList"
                            clearable
                            :rules="[rules.required]"
                            @input="updateValue"
                            @change="changerptmonth()"
                           
                        ></v-autocomplete>
                        </v-col>
                    </v-row>

                <v-row>
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('trainingrecord.amount')"
                            type="number"
                            ref="rebatelimitamount"
                            v-model="formData.amount"
                            @input="updateValue"
                            class="numberfield"
                            dense
                            :rules="[rules.required]"
                        > 
                        </v-text-field>
                    </v-col>
                
                    <v-col cols="3" sm="3" class="pb-n5 mt-n8">
                    <v-autocomplete
                        outlined
                        :label="this.$t('columns.currency')"
                        type="text"
                        ref="crcyid"
                        :rules="[rules.required]"
                        v-model="formData.currencyid"
                        @input="updateValue"
                        dense
                        :items="currencyItemsList"
                        @change="changeCurrencyHandler(formData.currencyid)"
                    ></v-autocomplete>
                    </v-col>

                    <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="currencyCode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                </v-container>
                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>

//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";
import moment from "moment";

export default {
    props: {
        parentObject: Object
    },

    components: {
        //'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },
  
    data() {
        return {
            formData: {
                staffid: this.parentObject.data.staffid,
                rebateypeid: this.parentObject.data.rebateypeid,
                rebateypecode: this.parentObject.data.rebateypecode,
                taxperiodid: this.parentObject.data.taxperiodid,
                startyear: this.parentObject.data.startyear,
                startmonth: this.parentObject.data.startmonth,
                endyear: this.parentObject.data.endyear,
                endmonth: this.parentObject.data.endmonth,
                rptyear: this.parentObject.data.rptyear,
                rptmonth: this.parentObject.data.rptmonth,
                currencyid: this.parentObject.data.currencyid,
                amount: this.parentObject.data.amount,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid,
            },

            rules:  {
               required:value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length <= 200 || 'Max 200 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },

            //taxableRebateCodes: [],
            taxableRebateList: [],
            currencyItemsList: [],
            currencyCode: '',
            currencyLoading: true,
            taxdedtLoading: true,
            taxablerebatetypes: [],

            rebateypecode: '',
            dedtdesc: '',

            yearList:[],
            monthList: [],

            taxperiods: [],
            taxperiodList: [], 
            taxperiod: '',
            // startyear: '',
            // startmonth: '',
            // endyear: '',
            // endmonth:'',
            staffcode: '',
            compnyid: '',
            compnyname: '',
            
            staffdetails: [],
            staffDetailsList: [],
            errMsg: "",
            isErr: false,
            search: this.parentObject.searchnew

        } //end of return 
            
    },  //end of data 

   

    methods: {
        updateValue() {
            this.$emit('input', {
                staffid: this.formData.staffid,
                rebateypeid: this.formData.rebateypeid,
                rebateypecode: this.formData.rebateypecode,
                taxperiodid: this.formData.taxperiodid,
                startyear: this.formData.startyear,
                startmonth: this.formData.startmonth,
                endyear: this.formData.endyear,
                endmonth: this.formData.endmonth,
                rptyear: this.formData.rptyear,
                rptmonth: this.formData.rptmonth,
                currencyid: this.formData.currencyid,
                amount: this.formData.amount,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
             //console.log('formdt', this.formData)
        },

        yearGeneration() {
            for (let i = 0; i < 200; i++) {
                this.yearList.push(2020 + i)
            }
        },

        monthGeneration() {
            for (let i = 1; i <= 12; i++) {
                this.monthList.push(i)
            }
        },

     //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
          url: `/staff/all/${this.$store.getters.userId}`
        })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

             if (vm.formData.staffid) {
               this.changeStaffIDandCompanyHandler(vm.formData.staffid);
             }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

        //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid

      this.formData.staffid = item.id;
      this.staffcode = item.staffcode;
      this.compnyid = item.compnyid;
      this.compnyname = item.compnyname;

      this.updateValue();
    },

        loadTaxPeriodHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxperiodsetup/all'})
                .then(res => {
                    vm.taxperiods = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxperiodList.push(
                            {
                                text: res.data.rec[i].taxperiod,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.taxdedtLoading = false;

                    if (vm.formData.taxperiodid) {
                        vm.changeTaxableRebateHandler(vm.formData.taxperiodid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeTaxPeriodHandler(id) {
            let item = this.taxperiods.find(item => item.id === id);
            this.taxperiod = item.taxperiod;
            this.formData.startyear = item.startyear;
            this.formData.endyear = item.endyear;
            this.formData.startmonth = item.startmonth;
            this.formData.endmonth = item.endmonth;

          //  console.log('item', item)
        },

        daysInMonth(month, year) {
          return new Date(month, year, 0).getDate();
        },
        
       changerptmonth(){

       
        let startdateHolder1 = this.formData.startyear + "-" + this.padRJ(this.formData.startmonth, 2) + "-" + this.padRJ(1, 2);
        let enddateHolder2 = this.formData.endyear + "-" + this.padRJ(this.formData.endmonth, 2) + "-" + this.daysInMonth(this.formData.endmonth, this.formData.endyear);
        let currentdateHolder3 = this.formData.rptyear + "-" + this.padRJ(this.formData.rptmonth, 2) + "-" + this.daysInMonth(this.formData.rptmonth, this.formData.rptyear);

        let startdate = moment(startdateHolder1).format("YYYY-MM-DD");
        let endddate = moment(enddateHolder2).format("YYYY-MM-DD");
        let currentdate = moment(currentdateHolder3).format("YYYY-MM-DD");


         if (currentdate < startdate || currentdate > endddate){
          //  console.log('abc')
               this.$dialog.alert("Selected Year and Month should be between Tax Period Duration").then((dialog) => {
                 console.log('dailog', dialog);
              });
        }

        this.updateValue();

    },


    padRJ(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  

    loadTaxableRebateHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/mmtaxablerebatelimittype/all'})
                .then(res => {
                    vm.taxablerebatetypes = res.data.rec;
                    //console.log('type', vm.taxablerebatetypes)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.taxableRebateList.push(
                            {
                                text: res.data.rec[i].dedtdesc,
                                code: res.data.rec[i].dedtcode,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.taxdedtLoading = false;

                    if (vm.formData.rebateypeid) {
                        vm.changeTaxableRebateHandler(vm.formData.rebateypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeTaxableRebateHandler(id) {
            this.taxablerebatetypes.find(item => item.id === id);
            
           // this.rebateypecode = this.taxablerebatetypes.find (item2 => item2.dedtcode == dedtcode)

          //  console.log('dedtdesc', this.rebateypecode)
            //this.rebateypecode = item.dedtcode;
           // this.dedtdesc = item.dedtdesc
        },

        
        loadCurrencyListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/currency/all'})
                .then(res => {
                    vm.currencies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.currencyItemsList.push(
                            {
                                text: res.data.rec[i].crcydesc,
                                value: res.data.rec[i].id
                            }
                        )
                    }

                    vm.currencyLoading = false;

                    if (vm.formData.currencyid) {
                        vm.changeCurrencyHandler(vm.formData.currencyid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        //change handler is for showing the contents that has been loaded
        changeCurrencyHandler(id) {
            let item = this.currencies.find(item => item.id === id);
            this.currencyCode = item.crcycode;
        },
        
        newHandler() {
            this.$emit('new');
        },
        saveHandler() {
            this.updateValue()
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'mmpersontaxablerebatereglist', params: { search: this.search}});
        },

    },

    mounted() {
        this.loadCurrencyListHandler();
        this.loadTaxableRebateHandler();
        this.loadTaxPeriodHandler();
        this.loadStaffDetailsListHandler();
        this.yearGeneration();
        this.monthGeneration();
    }
} 
</script>

<style>

.numberfield input[type='number'] {
    -moz-appearance:textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}


</style>

