    <template>
    <div>
        <v-dialog v-model="claimlimitdialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline mb-4">{{$t('setupclaimlimit.claimlimitinputitem')}}</span>
              <!--<span class="headline mb-4">Claim Limit Input Item</span>-->
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form">
                <v-row>
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupclaimlimit.claimgroup')"
                            type="text"
                            ref="clmgrpid"
                            dense
                            :rules="[rules.required]"
                            :items="claimList"
                            v-model="selections[0].clmgrpid"
                            @change="changeClaimHandler(selections[0].clmgrpid)"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    
                    <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].clmgrpdesc"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                 <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.uptoservice')"
                                type="text"
                                ref="uptosrv"
                              
                                v-model="selections[0].uptosrv"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.maxperiod')"
                                type="decimal"
                                ref="maxperprd"
                                :rules="[rules.required]"
                                v-model="selections[0].maxperprd"
                                dense
                                >
                            </v-text-field>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.maxtransaction')"
                                type="text"
                                ref="maxpertran"
                              
                                v-model="selections[0].maxpertran"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.maxtransactionperiod')"
                                type="decimal"
                                ref="maxtranperprd"
                                v-model="selections[0].maxtranperprd"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>

                <v-row>
                        <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupclaimlimit.claimable')"
                                type="decimal"
                                ref="clmpercent"
                                v-model="selections[0].clmpercent"
                                dense
                            ></v-text-field>
                        </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeClaimLimitInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveClaimLimitInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('periodicincentivesetup.valueis')
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            claimlimitdialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                clmgrpid: null,
                clmgrp: null,
                clmgrpdesc: null,
                uptosrv: null,
                maxperprd: null,
                maxtranperprd: null,
                maxpertran: null,
                clmpercent:null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }],

            claimList:[],
            claimItems:[],
       
            itemHolder: [],
            detailList: [],


            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
       compareArray,
    
         loadClaim(){
             const vm = this;

            this.$store.dispatch('get', {
                url: '/groupp_grouppb_claim/all'
            })
            .then(res => {

                vm.claimItems = res.data.rec;

                for (let i = 0; i < vm.claimItems.length; i++) {
                    vm.claimList.push({
                        text: vm.claimItems[i].valuecode,
                        code: vm.claimItems[i].valuedesc,
                        value: vm.claimItems[i].valvalueid,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });

        },

        changeClaimHandler(valvalueid){
            let item = this.claimItems.find(item => item.valvalueid == valvalueid);

            this.selections[0].clmgrp = item.valuecode;
            this.selections[0].clmgrpdesc = item.valuedesc;

        },

        
         modifyItemHandler(item) {

             if(item.action == 1) {
                  this.modify = true;    
             }
      
             if(item.action == 2) {
                this.isExisting = true;  
             }

             this.selections[0] = item;
             this.claimlimitdialog = true;

             if (!this.isExisting){
                 // duplicate this.detailItem for duplicate checking
                
                 //find index 
                
                 var index = this.detailItem.findIndex( x => x.clmgrpid == item.clmgrpid);

                 this.detailItem[index].clmgrpdesc = item.clmgrpdesc;
                 this.detailItem[index].uptosrv = item.uptosrv;
                 this.detailItem[index].maxperprd = item.maxperprd;
                  this.detailItem[index].maxtranperprd = item.maxtranperprd;
                 this.detailItem[index].maxpertran = item.maxpertran;
                 this.detailItem[index].clmpercent = item.clmpercent;

                 //this.resetSelections();

             } else {
                 this.selections[0].action = 2;

                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
             }

             //this.editDialog = true;
           
         },        

       
        //   modifyItemHandler(item) {

        //          // assign loaded data to selections array
        //     if (item.action == 1) {
        //         this.modify = true;
        //     }
        //     if (item.action == 2) {
        //         this.isExisting = true;
        //     }

        //    this.itemHolder = item;
        //    this.selections[0] = this.itemHolder;

        
        //    this.claimlimitdialog = true;
        //    },


        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },
       
        closeClaimLimitInput() {
            this.claimlimitdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        
        saveClaimLimitInput() {

             if (this.selections[0].clmgrpid == null || this.selections[0].maxperprd == null){

              this.$dialog.alert(this.$t('fillinclaimgroupandmax')).then((dialog) => {
                console.log(dialog);
             });
            } else {
            
            this.isErr = false;
            
             if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }

           

            this.$emit("input", this.detailItem);

            //this.modify = false;
            this.claimlimitdialog = false;

            this.resetSelections();
            }

        
        },

        // saveTaskHandler() {

        //     this.isErr = false;

        //     if (!this.isExisting) {
        //         this.selections[0].id = null;
        //         this.selections[0].action = 1;

        //         this.detailItem.push(Object.assign({}, this.selections[0]));
        //     } else {
        //         if (this.compareArray(this.detailItem, this.selections[0])) {
        //             console.log(this.compareArray(this.detailItem, this.selections[0]))
        //         }
                
        //     }
            
        //     this.$emit('input', this.detailItem);
        //     //this.seqnoHandler();
        //     this.claimlimitdialog = false;

        //     // reset the form fields
        //     this.resetSelections();
        
        // },
       
        resetSelections() {
            this.selections = [{
                id: null,
                clmgrpid: null,
                clmgrp: null,
                clmgrpdesc: null,
                uptosrv: null,
                maxperprd: null,
                maxpertran: null,
                maxtranperprd: null,
                clmpercent:null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

    },

    created() {
        this.loadClaim();
    }
}
</script>   


       