<template>
    <div>

 <app-gridview
      v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
    ></app-gridview>

    </div>
   
</template>


<script>

import moment from 'moment';

import GridView from '../../../components/common/GridView'; 
export default {
    
    components: {
        'app-gridview': GridView
    
    },  

    data() {

         return {
            pageTitle: this.$t('setupbaitulmal.statutoryreportsetuplist'),
            dataHeader: [
                { text: this.$t('columns.actions'), value: 'action', sortable: false, width:'80px', },
                { text: this.$t('columns.effectivefrom'), value: 'startdate', width: '50px' },
                { text: this.$t('columns.effectiveuntil'), value: 'enddate', width: '50px' },
              
            ],
            dataItems: [],

            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }

    }, 

    methods: {
        //get all records 
        getStatutoryReportSetupList() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/statutoryreportsetup/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    //set to correct date format using moment 
                for (let i = 0; i < vm.dataItems.length; i++) {
                    if (vm.dataItems[i].startdate) {
                        vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format('YYYY-MM-DD');
                    }

                    if (vm.dataItems[i].enddate) {
                        vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format('YYYY-MM-DD');
                    }
                }
                   
                    vm.loading = !vm.loading;
                   
                })
                .catch(function (err) {
                    vm.errMsg = err;
            });

        },

        editItemHandler(item) {
            this.$router.push({name: 'statutoryreportsetupedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'statutoryreportsetupnew'});
        }, 

         


    },

    mounted() {
        this.getStatutoryReportSetupList();
    }
}
</script>