<template>
    <div>
        <v-dialog v-model="ratingdialog" max-width="900px">
          <v-card>
            <v-card-text>
              <v-container>
                    <v-row>
                        <span style="font-weight: bold;" class="mb-2 mt-6">Edit KPI For<span class="page_title_value pl-1">Test</span></span>
                    </v-row>

                    <div>
                        <v-row class="pb-n4 mb-2 mt-0">
                            <v-col cols="5" class="ml-0 pl-0">
                                <v-text-field
                                    outlined
                                    label="Type"
                                    type="text"
                                    ref="asofdate1"
                                    :rules="[rules.required]"
                                    v-model="formData.asofdate1"
                                    @input="updateValue"
                                    @change="updateValue"
                                    dense
                                    readonly
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="5" class="ml-0 pl-0"></v-col>

                            <v-col cols="2">
                                <v-text-field
                                    outlined
                                    label="Weightage"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].ratingdesc"
                                    dense
                                    class="v-input"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <div class="mr-3">
                            <v-row class="pb-n4 mt-n7">
                                <v-text-field
                                    outlined
                                    label="Goal"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].label"
                                    dense
                                    class="v-input"
                                ></v-text-field>
                            </v-row>
                            <v-row class="pb-n4 mt-n3">
                                <v-text-field
                                    outlined
                                    label="KPI"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].label"
                                    dense
                                    class="v-input"
                                ></v-text-field>
                            </v-row>
                            <v-row class="pb-n4 mt-n6">
                                <v-col cols="4" class="pl-0">
                                    <v-autocomplete
                                        outlined
                                        label="Target Option"
                                        type="text"
                                        ref="compnyid"
                                        :rules="[rules.required]"
                                        v-model="formData.compnyid"
                                        dense
                                        :items="companyList"
                                        @input="updateValue"
                                        @change="changeCompany(formData.compnyid)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="4" class="pl-0">
                                    <v-autocomplete
                                        outlined
                                        label="Calculation Method"
                                        type="text"
                                        ref="compnyid"
                                        :rules="[rules.required]"
                                        v-model="formData.compnyid"
                                        dense
                                        :items="companyList"
                                        @input="updateValue"
                                        @change="changeCompany(formData.compnyid)"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="pb-n4 mt-n8">
                                <v-col cols="6" class="mx-0">
                                    <v-row>
                                        <v-col cols="4" class="pl-0">
                                            <v-text-field
                                                outlined
                                                label="Threshold"
                                                type="text"
                                                :rules="[rules.required]"
                                                v-model="selections[0].label"
                                                dense
                                                class="v-input"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="pl-0">
                                            <v-text-field
                                                outlined
                                                label="Target"
                                                type="text"
                                                :rules="[rules.required]"
                                                v-model="selections[0].label"
                                                dense
                                                class="v-input"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="pl-0">
                                            <v-text-field
                                                outlined
                                                label="Stretch"
                                                type="text"
                                                :rules="[rules.required]"
                                                v-model="selections[0].label"
                                                dense
                                                class="v-input"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeRatingInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveRatingInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
            <div class="ma-4 pb-1">
                <v-row class="mt-4 ml-0">
                    <v-col cols="11" sm="11" class="pb-n5 pt-2 mt-4 ml-0 pl-0">
                        <v-col cols="3" class="pl-0 pb-0">
                            <v-text-field
                                outlined
                                label="Type"
                                type="text"
                                ref="asofdate1"
                                :rules="[rules.required]"
                                v-model="formData.asofdate1"
                                @input="updateValue"
                                @change="updateValue"
                                dense
                                readonly
                                background-color="#FFFDD0"
                            ></v-text-field>
                        </v-col>
                    </v-col>
                    <v-col cols="1" sm="1" class="pb-n5 pt-2 mt-4">
                        <v-icon
                            medium
                            class="ml-2 mr-2"
                            @click="modifyItemHandler(item)"
                        >
                            mdi-pen
                        </v-icon>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="9" sm="9" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Goal"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Weightage"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="9" sm="9" class="mt-n2">
                        <v-text-field
                            class="kpi-field"
                            outlined
                            label="KPI"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Progress %"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="4" sm="4" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Target Option"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Calculation Method"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4">
                    <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Threshold"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Target"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" class="mt-n2">
                        <v-text-field
                            outlined
                            label="Stretch"
                            type="text"
                            ref="asofdate1"
                            :rules="[rules.required]"
                            v-model="formData.asofdate1"
                            @input="updateValue"
                            @change="updateValue"
                            dense
                            readonly
                            background-color="#FFFDD0"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n4 ml-0 mb-12 pb-10">
                    <app-progress-review
                        v-bind:parentObject = "{
                            tabHeader: this.tabHeader,
                            detailItem: this.ratingscalesetdetail,
                            isWizard: this.parentObject.isWizard
                        }"
                    
                        v-on:input="addItemHandler"
                    />
                </v-row>

                <v-row class="mb-10 mx-4 mt-n12">
                    <app-comments-card
                        style="width: 100%;"
                        v-bind:parentObject = "{
                            tabHeader: this.tabHeader,
                            detailItem: this.ratingscalesetdetail,
                            isWizard: this.parentObject.isWizard
                        }"
                    
                        v-on:input="addItemHandler"
                    />
                </v-row>
            </div>
        </v-card>

        <div v-if="isErr">  
            <app-error
                v-bind:parentError="{
                data: this.errMsg,
                isErr: this.isErr,
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";
import ProgressReviewIndividual from "./ProgressReviewIndividual";
import KPICommentsCard from "./KPICommentsCard";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-progress-review": ProgressReviewIndividual,
        "app-comments-card": KPICommentsCard,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                compnyid: 1
            },

            ratingdialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],

            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            // isEditCommonKPI: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,
      
       modifyItemHandler(item) {
        //     // assign loaded data to selections array
        //     if(item.action == 1) {
        //         this.modify = true;    
        //     }
        //     if(item.action == 2) {
        //         this.isExisting = true;    
        //     }

        //    // this.itemHolder = item
        //     this.selections[0] =  item;
            console.log(item);
            // this.isEditCommonKPI = true;

            this.ratingdialog = true;
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        openRatingInput() {
            this.ratingdialog = true;
        },

        saveRatingInput() {

            
             if (this.selections[0].ratingcode == null || this.selections[0].label == null
             || this.selections[0].ratingdesc == null || this.selections[0].score == null){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.ratingdialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        onAdvanceEditItem(item) {
            console.log('item', item);
            // this.$router.push({ name: "kpisettingedit", params: { id: item.id } });
            this.$router.push({ name: "kpisettingeditcommonkpi", params: { id: 1 } });
        },

        closeRatingInput() {
            console.log('here')
            // this.isEditCommonKPI = !this.isEditCommonKPI;
            this.ratingdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.ratingdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    created() {
  
    }
}
</script>

<style scoped>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}

.mdi-delete::before {
    margin-top: -8px;
}

.mdi-pen::before {
  margin-top: 20px;
  margin-left: 50px;
}

.v-icon.v-icon::after {
    background-color: transparent !important;
}
</style>