<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
      v-on:new="createHandler"
  
      v-on:delete="isConfirm = !isConfirm"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import LeaveEntitlementPolicy from "../../../components/leave/leaveentitlementpolicy/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": LeaveEntitlementPolicy,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupleaveentitlementpolicysetup.editentitlementpolicy'),
      pageTitleValue: "",
      formData: {
        leavetypeid: null,
        startdate: null,
        enddate: null,
        roundingid: null,
        roundto: null,
        lvprorateid: null,
        allocateid: null,
        leaveentitlementdetail: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      deletedItems: [],
      insertedItems: [],
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: true,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadLeaveEntitlement() {
      const vm = this;

      this.$store.dispatch('get', {url: "/leaveentitlementdt/" + this.id})
        .then((res) => {
            if (res.data.rec[0].startdate) {
                vm.formData.startdate = moment(res.data.rec[0].startdate).format(
                "YYYY-MM-DD"
                );
            }

            if (res.data.rec[0].enddate) {
                vm.formData.enddate = moment(res.data.rec[0].enddate).format(
                "YYYY-MM-DD"
                );
            }

            vm.formData.leavetypeid = res.data.rec[0].leavetypeid;
            vm.formData.roundingid = res.data.rec[0].roundingid;
            vm.formData.roundto = res.data.rec[0].roundto;
            vm.formData.lvprorateid = res.data.rec[0].lvprorateid;
            vm.formData.allocateid = res.data.rec[0].allocateid;
            vm.formData.lockstaff = res.data.rec[0].lockstaff;
            vm.formData.stamp = res.data.rec[0].stamp;

            /*
                    let items = JSON.parse(res.data.rec[0].affectitems)

                    
                    for (let i = 0; i < items.length; i++) {
                        vm.formData.affectitems.push(items[i]);
                    }
                    */

            
            this.loadLeaveEntitlementDetail();
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    loadLeaveEntitlementDetail() {
      const vm = this;

      this.$store.dispatch('get', {url: "/leaveentitlementd/" + this.id})
        .then((res) => {
          vm.formData.leaveentitlementdetail = res.data.rec;

          for (let i = 0; i < vm.formData.leaveentitlementdetail.length; i++) {
            Object.assign(vm.formData.leaveentitlementdetail[i], {
              action: 2,
            });
          }

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      this.$store.dispatch('put', {url: "/leaveentitlementdt/" + this.id,
          payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leaveentitlementpolicylist" , params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.$store.dispatch('delete', {url:
            "/leaveentitlementdt/" +
            this.id +
            "/" +
            this.formData.userid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "leaveentitlementpolicylist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "leaveentitlementpolicynew" });
    },

    cancelHandler() {
      this.$router.push({ name: "leaveentitlementpolicylist" });
    },
  },

  mounted() {
    this.loadLeaveEntitlement();
  },
};
</script>