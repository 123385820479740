<template>
    <div id="`eissocsublist`">
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
// import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('alleissocso.monthlysocsoandeissubmissionreclist'),
            dataHeader: [ 
                { text: this.$t('columns.actions'),                       value: 'action',            width: '100px', sortable: false},
                { text: this.$t('alleissocso.socsoandeissubmissionrec'),value: 'eissocsubreccode',   width: '200px'},
                { text: this.$t('columns.description'),                  value: 'eissocsubrecdesc',   width: '200px' },
                { text: this.$t('reportsparam.year'),                         value: 'recyear',           width: '120px' },
                { text: this.$t('reportsparam.month'),                        value: 'recmonth',          width: '120px' },
                { text: this.$t('statutoryexportcommonused.approvalname'),                value: 'aprname',              width: '200px' },
                { text: this.$t('statutoryexportcommonused.approvalicno'),               value: 'apricno',              width: '200px' },
                { text: this.$t('statutoryexportcommonused.approvaltel'),                 value: 'aprtel',              width: '200px' },
                { text: this.$t('statutoryexportcommonused.approvalemail'),               value: 'apremail',              width: '200px' },
                { text: this.$t('statutoryexportcommonused.approvaldesignation'),         value: 'aprdesignation',              width: '150px' },
            ],

            dataItems: [],
            loading: false,
            errMsg: '',
             search: this.$route.params.search,
        }
    },

    methods: {
        getThisListHandler() {
            const vm = this;

            this.loading = !this.loading;

            this.$store.dispatch('get', {url: '/eissocsosubmissionrecord/all'})
                .then(function (res) {
                    vm.dataItems = res.data.rec;

                    vm.loading = !vm.loading;
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });

        },

        editItemHandler(item) {
            this.$router.push({name: 'eissocsubedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'eissocsubnew'});
        }
    },

    mounted() {
        this.getThisListHandler();
    }
}
</script>