    <template>
    <div>
        <v-dialog v-model="patterndialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{$t('setuprestdaypatternsetup.restdaypatterninputitem')}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="6" sm="6" md="6" >
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuprestdaypatternsetup.day')"
                            type="text"
                            ref= "wday"
                            :items="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                            hide-selected
                            :rules="[rules.required]"
                            v-model="selections[0].wday"
                            dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" class="mt-n8">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setuprestdaypatternsetup.daytypedesc')"
                            type="text"
                            ref="daytypedesc"
                            dense
                            :rules="[rules.required]"
                            :items="daytypeList"
                            v-model="selections[0].daytypeid"
                            @change="
                                changeDayHandler(
                                    selections[0].daytypeid
                                )
                            "
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" sm="2" md="2" class="mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.code')"
                            type="text"
                            v-model="selections[0].daytypecode"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="10" sm="6" md="6" class="mt-n8">
                         <v-autocomplete
                            outlined
                                    :label="this.$t('setuprestdaypatternsetup.fallsongazettedhol')"
                                    type="text"
                                    ref= "gzactiontype"
                                    :items="gzactionList"
                                    hide-selected
                                    :rules="[rules.required]"
                                    v-model="selections[0].gzactionid"
                                      @change="
                                changeGzactionHandler(
                                    selections[0].gzactionid
                                )
                            "
                                    dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="10" sm="6" md="6" class="mt-n8">
                         <v-autocomplete
                            outlined
                                    :label="this.$t('setuprestdaypatternsetup.fallsonnongazettedhol')"
                                    type="text"
                                    ref= "ngzactiontype"
                                    :items="ngzactionList"
                                    hide-selected
                                    :rules="[rules.required]"
                                    v-model="selections[0].ngzactionid"
                                             @change="
                                changeNGzactionHandler(
                                    selections[0].ngzactionid
                                )
                            "
                                    dense
                        ></v-autocomplete>
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeRestDayInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveRestDayInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
            :headers="this.parentObject.tabHeader"
            :items="detailItem"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="modifyItemHandler(item)"
            >
                mdi-pen
            </v-icon>
            <v-icon
                small
                class="ml-3 mr-2"
                @click="deleteItemHandler(item)"
            >
                mdi-delete
            </v-icon>
        </template>

        </v-data-table>
    </div>
</template>

<script>
import compareArray from '../../../util/comparearray';

export default {
    props: {
        parentObject: Object
    },

    data() {
        return {

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                percentage: value => !(value < 0 || value > 100) || this.$t('reportsparam.valueonetohundred')
            },

            
            //    tableOpt: {
            //     itemsPerPage: -1
            // },

            patterndialog: false,
            detailItem: this.parentObject.detailItem,
            selections: [{
                id: null,
                wday: null,
                daytypecode: null,
                daytypedesc: null,
                daytypeid: null,
                gzactionid: null,
                ngzactionid: null,
                gzactiontype: null,
                ngzactiontype:null,
                rectimestamp: null,
                updatedby: null,
                action:0 ,
                stamp: 0,
            }],

            daytypeList:[],
            dayItems:[],
            itemHolder: [],
            detailList: [],
            gzactionList: [],
            ngzactionList: [],
            actiontypeid: [],
            actionid: [],
            daytypeItems: [],


            modify: false,
            isExisting: false,
            isErr: false,
            errMsg: ''
        }
    },

 

    methods: {
        compareArray,

        loadGroup() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/daytype/all'})
            .then(res => {

                vm.daytypeItems = res.data.rec;

                for (let i = 0; i < vm.daytypeItems.length; i++) {
                    vm.daytypeList.push({
                        text: vm.daytypeItems[i].daytypedesc,
                        code: vm.daytypeItems[i].daytypecode,
                        value: vm.daytypeItems[i].id,
                    });
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        LoadActionType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/actiontype/all'})
                .then(res => {
                    vm.actiontypeid = res.data.rec;
                    console.log('item',vm.actiontypeid)

                    for (let i = 0; i < vm.actiontypeid.length; i++) {
                        vm.gzactionList.push(
                            {
                                text: vm.actiontypeid[i].actiontype,
                                value: vm.actiontypeid[i].id
                            }
                        )}

                  //  this.changeGzactionHandler(id);

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        LoadActionListType() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/actiontype/all'})
                .then(res => {
                    vm.actionid = res.data.rec;
                    console.log('item',vm.actionid)

                    for (let i = 0; i < vm.actionid.length; i++) {

                          vm.ngzactionList.push(
                             {
                                 text: vm.actionid[i].actiontype,
                                 value: vm.actionid[i].id
                             }
                         )}

                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        changeDayHandler(id) {
            let item = this.daytypeItems.find(item => item.id == id);

            this.selections[0].daytypecode = item.daytypecode;
            this.selections[0].daytypedesc = item.daytypedesc;
        },

         changeGzactionHandler(id) {
             let item = this.actiontypeid.find(item => item.id == id);

             this.selections[0].gzactiontype = item.actiontype;

         },

         changeNGzactionHandler(id) {
             let item = this.actionid.find(item => item.id == id);

             this.selections[0].ngzactiontype = item.actiontype;
         },


         modifyItemHandler(item) {
          // assign loaded data to selections array
           
           if(item.action == 1) {
                 this.modify = true;    
              }

             if(item.action == 2) {
                  this.isExisting = true;    
            }

              this.itemHolder = item
              this.selections[0] =  this.itemHolder;
              console.log("selections", this.selections)

              this.patterndialog = true;
          },




        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);

        },


          saveRestDayInput() {
              if (this.selections[0].wday == null){
                 this.$dialog.alert(this.$t('fillindaycolumn')).then((dialog) => {
                console.log(dialog);
             }); }
             else if (this.selections[0].daytypeid == null) {
                 this.$dialog.alert(this.$t('fillindaytypecolumn')).then((dialog) => {
                console.log(dialog);
             });
             }  else if (this.selections[0].gzactionid == null) {
                 this.$dialog.alert(this.$t('fillinfallsongazet')).then((dialog) => {
                console.log(dialog);
             });
             }  else if (this.selections[0].ngzactionid == null) {
                 this.$dialog.alert(this.$t('fillinfallsongazetnon')).then((dialog) => {
                console.log(dialog);
             });
             }
             else {
           this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;
                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
           // console.log('detailitem', this.detailItem)
            this.$emit('input', this.detailItem);
            this.patterndialog = false;
            

            // reset the form fields
            this.resetSelections();
            }
        },

        
        closeRestDayInput() {
            this.patterndialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

           saveTaskHandler() {

             this.isErr = false;

             if (!this.isExisting) {
                 this.selections[0].id = null;
                 this.selections[0].action = 1;

                 this.detailItem.push(Object.assign({}, this.selections[0]));
             } else {
                 if (this.compareArray(this.detailItem, this.selections[0])) {
                     console.log(this.compareArray(this.detailItem, this.selections[0]))
                 }
                
             }
            
            this.$emit('input', this.detailItem);

            this.patterndialog = false;

             // reset the form fields
            this.resetSelections();
        
     },

        
        resetSelections() {
            this.selections = [{
                id: null,
                wday: null,
                daytypeid: null,
                daytypecode: null,
                daytypedesc: null,
                gzactionid: null,
                gzactiontype: null,
                ngzactionid: null,
                ngzactiontype: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0
            }]
        },

    },

    created() {
        this.loadGroup();
        this.LoadActionType();
        this.LoadActionListType();
    }
}
</script>   


       