<template>
    <div id="app-headerlogo">
          <v-img v-if="this.picture"
            class="float-left"
            :src="this.picture"
            max-height="50"
            max-width="220"
          />
          <v-img v-else
            class="float-left"
            :src="`${publicPath}images/company_name.png`"
            max-height="50"
            max-width="220"
          />
    </div>
</template>
<script>
export default {
    data() {
        return {
            picture: null,
            publicPath: process.env.BASE_URL,
        }
    },
    methods: {
        loadLogo() {
            const vm = this;

            this.$store.dispatch('get', {url: "/systemlogo/all"})
            .then(res => {
                vm.picture = res.data.rec[0].image;
            })
            .catch(err => {
                vm.isErr = true;
                vm.errMsg = err;
            });
        }
    },
    mounted() {
        this.loadLogo();
    }
}
</script>