<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form ref="form">
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <!-- <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        > -->
        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n6">
            <v-autocomplete
              outlined
              :label="this.$t('columns.name')"
              type="text"
              ref="psnid"
              :rules="[rules.required]"
              v-model="formData.staffid"
              dense
              :items="staffDetailsList"
              @input="updateValue"
              @change="changeStaffIDandCompanyHandler(formData.staffid)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.staffcode')"
              type="text"
              v-model="formData.staffcode"
              dense
              readonly
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>

          <v-col cols="8" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('columns.company')"
              type="text"
              ref="compnyid"
              v-model="formData.compnyname"
              dense
              readonly
              append-icon=""
              background-color="#ffeab2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-text-field
              outlined
              :label="this.$t('reportsparam.asatdate')"
              type="date"
              ref="asofdate"
              :rules="[rules.required]"
              v-model="formData.asofdate"
              @input="updateValue"
              @change="updateValue"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4" sm="5" class="pb-n5 mt-n8">
            <v-btn color="primary" style="width: auto" @click="CheckBalance">
              {{$t('button.checkbalance')}}
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" sm="10" class="pb-n5 mt-n8">
            <v-toolbar flat>
              <v-toolbar-title>{{$t('reportleavebalancebystaff.leavebalance')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-avatar class="profile mr-5" size="40" tile>
                <img
                  :src="`${publicPath}images/export_pdf.png`"
                  @click="printPDF"
                />
              </v-avatar>

              <v-avatar class="profile" size="40" tile>
                <img
                  :src="`${publicPath}images/export_csv.png`"
                  @click="getCSV"
                />
              </v-avatar>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="10">
            <v-data-table
              hide-default-footer
              :headers="dataHeader"
              :items="dataItems"
              :items-per-page="1000"
              multi-sort
            >
            </v-data-table>
          </v-col>
        </v-row>
        <!-- </v-container> -->
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
import jsPDF from "jspdf";
import "jspdf-autotable";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    Loading,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },
      formData: {
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyname,
        compnyname: this.parentObject.data.compnyname,
        asofdate: this.parentObject.data.asofdate,
        userid: this.$store.getters.userId,
      },

      dataHeader: [
        { text: this.$t('generateunpaidleaverecord.leavetype'), value: "leavedesc" },
        { text: this.$t('reportleavebalancebystaff.balance'), value: "allowed_take" },
        { text: this.$t('reportleavebalancebystaff.currentyearentitlement'), value: "cur_period_ent" },
        { text: this.$t('reportleavebalancebystaff.bf'), value: "bf" },
        { text: this.$t('reportleavebalancebystaff.approved'), value: "approved" },
        { text: this.$t('reportleavebalancebystaff.rejected'), value: "rejected" },
        { text: this.$t('reportleavebalancebystaff.pending'), value: "pending" },
        { text: this.$t('reportleavebalancebystaff.adjustedup'), value: "adj_add" },
        { text: this.$t('reportleavebalancebystaff.adjusteddown'), value: "adj_minus" },
        { text: this.$t('reportleavebalancebystaff.forfeited'), value: "forfeited" },
        { text: this.$t('reportleavebalancebystaff.earmarkedforcf'), value: "earnmarked" },
      ],
      dataItems: [],

      //arrays to store data objects from load list handlers
      staffdetails: [],
      staffDetailsList: [],
      companies: [],
      companiesitemsList: [],
      subDialog: false,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      loading: false,
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        compnyname: this.formData.compnyname,
        asofdate: this.formData.asofdate,
        userid: this.formData.userid,
      });
    },

    //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            if (vm.formData.staffid) {
              this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      this.updateValue();
    },

    CheckBalance() {
      const vm = this;
      vm.dataItems = [];
      if ((this.$refs.form.validate())){
      if (
        this.formData.staffid &&
        this.formData.asofdate &&
        this.formData.staffcode
      ) {
        vm.loading = true;
        var data = {
          asofdate: this.formData.asofdate,
          staffcode: this.formData.staffcode,
          staffid: this.formData.staffid,
          userid: this.$store.getters.userId,
        };
        console.log(data);
        this.$store.dispatch('post', {
          url: `/leave/staffbalance`, payload:data})
          .then((res) => {
            vm.loading = false;
            //req success --> push res into array
            console.log('1',res.data.rec);
            if (res.data.rec.length > 0) {
              vm.dataItems = res.data.rec;
              for (let i =0; i<vm.dataItems.length; i++){
                this.dataItems[i].allowed_take = parseFloat(this.dataItems[i].allowed_take.toFixed(2));
                this.dataItems[i].cur_period_ent = parseFloat(this.dataItems[i].cur_period_ent.toFixed(2));
                this.dataItems[i].bf = parseFloat(this.dataItems[i].bf.toFixed(2));
                this.dataItems[i].approved = parseFloat(this.dataItems[i].approved.toFixed(2));
                this.dataItems[i].rejected = parseFloat(this.dataItems[i].rejected.toFixed(2));
                this.dataItems[i].pending = parseFloat(this.dataItems[i].pending.toFixed(2));
                this.dataItems[i].adj_add = parseFloat(this.dataItems[i].adj_add.toFixed(2));
                this.dataItems[i].adj_minus = parseFloat(this.dataItems[i].adj_minus.toFixed(2));
                this.dataItems[i].forfeited = parseFloat(this.dataItems[i].forfeited.toFixed(2));
                this.dataItems[i].earnmarked = parseFloat(this.dataItems[i].earnmarked.toFixed(2));
                
              //console.log('dataitems', vm.dataItems[i].allowed_take)
              }

                
            } else {
              this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
                console.log(dialog);
              });
            }
          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
      }
    },

    printPDF() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var head = [
        { title: this.$t('generateunpaidleaverecord.leavetype'), dataKey: "leavedesc" },
        { title: this.$t('reportleavebalancebystaff.balance'), dataKey: "allowed_take" },
        { title: this.$t('reportleavebalancebystaff.currentyearentitlement'), dataKey: "cur_period_ent" },
        { title: this.$t('reportleavebalancebystaff.bf'), dataKey: "bf" },
        { title: this.$t('reportleavebalancebystaff.approved'), dataKey: "approved" },
        { title: this.$t('reportleavebalancebystaff.rejected'), dataKey: "rejected" },
        { title: this.$t('reportleavebalancebystaff.pending'), dataKey: "pending" },
        { title: this.$t('reportleavebalancebystaff.adjustedup'), dataKey: "adj_add" },
        { title: this.$t('reportleavebalancebystaff.adjusteddown'), dataKey: "adj_minus" },
        { title: this.$t('reportleavebalancebystaff.forfeited'), dataKey: "forfeited" },
        { title: this.$t('reportleavebalancebystaff.earmarkedforcf'), dataKey: "earnmarked" },
      ];

      let body = [];

      for (var i = 0; i < this.dataItems.length; i++) {
        body.push({
          leavedesc: this.dataItems[i].leavedesc,
          allowed_take: this.dataItems[i].allowed_take,
          cur_period_ent: this.dataItems[i].cur_period_ent,
          bf: this.dataItems[i].bf,
          approved: this.dataItems[i].approved,
          rejected: this.dataItems[i].rejected,
          pending: this.dataItems[i].pending,
          adj_add: this.dataItems[i].adj_add,
          adj_minus: this.dataItems[i].adj_minus,
          forfeited: this.dataItems[i].forfeited,
          earnmarked: this.dataItems[i].earnmarked,
        });
      }

      doc.text(
        `${this.$t('reportleavebalancebystaff.staffleavebalance')} - ${this.formData.staffcode} as of ${this.formData.asofdate}`,
        10,
        10
      );

      doc.autoTable(head, body);
      doc.save(this.$t('reportleavebalancebystaff.staffleavebalance') + ' "' + "-" + this.formData.staffcode + ".pdf");
    },

    objectToCSV(data) {
      const csvRows = [];

      // get header
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    download(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        this.$t('reportleavebalancebystaff.staffleavebalance') + ' "' + "-" + this.formData.staffcode + ".csv"
      );

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getCSV() {
      let item = this.dataItems;
      let data = [];

      for (var i = 0; i < item.length; i++) {
        data.push({
          [this.$t('generateunpaidleaverecord.leavetype')]: item[i].leavedesc,
          [this.$t('reportleavebalancebystaff.balance')]: item[i].allowed_take,
          [this.$t('reportleavebalancebystaff.currentyearentitlement')]: item[i].cur_period_ent,
          [this.$t('reportleavebalancebystaff.bf')]: item[i].bf,
          [this.$t('reportleavebalancebystaff.approved')]: item[i].approved,
          [this.$t('reportleavebalancebystaff.rejected')]: item[i].rejected,
          [this.$t('reportleavebalancebystaff.pending')]: item[i].pending,
          [this.$t('reportleavebalancebystaff.adjustedup')]: item[i].adj_add,
          [this.$t('reportleavebalancebystaff.adjusteddown')]: item[i].adj_minus,
          [this.$t('reportleavebalancebystaff.forfeited')]: item[i].forfeited,
          [this.$t('reportleavebalancebystaff.earmarkedforcf')]: item[i].earnmarked,
        });
      }

      const csvData = this.objectToCSV(data);
      this.download(csvData);
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
