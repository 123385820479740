<template>
  <div>
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>
<script>
// import moment from 'moment';

import GridView from "../../../components/common/GridView";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('setupclaim.claimsetuplist'),
      dataHeader: [

        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "50px" },
        { text: this.$t('setupclaim.claimcode'), value: "clmtype", width: "120px" },
        { text: this.$t('setupclaim.claimdescription'), value: "clmtypedesc", width: "200px" },
        { text: this.$t('setupclaimlimit.claimcategory'), value: "clmtypecat", width: "200px" },
        { text: this.$t('setupclaim.parentclaim'), value: "parentclmtype", width: "200px" },
        { text: this.$t('setupclaim.claimlimitperiod'), value: "prdtype", width: "200px" },
        { text: this.$t('setupclaim.startmonth'), value: "startmth", width: "200px" },
        { text: this.$t('setupclaim.reimburseviapayroll'), value: "clmviapayroll", width: "200px" },
        { text: this.$t('setupclaim.payitem'), value: "payitemcode", width: "200px" },
        { text: this.$t('setupclaim.maximumbackdateddays'), value: "backdateddays", width: "200px" },
        { text: this.$t('setupclaim.defaulttax'), value: "taxrate", width: "200px" },
        { text: this.$t('columns.currency'), value: "crcydesc", width: "200px"},
        { text: this.$t('payrollprocessrun.exchangerate'), value: "exchratedesc", width: "200px"},
        { text: this.$t('setupclaim.publishtoselfserviceportal'), value: "selfservice", width: "250px" },
        { text: this.$t('setupclaim.confirmedstaffonly'), value: "confirmedstaff", width: "200px" },
        { text: this.$t('setupclaim.onceinemployment'), value: "onceinemplymt", width: "200px" },
        { text: this.$t('columns.disable'), value: "isdisable", width: "200px" },
        // { text: "ACTIONS", value: "action", sortable: false, width: "50px" },
        // { text: "CODE", value: "cliniccode", width: "120px" },
        // { text: "DESCRIPTION", value: "clinicdesc", width: "200px" },
        // { text: "ADDRESS 1", value: "add1", width: "200px" },
        // { text: "ADDRESS 2", value: "add2", width: "200px" },
        // { text: "ADDRESS 3", value: "add3", width: "200px" },
        // { text: "CITY", value: "cityname", width: "200px" },
        // { text: "COUNTRY", value: "cntryname", width: "120px" },
        // { text: "POSTCODE", value: "poscode", width: "120px" },
        // { text: "TELEPHONE", value: "tel", width: "200px" },
        // { text: "EMAIL", value: "email", width: "200px" },
        // { text: "DESCRIPTION", value: "clinicdesc", width: "200px" },
        // { text: "ADDRESS 1", value: "add1", width: "200px" },
        // { text: "ADDRESS 2", value: "add2", width: "200px" },
        // { text: "ADDRESS 3", value: "add3", width: "200px" },
        // { text: "CITY", value: "cityname", width: "200px" },
        // { text: "COUNTRY", value: "cntryname", width: "120px" },
        // { text: "POSTCODE", value: "poscode", width: "120px" },
        // { text: "TELEPHONE", value: "tel", width: "200px" },
        // { text: "EMAIL", value: "email", width: "200px" },
       
      ],
      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    getClinicListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: "/claimtypesweb/all"})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "claimtypesetupedit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "claimtypesetupnew" });
    },
  },
  mounted() {
    this.getClinicListHandler();
  },
};
</script>