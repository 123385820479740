<template>
    <div>
        <v-container>
            <form enctype="multipart/form-data">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTtileValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5">

                    <v-row>
                        <v-col cols="10" sm="10"  class="pb-n4">
                            <v-row v-if="!this.formData.lockstaff">
                                <v-col cols="10" sm="10" class="pb-n4">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.name')"
                                        type="text"
                                        ref="psnid"
                                        :rules="[rules.required]"
                                        v-model="formData.staffid"
                                        dense
                                        @input="updateValue"
                                        :items="staffDetailsList"
                                        @change="
                                            changeStaffIDandCompanyHandler(
                                                formData.staffid
                                            )
                                        "
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row v-else>
                                <v-col cols="10" sm="10" class="pb-n4">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.name')"
                                        type="text"
                                        ref="psnid"
                                        :rules="[rules.required]"
                                        hide-selected
                                        v-model="formData.staffid"
                                        dense
                                        readonly
                                        background-color="#ffeab2"
                                        :items="staffDetailsList"
                        
                                    ></v-autocomplete>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.staffcode')"
                                        type="text"
                                        ref="staffid"
                                        :rules="[rules.required]"
                                        v-model="formData.staffcode"
                                        dense
                                        @input="updateValue"
                                        readonly
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.company')"
                                        type="text"
                                        ref="compnyid"
                                        v-model="formData.compnyname"
                                        dense
                                        readonly
                                        append-icon=""
                                        background-color="#ffeab2"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <!--v-row>
                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="Effective Date"
                                        type="date"
                                        ref="effectivedate"
                                        :rules="[rules.required]"
                                        v-model="formData.effectivedate"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="Invalid Date"
                                        type="date"
                                        ref="invaliddate"
                                        :rules="[rules.required]"
                                        v-model="formData.invaliddate"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row-->

                            <v-row>
                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.email')"
                                        type="text"
                                        ref="email"
                                        v-model="formData.email"
                                        @input="updateValue"
                                        :rules="[rules.required, rules.email]"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="8" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.hp_no')"
                                        type="text"
                                        ref="hpno"
                                        v-model="formData.hpno"
                                        @input="updateValue"
                                        dense
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        type="text"
                                        ref="alternativeemail"
                                        :label="this.$t('reccontactdetails.alternativeemail')"
                                        outlined
                                        dense
                                        v-model="formData.alternativeemail"
                                        @input="updateValue"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.externalno')"
                                        type="number"
                                        ref="externalno"
                                        v-model="formData.externalno"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.correspondanceaddress_1')"
                                        type="text"
                                        ref="correspondanceaddr1"
                                        v-model="formData.correspondanceaddr1"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.correspondanceaddress_2')"
                                        type="text"
                                        ref="correspondanceaddr2"
                                        v-model="formData.correspondanceaddr2"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.correspondanceaddress_3')"
                                        type="text"
                                        ref="correspondanceaddr3"
                                        v-model="formData.correspondanceaddr3"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.city')"
                                        type="text"
                                        ref="cityid"
                                        hide-selected
                                        :rules="[rules.required]"
                                        :items="cityList"
                                        v-model="formData.cityid"
                                        @input="updateValue"
                                        @change="
                                            changeCityHandler(formData.cityid)
                                        "
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="formData.cityCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog
                                        v-model="cityDialog"
                                        max-width="500px"
                                        persistent
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform
                                                v-bind:parentData="{
                                                    tabItems: cityItems,
                                                    formTitle: `${this.$t('columns.city')}`,
                                                    formId: 'city'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveCity="saveCityHandler"
                                                v-on:deleteCity="
                                                    deleteCityHandler($event)
                                                "
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="cityDialog = false"
                                                    >{{$t('button.close')}}</v-btn
                                                >
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.country')"
                                        type="text"
                                        ref="cntryid"
                                        :rules="[rules.required]"
                                        :items="countryList"
                                        hide-selected
                                        v-model="formData.cntryid"
                                        @input="updateValue"
                                        @change="
                                            changeCountryHandler(
                                                formData.cntryid
                                            )
                                        "
                                        dense
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="formData.countryCode"
                                        @input="updateValue"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog
                                        v-model="countryDialog"
                                        max-width="500px"
                                        persistent
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform
                                                v-bind:parentData="{
                                                    tabItems: countryItems,
                                                    formTitle: `${this.$t('columns.country')}`,
                                                    formId: 'country'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveCountry="
                                                    saveCountryHandler
                                                "
                                                v-on:deleteCountry="
                                                    deleteCountryHandler
                                                "
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="
                                                        countryDialog = false
                                                    "
                                                    >{{$t('button.close')}}</v-btn
                                                >
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        type="number"
                                        ref="poscode"
                                        :label="this.$t('columns.postcode')"
                                        outlined
                                        dense
                                        class="numberfield"
                                        :rules="[rules.required]"
                                        v-model="formData.poscode"
                                        @input="updateValue"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.telephone')"
                                        type="number"
                                        v-model="formData.tel"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.permanentaddress_1')"
                                        type="text"
                                        ref="permanentaddr1"
                                        v-model="formData.permanentaddr1"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n5 mt-n8">
                                    <v-checkbox
                                        ref="formData.asabove"
                                        v-model="formData.asabove"
                                        :label="this.$t('reccontactdetails.asabove')"
                                        @change="
                                            changePermanentFieldsHandler(
                                                formData.asabove
                                            )
                                        "
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.permanentaddress_2')"
                                        type="text"
                                        ref="permanentaddr2"
                                        v-model="formData.permanentaddr2"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="10" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.permanentaddress_3')"
                                        type="text"
                                        ref="permanentaddr3"
                                        v-model="formData.permanentaddr3"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.city')"
                                        type="text"
                                        ref="permanentcityid"
                                        hide-selected
                                        :rules="[rules.required]"
                                        v-model="formData.permanentcityid"
                                        @input="updateValue"
                                        @change="
                                            changePermanentCityHandler(
                                                formData.permanentcityid
                                            )
                                        "
                                        dense
                                        :items="cityList"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="formData.permanentcityCode"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog
                                        v-model="cityDialog"
                                        max-width="500px"
                                        persistent
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform
                                                v-bind:parentData="{
                                                    tabItems: cityItems,
                                                    formTitle: `${this.$t('columns.city')}`,
                                                    formId: 'city'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveCity="saveCityHandler"
                                                v-on:deleteCity="
                                                    deleteCityHandler($event)
                                                "
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="cityDialog = false"
                                                    >{{$t('button.close')}}</v-btn
                                                >
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n4 mt-n8">
                                    <v-autocomplete
                                        outlined
                                        :label="this.$t('columns.country')"
                                        type="text"
                                        ref="permanentcntryid"
                                        :rules="[rules.required]"
                                        hide-selected
                                        v-model="formData.permanentcntryid"
                                        @input="updateValue"
                                        @change="
                                            changePermanentCountryHandler(
                                                formData.permanentcntryid
                                            )
                                        "
                                        dense
                                        :items="countryList"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="2" sm="3" class="pb-n4 mt-n8">
                                    <v-text-field
                                        dense
                                        :label="this.$t('columns.code')"
                                        outlined
                                        readonly
                                        background-color="#ffeab2"
                                        v-model="formData.permanentcountryCode"
                                        @input="updateValue"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                                    <v-dialog
                                        v-model="countryDialog"
                                        max-width="500px"
                                        persistent
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn dense v-on="on">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <app-genericform
                                                v-bind:parentData="{
                                                    tabItems: countryItems,
                                                    formTitle: `${this.$t('columns.country')}`,
                                                    formId: 'country'
                                                }"
                                                v-on:input="itemData = $event"
                                                v-on:saveCountry="
                                                    saveCountryHandler
                                                "
                                                v-on:deleteCountry="
                                                    deleteCountryHandler
                                                "
                                            />

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="warning"
                                                    text
                                                    @click="
                                                        countryDialog = false
                                                    "
                                                    >{{$t('button.close')}}</v-btn
                                                >
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        type="number"
                                        ref="permanentposcode"
                                        :label="this.$t('columns.postcode')"
                                        outlined
                                        dense
                                        class="numberfield"
                                        :rules="[rules.required]"
                                        v-model="formData.permanentposcode"
                                        @input="updateValue"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('columns.telephone')"
                                        type="number"
                                        ref="permanenttel"
                                        v-model="formData.permanenttel"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.emergencycontact_1')"
                                        type="text"
                                        ref="emergencycontact1"
                                        v-model="formData.emergencycontact1"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.contact_1no')"
                                        type="number"
                                        ref="contactno1"
                                        v-model="formData.contactno1"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.emergencycontact_2')"
                                        type="text"
                                        ref="emergencycontact2"
                                        v-model="formData.emergencycontact2"
                                        @input="updateValue"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="2" sm="5" class="pb-n5 mt-n8">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reccontactdetails.contact_2no')"
                                        type="number"
                                        ref="contactno2"
                                        v-model="formData.contactno2"
                                        @input="updateValue"
                                        dense
                                        class="numberfield"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                                    <v-textarea
                                        outlined
                                        :label="this.$t('columns.remark')"
                                        type="text"
                                        ref="remark"
                                        v-model="formData.remark"
                                        @input="updateValue"
                                        dense
                                        class="border-color"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons
                    v-bind:EditBool="this.isEdit"
                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler"
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler"
                />
            </form>
        </v-container>
    </div>
</template>

<script>
import GenericForm from "../../generic/GridForm";

export default {
    props: {
        parentObject: Object
    },

    components: {
        "app-genericform": GenericForm
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            formData: {
                psnid: this.parentObject.data.psnid,
                pic: this.parentObject.data.pic,
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                compnyid: this.parentObject.data.compnyid,
                compnycode: this.parentObject.data.compnycode,
                compnyname: this.parentObject.data.compnyname,
                // effectivedate: this.parentObject.data.effectivedate,
                // invaliddate: this.parentObject.data.invaliddate,
                email: this.parentObject.data.email,
                hpno: this.parentObject.data.hpno,
                alternativeemail: this.parentObject.data.alternativeemail,
                externalno: this.parentObject.data.externalno,
                correspondanceaddr1: this.parentObject.data.correspondanceaddr1,
                correspondanceaddr2: this.parentObject.data.correspondanceaddr2,
                correspondanceaddr3: this.parentObject.data.correspondanceaddr3,
                cityid: this.parentObject.data.cityid,
                cityCode: this.parentObject.data.citycode,
                cntryid: this.parentObject.data.cntryid,
                poscode: this.parentObject.data.poscode,
                tel: this.parentObject.data.tel,
                permanentaddr1: this.parentObject.data.permanentaddr1,
                asabove: this.parentObject.data.asabove,
                permanentaddr2: this.parentObject.data.permanentaddr2,
                permanentaddr3: this.parentObject.data.permanentaddr3,
                permanentcityid: this.parentObject.data.permanentcityid,
                permanentcityCode: this.parentObject.data.permanentcitycode,
                permanentcntryid: this.parentObject.data.permanentcntryid,
                permanentcountryCode: this.parentObject.data.permanentcountryCode,
                permanentposcode: this.parentObject.data.permanentposcode,
                permanenttel: this.parentObject.data.permanenttel,
                emergencycontact1: this.parentObject.data.emergencycontact1,
                contactno1: this.parentObject.data.contactno1,
                emergencycontact2: this.parentObject.data.emergencycontact2,
                contactno2: this.parentObject.data.contactno2,
                lockstaff: this.parentObject.data.lockstaff,
                remark: this.parentObject.data.remark,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                counter: value => value.length <= 200 || this.$t('reportsparam.maxchar'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t('reportsparam.invalidemail');
                }
            },

            //arrays used to store data objects from load handlers
            cityList: [],
            cityItems: [],
            cities: [],
            countries: [],
            countryList: [],
            countryItems: [],
            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            cityDialog: false,
            countryDialog: false,
            isEdit: this.parentObject.isEdit,

            invaliddate: "",
            search: this.parentObject.searchnew
        };
    },

    methods: {
        //convert image to base64
        imgToBase64() {
            var file = this.$refs.profileimg.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.pic = b64;

                this.$emit("profile", b64);
            };
            reader.readAsDataURL(file);
        },

        //convert file to base64
        fileToBase64() {
            var file = this.$refs.file.internalValue;
            var reader = new FileReader();

            reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.attach = b64;
            };

            reader.readAsDataURL(file);
        },

        // load list handlers

        //load cities list
        loadCityListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/city/all"})
                .then(res => {
                    vm.cities = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.cityList.push({
                            text: res.data.rec[i].cityname,
                            value: res.data.rec[i].id
                        });

                        vm.cityItems.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].citycode,
                            desc: res.data.rec[i].cityname,
                            stamp: res.data.rec[i].stamp
                        });
                    }

                    if (vm.formData.cityid) {
                        vm.changeCityHandler(vm.formData.cityid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //laod countries list
        loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/country/all"})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push({
                            text: res.data.rec[i].cntryname,
                            value: res.data.rec[i].id
                        });

                        vm.countryItems.push({
                            id: res.data.rec[i].id,
                            code: res.data.rec[i].cntrycode,
                            desc: res.data.rec[i].cntryname,
                            stamp: res.data.rec[i].stamp
                        });
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //laod staff details list
        loadStaffDetailsListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/staff/alls"})
                .then(res => {
                    vm.staffdetails = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });

                        // console.log("staffdetailslist", vm.staffDetailsList);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load companies list
        loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        updateValue() {
            this.$emit("input", {
                psnid: this.formData.psnid,
                pic: this.formData.pic,
                staffid: this.formData.staffid,
                compnyid: this.formData.compnyid,
                // effectivedate: this.formData.effectivedate,
                // invaliddate: this.formData.invaliddate,
                email: this.formData.email,
                hpno: this.formData.hpno,
                alternativeemail: this.formData.alternativeemail,
                externalno: this.formData.externalno,
                correspondanceaddr1: this.formData.correspondanceaddr1,
                correspondanceaddr2: this.formData.correspondanceaddr2,
                correspondanceaddr3: this.formData.correspondanceaddr3,
                cityid: this.formData.cityid,
                cityCode: this.formData.cityCode,
                cntryid: this.formData.cntryid,
                poscode: this.formData.poscode,
                tel: this.formData.tel,
                permanentaddr1: this.formData.permanentaddr1,
                asabove: this.formData.asabove,
                permanentaddr2: this.formData.permanentaddr2,
                permanentaddr3: this.formData.permanentaddr3,
                permanentcityid: this.formData.permanentcityid,
                permanentcountryCode: this.formData.permanentcountryCode,
                permanentcityCode: this.formData.permanentcitycode,
                permanentcntryid: this.formData.permanentcntryid,
                permanentposcode: this.formData.permanentposcode,
                permanenttel: this.formData.permanenttel,
                emergencycontact1: this.formData.emergencycontact1,
                contactno1: this.formData.contactno1,
                emergencycontact2: this.formData.emergencycontact2,
                contactno2: this.formData.contactno2,
                lockstaff: this.formData.lockstaff,
                remark: this.formData.remark,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveCityHandler() {
            const vm = this;

            //if already exists, update record
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url: "/city/" + this.itemData.itemid,
                        payload: {
                            citycode: this.itemData.itemcode,
                            cityname: this.itemData.itemdesc,
                            stamp: this.itemData.itemstamp
                        }}
                    )
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.cities = [];
                            vm.cityList = [];
                            vm.cityItems = [];

                            this.loadCityListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            } else {
                //if not exists, insert record
                this.$store.dispatch('post', {url: "/city/create", payload: {
                        citycode: this.itemData.itemcode,
                        cityname: this.itemData.itemdesc
                    }})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.cities = [];
                            vm.cityList = [];
                            vm.cityItems = [];

                            this.loadCityListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        saveCountryHandler() {
            const vm = this;

            //if already exists, update record
            if (this.itemData.itemid) {
                this.$store.dispatch('put', {url:"/country/" + this.itemData.itemid,
                        payload: {
                            cntrycode: this.itemData.itemcode,
                            cntryname: this.itemData.itemdesc,
                            stamp: this.itemData.itemstamp
                        }}
                    )
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.countries = [];
                            vm.countryList = [];
                            vm.countryItems = [];

                            this.loadCountryListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            } else {
                //else if not exits, update record
                this.$store.dispatch('post', {url: "/country/create", payload:{
                        cntrycode: this.itemData.itemcode,
                        cntryname: this.itemData.itemdesc
                    }})
                    .then(res => {
                        if (res.data.status == 201) {
                            // resets all arrays
                            vm.countries = [];
                            vm.countryList = [];
                            vm.countryItems = [];

                            this.loadCountryListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        deleteCityHandler($event) {
            const vm = this;

            if (confirm("Remove " + $event.desc + "?")) {
                this.$store.dispatch('delete', {url: "/city/" + $event.id})
                    .then(res => {
                        if (res.data.status == 200) {
                            // resets all arrays
                            vm.cities = [];
                            vm.cityList = [];
                            vm.cityItems = [];

                            this.loadCityListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        deleteCountryHandler($event) {
            const vm = this;

            if (confirm("Remove " + $event.desc + "?")) {
                this.$store.dispatch('delete', {url: "/country/" + $event.id})
                    .then(res => {
                        if (res.data.status == 200) {
                            // resets all arrays
                            vm.countries = [];
                            vm.countryList = [];
                            vm.countryItems = [];

                            this.loadCountryListHandler();
                        } else {
                            vm.isErr = true;
                            vm.errMsg = res.data.error;
                            vm.$emit("error", {
                                isErr: vm.isErr,
                                errMsg: vm.errMsg
                            });
                        }
                    })
                    .catch(err => {
                        vm.isErr = true;
                        vm.errMsg = err;
                        vm.$emit("error", {
                            isErr: vm.isErr,
                            errMsg: vm.errMsg
                        });
                    });
            }
        },

        // change handlers
        //change cityCode after selectin cityid
        changeCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.formData.cityCode = item.citycode;
        },

        //change permanentcityCode after selectin permanentcityid
        changePermanentCityHandler(id) {
            let item = this.cities.find(item => item.id === id);

            this.formData.permanentcityCode = item.citycode;
        },

        //change countryCode after selecting cntryid
        changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.formData.countryCode = item.cntrycode;
        },

        //change permanentcountryCode after selecting permanentcntryid
        changePermanentCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);
            this.formData.permanentcountryCode = item.cntrycode;
        },

        //change values of permanent fields when asabove checkbox is clicked
        changePermanentFieldsHandler(asabove) {
            //set values same asabove
            if (asabove == true) {
                this.formData.permanentaddr1 = this.formData.correspondanceaddr1;
                (this.formData.permanentaddr2 = this.formData.correspondanceaddr2),
                    (this.formData.permanentaddr3 = this.formData.correspondanceaddr3),
                    (this.formData.permanentcityid = this.formData.cityid),
                    (this.formData.permanentcityCode = this.formData.cityCode),
                    (this.formData.permanentcntryid = this.formData.cntryid),
                    (this.formData.permanentcountryCode = this.formData.countryCode),
                    (this.formData.permanentposcode = this.formData.poscode),
                    (this.formData.permanenttel = this.formData.tel);
            } else {
                //reset fields
                this.formData.permanentaddr1 = "";
                (this.formData.permanentaddr2 = ""),
                    (this.formData.permanentaddr3 = ""),
                    (this.formData.permanentcityCode = ""),
                    (this.formData.permanentcityid = ""),
                    (this.formData.permanentcountryCode = ""),
                    (this.formData.permanentcntryid = ""),
                    (this.formData.permanentposcode = ""),
                    (this.formData.permanenttel = "");
            }
        },

        //change staffid and compnyid after selectin psnid
        changeStaffIDandCompanyHandler(id) {
            let item = this.staffdetails.find(item => item.id === id);
            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;
        },

        newHandler() {
            this.$emit("new");
        },
        saveHandler() {
            this.$emit("save");
        },

        deleteHandler() {
            this.$emit("delete");
        },

        cancelHandler() {
            this.$router.push({ name: "contactdetailslist", params: { search: this.search} });
        }
    },

    mounted() {
        this.loadCityListHandler();
        this.loadCountryListHandler();
        this.loadStaffDetailsListHandler();
        //this.loadCompaniesListHandler();

        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    },
};
</script>


<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
    -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>

