<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >
                    <!--v-row>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Effective From"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5"></v-col>

                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-text-field
                                outlined
                                label="Invalid Date"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row-->

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n5">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.job')"
                                id="jobcode"
                                type="text"
                                ref="jobcode"
                                :rules="[rules.required]"
                                v-model="formData.jobcode"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('setupjob.jobtitle')"
                                type="text"
                                ref="jobtitle"
                                :rules="[rules.required]"
                                v-model="formData.jobtitle"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupjob.jobcat')"
                                id="jobcatid"
                                type="text"
                                ref="jobcatid"
                                :rules="[rules.required]"
                                :items="jobCatList"
                                hide-selected
                                v-model="formData.jobcatid"
                                @input="updateValue"
                                @change="changeJobCatHandler(formData.jobcatid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="jobCatCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="jobCatDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: jobCatItems,
                                            formTitle: `${this.$t('setupjob.jobcat')}`,
                                            formId: 'jobcat'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveJobCat="saveJobCatHandler"
                                        v-on:deleteJobCat="deleteJobCatHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="jobCatDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.employmenttype')"
                                id="emptypeid"
                                type="text"
                                ref="emptypeid"
                                :rules="[rules.required]"
                                :items="emptypeList"
                                hide-selected
                                v-model="formData.emptypeid"
                                @input="updateValue"
                                @change="changeEmpTypeHandler(formData.emptypeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="empTypeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.grade')"
                                id="gradeid"
                                type="text"
                                ref="gradeid"
                                :rules="[rules.required]"
                                :items="gradeList"
                                hide-selected
                                v-model="formData.gradeid"
                                @input="updateValue"
                                @change="changeGradeHandler(formData.gradeid)"
                                dense
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="gradeCode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.gradecategory')"
                                id="grdcatdesc"
                                type="text"
                                ref="grdcatdesc"
                                background-color="#ffeab2"
                                readonly
                                v-model="GradeCat"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="10" sm="10" class="pb-n4 mt-n8">
                            <v-textarea
                                outlined
                                :label="this.$t('columns.remark')"
                                type="text"
                                ref="remark"
                                v-model="formData.remark"
                                @input="updateValue"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="pb-n4 mt-n8">
                            <v-checkbox
                                ref="isdisable"
                                v-model="formData.isdisable"
                                :label="this.$t('columns.disable')"
                                @change="updateValue"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
// import moment from 'moment';

import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-genericform': GenericForm,
    },

    data() {
        return {
            formData: {
                jobcode: this.parentObject.data.jobcode,
                jobtitle: this.parentObject.data.jobtitle,
                jobcatid: this.parentObject.data.jobcatid,
                emptypeid: this.parentObject.data.emptypeid,
                gradeid: this.parentObject.data.gradeid,
                // startdate: this.parentObject.data.startdate,
                // enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                isdisable: this.parentObject.data.isdisable,
                joblongdesc: this.parentObject.data.joblongdesc,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },
            itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
            GradeCat: '',
            jobCatCode: '',
            empTypeCode: '',
            gradeCode: '',
            jobCatList: [],
            emptypeList: [],
            gradeList: [],
            jobCats: [],
            jobCatItems: [],
            empTypes: [],
            grades: [],
            jobCatDialog: false,
            errMsg: '',
            isErr: false,
             search: this.parentObject.searchnew
        }
    },

    methods: {
        test($event) {
            console.log($event)
        },
        loadEmpTypeList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/emptype/all'})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].isdisable == 'N')
                        {
                            vm.emptypeList.push(
                                {
                                    text: res.data.rec[i].emptypedesc,
                                    value: res.data.rec[i].id
                                }
                            )
                        }
                    }

                    vm.empTypes = res.data.rec;

                    if (this.formData.emptypeid) {
                        this.changeEmpTypeHandler(this.formData.emptypeid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadJobCatList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/jobcat/all'})
                .then(res => {
                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.jobCatList.push(
                            {
                                text: res.data.rec[i].jobcatdesc,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.jobCatItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].jobcat,
                                desc: res.data.rec[i].jobcatdesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.jobCats = res.data.rec;

                    if (this.formData.jobcatid) {
                        this.changeJobCatHandler(this.formData.jobcatid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadGradeList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/grade/all'})
                .then(res => {
                    vm.grades = res.data.rec;
                    // const todayDate = moment();

                    for (let i = 0; i < res.data.rec.length; i++) {
                        // if (todayDate.isSameOrAfter(res.data.rec[i].startdate) && todayDate.isBefore(res.data.rec[i].enddate) && res.data.rec[i].isdisable == 'N') 
                        {
                            vm.gradeList.push(
                                {
                                    text: res.data.rec[i].grddesc,
                                    value: res.data.rec[i].id
                                }
                            )    
                        }
                    }

                    if (this.formData.gradeid) {
                        this.changeGradeHandler(this.formData.gradeid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        updateValue() {
            this.$emit('input', {
                jobcode: this.formData.jobcode,
                jobtitle: this.formData.jobtitle,
                jobcatid: this.formData.jobcatid,
                emptypeid: this.formData.emptypeid,
                gradeid: this.formData.gradeid,
                // startdate: this.formData.startdate,
                // enddate: this.formData.enddate,
                remark: this.formData.remark,
                isdisable: this.formData.isdisable,
                joblongdesc: this.formData.joblongdesc,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveJobCatHandler() {
            const vm = this;
            if (this.itemData.itemid) {
                
                this.$store.dispatch('put', {url: '/jobcat/' + this.itemData.itemid, payload: {
                jobcat: this.itemData.itemcode,
                jobcatdesc: this.itemData.itemdesc,
                stamp: this.itemData.itemstamp
                }})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.jobCats = [];
                        vm.jobCatItems = [];
                        vm.jobCatList = [];

                        this.loadJobCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {url: '/jobcat/create', payload: {
                jobcat: this.itemData.itemcode,
                jobcatdesc: this.itemData.itemdesc,
                userid: this.$store.getters.userId
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.jobCats = [];
                        vm.jobCatItems = [];
                        vm.jobCatList = [];

                        this.loadJobCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteJobCatHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {url: '/jobcat/' + $event.id + '/' + this.$store.getters.userId})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.jobCats = [];
                        vm.jobCatItems = [];
                        vm.jobCatList = [];

                        this.loadJobCatList();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }
        },

        changeJobCatHandler(id) {
            let item = this.jobCats.find(item => item.id === id);

            this.jobCatCode = item.jobcat;
        },

        changeEmpTypeHandler(id) {
            let item = this.empTypes.find(item => item.id === id);

            this.empTypeCode = item.emptype;
        },

        changeGradeHandler(id) {
            let item = this.grades.find(item => item.id === id);

            this.GradeCat = item.grdcatdesc;
            this.gradeCode = item.grdcode;
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'joblist', params: { search: this.search}});
        }
    },

    mounted() {
        this.loadEmpTypeList();
        this.loadJobCatList();
        this.loadGradeList();
    }
}
</script>