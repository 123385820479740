<template>
    <div>
        <loading
            :active.sync="loading"
            :can-cancel="false"
            :is-full-page="true"
            :height="150"
            :width="150"
            :color="'#FFFF00'"
            :background-color="'#000000'"
            :opacity="0.5"
        ></loading>
        <v-container fluid style="height: 100%; width:100%; float: left; padding-left: 0px">
            <v-form>


                 <v-row>
                    <div >
                        <v-row>
                            <v-col>
                                <v-btn
                                    v-model="goBtn"
                                    color="warning"
                                    style="width: 100px"
                                    @click="backHandler"
                                    >
                                    {{$t('button.back')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                </v-row>


                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}

                    </h2>
                </v-row>
                  <v-row class="mb-8">
                        <v-col cols="1">
                             {{$t('button.filter')}}
                        </v-col>
                        <v-col class="page_title_value">
                            <div v-if="emptypedescholder"> {{ this.emptypedescholder }} </div>
                            <div v-if="compnynameholder"> {{ this.compnynameholder }} </div>
                            <div v-if="brchnameholder"> {{ this.brchnameholder }} </div>
                            <div v-if="deptnameholder"> {{ this.deptnameholder }} </div>
                            <div v-if="valuedescholder"> {{ this.valuedescholder }} </div>
                            <div v-if="sectionameholder"> {{ this.sectionameholder }} </div>
                            <div v-if="divisionameholder"> {{ this.divisionameholder }} </div>
                            <div v-if="unitnameholder"> {{ this.unitnameholder }} </div>
                            <div v-if="gradenameholder"> {{ this.gradenameholder }} </div>
                            <div v-if="jobdescholder"> {{this.jobdescholder}} </div>
                            <div v-if="positiondescholder"> {{this.positiondescholder}} </div>
                        </v-col>
                </v-row>


            <v-container
            fluid id="scroll-target"
            class="custom-form overflow-y-auto px-5"
            >

                <v-row justify="center" align="center">
                  <v-col cols="8" >
                    <v-card elevation="5" outlined>
                        <div id="chart">
                            <apexchart
                                ref="realtimeChart"
                                type="line"
                                width= "100%"
                                height="350"
                                :options="chartOptions"
                                :series="series">
                            </apexchart>
                        </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                    <v-toolbar flat>
                    <v-toolbar-title
                        >
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                        <v-avatar class="profile mr-5" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_pdf.png`"
                        @click="printPDF"
                        />
                    </v-avatar>

                    <v-avatar class="profile" size="40" tile>
                        <img
                        :src="`${publicPath}images/export_csv.png`"
                        @click="getCSV"
                        />
                    </v-avatar>

                    <v-text-field
                            v-model="search"
                            append-icon="search"
                            style="width: 20px"
                            class="pr-5 pt-3"
                            hide-details>
                        </v-text-field>
                </v-toolbar>
                </v-row>

                <v-row>
                        <v-col cols="20" sm="20" >
                        <v-data-table
                            hide-default-footer
                            :headers="dataHeader"
                            :search ="search"
                            :items="dataItems"
                            :items-per-page="1000"
                            class="elavation-1 fixed header"
                            fixed-header
                            style="margin-top: 20px"
                            no-data-text="No records found for the selected training hours analysis."
                        ></v-data-table>
                        </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import Loading from "vue-loading-overlay";

export default {
    components: {
        'apexchart': VueApexCharts,
        Loading
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,

             rules: {
                required: (value) => !!value || this.$t('reportsparam.required'),
                email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
                },
            },

            data: {
                formData: {
                    appexerciseid: this.$route.params.formData.appexerciseid,
                    startdate: this.$route.params.formData.startdate,
                    enddate: this.$route.params.formData.enddate,
                    rankingsetid: this.$route.params.formData.rankingsetid,
                    userid: this.$store.getters.userId,
                },

                  parameters: {
                    compnyid: this.$route.params.param.companyid,
                    sectioid: this.$route.params.param.sectionid,
                    emptypeid: this.$route.params.param.employmenttypeid,
                    brchid: this.$route.params.param.branchid,
                    unitid: this.$route.params.param.unitid,
                    positionid: this.$route.params.param.positionid,
                    deptid: this.$route.params.param.departmentid,
                    gradeid: this.$route.params.param.gradeid,
                    jobid: this.$route.params.param.jobid,
                    divnid: this.$route.params.param.divisionid,
              

                },

            },

               //table header
            dataHeader: [
                    { text: this.$t('columns.no'), width: "50px", value: "no" },
                    { text: this.$t('performancereport.reviewexercisedescription'), value: 'assessmenttypedesc', width: '200px' },
                    { text: this.$t('appraisalrecord.reviewfrom'), value: 'startdate', width: '150px' },
                    { text: this.$t('appraisalrecord.reviewuntil'), value: 'enddate', width: '150px' },
                    { text: this.$t('columns.staffname'), value: 'staffname', width: '200px' },
                    { text: this.$t('columns.staffcode'), value: 'staffcode', width: '100px' },
                    { text: this.$t('Setupratingscaleset.score'), value: 'score', width: '150px' },
                    { text: this.$t('appraisalrecord.adjustedscore'), value: 'adjustedscore', width: '150px' },
                    { text: this.$t('Setuprankingset.rank'), value: 'rankingcode', width: '150px' },
                    { text: this.$t('appraisalrecord.adjustedrank'), value: 'adjustedrankingcode', width: '150px' },
                    { text: this.$t('columns.company'), value: 'compnycode', width: '120px' },
                    { text: this.$t('columns.branch'), value: 'brchcode', width: '120px' },
                    { text: this.$t('columns.department'), value: 'deptcode', width: '120px' },
                    { text: this.$t('columns.division'), value: 'divncode', width: '120px' },
                    { text: this.$t('columns.section'), value: 'sectiocode', width: '120px' },
                    { text: this.$t('columns.unit'), value: 'unitcode', width: '120px' },
                    { text: this.$t('columns.job'), value: 'jobcode', width: '150px' },
                    { text: this.$t('columns.grade'), value: 'grdcode', width: '200px' },
            ],

            tableOpt: {
                itemsPerPage: -1,
            },

            dataItems: [],
            chartItems: [],

            parameter: null,

            //load error message variable
            isErr: false,
            loading: true,
            checkloading:0,

            goBtn: false,

           pageTitle: this.$t('performancereport.performancerewardsummaryfor') + " " + this.$route.params.formData.appexercisedesc,

           pageTitleValue: "",

            search: '',


            emptypedescholder: null,
            compnynameholder: null,
            brchnameholder: null,
            deptnameholder: null,
            valuedescholder: null,
            sectionameholder: null,
            divisionameholder: null,
            unitnameholder: null,
            gradenameholder: null,
            jobdescholder: null,
            positiondescholder: null,
            gradecatdescholder: null,

            series: [{
            name: this.$t('performancereport.totalstaff'),
            type: 'bar',
            data: []
            },
            {
            name: this.$t('performancereport.distribution(%)'),
            type: 'line',
            data: []
          }],

          chartOptions: {
            chart: {
              id: 'linechart',
              height: 350,
              width: "100%",
              locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
              defaultLocale: this.$route.params.lang,
              type: 'line',
              
            },
             stroke: {
               width: [0, 4]
             },
            title: {
              text: this.$t('performancereport.rankdistribution'),
              align: 'center'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1]
            },
            yaxis: [{
              title: {
                //text: 'Rank',
              },
            }, {
              opposite: true,
              title: {
                text: '%'
                
              }
            }],
            xaxis: {
              categories: [],
            },

            markers: {
              size: 1
            },

            responsive: [{
             breakpoint: 480,
             options: {

                 plotOptions: {
                    bar: {
                    horizontal: true,
                    distributed: true,
                    borderRadius: 4,
                    }
                  },
                  
                    legend: {
                    position: 'bottom',
                    show: false,
                    },
                }
            }],
          },

        }
    },

    methods: {

   PerformanceReviewerAnalysisChart(){

    //this.goBtn = true;
    const vm = this;

    this.loading = true;

      this.$store.dispatch('post', {
        url:`/performancerewardsummarychart/${this.$store.getters.userId}`,
        payload: this.data
      })
        .then(function (res) {

          vm.chartItems = res.data.rec;
        //  console.log('chartItems', vm.chartItems)

          let sum = [];

          let average = [];

          for (var i = 0; i < vm.chartItems.length; i++) {
                    
            vm.chartOptions.xaxis.categories.push(vm.chartItems[i].rankingdesc);    
            
          //  console.log('xaxis', vm.chartOptions.xaxis.categories)

             sum.push(
              parseFloat(vm.chartItems[i].staffid)
            );
            }

            let gettotal = sum.reduce(function (a, b) {
                return a + b;
            }, 0);

            gettotal = gettotal.toFixed(2);

           // console.log('gettotal', gettotal)

            
             for (var j = 0; j < sum.length; j++) {
            
       
            average.push(parseFloat((sum[j])/(gettotal) * 100)).toFixed(2);
         

             vm.series[0].data.push(parseFloat(sum[j]).toFixed(2))
             vm.series[1].data.push(parseFloat(average[j]).toFixed(2))

             console.log('series2', vm.series[1].data)

             }

            // console.log('series1', vm.series[0].data)
            // console.log('series2', vm.series[1].data)

             vm.loading = false; 
             vm.updateChart();           
                 
        })
        .catch(function (err) {
          vm.errMsg = err;
       //   console.log('err', err)
        });
    },

    updateChart() {

    const vm = this;

      this.$refs.realtimeChart.updateSeries([{
        name: this.$t('performancereport.totalstaff'),
            type: 'bar',
            data: vm.series[0].data
      },
      {
            name: this.$t('performancereport.distribution(%)'),
            type: 'line',
            data: vm.series[1].data
          }
      ], false, true);

    },


        daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
        },

        padRJ(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
        },  

        PerformanceRewardSummaryLoad(){

            this.goBtn = true;
            const vm = this;

            vm.loading = true

                this.$store.dispatch('post', {
                    url: `/performancerewardsummary/${this.$store.getters.userId}`,
                    payload: this.data
                })
                .then(function (res) {


                vm.dataItems = res.data.rec;

                vm.loading = false

                for (let i = 0; i < vm.dataItems.length; i++) {
                    vm.dataItems[i].no = i + 1;
                    vm.dataItems[i].startdate = moment(vm.dataItems[i].startdate).format("DD-MM-YYYY");
                    vm.dataItems[i].enddate = moment(vm.dataItems[i].enddate).format("DD-MM-YYYY");

                }

               vm.PerformanceReviewerAnalysisChart();

                })
                .catch(function (err) {
                vm.errMsg = err;
                });
         },

        employmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/emptype/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.emptypeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.emptypeid)[0]
                        vm.emptypedescholder = itemholder.emptypedesc
                    }

                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        companyHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/company/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.compnyid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.compnyid)[0]
                        vm.compnynameholder = itemholder.compnyname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        branchHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/branch/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.brchid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.brchid)[0]
                        vm.brchnameholder = itemholder.brchname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        departmentHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/department/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.deptid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.deptid)[0]
                        vm.deptnameholder = itemholder.deptname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        valvalueHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/valvalue/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.targettraininggroupid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.targettraininggroupid)[0]
                        vm.valuedescholder = itemholder.valuedesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        jobHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/job/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.jobid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.jobid)[0]
                        vm.jobdescholder = itemholder.jobtitle
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        gradecatHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/gradecat/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradecatid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradecatid)[0]
                        vm.gradecatdescholder = itemholder.grdcatdesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        positionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/position/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.positionid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.positionid)[0]
                        vm.positiondescholder = itemholder.posdesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        sectionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/emptype/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.sectioid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.sectioid)[0]
                        vm.sectionameholder = itemholder.sectioname
                    }

                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        unitHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/company/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.unitid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.unitid)[0]
                        vm.unitnameholder = itemholder.unitname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        divisionHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/branch/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.divnid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.divnid)[0]
                        vm.divisionameholder = itemholder.divnname
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },

        gradeHandler() {
            const vm = this

            this.$store.dispatch('get', {
                url: `/grade/all`
            })
                .then(async res => {
                    let item = res.data.rec;
                    if (vm.data.parameters.gradeid) {
                        let itemholder = item.filter(x => x.id == vm.data.parameters.gradeid)[0]
                        vm.gradenameholder = itemholder.grddesc
                    }
                })
                .catch(err => {
                    //error - unsucessfull request
                    this.isErr = true;
                    this.errMsg = err;
                });
        },


        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        // get CSV for dataHeader

        async getCSV() {
            let item = this.dataItems;
            let data = []

            for (var i = 0; i < item.length; i++) {

                data.push({
                    [`${this.$t('columns.no')}`]: item[i].no,
                    [`${this.$t('performancereport.reviewexercisedescription')}`]: item[i].assessmenttypedesc,
                    [`${this.$t('appraisalrecord.reviewfrom')}`]: item[i].startdate,
                    [`${this.$t('appraisalrecord.reviewuntil')}`]: item[i].enddate,
                    [`${this.$t('columns.staffname')}`]: item[i].staffname,
                    [`${this.$t('columns.staffcode')}`]: item[i].staffcode,
                    [`${this.$t('Setupratingscaleset.score')}`]: item[i].score,
                    [`${this.$t('appraisalrecord.adjustedscore')}`]: item[i].adjustedscore,
                    [`${this.$t('Setuprankingset.rank')}`]: item[i].rankingcode,
                    [`${this.$t('appraisalrecord.adjustedrank')}`]: item[i].adjustedrankingcode,
                    [`${this.$t('columns.company')}`]: item[i].compnycode,
                    [`${this.$t('columns.branch')}`]: item[i].brchcode,
                    [`${this.$t('columns.department')}`]: item[i].deptcode,
                    [`${this.$t('columns.division')}`]: item[i].divncode,
                    [`${this.$t('columns.section')}`]: item[i].sectiocode,
                    [`${this.$t('columns.unit')}`]: item[i].unitcode,
                    [`${this.$t('columns.job')}`]: item[i].jobtitle,
                    [`${this.$t('columns.grade')}`]: item[i].grddesc,
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },



     // print PDF for dataheader
        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })

            var head = [
                { title: this.$t('columns.no'), dataKey: 'no' },
                { title: this.$t('performancereport.reviewexercisedescription'), dataKey: 'assessmenttypedesc' },
                { title: this.$t('appraisalrecord.reviewfrom'), dataKey: 'startdate' },
                { title: this.$t('appraisalrecord.reviewuntil'), dataKey: 'enddate' },
                { title: this.$t('columns.staffname'), dataKey: 'staffname' },
                { title: this.$t('columns.staffcode'), dataKey: 'staffcode' },
                { title: this.$t('Setupratingscaleset.score'), dataKey: 'score'},
                { title: this.$t('appraisalrecord.adjustedscore'), dataKey: 'adjustedscore' },
                { title: this.$t('Setuprankingset.rank'), dataKey: 'rankingcode' },
                { title: this.$t('appraisalrecord.adjustedrank'), dataKey: 'adjustedrankingcode' },
                { title: this.$t('columns.company'), dataKey: 'compnycode' },
                { title: this.$t('columns.branch'), dataKey: 'brchcode'},
                { title: this.$t('columns.department'), dataKey: 'deptcode' },
                { title: this.$t('columns.division'), dataKey: 'divncode' },
                { title: this.$t('columns.section'), dataKey: 'sectiocode'},
                { title: this.$t('columns.unit'), dataKey: 'unitcode' },
                { title: this.$t('columns.job'), dataKey: 'jobtitle' },
                { title: this.$t('columns.grade'), dataKey: 'grddesc'}

            ]

            let body = []

            for (var i = 0; i < this.dataItems.length; i++) {
                body.push({
                    no: this.dataItems[i].no,
                    assessmenttypedesc: this.dataItems[i].assessmenttypedesc,
                    startdate: this.dataItems[i].startdate,
                    enddate: this.dataItems[i].enddate,
                    staffname: this.dataItems[i].staffname,
                    staffcode: this.dataItems[i].staffcode,
                    score: this.dataItems[i].score,
                    adjustedscore: this.dataItems[i].adjustedscore,
                    rankingcode: this.dataItems[i].rankingcode,
                    adjustedrankingcode: this.dataItems[i].adjustedrankingcode,
                    compnycode: this.dataItems[i].compnycode,
                    brchcode: this.dataItems[i].brchcode,
                    deptcode: this.dataItems[i].deptcode,
                    divncode: this.dataItems[i].divncode,
                    sectiocode: this.dataItems[i].sectiocode,
                    unitcode: this.dataItems[i].unitcode,
                    jobtitle: this.dataItems[i].jobtitle,
                    grddesc: this.dataItems[i].grddesc,

                })
            }

            this.title = this.$t('performancereport.performancerewardsummary');
            doc.text(this.title, 10, 10)
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        },

        //back handler - go back to TrainingHoursAnalysisParameter.vue
        backHandler(){
            this.$router.push({name: 'performancerewardparameter'});
        },

        loadParameters() {

            this.companyHandler();
            this.departmentHandler();
            this.branchHandler();
            this.employmentHandler();
            this.valvalueHandler();
            this.sectionHandler();
            this.divisionHandler();
            this.unitHandler();
            this.gradeHandler();
            this.gradecatHandler();

        }
    },

    created() {
        const vm = this;
        vm.formData = this.$route.params.formData;

         this.PerformanceRewardSummaryLoad();
         

         
         //this.abc();

          if (typeof(this.$route.params.parameters) == 'string') {
            let paramHolder = JSON.parse(this.$route.params.parameters)
            this.data.parameters = {
                brchid: paramHolder.branchid,
                compnyid: paramHolder.companyid,
                deptid: paramHolder.departmentid,
                emptypeid: paramHolder.employmenttypeid,
                targettraininggroupid: paramHolder.targettraininggroupid,
                jobid: paramHolder.jobid,
                positionid: paramHolder.positionid,
                divnid:paramHolder.divisionid,
                sectioid:paramHolder.sectionid,
                unitid:paramHolder.unitid,
                grdid:paramHolder.gradeid,
                gradecatid: paramHolder.gradecatid,
            }
          }
    },

    mounted() {
     this.loadParameters();

    }
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}

.TotalClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: orange;
    align-content: center;
    justify-content: center;
}
.TotalClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.ClaimAmt {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: green;
    align-content: center;
    justify-content: center;
}
.ClaimAmtContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}

.Average {
    font-size: 12px;
    color:#FFFFFF;
    height: 2px;
    background-color: blue;
    align-content: center;
    justify-content: center;
}
.AverageContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;

    min-height: 60px;
}

.AverageClaim {
    font-size: 12px;
    color:#FFFFFF;
    height: 0px;
    background-color: red;
    align-content: center;
    justify-content: center;
}
.AverageClaimContent {
    font-weight: 700;
    font-size: 14px;
    padding-top: 15px;
    text-align: center;
    justify-content: center;
    color: #2374BB;
    min-height: 60px;
}
</style>