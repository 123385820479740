<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >


                 <v-row>
                        <v-col cols="4" sm="4" class="pb-n4">
                                <v-autocomplete
                                    outlined
                                    :label="this.$t('apprailsalexercise')"
                                    type="text"
                                    ref="appexerciseid"
                                    v-model="formData.appexerciseid"
                                    dense
                                    clearable
                                    :items="appraisalexerciseList"
                                    @input="updateValue"
                                    :rules="[rules.required]"
                                    @change="changeAppraisalExercise(formData.appexerciseid)"
                                    
                                ></v-autocomplete>
                            </v-col>

                             <v-col cols="8" sm="4" class="pb-n4">
                            <v-text-field
                                dense
                                :label="this.$t('columns.code')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="appraisalexercisecode"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                          <v-col cols="8" sm="4" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewtype')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="reviewtype"
                            ></v-text-field>
                        </v-col>

                          <v-col cols="8" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewfrom')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="formData.reviewfromdate"
                                @input="updateValue"
                            ></v-text-field>
                        </v-col>

                          <v-col cols="8" sm="2" class="pb-n4 mt-n8">
                            <v-text-field
                                dense
                                :label="this.$t('appraisalrecord.reviewuntil')"
                                outlined
                                readonly
                                background-color="#ffeab2"
                                v-model="reviewuntildate"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                       <v-row>
                      <v-col cols="4" sm="8" class="pb-n5 mt-n6">
                        <v-autocomplete
                        outlined
                        :label="this.$t('columns.staffname')"
                        type="text"
                        ref="psnid"
                        :rules="[rules.required]"
                        v-model="formData.staffid"
                        dense
                        :items="staffDetailsList"
                        @input="updateValue"
                        @change="changeStaffIDandCompanyHandler(formData.staffid)"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.staffid')"
                                type="text"
                                :rules="[rules.required]"
                                v-model="formData.staffcode"
                                dense
                                readonly
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="8" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.company')"
                                type="text"
                                ref="compnyid"
                                v-model="formData.compnyname"
                                dense
                                readonly
                                append-icon=""
                                background-color="#ffeab2"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    
                    <v-row>
                        <v-col cols="4" sm="8" class="pb-n5 mt-n8">
                        <div class="text-center">
                            <v-file-input
                            ref="attach"
                            :label="this.$t('claimrecordlisting.browseforattachment')"
                            outlined
                            v-model="fileHolder"
                            @change="fileToBase64"
                            dense
                            ></v-file-input>
                        </div>
                        </v-col>
                </v-row>

            <div v-if="this.formData.doc">
                <v-row>
                <v-col cols="4" sm="6" class="pb-8 mt-n5">
                    <p>
                    <a
                        v-bind:href="this.formData.doc"
                        v-bind:download="this.formData.doc"
                        >{{$t('download.downloadattachment')}}</a
                    >
                    <v-icon
                        small
                        class="ml-3 mr-2"
                        @click="deleteAttachmentHandler"
                    >
                        mdi-delete
                    </v-icon>
                    </p>
                </v-col>
                </v-row>
            </div>

            <v-row>
                <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                        outlined
                        :label="this.$t('columns.status')"
                        type="text"
                        ref="pmstatusid"
                        v-model="formData.pmstatusid"
                        dense
                        clearable
                        :items="pmstatusList"
                        @input="updateValue"
                        :rules="[rules.required]"
                        @change="changePMStatus(formData.pmstatusid)"
                    ></v-autocomplete>
                </v-col>
        

            
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                        :label="this.$t('Setupratingscaleset.score')"
                        type="text"
                        ref="score"
                        v-model="formData.score"
                        @input="updateValue"
                        @change="getRanking(formData.score)"
                        dense
                    ></v-text-field>
                </v-col>
           
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                        :label="this.$t('appraisalrecord.adjustedscore')"
                        type="text"
                        ref="adjustedscore"
                        v-model="formData.adjustedscore"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
              

                <v-col cols="8" sm="6" class="pb-n4 mt-n8">
                    <v-text-field
                        dense
                        :label="this.$t('Setuprankingset.rankdescription')"
                        outlined
                        readonly
                        background-color="#ffeab2"
                        v-model="formData.rankingdesc"
                    ></v-text-field>
                </v-col>

                  <v-col cols="8" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        dense
                        :label="this.$t('columns.code')"
                        outlined
                        readonly
                        background-color="#ffeab2"
                        v-model="formData.rankingcode"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="4" sm="6" class="pb-n4 mt-n8">
                    <v-autocomplete
                        outlined
                        :label="this.$t('appraisalrecord.adjustedrank')"
                        type="text"
                        ref="adjustedrankid"
                        v-model="formData.adjustedrankid"
                        dense
                        clearable
                        :items="rankList"
                         @input="updateValue"
                         @change="changeRank(formData.adjustedrankid)"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="8" sm="2" class="pb-n4 mt-n8">
                        <v-text-field
                            dense
                            :label="this.$t('columns.code')"
                            outlined
                            readonly
                            background-color="#ffeab2"
                            v-model="formData.adjustedrankingcode"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>

                    <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                        :label="this.$t('appraisalrecord.proposedincrement%')"
                        type="text"
                        ref="proposedincrpercent"
                        v-model="formData.proposedincrpercent"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
           
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                        :label="this.$t('appraisalrecord.proposedincrementamount')"
                        type="text"
                        ref="proposedincramt"
                        v-model="formData.proposedincramt"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>

                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.confirmedincrement%')"
                        type="text"
                        ref="confincrpercent"
                        v-model="formData.confincrpercent"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
           
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.confirmedincrementamount')"
                        type="text"
                        ref="confincramt"
                        v-model="formData.confincramt"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>

              <v-row>

                    <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.proposedbonusfactor')"
                        type="text"
                        ref="proposedbnsfact"
                        v-model="formData.proposedbnsfact"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
           
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.proposedbonusamount')"
                        type="text"
                        ref="proposedbnsamt"
                        v-model="formData.proposedbnsamt"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>

                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.confirmedbonusfactor')"
                        type="text"
                        ref="confbnsfact"
                        v-model="formData.confbnsfact"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
           
                <v-col cols="4" sm="2" class="pb-n4 mt-n8">
                    <v-text-field
                        outlined
                         :label="this.$t('appraisalrecord.confirmedbonusamount')"
                        type="text"
                        ref="confbnsamt"
                        v-model="formData.confbnsamt"
                        @input="updateValue"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>

             <v-row>
                        <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="isincrementpost"
                                v-model="formData.isincrementpost"
                                 :label="this.$t('appraisalrecord.incrementdatapostedtoupdatestaffdetails')"
                                @change="updateValue"
                                dense
                                readonly
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                     <v-row>
                        <v-col cols="2" sm="8" class="pb-n4 mt-n8">
                            <v-checkbox
                                outlined
                                ref="isbonuspost"
                                v-model="formData.isbonuspost"
                                 :label="this.$t('appraisalrecord.bonusdatapostedtopayroll')"
                                @change="updateValue"
                                dense
                                readonly
                            ></v-checkbox>
                        </v-col>
                     </v-row>

                       
                     <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('appraisalrecord.reviews')}}
                        </v-col>
                    </v-row>

                    <v-row>
                        <app-table
                            v-bind:parentObject = "{
                                tabHeader: this.tabHeader,
                                detailItem: this.formData.appraisalrecorddetail,
                                rankingsetid: this.rankingsetid
                            }"

                            v-on:input="addItemHandler"
                        />
                    </v-row>


                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment';
import CRUDTable from './CRUDTable';

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            },
            formData: {
                id: this.parentObject.data.id,
                appexerciseid : this.parentObject.data.appexerciseid,
                psnid: this.parentObject.data.psnid,
                staffid: this.parentObject.data.staffid,
                staffcode: this.parentObject.data.staffcode,
                staffname: this.parentObject.data.staffname,
                compnyname: this.parentObject.data.compnyname,
                compnyid: this.parentObject.data.compnyid,
                rankingsetid: this.parentObject.data.rankingsetid,
                doc: this.parentObject.data.doc,
                pmstatusid : this.parentObject.data.pmstatusid,
                score : this.parentObject.data.score,
                adjustedscore: this.parentObject.data.adjustedscore,
                rankid : this.parentObject.data.rankid,
                rankingcode: this.parentObject.data.rankingcode,
                rankingdesc: this.parentObject.data.rankingdesc,
                adjustedrankid : this.parentObject.data.adjustedrankid,
                adjustedrankingcode: this.parentObject.data.adjustedrankingcode,
                adjustedrankingdesc: this.parentObject.data.adjustedrankingdesc,
                proposedincrpercent : this.parentObject.data.proposedincrpercent,
                proposedincramt : this.parentObject.data.proposedincramt,
                confincrpercent : this.parentObject.data.confincrpercent,
                confincramt : this.parentObject.data.confincrpercent,
                proposedbnsfact : this.parentObject.data.proposedbnsfact,
                proposedbnsamt: this.parentObject.data.proposedbnsamt,
                confbnsfact: this.parentObject.data.confbnsfact,
                confbnsamt: this.parentObject.data.confbnsamt,
                isincrementpost: this.parentObject.data.isincrementpost,
                isbonuspost: this.parentObject.data.isbonuspost,
                reviewfromdate: this.parentObject.data.reviewfromdate,
                appraisalrecorddetail: this.parentObject.data.appraisalrecorddetail,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

          
           itemData: {
                itemcode: null,
                itemdesc: null,
                itemstamp: 0
            },
      
            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,

              tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '100px', sortable: false},
                { text: this.$t('appraisalrecord.reviewer'), value: 'reviewername', width: '200px', sortable: true},
                { text: this.$t('appraisalrecord.reviewercode(staffcode)'), value: 'reviewercode', width: '200px', sortable: true},
                { text: this.$t('appraisalrecord.periodfrom'), value: 'startdate', width: '200px', sortable: true},
                { text: this.$t('appraisalrecord.perioduntil'), value: 'enddate', width: '200px', sortable: true},
                { text: this.$t('appraisalrecord.factor'), value: 'factor', width: '200px', sortable: true},
                { text: this.$t('Setupratingscaleset.score'), value: 'score', width: '200px', sortable: true},
                { text: this.$t('Setuprankingset.rank'), value: 'rankingcode', width: '200px', sortable: true},
              
            ],

            appraisalexerciseItems: [],
            appraisalexerciseList: [],
            appraisalexercisecode: '',
            reviewtype: '',
            reviewfromdate: '',
            reviewuntildate: '',

            staffdetails: [],
            staffDetailsList: [],
            companies: [],
            companiesitemsList: [],

            fileHolder: null,

            pmstatusList: [],
            pmstatusItems: [],

            rankItems: [],
            rankList: [],
        
            rankingsetid: '',

            ranksetItems: [],

            appraisalrecordscore: [],
            total: null,
            amount : null,
            search: this.parentObject.searchnew




        }
    },

    methods: {

        addItemHandler($event) {
            this.formData.appraisalrecorddetail=$event
            this.rankingsetid = this.rankingsetid;
    
            this.updateValue();
        },


           changeAppraisalExercise() {
            const vm = this;

            this.$store.dispatch('get', {url: '/appraisalexercise/all'})
            .then(res => {

                vm.appraisalexerciseItems = res.data.rec;

             //   console.log('1234', vm.appraisalexerciseItems)


                for (let i = 0; i < vm.appraisalexerciseItems.length; i++) {
                    vm.appraisalexerciseList.push({
                        text: vm.appraisalexerciseItems[i].appexercisedesc,
                        value: vm.appraisalexerciseItems[i].id,
                    });
                }
                if (this.formData.appexerciseid) {
                this.changeAppraisalExerciseHandler(this.formData.appexerciseid);
                }
                this.changeRank();
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeAppraisalExerciseHandler(id) {
            var item = this.appraisalexerciseItems.find(item => item.id === id);
            this.appraisalexercisecode = item.appexercisecode;
            this.reviewtype = item.assessmenttypedesc;
            this.formData.reviewfromdate = moment(item.startdate).format("DD-MM-YYYY");
            this.reviewuntildate = moment(item.enddate).format("DD-MM-YYYY");
            this.rankingsetid = item.rankingsetid;

            this.updateValue();
            this.loadStaffDetailsListHandler();
            
          
        },

        getRanking(){
            const vm = this;

             this.$store.dispatch('get', {url: "/rankingperformance/" + this.rankingsetid + "/" +
             this.formData.score + "/" + this.formData.score})
            .then((res) => {

                vm.ranksetItems = res.data.rec;

                this.formData.rankid = res.data.rec[0].id;
                this.formData.rankingdesc = res.data.rec[0].rankingdesc;
                this.formData.rankingcode = res.data.rec[0].rankingcode;

                    
                this.changeRank();
                this.updateValue();
    
            })
    

           
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });

        }, 
        
        
    loadScore(){
            const vm = this;
            
            this.$store.dispatch('get', {url: "/adjustedrankingscore/" + this.formData.id})
            .then((res) => {

            vm.appraisalrecordscore = res.data.rec;
            let getamount = [];
            let adjscore = [];

            for (let i = 0; i < vm.appraisalrecordscore.length; i++) {

            vm.appraisalrecordscore[i].score = parseFloat(vm.appraisalrecordscore[i].score).toFixed(2);

            getamount.push(parseFloat(vm.appraisalrecordscore[i].score.replace(/,/g, "")));
     
            }

            let total = getamount.reduce(function (a, b) {
                return a + b;
            }, 0);

            total = total.toFixed(2);

            for (let j = 0; j < vm.appraisalrecordscore.length; j++) {

            vm.appraisalrecordscore[j].score = parseFloat(vm.appraisalrecordscore[j].score).toFixed(2);

            const date1 = new Date(vm.appraisalrecordscore[j].startdate);
            const date2 = new Date(vm.appraisalrecordscore[j].enddate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


            const date3 = new Date(vm.appraisalrecordscore[j].astartdate);
            const date4 = new Date(vm.appraisalrecordscore[j].aenddate);
            const diffTime2 = Math.abs(date4 - date3);
            const diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24));
       

            let newscore = vm.appraisalrecordscore[j].factor * total * (diffDays/diffDays2)
            console.log('newscore', newscore)

         

            adjscore.push(parseFloat(newscore));

            }   

            let amount = adjscore.reduce(function (a, b) {
                return a + b;
            }, 0);

            amount = amount.toFixed(2);

            console.log('amount', amount)

            this.formData.score = amount;
     
        })
        
        },

         loadCompaniesListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: "/company/all"})
                .then(res => {
                    //req success --> push data in arrays
                    vm.companies = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.companiesitemsList.push({
                            text: res.data.rec[i].compnyname,
                            value: res.data.rec[i].id
                        });
                    }
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //load staffdetails list view
        loadStaffDetailsListHandler() {
            const vm = this;

            this.reviewfromdate = moment(this.formData.reviewfromdate).format("YYYY-MM-DD");
          //  console.log('reviewdate', this.reviewfromdate)
            this.$store.dispatch('get', {url: "/activestaffrecord/" + this.reviewfromdate})
                .then(res => {
                    //req success --> push res into array
                    vm.staffdetails = res.data.rec;
                   // console.log('staffdetails', vm.staffdetails)

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.staffDetailsList.push({
                            text: res.data.rec[i].psndetails,
                            value: res.data.rec[i].id
                        });
                    }
                    
                })
                .catch(err => {
                    //req failure --> emit error
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
                });
        },

        //change staff id and company after selecting psnnid
        changeStaffIDandCompanyHandler(id) {

            let item = this.staffdetails.find(item => item.id === id);

            //set psnid to psnid and staffid to staffid
            this.formData.psnid = item.psnid;
            this.formData.staffid = item.id;
            this.formData.staffcode = item.staffcode;
            this.formData.compnyid = item.compnyid;
            this.formData.compnyname = item.compnyname;

            this.updateValue();
            this.changeRank();
            // this.dayDiff();

        },

          fileToBase64() {
            if (this.fileHolder) {
                var file = this.$refs.attach.internalValue;
                this.formData.doc = this.fileHolder.name;

                var reader = new FileReader();

                reader.onloadend = () => {
                //var b64 = reader.result.replace(/^data:.+base64,/, '');
                var b64 = reader.result;
                this.formData.doc = b64;
                this.updateValue();
                };

                reader.readAsDataURL(file);
            }
        },

         deleteAttachmentHandler() {
            this.formData.doc = "";
            this.formData.doc = "";
        },

           changePMStatus() {
            const vm = this;

            this.$store.dispatch('get', {url: '/pmstatus/all'})
            .then(res => {

                vm.pmstatusItems = res.data.rec;

                for (let i = 0; i < vm.pmstatusItems.length; i++) {
                    vm.pmstatusList.push({
                        text: vm.pmstatusItems[i].statusdesc,
                        value: vm.pmstatusItems[i].id,
                    });
                }
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

         changeRank() {
            const vm = this;

            this.$store.dispatch('get', {url: '/adjustedrankingperformance/' + this.rankingsetid})
            .then(res => {

                vm.rankItems = res.data.rec;

                vm.rankList = [];

                for (let i = 0; i < vm.rankItems.length; i++) {
                    vm.rankList.push({
                        text: vm.rankItems[i].rankingdesc,
                        value: vm.rankItems[i].id,
                    });
                }
                if (this.formData.adjustedrankid) {
                this.changeRankHandler(this.formData.adjustedrankid);
                }

            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        changeRankHandler(id) {
            var item = this.rankItems.find(item => item.id === id);

            this.formData.adjustedrankingcode = item.rankingcode;
            this.formData.adjustedrankingdesc = item.adjustedrankingdesc;
            //this.rankingsetdesc = item.rankingsetdesc;
        },



        updateValue() {
            this.$emit('input', {
                id: this.formData.id,
                appexerciseid : this.formData.appexerciseid,
                psnid: this.formData.psnid,
                staffid: this.formData.staffid,
                staffcode: this.formData.staffcode,
                staffname: this.formData.staffname,
                compnyname: this.formData.compnyname,
                compnyid: this.formData.compnyid,
                doc: this.formData.doc,
                pmstatusid : this.formData.pmstatusid,
                score : this.formData.score,
                adjustedscore: this.formData.adjustedscore,
                rankid : this.formData.rankid,
                rankingcode: this.formData.rankingcode,
                rankingdesc: this.formData.rankingdesc,
                adjustedrankid : this.formData.adjustedrankid,
                adjustedrankingcode : this.formData.adjustedrankingcode,
                adjustedrankingdesc : this.formData.adjustedrankingdesc,
                proposedincrpercent : this.formData.proposedincrpercent,
                proposedincramt : this.formData.proposedincramt,
                confincrpercent : this.formData.confincrpercent,
                confincramt : this.formData.confincrpercent,
                proposedbnsfact : this.formData.proposedbnsfact,
                proposedbnsamt: this.formData.proposedbnsamt,
                confbnsfact: this.formData.confbnsfact,
                confbnsamt: this.formData.confbnsamt,
                isincrementpost: this.formData.isincrementpost,
                isbonuspost: this.formData.isbonuspost,
                reviewfromdate: this.formData.reviewfromdate,
                appraisalrecorddetail: this.formData.appraisalrecorddetail,
                search: this.formData.search,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
          //  console.log('reviewfromdate', this.formData.reviewfromdate)
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'appraisalrecordlist', params: { search: this.search}});
        },
    },

    mounted() {

        this.changeAppraisalExercise();
       // this.loadStaffDetailsListHandler();
        this.loadCompaniesListHandler();
        this.changePMStatus();

        if(this.parentObject.isEdit == true && (this.formData.score == null || this.formData.score == '0')) {
            this.loadScore();
        }
      //  this.changeRank();
       // this.getRanking();

    }
    
}
</script>