<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.pageTitle }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-row>
                        <v-col cols="3" sm="3" class="pb-n5">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.year')"
                                v-model="formData.salexpyear"
                                dense
                                :items="yearList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="3" sm="3" class="pb-n5">
                            <v-autocomplete
                                clearable
                                outlined
                                :label="this.$t('reportsparam.month')"
                                v-model="formData.salexpmonth"
                                dense
                                :items="monthList"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="1" sm="1" class="pb-n5 mt-1">
                            <v-btn icon color="orange">
                                <v-icon @click="loadPayoutList()">mdi-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" sm="6" class="blue--text">
                            {{$t('statutoryexportcommonused.payouts')}} 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-data-table
                            v-model="selected"
                            @input="changePayoutHandler()"
                            :headers="dataHeader"
                            :items="this.dataItems"
                            :options="tableOpt"
                            hide-default-footer
                            :single-select="singleSelect"
                            item-key="payoutcode"
                            show-select
                        >
                        </v-data-table>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-6">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.glmappingpolicy')"
                                type="text"
                                v-model="formData.payglmappolicyid"
                                :items="payGLCodeMappingPolicyList"
                                :rules="[rules.required]"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                            <v-autocomplete
                                outlined
                                :label="this.$t('glcode.glexportfmt')"
                                type="text"
                                v-model="formData.glexpformatid"
                                :items="exportFmtList"
                                :rules="[rules.required]"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
                
                <v-row>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px; 
                            margin-bottom: 20px;"
                            @click="backHandler">
                            {{$t('button.back')}}
                        </v-btn>
                    </div>
                    <div class="pt-5 pl-4">
                        <v-btn 
                            color="warning" 
                            style="width: 100px;
                            margin-bottom: 20px;" 
                            @click="nextHandler"> 
                            {{$t('button.next')}}
                        </v-btn>
                    </div>
                </v-row>
            </v-form>
        </v-container>
        <div v-if="isErr">
            <app-error 
                v-bind:parentError = "{
                    data: this.errMsg,
                    isErr: this.isErr
                }"
                v-on:closeError="isErr = $event"
            />
        </div>
    </div>
</template>

<script>

import ErrorDialog from '../../../components/common/ErrorDialog';

export default {
    components: {
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            pageTitle: this.$t('glcode.glexport'),
            dataHeader: [
                { text: this.$t('statutoryexportcommonused.payoutid'),            value: 'payoutcode',    width: '150px', sortable: true},
                { text: this.$t('statutoryexportcommonused.payoutdescription'),   value: 'payoutdesc',    width: '300px', sortable: true},
                { text: this.$t('payrollprocessrun.payoutstatus'),        value: 'pystatusdesc',  width: '300px', sortable: true}
            ],
            formData: {
                salexprecid: null,
                bank: null,
                year: null,
                month: null,
                saleexportrecorddetail: [],
                glexpformatid: null,
                salexpreccode: null,
                salexprecdesc: null,
                salexpyear: null,
                salexpmonth: null,
                stamp: null,
                userid: null
            },

            parameters: {
                brchid: this.$route.params.brchid,
                compnyid: this.$route.params.compnyid,
                deptid: this.$route.params.deptid,
                emptypeid: this.$route.params.emptypeid,
                gradeid: this.$route.params.gradeid,
                staffid: this.$route.params.staffid,
                divnid: this.$route.params.divnid,
                sectioid: this.$route.params.sectioid,
                unitid: this.$route.params.unitid,
                jobid: this.$route.params.jobid,
                positionid: this.$route.params.positionid,
                pomth: null,
                poyr: null,
            },

            tableOpt: {
                itemsPerPage: -1
            },

            selected: [],
            data2: [],
            items: [],
            dataItems: [],
            salaryExpRecList: [],
            payoutItems: [],
            exportformatItems: [],
            exportFmtList: [],
            monthList: [],
            yearList: [],
            payGLCodeMappingPolicyList: [],
            payGLCodeMappingPolicyItems: [],

            singleSelect: false,
            noPayout: false,
            isErr: false,
            finalizeStatus: false,

            errMsg: ''
        }
    },

    methods: {

        loadGLMappingPolicy() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/payglcodemappingpolicylist/all'
            })
            .then(res => {
                vm.payGLCodeMappingPolicyItems = res.data.rec;

                for (let i = 0; i < vm.payGLCodeMappingPolicyItems.length; i++) {
                    vm.payGLCodeMappingPolicyList.push({
                        text: vm.payGLCodeMappingPolicyItems[i].policydesc,
                        code: vm.payGLCodeMappingPolicyItems[i].policycode,
                        value: vm.payGLCodeMappingPolicyItems[i].id,
                    });
                }

                if (this.formData.payglmappolicyid) {
                    this.changeGLCodePolicyHandler(this.formData.payglmappolicyid);
                }
                
            })
            .catch(err => {
                vm.errMsg = err;
            });
        },

        loadPayout() {
            const vm = this;

            this.$store.dispatch('get', {url: '/payoutctrl/all'})
                .then(res => {
                    vm.payoutList = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.yearList.push(
                            {
                                text: res.data.rec[i].pyyear,
                                value: res.data.rec[i].pyyear
                            }
                        )        
                        
                        vm.monthList.push(
                            {
                                text: res.data.rec[i].pymonth,
                                value: res.data.rec[i].pymonth
                            }
                        )

                        vm.monthList.sort((a, b) => a.value - b.value)
                    }
                    this.loadPayoutEdit();

                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

        loadExportFormatList() {
            const vm = this;

            this.$store.dispatch('get', {url: '/exportformat/all'})
                .then(function (res) {
                    vm.exportformatItems = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        if (res.data.rec[i].exptypeid == 9) {
                            vm.exportFmtList.push(
                                {
                                    text: res.data.rec[i].expdesc,
                                    value: res.data.rec[i].id
                                }
                            )
                        }
                    } 
                })
                .catch(function (err) {
                    vm.errMsg = err;
                });
        },

        nextHandler() {
            let item = this.formData
            let id = this.formData.glexpformatid
            let parameter = this.parameters

            if (!this.$refs.form.validate()) {
                this.isErr =  true;
                this.errMsg = this.$t('vdatatable.someinfoismissing')
            } else if (this.noPayout) {
                this.isErr =  true;
                this.errMsg = this.$t('vdatatable.payoutismissing')
            } else {
               if (this.finalizeStatus == false) {
                    this.isErr =  true;
                    this.errMsg = this.$t('vdatatable.somepayoutnotfinalize')
                } else {
                    this.$router.push({name: 'glexportparam', params: { id, item, parameter }});
                }
            }
        },

        backHandler() {
            this.$router.push({ name: "glexportparameter" });
        },

        changePayoutHandler() {
            this.finalizeStatus = true
            this.formData.saleexportrecorddetail = this.selected
            for (let i = 0; i < this.formData.saleexportrecorddetail.length; i++) {
                if (this.formData.saleexportrecorddetail[i].pystatuscode != '99') {
                    this.finalizeStatus = false
                }
            }
        },

        loadPayoutList() {
            let payoutYear = [];
            if (!this.formData.salexpyear && !this.formData.salexpmonth) {
                this.dataItems = this.payoutList
            } else if (this.formData.salexpyear) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    if (this.payoutList[i].pyyear === this.formData.salexpyear) {
                        payoutYear.push(this.payoutList[i])
                    }
                }
                if (this.formData.salexpmonth) {
                    this.dataItems = []
                    for (let j = 0; j < payoutYear.length; j++) {
                        if (payoutYear[j].pymonth === this.formData.salexpmonth) {
                            this.dataItems.push(payoutYear[j])
                        }
                    }
                }
            }
        },

        loadPayoutEdit() {
            let getItem = []
            if (this.isEdit) {
                for (let i = 0; i < this.payoutList.length; i++) {
                    for (let j = 0; j < this.formData.saleexportrecorddetail.length; j++) {
                        if (this.payoutList[i].id === this.formData.saleexportrecorddetail[j].payoutctrlid) {
                            getItem[j] = this.payoutList[i]
                            getItem[j].exId = this.formData.saleexportrecorddetail[j].id
                        }
                    }
                }
                this.loadPayoutList()
                this.selected = getItem
                this.formData.saleexportrecorddetail = []
            }
        },

    },

    mounted() {
        this.loadPayout();
        this.loadGLMappingPolicy();
        this.loadExportFormatList();
    }
}
</script>