<template>
    <div>
        <v-container>
            <v-row>
                <h2 class="pb-5">{{ this.pageTitle }}</h2>
            </v-row>
            <v-row>
                <div class="pt-5 mt-n8">
                    <v-row>
                        <v-col>
                            <v-btn 
                                color="warning" 
                                style="width: 100px"
                                @click="backHandler" 
                                > 
                                {{$t('button.back')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-row>
            
            <v-container fluid id="scroll-target" class="custom-form overflow-y-auto px-5">
                <v-row justify="center" align="center">
                    <v-col cols="6">
                        <v-card v-if="hide" elevation="5" outlined> 
                            <apexchart ref="piechart" type="pie" width="100%" :options="piechartOptions" :series="pieseries"></apexchart>
                        </v-card>
                    </v-col>
                </v-row> 
                <v-tabs>
                    <v-tab>{{$t('columns.assigned')}}</v-tab>
                    <v-tab>{{$t('reportpositionassigmentanalysis.vacant')}}</v-tab>

                    <!-- tab items for assigned -->
                    <v-tab-item>
                        <div class="row">
                            <div class="col-sm-12">
                                <v-toolbar>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-avatar class="mr-5" size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_pdf.png`"
                                                @click="printPDF"
                                                style="height: 40px; float: right;"
                                            >
                                        </v-avatar>

                                        <v-avatar size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_csv.png`"
                                                @click="getCSV"
                                                style="height: 40px; float: right;"
                                            >
                                        </v-avatar>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            style="width: 250px"
                                            class="pr-5 pt-3"
                                            hide-details>
                                        </v-text-field>
                                    </v-toolbar-items>
                                </v-toolbar>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 mt-n8">
                                <v-data-table
                                    :headers="headers"
                                    :items="positionassignmentdetailsassigned"
                                    :search="search"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                >
                                    <template slot="items" slot-scope="props">
                                        <td class="text-xs-left">{{ props.item.poscode }}</td>
                                        <td class="text-xs-left">{{ props.item.posdesc }}</td>
                                        <td class="text-xs-left">{{ props.item.repposcode }}</td>
                                        <td class="text-xs-left">{{ props.item.repposdesc }}</td>
                                        <td class="text-xs-left">{{ props.item.staffcode }}</td>
                                        <td class="text-xs-center">{{ props.item.psnname }}</td>
                                        <td class="text-xs-center">{{ props.item.repposstaffcode }}</td>
                                        <td class="text-xs-center">{{ props.item.reppospsnname }}</td>
                                        <td class="text-xs-center">{{ props.item.compnycode }}</td>
                                        <td class="text-xs-center">{{ props.item.brchcode }}</td>
                                        <td class="text-xs-center">{{ props.item.divncode }}</td>
                                        <td class="text-xs-left">{{ props.item.deptcode }}</td>
                                        <td class="text-xs-left">{{ props.item.sectiocode }}</td>
                                        <td class="text-xs-center">{{ props.item.unitcode }}</td>
                                        <td class="text-xs-left">{{ props.item.grdcode }}</td>
                                        <td class="text-xs-left">{{ props.item.jobcode }}</td>
                                    </template>
                                    <template slot="pageText" slot-scope="props"> 
                                        {{$t('vdatatable.rowsperpage')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('columns.of')}} {{ props.itemsLength }}
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-tab-item>
                    <!-- tab items for vacant -->
                    <v-tab-item>
                        <div class="row">
                            <div class="col-sm-12">
                                <v-toolbar>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-avatar class="mr-5" size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_pdf.png`"
                                                @click="printPDF2"
                                                style="height: 50px; float: right;"
                                            >
                                        </v-avatar>

                                        <v-avatar size="40" tile>
                                            <img
                                                :src="`${publicPath}images/export_csv.png`"
                                                @click="getCSV2"
                                                style="height: 50px; float: right;"
                                            >
                                        </v-avatar>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            style="width: 250px"
                                            class="pr-5 pt-3"
                                            hide-details>
                                        </v-text-field>
                                    </v-toolbar-items>
                                </v-toolbar>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 mt-n8">
                                <v-data-table
                                    :headers="headers2"
                                    :items="positionassignmentdetailsvacant"
                                    :search="search"
                                    class="elavation-1 fixed header"
                                    fixed-header
                                    style="margin-top: 20px"
                                >
                                    <template slot="items" slot-scope="props">
                                        <td class="text-xs-left">{{ props.item.poscode }}</td>
                                        <td class="text-xs-left">{{ props.item.posdesc }}</td>
                                        <td class="text-xs-left">{{ props.item.repposdesc }}</td>
                                    </template>
                                    <template slot="pageText" slot-scope="props">
                                        {{$t('vdatatable.rowsperpage')}} {{ props.pageStart }} - {{ props.pageStop }} {{$t('columns.of')}} {{ props.itemsLength }}
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </v-container>
        </v-container>    
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    components: {
        'apexchart': VueApexCharts,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            positionassignmentdetailsassigned: [],
            positionassignmentdetailsvacant: [],
            pageTitle: this.$t('reportpositionassigmentanalysis.positionassigmentanalysisasat') + this.$route.params.asatdate, 
            pageTitleValue: "",
            search: '',

            //for pie chart - position assignment
            pieseries: [],
            piechartOptions: {
                    chart: {
                        locales: [require(`../../../locales/${this.$route.params.lang}.json`)],
                        defaultLocale: this.$route.params.lang,  
                        type: 'pie',
                        toolbar:{
                             show: true,
                             offsetX: 0,
                             offsetY: 0,

                             tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                                },

                                export : {
                                    csv: {
                                        filename: this.$t('reportheadcountanalysis.overallheadcountdistributionbygender'),
                                        columnDelimiter: ',',
                                        headerCategory: 'category',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                        return new Date(timestamp).toDateString()
                                        }
                                    },

                                    svg: {
                                        filename: this.$t('reportpositionassigmentanalysis.positionassigment'),
                                    },

                                    png: {
                                         filename: this.$t('reportpositionassigmentanalysis.positionassigment'),
                                    }
                                },

                                autoSelected: 'zoom' 
                        }
                    },
                    title: {
                        text: this.$t('reportpositionassigmentanalysis.positionassigment'),
                        margin: 20,
                        align: 'center',
                        style: {
                            fontsize: '14px',
                            color: ' #004080',
                            fontfamily: 'Lato-Bold'
                        },
                    },
                    plotOptions: {
                        customScale: 1.5
                    },
                    labels: [this.$t('columns.assigned'), this.$t('reportpositionassigmentanalysis.vacant')],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            },
                        }
                    }],
                },

            //table header for assigned
            headers: [
                    { text: this.$t('statutoryexportcommonused.no'), value: 'no', width: '100px' },
                    { text: this.$t('reportpositionassigmentanalysis.positioncode'), value: 'poscode', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.positiondesc'), value: 'posdesc', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.reportingpositioncode'), value: 'repposcode', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.reportingpositiondesc'), value: 'repposdesc', width: '250px' },
                    { text: this.$t('columns.staffcode'), value: 'staffcode', width: '200px' },
                    { text: this.$t('columns.name'), value: 'psnname', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.reportingpositionstaffcode'), value: 'repposstaffcode', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.reportingpositionstaffname'), value: 'reppospsnname', width: '200px' },
                    { text: this.$t('columns.company'), value: 'compnycode', width: '200px' },
                    { text: this.$t('columns.branch'), value: 'brchcode', width: '200px' },
                    { text: this.$t('columns.division'), value: 'divncode', width: '200px' },
                    { text: this.$t('columns.department'), value: 'deptcode', width: '200px' },
                    { text: this.$t('columns.section'), value: 'sectiocode', width: '200px' },
                    { text: this.$t('columns.unit'), value: 'unitcode', width: '200px' },
                    { text: this.$t('columns.grade'), value: 'grdcode', width: '200px' },
                    { text: this.$t('columns.job'), value: 'jobcode', width: '200px' },
            ],

            //table header for vacant
            headers2: [
                    { text: this.$t('statutoryexportcommonused.no'), value: 'no', width: '100px' },
                    { text: this.$t('reportpositionassigmentanalysis.positioncode'), value: 'poscode', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.positiondesc'), value: 'posdesc', width: '200px' },
                    { text: this.$t('reportpositionassigmentanalysis.reportingpositioncode'), value: 'repposdesc', width: '250px' },
            ],

            //load error message variable
            isErr: false,
            loading: true,

            //hide or show charts & tables variables
            hide : true,
            hidetable: true,

            //title for print to pdf 
            title: this.$t('reportpositionassigmentanalysis.positionassigmentanalysisasat') + this.asatdate,

        }
    },

    methods: {

        // get assigned details

        getPositionAssignmentDetailsAssigned() {
            const vm = this;

            //pass asatdate as params 
            this.$store.dispatch('get', {url: "/positionassignmentanalysis/assigned/" + vm.asatdate})
            .then(res => {

                vm.positionassignmentdetailsassigned = res.data.rec;

                // load numbering for table
                for (let i = 0; i < vm.positionassignmentdetailsassigned.length; i++) {
                    vm.positionassignmentdetailsassigned[i].no = i + 1;
                }

                // add length of array of assigned records as input
                vm.pieseries.push(vm.positionassignmentdetailsassigned.length);

                // array to get data for vacant must run after data for assigned is retrieved, otherwise the data may be pushed to the pie chart in the wrong order
                this.getPositionAssignmentDetailsVacant();

                //show table 
                vm.hidetable = false;
                vm.loading = false; 
            
            })
            .catch(err => {
                //error - unsuccessful request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

        // get vacant details

        getPositionAssignmentDetailsVacant() {
            const vm = this;

            //pass asatdate as params 
            this.$store.dispatch('get', {url: "/positionassignmentanalysis/vacant/" + vm.asatdate})
            .then(res => {
                vm.positionassignmentdetailsvacant = res.data.rec;

                // load numbering for table
                for (let i = 0; i < vm.positionassignmentdetailsvacant.length; i++) {
                    vm.positionassignmentdetailsvacant[i].no = i + 1;
                }
                
                // add length of array of vacant records as input
                vm.pieseries.push(vm.positionassignmentdetailsvacant.length);

                //show table 
                vm.hidetable = false;
                vm.loading = false; 
            
            })
            .catch(err => {
                //error - unsuccessful request
                this.isErr = true;
                this.errMsg = err;
                this.$emit("error", { isErr: this.isErr, errMsg: this.errMsg });
            });
        },

        // functions for print to pdf and export to csv
        objectToCSV(data) {
            const csvRows = []

            // get header
            const headers = Object.keys(data[0])
            csvRows.push(headers.join(','))

            // loop over the rows
            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"')
                    return `"${escaped}"`
                })
                csvRows.push(values.join(','))
            }
            return csvRows.join('\n')
        },

        download(data) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: 'text/csv'})
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.setAttribute('hidden', '')
            a.setAttribute('href', url)
            a.setAttribute('download', this.title + '.csv')

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        },

        // get CSV for assigned

        async getCSV() {
            let item = this.positionassignmentdetailsassigned;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [this.$t('statutoryexportcommonused.no')]: item[i].no,
                    [this.$t('reportpositionassigmentanalysis.positioncode')]: item[i].poscode,
                    [this.$t('reportpositionassigmentanalysis.positiondesc')]: item[i].posdesc,
                    [this.$t('reportpositionassigmentanalysis.reportingpositioncode')]: item[i].repposcode,
                    [this.$t('reportpositionassigmentanalysis.reportingpositiondesc')]: item[i].repposdesc,
                    [this.$t('columns.staffcode')]: item[i].staffcode,
                    [this.$t('columns.name')]: item[i].psnname,
                    [this.$t('reportpositionassigmentanalysis.reportingpositionstaffcode')]: item[i].repposstaffcode,
                    [this.$t('reportpositionassigmentanalysis.reportingpositionstaffname')]: item[i].reppospsnname,
                    [this.$t('columns.company')]: item[i].compnycode,
                    [this.$t('columns.branch')]: item[i].brchcode,
                    [this.$t('columns.division')]: item[i].divncode,
                    [this.$t('columns.department')]: item[i].deptcode,
                    [this.$t('columns.section')]: item[i].sectiocode,
                    [this.$t('columns.unit')]: item[i].unitcode,
                    [this.$t('columns.grade')]: item[i].grdcode,
                    [this.$t('columns.job')]: item[i].jobcode
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // get CSV for vacant

        async getCSV2() {
            let item = this.positionassignmentdetailsvacant;
            let data = []

            for (var i = 0; i < item.length; i++) {
                
                data.push({
                    [this.$t('statutoryexportcommonused.no')]: item[i].no,
                    [this.$t('reportpositionassigmentanalysis.positioncode')]: item[i].poscode,
                    [this.$t('reportpositionassigmentanalysis.positiondesc')]: item[i].posdesc,
                    [this.$t('reportpositionassigmentanalysis.reportingpositiondesc')]: item[i].repposdesc,
                })
            }

            const csvData = this.objectToCSV(data)
            this.download(csvData)
        },

        // print PDF for assigned

        printPDF() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.positionassignmentdetailsassigned

            var head = [  
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },              
                { title: this.$t('reportpositionassigmentanalysis.positioncode'), dataKey: 'poscode' },
                { title: this.$t('reportpositionassigmentanalysis.positiondesc'), dataKey: 'posdesc' },
                { title: this.$t('reportpositionassigmentanalysis.reportingpositioncode'), dataKey: 'repposcode' },
                { title: this.$t('reportpositionassigmentanalysis.reportingpositiondesc'), dataKey: 'repposdesc' },
                { title: this.$t('columns.staffcode'), dataKey: 'staffcode' },
                { title: this.$t('columns.name'), dataKey: 'psnname' },
                { title: this.$t('reportpositionassigmentanalysis.reportingpositionstaffcode'), dataKey: 'repposstaffcode' },
                { title: this.$t('reportpositionassigmentanalysis.reportingpositionstaffname'), dataKey: 'reppospsnname' },
                { title: this.$t('columns.company'), dataKey: 'compnycode' },
                { title: this.$t('columns.branch'), dataKey: 'brchcode' },
                { title: this.$t('columns.division'), dataKey: 'divncode' },
                { title: this.$t('columns.department'), dataKey: 'deptcode' },
                { title: this.$t('columns.section'), dataKey: 'sectiocode' },
                { title: this.$t('columns.unit'), dataKey: 'unitcode' },
                { title: this.$t('columns.grade'), dataKey: 'grdcode' },
                { title: this.$t('columns.job'), dataKey: 'jobcode' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    no: item[i].no,
                    poscode: item[i].poscode,
                    posdesc: item[i].posdesc,
                    repposcode: item[i].repposcode,
                    repposdesc: item[i].repposdesc,
                    staffcode: item[i].staffcode,
                    psnname: item[i].psnname,
                    repposstaffcode: item[i].repposstaffcode,
                    reppospsnname: item[i].reppospsnname,
                    compnycode: item[i].compnycode,
                    brchcode: item[i].brchcode,
                    divncode: item[i].divncode,
                    deptcode: item[i].deptcode,
                    sectiocode: item[i].sectiocode,
                    unitcode: item[i].unitcode,
                    grdcode: item[i].grdcode,
                    jobcode: item[i].jobcode
                })
            }

            this.title = this.$t('reportpositionassigmentanalysis.positionassigmentanalysisasat') + this.asatdate;
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        // print PDF for vacant

        printPDF2() {
            const doc = new jsPDF({
                orientation: 'landscape'
            })
            let item = this.positionassignmentdetailsvacant

            var head = [     
                { title: this.$t('statutoryexportcommonused.no'), dataKey: 'no' },           
                { title: this.$t('reportpositionassigmentanalysis.positioncode'), dataKey: 'poscode' },
                { title: this.$t('reportpositionassigmentanalysis.positiondesc'), dataKey: 'posdesc' },
                { title: this.$t('reportpositionassigmentanalysis.reportingpositiondesc'), dataKey: 'repposdesc' },
            ]

            let body = []

            for (var i = 0; i < item.length; i++) {
                body.push({
                    no: item[i].no,
                    poscode: item[i].poscode,
                    posdesc: item[i].posdesc,
                    repposdesc: item[i].repposdesc,
                })
            }

            this.title = this.$t('reportpositionassigmentanalysis.positionassigmentanalysisasat') + this.asatdate;
            doc.text(this.title, 10, 10) 
            doc.autoTable(head, body)
            doc.save(this.title + '.pdf')
        }, 

        //back handler - go back to PositionAssignmentAnalysisParameter.vue
        backHandler(){
            this.$router.push({name: 'positionassignmentanalysisparameter'});
        }
    },

    created() {
        const vm = this;
        vm.asatdate = this.$route.params.asatdate;
    },   

    mounted() {
        this.getPositionAssignmentDetailsAssigned();
    }
}
</script>

<style scoped>
.textBlue {
    color:  #004080;
}
</style>