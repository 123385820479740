<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import PayRateForm from "../../../components/payroll/payrate/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": PayRateForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setuppayrate.createnewpayrate'),
      pageTitleValue: "",
      formData: {
        payitemid: null,
        payitemtypeid: null,
        startdate: null,
        enddate: null,
        remark: null,
        payratedetail: [],
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      this.$store.dispatch('post', {url: "/payrate/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "payratelist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "payratelist" });
    },
  },
};
</script>