    <template>
  <div>
    <v-dialog v-model="grouppaymentdialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark class="mb-2" v-on="on">+</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('setupgrouppayment.grouppaymentitem')}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.company')"
                  type="text"
                  dense
                  v-model="selections[0].compnyid"
                  :items="companyList"
                  @change="changeCompanyHandler(selections[0].compnyid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.branch')"
                  type="text"
                  dense
                  v-model="selections[0].brchid"
                  :items="branchList"
                  @change="changeBranchHandler(selections[0].brchid)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.division')"
                  type="text"
                  dense
                  v-model="selections[0].divnid"
                  :items="divisionList"
                  @change="changeDivisionHandler(selections[0].divnid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.department')"
                  type="text"
                  dense
                  v-model="selections[0].deptid"
                  :items="departmentList"
                  @change="changeDepartmentHandler(selections[0].deptid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.section')"
                  type="text"
                  dense
                  v-model="selections[0].sectioid"
                  :items="sectionList"
                  @change="changeSectionHandler(selections[0].sectioid)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.unit')"
                  type="text"
                  dense
                  v-model="selections[0].unitid"
                  :items="unitList"
                  @change="changeUnitHandler(selections[0].unitid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.employmenttype')"
                  type="text"
                  dense
                  v-model="selections[0].emptypeid"
                  :items="emptypeList"
                  @change="changeEmploymentTypeHandler(selections[0].emptypeid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.grade')"
                  type="text"
                  dense
                  v-model="selections[0].gradeid"
                  :items="gradeList"
                  @change="changeGradeHandler(selections[0].gradeid)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.job')"
                  type="text"
                  dense
                  v-model="selections[0].jobid"
                  :items="jobList"
                  @change="changeJobHandler(selections[0].jobid)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-autocomplete
                  outlined
                  :label="this.$t('columns.position')"
                  type="text"
                  dense
                  v-model="selections[0].positionid"
                  :items="positionList"
                  @change="changePositionHandler(selections[0].positionid)"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('setupepf.serviceperiod')"
                  type="text"
                  dense
                  v-model="selections[0].serviceperiod"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-n8">
                <v-text-field
                  outlined
                  :label="this.$t('setuppersonpayrolldirectassignment.amount')"
                  type="number"
                  dense
                  v-model="selections[0].amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="isErr" class="mt-n12">
          <span style="font-weight: bold; color: red">{{ errMsg }}</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeGroupPayment"
            >{{$t('button.cancel')}}</v-btn
          >
          <v-btn color="green darken-1" text @click="saveGroupPayment"
            >{{$t('button.save')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="this.parentObject.tabHeader"
      :items="detailItem"
      class="elavation-1"
      fixed-header
      :sort-by="['code']"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="modifyItemHandler(item)">
          mdi-pen
        </v-icon>
        <v-icon small class="ml-3 mr-2" @click="deleteItemHandler(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import compareArray from "../../../util/comparearray";

export default {
  props: {
    parentObject: Object,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || "Value is 1-100",
      },

      selections: [
        {
          id: null,
          grouppaymentid: null,
          compnyid: null,
          compnyname: null,
          compnycode: null,
          brchid: null,
          brchname: null,
          brchcode: null,
          divnid: null,
          divnname: null,
          divncode: null,
          deptid: null,
          deptname: null,
          deptcode: null,
          sectioid: null,
          sectioname: null,
          sectiocode: null,
          unitid: null,
          unitname: null,
          unitcode: null,
          emptypeid: null,
          emptypecode: null,
          emptypedesc: null,
          gradeid: null,
          gradedesc: null,
          grdcode: null,
          jobid: null,
          jobdesc: null,
          jobcode: null,
          positionid: null,
          positiondesc: null,
          positioncode: null,
          serviceperiod: null,
          amount: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ],

      itemType: null,
      isPayeeInput: false,
      grouppaymentdialog: false,
      detailItem: this.parentObject.detailItem,

      positionList: [],
      companyList: [],
      branchList: [],
      divisionList: [],
      departmentList: [],
      sectionList: [],
      unitList: [],
      jobList: [],
      gradeList: [],
      emptypeList: [],
      modify: false,

      isExisting: false,
      isErr: false,
      errMsg: "",
    };
  },

  methods: {
    compareArray,

    loadPositions() {
      const vm = this;

      this.$store.dispatch('get', {
        url: "/position/all"})
        .then((res) => {
          vm.positions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.positionList.push({
              text: res.data.rec[i].posdesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.selections[0].positionid) {
             vm.changePositionHandler(vm.selections[0].positionid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadCompanies() {
      const vm = this;
      this.$store.dispatch('get', {
          // this.$store.getters.apiURL + '/company/all'
          url: `/company/allfilter/${this.$store.getters.userId}`
        })

        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.companyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }
         
         if (vm.selections[0].compnyid) {
             vm.changeCompanyHandler(vm.selections[0].compnycode);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadBranches() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/branch/all"})
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].id,
            });
          }
          if (vm.selections[0].brchid) {
             vm.changeBranchHandler(vm.selections[0].brchid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadDivisions() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/division/all"})
        .then((res) => {
          vm.divisions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
          }
          if (vm.selections[0].divnid) {
             vm.changeDivisionHandler(vm.selections[0].divnid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadDepartments() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/department/all"})
        .then((res) => {
          vm.departments = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.departmentList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].id,
            });
          }
          if (vm.selections[0].deptid) {
             vm.changeDepartmentHandler(vm.selections[0].deptid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadSections() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/section/all"})
        .then((res) => {
          vm.sections = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.selections[0].sectioid) {
             vm.changeSectionHandler(vm.selections[0].sectioid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadUnits() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/unit/all"})
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
          }
          if (vm.selections[0].unitid) {
             vm.changeUnitHandler(vm.selections[0].unitid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadJobs() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/job/all"})
        .then((res) => {
          vm.jobs = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.jobList.push({
              text: res.data.rec[i].jobtitle,
              value: res.data.rec[i].id,
            });
          }

          if (vm.selections[0].jobid) {
             vm.changeJobHandler(vm.selections[0].jobid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadGrades() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/grade/all"})
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.selections[0].gradeid) {
             vm.changeGradeHandler(vm.selections[0].gradeid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadEmpTypes() {
      const vm = this;
      this.$store.dispatch('get', {
        url: "/emptype/all"})
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptype,
              value: res.data.rec[i].id,
            });
          }

          if (vm.selections[0].emptypeid) {
             vm.changeEmploymentTypeHandler(vm.selections[0].emptypeid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadDropDowns() {
      this.loadPositions();
      this.loadCompanies();
      this.loadBranches();
      this.loadDivisions();
      this.loadDepartments();
      this.loadSections();
      this.loadUnits();
      this.loadJobs();
      this.loadGrades();
      this.loadEmpTypes();
    },

    changePositionHandler(id) {
      let item = this.positions.find((item) => item.id === id);
      this.selections[0].positiondesc = item.posdesc;
      this.selections[0].positioncode = item.poscode;
    },

    changeCompanyHandler(id) {
      let item = this.companies.find((item) => item.id === id);
      this.selections[0].compnyname = item.compnyname;
      this.selections[0].compnycode = item.compnycode;
    },

    changeBranchHandler(id) {
      let item = this.branches.find((item) => item.id === id);
      this.selections[0].brchname = item.brchname;
      this.selections[0].brchcode = item.brchcode;
    },

    changeDivisionHandler(id) {
      let item = this.divisions.find((item) => item.id === id);
      this.selections[0].divnname = item.divnname;
      this.selections[0].divncode = item.divncode;
    },

    changeDepartmentHandler(id) {
      let item = this.departments.find((item) => item.id === id);
      this.selections[0].deptname = item.deptname;
      this.selections[0].deptcode = item.deptcode
    },

    changeSectionHandler(id) {
      let item = this.sections.find((item) => item.id === id);
      this.selections[0].sectioname = item.sectioname;
      this.selections[0].sectiocode = item.sectiocode;
    },

    changeUnitHandler(id) {
      let item = this.units.find((item) => item.id === id);
      this.selections[0].unitname = item.unitname;
      this.selections[0].unitcode = item.unitcode;
    },

    changeJobHandler(id) {
      let item = this.jobs.find((item) => item.id === id);
      this.selections[0].jobdesc = item.jobtitle;
      this.selections[0].jobcode = item.jobcode;
    },

    changeGradeHandler(id) {
      let item = this.grades.find((item) => item.id === id);
      this.selections[0].gradedesc = item.grddesc;
      this.selections[0].grdcode = item.grdcode;
    },

    changeEmploymentTypeHandler(id) {
      let item = this.emptypes.find((item) => item.id === id);
      this.selections[0].emptypedesc = item.emptypedesc;
      this.selections[0].emptypecode = item.emptype;
    },

    modifyItemHandler(item) {
      // assign loaded data to selections array

      if (item.action == 1) {
        this.modify = true;
      }
      if (item.action == 2) {
        this.isExisting = true;
      }

      this.selections[0] = item;

      this.grouppaymentdialog = true;
    },

    deleteItemHandler(item) {
      let itemIndex = this.detailItem.indexOf(item);

      this.detailItem.splice(itemIndex, 1);
    },

    closeGroupPayment() {
      this.grouppaymentdialog = false;
      this.isErr = false;

      // reset the form fields
      this.resetSelections();
    },
    // Saving payeeinput details into payeeinputd
    saveGroupPayment() {

      if (this.selections[0].amount == null){
            this.$dialog.alert(this.$t('fillinamountcolumn')).then((dialog) => {
            console.log(dialog);
          });
      } else {
          
      this.isErr = false;
     
      if (!this.isExisting && this.modify) {
        this.resetSelections();
      } else if (!this.isExisting) {
        this.selections[0].id = null;
        this.selections[0].action = 1;

        this.detailItem.push(Object.assign({}, this.selections[0]));
      } else {
        if (this.compareArray(this.detailItem, this.selections[0])) {
          console.log(this.compareArray(this.detailItem, this.selections[0]));
        }
      }

      this.$emit("input", this.detailItem);

      this.modify = false;
      this.grouppaymentdialog = false;

      // reset the form fields
      this.resetSelections();
      }
    },

    resetSelections() {
      this.selections = [
        {
         id: null,
          grouppaymentid: null,
          compnyid: null,
          compnyname: null,
          compnycode: null,
          brchid: null,
          brchname: null,
          brchcode: null,
          divnid: null,
          divnname: null,
          divncode: null,
          deptid: null,
          deptname: null,
          deptcode: null,
          sectioid: null,
          sectioname: null,
          sectiocode: null,
          unitid: null,
          unitname: null,
          unitcode: null,
          emptypeid: null,
          emptypecode: null,
          emptypedesc: null,
          gradeid: null,
          gradedesc: null,
          grdcode: null,
          jobid: null,
          jobdesc: null,
          jobcode: null,
          positionid: null,
          positiondesc: null,
          positioncode: null,
          serviceperiod: null,
          amount: null,
          rectimestamp: null,
          updatedby: null,
          action: 0,
        },
      ];
    },
  },

  created() {
    this.loadDropDowns();
  },
};
</script>