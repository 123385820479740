<template>
  <div>
    <app-input-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        errMsg: this.errMsg,
        isEdit: this.editMode,
        isDisabled: this.isDisabled,
      }"
      v-on:new="createHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>


<script>
import MappingForm from "../../../components/settings/mapping/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    "app-input-form": MappingForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
      },
      pageTitle: this.$t('setupmapping.editgrpmappinginstruction'),
       search: this.$route.params.search,
      errMsg: "",
      isErr: false,
      id: this.$route.params.id,
      formData: {
        gtypeid: this.$route.params.id,
        detailItem: [],
        userid: this.$store.getters.userId,
        stamp: null,
      },
      stamp: null,
      isConfirm: false,
      editMode: true,
      isDisabled: true,
    };
  },
  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },
    loadMappingHandler() {
      const vm = this;
      this.$store.dispatch('get', {url: "/mapping/getmapping/" + vm.id})
        .then((res) => {
          console.log(res);
          if (res.data.rec.length > 0) {
            this.stamp = res.data.rec[0].stamp;
          }
          for (let i = 0; i < res.data.rec.length; i++) {
            vm.formData.detailItem.push({
              gtypeid: res.data.rec[i].gtypeid,
              valvalueid: res.data.rec[i].valvalueid,
              valuecode: res.data.rec[i].valvaluecode,
              valuedesc: res.data.rec[i].valvaluedesc,
              compnyid: res.data.rec[i].compnyid,
              compnycode: res.data.rec[i].compnycode,
              brchid: res.data.rec[i].brchid,
              brchcode: res.data.rec[i].brchcode,
              divnid: res.data.rec[i].divnid,
              divncode: res.data.rec[i].divncode,
              deptid: res.data.rec[i].deptid,
              deptcode: res.data.rec[i].deptcode,
              sectioid: res.data.rec[i].sectioid,
              sectiocode: res.data.rec[i].sectiocode,
              unitid: res.data.rec[i].unitid,
              unitcode: res.data.rec[i].unitcode,
              emptypeid: res.data.rec[i].emptypeid,
              emptype: res.data.rec[i].emptypecode,
              grdid: res.data.rec[i].gradeid,
              grdcode: res.data.rec[i].gradecode,
              jobid: res.data.rec[i].jobid,
              jobcode: res.data.rec[i].jobcode,
              positionid: res.data.rec[i].positionid,
              poscode: res.data.rec[i].positioncode,
              updatedby: this.$store.getters.userId,
              action: 2,
            });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      vm.formData.userid = this.$store.getters.userId;
      vm.formData.stamp = this.stamp;
      console.log(vm.formData);
      this.$store.dispatch('post', {url: "/mapping/edit", payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mappinglist", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url: "/mapping/delete/" + vm.formData.gtypeid})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "mappinglist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },
    createHandler() {
      this.$router.push({ name: "mappingnew" });
    },
  },
  mounted() {
    this.loadMappingHandler();
  },
};
</script>