<template>
  <div id="newgridview">
    <v-toolbar color="#ECEFF1">
      <h2 style="color: #3f6892; padding-left: 10px">
        {{ this.parentData.pageTitle }}
      </h2>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text @click="getCSVHandler" class="mr-5">
          <v-icon>mdi-export</v-icon>
        </v-btn>
        <v-btn disabled text @click="onNewPressedHandler" class="mr-5">
          <v-icon>add</v-icon>
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="search"
          style="width: 250px"
          class="pr-5 pt-3"
          hide-details
        >
        </v-text-field>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
      dense
      loading
      :headers="this.parentData.tabHeaders"
      :items="this.parentData.tabItems"
      :search="search"
      :items-per-page="10"
      class="elevation-2 ; font-weight-light;text--secondary;"
      style="font-size: small"
      multi-sort
      fixed-header
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-icon small class="ml-5 mr-2" @click="onEditItem(item)">
          mdi-pen
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    parentData: Object,
  },

  data() {
    return {
      search: "",
    };
  },

  methods: {
    onEditItem(item) {
    //  let search = this.search
      
      this.$emit("EditItem", {id: item.id, search: this.search});
      //this.search = null;
      console.log('search grid', this.search)
     
    },

    onDeleteItemHandler(item) {
      this.$emit("DeleteItem", item.id);
    },

    onCancelItemHandler(item) {
      this.$emit("CancelItem", item.id, this.search);
    },

    onNewPressedHandler() {
      this.$emit("NewPressed");
    },

    

    objectToCSV(data) {
      const csvRows = [];

      //get header
      const headers = Object.keys(data[0]);
      //const headers = this.titles
      csvRows.push(headers.join(","));

      //loop over the rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },
    
    downloadHandler(data) {
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.parentData.pageTitle + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async getCSVHandler() {
      let titles = this.parentData.tabHeaders;
      let item = this.parentData.tabItems;
      let data = [];
      let obj2 = {};

      titles.forEach((element1) => {
        let x = element1.text;
        obj2[x] = element1.value;
      });

      item.forEach((element1) => {
        let obj = {};
        titles.forEach((element2) => {
          if (element2.value != "action") {
            let key = element2.text;
            obj[key] = element1[element2.value];
          }
        });
        data.push(obj);
      });

      const csvData = this.objectToCSV(data);
      this.downloadHandler(csvData);
    },

  },

  mounted(){
      this.search = this.parentData.search;
    },
};
</script>

<style lang="scss">
table {
  background: #f8f9f9;
}

.v-data-table td {
  font-size: 14px;
  height: 50px;
}

.class-on-data-table table {
  table-layout: fixed;
}
</style>