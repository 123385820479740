<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>

                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-row>
                    <v-col cols="12" sm="7" md="7" class="pb-n5" v-if="!this.formData.lockstaff">
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.baitulmalcategory')"
                            type="text"
                            dense
                            v-model="formData.baitulmalid"
                            :items="baitulmalList"
                            @change="changeBaitulmalHandler(formData.baitulmalid)"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="7" md="7" class="pb-n5" v-else>
                        <v-autocomplete
                            outlined
                            :label="this.$t('setupbaitulmal.baitulmalcategory')"
                            type="text"
                            dense
                            v-model="formData.baitulmalid"
                            :items="baitulmalList"
                           readonly
                           background-color="#ffeab2"
                        ></v-autocomplete>
                    </v-col>

                    <v-col cols="1" sm="1" class="pb-n5">
                        <v-dialog v-model="baitulmalDialog" max-width="600px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn dense v-on="on">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <app-genericform 
                                    v-bind:parentData = "{
                                        tabItems: baitulmalItems,
                                        formTitle: `${$t('setupbaitulmal.baitulmalsetup')}`,
                                        formId: 'baitulmalsetup'
                                    }"

                                    v-on:input="itemData = $event"
                                    v-on:saveBaitulmal="saveBaitulmalHandler"
                                    v-on:deleteBaitulmal="deleteBaitulmalHandler"
                                />

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="warning"
                                        text
                                        @click="baitulmalDialog = false"
                                    >
                                        {{$t('button.close')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            background-color="#ffeab2"
                            readonly
                            :label="this.$t('columns.description')"
                            type="text"
                            :rules="[rules.required]"
                            v-model="formData.baitulmaldesc"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.effectivefrom')"
                            type="date"
                            ref="startdate"
                            :rules="[rules.required]"
                            v-model="formData.startdate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.effectiveuntil')"
                            type="date"
                            ref="enddate"
                            :rules="[rules.required]"
                            v-model="formData.enddate"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="10" class="pb-n4 mt-n8">
                        <v-text-field
                            outlined
                            :label="this.$t('columns.remark')"
                            type="text"
                            v-model="formData.remark"
                            @input="updateValue"
                            dense
                        ></v-text-field>
                    </v-col>    
                </v-row>

                <!--
                    The row below is to show the (+) button and the table 
                    detailItem = baitulmal detail, which means 
                    we'll need another 2 detailItem for each table we need
                -->
                <v-row>
                    <app-table 
                        v-bind:parentObject = "{
                            tabHeader: this.tabHeader,
                            detailItem: this.formData.baitulmaldetails
                        }"

                        v-on:input="addItemHandler"
                    />

                </v-row>

                </v-container>

                <div v-if="isErr">
                    <app-error
                        v-bind:parentError="{
                            data: this.errMsg,
                            isErr: this.isErr
                        }"
                        v-on:closeError="isErr = $event"
                    />
                </div>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" />

            </v-form>
        </v-container>
    </div>
</template>

<script>
import CRUDTable from './CRUDTable';
import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
        'app-table': CRUDTable,
        'app-genericform': GenericForm,
        'app-error': ErrorDialog,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                baitulmalid: this.parentObject.data.baitulmalid,
                baitulmalcode: this.parentObject.data.baitulmalcode,
                baitulmaldesc: this.parentObject.data.baitulmaldesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
                remark: this.parentObject.data.remark,
                baitulmaldetails: this.parentObject.data.baitulmaldetails,
                lockstaff: this.parentObject.data.lockstaff,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },
            
            tabHeader: [
                { text: this.$t('columns.actions'), value: 'action', width: '80px', sortable: false},
                { text: this.$t('setupsocso.uptowages'), value: 'wagesamt', width: '110px', sortable: true},
                { text: this.$t('setupepf.eeamount'), value: 'eeamt', width: '100px', sortable: true},
                { text: this.$t('setupepf.eramount'), value: 'eramt', width: '100px', sortable: true}
            ],  

            baitulmalList: [],
            baitulmalItems: [],
            baitulmalCategory: [],

            errMsg: '',

            baitulmalDialog: false,
            isErr: false,
            isEdit: this.parentObject.isEdit,
             search: this.parentObject.searchnew
        }
    },

    methods: {

        loadBaitulmalListHandler() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/baitulmal/all'})
                .then(res => {
                    vm.baitulmalCategory = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.baitulmalList.push(
                            {
                                text: res.data.rec[i].baitulmalcode,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.baitulmalItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].baitulmalcode,
                                desc: res.data.rec[i].baitulmaldesc,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },
        
        addItemHandler($event) {
            this.formData.baitulmaldetails=$event

            this.updateValue();
        },

        updateValue() {
            this.$emit('input', {
                baitulmalid: this.formData.baitulmalid,
                baitulmalcode: this.formData.baitulmalcode,
                baitulmaldesc: this.formData.baitulmaldesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                remark: this.formData.remark,
                baitulmaldetails: this.formData.baitulmaldetails,
                lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        saveBaitulmalHandler() {
            const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                    url: '/baitulmal/' + this.itemData.itemid, 
                    payload: {
                        baitulmalcode: this.itemData.itemcode,
                        baitulmaldesc: this.itemData.itemdesc,
                        stamp: this.itemData.itemstamp,
                        userid: this.formData.userid
                    }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.baitulmalCategory = [];
                        vm.baitulmalItems = [];
                        vm.baitulmalList = [];

                        this.loadBaitulmalListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                    url: '/baitulmal/create', 
                    payload: {
                    baitulmalcode: this.itemData.itemcode,
                    baitulmaldesc: this.itemData.itemdesc,
                    userid: this.formData.userid
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.baitulmalCategory = [];
                        vm.baitulmalItems = [];
                        vm.baitulmalList = [];

                        this.loadBaitulmalListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

        deleteBaitulmalHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                    url: '/baitulmal/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.baitulmalCategory = [];
                        vm.baitulmalItems = [];
                        vm.baitulmalList = [];

                        this.loadBaitulmalListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

        changeBaitulmalHandler(id) {
            let item = this.baitulmalCategory.find(item => item.id === parseInt(id));
            
            this.formData.baitulmalcode = item.baitulmalcode;
            this.formData.baitulmaldesc = item.baitulmaldesc;
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'baitulmallist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadBaitulmalListHandler();
        if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
}
</script>