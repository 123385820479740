<template>
    <div>
        <v-dialog v-model="ratingdialog" max-width="900px">
          <v-card>
            <v-card-text>
              <v-container>
                    <v-row>
                        <span style="font-weight: bold;" class="mb-2 mt-6">Add KPI Progress & Comment</span>
                    </v-row>

                    <div>
                        <v-row class="pb-n4 mt-2">
                            <v-col cols="3" class="ml-0 pl-0">
                                <v-text-field
                                    outlined
                                    label="Review Date"
                                    type="date"
                                    :rules="[rules.required]"
                                    v-model="selections[0].ratingdesc"
                                    dense
                                    class="v-input"
                                    readonly
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="9">
                                    <v-autocomplete
                                        outlined
                                        label="Reviewed By"
                                        type="text"
                                        ref="compnyid"
                                        :rules="[rules.required]"
                                        v-model="formData.compnyid"
                                        dense
                                        :items="companyList"
                                        @input="updateValue"
                                        @change="changeCompany(formData.compnyid)"
                                    ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row class="pb-n4 mt-n8">
                            <v-col cols="3" class="ml-0 pl-0">
                                <v-text-field
                                    outlined
                                    label="Achievement"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].ratingdesc"
                                    dense
                                    class="v-input"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="2">
                                <v-text-field
                                    outlined
                                    label="Progress %"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].ratingdesc"
                                    dense
                                    class="v-input"
                                    readonly
                                    background-color="#FFFDD0"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="pb-n4 mb-4 mt-n8">
                            <v-col cols="12" class="ml-0 pl-0">
                                <v-textarea
                                    outlined
                                    label="Comments"
                                    type="text"
                                    :rules="[rules.required]"
                                    v-model="selections[0].ratingdesc"
                                    dense
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
              </v-container>
            </v-card-text>

            <v-card-text v-if="isErr" class="mt-n12">
                <span style="font-weight: bold; color: red">{{errMsg}}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="closeRatingInput">{{$t('button.cancel')}}</v-btn>
              <v-btn color="green darken-1" text @click="saveRatingInput">{{$t('button.save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row class="mt-4 ml-6">
                <v-row class="blue--text">
                    <span style="align-content: center;" >Progress Review</span>
                    <v-btn class="ml-5" color="primary" dark @click="openRatingInput">+</v-btn>
                </v-row>
        </v-row>

        <div v-if="isErr">
        <app-error
            v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
            }"
            v-on:closeError="isErr = $event"
        />
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
import compareArray from '../../../util/comparearray';
//import GenericForm from '../../generic/GridForm';
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
    props: {
        parentObject: Object
    },

    components: {
       // 'app-genericform': GenericForm,
        "app-error": ErrorDialog,
    },

    data() {
        return {
            publicPath: process.env.BASE_URL,
            radioSelected: 1,

            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
            },

            formData: {
                compnyid: 1
            },

            ratingdialog: false,
            editDialog: false,
            detailItem: this.parentObject.detailItem,
         
            selections: [{
               // clmtypeid: null,
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }],

            
            //error msg and dialog, check for duplicates vars
            isExisting: false,
            isErr: false,
            errMsg: '',
        }
    },

    methods: {
        compareArray,
      
        modifyItemHandler(item) {
            console.log(item);
            this.$router.push({ name: "kpisettingstaffexercise", params: { id: 1 } });
        },

        deleteItemHandler(item) {
            let itemIndex = this.detailItem.indexOf(item);

            this.detailItem.splice(itemIndex, 1);
        },

        openRatingInput() {
            this.ratingdialog = true;
        },

        saveRatingInput() {

            
             if (this.selections[0].ratingcode == null || this.selections[0].label == null
             || this.selections[0].ratingdesc == null || this.selections[0].score == null){

              this.$dialog.alert(this.$t('Setupratingscaleset.ratingmessage')).then((dialog) => {
                console.log(dialog);
             });
            } else {

            if (!this.isExisting && this.modify) {
                this.resetSelections();
            } else if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                console.log(this.compareArray(this.detailItem, this.selections[0]));
                }
            }
            }

            this.$emit('input', this.detailItem);
            this.ratingdialog = false;
            

            // reset the form fields
            this.resetSelections();
      
        },

        closeRatingInput() {
            console.log('here')
            this.radioSelected = 1;
            this.ratingdialog = false;
            this.isErr = false;
            this.isExisting = false;
            this.modify = false;

            // reset the form fields
            this.resetSelections();
        },

        // Saving wizardsetup details into wizardsetupd
        saveTaskHandler() {

            this.isErr = false;

            if (!this.isExisting) {
                this.selections[0].id = null;
                this.selections[0].action = 1;

                this.detailItem.push(Object.assign({}, this.selections[0]));
            } else {
                if (this.compareArray(this.detailItem, this.selections[0])) {
                    console.log(this.compareArray(this.detailItem, this.selections[0]))
                }
                
            }
            
            this.$emit('input', this.detailItem);
            //this.seqnoHandler();
            this.ratingdialog = false;

            // reset the form fields
            this.resetSelections();
        
        },


        resetSelections() {
            console.log('select', this.selections)
            this.selections = [{
                id: null,
                ratingscaleid: null,
                ratingcode: null,
                label: null,
                ratingdesc: null,
                score: null,
                rectimestamp: null,
                updatedby: null,
                stamp: 0,
                action: 0,
                userid: this.$store.getters.userId,
            }]

          //  console.log('lm1373', this.selections)
        },          
    },

    mounted() {
        console.log(this.parentObject);
    }
}
</script>

<style scoped>

.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}

.mdi-delete::before {
    margin-top: -8px;
}

.mdi-pen::before {
  margin-top: -8px;
}

.radio-label {
    left: 5px;
    position: relative;
}
</style>