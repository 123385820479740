<template>
  <div>
    <refnum-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import RefNumForm from "../../../components/payroll/referencenumbersetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "refnum-form": RefNumForm,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('setupcompanyreferencenumber.createnewcompanyreferencenumber'),
      pageTitleValue: "",
      formData: {
        vendorcatid: null,
        catcode: null,
        refnum: null,
        refcode: null,
        vendorid: null,
        vendoraddress: null,
        compnyid: null,
        compnycode:null,
        addressid: null,
        adrmasdesc: null,
        adrmasaddress: null,
        remark: null,
        userid: this.$store.getters.userId,
      },

      errMsg: "",
      isErr: false,
      editMode: false,

      //arrays to store data returned from db
      categories: [],
      catitemsList: [],
      catitems: [],
      vendors: [],
      vendoritemList: [],
      vendoritems: [],
      companies: [],
      companyitemList: [],
      companyitems: [],
      addresses: [],
      addressitemList: [],
      addressitems: [],

      catcode: "",

      vendoraddress: "",

      cityCode: "",

      companyCode: "",

      adrmasdesc: "",
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      //req to insert record
      this.$store.dispatch('post', {url: "/refnum/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "refnumlist" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "refnumlist" });
    },
  },
};
</script>
