<template>
    <div>
        <v-data-table
            :headers="tabHeaders"
            :items="this.parentData.tabItems"
            :search="search"
            append-icon="search"
            class="elavation-1"
            fixed-header
            :sort-by="['code']"
        >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    style="width: 200px"
                    class="pr-5 pt-3"
                    hide-details>
                </v-text-field>
                <v-spacer></v-spacer>
            </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="addItemHandler(item)"
            >
                add
            </v-icon>
        </template>

        </v-data-table>
        
    </div>
</template>

<script>

export default {
    props: {
        parentData: Object
    },

    data() {
        return {
            search: "",
            formTitle: this.parentData.formTitle,
            formId: this.parentData.formId,
            subDialogTitle: '',
            subDialogTitleValue: '',
            subDialog: false,
            tabHeaders: [
                {text: this.$t('columns.actions'), value:'action', sortable: false},
                {text: this.$t('columns.grouptype'), value: 'gtypedesc'},
                {text: this.$t('recgrpassignment.grpcode'), value: 'valuecode'},
                {text: this.$t('columns.description'), value: 'valuedesc'}
            ],
        }
    },

    methods: {
        addItemHandler($event) {
          
            if (this.formId == 'groupitems') {
                this.$emit('addGroupItem', $event);
            }


        }
    }
    
}
</script>

<style lang="scss">

    table{
        background: #F8F9F9
    }

    .v-data-table td {
        font-size: 14px;
        height: 38px;
    }

    .class-on-data-table table {
    table-layout: fixed;
  }

</style>