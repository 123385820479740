<template>
    <div>
        <app-gridview 
            v-bind:parentData = "{
                tabHeaders: this.dataHeader,
                tabItems: this.dataItems,
                pageTitle: this.pageTitle,
                search: this.search,
            }"
            v-on:EditItem="editItemHandler($event)"
            v-on:DeleteItem="deleteItemHandler($event)"
            v-on:NewPressed="newRecordHandler"
        ></app-gridview>
    </div>
</template>

<script>
//import moment from 'moment';

import GridView from '../../../components/common/GridView';

export default {
    components: {
        'app-gridview': GridView
    },

    data() {
        return {
            pageTitle: this.$t('setupholassigment.holassigmentlist'),
            dataHeader: [
                { text: this.$t('columns.actions'),  value: 'action',  width: '100px', sortable: false},
                { text: this.$t('columns.code'), value: 'valuecode', width: '200px' },
                { text: this.$t('columns.description'), value: 'valuedesc', width: '200px' },
            ],

            dataItems: [],
            getId: [],
            errMsg: '',
            search: this.$route.params.search,
        }
    },

    methods: {

         loadHolidayAssignmentList() {
             const vm = this;

             this.$store.dispatch('get', {url: '/holidayassignment/all'})
             .then (res => {
                 vm.dataItems = res.data.rec

             })
             .catch(err => {
                 vm.isErr = true;
                 vm.errMsg = err;
                 vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
             })
         },

        editItemHandler(item) {
            this.$router.push({name: 'holidayassignmentedit', params: { id: item.id, search: item.search } });
        },

        newRecordHandler() {
            this.$router.push({name: 'holidayassignmentnew'});
        }
    },

    mounted() {
        this.loadHolidayAssignmentList()
    }
}
</script>