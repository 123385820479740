<template>
  <div v-if="!loading">
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        searchnew: this.search,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:new="createHandler"
      v-on:save="saveHandler"
      v-on:delete="isConfirm = !isConfirm"
      v-on:error="errorHandler"
    />

    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>

    <div v-if="isConfirm">
      <app-confirm
        v-bind:parentObject="{
          pageTitleValue: this.pageTitleValue,
          isConfirm: this.isConfirm,
        }"
        v-on:confirm="deleteHandler($event)"
        v-on:cancelConfirm="isConfirm = !isConfirm"
      />
    </div>
  </div>
</template>

<script>
//import moment from 'moment';

import ClaimTypeSetupForm from "../../../components/claim/claimtypesetup/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": ClaimTypeSetupForm,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('setupclaim.editclaimtypesetup'),
      pageTitleValue: "",
      formData: {
        clmtype: null,
        clmtypedesc: null,
        clmtypecat: null,
        clmtypecatid: null,
        parentclmid: null,
        parentclmtype: null,
        parentclmtypedesc: null,
        prdtype: null,
        clmprdtypeid: null,
        startmth: null,
        clmviapayroll: null,
        payitemcode: null,
        payitemid: null,
        payitemdesc:null,
        backdateddays: null,
        taxrate: null,
        crcyid: null,
        exchrateid : null,
        selfservice: null,
        confirmedstaff: null,
        onceinemplymt: null, 
        isdisable: null,
        logicscrpt: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },
      loading: true,
      errMsg: "",
      isErr: false,
      isConfirm: false,
      editMode: false,
    };
  },

  methods: {
    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    loadClaimTypeSetup() {
      const vm = this;

      this.$store.dispatch('get', {url: "/claimtype/" + this.id})
        .then((res) => {
          if (
            res.data.rec[0].clmviapayroll == "N" ||
            res.data.rec[0].clmviapayroll == null
          ) {
            vm.formData.clmviapayroll = false;
          } else {
            vm.formData.clmviapayroll = true;
          }

          if (res.data.rec[0].selfservice == "N" || 
          res.data.rec[0].selfservice == null) {
            vm.formData.selfservice = false;
          } else {
            vm.formData.selfservice = true;
          }

          if (
            res.data.rec[0].confirmedstaff == "N" ||
            res.data.rec[0].confirmedstaff == null
          ) {
            vm.formData.confirmedstaff = false;
          } else {
            vm.formData.confirmedstaff = true;
          }

          if (
            res.data.rec[0].onceinemplymt == "N" ||
            res.data.rec[0].onceinemplymt == null
          ) {
            vm.formData.onceinemplymt = false;
          } else {
            vm.formData.onceinemplymt = true;
          }

            if (
            res.data.rec[0].isdisable == "N" ||
            res.data.rec[0].isdisable == null
          ) {
            vm.formData.isdisable = false;
          } else {
            vm.formData.isdisable = true;
          }

          vm.formData.clmtype = res.data.rec[0].clmtype;
          vm.formData.clmtypedesc = res.data.rec[0].clmtypedesc;
          vm.formData.clmtypecat = res.data.rec[0].clmtypecat;
          vm.formData.clmtypecatid = res.data.rec[0].clmtypecatid;
          vm.formData.parentclmid = res.data.rec[0].parentclmid;
          vm.formData.parentclmtype = res.data.rec[0].parentclmtype;
          vm.formData.parentclmtypedesc = res.data.rec[0].parentclmtypedesc;
          vm.formData.clmprdtypeid = res.data.rec[0].clmprdtypeid;
          vm.formData.prdtype = res.data.rec[0].prdtype;
          vm.formData.startmth = res.data.rec[0].startmth;
          vm.formData.payitemcode = res.data.rec[0].payitemcode;
          vm.formData.payitemid = res.data.rec[0].payitemid;
          vm.formData.payitemdesc = res.data.rec[0].payitemdesc;
          vm.formData.backdateddays = res.data.rec[0].backdateddays;
          vm.formData.taxrate = res.data.rec[0].taxrate;
          vm.formData.crcyid = res.data.rec[0].crcyid;
          vm.formData.exchrateid = res.data.rec[0].exchrateid;
          //vm.formData.prdtype = res.data.rec[0].maximumattempt;
          vm.formData.logicscrpt = res.data.rec[0].logicscrpt;
          vm.formData.stamp = res.data.rec[0].stamp;
          vm.pageTitleValue = res.data.rec[0].clmtypedesc;

          vm.loading = false;
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    saveHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }

      this.$store.dispatch('put', {url: "/claimtypesetup/" + this.id, payload: this.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "claimtypesetuplist", params: { search: this.search}  });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    deleteHandler() {
      const vm = this;

      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }

      this.$store.dispatch('delete', {url:
            "/claimtypesetup/" +
            this.id +
            "/" +
            this.formData.userid
        })
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "claimtypesetuplist" });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    createHandler() {
      this.$router.push({ name: "claimtypesetupnew" });
    },
  },

  mounted() {
    this.editMode = true;
    this.loadClaimTypeSetup();
  },
};
</script>