<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}
                        <span class="page_title_value">
                            {{ this.parentObject.pageTitleValue }}
                            </span></h2>
                </v-row>
        
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                       <v-row v-if="!this.formData.lockstaff">
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupholassigment.selectgrp')"
                                type="text"
                                ref= "wdaygrpid"
                                :items="wdaygrpList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.wdaygrpid"
                                @change ="changeGroupHandler(formData.wdaygrpid)"
                                dense
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row v-else>
                        <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('setupholassigment.selectgrp')"
                                type="text"
                                ref= "wdaygrpid"
                                :items="wdaygrpList"
                                :rules="[rules.required]"
                                readonly
                                v-model="formData.wdaygrpid"
                                background-color="#ffeab2"
                                dense
                            ></v-autocomplete>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="8" sm="8" class="pb-n4 mt-n8">
                           <v-text-field
                                outlined
                                :label="this.$t('setupholassigment.grpvaldesc')"
                                id="wdaygrpdesc"
                                type="text"
                                ref="wdaygrdesc" 
                                background-color="#ffeab2"
                                readonly
                                v-model="wdaygrpdesc" 
                                dense                       
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectivefrom')"
                                type="date"
                                ref="startdate"
                                :rules="[rules.required]"
                                v-model="formData.startdate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4" sm="4" class="pb-n5 mt-n8">
                            <v-text-field
                                outlined
                                :label="this.$t('columns.effectiveuntil')"
                                type="date"
                                ref="enddate"
                                :rules="[rules.required]"
                                v-model="formData.enddate"
                                @input="updateValue"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>

                      <v-toolbar flat>
                        <v-row class="mt-4">
                            <v-toolbar-title class="subtitle-2">{{$t('setuprestdayschedule.restdayapttern')}}</v-toolbar-title>
                        </v-row>
                    </v-toolbar>

                    <v-row>
                       <v-col cols="4" sm="4" class="pb-n4">
                           <v-autocomplete
                                outlined
                                :label="this.$t('columns.patterndescription')"
                                id = "rdaypatternid"
                                type="text"
                                ref= "rdaypatternid"
                                :items="rdaypatternList"
                                hide-selected
                                :rules="[rules.required]"
                                @input="updateValue"
                                v-model="formData.rdaypatternid"
                                @change ="changeRdayHandler(formData.rdaypatternid)"
                                dense
                        ></v-autocomplete>
                        </v-col>
                   
                       <v-col cols="4" sm="4" class="pb-n4">
                           <v-text-field
                                outlined
                                id="rdaypatterncode"
                                v-model="rdaypatterncode"
                                type="text"
                                :label="this.$t('columns.patterncode')"
                                readonly
                                background-color="#ffeab2"
                                dense
                        ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <app-formbuttons 
                    v-bind:EditBool = this.parentObject.isEdit

                    v-on:NewPressed="newHandler"
                    v-on:SavePressed="saveHandler" 
                    v-on:DeletePressed="deleteHandler"
                    v-on:CancelPressed="cancelHandler" 
                />

            </v-form>
        </v-container>
    </div>
</template>

<script>
//import GenericForm from '../../generic/GridForm';

export default {
    props: {
        parentObject: Object
    },

    components: {
      //  'app-genericform': GenericForm,
    },

    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('reportsparam.invalidemail')
                }
            },
            formData: {
                wdaygrpid: this.parentObject.data.wdaygrpid,
               // wdaygrpdesc: this.parentObject.data.wdaygrpdesc,
                startdate: this.parentObject.data.startdate,
                enddate: this.parentObject.data.enddate,
              //  rdaypatterndesc: this.parentObject.data.rdaypatterndesc,
               // rdaypatterncode: this.parentObject.data.rdaypatterncode,
                rdaypatternid: this.parentObject.data.rdaypatternid,
                lockstaff: this.parentObject.data.lockstaff,
                search: this.parentObject.data.search,
                stamp: this.parentObject.data.stamp,
                userid: this.parentObject.data.userid
            },

            wdaygrpList: [],
            wdaygrpdesc: '',
            valvalue: [],
            pattern: [],
            rdaypatternList: [],
            rdaypatterncode: '',

            errMsg: '',
            isErr: false,
            isEdit : this.parentObject.isEdit,
            search: this.parentObject.searchnew

        }
    },

    methods: {

        loadValvalueList() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/groupp_grouppb_workday/all'})
                .then(res => {
                    vm.valvalue = res.data.rec;
                    console.log('value', vm.valvalue)

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.wdaygrpList.push(
                                {
                                    text: res.data.rec[i].valuecode,
                                    value: res.data.rec[i].valvalueid
                                }
                            )    
                        }
                    }

                    if (this.formData.wdaygrpid) {
                        this.changeGroupHandler(this.formData.wdaygrpid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeGroupHandler(valvalueid) {
            let item = this.valvalue.find(item => item.valvalueid === valvalueid);

            this.wdaygrpdesc = item.valuedesc;
        },

        loadPatternList() {
            const vm = this;

            this.$store.dispatch('get', {
                url: '/restday/all'})
                .then(res => {
                    vm.pattern = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) { 
                        {
                            vm.rdaypatternList.push(
                                {
                                    text: res.data.rec[i].patterndesc,
                                    value: res.data.rec[i].id
                                }
                            )    
                        }
                    }

                    if (this.formData.rdaypatternid) {
                        this.changeRdayHandler(this.formData.rdaypatternid);
                    }
                })
                .catch(err => {
                    vm.isErr = !vm.isErr;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

         changeRdayHandler(id) {
            let item = this.pattern.find(item => item.id === id);

            this.rdaypatterncode = item.patterncode;
        },


        updateValue() {
            this.$emit('input', {
                wdaygrpid: this.formData.wdaygrpid,
              //  wdaygrpdesc: this.formData.wdaygrpdesc,
                startdate: this.formData.startdate,
                enddate: this.formData.enddate,
                rdaypatternid: this.formData.rdaypatternid,
                search: this.formData.search,
               // rdaypatterncode: this.formData.rdaypatterncode,
               // rdaypatterndesc: this.formData.rdaypatterndesc,
               lockstaff: this.formData.lockstaff,
                stamp: this.formData.stamp,
                userid: this.formData.userid
            });
        },

        newHandler() {
            this.$emit('new');
        },

        saveHandler() {
            this.$emit('save');
        },

        deleteHandler() {
            this.$emit('delete');
        },

        cancelHandler() {
            this.$router.push({name: 'rdayscheduleinstructionlist', params: { search: this.search}});
        },
    },

    mounted() {
        this.loadValvalueList();
        this.loadPatternList();

         if (this.parentObject.isEdit){
            this.duplicateStaffData = this.formData;
            
            if (this.parentObject.data.lockstaff == "N") {
                this.formData.lockstaff = false;
            } else {
                this.formData.lockstaff = true;
            }
        }
    }
    
}
</script>