<template>
    <div>
        <v-container>
            <v-form>
                <v-row>
                    <h2 class="pb-5">{{ formData.title }}</h2>
                </v-row>

                <v-container id="scroll-target" class="custom-form overflow-y-auto px-5">
                    <v-card>
                        <div class="ma-4">
                            <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                            <v-row>
                                <v-col cols="4" sm="4" class="pb-n5 ">
                                    <v-text-field
                                        outlined
                                        :label="this.$t('reportsparam.asatdate')"
                                        type="date"
                                        id="asatdate"
                                        v-model="formData.asatdate"
                                        dense                                
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2" class="pb-n5 mt-1">
                                    <v-checkbox 
                                    input-value="true"
                                    :label="this.$t('reportstaffenquiry.activestaff')"
                                    v-model="activeStaff"
                                    value
                                    ></v-checkbox>
                                </v-col>
                            </v-row>

                        </div>
                    </v-card>
                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                            <v-col>
                                <v-btn 
                                    color="warning" 
                                    style="width: 100px"
                                    @click="goHandler" 
                                > 
                                {{$t('button.go')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
export default {

    data() {
        return{
            formData:{
                asatdate: new Date().toISOString().slice(0,10),
                title: this.$t('leaveenquiry')
            },

            activeStaff: true,
        }
    },

    methods: {
         goHandler() {

            if(this.activeStaff === true) {
                this.$router.push({name: 'leaveenquirydetails', params: { asatdate: this.formData.asatdate, activedate: this.formData.asatdate} });
            } else {
                this.$router.push({name: 'leaveenquirydetails', params: { asatdate: this.formData.asatdate } });
            }
            
        },
    },
}
</script>