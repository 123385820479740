<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      :height="150"
      :width="150"
      :color="'#FFFF00'"
      :background-color="'#000000'"
      :opacity="0.5"
    ></loading>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span style="color: #42adf5" class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-col class="mt-n8">
          <v-row>
            <v-btn
              v-model="goBtn"
              v-if="!goBtn"
              @click="backButton()"
              color="orange"
              class="white--text"
              >BACK</v-btn
            >

            <v-col cols="10" sm="10" class="pb-n5 mt-n6"> </v-col>

            <v-col cols="1" sm="1" class="pb-n5 mt-n4" v-if="!goBtn">
              <v-btn icon>
                <v-icon @click="printThis()">mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-card v-if="goBtn">
            <div class="ma-4">
              <v-subheader class="blue--text">Parameters</v-subheader>
              <v-row>
                <v-col cols="4" sm="4" class="pb-n5">
                  <v-text-field
                    outlined
                    label="Record As At Date"
                    type="date"
                    hide-selected
                    v-model="formData.recdate"
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <v-expansion-panels v-if="goBtn">
            <v-expansion-panel>
              <v-expansion-panel-header class="blue--text"
                >Filter Criteria</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4" sm="4" class="pb-4">
                    <v-autocomplete
                      outlined
                      label="Staff"
                      type="text"
                      ref="staff"
                      :rules="[rules.required]"
                      v-model="formData.staffid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-4">
                    <v-autocomplete
                      outlined
                      label="Division"
                      type="text"
                      ref="division"
                      :rules="[rules.required]"
                      v-model="formData.divid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-4">
                    <v-autocomplete
                      outlined
                      label="Grade Category"
                      type="text"
                      ref="gradeCategory"
                      :rules="[rules.required]"
                      v-model="formData.gradeCatid"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Company"
                      type="text"
                      ref="company"
                      :rules="[rules.required]"
                      v-model="formData.compnyid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Section"
                      type="text"
                      ref="section"
                      :rules="[rules.required]"
                      v-model="formData.sectioid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Employment Type"
                      type="text"
                      ref="employmentType"
                      :rules="[rules.required]"
                      v-model="formData.emptypeid"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Branch"
                      type="text"
                      ref="description"
                      :rules="[rules.required]"
                      v-model="formData.brchid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Unit"
                      type="text"
                      ref="unit"
                      :rules="[rules.required]"
                      v-model="formData.unitid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Payroll Group"
                      type="text"
                      ref="payrollGroup"
                      :rules="[rules.required]"
                      v-model="formData.pay"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Department"
                      type="text"
                      ref="department"
                      :rules="[rules.required]"
                      v-model="formData.deptid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Grade"
                      type="text"
                      ref="grade"
                      :rules="[rules.required]"
                      v-model="formData.grdid"
                      dense
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                    <v-autocomplete
                      outlined
                      label="Position"
                      type="text"
                      ref="position"
                      :rules="[rules.required]"
                      v-model="formData.positionid"
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <v-col>
          <v-row
            ><v-btn
              v-if="goBtn"
              v-model="goBtn"
              @click="displayReport()"
              color="orange"
              class="white--text"
              >PRINT</v-btn
            >
          </v-row></v-col
        >
      </v-form>
    </v-container>
  </div>
</template>
<script>
// import moment from "moment";
// import jsPDF from "jspdf";
// import jsPDF to use the print function, dont forget to npm save --jspdf
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    parentObject: Object,
  },
  components: {
    Loading,
  },

  data() {
    return {
      publicPath: process.env.BASE_URL,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      formData: {
        recdate: this.parentObject.data.recdate,
      },

      itemData: {
        itemid: null,
        itemcode: null,
        itemdesc: null,
        itemstamp: 0,
      },

      show: false,
      goBtn: true,

      showSecurityInfo: false,
      showPayrollInfo: false,
      showClaimInfo: false,
      showLeaveInfo: false,
      showCostInfo: false,
      showClaimchargingInfo: false,
      showWorkdayInfo: false,
      showEmployeeInfo: false,

      staffList: [],
      compnyList: [],
      branchList: [],
      deptList: [],
      divisionList: [],
      sectionList: [],
      unitList: [],
      gradeList: [],
      emptypeList: [],
      positionList: [],

      companies: [],
      staffs: [],
      branches: [],
      departs: [],
      divisions: [],
      sections: [],
      units: [],
      grades: [],
      emptypes: [],
      positions: [],

      errMsg: "",
      isErr: false,
      reportFile: "fulldetails.rptdesign",
      loading: false,
    };
  },

  methods: {
    // load handler to retrieve the staffs according to the record date (recdate)
    loadReport() {
      window.open(
        //'http://localhost:8888/birt/frameset?__report=fulldetails.rptdesign&__format=pdf', '_blank' // <- This is what makes it open in a new window.
        `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&Userid=${this.$store.getters.userId}&dbname=${this.$store.getters.dbname}&__format=pdf`,
        "_blank"
      );
    },
    displayReport() {
      const vm = this;
      vm.loading = true;
      const reporturl = `${this.$store.getters.rptSvrURL}/frameset?__report=report/${this.reportFile}&Userid=${this.$store.getters.userId}&dbname=${this.$store.getters.dbname}&__format=pdf`;

      this.$store.dispatch('post', {
        url: "/reportdisplay/url", 
        payload: {reporturl: reporturl, reportname: this.reportFile},
        responseType: "blob"})
        .then((res) => {
          console.log(res.data);
          vm.loading = false;
          if (res.data && res.status == 200) {
            var fileURL = URL.createObjectURL(res.data);
            window.open(fileURL, "_blank");
          } else {
            vm.isErr = true;
            vm.errMsg = res.data.error;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
          vm.loading = false;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadCompanies() {
      const vm = this;

      this.$store.dispatch('get', {url: `/company/allfilter/${this.$store.getters.userId}`})
        .then((res) => {
          vm.companies = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.compnyList.push({
              text: res.data.rec[i].compnyname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.compnyid) {
            vm.changeCompaniesHandler(vm.formData.compnyid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeCompaniesHandler(id) {
      this.companies.find((item) => item.id === id);
    },

    loadStaff() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.staffs = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffList.push({
              text: res.data.rec[i].staffcode,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.staffid) {
            vm.changeStaffsHandler(vm.formData.staffid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeStaffHandler(id) {
      this.staffs.find((item) => item.id === id);
    },

    loadBranch() {
      const vm = this;

      this.$store.dispatch('get', {url: "/branch/all"})
        .then((res) => {
          vm.branches = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.branchList.push({
              text: res.data.rec[i].brchname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.brchid) {
            vm.changeBranchHandler(vm.formData.brchid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeBranchHandler(id) {
      this.branches.find((item) => item.id === id);
    },

    loadDepartment() {
      const vm = this;

      this.$store.dispatch('get', {url: "/department/all"})
        .then((res) => {
          vm.departs = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.deptList.push({
              text: res.data.rec[i].deptname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.deptid) {
            vm.changeDeptHandler(vm.formData.deptid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeDeptHandler(id) {
      this.departs.find((item) => item.id === id);
    },

    loadDivision() {
      const vm = this;

      this.$store.dispatch('get', {url: "/division/all"})
        .then((res) => {
          vm.divisions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.divisionList.push({
              text: res.data.rec[i].divnname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.divid) {
            vm.changeDivisionHandler(vm.formData.divid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeDivisionHandler(id) {
      this.divisions.find((item) => item.id === id);
    },

    loadSection() {
      const vm = this;

      this.$store.dispatch('get', {url: "/section/all"})
        .then((res) => {
          vm.sections = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.sectionList.push({
              text: res.data.rec[i].sectioname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.sectioid) {
            vm.changeSectionHandler(vm.formData.sectioid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeSectionHandler(id) {
      this.sections.find((item) => item.id === id);
    },

    loadUnit() {
      const vm = this;

      this.$store.dispatch('get', {url: "/unit/all"})
        .then((res) => {
          vm.units = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.unitList.push({
              text: res.data.rec[i].unitname,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.unitid) {
            vm.changeUnitHandler(vm.formData.unitid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeUnitHandler(id) {
      this.units.find((item) => item.id === id);
    },

    loadGrade() {
      const vm = this;

      this.$store.dispatch('get', {url: "/grade/all"})
        .then((res) => {
          vm.grades = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.gradeList.push({
              text: res.data.rec[i].grddesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.grdid) {
            vm.changeGradeHandler(vm.formData.grdid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeGradeHandler(id) {
      this.grades.find((item) => item.id === id);
    },

    // Grade Category is on hold for now
    // loadGradeCat() {
    //     const vm = this;

    //     this.$store.dispatch('get', {url: '/gradecat/all'})
    //         .then(res => {
    //             vm.gradecats = res.data.rec;

    //             for (let i = 0; i < res.data.rec.length; i++) {
    //                 vm.gradeList.push(
    //                     {
    //                         text: res.data.rec[i].grddesc,
    //                         value: res.data.rec[i].id
    //                     }
    //                 )
    //             }

    //             if (vm.formData.grdid) {
    //                 vm.changeGradeHandler(vm.formData.grdid);
    //             }
    //         })
    //         .catch(err => {
    //             vm.isErr = !vm.isErr;
    //             vm.errMsg = err;
    //             vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
    //         });
    // },

    // changeGradeHandler(id) {

    //     this.grades.find(item => item.id === id);

    // },

    loadEmployType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/emptype/all"})
        .then((res) => {
          vm.emptypes = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.emptypeList.push({
              text: res.data.rec[i].emptypedesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.emptypeid) {
            vm.changeEmptypeHandler(vm.formData.emptypeid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changeEmptypeHandler(id) {
      this.emptypes.find((item) => item.id === id);
    },

    //Payrol Group is on hold for now

    loadPosition() {
      const vm = this;

      this.$store.dispatch('get', {url: "/position/all"})
        .then((res) => {
          vm.positions = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.positionList.push({
              text: res.data.rec[i].posdesc,
              value: res.data.rec[i].id,
            });
          }

          if (vm.formData.positionid) {
            vm.changePositionsHandler(vm.formData.positionid);
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    changePositionsHandler(id) {
      this.positions.find((item) => item.id === id);
    },
  },

  mounted() {
    this.loadStaff();
    this.loadCompanies();
    this.loadBranch();
    this.loadDepartment();
    this.loadDivision();
    this.loadSection();
    this.loadUnit();
    this.loadGrade();
    this.loadEmployType();
    this.loadPosition();
  },
};
</script>

<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.styled-input {
  height: 25px;
  font-size: 10pt;
}
.styled-input input[type="text"] {
  height: 25x;
  font-size: 10pt;
  background-color: white;
  color: gray;
}
</style>