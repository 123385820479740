var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-form',{attrs:{"parentObject":{
          data: this.formData,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode
      }}}),(_vm.isErr)?_c('div',[_c('app-error',{attrs:{"parentError":{
                  data: this.errMsg,
                  isErr: this.isErr
              }},on:{"closeError":function($event){_vm.isErr = $event}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }