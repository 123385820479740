var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-gridview',{attrs:{"parentData":{
      tabHeaders: this.dataHeader,
      tabItems: this.dataItems,
      pageTitle: this.pageTitle,
      search: this.search,
      isAdvanceEdit: true,
    }},on:{"AdvanceEditItem":function($event){return _vm.advanceEditItemHandler($event)},"EditItem":function($event){return _vm.editItemHandler($event)},"DeleteItem":function($event){return _vm.deleteItemHandler($event)},"NewPressed":_vm.newRecordHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }